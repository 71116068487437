/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { DebitCardHistoryType } from './DebitCardHistory.type';

export interface DebitCardHistoryOptionContextProps {
  historyOption: DebitCardHistoryType;
  setHistoryOption: (type: DebitCardHistoryType) => void;
}

const DebitCardHistoryOptionContext = React.createContext<
  DebitCardHistoryOptionContextProps
>({
  historyOption: DebitCardHistoryType.cardTransaction,
  setHistoryOption: (type: DebitCardHistoryType) => undefined,
});

export default DebitCardHistoryOptionContext;

import Joi from '@hapi/joi';
import { UserType } from '../../../../types/User.type';

export interface TimeDepositProps {
  id: string;

  name: string;

  commissionRate: number;

  interestRate: number;

  isAnnualPayOut: boolean;

  limitedCustomers: Array<string>;

  minDeposit: number;

  maxDeposit: number;

  term: number;

  D01: boolean;

  D02: boolean;

  A01: boolean;

  A02: boolean;

  C01: boolean;

  C02: boolean;

  VIP: boolean;

  userType: UserType;

  isActive: boolean;

  createdAt?: number;

  updatedAt?: number;
}

export const TimeDepositPropsFormat = {
  id: Joi.string().min(1).required(),

  name: Joi.string().min(1).required(),

  commissionRate: Joi.number().min(0).required(),

  interestRate: Joi.number().min(0).required(),

  isAnnualPayOut: Joi.boolean().required(),

  limitedCustomers: Joi.array().items(Joi.string()).required(),

  minDeposit: Joi.number().min(0).required(),

  maxDeposit: Joi.number().min(0).required(),

  term: Joi.number().min(1).required(),

  D01: Joi.boolean().required(),

  D02: Joi.boolean().required(),

  A01: Joi.boolean().required(),

  A02: Joi.boolean().required(),

  C01: Joi.boolean().required(),

  C02: Joi.boolean().required(),

  VIP: Joi.boolean().required(),

  userType: Object.keys(UserType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),

  isActive: Joi.boolean().required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

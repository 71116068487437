import React, { FC } from 'react';
import { CryptoCurrencyType } from '../../../../types/CryptoCurrency.type';
import BitcoinIcon from '../../../../assets/images/bitcoin.png';
import LitecoinIcon from '../../../../assets/images/litecoin.png';
import Box from '../../utils/layout/Box/Box';

export interface CryptoCurrencyIconProps {
  type: CryptoCurrencyType;
  width: string | number;
  height: string | number;
}

const CryptoCurrencyIcon: FC<CryptoCurrencyIconProps> = ({
  type,
  width,
  height,
}: CryptoCurrencyIconProps) => {
  return (
    <Box width={width} height={height}>
      {(() => {
        switch (type) {
          case CryptoCurrencyType.BTC:
            return <img src={BitcoinIcon} alt="" width="100%" height="100%" />;
          case CryptoCurrencyType.LTCT:
            return <img src={LitecoinIcon} alt="" width="100%" height="100%" />;
          default:
            return <></>;
        }
      })()}
    </Box>
  );
};
export default CryptoCurrencyIcon;

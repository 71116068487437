// Type
import User from '../../../domain/public/user/User/User/User';
import { UseStateType } from '../../../types/typeof/UseState';

// IUseCase
import IAuthenticatedPageViewUseCase from '../../../enhancers/useCase/authentication/AuthenticatedPageViewProvider/IAuthenticatedPageViewUseCase';

// IService
import IUserQueryService from '../../public/user/interface/IUserQueryService';

// Service
import UserQueryService from '../../../service/public/user/UserQueryService';

// IRepository
import IUserRepository from '../../../domain/public/user/User/IUserRepository';

class AuthenticatedPageViewUseCase implements IAuthenticatedPageViewUseCase {
  private userQueryService: IUserQueryService;

  constructor(userRepository: IUserRepository) {
    this.userQueryService = new UserQueryService(userRepository);
  }

  public open = async (
    id: string,
    setter: UseStateType<User | undefined>,
    setState: UseStateType<string>,
  ) => {
    await this.userQueryService.setById(id, setter, setState);
  };
}

export default AuthenticatedPageViewUseCase;

// Error
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';

// Type
import { ConsentFormDocumentData } from '../../../infrastructure/firebase/firestore/collections/verification/consentForm/documentData/ConsentFormDocumentData.type';

// Firestore
import ConsentFormsCollection from '../../../infrastructure/firebase/firestore/collections/verification/consentForm/ConsentFormsCollection';

// Mapper
import ConsentFormMapperFirestore from './mapper/ConsentFormMapperFirestore';

// IRepository
import IConsentFormRepository from '../../../domain/verification/consentForm/IConsentFormRepository';

// DomainObject
import ConsentForm from '../../../domain/verification/consentForm/ConsentForm/ConsentForm';
import ConsentFormFactory from '../../../domain/verification/consentForm/ConsentForm/ConsentFormFactory';

class ConsentFormFirestore implements IConsentFormRepository {
  public findById = async (id: string) => {
    const consentFormsCollection = new ConsentFormsCollection();

    const doc = await consentFormsCollection.fetchSpecific(id);

    if (!doc)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.DATABASE_DATA_NOT_FOUND_BY_ID,
        { dataSource: `ConsentForm`, id },
      );

    const props = ConsentFormMapperFirestore.documentToProps(
      doc.data() as ConsentFormDocumentData,
    );

    return ConsentFormFactory.create(doc.id, props);
  };

  public upsert = async (consentForm: ConsentForm) => {
    const consentFormsCollection = new ConsentFormsCollection();

    const id = consentForm.getId();

    const props = {
      ...consentForm.getCommandParameter(),
      updatedAt: Date.now(),
    };

    await consentFormsCollection.set(id, props);

    return consentForm;
  };
}
export default ConsentFormFirestore;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CryptoCurrencyTradeTransactionContext from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactionContext';

// Style
import {
  CheckMarkIconStyle,
  DescriptionStyle,
  OrderIdLabelStyle,
  OrderIdStyle,
} from './style';

// Component
import { PageTitle } from '../../../../atoms/headLine/HeadLine';
import { TextPrimary, TextSecondary } from '../../../../atoms/text2/Text2';
import HeaderCryptoCurrencyTradeComplete from '../../../../organisms/header/HeaderContentTitle/cryptoCurrency/HeaderCryptoCurrencyTradeComplete';
import IconCheckMarkCircle from '../../../../atoms/icon/IconCheckMarkCircle';
import LineBreakReplaced from '../../../../atoms/text2/LineBreakReplaced';
import TableConfirmCryptoCurrencyTrade from '../../../../organisms/table/confirm/TableConfirmCryptoCurrencyTrade';
import CryptoCurrencyTradeCompleteTemplateLayout from './CryptoCurrencyTradeCompleteTemplateLayout';

const TEMPLATE_ID =
  'templates.cryptoCurrency.CryptoCurrencyTradeCompleteTemplate';

const CryptoCurrencyTradeCompleteTemplate: FC = () => {
  const { t } = useTranslation();

  const { cryptoCurrencyTradeTransaction } = useContext(
    CryptoCurrencyTradeTransactionContext,
  );

  if (!cryptoCurrencyTradeTransaction) return <></>;

  return (
    <CryptoCurrencyTradeCompleteTemplateLayout>
      <HeaderCryptoCurrencyTradeComplete key="header" />
      <IconCheckMarkCircle
        key="icon"
        theme={CheckMarkIconStyle}
        size={`${35 / 16}rem`}
        diameter={73}
      />
      <PageTitle key="contentTitle">
        {t(`${TEMPLATE_ID}.contentTitle`)}
      </PageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced text={t(`${TEMPLATE_ID}.description`)} />
      </TextSecondary>
      <TextPrimary key="orderIdLabel" theme={OrderIdLabelStyle}>
        {t(`${TEMPLATE_ID}.orderId.label`)}
      </TextPrimary>
      <TextPrimary key="orderIdValue" theme={OrderIdStyle}>
        {cryptoCurrencyTradeTransaction?.getRawId()}
      </TextPrimary>
      <TableConfirmCryptoCurrencyTrade key="table" />
    </CryptoCurrencyTradeCompleteTemplateLayout>
  );
};

export default CryptoCurrencyTradeCompleteTemplate;

import { useState } from 'react';
import CryptoCurrencyDeposits from './CryptoCurrencyDeposits';

export const useCryptoCurrencyDeposits = (
  defaultValue?: CryptoCurrencyDeposits,
) => {
  const [cryptoCurrencyDeposits, setCryptoCurrencyDeposits] = useState<
    CryptoCurrencyDeposits
  >(defaultValue || new CryptoCurrencyDeposits());

  return {
    cryptoCurrencyDeposits,
    setCryptoCurrencyDeposits,
  };
};

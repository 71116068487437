import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Style
import { Container } from './style';

// LayoutComponent
import Margin from '../../../atoms/utils/layout/Margin';

export interface ResetAuthenticatorCompleteTemplateLayoutProps {
  children: React.ReactElement[];
}

const ResetAuthenticatorCompleteTemplateLayout: FC<ResetAuthenticatorCompleteTemplateLayoutProps> = ({
  children,
}: ResetAuthenticatorCompleteTemplateLayoutProps) => {
  const elements = ['title', 'description', 'link'];
  const [title, description, link] = getElementsFromKeys(children, elements);

  return (
    <Container>
      <Margin top={200} />
      {title}
      <Margin top={50} />
      {description}
      <Margin top={100} />
      {link}
    </Container>
  );
};
export default ResetAuthenticatorCompleteTemplateLayout;

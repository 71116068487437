import fontWeight from '../../../atoms/utils/const/fontWeight';
import color from '../../../atoms/utils/const/color';

export const contentStyle = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  padding: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(92, 92, 92, 0.75)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const overlayStyle = {
  backgroundColor: 'transparent',
  zIndex: 300,
};

export const ContainerBoxStyle = {
  backgroundColor: color.white,
};

export const VerticalTableLayout = {
  rowWidth: 560,
  rowHeight: 40,
  thWidth: 180,
  tdWidth: 380,
};

export const TitleStyle = {
  fontSize: 28,
  color: color.text.primary,
  fontWeight: fontWeight.bold,
};

export const CancelButtonTextStyle = {
  fontSize: 24,
  color: color.blue,
  fontWeight: fontWeight.medium,
};

export const MessageStyle = {
  fontSize: 28,
  color: color.text.primary,
  fontWeight: fontWeight.regular,
};

export const ErrorMessageStyle = {
  fontSize: 28,
  color: color.errorMessage,
  fontWeight: fontWeight.regular,
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export interface CorporateInformationFileContextProps {
  corporateRegistry: File | undefined;
  setCorporateRegistry: (file: File | undefined) => void;
}

const CorporateInformationFileContext = React.createContext<
  CorporateInformationFileContextProps
>({
  corporateRegistry: undefined,
  setCorporateRegistry: (file: File | undefined) => undefined,
});

export default CorporateInformationFileContext;

import React from 'react';

export interface CardIssuanceFeePaymentModalContextProps {
  showModal: () => void;
  hideModal: () => void;
}

const CardIssuanceFeePaymentModalContext = React.createContext<
  CardIssuanceFeePaymentModalContextProps
>({
  showModal: () => undefined,
  hideModal: () => undefined,
});

export default CardIssuanceFeePaymentModalContext;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Type
import {
  FormInputCorporateAddress,
  FormInputShippingAddress,
} from '../../../../../../components/organisms/form/verification/kycRequest/FormCorporateAddress/FormCorporateAddress.type';

// Constant
import { INITIAL } from '../../../../../../config/constants/requestState';

export interface CorporateAddressSubmitContextProps {
  state: string;
  submitCorporateAddress: (
    content: FormInputCorporateAddress & FormInputShippingAddress,
    callback?: () => void,
  ) => void;
}

const CorporateAddressSubmitContext = React.createContext<
  CorporateAddressSubmitContextProps
>({
  state: INITIAL,
  submitCorporateAddress: (
    content: FormInputCorporateAddress & FormInputShippingAddress,
    callback?: () => void,
  ) => undefined,
});

export default CorporateAddressSubmitContext;

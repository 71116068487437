import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTwoFactorAuthAppSettingsViewUseCase } from './useTwoFactorAuthAppSettingsViewUseCase';
import AuthenticatorContext from '../../../../../domain/authenticator/authenticator/Authenticator/AuthenticatorContext';
import ConsentFormContext from '../../../../../domain/verification/consentForm/ConsentForm/ConsentFormContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Type
import { KycStatusType } from '../../../../../types/KycRequest.type';

// Constant
import { ROUTE_KYC_STATUS } from '../../../../../config/constants/routing';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

export type TwoFactorAuthAppSettingsViewProviderProps = {
  children: React.ReactNode;
};

const TwoFactorAuthAppSettingsViewProvider: FC<TwoFactorAuthAppSettingsViewProviderProps> = ({
  children,
}: TwoFactorAuthAppSettingsViewProviderProps) => {
  const history = useHistory();

  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    authenticator,
    consentForm,
  } = useTwoFactorAuthAppSettingsViewUseCase(userAuthorized);

  /* *
   *
   *  useEffect (Redirect)
   *
   * */

  // Status of ConsentForm
  const status = useMemo(
    () =>
      consentForm?.getConsentFormStepsStatus().getProps()
        .statusTwoFactorAuthenticationAppSettings,
    [consentForm],
  );

  // Redirect if status is invalid
  useEffect(() => {
    if (
      status &&
      ![
        KycStatusType.initial,
        KycStatusType.submitted,
        KycStatusType.rejected,
      ].includes(status)
    )
      history.push(ROUTE_KYC_STATUS);
    // eslint-disable-next-line
  }, [status]);

  return (
    <AuthenticatorContext.Provider value={{ authenticator }}>
      <ConsentFormContext.Provider value={{ consentForm }}>
        <SystemErrorHandler state={state} setState={setState}>
          {children}
        </SystemErrorHandler>
      </ConsentFormContext.Provider>
    </AuthenticatorContext.Provider>
  );
};

export default TwoFactorAuthAppSettingsViewProvider;

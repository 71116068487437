import React, { FC } from 'react';

// Component
import DebitCardRequestTemplate from '../../../components/templates/debitCardRequest/DebitCardRequestTemplate';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';

const DebitCardRequestPage: FC = () => {
  return (
    <DashboardTemplate>
      <DebitCardRequestTemplate />
    </DashboardTemplate>
  );
};

export default DebitCardRequestPage;

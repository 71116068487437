import ReferralTimeDeposit from '../../referralTimeDeposit/ReferralTimeDeposit/ReferralTimeDeposit';

class ReferralTimeDepositByRankD extends ReferralTimeDeposit {
  public addCommissionTotal = (commission: number) => {
    return new ReferralTimeDepositByRankD(
      this.contractTimeDepositId.getRawId(),
      {
        ...this.props,
        commissionTotal: this.commissionTotal + commission,
      },
    );
  };

  public payContractTimeDepositCommission = (amount: number) => {
    return new ReferralTimeDepositByRankD(
      this.contractTimeDepositId.getRawId(),
      {
        ...this.props,
        commissionPaid: this.commissionPaid + amount,
      },
    );
  };

  public getAffiliatorContractName = () => undefined;
}

export default ReferralTimeDepositByRankD;

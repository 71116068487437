// Type
import { NotificationLogType } from '../../types/Notification.type';

// IService
import INotificationLogCreateService from '../../useCases/notification/INotificationLogCreateService';

// IRepository
import { INotificationLogCreateRepository } from '../../domain/notification/notificationLog/INotificationLogRepository';

// DomainObject

class NotificationLogCreateService implements INotificationLogCreateService {
  private notificationLogCreateRepository: INotificationLogCreateRepository;

  constructor(
    notificationLogCreateRepository: INotificationLogCreateRepository,
  ) {
    this.notificationLogCreateRepository = notificationLogCreateRepository;
  }

  public executeCreateReadLogByIdAndType = async (
    id: string,
    type: NotificationLogType,
  ) => {
    await this.notificationLogCreateRepository.createReadLogByIdAndType(
      id,
      type,
    );
  };
}

export default NotificationLogCreateService;

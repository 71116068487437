import { InviterRankDType } from '../../../../types/Invite.type';
import { InviterRankDProps } from './InviterRankD.type';
import Inviter from '../Inviter/Inviter';

class InviterRankD extends Inviter {
  protected rank: InviterRankDType;

  private commissionRate: number;

  private totalCommission: number;

  private totalCommissionReceived: number;

  private totalReferralChildrenTimeDepositAmount: number;

  constructor(id: string, props: InviterRankDProps) {
    super(id, props);
    this.rank = props.rank;
    this.commissionRate = props.commissionRate;
    this.totalCommission = props.totalCommission;
    this.totalCommissionReceived = props.totalCommissionReceived;
    this.totalReferralChildrenTimeDepositAmount =
      props.totalReferralChildrenTimeDepositAmount;
  }

  public getId = () => this.id;

  public getProps = () => {
    return {
      ...super.getProps(),
      rank: this.rank,
      commissionRate: this.commissionRate,
      totalCommission: this.totalCommission,
      totalCommissionReceived: this.totalCommissionReceived,
      totalReferralChildrenTimeDepositAmount: this
        .totalReferralChildrenTimeDepositAmount,
    };
  };
}
export default InviterRankD;

import { useState } from 'react';
import TransferDebitCardTransactionV2 from './TransferDebitCardTransactionV2';

export const useTransferDebitCardTransactionV2 = (
  defaultValue?: TransferDebitCardTransactionV2,
) => {
  const [
    transferDebitCardTransactionV2,
    setTransferDebitCardTransactionV2,
  ] = useState<TransferDebitCardTransactionV2 | undefined>(defaultValue);

  return {
    transferDebitCardTransactionV2,
    setTransferDebitCardTransactionV2,
  };
};

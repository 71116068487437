import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  CorporateAddressProps,
  CorporateAddressPropsFormat,
  CorporateAddressInitialProps,
} from './CorporateAddress.type';

// ValueObject
import CorporateAddress from './CorporateAddress';

class CorporateAddressFactory {
  static create = (props: CorporateAddressProps) => {
    const result = Joi.object(CorporateAddressPropsFormat).validate(props);

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: CorporateAddress.name,
          reason: result.error.details[0].message,
        },
      );

    return new CorporateAddress(props);
  };

  static createInitial = () => {
    return CorporateAddressFactory.create(CorporateAddressInitialProps);
  };
}

export default CorporateAddressFactory;

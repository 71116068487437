import React, { FC, useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Container } from './style';
import storage from '../../../../utils/firebase/cloudStorage/storage';
import Pagination from '../../../atoms/table/pagination/Pagination';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface PDFPageViewerProps {
  filepath: string;
}

const PDFPageViewer: FC<PDFPageViewerProps> = ({
  filepath,
}: PDFPageViewerProps) => {
  const [url, setUrl] = useState();
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = (pages: number) => {
    setNumPages(pages);
  };

  useEffect(() => {
    const setDownloadURL = async () => {
      if (filepath) {
        const pathReference = storage.ref(filepath);
        const dlUrl = await pathReference.getDownloadURL();
        setUrl(dlUrl);
      }
    };
    setDownloadURL();
  }, [filepath]);

  return (
    <Container>
      {(() => {
        if (!url) return <></>;
        return (
          <>
            <Document
              file={url}
              onLoadSuccess={(page) => onDocumentLoadSuccess(page.numPages)}
              // eslint-disable-next-line no-console
              onLoadError={console.error}
            >
              <Page pageNumber={pageNumber} />
            </Document>
            <div style={{ marginTop: `${40 / 16}rem` }}>
              <Pagination
                pageCount={numPages}
                gotoPage={(page: number) => {
                  setPageNumber(page + 1);
                }}
                pageIndex={pageNumber - 1}
              />
            </div>
          </>
        );
      })()}
    </Container>
  );
};
export default PDFPageViewer;

import React, { FC, useContext } from 'react';
import { useCorporateInformationSubmitUseCase } from './useCorporateInformationSubmitUseCase';
import CorporateInformationSubmitContext from './CorporateInformationSubmitContext';
import KycRequestCorporateContext from '../../../../../../domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporateContext';
import CorporateInformationFileContext from '../../../../../fileHandler/verification/kycRequest/CorporateInformationFileProvider/CorporateInformationFileContext';

// Error
import SystemErrorHandler from '../../../../../errorHandler/SystemErrorHandler';

// Type
import { FormInputCorporateInformationContext } from '../../../../../../components/organisms/form/verification/kycRequest/FormCorporateInformaition/FormCorporateInformation.type';

// DomainObject
import UserAuthorizedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

export type CorporateInformationSubmitProviderProps = {
  children: React.ReactNode;
};

const CorporateInformationSubmitProvider: FC<CorporateInformationSubmitProviderProps> = ({
  children,
}: CorporateInformationSubmitProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { kycRequest } = useContext(KycRequestCorporateContext);

  const {
    state,
    setState,
    submitCorporateInformation,
    formInputCorporateInformationContext,
    corporateInformationFileContext,
  } = useCorporateInformationSubmitUseCase(kycRequest, userAuthorized);

  return (
    <FormInputCorporateInformationContext.Provider
      value={formInputCorporateInformationContext}
    >
      <CorporateInformationSubmitContext.Provider
        value={{
          state,
          submitCorporateInformation,
        }}
      >
        <CorporateInformationFileContext.Provider
          value={corporateInformationFileContext}
        >
          <SystemErrorHandler state={state} setState={setState}>
            {children}
          </SystemErrorHandler>
        </CorporateInformationFileContext.Provider>
      </CorporateInformationSubmitContext.Provider>
    </FormInputCorporateInformationContext.Provider>
  );
};
export default CorporateInformationSubmitProvider;

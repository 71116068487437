import { useState } from 'react';
import { DebitCardHistoryType } from './DebitCardHistory.type';

export const useDebitCardHistoryOption = () => {
  const [historyOption, setHistoryOption] = useState<DebitCardHistoryType>(
    DebitCardHistoryType.cardTransaction,
  );

  return {
    historyOption,
    setHistoryOption,
  };
};

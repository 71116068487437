import { useState } from 'react';
import auth from '../../../infrastructure/firebase/firebaseAuthentication/firebaseAuth';
import { checkInternetConnection } from '../../../utils/helpers/connection';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../config/constants/requestState';
import { ErrorCodeType } from '../../../utils/errors/ErrorHandler/ErrorCode.type';

export const useVerifyResetPassword = (oobCode: string) => {
  const [state, setState] = useState<string>(INITIAL);

  const verifyResetPassword = async () => {
    setState(IS_LOADING);

    try {
      // ---check onLine ---- //
      checkInternetConnection();

      await auth.verifyPasswordResetCode(oobCode);
      setState(SUCCESS);
    } catch (error) {
      const code = error.response?.data?.code;

      switch (code) {
        case ErrorCodeType.REQUEST_TIMEOUT_ERROR:
        case ErrorCodeType.AXIOS_CONNECTION_TIMEOUT:
        case ErrorCodeType.FIREBASE_AUTH_NETWORK_FAILED:
          setState(ErrorCodeType.REQUEST_TIMEOUT_ERROR);
          break;
        default:
          setState(ErrorCodeType.RESET_PASSWORD_UNAUTHORIZED);
      }
    }
  };

  return {
    state,
    setState,
    verifyResetPassword,
  };
};

import React, { FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import MaintenanceContext from '../../../domain/settings/maintenance/Maintenance/Maintenance/MaintenanceContext';

// Constant
import { ROUTE_SIGN_IN } from '../../../config/constants/routing';

// Component
import MaintenanceTemplate from '../../../components/templates/maintenance/MaintenanceTemplate';
import FrontPageTemplate from '../../../components/templates/front/FrontPageTemplate';

const MaintenancePage: FC = () => {
  const { maintenance } = useContext(MaintenanceContext);
  const history = useHistory();

  // Redirect
  useEffect(() => {
    if (maintenance && !maintenance.isCurrentlySystemMaintenance())
      history.push(ROUTE_SIGN_IN);
    // eslint-disable-next-line
  }, [maintenance]);

  return (
    <FrontPageTemplate>
      <MaintenanceTemplate />
    </FrontPageTemplate>
  );
};

export default MaintenancePage;

import { useState, useEffect } from 'react';

export const useCountdown = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 0) {
      setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    }
  }, [count]);

  return { count, setCount };
};

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle } from './style';

// Context
import ContractTimeDepositRenewalReservationCreateContext from '../../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationCreateProvider/ContractTimeDepositRenewalReservationCreateContext';

// Component
import { TextSecondary } from '../../../atoms/text2/Text2';
import HeaderContractTimeDepositRenewalReservationConfirmAdd from '../../../organisms/header/HeaderContentTitle/contract/HeaderContractTimeDepositRenewalReservationAddConfirm';
import ProgressStepBarBankTransaction from '../../../molecules/progressStep/ProgressStepBar/ProgressStepBarBankTransaction';
import PropertyListContractTimeDepositAfterMatured from '../../../organisms/propertyList/contract/PropertyListContractTimeDepositAfterMatured';
import FormContractTimeDepositRenewalReservationConfirm from '../../../organisms/form/contract/contractTimeDeposit/FormContractTimeDepositRenewalReservationConfirm';
import TimeDepositAddConfirmTemplateLayout from './ContractTimeDepositRenewalReservationAddConfirmTemplateLayout';

const TEMPLATE_ID =
  'templates.contract.ContractTimeDepositRenewalReservationAddConfirmTemplate';

const ContractTimeDepositRenewalReservationAddConfirmTemplate: FC = () => {
  const { t } = useTranslation();

  // Execute Create
  const {
    requestState,
    executeCreateContractTimeDepositRenewalReservation,
  } = useContext(ContractTimeDepositRenewalReservationCreateContext);

  return (
    <TimeDepositAddConfirmTemplateLayout>
      <HeaderContractTimeDepositRenewalReservationConfirmAdd key="header" />
      <ProgressStepBarBankTransaction
        key="progressStep"
        currentStep="confirm"
      />
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${TEMPLATE_ID}.description`)}
      </TextSecondary>
      <PropertyListContractTimeDepositAfterMatured key="propertyList" />
      <FormContractTimeDepositRenewalReservationConfirm
        key="form"
        requestState={requestState}
        onSubmit={executeCreateContractTimeDepositRenewalReservation}
      />
    </TimeDepositAddConfirmTemplateLayout>
  );
};
export default ContractTimeDepositRenewalReservationAddConfirmTemplate;

import React, { FC, useContext } from 'react';

// Context
import RequestStateContext from '../../../../../value/view/RequestState/RequestStateContext';
import DebitCardRequestsContext from '../../../../../domain/debitCard/debitCardRequest/DebitCardRequest/DebitCardRequests/DebitCardRequestsContext';
import SavingsAccountsContext from '../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccountsContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// UseCase
import { useSavingsAccountsViewUseCase } from './useSavingsAccountsViewUseCase';

// Components
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

export type SavingsAccountsViewProviderProps = {
  children: React.ReactNode;
};
const SavingsAccountsViewProvider: FC<SavingsAccountsViewProviderProps> = ({
  children,
}: SavingsAccountsViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    debitCardRequests,
    savingsAccounts,
  } = useSavingsAccountsViewUseCase(userAuthorized);

  return (
    <RequestStateContext.Provider value={{ requestState: state }}>
      <DebitCardRequestsContext.Provider value={{ debitCardRequests }}>
        <SavingsAccountsContext.Provider value={{ savingsAccounts }}>
          <SystemErrorHandler state={state} setState={setState}>
            {children}
          </SystemErrorHandler>
        </SavingsAccountsContext.Provider>
      </DebitCardRequestsContext.Provider>
    </RequestStateContext.Provider>
  );
};
export default SavingsAccountsViewProvider;

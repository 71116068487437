import { TypeOfT } from 'types/typeof/Translation.type';

// Helper functions
import {
  convertMoneyFormat,
  getSymbolFromCurrency,
} from 'utils/helpers/currencyHelper';

// DomainObject
import TransferSavingsAccountTransactionSeed from 'domain/transaction/transferSavingsAccountTransaction/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed';
import TransferSavingsAccountTransaction from 'domain/transaction/transferSavingsAccountTransaction/TransferSavingsAccountTransaction/TransferSavingsAccountTransaction/TransferSavingsAccountTransaction';

const ORGANISM_ID = `organisms.table.TableConfirmTransferSavingsAccount`;

class TransferSavingsAccountTransactionTableMapper {
  static toTableData = (
    transferSavingsAccountTransaction:
      | TransferSavingsAccountTransactionSeed
      | TransferSavingsAccountTransaction,
    t: TypeOfT,
  ) => {
    const props = transferSavingsAccountTransaction.getProps();

    // Account
    const payAccount = transferSavingsAccountTransaction.getFormattedSavingsAccountNumber();
    const beneficiaryAccount = transferSavingsAccountTransaction.getFormattedBeneficiaryAccountNumber();

    // Amount
    const amountFormatted = convertMoneyFormat(props.amount, props.currency);
    const feeFormatted = convertMoneyFormat(props.fee, props.currency);
    const currencySymbol = getSymbolFromCurrency(props.currency);

    return [
      {
        header: t(`${ORGANISM_ID}.th.payAccount`),
        data: t(`${ORGANISM_ID}.td.savingsAccount`, {
          savingsAccount: payAccount,
        }),
      },
      {
        header: t(`${ORGANISM_ID}.th.beneficiaryBankName`),
        data: t(
          `${ORGANISM_ID}.td.beneficiaryBankName.${props.transferSavingsAccountType}`,
        ),
      },
      {
        header: t(`${ORGANISM_ID}.th.beneficiaryAccount`),
        data: t(`${ORGANISM_ID}.td.beneficiaryAccount`, {
          savingsAccount: beneficiaryAccount,
        }),
      },
      {
        header: t(`${ORGANISM_ID}.th.beneficiaryAccountHolder`),
        data: props.beneficiaryAccountHolder,
      },

      {
        header: t(`${ORGANISM_ID}.th.amount`),
        data: `${currencySymbol} ${amountFormatted}`,
      },
      {
        header: t(`${ORGANISM_ID}.th.fee`),
        data: `${currencySymbol} ${feeFormatted}`,
      },
      {
        header: t(`${ORGANISM_ID}.th.purpose`),
        data: props.purpose,
      },
    ];
  };
}

export default TransferSavingsAccountTransactionTableMapper;

import React, { FC, useContext } from 'react';
import { useCorporateAddressSubmitUseCase } from './useCorporateAddressSubmitUseCase';
import CorporateAddressSubmitContext from './CorporateAddressSubmitContext';
import KycRequestCorporateContext from '../../../../../../domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporateContext';
import CorporateAddressFileContext from '../../../../../fileHandler/verification/kycRequest/CorporateAddressFileProvider/CorporateAddressFileContext';

// Error
import SystemErrorHandler from '../../../../../errorHandler/SystemErrorHandler';

// Type
import { FormInputCorporateAddressContext } from '../../../../../../components/organisms/form/verification/kycRequest/FormCorporateAddress/FormCorporateAddress.type';

// DomainObject
import UserAuthorizedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

export type CorporateAddressSubmitProviderProps = {
  children: React.ReactNode;
};

const CorporateAddressSubmitProvider: FC<CorporateAddressSubmitProviderProps> = ({
  children,
}: CorporateAddressSubmitProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { kycRequest } = useContext(KycRequestCorporateContext);

  const {
    state,
    setState,
    submitCorporateAddress,
    formInputCorporateAddressContext,
    corporateAddressFileContext,
  } = useCorporateAddressSubmitUseCase(kycRequest, userAuthorized);

  return (
    <FormInputCorporateAddressContext.Provider
      value={formInputCorporateAddressContext}
    >
      <CorporateAddressSubmitContext.Provider
        value={{
          state,
          submitCorporateAddress,
        }}
      >
        <CorporateAddressFileContext.Provider
          value={corporateAddressFileContext}
        >
          <SystemErrorHandler state={state} setState={setState}>
            {children}
          </SystemErrorHandler>
        </CorporateAddressFileContext.Provider>
      </CorporateAddressSubmitContext.Provider>
    </FormInputCorporateAddressContext.Provider>
  );
};
export default CorporateAddressSubmitProvider;

import styled from 'styled-components';

export const Container = styled.div`
  height: ${35 / 16}rem;
  nav {
    height: 100%;
  }
  ul {
    height: 100%;
    display: flex;
  }
  li {
    height: 100%;
    width: ${35 / 16}rem;
    margin-right: ${8 / 16}rem;
  }

  button {
    min-width: initial;
    width: 100%;
    height 100%;
    border: 1px solid #E8E9EC;
    background-color: #fff;
    line-height: ${20 / 16}rem;
    font-size: ${15 / 16}rem;
    font-family: LatoRegular;
    color: #A6A7AD;
  }
  button.Mui-selected {
    background-color: #299ADF;
    color: #fff;
  }
  .MuiPaginationItem-ellipsis {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: ${5 / 16}rem;
  }
`;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CryptoCurrencyTradeTransactionSeedContext from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeedContext';

// Style
import { TableVerticalLayout, TableVerticalStyle } from './style';

// Mapper
import CryptoCurrencyTradeTableMapper from './CryptoCurrencyTradeTableMapper';

// Component
import TableVertical from '../../../../molecules/table/table/TableVertical';

const TableConfirmCryptoCurrencyTrade: FC = () => {
  const { t } = useTranslation();
  const { cryptoCurrencyTradeTransactionSeed } = useContext(
    CryptoCurrencyTradeTransactionSeedContext,
  );

  if (!cryptoCurrencyTradeTransactionSeed) return <></>;

  const rows = CryptoCurrencyTradeTableMapper.toTableData(
    cryptoCurrencyTradeTransactionSeed,
    t,
  );

  return (
    <TableVertical
      isTableBorder
      layout={TableVerticalLayout}
      rows={rows}
      theme={TableVerticalStyle}
    />
  );
};

export default TableConfirmCryptoCurrencyTrade;

import React, { FC } from 'react';
import LogoImage from '../../../atoms/image/LogoImage';
import { Container, Content } from './style';

const HeaderLogo: FC = () => (
  <Container>
    <Content>
      <LogoImage logoType="textLogoWithName" />
    </Content>
  </Container>
);

export default HeaderLogo;

import React, { FC } from 'react';
import CryptoCurrencyRateFetchProvider from '../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyRateFetchProvider';
import CryptoCurrencyTradePage from './CryptoCurrencyTradePage';
import CryptoCurrencyTradeCreateUseCase from '../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyTradeCreateUseCase';
import CryptoCurrencyTradeViewProvider from '../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyTradeViewProvider';

const EnhancedCryptoCurrencyTradePage: FC = () => {
  return (
    <CryptoCurrencyTradeViewProvider>
      <CryptoCurrencyRateFetchProvider>
        <CryptoCurrencyTradeCreateUseCase>
          <CryptoCurrencyTradePage />
        </CryptoCurrencyTradeCreateUseCase>
      </CryptoCurrencyRateFetchProvider>
    </CryptoCurrencyTradeViewProvider>
  );
};

export default EnhancedCryptoCurrencyTradePage;

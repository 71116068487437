import styled from 'styled-components';
import { mediumPx22 } from '../../../../fonts/fontSize.json';
import fontColor from '../../../../fonts/color.json';
import fontWeight from '../../../../fonts/fontWeight.json';

export const StyledTextInput = styled.input`
  box-sizing: border-box;
  opacity: 1;
  width: 100%;
  height: ${50 / 16}rem;
  font: ${fontWeight.regular} ${22 / 16}rem LatoRegular;
  background: ${fontColor.textBright} 0% 0% no-repeat padding-box;
  border: 1px solid #D6DDEC;
  padding: ${10 / 16}rem ${20 / 16}rem ;
  letter-spacing: ${mediumPx22 * 0.02}rem;
  ime-mode:disabled;

  :focus {
    outline: none;
    box-shadow: 0px 0px 7px #0093EE45;
    border: 1px solid #D6DDEC; !important
  }

  :disabled {
    outline: none;
    // background: #DBDDE8 0% 0% no-repeat padding-box;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #D5D0DA;
  }
  ::-ms-input-placeholder {
    color: #D5D0DA;
  }
`;

import React, { FC } from 'react';
import { IoIosSend } from 'react-icons/io';
import fontSize from '../../../../../fonts/fontSizePixel.json';
import fontWeight from '../../../../../fonts/fontWeight.json';
import fontColor from '../../../../../fonts/color.json';
import { IconTextContainer, IconContainer } from './style';
import Button from '../../Button';
import TextFonted from '../../../text/TextFonted';
import Display from '../../../div/Display';

export interface ActionButtonProps {
  name: string;
  width?: string;
  height?: string;
  textSize?: number;
  borderRadius?: string;
  boxShadow?: string;
  backgroundColor?: string;
  withIcon?: boolean;
  sizeProgress?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

const ActionButton: FC<ActionButtonProps> = ({
  name,
  width = '100%',
  height = '100%',
  textSize = fontSize.pixel24,
  borderRadius = `0rem`,
  boxShadow = 'none',
  backgroundColor,
  withIcon = false,
  isLoading = false,
  disabled = false,
  sizeProgress = `${30 / 16}rem`,
  onClick = () => undefined,
}: ActionButtonProps) => {
  const colorDefault = fontColor.textBright;
  const colorDisabled = fontColor.textBright;

  const color = disabled ? colorDisabled : colorDefault;

  return (
    <Button
      width={width}
      height={height}
      borderRadius={borderRadius}
      disabled={disabled}
      borderColorDefault={backgroundColor}
      borderColorDisabled="#DBDDE8"
      borderColorLoading="#77B6DD"
      backgroundColorDefault={backgroundColor}
      backgroundColorDisabled="#DBDDE8"
      boxShadow={boxShadow}
      textColorDisabled="#fff"
      sizeProgress={sizeProgress}
      isLoading={isLoading}
      onClick={onClick}
    >
      <TextFonted
        pixel={textSize}
        color={color}
        fontWeight={fontWeight.regular}
        fontFamilyType={'primary' as 'primary' | 'sub'}
      >
        <IconTextContainer>
          <Display isDisplay={withIcon}>
            <IconContainer>
              <IoIosSend color="white" size={`${26 / 16}rem`} />
            </IconContainer>
          </Display>
          <>{name}</>
        </IconTextContainer>
      </TextFonted>
    </Button>
  );
};

export default ActionButton;

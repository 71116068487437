import React, { FC } from 'react';

// Component
import FormInputAddress from '../../../../../../molecules/form/formInputGroup/FormInputAddress';
import KycFormContainer from '../../../../common/KycFormContainer';

const FORM_ID = `organisms.form.verification.FormProofOfResidency.residentialAddress`;

const ResidentialAddressInputForm: FC = () => {
  return (
    <KycFormContainer order={1} formId={FORM_ID}>
      <FormInputAddress
        addressKeys={{
          country: 'country',
          addressLine1: 'addressLine1',
          addressLine2: 'addressLine2',
          city: 'city',
          state: 'state',
          postalCode: 'postalCode',
        }}
      />
    </KycFormContainer>
  );
};

export default ResidentialAddressInputForm;

import React, { FC, useContext } from 'react';
import CryptoCurrencyRateContext from '../../../../../domain/cryptoCurrency/cryptoCurrencyRate/CryptoCurrencyRate/CryptoCurrencyRate/CryptoCurrencyRateContext';

// Type
import { CurrencyType } from '../../../../../types/Currency.type';
import { CryptoCurrencyTradeType } from '../../../../../types/CryptoCurrency.type';
import { TextThemeProps } from '../../../../atoms/text2/Text2';

// LayoutComponent
import { FlexBoxRow } from '../../../../atoms/utils/layout/Box/FlexBox';

// Component
import CryptoCurrencyRatePriceCard from '../../../../molecules/card/cryptoCurrency/CryptoCurrencyRatePriceCard';

export interface CryptoCurrencyTradeRateProps {
  targetCurrency: CurrencyType;
  baseCurrency: CurrencyType;
  theme: {
    label: TextThemeProps;
    arrow: TextThemeProps;
  };
}

const CryptoCurrencyTradeRate: FC<CryptoCurrencyTradeRateProps> = (
  props: CryptoCurrencyTradeRateProps,
) => {
  const { cryptoCurrencyRate } = useContext(CryptoCurrencyRateContext);

  const sellRate = cryptoCurrencyRate?.getProps().sellRate.feeIncluded;
  const buyRate = cryptoCurrencyRate?.getProps().buyRate.feeIncluded;

  return (
    <FlexBoxRow height="auto" justifyContent="space-between">
      <CryptoCurrencyRatePriceCard
        tradeType={CryptoCurrencyTradeType.buy}
        rate={sellRate}
        {...props}
      />
      <CryptoCurrencyRatePriceCard
        tradeType={CryptoCurrencyTradeType.sell}
        rate={buyRate}
        {...props}
      />
    </FlexBoxRow>
  );
};

export default CryptoCurrencyTradeRate;

import React, { FC, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ContractTimeDepositContext from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDepositContext';
import TimeDepositsContext from '../../../../../domain/product/timeDeposit/TimeDeposits/TimeDepositsContext';
import UserVerifiedContext from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedContext';

// Style
import {
  TableVerticalLayout,
  TableVerticalStyle,
} from '../PropertyListContractTimeDeposit/style';

// Mapper
import TableContractTimeDepositAfterMaturedMapper from './TableContractTimeDepositAfterMaturedMapper';

// Component
import TableVertical from '../../../../molecules/table/table/TableVertical';

const PropertyListContractTimeDepositAfterMatured: FC = () => {
  const { t } = useTranslation();
  const { contractTimeDeposit } = useContext(ContractTimeDepositContext);
  const { timeDeposits } = useContext(TimeDepositsContext);
  const { user } = useContext(UserVerifiedContext);

  const rows = useMemo(
    () =>
      contractTimeDeposit &&
      user &&
      TableContractTimeDepositAfterMaturedMapper.domainToTd(
        contractTimeDeposit,
        timeDeposits,
        user,
        t,
      ),
    [contractTimeDeposit, user, timeDeposits, t],
  );

  return (
    <TableVertical
      isTableBorder
      layout={TableVerticalLayout}
      rows={rows}
      theme={TableVerticalStyle}
    />
  );
};

export default PropertyListContractTimeDepositAfterMatured;

import { useState } from 'react';
import TransferSavingsAccountTransaction from './TransferSavingsAccountTransaction';

export const useTransferSavingsAccountTransaction = (
  defaultValue?: TransferSavingsAccountTransaction,
) => {
  const [
    transferSavingsAccountTransaction,
    setTransferSavingsAccountTransaction,
  ] = useState<TransferSavingsAccountTransaction | undefined>(defaultValue);

  return {
    transferSavingsAccountTransaction,
    setTransferSavingsAccountTransaction,
  };
};

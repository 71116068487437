/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Constant
import { INITIAL } from '../../../../../../config/constants/requestState';

export interface DebitCardRequestViewContextProps {
  state: string;
}

const DebitCardRequestViewContext = React.createContext<
  DebitCardRequestViewContextProps
>({
  state: INITIAL,
});

export default DebitCardRequestViewContext;

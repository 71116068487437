import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

// Constant
import {
  EMAIL_EDIT,
  EMAIL_EDIT_COMPLETE,
} from '../../../../config/constants/pageId.json';

// Style
import { NotificationContainer, TitleStyle, DescriptionStyle } from './style';

// Component
import { TitleWithBackLink } from '../../../molecules/text/TitleWithActionLink';
import NotificationSuccess from '../../../organisms/notification/NotificationSuccess';
import SettingsTemplate from '../SettingsTemplateContainer';

const EmailEditCompleteTemplate: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <SettingsTemplate>
      <TitleWithBackLink
        title={t(`${EMAIL_EDIT}.title`)}
        onClick={() => {
          history.go(0);
        }}
      />
      <div style={{ marginTop: `${50 / 16}rem` }}>
        <NotificationContainer>
          <NotificationSuccess
            title={t(`${EMAIL_EDIT_COMPLETE}.notification.title`)}
            titleStyle={TitleStyle}
            descriptionStyle={DescriptionStyle}
            description={t(`${EMAIL_EDIT_COMPLETE}.notification.description`)}
          />
        </NotificationContainer>
      </div>
    </SettingsTemplate>
  );
};

export default EmailEditCompleteTemplate;

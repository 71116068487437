// Types
import { FiatCurrencyType } from '../../../types/FiatCurrency.type';

// IService
import IAssetDebitCardService from '../../../useCases/asset/assetSummary/IAssetDebitCardQueryService';

// IRepository
import IAssetDebitCardRepository from '../../../domain/asset/assetDebitCard/IAssetDebitCardRepository';

class AssetDebitCardQueryService implements IAssetDebitCardService {
  private AssetDebitCardRepository: IAssetDebitCardRepository;

  constructor(AssetDebitCardRepository: IAssetDebitCardRepository) {
    this.AssetDebitCardRepository = AssetDebitCardRepository;
  }

  public findUserSavingsAccountAsset = async (
    userId: string,
    currency: FiatCurrencyType,
  ) => {
    const AssetDebitCard = this.AssetDebitCardRepository.findByPrimaryKey({
      userId,
      currency,
    });

    return AssetDebitCard;
  };
}
export default AssetDebitCardQueryService;

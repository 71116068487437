import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { Container } from './style';

export interface ProgressStepPointLayoutProps {
  children: React.ReactElement[];
  marginBetween: number;
  diameterCircle: string | number;
}

const ProgressStepPointLayout: FC<ProgressStepPointLayoutProps> = ({
  children,
  marginBetween,
  diameterCircle,
}: ProgressStepPointLayoutProps) => {
  const elements = ['circle', 'text'];
  const [circle, text] = getElementsFromKeys(children, elements);

  return (
    <Container>
      {circle}
      <div
        style={{
          position: 'absolute',
          top: diameterCircle,
          marginTop: `${marginBetween / 16}rem`,
        }}
      >
        {text}
      </div>
    </Container>
  );
};
export default ProgressStepPointLayout;

// Type
import { KycRequestProps } from './KycRequest.type';
import { UserType } from '../../../../../types/User.type';
import { PersonalInformationProps } from '../../../../../value/verification/kycRequest/KycRequestContent/PersonalInformation/PersonalInformation.type';
import { ProofOfResidencyProps } from '../../../../../value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidency.type';

// ValueObject
import PersonalInformation from '../../../../../value/verification/kycRequest/KycRequestContent/PersonalInformation/PersonalInformation';
import PersonalInformationFactory from '../../../../../value/verification/kycRequest/KycRequestContent/PersonalInformation/PersonalInformationFactory';
import ProofOfResidency from '../../../../../value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidency';
import ProofOfResidencyFactory from '../../../../../value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidencyFactory';
import KycStepStatus from '../../../../../value/verification/kycRequest/KycStepStatus/KycStepStatus/KycStepStatus';
import KycStepStatusIndividual from '../../../../../value/verification/kycRequest/KycStepStatus/KycStepStatusIndividual/KycStepStatusIndividual';

abstract class KycRequest {
  protected id: string;

  protected username: string;

  protected type: UserType;

  protected personalInformation: PersonalInformation;

  protected proofOfResidency: ProofOfResidency;

  protected kycStepStatus: KycStepStatus;

  protected createdAt: number;

  protected updatedAt: number;

  private props: KycRequestProps;

  constructor(id: string, props: KycRequestProps) {
    this.id = id;
    this.username = props.username;
    this.type = props.type;
    this.personalInformation = PersonalInformationFactory.create(
      props.personalInformation,
    );
    this.proofOfResidency = ProofOfResidencyFactory.create(
      props.proofOfResidency,
    );
    this.kycStepStatus = new KycStepStatusIndividual(props.kycStepStatus);
    this.createdAt = props.createdAt || Date.now();
    this.updatedAt = props.updatedAt || Date.now();
    this.props = props;
  }

  public getId = () => this.id;

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public getCommandParameter() {
    return {
      username: this.username,
      type: this.type,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public getPersonalInformation = () => this.personalInformation;

  public getProofOfResidency = () => this.proofOfResidency;

  public abstract getKycStepStatus: () => KycStepStatus;

  public isReviewing = () => this.kycStepStatus.isReviewing();

  public abstract updatePersonalInformation: (
    props: PersonalInformationProps,
  ) => KycRequest;

  public abstract updateProofOfResidency: (
    props: ProofOfResidencyProps,
  ) => KycRequest;
}
export default KycRequest;

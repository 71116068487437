import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ManualState {
  [key: string]: Array<{ fileName: string; url: string }>;
}

export const initialState = {} as ManualState;

const ManualsSlice = createSlice({
  name: 'manuals',
  initialState,
  reducers: {
    setManuals: (state: ManualState, action: PayloadAction<ManualState>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { setManuals } = ManualsSlice.actions;
export default ManualsSlice.reducer;

import React from 'react';
import TransferSavingsAccountTransaction from './TransferSavingsAccountTransaction';

export interface TransferSavingsAccountTransactionContextProps {
  transferSavingsAccountTransaction?: TransferSavingsAccountTransaction;
}

const TransferSavingsAccountTransactionContext = React.createContext<
  TransferSavingsAccountTransactionContextProps
>({
  transferSavingsAccountTransaction: undefined,
});

export default TransferSavingsAccountTransactionContext;

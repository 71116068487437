import React, { FC } from 'react';
import ArrowIcon from '../../../atoms/icon/ArrowIcon';
import LinkActionText from '../LinkActionText';
import {
  Container,
  ArrowIconContainer,
  NavigationTextContainer,
} from './style';

export interface BreadcrumbActionLinkProps {
  text: string;
  textPixel: number;
  textColor: string;
  textFontWeight: number;
  arrowIconSize?: number;
  onClick: () => void;
}

const BreadcrumbActionLink: FC<BreadcrumbActionLinkProps> = ({
  text,
  textPixel,
  textColor,
  textFontWeight,
  arrowIconSize = 14,
  onClick,
}: BreadcrumbActionLinkProps) => {
  return (
    <Container>
      <ArrowIconContainer>
        <ArrowIcon type="forward" size={arrowIconSize} color={textColor} />
      </ArrowIconContainer>
      <NavigationTextContainer>
        <LinkActionText
          text={text}
          textPixel={textPixel}
          textColor={textColor}
          textFontWeight={textFontWeight}
          onClick={onClick}
        />
      </NavigationTextContainer>
    </Container>
  );
};
export default BreadcrumbActionLink;

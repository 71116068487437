import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import ContractTimeDepositHistoriesContext from '../../../../../../domain/contract/contractTimeDepositHistory/ContractTimeDepositHistories/ContractTimeDepositHistoriesContext';
import DebitCardV2sContext from '../../../../../../domain/debitCard/debitCardV2/DebitCardV2s/DebitCardV2sContext';
import SavingsAccountsContext from '../../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccountsContext';
import TransferDebitCardCreateContext from '../../../../../../enhancers/useCase/transfer/debitCard/TransferDebitCardCreateProvider/TransferDebitCardCreateContext';
import TransferDebitCardPageStateContext from '../../../../../../enhancers/pageState/transfer/TransferDebitCardPageState/TransferDebitCardPageStateContext';
import TransferDebitCardTypeOptionContext from '../../../../../molecules/selector/tabBar/TransferDebitCardTabBar/TransferDebitCardTypeOptionContext';
import TransferDebitCardTransactionV2sContext from '../../../../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2s/TransferDebitCardTransactionV2sContext';
import UserVerifiedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedContext';

// Lib
import { calculateTopUpFee } from './helper';
import { convertMoneyFormat } from '../../../../../../utils/helpers/currencyHelper';
import { transferDebitCardValidation } from '../../../../../../utils/validation/registers';

// Type
import { FiatCurrencyType } from '../../../../../../types/FiatCurrency.type';
import {
  FormInputTransferDebitCard,
  FormInputTransferDebitCardContext,
} from './FormInputTransferDebitCard.type';

// Constant
import { IS_LOADING } from '../../../../../../config/constants/requestState';
import {
  MINIMUM_SAVINGS_ACCOUNT_TO_DEBIT_CARD_AMOUNT,
  MINIMUM_DEBIT_CARD_TO_SAVINGS_ACCOUNT_AMOUNT,
  TIMESTAMP_TOP_UP_FEE_START,
} from '../../../../../../config/constants/business';

// Style
import {
  LabelStyle,
  SubLabelStyle,
  PendingErrorTextStyle,
  AttentionTextStyle,
  AttentionTextEmphasizeStyle,
  DescriptionStyle,
  DescriptionImpactStyle,
  FeeTextStyle,
  FeeLabelStyle,
} from './style';

// Components
import {
  ConfirmButton,
  DefaultButtonTextStyle,
} from '../../../../../atoms/button/Button2';
import { TextPrimary, TextSecondary } from '../../../../../atoms/text2/Text2';
import { TransferDebitCardType } from '../../../../../../types/Transfer.type';
import FormInputTransactionAmount from '../../../../../molecules/form/formInput/textField/FormInputTransactionAmount';
import FormInputTransferFundSource from '../../../../../molecules/form/formInputGroup/FormInputTransferFundSource';
import FormTransferDebitCardLayout from './FormTransferDebitCardLayout';
import AttentionMessageBox from '../../../../notification/AttentionMessageBox';
import LineBreakReplaced from '../../../../../atoms/text2/LineBreakReplaced';
import LinkTextSentence from '../../../../../molecules/link/LinkTextSentence';
import PropertyListTransferDebitCardFee from '../../../../propertyList/debitCard/PropertyListTransferDebitCardFee';

// DomainObject
import ContractTimeDepositHistories from '../../../../../../domain/contract/contractTimeDepositHistory/ContractTimeDepositHistories/ContractTimeDepositHistories';
import DebitCardV2s from '../../../../../../domain/debitCard/debitCardV2/DebitCardV2s/DebitCardV2s';

const FORM_ID = `organisms.form.transfer.FormTransferDebitCard`;

// Is user not blocked from topping up debit card
const BLOCKED_USER_IDS_TOP_UP: Array<string> = [
  // 'teWQodUJlmfLHIDMHUbd7M484hS2',
  // 'p9UQLmqKQrtQuGVP4Use',
];

const FormTransferDebitCard: FC = () => {
  // Mouseover
  const [isDisplayFeeTable, setIsDisplayFeeTable] = useState<boolean>(false);

  const { t } = useTranslation();
  const { formInput, setFormInput } = useContext(
    FormInputTransferDebitCardContext,
  );

  // Page State
  const { pageState, setPageState } = useContext(
    TransferDebitCardPageStateContext,
  );

  // Method
  const { requestState, createTransferDebitCard } = useContext(
    TransferDebitCardCreateContext,
  );

  // DomainObject
  const { contractTimeDepositHistories } = useContext(
    ContractTimeDepositHistoriesContext,
  );
  const { debitCards } = useContext(DebitCardV2sContext);
  const { savingsAccounts } = useContext(SavingsAccountsContext);
  const { transferDebitCardTransactions } = useContext(
    TransferDebitCardTransactionV2sContext,
  );
  const { user } = useContext(UserVerifiedContext);

  // Option
  const { transferTypeOption } = useContext(TransferDebitCardTypeOptionContext);

  /**
   *
   *  Form
   *
   */
  const methods = useForm<FormInputTransferDebitCard>({
    mode: 'onChange',
  });

  const {
    formState: { isValid },
    handleSubmit,
    setValue,
    trigger,
    register,
    reset,
    watch,
  } = methods;

  /**
   *
   *  Domain Object
   *
   */
  const savingsAccount = savingsAccounts.filterById(
    watch(`savingsAccountNumber`),
  );
  const transactionFilterByDebitCardId = transferDebitCardTransactions.filterByDebitCardId(
    watch(`debitCardId`),
  );

  // PendingTransactions
  const pendingTransactions = useMemo(
    () => transactionFilterByDebitCardId.filterUnconfirmed(),
    [transactionFilterByDebitCardId],
  );

  // ActiveTransactions
  const activeTransactions = useMemo(
    () => transactionFilterByDebitCardId.filterActive(),
    [transactionFilterByDebitCardId],
  );

  const activeTransactionsAfterTopUpFeeStart = useMemo(
    () => activeTransactions.filterCreatedAfter(TIMESTAMP_TOP_UP_FEE_START),
    [activeTransactions],
  );

  /**
   *
   *  Dependent Value
   *
   */

  // DebitCardsFiltered
  const debitCardsFiltered = useMemo(
    () =>
      // eslint-disable-next-line no-console
      !savingsAccount
        ? new DebitCardV2s()
        : debitCards.filterBySavingsAccountNumber(
            savingsAccount.getRawAccountNumber(),
          ),
    [savingsAccount, debitCards],
  );

  // DebitCard
  const debitCard = debitCardsFiltered.filterById(watch(`debitCardId`));

  // ContractTimeDepositHistories
  const contractTimeDepositHistoriesFiltered = useMemo(() => {
    if (!savingsAccount) return new ContractTimeDepositHistories();

    return contractTimeDepositHistories.filterBySavingsAccount(savingsAccount);
  }, [savingsAccount, contractTimeDepositHistories]);

  // Pending
  const hasPending = useMemo(() => pendingTransactions.hasTransaction(), [
    pendingTransactions,
  ]);

  const userId = user?.getId() as string;
  const isUserBlockedForTopUp = BLOCKED_USER_IDS_TOP_UP.some(
    (blockedUserId) => blockedUserId === userId,
  );

  // Calculate fee
  const totalToppedUpAmount = useMemo(
    () => activeTransactionsAfterTopUpFeeStart?.getTotalToppedUpAmount(),
    [activeTransactionsAfterTopUpFeeStart],
  );
  const maxAmountTotalContractTimeDeposit = useMemo(
    () => contractTimeDepositHistoriesFiltered?.getMaxAmount() || 0,
    [contractTimeDepositHistoriesFiltered],
  );

  const currency = useMemo(
    () => (debitCard ? debitCard.getProps().currency : FiatCurrencyType.USD),
    [debitCard],
  );

  const amount = watch('amount');

  /**
   *
   *  DefaultValue
   *
   */

  // Set DefaultValue
  useEffect(() => {
    if (formInput) reset(formInput);
    // eslint-disable-next-line
  }, [formInput, pageState]);

  // Set Default SavingsAccountNumber
  useEffect(() => {
    if (!formInput)
      setValue(
        `savingsAccountNumber`,
        savingsAccounts.head()?.getRawAccountNumber(),
      );
    // eslint-disable-next-line
  }, [formInput, savingsAccounts]);

  // Set Default DebitCardNumber
  useEffect(() => {
    if (!formInput) setValue(`debitCardId`, debitCardsFiltered.head()?.getId());
    // eslint-disable-next-line
  }, [debitCardsFiltered]);

  // Set TopUpFee
  useEffect(() => {
    setValue(
      'fee',
      calculateTopUpFee(
        totalToppedUpAmount,
        maxAmountTotalContractTimeDeposit,
        Number(amount || 0),
        currency,
      ),
    );
    // eslint-disable-next-line
  }, [
    totalToppedUpAmount,
    maxAmountTotalContractTimeDeposit,
    currency,
    amount,
  ]);

  /**
   *
   * UseEffect
   *
   */

  // Set TransferDebitCard
  useEffect(() => {
    setValue('transferDebitCardType', transferTypeOption);
  }, [transferTypeOption, setValue]);

  /**
   *
   *  Minimum for Transfer Amount
   *
   */

  // TopUpLimit
  const topUpLimit = useMemo(
    () => (debitCard ? debitCard.getProps().topUpLimit : 0),
    [debitCard],
  );

  // Balance and minAmount
  const [balance, minAmount] = useMemo(
    () =>
      transferTypeOption === TransferDebitCardType.savingsAccountToCard
        ? [
            savingsAccount?.getProps().balance ?? 0,
            Math.min(MINIMUM_SAVINGS_ACCOUNT_TO_DEBIT_CARD_AMOUNT, topUpLimit),
          ]
        : [
            debitCard?.getProps().balance ?? 0,
            Math.min(MINIMUM_DEBIT_CARD_TO_SAVINGS_ACCOUNT_AMOUNT),
          ],
    [transferTypeOption, savingsAccount, debitCard, topUpLimit],
  );

  /**
   *
   *  Event
   *
   */
  const onSubmit = useCallback(
    async (input: FormInputTransferDebitCard) => {
      setFormInput(input);

      if (savingsAccount && debitCard) {
        createTransferDebitCard(input, savingsAccount, debitCard, () => {
          setPageState('confirm');
        });
      }
      // eslint-disable-next-line
    },
    [savingsAccount, debitCard],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <FormTransferDebitCardLayout
          hasPending={hasPending}
          isNotAllowed={isUserBlockedForTopUp}
          isDisplayFeeTable={isDisplayFeeTable}
        >
          <AttentionMessageBox key="attention">
            <TextSecondary theme={AttentionTextStyle}>
              <LineBreakReplaced text={t(`${FORM_ID}.message.attention`)} />
              {t(`${FORM_ID}.message.attentionTopUpAmount1`)}
              <TextSecondary theme={AttentionTextEmphasizeStyle}>
                {convertMoneyFormat(topUpLimit, currency)}
              </TextSecondary>
              {t(`${FORM_ID}.message.attentionTopUpAmount2`)}
            </TextSecondary>
          </AttentionMessageBox>
          <LinkTextSentence
            key="cardNotice"
            text={t(`${FORM_ID}.message.cardNotice`)}
            urlList={[
              {
                replaceText: t(`${FORM_ID}.message.here`),
                url: '#',
                onMouseOver: () => {
                  setIsDisplayFeeTable(true);
                },
                onMouseLeave: () => {
                  setIsDisplayFeeTable(false);
                },
              },
            ]}
            theme={{
              text: DescriptionStyle,
              link: DescriptionImpactStyle,
            }}
          />
          <PropertyListTransferDebitCardFee
            key="feesTable"
            maxAmountTotalContractTimeDeposit={
              maxAmountTotalContractTimeDeposit
            }
            totalToppedUpAmount={totalToppedUpAmount}
            currency={currency}
          />
          <FormInputTransferFundSource
            key="fundSource"
            debitCards={debitCardsFiltered}
            label={t(`${FORM_ID}.label.fundSource`)}
            theme={{
              mainLabel: LabelStyle,
              subLabel: SubLabelStyle,
            }}
            transferType={transferTypeOption}
          />
          <FormInputTransactionAmount
            key="amount"
            label={t(`${FORM_ID}.label.amount`)}
            currency={FiatCurrencyType.USD}
            disabled={hasPending}
            inputWidth={534}
            name="amount"
            onChange={(value: string) => {
              setValue('amount', value);
              trigger();
            }}
            register={register(
              transferDebitCardValidation(t).amount(
                balance,
                Number(watch('fee') ?? 0),
                minAmount,
                topUpLimit,
              ),
            )}
          />
          <TextSecondary key="labelFee" theme={FeeLabelStyle}>
            {t(`${FORM_ID}.label.fee`)}
          </TextSecondary>
          <TextSecondary key="fee" theme={FeeTextStyle}>
            {convertMoneyFormat(watch('fee') ?? 0, currency)}
          </TextSecondary>
          <TextPrimary key="pendingError" theme={PendingErrorTextStyle}>
            {t(`${FORM_ID}.message.pendingError`)}
          </TextPrimary>
          <TextPrimary key="notAllowedError" theme={PendingErrorTextStyle}>
            {t(`${FORM_ID}.message.notAllowedError`)}
          </TextPrimary>
          <ConfirmButton
            key="button"
            width={'100%'}
            height={75}
            isLoading={requestState === IS_LOADING}
            disabled={!(isValid && !hasPending) || isUserBlockedForTopUp}
          >
            <TextPrimary theme={DefaultButtonTextStyle}>
              {t(`atoms.button.confirm`)}
            </TextPrimary>
          </ConfirmButton>
        </FormTransferDebitCardLayout>
      </FormProvider>
    </form>
  );
};

export default React.memo(FormTransferDebitCard);

import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedPageViewProvider from '../enhancers/useCase/authentication/AuthenticatedPageViewProvider';
import AccessHandlerUserStatusProvider from '../enhancers/routing/AccessHandlerUserStatusProvider';

// Type
import { MenuType } from '../config/menu/main/Menu.type';

// Constant
import { ALLOWED_USER_STATUS } from './accessHandler/userStatusPageAccessMapper';
import {
  ROUTE_INVITATION_CODES,
  ROUTE_INVITATIONS,
  ROUTE_REFERRAL_RESULTS,
} from '../config/constants/routing';

// Component
import { EnhancedInvitationCodesPage } from '../containers/invite/InvitationCodesPage';
import { EnhancedInvitationsPage } from '../containers/invite/InvitationsPage';
import { EnhancedReferralResultsPage } from '../containers/invite/ReferralResultsPage';

const InvitationRouter: FC = () => {
  return (
    <Switch>
      <AuthenticatedPageViewProvider>
        <AccessHandlerUserStatusProvider
          statusAllowed={ALLOWED_USER_STATUS[MenuType.invitations]}
        >
          <Route exact path={ROUTE_INVITATION_CODES}>
            <EnhancedInvitationCodesPage />
          </Route>
          <Route exact path={ROUTE_INVITATIONS}>
            <EnhancedInvitationsPage />
          </Route>
          <Route exact path={ROUTE_REFERRAL_RESULTS}>
            <EnhancedReferralResultsPage />
          </Route>
        </AccessHandlerUserStatusProvider>
      </AuthenticatedPageViewProvider>
    </Switch>
  );
};

export default InvitationRouter;

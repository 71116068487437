import Joi from '@hapi/joi';

// Type
import { AssetProps, AssetPropsFormat } from '../../asset/Asset/Asset.type';
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';

export interface AssetDebitCardProps extends AssetProps {
  joint: {
    balance: number;
    pendingBalance: number;
  };
}

export const assetDebitCardInitialProps: AssetDebitCardProps = {
  single: {
    balance: 0,
    pendingBalance: 0,
  },
  joint: {
    balance: 0,
    pendingBalance: 0,
  },
  createdAt: Date.now(),
  updatedAt: Date.now(),
};

export interface AssetDebitCardRawDataType {
  currency: FiatCurrencyType;

  props: AssetDebitCardProps;
}

export interface AssetDebitCardPrimaryKey {
  userId: string;
  currency: FiatCurrencyType;
}

export const AssetDebitCardPropsFormat = {
  ...AssetPropsFormat,

  id: Object.keys(FiatCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),

  joint: Joi.object({
    balance: Joi.number().required(),

    pendingBalance: Joi.number().required(),
  }),
};

import { useState, useCallback } from 'react';
import firestore from '../../../infrastructure/firebase/firestore/client/firestoreClient';

// Functions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// Lib
import {
  firebaseReauth,
  firebaseUpdatePassword,
} from '../../../infrastructure/firebase/firebaseAuthentication/firebaseAuth';
import { executeWithTimeout } from '../../../utils/helpers/timeout';
import { checkInternetConnection } from '../../../utils/helpers/connection';
import { getCurrentTimeTimezone } from '../../../utils/helpers/datetimeHelper';

// Type
import { UserStatusType } from '../../../types/User.type';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../config/constants/requestState';
import { AUTHENTICATOR_VERIFY } from '../../../infrastructure/firebase/firebaseFunctions/endpoint';
import { REQUEST_TIMEOUT } from '../../../config/constants/business';

// Error
import ErrorHandler from '../../../utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from '../../../utils/errors/ErrorHandler/IErrorHandler';

// Interface
import IMailsPasswordEditComplete from '../../../utils/firebase/firestore/mails/IMailsPasswordEditComplete';

// DomainObject
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import Logs from '../../../utils/firebase/firestore/logs/users/updatePassword/LogsUpdatePassword';
import Mails from '../../../utils/firebase/firestore/mails/Mails';

export const useUpdatePassword = (
  email: string,
  userStatus: UserStatusType,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);

  // create
  const updatePassword = useCallback(
    async (currentPassword: string, newPassword: string, code: string) => {
      if (!userAuthorized) return;

      setState(IS_LOADING);

      try {
        checkInternetConnection();

        const id = userAuthorized.getId();

        // verify authenticator
        if ([UserStatusType.initial].indexOf(userStatus) < 0)
          // Verify authenticator
          await new FirebasePrivateFunctions().privatePOST(
            `${AUTHENTICATOR_VERIFY}`,
            {
              id,
              code,
            },
          );

        // Reauth
        await executeWithTimeout(
          firebaseReauth(email, currentPassword),
          REQUEST_TIMEOUT / 2,
        );

        // update password
        await executeWithTimeout(
          firebaseUpdatePassword(newPassword),
          REQUEST_TIMEOUT / 2,
        );

        setState(SUCCESS);

        // Batch 2 Functions
        const batch = firestore().batch();
        const now = getCurrentTimeTimezone();
        const currentTimestamp = now.valueOf();

        // 1. create log (shipping address)
        new Logs(id).create(
          String(currentTimestamp),
          {
            updatedAt: currentTimestamp,
          },
          batch,
        );

        // 2. send notification mail
        const mails: IMailsPasswordEditComplete = new Mails();
        mails.sendPasswordEditComplete(email, now, batch);

        // Batch Execution
        batch.commit().catch((error) => {
          // ignore error
          // eslint-disable-next-line no-console
          console.log('----error----');
          // eslint-disable-next-line no-console
          console.log(error);
        });
      } catch (error) {
        const handler: IErrorHandler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    [email, userStatus, userAuthorized],
  );

  return { state, setState, updatePassword };
};

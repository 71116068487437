import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Style
import { ContainerStyle } from './style';

// LayoutComponent
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface DebitCardMaintenanceNotificationLayoutProps {
  children: React.ReactElement[];
}

const DebitCardMaintenanceNotificationLayout: FC<DebitCardMaintenanceNotificationLayoutProps> = ({
  children,
}: DebitCardMaintenanceNotificationLayoutProps) => {
  const elements = ['icon', 'title', 'description', 'button'];
  const [icon, title, description, button] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn
      width={668}
      height="auto"
      padding={60}
      alignItems="center"
      theme={ContainerStyle}
    >
      {icon}
      <Margin top={30} />
      <div>{title}</div>
      <Margin top={20} />
      <FlexBoxColumn
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <div>{description}</div>
      </FlexBoxColumn>
      <Margin top={40} />
      {button}
    </FlexBoxColumn>
  );
};
export default DebitCardMaintenanceNotificationLayout;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ContractTimeDepositAddPageState } from './useContractTimeDepositAddPageState';

export interface ContractTimeDepositAddPageStateContextProps {
  pageState: ContractTimeDepositAddPageState;
  setPageState: (state: ContractTimeDepositAddPageState) => void;
}

const ContractTimeDepositAddPageStateContext = React.createContext<
  ContractTimeDepositAddPageStateContextProps
>({
  pageState: 'initial',
  setPageState: (state: ContractTimeDepositAddPageState) => undefined,
});

export default ContractTimeDepositAddPageStateContext;

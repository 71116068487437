import React, { FC } from 'react';

// Image
import SLASH from '../../../../../assets/images/slash.svg';

// LayoutOmponent
import Box from '../../../utils/layout/Box/Box';

export interface DebitCardSlashImageProps {
  layout: {
    height: number | string;
    width: number | string;
  };
}

const DebitCardSlashImage: FC<DebitCardSlashImageProps> = ({
  layout,
}: DebitCardSlashImageProps) => {
  return (
    <Box {...layout}>
      <img src={SLASH} alt="" width="100%" height="100%" />
    </Box>
  );
};
export default DebitCardSlashImage;

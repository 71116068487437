import color from '../../../../atoms/utils/const/color';

export const TdStyle = {
  backgroundColor: 'transparent',
  borderBottom: `${1 / 16}rem solid ${color.border.hawkesBlue}`,
  textAlign: 'right',
};

export const ThStyle = {
  backgroundColor: 'transparent',
  borderBottom: `${1 / 16}rem solid ${color.border.hawkesBlue}`,
};

export const TableLayout = {
  row: {
    width: 800,
    height: 88,
  },
  th: {
    width: 500,
    padding: '0 25 0 25',
  },
  tdCurrency: {
    width: 50,
  },
  tdAmount: {
    width: 250,
    padding: '0 50 0 25',
  },
};

import { RankType } from '../../../../types/Invite.type';
import { InviterProps } from './Inviter.type';

abstract class Inviter {
  protected id: string;

  protected username: string;

  protected totalReferralTimeDepositAmount: number;

  protected rank: RankType;

  protected isDeleted: boolean;

  protected createdAt: number;

  protected updatedAt: number;

  protected props: InviterProps;

  constructor(id: string, props: InviterProps) {
    this.id = id;
    this.username = props.username;
    this.rank = props.rank;
    this.isDeleted = props.isDeleted;
    this.totalReferralTimeDepositAmount = props.totalReferralTimeDepositAmount;
    this.createdAt = props.createdAt || Date.now();
    this.updatedAt = props.updatedAt || Date.now();
    this.props = props;
  }

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public getId = () => this.id;

  // public abstract referTimeDeposit: (
  //   depositAmount: number,
  //   term: number,
  // ) => Inviter;

  // public getCommandParameter() {
  //   return {
  //     username: this.username,
  //     totalReferralTimeDepositAmount: this.totalReferralTimeDepositAmount,
  //     isDeleted: this.isDeleted,
  //     createdAt: this.createdAt,
  //   };
  // }
  // public isActive = () => !this.isDeleted;
}
export default Inviter;

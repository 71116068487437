import styled from 'styled-components';

export const IconTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  margin-top: ${5 / 16}rem;
  margin-right: ${8 / 16}rem;
`;

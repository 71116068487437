import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../../atoms/utils/layout/Box/FlexBox';
import Box from '../../../../atoms/utils/layout/Box/Box';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface FormInputTransferFundSourceLayoutProps {
  children: React.ReactElement[];
}

const FormInputTransferFundSourceLayout: FC<FormInputTransferFundSourceLayoutProps> = ({
  children,
}: FormInputTransferFundSourceLayoutProps) => {
  const elements = ['label', 'fromLabel', 'toLabel', 'from', 'to'];
  const [label, fromLabel, toLabel, from, to] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxRow width="100%" justifyContent="space-between">
      {label}
      <FlexBoxRow width={600}>
        <FlexBoxColumn width={96}>
          <Box height={76} textAlign="left">
            {fromLabel}
          </Box>
          <Margin top={30} />
          <Box height={76} textAlign="left">
            {toLabel}
          </Box>
        </FlexBoxColumn>
        <FlexBoxColumn width={504} justifyContent="flex-start">
          {from}
          <Margin top={30} />
          {to}
        </FlexBoxColumn>
      </FlexBoxRow>
    </FlexBoxRow>
  );
};

export default FormInputTransferFundSourceLayout;

import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// JSON
import occupationOptions from './occupations.json';

// LayoutComponent
import Box from '../../../../../atoms/utils/layout/Box/Box';

// Component
import {
  DefaultOptionContainer,
  DefaultSingleValueContainer,
} from '../../../option/optionContainer/DefaultOptionContainer';
import SelectBox from '../../../../../atoms/select/SelectBox';
import HiddenInput from '../../../../../atoms/form/HiddenInput';

export interface OccupationSelectBoxProps {
  name: string;
}

const OccupationSelectBox: FC<OccupationSelectBoxProps> = ({
  name,
}: OccupationSelectBoxProps) => {
  const { setValue, watch } = useFormContext();

  const { t } = useTranslation();

  return (
    <Box>
      <SelectBox
        borderColor="#d6ddec"
        dropdownColor="#212F41"
        isSearchable={true}
        marginLeft={`${0 / 16}rem`}
        minHeight={`${50 / 16}rem`}
        onSelect={(value: string) => {
          setValue(name, value);
        }}
        options={occupationOptions}
        placeholder={t(`molecules.select.placeholder.default`)}
        placeholderColor="#D5D0DA"
        value={watch(name)}
        OptionComponent={DefaultOptionContainer}
        SingleValueComponent={DefaultSingleValueContainer}
      />
      <HiddenInput name={name} value={watch(name) || ''} />
    </Box>
  );
};

export default OccupationSelectBox;

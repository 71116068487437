// IService
import ISavingsAccountQueryService from '../../../useCases/bankAccount/savingsAccount/ISavingsAccountQueryService';

// IRepository
import { ISavingsAccountQueryRepository } from '../../../domain/bankAccount/savingsAccount/ISavingsAccountRepository';

class SavingsAccountQueryService implements ISavingsAccountQueryService {
  private savingsAccountQueryRepository: ISavingsAccountQueryRepository;

  constructor(savingsAccountQueryRepository: ISavingsAccountQueryRepository) {
    this.savingsAccountQueryRepository = savingsAccountQueryRepository;
  }

  public findAllByUserId = async (userId: string) => {
    const savingsAccounts = await this.savingsAccountQueryRepository.findAllByUserId(
      userId,
    );

    return savingsAccounts;
  };

  public findById = async (savingsAccountNumber: string) => {
    const savingsAccount = await this.savingsAccountQueryRepository.findById(
      savingsAccountNumber,
    );

    return savingsAccount;
  };

  public findRawAllByUserId = async (userId: string) => {
    const savingsAccounts = await this.savingsAccountQueryRepository.findRawAllByUserId(
      userId,
    );

    return savingsAccounts;
  };
}

export default SavingsAccountQueryService;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, { FC } from 'react';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { ColumnInstance, UseSortByColumnProps } from 'react-table';
import { Container } from './style';

export interface TableColumn<D extends { [key: string]: any } = {}>
  extends ColumnInstance<D>,
    UseSortByColumnProps<D> {}

export interface ColumnSortProps {
  column: TableColumn;
}

const ColumnSort: FC<ColumnSortProps> = ({ column }: ColumnSortProps) => {
  const ArrowColor = (disabled: boolean | undefined) => {
    return disabled ? '#fff' : '#A6A7AD';
  };

  const onClickUp = () => {
    /* *
     *
     *  if Default or DESC
     *    set (ASC)
     *
     *  if ASC
     *    set Default
     *
     *  */
    if (!column.isSorted || column.isSortedDesc) {
      column.toggleSortBy(false, true); // set ASC
    } else {
      column.clearSortBy();
    }
  };

  const onClickDown = () => {
    /* *
     *
     *  if Default or ASC
     *    set (DESC)
     *
     *  if DESC
     *    set Default
     *
     *  */
    if (!column.isSorted || !column.isSortedDesc) {
      column.toggleSortBy(true, true);
    } else {
      column.clearSortBy();
    }
  };

  return (
    // The value of style is followed by this url
    // It seems to be "React Bug" !!.
    // https://github.com/react-dropzone/react-dropzone/issues/1000#issuecomment-733180854
    <Container
      style={{
        ...column.getHeaderProps(column.getSortByToggleProps()).style,
        appearance: 'none',
        columnFill: 'balance',
        MozColumnFill: 'balance',
        WebkitColumnFill: 'balance',
      }}
    >
      <TiArrowSortedUp
        color={ArrowColor(column.isSorted && !column.isSortedDesc)}
        onClick={onClickUp}
        // onClick={() => column.toggleSortBy(!column.isSortedDesc)}
        size="16px"
      />
      <TiArrowSortedDown
        color={ArrowColor(column.isSortedDesc)}
        onClick={onClickDown}
        // onClick={() => column.toggleSortBy(!column.isSortedDesc)}
        size="16px"
      />
    </Container>
  );
};

export default ColumnSort;

import styled from 'styled-components';
import { bold } from '../../../../fonts/fontWeight.json';
import { mediumPx32 } from '../../../../fonts/fontSize.json';

export const StyledTextInput = styled.input`
  width: ${62 / 16}rem;
  height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: ${5 / 16}rem;
  border: 1px solid #d6ddec;
  opacity: 1;
  box-sizing: border-box;
  font: ${bold} ${mediumPx32}rem Playfair Display;
  text-align: center;

  :focus {
    outline: none;
    box-shadow: 0px 0px 7px #0093EE45;
    border: 1px solid #D6DDEC; !important
  }
`;

// React
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SignInTimeOutContext from '../../../../enhancers/auth/authenticated/SignInTimeOut/SignInTimeOutContext';
import SignInTimeOutModalContext from '../../modal/SignInTimeOutModal/SignInTimeOutModalContext';
import SignOutContext from '../../../../enhancers/auth/authenticated/SignOut/SignOutContext';

// Style
import {
  DescriptionStyle,
  RemainingTimeTextStyle,
  SignOutButtonTextStyle,
} from './style';

import {
  SubmitButton,
  CancelButton,
  DefaultButtonTextStyle,
} from '../../../atoms/button/Button2';

// Component
import AttentionIcon from '../../../atoms/text/CircledText/AttentionIcon';
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import { TextPrimary, TextSecondary } from '../../../atoms/text2/Text2';
import NotificationSignInTimeOutLayout from './NotificationSignInTimeOutLayout';

const ORGANISM_ID = `organisms.notification.NotificationSignInTimeOut`;

const NotificationSignInTimeOut: FC = () => {
  const { t } = useTranslation();
  const { remainingTime, refreshSignInTimeOut } = useContext(
    SignInTimeOutContext,
  );
  const { hideSignInTimeOutModal } = useContext(SignInTimeOutModalContext);
  const { signOut } = useContext(SignOutContext);

  return (
    <NotificationSignInTimeOutLayout>
      <AttentionIcon
        key="icon"
        size={`${50 / 16}rem`}
        textSize={`${25 / 16}rem`}
      />
      <PageTitle key="title">{t(`${ORGANISM_ID}.title`)}</PageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${ORGANISM_ID}.description`)}&nbsp;
        <TextSecondary theme={RemainingTimeTextStyle}>
          {Math.ceil(remainingTime / 1000)}
        </TextSecondary>
        &nbsp;
        {t(`${ORGANISM_ID}.units.seconds`)}
      </TextSecondary>
      <SubmitButton
        key="stayButton"
        width={200}
        height={50}
        theme={{ borderRadius: 25 }}
        onClick={() => {
          refreshSignInTimeOut();
          hideSignInTimeOutModal();
        }}
      >
        <TextPrimary theme={DefaultButtonTextStyle}>
          {t(`atoms.button.staySignIn`)}
        </TextPrimary>
      </SubmitButton>
      <CancelButton
        key="signOutButton"
        width={200}
        height={50}
        theme={{ borderRadius: 25 }}
        onClick={signOut}
      >
        <TextPrimary theme={SignOutButtonTextStyle}>
          {t(`atoms.button.signOut`)}
        </TextPrimary>
      </CancelButton>
    </NotificationSignInTimeOutLayout>
  );
};

export default NotificationSignInTimeOut;

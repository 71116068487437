import React, { FC } from 'react';
import KycRequestCreateProvider from '../../../enhancers/useCase/verification/kycRequest/KycRequestCreateProvider';
import SelectUserTypePage from './SelectUserTypePage';

const EnhancedSelectUserTypePage: FC = () => {
  return (
    <KycRequestCreateProvider>
      <SelectUserTypePage />
    </KycRequestCreateProvider>
  );
};

export default EnhancedSelectUserTypePage;

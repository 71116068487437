import { useState } from 'react';
import CryptoCurrencySellTransactions from './CryptoCurrencySellTransactions';

export const useCryptoCurrencySellTransactions = (
  defaultValue?: CryptoCurrencySellTransactions,
) => {
  const [
    cryptoCurrencySellTransactions,
    setCryptoCurrencySellTransactions,
  ] = useState<CryptoCurrencySellTransactions>(
    defaultValue || new CryptoCurrencySellTransactions(),
  );

  return {
    cryptoCurrencySellTransactions,
    setCryptoCurrencySellTransactions,
  };
};

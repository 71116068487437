/* eslint-disable @typescript-eslint/no-explicit-any */
import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducers';

interface ExtendedWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}
declare const window: ExtendedWindow;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const composeReduxDevToolsEnhancers =
  // eslint-disable-next-line no-underscore-dangle
  (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(...[sagaMiddleware]);

const enhancer =
  process.env.NODE_ENV === 'production'
    ? compose(middleware)
    : composeReduxDevToolsEnhancers(middleware);

const store = createStore<
  ReturnType<typeof reducer> & PersistPartial,
  any,
  any,
  any
>(reducer, enhancer);
const persistor = persistStore(store);
export type GlobalStateType = ReturnType<typeof store.getState>;
export { store, persistor };

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { SelectBoxContainer } from './style';

// Component
import { PageTitle } from '../../../../atoms/headLine/HeadLine';
import CryptoCurrencySelectBox from '../../../../molecules/selectBox/selectBox/cryptoCurrency/CryptoCurrencySelectBox';
import HeaderContentTitleLayout from '../../HeaderContentTitle/HeaderContentTitleLayout';

const ORGANISM_ID = `organisms.header.cryptoCurrency.HeaderCryptoCurrencyWallet`;

const HeaderCryptoCurrencyWallet: FC = () => {
  const { t } = useTranslation();

  return (
    <HeaderContentTitleLayout>
      <PageTitle key="left">{t(`${ORGANISM_ID}.title`)}</PageTitle>
      <SelectBoxContainer key="right">
        <CryptoCurrencySelectBox />
      </SelectBoxContainer>
    </HeaderContentTitleLayout>
  );
};

export default HeaderCryptoCurrencyWallet;

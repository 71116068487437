import React, { FC } from 'react';
import SignInTimeOutModalContext from './SignInTimeOutModalContext';
import SignInTimeOutModal from './SignInTimeOutModal';
import SignInTimeOutContext from '../../../../enhancers/auth/authenticated/SignInTimeOut/SignInTimeOutContext';
import SignOutContext from '../../../../enhancers/auth/authenticated/SignOut/SignOutContext';

export interface EnhancedSignInTimeOutModalProps {
  remainingTime: number;
  refreshSignInTimeOut: () => void;
  signOut: () => Promise<void>;
  showSignInTimeOutModal: () => void;
  hideSignInTimeOutModal: () => void;
}

const EnhancedSignInTimeOutModal: FC<EnhancedSignInTimeOutModalProps> = ({
  remainingTime,
  refreshSignInTimeOut,
  signOut,
  showSignInTimeOutModal,
  hideSignInTimeOutModal,
}: EnhancedSignInTimeOutModalProps) => {
  return (
    <SignInTimeOutModalContext.Provider
      value={{
        showSignInTimeOutModal,
        hideSignInTimeOutModal,
      }}
    >
      <SignInTimeOutContext.Provider
        value={{ remainingTime, refreshSignInTimeOut }}
      >
        <SignOutContext.Provider value={{ signOut }}>
          <SignInTimeOutModal />
        </SignOutContext.Provider>
      </SignInTimeOutContext.Provider>
    </SignInTimeOutModalContext.Provider>
  );
};

export default EnhancedSignInTimeOutModal;

import { useState } from 'react';
import BankWithdrawals from './BankWithdrawals';

export const useBankWithdrawals = (defaultValue?: BankWithdrawals) => {
  const [bankWithdrawals, setBankWithdrawals] = useState<BankWithdrawals>(
    defaultValue || new BankWithdrawals(),
  );

  return {
    bankWithdrawals,
    setBankWithdrawals,
  };
};

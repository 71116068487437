import React from 'react';
import TimeDeposits from './TimeDeposits';

export interface TimeDepositsContextProps {
  timeDeposits: TimeDeposits;
}

const TimeDepositsContext = React.createContext<TimeDepositsContextProps>({
  timeDeposits: new TimeDeposits(),
});

export default TimeDepositsContext;

import React, { FC } from 'react';
import AuthErrorTemplate from '../../../components/templates/error/AuthErrorTemplate';
import FrontPageTemplate from '../../../components/templates/front/FrontPageTemplate';

const AuthError: FC = () => {
  return (
    <FrontPageTemplate>
      <AuthErrorTemplate />
    </FrontPageTemplate>
  );
};

export default AuthError;

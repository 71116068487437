import React, { FC, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import CherryVisa01ProofOfIdentityFileContext from '../../../../../../enhancers/fileHandler/debitCard/debitCardRequest/ProofOfIdentityFileProvider/CherryVisa01ProofOfIdentityFileContext';
import UserAuthorizedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Type
import { FileType } from '../../../../../../types/File.type';

// Style
import { DescriptionStyle } from './style';

// Component
import CheckPointList from '../../../../list/CheckPointList';
import DropzoneArea from '../../../../../molecules/dropzone2/DropzoneArea';
import DebitCardRequestFormContainer from '../../../common/DebitCardRequestFormContainer';
import { TextSecondary } from '../../../../../atoms/text2/Text2';
import FormSignatureUploadLayout from './FormSignatureUploadLayout';

// ValueObject
import CherryVisa01ProofOfIdentity from '../../../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentity';

const FORM_ID = 'organisms.form.debitCards.FormProofOfIdentity.signatureUpload';

export interface SignatureUploadFormProps {
  proofOfIdentity?: CherryVisa01ProofOfIdentity;
}

const FormSignatureUpload: FC<SignatureUploadFormProps> = ({
  proofOfIdentity,
}: SignatureUploadFormProps) => {
  const { t } = useTranslation();
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // Form
  const { setValue } = useFormContext();

  // FileHandler
  const { signature, setSignature } = useContext(
    CherryVisa01ProofOfIdentityFileContext,
  );

  const defaultFilePath = useMemo(() => {
    const uploadedFile = proofOfIdentity?.getProps().signature;

    const url = uploadedFile ? URL.createObjectURL(uploadedFile) : '';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return uploadedFile && `${url}`;
  }, [proofOfIdentity]);

  /* *
   *
   *  UseEffect
   *
   * */

  useEffect(() => {
    if (signature && userAuthorized) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const uploadedFilePath = (signature as any).path;
      setValue('signatureUrl', uploadedFilePath);
    }
    // eslint-disable-next-line
    }, [signature, userAuthorized]);

  return (
    <DebitCardRequestFormContainer key="signature" order={8} formId={FORM_ID}>
      <FormSignatureUploadLayout>
        <TextSecondary key="description" theme={DescriptionStyle}>
          {t(`${FORM_ID}.description`)}
        </TextSecondary>
        <DropzoneArea
          key="dropzone"
          name="signature"
          urlName="signatureUrl"
          acceptFileTypes={[FileType.png, FileType.jpeg]}
          defaultFilePath={defaultFilePath ?? ''}
          setFile={setSignature}
          maxFileSize={2}
          width={667}
        />
        <CheckPointList
          key="checkPoint"
          title={t(`organisms.CheckPointList.title`)}
          checkPoints={[
            t(`organisms.CheckPointList.text.signatureSame`),
            t(`organisms.CheckPointList.text.clear`),
          ]}
        />
      </FormSignatureUploadLayout>
    </DebitCardRequestFormContainer>
  );
};

export default FormSignatureUpload;

import React, { FC } from 'react';

// Type
import { CurrencyType } from '../../../../../types/Currency.type';
import { getSymbolFromCurrency } from '../../../../../utils/helpers/currencyHelper';

// Style
import { DataTextStyle, HeaderTextStyle } from './style';

// Component
import { TextPrimary, TextSecondary } from '../../../../atoms/text2/Text2';
import TrTableVerticalCurrencyLayout from './TrTableVerticalCurrencyLayout';

export interface TrTableVerticalCurrencyProps {
  currency?: CurrencyType;
  data: string;
  header: string;
  rowHeight: number | string;
  layout: {
    th: {
      width: number | string;
    };
    tdCurrency: {
      width: number | string;
    };
    tdAmount: {
      width: number | string;
    };
  };
  theme: {
    th: {
      backgroundColor: string;
      borderBottom?: string;
      textAlign?: string;
    };
    tdCurrency: {
      backgroundColor: string;
      borderBottom?: string;
      textAlign?: string;
    };
    tdAmount: {
      backgroundColor: string;
      borderBottom?: string;
      textAlign?: string;
    };
  };
}

const TrTableVerticalCurrency: FC<TrTableVerticalCurrencyProps> = ({
  currency,
  data,
  header,
  layout,
  rowHeight,
  theme,
}: TrTableVerticalCurrencyProps) => (
  <TrTableVerticalCurrencyLayout
    rowHeight={rowHeight}
    layout={layout}
    theme={theme}
  >
    <TextPrimary key="header" theme={HeaderTextStyle}>
      {header}
    </TextPrimary>
    <TextSecondary key="currency" theme={DataTextStyle}>
      {currency && getSymbolFromCurrency(currency)}
    </TextSecondary>
    <TextSecondary key="amount" theme={DataTextStyle}>
      {data}
    </TextSecondary>
  </TrTableVerticalCurrencyLayout>
);

export default TrTableVerticalCurrency;

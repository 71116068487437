import { useState } from 'react';
import { CryptoCurrencyHistoryType } from './CryptoCurrencyHistory.type';

export const useCryptoCurrencyHistoryOption = () => {
  const [historyOption, setHistoryOption] = useState<CryptoCurrencyHistoryType>(
    CryptoCurrencyHistoryType.all,
  );

  return {
    historyOption,
    setHistoryOption,
  };
};

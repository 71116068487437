import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import Margin from '../../../atoms/utils/layout/Margin';
import { FlexRow } from '../../../atoms/utils/layout/Flex';

export interface CurrencyNameWithShortNameLayoutProps {
  children: React.ReactElement[];
}

const CurrencyNameWithShortNameLayout: FC<CurrencyNameWithShortNameLayoutProps> = ({
  children,
}: CurrencyNameWithShortNameLayoutProps) => {
  const elements = ['name', 'shortName'];
  const [name, shortName] = getElementsFromKeys(children, elements);

  return (
    <FlexRow alignItems="center">
      {name}
      <Margin left={10}>{shortName}</Margin>
    </FlexRow>
  );
};
export default CurrencyNameWithShortNameLayout;

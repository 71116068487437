import { useState } from 'react';

import Inviter from './Inviter';

export const useInviter = (defaultValue?: Inviter) => {
  const [inviter, setInviter] = useState<Inviter | undefined>(defaultValue);

  return {
    inviter,
    setInviter,
  };
};

import React, { FC, useContext } from 'react';
import { useContractTimeDepositViewUseCase } from './useContractTimeDepositViewUseCase';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

// Context
import ContractsTimeDepositContext from '../../../../../domain/contract/contractTimeDeposit/ContractsTimeDeposit/ContractsTimeDepositContext';
import SavingsAccountOptionContext from '../../../../../components/molecules/selectBox/option/option/SavingsAccountOption/SavingsAccountOptionContext';
import SavingsAccountsContext from '../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccountsContext';
import RequestStateContext from '../../../../../value/view/RequestState/RequestStateContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

export type ContractTimeDepositViewUseCaseProps = {
  children: React.ReactNode;
};

const ContractTimeDepositViewUseCase: FC<ContractTimeDepositViewUseCaseProps> = ({
  children,
}: ContractTimeDepositViewUseCaseProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    contractsTimeDepositFiltered,
    savingsAccounts,
    savingsAccountOption,
    setSavingsAccountOption,
  } = useContractTimeDepositViewUseCase(userAuthorized);

  return (
    <RequestStateContext.Provider value={{ requestState: state }}>
      <ContractsTimeDepositContext.Provider
        value={{ contractsTimeDeposit: contractsTimeDepositFiltered }}
      >
        <SavingsAccountsContext.Provider value={{ savingsAccounts }}>
          <SavingsAccountOptionContext.Provider
            value={{
              savingsAccountOption,
              setSavingsAccountOption,
            }}
          >
            <SystemErrorHandler state={state} setState={setState}>
              {children}
            </SystemErrorHandler>
          </SavingsAccountOptionContext.Provider>
        </SavingsAccountsContext.Provider>
      </ContractsTimeDepositContext.Provider>
    </RequestStateContext.Provider>
  );
};

export default ContractTimeDepositViewUseCase;

import React, { useState } from 'react';

export interface FormInputContractTimeDepositAdd {
  savingsAccountNumber: string;
  amount: string;
  term: number;
  interestRate: number;
  isAnnualPayOut: string;
}

export const useFormInputContractTimeDepositAdd = () => {
  const [formInput, setFormInput] = useState<FormInputContractTimeDepositAdd>();

  return {
    formInput,
    setFormInput,
  };
};

export const FormInputContractTimeDepositAddContext = React.createContext<{
  formInput?: FormInputContractTimeDepositAdd;
  setFormInput: (formInput?: FormInputContractTimeDepositAdd) => void;
}>({
  formInput: undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFormInput: (formInput?: FormInputContractTimeDepositAdd) => undefined,
});

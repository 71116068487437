import React, { FC } from 'react';
import { TextPrimary, TextSecondary, TextThemeProps } from '../Text2';
import Button from '../../button/Button2/Button';

export interface TextButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  theme: TextThemeProps;
}

export const TextButtonPrimary: FC<TextButtonProps> = ({
  children,
  onClick = () => undefined,
  theme,
}: TextButtonProps) => {
  return (
    <Button onClick={onClick} theme={{ backgroundColor: 'transparent' }}>
      <TextPrimary theme={theme}>{children}</TextPrimary>
    </Button>
  );
};

export const TextButtonSecondary: FC<TextButtonProps> = ({
  children,
  onClick,
  theme,
}: TextButtonProps) => {
  return (
    <Button onClick={onClick} theme={{ backgroundColor: 'transparent' }}>
      <TextSecondary theme={theme}>{children}</TextSecondary>
    </Button>
  );
};

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AssetTimeDepositContext from '../../../../../domain/asset/assetTimeDeposit/AssetTimeDeposit/AssetTimeDepositContext';

// Style
import { CardLayout } from './style';

// Component
import AssetFiatCurrencyCard from '../../../../molecules/card/asset/AssetFiatCurrencyCard';

const AssetTimeDepositCard: FC = () => {
  const { t } = useTranslation();
  const { assetTimeDeposit } = useContext(AssetTimeDepositContext);

  const props = assetTimeDeposit?.getProps();

  return (
    <AssetFiatCurrencyCard
      // eslint-disable-next-line react/prop-types
      balance={props && props.single.balance}
      label={t(`atoms.label.balance`)}
      title={t(`organisms.AssetCard.timeDeposit`)}
      layout={CardLayout}
    />
  );
};

export default AssetTimeDepositCard;

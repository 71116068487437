// IUseCase
import ICryptoCurrencyWalletViewUseCase from '../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyWalletViewProvider/ICryptoCurrencyWalletViewUseCase';

// IService
import ICryptoCurrencyAvailableQueryService from '../../settings/ICryptoCurrencyAvailableQueryService';
import ICryptoCurrencyWalletQueryService from './interface/ICryptoCurrencyWalletQueryService';

// Service
import CryptoCurrencyAvailableQueryService from '../../../service/settings/CryptoCurrencyAvailableQueryService';
import CryptoCurrencyWalletQueryServiceFactory from '../../../service/cryptocurrency/cryptoCurrencyWallet/cryptoCurrencyWallet/CryptoCurrencyWalletQueryServiceFactory';

// IRepository
import ICryptoCurrencyAvailableRepository from '../../../domain/settings/cryptoCurrencyAvailable/ICryptoCurrencyAvailableRepository';
import ICryptoCurrencyWalletRepository from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/ICryptoCurrencyWalletRepository';

// DomainObject
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class CryptoCurrencyWalletViewUseCase
  implements ICryptoCurrencyWalletViewUseCase {
  private cryptoCurrencyAvailableQueryService: ICryptoCurrencyAvailableQueryService;

  private cryptoCurrencyWalletQueryService: ICryptoCurrencyWalletQueryService;

  constructor(
    cryptoCurrencyAvailableRepository: ICryptoCurrencyAvailableRepository,
    cryptoCurrencyWalletRepository: ICryptoCurrencyWalletRepository,
  ) {
    this.cryptoCurrencyAvailableQueryService = new CryptoCurrencyAvailableQueryService(
      cryptoCurrencyAvailableRepository,
    );

    this.cryptoCurrencyWalletQueryService = CryptoCurrencyWalletQueryServiceFactory.create(
      cryptoCurrencyWalletRepository,
    );
  }

  public open = async () => {
    const cryptoCurrenciesAvailable = await this.cryptoCurrencyAvailableQueryService.findAll();

    return { cryptoCurrenciesAvailable };
  };

  public selectCryptoCurrency = async (user: UserAuthorized) => {
    const userId = user.getId();
    const cryptoCurrencyWallet = await this.cryptoCurrencyWalletQueryService.findMainWallet(
      userId,
    );
    return { cryptoCurrencyWallet };
  };
}
export default CryptoCurrencyWalletViewUseCase;

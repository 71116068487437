import React, { FC } from 'react';

// Style
import { LabelStyle } from './style';

// DomainObject
import { TextPrimary } from '../../../../../../atoms/text2/Text2';
import CherryVisa01CountrySelectBox from '../../../../../selectBox/selectBox/verification/CherryVisa01CountrySelectBox';
import FormInputCherryVisa01CountryLayout from './FormInputCherryVisa01CountryLayout';

interface FormInputCherryVisa01CountryProps {
  label: string;
  labelWidth: string | number;
  inputWidth: string | number;
  name: string;
}

const FormInputCherryVisa01Country: FC<FormInputCherryVisa01CountryProps> = ({
  label,
  labelWidth,
  inputWidth,
  name,
}: FormInputCherryVisa01CountryProps) => {
  return (
    <FormInputCherryVisa01CountryLayout
      inputWidth={inputWidth}
      labelWidth={labelWidth}
    >
      <TextPrimary key="label" theme={LabelStyle}>
        {label}
      </TextPrimary>
      <CherryVisa01CountrySelectBox key="input" name={name} />
    </FormInputCherryVisa01CountryLayout>
  );
};

export default FormInputCherryVisa01Country;

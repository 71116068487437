import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Header } from './style';
import { TitleNotification } from '../../../atoms/text/DomainText';
import { AttentionIcon } from '../../../atoms/text/CircledText';

export interface AttentionMessageBoxProps {
  children: React.ReactNode;
}

const ORGANISMS_ID = 'organisms.AttentionMessageBox';

const AttentionMessageBox: FC<AttentionMessageBoxProps> = ({
  children,
}: AttentionMessageBoxProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Header>
        <div>
          <AttentionIcon />
        </div>
        <div style={{ marginLeft: `${15 / 16}rem` }}>
          <TitleNotification color="#D06E77">
            {t(`${ORGANISMS_ID}.title`)}
          </TitleNotification>
        </div>
      </Header>
      <div style={{ marginTop: `${20 / 16}rem` }}>{children}</div>
    </Container>
  );
};

export default AttentionMessageBox;

import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import ReferralResultsTemplate from '../../../components/templates/invitations/ReferralResultsTemplate';

const ReferralResultsPage: FC = () => {
  return (
    <DashboardTemplate>
      <ReferralResultsTemplate />
    </DashboardTemplate>
  );
};

export default ReferralResultsPage;

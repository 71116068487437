/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { TransferDebitCardPageState } from './useTransferDebitCardPageState';

export interface TransferDebitCardPageStateContextProps {
  pageState: TransferDebitCardPageState;
  setPageState: (state: TransferDebitCardPageState) => void;
}

const TransferDebitCardPageStateContext = React.createContext<
  TransferDebitCardPageStateContextProps
>({
  pageState: 'initial',
  setPageState: (state: TransferDebitCardPageState) => undefined,
});

export default TransferDebitCardPageStateContext;

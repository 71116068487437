import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextPrimary, TextSecondary } from '../../../atoms/text2/Text2';
import { CopyButton } from '../../../atoms/button/Button2';
import TextCopyButtonGroupGroupLayout from './TextCopyButtonGroupLayout';
import Display from '../../../atoms/div/Display';

export interface TextCopyButtonGroupProps {
  text: string;
  buttonMarginLeft?: string | number;
  buttonMarginRight?: string | number;
  buttonStyle?: {
    backgroundColor?: string;
    borderRadius?: string | number;
  };
  buttonTextStyle: {
    fontSize: number;
    color: string;
    fontWeight: number;
  };
  buttonWidth: string | number;
  height: string | number;
  successMessageStyle: {
    fontSize: number;
    color: string;
    fontWeight: number;
  };
  textStyle: {
    fontSize: number;
    color: string;
    fontWeight: number;
  };
  textContainerWidth: string | number;
  textContainerStyle: {
    border?: string;
    backgroundColor?: string;
  };
}

const TextCopyButtonGroup: FC<TextCopyButtonGroupProps> = ({
  text,
  buttonMarginLeft = 0,
  buttonMarginRight = 0,
  buttonStyle,
  buttonTextStyle,
  buttonWidth,
  height,
  successMessageStyle,
  textStyle,
  textContainerWidth,
  textContainerStyle,
}: TextCopyButtonGroupProps) => {
  const { t } = useTranslation();

  const [isCopySuccess, setIsCopySuccess] = useState(false);

  // Make success message hidden in 3 seconds
  useEffect(() => {
    if (isCopySuccess)
      setTimeout(() => {
        setIsCopySuccess(false);
      }, 2000);
  }, [isCopySuccess]);

  return (
    <TextCopyButtonGroupGroupLayout
      textContainerWidth={textContainerWidth}
      textContainerHeight={height}
      buttonMarginLeft={buttonMarginLeft}
      buttonMarginRight={buttonMarginRight}
      textContainerStyle={textContainerStyle}
    >
      <TextSecondary key="text" theme={textStyle}>
        {text}
      </TextSecondary>
      <CopyButton
        key="button"
        width={buttonWidth}
        height={height}
        copyText={text}
        callbackAfterCopy={() => setIsCopySuccess(true)}
        theme={buttonStyle}
      >
        <TextPrimary key="text" theme={buttonTextStyle}>
          {t(`atoms.button.copy`)}
        </TextPrimary>
      </CopyButton>
      <Display key="result" isDisplay={isCopySuccess}>
        <TextSecondary theme={successMessageStyle}>
          {t(`atoms.copyButton.successMessage.short`)}
        </TextSecondary>
      </Display>
    </TextCopyButtonGroupGroupLayout>
  );
};

export default TextCopyButtonGroup;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { INITIAL } from '../../../../config/constants/requestState';

export interface SignInContextProps {
  state: string;
  setState: (state: string) => void;
  signIn: (formInput: { email: string; password: string }) => Promise<void>;
}

const SignInContext = React.createContext<SignInContextProps>({
  state: INITIAL,
  setState: (state: string) => undefined,
  signIn: (formInput: { email: string; password: string }) =>
    new Promise(() => undefined),
});

export default SignInContext;

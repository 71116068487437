// IUseCase
import INotificationLogViewUseCase from '../../enhancers/useCase/notification/NotificationLogViewProvider/INotificationLogViewUseCaseX';

// IService
import INotificationLogQueryService from './INotificationLogQueryService';

// Service
import NotificationLogQueryService from '../../service/notification/NotificationLogQueryService';

// IRepository
import INotificationLogRepository from '../../domain/notification/notificationLog/INotificationLogRepository';

// DomainObject
import UserAuthorized from '../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class NotificationLogViewUseCase implements INotificationLogViewUseCase {
  private notificationLogQueryService: INotificationLogQueryService;

  constructor(notificationLogRepository: INotificationLogRepository) {
    this.notificationLogQueryService = new NotificationLogQueryService(
      notificationLogRepository,
    );
  }

  public open = async (user: UserAuthorized) => {
    const userId = user.getId();

    const notificationLog = await this.notificationLogQueryService.findCustodyFeeAndTopUpFeeById(
      userId,
    );

    return { notificationLog };
  };
}
export default NotificationLogViewUseCase;

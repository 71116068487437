import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { TextSelectedStyle, TextUnSelectedStyle } from './style';

// Component
import TextFonted from '../../../../atoms/text2/TextFonted2';
import SavingsAccount from '../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';
import SavingsAccountTabItemLayout from './SavingsAccountTabItemLayout';

export type SavingsAccountTabItemProps = {
  item: SavingsAccount;
  onClick: () => void;
  selected: SavingsAccount;
  width: string | number;
};

const SavingsAccountTabItem: FC<SavingsAccountTabItemProps> = ({
  item,
  onClick,
  selected,
  width,
}: SavingsAccountTabItemProps) => {
  const { t } = useTranslation();

  const isSelected =
    item?.getRawAccountNumber() === selected?.getRawAccountNumber();

  const textStyle = isSelected ? TextSelectedStyle : TextUnSelectedStyle;

  return (
    <SavingsAccountTabItemLayout
      isSelected={isSelected}
      onClick={onClick}
      width={width}
    >
      <TextFonted key="label" fontFamilyType="secondary" theme={textStyle}>
        {t(`atoms.label.savingsAccount`)}
      </TextFonted>
      <TextFonted
        key="accountNumber"
        fontFamilyType="secondary"
        theme={textStyle}
      >
        {item?.getFormattedAccountNumber()}
      </TextFonted>
    </SavingsAccountTabItemLayout>
  );
};
export default SavingsAccountTabItem;

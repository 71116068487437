import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
import { BankTransactionIdProps } from './BankTransactionId.type';
import TransactionId from '../TransactionId/TransactionId';

class BankTransactionId extends TransactionId {
  private currency: FiatCurrencyType;

  constructor(props: BankTransactionIdProps) {
    super(props);
    this.currency = props.currency;
  }

  protected getPropsOrderForId = () => [
    this.prefix,
    this.currency,
    this.date,
    this.serialNumber.getSerialNumber(),
    this.target.substr(-6),
  ];

  public getAllProps() {
    return {
      ...super.getProps(),
      currency: this.currency,
    };
  }
}
export default BankTransactionId;

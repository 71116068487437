import React, { FC } from 'react';
import UpdatePassword from '../../../enhancers/settings/UpdatePasswordProvider';
import PasswordEditPage from './PasswordEditPage';

const EnhancedPasswordEditPage: FC = () => {
  return (
    <UpdatePassword>
      <PasswordEditPage />
    </UpdatePassword>
  );
};

export default EnhancedPasswordEditPage;

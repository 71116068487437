/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

// DomainObject
import { TextErrorMessage } from '../../../atoms/text/DomainText';
import TextInput from '../../../atoms/form/TextInput';
import TextFieldLayout from './TextFieldLayout';

export interface TextFieldProps {
  disabled?: boolean;
  disabledBackgroundColor?: string;
  errorMessage?: string;
  textInputWidth?: string | number;
  maxLength: number;
  name: string;
  onChange?: (text: string) => void;
  pattern?: string;
  placeholder?: string;
  register?: any;
  textAlign?: 'left' | 'right';
  type?: 'number' | 'password';
  value?: string;
}

const TextField: FC<TextFieldProps> = ({
  disabled = false,
  disabledBackgroundColor = '#DBDDE8',
  textInputWidth = `${400 / 16}rem`,
  errorMessage,
  onChange = () => undefined,
  maxLength = 50,
  name = '',
  pattern,
  placeholder = '',
  register = null,
  textAlign = 'left',
  type,
  value,
}: TextFieldProps) => {
  const { watch, errors } = useFormContext();

  return (
    <TextFieldLayout textInputWidth={textInputWidth}>
      <TextInput
        key="textInput"
        name={name}
        type={type}
        pattern={pattern}
        value={value || watch(name)}
        textAlign={textAlign}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        disabledBackgroundColor={disabledBackgroundColor}
        register={register}
        onChange={onChange}
      />
      <TextErrorMessage key="errorMessage">
        {errors[name]?.message || errorMessage}
      </TextErrorMessage>
    </TextFieldLayout>
  );
};

export default TextField;

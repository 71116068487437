import React from 'react';
import { CSVData } from '../../../../types/CSVData.type';

export interface CSVDataContextProps {
  csvData?: CSVData | undefined;
  setCSVData: (csvData?: CSVData) => void;
}

const CSVDataContext = React.createContext<CSVDataContextProps>({
  csvData: undefined,
  setCSVData: () => undefined,
});

export default CSVDataContext;

// Type
import { MenuType } from '../../config/menu/main/Menu.type';
import { UserStatusType } from '../../types/User.type';

export const ALLOWED_USER_STATUS = {
  [MenuType.assets]: [
    UserStatusType.ready,
    UserStatusType.investing,
    UserStatusType.cancelInvesting,
  ],
  [MenuType.cryptocurrencies]: [UserStatusType.ready, UserStatusType.investing],
  [MenuType.savingsAccounts]: [
    UserStatusType.ready,
    UserStatusType.investing,
    UserStatusType.cancelInvesting,
  ],
  [MenuType.timeDeposits]: [
    UserStatusType.ready,
    UserStatusType.investing,
    UserStatusType.cancelInvesting,
  ],
  [MenuType.debitCards]: [
    UserStatusType.investing,
    UserStatusType.cancelInvesting,
  ],
  [MenuType.transfer]: [
    UserStatusType.investing,
    UserStatusType.cancelInvesting,
  ],
  [MenuType.invitations]: [
    UserStatusType.investing,
    UserStatusType.cancelInvesting,
  ],
  [MenuType.verification]: [UserStatusType.initial],
  [MenuType.settings]: [
    UserStatusType.initial,
    UserStatusType.ready,
    UserStatusType.investing,
    UserStatusType.cancelInvesting,
  ],
  [MenuType.manual]: [
    UserStatusType.ready,
    UserStatusType.investing,
    UserStatusType.cancelInvesting,
  ],
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { Container, TextInputContainer, ErrorMessageContainer } from './style';

export interface TextAreaFieldLayoutProps {
  children: React.ReactElement[];
  textInputWidth: string | number;
}

const TextAreaFieldLayout: FC<TextAreaFieldLayoutProps> = ({
  children,
  textInputWidth,
}: TextAreaFieldLayoutProps) => {
  const elements = ['textArea', 'errorMessage'];
  const [textArea, errorMessage] = getElementsFromKeys(children, elements);

  return (
    <Container>
      <TextInputContainer width={textInputWidth} height={50}>
        {textArea}
      </TextInputContainer>
      <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
    </Container>
  );
};
export default TextAreaFieldLayout;

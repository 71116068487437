import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

// Style
import { ErrorMessageStyle } from './style';

// Component
import {
  TextPrimary,
  TextSecondary,
  TextThemeProps,
} from '../../../../../atoms/text2/Text2';
import TextInput from '../../../../../atoms/form/TextInput';
import FormInputTextFieldLayout from './FormInputTextFieldLayout';

interface FormInputTextFieldProps {
  disabled?: boolean;
  errorMessage?: string;
  labelProps: {
    label: string;
    theme: TextThemeProps;
  };
  layoutProps: {
    inputWidth: string | number;
    width: string | number;
  };
  maxLength: number;
  name: string;
  onChange?: (value: string) => void;
  placeHolder?: string;
  textAlign?: 'left' | 'center' | 'right';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation?: { [key: string]: any };
  value?: string;
}

const FormInputTextField: FC<FormInputTextFieldProps> = ({
  disabled = false,
  errorMessage,
  labelProps,
  layoutProps,
  maxLength,
  name,
  onChange,
  placeHolder,
  textAlign,
  validation,
  value,
}: FormInputTextFieldProps) => {
  const { errors, register, watch } = useFormContext();

  return (
    <FormInputTextFieldLayout layoutProps={layoutProps}>
      <TextPrimary key="label" theme={labelProps.theme}>
        {labelProps.label}
      </TextPrimary>
      <TextInput
        key="input"
        disabled={disabled}
        maxLength={maxLength}
        name={name}
        onChange={onChange}
        placeholder={placeHolder ?? ''}
        register={validation ? register(validation) : register}
        textAlign={textAlign}
        value={value || watch(name)}
      />
      <TextSecondary key="errorMessage" theme={ErrorMessageStyle}>
        {errors[name]?.message || errorMessage}
      </TextSecondary>
    </FormInputTextFieldLayout>
  );
};

export default FormInputTextField;

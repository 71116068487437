import { TransferSavingsAccountTransactionSeedProps } from './TransferSavingsAccountTransactionSeed.type';
import { TransferSavingsAccountType } from '../../../../../types/Transfer.type';
import TransferForbesAccountTransactionSeed from '../TransferForbesAccountTransactionSeed/TransferForbesAccountTransactionSeed';

// Error
import InvalidArgumentError from '../../../../../utils/errors/InvalidArgumentError';

class TransferSavingsAccountTransactionSeedFactory {
  static create(props: TransferSavingsAccountTransactionSeedProps) {
    switch (props.transferSavingsAccountType) {
      case TransferSavingsAccountType.forbes:
        return new TransferForbesAccountTransactionSeed(props);
      case TransferSavingsAccountType.ecb:
        return new TransferForbesAccountTransactionSeed(props);
      default:
        throw new InvalidArgumentError(
          `Invalid tradeType ${props.transferSavingsAccountType} is found.`,
        );
    }
  }
}

export default TransferSavingsAccountTransactionSeedFactory;

import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import CryptoCurrencyDepositWithdrawalTemplate from '../../../components/templates/cryptonCurrency/depositWithdrawal/CryptoCurrencyDepositWithdrawalTemplate';

const CryptoCurrencyDepositWithdrawalPage: FC = () => {
  return (
    <DashboardTemplate>
      <CryptoCurrencyDepositWithdrawalTemplate />
    </DashboardTemplate>
  );
};

export default CryptoCurrencyDepositWithdrawalPage;

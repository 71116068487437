// FirebaseFunctions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// Constant
import { CONTRACT_TIME_DEPOSIT_HISTORIES_GET_RAW_ALL_BY_USER_ID } from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// Type
import { ContractTimeDepositHistoryRawDataType } from '../../../domain/contract/contractTimeDepositHistory/ContractTimeDepositHistory/ContractTimeDepositHistory.type';

// IRepository
import IContractTimeDepositHistoryRepository from '../../../domain/contract/contractTimeDepositHistory/IContractTimeDepositHistoryRepository';

// DomainObject
import ContractTimeDepositHistoryFactory from '../../../domain/contract/contractTimeDepositHistory/ContractTimeDepositHistory/ContractTimeDepositHistoryFactory';
import ContractsTimeDepositHistories from '../../../domain/contract/contractTimeDepositHistory/ContractTimeDepositHistories/ContractTimeDepositHistories';

class ContractTimeDepositHistoryFunctions
  extends FirebasePrivateFunctions
  implements IContractTimeDepositHistoryRepository {
  public findAllByUserId = async (userId: string) => {
    const endpoint = CONTRACT_TIME_DEPOSIT_HISTORIES_GET_RAW_ALL_BY_USER_ID.replace(
      /:userId/,
      userId,
    );

    const response = await this.privateGET(endpoint);

    const rows = response.data.data as Array<
      ContractTimeDepositHistoryRawDataType
    >;

    return rows.reduce((contractTimeDepositHistory, row) => {
      contractTimeDepositHistory.add(
        ContractTimeDepositHistoryFactory.create(row.id, {
          ...row.props,
        }),
      );
      return contractTimeDepositHistory;
    }, new ContractsTimeDepositHistories());
  };
}
export default ContractTimeDepositHistoryFunctions;

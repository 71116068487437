import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TransferDebitCardTransactionV2Context from '../../../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2/TransferDebitCardTransactionV2Context';

// Style
import {
  DescriptionStyle,
  PendingIconStyle,
  OrderIdLabelStyle,
  OrderIdStyle,
} from './style';

// Component
import { PageTitle } from '../../../../atoms/headLine/HeadLine';
import { TextSecondary, TextPrimary } from '../../../../atoms/text2/Text2';
import HeaderTransferDebitCardComplete from '../../../../organisms/header/HeaderContentTitle/transfer/HeaderTransferDebitCardComplete';
import LineBreakReplaced from '../../../../atoms/text2/LineBreakReplaced';
import IconPendingCircle from '../../../../atoms/icon/IconPendingCircle';
import ProgressStepBarBankTransaction from '../../../../molecules/progressStep/ProgressStepBar/ProgressStepBarBankTransaction';
import TableConfirmTransferDebitCard from '../../../../organisms/table/confirm/TableConfirmTransferDebitCard';
import TransferDebitCardCompleteTemplateLayout from './TransferDebitCardCompleteTemplateLayout';

const TEMPLATE_ID = `templates.transfers.TransferDebitCardCompleteTemplate`;

const TransferDebitCardCompleteTemplate: FC = () => {
  const { t } = useTranslation();

  const { transferDebitCardTransaction } = useContext(
    TransferDebitCardTransactionV2Context,
  );

  if (!transferDebitCardTransaction) return <></>;

  return (
    <TransferDebitCardCompleteTemplateLayout>
      <HeaderTransferDebitCardComplete key="header" />
      <ProgressStepBarBankTransaction
        key="progressStep"
        currentStep="complete"
      />
      <IconPendingCircle
        key="icon"
        theme={PendingIconStyle}
        size={`${73 / 16}rem`}
      />
      <PageTitle key="contentTitle">
        {t(`${TEMPLATE_ID}.contentTitle`)}
      </PageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced text={t(`${TEMPLATE_ID}.description`)} />
      </TextSecondary>
      <TextPrimary key="orderIdLabel" theme={OrderIdLabelStyle}>
        {t(`${TEMPLATE_ID}.orderId.label`)}
      </TextPrimary>
      <TextPrimary key="orderIdValue" theme={OrderIdStyle}>
        {transferDebitCardTransaction.getFormattedId()}
      </TextPrimary>
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${TEMPLATE_ID}.description`)}
      </TextSecondary>
      <TableConfirmTransferDebitCard key="table" />
    </TransferDebitCardCompleteTemplateLayout>
  );
};

export default TransferDebitCardCompleteTemplate;

// FirebaseFunctions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// IRepository
import { IConsentFormUpdateRepository } from '../../../domain/verification/consentForm/IConsentFormRepository';

// Constant
import {
  CONSENT_FORM_UPDATE_CONSENT_FORM,
  CONSENT_FORM_UPDATE_TWO_FACTOR_AUTH_APP_SETTINGS,
} from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// DomainObject
import ConsentForm from '../../../domain/verification/consentForm/ConsentForm/ConsentForm';
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class ConsentFormFunctions
  extends FirebasePrivateFunctions
  implements IConsentFormUpdateRepository {
  public updateConsentForm = async (consentForm: ConsentForm) => {
    const id = consentForm.getId();
    const consentFormContent = consentForm.getConsentFormContent();

    const { documentUrl } = consentFormContent.getProps();

    await this.privatePUT(`${CONSENT_FORM_UPDATE_CONSENT_FORM}/${id}`, {
      documentUrl,
    });

    return consentForm;
  };

  public updateTwoFactorAuthAppSettings = async (
    user: UserAuthorized,
    code: string,
  ) => {
    await this.privatePUT(
      `${CONSENT_FORM_UPDATE_TWO_FACTOR_AUTH_APP_SETTINGS}/${user.getId()}`,
      {
        code,
      },
    );
  };
}
export default ConsentFormFunctions;

// React
import React, { FC, useContext } from 'react';
import CryptoCurrencyHistoryOptionContext from '../../../../../molecules/selector/tabBar/UnderBarTabBar/CryptoCurrencyHistoryTabBar/CryptoCurrencyHistoryOptionContext';

// Error
import SystemErrorFactory from '../../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import { CryptoCurrencyHistoryType } from '../../../../../molecules/selector/tabBar/UnderBarTabBar/CryptoCurrencyHistoryTabBar/CryptoCurrencyHistory.type';

// Components
import TableCryptoCurrencyTransactionAllHistory from '../TableCryptoCurrencyTransactionAllHistory';
import TableCryptoCurrencySpotTransactionHistory from '../TableCryptoCurrencySpotTransactionHistory';
import TableCryptoCurrencyTradeHistory from '../TableCryptoCurrencyTradeHistory';

const TableCryptoCurrencyTransactionHistory: FC = () => {
  const { historyOption } = useContext(CryptoCurrencyHistoryOptionContext);

  switch (historyOption) {
    case CryptoCurrencyHistoryType.all:
      return <TableCryptoCurrencyTransactionAllHistory />;
    case CryptoCurrencyHistoryType.depositWithdrawal:
      return <TableCryptoCurrencySpotTransactionHistory />;
    case CryptoCurrencyHistoryType.trade:
      return <TableCryptoCurrencyTradeHistory />;
    default:
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_SWITCH,
        {
          value: historyOption,
          place: 'TableCryptoCurrencyTransactionHistory',
        },
      );
  }
};

export default TableCryptoCurrencyTransactionHistory;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Type
import { UserType } from '../../../../../types/User.type';

// Constant
import { INITIAL } from '../../../../../config/constants/requestState';

export interface KycRequestCreateContextProps {
  state: string;
  createKycRequest: (type: UserType, callback?: () => void) => void;
}

const KycRequestCreateContext = React.createContext<
  KycRequestCreateContextProps
>({
  state: INITIAL,
  createKycRequest: (type: UserType, callback?: () => void) => undefined,
});

export default KycRequestCreateContext;

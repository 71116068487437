import { useCallback, useState } from 'react';

// Error
import ErrorHandler from '../../../../../../../utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from '../../../../../../../utils/errors/ErrorHandler/IErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../../../../utils/helpers/connection';

// Type
import {
  FormInputCherryVisa01ProofOfIdentity,
  useFormInputCherryVisa01ProofOfIdentity,
} from '../../../../../../../components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/FormCherryVisa01ProofOfIdentity.type';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../../../config/constants/requestState';

// Repository
import CherryStorageFunctions from '../../../../../../../storage/cherryStorageHandler/CherryStorageFunctions';
import DebitCardRequestCherryVisa01Functions from '../../../../../../../repository/debitCard/debitCardRequest/DebitCardRequestCherryVisa01Functions';

// UseCase
import DebitCardRequestCherryVisa01ProofOfIdentityUpdateUseCase from '../../../../../../../useCases/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01ProofOfIdentityUpdateUseCase';

// DomainObject
import DebitCardRequestCherryVisa01 from '../../../../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01';
import UserAuthorized from '../../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

// File
import { useCherryVisa01ProofOfIdentityFile } from '../../../../../../fileHandler/debitCard/debitCardRequest/ProofOfIdentityFileProvider/useCherryVisa01ProofOfIdentityFile';

export const useDebitCardRequestCherryVisa01ProofOfIdentityUpdateUseCase = (
  debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01 | undefined,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const formInputProofOfIdentityContext = useFormInputCherryVisa01ProofOfIdentity();

  // File
  const {
    document1,
    setDocument1,
    document2,
    setDocument2,
    passport,
    setPassport,
    selfie,
    setSelfie,
    signature,
    setSignature,
  } = useCherryVisa01ProofOfIdentityFile();

  /* *
   *
   *  Repository
   *
   * */
  const debitCardRequestCherryVisa01Functions = new DebitCardRequestCherryVisa01Functions();
  const storageHandler = new CherryStorageFunctions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new DebitCardRequestCherryVisa01ProofOfIdentityUpdateUseCase(
    debitCardRequestCherryVisa01Functions,
    storageHandler,
  );

  /* *
   *
   *  Method
   *
   * */
  const submitProofOfIdentity = useCallback(
    async (
      formInput: FormInputCherryVisa01ProofOfIdentity,
      callback?: () => void,
    ) => {
      if (!userAuthorized || !debitCardRequestCherryVisa01) return;

      setState(IS_LOADING);

      try {
        // ---check onLine ---- //
        checkInternetConnection();

        const outputs = await useCase.update(
          debitCardRequestCherryVisa01,
          formInput,
          document1,
          document2,
          passport,
          selfie,
          signature,
        );

        await useCase.executeUpdate(outputs);

        setState(SUCCESS);

        if (callback) callback();
      } catch (error) {
        const handler: IErrorHandler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [
      userAuthorized,
      debitCardRequestCherryVisa01,
      document1,
      document2,
      passport,
      selfie,
      signature,
    ],
  );

  return {
    state,
    setState,
    submitProofOfIdentity,
    formInputProofOfIdentityContext,
    proofOfIdentityFileContext: {
      document1,
      setDocument1,
      document2,
      setDocument2,
      passport,
      setPassport,
      selfie,
      setSelfie,
      signature,
      setSignature,
    },
  };
};

import React, { FC } from 'react';
import RegisterPage from './RegisterPage';
import RegisterViewProvider from '../../../enhancers/useCase/authentication/RegisterViewProvider';
import RegisterProvider from '../../../enhancers/useCase/authentication/RegisterProvider';

const EnhancedRegisterPage: FC = () => {
  return (
    <RegisterViewProvider>
      <RegisterProvider>
        <RegisterPage />
      </RegisterProvider>
    </RegisterViewProvider>
  );
};

export default EnhancedRegisterPage;

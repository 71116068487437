import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  ContractTimeDepositRenewalReservationProps,
  ContractTimeDepositRenewalReservationPropsFormat,
} from './ContractTimeDepositRenewalReservation.type';

// ValueObject
import ContractTimeDepositRenewalReservation from './ContractTimeDepositRenewalReservation';

class ContractTimeDepositRenewalReservationFactory {
  static create = (props: ContractTimeDepositRenewalReservationProps) => {
    const result = Joi.object(
      ContractTimeDepositRenewalReservationPropsFormat,
    ).validate({
      ...props,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: ContractTimeDepositRenewalReservation.name,
          reason: result.error.details[0].message,
        },
      );

    return new ContractTimeDepositRenewalReservation(props);
  };
}

export default ContractTimeDepositRenewalReservationFactory;

import React, { FC, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDebitCardRequestCherryVisa01ProofOfIdentityViewUseCase } from './useDebitCardRequestCherryVisa01ProofOfIdentityViewUseCase';
import DebitCardRequestCherryVisa01Context from '../../../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Context';
import UserAuthorizedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import { ErrorCodeType } from '../../../../../../utils/errors/ErrorHandler/ErrorCode.type';
import SystemErrorHandler from '../../../../../errorHandler/SystemErrorHandler';

// Constant
import {
  ROUTE_DEBIT_CARD,
  ROUTE_DEBIT_CARD_REQUEST_CHERRY_VISA_01_STATUS,
} from '../../../../../../config/constants/routing';

// For Redirect
const REDIRECT_ERROR_CODES = [
  ErrorCodeType.DEBIT_CARD_REQUEST_INVALID_ISSUER,
  ErrorCodeType.SAVINGS_ACCOUNT_INVALID_ACCOUNT_NUMBER_BY_CLIENT,
  ErrorCodeType.SAVINGS_ACCOUNT_FORBIDDEN_READ_SAVINGS_INFO,
];

export type DebitCardRequestCherryVisa01ProofOfIdentityViewProviderProps = {
  children: React.ReactNode;
};

const DebitCardRequestCherryVisa01ProofOfIdentityViewProvider: FC<DebitCardRequestCherryVisa01ProofOfIdentityViewProviderProps> = ({
  children,
}: DebitCardRequestCherryVisa01ProofOfIdentityViewProviderProps) => {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  // DomainObject
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    debitCardRequestCherryVisa01,
  } = useDebitCardRequestCherryVisa01ProofOfIdentityViewUseCase(
    id,
    userAuthorized,
  );

  /**
   *
   *  UseEffect (Redirect)
   *
   */

  // state (client error)
  useEffect(() => {
    if (REDIRECT_ERROR_CODES.includes(state as ErrorCodeType))
      history.push(ROUTE_DEBIT_CARD);
    // eslint-disable-next-line
    }, [state]);

  // Redirect if status is invalid
  useEffect(() => {
    if (
      debitCardRequestCherryVisa01 &&
      !debitCardRequestCherryVisa01.isProofOfIdentityEditable()
    )
      history.push(
        `${ROUTE_DEBIT_CARD_REQUEST_CHERRY_VISA_01_STATUS}/${debitCardRequestCherryVisa01.getId()}`,
      );
    // eslint-disable-next-line
  }, [debitCardRequestCherryVisa01]);

  return (
    <DebitCardRequestCherryVisa01Context.Provider
      value={{ debitCardRequestCherryVisa01 }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </DebitCardRequestCherryVisa01Context.Provider>
  );
};

export default DebitCardRequestCherryVisa01ProofOfIdentityViewProvider;

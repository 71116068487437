import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import CryptoCurrencyWalletContext from '../../../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWalletContext';
import CryptoCurrencyTradePageStateContext from '../../../../../../enhancers/pageState/cryptoCurrency/CryptoCurrencyTradePageState/CryptoCurrencyTradePageStateContext';
import SavingsAccountsContext from '../../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccountsContext';

// Type
import { CryptoCurrencyType } from '../../../../../../types/CryptoCurrency.type';
import { FiatCurrencyType } from '../../../../../../types/FiatCurrency.type';
import {
  FormInputCryptoCurrencyTrade,
  FormInputCryptoCurrencyTradeContext,
} from './FormInputCryptoCurrencyTrade.type';

// Constant
import {
  TIMEZONE_SHORT,
  TIMEZONE_SHORT_SUMMER_TIME,
} from '../../../../../../config/constants/business';

// Style
import {
  BalanceStyle,
  CryptoCurrencyRatePriceCardStyle,
  CountdownTextStyle,
  CountdownNumberStyle,
  CurrencyStyle,
  DescriptionStyle,
  LabelStyle,
} from './style';

// Component
import { TextSecondary } from '../../../../../atoms/text2/Text2';
import CountdownDescription from '../../../../../molecules/textGroup/CountdownDescription';
import CryptoCurrencySelectBox from '../../../../../molecules/selectBox/selectBox/cryptoCurrency/CryptoCurrencySelectBox';
import CryptoCurrencyTradeRate from '../../../../cardGroup/cryptoCurrency/CryptoCurrencyTradeRate';
import FormCryptoCurrencyBuy from '../FormCryptoCurrencyBuy';
import FormCryptoCurrencySell from '../FormCryptoCurrencySell';
import FormInputSavingsAccountSelectBox from '../../../../../molecules/form/formInput/selectBox/bankAccount/FormInputSavingsAccountSelectBox';
import LabeledCurrencyAmount from '../../../../../molecules/textGroup/LabeledCurrencyAmount';
import FormCryptoCurrencyTradeLayout from './FormCryptoCurrencyTradeLayout';

// ValueObject
import TimeZonedTime from '../../../../../../value/datetime/TimeZonedTime';

const ORGANISM_ID = `organisms.form.cryptoCurrency.FormCryptoCurrencyTrade`;

const FormCryptoCurrencyTrade: FC = () => {
  const { t } = useTranslation();
  const { formInput } = useContext(FormInputCryptoCurrencyTradeContext);

  // DomainObject
  const { cryptoCurrencyWallet } = useContext(CryptoCurrencyWalletContext);

  // Options
  const { savingsAccounts } = useContext(SavingsAccountsContext);

  // PageState
  const { pageState } = useContext(CryptoCurrencyTradePageStateContext);

  /* *
   *
   *  Form
   *
   * */

  const methods = useForm<FormInputCryptoCurrencyTrade>({
    mode: 'onChange',
  });
  const { reset, setValue, watch } = methods;

  // Currency
  useEffect(() => {
    setValue('baseCurrency', cryptoCurrencyWallet?.getCurrency());
  }, [cryptoCurrencyWallet, setValue]);

  /* *
   *
   *  DomainObject
   *
   * */
  const savingsAccount = savingsAccounts.filterById(
    watch(`savingsAccountNumber`),
  );

  /* *
   *
   *  Default Variable
   *
   * */

  // Set DefaultValue
  useEffect(() => {
    if (formInput)
      reset({
        savingsAccountNumber: formInput.savingsAccountNumber,
        [`${formInput.tradeType}PayAmount`]: formInput.payAmount,
        [`${formInput.tradeType}PayCurrency`]: formInput.payCurrency,
        [`${formInput.tradeType}ReceiveAmount`]: formInput.receiveAmount,
        [`${formInput.tradeType}ReceiveCurrency`]: formInput.receiveCurrency,
      });
    // eslint-disable-next-line
  }, [formInput, pageState]);

  // SavingsAccountNumber
  useEffect(() => {
    if (!formInput)
      setValue(
        `savingsAccountNumber`,
        savingsAccounts.head()?.getRawAccountNumber(),
      );
    // eslint-disable-next-line
  }, [savingsAccounts]);

  const timezone = new TimeZonedTime().isSummerTime()
    ? TIMEZONE_SHORT_SUMMER_TIME
    : TIMEZONE_SHORT;

  return (
    <FormProvider {...methods}>
      <FormCryptoCurrencyTradeLayout>
        <CryptoCurrencySelectBox key="cryptoCurrencySelect" />
        <CryptoCurrencyTradeRate
          key="rate"
          targetCurrency={
            cryptoCurrencyWallet?.getProps().currency || CryptoCurrencyType.BTC
          }
          baseCurrency={FiatCurrencyType.USD}
          theme={CryptoCurrencyRatePriceCardStyle}
        />
        <CountdownDescription
          key="rateUpdateInterval"
          text={t(`${ORGANISM_ID}.rateUpdateIntervalText`)}
          marginBetween={10}
          theme={{
            textStyle: CountdownTextStyle,
            timerStyle: CountdownNumberStyle,
          }}
        />
        <LabeledCurrencyAmount
          key="balance"
          amount={cryptoCurrencyWallet?.getFormattedBalance() || '0'}
          currency={
            cryptoCurrencyWallet?.getCurrency() || CryptoCurrencyType.BTC
          }
          direction="row"
          label={t(`atoms.label.balance`)}
          marginBetween={20}
          theme={{
            amount: BalanceStyle,
            currency: CurrencyStyle,
            label: LabelStyle,
          }}
        />
        <TextSecondary key="custodyFeeDescription" theme={DescriptionStyle}>
          {t(`${ORGANISM_ID}.custodyFeeDescription`, { timezone })}
        </TextSecondary>
        <FormInputSavingsAccountSelectBox
          key="savingsAccount"
          name="savingsAccountNumber"
          labelProps={{
            label: t(`${ORGANISM_ID}.label.savingsAccount`),
            theme: LabelStyle,
          }}
          inputWidth={740}
        />
        <FormCryptoCurrencyBuy key="buy" savingsAccount={savingsAccount} />
        <FormCryptoCurrencySell key="sell" savingsAccount={savingsAccount} />
      </FormCryptoCurrencyTradeLayout>
    </FormProvider>
  );
};

export default FormCryptoCurrencyTrade;

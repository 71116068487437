// Lib
import { min, required } from '../validator';

// Type
import { TypeOfT } from '../../../../types/typeof/Translation.type';

const contractTimeDepositRenewalReservationValidation = (t: TypeOfT) => ({
  principalIncreased: (minDeposit: number, depositAmount: number) => ({
    required: required(`Deposit Amount`, t),
    validate: {
      min: min(`Deposit Amount`, Math.max(depositAmount, minDeposit), t),
    },
  }),
});

export default contractTimeDepositRenewalReservationValidation;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinkTextContainer = styled.div`
  position: relative;
  top: -${40 / 16}rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SubmitButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

// export const SubmitButtonWrap = styled.div`
//   width: ${162 / 16}rem;
//   height: ${50 / 16}rem;
// `;

export const ErrorMessageContainer = styled.div`
  position: relative;
  top: -${5 / 16}rem;
  width: 100%;
  height: ${20 / 16}rem;
  display: flex;
  justify-content: center;
`;

export const LabelGroup = styled.div`
  width: ${200 / 16}rem;
  display: flex;
  flex-direction: column;
`;

export const LabelContainer = styled.div`
  width: 100%;
  height: ${50 / 16}rem;
  display: flex;
  align-items: center;
`;

export const FormInputGroup = styled.div``;

export const FormInputContainer = styled.div`
  display: flex;
`;

export const TextFieldGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextFieldRow = styled.div`
  display: flex;
`;

import React, { FC } from 'react';
import {
  getSymbolFromCurrency,
  convertMoneyFormat,
} from '../../../../utils/helpers/currencyHelper';
import { CurrencyType } from '../../../../types/Currency.type';
import { TextSecondary, TextThemeProps } from '../../../atoms/text2/Text2';
import TextStyled from '../../../atoms/text2/TextStyled';
import LabeledCurrencyAmountLayout from './LabeledCurrencyAmountLayout';

export interface LabeledCurrencyAmountProps {
  amount?: number | string;
  currency: CurrencyType;
  direction: 'row' | 'column';
  isSymbol?: boolean;
  label: string;
  labelFontFamily?: 'primary' | 'secondary';
  marginBetween: number | string;
  theme: {
    label: TextThemeProps;
    currency: TextThemeProps;
    amount: TextThemeProps;
  };
}

const LabeledCurrencyAmount: FC<LabeledCurrencyAmountProps> = ({
  amount,
  currency,
  direction,
  label,
  labelFontFamily = 'primary',
  isSymbol = false,
  marginBetween,
  theme,
}: LabeledCurrencyAmountProps) => {
  return (
    <LabeledCurrencyAmountLayout
      direction={direction}
      marginBetween={marginBetween}
    >
      <TextStyled
        key="label"
        theme={theme.label}
        fontFamilyType={labelFontFamily}
      >
        {label}
      </TextStyled>
      <TextSecondary key="currency" theme={theme.currency}>
        {isSymbol ? getSymbolFromCurrency(currency) : currency}
      </TextSecondary>
      <TextSecondary key="amount" theme={theme.amount}>
        {Number.isNaN(Number(amount))
          ? amount
          : convertMoneyFormat(amount as number, currency)}
      </TextSecondary>
    </LabeledCurrencyAmountLayout>
  );
};

export default LabeledCurrencyAmount;

import React, { FC } from 'react';
import TextPrimary from '../TextPrimary';
import TextSub from '../TextSub';

export interface TextFontedProps {
  children: React.ReactNode;
  pixel: number;
  color: string;
  fontWeight: number;
  fontFamilyType: 'primary' | 'sub';
  textDecoration?: string;
  opacity?: number;
}
const TextFonted: FC<TextFontedProps> = ({
  children,
  pixel,
  color,
  fontWeight,
  fontFamilyType,
  textDecoration = 'none',
  opacity = 1,
}: TextFontedProps) => {
  switch (fontFamilyType) {
    case 'primary':
      return (
        <TextPrimary
          pixel={pixel}
          color={color}
          fontWeight={fontWeight}
          textDecoration={textDecoration}
          opacity={opacity}
        >
          {children}
        </TextPrimary>
      );
    case 'sub':
      return (
        <TextSub
          pixel={pixel}
          color={color}
          fontWeight={fontWeight}
          textDecoration={textDecoration}
          opacity={opacity}
        >
          {children}
        </TextSub>
      );
    default:
      return <></>;
  }
};
export default TextFonted;

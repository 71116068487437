// Type
import { CryptoCurrencyType } from '../../../types/CryptoCurrency.type';
import { FiatCurrencyType } from '../../../types/FiatCurrency.type';

// IUseCase
import IAssetSummaryViewUseCase from '../../../enhancers/useCase/asset/AssetSummaryViewProvider/IAssetSummaryViewUseCase';

// IService
import IAssetCryptoCurrencyQueryService from './IAssetCryptoCurrencyQueryService';
import IAssetDebitCardQueryService from './IAssetDebitCardQueryService';
import IAssetSavingsAccountQueryService from './IAssetSavingsAccountQueryService';
import IAssetTimeDepositQueryService from './IAssetTimeDepositQueryService';

// Service
import AssetCryptoCurrencyQueryService from '../../../service/asset/assetCurrency/AssetCryptoCurrencyQueryService';
import AssetDebitCardQueryService from '../../../service/asset/assetDebitCard/AssetDebitCardQueryService';
import AssetTimeDepositQueryService from '../../../service/asset/assetTimeDeposit/AssetTimeDepositQueryService';
import AssetSavingsAccountQueryService from '../../../service/asset/assetSavingsAccount/AssetSavingsAccountQueryService';

// IRepository
import IAssetCryptoCurrencyRepository from '../../../domain/asset/assetCryptoCurrency/IAssetCryptoCurrencyRepository';
import IAssetDebitCardRepository from '../../../domain/asset/assetDebitCard/IAssetDebitCardRepository';
import IAssetTimeDepositRepository from '../../../domain/asset/assetTimeDeposit/IAssetTimeDepositRepository';
import IAssetSavingsAccountRepository from '../../../domain/asset/assetSavingsAccount/IAssetSavingsAccountRepository';

// DomainObject
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class AssetSummaryViewUseCase implements IAssetSummaryViewUseCase {
  private assetCryptoCurrencyQueryService: IAssetCryptoCurrencyQueryService;

  private assetDebitCardQueryService: IAssetDebitCardQueryService;

  private assetTimeDepositQueryService: IAssetTimeDepositQueryService;

  private assetSavingsAccountQueryService: IAssetSavingsAccountQueryService;

  constructor(
    assetCryptoCurrencyRepository: IAssetCryptoCurrencyRepository,
    assetDebitCardRepository: IAssetDebitCardRepository,
    assetTimeDepositRepository: IAssetTimeDepositRepository,
    assetSavingsAccountRepository: IAssetSavingsAccountRepository,
  ) {
    this.assetCryptoCurrencyQueryService = new AssetCryptoCurrencyQueryService(
      assetCryptoCurrencyRepository,
    );

    this.assetDebitCardQueryService = new AssetDebitCardQueryService(
      assetDebitCardRepository,
    );

    this.assetTimeDepositQueryService = new AssetTimeDepositQueryService(
      assetTimeDepositRepository,
    );

    this.assetSavingsAccountQueryService = new AssetSavingsAccountQueryService(
      assetSavingsAccountRepository,
    );
  }

  public open = async (
    user: UserAuthorized,
    currency: FiatCurrencyType,
    cryptoCurrency: CryptoCurrencyType,
  ) => {
    const userId = user.getId();

    // Fetch
    const [
      assetCryptoCurrency,
      assetTimeDeposit,
      assetSavingsAccount,
      // assetDebitCard,
    ] = await Promise.all([
      this.assetCryptoCurrencyQueryService.findUserCurrencyAsset(
        userId,
        cryptoCurrency,
      ),

      this.assetTimeDepositQueryService.findUserTimeDepositAsset(
        userId,
        currency,
      ),

      this.assetSavingsAccountQueryService.findUserSavingsAccountAsset(
        userId,
        currency,
      ),

      // this.assetDebitCardQueryService.findUserSavingsAccountAsset(
      //   userId,
      //   currency,
      // ),
    ]);

    return {
      assetCryptoCurrency,
      assetTimeDeposit,
      assetSavingsAccount,
      // assetDebitCard,
    };
  };
}
export default AssetSummaryViewUseCase;

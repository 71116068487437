import { useState } from 'react';

// DomainObject
import SavingsAccounts from './SavingsAccounts';

export const useSavingsAccounts = (defaultValue?: SavingsAccounts) => {
  const [savingsAccounts, setSavingsAccounts] = useState<SavingsAccounts>(
    defaultValue ?? new SavingsAccounts(),
  );

  return {
    savingsAccounts,
    setSavingsAccounts,
  };
};

// // Types
// import { GlobalStateType } from '../../../../redux/store';
// import { SavingsAccountState } from '../../../../redux/reducers/savingsAccountsReducer';

// // DomainObject
// import SavingsAccounts from './SavingsAccounts';
// import SavingsAccountFactory from '../SavingsAccount/SavingsAccountFactory';

// // ValueObject
// import SerialNumberSavingsAccount from '../../../idManager/productSerialNumber/ProductSerialNumber/SerialNumber/concreteSerialNumber/SerialNumberSavingsAccount';

// // State -> Objects
// const convertStateToObjects = (state: SavingsAccountState) =>
//   Object.keys(state).reduce((objs, id) => {
//     if (!state[id]) return objs;

//     const savingsAccountNumber = SerialNumberSavingsAccount.createNumberFromRawId(
//       id,
//     );

//     objs.add(SavingsAccountFactory.create(savingsAccountNumber, state[id]));
//     return objs;
//   }, new SavingsAccounts());

// // SavingsAccounts
// export const useSavingsAccounts = () => {
//   const [savingsAccounts, setSavingsAccounts] = useState<SavingsAccounts>(
//     new SavingsAccounts(),
//   );
//   const state = useSelector(
//     (globalState: GlobalStateType) => globalState.savingsAccountsStore.id,
//   );

//   useEffect(() => {
//     const domainObjects = convertStateToObjects(state);
//     setSavingsAccounts(domainObjects);
//   }, [state]);

//   return {
//     savingsAccounts,
//     setSavingsAccounts,
//   };
// };

// // SavingsAccounts (filter By UserId)
// export const useSavingsAccountsByUserId = (userId?: string) => {
//   const [savingsAccounts, setSavingsAccounts] = useState<SavingsAccounts>(
//     new SavingsAccounts(),
//   );
//   const state = useSelector(
//     (globalState: GlobalStateType) =>
//       globalState.savingsAccountsStore.userId[userId || ''],
//   );

//   useEffect(() => {
//     if (!userId || !state) return;

//     const domainObjects = convertStateToObjects(state);
//     setSavingsAccounts(domainObjects);
//   }, [userId, state]);

//   return {
//     savingsAccounts,
//     setSavingsAccounts,
//   };
// };

import Joi from '@hapi/joi';

// Type
import { CurrencyType } from '../../../../../types/Currency.type';
import { FiatCurrencyType } from '../../../../../types/FiatCurrency.type';

// Constant
// import { FORMAT_SAVINGS_ACCOUNT_NUMBER } from '../../../../../config/constants/regularExpression';

export interface ReferralTimeDepositProps {
  topLevelInviterId: string;

  topLevelInviterContractName: string;

  // topLevelInviterSavingsAccountNumber: string;

  userId: string;

  userContractName: string;

  depositAmount: number;

  depositCurrency: CurrencyType;

  interestRate: number;

  term: number;

  commissionTotal: number;

  commissionPaid: number;

  countOfMaturity: number;

  createdAt?: number;

  updatedAt?: number;
}

export interface ReferralTimeDepositRawDataType {
  id: string;

  props: ReferralTimeDepositProps & {
    secondLevelInviterId?: string;

    secondLevelInviterContractName?: string;
  };
}

export const ReferralTimeDepositPropsFormat = {
  id: Joi.string().required(),

  topLevelInviterId: Joi.string().required(),

  topLevelInviterContractName: Joi.string().required(),

  // topLevelInviterSavingsAccountNumber: Joi.string()
  //   .length(10)
  //   .pattern(FORMAT_SAVINGS_ACCOUNT_NUMBER)
  //   .required(),

  userId: Joi.string().required(),

  userContractName: Joi.string().required(),

  depositAmount: Joi.number().required(),

  depositCurrency: Object.keys(FiatCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),

  interestRate: Joi.number().min(0).required(),

  term: Joi.number().min(0).required(),

  commissionTotal: Joi.number().required(),

  commissionPaid: Joi.number().required(),

  countOfMaturity: Joi.number().min(0).required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

import styled from 'styled-components';
import Box from '../../../../atoms/utils/layout/Box/Box';

export const Container = styled(Box)`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const ProgressStepPointContainer = styled.div``;

import React, { FC } from 'react';
import { Container } from './style';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormTransferSavingsAccountConfirmLayoutProps {
  children: React.ReactElement[];
}

const FormTransferSavingsAccountConfirmLayout: FC<FormTransferSavingsAccountConfirmLayoutProps> = ({
  children,
}: FormTransferSavingsAccountConfirmLayoutProps) => {
  const elements = ['twoFactorAuth', 'buttonGroup'];
  const [twoFactorAuth, buttonGroup] = getElementsFromKeys(children, elements);

  return (
    <Container>
      {twoFactorAuth}
      <Margin top={50}>{buttonGroup}</Margin>
    </Container>
  );
};

export default FormTransferSavingsAccountConfirmLayout;

export enum FileType {
  jpeg = 'image/jpeg',
  png = 'image/png',
  pdf = '.pdf',
}

export enum ExtensionType {
  jpeg = 'jpeg',
  png = 'png',
}

export const FileTypeName: { [key: string]: string } = {
  [FileType.jpeg]: 'JPEG',
  [FileType.png]: 'PNG',
  [FileType.pdf]: 'PDF',
};

export const ExtensionTypeFileTypeMapper: { [key: string]: string } = {
  [ExtensionType.jpeg]: FileType.jpeg,
  [ExtensionType.png]: FileType.png,
};

import { useCallback, useState, useEffect, useMemo } from 'react';
import { useCSVData } from '../../../../../components/organisms/csv/CSVData/CSVData';
import { checkInternetConnection } from '../../../../../utils/helpers/connection';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';

// ErrorHandler
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import DebitCardTransactionV2ViewUseCase from '../../../../../useCases/debitCard/debitCardTransaction/DebitCardTransactionV2ViewUseCase';

// Repository
import DebitCardSpotTransactionFunctions from '../../../../../repository/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardSpotTransactionFunctions';
import TransferDebitCardTransactionV2Functions from '../../../../../repository/transfer/TransferDebitCardTransactionV2Functions';

// DomainObject
import { useDebitCardSpotTransactionV2s } from '../../../../../domain/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardSpotTransactionV2/DebitCardSpotTransactionV2s/useDebitCardSpotTransactionV2s';
import { useTransferDebitCardTransactionV2s } from '../../../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2s/useTransferDebitCardTransactionV2s';
import DebitCardV2 from '../../../../../domain/debitCard/debitCardV2/DebitCardV2/DebitCardV2';

// Option
import { useDebitCardHistoryOption } from '../../../../../components/molecules/selector/tabBar/UnderBarTabBar/DebitCardHistoryTabBar/useDebitCardHistoryOption';

export const useDebitCardTransactionViewUseCase = (debitCard?: DebitCardV2) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  DomainObject
   *
   * */

  const {
    debitCardSpotTransactionV2s,
    setDebitCardSpotTransactionV2s,
  } = useDebitCardSpotTransactionV2s();

  const {
    transferDebitCardTransactions,
    setTransferDebitCardTransactions,
  } = useTransferDebitCardTransactionV2s();

  // Sort
  const debitCardSpotTransactionV2sSorted = useMemo(
    () => debitCardSpotTransactionV2s.sortDescByCreatedAt(),
    [debitCardSpotTransactionV2s],
  );

  const transferDebitCardTransactionsSorted = useMemo(
    () => transferDebitCardTransactions.sortDescByCreatedAt(),
    [transferDebitCardTransactions],
  );

  // Balance History Calculated
  // const debitCardSpotTransactionBalanceHistory = useMemo(
  //   () =>
  //     !debitCard
  //       ? new DebitCardSpotTransactionV2s()
  //       : debitCardSpotTransactionsSorted.calculateBalanceHistory(debitCard),
  //   [debitCardSpotTransactionsSorted, debitCard],
  // );

  // TabBar
  const debitCardHistoryOptionContext = useDebitCardHistoryOption();

  // CSV
  const csvDataContext = useCSVData();

  /* *
   *
   *  Repository
   *
   * */
  const debitCardSpotTransactionRepository = new DebitCardSpotTransactionFunctions();
  const transferDebitCardTransactionRepository = new TransferDebitCardTransactionV2Functions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new DebitCardTransactionV2ViewUseCase(
    debitCardSpotTransactionRepository,
    transferDebitCardTransactionRepository,
  );

  /* *
   *
   *  Methods
   *
   * */
  const openDebitCardTransaction = useCallback(async () => {
    if (!debitCard) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // Execution
      const outputs = await useCase.open(debitCard);

      setDebitCardSpotTransactionV2s(outputs.debitCardSpotTransactionV2s);
      setTransferDebitCardTransactions(outputs.transferDebitCardTransactionV2s);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [debitCard]);

  /* *
   *
   *  UseCase
   *
   * */

  // UseCase.open
  useEffect(() => {
    let isMounted = true;

    if (isMounted && debitCard) openDebitCardTransaction();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [openDebitCardTransaction]);

  return {
    state,
    setState,
    debitCardHistoryOptionContext,
    // debitCardSpotTransactions: debitCardSpotTransactionBalanceHistory,
    debitCardSpotTransactions: debitCardSpotTransactionV2sSorted,
    transferDebitCardTransactions: transferDebitCardTransactionsSorted,
    csvDataContext,
  };
};

// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Type
import { SavingsAccountProps } from '../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount.type';

export interface SavingsAccountState {
  [id: string]: SavingsAccountProps;
}

interface SavingsAccountsState {
  id: SavingsAccountState;
  userId: {
    [userId: string]: SavingsAccountState;
  };
}

const initialState: SavingsAccountsState = {
  id: {},
  userId: {},
};

const savingsAccountsSlice = createSlice({
  name: 'savingsAccounts',
  initialState,
  reducers: {
    setSavingsAccounts: (
      state: SavingsAccountsState,
      action: PayloadAction<SavingsAccountState>,
    ) => ({
      ...state,
      id: {
        ...action.payload,
      },
    }),
    setSavingsAccountsByUserId: (
      state: SavingsAccountsState,
      action: PayloadAction<{
        userId: string;
        state: SavingsAccountState;
      }>,
    ) => ({
      ...state,
      userId: {
        [action.payload.userId]: { ...action.payload.state },
      },
    }),
  },
});

export const {
  setSavingsAccounts,
  setSavingsAccountsByUserId,
} = savingsAccountsSlice.actions;
export default savingsAccountsSlice.reducer;

import Joi from '@hapi/joi';

// Error
import { ErrorIdType } from '../../../../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import {
  CryptoCurrencyWithdrawalProps,
  CryptoCurrencyWithdrawalPropsFormat,
} from './CryptoCurrencyWithdrawal.type';

// DomainObject
import CryptoCurrencyWithdrawal from './CryptoCurrencyWithdrawal';

// ValueObject
import CryptoCurrencyTransactionId from '../../../../../../idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionId';

class CryptoCurrencyWithdrawalFactory {
  static create = (
    cryptoCurrencyTransactionId: CryptoCurrencyTransactionId,
    props: CryptoCurrencyWithdrawalProps,
  ) => {
    const result = Joi.object(CryptoCurrencyWithdrawalPropsFormat).validate({
      ...props,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: CryptoCurrencyWithdrawal.name,
          reason: result.error.details[0].message,
        },
      );

    return new CryptoCurrencyWithdrawal(cryptoCurrencyTransactionId, props);
  };
}

export default CryptoCurrencyWithdrawalFactory;

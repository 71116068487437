// Type
import { KycStatusType } from '../../../../../types/KycRequest.type';
import { KycRequestContentProps } from './KycRequestContent.type';

abstract class KycRequestContent {
  protected status: KycStatusType;

  protected rejectedReason?: string;

  protected updatedAt: number;

  protected props: KycRequestContentProps;

  constructor(props: KycRequestContentProps) {
    this.status = props.status;
    this.rejectedReason = props.rejectedReason;
    this.updatedAt = props.updatedAt || Date.now();
    this.props = props;
  }

  public getProps() {
    const props = {
      status: this.status,
      updatedAt: this.updatedAt,
    };

    if (this.rejectedReason)
      Object.assign(props, { rejectedReason: this.rejectedReason });

    return props as KycRequestContentProps;
  }

  public getCommandParameter() {
    const props = { updatedAt: this.updatedAt };

    if (this.rejectedReason)
      Object.assign(props, { rejectedReason: this.rejectedReason });

    return props as {
      updatedAt: number;
      rejectedReason?: string;
    };
  }

  public getRejectedReason = () => this.rejectedReason;
}

export default KycRequestContent;

import { useState } from 'react';

// Original Lib
import { executeWithTimeout } from '../../../../utils/helpers/timeout';
import { checkInternetConnection } from '../../../../utils/helpers/connection';
import { convertMoneyRoundDown } from '../../../../utils/helpers/currencyHelper';
import { commaSeparatedToNumber } from '../../../../utils/helpers/numberHelper';

// Constant
import { REQUEST_TIMEOUT } from '../../../../config/constants/business';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Types
import { FormInputCryptoCurrencyTrade } from '../../../../components/organisms/form/cryptoCurrency/cryptoCurrencyTrade/FormCryptoCurrencyTrade/FormInputCryptoCurrencyTrade.type';

// UseCase
import CryptoCurrencyTradeCreateUseCase from '../../../../useCases/cryptoCurrency/cryptoCurrencyTrade/CryptoCurrencyTradeCreateUseCase';
import { CryptoCurrencyTradeCreateParameter } from './ICryptoCurrencyTradeCreateUseCase';

// Repository & API
import AuthenticatorFunctions from '../../../../repository/authenticator/AuthenticatorFunctions';
import CryptoCurrencyTradeFunctions from '../../../../repository/cryptoCurrency/cryptoCurrencyTrade/CryptoCurrencyTradeFunctions';

// DomainObject
import { useCryptoCurrencyTradeTransactionSeed } from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed/useCryptoCurrencyTradeTransactionSeed';
import { useCryptoCurrencyTradeTransaction } from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/useCryptoCurrencyTradeTransaction';
import CryptoCurrencyWallet from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet';
import CryptoCurrencyTradeLimit from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeLimit/CryptoCurrencyTradeLimit/CryptoCurrencyTradeLimit';
import UserVerified from '../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';

export const useCryptoCurrencyTradeCreateUseCase = (
  cryptoCurrencyWallet: CryptoCurrencyWallet | undefined,
  user: UserVerified | undefined,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  DomainObject
   *
   * */
  const {
    cryptoCurrencyTradeTransactionSeed,
    setCryptoCurrencyTradeTransactionSeed,
  } = useCryptoCurrencyTradeTransactionSeed();

  const {
    cryptoCurrencyTradeTransaction,
    setCryptoCurrencyTradeTransaction,
  } = useCryptoCurrencyTradeTransaction();

  /* *
   *
   *  Repository
   *
   * */
  const authenticatorVerifier = new AuthenticatorFunctions();
  const cryptoCurrencyTradeCreateRepository = new CryptoCurrencyTradeFunctions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new CryptoCurrencyTradeCreateUseCase(
    authenticatorVerifier,
    cryptoCurrencyTradeCreateRepository,
  );

  /* *
   *
   *  Methods
   *
   * */

  // UseCase.create
  const createCryptoCurrencyTrade = (
    formInput: FormInputCryptoCurrencyTrade,
    cryptoCurrencyTradeLimit: CryptoCurrencyTradeLimit,
  ) => {
    if (!user || !cryptoCurrencyWallet) return;

    setState(IS_LOADING);

    try {
      // Convert payAmount & receiveAmount
      const payAmount = convertMoneyRoundDown(
        commaSeparatedToNumber(formInput.payAmount),
        formInput.payCurrency,
      );
      const receiveAmount = convertMoneyRoundDown(
        commaSeparatedToNumber(formInput.receiveAmount),
        formInput.receiveCurrency,
      );

      // Params
      const params = {
        ...formInput,
        payAmount,
        receiveAmount,
      } as CryptoCurrencyTradeCreateParameter;

      // Create
      const output = useCase.create(
        user,
        cryptoCurrencyTradeLimit,
        cryptoCurrencyWallet,
        params,
      );

      setCryptoCurrencyTradeTransactionSeed(output.cryptoCurrencyTradeSeed);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  // UseCase.create
  const executeCreateCryptoCurrencyTrade = async (
    code: string,
    callback?: () => void,
  ) => {
    setState(IS_LOADING);

    try {
      checkInternetConnection();

      const output = await executeWithTimeout(
        useCase.executeCreate(user!, cryptoCurrencyTradeTransactionSeed!, code),
        REQUEST_TIMEOUT,
      );

      setCryptoCurrencyTradeTransaction(output.cryptoCurrencyTrade);

      if (callback) callback();

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  return {
    state,
    setState,
    cryptoCurrencyTradeTransaction,
    cryptoCurrencyTradeTransactionSeed,
    createCryptoCurrencyTrade,
    executeCreateCryptoCurrencyTrade,
  };
};

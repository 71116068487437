import { useState } from 'react';

// DomainObject
import CryptoCurrencyTradeTransactionSeed from './CryptoCurrencyTradeTransactionSeed';

export const useCryptoCurrencyTradeTransactionSeed = (
  defaultValue?: CryptoCurrencyTradeTransactionSeed,
) => {
  const [
    cryptoCurrencyTradeTransactionSeed,
    setCryptoCurrencyTradeTransactionSeed,
  ] = useState<CryptoCurrencyTradeTransactionSeed | undefined>(defaultValue);

  return {
    cryptoCurrencyTradeTransactionSeed,
    setCryptoCurrencyTradeTransactionSeed,
  };
};

import React, { FC, useContext } from 'react';
import { useContractTimeDepositAddViewUseCase } from './useContractTimeDepositAddViewUseCase';
import { useContractTimeDepositAddState } from '../../../../pageState/contract/ContractTimeDepositAddPageState/useContractTimeDepositAddPageState';

// Context
import ContractTimeDepositAddPageStateContext from '../../../../pageState/contract/ContractTimeDepositAddPageState/ContractTimeDepositAddPageStateContext';
import RequestStateContext from '../../../../../value/view/RequestState/RequestStateContext';
import SavingsAccountsContext from '../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccountsContext';
import TimeDepositsContext from '../../../../../domain/product/timeDeposit/TimeDeposits/TimeDepositsContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import UserVerifiedContext from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedContext';
import { FormInputContractTimeDepositAddContext } from '../../../../../components/organisms/form/contract/contractTimeDeposit/FormContractTimeDepositAdd/FormInputContractTimeDepositAdd.type';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

export type ContractTimeDepositAddViewProviderProps = {
  children: React.ReactNode;
};

const ContractTimeDepositAddViewProvider: FC<ContractTimeDepositAddViewProviderProps> = ({
  children,
}: ContractTimeDepositAddViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // PageState
  const { pageState, setPageState } = useContractTimeDepositAddState();

  const {
    state,
    setState,
    userVerified,
    timeDeposits,
    savingsAccounts,
    formInputContractTimeDepositAddContext,
  } = useContractTimeDepositAddViewUseCase(userAuthorized);

  return (
    <FormInputContractTimeDepositAddContext.Provider
      value={formInputContractTimeDepositAddContext}
    >
      <RequestStateContext.Provider value={{ requestState: state }}>
        <ContractTimeDepositAddPageStateContext.Provider
          value={{ pageState, setPageState }}
        >
          <SavingsAccountsContext.Provider value={{ savingsAccounts }}>
            <TimeDepositsContext.Provider value={{ timeDeposits }}>
              <UserVerifiedContext.Provider value={{ user: userVerified }}>
                <SystemErrorHandler state={state} setState={setState}>
                  {children}
                </SystemErrorHandler>
              </UserVerifiedContext.Provider>
            </TimeDepositsContext.Provider>
          </SavingsAccountsContext.Provider>
        </ContractTimeDepositAddPageStateContext.Provider>
      </RequestStateContext.Provider>
    </FormInputContractTimeDepositAddContext.Provider>
  );
};

export default ContractTimeDepositAddViewProvider;

import SignInUserActionType from '../constants/signInUser.constant';
import { UserAuthorizedProps } from '../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized.type';

export interface SignInUserAction {
  type: SignInUserActionType;
  payload: SignInUserActionParams | { [key: string]: string };
}

export interface SignInUserActionParams {
  id: string;
  signInUserProps: UserAuthorizedProps;
}

export const setUserAuthorizedAction = (
  params: SignInUserActionParams,
): SignInUserAction => ({
  type: SignInUserActionType.SET_SIGN_IN_USER,
  payload: {
    id: params.id,
    signInUserProps: params.signInUserProps,
  },
});

export const initializeUserAuthorizedAction = (): SignInUserAction => ({
  type: SignInUserActionType.INITIALIZE_SIGN_IN_USER,
  payload: {},
});

export enum TransferSavingsAccountType {
  forbes = 'forbes',
  ecb = 'ecb',
  // domestic = 'domestic',
  // international = 'international',
}

export enum TransferDebitCardType {
  savingsAccountToCard = 'savingsAccountToCard',
  cardToSavingsAccount = 'cardToSavingsAccount',
}

export enum TransferDebitCardStatusType {
  unconfirmed = 'unconfirmed',
  confirmed = 'confirmed',
  canceled = 'canceled',
  rejected = 'rejected',
}

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface ContractTimeDepositRenewalReservationAddCardLayoutProps {
  children: React.ReactElement[];
}

const ContractTimeDepositRenewalReservationAddCardLayout: FC<ContractTimeDepositRenewalReservationAddCardLayoutProps> = ({
  children,
}: ContractTimeDepositRenewalReservationAddCardLayoutProps) => {
  const elements = ['button', 'description'];

  const [button, description] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow padding="17 0" height="auto" alignItems="center">
      <FlexBoxRow width={220} height="auto">
        {button}
      </FlexBoxRow>
      <Margin left={20} />
      {description}
    </FlexBoxRow>
  );
};

export default ContractTimeDepositRenewalReservationAddCardLayout;

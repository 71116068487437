// Type
import { UserType } from '../../../types/User.type';

// IService
import IKycRequestUpsertService from '../../../useCases/verification/kycRequest/IKycRequestUpsertService';

// IRepository
import { IKycRequestUpsertRepository } from '../../../domain/verification/kycRequest/KycRequest/IKycRequestRepository';

// DomainObject
import KycRequestFactory from '../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequestFactory';
import KycRequest from '../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequest';
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class KycRequestUpsertService implements IKycRequestUpsertService {
  private kycRequestUpsertRepository: IKycRequestUpsertRepository;

  constructor(kycRequestUpsertRepository: IKycRequestUpsertRepository) {
    this.kycRequestUpsertRepository = kycRequestUpsertRepository;
  }

  public createInitialByUserType = (
    user: UserAuthorized,
    userType: UserType,
  ) => {
    return KycRequestFactory.createInitialByUserType(user, userType);
  };

  public executeUpsert = async (kycRequest: KycRequest) => {
    await this.kycRequestUpsertRepository.upsert(kycRequest);

    return kycRequest;
  };
}

export default KycRequestUpsertService;

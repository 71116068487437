// Lib
import {
  formattedBankAccountNumber,
  withHyphenFormat,
} from 'utils/helpers/stringHelper';

// Type
import { TransferSavingsAccountType } from 'types/Transfer.type';

class SavingsAccountNumber {
  private accountNumber: string;

  constructor(accountNumber: string) {
    this.accountNumber = accountNumber;
  }

  public getFormattedId = (
    transferSavingsAccountType?: TransferSavingsAccountType,
  ) => {
    return transferSavingsAccountType === TransferSavingsAccountType.ecb
      ? formattedBankAccountNumber(this.accountNumber)
      : withHyphenFormat(this.accountNumber, 3);
  };

  public getHalfFacedNumber = () => {
    const head = this.accountNumber.substr(0, 3);
    const tail = this.accountNumber.substr(-3);
    return `${head}-****-${tail}`;
  };

  public getAccountNumber = () => this.accountNumber;
}
export default SavingsAccountNumber;

// React
import React, { FC } from 'react';

// Type
import { TransferDebitCardStatusType } from '../../../../../types/Transfer.type';

// Components
import { FlexBoxRow } from '../../../../atoms/utils/layout/Box/FlexBox';
import StatusBadge from '../../../badge/StatusBadge';

export interface StatusBadgeTdBoxProps {
  status: TransferDebitCardStatusType;
}

const StatusBadgeTdBox: FC<StatusBadgeTdBoxProps> = ({
  status,
}: StatusBadgeTdBoxProps) => {
  if (!Object.keys(TransferDebitCardStatusType).includes(status)) return <></>;

  return (
    <FlexBoxRow height="100%" justifyContent="center" alignItems="center">
      <StatusBadge
        status={status}
        layout={{
          height: 45,
          iconWidth: 20,
          iconHeight: 20,
        }}
      />
    </FlexBoxRow>
  );
};

export default StatusBadgeTdBox;

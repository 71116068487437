import { firestore } from 'firebase';

// Error
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';

// Firestore
import AssetsCryptoCurrencyCollection from '../../../infrastructure/firebase/firestore/collections/asset/users/AssetsCryptoCurrency/AssetsCryptoCurrencyCollection';

// Types
import { CryptoCurrencyType } from '../../../types/CryptoCurrency.type';
import {
  AssetCryptoCurrencyPrimaryKey,
  AssetCryptoCurrencyProps,
} from '../../../domain/asset/assetCryptoCurrency/AssetCryptoCurrency/AssetCryptoCurrency.type';
import { UseStateType } from '../../../types/typeof/UseState';

// IRepository
import IAssetCryptoCurrencyRepository from '../../../domain/asset/assetCryptoCurrency/IAssetCryptoCurrencyRepository';

// DomainObject
import AssetCryptoCurrencyFactory from '../../../domain/asset/assetCryptoCurrency/AssetCryptoCurrency/AssetCryptoCurrencyFactory';
import AssetsCryptoCurrency from '../../../domain/asset/assetCryptoCurrency/AssetsCryptoCurrency/AssetsCryptoCurrency';

class AssetCryptoCurrencyFirestore implements IAssetCryptoCurrencyRepository {
  public findByPrimaryKey = async (params: AssetCryptoCurrencyPrimaryKey) => {
    const { userId, currency } = params;

    const doc = await new AssetsCryptoCurrencyCollection(userId).fetchSpecific(
      currency,
    );

    if (!doc)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.DATABASE_DATA_NOT_FOUND_UNIQUE_KEY,
        {
          dataSource: 'AssetCryptoCurrency',
          params: `userId = ${params.userId}, currency = ${params.currency}`,
          place: `AssetCryptoCurrencyFirestore.findByPrimaryKey`,
        },
      );

    return AssetCryptoCurrencyFactory.create(currency as CryptoCurrencyType, {
      ...(doc.data() as AssetCryptoCurrencyProps),
    });
  };

  public findByUserId = async (userId: string) => {
    const docs = await new AssetsCryptoCurrencyCollection(userId).fetchAll();

    if (docs.length === 0)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.DATABASE_DATA_NOT_FOUND_UNIQUE_KEY,
        {
          dataSource: 'AssetCryptoCurrency',
          params: `userId = ${userId}`,
          place: `AssetCryptoCurrencyFirestore.findByUserId`,
        },
      );

    return docs.reduce<AssetsCryptoCurrency>((assetsCryptoCurrency, doc) => {
      assetsCryptoCurrency.add(
        AssetCryptoCurrencyFactory.create(doc.id as CryptoCurrencyType, {
          ...(doc.data() as AssetCryptoCurrencyProps),
        }),
      );
      return assetsCryptoCurrency;
    }, new AssetsCryptoCurrency());
  };

  public subscribeByUserId = async (
    userId: string,
    setter: UseStateType<AssetsCryptoCurrency>,
    setState: UseStateType<string>,
  ) => {
    const assetCryptoCurrencyCollection = new AssetsCryptoCurrencyCollection(
      userId,
    );

    const converter = (docs: firestore.DocumentSnapshot[]) => {
      return docs.reduce<AssetsCryptoCurrency>((assetsCryptoCurrency, doc) => {
        assetsCryptoCurrency.add(
          AssetCryptoCurrencyFactory.create(doc.id as CryptoCurrencyType, {
            ...(doc.data() as AssetCryptoCurrencyProps),
          }),
        );
        return assetsCryptoCurrency;
      }, new AssetsCryptoCurrency());
    };

    return assetCryptoCurrencyCollection.subscribeAll(
      setter,
      converter,
      setState,
    );
  };
}
export default AssetCryptoCurrencyFirestore;

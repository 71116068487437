import { useState } from 'react';
import CryptoCurrencyBuyTransactions from './CryptoCurrencyBuyTransactions';

export const useCryptoCurrencyBuyTransactions = (
  defaultValue?: CryptoCurrencyBuyTransactions,
) => {
  const [
    cryptoCurrencyBuyTransactions,
    setCryptoCurrencyBuyTransactions,
  ] = useState<CryptoCurrencyBuyTransactions>(
    defaultValue || new CryptoCurrencyBuyTransactions(),
  );

  return {
    cryptoCurrencyBuyTransactions,
    setCryptoCurrencyBuyTransactions,
  };
};

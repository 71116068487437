import React, { FC } from 'react';
import CorporateAddressPage from './CorporateAddressPage';
import CorporateAddressViewProvider from '../../../enhancers/useCase/verification/kycRequest/CorporateAddressViewProvider';
import CorporateAddressSubmitProvider from '../../../enhancers/useCase/verification/kycRequest/KycRequestSubmitProvider/CorporateAddressSubmitProvider';

const EnhancedCorporateAddressPage: FC = () => {
  return (
    <CorporateAddressViewProvider>
      <CorporateAddressSubmitProvider>
        <CorporateAddressPage />
      </CorporateAddressSubmitProvider>
    </CorporateAddressViewProvider>
  );
};

export default EnhancedCorporateAddressPage;

// React
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle } from './style';
import {
  CloseButton,
  DefaultButtonTextStyle,
} from '../../../atoms/button/Button2';

// Component
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import { TextPrimary, TextSecondary } from '../../../atoms/text2/Text2';
import MaintenanceSmallIcon from '../../../atoms/image/MaintenanceSmallIcon';
import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';
import DebitCardMaintenanceNotificationLayout from './DebitCardMaintenanceNotificationLayout';

export interface DebitCardMaintenanceNotificationProps {
  closeAction: () => void;
}

const ORGANISM_ID = 'organisms.notification.DebitCardMaintenanceNotification';

const DebitCardMaintenanceNotification: FC<DebitCardMaintenanceNotificationProps> = ({
  closeAction,
}: DebitCardMaintenanceNotificationProps) => {
  const { t } = useTranslation();
  return (
    <DebitCardMaintenanceNotificationLayout>
      <MaintenanceSmallIcon key="icon" width={55} height={55} />
      <PageTitle key="title">
        <LineBreakReplaced text={t(`${ORGANISM_ID}.title`)} />
      </PageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced text={t(`${ORGANISM_ID}.line`)} />
      </TextSecondary>
      <CloseButton
        key="button"
        width={140}
        height={50}
        theme={{ borderRadius: 25 }}
        onClick={() => closeAction()}
      >
        <TextPrimary theme={DefaultButtonTextStyle}>
          {t(`atoms.button.close`)}
        </TextPrimary>
      </CloseButton>
    </DebitCardMaintenanceNotificationLayout>
  );
};

export default DebitCardMaintenanceNotification;

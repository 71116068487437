import { useState } from 'react';

// DomainObject
import NotificationLogCustodyFeeAndTopUpFee from './NotificationLogCustodyFeeAndTopUpFee';

export const useNotificationLogCustodyFeeAndTopUpFee = () => {
  const [
    notificationLogCustodyFeeAndTopUpFee,
    setNotificationLogCustodyFeeAndTopUpFee,
  ] = useState<NotificationLogCustodyFeeAndTopUpFee>();

  return {
    notificationLogCustodyFeeAndTopUpFee,
    setNotificationLogCustodyFeeAndTopUpFee,
  };
};

import { useState } from 'react';
import User from './User';

export const useUser = (initial?: User) => {
  const [user, setUser] = useState<User | undefined>(initial);

  return {
    user,
    setUser,
  };
};

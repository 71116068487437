import React, { useState } from 'react';

import { CryptoCurrencyTradeType } from '../../../../../../types/CryptoCurrency.type';
import { CurrencyType } from '../../../../../../types/Currency.type';

export interface FormInputCryptoCurrencyTrade {
  savingsAccountNumber: string;

  payAmount: string;

  payCurrency: CurrencyType;

  rate: number;

  receiveAmount: string;

  receiveCurrency: CurrencyType;

  tradeType: CryptoCurrencyTradeType;
}

export const useFormInputCryptoCurrencyTrade = () => {
  const [formInput, setFormInput] = useState<FormInputCryptoCurrencyTrade>();

  return {
    formInput,
    setFormInput,
  };
};

export const FormInputCryptoCurrencyTradeContext = React.createContext<{
  formInput?: FormInputCryptoCurrencyTrade;
  setFormInput: (formInput?: FormInputCryptoCurrencyTrade) => void;
}>({
  formInput: undefined,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFormInput: (formInput?: FormInputCryptoCurrencyTrade) => undefined,
});

import styled from 'styled-components';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  position: relative;
  top: 0;
  cursor: pointer;
`;

export const MenuItemContainer = styled.div`
  box-sizing: border-box;
  width: ${330 / 16}rem;
  height: ${80 / 16}rem;
  padding-left: ${33 / 16}rem;
  color: white;
  display: flex;
  align-items: center;

  :hover {
    border-left: ${4 / 16}rem solid #299adf;
    background-color: #115386;
  }
`;
export const SubMenuListContainer = styled.div`
  position: absolute;
  top: ${0 / 16}rem;
  left: ${330 / 16}rem;
`;

export const MenuTextStyle = {
  fontSize: 18,
  color: color.white,
  fontWeight: fontWeight.regular,
};

// Type
import {
  PersonalInformationProps,
  PersonalInformationUserInput,
} from './PersonalInformation.type';
import { Gender, KycStatusType } from '../../../../../types/KycRequest.type';

// ValueObject
import KycRequestContent from '../KycRequestContent/KycRequestContent';
import Name from '../../../../personalInformation/name/Name/Name/Name';

class PersonalInformation extends KycRequestContent {
  private name: Name;

  private gender: string;

  private dateOfBirth: string;

  private passport: {
    passportNo: string;
    expiredAt: string;
    passportUrl: string;
  };

  private selfieUrl: string;

  constructor(props: PersonalInformationProps) {
    super(props);
    this.name = new Name(props);
    this.gender = props.gender;
    this.dateOfBirth = props.dateOfBirth;
    this.passport = props.passport;
    this.selfieUrl = props.selfieUrl;
  }

  public getProps() {
    const props = {
      ...super.getProps(),
      ...this.name.getProps(),
      fullName: this.name.getFullName(),
      dateOfBirth: this.dateOfBirth,
      passport: this.passport,
      selfieUrl: this.selfieUrl,
      gender: this.gender as Gender,
    };

    return props;
  }

  public getCommandParameter = () => {
    const props = {
      ...super.getCommandParameter(),
      ...this.name.getProps(),
      fullName: this.name.getFullName(),
      dateOfBirth: this.dateOfBirth,
      passport: this.passport,
      selfieUrl: this.selfieUrl,
    };

    if (this.gender) Object.assign(props, { gender: this.gender });

    return props;
  };

  public submit = (params: PersonalInformationUserInput) => {
    return new PersonalInformation({
      ...this.getProps(),
      firstName: params.firstName,
      middleName: params.middleName,
      lastName: params.lastName,
      gender: params.gender,
      dateOfBirth: params.dateOfBirth,
      passport: params.passport,
      selfieUrl: params.selfieUrl,
      status: KycStatusType.submitted,
      updatedAt: Date.now(),
    });
  };

  public getFullName = () => this.name.getFullName();

  public updateProps = (props: PersonalInformationProps) => {
    return new PersonalInformation({ ...this.getProps(), ...props });
  };
}

export default PersonalInformation;

import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Type
import { MenuType } from '../../../../config/menu/main/Menu.type';

// Style
import { SubMenuListContainer, MenuTextStyle } from './style';

// Component
import Display from '../../../atoms/div/Display';
import MenuIcon from '../../../atoms/icon/MenuIcon';
import { TextPrimary } from '../../../atoms/text2/Text2';
import SubMenuList from '../../../organisms/list/SubMenuList';
import MenuItemWithSubLayout from './MenuItemWithSubLayout';

export interface MenuItemWithSubProps {
  type: MenuType;
  menuItems: Array<string>;
}

const MenuItemWithSub: FC<MenuItemWithSubProps> = ({
  type,
  menuItems,
}: MenuItemWithSubProps) => {
  const [isSubMenuVisible, setIsSubMenuVisible] = useState<boolean>(false);

  const { t } = useTranslation();

  return (
    <MenuItemWithSubLayout setIsSubMenuVisible={setIsSubMenuVisible}>
      <MenuIcon key="icon" type={type} />
      <TextPrimary key="menu" theme={MenuTextStyle}>
        {t(`menu.${type}`)}
      </TextPrimary>
      <Display key="subMenu" isDisplay={isSubMenuVisible}>
        <SubMenuListContainer>
          <SubMenuList menuItems={menuItems} />
        </SubMenuListContainer>
      </Display>
    </MenuItemWithSubLayout>
  );
};
export default MenuItemWithSub;

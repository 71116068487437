import React, { FC } from 'react';
import Margin from '../../../../../atoms/utils/layout/Margin';
import ContentBox from '../../../../../atoms/utils/layout/Box/Box/ContentBox';
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../../../atoms/utils/layout/Box/FlexBox';

import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

export interface TimeDepositAddFormLayoutProps {
  children: React.ReactElement[];
}

const TimeDepositAddFormLayout: FC<TimeDepositAddFormLayoutProps> = ({
  children,
}: TimeDepositAddFormLayoutProps) => {
  const elements = [
    'cryptoCurrencySelect',
    'rate',
    'rateUpdateInterval',
    'balance',
    'custodyFeeDescription',
    'savingsAccount',
    'buy',
    'sell',
  ];
  const [
    cryptoCurrencySelect,
    rate,
    rateUpdateInterval,
    balance,
    custodyFeeDescription,
    savingsAccount,
    buy,
    sell,
  ] = getElementsFromKeys(children, elements);

  return (
    <ContentBox width={1200} padding={100}>
      {cryptoCurrencySelect}
      <Margin top={33} />
      {rate}
      <Margin top={10} />
      <FlexBoxRow justifyContent="flex-end" paddingRight="15">
        {rateUpdateInterval}
      </FlexBoxRow>
      <Margin top={20} />
      {balance}
      <Margin top={10} left={25}>
        {custodyFeeDescription}
      </Margin>
      <Margin top={50} />
      {savingsAccount}
      <Margin top={50} />
      <FlexBoxRow justifyContent="space-between">
        <FlexBoxColumn justifyContent="center" width={470}>
          {buy}
        </FlexBoxColumn>
        <FlexBoxColumn justifyContent="center" width={470}>
          {sell}
        </FlexBoxColumn>
      </FlexBoxRow>
    </ContentBox>
  );
};

export default TimeDepositAddFormLayout;

import React, { FC } from 'react';
import { Container, Content, Main } from './style';
import AuthPageLanguageSelectorHeader from '../../../organisms/header/HeaderAuthPageLanguageSelector';
import CopyRightFooter from '../../../organisms/footer/CopyRightFooter';

export interface FrontPageTemplateProps {
  children: React.ReactNode;
}

const FrontPageTemplate: FC<FrontPageTemplateProps> = ({
  children,
}: FrontPageTemplateProps) => {
  return (
    <Container>
      <AuthPageLanguageSelectorHeader />
      <Content>
        <Main>{children}</Main>
        <CopyRightFooter />
      </Content>
    </Container>
  );
};

export default FrontPageTemplate;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ContractTimeDepositContext from '../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDepositContext';

// Style
import {
  CheckMarkIconStyle,
  DescriptionStyle,
  OrderIdLabelStyle,
  OrderIdStyle,
} from './style';

// Component
import { TextPrimary, TextSecondary } from '../../../atoms/text2/Text2';
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import HeaderContractTimeDepositRenewalReservationDeleteComplete from '../../../organisms/header/HeaderContentTitle/contract/HeaderContractTimeDepositRenewalReservationDeleteComplete';
import IconCheckMarkCircle from '../../../atoms/icon/IconCheckMarkCircle';
import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';
import ProgressStepBarBankTransaction from '../../../molecules/progressStep/ProgressStepBar/ProgressStepBarBankTransaction';
import PropertyListContractTimeDepositAfterMatured from '../../../organisms/propertyList/contract/PropertyListContractTimeDepositAfterMatured';
import ContractTimeDepositRenewalReservationDeleteCompleteTemplateLayout from './ContractTimeDepositRenewalReservationDeleteCompleteTemplateLayout';

const TEMPLATE_ID =
  'templates.contract.ContractTimeDepositRenewalReservationDeleteCompleteTemplate';

const ContractTimeDepositRenewalReservationDeleteCompleteTemplate: FC = () => {
  const { t } = useTranslation();
  const { contractTimeDeposit } = useContext(ContractTimeDepositContext);

  if (!contractTimeDeposit) return <></>;

  return (
    <ContractTimeDepositRenewalReservationDeleteCompleteTemplateLayout>
      <HeaderContractTimeDepositRenewalReservationDeleteComplete key="header" />
      <ProgressStepBarBankTransaction
        key="progressStep"
        currentStep="complete"
      />
      <IconCheckMarkCircle
        key="icon"
        theme={CheckMarkIconStyle}
        size={`${35 / 16}rem`}
        diameter={73}
      />
      <PageTitle key="contentTitle">
        {t(`${TEMPLATE_ID}.contentTitle`)}
      </PageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced text={t(`${TEMPLATE_ID}.description`)} />
      </TextSecondary>
      <TextPrimary key="orderIdLabel" theme={OrderIdLabelStyle}>
        {t(`${TEMPLATE_ID}.orderId`)}
      </TextPrimary>
      <TextPrimary key="orderIdValue" theme={OrderIdStyle}>
        {contractTimeDeposit?.getFormattedSerialNumber() || ''}
      </TextPrimary>
      <PropertyListContractTimeDepositAfterMatured key="propertyList" />
    </ContractTimeDepositRenewalReservationDeleteCompleteTemplateLayout>
  );
};
export default ContractTimeDepositRenewalReservationDeleteCompleteTemplate;

import styled from 'styled-components';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TitleStyle = {
  fontSize: 21,
  fontWeight: fontWeight.bold,
  color: color.text.primary,
};

export const IconProps = {
  theme: {
    color: color.text.white,
    backgroundColor: color.background.success,
  },
  size: 12,
  diameter: 30,
};

import React, { FC } from 'react';

// Component
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import SelectUserTypeTemplate from '../../../components/templates/kycRequest/SelectUserTypeTemplate';

const SelectUserTypePage: FC = () => {
  return (
    <DashboardTemplate>
      <SelectUserTypeTemplate />
    </DashboardTemplate>
  );
};

export default SelectUserTypePage;

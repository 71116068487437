import React, { FC } from 'react';

// Lib
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';

export interface ContractTimeDepositCardTermLayoutProps {
  children: React.ReactElement[];
}

const ContractTimeDepositCardTermLayout: FC<ContractTimeDepositCardTermLayoutProps> = ({
  children,
}: ContractTimeDepositCardTermLayoutProps) => {
  const elements = ['depositDate', 'maturityDate'];

  const [depositDate, maturityDate] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow height="auto" justifyContent="space-between">
      {depositDate}
      {maturityDate}
    </FlexBoxRow>
  );
};

export default ContractTimeDepositCardTermLayout;

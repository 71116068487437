import RequestTimeoutError from '../errors/RequestTimeoutError';

/*
 * function checkInternetConnection
 *
 * return void
 *
 */

export const checkInternetConnection = () => {
  if (!window.navigator.onLine) throw new RequestTimeoutError();
};

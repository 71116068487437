// Type
import { CherryAPIFilePurposeType } from '../../../../types/CherryAPIFilePurposeType';
import { FormInputCherryVisa01ProofOfIdentity } from '../../../../components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/FormCherryVisa01ProofOfIdentity.type';

// IUseCase
import IDebitCardRequestCherryVisa01ProofOfIdentityUpdateUseCase from '../../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01ProofOfIdentityUpdateProvider/IDebitCardRequestCherryVisa01ProofOfIdentityUpdateUseCase';

// IService
import ICherryStorageService from '../../../storage/ICherryStorageService';
import IDebitCardRequestCherryVisa01UpdateService from './IDebitCardRequestCherryVisa01UpdateService';

// Service
import DebitCardRequestCherryVisa01UpdateService from '../../../../service/debitCard/debitCardRequest/debitCardRequestCherryVisa01/DebitCardRequestCherryVisa01UpdateService';
import CherryStorageService from '../../../../service/storage/CherryStorageService';

// IRepository
import ICherryStorageHandler from '../../../../storage/cherryStorageHandler/ICherryStorageHandler';
import { IDebitCardRequestCherryVisa01UpdateRepository } from '../../../../domain/debitCard/debitCardRequest/IDebitCardRequestCherryVisa01Repository';

// DomainObject
import DebitCardRequestCherryVisa01 from '../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01';

class DebitCardRequestCherryVisa01ProofOfIdentityUpdateUseCase
  implements IDebitCardRequestCherryVisa01ProofOfIdentityUpdateUseCase {
  private debitCardRequestCherryVisa01UpdateService: IDebitCardRequestCherryVisa01UpdateService;

  private storageService: ICherryStorageService;

  constructor(
    debitCardRequestCherryVisa01Repository: IDebitCardRequestCherryVisa01UpdateRepository,
    storageHandler: ICherryStorageHandler,
  ) {
    this.debitCardRequestCherryVisa01UpdateService = new DebitCardRequestCherryVisa01UpdateService(
      debitCardRequestCherryVisa01Repository,
    );

    this.storageService = new CherryStorageService(storageHandler);
  }

  public update = async (
    debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01,
    formInputProofOfIdentity: FormInputCherryVisa01ProofOfIdentity,
    document1: File | undefined,
    document2: File | undefined,
    passport: File | undefined,
    selfie: File | undefined,
    signature: File | undefined,
  ) => {
    // Upload DocumentId1
    const uploadDocumentId1 = async () => {
      if (document1) {
        return this.storageService.upload(
          document1,
          CherryAPIFilePurposeType.identityDocument,
        );
      }

      return undefined;
    };

    // Upload DocumentId2
    const uploadDocumentId2 = async () => {
      if (document2) {
        return this.storageService.upload(
          document2,
          CherryAPIFilePurposeType.identityDocument,
        );
      }

      return undefined;
    };

    // Upload Passport
    const uploadPassport = async () => {
      if (passport) {
        return this.storageService.upload(
          passport,
          CherryAPIFilePurposeType.identityDocument,
        );
      }

      return undefined;
    };

    // Upload Selfie
    const uploadSelfie = async () => {
      if (selfie) {
        return this.storageService.upload(
          selfie,
          CherryAPIFilePurposeType.identityDocument,
        );
      }

      return undefined;
    };

    // Upload Signature
    const uploadSignature = async () => {
      if (signature) {
        return this.storageService.upload(
          signature,
          CherryAPIFilePurposeType.customerSignature,
        );
      }

      return undefined;
    };

    // UploadFile
    const [
      documentId1,
      documentId2,
      passportId,
      selfieId,
      signatureId,
    ] = await Promise.all([
      uploadDocumentId1(),
      uploadDocumentId2(),
      uploadPassport(),
      uploadSelfie(),
      uploadSignature(),
    ]);

    const updatedDebitCardRequestCherryVisa01 = await this.debitCardRequestCherryVisa01UpdateService.updateProofOfIdentity(
      debitCardRequestCherryVisa01,
      formInputProofOfIdentity,
      documentId1,
      documentId2,
      passportId,
      selfieId,
      signatureId,
    );

    return updatedDebitCardRequestCherryVisa01;
  };

  executeUpdate = async (
    debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01,
  ) => {
    const updatedDebitCardRequestCherryVisa01 = await this.debitCardRequestCherryVisa01UpdateService.executeUpdateProofOfIdentity(
      debitCardRequestCherryVisa01,
    );

    return {
      debitCardRequestCherryVisa01: updatedDebitCardRequestCherryVisa01,
    };
  };
}

export default DebitCardRequestCherryVisa01ProofOfIdentityUpdateUseCase;

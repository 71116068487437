// Types
import { FiatCurrencyType } from '../../../types/FiatCurrency.type';

// IService
import IAssetSavingsAccountService from '../../../useCases/asset/assetSummary/IAssetSavingsAccountQueryService';

// IRepository
import IAssetSavingsAccountRepository from '../../../domain/asset/assetSavingsAccount/IAssetSavingsAccountRepository';

class AssetSavingsAccountQueryService implements IAssetSavingsAccountService {
  private assetSavingsAccountRepository: IAssetSavingsAccountRepository;

  constructor(assetSavingsAccountRepository: IAssetSavingsAccountRepository) {
    this.assetSavingsAccountRepository = assetSavingsAccountRepository;
  }

  public findUserSavingsAccountAsset = async (
    userId: string,
    currency: FiatCurrencyType,
  ) => {
    const AssetSavingsAccount = this.assetSavingsAccountRepository.findByPrimaryKey(
      {
        userId,
        currency,
      },
    );

    return AssetSavingsAccount;
  };
}
export default AssetSavingsAccountQueryService;

// React
import React, { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';

// Context
import CSVDataContext from '../../../../../components/organisms/csv/CSVData/CSVDataContext';
import RequestStateContext from '../../../../../value/view/RequestState/RequestStateContext';
import SavingsAccountContext from '../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccountContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import BankSpotTransactionContext from '../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankSpotTransaction/BankSpotTransactions/BankSpotTransactionsContext';

// UseCase
import { useSavingsAccountHistoryViewUseCase } from './useSavingsAccountHistoryViewUseCase';

// Components
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

export type SavingsAccountHistoryViewProviderProps = {
  children: React.ReactNode;
};

const SavingsAccountHistoryViewProvider: FC<SavingsAccountHistoryViewProviderProps> = ({
  children,
}: SavingsAccountHistoryViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { accountNumber } = useParams<{ accountNumber: string }>();

  const {
    state,
    setState,
    savingsAccount,
    bankSpotTransactions,
    csvDataContext,
  } = useSavingsAccountHistoryViewUseCase(userAuthorized, accountNumber);

  return (
    <RequestStateContext.Provider value={{ requestState: state }}>
      <SavingsAccountContext.Provider value={{ savingsAccount }}>
        <BankSpotTransactionContext.Provider value={{ bankSpotTransactions }}>
          <CSVDataContext.Provider value={csvDataContext}>
            <SystemErrorHandler state={state} setState={setState}>
              {children}
            </SystemErrorHandler>
          </CSVDataContext.Provider>
        </BankSpotTransactionContext.Provider>
      </SavingsAccountContext.Provider>
    </RequestStateContext.Provider>
  );
};
export default SavingsAccountHistoryViewProvider;

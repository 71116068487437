import { useState } from 'react';
import AssetDebitCard from './AssetDebitCard';

export const useAssetDebitCard = (defaultValue?: AssetDebitCard) => {
  const [assetDebitCard, setAssetDebitCard] = useState<
    AssetDebitCard | undefined
  >(defaultValue);

  return {
    assetDebitCard,
    setAssetDebitCard,
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { components } from 'react-select';
import { OptionTextStyle, Hover } from './style';
import { TextSecondary } from '../../../../../atoms/text2/Text2';
import Margin from '../../../../../atoms/utils/layout/Margin';
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';

const DebitCardOptionContent: FC = (props: any) => {
  const { type, label } = props.data;

  return (
    <>
      <FlexBoxRow alignItems="center" height="100%">
        <TextSecondary theme={OptionTextStyle}>{`${type}`}</TextSecondary>
        <Margin left={15} />
        <TextSecondary theme={OptionTextStyle}>{`${label}`}</TextSecondary>
      </FlexBoxRow>
    </>
  );
};

const DebitCardSingleValueContainer: FC = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <FlexBoxRow alignItems="center">
        <DebitCardOptionContent {...props} />
      </FlexBoxRow>
    </components.SingleValue>
  );
};

const DebitCardOptionContainer: FC = (props: any) => {
  return (
    <Hover>
      <components.Option {...props}>
        <FlexBoxRow alignItems="center">
          <DebitCardOptionContent {...props} />
        </FlexBoxRow>
      </components.Option>
    </Hover>
  );
};

export { DebitCardSingleValueContainer, DebitCardOptionContainer };

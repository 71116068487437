import React, { FC, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { usePersonalInformationViewUseCase } from './usePersonalInformationViewUseCase';
import KycRequestContext from '../../../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequestContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Type
import { KycStatusType } from '../../../../../types/KycRequest.type';

// Constant
import { ROUTE_KYC_STATUS } from '../../../../../config/constants/routing';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

export type PersonalInformationViewProviderProps = {
  children: React.ReactNode;
};

const PersonalInformationViewProvider: FC<PersonalInformationViewProviderProps> = ({
  children,
}: PersonalInformationViewProviderProps) => {
  const history = useHistory();

  const { userAuthorized } = useContext(UserAuthorizedContext);

  const { state, setState, kycRequest } = usePersonalInformationViewUseCase(
    userAuthorized,
  );

  /* *
   *
   *  useEffect (Redirect)
   *
   * */

  // Status of PersonalInformation
  const status = useMemo(
    () => kycRequest?.getKycStepStatus().getProps().personalInformation,
    [kycRequest],
  );

  // Redirect if status is invalid
  useEffect(() => {
    if (
      status &&
      ![
        KycStatusType.initial,
        KycStatusType.submitted,
        KycStatusType.rejected,
      ].includes(status)
    )
      history.push(ROUTE_KYC_STATUS);
    // eslint-disable-next-line
  }, [status]);

  return (
    <KycRequestContext.Provider value={{ kycRequest }}>
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </KycRequestContext.Provider>
  );
};

export default PersonalInformationViewProvider;

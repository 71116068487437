import React, { FC } from 'react';
import TransferSavingAccountPage from './TransferSavingsAccountPage';
import TransferSavingsAccountViewProvider from '../../../enhancers/useCase/transfer/savingsAccount/TransferSavingsAccountViewProvider';
import TransferSavingsAccountCreateProvider from '../../../enhancers/useCase/transfer/savingsAccount/TransferSavingsAccountCreateProvider';

const EnhancedTransferSavingAccountPage: FC = () => {
  return (
    <TransferSavingsAccountViewProvider>
      <TransferSavingsAccountCreateProvider>
        <TransferSavingAccountPage />
      </TransferSavingsAccountCreateProvider>
    </TransferSavingsAccountViewProvider>
  );
};

export default React.memo(EnhancedTransferSavingAccountPage);

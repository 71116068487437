// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import { AssetDebitCardProps } from './AssetDebitCard.type';
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';

// DomainObject
import Asset from '../../asset/Asset/Asset';

class AssetDebitCard extends Asset {
  private joint: {
    balance: number;
    pendingBalance: number;
  };

  constructor(currency: FiatCurrencyType, props: AssetDebitCardProps) {
    super(currency, props);
    this.joint = props.joint;

    // TYPE GUARD
    if (!Object.keys(FiatCurrencyType).includes(currency))
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
        {
          type: 'FiatCurrencyType',
          place: 'AssetDebitCard.constructor',
        },
      );
  }
}
export default AssetDebitCard;

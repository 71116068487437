import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ContractsTimeDepositContext from '../../../../domain/contract/contractTimeDeposit/ContractsTimeDeposit/ContractsTimeDepositContext';

// Type
import { CurrencyType } from '../../../../types/Currency.type';

// Style
import { LabeledCurrencyAmountTheme } from './style';

// Constant
import { BASE_CURRENCY_SYMBOL } from '../../../../config/constants/business';

// Component
import HeaderContractTimeDeposit from '../../../organisms/header/HeaderContentTitle/contract/HeaderContractTimeDeposit';
import SavingsAccountTabBar from '../../../molecules/selector/tabBar/SavingsAccountTabBar';
import LabeledCurrencyAmount from '../../../molecules/textGroup/LabeledCurrencyAmount';
import ContractTimeDepositCardList from '../../../organisms/list/ContractTimeDepositCardList';
import ContractTimeDepositTemplateLayout from './ContractTimeDepositTemplateLayout';

const TEMPLATE_ID = 'templates.contract.ContractsTimeDepositTemplate';

const ContractTimeDepositTemplate: FC = () => {
  const { t } = useTranslation();

  const { contractsTimeDeposit } = useContext(ContractsTimeDepositContext);

  return (
    <ContractTimeDepositTemplateLayout>
      <HeaderContractTimeDeposit key="header" />
      <SavingsAccountTabBar key="tabBar" />
      <LabeledCurrencyAmount
        key="total"
        amount={contractsTimeDeposit.getTotalAmount()}
        currency={BASE_CURRENCY_SYMBOL as CurrencyType}
        direction="row"
        label={t(`${TEMPLATE_ID}.label.total`)}
        isSymbol
        marginBetween={10}
        theme={LabeledCurrencyAmountTheme}
      />
      <ContractTimeDepositCardList key="cardList" />
    </ContractTimeDepositTemplateLayout>
  );
};
export default ContractTimeDepositTemplate;

import React, { FC, useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import ResetAuthenticatorContext from '../../../../../enhancers/useCase/authentication/ResetAuthenticatorProvider/ResetAuthenticatorContext';
import ResetAuthenticatorRequestContext from '../../../../../domain/userRequest/resetAuthenticatorRequest/ResetAuthenticatorRequest/ResetAuthenticatorRequestContext';

// Type
import {
  FormInputResetAuthenticatorTwoFactorAuth,
  FormInputResetAuthenticatorTwoFactorAuthContext,
} from './FormResetAuthenticatorTwoFactorAuthInput';

// Constant
import { ErrorCodeType } from '../../../../../utils/errors/ErrorHandler';
import { IS_LOADING } from '../../../../../config/constants/requestState';

// Component
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../atoms/button/Button2';
import LabelSendIcon from '../../../../atoms/button/ButtonLabel/LabelSendIcon';
import GoogleAuthenticatorDownload from '../../twoFactorAuthAppSettings/FormTwoFactorAuthAppSettings/GoogleAuthenticatorDownload';
import AccountKeyDetail from '../../twoFactorAuthAppSettings/FormTwoFactorAuthAppSettings/AccountKeyDetail';
import FormTwoFactorAuthCode from '../../twoFactorAuthAppSettings/FormTwoFactorAuthAppSettings/FormTwoFactorAuthCode';
import FormResetAuthenticatorTwoFactorAuthLayout from './FormResetAuthenticatorTwoFactorAuthLayout';

const FormResetAuthenticatorTwoFactorAuth: FC = () => {
  const { t } = useTranslation();
  const { formInput, setFormInput } = useContext(
    FormInputResetAuthenticatorTwoFactorAuthContext,
  );

  // Method
  const { state, resetAuthenticator } = useContext(ResetAuthenticatorContext);

  // Domain Object
  const { resetAuthenticatorRequest } = useContext(
    ResetAuthenticatorRequestContext,
  );

  /* *
   *
   *  Form
   *
   * */

  const methods = useForm<
    FormInputResetAuthenticatorTwoFactorAuth & { validCode: string }
  >({
    mode: 'onChange',
  });

  const { handleSubmit, setError, setValue, watch } = methods;

  /* *
   *
   *  UseEffect
   *
   * */

  useEffect(() => {
    if (state === ErrorCodeType.AUTHENTICATION_SIGN_UP_USERNAME_EXISTED)
      setError('validCode', {
        type: '',
        message: t(`validation.${state}`),
      });
  }, [state, setError, t]);

  // Set DefaultValue
  useEffect(() => {
    if (formInput) setValue('code', formInput.code);
    // eslint-disable-next-line
  }, [formInput, state]);

  // Variable
  const accountName = resetAuthenticatorRequest?.getProps().email;
  const accountKey = resetAuthenticatorRequest?.getProps().accountKey;

  /* *
   *
   *  Event
   *
   * */
  const onSubmit = useCallback(
    async (input: FormInputResetAuthenticatorTwoFactorAuth) => {
      if (!resetAuthenticatorRequest) return;

      const id = resetAuthenticatorRequest.getId();

      setFormInput(input);

      await resetAuthenticator(input, id);
    },
    // eslint-disable-next-line
    [resetAuthenticator, resetAuthenticatorRequest],
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormResetAuthenticatorTwoFactorAuthLayout>
          <GoogleAuthenticatorDownload key="downloadApp" />
          <AccountKeyDetail
            key="accountKey"
            accountKey={accountKey}
            accountName={accountName}
          />
          <FormTwoFactorAuthCode key="form" state={state} />
          <SubmitButton
            key="button"
            disabled={!watch('code')}
            height={75}
            isLoading={state === IS_LOADING}
            theme={{ borderRadius: `${10 / 16}rem` }}
            width={'100%'}
          >
            <LabelSendIcon
              iconSize={26}
              iconStyle={{ color: 'white' }}
              label={t(`atoms.button.submit`)}
              marginBetween={8}
              textStyle={DefaultButtonTextStyle}
            />
          </SubmitButton>
        </FormResetAuthenticatorTwoFactorAuthLayout>
      </form>
    </FormProvider>
  );
};

export default FormResetAuthenticatorTwoFactorAuth;

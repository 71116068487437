import { FC } from 'react';

// Interface
import { IIterable } from '../../../interface/Iterator/IIterable';

// DomainObject
import DebitCardV2 from '../DebitCardV2/DebitCardV2';

class DebitCardV2s implements IIterable<DebitCardV2> {
  private items: DebitCardV2[] = [];

  constructor(items?: DebitCardV2[]) {
    this.items = items ?? [];
  }

  public add = (item: DebitCardV2) => {
    this.items.push(item);
  };

  public head = () => this.items[0];

  public hasItem = () => this.items.length > 0;

  public map = <T>(callback: (item: DebitCardV2) => T) => {
    return this.items.map((item) => callback(item));
  };

  public forEach = (callback: (item: DebitCardV2) => void) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.items) {
      // eslint-disable-next-line no-await-in-loop
      callback(item);
    }
  };

  public forEachAsync = async (
    callback: (item: DebitCardV2) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.items) {
      // eslint-disable-next-line no-await-in-loop
      await callback(item);
    }
  };

  public mapComponent = (component: FC<{ item: DebitCardV2; i: number }>) => {
    return this.items.map((item, i) => component({ item, i }));
  };

  public filterById = (id: string) => {
    const filtered = this.items.filter((debitCard) => debitCard.getId() === id);
    return filtered[0];
  };

  public filterBySavingsAccountNumber = (savingsAccountNumber: string) => {
    const filtered = this.items.filter(
      (debitCard) =>
        debitCard.getProps().savingsAccountNumber === savingsAccountNumber,
    );
    return filtered.length > 0
      ? new DebitCardV2s(filtered)
      : new DebitCardV2s();
  };
}
export default DebitCardV2s;

import React, { FC, useContext } from 'react';
import ResetAuthenticatorContext from '../../../enhancers/useCase/authentication/ResetAuthenticatorProvider/ResetAuthenticatorContext';

// Constant
import { SUCCESS } from '../../../config/constants/requestState';

// Component
import SignUpPageGroupTemplate from '../../../components/templates/front/SignUpPageGroupTemplate';
import ResetAuthenticatorTemplate from '../../../components/templates/front/ResetAuthenticatorTemplate';
import ResetAuthenticatorCompleteTemplate from '../../../components/templates/front/ResetAuthenticatorCompleteTemplate';

const ResetAuthenticatorPage: FC = () => {
  const { state } = useContext(ResetAuthenticatorContext);

  const TemplateComponent: FC = () => {
    switch (state) {
      case SUCCESS:
        return <ResetAuthenticatorCompleteTemplate />;
      default:
        return <ResetAuthenticatorTemplate />;
    }
  };

  return (
    <SignUpPageGroupTemplate>
      <TemplateComponent />
    </SignUpPageGroupTemplate>
  );
};

export default ResetAuthenticatorPage;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Type
import { FiatCurrencyType } from '../../../../../../types/FiatCurrency.type';

// Style
import { LabeledCurrencyAmountStyle } from './style';

// DomainObject
import ContractTimeDeposit from '../../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';

// Component
import LabeledCurrencyAmount from '../../../../../molecules/textGroup/LabeledCurrencyAmount';
import ContractTimeDepositCardAmountInformationLayout from './ContractTimeDepositCardAmountInformationLayout';

export interface ContractTimeDepositCardAmountInformationProps {
  contractTimeDeposit?: ContractTimeDeposit;
}

const ORGANISM_ID = `organisms.card.ContractTimeDepositCard`;

const ContractTimeDepositCardAmountInformation: FC<ContractTimeDepositCardAmountInformationProps> = ({
  contractTimeDeposit,
}: ContractTimeDepositCardAmountInformationProps) => {
  const { t } = useTranslation();

  const currency = contractTimeDeposit?.getProps().depositCurrency;
  const depositAmount = contractTimeDeposit?.getProps().depositAmount;
  const totalInterest = contractTimeDeposit?.calculateTotalInterest();

  return (
    <ContractTimeDepositCardAmountInformationLayout>
      <LabeledCurrencyAmount
        key="depositAmount"
        amount={depositAmount ?? 0}
        currency={currency ?? FiatCurrencyType.USD}
        direction="column"
        label={t(`${ORGANISM_ID}.label.amount`)}
        isSymbol
        marginBetween={4}
        theme={LabeledCurrencyAmountStyle}
      />
      <LabeledCurrencyAmount
        key="amountIncreased"
        amount={totalInterest ?? 0}
        currency={currency ?? FiatCurrencyType.USD}
        direction="column"
        label={t(`${ORGANISM_ID}.label.interest`)}
        isSymbol
        marginBetween={4}
        theme={LabeledCurrencyAmountStyle}
      />
    </ContractTimeDepositCardAmountInformationLayout>
  );
};

export default ContractTimeDepositCardAmountInformation;

import firestore from '../../client/firestoreClient';
import Collection from '../collection/Collection';
import { FIRESTORE_DATA_VERSION } from '../../../../../config/constants/firebase';

class CryptoCurrenciesTradeFeeCollection extends Collection {
  collection: firestore.CollectionReference;

  constructor() {
    super();
    this.collection = firestore()
      .collection('settings')
      .doc(FIRESTORE_DATA_VERSION)
      .collection('cryptoCurrenciesTradeFeeRate');
  }
}

export default CryptoCurrenciesTradeFeeCollection;

// React
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle, DepositResultTextStyle } from './style';
import {
  CloseButton,
  DefaultButtonTextStyle,
} from '../../../atoms/button/Button2';

// Component
import CryptoCurrencyDepositNotificationIcon from '../../../atoms/icon/CryptoCurrencyDepositNotificationIcon';
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import { TextPrimary, TextSecondary } from '../../../atoms/text2/Text2';
import CryptoCurrencyDepositNotificationLayout from './CryptoCurrencyDepositNotificationLayout';
import CryptoCurrencyDepositResultCard from '../../../molecules/card/cryptoCurrency/CryptoCurrencyDepositResultCard';
import CryptoCurrencyDepositNotificationDescription from './CryptoCurrencyDepositNotificationDescription';
import CryptoCurrencyDeposit from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/CryptoCurrencyDeposit/CryptoCurrencyDeposit';

export interface CryptoCurrencyDepositNotificationProps {
  cryptoCurrencyDeposit?: CryptoCurrencyDeposit;
  closeAction: () => void;
}

const ORGANISM_ID = 'organisms.notification.CryptoCurrencyDepositNotification';

const CryptoCurrencyDepositNotification: FC<CryptoCurrencyDepositNotificationProps> = ({
  cryptoCurrencyDeposit,
  closeAction,
}: CryptoCurrencyDepositNotificationProps) => {
  const { t } = useTranslation();

  if (!cryptoCurrencyDeposit) return <></>;

  const { amount, currency, fee, status } = cryptoCurrencyDeposit.getProps();

  const title = t(`${ORGANISM_ID}.${status}.title`);
  return (
    <CryptoCurrencyDepositNotificationLayout>
      <CryptoCurrencyDepositNotificationIcon
        key="icon"
        status={status}
        size={73}
      />
      <PageTitle key="title">{title}</PageTitle>
      <TextSecondary key="description1" theme={DescriptionStyle}>
        {t(`${ORGANISM_ID}.${status}.description.line1`)} <br />
      </TextSecondary>
      <CryptoCurrencyDepositResultCard
        key="depositResult"
        currency={currency}
        depositAmount={amount}
        fee={fee}
        marginBetweenCurrencyAmount={10}
        marginBetweenLabelAmount={20}
        theme={{
          label: DepositResultTextStyle,
          currency: DepositResultTextStyle,
          amount: DepositResultTextStyle,
        }}
      />
      <CryptoCurrencyDepositNotificationDescription
        key="description2"
        status={status}
      />
      <CloseButton
        key="button"
        width={140}
        height={50}
        theme={{ borderRadius: 25 }}
        onClick={() => closeAction()}
      >
        <TextPrimary theme={DefaultButtonTextStyle}>
          {t(`atoms.button.gotIt`)}
        </TextPrimary>
      </CloseButton>
    </CryptoCurrencyDepositNotificationLayout>
  );
};

export default CryptoCurrencyDepositNotification;

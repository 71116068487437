// Helper
import { convertMoneyFormat } from '../../../../../../utils/helpers/currencyHelper';

// Type
import { TypeOfT } from '../../../../../../types/typeof/Translation.type';
import { CryptoCurrencyType } from '../../../../../../types/CryptoCurrency.type';

// DomainObject
import CryptoCurrencyTransactions from '../../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTransaction/CryptoCurrencyTransactions/CryptoCurrencyTransactions';
import CryptoCurrencyTransaction from '../../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTransaction/CryptoCurrencyTransaction/CryptoCurrencyTransaction';
import CryptoCurrencyBuyTransaction from '../../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyBuyTransaction/CryptoCurrencyBuyTransaction/CryptoCurrencyBuyTransaction';
import CryptoCurrencyDeposit from '../../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/CryptoCurrencyDeposit/CryptoCurrencyDeposit';

// ValueObject
import TimeZonedTime from '../../../../../../value/datetime/TimeZonedTime';

const ORGANISM_ID = `organisms.table.TableCryptoCurrencyTransactionHistory`;

type Object = { [key: string]: string };

export interface TdCryptoCurrencyAllHistory extends Object {
  createdAt: string;
  transactionType: string;
  deposit: string;
  withdrawal: string;
  fee: string;
  status: string;
  balance: string;
}

class TableCryptoCurrencyAllHistoryMapper {
  static domainToTd = (
    transactions: CryptoCurrencyTransactions,
    currency: CryptoCurrencyType | '',
    t: TypeOfT,
  ) => {
    if (currency === '')
      return [
        {
          createdAt: '',
          transactionType: '',
          deposit: '',
          withdrawal: '',
          fee: '',
          status: '',
          balance: '',
        } as TdCryptoCurrencyAllHistory,
      ];

    const converter = (
      cryptoCurrencyTransaction: CryptoCurrencyTransaction,
    ) => {
      // Preparation
      const { createdAt, amount } = cryptoCurrencyTransaction.getProps();

      // Conversion
      const tdCreatedAt = new TimeZonedTime(createdAt).getTwoLinesDateTime();
      const tdTransactionType = t(
        `${ORGANISM_ID}.td.transactionType.${cryptoCurrencyTransaction.getTransactionType()}`,
      );
      const [tdDeposit, tdWithdrawal] =
        cryptoCurrencyTransaction instanceof CryptoCurrencyBuyTransaction ||
        cryptoCurrencyTransaction instanceof CryptoCurrencyDeposit
          ? [convertMoneyFormat(amount, currency), '']
          : ['', convertMoneyFormat(amount, currency)];
      const tdFee = convertMoneyFormat(
        cryptoCurrencyTransaction.getFee(),
        currency,
      );
      const tdStatus = cryptoCurrencyTransaction.getStatus();
      const tdBalance = convertMoneyFormat(
        cryptoCurrencyTransaction.getProps().balance,
        currency,
      );

      // Mapping
      return {
        createdAt: tdCreatedAt,
        transactionType: tdTransactionType,
        deposit: tdDeposit,
        withdrawal: tdWithdrawal,
        fee: tdFee,
        status: tdStatus,
        balance: tdBalance,
      } as TdCryptoCurrencyAllHistory;
    };

    return transactions.map<TdCryptoCurrencyAllHistory>(converter);
  };
}

export default TableCryptoCurrencyAllHistoryMapper;

// Type
import { ConsentFormStepsStatusProps } from './ConsentFormStepsStatus.type';
import { KycStatusType } from '../../../../../types/KycRequest.type';

// ValueObject
import ConsentFormStatus from '../ConsentFormStatus/ConsentFormStatus/ConsentFormStatus';
import StatusConsentForm from '../ConsentFormStepStatus/StatusConsentForm/StatusConsentForm';
import StatusTwoFactorAuthenticationAppSettings from '../ConsentFormStepStatus/StatusTwoFactorAuthenticationAppSettings/StatusTwoFactorAuthenticationAppSettings';
import StatusWaitingConsentFormReview from '../ConsentFormStepStatus/StatusWaitingConsentFormReview/StatusWaitingConsentFormReview';

class ConsentFormStepsStatus {
  private status: ConsentFormStatus;

  private statusConsentForm: StatusConsentForm;

  private statusTwoFactorAuthenticationAppSettings: StatusTwoFactorAuthenticationAppSettings;

  private statusWaitingConsentFormReview: StatusWaitingConsentFormReview;

  private props: ConsentFormStepsStatusProps;

  constructor(props: ConsentFormStepsStatusProps) {
    this.status = new ConsentFormStatus(props.status);
    this.statusConsentForm = new StatusConsentForm(props.statusConsentForm);
    this.statusTwoFactorAuthenticationAppSettings = new StatusTwoFactorAuthenticationAppSettings(
      props.statusTwoFactorAuthenticationAppSettings,
    );
    this.statusWaitingConsentFormReview = new StatusWaitingConsentFormReview(
      props.statusWaitingConsentFormReview,
    );
    this.props = props;
  }

  public getProps = () => ({
    status: this.status.getStatus(),
    statusConsentForm: this.statusConsentForm.getStatus(),
    statusTwoFactorAuthenticationAppSettings: this.statusTwoFactorAuthenticationAppSettings.getStatus(),
    statusWaitingConsentFormReview: this.statusWaitingConsentFormReview.getStatus(),
  });

  public isReviewing = () =>
    this.status.getStatus() === KycStatusType.reviewing;
}
export default ConsentFormStepsStatus;

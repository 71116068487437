import React, { FC, useEffect, useContext } from 'react';
import { isNaN } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import SignInTwoFactorAuthContext from '../../../../../enhancers/useCase/authentication/SignInTwoFactorAuthProvider/SignInTwoFactorAuthContext';
import { FormSignInTwoFactorAuthInput } from './FormSignInTwoFactorAuth.type';
import { ErrorCodeType } from '../../../../../utils/errors/ErrorHandler';
import { IS_LOADING } from '../../../../../config/constants/requestState';
import SeparatedDigitInput from '../../../../molecules/form/formInputGroup/SeparatedDigitInput';
import { TextErrorMessage } from '../../../../atoms/text/DomainText';

// Component
import { TextPrimary } from '../../../../atoms/text2/Text2';
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../atoms/button/Button2';
import FormSignInTwoFactorAuthLayout from './FormSignInTwoFactorAuthLayout';

const FormSignInTwoFactorAuth: FC = () => {
  const { state, signInTwoFactorAuth } = useContext(SignInTwoFactorAuthContext);

  const { t } = useTranslation();
  const methods = useForm<
    FormSignInTwoFactorAuthInput & {
      authentication: string;
      currentPosition: number;
    }
  >({
    mode: 'onChange',
  });

  const {
    clearErrors,
    errors,
    formState: { isValid },
    handleSubmit,
    register,
    setValue,
    setError,
    watch,
  } = methods;

  useEffect(() => {
    setValue('currentPosition', 1);
  }, [setValue]);

  useEffect(() => {
    if (state === ErrorCodeType.AUTHENTICATION_LOGIN_INVALID_CODE)
      setError('authentication', {
        type: '',
        message: t(
          `validation.${ErrorCodeType.AUTHENTICATION_LOGIN_INVALID_CODE}`,
        ),
      });
  }, [state, setError, t]);

  const onChangeCode = (name: string, code: string, position: number) => {
    if (isNaN(Number(code))) {
      setValue(name, '');
    } else if (code.length === 0) {
      setValue('currentPosition', position);
    } else {
      setValue('currentPosition', position === 6 ? position : position + 1);
    }
    if (errors.authentication) clearErrors('authentication');
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit((formInput: FormSignInTwoFactorAuthInput) => {
          signInTwoFactorAuth(formInput);
        })}
      >
        <FormSignInTwoFactorAuthLayout>
          <SeparatedDigitInput
            key="input"
            currentPosition={watch('currentPosition')}
            digit={6}
            onChange={onChangeCode}
            type="password"
            register={register}
          />
          <TextErrorMessage key="errorMessage">
            {errors.authentication?.message || ''}
          </TextErrorMessage>
          <SubmitButton
            key="button"
            disabled={!isValid}
            height={50}
            isLoading={state === IS_LOADING}
            theme={{ borderRadius: 25 }}
            sizeProgress={20}
            width={165}
          >
            <TextPrimary theme={DefaultButtonTextStyle}>
              {t(`atoms.button.submit`)}
            </TextPrimary>
          </SubmitButton>
        </FormSignInTwoFactorAuthLayout>
      </form>
    </FormProvider>
  );
};

export default FormSignInTwoFactorAuth;

import storage from './storage';
import { executeWithTimeout } from '../../helpers/timeout';
import { FILE_UPLOAD_TIMEOUT } from '../../../config/constants/business';

export const uploadFile = async (f: File, path: string) => {
  const ref = storage.ref();
  const fileRef = ref.child(path);

  const promiseUpload = new Promise((resolve, reject) => {
    fileRef
      .put(f)
      .then(() => {
        resolve(undefined);
      })
      .catch((error) => reject(error));
  });

  await executeWithTimeout(promiseUpload, FILE_UPLOAD_TIMEOUT);
};

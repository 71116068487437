import Joi from '@hapi/joi';

// Lib
import { generateRandomHash } from '../../../../utils/helpers/hash';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import { DebitCardType } from '../../../../types/DebitCard.type';
import {
  DebitCardRequestCherryVisa01InitialProps,
  DebitCardRequestCherryVisa01Props,
  DebitCardRequestCherryVisa01PropsFormat,
  DebitCardRequestCherryVisa01RawDataType,
} from './DebitCardRequestCherryVisa01.type';

// DomainObject
import DebitCardRequestCherryVisa01 from './DebitCardRequestCherryVisa01';

// ValueObject
import CherryVisa01ProofOfIdentityFactory from '../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentityFactory';
import CherryVisa01ProofOfReceiptFactory from '../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfReceipt/CherryVisa01ProofOfReceiptFactory';
import DeliveryTrackingInformationFactory from '../../../../value/debitCard/DebitCardRequestContent/DeliveryTrackingInformation/DeliveryTrackingInformationFactory';
import IssuedDebitCardInformationFactory from '../../../../value/debitCard/DebitCardRequestContent/IssuedDebitCardInformation/IssuedDebitCardInformationFactory';

class DebitCardRequestCherryVisa01Factory {
  static create = (id: string, props: DebitCardRequestCherryVisa01Props) => {
    const result = Joi.object(DebitCardRequestCherryVisa01PropsFormat).validate(
      {
        ...props,
        id,
      },
    );

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: DebitCardRequestCherryVisa01.name,
          reason: `${result.error.details[0].message}`,
        },
      );

    return new DebitCardRequestCherryVisa01(id, props);
  };

  static createInitial = (savingsAccountNumber: string) => {
    const id = generateRandomHash();

    return DebitCardRequestCherryVisa01Factory.create(id, {
      ...DebitCardRequestCherryVisa01InitialProps,
      savingsAccountNumber,
      type: DebitCardType.VISA,
      userId: 'dummy',
    });
  };

  static createFromRawData = (
    debitCardRequestCherryVisa01RawData: DebitCardRequestCherryVisa01RawDataType,
  ) => {
    const { id, props } = debitCardRequestCherryVisa01RawData;

    const proofOfIdentity =
      props.proofOfIdentity &&
      CherryVisa01ProofOfIdentityFactory.create(props.proofOfIdentity);

    const deliveryTrackingInformation =
      props.deliveryTrackingInformation &&
      DeliveryTrackingInformationFactory.create(
        props.deliveryTrackingInformation,
      );

    const proofOfReceipt =
      props.proofOfReceipt &&
      CherryVisa01ProofOfReceiptFactory.create(props.proofOfReceipt);

    const issuedDebitCardInformation =
      props.issuedDebitCardInformation &&
      IssuedDebitCardInformationFactory.create(
        props.issuedDebitCardInformation,
      );

    return DebitCardRequestCherryVisa01Factory.create(id, {
      ...debitCardRequestCherryVisa01RawData.props,
      proofOfIdentity,
      deliveryTrackingInformation,
      proofOfReceipt,
      issuedDebitCardInformation,
    });
  };
}

export default DebitCardRequestCherryVisa01Factory;

import React, { FC } from 'react';
import { CircleBox } from '../../../utils/layout/Box/Box/CircleBox';

export interface ProgressStepCurrentProps {
  diameterLargeCircle: string | number;
  diameterSmallCircle: string | number;
  theme: {
    backgroundColorLarge: string;
    backgroundColorSmall: string;
  };
}

const ProgressStepCurrent: FC<ProgressStepCurrentProps> = ({
  diameterLargeCircle,
  diameterSmallCircle,
  theme,
}: ProgressStepCurrentProps) => {
  return (
    <CircleBox
      theme={{ backgroundColor: theme.backgroundColorLarge }}
      width={diameterLargeCircle}
      height={diameterLargeCircle}
    >
      <CircleBox
        theme={{ backgroundColor: theme.backgroundColorSmall }}
        width={diameterSmallCircle}
        height={diameterSmallCircle}
      />
    </CircleBox>
  );
};
export default ProgressStepCurrent;

import styled from 'styled-components';
import fontWeight from '../../utils/const/fontWeight';

const TextArea = styled.textarea`
  box-sizing: border-box;
  outline: none;
  width: 100%;
  border: 1px solid #d6ddec;
  padding: ${10 / 16}rem ${20 / 16}rem;
  font: ${fontWeight.regular} ${22 / 16}rem LatoRegular;

  :focus {
    outline: none;
    box-shadow: 0px 0px 7px #0093EE45;
    border: 1px solid #D6DDEC; !important
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #D5D0DA;
  }
  ::-ms-input-placeholder {
    color: #D5D0DA;
  }
`;

export default TextArea;

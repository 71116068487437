import { useCallback, useEffect, useMemo, useState } from 'react';

import { checkInternetConnection } from '../../../../../utils/helpers/connection';

// Error
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from '../../../../../utils/errors/ErrorHandler/IErrorHandler';

// Type
import { useFormInputContractTimeDepositAdd } from '../../../../../components/organisms/form/contract/contractTimeDeposit/FormContractTimeDepositAdd/FormInputContractTimeDepositAdd.type';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';

// UseCase
import ContractTimeDepositAddViewUseCase from '../../../../../useCases/contract/contractTimeDeposit/ContractTimeDepositAddViewUseCase';

// Repository
import SavingsAccountFunctions from '../../../../../repository/bankAccount/savingsAccount/SavingsAccountFunctions';
import TimeDepositFirestore from '../../../../../repository/product/timeDeposit/TimeDepositFirestore';

// DomainObject
import { useSavingsAccounts } from '../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/useSavingsAccounts';
import { useTimeDeposits } from '../../../../../domain/product/timeDeposit/TimeDeposits/useTimeDeposits';
import { useUserVerified } from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/useUserVerified';
import UserAuthorized from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useContractTimeDepositAddViewUseCase = (user?: UserAuthorized) => {
  const [state, setState] = useState<string>(INITIAL);
  const formInputContractTimeDepositAddContext = useFormInputContractTimeDepositAdd();

  /* *
   *
   *  DomainObject
   *
   * */
  const { savingsAccounts, setSavingsAccounts } = useSavingsAccounts();
  const { timeDeposits, setTimeDeposits } = useTimeDeposits();
  const { userVerified, setUserVerified } = useUserVerified();

  // Filtered
  const timeDepositsFiltered = useMemo(() => {
    if (!userVerified) return timeDeposits;

    return timeDeposits
      .filterByUser(userVerified)
      .sortForSelector()
      .filterByActive();
  }, [timeDeposits, userVerified]);

  /* *
   *
   *  Repository
   *
   * */
  const savingsAccountRepository = new SavingsAccountFunctions();
  const timeDepositRepository = new TimeDepositFirestore();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new ContractTimeDepositAddViewUseCase(
    timeDepositRepository,
    savingsAccountRepository,
  );

  /* *
   *
   *  Method
   *
   * */
  const openContractTimeDepositAdd = useCallback(async () => {
    if (!user) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // setters
      const setters = {
        timeDeposits: setTimeDeposits,
      };

      // UseCase
      const output = await useCase.open(user, setters, setState);

      // Setter
      setUserVerified(output.user);
      setSavingsAccounts(output.savingsAccounts);

      setState(SUCCESS);
    } catch (error) {
      const handler: IErrorHandler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }

    // eslint-disable-next-line
  }, [user]);

  /* *
   *
   *  UseEffect
   *
   * */

  // UseCase.open
  useEffect(() => {
    let isMounted = true;

    if (isMounted && user) openContractTimeDepositAdd();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [user]);

  return {
    state,
    setState,
    userVerified,
    timeDeposits: timeDepositsFiltered,
    savingsAccounts,
    formInputContractTimeDepositAddContext,
  };
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { INITIAL } from '../../../../config/constants/requestState';

export interface SignInTwoFactorAuthContextProps {
  state: string;
  signInTwoFactorAuth: (formInput: {
    digit1: string;
    digit2: string;
    digit3: string;
    digit4: string;
    digit5: string;
    digit6: string;
  }) => Promise<void>;
}

const SignInTwoFactorAuthContext = React.createContext<
  SignInTwoFactorAuthContextProps
>({
  state: INITIAL,
  signInTwoFactorAuth: (formInput: {
    digit1: string;
    digit2: string;
    digit3: string;
    digit4: string;
    digit5: string;
    digit6: string;
  }) => new Promise(() => undefined),
});

export default SignInTwoFactorAuthContext;

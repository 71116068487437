import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DebitCardRequestCherryVisa01Context from '../../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Context';

// Style
import { TableVerticalLayout, TableVerticalStyle } from './style';

// Mapper
import TableCardDeliveryStatusMapper from './TableCardDeliveryStatusMapper';

// Component
import TableVertical from '../../../../molecules/table/table/TableVertical';

const TableCardDeliveryStatus: FC = () => {
  const { t } = useTranslation();
  const { debitCardRequestCherryVisa01 } = useContext(
    DebitCardRequestCherryVisa01Context,
  );

  if (!debitCardRequestCherryVisa01) return <></>;

  const {
    deliveryTrackingInformation,
  } = debitCardRequestCherryVisa01.getProps();

  if (!deliveryTrackingInformation) return <></>;

  const rows = TableCardDeliveryStatusMapper.toTableData(
    deliveryTrackingInformation,
    t,
  );

  return (
    <TableVertical
      isTableBorder
      layout={TableVerticalLayout}
      rows={rows}
      theme={TableVerticalStyle}
    />
  );
};

export default TableCardDeliveryStatus;

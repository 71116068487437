// React
import React, { FC } from 'react';

// Components
import Box from '../../../../atoms/utils/layout/Box/Box';
import LineBreakReplaced from '../../../../atoms/text2/LineBreakReplaced';

export interface DefaultTdBoxProps {
  text: string;
}

const DefaultTdBox: FC<DefaultTdBoxProps> = ({ text }: DefaultTdBoxProps) => {
  return (
    <Box width="100%" height="100%" padding={13} textAlign="left">
      <LineBreakReplaced text={text} />
    </Box>
  );
};

export default DefaultTdBox;

import React, { FC, useContext } from 'react';
import MenuSidebar from '../../../organisms/sidebar/MenuSidebar';
import HeaderDashboard from '../../../organisms/header/HeaderDashboard';
import CopyRightFooter from '../../../organisms/footer/CopyRightFooter';
import DashboardTemplateLayout from './DashboardTemplateLayout';
import HTMLElementsAdjustContext from '../../../../enhancers/screen/HTMLElementsAdjustProvider/HTMLElementsAdjustContext';

export interface DashboardTemplateProps {
  children: React.ReactNode;
}

const DashboardTemplate: FC<DashboardTemplateProps> = ({
  children,
}: DashboardTemplateProps) => {
  const { element1, heightLonger } = useContext(HTMLElementsAdjustContext);

  return (
    <DashboardTemplateLayout
      content={children}
      divRef={element1.ref}
      containerHeight={heightLonger}
    >
      <MenuSidebar key="menu" />
      <HeaderDashboard key="header" />
      <CopyRightFooter key="footer" />
    </DashboardTemplateLayout>
  );
};

export default DashboardTemplate;

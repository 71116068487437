// IService
import INotificationLogQueryService from '../../useCases/notification/INotificationLogQueryService';

// IRepository
import { INotificationLogQueryRepository } from '../../domain/notification/notificationLog/INotificationLogRepository';

class NotificationLogQueryService implements INotificationLogQueryService {
  private notificationLogQueryRepository: INotificationLogQueryRepository;

  constructor(notificationLogQueryRepository: INotificationLogQueryRepository) {
    this.notificationLogQueryRepository = notificationLogQueryRepository;
  }

  public findCustodyFeeAndTopUpFeeById = async (id: string) => {
    const notificationLog = await this.notificationLogQueryRepository.findCustodyFeeAndTopUpFeeById(
      id,
    );

    return notificationLog;
  };
}

export default NotificationLogQueryService;

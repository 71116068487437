import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UserType } from '../../../../../types/User.type';

// Style
import {
  SelectedTitleStyle,
  UnSelectedTitleStyle,
  SelectedDescriptionStyle,
  UnSelectedDescriptionStyle,
} from './style';

// Component
import { TextPrimary, TextSecondary } from '../../../../atoms/text2/Text2';
import UserTypeIcon from '../../../../atoms/image/UserTypeIcon';
import LineBreakReplaced from '../../../../atoms/text2/LineBreakReplaced';
import UserTypeCardLayout from './UserTypeCardLayout';

const ORGANISM_ID = `organisms.card.UserTypeCard`;

export interface UserTypeCardProps {
  type: UserType;
  isSelected: boolean;
  onClick: (type: UserType) => void;
}

const UserTypeCard: FC<UserTypeCardProps> = ({
  type,
  isSelected,
  onClick,
}: UserTypeCardProps) => {
  const { t } = useTranslation();

  const [titleTheme, descriptionTheme] = isSelected
    ? [SelectedTitleStyle, SelectedDescriptionStyle]
    : [UnSelectedTitleStyle, UnSelectedDescriptionStyle];

  return (
    <UserTypeCardLayout onClick={() => onClick(type)} isSelected={isSelected}>
      <UserTypeIcon
        key="icon"
        type={type}
        isSelected={isSelected}
        height={94}
        width={94}
      />
      <TextPrimary key="title" theme={titleTheme}>
        {t(`userType.${type}`)}
      </TextPrimary>
      <TextSecondary key="description" theme={descriptionTheme}>
        <LineBreakReplaced text={t(`${ORGANISM_ID}.description.${type}`)} />
      </TextSecondary>
    </UserTypeCardLayout>
  );
};

export default UserTypeCard;

// React
import { useState, useCallback } from 'react';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';
import { executeWithTimeout } from '../../../../utils/helpers/timeout';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';
import { REQUEST_TIMEOUT } from '../../../../config/constants/business';

// UseCase
import SignUpUseCase from '../../../../useCases/authentication/signUp/SignUpUseCase';

// Repository
import SignUpRequestFunctions from '../../../../repository/userRequest/signUpRequest/SignUpRequestFunctions';

export const useSignUpUseCase = () => {
  const [state, setState] = useState<string>(INITIAL);
  const [email, setEmail] = useState<string>();
  const [invitationCode, setInvitationCode] = useState<string>();

  /**
   *
   *  Repository
   *
   */
  const signUpRequestRepository = new SignUpRequestFunctions();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new SignUpUseCase(signUpRequestRepository);

  /**
   *
   *  Method
   *
   */
  const signUp = useCallback(
    async (
      formInput: {
        email: string;
        invitationCode: string;
      },
      isResend = false,
    ) => {
      if (!isResend) setState(IS_LOADING);

      try {
        // ---check onLine ---- //
        checkInternetConnection();

        // SetValue
        setEmail(formInput.email);
        setInvitationCode(formInput.invitationCode);

        // UseCase.signUp
        await executeWithTimeout(
          useCase.signUp(formInput.email, formInput.invitationCode),
          REQUEST_TIMEOUT,
        );

        if (!isResend) setState(SUCCESS);
      } catch (error) {
        const handler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [],
  );

  const resendSignUpComplete = useCallback(async () => {
    if (!email || !invitationCode) return;

    await signUp({ email, invitationCode }, true);
  }, [signUp, email, invitationCode]);

  return {
    state,
    setState,
    signUp,
    email,
    invitationCode,
    resendSignUpComplete,
  };
};

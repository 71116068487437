// IUseCase
import IPersonalInformationViewUseCase from '../../../enhancers/useCase/verification/kycRequest/PersonalInformationViewProvider/IPersonalInformationViewUseCase';

// IService
import IKycRequestQueryService from './IKycRequestQueryService';

// Service
import KycRequestQueryService from '../../../service/verification/kycRequest/KycRequestQueryService';

// IRepository
import { IKycRequestQueryRepository } from '../../../domain/verification/kycRequest/KycRequest/IKycRequestRepository';

// Domain
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class PersonalInformationViewUseCase
  implements IPersonalInformationViewUseCase {
  private kycRequestQueryService: IKycRequestQueryService;

  constructor(kycRequestQueryRepository: IKycRequestQueryRepository) {
    this.kycRequestQueryService = new KycRequestQueryService(
      kycRequestQueryRepository,
    );
  }

  public open = async (user: UserAuthorized) => {
    const id = user.getId();

    const kycRequest = await this.kycRequestQueryService.findById(id);

    return { kycRequest };
  };
}

export default PersonalInformationViewUseCase;

import React, { FC } from 'react';
import reactStringReplace from 'react-string-replace';
import { TextSecondary, TextThemeProps } from '../../../atoms/text2/Text2';
import { LinkTextSecondary } from '../../../atoms/text2/LinkText';

export interface LinkTextSentenceProps {
  text: string;
  urlList: Array<{
    replaceText: string;
    url: string;
    target?: string;
    onClick?: () => void;
    onMouseOver?: () => void;
    onMouseLeave?: () => void;
  }>;
  theme: {
    text: TextThemeProps;
    link: TextThemeProps;
  };
}

const LinkTextSentence: FC<LinkTextSentenceProps> = ({
  text,
  urlList,
  theme,
}: LinkTextSentenceProps) => {
  let items: string | React.ReactNodeArray = text;

  // eslint-disable-next-line no-restricted-syntax
  for (const replace of urlList) {
    items = reactStringReplace(items, replace.replaceText, (match) => {
      return (
        <LinkTextSecondary
          theme={theme.link}
          href={`${replace.url}`}
          target={replace.target ? replace.target : ''}
          onClick={replace.onClick}
          onMouseOver={replace.onMouseOver}
          onMouseLeave={replace.onMouseLeave}
        >
          {match}
        </LinkTextSecondary>
      );
    });
  }

  return (
    <>
      {(items as React.ReactNodeArray).map((item, i) => {
        if (typeof item === 'string') {
          if (urlList.length === 1)
            return (
              <span key={`match${i}`}>
                <TextSecondary theme={theme.text}>{item}</TextSecondary>
              </span>
            );

          return (
            <p key={`match${i}`}>
              &nbsp;<TextSecondary theme={theme.text}>{item}</TextSecondary>
              &nbsp;
            </p>
          );
        }
        return (
          <span
            style={{
              ...theme.link,
              fontSize: `${Number(theme.link.fontSize) / 16}rem`,
              marginTop: `${2 / 16}rem`,
            }}
            key={`match${i}`}
          >
            {item}
          </span>
        );
      })}
    </>
  );
};
export default LinkTextSentence;

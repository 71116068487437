// IService
import IDocumentTypeQueryService from '../../../useCases/settings/documentType/IDocumentTypeQueryService';

// IRepository
import { IDocumentTypeQueryRepository } from '../../../domain/settings/documentType/DocumentType/IDocumentTypeRepository';

class DocumentTypeQueryService implements IDocumentTypeQueryService {
  private documentTypeQueryRepository: IDocumentTypeQueryRepository;

  constructor(documentTypeQueryRepository: IDocumentTypeQueryRepository) {
    this.documentTypeQueryRepository = documentTypeQueryRepository;
  }

  public findCorporateAll = async () => {
    const documentTypes = await this.documentTypeQueryRepository.findCorporateAll();

    return documentTypes;
  };

  public findIndividualAll = async () => {
    const documentTypes = await this.documentTypeQueryRepository.findIndividualAll();

    return documentTypes;
  };
}

export default DocumentTypeQueryService;

import { useState } from 'react';
import { ReferralResultOptionType } from './ReferralResultTabBar.type';

export const useReferralResultTabBarOption = () => {
  const [referralResultOption, setReferralResultOption] = useState<
    ReferralResultOptionType
  >(ReferralResultOptionType.contractTimeDeposit);

  return {
    referralResultOption,
    setReferralResultOption,
  };
};

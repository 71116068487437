/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Constant
import { INITIAL } from '../../../../../../../config/constants/requestState';

export interface DebitCardRequestCherryVisa01ProofOfReceiptUpdateContextProps {
  state: string;
  submitProofOfReceipt: (callback?: () => void) => void;
}

const DebitCardRequestCherryVisa01ProofOfReceiptUpdateContext = React.createContext<
  DebitCardRequestCherryVisa01ProofOfReceiptUpdateContextProps
>({
  state: INITIAL,
  submitProofOfReceipt: (callback?: () => void) => undefined,
});

export default DebitCardRequestCherryVisa01ProofOfReceiptUpdateContext;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface FormInputShippingAddressLayoutProps {
  children: React.ReactElement[];
}

const FormInputShippingAddressLayout: FC<FormInputShippingAddressLayoutProps> = ({
  children,
}: FormInputShippingAddressLayoutProps) => {
  const elements = ['address', 'receiverName', 'phoneNumber'];
  const [address, receiverName, contactNumber] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn>
      {address}
      <Margin top={80} />
      <FlexBoxRow justifyContent="flex-start" width={600}>
        {receiverName}
      </FlexBoxRow>
      <Margin top={80} />
      <FlexBoxRow justifyContent="flex-start" width={767}>
        {contactNumber}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};

export default FormInputShippingAddressLayout;

export enum UserRequestStatusType {
  unconfirmed = 'unconfirmed',
  confirmed = 'confirmed',
}

export enum ResignUpRequestStatusType {
  initial = 'initial',
  unconfirmed = 'unconfirmed',
  confirmed = 'confirmed',
}

export enum ResetAuthenticatorRequestStatusType {
  unconfirmed = 'unconfirmed',
  confirmed = 'confirmed',
}

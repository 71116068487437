import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TitleWithActionLink from './TitleWithActionLink';

export interface TitleWithBackLinkProps {
  title: string;
  onClick: () => void;
}

const TitleWithBackLink: FC<TitleWithBackLinkProps> = ({
  title,
  onClick,
}: TitleWithBackLinkProps) => {
  const { t } = useTranslation();
  return (
    <TitleWithActionLink
      title={title}
      linkText={t(`atoms.button.back`)}
      onClick={onClick}
    />
  );
};
export default TitleWithBackLink;

import { FC } from 'react';
import { IIterable } from '../../../../../interface/Iterator/IIterable';

// DomainObject
import DebitCardSpotTransactionV2 from '../DebitCardSpotTransactionV2/DebitCardSpotTransactionV2';

class DebitCardSpotTransactionV2s
  implements IIterable<DebitCardSpotTransactionV2> {
  private items: DebitCardSpotTransactionV2[] = [];

  constructor(item?: DebitCardSpotTransactionV2[]) {
    this.items = item || [];
  }

  public add = (item: DebitCardSpotTransactionV2) => {
    this.items.push(item);
  };

  public head = () => this.items[0];

  public map = <T>(callback: (item: DebitCardSpotTransactionV2) => T) => {
    return this.items.map((item) => callback(item));
  };

  public forEach = (callback: (item: DebitCardSpotTransactionV2) => void) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.items) {
      // eslint-disable-next-line no-await-in-loop
      callback(item);
    }
  };

  public forEachAsync = async (
    callback: (item: DebitCardSpotTransactionV2) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.items) {
      // eslint-disable-next-line no-await-in-loop
      await callback(item);
    }
  };

  public mapComponent = (
    component: FC<{ item: DebitCardSpotTransactionV2; i: number }>,
  ) => {
    return this.items.map((item, i) => component({ item, i }));
  };

  public sortDescByCreatedAt = () => {
    const compare = (
      a: DebitCardSpotTransactionV2,
      b: DebitCardSpotTransactionV2,
    ) => {
      const aCreatedAt = a.getProps().createdAt!;
      const bCreatedAt = b.getProps().createdAt!;

      return aCreatedAt < bCreatedAt ? 1 : -1;
    };
    return new DebitCardSpotTransactionV2s(this.items.sort(compare));
  };

  // public calculateBalanceHistory = (item: DebitCardV2) => {
  //   const { balance } = item.getProps();

  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   let currentBalance = balance;

  //   return this.items.reduce(
  //     (debitCardSpotTransactions, debitCardSpotTransaction) => {
  //       const transactionUpdated = debitCardSpotTransaction.overwriteBalance(
  //         currentBalance,
  //       );

  //       const { amount, status } = transactionUpdated.getProps();

  //       // Update Balance only if transaction is confirmed
  //       if (status === DebitCardTransactionStatusType.confirmed) {
  //         if (debitCardSpotTransaction instanceof DebitCardDepositV2)
  //           currentBalance = minusByDecimal(currentBalance, amount);
  //         else if (debitCardSpotTransaction instanceof DebitCardWithdrawalV2)
  //           currentBalance = addByDecimal(currentBalance, amount);
  //         else
  //           throw SystemErrorFactory.createByErrorId(
  //             ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
  //             {
  //               type: 'DebitCardSpotTransaction',
  //               place: 'DebitCardSpotTransaction.calculateBalanceHistory',
  //             },
  //           );
  //       }
  //       debitCardSpotTransactions.add(transactionUpdated);

  //       return debitCardSpotTransactions;
  //     },
  //     new DebitCardSpotTransactions(),
  //   );
  // };
}
export default DebitCardSpotTransactionV2s;

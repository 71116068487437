import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import { Container, BalanceContainer, ConversionContainer } from './style';

export interface AssetCryptoCurrencyCardLayoutProps {
  children: React.ReactElement[];
}

const AssetCryptoCurrencyCardLayout: FC<AssetCryptoCurrencyCardLayoutProps> = ({
  children,
}: AssetCryptoCurrencyCardLayoutProps) => {
  const elements = [
    'currency',
    'balanceLabel',
    'balance',
    'conversionLabel',
    'conversion',
  ];

  const [
    currency,
    balanceLabel,
    balance,
    conversionLabel,
    conversion,
  ] = getElementsFromKeys(children, elements);

  return (
    <Container>
      <div>{currency}</div>
      <div style={{ marginLeft: `${93 / 16}rem` }}>
        <BalanceContainer>
          {balanceLabel}
          {balance}
        </BalanceContainer>
      </div>
      <div style={{ marginLeft: `${63 / 16}rem` }}>
        <ConversionContainer>
          {conversionLabel}
          {conversion}
        </ConversionContainer>
      </div>
    </Container>
  );
};

export default AssetCryptoCurrencyCardLayout;

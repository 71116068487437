import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  DocumentTypeProps,
  DocumentTypePropsFormat,
} from './DocumentType.type';

// DomainObject
import DocumentType from './DocumentType';

class DocumentTypeFactory {
  static create = (props: DocumentTypeProps) => {
    const result = Joi.object(DocumentTypePropsFormat).validate(props);

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: DocumentType.name,
          reason: result.error.details[0].message,
        },
      );

    return new DocumentType(props);
  };
}

export default DocumentTypeFactory;

import { TypeOfT } from '../../../../types/typeof/Translation.type';
import {
  required,
  minLength,
  includeLowercase,
  includeUppercase,
  includeNumber,
  includeSpecialCharacter,
  passwordEqualToConfirm,
} from '../validator';

const passwordEditCurrentPasswordRegister = (t: TypeOfT) => ({
  required: required('Current Password', t),
});

const passwordEditNewPasswordRegister = (t: TypeOfT) => {
  return {
    required: required('New Password', t),
    minLength: minLength('New Password', 10, t),
    validate: {
      includeLowercase: includeLowercase('New Password', t),
      includeUppercase: includeUppercase('New Password', t),
      includeNumber: includeNumber('New Password', t),
      includeSpecialCharacter: includeSpecialCharacter('Password', t),
    },
  };
};

const passwordEditConfirmPasswordRegister = (t: TypeOfT, password: string) => {
  return {
    validate: {
      passwordEqualToConfirm: passwordEqualToConfirm(password, t),
    },
  };
};

export {
  passwordEditCurrentPasswordRegister,
  passwordEditNewPasswordRegister,
  passwordEditConfirmPasswordRegister,
};

import React, { FC, useContext } from 'react';
import { useKycStatusViewUseCase } from './useKycStatusViewUseCase';
import KycRequestContext from '../../../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequestContext';
import ConsentFormContext from '../../../../../domain/verification/consentForm/ConsentForm/ConsentFormContext';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

// Context
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

export type KycStatusViewProviderProps = {
  children: React.ReactNode;
};

const KycStatusViewProvider: FC<KycStatusViewProviderProps> = ({
  children,
}: KycStatusViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const { state, setState, kycRequest, consentForm } = useKycStatusViewUseCase(
    userAuthorized,
  );

  return (
    <KycRequestContext.Provider value={{ kycRequest }}>
      <ConsentFormContext.Provider value={{ consentForm }}>
        <SystemErrorHandler state={state} setState={setState}>
          {children}
        </SystemErrorHandler>
      </ConsentFormContext.Provider>
    </KycRequestContext.Provider>
  );
};

export default KycStatusViewProvider;

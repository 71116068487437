import React, { FC, useContext } from 'react';
import TransferSavingsAccountTransactionContext from '../../../../../domain/transaction/transferSavingsAccountTransaction/TransferSavingsAccountTransaction/TransferSavingsAccountTransaction/TransferSavingsAccountTransactionContext';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

// Context
import UserVerifiedContext from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedContext';
import TransferSavingsAccountCreateContext from './TransferSavingsAccountCreateContext';
import RequestStateContext from '../../../../../value/view/RequestState/RequestStateContext';

// Types
import { FormInputTransferSavingsAccountContext } from '../../../../../components/organisms/form/transfer/savingsAccount/FormTransferSavingsAccount/FormInputTransferSavingsAccount.type';

// DomainObject
import TransferSavingsAccountTransactionSeedContext from '../../../../../domain/transaction/transferSavingsAccountTransaction/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeedContext';

// Hook
import { useTransferSavingsAccountCreateUseCase } from './useTransferSavingsAccountCreateUseCase';

export type TransferSavingsAccountCreateProviderProps = {
  children: React.ReactNode;
};

const TransferSavingsAccountCreateProvider: FC<TransferSavingsAccountCreateProviderProps> = ({
  children,
}: TransferSavingsAccountCreateProviderProps) => {
  const { user } = useContext(UserVerifiedContext);

  const {
    state,
    setState,
    createTransferSavingsAccount,
    executeCreateTransferSavingsAccount,
    transferSavingsAccountTransactionSeed,
    transferSavingsAccountTransaction,
    formInputTransferSavingsAccountContext,
  } = useTransferSavingsAccountCreateUseCase(user);

  return (
    <FormInputTransferSavingsAccountContext.Provider
      value={formInputTransferSavingsAccountContext}
    >
      <RequestStateContext.Provider value={{ requestState: state }}>
        <TransferSavingsAccountTransactionContext.Provider
          value={{ transferSavingsAccountTransaction }}
        >
          <TransferSavingsAccountTransactionSeedContext.Provider
            value={{ transferSavingsAccountTransactionSeed }}
          >
            <TransferSavingsAccountCreateContext.Provider
              value={{
                requestState: state,
                createTransferSavingsAccount,
                executeCreateTransferSavingsAccount,
              }}
            >
              <SystemErrorHandler state={state} setState={setState}>
                {children}
              </SystemErrorHandler>
            </TransferSavingsAccountCreateContext.Provider>
          </TransferSavingsAccountTransactionSeedContext.Provider>
        </TransferSavingsAccountTransactionContext.Provider>
      </RequestStateContext.Provider>
    </FormInputTransferSavingsAccountContext.Provider>
  );
};
export default TransferSavingsAccountCreateProvider;

import React, { FC } from 'react';

// Style
import { TitleStyle, IconProps } from './style';

// LayoutComponent
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';

// Component
import { TextPrimary } from '../../../atoms/text2/Text2';
import CheckPointItem from '../../../molecules/item/CheckPointItem';

export interface CheckPointListProps {
  title: string;
  checkPoints: Array<string>;
}

const CheckPointList: FC<CheckPointListProps> = ({
  title,
  checkPoints,
}: CheckPointListProps) => {
  return (
    <FlexBoxColumn>
      <TextPrimary theme={TitleStyle}>{title}</TextPrimary>
      {checkPoints.map((checkPoint, i) => {
        return (
          <div key={`item${i}`} style={{ marginTop: `${14 / 16}rem` }}>
            <CheckPointItem
              text={checkPoint}
              marginBetween={11}
              iconProps={IconProps}
            />
          </div>
        );
      })}
    </FlexBoxColumn>
  );
};

export default CheckPointList;

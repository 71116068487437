import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { FlexColumn, FlexRow } from '../../../atoms/utils/layout/Flex';
import Margin from '../../../atoms/utils/layout/Margin';

export interface LabeledNameLayoutProps {
  children: React.ReactElement[];
  direction: 'row' | 'column';
  marginBetween: string | number;
}

const LabeledNameLayout: FC<LabeledNameLayoutProps> = ({
  children,
  direction,
  marginBetween,
}: LabeledNameLayoutProps) => {
  const elements = ['label', 'name'];
  const [label, name] = getElementsFromKeys(children, elements);

  if (direction === 'row')
    return (
      <FlexRow alignItems="center">
        {label}
        <Margin left={marginBetween}>{name}</Margin>
      </FlexRow>
    );

  return (
    <FlexColumn>
      {label}
      <Margin top={marginBetween}>{name}</Margin>
    </FlexColumn>
  );
};
export default LabeledNameLayout;

import { useState } from 'react';
import SavingsAccount from './SavingsAccount';

export const useSavingsAccount = (defaultValue?: SavingsAccount) => {
  const [savingsAccount, setSavingsAccount] = useState<
    SavingsAccount | undefined
  >(defaultValue);

  return {
    savingsAccount,
    setSavingsAccount,
  };
};

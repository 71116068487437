import { useState, useCallback } from 'react';

// Type
import { NotificationLogType } from '../../../../types/Notification.type';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import NotificationLogCreateReadLogUseCase from '../../../../useCases/notification/NotificationLogCreateReadLogUseCase';

// Repository
import NotificationLogFirestore from '../../../../repository/notification/NotificationLogFirestore';

// DomainObject
import UserAuthorized from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useNotificationCreateReadLogUseCase = (
  userAuthorized: UserAuthorized | undefined,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /**
   *
   *  Repository
   *
   */
  const notificationLogRepository = new NotificationLogFirestore();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new NotificationLogCreateReadLogUseCase(
    notificationLogRepository,
  );

  /**
   *
   *  Method
   *
   */

  const createReadLog = useCallback(
    async (type: NotificationLogType, callback?: () => void) => {
      if (!userAuthorized) return;

      setState(IS_LOADING);

      try {
        await useCase.executeCreateReadLog(userAuthorized, type);

        if (callback) callback();

        setState(SUCCESS);
      } catch (error) {
        const handler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [userAuthorized],
  );

  return {
    state,
    setState,
    createReadLog,
  };
};

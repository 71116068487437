import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';
import Margin from '../../../../../atoms/utils/layout/Margin';
import { FlexRow } from '../../../../../atoms/utils/layout/Flex';

export interface GoogleAuthenticatorDownloadLayoutProps {
  children: React.ReactElement[];
}

const GoogleAuthenticatorDownloadLayout: FC<GoogleAuthenticatorDownloadLayoutProps> = ({
  children,
}: GoogleAuthenticatorDownloadLayoutProps) => {
  const elements = ['badge1', 'badge2'];
  const [badge1, badge2] = getElementsFromKeys(children, elements);

  return (
    <FlexRow>
      {badge1}
      <Margin left={40}>{badge2}</Margin>
    </FlexRow>
  );
};

export default GoogleAuthenticatorDownloadLayout;

import React, { FC, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

// Context
import TransferDebitCardCreateContext from '../../../../../../enhancers/useCase/transfer/debitCard/TransferDebitCardCreateProvider/TransferDebitCardCreateContext';
import TransferDebitCardTransactionV2SeedContext from '../../../../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2Seed/TransferDebitCardTransactionV2SeedContext';
import TransferDebitCardPageStateContext from '../../../../../../enhancers/pageState/transfer/TransferDebitCardPageState/TransferDebitCardPageStateContext';

import { FormInputAuthenticatorType } from '../../../../../molecules/form/formInput/textField/FormInputAuthenticator/FormInputAuthenticator.type';

// Constant
import { IS_LOADING } from '../../../../../../config/constants/requestState';

// Components
import FormInputAuthenticator from '../../../../../molecules/form/formInput/textField/FormInputAuthenticator';
import TransferDebitCardConfirmButtonGroup from '../../../../../molecules/button/ButtonGroup/TransferSavingsAccountConfirmButtonGroup';
import FormTransferDebitCardLayout from './FormTransferDebitCardLayout';

const FormTransferDebitCardConfirm: FC = () => {
  // PageState
  const { setPageState } = useContext(TransferDebitCardPageStateContext);

  // Execute Create
  const { requestState, executeCreateTransferDebitCard } = useContext(
    TransferDebitCardCreateContext,
  );

  // DomainObject
  const { transferDebitCardTransactionSeed } = useContext(
    TransferDebitCardTransactionV2SeedContext,
  );

  /* *
   *
   *  Form
   *
   * */
  const methods = useForm<FormInputAuthenticatorType>({
    mode: 'onChange',
  });

  const {
    formState: { isValid },
    watch,
  } = methods;

  if (!transferDebitCardTransactionSeed) return <></>;

  return (
    <FormProvider {...methods}>
      <FormTransferDebitCardLayout>
        <FormInputAuthenticator key="twoFactorAuth" state={requestState} />
        <TransferDebitCardConfirmButtonGroup
          key="buttonGroup"
          onClickLeft={() => {
            executeCreateTransferDebitCard(watch('code'), () => {
              setPageState('complete');
            });
          }}
          leftButtonDisabled={!isValid}
          isLoading={requestState === IS_LOADING}
          onClickRight={() => {
            setPageState('initial');
          }}
        />
      </FormTransferDebitCardLayout>
    </FormProvider>
  );
};

export default FormTransferDebitCardConfirm;

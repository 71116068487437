import React from 'react';

// Constant
import { INITIAL } from 'config/constants/requestState';

export interface TransferDebitCardCancelContextProps {
  state: string;

  cancelTopUp: (id: string, callback?: () => void) => void;
}

const TransferDebitCardCancelContext = React.createContext<
  TransferDebitCardCancelContextProps
>({
  state: INITIAL,

  cancelTopUp: () => undefined,
});

export default TransferDebitCardCancelContext;

enum ErrorCodeType {
  // General
  CLIENT_ERROR = '400',
  UNAUTHORIZED_ERROR = '401',
  REQUEST_TIMEOUT_ERROR = '408',
  SERVER_ERROR = '500',

  // 40x-07xx ContractTimeDeposit
  CONTRACT_TIME_DEPOSIT_FORBIDDEN_READ_DEPOSIT_INFO = '400-0701',
  CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_ALREADY_EXISTS = '400-0702',
  CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_NOT_EXISTS = '403-0703',

  // 50x-07xx TimeDeposit
  TIME_DEPOSIT_PRODUCT_NO_MATCH_PRODUCT = '500-0701',

  // AUTHENTICATION_USER_ID_NOT_MATCH = '401-0103',
  // AUTHENTICATION_TOKEN_NOT_FOUND = '401-0104',
  // AUTHENTICATION_USER_ALREADY_DELETED = '401-0105',
  // AUTHENTICATION_USER_INVALID_ROLE = '401-0106',
  // AUTHENTICATION_USER_NOT_FOUND = '401-0107',

  // // 40x-03xx COURSE
  // COURSE_INVALID_COURSE_CODE = '400-0301',
  // COURSE_INVALID_LECTURE_CODE = '400-0302',
  // COURSE_INVALID_MY_COURSE_CODE = '400-0303',

  // // Axios
  // AXIOS_CONNECTION_TIMEOUT = 'ECONNABORTED',

  // // Firebase Auth
  // FIREBASE_AUTH_WRONG_PASSWORD = 'auth/wrong-password',
  // FIREBASE_AUTH_NETWORK_FAILED = 'auth/network-request-failed',
  // FIREBASE_AUTH_USER_NOT_FOUND = 'auth/user-not-found',

  // // 40x-04xx Settings
  // SETTINGS_EMAIL_UPDATE_ALREADY_IN_USE = '400-0400',
  // SETTINGS_EMAIL_UPDATE_EXPIRED = '401-0401',
  // SETTINGS_EMAIL_UPDATE_INVALID_OTP = '400-0402',

  // // Reset Password
  // RESET_PASSWORD_UNAUTHORIZED = 'reset_password/unauthorized',
}

export { ErrorCodeType };

import React, { FC } from 'react';
import { MenuType } from '../../../../config/menu/main/Menu.type';
import { SavingsAccountMenuType } from '../../../../config/menu/sub/SavingsAccountMenu.type';
import MenuItemWithSub from '../MenuItemWithSub';

const MAIN_MENU_TYPE = MenuType.savingsAccounts;

const SavingsAccountMenu: FC = () => {
  const savingsAccountMenuItems = Object.keys(SavingsAccountMenuType);

  return (
    <MenuItemWithSub
      key={MAIN_MENU_TYPE}
      type={MAIN_MENU_TYPE}
      menuItems={savingsAccountMenuItems}
    />
  );
};

export default SavingsAccountMenu;

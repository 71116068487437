import React, { FC, useContext } from 'react';
import { FormInputEmailEditContext } from '../../../components/organisms/form/settings/FormEmailEdit/FormEmailEdit.type';
import { useUpdateEmail } from './useUpdateEmail';
import UserAuthorizedContext from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import UpdateEmailContext from './UpdateEmailContext';
import SystemErrorHandler from '../../errorHandler/SystemErrorHandler';

export type UpdateEmailProviderProps = {
  children: React.ReactNode;
};

const UpdateEmailProvider: FC<UpdateEmailProviderProps> = ({
  children,
}: UpdateEmailProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const id = userAuthorized?.getId() || '';
  const {
    state,
    setState,
    updateEmail,
    newEmail,
    formInputEmailEditContext,
  } = useUpdateEmail(id);

  return (
    <FormInputEmailEditContext.Provider value={formInputEmailEditContext}>
      <UpdateEmailContext.Provider
        value={{
          updateEmailState: state,
          setUpdateEmailState: setState,
          updateEmail,
          newEmail,
        }}
      >
        <SystemErrorHandler state={state} setState={setState}>
          {children}
        </SystemErrorHandler>
      </UpdateEmailContext.Provider>
    </FormInputEmailEditContext.Provider>
  );
};
export default UpdateEmailProvider;

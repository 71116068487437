import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';

// Error
import { ErrorCodeType } from 'utils/errors/ErrorHandler/ErrorCode.type';

// Type
import {
  FormInputTransferSavingsAccount,
  FormInputTransferSavingsAccountContext,
} from 'components/organisms/form/transfer/savingsAccount/FormTransferSavingsAccount/FormInputTransferSavingsAccount.type';
import { TransferSavingsAccountType } from 'types/Transfer.type';

// Context
import FeeTransferSavingsAccountContext from 'domain/settings/transactionFee/FeeTransferSavingsAccount/FeeTransferSavingsAccountContext';
import SavingsAccountsContext from 'domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccountsContext';
import TransferSavingsAccountCreateContext from 'enhancers/useCase/transfer/savingsAccount/TransferSavingsAccountCreateProvider/TransferSavingsAccountCreateContext';
import TransferSavingsAccountPageStateContext from 'enhancers/pageState/transfer/TransferSavingsAccountPageState/TransferSavingsAccountPageStateContext';
import TransferSavingsAccountTypeOptionContext from 'components/molecules/selectBox/option/option/TransferSavingsAccountTypeOption/TransferSavingsAccountTypeOptionContext';

// Constant
import { IS_LOADING } from 'config/constants/requestState';

// Style
import { LabelStyle } from 'components/organisms/form/transfer/savingsAccount/FormTransferSavingsAccount/style';

// Components
import {
  ConfirmButton,
  DefaultButtonTextStyle,
} from 'components/atoms/button/Button2';
import { TextPrimary } from 'components/atoms/text2/Text2';
import FormInputSavingsAccountSelectBox from 'components/molecules/form/formInput/selectBox/bankAccount/FormInputSavingsAccountSelectBox';
import FormInputTransferSavingsAccountType from 'components/molecules/form/formInput/selectBox/transfer/FormInputTransferSavingsAccountType';
import FormInputTransferSavingsAccountDetail from 'components/molecules/form/formInputGroup/FormInputTransferSavingsAccountDetail/FormInputTransferSavingsAccountDetail';
import FormTransferSavingsAccountLayout from 'components/organisms/form/transfer/savingsAccount/FormTransferSavingsAccount/FormTransferSavingsAccountLayout';

const FORM_ID = `organisms.form.transfer.FormTransferSavingsAccount`;

const CLIENT_ERROR_CODES = [
  ErrorCodeType.SAVINGS_ACCOUNT_ACCOUNT_HOLDER_WITH_NO_CONTRACT_NAME,
  ErrorCodeType.SAVINGS_ACCOUNT_INVALID_ACCOUNT_NUMBER_ECB_BY_CLIENT,
];

const FormTransferSavingsAccount: FC = () => {
  const { t } = useTranslation();
  const { formInput, setFormInput } = useContext(
    FormInputTransferSavingsAccountContext,
  );

  // Page State
  const { pageState, setPageState } = useContext(
    TransferSavingsAccountPageStateContext,
  );

  // Method
  const { requestState, createTransferSavingsAccount } = useContext(
    TransferSavingsAccountCreateContext,
  );

  // Fee
  const { feeTransferSavingsAccount } = useContext(
    FeeTransferSavingsAccountContext,
  );

  // Options
  const { savingsAccounts } = useContext(SavingsAccountsContext);
  const { setTransferSavingsAccountTypeOption } = useContext(
    TransferSavingsAccountTypeOptionContext,
  );

  /* *
   *
   *  Form
   *
   * */
  const methods = useForm<FormInputTransferSavingsAccount>({
    mode: 'onChange',
  });

  const {
    formState: { isValid },
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
    trigger,
  } = methods;

  /* *
   *
   *  Domain Object
   *
   * */
  const savingsAccount = savingsAccounts.filterById(
    watch(`savingsAccountNumber`),
  );

  /* *
   *
   *  Default Variable
   *
   * */

  // Set DefaultValue
  useEffect(() => {
    if (formInput) reset(formInput);
    // eslint-disable-next-line
  }, [formInput, pageState]);

  // SavingsAccount
  useEffect(() => {
    if (!formInput)
      setValue(
        `savingsAccountNumber`,
        savingsAccounts.head()?.getRawAccountNumber(),
      );
    // eslint-disable-next-line
  }, [savingsAccounts]);

  // TransferSavingsAccountType
  useEffect(() => {
    if (formInput) return;
    const defaultTransferSavingsAccountType = TransferSavingsAccountType.forbes;

    setValue(`transferSavingsAccountType`, defaultTransferSavingsAccountType);
    setValue(
      'bankName',
      t(`${FORM_ID}.bankName.${defaultTransferSavingsAccountType}`) as string,
    );

    setTransferSavingsAccountTypeOption(defaultTransferSavingsAccountType);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (watch('beneficiaryAccountNumber')) trigger('beneficiaryAccountNumber');
    // eslint-disable-next-line
  }, [watch('transferSavingsAccountType'), watch('beneficiaryAccountNumber')]);

  // Fee
  useEffect(() => {
    setValue('fee', feeTransferSavingsAccount?.getProps().fee);
  }, [feeTransferSavingsAccount, setValue]);

  /**
   *
   *  Client Error
   *
   */

  // Client Error (Invalid Beneficiary Account Number)
  useEffect(() => {
    if (CLIENT_ERROR_CODES.includes(requestState as ErrorCodeType))
      setError('beneficiaryAccountNumber', {
        type: '',
        message: t(`validation.${requestState}`),
      });
  }, [requestState, setError, t]);

  /**
   *
   *  Event
   *
   */
  const onSubmit = useCallback(
    async (input: FormInputTransferSavingsAccount) => {
      setFormInput(input);

      if (savingsAccount) {
        createTransferSavingsAccount(input, savingsAccount, () => {
          setPageState('confirm');
        });
      }
    },
    // eslint-disable-next-line
    [savingsAccount],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <FormTransferSavingsAccountLayout>
          <FormInputSavingsAccountSelectBox
            key="savingsAccount"
            name="savingsAccountNumber"
            inputWidth={600}
            labelProps={{
              label: t(`${FORM_ID}.label.savingsAccount`),
              theme: LabelStyle,
            }}
          />
          <FormInputTransferSavingsAccountType
            key="transferType"
            name="transferSavingsAccountType"
            inputWidth={599}
            labelProps={{
              label: t(`${FORM_ID}.label.transferType`),
              theme: LabelStyle,
            }}
            onSelect={(transferType) => {
              setValue(
                'bankName',
                t(`${FORM_ID}.bankName.${transferType}`) as string,
              );

              setTransferSavingsAccountTypeOption(
                transferType as TransferSavingsAccountType,
              );

              if (watch('beneficiaryAccountNumber'))
                trigger(['beneficiaryAccountNumber']);
            }}
          />
          <FormInputTransferSavingsAccountDetail
            key="detail"
            savingsAccount={savingsAccount}
          />
          <ConfirmButton
            key="button"
            width={'100%'}
            height={75}
            isLoading={requestState === IS_LOADING}
            disabled={!isValid}
          >
            <TextPrimary theme={DefaultButtonTextStyle}>
              {t(`atoms.button.confirm`)}
            </TextPrimary>
          </ConfirmButton>
        </FormTransferSavingsAccountLayout>
      </FormProvider>
    </form>
  );
};

export default React.memo(FormTransferSavingsAccount);

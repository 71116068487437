import React from 'react';
import KycRequest from './KycRequest';

export interface KycRequestContextProps {
  kycRequest?: KycRequest;
}

const KycRequestContext = React.createContext<KycRequestContextProps>({
  kycRequest: undefined,
});

export default KycRequestContext;

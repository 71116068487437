import firestore from '../../../client/firestoreClient';
import Collection from '../../collection/Collection';

// Constant
import { FIRESTORE_DATA_VERSION } from '../../../../../../config/constants/firebase';
import {
  FirestoreDomain,
  CollectionName,
} from '../../collection/Collection.type';

class InvitersCollection extends Collection {
  collection: firestore.CollectionReference;

  constructor() {
    super();
    this.collection = firestore()
      .collection(FirestoreDomain.invite)
      .doc(FIRESTORE_DATA_VERSION)
      .collection(CollectionName.users);
  }
}

export default InvitersCollection;

import { useState } from 'react';

export type ReregistrationPageState = 'initial' | 'twoFactorAuth' | 'complete';

export const useReregistrationPageState = () => {
  const [pageState, setPageState] = useState<ReregistrationPageState>(
    'initial',
  );

  return {
    pageState,
    setPageState,
  };
};

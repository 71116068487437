// Error
// import ErrorFactory from '../../../utils/errors/ErrorFactory';
// import { ErrorCodeType } from '../../../utils/errors/ErrorHandler/ErrorCode.type';

// Type
// import { TypeOfT } from '../../../types/typeof/Translation.type';

// IService
import IResetAuthenticatorRequestQueryService from '../../../useCases/userRequest/resetAuthenticatorRequest/IResetAuthenticatorRequestQueryService';

// IRepository
import IResetAuthenticatorRequestRepository from '../../../domain/userRequest/resetAuthenticatorRequest/IResetAuthenticatorRequestRepository';

// Error Handler
// import InvalidDataFoundError from '../../../utils/errors/InvalidDataFoundError';

class ResetAuthenticatorRequestQueryService
  implements IResetAuthenticatorRequestQueryService {
  private resetAuthenticatorRequestQueryRepository: IResetAuthenticatorRequestRepository;

  constructor(
    resetAuthenticatorRequestRepository: IResetAuthenticatorRequestRepository,
  ) {
    this.resetAuthenticatorRequestQueryRepository = resetAuthenticatorRequestRepository;
  }

  public findByOobCode = async (
    oobCode: string,
    // t: TypeOfT
  ) => {
    // try {
    const resetAuthenticatorRequest = await this.resetAuthenticatorRequestQueryRepository.findByPrimaryKey(
      {
        oobCode,
      },
    );

    return resetAuthenticatorRequest;

    //   if (resetAuthenticatorRequest.isExpired()) {
    //     throw ErrorFactory.create(
    //       ErrorCodeType.AUTHENTICATION_SIGN_UP_REQUEST_NOT_FOUND,
    //       t(
    //         `validation.${ErrorCodeType.AUTHENTICATION_SIGN_UP_REQUEST_NOT_FOUND}`,
    //       ),
    //     );
    //   }

    //   return resetAuthenticatorRequest;
    // } catch (error) {
    //   if (error instanceof InvalidDataFoundError)
    //     throw ErrorFactory.create(
    //       ErrorCodeType.AUTHENTICATION_SIGN_UP_REQUEST_NOT_FOUND,
    //       t(
    //         `validation.${ErrorCodeType.AUTHENTICATION_SIGN_UP_REQUEST_NOT_FOUND}`,
    //       ),
    //     );
    //   throw error;
    // }
  };
}

export default ResetAuthenticatorRequestQueryService;

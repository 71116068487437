import {
  enoughBalance,
  min,
  isNotMySavingsAccountNumber,
  required,
  accountNumberFormatByBank,
} from 'utils/validation/registers/validator';

// Type
import { TypeOfT } from 'types/typeof/Translation.type';
import { TransferSavingsAccountType } from 'types/Transfer.type';

const KEY_BENEFICIARY_ACCOUNT_NUMBER = `Beneficiary Account Number`;
const KEY_PURPOSE_OF_TRANSFER = `Purpose of Transfer`;

export const transferForbesSavingsAccountValidation = (t: TypeOfT) => ({
  bankName: () => ({}),
  beneficiaryAccountNumber: (
    mySavingsAccountNumber: string,
    bank: TransferSavingsAccountType,
  ) => ({
    required: required(KEY_BENEFICIARY_ACCOUNT_NUMBER, t),
    validate: {
      isNotMySavingsAccountNumber: isNotMySavingsAccountNumber(
        mySavingsAccountNumber,
        t,
      ),
      accountNumberFormat: accountNumberFormatByBank(
        KEY_BENEFICIARY_ACCOUNT_NUMBER,
        bank,
        t,
      ),
    },
  }),
  amount: (balance: number, minimumAmount: number) => ({
    required: required('Amount', t),
    validate: {
      min: min('Amount', minimumAmount, t),
      balance: enoughBalance(balance, t),
    },
  }),
  purpose: {
    pattern: {
      value: /^[0-9a-zA-Z .]*$/,
      message: t(`validation.alphaNumeric`, {
        field: t(`words.${KEY_PURPOSE_OF_TRANSFER}`),
      }),
    },
  },
});

import { useCallback, useState, useEffect } from 'react';
import { checkInternetConnection } from '../../../../../utils/helpers/connection';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import KycStatusViewUseCase from '../../../../../useCases/verification/kycRequest/KycRequestStatusUseCase';

// Repository
import KycRequestFirestore from '../../../../../repository/verification/kycRequest/KycRequestFirestore';
import ConsentFormFirestore from '../../../../../repository/verification/consentForm/ConsentFormFirestore';

// DomainObject
import { useKycRequest } from '../../../../../domain/verification/kycRequest/KycRequest/KycRequest/useKycRequest';
import { useConsentForm } from '../../../../../domain/verification/consentForm/ConsentForm/useConsentForm';
import UserAuthorized from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useKycStatusViewUseCase = (userAuthorized?: UserAuthorized) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  DomainObject
   *
   * */
  const { kycRequest, setKycRequest } = useKycRequest();
  const { consentForm, setConsentForm } = useConsentForm();

  /* *
   *
   *  Repository
   *
   * */
  const kycRequestRepository = new KycRequestFirestore();
  const consentFormRepository = new ConsentFormFirestore();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new KycStatusViewUseCase(
    kycRequestRepository,
    consentFormRepository,
  );

  /* *
   *
   *  Method
   *
   * */
  const openKycStatusPage = useCallback(async () => {
    if (!userAuthorized) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const output = await useCase.open(userAuthorized);

      setKycRequest(output.kycRequest);
      setConsentForm(output.consentForm);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized]);

  /* *
   *
   *  UseCase
   *
   * */
  useEffect(() => {
    let isMounted = true;

    if (userAuthorized && isMounted) openKycStatusPage();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [openKycStatusPage]);

  return {
    state,
    setState,
    kycRequest,
    consentForm,
  };
};

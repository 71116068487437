import React from 'react';

export interface LinksDebitCardContextProps {
  debitCardFeeFileUrl?: string;
}

const LinksDebitCardContext = React.createContext<LinksDebitCardContextProps>({
  debitCardFeeFileUrl: undefined,
});

export default LinksDebitCardContext;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Style
import { FormContainerStyle } from './style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface ContractTimeDepositAddConfirmTemplateLayoutProps {
  children: React.ReactElement[];
}

const ContractTimeDepositAddConfirmTemplateLayout: FC<ContractTimeDepositAddConfirmTemplateLayoutProps> = ({
  children,
}: ContractTimeDepositAddConfirmTemplateLayoutProps) => {
  const elements = ['header', 'progressStep', 'description', 'table', 'form'];
  const [header, progressStep, description, table, form] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn padding="85 0 0 80" textAlign="center" width={1280}>
      {header}
      <Margin top={50} />
      <FlexBoxRow justifyContent="center">{progressStep}</FlexBoxRow>
      <Margin top={100} />
      <FlexBoxColumn
        alignItems="center"
        justifyContent="center"
        width={1200}
        padding="80"
        theme={FormContainerStyle}
      >
        {description}
        <Margin top={30} />
        {table}
        <Margin top={50} />
        {form}
      </FlexBoxColumn>
      <Margin top={250} />
    </FlexBoxColumn>
  );
};
export default ContractTimeDepositAddConfirmTemplateLayout;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import Margin from '../../../atoms/utils/layout/Margin';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../atoms/utils/layout/Box/FlexBox';
import ContentBox from '../../../atoms/utils/layout/Box/Box/ContentBox';

export interface MaintenanceScheduleLayoutProps {
  children: React.ReactElement[];
}

const MaintenanceScheduleLayout: FC<MaintenanceScheduleLayoutProps> = ({
  children,
}: MaintenanceScheduleLayoutProps) => {
  const elements = ['description', 'from', 'fromTime', 'to', 'toTime'];
  const [description, from, fromTime, to, toTime] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <ContentBox padding="30 60">
      <FlexBoxColumn alignItems="center" height="auto">
        {description}
        <Margin top={20} />
        <FlexBoxRow>
          {from}
          <Margin left={20} />
          {fromTime}
        </FlexBoxRow>
        <Margin top={20} />
        <FlexBoxRow>
          {to}
          <Margin left={48} />
          {toTime}
        </FlexBoxRow>
      </FlexBoxColumn>
    </ContentBox>
  );
};
export default MaintenanceScheduleLayout;

import React, { FC, useContext } from 'react';
import ContractTimeDepositRenewalReservationDeletePageStateContext from '../../../enhancers/pageState/contract/ContractTimeDepositRenewalReservationPageState/ContractTimeDepositRenewalReservationPageStateContext';

// Error
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';

// Component
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import ContractTimeDepositRenewalReservationDeleteConfirmTemplate from '../../../components/templates/contractTimeDeposit/ContractTimeDepositRenewalReservationDeleteConfirmTemplate';
import ContractTimeDepositRenewalReservationDeleteCompleteTemplate from '../../../components/templates/contractTimeDeposit/ContractTimeDepositRenewalReservationDeleteCompleteTemplate';

const ContractTimeDepositRenewalReservationDeletePage: FC = () => {
  const { pageState } = useContext(
    ContractTimeDepositRenewalReservationDeletePageStateContext,
  );

  const TemplateComponent: FC = () => {
    switch (pageState) {
      case 'initial':
        return <ContractTimeDepositRenewalReservationDeleteConfirmTemplate />;
      case 'complete':
        return <ContractTimeDepositRenewalReservationDeleteCompleteTemplate />;
      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_SWITCH,
          {
            value: pageState,
            place: `ContractTimeDepositRenewalReservationDeletePage`,
          },
        );
    }
  };

  return (
    <DashboardTemplate>
      <TemplateComponent />
    </DashboardTemplate>
  );
};

export default ContractTimeDepositRenewalReservationDeletePage;

import React, { FC } from 'react';

// Component
import {
  TextPrimary,
  TextSecondary,
  TextThemeProps,
} from '../../../atoms/text2/Text2';
import LabeledTextLayout from './LabeledTextLayout';

export interface LabeledTextProps {
  direction: 'row' | 'column';
  label: string;
  marginBetween: string | number;
  value: string;
  theme: {
    label: TextThemeProps;
    value: TextThemeProps;
  };
}

const LabeledText: FC<LabeledTextProps> = ({
  direction = 'row',
  label,
  marginBetween,
  value,
  theme,
}: LabeledTextProps) => {
  return (
    <LabeledTextLayout direction={direction} marginBetween={marginBetween}>
      <TextPrimary key="label" theme={theme.label}>
        {label}
      </TextPrimary>
      <TextSecondary key="name" theme={theme.value}>
        {value}
      </TextSecondary>
    </LabeledTextLayout>
  );
};

export default LabeledText;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';
import Box from '../../../../../atoms/utils/layout/Box/Box';

export interface FormInputPhoneNumberLayoutProps {
  children: React.ReactElement[];
}

const FormInputPhoneNumberLayout: FC<FormInputPhoneNumberLayoutProps> = ({
  children,
}: FormInputPhoneNumberLayoutProps) => {
  const elements = ['label', 'countryCode', 'number'];
  const [label, countryCode, number] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow alignItems="center" justifyContent="space-between">
      {label}
      <FlexBoxRow width="auto">
        <Box width={144}>{countryCode}</Box>
        <Margin left={20} />
        {number}
      </FlexBoxRow>
    </FlexBoxRow>
  );
};
export default FormInputPhoneNumberLayout;

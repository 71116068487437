import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  DebitCardRequestProps,
  DebitCardRequestPropsFormat,
} from './DebitCardRequest.type';
import { DebitCardRequestCherryVisa01Props } from '../../DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01.type';
import { DebitCardIssuerType } from '../../../../../types/DebitCard.type';

// DomainObject
import DebitCardRequest from './DebitCardRequest';
import DebitCardRequestCherryVisa01Factory from '../../DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Factory';

class DebitCardRequestFactory {
  static createByIssuer = (id: string, props: DebitCardRequestProps) => {
    const { issuer } = props;

    switch (issuer) {
      case DebitCardIssuerType.cherryVisa01:
        return DebitCardRequestCherryVisa01Factory.create(
          id,
          props as DebitCardRequestCherryVisa01Props,
        );

      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_SWITCH,
          {
            value: issuer,
            place: 'DebitCardRequestFactory.create',
          },
        );
    }
  };

  static create = (id: string, props: DebitCardRequestProps) => {
    const result = Joi.object(DebitCardRequestPropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: DebitCardRequest.name,
          reason: result.error.details[0].message,
        },
      );

    return new DebitCardRequest(id, props);
  };

  static createInitial = (
    issuer: DebitCardIssuerType,
    savingsAccountNumber: string,
  ) => {
    switch (issuer) {
      case DebitCardIssuerType.cherryVisa01:
        return DebitCardRequestCherryVisa01Factory.createInitial(
          savingsAccountNumber,
        );

      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_SWITCH,
          {
            value: issuer,
            place: 'DebitCardRequestFactory.createInitial',
          },
        );
    }
  };
}

export default DebitCardRequestFactory;

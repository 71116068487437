// React
import { useCallback, useState, useEffect } from 'react';

// ErrorHandler
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../../utils/helpers/connection';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';

// UseCase
import DebitCardViewUseCase from '../../../../../useCases/debitCard/debitCard/DebitCardViewUseCase';

// Repository
import SavingsAccountFunctions from '../../../../../repository/bankAccount/savingsAccount/SavingsAccountFunctions';
import DebitCardV2Functions from '../../../../../repository/debitCard/debitCard/DebitCardV2Functions';

// DomainObject
import UserAuthorized from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import { useSavingsAccounts } from '../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/useSavingsAccounts';
import { useDebitCardV2s } from '../../../../../domain/debitCard/debitCardV2/DebitCardV2s/useDebitCardV2s';

// Option
import { useSavingsAccountOption } from '../../../../../components/molecules/selectBox/option/option/SavingsAccountOption/useSavingsAccountOption';
import { useDebitCardOption } from '../../../../../components/molecules/selectBox/option/option/DebitCardOption/useDebitCardOption';

export const useDebitCardViewUseCase = (user?: UserAuthorized) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  DomainObject
   *
   * */
  const { savingsAccounts, setSavingsAccounts } = useSavingsAccounts();
  const { debitCardV2s, setDebitCardV2s } = useDebitCardV2s();

  // SelectBox
  const {
    savingsAccountOption,
    setSavingsAccountOption,
  } = useSavingsAccountOption();
  const { debitCardOption, setDebitCardOption } = useDebitCardOption();

  /* *
   *
   *  Repository
   *
   * */
  const savingsAccountRepository = new SavingsAccountFunctions();
  const debitCardV2Repository = new DebitCardV2Functions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new DebitCardViewUseCase(
    debitCardV2Repository,
    savingsAccountRepository,
  );

  /* *
   *
   *  Methods
   *
   * */
  const openDebitCard = useCallback(async () => {
    if (!user) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // execute
      const output = await useCase.open(user);

      setSavingsAccounts(output.savingsAccounts);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [user]);

  const fetchDebitCards = useCallback(async () => {
    if (!savingsAccountOption) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // execute
      const output = await useCase.findDebitCards(savingsAccountOption);

      setDebitCardV2s(output.debitCardV2s);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [savingsAccountOption]);

  /* *
   *
   *  UseEffect
   *
   * */
  // UseCase.open
  useEffect(() => {
    let isMounted = true;

    if (isMounted && user) openDebitCard();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [user]);

  // Initial setup (SavingsAccounts is fetched once)
  useEffect(() => {
    setSavingsAccountOption(savingsAccounts.head());
  }, [savingsAccounts, setSavingsAccountOption]);

  // Fetch debitCards (by SavingsAccountOption)
  useEffect(() => {
    let isMounted = true;

    if (isMounted && savingsAccountOption) fetchDebitCards();

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line
  }, [savingsAccountOption]);

  // Initial setup (DebitCards is fetched once)
  useEffect(() => {
    setDebitCardOption(debitCardV2s.head());
  }, [debitCardV2s, setDebitCardOption]);

  return {
    state,
    setState,
    savingsAccounts,
    savingsAccountOption,
    setSavingsAccountOption,
    debitCards: debitCardV2s,
    debitCardOption,
    setDebitCardOption,
  };
};

import React, { FC } from 'react';

// Component
import MobileAppDownloadBadge from '../../../../../atoms/image/MobileAppDownloadBadge';
import KycFormContainer from '../../../common/KycFormContainer';
import GoogleAuthenticatorDownloadLayout from './GoogleAuthenticatorDownloadLayout';

const FORM_ID =
  'organisms.form.verification.FormTwoFactorAuthAppSettings.googleAuthenticatorDownload';

const GoogleAuthenticatorDownload: FC = () => {
  return (
    <KycFormContainer key="downloadApp" order={1} formId={FORM_ID}>
      <GoogleAuthenticatorDownloadLayout>
        <MobileAppDownloadBadge
          key="badge1"
          type="iOS"
          width={187.33}
          height={58.12}
        />
        <MobileAppDownloadBadge
          key="badge2"
          type="android"
          width={187.33}
          height={58.12}
        />
      </GoogleAuthenticatorDownloadLayout>
    </KycFormContainer>
  );
};

export default GoogleAuthenticatorDownload;

import React from 'react';
import SavingsAccounts from './SavingsAccounts';

export interface SavingsAccountsContextProps {
  savingsAccounts: SavingsAccounts;
}

const SavingsAccountsContext = React.createContext<SavingsAccountsContextProps>(
  {
    savingsAccounts: new SavingsAccounts(),
  },
);

export default SavingsAccountsContext;

import { useCallback, useState } from 'react';

// Lib
import { checkInternetConnection } from '../../../../../../utils/helpers/connection';

// Error
import ErrorHandler from '../../../../../../utils/errors/ErrorHandler/ErrorHandler';

// Type
import { DebitCardIssuerType } from '../../../../../../types/DebitCard.type';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../../config/constants/requestState';

// UseCase
import DebitCardRequestCreateUseCase from '../../../../../../useCases/debitCard/debitCardRequest/DebitCardRequest/DebitCardRequestCreateUseCase';

// Repository
import DebitCardRequestFunctions from '../../../../../../repository/debitCard/debitCardRequest/DebitCardRequestFunctions';

// DomainObject
import { useDebitCardRequest } from '../../../../../../domain/debitCard/debitCardRequest/DebitCardRequest/DebitCardRequest/useDebitCardRequest';
import UserAuthorized from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useDebitCardRequestCreateUseCase = (
  savingsAccountNumber: string | null,
  issuer: string | null,
  userAuthorized: UserAuthorized | undefined,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /**
   *
   *  DomainObject
   *
   */
  const { debitCardRequest, setDebitCardRequest } = useDebitCardRequest();

  /**
   *
   *  Repository
   *
   */
  const debitCardRequestRepository = new DebitCardRequestFunctions();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new DebitCardRequestCreateUseCase(debitCardRequestRepository);

  /* *
   *
   *  Method
   *
   * */
  const createDebitCardRequest = useCallback(
    async (callback?: () => void) => {
      if (!userAuthorized || !savingsAccountNumber || !issuer) return;

      setState(IS_LOADING);

      try {
        // Is ONLINE?
        checkInternetConnection();

        // UseCase
        const outputs = await useCase.executeCreateInitial(
          savingsAccountNumber,
          issuer as DebitCardIssuerType,
        );
        setDebitCardRequest(outputs.debitCardRequest);

        setState(SUCCESS);

        if (callback) callback();
      } catch (error) {
        const handler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
      // eslint-disable-next-line
  }, [userAuthorized, savingsAccountNumber, issuer]);

  return {
    state,
    setState,
    debitCardRequest,
    createDebitCardRequest,
  };
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Component
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import FormSelectUserType from '../../../organisms/form/verification/selectUserType/FormSelectuserType';
import SelectUserTypeTemplateLayout from './SelectUserTypeTemplateLayout';

const TEMPLATE_ID = `templates.verification.SelectUserTypeTemplate`;

const SelectUserTypeTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <SelectUserTypeTemplateLayout>
      <PageTitle key="header">{t(`${TEMPLATE_ID}.title`)}</PageTitle>
      <FormSelectUserType key="form" />
    </SelectUserTypeTemplateLayout>
  );
};

export default SelectUserTypeTemplate;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxRow } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface DebitCardValidThroughLayoutProps {
  children: React.ReactElement[];
}

const DebitCardValidThroughLayout: FC<DebitCardValidThroughLayoutProps> = ({
  children,
}: DebitCardValidThroughLayoutProps) => {
  const elements = ['title', 'month01', 'month02', 'slash', 'year01', 'year02'];
  const [title, month01, month02, slash, year01, year02] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxRow alignItems="center" paddingBottom="5%" width="auto">
      {title}
      <Margin left="10%" />
      {month01}
      <Margin left="4%" />
      {month02}
      <Margin left="4%" />
      {slash}
      <Margin left="4%" />
      {year01}
      <Margin left="4%" />
      {year02}
    </FlexBoxRow>
  );
};
export default DebitCardValidThroughLayout;

// React
import React, { FC, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DebitCardOptionContext from 'components/molecules/selectBox/option/option/DebitCardOption/DebitCardOptionContext';
import TransferDebitCardTransactionV2sContext from 'domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2s/TransferDebitCardTransactionV2sContext';

// Lib
import { getSymbolFromCurrency } from 'utils/helpers/currencyHelper';

// Type
import { FiatCurrencyType } from 'types/FiatCurrency.type';

// Mapper
import TableTransferDebitCardTransactionHistoryMapper, {
  TdTransferDebitCardTransactionHistory,
} from 'components/organisms/table/history/TableTransferDebitCardTransactionHistory/TableTransferDebitCardTransactionHistoryMapper';

// Constant
import { FILTER_RULE } from 'config/constants/business';

// Components
import TransferDebitCardTransactionHistoryActionTdBox from 'components/molecules/table/td/TransferDebitCardTransactionHistoryActionTdBox';
import DefaultTdBox from 'components/molecules/table/td/DefaultTdBox';
import {
  HiddenFilter,
  SelectColumnFilter,
  TextInputFilter,
} from 'components/atoms/table/filter';
import DebitCardTransferTypeTdBox from 'components/molecules/table/td/DebitCardTransferTypeTdBox';
import CurrencyAmountTdBox from 'components/molecules/table/td/CurrencyAmountTdBox';
import StatusBadgeTdBox from 'components/molecules/table/td/StatusBadgeTdBox';
import TableFunctional from 'components/molecules/table/table/TableFunctional';
import ModalTransferDebitCardTransactionV2sContext from 'components/organisms/modal/TransferDebitCardTransactionCancelModal/TransferDebitCardTransactionCancelModalContext';
import { TransferDebitCardStatusType } from 'types/Transfer.type';
import SelectRowTableTopUpContext from '../../selectRow/SelectRowTableTopUp/SelectRowTableTopUpContext';

// ID
const ORGANISM_ID = `organisms.table.TableTransferDebitCardTransactionHistory`;

const TableTransferDebitCardTransactionHistory: FC = () => {
  const { t } = useTranslation();
  const { transferDebitCardTransactions } = useContext(
    TransferDebitCardTransactionV2sContext,
  );
  const { setSelectRowTableTopUp } = useContext(SelectRowTableTopUpContext);
  const { showModal: showModalTopUpCancellation } = useContext(
    ModalTransferDebitCardTransactionV2sContext,
  );
  const { debitCardOption } = useContext(DebitCardOptionContext);

  // Currency
  const currency = useMemo(
    () =>
      debitCardOption
        ? debitCardOption.getProps().currency
        : FiatCurrencyType.USD,
    [debitCardOption],
  );

  const data = useMemo(
    () =>
      TableTransferDebitCardTransactionHistoryMapper.domainToTd(
        transferDebitCardTransactions,
        t,
      ),
    [transferDebitCardTransactions, t],
  );

  const columns = useMemo(
    () => [
      {
        Header: t(`${ORGANISM_ID}.th.requestDate`),
        accessor: (values: TdTransferDebitCardTransactionHistory) =>
          values.createdAtString,
        Cell: (value: {
          row: { original: TdTransferDebitCardTransactionHistory };
        }) => DefaultTdBox({ text: value.row.original.createdAtString }),
        Filter: HiddenFilter,
        filter: FILTER_RULE,
        minWidth: `${160 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.reviewedDate`),
        accessor: (values: TdTransferDebitCardTransactionHistory) =>
          values.updatedAtString,
        Cell: (value: {
          row: { original: TdTransferDebitCardTransactionHistory };
        }) => DefaultTdBox({ text: value.row.original.updatedAtString }),
        Filter: HiddenFilter,
        filter: FILTER_RULE,
        minWidth: `${160 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.transferType`),
        accessor: (values: TdTransferDebitCardTransactionHistory) =>
          values.transferTypeString,
        Cell: (value: {
          row: { original: TdTransferDebitCardTransactionHistory };
        }) =>
          DebitCardTransferTypeTdBox({ type: value.row.original.transferType }),
        Filter: SelectColumnFilter,
        filter: FILTER_RULE,
        minWidth: `${180 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.amount`, {
          currency: getSymbolFromCurrency(currency),
        }),
        accessor: (values: TdTransferDebitCardTransactionHistory) =>
          values.amount,
        Cell: (value: { cell: { value: number } }) =>
          CurrencyAmountTdBox({
            currency: currency as FiatCurrencyType,
            number: value.cell.value,
          }),
        Filter: TextInputFilter,
        filter: FILTER_RULE,
        minWidth: `${180 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.status`),
        accessor: (values: TdTransferDebitCardTransactionHistory) =>
          values.statusString,
        Cell: (value: {
          row: { original: TdTransferDebitCardTransactionHistory };
        }) => StatusBadgeTdBox({ status: value.row.original.status }),
        Filter: SelectColumnFilter,
        filter: FILTER_RULE,
        minWidth: `${200 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.action`),
        accessor: () => undefined,
        Cell: (value: {
          row: { original: TdTransferDebitCardTransactionHistory };
        }) => {
          return TransferDebitCardTransactionHistoryActionTdBox({
            isShowAction:
              value.row.original.status ===
              TransferDebitCardStatusType.unconfirmed,
            onClick: () => {
              setSelectRowTableTopUp(value.row.original);
              showModalTopUpCancellation();
            },
          });
        },
        minWidth: `${125 / 16}rem`,
      },
    ],
    // eslint-disable-next-line
    [],
  );

  return (
    <TableFunctional data={data} columns={columns} width={880} isCSV={false} />
  );
};

export default TableTransferDebitCardTransactionHistory;

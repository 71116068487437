import React, { FC, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import CherryVisa01ProofOfIdentityFileContext from '../../../../../../enhancers/fileHandler/debitCard/debitCardRequest/ProofOfIdentityFileProvider/CherryVisa01ProofOfIdentityFileContext';
import UserAuthorizedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Type
import { FileType } from '../../../../../../types/File.type';
import { ImageType } from '../../../../../atoms/image/Image/Image.type';

// Style
import { DescriptionStyle } from './style';

// Component
import { TextSecondary } from '../../../../../atoms/text2/Text2';
import DebitCardRequestFormContainer from '../../../common/DebitCardRequestFormContainer';
import DropzoneArea from '../../../../../molecules/dropzone2/DropzoneArea';
import CheckPointList from '../../../../list/CheckPointList';
import DebitCardRequestExampleImageDescriptionCard from '../../../../card/verification/DebitCardRequestExampleImageDescriptionCard';
import FormSelfieUploadLayout from './FormSelfieUploadLayout';

// ValueObject
import CherryVisa01ProofOfIdentity from '../../../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentity';

const FORM_ID = `organisms.form.debitCards.FormProofOfIdentity.selfieUpload`;

export interface SelfieUploadFormProps {
  proofOfIdentity?: CherryVisa01ProofOfIdentity;
}

const SelfieUploadForm: FC<SelfieUploadFormProps> = ({
  proofOfIdentity,
}: SelfieUploadFormProps) => {
  const { t } = useTranslation();
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // Form
  const { setValue } = useFormContext();

  // FileHandler
  const { selfie, setSelfie } = useContext(
    CherryVisa01ProofOfIdentityFileContext,
  );

  const defaultFilePath = useMemo(() => {
    const uploadedFile = proofOfIdentity?.getProps().passport.selfie;

    const url = uploadedFile ? URL.createObjectURL(uploadedFile) : '';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return uploadedFile && `${url}`;
  }, [proofOfIdentity]);

  /* *
   *
   *  UseEffect
   *
   * */

  useEffect(() => {
    if (selfie && userAuthorized) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const uploadedFilePath = (selfie as any).path;
      setValue('selfieUrl', uploadedFilePath);
    }
    // eslint-disable-next-line
  }, [selfie, userAuthorized]);

  return (
    <DebitCardRequestFormContainer key="selfie" order={6} formId={FORM_ID}>
      <FormSelfieUploadLayout>
        <TextSecondary key="description" theme={DescriptionStyle}>
          {t(`${FORM_ID}.description`)}
        </TextSecondary>
        <DropzoneArea
          key="dropzone"
          name="selfie"
          urlName="selfieUrl"
          acceptFileTypes={[FileType.png, FileType.jpeg]}
          defaultFilePath={defaultFilePath ?? ''}
          maxFileSize={2}
          setFile={setSelfie}
          width={667}
        />
        <DebitCardRequestExampleImageDescriptionCard
          key="exampleOK"
          imageType={ImageType.exampleImageOKNoCard}
        />
        <DebitCardRequestExampleImageDescriptionCard
          key="exampleNG1"
          imageType={ImageType.exampleImageNG1NoCard}
        />
        <DebitCardRequestExampleImageDescriptionCard
          key="exampleNG2"
          imageType={ImageType.exampleImageNG2NoCard}
        />
        <DebitCardRequestExampleImageDescriptionCard
          key="exampleNG3"
          imageType={ImageType.exampleImageNG3NoCard}
        />
        <CheckPointList
          key="checkPoint"
          title={t(`organisms.CheckPointList.title`)}
          checkPoints={[
            t(`organisms.CheckPointList.text.faceClear`),
            t(`organisms.CheckPointList.text.documentClear`),
            t(`organisms.CheckPointList.text.isSamePassport`),
          ]}
        />
      </FormSelfieUploadLayout>
    </DebitCardRequestFormContainer>
  );
};

export default SelfieUploadForm;

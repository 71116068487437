import BankDeposit from '../BankDeposit/BankDeposit';
import { IIterable } from '../../../../../interface/Iterator/IIterable';

class BankDeposits implements IIterable<BankDeposit> {
  private items: BankDeposit[] = [];

  constructor(items?: BankDeposit[]) {
    this.items = items || [];
  }

  public add = (bankDeposit: BankDeposit) => {
    this.items.push(bankDeposit);
  };

  public head = () => this.items[0];

  public map = <T>(callback: (item: BankDeposit) => T) => {
    return this.items.map((item) => callback(item));
  };

  public forEach = (callback: (item: BankDeposit) => void) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.items) {
      // eslint-disable-next-line no-await-in-loop
      callback(item);
    }
  };

  public forEachAsync = async (
    callback: (item: BankDeposit) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.items) {
      // eslint-disable-next-line no-await-in-loop
      await callback(item);
    }
  };
}
export default BankDeposits;

/* eslint-disable @typescript-eslint/no-explicit-any */
// React
import React, { FC, useContext, useEffect } from 'react';
import { useFilters, usePagination, useSortBy, useTable } from 'react-table';
import matchSorter from 'match-sorter';
import CSVDataContext from '../../../../organisms/csv/CSVData/CSVDataContext';

// Const
import { DEFAULT_PAGE_SIZE } from '../../../../../config/constants/business';

// Style
import { ThStyle, ThTextStyle, TdTextStyle } from './style';

// Components
import { TextPrimary, TextSecondary } from '../../../../atoms/text2/Text2';
import ColumnSort from '../../../../atoms/table/sort/ColumnSort';
import Display from '../../../../atoms/div/Display';
import Pagination from '../../../../atoms/table/pagination/Pagination';
import Td from '../../../../atoms/table/Table/Td';
import Th from '../../../../atoms/table/Table/Th';
import Tr from '../../../../atoms/table/Table/Tr';
import TableFunctionalLayout from './TableFunctionalLayout';

// LayoutComponent
import { FlexBoxRow } from '../../../../atoms/utils/layout/Box/FlexBox';

export interface TableFunctionalProps {
  columns: Array<{ Header: string; accessor: any }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { [key: string]: any }[];
  onClickTr?: (id: string) => void;
  width?: string | number;
  isCSV?: boolean;
}

const TableFunctional: FC<TableFunctionalProps> = ({
  columns,
  data,
  onClickTr = () => undefined,
  width,
  isCSV = true,
}: TableFunctionalProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fuzzyTextFilterFn = (rows: any, id: any, filterValue: any) =>
    matchSorter(rows, filterValue, {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      keys: [(row: any) => row.values[id]],
    });

  const filterTypes = React.useMemo(
    () => ({
      fuzzyTxt: fuzzyTextFilterFn,

      text: (rows: any[], id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const { setCSVData } = useContext(CSVDataContext);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: DEFAULT_PAGE_SIZE },
      filterTypes,
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const {
    gotoPage,
    headerGroups,
    page,
    pageCount,
    prepareRow,
    state: { pageIndex },
    rows,
  } = tableInstance;

  const headerGroup = headerGroups[0];
  const thHeight = 125;
  const tdHeight = 70;

  // set filtered Data for CSV Download
  useEffect(() => {
    if (isCSV) setCSVData(rows.map((row) => row.original));
  }, [rows, setCSVData, isCSV]);

  return (
    <TableFunctionalLayout pageCount={pageCount} width={width}>
      <thead key="thead">
        <Tr>
          {headerGroup.headers.map((column, j) => (
            <Th
              key={`th${j}`}
              height={thHeight}
              paddingLeft={14.38}
              paddingRight={14.38}
              textAlign="left"
              verticalAlign="top"
              minWidth={column.minWidth}
              theme={ThStyle}
            >
              <FlexBoxRow
                height={65}
                textAlign="left"
                paddingRight={10}
                justifyContent="space-between"
                alignItems="center"
                style={{ borderBottom: `${1 / 16}rem solid white` }}
              >
                <TextPrimary theme={ThTextStyle}>
                  {column.render('Header')}
                </TextPrimary>
                <Display isDisplay={column.filter !== undefined}>
                  <ColumnSort column={column} />
                </Display>
              </FlexBoxRow>
              <FlexBoxRow
                height={60}
                theme={{
                  borderTop: `${1 / 16}rem solid #ffffff`,
                }}
                alignItems="center"
              >
                {column.filter !== undefined ? (
                  column.render('Filter')
                ) : (
                  <div
                    style={{
                      minWidth: `100%`,
                      minHeight: `${30 / 16}rem`,
                    }}
                  />
                )}
              </FlexBoxRow>
            </Th>
          ))}
        </Tr>
      </thead>
      <tbody key="tbody">
        {page.map((row, k) => {
          prepareRow(row);
          return (
            <Tr
              onClick={() => {
                onClickTr(
                  (row.original && (row.original as any).id) || undefined,
                );
              }}
              key={`tbodyTr${k}`}
            >
              {row.cells.map((cell, l) => {
                return (
                  <Td key={`tbodyTd${k}-${l}`} height={tdHeight}>
                    <TextSecondary theme={TdTextStyle}>
                      {cell.render('Cell')}
                    </TextSecondary>
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </tbody>
      <Pagination
        key="pagination"
        pageCount={pageCount}
        gotoPage={gotoPage}
        pageIndex={pageIndex}
      />
    </TableFunctionalLayout>
  );
};

export default TableFunctional;

// Type
import { OptionObject } from '../../../../atoms/select/SelectBox/Option.type';
import { CryptoCurrencyProps } from '../../../../../types/CryptoCurrency.type';
import { SavingsAccountOptionObject } from './SavingsAccountOption/SavingsAccountOption.type';

// DomainObject
import AssetsCryptoCurrency from '../../../../../domain/asset/assetCryptoCurrency/AssetsCryptoCurrency/AssetsCryptoCurrency';
import AssetCryptoCurrency from '../../../../../domain/asset/assetCryptoCurrency/AssetCryptoCurrency/AssetCryptoCurrency';
import CryptoCurrencyAvailable from '../../../../../domain/settings/cryptoCurrencyAvailable/CryptoCurrencyAvailable/CryptoCurrencyAvailable';
import CryptoCurrenciesAvailable from '../../../../../domain/settings/cryptoCurrencyAvailable/CryptoCurrenciesAvailable/CryptoCurrenciesAvailable';
import SavingsAccounts from '../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccounts';
import SavingsAccount from '../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

class DomainOptionsMapper {
  static assetsCryptoCurrencyToOptions = (
    assetsCryptoCurrency: AssetsCryptoCurrency,
  ) => {
    const getOption = (
      assetCryptoCurrency: AssetCryptoCurrency,
    ): OptionObject => ({
      label: CryptoCurrencyProps[assetCryptoCurrency.getCurrency()].name,
      value: assetCryptoCurrency.getCurrency() as string,
    });
    return assetsCryptoCurrency.map<OptionObject>(getOption);
  };

  static cryptoCurrenciesAvailableToOptions = (
    cryptoCurrenciesAvailable: CryptoCurrenciesAvailable,
  ) => {
    const getOption = (
      cryptoCurrencyAvailable: CryptoCurrencyAvailable,
    ): OptionObject => ({
      label: CryptoCurrencyProps[cryptoCurrencyAvailable.getId()].name,
      value: cryptoCurrencyAvailable.getId() as string,
    });
    return cryptoCurrenciesAvailable.map<OptionObject>(getOption);
  };

  static savingsAccountsToTabItem = (savingsAccounts: SavingsAccounts) => {
    const getTabItem = (savingsAccount: SavingsAccount): SavingsAccount =>
      savingsAccount;
    return savingsAccounts.map<SavingsAccount>(getTabItem);
  };

  static savingsAccountsToOption = (savingsAccounts: SavingsAccounts) => {
    const getOption = (
      savingsAccount: SavingsAccount,
    ): SavingsAccountOptionObject => {
      const props = savingsAccount.getProps();
      const { currency, accountType, balance } = props;

      return {
        label: savingsAccount.getRawAccountNumber(),
        value: savingsAccount.getRawAccountNumber(),
        balance,
        currency,
        accountType,
      };
    };
    return savingsAccounts.map<SavingsAccountOptionObject>(getOption);
  };
}

export default DomainOptionsMapper;

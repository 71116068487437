// Type
import {
  KycRequestCorporateDocumentData,
  KycRequestIndividualDocumentData,
} from '../../../../infrastructure/firebase/firestore/collections/verification/kycRequest/KycRequestDocumentData.type';

class KycStepStatusMapperFirestore {
  static convertCorporateDocDataToDomain = (
    documentData: KycRequestCorporateDocumentData,
  ) => {
    const { corporate } = documentData;
    return {
      status: documentData.status,
      corporateInformation: corporate.corporateInformation.status,
      corporateAddress: corporate.corporateAddress.status,
      personalInformation: corporate.personalInformation.status,
      proofOfResidency: corporate.proofOfResidency.status,
      waitingReview: corporate.waitingReview.status,
    };
  };

  static convertIndividualDocDataToDomain = (
    documentData: KycRequestIndividualDocumentData,
  ) => {
    const { individual } = documentData;
    return {
      status: documentData.status,
      personalInformation: individual.personalInformation.status,
      proofOfResidency: individual.proofOfResidency.status,
      waitingReview: individual.waitingReview.status,
    };
  };
}

export default KycStepStatusMapperFirestore;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormConsentFormLayoutProps {
  children: React.ReactElement[];
}

const FormConsentFormLayout: FC<FormConsentFormLayoutProps> = ({
  children,
}: FormConsentFormLayoutProps) => {
  const elements = [
    'download',
    'signatureExplanation',
    'consentForm',
    'button',
  ];
  const [
    download,
    signatureExplanation,
    consentForm,
    button,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn width={1200} height="auto">
      {download}
      <Margin top={60} />
      {signatureExplanation}
      <Margin top={60} />
      {consentForm}
      <Margin top={60} />
      {button}
      <Margin top={83} />
    </FlexBoxColumn>
  );
};
export default FormConsentFormLayout;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle, NavigationTextStyle } from './style';

// Constant
import { ROUTE_SIGN_IN } from '../../../../config/constants/routing';

// Component
import { FrontPageTitle } from '../../../atoms/headLine/HeadLine';
import { TextSecondary } from '../../../atoms/text2/Text2';
import Breadcrumb from '../../../molecules/link/Breadcrumb';
import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';

import ResetAuthenticatorCompleteTemplateLayout from './ResetAuthenticatorTemplateLayout';

const TEMPLATE_ID = `templates.authentication.ResetAuthenticatorCompleteTemplate`;

const ResetAuthenticatorCompleteTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <ResetAuthenticatorCompleteTemplateLayout>
      <FrontPageTitle key="title">{t(`${TEMPLATE_ID}.title`)}</FrontPageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced text={t(`${TEMPLATE_ID}.description`)} />
      </TextSecondary>
      <Breadcrumb
        key="link"
        href={ROUTE_SIGN_IN}
        iconSize={10}
        marginBetween={13}
        text={t(`atoms.button.signIn`)}
        theme={{ text: NavigationTextStyle }}
      />
    </ResetAuthenticatorCompleteTemplateLayout>
  );
};

export default ResetAuthenticatorCompleteTemplate;

// React
import { UseStateType } from '../../../../types/typeof/UseState';

// IService
import ILitecoinTestnetWalletQueryService from '../../../../useCases/cryptoCurrency/cryptoCurrencyWallet/interface/ILitecoinTestnetWalletQueryService';

// IRepository
import ILitecoinTestnetWalletRepository from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/LitecoinTestnetWallet/ILitecoinTestnetWalletRepository';

// DomainObject
import LitecoinTestnetWallet from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/LitecoinTestnetWallet/LitecoinTestnetWallet/LitecoinTestnetWallet';

class LitecoinTestnetWalletQueryService
  implements ILitecoinTestnetWalletQueryService {
  private litecoinTestnetWalletRepository: ILitecoinTestnetWalletRepository;

  constructor(
    litecoinTestnetWalletRepository: ILitecoinTestnetWalletRepository,
  ) {
    this.litecoinTestnetWalletRepository = litecoinTestnetWalletRepository;
  }

  public findMainWallet = async (userId: string) => {
    const wallets = await this.litecoinTestnetWalletRepository.findByUserId(
      userId,
    );

    return wallets.head();
  };

  public subscribeMainWallet = (
    userId: string,
    setter: UseStateType<LitecoinTestnetWallet | undefined>,
    setState: UseStateType<string>,
  ) => {
    this.litecoinTestnetWalletRepository.subscribeByUserId(
      userId,
      setter,
      setState,
    );
  };
}
export default LitecoinTestnetWalletQueryService;

import React, { useState } from 'react';
import { Gender } from '../../../../../types/KycRequest.type';

export interface FormInputCherryVisa01ProofOfIdentity {
  country: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  documentType: string;
  documentUrl1?: string;
  documentUrl2?: string;
  countryCode: string;
  phoneNumber: string;
  gender: Gender;
  isInscribed: boolean;
  dateOfBirthYear: string;
  dateOfBirthMonth: string;
  dateOfBirthDay: string;
  passportNo: string;
  issuedDateYear: string;
  issuedDateMonth: string;
  issuedDateDay: string;
  expiredDateYear: string;
  expiredDateMonth: string;
  expiredDateDay: string;
  issuingCountry: string;
  nationality: string;
  selfieUrl?: string;
  signatureUrl?: string;
  occupation: string;
}

export const useFormInputCherryVisa01ProofOfIdentity = () => {
  const [formInput, setFormInput] = useState<
    FormInputCherryVisa01ProofOfIdentity
  >();

  return {
    formInput,
    setFormInput,
  };
};

export const FormInputCherryVisa01ProofOfIdentityContext = React.createContext<{
  formInput?: FormInputCherryVisa01ProofOfIdentity;
  setFormInput: (formInput?: FormInputCherryVisa01ProofOfIdentity) => void;
}>({
  formInput: undefined,
  setFormInput: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formInput?: FormInputCherryVisa01ProofOfIdentity,
  ) => undefined,
});

// React
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import TableConfirmTransferDebitCardTransactionV2s from 'components/organisms/table/confirm/TableConfirmTransferDebitCardTransactionV2s';
import ModalTransferDebitCardTransactionCancelContext from 'components/organisms/modal/TransferDebitCardTransactionCancelModal/TransferDebitCardTransactionCancelModalContext';
import TransferDebitCardCancelContext from 'enhancers/useCase/transfer/debitCard/TransferDebitCardCancelProvider/TransferDebitCardCancelContext';
import SelectRowTableTopUpContext from 'components/organisms/table/selectRow/SelectRowTableTopUp/SelectRowTableTopUpContext';

// Constant
import { IS_LOADING } from 'config/constants/requestState';

// Style
import {
  CancelButtonTextStyle,
  contentStyle,
  MessageStyle,
  overlayStyle,
  TitleStyle,
} from 'components/organisms/modal/TransferDebitCardTransactionCancelModal/style';

// Components
import { TextPrimary, TextSecondary } from 'components/atoms/text2/Text2';
import CircleSpinLoader from 'components/atoms/spinner/CircleSpinLoader';
import {
  CancelButton,
  DefaultButtonTextStyle,
  SubmitButton,
} from 'components/atoms/button/Button2';
import ModalTransferDebitCardTransactionCancelLayout from 'components/organisms/modal/TransferDebitCardTransactionCancelModal/TransferDebitCardTransactionCancelModalLayout';

const ORGANISM_ID = `organisms.modal.ModalTransferDebitCardTransactionCancel`;

const TransferDebitCardTransactionCancelModal: FC = () => {
  const { t } = useTranslation();
  const { selectRowTableTopUp } = useContext(SelectRowTableTopUpContext);
  const { hideModal } = useContext(
    ModalTransferDebitCardTransactionCancelContext,
  );

  const { state, cancelTopUp } = useContext(TransferDebitCardCancelContext);

  return (
    <ReactModal
      isOpen={true}
      style={{ content: contentStyle, overlay: overlayStyle }}
      ariaHideApp={false}
    >
      <ModalTransferDebitCardTransactionCancelLayout isLoading={false}>
        <TextPrimary key="title" theme={TitleStyle}>
          {t(`${ORGANISM_ID}.title`)}
        </TextPrimary>
        <TableConfirmTransferDebitCardTransactionV2s key="table" />
        <CircleSpinLoader key="loader" />
        <TextSecondary key="message" theme={MessageStyle}>
          {t(`${ORGANISM_ID}.message`)}
        </TextSecondary>
        <SubmitButton
          key="submitButton"
          isLoading={state === IS_LOADING}
          theme={{ borderRadius: 30 }}
          height={60}
          width={200}
          sizeProgress={20}
          onClick={() => {
            if (selectRowTableTopUp)
              cancelTopUp(selectRowTableTopUp.id, () => {
                hideModal();
                window.location.reload();
              });
          }}
        >
          <TextPrimary theme={DefaultButtonTextStyle}>
            {t(`atoms.button.yes`)}
          </TextPrimary>
        </SubmitButton>
        <CancelButton
          key="cancelButton"
          theme={{ borderRadius: 30 }}
          height={60}
          width={200}
          onClick={hideModal}
        >
          <TextPrimary theme={CancelButtonTextStyle}>
            {t(`atoms.button.cancel`)}
          </TextPrimary>
        </CancelButton>
      </ModalTransferDebitCardTransactionCancelLayout>
    </ReactModal>
  );
};

export default TransferDebitCardTransactionCancelModal;

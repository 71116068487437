// React
import React, { FC } from 'react';

// Components
import Box from '../../../../atoms/utils/layout/Box/Box';
import LineBreakReplaced from '../../../../atoms/text2/LineBreakReplaced';

export interface NumberTdBoxProps {
  number: number;
  precision?: number;
}

const NumberTdBox: FC<NumberTdBoxProps> = ({
  number,
  precision = 0,
}: NumberTdBoxProps) => {
  return (
    <Box
      width="100%"
      height="100%"
      textAlign="right"
      paddingRight={13}
      paddingTop={13}
    >
      <LineBreakReplaced text={number.toFixed(precision)} />
    </Box>
  );
};

export default NumberTdBox;

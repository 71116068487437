// Lib
import { cloneDeep } from 'lodash';

// Type
import { UseStateType } from '../../../types/typeof/UseState';

// IRepository
import IUserRepository from '../../../domain/public/user/User/IUserRepository';

// Firestore
import firestore from '../../../infrastructure/firebase/firestore/client/firestoreClient';
import UsersCollection from '../../../infrastructure/firebase/firestore/collections/public/UsersCollection';

// DomainObject
import User from '../../../domain/public/user/User/User/User';
import UserFactory from '../../../domain/public/user/User/User/UserFactory';
import { UserProps } from '../../../domain/public/user/User/User/User.type';

// Others
import InvalidDataFoundError from '../../../utils/errors/InvalidDataFoundError';

class UserFirestore implements IUserRepository {
  public findById = async (userId: string) => {
    const usersCollection = new UsersCollection();
    const doc = await usersCollection.fetchSpecific(userId);

    if (!doc)
      throw new InvalidDataFoundError(`User with '${userId}' is not found.`);

    return new User(doc.id, doc.data() as UserProps);
  };

  public subscribeById = async (
    userId: string,
    setter: UseStateType<User | undefined>,
    setState: UseStateType<string>,
  ) => {
    const usersCollection = new UsersCollection();

    const converter = (doc: firestore.DocumentSnapshot) => {
      return UserFactory.create(doc.id, doc.data() as UserProps);
    };

    return usersCollection.subscribeSpecific(
      userId,
      setter,
      converter,
      setState,
    );
  };

  public update = async (user: User) => {
    const id = user.getId();
    const props = cloneDeep(user.getProps());
    const now = Date.now();

    if (props.token) delete props.token;

    const usersCollection = new UsersCollection();

    await usersCollection.update(id, { ...props, updatedAt: now });

    return user;
  };
}
export default UserFirestore;

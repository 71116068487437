// Lib
import { isEqualArbitraryOrder } from '../../../../../utils/helpers/arrayHelper';

// Error
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';

// Types
import { CryptoCurrencyBuyTransactionProps } from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyBuyTransaction/CryptoCurrencyBuyTransaction/CryptoCurrencyBuyTransaction.type';
import { CryptoCurrencyTradeType } from '../../../../../types/CryptoCurrency.type';

// Collection
import CryptoCurrencyBuysCollection from '../../../../../infrastructure/firebase/firestore/collections/transaction/cryptoCurrencyTransaction/addresses/CryptoCurrencyBuysCollection';

// IRepository
import ICryptoCurrencyBuyTransactionRepository from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyBuyTransaction/ICryptoCurrencyBuyTransactionRepository';

// DomainObject
import CryptoCurrencyBuyTransactions from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyBuyTransaction/CryptoCurrencyBuyTransactions/CryptoCurrencyBuyTransactions';
import CryptoCurrencyBuyTransactionFactory from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyBuyTransaction/CryptoCurrencyBuyTransaction/CryptoCurrencyBuyTransactionFactory';

import CryptoCurrencyTransactionIdFactory from '../../../../../domain/idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionIdFactory';

class CryptoCurrencyBuyTransactionFirestore
  implements ICryptoCurrencyBuyTransactionRepository {
  public findAllByFields = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: { [key: string]: any },
  ) => {
    if (isEqualArbitraryOrder(Object.keys(params), ['address', 'currency'])) {
      const transactionParams = params as { address: string; currency: string };

      const cryptoCurrencyBuyTransactions = await this.findByCurrencyAndAddress(
        transactionParams,
      );

      return cryptoCurrencyBuyTransactions;
    }

    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_SWITCH,
      {
        value: JSON.stringify(params),
        place: `CryptoCurrencyBuyTransactionFirestore.findAllByFields`,
      },
    );
  };

  private findByCurrencyAndAddress = async (params: {
    address: string;
    currency: string;
  }) => {
    const cryptoCurrencyBuysCollection = new CryptoCurrencyBuysCollection(
      params.currency,
      params.address,
    );

    const docs = await cryptoCurrencyBuysCollection.fetchAll();

    return docs.reduce<CryptoCurrencyBuyTransactions>(
      (cryptoCurrencyBuyTransactions, doc) => {
        const transactionId = CryptoCurrencyTransactionIdFactory.createFromRawId(
          doc.id,
          doc.data()!.commonId,
        );

        cryptoCurrencyBuyTransactions.add(
          CryptoCurrencyBuyTransactionFactory.create(transactionId, {
            ...doc.data(),
            userId: '',
            savingsAccountNumber: '',
            tradeType: CryptoCurrencyTradeType.buy,
          } as CryptoCurrencyBuyTransactionProps),
        );
        return cryptoCurrencyBuyTransactions;
      },
      new CryptoCurrencyBuyTransactions(),
    );
  };
}
export default CryptoCurrencyBuyTransactionFirestore;

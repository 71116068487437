import Joi from '@hapi/joi';
import { CurrencyPairType } from '../../../../types/CurrencyPair.type';
import {
  CryptoCurrencyTradeFeeRateProps,
  CryptoCurrencyTradeFeeRatePropsFormat,
} from './CryptoCurrencyTradeFeeRate.type';
import CryptoCurrencyTradeFeeRate from './CryptoCurrencyTradeFeeRate';

// Error
import InvalidDataFoundError from '../../../../utils/errors/InvalidDataFoundError';

class CryptoCurrencyTradeFeeRateFactory {
  static create = (
    id: CurrencyPairType,
    props: CryptoCurrencyTradeFeeRateProps,
  ) => {
    const result = Joi.object(CryptoCurrencyTradeFeeRatePropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw new InvalidDataFoundError(
        `Creation of CryptoCurrencyTradeFeeRate is rejected. The reason is ${result.error.details[0].message}`,
      );

    return new CryptoCurrencyTradeFeeRate(id, props);
  };
}

export default CryptoCurrencyTradeFeeRateFactory;

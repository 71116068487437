// Type
import { UserType } from '../../../types/User.type';

// IService
import IConsentFormUpsertService from '../../../useCases/verification/consentForm/IConsentFormUpsertService';

// IRepository
import { IConsentFormUpsertRepository } from '../../../domain/verification/consentForm/IConsentFormRepository';

// DomainObject
import ConsentFormFactory from '../../../domain/verification/consentForm/ConsentForm/ConsentFormFactory';
import ConsentForm from '../../../domain/verification/consentForm/ConsentForm/ConsentForm';
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class ConsentFormUpsertService implements IConsentFormUpsertService {
  private consentFormUpsertRepository: IConsentFormUpsertRepository;

  constructor(consentFormUpsertRepository: IConsentFormUpsertRepository) {
    this.consentFormUpsertRepository = consentFormUpsertRepository;
  }

  public createInitial = (user: UserAuthorized, userType: UserType) => {
    return ConsentFormFactory.createInitial(user, userType);
  };

  public executeUpsert = async (consentForm: ConsentForm) => {
    await this.consentFormUpsertRepository.upsert(consentForm);

    return consentForm;
  };
}

export default ConsentFormUpsertService;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface FormTwoFactorAuthNewAccountKeyLayoutProps {
  children: React.ReactElement[];
}

const FormTwoFactorAuthNewAccountKeyLayout: FC<FormTwoFactorAuthNewAccountKeyLayoutProps> = ({
  children,
}: FormTwoFactorAuthNewAccountKeyLayoutProps) => {
  const elements = ['downloadApp', 'accountKey', 'form', 'button'];
  const [downloadApp, accountKey, form, button] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn width={1200}>
      {downloadApp}
      <Margin top={60} />
      {accountKey}
      <Margin top={60} />
      {form}
      <Margin top={60} />
      {button}
      <Margin bottom={83} />
    </FlexBoxColumn>
  );
};

export default FormTwoFactorAuthNewAccountKeyLayout;

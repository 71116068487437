import React from 'react';
import SavingsAccount from './SavingsAccount';

export interface SavingsAccountContextProps {
  savingsAccount: SavingsAccount | undefined;
}

const SavingsAccountContext = React.createContext<SavingsAccountContextProps>({
  savingsAccount: undefined,
});

export default SavingsAccountContext;

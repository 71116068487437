// IService
import IUserCreateService from '../../../useCases/public/user/interface/IUserCreateService';

// IRepository
import IUserCreator from '../../../domain/public/user/User/IUserCreator';

// Factory

class UserCreateService implements IUserCreateService {
  private userCreator: IUserCreator;

  constructor(userCreator: IUserCreator) {
    this.userCreator = userCreator;
  }

  public register = async (
    username: string,
    password: string,
    oobCode: string,
  ) => {
    await this.userCreator.register(username, password, oobCode);
  };
}

export default UserCreateService;

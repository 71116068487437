import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { Container } from './style';
import Margin from '../../../atoms/utils/layout/Margin';

export interface CurrencyAmountLayoutProps {
  children: React.ReactElement[];
  marginBetween: string | number;
}

const CurrencyAmountLayout: FC<CurrencyAmountLayoutProps> = ({
  children,
  marginBetween,
}: CurrencyAmountLayoutProps) => {
  const elements = ['currency', 'amount'];
  const [currency, amount] = getElementsFromKeys(children, elements);

  return (
    <Container>
      {currency}
      <Margin left={marginBetween}>{amount}</Margin>
    </Container>
  );
};
export default CurrencyAmountLayout;

// IRepository
import { IIterable } from '../../../../../interface/Iterator/IIterable';

// DomainObject
import BankWithdrawal from '../BankWithdrawal/BankWithdrawal';

class BankWithdrawals implements IIterable<BankWithdrawal> {
  private bankWithdrawals: BankWithdrawal[] = [];

  public add = (bankWithdrawal: BankWithdrawal) => {
    this.bankWithdrawals.push(bankWithdrawal);
  };

  public head = () => this.bankWithdrawals[0];

  public map = <T>(callback: (bankWithdrawal: BankWithdrawal) => T) => {
    return this.bankWithdrawals.map((bankWithdrawal) =>
      callback(bankWithdrawal),
    );
  };

  public forEach = (callback: (bankWithdrawal: BankWithdrawal) => void) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const bankWithdrawal of this.bankWithdrawals) {
      // eslint-disable-next-line no-await-in-loop
      callback(bankWithdrawal);
    }
  };

  public forEachAsync = async (
    callback: (bankWithdrawal: BankWithdrawal) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const bankWithdrawal of this.bankWithdrawals) {
      // eslint-disable-next-line no-await-in-loop
      await callback(bankWithdrawal);
    }
  };
}
export default BankWithdrawals;

// Lib
import { floor } from 'lodash';
import {
  addByDecimal,
  minusByDecimal,
  timesByDecimal,
} from '../../../utils/helpers/calculationHelper';
import { convertMoneyRoundDown } from '../../../utils/helpers/currencyHelper';

// Types
import { CryptoCurrencyTradeCreateParameter } from '../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyTradeCreateUseCase/ICryptoCurrencyTradeCreateUseCase';
import { CryptoCurrencyTradeTransactionSeedProps } from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed.type';
import { CryptoCurrencyTradeType } from '../../../types/CryptoCurrency.type';

// IService
import ICryptoCurrencyTradeCreateService from '../../../useCases/cryptoCurrency/cryptoCurrencyTrade/interface/ICryptoCurrencyTradeCreateService';

// IRepository
import { ICryptoCurrencyTradeCreateRepository } from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/ICryptoCurrencyTradeRepository';

// Domain
import CryptoCurrencyTradeLimit from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeLimit/CryptoCurrencyTradeLimit/CryptoCurrencyTradeLimit';
import CryptoCurrencyTradeTransactionSeedFactory from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeedFactory';
import CryptoCurrencyTradeTransactionSeed from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed';
import CryptoCurrencyWallet from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet';
import UserVerified from '../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';

class CryptoCurrencyTradeCreateService
  implements ICryptoCurrencyTradeCreateService {
  private cryptoCurrencyTradeCreateRepository: ICryptoCurrencyTradeCreateRepository;

  constructor(
    cryptoCurrencyTradeCreateRepository: ICryptoCurrencyTradeCreateRepository,
  ) {
    this.cryptoCurrencyTradeCreateRepository = cryptoCurrencyTradeCreateRepository;
  }

  public create = (
    user: UserVerified,
    cryptoCurrencyTradeLimit: CryptoCurrencyTradeLimit,
    cryptoCurrencyWallet: CryptoCurrencyWallet,
    params: CryptoCurrencyTradeCreateParameter,
  ) => {
    const amount =
      params.tradeType === CryptoCurrencyTradeType.buy
        ? params.receiveAmount
        : params.payAmount;

    const amountRoundedDown = floor(
      amount,
      cryptoCurrencyTradeLimit.getProps().minOrderQtyDigit,
    );

    const balance =
      params.tradeType === CryptoCurrencyTradeType.buy
        ? addByDecimal(
            cryptoCurrencyWallet.getProps().balance,
            amountRoundedDown,
          )
        : minusByDecimal(
            cryptoCurrencyWallet.getProps().balance,
            amountRoundedDown,
          );

    const payAmount =
      params.tradeType === CryptoCurrencyTradeType.buy
        ? convertMoneyRoundDown(
            timesByDecimal(amountRoundedDown, params.rate),
            params.payCurrency,
          )
        : amountRoundedDown;

    const receiveAmount =
      params.tradeType === CryptoCurrencyTradeType.buy
        ? amountRoundedDown
        : convertMoneyRoundDown(
            timesByDecimal(amountRoundedDown, params.rate),
            params.receiveCurrency,
          );

    const props = {
      ...params,
      payAmount,
      receiveAmount,
      amount: amountRoundedDown,
      balance,
      descriptionParameter: 'tmp',
      tradeMargin: 0,
      userId: user.getId(),
    } as CryptoCurrencyTradeTransactionSeedProps;

    return CryptoCurrencyTradeTransactionSeedFactory.create(props);
  };

  public executeCreate = async (
    cryptoCurrencyTradeSeed: CryptoCurrencyTradeTransactionSeed,
  ) => {
    const cryptoCurrencyTrade = await this.cryptoCurrencyTradeCreateRepository.create(
      cryptoCurrencyTradeSeed,
    );

    return cryptoCurrencyTrade;
  };
}

export default CryptoCurrencyTradeCreateService;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Error
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import { BankFutureTransactionStatusType } from '../../../../types/BankAccountTransaction.type';

// Style
import { TextStyle } from './style';

// Constant
import color from '../../../atoms/utils/const/color';

// LayoutComponent
import { FlexBoxRow } from '../../../atoms/utils/layout/Box/FlexBox';
import { TextPrimary } from '../../../atoms/text2/Text2';

export interface BankFutureTransactionStatusTypeBadgeProps {
  status: BankFutureTransactionStatusType;
}

const BADGE_BACKGROUND_COLOR = {
  [BankFutureTransactionStatusType.paid]: color.status.pastelGreen,
  [BankFutureTransactionStatusType.waitingPaymentDate]: color.status.mischka,
  [BankFutureTransactionStatusType.cancelled]: color.status.liver,
};

const BankFutureTransactionStatusTypeBadge: FC<BankFutureTransactionStatusTypeBadgeProps> = ({
  status,
}: BankFutureTransactionStatusTypeBadgeProps) => {
  const { t } = useTranslation();

  const backgroundColor = BADGE_BACKGROUND_COLOR[status];

  if (!backgroundColor)
    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_SWITCH,
      {
        value: status,
        place: `UserStatusBadge`,
      },
    );

  return (
    <FlexBoxRow
      alignItems="center"
      height={40}
      padding="5 20"
      width="auto"
      theme={{ borderRadius: 20 }}
      style={{ backgroundColor, textAlign: 'center' }}
    >
      <TextPrimary theme={TextStyle}>
        {t(`status.bankFutureTransaction.${status}`)}
      </TextPrimary>
    </FlexBoxRow>
  );
};

export default BankFutureTransactionStatusTypeBadge;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash';

// ValueObject
import CorporateInformation from '../../../../../../value/verification/kycRequest/KycRequestContent/CorporateInformation/CorporateInformation';

export const isValid = (errors: any, watch: any) =>
  isEmpty(errors) &&
  !isEmpty(watch('corporateName')) &&
  !isEmpty(watch('corporateRegistryUrl'));

export const getDefaultValues = (
  corporateInformation: CorporateInformation,
) => {
  const props = corporateInformation.getProps();

  return {
    corporateName: props.corporateName,
    corporateRegistryUrl: props.corporateRegistryUrl,
  };
};

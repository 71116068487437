import React, { FC } from 'react';

// Image
import VALID from '../../../../../assets/images/valid.svg';

// LayoutOmponent
import Box from '../../../utils/layout/Box/Box';

export interface DebitCardValidThruImageProps {
  layout: {
    height: number | string;
    width: number | string;
  };
}

const DebitCardValidThruImage: FC<DebitCardValidThruImageProps> = ({
  layout,
}: DebitCardValidThruImageProps) => {
  return (
    <Box {...layout}>
      <img src={VALID} alt="valid thru" width="100%" height="100%" />
    </Box>
  );
};
export default DebitCardValidThruImage;

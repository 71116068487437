// Types
import { CryptoCurrencyTransactionProps } from './CryptoCurrencyTransaction.type';
import {
  CryptoCurrencyTransactionType,
  CryptoCurrencyTransactionStatusType,
} from '../../../../../types/CryptoCurrencyTransaction.type';

// DomainObject
import CryptoCurrencyTransactionId from '../../../../idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionId';
import Transaction from '../../../transaction/Transaction/Transaction';

abstract class CryptoCurrencyTransaction extends Transaction {
  protected address: string;

  protected userId: string;

  protected descriptionParameter: string;

  protected amount: number;

  protected balance: number;

  protected commonId: string;

  protected createdAt: number;

  protected updatedAt: number;

  protected props: CryptoCurrencyTransactionProps;

  constructor(
    cryptoCurrencyTransactionId: CryptoCurrencyTransactionId,
    props: CryptoCurrencyTransactionProps,
  ) {
    super(cryptoCurrencyTransactionId);
    this.address = props.address;
    this.userId = props.userId;
    this.amount = props.amount;
    this.balance = props.balance;
    this.commonId = props.commonId;
    this.descriptionParameter = props.descriptionParameter;
    this.createdAt = props.createdAt || Date.now();
    this.updatedAt = props.updatedAt || Date.now();
    this.props = props;
  }

  public getProps() {
    return this.props;
  }

  abstract getTransactionType: () => CryptoCurrencyTransactionType;

  abstract getFee: () => number;

  abstract getStatus: () => CryptoCurrencyTransactionStatusType;
}

export default CryptoCurrencyTransaction;

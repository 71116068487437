import { useState } from 'react';

// DomainObject
import DebitCardV2s from './DebitCardV2s';

export const useDebitCardV2s = (defaultValue?: DebitCardV2s) => {
  const [debitCardV2s, setDebitCardV2s] = useState<DebitCardV2s>(
    defaultValue ?? new DebitCardV2s(),
  );

  return {
    debitCardV2s,
    setDebitCardV2s,
  };
};

// import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';

// // Types
// import { GlobalStateType } from '../../../../redux/store';
// import { DebitCardV2State } from '../../../../redux/reducers/debitCardV2sReducer';

// // DomainObject
// import DebitCardV2s from './DebitCardV2s';
// import DebitCardV2Factory from '../DebitCardV2/DebitCardV2Factory';

// // State -> Objects
// const convertStateToObjects = (state: DebitCardV2State) =>
//   Object.keys(state).reduce((objs, id) => {
//     if (!state[id]) return objs;

//     objs.add(DebitCardV2Factory.create(id, state[id]));
//     return objs;
//   }, new DebitCardV2s());

// // DebitCardV2s
// export const useDebitCardV2s = () => {
//   const [debitCardV2s, setDebitCardV2s] = useState<DebitCardV2s>(
//     new DebitCardV2s(),
//   );
//   const state = useSelector(
//     (globalState: GlobalStateType) => globalState.debitCardV2sStore.id,
//   );

//   useEffect(() => {
//     const domainObjects = convertStateToObjects(state);
//     setDebitCardV2s(domainObjects);
//   }, [state]);

//   return {
//     debitCardV2s,
//     setDebitCardV2s,
//   };
// };

// // DebitCardV2s (filter By UserId)
// export const useDebitCardV2sByUserId = (userId?: string) => {
//   const [debitCardV2s, setDebitCardV2s] = useState<DebitCardV2s>(
//     new DebitCardV2s(),
//   );
//   const state = useSelector(
//     (globalState: GlobalStateType) =>
//       globalState.debitCardV2sStore?.userId[userId || ''],
//   );

//   useEffect(() => {
//     if (!userId || !state) return;

//     const domainObjects = convertStateToObjects(state);
//     setDebitCardV2s(domainObjects);
//   }, [userId, state]);

//   return {
//     debitCardV2s,
//     setDebitCardV2s,
//   };
// };

import { useState } from 'react';
import ResetAuthenticatorRequest from './ResetAuthenticatorRequest';

export const useResetAuthenticatorRequest = () => {
  const [resetAuthenticatorRequest, setResetAuthenticatorRequest] = useState<
    ResetAuthenticatorRequest
  >();

  return {
    resetAuthenticatorRequest,
    setResetAuthenticatorRequest,
  };
};

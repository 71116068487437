import React, { FC, useContext } from 'react';
import UpdateEmailContext from '../../../enhancers/settings/UpdateEmailProvider/UpdateEmailContext';
import ConfirmUpdateEmailContext from '../../../enhancers/settings/ConfirmUpdateEmailProvider/ConfirmUpdateEmailContext';
import { SUCCESS } from '../../../config/constants/requestState';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import EmailEditTemplate from '../../../components/templates/settings/EmailEditTemplate';
import EmailEditConfirmTemplate from '../../../components/templates/settings/EmailEditConfirmTemplate';
import EmailEditCompleteTemplate from '../../../components/templates/settings/EmailEditCompleteTemplate';

const EmailEditPage: FC = () => {
  const { updateEmailState } = useContext(UpdateEmailContext);
  const { confirmUpdateEmailState } = useContext(ConfirmUpdateEmailContext);

  const TemplateComponent: FC = () => {
    if (confirmUpdateEmailState === SUCCESS)
      return <EmailEditCompleteTemplate />;

    if (updateEmailState === SUCCESS) return <EmailEditConfirmTemplate />;

    return <EmailEditTemplate />;
  };

  return (
    <DashboardTemplate>
      <TemplateComponent />
    </DashboardTemplate>
  );
};

export default EmailEditPage;

// IService
import IConsentFormUpsertService from '../../../useCases/verification/consentForm/IConsentFormUpdateService';

// IRepository
import { IConsentFormUpdateRepository } from '../../../domain/verification/consentForm/IConsentFormRepository';

// DomainObject
import ConsentForm from '../../../domain/verification/consentForm/ConsentForm/ConsentForm';

class ConsentFormUpdateService implements IConsentFormUpsertService {
  private consentFormUpdateRepository: IConsentFormUpdateRepository;

  constructor(consentFormUpdateRepository: IConsentFormUpdateRepository) {
    this.consentFormUpdateRepository = consentFormUpdateRepository;
  }

  public updateConsentForm = async (consentForm: ConsentForm) => {
    await this.consentFormUpdateRepository.updateConsentForm(consentForm);

    return consentForm;
  };
}

export default ConsentFormUpdateService;

import React, {
  FC,
  // useContext
} from 'react';
// import { useTranslation } from 'react-i18next';
// import AssetDebitCardContext from '../../../../../domain/asset/assetDebitCard/AssetDebitCard/AssetDebitCardContext';

// Style
import { CardLayout } from './style';

// Component
// import AssetFiatCurrencyCard from '../../../../molecules/card/asset/AssetFiatCurrencyCard';
import AssetEmptyCard from '../../../../molecules/card/asset/AssetEmptyCard';

const AssetDebitCardCard: FC = () => {
  // const { t } = useTranslation();
  // const { assetDebitCard } = useContext(AssetDebitCardContext);

  // const props = assetDebitCard?.getProps();

  return (
    <AssetEmptyCard layout={CardLayout} />
    // <AssetFiatCurrencyCard
    //   // eslint-disable-next-line
    //   balance={props && props.single.balance}
    //   label={t(`atoms.label.balance`)}
    //   title={t(`organisms.AssetCard.debitCard`)}
    //   layout={CardLayout}
    // />
  );
};

export default AssetDebitCardCard;

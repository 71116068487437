import Joi from '@hapi/joi';
import {
  KycStatusType,
  ProofOfResidencyDocumentType,
} from '../../../../../types/KycRequest.type';
import {
  AddressPropsAllowEmptyFormat,
  AddressProps,
} from '../../../../personalInformation/address/Address/Address/Address.type';
import {
  KycRequestContentProps,
  KycRequestContentPropsFormat,
} from '../KycRequestContent/KycRequestContent.type';

export interface ProofOfResidencyProps extends KycRequestContentProps {
  country: string;

  addressLine1: string;

  addressLine2?: string;

  city: string;

  state: string;

  postalCode: string;

  document: {
    type: ProofOfResidencyDocumentType;

    documentUrl1: string;

    documentUrl2?: string;
  };
}

export const ProofOfResidencyPropsFormat = {
  ...KycRequestContentPropsFormat,

  ...AddressPropsAllowEmptyFormat,

  document: Joi.object({
    type: Object.entries(ProofOfResidencyDocumentType)
      .reduce((joi, [, value]) => joi.valid(value), Joi.string())
      .allow(''),

    documentUrl1: Joi.string().allow(''),

    documentUrl2: Joi.string().empty(''),
  }),
};

export interface ProofOfResidencyUserInput extends AddressProps {
  document: {
    type: ProofOfResidencyDocumentType;
    documentUrl1: string;
    documentUrl2: string;
  };
}

export const ProofOfResidencyInitialProps = {
  country: '',

  addressLine1: '',

  addressLine2: '',

  city: '',

  state: '',

  postalCode: '',

  document: {
    type: ProofOfResidencyDocumentType.driversLicense,

    documentUrl1: '',
  },

  status: KycStatusType.disabled,
};

import { CryptoCurrencyType } from '../../../../types/CryptoCurrency.type';
import { CryptoCurrencyAvailableProps } from './CryptoCurrencyAvailable.type';

class CryptoCurrencyAvailable {
  private currency: CryptoCurrencyType;

  private isAvailable: boolean;

  constructor(
    currency: CryptoCurrencyType,
    props: CryptoCurrencyAvailableProps,
  ) {
    this.currency = currency;
    this.isAvailable = props.isAvailable;
  }

  public getId = () => this.currency;

  public getProps() {
    return {
      isAvailable: this.isAvailable,
    };
  }
}
export default CryptoCurrencyAvailable;

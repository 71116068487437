/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';

// Style
import {
  OptionContainer,
  SingleValueContainer,
  MinDepositStyle,
  Hover,
  DescriptionStyle,
} from './style';

// Constant
import {
  BASE_CURRENCY_SYMBOL,
  INTEREST_RATE_PRECISION,
} from '../../../../../../config/constants/business';

// LayoutComponent
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';

// Component
import { TextSecondary } from '../../../../../atoms/text2/Text2';
import LabeledCurrencyAmount from '../../../../textGroup/LabeledCurrencyAmount';

const MOLECULES_ID = `molecules.select.timeDeposit.timeDepositContent`;

const TimeDepositOptionContent: FC = (props: any) => {
  const { t } = useTranslation();

  return (
    <>
      <FlexBoxRow>
        <TextSecondary theme={DescriptionStyle}>
          Interest:{' '}
          {t(`${MOLECULES_ID}.interestRate`, {
            interestRate: Number(props.data.interestRate).toFixed(
              INTEREST_RATE_PRECISION,
            ),
          })}
          &nbsp; Term: {t(`${MOLECULES_ID}.month`, { month: props.data.term })}
        </TextSecondary>
      </FlexBoxRow>
      <FlexBoxRow paddingRight={65} justifyContent="flex-end" width="auto">
        <div>
          <LabeledCurrencyAmount
            amount={props.data.minDeposit}
            currency={BASE_CURRENCY_SYMBOL}
            direction="row"
            isSymbol
            label={t(`${MOLECULES_ID}.minDeposit.label`)}
            marginBetween={10}
            theme={{
              amount: MinDepositStyle,
              currency: MinDepositStyle,
              label: MinDepositStyle,
            }}
          />
        </div>
      </FlexBoxRow>
    </>
  );
};

const TimeDepositSingleValueContainer: FC = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <SingleValueContainer>
        <TimeDepositOptionContent {...props} />
      </SingleValueContainer>
    </components.SingleValue>
  );
};

const TimeDepositOptionContainer: FC = (props: any) => {
  return (
    <Hover>
      <components.Option {...props}>
        <OptionContainer>
          <TimeDepositOptionContent {...props} />
        </OptionContainer>
      </components.Option>
    </Hover>
  );
};

export { TimeDepositSingleValueContainer, TimeDepositOptionContainer };

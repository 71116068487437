import { UserRequestStatusType } from '../../../../types/UserRequest.type';
import { ResignUpRequestProps } from './ResignUpRequest.type';

class ResignUpRequest {
  private id: string;

  private expiredAt: number;

  protected lastMailSentAt?: number;

  private oobCode: string;

  private reregistrationCode: string;

  private status: UserRequestStatusType;

  private createdAt: number;

  private updatedAt: number;

  private props: ResignUpRequestProps;

  constructor(id: string, props: ResignUpRequestProps) {
    this.id = id;
    this.expiredAt = props.expiredAt;
    this.lastMailSentAt = props.lastMailSentAt;
    this.oobCode = props.oobCode;
    this.reregistrationCode = props.reregistrationCode;
    this.status = props.status;
    this.createdAt = props.createdAt || Date.now();
    this.updatedAt = props.updatedAt || Date.now();
    this.props = props;
  }

  public getId = () => this.id;

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public isExpired = () =>
    this.expiredAt < Date.now() ||
    this.status === UserRequestStatusType.confirmed;
}
export default ResignUpRequest;

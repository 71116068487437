import React, { FC, useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

// Context
import CountdownContext from '../../../../../../enhancers/utils/Countdown/CountdownContext';
import CryptoCurrencyTradeCreateContext from '../../../../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyTradeCreateUseCase/CryptoCurrencyTradeCreateContext';
import CryptoCurrencyTradePageStateContext from '../../../../../../enhancers/pageState/cryptoCurrency/CryptoCurrencyTradePageState/CryptoCurrencyTradePageStateContext';
import CryptoCurrencyTradeTransactionSeedContext from '../../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeedContext';
import { FormInputAuthenticatorType } from '../../../../../molecules/form/formInput/textField/FormInputAuthenticator/FormInputAuthenticator.type';

// Constant
import { IS_LOADING } from '../../../../../../config/constants/requestState';

// Components
import FormInputAuthenticator from '../../../../../molecules/form/formInput/textField/FormInputAuthenticator';
import CryptoCurrencyTradeConfirmButtonGroup from '../../../../../molecules/button/ButtonGroup/CryptoCurrencyTradeConfirmButtonGroup';
import FormCryptoCurrencyTradeConfirmLayout from './FormCryptoCurrencyTradeConfirmLayout';

const FormCryptoCurrencyTradeConfirm: FC = () => {
  const { requestState, executeCreateCryptoCurrencyTrade } = useContext(
    CryptoCurrencyTradeCreateContext,
  );
  const { cryptoCurrencyTradeTransactionSeed } = useContext(
    CryptoCurrencyTradeTransactionSeedContext,
  );
  const { count } = useContext(CountdownContext);

  // PageState
  const { setPageState } = useContext(CryptoCurrencyTradePageStateContext);

  const methods = useForm<FormInputAuthenticatorType>({
    mode: 'onChange',
  });

  const {
    formState: { isValid },
    watch,
  } = methods;

  useEffect(() => {
    if (count === 0) setPageState('initial');
    // eslint-disable-next-line no-console
  }, [count, setPageState]);

  if (!cryptoCurrencyTradeTransactionSeed) return <></>;

  return (
    <FormProvider {...methods}>
      <FormCryptoCurrencyTradeConfirmLayout>
        <FormInputAuthenticator key="twoFactorAuth" state={requestState} />
        <CryptoCurrencyTradeConfirmButtonGroup
          key="buttonGroup"
          tradeType={cryptoCurrencyTradeTransactionSeed.getTradeType()}
          onClickLeft={() => {
            executeCreateCryptoCurrencyTrade(watch('code'), () => {
              setPageState('complete');
            });
          }}
          leftButtonDisabled={!isValid}
          isLoading={requestState === IS_LOADING}
          onClickRight={() => {
            setPageState('initial');
          }}
        />
      </FormCryptoCurrencyTradeConfirmLayout>
    </FormProvider>
  );
};

export default FormCryptoCurrencyTradeConfirm;

// Error
import InvalidArgumentError from '../../../../utils/errors/InvalidArgumentError';

// Type
import {
  CryptoCurrencyProps,
  CryptoCurrencyType,
} from '../../../../types/CryptoCurrency.type';

// IService
import ICryptoCurrencyWalletQueryService from '../../../../useCases/cryptoCurrency/cryptoCurrencyWallet/interface/ICryptoCurrencyWalletQueryService';

// IRepository
import ICryptoCurrencyWalletRepository from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/ICryptoCurrencyWalletRepository';
import IBitcoinWalletRepository from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/BitcoinWallet/IBitcoinWalletRepository';
import ILitecoinTestnetWalletRepository from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/LitecoinTestnetWallet/ILitecoinTestnetWalletRepository';

// Service
import BitcoinWalletQueryService from '../bitcoinWallet/BitcoinWalletQueryService';
import LiteCoinTestnetWalletQueryService from '../litecoinTestnetWallet/LitecoinTestnetWalletQueryService';

class CryptoCurrencyWalletQueryServiceFactory {
  static create = (
    cryptoCurrencyWalletRepository: ICryptoCurrencyWalletRepository,
  ): ICryptoCurrencyWalletQueryService => {
    const interfaceName = cryptoCurrencyWalletRepository.getInterface();

    switch (interfaceName) {
      case CryptoCurrencyProps[CryptoCurrencyType.BTC].repositoryInterface:
        return new BitcoinWalletQueryService(
          cryptoCurrencyWalletRepository as IBitcoinWalletRepository,
        );
      case CryptoCurrencyProps[CryptoCurrencyType.LTCT].repositoryInterface:
        return new LiteCoinTestnetWalletQueryService(
          cryptoCurrencyWalletRepository as ILitecoinTestnetWalletRepository,
        );
      default:
        throw new InvalidArgumentError(
          'Unknown cryptoCurrencyWalletQueryService is found.',
        );
    }
  };
}
export default CryptoCurrencyWalletQueryServiceFactory;

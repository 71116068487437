// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ResignUpRequestContext from '../../../../domain/userRequest/resignUpRequest/ResignUpRequest/ResignUpRequestContext';
import UserReregistrationContext from '../../../../domain/public/user/User/UserReregistration/UserReregistrationContext';

// Constant
import { RE_REGISTRATION } from '../../../../config/constants/pageId.json';

// Style
import { DescriptionStyle } from './style';

// Component
import { FrontPageTitle } from '../../../atoms/headLine/HeadLine';
import { TextSecondary } from '../../../atoms/text2/Text2';
import FormReregistration from '../../../organisms/form/front/FormReregistration';
import RegisterTemplateLayoutLayout from './ReregistrationTemplateLayout';

const ReregistrationTemplate: FC = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserReregistrationContext);
  const { resignUpRequest } = useContext(ResignUpRequestContext);

  if (!user || !resignUpRequest) return <></>;

  return (
    <RegisterTemplateLayoutLayout>
      <FrontPageTitle key="title">
        {t(`${RE_REGISTRATION}.title`)}
      </FrontPageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${RE_REGISTRATION}.description`)}
      </TextSecondary>
      <FormReregistration key="form" />
    </RegisterTemplateLayoutLayout>
  );
};

export default ReregistrationTemplate;

import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDateSelect } from './useDateSelect';

// Component
import SelectBox from '../../../../../atoms/select/SelectBox';
import {
  DefaultOptionContainer,
  DefaultSingleValueContainer,
} from '../../../option/optionContainer/DefaultOptionContainer';
import DateSelectBoxLayout from './DateSelectBoxLayout';

export interface DateSelectBoxProps {
  name: string;
  dateStart?: { year: number; month: number; day: number }; // timestamp
  dateEnd?: { year: number; month: number; day: number }; // timestamp
}

const DateSelectBox: FC<DateSelectBoxProps> = ({
  name,
  dateStart = { year: 0, month: 0, day: 0 },
  dateEnd = { year: 0, month: 0, day: 0 },
}: DateSelectBoxProps) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();

  const {
    setYear,
    setMonth,
    setDay,
    dayOptions,
    monthOptions,
    yearOptions,
  } = useDateSelect(dateStart, dateEnd);

  useEffect(() => {
    setYear(watch(`${name}Year`));
    setMonth(watch(`${name}Month`));
    setDay(watch(`${name}Day`));
    // eslint-disable-next-line
  }, [watch, name, setYear, setMonth, setDay]);

  return (
    <DateSelectBoxLayout>
      <SelectBox
        key="day"
        borderColor="#d6ddec"
        dropdownColor="#212F41"
        isSearchable={true}
        marginLeft={`${0 / 16}rem`}
        minHeight={`${50 / 16}rem`}
        options={dayOptions}
        onSelect={(value: string) => {
          setValue(`${name}Day`, value);
        }}
        placeholder={t(`molecules.select.placeholder.day`)}
        placeholderColor="#D5D0DA"
        value={watch(`${name}Day`)}
        OptionComponent={DefaultOptionContainer}
        SingleValueComponent={DefaultSingleValueContainer}
      />
      <SelectBox
        key="month"
        borderColor="#d6ddec"
        dropdownColor="#212F41"
        isSearchable={true}
        marginLeft={`${0 / 16}rem`}
        minHeight={`${50 / 16}rem`}
        options={monthOptions}
        onSelect={(value: string) => {
          setValue(`${name}Month`, value);
        }}
        placeholder={t(`molecules.select.placeholder.month`)}
        placeholderColor="#D5D0DA"
        value={watch(`${name}Month`)}
        OptionComponent={DefaultOptionContainer}
        SingleValueComponent={DefaultSingleValueContainer}
      />
      <SelectBox
        key="year"
        borderColor="#d6ddec"
        dropdownColor="#212F41"
        isSearchable={true}
        marginLeft={`${0 / 16}rem`}
        minHeight={`${50 / 16}rem`}
        options={yearOptions}
        onSelect={(value: string) => {
          setValue(`${name}Year`, value);
        }}
        placeholder={t(`molecules.select.placeholder.year`)}
        placeholderColor="#D5D0DA"
        value={watch(`${name}Year`)}
        OptionComponent={DefaultOptionContainer}
        SingleValueComponent={DefaultSingleValueContainer}
      />
    </DateSelectBoxLayout>
  );
};

export default DateSelectBox;

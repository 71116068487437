import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CancelButton,
  CancelButtonTextStyle,
} from '../../../../atoms/button/Button2';
import { TextPrimary } from '../../../../atoms/text2/Text2';
import CryptoCurrencyTradeExecuteButton from '../../../../atoms/button/CryptoCurrencyTradeExecuteButton';
import { CryptoCurrencyTradeType } from '../../../../../types/CryptoCurrency.type';

import CryptoCurrencyTradeConfirmButtonGroupLayout from './CryptoCurrencyTradeConfirmButtonGroupLayout';

export type CryptoCurrencyTradeConfirmButtonGroupProps = {
  tradeType: CryptoCurrencyTradeType;
  leftButtonDisabled: boolean;
  isLoading: boolean;
  onClickLeft: () => void;
  onClickRight: () => void;
};

const CryptoCurrencyTradeConfirmButtonGroup: FC<CryptoCurrencyTradeConfirmButtonGroupProps> = ({
  tradeType,
  leftButtonDisabled,
  isLoading,
  onClickLeft = () => undefined,
  onClickRight = () => undefined,
}: CryptoCurrencyTradeConfirmButtonGroupProps) => {
  const { t } = useTranslation();
  return (
    <CryptoCurrencyTradeConfirmButtonGroupLayout>
      <CryptoCurrencyTradeExecuteButton
        tradeType={tradeType}
        key="left"
        width={470}
        height={75}
        isLoading={isLoading}
        disabled={leftButtonDisabled}
        onClick={onClickLeft}
      />
      <CancelButton
        key="right"
        width={470}
        height={75}
        onClick={onClickRight}
        disabled={isLoading}
      >
        <TextPrimary theme={CancelButtonTextStyle}>
          {t(`atoms.button.cancel`)}
        </TextPrimary>
      </CancelButton>
    </CryptoCurrencyTradeConfirmButtonGroupLayout>
  );
};
export default CryptoCurrencyTradeConfirmButtonGroup;

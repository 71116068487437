import color from '../../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../../atoms/utils/const/fontWeight';

export const ButtonStyle = {
  backgroundColor: color.background.primary,
};

export const ButtonIconLayout = {
  icon: 22,
  marginBetween: 8,
};

export const ButtonIconStyle = {
  icon: {
    color: color.icon.white,
  },
  text: {
    fontSize: 20,
    color: color.text.white,
    fontWeight: fontWeight.regular,
  },
};

import CryptoCurrencyWithdrawal from '../CryptoCurrencyWithdrawal/CryptoCurrencyWithdrawal';
import { IIterable } from '../../../../../../interface/Iterator/IIterable';

class CryptoCurrencyWithdrawals implements IIterable<CryptoCurrencyWithdrawal> {
  private cryptoCurrencyWithdrawals: CryptoCurrencyWithdrawal[] = [];

  public add = (cryptoCurrencyWithdrawal: CryptoCurrencyWithdrawal) => {
    this.cryptoCurrencyWithdrawals.push(cryptoCurrencyWithdrawal);
  };

  public head = () => this.cryptoCurrencyWithdrawals[0];

  public map = <T>(
    callback: (cryptoCurrencyWithdrawal: CryptoCurrencyWithdrawal) => T,
  ) => {
    return this.cryptoCurrencyWithdrawals.map((cryptoCurrencyWithdrawal) =>
      callback(cryptoCurrencyWithdrawal),
    );
  };

  public forEach = (
    callback: (cryptoCurrencyWithdrawal: CryptoCurrencyWithdrawal) => void,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const cryptoCurrencyWithdrawal of this.cryptoCurrencyWithdrawals) {
      // eslint-disable-next-line no-await-in-loop
      callback(cryptoCurrencyWithdrawal);
    }
  };

  public forEachAsync = async (
    callback: (
      cryptoCurrencyWithdrawal: CryptoCurrencyWithdrawal,
    ) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const cryptoCurrencyWithdrawal of this.cryptoCurrencyWithdrawals) {
      // eslint-disable-next-line no-await-in-loop
      await callback(cryptoCurrencyWithdrawal);
    }
  };
}
export default CryptoCurrencyWithdrawals;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';
import { Width } from '../../../../../atoms/utils/layout/Width';
import { Container, LabelContainer } from './style';

export interface FormInputTextAreaLayoutProps {
  children: React.ReactElement[];
  inputWidth: string | number;
}

const FormInputTextAreaLayout: FC<FormInputTextAreaLayoutProps> = ({
  children,
  inputWidth,
}: FormInputTextAreaLayoutProps) => {
  const elements = ['label', 'input'];
  const [label, input] = getElementsFromKeys(children, elements);

  return (
    <Container>
      <LabelContainer>{label}</LabelContainer>
      <Width widthValue={inputWidth}>{input}</Width>
    </Container>
  );
};
export default FormInputTextAreaLayout;

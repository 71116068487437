import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const ContainerStyle = {
  borderLeft: `${6 / 16}rem solid ${color.border.primary}`,
};

export const LabelStyle = {
  fontSize: 22,
  color: color.text.primary,
  fontWeight: fontWeight.regular,
};

export const IDTextStyle = {
  fontSize: 29,
  color: color.text.primary,
  fontWeight: fontWeight.regular,
};

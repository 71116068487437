import React, { FC } from 'react';
import { TextPrimary } from '../../../../../atoms/text2/Text2';
import FormInputTextLabelColumnLayout from './FormInputTextLabelColumnLayout';
import TextField from '../../../TextField2';

interface FormInputTextLabelColumnProps {
  disabled?: boolean;
  errorMessage?: string;
  label: string;
  labelStyle: {
    fontSize: string | number;
    color: string;
    fontWeight: number;
  };
  marginBetween?: string | number;
  maxLength: number;
  name: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any;
  textAlign?: 'left' | 'right';
  type?: 'number' | 'password';
  width: string | number;
  value?: string;
}

const FormInputTextLabelColumn: FC<FormInputTextLabelColumnProps> = ({
  disabled = false,
  errorMessage,
  label,
  labelStyle,
  marginBetween = 0,
  maxLength,
  name,
  onChange,
  placeholder,
  register,
  textAlign = 'left',
  type,
  width,
  value,
}: FormInputTextLabelColumnProps) => {
  return (
    <FormInputTextLabelColumnLayout marginBetween={marginBetween}>
      <TextPrimary key="label" theme={labelStyle}>
        {label}
      </TextPrimary>
      <TextField
        key="input"
        disabled={disabled}
        errorMessage={errorMessage}
        name={name}
        maxLength={maxLength}
        onChange={onChange}
        placeholder={placeholder}
        register={register}
        textAlign={textAlign}
        textInputWidth={width}
        type={type}
        value={value}
      />
    </FormInputTextLabelColumnLayout>
  );
};

export default FormInputTextLabelColumn;

import React, { FC, useContext } from 'react';
import CountdownContext from '../../../utils/Countdown/CountdownContext';
import CryptoCurrencyRateContext from '../../../../domain/cryptoCurrency/cryptoCurrencyRate/CryptoCurrencyRate/CryptoCurrencyRate/CryptoCurrencyRateContext';
import CryptoCurrencyOptionContext from '../../../../components/molecules/selectBox/option/option/CryptoCurrencyOption/CryptoCurrencyOptionContext';
import RequestStateContext from '../../../../value/view/RequestState/RequestStateContext';

// Type
import { CryptoCurrencyType } from '../../../../types/CryptoCurrency.type';

// Hook
import { useCryptoCurrencyRateFetchUseCase } from './useCryptoCurrencyRateFetchUseCase';

// Component
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type CryptoCurrencyRateFetchProviderProps = {
  children: React.ReactNode;
};

const CryptoCurrencyRateFetchProvider: FC<CryptoCurrencyRateFetchProviderProps> = ({
  children,
}: CryptoCurrencyRateFetchProviderProps) => {
  const { cryptoCurrencyOption } = useContext(CryptoCurrencyOptionContext);

  const {
    state,
    setState,
    count,
    setCount,
    cryptoCurrencyRate,
  } = useCryptoCurrencyRateFetchUseCase(
    cryptoCurrencyOption || CryptoCurrencyType.BTC,
  );

  return (
    <RequestStateContext.Provider value={{ requestState: state }}>
      <CountdownContext.Provider value={{ count, setCount }}>
        <CryptoCurrencyRateContext.Provider value={{ cryptoCurrencyRate }}>
          <SystemErrorHandler state={state} setState={setState}>
            {children}
          </SystemErrorHandler>
        </CryptoCurrencyRateContext.Provider>
      </CountdownContext.Provider>
    </RequestStateContext.Provider>
  );
};
export default CryptoCurrencyRateFetchProvider;

import { TypeOfT } from '../../../../types/typeof/Translation.type';
import {
  required,
  minLength,
  includeLowercase,
  includeUppercase,
  includeNumber,
  includeSpecialCharacter,
  passwordEqualToConfirm,
} from '../validator';

const resetPasswordPasswordRegister = (t: TypeOfT) => {
  return {
    required: required('Password', t),
    minLength: minLength('Password', 10, t),
    validate: {
      includeLowercase: includeLowercase('Password', t),
      includeUppercase: includeUppercase('Password', t),
      includeNumber: includeNumber('Password', t),
      includeSpecialCharacter: includeSpecialCharacter('Password', t),
    },
  };
};

const resetPasswordConfirmPasswordRegister = (t: TypeOfT, password: string) => {
  return {
    validate: {
      passwordEqualToConfirm: passwordEqualToConfirm(password, t),
    },
  };
};

export { resetPasswordPasswordRegister, resetPasswordConfirmPasswordRegister };

import React, { FC } from 'react';
import Margin from '../../../../../atoms/utils/layout/Margin';
import ContentBox from '../../../../../atoms/utils/layout/Box/Box/ContentBox';
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';

import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

export interface InvitationCodeCardLayoutProps {
  children: React.ReactElement[];
}

const InvitationCodeCardLayout: FC<InvitationCodeCardLayoutProps> = ({
  children,
}: InvitationCodeCardLayoutProps) => {
  const elements = ['icon', 'title', 'badge', 'label', 'code'];
  const [icon, title, badge, label, code] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <ContentBox width={1200} padding={40} textAlign="left">
      <FlexBoxRow alignItems="center">
        {icon}
        <Margin left={30} />
        {title}
        <Margin left={12} />
        {badge}
      </FlexBoxRow>
      <Margin top={30} />
      {label}
      <Margin top={14} />
      {code}
    </ContentBox>
  );
};

export default InvitationCodeCardLayout;

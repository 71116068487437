// Lib
import { isEqualArbitraryOrder } from '../../../../../utils/helpers/arrayHelper';

// Error
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';

// Types
import { CryptoCurrencyType } from '../../../../../types/CryptoCurrency.type';
import { CryptoCurrencyWithdrawalProps } from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyWithdrawal/CryptoCurrencyWithdrawal/CryptoCurrencyWithdrawal.type';

// Collection
import CryptoCurrencyWithdrawalsCollection from '../../../../../infrastructure/firebase/firestore/collections/transaction/cryptoCurrencyTransaction/addresses/CryptoCurrencyWithdrawalsCollection';

// IRepository
import ICryptoCurrencyWithdrawalRepository from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyWithdrawal/ICryptoCurrencyWithdrawalRepository';

// DomainObject
import CryptoCurrencyWithdrawals from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyWithdrawal/CryptoCurrencyWithdrawals/CryptoCurrencyWithdrawals';
import CryptoCurrencyWithdrawalFactory from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyWithdrawal/CryptoCurrencyWithdrawal/CryptoCurrencyWithdrawalFactory';
import CryptoCurrencyTransactionIdFactory from '../../../../../domain/idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionIdFactory';

class CryptoCurrencyWithdrawalFirestore
  implements ICryptoCurrencyWithdrawalRepository {
  public findAllByFields = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: { [key: string]: any },
  ) => {
    if (isEqualArbitraryOrder(Object.keys(params), ['address', 'currency'])) {
      const transactionParams = params as { address: string; currency: string };

      const cryptoCurrencyWithdrawals = await this.findByCurrencyAndAddress(
        transactionParams,
      );

      return cryptoCurrencyWithdrawals;
    }

    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_SWITCH,
      {
        value: JSON.stringify(params),
        place: `CryptoCurrencyWithdrawalFirestore.findAllByFields`,
      },
    );
  };

  private findByCurrencyAndAddress = async (params: {
    address: string;
    currency: string;
  }) => {
    const cryptoCurrencyWithdrawalsCollection = new CryptoCurrencyWithdrawalsCollection(
      params.currency,
      params.address,
    );

    const docs = await cryptoCurrencyWithdrawalsCollection.fetchAll();

    return docs.reduce<CryptoCurrencyWithdrawals>(
      (cryptoCurrencyWithdrawals, doc) => {
        const transactionId = CryptoCurrencyTransactionIdFactory.createFromRawId(
          doc.id,
          doc.data()!.commonId,
        );

        cryptoCurrencyWithdrawals.add(
          CryptoCurrencyWithdrawalFactory.create(transactionId, {
            ...(doc.data() as CryptoCurrencyWithdrawalProps),
            userId: '',
            currency: params.currency as CryptoCurrencyType,
          }),
        );
        return cryptoCurrencyWithdrawals;
      },
      new CryptoCurrencyWithdrawals(),
    );
  };

  // public subscribeNewData = async (
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   params: { [key: string]: any },
  //   setter: UseStateType<CryptoCurrencyWithdrawal | undefined>,
  //   setState: UseStateType<string>,
  // ) => {
  //   if (isEqualArbitraryOrder(Object.keys(params), ['address', 'currency'])) {
  //     const transactionParams = params as { address: string; currency: string };

  //     this.subscribeNewDataByCurrencyAddress(
  //       transactionParams,
  //       setter,
  //       setState,
  //     );

  //     return;
  //   }

  //   throw new InvalidDataFoundError(
  //     `Invalid parameter ${JSON.stringify(params)} is specified at ${
  //       this.constructor.name
  //     }.subscribeNewData`,
  //   );
  // };

  // private subscribeNewDataByCurrencyAddress = async (
  //   params: { address: string; currency: string },
  //   setter: UseStateType<CryptoCurrencyWithdrawal | undefined>,
  //   setState: UseStateType<string>,
  // ) => {
  //   const cryptoCurrencyWithdrawalsCollection = new CryptoCurrencyWithdrawalsCollection(
  //     params.currency,
  //     params.address,
  //   );

  //   const converter = (doc: firestore.DocumentSnapshot) => {
  //     const transactionId = CryptoCurrencyTransactionIdFactory.createFromRawId(
  //       doc.id,
  //       doc.data()!.commonId,
  //     );

  //     return CryptoCurrencyWithdrawalFactory.create(transactionId, {
  //       ...(doc.data() as CryptoCurrencyWithdrawalProps),
  //       userId: '',
  //       address: '',
  //       currency: params.currency as CryptoCurrencyType,
  //     });
  //   };

  //   // eslint-disable-next-line no-useless-catch
  //   cryptoCurrencyWithdrawalsCollection.subscribeNewData(
  //     setter,
  //     converter,
  //     setState,
  //   );
  // };
}
export default CryptoCurrencyWithdrawalFirestore;

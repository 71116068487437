import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import CorporateInformationTemplate from '../../../components/templates/kycRequest/CorporateInformationTemplate';

const CorporateInformationPage: FC = () => {
  return (
    <DashboardTemplate>
      <CorporateInformationTemplate />
    </DashboardTemplate>
  );
};

export default CorporateInformationPage;

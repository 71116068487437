import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const CurrencyNameStyle = {
  fontSize: 41,
  color: color.primary,
  fontWeight: fontWeight.bold,
};

export const CurrencyShortNameStyle = {
  fontSize: 29,
  color: color.primary,
  fontWeight: fontWeight.medium,
  opacity: 0.5,
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Layout
import { FlexBoxRow } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface KycStatusBadgeLayoutProps {
  children: React.ReactElement[];
  backgroundColor: string;
  height: string | number;
}

const KycStatusBadgeLayout: FC<KycStatusBadgeLayoutProps> = ({
  children,
  backgroundColor,
  height,
}: KycStatusBadgeLayoutProps) => {
  const elements = ['icon', 'text'];
  const [icon, text] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow
      alignItems="center"
      justifyContent="center"
      padding="0 11"
      style={{
        backgroundColor,
        borderRadius: `${25 / 16}rem`,
      }}
      width="auto"
      height={height}
    >
      {icon}
      <Margin left={10} />
      {text}
    </FlexBoxRow>
  );
};

export default KycStatusBadgeLayout;

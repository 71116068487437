import React, { FC, useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
// import { useFormContext } from 'react-hook-form';
import { excerptFileName } from '../../../../utils/helpers/fileHelper';
import { useDropzoneArea } from './useDropzoneArea';
import DropzoneAreaContext from './DropzoneAreaContext';

// Lib
import { convertRem } from '../../../atoms/utils/helper/helper';

// Type
import { FileType, FileTypeName } from '../../../../types/File.type';

// Style
import {
  Container,
  ButtonStyle,
  ButtonTextStyle,
  ErrorMessageStyle,
  ExtensionsAllowedTextStyle,
} from './style';

// Component
import { FileUploadButton } from '../../../atoms/button/Button2';
import { TextSecondary } from '../../../atoms/text2/Text2';
import DropzonePreviewArea from '../DropzonePreview';
import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';
import DropzoneAreaLayout from './DropzoneAreaLayout';

export interface DropzoneAreaProps {
  acceptFileTypes: Array<FileType>;
  defaultFilePath: string | undefined;
  fileType?: FileType;
  maxFileSize?: number;
  name: string;
  urlName?: string;
  setFile: (file?: File) => void;
  width?: string | number;
}

const MOLECULE_ID = `molecules.dropzone.DropzoneArea`;

const DropzoneArea: FC<DropzoneAreaProps> = ({
  acceptFileTypes = [],
  defaultFilePath,
  fileType,
  maxFileSize = 2,
  setFile,
  name,
  urlName,
  width = '100%',
}: DropzoneAreaProps) => {
  const { t } = useTranslation();

  const {
    preview,
    setPreview,
    errorMessage,
    setErrorMessage,
    tmpFileName,
    setTmpFileName,
  } = useDropzoneArea();

  const [
    acceptFileTypeString,
    acceptFileExtensionString,
    errorExtensionString,
  ] =
    acceptFileTypes.length > 0
      ? acceptFileTypes.reduce(
          (array, type) => {
            const fileTypeName = FileTypeName[type];
            if (isEmpty(array[0])) return [type, fileTypeName, fileTypeName];
            return [
              `${array[0]} , ${type}`,
              `${array[1]}, ${fileTypeName}`,
              `${array[1]} or ${fileTypeName}`,
            ];
          },
          ['', '', ''],
        )
      : ['image/*, .pdf', 'JPEG, PNG, PDF', 'JPEG or PNG or PDF'];

  const onDrop = useCallback((acceptedFiles, errors) => {
    if (errors.length > 0) {
      const errorCode = errors[0].errors[0].code;
      const message =
        errorCode === 'file-invalid-type'
          ? t(`${MOLECULE_ID}.error.file-invalid-type`, {
              extensions: errorExtensionString,
            })
          : errors[0].errors[0].message;
      setErrorMessage(message);
    } else {
      setErrorMessage('');
      const tmpFile = acceptedFiles[0];

      if (tmpFile.size > maxFileSize * 1000 * 1000) {
        setErrorMessage(
          t(`${MOLECULE_ID}.error.over-size`, { max: maxFileSize }),
        );
        return;
      }

      if (tmpFile) {
        const url = URL.createObjectURL(tmpFile);
        setPreview(url);
        setFile(tmpFile);
        setTmpFileName(excerptFileName(tmpFile.name, 25));
      }
    }
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: acceptFileTypeString,
    onDrop,
    noClick: true,
  });

  // SetPreview if status === submitted
  useEffect(() => {
    if (!isEmpty(defaultFilePath)) {
      setPreview(defaultFilePath);
      setTmpFileName(t(`${MOLECULE_ID}.uploadedFileName`));
    }
  }, [defaultFilePath, setPreview, setTmpFileName, t]);

  return (
    <DropzoneAreaContext.Provider
      value={{
        preview,
        setPreview,
        errorMessage,
        setErrorMessage,
        tmpFileName,
        setTmpFileName,
        setFile,
      }}
    >
      <Container
        {...getRootProps({ className: 'dropzone' })}
        style={{ width: convertRem(width) }}
      >
        <input
          key="buttonInput"
          {...getInputProps()}
          type="file"
          id="file"
          style={{ display: 'none' }}
        />
        <DropzoneAreaLayout isErrorDisplay={!!errorMessage}>
          <DropzonePreviewArea
            key="preview"
            fileType={fileType}
            name={name}
            urlName={urlName}
          />
          <FileUploadButton
            key="button"
            width={196}
            height={50}
            onClick={open}
            theme={ButtonStyle}
          >
            <TextSecondary theme={ButtonTextStyle}>
              {t(`atoms.button.browseFiles`)}
            </TextSecondary>
          </FileUploadButton>
          <TextSecondary
            key="extensionsAllowed"
            theme={ExtensionsAllowedTextStyle}
          >
            <LineBreakReplaced
              text={t(`${MOLECULE_ID}.message`, {
                extensions: acceptFileExtensionString,
                beVerb: acceptFileTypes.length > 1 ? 'are' : 'is',
                maxFileSize,
              })}
            />
          </TextSecondary>
          <TextSecondary key="errorMessage" theme={ErrorMessageStyle}>
            {errorMessage}
          </TextSecondary>
        </DropzoneAreaLayout>
      </Container>
    </DropzoneAreaContext.Provider>
  );
};

export default DropzoneArea;

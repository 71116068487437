import React from 'react';

// Type
import { NotificationLogType } from '../../../../types/Notification.type';

// Constant
import { INITIAL } from '../../../../config/constants/requestState';

export interface NotificationLogCreateReadLogContextProps {
  requestState: string;

  createReadLog: (
    type: NotificationLogType,
    callback?: () => void,
  ) => Promise<void>;
}

const NotificationLogCreateReadLogContext = React.createContext<
  NotificationLogCreateReadLogContextProps
>({
  requestState: INITIAL,

  createReadLog: () => new Promise((resolve) => resolve(undefined)),
});

export default NotificationLogCreateReadLogContext;

import React, { FC, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import CSVDataContext from '../../../organisms/csv/CSVData/CSVDataContext';

// Style
import { ButtonStyle, ButtonTextStyle } from './style';

// Component
import { SolidButton } from '../Button2/Button';
import { TextPrimary } from '../../text2/Text2';

export interface CSVDownloadButtonProps {
  width?: string | number;
  height?: string | number;
  fileName: string;
  headers: { key: string; label: string }[];
}

const CSVDownloadButton: FC<CSVDownloadButtonProps> = ({
  width = '100%',
  height = '100%',
  fileName,
  headers,
}: CSVDownloadButtonProps) => {
  const { t } = useTranslation();
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link?: HTMLAnchorElement }
  >(null);

  const { csvData } = useContext(CSVDataContext);

  return (
    <SolidButton
      key="button"
      onClick={() => {
        if (csvLinkRef?.current?.link) {
          csvLinkRef.current.link.click();
        }
      }}
      width={width}
      height={height}
      theme={ButtonStyle}
    >
      <TextPrimary theme={ButtonTextStyle}>
        {t(`atoms.button.downloadHistory`)}
      </TextPrimary>
      <CSVLink
        data={csvData ?? []}
        headers={headers}
        filename={fileName}
        ref={csvLinkRef}
      />
    </SolidButton>
  );
};

export default CSVDownloadButton;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { Container, LeftContainer, RightContainer } from './style';

export interface ComponentConnectorProps {
  children: React.ReactElement[];
  direction?: 'row' | 'column';
  marginBetween: string;
}

const ComponentConnector: FC<ComponentConnectorProps> = ({
  children,
  direction = 'row',
  marginBetween,
}: ComponentConnectorProps) => {
  const elements = ['left', 'right'];
  const [left, right] = getElementsFromKeys(children, elements);

  let [flexDirection, marginType] = ['', ''];

  switch (direction) {
    case 'column':
      [flexDirection, marginType] = [direction, 'marginTop'];
      break;
    case 'row':
      [flexDirection, marginType] = [direction, 'marginLeft'];
      break;
    default: {
      // eslint-disable-next-line
      const _exhaustiveCheck: never = direction;
      return <></>;
    }
  }
  return (
    <Container style={{ flexDirection: flexDirection as 'column' | 'row' }}>
      <LeftContainer>{left}</LeftContainer>
      <RightContainer style={{ [marginType]: marginBetween }}>
        {right}
      </RightContainer>
    </Container>
  );
};
export default ComponentConnector;

import React, { FC, useContext } from 'react';
import { useProofOfResidencySubmitUseCase } from './useProofOfResidencySubmitUseCase';
import KycRequestContext from '../../../../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequestContext';
import ProofOfResidencyFileContext from '../../../../../fileHandler/verification/kycRequest/ProofOfResidencyFileProvider/ProofOfResidencyFileContext';
import ProofOfResidencySubmitContext from './ProofOfResidencySubmitContext';

// Error
import SystemErrorHandler from '../../../../../errorHandler/SystemErrorHandler';

// Type
import { FormInputProofOfResidencyContext } from '../../../../../../components/organisms/form/verification/kycRequest/FormProofOfResidency/FormProofOfResidency.type';

// DomainObject
import UserAuthorizedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

export type ProofOfResidencySubmitProviderProps = {
  children: React.ReactNode;
};

const ProofOfResidencySubmitProvider: FC<ProofOfResidencySubmitProviderProps> = ({
  children,
}: ProofOfResidencySubmitProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { kycRequest } = useContext(KycRequestContext);

  const {
    state,
    setState,
    submitProofOfResidency,
    formInputProofOfResidencyContext,
    proofOfResidencyFileContext,
  } = useProofOfResidencySubmitUseCase(kycRequest, userAuthorized);

  return (
    <FormInputProofOfResidencyContext.Provider
      value={formInputProofOfResidencyContext}
    >
      <ProofOfResidencySubmitContext.Provider
        value={{
          state,
          submitProofOfResidency,
        }}
      >
        <ProofOfResidencyFileContext.Provider
          value={proofOfResidencyFileContext}
        >
          <SystemErrorHandler state={state} setState={setState}>
            {children}
          </SystemErrorHandler>
        </ProofOfResidencyFileContext.Provider>
      </ProofOfResidencySubmitContext.Provider>
    </FormInputProofOfResidencyContext.Provider>
  );
};
export default ProofOfResidencySubmitProvider;

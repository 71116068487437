// React
import { useState } from 'react';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Type
import { ErrorCodeType } from '../../../../utils/errors/ErrorHandler/ErrorCode.type';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';
import { executeWithTimeout } from '../../../../utils/helpers/timeout';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';

// UseCase
import SignInUseCase from '../../../../useCases/authentication/signIn/SignInUseCase';

// Repository, Functions
import UserFirebaseAuth from '../../../../repository/public/user/UserFirebaseAuth';
import SignInRequestFunctions from '../../../../repository/userRequest/signInRequest/SignInRequestFunctions';
import { REQUEST_TIMEOUT } from '../../../../config/constants/business';

// DomainObject
import { useUserAuthorized } from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/useUserAuthorized';

export const useSignInUseCase = () => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   * Domain Object
   *
   * */
  const { userAuthorized, setUserAuthorized } = useUserAuthorized();

  /* *
   *
   * Repository
   *
   * */
  const userAuthentication = new UserFirebaseAuth();
  const signInRequestCreateRepository = new SignInRequestFunctions();

  /* *
   *
   * UseCase
   *
   * */
  const useCase = new SignInUseCase(
    userAuthentication,
    signInRequestCreateRepository,
  );

  /* *
   *
   * Method
   *
   * */
  const signIn = async (formInput: { email: string; password: string }) => {
    setState(IS_LOADING);

    try {
      // ---check onLine ---- //
      checkInternetConnection();

      const output = await executeWithTimeout(
        useCase.signIn(formInput.email, formInput.password),
        REQUEST_TIMEOUT,
      );

      setUserAuthorized(output);

      setState(SUCCESS);
    } catch (error) {
      if (
        error?.response?.data?.code ===
        ErrorCodeType.AUTHENTICATION_LOGIN_LOGIN_FAILED
      )
        setState(ErrorCodeType.FIREBASE_AUTH_WRONG_PASSWORD);
      else {
        const handler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    }
  };

  return {
    state,
    setState,
    signIn,
    userAuthorized,
    setUserAuthorized,
  };
};

import { Reducer } from 'redux';
import { CounterSagaAction } from '../actions/counter';
import CounterActionType from '../constants/counter.constant';

export interface CounterState {
  count: number;
}

export const initialState: CounterState = {
  count: 0,
};

const counterReducer: Reducer<CounterState, CounterSagaAction> = (
  state: CounterState = initialState,
  action: CounterSagaAction,
): CounterState => {
  switch (action.type) {
    case CounterActionType.SAGA_STORE_COUNTER:
      return {
        ...state,
        ...action.params,
      };
    default:
      return state;
  }
};

export default counterReducer;

import React, { FC } from 'react';

export interface DisplayProps {
  isDisplay: boolean;
  style?: { [key: string]: string };
  children: React.ReactNode;
}

const Display: FC<DisplayProps> = ({
  isDisplay,
  style,
  children,
}: DisplayProps) => {
  return (
    <span
      style={{
        ...style,
        display: isDisplay ? 'inline' : 'none',
      }}
    >
      {children}
    </span>
  );
};

export default Display;

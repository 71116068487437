import React from 'react';
import ContractTimeDeposit from './ContractTimeDeposit';

export interface ContractTimeDepositContextProps {
  contractTimeDeposit?: ContractTimeDeposit;
  setContractTimeDeposit: (contractTimeDeposit: ContractTimeDeposit) => void;
}

const ContractTimeDepositContext = React.createContext<
  ContractTimeDepositContextProps
>({
  contractTimeDeposit: undefined,
  setContractTimeDeposit: () => undefined,
});

export default ContractTimeDepositContext;

import { useState } from 'react';
import AssetCryptoCurrency from './AssetCryptoCurrency';

export const useAssetCryptoCurrency = (defaultValue?: AssetCryptoCurrency) => {
  const [assetCryptoCurrency, setAssetCryptoCurrency] = useState<
    AssetCryptoCurrency | undefined
  >(defaultValue);

  return {
    assetCryptoCurrency,
    setAssetCryptoCurrency,
  };
};

import React, { useState } from 'react';

export interface FormInputCherryVisa01ProofOfReceipt {
  selfieUrl?: string;
}

export const useFormInputCherryVisa01ProofOfReceipt = () => {
  const [formInput, setFormInput] = useState<
    FormInputCherryVisa01ProofOfReceipt
  >();

  return {
    formInput,
    setFormInput,
  };
};

export const FormInputCherryVisa01ProofOfReceiptContext = React.createContext<{
  formInput?: FormInputCherryVisa01ProofOfReceipt;
  setFormInput: (formInput?: FormInputCherryVisa01ProofOfReceipt) => void;
}>({
  formInput: undefined,
  setFormInput: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formInput?: FormInputCherryVisa01ProofOfReceipt,
  ) => undefined,
});

import React, { FC, useContext } from 'react';
import { useUpdatePassword } from './useUpdatePassword';
import UserAuthorizedContext from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import { UpdatePasswordContext } from './UpdatePasswordContext';
import SystemErrorHandler from '../../errorHandler/SystemErrorHandler';
import { UserStatusType } from '../../../types/User.type';

export type UpdatePasswordProviderProps = {
  children: React.ReactNode;
};

const UpdatePasswordProvider: FC<UpdatePasswordProviderProps> = ({
  children,
}: UpdatePasswordProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const email = userAuthorized?.getProps().email || '';
  const status = userAuthorized?.getProps().status || UserStatusType.initial;
  const { state, updatePassword, setState } = useUpdatePassword(
    email,
    status,
    userAuthorized,
  );

  return (
    <UpdatePasswordContext.Provider
      value={{
        updatePasswordState: state,
        setUpdatePasswordState: setState,
        updatePassword,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </UpdatePasswordContext.Provider>
  );
};
export default UpdatePasswordProvider;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

// Type
import { Gender } from '../../../../../../types/KycRequest.type';

// Constant
import {
  MIN_YEAR_DATE_OF_BIRTH,
  MAX_YEAR_DATE_OF_BIRTH,
} from '../../../../../../config/constants/business';

// Style
import { LabelStyle, RadioButtonLayout, RadioButtonTheme } from './style';

// Component
import DebitCardRequestFormContainer from '../../../common/DebitCardRequestFormContainer';
import FormInputRadioButton from '../../../../../molecules/form/formInput/radioButton/FormInputRadioButton';
import FormInputDateSelectBox from '../../../../../molecules/form/formInput/selectBox/verification/FormInputDateSelectBox';
import FormPersonalInformationInputLayout from './FormPersonalInformationInputLayout';
import FormInputCherryVisa01Country from '../../../../../molecules/form/formInput/selectBox/verification/FormInputCherryVisa01Country';
import FormInputOccupation from '../../../../../molecules/form/formInput/selectBox/verification/FormInputOccupation';

const FORM_ID = `organisms.form.debitCards.FormProofOfIdentity.personalInformation`;

const FormPersonalInformationInput: FC = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  return (
    <DebitCardRequestFormContainer order={1} formId={FORM_ID}>
      <FormPersonalInformationInputLayout>
        <FormInputRadioButton
          key="gender"
          labelWidth={200}
          labelProps={{
            label: t(`${FORM_ID}.gender.label`),
            theme: LabelStyle,
          }}
          radioButtonProps={{
            displayItems: [
              t(`gender.${Gender.male}`),
              t(`gender.${Gender.female}`),
            ],
            layout: RadioButtonLayout,
            items: [Gender.male, Gender.female],
            name: 'gender',
            selected: watch('gender'),
            theme: RadioButtonTheme,
          }}
        />
        <FormInputDateSelectBox
          key="dateOfBirth"
          name="dateOfBirth"
          labelWidth={200}
          label={t(`${FORM_ID}.dateOfBirth.label`)}
          dateStart={{
            year: MAX_YEAR_DATE_OF_BIRTH,
            month: 0,
            day: 0,
          }}
          dateEnd={{
            year: MIN_YEAR_DATE_OF_BIRTH,
            month: 0,
            day: 0,
          }}
        />
        <FormInputCherryVisa01Country
          key="nationality"
          labelWidth={200}
          label={t(`${FORM_ID}.nationality.label`)}
          inputWidth={600}
          name="nationality"
        />
        <FormInputOccupation
          key="occupation"
          labelWidth={200}
          label={t(`${FORM_ID}.occupation.label`)}
          inputWidth={600}
          name="occupation"
        />
      </FormPersonalInformationInputLayout>
    </DebitCardRequestFormContainer>
  );
};

export default FormPersonalInformationInput;

import { padStart } from 'lodash';
import moment from 'moment-timezone';
import { TIMEZONE } from '../../config/constants/business';
import TimeZonedTimeFactory from '../../value/datetime/TimeZonedTimeFactory';

const DoMMMMYYYYatLTFormat = (time: moment.Moment) => {
  return `${time.format('Do MMMM YYYY')} at ${time.format('LT')}`;
};

/*
 * function mailExecutedTimeFormat
 *
 * Return format '30th September 2020 at 09:00'
 */

export const mailExecutedTimeFormat = (time: moment.Moment) => {
  return DoMMMMYYYYatLTFormat(time);
};

/*
 * function getCurrentTimeTimezone
 *
 * Return moment.Moment
 */

export const getCurrentTimeTimezone = () => {
  return moment().tz(TIMEZONE);
};

/*
 * function hasPassedSpecifiedMinutes
 *
 * True
 *   If {duration} minutes has
 *   passed since from {fromTime (timestamp)}
 */
export const hasPassedSpecifiedMinutes = (
  fromTime: number,
  duration: number,
) => {
  const minutesPassed = moment().diff(moment(fromTime), 'minute');
  return minutesPassed >= duration;
};

/*
 * function getDaysDifference
 *
 * Return daysDifference (integer)
 *  difference between two timestamps
 *
 */
export const getDaysDifference = (startDay: string, endDay: string) => {
  const start = TimeZonedTimeFactory.createZeroOClock(startDay).getTimestamp();
  const end = TimeZonedTimeFactory.createZeroOClock(endDay).getTimestamp();

  const diffMillisecond = end - start;
  return diffMillisecond / (1000 * 60 * 60 * 24);
};

/*
 * function getDaysDifferenceFromToday
 *
 * Return daysDifference (integer)
 *  difference between two timestamps
 *
 */
export const getDaysDifferenceFromToday = (day: string) => {
  const today = moment().tz(TIMEZONE).format('YYYY-MM-DD');
  return getDaysDifference(today, day);
};

/*
 * function convertNumToMMM
 *
 * Return MMM Format (Jan, Feb etc...)
 *
 */
export const convertNumToMMM = (month: number) => {
  const mmmFormats = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return mmmFormats[month - 1];
};

/*
 * function yyyymmdd
 *
 * Return yyyymmdd format with separator
 *
 */

export const yyyymmdd = (
  year: string,
  month: string,
  day: string,
  separator?: string,
) => {
  const paddedMonth = padStart(month, 2, '0');
  const paddedDay = padStart(day, 2, '0');
  const definedSeparator = separator || '';

  return `${year}${definedSeparator}${paddedMonth}${definedSeparator}${paddedDay}`;
};

/*
 * function getYearFromyyyymmdd
 *
 * Return Year string
 *
 */

export const getYearFromyyyymmdd = (dateString: string, separator?: string) => {
  return dateString.split(separator ?? '')[0];
};

/*
 * function getMonthFromyyyymmdd
 *
 * Return Month string
 *
 */

export const getMonthFromyyyymmdd = (
  dateString: string,
  separator?: string,
) => {
  return dateString.split(separator ?? '')[1];
};

/*
 * function getDayFromyyyymmdd
 *
 * Return Day string
 *
 */

export const getDayFromyyyymmdd = (dateString: string, separator?: string) => {
  return dateString.split(separator ?? '')[2];
};

import CryptoCurrencyWallet from '../../CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet';
import { BitcoinWalletProps } from './BitcoinWallet.type';
// import SeverErrorFactory from '../../../../errors/ErrorFactory/ServerErrorFactory';

class BitcoinWallet extends CryptoCurrencyWallet {
  constructor(address: string, props: BitcoinWalletProps) {
    super(address, props);
    this.props = props;
  }
}

export default BitcoinWallet;

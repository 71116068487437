import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DescriptionStyle } from './style';

// Component
import { TextSecondary } from '../../../../atoms/text2/Text2';
import HeaderTransferDebitCardConfirm from '../../../../organisms/header/HeaderContentTitle/transfer/HeaderTransferDebitCardConfirm';
import FormTransferDebitCardConfirm from '../../../../organisms/form/transfer/debitCard/FormTransferDebitCardConfirm';
import ProgressStepBarBankTransaction from '../../../../molecules/progressStep/ProgressStepBar/ProgressStepBarBankTransaction';
import TableConfirmTransferDebitCard from '../../../../organisms/table/confirm/TableConfirmTransferDebitCard';
import TransferDebitCardConfirmTemplateLayout from './TransferDebitCardConfirmTemplateLayout';

const TEMPLATE_ID = `templates.transfers.TransferDebitCardConfirmTemplate`;

const TransferDebitCardConfirmTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <TransferDebitCardConfirmTemplateLayout>
      <HeaderTransferDebitCardConfirm key="header" />
      <ProgressStepBarBankTransaction
        key="progressStep"
        currentStep="confirm"
      />
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${TEMPLATE_ID}.description`)}
      </TextSecondary>
      <TableConfirmTransferDebitCard key="table" />
      <FormTransferDebitCardConfirm key="form" />
    </TransferDebitCardConfirmTemplateLayout>
  );
};

export default TransferDebitCardConfirmTemplate;

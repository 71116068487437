import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';
import Display from '../../../../../atoms/div/Display';

export interface FormProofOfResidencyLayoutProps {
  children: React.ReactElement[];
  isDisplayShippingAddress: boolean;
}

const FormProofOfResidencyLayout: FC<FormProofOfResidencyLayoutProps> = ({
  children,
  isDisplayShippingAddress,
}: FormProofOfResidencyLayoutProps) => {
  const elements = [
    'residentialAddress',
    'documentType',
    'documents',
    'shippingAddress',
    'button',
  ];
  const [
    residentialAddress,
    documentType,
    documents,
    shippingAddress,
    button,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn width={1200} height="auto">
      {residentialAddress}
      <Margin top={60} />
      {documentType}
      <Margin top={60} />
      {documents}
      <Margin top={60} />
      <Display style={{ width: '100%' }} isDisplay={isDisplayShippingAddress}>
        {shippingAddress}
        <Margin top={60} />
      </Display>
      {button}
      <Margin top={83} />
    </FlexBoxColumn>
  );
};
export default FormProofOfResidencyLayout;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface FormInputFullNameLayoutProps {
  children: React.ReactElement[];
  textInputWidth: string | number;
}

const FormInputFullNameLayout: FC<FormInputFullNameLayoutProps> = ({
  children,
  textInputWidth,
}: FormInputFullNameLayoutProps) => {
  const elements = ['label', 'firstName', 'middleName', 'lastName'];
  const [label, firstName, middleName, lastName] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn>
      <FlexBoxRow alignItems="center" justifyContent="space-between">
        {label}
        <FlexBoxRow width="auto">
          {firstName}
          <Margin left={40} />
          {middleName}
        </FlexBoxRow>
      </FlexBoxRow>
      <Margin top={40} />
      <FlexBoxRow justifyContent="flex-end">
        {lastName}
        <Margin right={40} />
        <Margin right={textInputWidth} />
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};

export default FormInputFullNameLayout;

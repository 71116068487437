import React, { FC, useMemo } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// Constant
import { HOURS_TIME_LIMIT_RESERVATION_CHANGE_CONTRACT_TIME_DEPOSIT } from '../../../../../../config/constants/business';

// Style
import { ContainerStyle } from './style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

// DomainObject
import ContractTimeDeposit from '../../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';

export interface ContractTimeDepositRenewalReservationEditCardLayoutProps {
  children: React.ReactElement[];
  contractTimeDeposit: ContractTimeDeposit;
}

const ContractTimeDepositRenewalReservationEditCardLayout: FC<ContractTimeDepositRenewalReservationEditCardLayoutProps> = ({
  children,
  contractTimeDeposit,
}: ContractTimeDepositRenewalReservationEditCardLayoutProps) => {
  const elements = [
    'title',
    'amount',
    'term',
    'payOut',
    'editButton',
    'cancelButton',
  ];

  const [
    title,
    amount,
    term,
    payOut,
    editButton,
    cancelButton,
  ] = getElementsFromKeys(children, elements);

  const buttonIsDisplay = useMemo(
    () =>
      !contractTimeDeposit.isMaturedWithInHours(
        HOURS_TIME_LIMIT_RESERVATION_CHANGE_CONTRACT_TIME_DEPOSIT,
      ),
    [contractTimeDeposit],
  );

  return (
    <FlexBoxColumn
      height="auto"
      padding="17 35"
      textAlign="left"
      theme={ContainerStyle}
    >
      {title}
      <Margin top={10} />
      <FlexBoxRow alignItems="center" height="auto">
        {amount}
        <Margin left={15} />
        <div>{term}</div>
        <Margin left={15} />
        {payOut}
        <Margin left={30} />
        {buttonIsDisplay && (
          <>
            <FlexBoxRow alignItems="center" height="auto">
              {editButton}
              <Margin left={15} />
              {cancelButton}
            </FlexBoxRow>
          </>
        )}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};

export default ContractTimeDepositRenewalReservationEditCardLayout;

import Joi from '@hapi/joi';

// Type
import {
  UserAuthorizedProps,
  UserAuthorizedPropsFormat,
} from './UserAuthorized.type';

// Domain Object
import UserAuthorized from './UserAuthorized';
import User from '../../User/User';

// Error
import InvalidDataFoundError from '../../../../../../utils/errors/InvalidDataFoundError';

class UserAuthorizedFactory {
  static create = (id: string, props: UserAuthorizedProps) => {
    const result = Joi.object(UserAuthorizedPropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw new InvalidDataFoundError(
        `Creation of UserAuthorized is rejected. The reason is ${result.error.details[0].message}`,
      );

    return new UserAuthorized(id, props);
  };

  static createByUser = (user: User, token: string) => {
    return UserAuthorizedFactory.create(user.getId(), {
      token,
      ...user.getProps(),
    });
  };
}

export default UserAuthorizedFactory;

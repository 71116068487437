// Types
import { TransferSavingsAccountType } from '../../../types/Transfer.type';

// IUseCase
import ITransferSavingsAccountViewUseCase from '../../../enhancers/useCase/transfer/savingsAccount/TransferSavingsAccountViewProvider/ITransferSavingsAccountViewUseCase';

// IService
import ISavingsAccountQueryService from '../../bankAccount/savingsAccount/ISavingsAccountQueryService';
import IFeeTransferSavingsAccountQueryService from '../../settings/IFeeTransferSavingsAccountQueryService';

// Service
import SavingsAccountQueryService from '../../../service/bankAccount/savingsAccount/SavingsAccountQueryService';
import FeeTransferSavingsAccountQueryService from '../../../service/settings/FeeTransferSavingsAccountQueryService';

// IRepository
import ISavingsAccountRepository from '../../../domain/bankAccount/savingsAccount/ISavingsAccountRepository';
import IFeeTransferSavingsAccountRepository from '../../../domain/settings/transactionFee/FeeTransferSavingsAccount/IFeeTransferSavingsAccountRepository';

// DomainObject
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import UserVerifiedFactory from '../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedFactory';

class TransferSavingsAccountViewUseCase
  implements ITransferSavingsAccountViewUseCase {
  private savingsAccountQueryService: ISavingsAccountQueryService;

  private feeTransferSavingsAccountQueryService: IFeeTransferSavingsAccountQueryService;

  constructor(
    savingsAccountRepository: ISavingsAccountRepository,
    feeTransferSavingsAccountRepository: IFeeTransferSavingsAccountRepository,
  ) {
    this.savingsAccountQueryService = new SavingsAccountQueryService(
      savingsAccountRepository,
    );
    this.feeTransferSavingsAccountQueryService = new FeeTransferSavingsAccountQueryService(
      feeTransferSavingsAccountRepository,
    );
  }

  public open = async (user: UserAuthorized) => {
    const userId = user.getId();

    // UserAuthorized -> UserVerified
    const userVerified = UserVerifiedFactory.createByUserAuthorized(user);

    // Fetch SavingsAccount
    const savingsAccounts = await this.savingsAccountQueryService.findAllByUserId(
      userId,
    );

    return {
      savingsAccounts,
      user: userVerified,
    };
  };

  public selectTransferSavingsAccountType = async (
    transferType: TransferSavingsAccountType,
  ) => {
    const feeTransferSavingsAccount = await this.feeTransferSavingsAccountQueryService.findById(
      transferType,
    );

    return {
      feeTransferSavingsAccount,
    };
  };
}
export default TransferSavingsAccountViewUseCase;

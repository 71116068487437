import { useCallback, useState } from 'react';

// Error
import ErrorHandler from '../../../../../../../utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from '../../../../../../../utils/errors/ErrorHandler/IErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../../../../utils/helpers/connection';

// Type
import { useFormInputCherryVisa01ProofOfReceipt } from '../../../../../../../components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfReceipt/FormCherryVisa01ProofOfReceipt.type';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../../../config/constants/requestState';

// Repository
import CherryStorageFunctions from '../../../../../../../storage/cherryStorageHandler/CherryStorageFunctions';
import DebitCardRequestCherryVisa01Functions from '../../../../../../../repository/debitCard/debitCardRequest/DebitCardRequestCherryVisa01Functions';

// UseCase
import DebitCardRequestCherryVisa01ProofOfReceiptUpdateUseCase from '../../../../../../../useCases/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01ProofOfReceiptUpdateUseCase';

// DomainObject
import DebitCardRequestCherryVisa01 from '../../../../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01';
import UserAuthorized from '../../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

// File
import { useCherryVisa01ProofOfReceiptFile } from '../../../../../../fileHandler/debitCard/debitCardRequest/ProofOfReceiptFileProvider/useCherryVisa01ProofOfReceiptFile';

export const useDebitCardRequestCherryVisa01ProofOfReceiptUpdateUseCase = (
  debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01 | undefined,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const formInputProofOfReceiptContext = useFormInputCherryVisa01ProofOfReceipt();

  // File
  const { selfie, setSelfie } = useCherryVisa01ProofOfReceiptFile();

  /* *
   *
   *  Repository
   *
   * */
  const debitCardRequestCherryVisa01Functions = new DebitCardRequestCherryVisa01Functions();
  const storageHandler = new CherryStorageFunctions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new DebitCardRequestCherryVisa01ProofOfReceiptUpdateUseCase(
    debitCardRequestCherryVisa01Functions,
    storageHandler,
  );

  /* *
   *
   *  Method
   *
   * */
  const submitProofOfReceipt = useCallback(
    async (callback?: () => void) => {
      if (!userAuthorized || !debitCardRequestCherryVisa01) return;

      setState(IS_LOADING);

      try {
        // ---check onLine ---- //
        checkInternetConnection();

        const outputs = await useCase.update(
          debitCardRequestCherryVisa01,
          selfie,
        );

        await useCase.executeUpdate(outputs);

        setState(SUCCESS);

        if (callback) callback();
      } catch (error) {
        const handler: IErrorHandler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [userAuthorized, debitCardRequestCherryVisa01, selfie],
  );

  return {
    state,
    setState,
    submitProofOfReceipt,
    formInputProofOfReceiptContext,
    proofOfReceiptFileContext: {
      selfie,
      setSelfie,
    },
  };
};

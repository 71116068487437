import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../../atoms/utils/layout/Margin';

export interface FormPersonalInformationInputLayoutProps {
  children: React.ReactElement[];
}

const FormPersonalInformationInputLayout: FC<FormPersonalInformationInputLayoutProps> = ({
  children,
}: FormPersonalInformationInputLayoutProps) => {
  const elements = ['name', 'gender', 'dateOfBirth'];
  const [name, gender, dateOfBirth] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn height="auto">
      {name}
      <Margin top={60} />
      {gender}
      <Margin top={80} />
      {dateOfBirth}
      <Margin top={40} />
    </FlexBoxColumn>
  );
};
export default FormPersonalInformationInputLayout;

import React, { FC } from 'react';
import { IoIosSend } from 'react-icons/io';
import { convertRem } from '../../../utils/helper/helper';

// Component
import { TextPrimary, TextThemeProps } from '../../../text2/Text2';
import LabelSendIconLayout from './LabelSendIconLayout';

export interface LabelSendIconProps {
  iconSize: string | number;
  iconStyle: {
    color: string;
  };
  label: string;
  marginBetween: number | string;
  textStyle: TextThemeProps;
}

const LabelSendIcon: FC<LabelSendIconProps> = ({
  iconSize,
  iconStyle,
  label,
  marginBetween,
  textStyle,
}: LabelSendIconProps) => {
  return (
    <LabelSendIconLayout marginBetween={marginBetween}>
      <IoIosSend
        key="icon"
        color={iconStyle.color}
        size={convertRem(iconSize)}
      />
      <TextPrimary key="label" theme={textStyle}>
        {label}
      </TextPrimary>
    </LabelSendIconLayout>
  );
};

export default LabelSendIcon;

// IUseCase
import ICryptoCurrencyTradeCreateUseCase, {
  CryptoCurrencyTradeCreateParameter,
} from '../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyTradeCreateUseCase/ICryptoCurrencyTradeCreateUseCase';

// IService
import ICryptoCurrencyTradeCreateService from './interface/ICryptoCurrencyTradeCreateService';
import IAuthenticatorVerifyService from '../../authenticator/authenticator/interface/IAuthenticatorVerifyService';

// Service
import CryptoCurrencyTradeCreateService from '../../../service/cryptocurrency/cryptoCurrencyTrade/CryptoCurrencyTradeCreateService';
import AuthenticatorVerifyService from '../../../service/authenticator/AuthenticatorVerifyService';

// IRepository
import ICryptoCurrencyTradeCreator from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/ICryptoCurrencyTradeRepository';

// DomainObject
import CryptoCurrencyTradeSeed from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed';
import CryptoCurrencyTradeLimit from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeLimit/CryptoCurrencyTradeLimit/CryptoCurrencyTradeLimit';
import CryptoCurrencyWallet from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet';
import UserVerified from '../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';

class CryptoCurrencyTradeCreateUseCase
  implements ICryptoCurrencyTradeCreateUseCase {
  private authenticatorVerifyService: IAuthenticatorVerifyService;

  private cryptoCurrencyTradeCreateService: ICryptoCurrencyTradeCreateService;

  constructor(
    authenticatorVerifyService: IAuthenticatorVerifyService,
    cryptoCurrencyTradeCreator: ICryptoCurrencyTradeCreator,
  ) {
    this.authenticatorVerifyService = new AuthenticatorVerifyService(
      authenticatorVerifyService,
    );
    this.cryptoCurrencyTradeCreateService = new CryptoCurrencyTradeCreateService(
      cryptoCurrencyTradeCreator,
    );
  }

  public create = (
    user: UserVerified,
    cryptoCurrencyTradeLimit: CryptoCurrencyTradeLimit,
    cryptoCurrencyWallet: CryptoCurrencyWallet,
    params: CryptoCurrencyTradeCreateParameter,
  ) => {
    const cryptoCurrencyTradeSeed = this.cryptoCurrencyTradeCreateService.create(
      user,
      cryptoCurrencyTradeLimit,
      cryptoCurrencyWallet,
      params,
    );

    return { cryptoCurrencyTradeSeed };
  };

  public executeCreate = async (
    user: UserVerified,
    cryptoCurrencyTradeSeed: CryptoCurrencyTradeSeed,
    code: string,
  ) => {
    // Authenticator
    await this.authenticatorVerifyService.verify(user, code);

    // Create CryptoCurrencyTrade
    const cryptoCurrencyTrade = await this.cryptoCurrencyTradeCreateService.executeCreate(
      cryptoCurrencyTradeSeed,
    );

    return { cryptoCurrencyTrade };
  };
}
export default CryptoCurrencyTradeCreateUseCase;

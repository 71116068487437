import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { Container } from './style';
import { FlexRow } from '../../../atoms/utils/layout/Flex';
import Margin from '../../../atoms/utils/layout/Margin';

export interface HeaderCryptoCurrencyTradeTypeLayoutProps {
  children: React.ReactElement[];
}

const HeaderCryptoCurrencyTradeTypeLayout: FC<HeaderCryptoCurrencyTradeTypeLayoutProps> = ({
  children,
}: HeaderCryptoCurrencyTradeTypeLayoutProps) => {
  const elements = ['icon', 'name', 'symbol', 'label'];
  const [icon, name, symbol, label] = getElementsFromKeys(children, elements);

  return (
    <Container>
      <FlexRow alignItems="center">
        {icon}
        <Margin left={18}>{name}</Margin>
        <Margin left={10}>{symbol}</Margin>
        <Margin left={20}>{label}</Margin>
      </FlexRow>
    </Container>
  );
};
export default HeaderCryptoCurrencyTradeTypeLayout;

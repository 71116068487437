import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { FlexRow } from '../../../atoms/utils/layout/Flex';
import Margin from '../../../atoms/utils/layout/Margin';

export interface BreadcrumbLayoutProps {
  children: React.ReactElement[];
  marginBetween: string | number;
}

const BreadcrumbLayout: FC<BreadcrumbLayoutProps> = ({
  children,
  marginBetween,
}: BreadcrumbLayoutProps) => {
  const elements = ['icon', 'link'];
  const [icon, link] = getElementsFromKeys(children, elements);

  return (
    <FlexRow alignItems="center">
      {icon}
      <Margin left={marginBetween}>{link}</Margin>
    </FlexRow>
  );
};
export default BreadcrumbLayout;

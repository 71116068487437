import { useCallback, useState } from 'react';
import { checkInternetConnection } from '../../../../../utils/helpers/connection';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// Type
import { UserType } from '../../../../../types/User.type';

// UseCase
import KycRequestCreateUseCase from '../../../../../useCases/verification/kycRequest/KycRequestCreateUseCase';

// Repository
import KycRequestFirestore from '../../../../../repository/verification/kycRequest/KycRequestFirestore';
import ConsentFormFirestore from '../../../../../repository/verification/consentForm/ConsentFormFirestore';
import UserFirestore from '../../../../../repository/public/user/UserFirestore';

// DomainObject
import UserAuthorized from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useKycRequestCreateUseCase = (userAuthorized?: UserAuthorized) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  Repository
   *
   * */
  const kycRequestRepository = new KycRequestFirestore();
  const consentFormRepository = new ConsentFormFirestore();
  const userRepository = new UserFirestore();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new KycRequestCreateUseCase(
    kycRequestRepository,
    consentFormRepository,
    userRepository,
  );

  /* *
   *
   *  Method
   *
   * */
  const createKycRequest = useCallback(
    async (type: UserType, callback?: () => void) => {
      if (!userAuthorized) return;

      setState(IS_LOADING);

      try {
        // Is ONLINE?
        checkInternetConnection();

        // UseCase
        const outputs = await useCase.createInitial(userAuthorized, type);
        await useCase.executeCreate(outputs);

        setState(SUCCESS);

        if (callback) callback();
      } catch (error) {
        const handler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
      // eslint-disable-next-line
  }, [userAuthorized]);

  return {
    state,
    setState,
    createKycRequest,
  };
};

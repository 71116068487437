import Joi from '@hapi/joi';

export interface AssetProps {
  single: {
    balance: number;
    pendingBalance: number;
  };
  createdAt?: number;
  updatedAt?: number;
}

export const AssetPropsFormat = {
  single: Joi.object({
    balance: Joi.number().required(),

    pendingBalance: Joi.number().required(),
  }),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

// Error
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';

// FirebaseFunctions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// Lib
import {
  addByDecimal,
  minusByDecimal,
  timesByDecimal,
  divideByDecimal,
} from '../../../utils/helpers/calculationHelper';

// Const
import { RATE_UPDATE_INTERVAL_MINUTES } from '../../../config/constants/business';
import { CRYPTO_CURRENCY_RATE_OSL } from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// Type
import { CurrencyPairType } from '../../../types/CurrencyPair.type';

// IRepository
import ICryptoCurrencyRateQueryRateRepository from '../../../domain/cryptoCurrency/cryptoCurrencyRate/CryptoCurrencyRate/ICryptoCurrencyRateRepository';

// DomainObject
import CryptoCurrencyTradeFeeRate from '../../../domain/settings/cryptoCurrencyTradeFeeRate/CryptoCurrencyTradeFeeRate/CryptoCurrencyTradeFeeRate';
import CryptoCurrencyRateFactory from '../../../domain/cryptoCurrency/cryptoCurrencyRate/CryptoCurrencyRate/CryptoCurrencyRate/CryptoCurrencyRateFactory';

// ValueObject
import TimeZonedTime from '../../../value/datetime/TimeZonedTime';

class CryptoCurrencyRateOSLExchangeAPI
  extends FirebasePrivateFunctions
  implements ICryptoCurrencyRateQueryRateRepository {
  public findById = async (
    cryptoCurrencyTradeFeeRate: CryptoCurrencyTradeFeeRate,
  ) => {
    const currencyPair = cryptoCurrencyTradeFeeRate.getCurrencyPair();

    if (currencyPair === CurrencyPairType.LTCTUSD) {
      return this.findTestCurrencyRate(currencyPair);
    }

    const response = await this.privateGET(
      `${CRYPTO_CURRENCY_RATE_OSL}/${currencyPair}`,
    );

    const buyRate = response.data.data.buy.value;
    const sellRate = response.data.data.sell.value;
    const last = response.data.data.last.value;
    const feeRate = cryptoCurrencyTradeFeeRate.getProps().rate;
    const expiredAt = new TimeZonedTime()
      .addMinute(RATE_UPDATE_INTERVAL_MINUTES)
      .getTimestamp();

    return CryptoCurrencyRateFactory.create(currencyPair, {
      buyRate: {
        original: Number(buyRate),
        feeIncluded: timesByDecimal(
          buyRate,
          minusByDecimal(1, divideByDecimal(feeRate, 100)),
        ),
      },
      expiredAt,
      last,
      sellRate: {
        original: Number(sellRate),
        feeIncluded: timesByDecimal(
          sellRate,
          addByDecimal(1, divideByDecimal(feeRate, 100)),
        ),
      },
      tradeFeeRate: feeRate,
    });
  };

  private findTestCurrencyRate = (currencyPair: CurrencyPairType) => {
    if (currencyPair !== CurrencyPairType.LTCTUSD)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_SWITCH,
        {
          value: currencyPair,
          place: `CryptoCurrencyRateOSLExchangeAPI.findTestCurrencyRate`,
        },
      );

    const expiredAt = new TimeZonedTime()
      .addMinute(RATE_UPDATE_INTERVAL_MINUTES)
      .getTimestamp();

    return CryptoCurrencyRateFactory.create(currencyPair, {
      buyRate: {
        original: 0.01,
        feeIncluded: 0.01,
      },
      expiredAt,
      last: 0.00000000001,
      sellRate: {
        original: 99999999999,
        feeIncluded: 99999999999,
      },
      tradeFeeRate: 0,
    });
  };
}
export default CryptoCurrencyRateOSLExchangeAPI;

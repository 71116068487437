// Functions
import FirebasePublicFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePublicFunctions';
import { USER_REQUEST_RESET_AUTHENTICATOR_REQUEST } from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// Type
import { ResetAuthenticatorRequestRawDataType } from '../../../domain/userRequest/resetAuthenticatorRequest/ResetAuthenticatorRequest/ResetAuthenticatorRequest.type';

// IRepository
import IResetAuthenticatorRequestRepository from '../../../domain/userRequest/resetAuthenticatorRequest/IResetAuthenticatorRequestRepository';

// DomainObject
import ResetAuthenticatorRequestFactory from '../../../domain/userRequest/resetAuthenticatorRequest/ResetAuthenticatorRequest/ResetAuthenticatorRequestFactory';

class ResetAuthenticatorRequestFunctions
  implements IResetAuthenticatorRequestRepository {
  public findByPrimaryKey = async (params: { oobCode: string }) => {
    const endpoint = `${USER_REQUEST_RESET_AUTHENTICATOR_REQUEST}/${params.oobCode}`;

    const response = await new FirebasePublicFunctions().publicGET(endpoint);

    const row = response.data.data as ResetAuthenticatorRequestRawDataType;

    return ResetAuthenticatorRequestFactory.create(row.id, row.props);
  };

  public resetAuthenticator = async (
    id: string,
    oobCode: string,
    code: string,
  ) => {
    const endpoint = `${USER_REQUEST_RESET_AUTHENTICATOR_REQUEST}/reset`;

    await new FirebasePublicFunctions().publicPOST(endpoint, {
      id,
      oobCode,
      code,
    });
  };
}
export default ResetAuthenticatorRequestFunctions;

// React
import { useEffect, useCallback, useState } from 'react';
// import { useDispatch } from 'react-redux';

// ErrorHandler
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../../utils/helpers/connection';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';

// UseCase
import TransferDebitCardViewUseCase from '../../../../../useCases/transfer/debitCard/TransferDebitCardViewUseCase';

// Repository
import DebitCardV2Functions from '../../../../../repository/debitCard/debitCard/DebitCardV2Functions';
import SavingsAccountFunctions from '../../../../../repository/bankAccount/savingsAccount/SavingsAccountFunctions';
import TransferDebitCardTransactionV2Functions from '../../../../../repository/transfer/TransferDebitCardTransactionV2Functions';
import ContractTimeDepositHistoryFunctions from '../../../../../repository/contract/contractTimeDepositHistory/ContractTimeDepositHistoryFunctions';

// DomainObject
import UserAuthorized from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import { useContractTimeDepositHistories } from '../../../../../domain/contract/contractTimeDepositHistory/ContractTimeDepositHistories/useContractTimeDepositHistories';
import { useDebitCardV2s } from '../../../../../domain/debitCard/debitCardV2/DebitCardV2s/useDebitCardV2s';
import { useSavingsAccounts } from '../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/useSavingsAccounts';
import { useTransferDebitCardTransactionV2s } from '../../../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2s/useTransferDebitCardTransactionV2s';
import { useUserVerified } from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/useUserVerified';

// SelectBox
import { useTransferDebitCardTypeOption } from '../../../../../components/molecules/selector/tabBar/TransferDebitCardTabBar/useTransferDbitCardTypeOption';

export const useTransferDebitCardViewUseCase = (user?: UserAuthorized) => {
  const [state, setState] = useState<string>(INITIAL);
  // const dispatch = useDispatch();

  /**
   *
   *  DomainObject
   *
   */
  const {
    contractTimeDepositHistories,
    setContractTimeDepositHistories,
  } = useContractTimeDepositHistories();
  const { debitCardV2s, setDebitCardV2s } = useDebitCardV2s();
  const { savingsAccounts, setSavingsAccounts } = useSavingsAccounts();
  const { userVerified, setUserVerified } = useUserVerified();
  const {
    transferDebitCardTransactions,
    setTransferDebitCardTransactions,
  } = useTransferDebitCardTransactionV2s();

  // SelectBox
  const {
    transferTypeOption,
    setTransferTypeOption,
  } = useTransferDebitCardTypeOption();

  /**
   *
   *  Repository
   *
   */
  const savingsAccountRepository = new SavingsAccountFunctions();
  const debitCardRepository = new DebitCardV2Functions();
  const transferDebitCardTransactionRepository = new TransferDebitCardTransactionV2Functions();
  const contractTimeDepositHistoryRepository = new ContractTimeDepositHistoryFunctions();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new TransferDebitCardViewUseCase(
    contractTimeDepositHistoryRepository,
    debitCardRepository,
    savingsAccountRepository,
    transferDebitCardTransactionRepository,
  );

  /**
   *
   *  Methods
   *
   */
  const openTransferDebitCard = useCallback(async () => {
    if (!user) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // execute
      const outputs = await useCase.open(user);

      setUserVerified(outputs.userVerified);
      setSavingsAccounts(outputs.savingsAccounts);
      setDebitCardV2s(outputs.debitCards);
      setContractTimeDepositHistories(outputs.contractTimeDepositHistories);
      setTransferDebitCardTransactions(outputs.transferDebitCardTransactions);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [user]);

  /* *
   *
   *  UseEffect
   *
   * */

  // UseCase.open
  useEffect(() => {
    let isMounted = true;

    if (isMounted && user) openTransferDebitCard();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [openTransferDebitCard]);

  return {
    state,
    setState,
    contractTimeDepositHistories,
    debitCards: debitCardV2s,
    savingsAccounts,
    transferTypeOption,
    setTransferTypeOption,
    userVerified,
    transferDebitCardTransactions,
  };
};

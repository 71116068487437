import React, { FC, useContext } from 'react';
import { useConsentForm } from './useConsentForm';
import ConsentFormDataContext from './ConsentFormDataContext';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import { ConsentFormDoc } from '../../../../utils/firebase/firestore/verification/ConsentForm.type';
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type ConsentFormDataProps = {
  children: React.ReactNode;
};

const ConsentFormData: FC<ConsentFormDataProps> = ({
  children,
}: ConsentFormDataProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const { consentFormDoc, updateConsentForm, state, setState } = useConsentForm(
    userAuthorized,
  );

  const consentFormData = consentFormDoc as ConsentFormDoc;

  return (
    <ConsentFormDataContext.Provider
      value={{
        consentFormData,
        consentFormState: state,
        updateConsentForm,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </ConsentFormDataContext.Provider>
  );
};
export default ConsentFormData;

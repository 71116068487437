import React, { FC } from 'react';

// Style
import { LabelStyle } from './style';

// Component
import { TextPrimary } from '../../../../../atoms/text2/Text2';
import TextField from '../../../TextField2';
import FormInputTextLayout from './FormInputTextLayout';

interface FormInputTextProps {
  inputWidth: string | number;
  label: string;
  maxLength: number;
  name: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any;
  disabled?: boolean;
  textAlign?: 'left' | 'right';
}

const FormInputText: FC<FormInputTextProps> = ({
  inputWidth,
  label,
  maxLength,
  name,
  onChange,
  placeholder,
  register,
  disabled = false,
  textAlign = 'left',
}: FormInputTextProps) => {
  return (
    <FormInputTextLayout inputWidth={inputWidth}>
      <TextPrimary key="label" theme={LabelStyle}>
        {label}
      </TextPrimary>
      <TextField
        key="input"
        name={name}
        placeholder={placeholder}
        textInputWidth="100%"
        onChange={onChange}
        maxLength={maxLength}
        register={register}
        disabled={disabled}
        textAlign={textAlign}
      />
    </FormInputTextLayout>
  );
};

export default FormInputText;

// Constant
import { INTEREST_RATE_PRECISION } from 'config/constants/business';

// Type
import { TypeOfT } from '../../../../../types/typeof/Translation.type';

// Lib
import {
  convertMoneyFormat,
  getSymbolFromCurrency,
} from '../../../../../utils/helpers/currencyHelper';

// DomainObject
import ContractTimeDepositSeed from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDepositSeed/ContractTimeDepositSeed';

// ValueObject
import TimeZonedTimeFactory from '../../../../../value/datetime/TimeZonedTimeFactory';

const ORGANISM_ID = `organisms.table.TableConfirmContractTimeDepositAdd`;

class TableConfirmContractTimeDepositMapper {
  static domainToTd = (
    contractTimeDepositSeed: ContractTimeDepositSeed,
    t: TypeOfT,
  ) => {
    const {
      depositAmount,
      depositCurrency,
      depositDate,
      maturityDate,
      isAnnualPayOut,
      term,
      interestRate,
    } = contractTimeDepositSeed.getProps();

    const tdSavingsAccountNumber = contractTimeDepositSeed.getFormattedSavingsAccountNumber();

    const currencySymbol = getSymbolFromCurrency(depositCurrency);

    return [
      {
        header: t(`${ORGANISM_ID}.th.savingsAccountNumber`),
        data: t(`${ORGANISM_ID}.td.savingsAccountNumber`, {
          savingsAccountNumber: tdSavingsAccountNumber,
        }),
      },
      {
        header: t(`${ORGANISM_ID}.th.depositAmount`),
        data: `${currencySymbol} ${convertMoneyFormat(
          depositAmount,
          depositCurrency,
        )}`,
      },
      {
        header: t(`${ORGANISM_ID}.th.term`),
        data: t(`${ORGANISM_ID}.td.term`, { term: String(term) }),
      },
      {
        header: t(`${ORGANISM_ID}.th.interestRate`),
        data: t(`${ORGANISM_ID}.td.interestRate`, {
          interestRate: String(interestRate.toFixed(INTEREST_RATE_PRECISION)),
        }),
      },
      {
        header: t(`${ORGANISM_ID}.th.annualPayOut`),
        data: t(
          `${ORGANISM_ID}.td.annualPayOut.${
            isAnnualPayOut ? 'annual' : 'maturity'
          }`,
        ),
      },
      {
        header: t(`${ORGANISM_ID}.th.depositDate`),
        data: TimeZonedTimeFactory.createZeroOClock(depositDate).format(
          'Do MMMM YYYY',
        ),
      },
      {
        header: t(`${ORGANISM_ID}.th.maturityDate`),
        data: TimeZonedTimeFactory.createZeroOClock(maturityDate).format(
          'Do MMMM YYYY',
        ),
      },
    ];
  };
}

export default TableConfirmContractTimeDepositMapper;

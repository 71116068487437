import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const TitleStyle = {
  fontSize: 24,
  color: color.text.froly,
  fontWeight: fontWeight.bold,
};

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  lineHeight: 35,
};

export const TitleMiddleStyle = {
  fontSize: 22,
  color: color.text.primary,
  fontWeight: fontWeight.bold,
  lineHeight: 30,
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Style
import { ContainerRejectedStyle } from './style';

// Type
import { DebitCardRequestCherryVisa01StepStatusType } from '../../../../types/DebitCardRequestCherryVisa01.type';

// LayoutComponent
import { FlexBoxRow } from '../../../atoms/utils/layout/Box/FlexBox';
import Display from '../../../atoms/div/Display';
import Margin from '../../../atoms/utils/layout/Margin';

export interface HeaderDebitCardRequestStatusLayoutProps {
  children: React.ReactElement[];
  debitCardRequestStatus: DebitCardRequestCherryVisa01StepStatusType.rejected;
}

const HeaderDebitCardRequestStatusLayout: FC<HeaderDebitCardRequestStatusLayoutProps> = ({
  children,
  debitCardRequestStatus,
}: HeaderDebitCardRequestStatusLayoutProps) => {
  const elements = ['title', 'status', 'rejectedReason'];
  const [title, status, rejectedReason] = getElementsFromKeys(
    children,
    elements,
  );

  const containerStyle = ContainerRejectedStyle;

  return (
    <FlexBoxRow
      alignItems="center"
      paddingLeft={85}
      height={89}
      theme={containerStyle}
    >
      {title}
      <Margin left={40} />
      {status}
      <Margin left={40} />
      <Display
        isDisplay={
          debitCardRequestStatus ===
          DebitCardRequestCherryVisa01StepStatusType.rejected
        }
      >
        {rejectedReason}
      </Display>
    </FlexBoxRow>
  );
};
export default HeaderDebitCardRequestStatusLayout;

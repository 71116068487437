import React from 'react';
import AssetSavingsAccount from './AssetSavingsAccount';

export interface AssetSavingsAccountContextProps {
  assetSavingsAccount?: AssetSavingsAccount;
}

const AssetSavingsAccountContext = React.createContext<
  AssetSavingsAccountContextProps
>({
  assetSavingsAccount: undefined,
});

export default AssetSavingsAccountContext;

// Constant
import {
  USER_REQUEST_RE_SIGN_UP_FETCH_RAW_BY_OOB_CODE,
  USER_REQUEST_RE_SIGN_UP,
  USER_REQUEST_RE_SIGN_UP_CONFIRM,
} from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// FirebaseFunctions
import FirebasePublicFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePublicFunctions';

// IRepository
import IResignUpRequestRepository from '../../../domain/userRequest/resignUpRequest/IResignUpRequestRepository';

// DomainObject
import ResignUpRequestFactory from '../../../domain/userRequest/resignUpRequest/ResignUpRequest/ResignUpRequestFactory';
import UserFactory from '../../../domain/public/user/User/User/UserFactory';

class ResignUpRequestFunctions implements IResignUpRequestRepository {
  public findByOobCode = async (oobCode: string) => {
    const endpoint = USER_REQUEST_RE_SIGN_UP_FETCH_RAW_BY_OOB_CODE.replace(
      /:oobCode/,
      oobCode,
    );

    const response = await new FirebasePublicFunctions().publicGET(endpoint);

    const resignUpRequestRaw = response.data.data.resignUpRequest;
    const userRaw = response.data.data.user;

    const resignUpRequest = ResignUpRequestFactory.create(
      resignUpRequestRaw.id,
      resignUpRequestRaw.props,
    );

    const user = UserFactory.create(userRaw.id, userRaw.props);

    return {
      user,
      resignUpRequest,
    };
  };

  public create = async (email: string, reregistrationCode: string) => {
    await new FirebasePublicFunctions().publicPOST(USER_REQUEST_RE_SIGN_UP, {
      email,
      reregistrationCode,
    });
  };

  public confirm = async (oobCode: string, password: string, code: string) => {
    await new FirebasePublicFunctions().publicPOST(
      USER_REQUEST_RE_SIGN_UP_CONFIRM,
      {
        oobCode,
        password,
        code,
      },
    );
  };
}
export default ResignUpRequestFunctions;

// Type
import { CryptoCurrencyBuyType } from '../../../../../../../types/CryptoCurrencyTransaction.type';
import { CryptoCurrencyType } from '../../../../../../../types/CryptoCurrency.type';
import { CurrencyPairFactory } from '../../../../../../../types/CurrencyPair.type';

// DomainObject
import CryptoCurrencyTradeTransaction from '../../CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction';

class CryptoCurrencyBuyTransaction extends CryptoCurrencyTradeTransaction {
  public getTradeDetail = () => ({
    payAccount: this.savingsAccountNumber.getFormattedId(),
    payAmount: this.payAmount,
    payCurrency: this.payCurrency,
    receiveAccount: this.receiveCurrency,
    receiveAmount: this.receiveAmount,
    receiveCurrency: this.receiveCurrency,
  });

  public getTargetCurrency = () => this.receiveCurrency as CryptoCurrencyType;

  public getCurrencyPair = () =>
    CurrencyPairFactory.create(this.payCurrency, this.receiveCurrency);

  public getTransactionType = () => CryptoCurrencyBuyType.cryptoCurrencyBuy;
}

export default CryptoCurrencyBuyTransaction;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DebitCardRequestCherryVisa01Context from '../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Context';

// Mapper
import DebitCardRequestCherryVisa01StepMapper from '../../../organisms/list/DebitCardRequestCherryVisa01StatusList/mapper/DebitCardRequestCherryVisa01StepMapper/DebitCardRequestCherryVisa01StepMapper';

// Component
import FormCherryVisa01ProofOfReceipt from '../../../organisms/form/debitCardRequests/FormCherryVisa01ProofOfReceipt';
import DebitCardRequestCherryVisa01StepTemplate from '../DebitCardRequestCherryVisa01StepTemplate';

const TEMPLATE_ID = `templates.debitCards.CherryVisa01ProofOfReceiptTemplate`;

const CherryVisa01ProofOfReceiptTemplate: FC = () => {
  const { t } = useTranslation();
  const { debitCardRequestCherryVisa01 } = useContext(
    DebitCardRequestCherryVisa01Context,
  );

  if (!debitCardRequestCherryVisa01) return <></>;

  // First Level Property
  const { proofOfReceipt } = debitCardRequestCherryVisa01.getProps();

  // Second Level Property
  const rejectedReason = proofOfReceipt?.getProps().rejectedReason;
  const status = DebitCardRequestCherryVisa01StepMapper.toStepsStatus(
    debitCardRequestCherryVisa01,
  )?.proofOfReceipt;

  return (
    <DebitCardRequestCherryVisa01StepTemplate
      debitCardRequestCherryVisa01={debitCardRequestCherryVisa01}
      status={status}
      title={t(`${TEMPLATE_ID}.title`)}
      rejectedReason={rejectedReason}
    >
      <FormCherryVisa01ProofOfReceipt
        id={debitCardRequestCherryVisa01.getId()}
        proofOfReceipt={proofOfReceipt}
      />
    </DebitCardRequestCherryVisa01StepTemplate>
  );
};

export default CherryVisa01ProofOfReceiptTemplate;

import styled from 'styled-components';
import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: ${12 / 16}rem;
  width: ${186 / 16}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  :hover {
    background-color: #dbf1ff;
  }
`;

const TextStyle = {
  fontSize: 18,
  fontWeight: fontWeight.medium,
};

export const TextSelectedStyle = {
  ...TextStyle,
  color: color.text.white,
};

export const TextUnSelectedStyle = {
  ...TextStyle,
  color: color.text.primary,
};

export const SelectedContainerStyle = {
  backgroundColor: color.background.primary,
  color: `${color.text.white} !important`,
};

export const UnSelectedContainerStyle = {
  color: color.text.primary,
  opacity: 0.3,
  border: `${1 / 16}rem solid ${color.border.periwinkle}`,
};

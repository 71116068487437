export enum SettingMenuType {
  twoFactorAuthAppEdit = 'twoFactorAuthAppEdit',
  emailEdit = 'emailEdit',
  passwordEdit = 'passwordEdit',
  shippingAddressEdit = 'shippingAddressEdit',
  consentForm = 'consentForm',
}

export const VerifiedUserSettingMenuType = [
  SettingMenuType.twoFactorAuthAppEdit,
  SettingMenuType.emailEdit,
  SettingMenuType.passwordEdit,
  SettingMenuType.consentForm,
];

export const UnverifiedUserSettingMenuType = [
  SettingMenuType.emailEdit,
  SettingMenuType.passwordEdit,
];

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface InvitationCodesTemplateLayoutProps {
  children: React.ReactElement[];
}

const InvitationCodesTemplateLayout: FC<InvitationCodesTemplateLayoutProps> = ({
  children,
}: InvitationCodesTemplateLayoutProps) => {
  const elements = ['title', 'description', 'codes'];
  const [title, description, codes] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn padding="85 0 0 80">
      {title}
      <Margin top={30} />
      {description}
      <Margin top={50} />
      {codes}
    </FlexBoxColumn>
  );
};
export default InvitationCodesTemplateLayout;

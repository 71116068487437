import { useState } from 'react';
import BankDeposits from './BankDeposits';

export const useBankDeposits = (defaultValue?: BankDeposits) => {
  const [bankDeposits, setBankDeposits] = useState<BankDeposits>(
    defaultValue || new BankDeposits(),
  );

  return {
    bankDeposits,
    setBankDeposits,
  };
};

import { Reducer } from 'redux';
import { TestSagaAction } from '../actions/testActions';
import { TestDoc } from '../../utils/firebase/firestore/Tests';
import TestActionType from '../constants/test.constant';

export interface TestState {
  [key: string]: TestDoc;
}

export const initialState = {} as TestState;

const TestReducer: Reducer<TestState, TestSagaAction> = (
  state: TestState = initialState,
  action: TestSagaAction,
): TestState => {
  switch (action.type) {
    case TestActionType.SAGA_STORE_TEST:
      return {
        ...state,
        ...action.params,
      };
    default:
      return state;
  }
};

export default TestReducer;

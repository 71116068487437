import AssetCryptoCurrency from '../AssetCryptoCurrency/AssetCryptoCurrency';
import { CryptoCurrencyType } from '../../../../types/CryptoCurrency.type';
import { IIterable } from '../../../interface/Iterator/IIterable';

class AssetsCryptoCurrency implements IIterable<AssetCryptoCurrency> {
  private assetsCryptoCurrency: AssetCryptoCurrency[] = [];

  public add = (assetCryptoCurrency: AssetCryptoCurrency) => {
    this.assetsCryptoCurrency.push(assetCryptoCurrency);
  };

  public head = () => this.assetsCryptoCurrency[0];

  public length = () => this.assetsCryptoCurrency.length;

  public getDefaultCurrency = () =>
    this.assetsCryptoCurrency.length === 0
      ? CryptoCurrencyType.BTC
      : this.assetsCryptoCurrency[0].getCurrency();

  public map = <T>(
    callback: (assetCryptoCurrency: AssetCryptoCurrency) => T,
  ) => {
    return this.assetsCryptoCurrency.map((assetCryptoCurrency) =>
      callback(assetCryptoCurrency),
    );
  };

  public forEach = (
    callback: (assetCryptoCurrency: AssetCryptoCurrency) => void,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const assetCryptoCurrency of this.assetsCryptoCurrency) {
      // eslint-disable-next-line no-await-in-loop
      callback(assetCryptoCurrency);
    }
  };

  public forEachAsync = async (
    callback: (assetCryptoCurrency: AssetCryptoCurrency) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const assetCryptoCurrency of this.assetsCryptoCurrency) {
      // eslint-disable-next-line no-await-in-loop
      await callback(assetCryptoCurrency);
    }
  };
}
export default AssetsCryptoCurrency;

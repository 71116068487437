import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const ButtonStyle = {
  borderRadius: `${5 / 16}rem`,
  backgroundColor: color.background.summerSky,
  border: `${1 / 16}rem solid ${color.background.summerSky}`,
  boxShadow: '0px 5px 0px #81818141',
};

export const ButtonTextStyle = {
  fontSize: 21,
  color: color.text.white,
  fontWeight: fontWeight.regular,
};

export const DescriptionStyle = {
  fontSize: 16,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  lineHeight: 22,
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CodeBoxStyle,
  CodeTextStyle,
  LabelStyle,
  TitleTextStyle,
  SuccessMessageStyle,
} from './style';

// Lib
import { rankKeyMapper } from '../../../../../../types/Invite.type';

// Type
import { InvitationGradeType } from '../../../../../atoms/text/EllipseWrappedText';

// Style
import { DefaultButtonTextStyle } from '../../../../../atoms/button/Button2';
import { CopyButtonStyle } from '../../../cryptoCurrency/CryptoCurrencyAddressQRCodeCard/style';

// Component
import InvitationRankIcon from '../../../../../atoms/icon/InvitationRankIcon';
import { TextPrimary, TextSecondary } from '../../../../../atoms/text2/Text2';
import InvitationCodeCardLayout from './InvitationCodeCardLayout';
import TextCopyButtonGroup from '../../../../text/TextCopyButtonGroup';

// DomainObject
import InvitationCode from '../../../../../../domain/invite/invitationCode/InvitationCode/InvitationCode';

export interface InvitationCodeCardProps {
  invitationCode: InvitationCode;
}

const InvitationCodeCard: FC<InvitationCodeCardProps> = ({
  invitationCode,
}: InvitationCodeCardProps) => {
  const { t } = useTranslation();
  const rank = invitationCode.getRawRank();
  const code = invitationCode.getRawCode();

  const { inviterType, gradeType } = rankKeyMapper[rank];

  return (
    <InvitationCodeCardLayout>
      <InvitationRankIcon key="icon" />
      <TextPrimary key="title" theme={TitleTextStyle}>
        {t(`invitation.inviterType.${inviterType}`)}
      </TextPrimary>
      <InvitationGradeType key="badge" grade={gradeType} />
      <TextSecondary key="label" theme={LabelStyle}>
        {t(`invitations.InvitationCodes.label`)}
      </TextSecondary>
      <TextCopyButtonGroup
        key="code"
        buttonMarginLeft={20}
        buttonMarginRight={15}
        buttonStyle={CopyButtonStyle}
        buttonTextStyle={DefaultButtonTextStyle}
        buttonWidth={148}
        height={51}
        successMessageStyle={SuccessMessageStyle}
        textStyle={CodeTextStyle}
        textContainerWidth={400}
        textContainerStyle={CodeBoxStyle}
        text={code}
      />
    </InvitationCodeCardLayout>
  );
};
export default InvitationCodeCard;

// Type
import {
  FormInputCorporateAddress,
  FormInputShippingAddress,
} from '../../../../../../components/organisms/form/verification/kycRequest/FormCorporateAddress/FormCorporateAddress.type';
import { CorporateAddressDocumentType } from '../../../../../../types/KycRequest.type';

export const separateFormInput = (
  formInput: FormInputCorporateAddress & FormInputShippingAddress,
) => {
  const {
    country,
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
    documentType,
    proofOfDocumentUrl1,
    proofOfDocumentUrl2,
    ...formInputShippingAddress
  } = formInput;

  const formInputCorporateAddress = {
    country,
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
    documentType,
    proofOfDocumentUrl1,
    proofOfDocumentUrl2,
  };

  return {
    formInputCorporateAddress,
    formInputShippingAddress,
  };
};

export const convertFormInputToProps = (formInput: {
  formInputCorporateAddress: FormInputCorporateAddress;
  formInputShippingAddress: FormInputShippingAddress;
}) => {
  const { formInputCorporateAddress, formInputShippingAddress } = formInput;

  const corporateAddressProps = {
    country: formInputCorporateAddress.country,
    addressLine1: formInputCorporateAddress.addressLine1,
    addressLine2: formInputCorporateAddress.addressLine2,
    city: formInputCorporateAddress.city,
    state: formInputCorporateAddress.state,
    postalCode: formInputCorporateAddress.postalCode,
    document: {
      type: formInputCorporateAddress.documentType as CorporateAddressDocumentType,
      documentUrl1: formInputCorporateAddress.proofOfDocumentUrl1,
    },
  };

  const shippingAddressProps = {
    country: formInputShippingAddress.shippingCountry,
    addressLine1: formInputShippingAddress.shippingAddressLine1,
    addressLine2: formInputShippingAddress.shippingAddressLine2,
    city: formInputShippingAddress.shippingCity,
    state: formInputShippingAddress.shippingState,
    postalCode: formInputShippingAddress.shippingPostalCode,
    receiverName: formInputShippingAddress.receiverName,
    contactNumber: {
      countryCode: formInputShippingAddress.countryCode,
      number: formInputShippingAddress.phoneNumber,
    },
  };

  return {
    corporateAddressProps,
    shippingAddressProps,
  };
};

import styled from 'styled-components';
import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${150 / 16}rem;
  padding-top: ${50 / 16}rem;
  border-bottom: ${2 / 16}rem solid #f0f3f8;
  display: flex;
  justify-content: space-between;
`;

export const UpperContainer = styled.div`
  box-sizing: border-box;
  border-bottom: ${1 / 16}rem solid #f0f3f8;
  padding-bottom: ${25 / 16}rem;
  display: flex;
  justify-content: space-between;
`;

export const LowerContainer = styled.div`
  box-sizing: border-box;
  padding-top: ${25 / 16}rem;
  display: flex;
  justify-content: space-between;
`;

export const AccountNumberStyle = {
  fontSize: 31,
  color: color.primary,
  fontWeight: fontWeight.bold,
};

export const LabelStyle = {
  fontSize: 24,
  color: color.primary,
  fontWeight: fontWeight.regular,
  opacity: 0.5,
};

export const HolderTextStyle = {
  fontSize: 28,
  color: color.primary,
  fontWeight: fontWeight.regular,
};

export const CurrencyStyle = {
  fontSize: 31,
  color: color.primary,
  fontWeight: fontWeight.bold,
  opacity: 0.5,
};

export const BalanceStyle = {
  fontSize: 31,
  color: color.primary,
  fontWeight: fontWeight.bold,
};

export const SavingsAccountTypeBadgeStyle = {
  layout: {
    iconSize: 23,
  },
  theme: {
    text: {
      fontSize: 20,
      color: color.primary, // overwrite by child component
      fontWeight: fontWeight.medium,
    },
  },
};

export const LinkTextStyle = {
  fontSize: 20,
  color: color.text.summerSky,
  fontWeight: fontWeight.regular,
  textDecoration: 'underline',
};

import { useState } from 'react';
import UserVerified from './UserVerified';

export const useUserVerified = (initial?: UserVerified) => {
  const [userVerified, setUserVerified] = useState<UserVerified | undefined>(
    initial,
  );

  return {
    userVerified,
    setUserVerified,
  };
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface TwoFactorAuthAppEditTemplateLayoutProps {
  children: React.ReactElement[];
}

const TwoFactorAuthAppEditTemplateLayout: FC<TwoFactorAuthAppEditTemplateLayoutProps> = ({
  children,
}: TwoFactorAuthAppEditTemplateLayoutProps) => {
  const elements = ['header', 'form'];
  const [header, form] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn height="auto" padding="80 0 80 85">
      {header}
      <Margin top={50} />
      {form}
    </FlexBoxColumn>
  );
};
export default TwoFactorAuthAppEditTemplateLayout;

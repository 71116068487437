import React, { FC } from 'react';
import { Container } from './style';
import Lato from '../Lato';

export interface CircledTextProps {
  text: string;
  textColor: string;
  textSize: string;
  textFontWeight: number;
  size: string;
  backgroundColor: string;
}

const CircledText: FC<CircledTextProps> = ({
  text,
  textColor,
  textSize,
  textFontWeight,
  size,
  backgroundColor,
}: CircledTextProps) => (
  <Container
    style={{
      width: size,
      height: size,
      backgroundColor,
    }}
  >
    <Lato color={textColor} size={textSize} fontWeight={textFontWeight}>
      {text}
    </Lato>
  </Container>
);
export default CircledText;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import SavingsAccount from '../../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

export interface SavingsAccountOptionContextProps {
  savingsAccountOption?: SavingsAccount;
  setSavingsAccountOption: (savingsAccount: SavingsAccount) => void;
}

const SavingsAccountOptionContext = React.createContext<
  SavingsAccountOptionContextProps
>({
  savingsAccountOption: undefined,
  setSavingsAccountOption: (savingsAccount: SavingsAccount) => undefined,
});

export default SavingsAccountOptionContext;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Type
import { FormInputProofOfResidency } from '../../../../../../components/organisms/form/verification/kycRequest/FormProofOfResidency/FormProofOfResidency.type';
import { FormInputShippingAddress } from '../../../../../../components/organisms/form/verification/kycRequest/FormCorporateAddress/FormCorporateAddress.type';

// Constant
import { INITIAL } from '../../../../../../config/constants/requestState';

export interface ProofOfResidencySubmitContextProps {
  state: string;
  submitProofOfResidency: (
    content: FormInputProofOfResidency & FormInputShippingAddress,
    callback?: () => void,
  ) => void;
}

const ProofOfResidencySubmitContext = React.createContext<
  ProofOfResidencySubmitContextProps
>({
  state: INITIAL,
  submitProofOfResidency: (
    content: FormInputProofOfResidency,
    callback?: () => void,
  ) => undefined,
});

export default ProofOfResidencySubmitContext;

// Type
import { CherryAPIFilePurposeType } from '../../types/CherryAPIFilePurposeType';

// IService
import ICherryStorageService from '../../useCases/storage/ICherryStorageService';

// IRepository
import ICherryStorageHandler from '../../storage/cherryStorageHandler/ICherryStorageHandler';

class CherryStorageService implements ICherryStorageService {
  private storageHandler: ICherryStorageHandler;

  constructor(storageHandler: ICherryStorageHandler) {
    this.storageHandler = storageHandler;
  }

  public upload = async (file: File, purpose: CherryAPIFilePurposeType) => {
    return this.storageHandler.upload(file, purpose);
  };
}

export default CherryStorageService;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { CryptoCurrencyType } from '../../../../../../types/CryptoCurrency.type';

export interface CryptoCurrencyOptionContextProps {
  cryptoCurrencyOption: CryptoCurrencyType;
  setCryptoCurrencyOption: (cryptoCurrency: CryptoCurrencyType) => void;
}

const CryptoCurrencyOptionContext = React.createContext<
  CryptoCurrencyOptionContextProps
>({
  cryptoCurrencyOption: CryptoCurrencyType.BTC,
  setCryptoCurrencyOption: (cryptoCurrency: CryptoCurrencyType) => undefined,
});

export default CryptoCurrencyOptionContext;

import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SelectRowTableTopUpContext from 'components/organisms/table/selectRow/SelectRowTableTopUp/SelectRowTableTopUpContext';

// Style
import {
  TableVerticalLayout,
  TableVerticalStyle,
} from 'components/organisms/table/confirm/TableConfirmTransferDebitCardTransactionV2s/style';

// Component
import TableVertical from 'components/molecules/table/table/TableVertical';

// Mapper
import TableConfirmTransferDebitCardTransactionV2sTransactionV2sMapper from 'components/organisms/table/confirm/TableConfirmTransferDebitCardTransactionV2s/TableConfirmTransferDebitCardTransactionV2sMapper';

const TableConfirmTransferDebitCardTransactionV2s: FC = () => {
  const { t } = useTranslation();

  const { selectRowTableTopUp } = useContext(SelectRowTableTopUpContext);

  const rows = useMemo(
    () =>
      !selectRowTableTopUp
        ? []
        : TableConfirmTransferDebitCardTransactionV2sTransactionV2sMapper.toTableData(
          selectRowTableTopUp,
          t,
        ),
    [selectRowTableTopUp, t],
  );

  return (
    <TableVertical
      isTableBorder
      rows={rows}
      layout={TableVerticalLayout}
      theme={TableVerticalStyle}
    />
  );
};

export default TableConfirmTransferDebitCardTransactionV2s;

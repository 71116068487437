import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMaintenanceUseCase } from './useMaintenanceUseCase';
import MaintenanceContext from '../../../../../domain/settings/maintenance/Maintenance/Maintenance/MaintenanceContext';

// Constant
import { ROUTE_MAINTENANCE } from '../../../../../config/constants/routing';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

export type MaintenanceProviderProps = {
  children: React.ReactNode;
};

const MaintenanceProvider: FC<MaintenanceProviderProps> = ({
  children,
}: MaintenanceProviderProps) => {
  const { state, setState, maintenance } = useMaintenanceUseCase();
  const history = useHistory();

  // Redirect to MaintenancePage
  useEffect(() => {
    if (maintenance && maintenance.isCurrentlySystemMaintenance())
      history.push(ROUTE_MAINTENANCE);
    // eslint-disable-next-line
  }, [maintenance]);

  return (
    <MaintenanceContext.Provider value={{ maintenance }}>
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </MaintenanceContext.Provider>
  );
};
export default MaintenanceProvider;

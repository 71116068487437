import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DebitCardRequestCherryVisa01Context from '../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Context';

// Type
import { DescriptionStyle } from './style';

// Component
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import { TextSecondary } from '../../../atoms/text2/Text2';
import CircleSpinLoader from '../../../atoms/spinner/CircleSpinLoader';
import DebitCardRequestCherryVisa01StatusList from '../../../organisms/list/DebitCardRequestCherryVisa01StatusList';
import DebitCardRequestCherryVisa01StatusTemplateLayout from './DebitCardRequestCherryVisa01StatusTemplateLayout';

const TEMPLATE_ID = `templates.debitCards.DebitCardRequestCherryVisa01StatusTemplate`;

const DebitCardRequestCherryVisa01StatusTemplate: FC = () => {
  const { t } = useTranslation();

  const { debitCardRequestCherryVisa01 } = useContext(
    DebitCardRequestCherryVisa01Context,
  );

  return (
    <DebitCardRequestCherryVisa01StatusTemplateLayout
      isLoading={!debitCardRequestCherryVisa01}
    >
      <PageTitle key="header">{t(`${TEMPLATE_ID}.title`)}</PageTitle>
      <CircleSpinLoader key="loader" />
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${TEMPLATE_ID}.description`)}
      </TextSecondary>
      <DebitCardRequestCherryVisa01StatusList
        key="list"
        debitCardRequestCherryVisa01={debitCardRequestCherryVisa01}
      />
    </DebitCardRequestCherryVisa01StatusTemplateLayout>
  );
};

export default DebitCardRequestCherryVisa01StatusTemplate;

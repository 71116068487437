import Joi from '@hapi/joi';

// Type
import { CryptoCurrencyWithdrawalType } from '../../../../../../../types/CryptoCurrencyTransaction.type';
import {
  CryptoCurrencySpotTransactionProps,
  CryptoCurrencySpotTransactionPropsFormat,
} from '../../CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction.type';

export interface CryptoCurrencyWithdrawalProps
  extends CryptoCurrencySpotTransactionProps {
  type: CryptoCurrencyWithdrawalType;
}

export const CryptoCurrencyWithdrawalPropsFormat = {
  ...CryptoCurrencySpotTransactionPropsFormat,

  type: Object.keys(CryptoCurrencyWithdrawalType)
    .reduce((joi, type) => joi.valid(type), Joi.string())
    .required(),
};

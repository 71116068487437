// Type
import { Gender } from '../../../../types/KycRequest.type';
import {
  CherryVisa01ProofOfIdentityDocumentType,
  CherryVisa01ProofOfIdentityProps,
} from './CherryVisa01ProofOfIdentity.type';

// ValueObject
import DebitCardRequestContent from '../DebitCardRequestContent/DebitCardRequestContent';

class CherryVisa01ProofOfIdentity extends DebitCardRequestContent {
  private email: string;

  private firstName: string;

  private middleName?: string;

  private lastName: string;

  private gender: Gender;

  private nationality: string;

  private countryCode: string;

  private phoneNumber: string;

  private occupation: string;

  private isInscribed: boolean;

  private dateOfBirth: string;

  private document: {
    type: CherryVisa01ProofOfIdentityDocumentType;

    issuingCountry: string;

    documentId1: string;

    documentId2?: string;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document1?: any;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document2?: any;
  };

  private passport: {
    passportNo: string;

    issuedAt: string;

    expiredAt: string;

    passportId: string;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    passport?: any;

    selfieId: string;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selfie?: any;
  };

  private signatureId: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private signature?: any;

  private shippingAddress: {
    country: string;

    addressLine1: string;

    addressLine2?: string;

    city: string;

    state: string;

    postalCode: string;
  };

  constructor(props: CherryVisa01ProofOfIdentityProps) {
    super(props);
    this.email = props.email;
    this.firstName = props.firstName;
    this.middleName = props.middleName;
    this.lastName = props.lastName;
    this.gender = props.gender;
    this.nationality = props.nationality;
    this.countryCode = props.countryCode;
    this.phoneNumber = props.phoneNumber;
    this.occupation = props.occupation;
    this.isInscribed = props.isInscribed;
    this.dateOfBirth = props.dateOfBirth;
    this.document = props.document;
    this.passport = props.passport;
    this.signatureId = props.signatureId;
    this.signature = props.signature;
    this.shippingAddress = props.shippingAddress;
  }

  public getProps = () => ({
    ...super.getProps(),
    email: this.email,
    firstName: this.firstName,
    middleName: this.middleName,
    lastName: this.lastName,
    gender: this.gender,
    nationality: this.nationality,
    countryCode: this.countryCode,
    phoneNumber: this.phoneNumber,
    occupation: this.occupation,
    isInscribed: this.isInscribed,
    dateOfBirth: this.dateOfBirth,
    document: this.document,
    passport: this.passport,
    signatureId: this.signatureId,
    signature: this.signature,
    shippingAddress: this.shippingAddress,
  });

  public updateProps = (props: CherryVisa01ProofOfIdentityProps) => {
    return new CherryVisa01ProofOfIdentity({ ...this.getProps(), ...props });
  };
}

export default CherryVisa01ProofOfIdentity;

import React, { FC, useContext } from 'react';
import DebitCardRequestCherryVisa01Context from '../../../../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Context';
import { useDebitCardRequestCherryVisa01ProofOfReceiptUpdateUseCase } from './useDebitCardRequestCherryVisa01ProofOfReceiptUpdateUseCase';
import ProofOfReceiptFileContext from '../../../../../../fileHandler/debitCard/debitCardRequest/ProofOfReceiptFileProvider/CherryVisa01ProofOfReceiptFileContext';
import DebitCardRequestCherryVisa01ProofOfReceiptUpdateContext from './DebitCardRequestCherryVisa01ProofOfReceiptUpdateContext';
import UserAuthorizedContext from '../../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import SystemErrorHandler from '../../../../../../errorHandler/SystemErrorHandler';

// Type
import { FormInputCherryVisa01ProofOfReceiptContext } from '../../../../../../../components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfReceipt/FormCherryVisa01ProofOfReceipt.type';

export type DebitCardRequestCherryVisa01ProofOfReceiptUpdateProviderProps = {
  children: React.ReactNode;
};

const DebitCardRequestCherryVisa01ProofOfReceiptUpdateProvider: FC<DebitCardRequestCherryVisa01ProofOfReceiptUpdateProviderProps> = ({
  children,
}: DebitCardRequestCherryVisa01ProofOfReceiptUpdateProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { debitCardRequestCherryVisa01 } = useContext(
    DebitCardRequestCherryVisa01Context,
  );

  const {
    state,
    setState,
    submitProofOfReceipt,
    formInputProofOfReceiptContext,
    proofOfReceiptFileContext,
  } = useDebitCardRequestCherryVisa01ProofOfReceiptUpdateUseCase(
    debitCardRequestCherryVisa01,
    userAuthorized,
  );

  return (
    <FormInputCherryVisa01ProofOfReceiptContext.Provider
      value={formInputProofOfReceiptContext}
    >
      <DebitCardRequestCherryVisa01ProofOfReceiptUpdateContext.Provider
        value={{
          state,
          submitProofOfReceipt,
        }}
      >
        <ProofOfReceiptFileContext.Provider value={proofOfReceiptFileContext}>
          <SystemErrorHandler state={state} setState={setState}>
            {children}
          </SystemErrorHandler>
        </ProofOfReceiptFileContext.Provider>
      </DebitCardRequestCherryVisa01ProofOfReceiptUpdateContext.Provider>
    </FormInputCherryVisa01ProofOfReceiptContext.Provider>
  );
};
export default DebitCardRequestCherryVisa01ProofOfReceiptUpdateProvider;

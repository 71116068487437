import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface FormTwoFactorAuthAppSettingsLayoutProps {
  children: React.ReactElement[];
}

const FormTwoFactorAuthAppSettingsLayout: FC<FormTwoFactorAuthAppSettingsLayoutProps> = ({
  children,
}: FormTwoFactorAuthAppSettingsLayoutProps) => {
  const elements = ['attention', 'accountKey', 'form', 'button'];
  const [attention, accountKey, form, button] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn width={1200}>
      {attention}
      <Margin top={60} />
      {accountKey}
      <Margin top={60} />
      {form}
      <Margin top={60} />
      {button}
      <Margin bottom={83} />
    </FlexBoxColumn>
  );
};

export default FormTwoFactorAuthAppSettingsLayout;

import React, { FC, useContext } from 'react';
import { useConsentFormSubmitUseCase } from './useConsentFormSubmitUseCase';
import ConsentFormContext from '../../../../../domain/verification/consentForm/ConsentForm/ConsentFormContext';
import ConsentFormFileContext from '../../../../fileHandler/verification/consentForm/ConsentFormFileProvider/ConsentFormFileContext';
import ConsentFormSubmitContext from './ConsentFormSubmitContext';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

// Type
import { FormInputConsentFormContext } from '../../../../../components/organisms/form/verification/consentForm/FormConsentForm/FormConsentForm.type';

// DomainObject
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

export type ConsentFormSubmitProviderProps = {
  children: React.ReactNode;
};

const ConsentFormSubmitProvider: FC<ConsentFormSubmitProviderProps> = ({
  children,
}: ConsentFormSubmitProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { consentForm } = useContext(ConsentFormContext);

  const {
    state,
    setState,
    submitConsentForm,
    formInputConsentFormContext,
    consentFormFileContext,
  } = useConsentFormSubmitUseCase(consentForm, userAuthorized);

  return (
    <FormInputConsentFormContext.Provider value={formInputConsentFormContext}>
      <ConsentFormSubmitContext.Provider value={{ state, submitConsentForm }}>
        <ConsentFormFileContext.Provider value={consentFormFileContext}>
          <SystemErrorHandler state={state} setState={setState}>
            {children}
          </SystemErrorHandler>
        </ConsentFormFileContext.Provider>
      </ConsentFormSubmitContext.Provider>
    </FormInputConsentFormContext.Provider>
  );
};

export default ConsentFormSubmitProvider;

import React, { FC } from 'react';
import { AiFillClockCircle } from 'react-icons/ai';
import { convertRem } from '../../utils/helper/helper';

export interface IconPendingCircleProps {
  theme: {
    color: string;
    backgroundColor: string;
  };
  size: string | number;
}

const IconPendingCircle: FC<IconPendingCircleProps> = ({
  theme,
  size,
}: IconPendingCircleProps) => {
  return (
    <AiFillClockCircle color={theme.backgroundColor} size={convertRem(size)} />
  );
};
export default IconPendingCircle;

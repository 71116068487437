import styled from 'styled-components';
import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${163 / 16}rem;
  padding-left: ${52 / 16}rem;
  background: transparent linear-gradient(180deg, #30a3ea 0%, #005f9a 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  border-radius: ${10 / 16}rem;
  display: flex;
  align-items: center;
`;

export const BalanceContainer = styled.div`
  height: ${64 / 16}rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ConversionContainer = styled.div`
  height: ${64 / 16}rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LabelStyle = {
  fontSize: 18,
  color: color.text.white,
  fontWeight: fontWeight.regular,
};

export const CurrencyAmountStyle = {
  currency: {
    fontSize: 27,
    color: color.text.white,
    fontWeight: fontWeight.bold,
  },
  amount: {
    fontSize: 27,
    color: color.text.white,
    fontWeight: fontWeight.bold,
  },
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// LayoutComponent
import Margin from '../../../atoms/utils/layout/Margin';
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';

export interface SelectUserTypeTemplateLayoutProps {
  children: React.ReactElement[];
}

const SelectUserTypeTemplateLayout: FC<SelectUserTypeTemplateLayoutProps> = ({
  children,
}: SelectUserTypeTemplateLayoutProps) => {
  const elements = ['header', 'form'];
  const [header, form] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn
      alignItems="center"
      justifyContent="center"
      paddingTop={`${(138 / 890) * 100}vh`}
    >
      {header}
      <Margin top={60} />
      {form}
    </FlexBoxColumn>
  );
};
export default SelectUserTypeTemplateLayout;

import Joi from '@hapi/joi';

export interface AddressProps {
  country: string;

  addressLine1: string;

  addressLine2?: string;

  city: string;

  state: string;

  postalCode: string;
}

export const AddressPropsFormat = {
  country: Joi.string().required(),

  addressLine1: Joi.string().required(),

  addressLine2: Joi.string().empty(''),

  city: Joi.string().required(),

  state: Joi.string().required(),

  postalCode: Joi.string().required(),
};

export const AddressPropsAllowEmptyFormat = {
  country: Joi.string().required().allow(''),

  addressLine1: Joi.string().required().allow(''),

  addressLine2: Joi.string().empty(''),

  city: Joi.string().required().allow(''),

  state: Joi.string().required().allow(''),

  postalCode: Joi.string().required().allow(''),
};

export const AddressInitialProps = {
  country: '',

  addressLine1: '',

  city: '',

  state: '',

  postalCode: '',
};

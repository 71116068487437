import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import AssetSummaryTemplate from '../../../components/templates/asset/AssetSummaryTemplate';

const AssetSummaryPage: FC = () => {
  return (
    <DashboardTemplate>
      <AssetSummaryTemplate />
    </DashboardTemplate>
  );
};

export default AssetSummaryPage;

import Joi from '@hapi/joi';

// Type
import { KycStatusType } from '../../../../../types/KycRequest.type';
import {
  KycRequestContentProps,
  KycRequestContentPropsFormat,
} from '../KycRequestContent/KycRequestContent.type';

export interface CorporateInformationProps extends KycRequestContentProps {
  corporateName: string;
  corporateRegistryUrl: string;
}

export const CorporateInformationPropsFormat = {
  ...KycRequestContentPropsFormat,

  corporateName: Joi.string().allow(''),

  corporateRegistryUrl: Joi.string().allow(''),
};

export const CorporateInformationInitialProps = {
  corporateName: '',

  corporateRegistryUrl: '',

  status: KycStatusType.initial,
};

import React, { FC, useContext } from 'react';

// Context
import CryptoCurrencyWalletContext from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWalletContext';
import CryptoCurrencyTransactionsContext from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTransaction/CryptoCurrencyTransactions/CryptoCurrencyTransactionsContext';
import CryptoCurrencyTradeTransactionsContext from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactions/CryptoCurrencyTradeTransactionsContext';
import CryptoCurrencySpotTransactionsContext from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransactions/CryptoCurrencySpotTransactionsContext';
import RequestStateContext from '../../../../value/view/RequestState/RequestStateContext';

// Hooks
import { useCryptoCurrencyTransactionViewUseCase } from './useCryptoCurrencyTransactionViewUseCase';

// Components
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type CryptoCurrencyTransactionViewProviderProps = {
  children: React.ReactNode;
};

const CryptoCurrencyTransactionViewProvider: FC<CryptoCurrencyTransactionViewProviderProps> = ({
  children,
}: CryptoCurrencyTransactionViewProviderProps) => {
  const { cryptoCurrencyWallet } = useContext(CryptoCurrencyWalletContext);

  const {
    state,
    setState,
    cryptoCurrencyTransactions,
    cryptoCurrencyTradeTransactions,
    cryptoCurrencySpotTransactions,
  } = useCryptoCurrencyTransactionViewUseCase(cryptoCurrencyWallet);

  return (
    <RequestStateContext.Provider value={{ requestState: state }}>
      <CryptoCurrencyTransactionsContext.Provider
        value={{ cryptoCurrencyTransactions }}
      >
        <CryptoCurrencyTradeTransactionsContext.Provider
          value={{ cryptoCurrencyTradeTransactions }}
        >
          <CryptoCurrencySpotTransactionsContext.Provider
            value={{ cryptoCurrencySpotTransactions }}
          >
            <SystemErrorHandler state={state} setState={setState}>
              {children}
            </SystemErrorHandler>
          </CryptoCurrencySpotTransactionsContext.Provider>
        </CryptoCurrencyTradeTransactionsContext.Provider>
      </CryptoCurrencyTransactionsContext.Provider>
    </RequestStateContext.Provider>
  );
};
export default CryptoCurrencyTransactionViewProvider;

// Type
import { UseStateType } from '../../../types/typeof/UseState';

// IUseCase
import IContractTimeDepositAddViewUseCase, {
  SettersContractTimeDepositAddView,
} from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositAddViewProvider/IContractTimeDepositAddViewUseCase';

// IService
import ISavingsAccountQueryService from '../../bankAccount/savingsAccount/ISavingsAccountQueryService';
import ITimeDepositQueryService from '../../product/timeDeposit/interface/ITimeDepositQueryService';

// Service
import SavingsAccountQueryService from '../../../service/bankAccount/savingsAccount/SavingsAccountQueryService';
import TimeDepositQueryService from '../../../service/product/timeDeposit/TimeDepositQueryService';

// IRepository
import ISavingsAccountRepository from '../../../domain/bankAccount/savingsAccount/ISavingsAccountRepository';
import ITimeDepositRepository from '../../../domain/product/timeDeposit/ITimeDepositRepository';

// DomainObject
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import UserVerifiedFactory from '../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedFactory';

class ContractTimeDepositAddViewUseCase
  implements IContractTimeDepositAddViewUseCase {
  private timeDepositQueryService: ITimeDepositQueryService;

  private savingsAccountQueryService: ISavingsAccountQueryService;

  constructor(
    timeDepositRepository: ITimeDepositRepository,
    savingsAccountRepository: ISavingsAccountRepository,
  ) {
    this.timeDepositQueryService = new TimeDepositQueryService(
      timeDepositRepository,
    );
    this.savingsAccountQueryService = new SavingsAccountQueryService(
      savingsAccountRepository,
    );
  }

  public open = async (
    user: UserAuthorized,
    setters: SettersContractTimeDepositAddView,
    setState: UseStateType<string>,
  ) => {
    // UserAuthorized -> UserVerified
    const userVerified = UserVerifiedFactory.createByUserAuthorized(user);

    // Fetch
    const [, savingsAccounts] = await Promise.all([
      this.timeDepositQueryService.setAll(setters.timeDeposits, setState),
      this.savingsAccountQueryService.findAllByUserId(userVerified.getId()),
    ]);

    return {
      user: userVerified,
      savingsAccounts,
    };
  };
}
export default ContractTimeDepositAddViewUseCase;

import React, { FC } from 'react';
import ConsentFormPage from './ConsentFormPage';
import ConsentFormViewProvider from '../../../enhancers/useCase/verification/consentForm/ConsentFormViewProvider';
import ConsentFormSubmitProvider from '../../../enhancers/useCase/verification/consentForm/ConsentFormSubmitProvider';

const EnhancedConsentFormPage: FC = () => {
  return (
    <ConsentFormViewProvider>
      <ConsentFormSubmitProvider>
        <ConsentFormPage />
      </ConsentFormSubmitProvider>
    </ConsentFormViewProvider>
  );
};

export default EnhancedConsentFormPage;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormShippingAddressLayoutProps {
  children: React.ReactElement[];
}

const FormShippingAddressLayout: FC<FormShippingAddressLayoutProps> = ({
  children,
}: FormShippingAddressLayoutProps) => {
  const elements = ['description', 'shippingAddress'];
  const [description, shippingAddress] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn width={1200} height="auto">
      {description}
      <Margin top={60} />
      {shippingAddress}
      <Margin top={40} />
    </FlexBoxColumn>
  );
};
export default FormShippingAddressLayout;

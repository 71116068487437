import React, { FC, useContext, useEffect } from 'react';
import { useModal } from 'react-modal-hook';
import ImportantNoticeModalContext from './ImportantNoticeModalContext';
import NotificationLogContext from '../../../../domain/notification/notificationLog/NotificationLog/NotificationLogContext';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Provider
import NotificationLogCreateReadLogProvider from '../../../../enhancers/useCase/notification/NotificationLogCreateReadLogProvider';

// Component
import ImportantNoticeModal from '.';

export type ImportantNoticeModalProviderProps = {
  children: React.ReactNode;
};

const ImportantNoticeModalProvider: FC<ImportantNoticeModalProviderProps> = ({
  children,
}: ImportantNoticeModalProviderProps) => {
  const userAuthorizedContext = useContext(UserAuthorizedContext);
  const { notificationLog } = useContext(NotificationLogContext);

  const [showModal, hideModal] = useModal(
    () => (
      <UserAuthorizedContext.Provider value={userAuthorizedContext}>
        <NotificationLogCreateReadLogProvider>
          <ImportantNoticeModalContext.Provider
            value={{ showModal, hideModal }}
          >
            <NotificationLogContext.Provider value={{ notificationLog }}>
              <ImportantNoticeModal />
            </NotificationLogContext.Provider>
          </ImportantNoticeModalContext.Provider>
        </NotificationLogCreateReadLogProvider>
      </UserAuthorizedContext.Provider>
    ),
    [notificationLog, userAuthorizedContext],
  );

  useEffect(() => {
    if (!notificationLog) return;

    if (!notificationLog.getProps().isRead) showModal();
    // eslint-disable-next-line
  }, [notificationLog]);

  return (
    <ImportantNoticeModalContext.Provider value={{ showModal, hideModal }}>
      {children}
    </ImportantNoticeModalContext.Provider>
  );
};
export default ImportantNoticeModalProvider;

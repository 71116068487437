// Helper
import { convertMoneyFormat } from '../../../../../utils/helpers/currencyHelper';

// Type
import { TypeOfT } from '../../../../../types/typeof/Translation.type';

// DomainObject
import DebitCardDepositV2 from '../../../../../domain/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardDepositV2/DebitCardDepositV2/DebitCardDepositV2';
import DebitCardSpotTransactionV2 from '../../../../../domain/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardSpotTransactionV2/DebitCardSpotTransactionV2/DebitCardSpotTransactionV2';
import DebitCardSpotTransactionV2s from '../../../../../domain/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardSpotTransactionV2/DebitCardSpotTransactionV2s/DebitCardSpotTransactionV2s';
import DebitCardWithdrawalV2 from '../../../../../domain/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardWithdrawalV2/DebitCardWithdrawalV2/DebitCardWithdrawalV2';

// ValueObject
import TimeZonedTime from '../../../../../value/datetime/TimeZonedTime';

type Object = { [key: string]: string | number };

export interface TdDebitCardTransactionHistory extends Object {
  createdAtDisplay: string;
  createdAtString: string;
  createdAtCSV: string;
  transactionType: string;
  yearMonth: string;
  description: string;
  deposit: string;
  withdrawal: string;
  balance: string;
}

class TableDebitCardTransactionHistoryMapper {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static domainToTd = (
    transactions: DebitCardSpotTransactionV2s,
    t: TypeOfT,
  ) => {
    const converter = (
      debitCardSpotTransaction: DebitCardSpotTransactionV2,
    ) => {
      // Props
      const {
        createdAt,
        currency,
        amount,
      } = debitCardSpotTransaction.getProps();
      const type = debitCardSpotTransaction.getTransactionType();

      const tdCreatedAtDisplay = new TimeZonedTime(
        createdAt,
      ).getTwoLinesDateTime();

      const tdCreatedAtString = new TimeZonedTime(createdAt).format(
        'YYYYMMDD HHmmss',
      );

      const tdCreatedAtCSV = new TimeZonedTime(createdAt).getDateTime();

      const yearMonth = new TimeZonedTime(createdAt).format('YYYYMM');

      const tdDeposit =
        debitCardSpotTransaction instanceof DebitCardDepositV2
          ? convertMoneyFormat(amount, currency)
          : '';

      const tdWithdrawal =
        debitCardSpotTransaction instanceof DebitCardWithdrawalV2
          ? convertMoneyFormat(amount, currency)
          : '';

      const tdTransactionType =
        debitCardSpotTransaction instanceof DebitCardWithdrawalV2
          ? t(
              `transaction.debitCardTransaction.type.debitCardWithdrawal.${type}`,
            )
          : t(`transaction.debitCardTransaction.type.debitCardDeposit.${type}`);

      return {
        createdAtDisplay: tdCreatedAtDisplay,
        createdAtString: tdCreatedAtString,
        createdAtCSV: tdCreatedAtCSV,
        yearMonth,
        description: debitCardSpotTransaction.createDescription(t),
        deposit: tdDeposit,
        withdrawal: tdWithdrawal,
        transactionType: tdTransactionType,
      } as TdDebitCardTransactionHistory;
    };

    return transactions.map<TdDebitCardTransactionHistory>(converter);
  };
}

export default TableDebitCardTransactionHistoryMapper;

// Type
import { TransferSavingsAccountType } from 'types/Transfer.type';

// IUseCase
import { TransferSavingsAccountCreateParameter } from 'enhancers/useCase/transfer/savingsAccount/TransferSavingsAccountCreateProvider/ITransferSavingsAccountCreateUseCase';

// IService
import ITransferSavingsAccountCreateService from 'useCases/transfer/savingsAccount/interface/ITransferSavingsAccountCreateService';

// IRepository
import {
  ITransferSavingsAccountTransactionCreateRepository,
  ITransferSavingsAccountTransactionCreateToECBRepository,
} from 'domain/transaction/transferSavingsAccountTransaction/ITransferSavingsAccountTransactionRepository';

// IAPI
import ISavingsAccountQueryAPI from 'api/interface/savingsAccount/ISavingsAccountQueryAPI';

// Domain
import TransferSavingsAccountTransactionSeedFactory from 'domain/transaction/transferSavingsAccountTransaction/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeedFactory';
import TransferSavingsAccountTransactionSeed from 'domain/transaction/transferSavingsAccountTransaction/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed';

class TransferSavingsAccountCreateService
  implements ITransferSavingsAccountCreateService {
  private transferSavingsAccountRepository: ITransferSavingsAccountTransactionCreateRepository;

  private transferSavingsAccountECBRepository: ITransferSavingsAccountTransactionCreateToECBRepository;

  private savingsAccountQueryAPI: ISavingsAccountQueryAPI;

  constructor(
    transferSavingsAccountRepository: ITransferSavingsAccountTransactionCreateRepository,
    transferSavingsAccountECBRepository: ITransferSavingsAccountTransactionCreateToECBRepository,
    savingsAccountQueryAPI: ISavingsAccountQueryAPI,
  ) {
    this.transferSavingsAccountRepository = transferSavingsAccountRepository;
    this.transferSavingsAccountECBRepository = transferSavingsAccountECBRepository;
    this.savingsAccountQueryAPI = savingsAccountQueryAPI;
  }

  public create = async (params: TransferSavingsAccountCreateParameter) => {
    const {
      beneficiaryAccountNumber,
      savingsAccount,
      purpose,
      transferSavingsAccountType,
    } = params;

    const contractName =
      transferSavingsAccountType === TransferSavingsAccountType.forbes
        ? await this.savingsAccountQueryAPI.getContractNameFromAccountNumber(
            beneficiaryAccountNumber,
          )
        : await this.savingsAccountQueryAPI.getContractNameFromAccountNumberECB(
            beneficiaryAccountNumber,
          );

    const { currency } = savingsAccount.getProps();

    const transferSavingsAccountTransactionSeed = TransferSavingsAccountTransactionSeedFactory.create(
      {
        savingsAccountNumber: savingsAccount.getRawAccountNumber(),
        beneficiaryAccountNumber: params.beneficiaryAccountNumber.replace(
          /-/g,
          '',
        ),
        amount: Number(params.amount),
        currency,
        beneficiaryAccountHolder: contractName,
        transferSavingsAccountType,
        purpose,
        fee: params.fee,
      },
    );

    return transferSavingsAccountTransactionSeed;
  };

  public executeCreate = async (
    transferSavingsAccountTransactionSeed: TransferSavingsAccountTransactionSeed,
  ) => {
    const props = transferSavingsAccountTransactionSeed.getProps();
    const transferSavingsAccountTransaction =
      props.transferSavingsAccountType === TransferSavingsAccountType.forbes
        ? await this.transferSavingsAccountRepository.create(
            transferSavingsAccountTransactionSeed,
          )
        : await this.transferSavingsAccountECBRepository.createECB(
            transferSavingsAccountTransactionSeed,
          );
    return transferSavingsAccountTransaction;
  };
}

export default TransferSavingsAccountCreateService;

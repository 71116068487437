import React, { FC } from 'react';
import QRCode from 'qrcode.react';

// LayoutComponent
import Box from '../../utils/layout/Box/Box';

export interface QRCodeImageProps {
  width: string | number;
  height: string | number;
  value?: string;
}

const QRCodeImage: FC<QRCodeImageProps> = ({
  width,
  height,
  value,
}: QRCodeImageProps) => (
  <Box width={width} height={height}>
    {value ? (
      <QRCode
        value={value}
        renderAs="svg"
        style={{ width: '100%', height: '100%' }}
      />
    ) : (
      <></>
    )}
  </Box>
);

export default QRCodeImage;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ResignUpContext from '../../../../enhancers/useCase/authentication/ResignUpProvider/ResignUpContext';

// Constant
import { RE_SIGN_UP_COMPLETE } from '../../../../config/constants/pageId.json';

// Style
import {
  DescriptionStyle,
  EmailStyle,
  NavigationTextStyle,
  SwitchContainer,
} from './style';

// Component
import { FrontPageTitle } from '../../../atoms/headLine/HeadLine';
import Breadcrumb from '../../../molecules/link/Breadcrumb';
import SwitchResendEmailCountdown from '../../../organisms/switch/SwitchResendEmailAndCountdown';
import ResignUpCompleteTemplateLayout from './ResignUpCompleteTemplateLayout';
import { TextSecondary } from '../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';

const ResignUpCompleteTemplate: FC = () => {
  const { t } = useTranslation();
  const { email, resendResignUpComplete } = useContext(ResignUpContext);
  const history = useHistory();

  return (
    <ResignUpCompleteTemplateLayout>
      <FrontPageTitle key="title">
        {t(`${RE_SIGN_UP_COMPLETE}.title`)}
      </FrontPageTitle>
      <TextSecondary key="description1" theme={DescriptionStyle}>
        <LineBreakReplaced text={t(`${RE_SIGN_UP_COMPLETE}.description1`)} />
      </TextSecondary>
      <TextSecondary key="email" theme={EmailStyle}>
        {email}
      </TextSecondary>
      <SwitchContainer key="description2">
        <SwitchResendEmailCountdown
          key="description2"
          margin={`${16 / 16}rem`}
          onClickResend={() => resendResignUpComplete()}
        />
      </SwitchContainer>
      <Breadcrumb
        key="link"
        onClick={() => {
          history.go(0);
        }}
        iconSize={10}
        marginBetween={13}
        text={t(`${RE_SIGN_UP_COMPLETE}.link.back`)}
        theme={{ text: NavigationTextStyle }}
      />
    </ResignUpCompleteTemplateLayout>
  );
};

export default ResignUpCompleteTemplate;

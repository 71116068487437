/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { INITIAL } from '../../../../config/constants/requestState';

export interface RegisterContextProps {
  registerUser: (formInput: {
    username: string;
    password: string;
  }) => Promise<void>;
  state: string;
}

const RegisterContext = React.createContext<RegisterContextProps>({
  registerUser: (formInput: { username: string; password: string }) =>
    new Promise(() => undefined),
  state: INITIAL,
});

export default RegisterContext;

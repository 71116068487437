/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { INITIAL } from '../../../config/constants/requestState';

export interface ResetPasswordContextProps {
  resetPasswordState: string;
  setResetPasswordState: (state: string) => void;
  resetPassword: (password: string) => void;
}

export const ResetPasswordContext = React.createContext<
  ResetPasswordContextProps
>({
  resetPasswordState: INITIAL,
  setResetPasswordState: (state: string) => undefined,
  resetPassword: (password: string) => undefined,
});

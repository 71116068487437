import styled from 'styled-components';
import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const Hover = styled.div`
  width: 100%;
  height: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  :hover {
    background-color: #f0f1f7;
  }
`;

export const SingleValueContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const OptionContainer = styled.div`
  width: 100%;
`;

export const DescriptionStyle = {
  fontSize: 20,
  color: color.text.zambezi,
  fontWeight: fontWeight.medium,
};

export const MinDepositStyle = {
  fontSize: 17,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

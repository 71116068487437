import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Type
import { KycStatusType } from '../../../../types/KycRequest.type';

// Component
import FormTwoFactorAuthAppSettings from '../../../organisms/form/twoFactorAuthAppSettings/FormTwoFactorAuthAppSettings';
import KycStepTemplate from '../KycStepTemplate';

const TEMPLATE_ID = `templates.verification.TwoFactorAuthAppSettingsTemplate`;

const TwoFactorAuthAppSettingsTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <KycStepTemplate
      status={KycStatusType.initial}
      title={t(`${TEMPLATE_ID}.title`)}
    >
      <FormTwoFactorAuthAppSettings />
    </KycStepTemplate>
  );
};

export default TwoFactorAuthAppSettingsTemplate;

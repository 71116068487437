import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  ShippingAddressProps,
  ShippingAddressPropsFormat,
} from './ShippingAddress.type';

// DomainObject
import ShippingAddress from './ShippingAddress';

class ShippingAddressFactory {
  static create = (props: ShippingAddressProps) => {
    const result = Joi.object(ShippingAddressPropsFormat).validate(props);

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: ShippingAddress.name,
          reason: result.error.details[0].message,
        },
      );

    return new ShippingAddress(props);
  };
}

export default ShippingAddressFactory;

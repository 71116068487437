import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

// Style
import { ContainerStyle } from './style';

export interface ImportantNoticeModalLayoutProps {
  children: React.ReactElement[];
}

const ImportantNoticeModalLayout: FC<ImportantNoticeModalLayoutProps> = ({
  children,
}: ImportantNoticeModalLayoutProps) => {
  const elements = ['notification', 'form'];
  const [notification, form] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn
      height="auto"
      theme={ContainerStyle}
      style={{
        maxWidth: `${649 / 16}rem`,
        maxHeight: `90%`,
        overflowY: `scroll`,
      }}
      padding="80 40"
    >
      {notification}
      <Margin top={50} />
      {form}
    </FlexBoxColumn>
  );
};
export default ImportantNoticeModalLayout;

import React, { FC, useContext } from 'react';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Type
import { MenuType } from '../../../../config/menu/main/Menu.type';
import MenuFactory from '../../../../config/menu/main/MenuFactory';

// Component
import MenuItem from '../../../molecules/item/MenuItem';
import {
  SettingsMenu,
  CryptoCurrencyMenu,
  SavingsAccountMenu,
  TimeDepositMenu,
  DebitCardMenu,
  TransferMenu,
  InvitationMenu,
  JointAccountMenu,
} from '../../../molecules/item/SubMenu';

const MenuList: FC = () => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  if (!userAuthorized) return <></>;

  const menuItems = MenuFactory.createUserMenu(userAuthorized);

  return (
    <>
      {menuItems.map((menu) => {
        switch (menu) {
          case MenuType.settings:
            return <SettingsMenu key={menu} user={userAuthorized} />;
          case MenuType.cryptocurrencies:
            return <CryptoCurrencyMenu key={menu} />;
          case MenuType.savingsAccounts:
            return <SavingsAccountMenu key={menu} />;
          case MenuType.timeDeposits:
            return <TimeDepositMenu key={menu} />;
          case MenuType.debitCards:
            return <DebitCardMenu key={menu} />;
          case MenuType.transfer:
            return <TransferMenu key={menu} />;
          case MenuType.jointAccounts:
            return <JointAccountMenu key={menu} />;
          case MenuType.invitations:
            return <InvitationMenu key={menu} />;
          default:
            return <MenuItem key={menu} type={menu as MenuType} />;
        }
      })}
    </>
  );
};

export default MenuList;

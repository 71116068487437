import { useState } from 'react';
import CryptoCurrencyTradeFeeRate from './CryptoCurrencyTradeFeeRate';

export const useCryptoCurrencyTradeFeeRate = (
  defaultValue?: CryptoCurrencyTradeFeeRate,
) => {
  const [cryptoCurrencyTradeFeeRate, setCryptoCurrencyTradeFeeRate] = useState<
    CryptoCurrencyTradeFeeRate | undefined
  >(defaultValue);

  return {
    cryptoCurrencyTradeFeeRate,
    setCryptoCurrencyTradeFeeRate,
  };
};

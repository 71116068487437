import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  BankFutureCommissionTimeDepositProps,
  BankFutureCommissionTimeDepositPropsFormat,
} from './BankFutureCommissionTimeDeposit.type';

// DomainObject
import BankFutureCommissionTimeDeposit from './BankFutureCommissionTimeDeposit';
import BankTransactionId from '../../../../../../idManager/transactionId/BankTransactionId/BankTransactionId';

class BankFutureCommissionTimeDepositFactory {
  static create = (
    id: BankTransactionId,
    props: BankFutureCommissionTimeDepositProps,
  ) => {
    const result = Joi.object(
      BankFutureCommissionTimeDepositPropsFormat,
    ).validate(props);

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: BankFutureCommissionTimeDeposit.name,
          reason: result.error.details[0].message,
        },
      );

    return new BankFutureCommissionTimeDeposit(id, props);
  };
}

export default BankFutureCommissionTimeDepositFactory;

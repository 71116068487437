import { useState } from 'react';

export type TransferSavingsAccountPageState =
  | 'initial'
  | 'confirm'
  | 'complete';

export const useTransferSavingsAccountPageState = () => {
  const [pageState, setPageState] = useState<TransferSavingsAccountPageState>(
    'initial',
  );

  return {
    pageState,
    setPageState,
  };
};

import React from 'react';

export interface HTMLElementsAdjustContextProps<T, U> {
  element1: {
    ref?: React.RefObject<T>;
    height: number;
    width: number;
  };
  element2: {
    ref?: React.RefObject<U>;
    height: number;
    width: number;
  };
  heightLonger: number;
}

export const createHTMLElementsAdjustContext = <T, U>() => {
  return React.createContext<HTMLElementsAdjustContextProps<T, U>>({
    element1: {
      ref: undefined,
      height: 0,
      width: 0,
    },
    element2: {
      ref: undefined,
      height: 0,
      width: 0,
    },
    heightLonger: 0,
  });
};

const HTMLElementsAdjustContext = createHTMLElementsAdjustContext<
  HTMLDivElement,
  HTMLDivElement
>();

export default HTMLElementsAdjustContext;

import React, { FC } from 'react';
import AuthPageLanguageSelectorHeader from '../../../organisms/header/HeaderAuthPageLanguageSelector';
import HeaderLeftLogo from '../../../organisms/header/HeaderLeftLogo';
import CopyRightFooter from '../../../organisms/footer/CopyRightFooter';
import SignUpPageGroupTemplateLayout from './SignUpPageGroupTemplateLayout';

export interface SignUpPageGroupTemplateProps {
  children: React.ReactNode;
}

const SignUpPageGroupTemplate: FC<SignUpPageGroupTemplateProps> = ({
  children,
}: SignUpPageGroupTemplateProps) => {
  return (
    <SignUpPageGroupTemplateLayout>
      <AuthPageLanguageSelectorHeader key="header" />
      <div key="header">header</div>
      <HeaderLeftLogo key="leftImage" />
      <div key="content">{children}</div>
      <CopyRightFooter key="footer" />
    </SignUpPageGroupTemplateLayout>
  );
};

export default SignUpPageGroupTemplate;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface FormInputCherryVisa01ShippingAddressLayoutProps {
  children: React.ReactElement[];
}

const FormInputCherryVisa01ShippingAddressLayout: FC<FormInputCherryVisa01ShippingAddressLayoutProps> = ({
  children,
}: FormInputCherryVisa01ShippingAddressLayoutProps) => {
  const elements = ['address', 'phoneNumber'];
  const [address, contactNumber] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn>
      {address}
      <Margin top={80} />
      <FlexBoxRow justifyContent="flex-start" width={767}>
        {contactNumber}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};

export default FormInputCherryVisa01ShippingAddressLayout;

import Collection from './collection/Collection';
import firestore from './firestore';

export interface TestDoc {
  a: number;
}

class Tests extends Collection {
  static readonly COLLECTION_NAME: string = 'tests';

  collection: firestore.CollectionReference;

  constructor() {
    super();
    this.collection = firestore().collection(Tests.COLLECTION_NAME);
    console.log(typeof this.collection);
  }
}

export default Tests;

import React, { FC } from 'react';
import ForgotPassword from '../../../enhancers/front/ForgotPassword';
import ForgotPasswordPage from './ForgotPasswordPage';

const EnhancedForgotPasswordPage: FC = () => {
  return (
    <ForgotPassword>
      <ForgotPasswordPage />
    </ForgotPassword>
  );
};

export default EnhancedForgotPasswordPage;

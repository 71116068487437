import styled from 'styled-components';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';
import color from '../../../../../atoms/utils/const/color';

export const Hover = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  text-align: left;
  align-items: center;
  :hover {
    background-color: #f0f1f7;
  }
`;

export const OptionTextStyle = {
  fontSize: 20,
  color: color.text.zambezi,
  fontWeight: fontWeight.medium,
};

import React, { FC } from 'react';
import { FiDownload } from 'react-icons/fi';
import { convertRem } from '../../../utils/helper/helper';

// Component
import { TextSecondary, TextThemeProps } from '../../../text2/Text2';
import LabelDownloadIconLayout from './LabelDownloadIconLayout';

export interface LabelDownloadIconProps {
  label: string;
  layout: {
    icon: string | number;
    marginBetween: number | string;
  };
  theme: {
    icon: {
      color: string;
    };
    text: TextThemeProps;
  };
}

const LabelDownloadIcon: FC<LabelDownloadIconProps> = ({
  label,
  layout,
  theme,
}: LabelDownloadIconProps) => {
  return (
    <LabelDownloadIconLayout marginBetween={layout.marginBetween}>
      <FiDownload
        key="icon"
        color={theme.icon.color}
        size={convertRem(layout.icon)}
      />
      <TextSecondary key="label" theme={theme.text}>
        {label}
      </TextSecondary>
    </LabelDownloadIconLayout>
  );
};

export default LabelDownloadIcon;

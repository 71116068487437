import Joi from '@hapi/joi';
import { UserRequestStatusType } from '../../../../types/UserRequest.type';

export interface ResignUpRequestProps {
  expiredAt: number;

  lastMailSentAt?: number;

  oobCode: string;

  reregistrationCode: string;

  status: UserRequestStatusType;

  createdAt?: number;

  updatedAt?: number;
}

export const ResignUpRequestPropsFormat = {
  id: Joi.string().min(1).required(),

  expiredAt: Joi.number().min(0).required(),

  lastMailSentAt: Joi.number().empty(''),

  oobCode: Joi.string().min(1).required(),

  reregistrationCode: Joi.string().min(1).required(),

  status: Object.keys(UserRequestStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexRow } from '../../../../../atoms/utils/layout/Flex';
import { Width } from '../../../../../atoms/utils/layout/Width';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormInputTransactionAmountLayoutProps {
  children: React.ReactElement[];
  inputWidth: string | number;
}

const FormInputTransactionAmountLayout: FC<FormInputTransactionAmountLayoutProps> = (
  props: FormInputTransactionAmountLayoutProps,
) => {
  const { children, inputWidth } = props;
  const elements = ['label', 'currency', 'input'];
  const [label, currency, input] = getElementsFromKeys(children, elements);

  return (
    <Width widthValue="100%">
      <FlexRow justifyContent="space-between" alignItems="center">
        {label}
        <FlexRow alignItems="center">
          <Margin right={25}>{currency}</Margin>
          <Width widthValue={inputWidth}>{input}</Width>
        </FlexRow>
      </FlexRow>
    </Width>
  );
};
export default FormInputTransactionAmountLayout;

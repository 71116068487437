import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconContainer, IconWrap, DescriptionContainer } from './style';
import { AUTH_ERROR } from '../../../../config/constants/pageId.json';
import { ROUTE_HOME } from '../../../../config/constants/routing';
import fontColor from '../../../../fonts/color.json';
import QuestionIconImage from '../../../atoms/image/QuestionIconImage';
import { DescriptionFront } from '../../../molecules/text/DomainDescription';
import BreadcrumbList from '../../../organisms/list/BreadcrumbList';

const AuthErrorTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <IconContainer>
        <IconWrap>
          <QuestionIconImage />
        </IconWrap>
      </IconContainer>
      <div style={{ marginTop: `${47.57 / 16}rem` }}>
        <DescriptionContainer>
          <DescriptionFront
            lines={[
              t(`${AUTH_ERROR}.description.line1`),
              t(`${AUTH_ERROR}.description.line2`),
            ]}
            marginBottom={`${8 / 16}rem`}
            color={fontColor.textDark}
          />
        </DescriptionContainer>
      </div>
      <div style={{ marginTop: `${55 / 16}rem` }}>
        <BreadcrumbList
          color={fontColor.textDark}
          linkedTexts={[
            {
              text: t(`${AUTH_ERROR}.link.top`),
              urlList: [
                {
                  replaceText: t(`${AUTH_ERROR}.link.top`),
                  url: ROUTE_HOME,
                },
              ],
            },
          ]}
        />
      </div>
    </>
  );
};

export default AuthErrorTemplate;

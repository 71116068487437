// IUseCase
import ITwoFactorAuthAppSettingsSubmitUseCase from '../../../enhancers/useCase/verification/consentForm/TwoFactorAuthAppSettingsSubmitProvider/ITwoFactorAuthAppSettingsUpdateUseCase';

// IService
import ITwoFactorAuthAppSettingsUpdateService from './ITwoFactorAuthAppSettingsUpdateService';

// Service
import TwoFactorAuthAppSettingsUpdateService from '../../../service/verification/consentForm/TwoFactorAuthAppSettingsUpdateService';

// IRepository
import { IConsentFormUpdateRepository } from '../../../domain/verification/consentForm/IConsentFormRepository';

// DomainObject
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class TwoFactorAuthAppSettingsSubmitUseCase
  implements ITwoFactorAuthAppSettingsSubmitUseCase {
  private twoFactorAuthAppSettingsUpdateService: ITwoFactorAuthAppSettingsUpdateService;

  constructor(consentFormUpdateRepository: IConsentFormUpdateRepository) {
    this.twoFactorAuthAppSettingsUpdateService = new TwoFactorAuthAppSettingsUpdateService(
      consentFormUpdateRepository,
    );
  }

  public submit = async (user: UserAuthorized, code: string) => {
    await this.twoFactorAuthAppSettingsUpdateService.update(user, code);
  };
}
export default TwoFactorAuthAppSettingsSubmitUseCase;

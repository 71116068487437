import styled from 'styled-components';
import color from '../../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  display: flex;
`;

export const LabelGroup = styled.div`
  width: ${200 / 16}rem;
  display: flex;
  flex-direction: column;
`;

export const LabelContainer = styled.div`
  width: ${171 / 16}rem;
  display: flex;
  align-items: center;
`;

export const FormInputGroup = styled.div``;

export const FormInputContainer = styled.div`
  display: flex;
`;

export const TextFieldRow = styled.div`
  width: ${(400 + 400 + 39) / 16}rem;
  display: flex;
`;

export const LabelStyle = {
  fontSize: 21,
  color: color.text.primary,
  fontWeight: fontWeight.regular,
};

// Type
import { ContractTimeDepositSeedProps } from './ContractTimeDepositSeed.type';

// Constant
import { DATE_FORMAT } from '../../../../config/constants/business';

// DomainObject
import ContractTimeDeposit from '../ContractTimeDeposit/ContractTimeDeposit';

// ValueObject
import SerialNumberContractTimeDeposit from '../../../idManager/productSerialNumber/ProductSerialNumber/SerialNumber/concreteSerialNumber/SerialNumberContractTimeDeposit';

class ContractTimeDepositSeed extends ContractTimeDeposit {
  constructor(props: ContractTimeDepositSeedProps) {
    super(
      SerialNumberContractTimeDeposit.createNumberFromRawId('0100000000'),
      props,
    );
  }

  public getRequestParameterCreate = () => ({
    id: this.props.userId,
    savingsAccountNumber: this.savingsAccountNumber.getAccountNumber(),
    depositAmount: this.props.depositAmount,
    depositCurrency: this.props.depositCurrency,
    commissionRate: this.props.commissionRate,
    interestRate: this.props.interestRate,
    term: this.props.term,
    depositDate: this.depositDate.format(DATE_FORMAT),
    maturityDate: this.maturityDate.format(DATE_FORMAT),
    isAnnualPayOut: this.props.isAnnualPayOut,
  });
}

export default ContractTimeDepositSeed;

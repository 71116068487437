// React
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';
import { executeWithTimeout } from '../../../../utils/helpers/timeout';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';
import { REQUEST_TIMEOUT } from '../../../../config/constants/business';

// UseCase
import RegisterViewUseCase from '../../../../useCases/authentication/register/RegisterViewUseCase';

// Repository, Functions
import SignUpRequestFirestore from '../../../../repository/userRequest/signUpRequest/SignUpRequestFirestore';

// DomainObject
import { useSignUpRequest } from '../../../../domain/userRequest/signUpRequest/SignUpRequest/useSignUpRequest';

export const useRegisterViewUseCase = (oobCode: string) => {
  const [state, setState] = useState<string>(INITIAL);
  const { t } = useTranslation();

  /**
   *
   * DomainObject
   *
   */

  const { signUpRequest, setSignUpRequest } = useSignUpRequest();

  /**
   *
   *  Repository
   *
   */
  const signUpRequestRepository = new SignUpRequestFirestore();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new RegisterViewUseCase(signUpRequestRepository);

  /**
   *
   *  Method
   *
   */
  const openRegisterPage = useCallback(async () => {
    setState(IS_LOADING);

    try {
      checkInternetConnection();

      // UseCase.open
      const outputs = await executeWithTimeout(
        useCase.open(oobCode, t),
        REQUEST_TIMEOUT,
      );

      setSignUpRequest(outputs.signUpRequest);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, []);

  /**
   *
   *  UseEffect
   *
   */
  useEffect(
    () => {
      let isMounted = true;

      if (isMounted) openRegisterPage();

      return () => {
        isMounted = false;
      };
    },
    // eslint-disable-next-line
    [],
  );

  return {
    state,
    setState,
    signUpRequest,
  };
};

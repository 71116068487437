import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { Container, Content } from './style';
import Margin from '../../../atoms/utils/layout/Margin';

export interface TimeDepositTemplateLayoutProps {
  children: React.ReactElement[];
}

const TimeDepositTemplateLayout: FC<TimeDepositTemplateLayoutProps> = ({
  children,
}: TimeDepositTemplateLayoutProps) => {
  const elements = ['header', 'tabBar', 'total', 'cardList'];
  const [header, tabBar, total, cardList] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <Container>
      {header}
      <Margin top={45} />
      {tabBar}
      <Content>
        {total}
        <Margin top={40} />
        {cardList}
      </Content>
    </Container>
  );
};
export default TimeDepositTemplateLayout;

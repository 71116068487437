/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState, useEffect } from 'react';
import { checkInternetConnection } from '../../../../../../utils/helpers/connection';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../../config/constants/requestState';
import ErrorHandler from '../../../../../../utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import DebitCardRequestCherryVisa01PayIssuanceFeeViewUseCase from '../../../../../../useCases/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01PayIssuanceFeeViewUseCase';

// Repository
import SavingsAccountFunctions from '../../../../../../repository/bankAccount/savingsAccount/SavingsAccountFunctions';

// DomainObject
import UserAuthorized from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import { useSavingsAccount } from '../../../../../../domain/bankAccount/savingsAccount/SavingsAccount/useSavingsAccount';

export const useDebitCardRequestCherryVisa01PayIssuanceFeeViewUseCase = (
  savingsAccountNumber?: string,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  DomainObject
   *
   * */
  const { savingsAccount, setSavingsAccount } = useSavingsAccount();

  /* *
   *
   *  Repository
   *
   * */
  const savingsAccountRepository = new SavingsAccountFunctions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new DebitCardRequestCherryVisa01PayIssuanceFeeViewUseCase(
    savingsAccountRepository,
  );

  /* *
   *
   *  Method
   *
   * */
  const open = useCallback(async () => {
    if (!savingsAccountNumber || !userAuthorized) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const output = await useCase.open(savingsAccountNumber);

      setSavingsAccount(output.savingsAccount);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized]);

  /* *
   *
   *  UseCase
   *
   * */
  useEffect(() => {
    let isMounted = true;

    if (userAuthorized && isMounted) open();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [open]);

  return {
    state,
    setState,
    savingsAccount,
  };
};

// Types
import { FiatCurrencyType } from '../../../types/FiatCurrency.type';

// IService
import IAssetTimeDepositService from '../../../useCases/asset/assetSummary/IAssetTimeDepositQueryService';

// IRepository
import IAssetTimeDepositRepository from '../../../domain/asset/assetTimeDeposit/IAssetTimeDepositRepository';

class AssetTimeDepositQueryService implements IAssetTimeDepositService {
  private assetTimeDepositRepository: IAssetTimeDepositRepository;

  constructor(assetTimeDepositRepository: IAssetTimeDepositRepository) {
    this.assetTimeDepositRepository = assetTimeDepositRepository;
  }

  public findUserTimeDepositAsset = async (
    userId: string,
    currency: FiatCurrencyType,
  ) => {
    const AssetTimeDeposit = this.assetTimeDepositRepository.findByPrimaryKey({
      userId,
      currency,
    });

    return AssetTimeDeposit;
  };
}
export default AssetTimeDepositQueryService;

import { useState } from 'react';
import DocumentTypes from './DocumentTypes';

export const useDocumentTypes = (defaultValue?: DocumentTypes) => {
  const [documentTypes, setDocumentTypes] = useState<DocumentTypes>(
    defaultValue || new DocumentTypes(),
  );

  return {
    documentTypes,
    setDocumentTypes,
  };
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState, useEffect } from 'react';
import { checkInternetConnection } from '../../../../../../utils/helpers/connection';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../../config/constants/requestState';
import ErrorHandler from '../../../../../../utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import DebitCardRequestCherryVisa01StatusViewUseCase from '../../../../../../useCases/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01StatusViewUseCase';

// Repository
import DebitCardRequestCherryVisa01Functions from '../../../../../../repository/debitCard/debitCardRequest/DebitCardRequestCherryVisa01Functions';

// DomainObject
import { useDebitCardRequestCherryVisa01 } from '../../../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/useDebitCardRequestCherryVisa01';
import UserAuthorized from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useDebitCardRequestCherryVisa01StatusViewUseCase = (
  id: string,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /**
   *
   *  DomainObject
   *
   */
  const {
    debitCardRequestCherryVisa01,
    setDebitCardRequestCherryVisa01,
  } = useDebitCardRequestCherryVisa01();

  /**
   *
   *  Repository
   *
   */
  const debitCardRequestCherryVisa01Repository = new DebitCardRequestCherryVisa01Functions();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new DebitCardRequestCherryVisa01StatusViewUseCase(
    debitCardRequestCherryVisa01Repository,
  );

  /**
   *
   *  Method
   *
   */
  const openDebitCardRequestCherryVisa01StatusPage = useCallback(async () => {
    if (!userAuthorized) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const output = await useCase.open(id);

      setDebitCardRequestCherryVisa01(output.debitCardRequestCherryVisa01);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized]);

  /**
   *
   *  UseCase
   *
   */
  useEffect(() => {
    let isMounted = true;

    if (userAuthorized && isMounted)
      openDebitCardRequestCherryVisa01StatusPage();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [openDebitCardRequestCherryVisa01StatusPage]);

  return {
    state,
    setState,
    debitCardRequestCherryVisa01,
  };
};

import React, { FC, useContext } from 'react';
import DebitCardRequestCherryVisa01Context from '../../../../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Context';
import DebitCardRequestCherryVisa01PayIssuanceFeeContext from './DebitCardRequestCherryVisa01PayIssuanceFeeContext';
import UserAuthorizedContext from '../../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import SystemErrorHandler from '../../../../../../errorHandler/SystemErrorHandler';

// UseCase
import { useDebitCardRequestCherryVisa01PayIssuanceFeeUseCase } from './useDebitCardRequestCherryVisa01PayIssuanceFeeUseCase';

export type DebitCardRequestCherryVisa01PayIssuanceFeeProviderProps = {
  children: React.ReactNode;
};

const DebitCardRequestCherryVisa01PayIssuanceFeeProvider: FC<DebitCardRequestCherryVisa01PayIssuanceFeeProviderProps> = ({
  children,
}: DebitCardRequestCherryVisa01PayIssuanceFeeProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { debitCardRequestCherryVisa01 } = useContext(
    DebitCardRequestCherryVisa01Context,
  );

  const {
    state,
    setState,
    submit,
  } = useDebitCardRequestCherryVisa01PayIssuanceFeeUseCase(
    debitCardRequestCherryVisa01,
    userAuthorized,
  );

  return (
    <DebitCardRequestCherryVisa01PayIssuanceFeeContext.Provider
      value={{
        state,
        submit,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </DebitCardRequestCherryVisa01PayIssuanceFeeContext.Provider>
  );
};
export default DebitCardRequestCherryVisa01PayIssuanceFeeProvider;

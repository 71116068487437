import { useState } from 'react';
import { checkInternetConnection } from '../../../../../utils/helpers/connection';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import TwoFactorAuthAppSettingsSubmitUseCase from '../../../../../useCases/verification/consentForm/TwoFactorAuthAppSettingsSubmitUseCase';

// Repository
import ConsentFormFunctions from '../../../../../repository/verification/consentForm/ConsentFormFunctions';

// DomainObject
import UserAuthorized from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useTwoFactorAuthAppSettingsSubmitUseCase = (
  user?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);

  // Repository
  const consentFormUpdateRepository = new ConsentFormFunctions();

  // UseCase
  const useCase = new TwoFactorAuthAppSettingsSubmitUseCase(
    consentFormUpdateRepository,
  );

  const submitTwoFactorAuthAppSettings = async (
    code: string,
    callback?: () => void,
  ) => {
    setState(IS_LOADING);

    if (!user) return;

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase.submit
      await useCase.submit(user, code);

      if (callback) callback();

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  return {
    state,
    setState,
    submitTwoFactorAuthAppSettings,
  };
};

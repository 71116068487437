import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle } from './style';

// Context
import ContractTimeDepositRenewalReservationUpdateContext from '../../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationUpdateProvider/ContractTimeDepositRenewalReservationUpdateContext';

// Component
import { TextSecondary } from '../../../atoms/text2/Text2';
import HeaderContractTimeDepositRenewalReservationEditConfirm from '../../../organisms/header/HeaderContentTitle/contract/HeaderContractTimeDepositRenewalReservationEditConfirm';
import ProgressStepBarBankTransaction from '../../../molecules/progressStep/ProgressStepBar/ProgressStepBarBankTransaction';
import PropertyListContractTimeDepositAfterMatured from '../../../organisms/propertyList/contract/PropertyListContractTimeDepositAfterMatured';
import FormContractTimeDepositRenewalReservationConfirm from '../../../organisms/form/contract/contractTimeDeposit/FormContractTimeDepositRenewalReservationConfirm';
import ContractTimeDepositRenewalReservationEditConfirmTemplateLayout from './ContractTimeDepositRenewalReservationEditConfirmTemplateLayout';

const TEMPLATE_ID =
  'templates.contract.ContractTimeDepositRenewalReservationEditConfirmTemplate';

const ContractTimeDepositRenewalReservationAddConfirmTemplate: FC = () => {
  const { t } = useTranslation();

  // Execute Update
  const {
    requestState,
    executeUpdateContractTimeDepositRenewalReservation,
  } = useContext(ContractTimeDepositRenewalReservationUpdateContext);

  return (
    <ContractTimeDepositRenewalReservationEditConfirmTemplateLayout>
      <HeaderContractTimeDepositRenewalReservationEditConfirm key="header" />
      <ProgressStepBarBankTransaction
        key="progressStep"
        currentStep="confirm"
      />
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${TEMPLATE_ID}.description`)}
      </TextSecondary>
      <FormContractTimeDepositRenewalReservationConfirm
        key="form"
        requestState={requestState}
        onSubmit={executeUpdateContractTimeDepositRenewalReservation}
      />
      <PropertyListContractTimeDepositAfterMatured key="propertyList" />
    </ContractTimeDepositRenewalReservationEditConfirmTemplateLayout>
  );
};
export default ContractTimeDepositRenewalReservationAddConfirmTemplate;

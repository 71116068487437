import React, { FC } from 'react';
import { Container, Content } from './style';

export interface CryptoCurrencyTemplateContainerProps {
  children: React.ReactNode;
}

const CryptoCurrencyTemplateContainer: FC<CryptoCurrencyTemplateContainerProps> = ({
  children,
}: CryptoCurrencyTemplateContainerProps) => (
  <Container>
    <Content>{children}</Content>
  </Container>
);

export default CryptoCurrencyTemplateContainer;

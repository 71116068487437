import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle } from './style';

// Component
import { TextSecondary } from '../../../../../../atoms/text2/Text2';
import FormInputDocumentTypeSelectBox from '../../../../../../molecules/form/formInput/selectBox/verification/FormInputDocumentTypeSelectBox';
import KycFormContainer from '../../../../common/KycFormContainer';
import LineBreakReplaced from '../../../../../../atoms/text2/LineBreakReplaced';
import SelectDocumentTypeLayout from './SelectDocumentTypeLayout';

const FORM_ID = `organisms.form.verification.FormProofOfResidency.selectDocumentType`;

const SelectDocumentType: FC = () => {
  const { t } = useTranslation();

  return (
    <KycFormContainer order={2} formId={FORM_ID}>
      <SelectDocumentTypeLayout>
        <TextSecondary key="description" theme={DescriptionStyle}>
          <LineBreakReplaced text={t(`${FORM_ID}.description`)} />
        </TextSecondary>
        <FormInputDocumentTypeSelectBox
          key="selectBox"
          inputWidth={400}
          label={t(`${FORM_ID}.documentType.label`)}
          name="documentType"
        />
      </SelectDocumentTypeLayout>
    </KycFormContainer>
  );
};

export default SelectDocumentType;

import CryptoCurrencySpotTransaction from '../CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction';
import { IIterable } from '../../../../../../interface/Iterator/IIterable';

class CryptoCurrencySpotTransactions
  implements IIterable<CryptoCurrencySpotTransaction> {
  private cryptoCurrencySpotTransactions: CryptoCurrencySpotTransaction[] = [];

  constructor(
    cryptoCurrencySpotTransactions?: CryptoCurrencySpotTransaction[],
  ) {
    this.cryptoCurrencySpotTransactions = cryptoCurrencySpotTransactions || [];
  }

  public add = (
    cryptoCurrencySpotTransaction: CryptoCurrencySpotTransaction,
  ) => {
    this.cryptoCurrencySpotTransactions.push(cryptoCurrencySpotTransaction);
  };

  public head = () => this.cryptoCurrencySpotTransactions[0];

  public map = <T>(
    callback: (
      CryptoCurrencySpotTransaction: CryptoCurrencySpotTransaction,
    ) => T,
  ) => {
    return this.cryptoCurrencySpotTransactions.map(
      (cryptoCurrencySpotTransaction) =>
        callback(cryptoCurrencySpotTransaction),
    );
  };

  public forEach = (
    callback: (
      CryptoCurrencySpotTransaction: CryptoCurrencySpotTransaction,
    ) => void,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const cryptoCurrencySpotTransaction of this
      .cryptoCurrencySpotTransactions) {
      // eslint-disable-next-line no-await-in-loop
      callback(cryptoCurrencySpotTransaction);
    }
  };

  public forEachAsync = async (
    callback: (
      CryptoCurrencySpotTransaction: CryptoCurrencySpotTransaction,
    ) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const cryptoCurrencySpotTransaction of this
      .cryptoCurrencySpotTransactions) {
      // eslint-disable-next-line no-await-in-loop
      await callback(cryptoCurrencySpotTransaction);
    }
  };

  public sortDescByCreatedAt = () => {
    const compare = (
      a: CryptoCurrencySpotTransaction,
      b: CryptoCurrencySpotTransaction,
    ) => {
      const aCreatedAt = a.getProps().createdAt!;
      const bCreatedAt = b.getProps().createdAt!;

      return aCreatedAt < bCreatedAt ? 1 : -1;
    };
    return new CryptoCurrencySpotTransactions(
      this.cryptoCurrencySpotTransactions.sort(compare),
    );
  };
}
export default CryptoCurrencySpotTransactions;

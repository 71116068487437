// IService
import IDebitSpotTransactionV2QueryService from '../../../../useCases/transaction/debitCardTransaction/debitCardSpotTransaction/IDebitCardSpotTransactionV2QueryService';

// IRepository
import { IDebitCardSpotTransactionV2QueryRepository } from '../../../../domain/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardSpotTransactionV2/IDebitCardSpotTransactionV2Repository';

class DebitSpotTransactionV2QueryService
  implements IDebitSpotTransactionV2QueryService {
  private debitCardSpotTransactionV2QueryRepository: IDebitCardSpotTransactionV2QueryRepository;

  constructor(
    debitCardSpotTransactionV2QueryRepository: IDebitCardSpotTransactionV2QueryRepository,
  ) {
    this.debitCardSpotTransactionV2QueryRepository = debitCardSpotTransactionV2QueryRepository;
  }

  public findAllByDebitCardId = async (debitCardId: string) => {
    const debitCardSpotTransactions = await this.debitCardSpotTransactionV2QueryRepository.findAllByCardId(
      debitCardId,
    );

    return debitCardSpotTransactions;
  };
}

export default DebitSpotTransactionV2QueryService;

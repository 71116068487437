import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';

// Type
import { TransferDebitCardType } from '../../../../../types/Transfer.type';

// Constant
import {
  IS_LOADING,
  INITIAL,
} from '../../../../../config/constants/requestState';

// LayoutComponent
import { FlexBoxRow } from '../../../../atoms/utils/layout/Box/FlexBox';
import Display from '../../../../atoms/div/Display';
import Margin from '../../../../atoms/utils/layout/Margin';
import TransferTemplateContainer from '../../common/TransferTemplateContainer';
import TransferTemplateContent from '../../common/TransferTemplateContent';

export interface TransferDebitCardTemplateLayoutProps {
  children: React.ReactElement[];
  hasDebitCard: boolean;
  transferType: TransferDebitCardType;
  state: string;
}

const TransferDebitCardTemplateLayout: FC<TransferDebitCardTemplateLayoutProps> = ({
  children,
  hasDebitCard,
  transferType,
  state,
}: TransferDebitCardTemplateLayoutProps) => {
  const elements = [
    'transactionType',
    'header',
    'progressStep',
    'savingsAccountToCard',
    'cardToSavingsAccount',
    // 'noCardDescription',
    'loading',
  ];
  const [
    transactionType,
    header,
    progressStep,
    savingsAccountToCard,
    cardToSavingsAccount,
    // noCardDescription,
    loading,
  ] = getElementsFromKeys(children, elements);

  return (
    <TransferTemplateContainer>
      {header}
      <Margin top={50} />
      <FlexBoxRow justifyContent="center">{progressStep}</FlexBoxRow>
      <Margin top={100} />
      <TransferTemplateContent>
        {transactionType}
        <Margin top={30} />
        {/* <Display
          isDisplay={!hasDebitCard && state !== IS_LOADING && state !== INITIAL}
        >
          <FlexBoxRow alignItems="center" height={435} justifyContent="center">
            {noCardDescription}
          </FlexBoxRow>
        </Display> */}
        <Display isDisplay={state === IS_LOADING || state === INITIAL}>
          <FlexBoxRow alignItems="center" height={435} justifyContent="center">
            {loading}
          </FlexBoxRow>
        </Display>
        <Display
          isDisplay={
            hasDebitCard &&
            transferType === TransferDebitCardType.savingsAccountToCard
          }
        >
          {savingsAccountToCard}
        </Display>
        <Display
          isDisplay={
            hasDebitCard &&
            transferType === TransferDebitCardType.cardToSavingsAccount
          }
        >
          {cardToSavingsAccount}
        </Display>
      </TransferTemplateContent>
    </TransferTemplateContainer>
  );
};
export default TransferDebitCardTemplateLayout;

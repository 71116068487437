// Error
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';

// Type
import { UserType } from '../../../types/User.type';

// IService
import IKycRequestQueryService from '../../../useCases/verification/kycRequest/IKycRequestQueryService';

// IRepository
import { IKycRequestQueryRepository } from '../../../domain/verification/kycRequest/KycRequest/IKycRequestRepository';

// DomainObject
import KycRequestCorporate from '../../../domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporate';
import KycRequestIndividual from '../../../domain/verification/kycRequest/KycRequest/KycRequestIndividual/KycRequestIndividual';

class KycRequestQueryService implements IKycRequestQueryService {
  private kycRequestQueryRepository: IKycRequestQueryRepository;

  constructor(kycRequestQueryRepository: IKycRequestQueryRepository) {
    this.kycRequestQueryRepository = kycRequestQueryRepository;
  }

  public findByIdOrUndef = async (id: string) => {
    const kycRequest = await this.kycRequestQueryRepository.findByIdOrUndef(id);

    return kycRequest;
  };

  public findById = async (id: string) => {
    const kycRequest = await this.kycRequestQueryRepository.findById(id);

    return kycRequest;
  };

  public findCorporateById = async (id: string) => {
    const kycRequest = await this.kycRequestQueryRepository.findById(id);

    if (!(kycRequest instanceof KycRequestCorporate))
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
        {
          type: 'KycRequestCorporate',
          place: 'KycRequestQueryService.findCorporateById',
        },
      );

    return kycRequest;
  };

  public findIndividualById = async (id: string) => {
    const kycRequest = await this.kycRequestQueryRepository.findById(id);

    if (!(kycRequest instanceof KycRequestIndividual))
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
        {
          type: 'KycRequestIndividual',
          place: 'KycRequestQueryService.findIndividualById',
        },
      );

    return kycRequest;
  };

  public findByIdAndUserType = async (id: string, userType: UserType) => {
    const kycRequest = await this.kycRequestQueryRepository.findByIdAndUserType(
      id,
      userType,
    );

    return kycRequest;
  };
}

export default KycRequestQueryService;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import {
  convertRem,
  convertRemPadding,
} from '../../../../atoms/utils/helper/helper';

// Component
import Tr from '../../../../atoms/table/Table/Tr';
import Th from '../../../../atoms/table/Table/Th';
import Td, { TdBorderBottom } from '../../../../atoms/table/Table/Td';

export interface TrTableVerticalLayoutProps {
  children: React.ReactElement[];
  isBordered: boolean;
  layout: {
    rowHeight: number | string;
    thWidth: number | string;
    tdWidth: number | string;
    thPadding?: string;
    tdPadding?: string;
  };
  theme: {
    td: {
      backgroundColor: string;
      borderBottom?: string;
      textAlign?: string;
    };
    th: {
      backgroundColor: string;
      borderBottom?: string;
      textAlign?: string;
    };
  };
}

const TrTableVerticalLayout: FC<TrTableVerticalLayoutProps> = ({
  children,
  isBordered,
  layout,
  theme,
}: TrTableVerticalLayoutProps) => {
  const elements = ['header', 'data'];
  const [header, data] = getElementsFromKeys(children, elements);

  return (
    <Tr style={{ textAlign: 'right' }}>
      <Th
        height={layout.rowHeight}
        minWidth={layout.thWidth}
        style={{ padding: convertRem(layout.thPadding || 25) }}
        textAlign="left"
        theme={{
          ...theme.th,
          width: convertRem(layout.thWidth),
        }}
        verticalAlign="middle"
      >
        {header}
      </Th>
      {(() => {
        if (isBordered)
          return (
            <TdBorderBottom
              style={{
                ...convertRemPadding({
                  padding: layout.tdPadding || '10 25',
                }),
                textAlign: (theme.td.textAlign as 'left' | 'right') || 'left',
              }}
              theme={{
                ...theme.td,
                width: convertRem(layout.tdWidth),
              }}
              width={layout.tdWidth}
              verticalAlign="middle"
            >
              {data}
            </TdBorderBottom>
          );
        return (
          <Td
            style={{
              ...convertRemPadding({
                padding: layout.tdPadding || '10 25',
              }),
              textAlign: (theme.td.textAlign as 'left' | 'right') || 'left',
            }}
            theme={{
              ...theme.td,
              width: convertRem(layout.tdWidth),
            }}
            width={layout.tdWidth}
            verticalAlign="middle"
          >
            {data}
          </Td>
        );
      })()}
    </Tr>
  );
};
export default TrTableVerticalLayout;

import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedPageVewProvider from '../enhancers/useCase/authentication/AuthenticatedPageViewProvider';
import AccessHandlerUserStatusProvider from '../enhancers/routing/AccessHandlerUserStatusProvider';

// Type
import { MenuType } from '../config/menu/main/Menu.type';

// Constant
import { ALLOWED_USER_STATUS } from './accessHandler/userStatusPageAccessMapper';
import { ROUTE_MANUAL } from '../config/constants/routing';

// Component
import { EnhancedManualPage } from '../containers/manual/ManualPage';

const ManualRouter: FC = () => {
  return (
    <Switch>
      <AuthenticatedPageVewProvider>
        <AccessHandlerUserStatusProvider
          statusAllowed={ALLOWED_USER_STATUS[MenuType.manual]}
        >
          <Route exact path={ROUTE_MANUAL}>
            <EnhancedManualPage />
          </Route>
        </AccessHandlerUserStatusProvider>
      </AuthenticatedPageVewProvider>
    </Switch>
  );
};

export default ManualRouter;

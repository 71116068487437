import Joi from '@hapi/joi';

// Error
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import {
  ContractTimeDepositHistoryProps,
  ContractTimeDepositHistoryPropsFormat,
} from './ContractTimeDepositHistory.type';

// DomainObject
import ContractTimeDepositHistory from './ContractTimeDepositHistory';

class ContractTimeDepositHistoryFactory {
  static create = (id: string, props: ContractTimeDepositHistoryProps) => {
    const result = Joi.object(ContractTimeDepositHistoryPropsFormat).validate({
      id,
      ...props,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: ContractTimeDepositHistoryFactory.name,
          reason: result.error.details[0].message,
        },
      );

    return new ContractTimeDepositHistory(id, props);
  };
}

export default ContractTimeDepositHistoryFactory;

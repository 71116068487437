import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
// import { Container } from './style';

// LayoutComponent
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface MaintenanceTemplateLayoutProps {
  children: React.ReactElement[];
}

const MaintenanceTemplateLayout: FC<MaintenanceTemplateLayoutProps> = ({
  children,
}: MaintenanceTemplateLayoutProps) => {
  const elements = ['title', 'description', 'schedule', 'logo'];
  const [title, description, schedule, logo] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn alignItems="center" justifyContent="center">
      <FlexBoxColumn height={500} width="auto" alignItems="center">
        {title}
        <Margin top={11} />
        {description}
        <Margin top={50} />
        {schedule}
        <Margin top={40} />
        {logo}
        <Margin bottom={150} />
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};
export default MaintenanceTemplateLayout;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { FlexBoxRow } from '../../../atoms/utils/layout/Box/FlexBox';

export interface DebitCardNumberLayoutProps {
  children: React.ReactElement[];
  layout: {
    width4digits: string | number;
    height: string | number;
  };
}

const DebitCardNumberLayout: FC<DebitCardNumberLayoutProps> = ({
  children,
  layout,
}: DebitCardNumberLayoutProps) => {
  const elements = [
    'number01',
    'number02',
    'number03',
    'number04',
    'number05',
    'number06',
    'number07',
    'number08',
    'number09',
    'number10',
    'number11',
    'number12',
    'number13',
    'number14',
    'number15',
    'number16',
  ];
  const [
    number01,
    number02,
    number03,
    number04,
    number05,
    number06,
    number07,
    number08,
    number09,
    number10,
    number11,
    number12,
    number13,
    number14,
    number15,
    number16,
  ] = getElementsFromKeys(children, elements);

  // const digits4Margin = '22%';

  return (
    <FlexBoxRow
      width="100%"
      // height={'23%'}
      height={layout.height}
      justifyContent="space-between"
    >
      <FlexBoxRow width={layout.width4digits} justifyContent="space-between">
        {number01}
        {number02}
        {number03}
        {number04}
      </FlexBoxRow>
      <FlexBoxRow width={layout.width4digits} justifyContent="space-between">
        {number05}
        {number06}
        {number07}
        {number08}
      </FlexBoxRow>
      <FlexBoxRow width={layout.width4digits} justifyContent="space-between">
        {number09}
        {number10}
        {number11}
        {number12}
      </FlexBoxRow>
      <FlexBoxRow width={layout.width4digits} justifyContent="space-between">
        {number13}
        {number14}
        {number15}
        {number16}
      </FlexBoxRow>
    </FlexBoxRow>
  );
};
export default DebitCardNumberLayout;

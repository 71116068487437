import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle, AccountKeyStyle } from './style';

// Component
import { GoogleAuthenticatorAccountKeyQRCode } from '../../../../../atoms/barcode/QRCodeImage';
import { TextSecondary } from '../../../../../atoms/text2/Text2';
import AttentionMessageBox from '../../../../notification/AttentionMessageBox';
import LineBreakReplace from '../../../../../atoms/text2/LineBreakReplaced';
import KycFormContainer from '../../../common/KycFormContainer';
import AccountKeyDetailLayout from './AccountKeyDetailLayout';

const FORM_ID =
  'organisms.form.verification.FormTwoFactorAuthAppSettings.accountKeyDetail';

export interface AccountKeyDetailProps {
  accountName: string | undefined;
  accountKey: string | undefined;
}

const AccountKeyDetail: FC<AccountKeyDetailProps> = ({
  accountName,
  accountKey,
}: AccountKeyDetailProps) => {
  const { t } = useTranslation();

  // DomainObject
  // const { authenticator } = useContext(AuthenticatorContext);

  // Variable
  // const accountKey = authenticator?.getProps().accountKey;

  // console.log({ authenticator });
  // console.log({ accountKey });

  return (
    <KycFormContainer key="accountKey" order={2} formId={FORM_ID}>
      <AccountKeyDetailLayout>
        <GoogleAuthenticatorAccountKeyQRCode
          key="qrcode"
          accountName={accountName}
          accountKey={accountKey}
        />
        <TextSecondary key="description" theme={DescriptionStyle}>
          {t(`${FORM_ID}.description`)}
        </TextSecondary>
        <TextSecondary key="accountKey" theme={AccountKeyStyle}>
          {accountKey}
        </TextSecondary>
        <AttentionMessageBox key="attention">
          <TextSecondary key="description" theme={DescriptionStyle}>
            <LineBreakReplace text={t(`${FORM_ID}.attentionMessage`)} />
          </TextSecondary>
        </AttentionMessageBox>
      </AccountKeyDetailLayout>
    </KycFormContainer>
  );
};

export default AccountKeyDetail;

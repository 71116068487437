import { useState } from 'react';
import CryptoCurrencyTradeTransactions from './CryptoCurrencyTradeTransactions';

export const useCryptoCurrencyTradeTransactions = (
  defaultValue?: CryptoCurrencyTradeTransactions,
) => {
  const [
    cryptoCurrencyTradeTransactions,
    setCryptoCurrencyTradeTransactions,
  ] = useState<CryptoCurrencyTradeTransactions>(
    defaultValue || new CryptoCurrencyTradeTransactions(),
  );

  return {
    cryptoCurrencyTradeTransactions,
    setCryptoCurrencyTradeTransactions,
  };
};

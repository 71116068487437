import React, { FC } from 'react';

// Style
import { LabelStyle } from './style';

// Component
import { TextPrimary } from '../../../../../atoms/text2/Text2';
import TextField from '../../../TextField2';
import CountryCodeSelectBox from '../../../../selectBox/selectBox/verification/CountryCodeSelectBox';
import FormInputPhoneNumberLayout from './FormInputPhoneNumberLayout';

interface FormInputPhoneNumberLayoutProps {
  label: string;
  inputWidth: string | number;
  onChange?: (value: string) => void;
  name: {
    countryCode: string;
    phoneNumber: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any;
}

const FormInputPhoneNumber: FC<FormInputPhoneNumberLayoutProps> = ({
  label,
  inputWidth,
  onChange,
  name,
  register,
}: FormInputPhoneNumberLayoutProps) => {
  return (
    <FormInputPhoneNumberLayout>
      <TextPrimary key="label" theme={LabelStyle}>
        {label}
      </TextPrimary>
      <CountryCodeSelectBox key="countryCode" name={name.countryCode} />
      <TextField
        key="number"
        name={name.phoneNumber || 'phoneNumber'}
        textInputWidth={inputWidth}
        maxLength={15}
        onChange={onChange}
        register={register}
      />
    </FormInputPhoneNumberLayout>
  );
};

export default FormInputPhoneNumber;

import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  AssetDebitCardProps,
  AssetDebitCardPropsFormat,
} from './AssetDebitCard.type';
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';

// DomainObject
import AssetDebitCard from './AssetDebitCard';

class AssetDebitCardFactory {
  static create = (id: string, props: AssetDebitCardProps) => {
    const result = Joi.object(AssetDebitCardPropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: AssetDebitCard.name,
          reason: `${result.error.details[0].message}`,
        },
      );

    return new AssetDebitCard(id as FiatCurrencyType, props);
  };
}

export default AssetDebitCardFactory;

import Joi from '@hapi/joi';

// Type
import {
  DebitCardIssuerType,
  DebitCardType,
} from '../../../../../types/DebitCard.type';

// Constant
import { FORMAT_SAVINGS_ACCOUNT_NUMBER } from '../../../../../config/constants/regularExpression';

export interface DebitCardRequestProps {
  savingsAccountNumber: string;

  type: DebitCardType;

  issuer: DebitCardIssuerType;

  isCompleted: boolean;

  userId: string;

  createdAt?: number;

  updatedAt?: number;
}

export interface DebitCardRequestRawDataType {
  id: string;

  props: DebitCardRequestProps;
}

export const DebitCardRequestPropsFormat = {
  id: Joi.string().min(1).required(),

  savingsAccountNumber: Joi.string()
    .length(10)
    .pattern(FORMAT_SAVINGS_ACCOUNT_NUMBER),

  type: Object.keys(DebitCardType)
    .reduce((joi, type) => joi.valid(type), Joi.string())
    .required(),

  isCompleted: Joi.boolean().required(),

  issuer: Object.keys(DebitCardIssuerType)
    .reduce((joi, type) => joi.valid(type), Joi.string())
    .required(),

  userId: Joi.string().required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

export const DebitCardRequestInitialProps = {
  isCompleted: false,
};

import Joi from '@hapi/joi';
import { FiatCurrencyType } from '../../../../../types/FiatCurrency.type';

export interface BankTransactionProps {
  id?: string;

  savingsAccountNumber: string;

  amount: number;

  currency: FiatCurrencyType;

  descriptionParameter: string;

  createdAt?: number;

  updatedAt?: number;
}

export const BankTransactionPropsFormat = {
  savingsAccountNumber: Joi.string().min(1).required(),

  amount: Joi.number().min(0).required(),

  currency: Object.keys(FiatCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),

  descriptionParameter: Joi.string().allow('').required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

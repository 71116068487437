import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Component
import { DescriptionStyle } from './style';
import { TextSecondary } from '../../../../atoms/text2/Text2';
import HeaderTransferSavingsAccountConfirm from '../../../../organisms/header/HeaderContentTitle/transfer/HeaderTransferSavingsAccountConfirm';
import ProgressStepBarBankTransaction from '../../../../molecules/progressStep/ProgressStepBar/ProgressStepBarBankTransaction';
import TableConfirmTransferSavingsAccount from '../../../../organisms/table/confirm/TableTransferSavingsAccount/TableConfirmTransferSavingsAccount';
import FormTransferSavingsAccountConfirm from '../../../../organisms/form/transfer/savingsAccount/FormTransferSavingsAccountConfirm';
import TransferSavingsAccountConfirmTemplateLayout from './TransferSavingsAccountConfirmTemplateLayout';

const TEMPLATE_ID = `templates.transfers.TransferSavingsAccountConfirmTemplate`;

const TransferSavingsAccountConfirmTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <TransferSavingsAccountConfirmTemplateLayout>
      <HeaderTransferSavingsAccountConfirm key="header" />
      <ProgressStepBarBankTransaction
        key="progressStep"
        currentStep="confirm"
      />
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${TEMPLATE_ID}.description`)}
      </TextSecondary>
      <TableConfirmTransferSavingsAccount key="table" />
      <FormTransferSavingsAccountConfirm key="form" />
    </TransferSavingsAccountConfirmTemplateLayout>
  );
};

export default TransferSavingsAccountConfirmTemplate;

import React, { FC } from 'react';
import CherryVisa01ProofOfIdentityPage from './CherryVisa01ProofOfIdentityPage';
import CherryVisa01ProofOfIdentityViewProvider from '../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01ProofOfIdentityViewProvider';
import CherryVisa01ProofOfIdentitySubmitProvider from '../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01ProofOfIdentityUpdateProvider/DebitCardRequestCherryVisa01ProofOfIdentityUpdateProvider';

const EnhancedCherryVisa01ProofOfIdentityPage: FC = () => {
  return (
    <CherryVisa01ProofOfIdentityViewProvider>
      <CherryVisa01ProofOfIdentitySubmitProvider>
        <CherryVisa01ProofOfIdentityPage />
      </CherryVisa01ProofOfIdentitySubmitProvider>
    </CherryVisa01ProofOfIdentityViewProvider>
  );
};

export default EnhancedCherryVisa01ProofOfIdentityPage;

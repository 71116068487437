import { takeLatest } from 'redux-saga/effects';
// test
import { userPressPlusButton, userPressMinusButton } from './counter/user.saga';
// test
import { userClickTestFetchButton } from './test/user.saga';

// test
import CounterActionType from '../constants/counter.constant';
// test
import TestActionType from '../constants/test.constant';

export default function* rootSaga() {
  /** test * */
  yield takeLatest(
    CounterActionType.USER_PRESS_PLUS_BUTTON,
    userPressPlusButton,
  );
  yield takeLatest(
    CounterActionType.USER_PRESS_MINUS_BUTTON,
    userPressMinusButton,
  );
  yield takeLatest(
    TestActionType.USER_CLICK_TEST_FETCH_BUTTON,
    userClickTestFetchButton,
  );
}

// Type
import { KycStatusType } from '../../../../../types/KycRequest.type';
import { KycStepStatusCorporateProps } from './KycStepStatusCorporate.type';

// ValueObject
import KycStepStatus from '../KycStepStatus/KycStepStatus';

class KycStepStatusCorporate extends KycStepStatus {
  private corporateInformation: KycStatusType;

  private corporateAddress: KycStatusType;

  constructor(props: KycStepStatusCorporateProps) {
    super(props);
    this.corporateInformation = props.corporateInformation;
    this.corporateAddress = props.corporateAddress;
  }

  public getProps = () => ({
    ...super.getProps(),
    corporateInformation: this.corporateInformation,
    corporateAddress: this.corporateAddress,
  });
}
export default KycStepStatusCorporate;

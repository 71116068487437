import React, { FC, useContext } from 'react';
import { useClock } from '../../../../hooks/common/clock';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import { DatetimeTextStyle, UserNameTextStyle } from './style';
import { TextPrimary, TextSecondary } from '../../../atoms/text2/Text2';
import HeaderDashboardLayout from './HeaderDashboardLayout';

const HeaderDashboard: FC = () => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { datetime } = useClock();
  const userName = userAuthorized && userAuthorized.getDisplayName();

  return (
    <HeaderDashboardLayout>
      <TextSecondary key="datetime" theme={DatetimeTextStyle}>
        {datetime}
      </TextSecondary>
      <TextPrimary key="userName" theme={UserNameTextStyle}>
        {userName || ''}
      </TextPrimary>
    </HeaderDashboardLayout>
  );
};

export default HeaderDashboard;

// Lib
import { isEqualArbitraryOrder } from '../../../../../utils/helpers/arrayHelper';

// Error
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';

// Types
import { CryptoCurrencyType } from '../../../../../types/CryptoCurrency.type';
import { UseStateType } from '../../../../../types/typeof/UseState';

// Collection
import firestore from '../../../../../infrastructure/firebase/firestore/client/firestoreClient';
import CryptoCurrencyDepositsCollection from '../../../../../infrastructure/firebase/firestore/collections/transaction/cryptoCurrencyTransaction/addresses/CryptoCurrencyDepositsCollection';

// IRepository
import ICryptoCurrencyDepositRepository from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/ICryptoCurrencyDepositRepository';

// DomainObject
import CryptoCurrencyDeposits from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/CryptoCurrencyDeposits/CryptoCurrencyDeposits';
import CryptoCurrencyDeposit from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/CryptoCurrencyDeposit/CryptoCurrencyDeposit';
import CryptoCurrencyDepositFactory from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/CryptoCurrencyDeposit/CryptoCurrencyDepositFactory';
import { CryptoCurrencyDepositProps } from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/CryptoCurrencyDeposit/CryptoCurrencyDeposit.type';
import CryptoCurrencyTransactionIdFactory from '../../../../../domain/idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionIdFactory';

class CryptoCurrencyDepositFirestore
  implements ICryptoCurrencyDepositRepository {
  public findAllByFields = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: { [key: string]: any },
  ) => {
    if (isEqualArbitraryOrder(Object.keys(params), ['address', 'currency'])) {
      const transactionParams = params as { address: string; currency: string };

      const cryptoCurrencyDeposits = await this.findByCurrencyAndAddress(
        transactionParams,
      );

      return cryptoCurrencyDeposits;
    }

    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_SWITCH,
      {
        value: JSON.stringify(params),
        place: `CryptoCurrencyDepositFirestore.findAllByFields`,
      },
    );
  };

  private findByCurrencyAndAddress = async (params: {
    address: string;
    currency: string;
  }) => {
    const cryptoCurrencyDepositsCollection = new CryptoCurrencyDepositsCollection(
      params.currency,
      params.address,
    );

    const docs = await cryptoCurrencyDepositsCollection.fetchAll();

    return docs.reduce<CryptoCurrencyDeposits>(
      (cryptoCurrencyDeposits, doc) => {
        const transactionId = CryptoCurrencyTransactionIdFactory.createFromRawId(
          doc.id,
          doc.data()!.commonId,
        );

        cryptoCurrencyDeposits.add(
          CryptoCurrencyDepositFactory.create(transactionId, {
            ...(doc.data() as CryptoCurrencyDepositProps),
            userId: '',
            currency: params.currency as CryptoCurrencyType,
          }),
        );
        return cryptoCurrencyDeposits;
      },
      new CryptoCurrencyDeposits(),
    );
  };

  public subscribeNewData = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: { [key: string]: any },
    setter: UseStateType<CryptoCurrencyDeposit | undefined>,
    setState: UseStateType<string>,
  ) => {
    if (isEqualArbitraryOrder(Object.keys(params), ['address', 'currency'])) {
      const transactionParams = params as { address: string; currency: string };

      this.subscribeNewDataByCurrencyAddress(
        transactionParams,
        setter,
        setState,
      );

      return;
    }

    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_SWITCH,
      {
        value: JSON.stringify(params),
        place: `CryptoCurrencyDepositFirestore.subscribeNewData`,
      },
    );
  };

  private subscribeNewDataByCurrencyAddress = async (
    params: { address: string; currency: string },
    setter: UseStateType<CryptoCurrencyDeposit | undefined>,
    setState: UseStateType<string>,
  ) => {
    const cryptoCurrencyDepositsCollection = new CryptoCurrencyDepositsCollection(
      params.currency,
      params.address,
    );

    const converter = (doc: firestore.DocumentSnapshot) => {
      const transactionId = CryptoCurrencyTransactionIdFactory.createFromRawId(
        doc.id,
        doc.data()!.commonId,
      );

      return CryptoCurrencyDepositFactory.create(transactionId, {
        ...(doc.data() as CryptoCurrencyDepositProps),
        userId: '',
        address: '',
        currency: params.currency as CryptoCurrencyType,
      });
    };

    // eslint-disable-next-line no-useless-catch
    cryptoCurrencyDepositsCollection.subscribeNewData(
      setter,
      converter,
      setState,
    );
  };
}
export default CryptoCurrencyDepositFirestore;

import CryptoCurrencyTransactions from './CryptoCurrencyTransactions';
import CryptoCurrencySpotTransactions from '../../cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransactions/CryptoCurrencySpotTransactions';
import CryptoCurrencySpotTransaction from '../../cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction';
import CryptoCurrencyTradeTransactions from '../../cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactions/CryptoCurrencyTradeTransactions';
import CryptoCurrencyTradeTransaction from '../../cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction';

class CryptoCurrencyTransactionsFactory {
  static createByMerge = (
    cryptoCurrencySpotTransactions: CryptoCurrencySpotTransactions,
    cryptoCurrencyTradeTransactions: CryptoCurrencyTradeTransactions,
  ) => {
    const cryptoCurrencyTransactions = new CryptoCurrencyTransactions();

    cryptoCurrencySpotTransactions.forEach(
      (cryptoCurrencySpotTransaction: CryptoCurrencySpotTransaction) => {
        cryptoCurrencyTransactions.add(cryptoCurrencySpotTransaction);
      },
    );

    cryptoCurrencyTradeTransactions.forEach(
      (cryptoCurrencyTradeTransaction: CryptoCurrencyTradeTransaction) => {
        cryptoCurrencyTransactions.add(cryptoCurrencyTradeTransaction);
      },
    );

    return cryptoCurrencyTransactions;
  };
}

export default CryptoCurrencyTransactionsFactory;

import React, { FC } from 'react';

// Error
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';

// Style
import { TextStyle, LinkButtonStyle } from './style';

// Constant
import color from '../../../atoms/utils/const/color';

// Component
import { TextSecondary } from '../../../atoms/text2/Text2';
import ArrowIcon from '../../../atoms/icon/ArrowIcon';
import BreadCrumbLinkLayout from './BreadCrumbLinkLayout';

export interface BreadCrumbLinkProps {
  href?: string;
  iconSize?: number;
  onClick?: () => void;
  text: string;
}

const BreadCrumbLink: FC<BreadCrumbLinkProps> = ({
  href,
  iconSize = 16,
  onClick,
  text,
}: BreadCrumbLinkProps) => {
  const TextComponent: FC = () => {
    if (onClick)
      return (
        <button onClick={onClick} style={LinkButtonStyle}>
          <TextSecondary theme={TextStyle}>{text}</TextSecondary>
        </button>
      );

    if (href)
      return (
        <a key="link" href={href}>
          <TextSecondary theme={TextStyle}>{text}</TextSecondary>
        </a>
      );

    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_SWITCH,
      {
        value: `props.href or props.onClick`,
        place: `BreadCrumbLink`,
      },
    );
  };

  return (
    <BreadCrumbLinkLayout>
      <ArrowIcon
        key="arrow"
        type="forward"
        size={iconSize}
        color={color.text.zambezi}
      />
      <TextComponent key="link" />
      {/* <a key="link" href={href}>
        <TextSecondary theme={TextStyle}>{text}</TextSecondary>
      </a> */}
    </BreadCrumbLinkLayout>
  );
};

export default BreadCrumbLink;

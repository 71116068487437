import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Constant
import {
  ROUTE_ABOUT,
  ROUTE_HOME,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_SIGN_IN,
  ROUTE_SIGN_UP,
  ROUTE_RESET_PASSWORD,
  ROUTE_REGISTER,
  ROUTE_RE_SIGN_UP,
  ROUTE_RESET_AUTHENTICATOR,
  ROUTE_REREGISTRATION,
  ROUTE_MAINTENANCE,
} from '../config/constants/routing';

// Page Component (Authentication)
import { EnhancedForgotPasswordPage } from '../containers/front/ForgotPasswordPage';
import { EnhancedRegisterPage } from '../containers/front/RegisterPage';
import { EnhancedResetPasswordPage } from '../containers/front/ResetPasswordPage';
import { EnhancedSignUpPage } from '../containers/front/SignUpPage';
import { EnhancedSignInPage } from '../containers/front/SignInPage';
import { EnhancedResetAuthenticatorPage } from '../containers/front/ResetAuthenticatorPage';

// Maintenance
import MaintenancePage from '../containers/maintenance/MaintenancePage';

// Page Component (Reregistration)
import { EnhancedResignUpPage } from '../containers/front/ResignUpPage';
import { EnhancedReregistrationPage } from '../containers/front/ReregistrationPage';

// Page Component (Home)
// import { EnhancedAboutPage } from '../containers/home/AboutPage';
// import { EnhancedHomePage } from '../containers/home/HomePage';

const FrontPageRouter: FC = () => (
  <Switch>
    <Route
      exact
      path={ROUTE_ABOUT}
      component={() => {
        window.location.href = '/about.html';
        return null;
      }}
    >
      {/* <EnhancedAboutPage /> */}
    </Route>
    <Route
      exact
      path={ROUTE_HOME}
      component={() => {
        window.location.href = '/home.html';
        return null;
      }}
    >
      {/* <EnhancedHomePage /> */}
    </Route>
    <Route exact path={ROUTE_FORGOT_PASSWORD}>
      <EnhancedForgotPasswordPage />
    </Route>
    <Route exact path={`${ROUTE_REGISTER}/:oobCode`}>
      <EnhancedRegisterPage />
    </Route>
    <Route exact path={ROUTE_RESET_PASSWORD}>
      <EnhancedResetPasswordPage />
    </Route>
    <Route exact path={ROUTE_SIGN_UP}>
      <EnhancedSignUpPage />
    </Route>
    <Route exact path={ROUTE_RE_SIGN_UP}>
      <EnhancedResignUpPage />
    </Route>
    <Route exact path={`${ROUTE_REREGISTRATION}/:oobCode`}>
      <EnhancedReregistrationPage />
    </Route>
    <Route exact path={`${ROUTE_RESET_AUTHENTICATOR}/:oobCode`}>
      <EnhancedResetAuthenticatorPage />
    </Route>
    <Route exact path={`${ROUTE_SIGN_IN}`}>
      <EnhancedSignInPage />
    </Route>
    <Route exact path={`${ROUTE_MAINTENANCE}`}>
      <MaintenancePage />
    </Route>
    <Redirect to={ROUTE_SIGN_IN} />
  </Switch>
);

export default FrontPageRouter;

import Joi from '@hapi/joi';

// Error
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';

// type
import {
  TransferDebitCardTransactionV2SeedProps,
  TransferDebitCardTransactionV2SeedPropsFormat,
} from './TransferDebitCardTransactionV2Seed.type';

// DomainObject
import TransferDebitCardTransactionV2Seed from './TransferDebitCardTransactionV2Seed';

class TransferDebitCardTransactionV2SeedFactory {
  static create = (props: TransferDebitCardTransactionV2SeedProps) => {
    const result = Joi.object(
      TransferDebitCardTransactionV2SeedPropsFormat,
    ).validate({
      ...props,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: TransferDebitCardTransactionV2Seed.name,
          reason: result.error.details[0].message,
        },
      );

    return new TransferDebitCardTransactionV2Seed(props);
  };
}

export default TransferDebitCardTransactionV2SeedFactory;

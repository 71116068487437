import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const LabeledTextStyle = {
  label: {
    fontSize: 22,
    fontWeight: fontWeight.regular,
    color: color.text.primary,
    opacity: 0.5,
  },
  value: {
    fontSize: 26,
    fontWeight: fontWeight.regular,
    color: color.text.primary,
  },
};

import { useCallback, useState } from 'react';

// Error
import ErrorHandler from '../../../../../../utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from '../../../../../../utils/errors/ErrorHandler/IErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../../../utils/helpers/connection';

// Type
import {
  useFormInputCorporateInformation,
  FormInputCorporateInformation,
} from '../../../../../../components/organisms/form/verification/kycRequest/FormCorporateInformaition/FormCorporateInformation.type';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../../config/constants/requestState';

// Repository
import KycRequestFunctions from '../../../../../../repository/verification/kycRequest/KycRequestFunctions';
import CloudStorage from '../../../../../../storage/cloudStorage/CloudStorage';

// DomainObject
import KycRequestCorporate from '../../../../../../domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporate';
import UserAuthorized from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import KycRequestSubmitUseCase from '../../../../../../useCases/verification/kycRequest/KycRequestSubmitUseCase';

// File
import { useCorporateInformationFile } from '../../../../../fileHandler/verification/kycRequest/CorporateInformationFileProvider/useCorporateInformationFile';

export const useCorporateInformationSubmitUseCase = (
  kycRequest: KycRequestCorporate | undefined,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const formInputCorporateInformationContext = useFormInputCorporateInformation();

  // File
  const {
    corporateRegistry,
    setCorporateRegistry,
  } = useCorporateInformationFile();

  /* *
   *
   *  Repository
   *
   * */
  const kycRequestFunctions = new KycRequestFunctions();
  const storageHandler = new CloudStorage();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new KycRequestSubmitUseCase(
    kycRequestFunctions,
    storageHandler,
  );

  /* *
   *
   *  Method
   *
   * */
  const submitCorporateInformation = useCallback(
    async (formInput: FormInputCorporateInformation, callback?: () => void) => {
      if (!userAuthorized || !kycRequest) return;

      setState(IS_LOADING);

      try {
        // ---check onLine ---- //
        checkInternetConnection();

        const kycRequestUpdated = kycRequest.updateCorporateInformation({
          ...formInput,
          status: kycRequest.getCorporateInformation().getProps().status,
        });

        await useCase.submitCorporateInformation(
          kycRequestUpdated,
          corporateRegistry,
        );

        setState(SUCCESS);

        if (callback) callback();
      } catch (error) {
        const handler: IErrorHandler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [userAuthorized, kycRequest, corporateRegistry],
  );

  return {
    state,
    setState,
    submitCorporateInformation,
    formInputCorporateInformationContext,
    corporateInformationFileContext: {
      corporateRegistry,
      setCorporateRegistry,
    },
  };
};

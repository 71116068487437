import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

// Register
import { transferForbesSavingsAccountValidation } from 'utils/validation/registers';

// Helper Function
import { deleteFullWidth } from 'utils/helpers/stringHelper';
import { getMinTransferAmount } from 'utils/helpers/currencyHelper';
import { getAmount } from 'components/molecules/form/formInputGroup/FormInputTransferSavingsAccountDetail/FormInputTransferForbesAccount/helper';

// Type
import { FiatCurrencyType } from 'types/FiatCurrency.type';
import { TransferSavingsAccountType } from 'types/Transfer.type';

// Constant
import {
  LENGTH_FORMATTED_SAVINGS_ACCOUNT_NUMBER,
  LENGTH_FORMATTED_BANK_ACCOUNT_NUMBER_ECB,
} from 'config/constants/business';

// Components
import FormInputText from 'components/molecules/form/formInput/textField/FormInputText';
import FormInputTransactionAmount from 'components/molecules/form/formInput/textField/FormInputTransactionAmount';
import FormInputTextArea from 'components/molecules/form/formInput/textArea/FormInputTextArea';
import FormInputTransferForbesAccountLayout from 'components/molecules/form/formInputGroup/FormInputTransferSavingsAccountDetail/FormInputTransferForbesAccount/FormInputTransferForbesAccountLayout';

// DomainObject
import SavingsAccount from 'domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

const MOLECULE_ID = `molecules.formInputGroup.formInputTransferSavingsAccountDetail`;

export interface FormInputTransferForbesAccountProps {
  savingsAccount?: SavingsAccount;
}

const FormInputTransferForbesAccount: FC<FormInputTransferForbesAccountProps> = ({
  savingsAccount,
}: FormInputTransferForbesAccountProps) => {
  const { t } = useTranslation();
  const { clearErrors, register, setValue, trigger, watch } = useFormContext();

  const balance = savingsAccount?.getBalanceRounded();
  const savingsAccountNumber = savingsAccount?.getRawAccountNumber();

  return (
    <FormInputTransferForbesAccountLayout>
      <FormInputText
        key="bankName"
        name="bankName"
        label={t(`${MOLECULE_ID}.bankName.label`)}
        inputWidth={599}
        maxLength={999}
        register={register(
          transferForbesSavingsAccountValidation(t).bankName(),
        )}
        disabled={true}
      />
      <FormInputText
        key="accountNumber"
        name="beneficiaryAccountNumber"
        label={t(`${MOLECULE_ID}.beneficiaryAccountNumber.label`)}
        inputWidth={599}
        maxLength={
          watch('transferSavingsAccountType') === TransferSavingsAccountType.ecb
            ? LENGTH_FORMATTED_BANK_ACCOUNT_NUMBER_ECB
            : LENGTH_FORMATTED_SAVINGS_ACCOUNT_NUMBER
        }
        placeholder={t(
          `${MOLECULE_ID}.beneficiaryAccountNumber.placeholder.${watch(
            'transferSavingsAccountType',
          )}`,
        )}
        onChange={(value: string) => {
          setValue('beneficiaryAccountNumber', deleteFullWidth(value));
        }}
        register={register(
          transferForbesSavingsAccountValidation(t).beneficiaryAccountNumber(
            savingsAccountNumber || '',
            watch('transferSavingsAccountType') as TransferSavingsAccountType,
          ),
        )}
      />
      <FormInputTransactionAmount
        key="amount"
        label={t(`${MOLECULE_ID}.amount.label`)}
        currency={FiatCurrencyType.USD}
        inputWidth={534}
        name="amount"
        onChange={(value: string) => {
          clearErrors('beneficiaryAccountNumber');
          setValue('amount', getAmount(value));
          trigger(['amount', 'beneficiaryAccountNumber']);
        }}
        register={register(
          transferForbesSavingsAccountValidation(t).amount(
            balance ? Math.max(balance - (watch('fee'), 0)) : 0,
            getMinTransferAmount(
              (watch('currency') || FiatCurrencyType.USD) as FiatCurrencyType,
            ),
          ),
        )}
      />
      <FormInputTextArea
        key="purpose"
        name="purpose"
        label={t(`${MOLECULE_ID}.purpose.label`)}
        inputWidth={599}
        maxLength={255}
        onChange={(value: string) => {
          clearErrors('beneficiaryAccountNumber');
          setValue('purpose', deleteFullWidth(value));
        }}
        register={register(transferForbesSavingsAccountValidation(t).purpose)}
      />
    </FormInputTransferForbesAccountLayout>
  );
};

export default FormInputTransferForbesAccount;

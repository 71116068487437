import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import SignUpContext from '../../../../../enhancers/useCase/authentication/SignUpProvider/SignUpContext';
import LinksSignUpContext from '../../../../../enhancers/storage/context/LinksSignUpContext';

// Lib
import { deleteFullWidth } from '../../../../../utils/helpers/stringHelper';
import { signUpRegister } from '../../../../../utils/validation/registers';

// Constant
import { ErrorCodeType } from '../../../../../utils/errors/ErrorHandler/ErrorCode.type';

// Constant
import { SIGN_UP } from '../../../../../config/constants/pageId.json';
import { IS_LOADING } from '../../../../../config/constants/requestState';

// Style
import {
  LabelStyle,
  CheckBoxStyle,
  CheckboxTextStyle,
  CheckboxLinkStyle,
} from './style';

// Component
import FormInputTextLabelColumn from '../../../../molecules/form/formInput/textField/FormInputTextLabelColumn';
import Checkbox from '../../../../atoms/checkbox/Checkbox';
import LinkTextSentence from '../../../../molecules/link/LinkTextSentence';
import { TextPrimary } from '../../../../atoms/text2/Text2';
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../atoms/button/Button2';
import FormSignUpLayout from './FormSignUpLayout';

export interface FormSignUpInput {
  email: string;
  invitationCode: string;
  agreed: boolean;
}

const FormSignUp: FC = () => {
  const { t } = useTranslation();
  const { state, signUp } = useContext(SignUpContext);
  const { termsAndConditionsUrl, privacyPolicyUrl } = useContext(
    LinksSignUpContext,
  );

  const methods = useForm<
    FormSignUpInput & {
      emailClientError: string;
      invitationCodeClientError: string;
    }
  >({
    mode: 'onChange',
  });

  const {
    clearErrors,
    errors,
    formState: { isValid },
    setValue,
    handleSubmit,
    register,
    watch,
    setError,
  } = methods;

  useEffect(() => {
    if (state === ErrorCodeType.AUTHENTICATION_SIGN_UP_EMAIL_EXISTED)
      setError('emailClientError', {
        type: '',
        message: t(
          `validation.${ErrorCodeType.AUTHENTICATION_SIGN_UP_EMAIL_EXISTED}`,
        ),
      });
    if (
      state === ErrorCodeType.AUTHENTICATION_SIGN_UP_INVALID_INVITATION_CODE ||
      state === ErrorCodeType.AUTHENTICATION_SIGN_UP_INVALID_INVITER
    )
      setError('invitationCodeClientError', {
        type: '',
        message: t(`validation.${state}`),
      });
  }, [state, setError, t]);

  const clearAPIError = () => {
    clearErrors('emailClientError');
    clearErrors('invitationCodeClientError');
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit((formInput) => {
          const { email, invitationCode } = formInput;
          signUp({ email, invitationCode });
        })}
      >
        <FormSignUpLayout>
          <FormInputTextLabelColumn
            key="email"
            label={t(`${SIGN_UP}.form.email.label`)}
            labelStyle={LabelStyle}
            placeholder={t(`${SIGN_UP}.form.email.placeholder`)}
            marginBetween={12}
            maxLength={255}
            name="email"
            errorMessage={
              (errors.email && errors.email.message) ||
              (errors.emailClientError && errors.emailClientError.message)
            }
            onChange={(text: string) => {
              clearAPIError();
              setValue('email', deleteFullWidth(text));
            }}
            register={register(signUpRegister('email', t))}
            width={400}
          />
          <FormInputTextLabelColumn
            key="invitationCode"
            label={t(`${SIGN_UP}.form.invitationCode.label`)}
            labelStyle={LabelStyle}
            placeholder={t(`${SIGN_UP}.form.invitationCode.placeholder`)}
            marginBetween={12}
            maxLength={50}
            name="invitationCode"
            errorMessage={
              (errors.invitationCode && errors.invitationCode.message) ||
              (errors.invitationCodeClientError &&
                errors.invitationCodeClientError.message)
            }
            onChange={(text: string) => {
              clearAPIError();
              setValue('invitationCode', deleteFullWidth(text));
            }}
            register={register(signUpRegister('invitationCode', t))}
            width={400}
          />
          <Checkbox
            key="checkbox"
            checked={watch('agreed')}
            height={30}
            iconSize={15}
            onClick={() => {
              setValue('agreed', !watch('agreed'));
            }}
            theme={CheckBoxStyle}
            width={30}
          />
          <LinkTextSentence
            key="checkboxText"
            text={t(`${SIGN_UP}.form.checkbox.label`)}
            urlList={[
              {
                replaceText: t(`${SIGN_UP}.link.termsOfUse`),
                url: termsAndConditionsUrl || '',
                target: '_blank',
              },
              {
                replaceText: t(`${SIGN_UP}.link.privacyPolicy`),
                url: privacyPolicyUrl || '',
                target: '_blank',
              },
            ]}
            theme={{
              text: CheckboxTextStyle,
              link: CheckboxLinkStyle,
            }}
          />
          <SubmitButton
            key="button"
            disabled={!(isValid && watch('agreed'))}
            height={50}
            isLoading={state === IS_LOADING}
            theme={{ borderRadius: 25 }}
            sizeProgress={20}
            width={165}
          >
            <TextPrimary theme={DefaultButtonTextStyle}>
              {t(`atoms.button.submit`)}
            </TextPrimary>
          </SubmitButton>
        </FormSignUpLayout>
      </form>
    </FormProvider>
  );
};

export default FormSignUp;

import styled from 'styled-components';
import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const CircleStyle = {
  backgroundColorLarge: '#95CB4B',
  backgroundColorSmall: color.white,
  backgroundColorDisabled: color.disabled,
};

export const IconStyle = {
  iconColor: color.white,
  iconSize: `${24 / 16}rem`,
};

export const TextStyle = {
  fontSize: 21,
  color: color.primary,
  fontWeight: fontWeight.regular,
};

export const ProgressStepPointContainer = styled.div``;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle } from './style';

// Component
import { TextSecondary } from '../../../atoms/text2/Text2';
import HeaderContractTimeDepositAddConfirm from '../../../organisms/header/HeaderContentTitle/contract/HeaderContractTimeDepositAddConfirm';
import ProgressStepBarBankTransaction from '../../../molecules/progressStep/ProgressStepBar/ProgressStepBarBankTransaction';
import TableConfirmContractTimeDepositAdd from '../../../organisms/table/confirm/TableConfirmContractTimeDepositAdd';
import FormTimeDepositAddConfirm from '../../../organisms/form/contract/contractTimeDeposit/FormContractTimeDepositAddConfirm';
import TimeDepositAddConfirmTemplateLayout from './ContractTimeDepositAddConfirmTemplateLayout';

const TEMPLATE_ID = 'templates.contract.ContractTimeDepositAddConfirmTemplate';

const ContractTimeDepositAddConfirmTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <TimeDepositAddConfirmTemplateLayout>
      <HeaderContractTimeDepositAddConfirm key="header" />
      <ProgressStepBarBankTransaction
        key="progressStep"
        currentStep="confirm"
      />
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${TEMPLATE_ID}.description`)}
      </TextSecondary>
      <TableConfirmContractTimeDepositAdd key="table" />
      <FormTimeDepositAddConfirm key="form" />
    </TimeDepositAddConfirmTemplateLayout>
  );
};
export default ContractTimeDepositAddConfirmTemplate;

import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProofOfIdentitySubmitContext from 'enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01ProofOfIdentityUpdateProvider/DebitCardRequestCherryVisa01ProofOfIdentityUpdateProvider/DebitCardRequestCherryVisa01ProofOfIdentityUpdateContext';

// Lib
import {
  isValid,
  getDefaultValues,
} from 'components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/helper';

// Error
import { ErrorCodeType } from 'utils/errors/ErrorHandler';

// Type
import {
  FormInputCherryVisa01ProofOfIdentity,
  FormInputCherryVisa01ProofOfIdentityContext,
} from 'components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/FormCherryVisa01ProofOfIdentity.type';

// Constant
import { IS_LOADING } from 'config/constants/requestState';
import { ROUTE_DEBIT_CARD_REQUEST_CHERRY_VISA_01_STATUS } from 'config/constants/routing';

// Style
import { ErrorMessageStyle } from 'components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/style';

// Component
import { TextSecondary } from 'components/atoms/text2/Text2';
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from 'components/atoms/button/Button2';
import FormIdDocumentUpload from 'components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/FormIdDocumentUpload';
import FormPassportInput from 'components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/FormPassportInput';
import FormPassportUpload from 'components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/FormPassportUpload';
import FormPersonalInformationInput from 'components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/FormPersonalInformationInput';
import FormCardOptions from 'components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/FormCardOptions';
import FormSelfieUpload from 'components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/FormSelfieUpload';
import FormShippingAddress from 'components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/FormShippingAddress';
import FormSignatureExplanation from 'components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/FormSignatureExplanation';
import FormSignatureUpload from 'components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/FormSignatureUpload';
import SelectDocumentType from 'components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/SelectDocumentType';
import LabelSendIcon from 'components/atoms/button/ButtonLabel/LabelSendIcon';
import FormCherryVisa01ProofOfIdentityLayout from 'components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/FormCherryVisa01ProofOfIdentityLayout';

// ValueObject
import CherryVisa01ProofOfIdentity from 'value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentity';

const FORM_ID = `organisms.form.debitCards.FormCherryVisa01ProofOfIdentity`;

const ERROR_CODES_HANDLED = [
  ErrorCodeType.DEBIT_CARD_REQUEST_INVALID_MAP_ADDRESS,
];

export interface FormCherryVisa01ProofOfIdentityProps {
  id: string;
  proofOfIdentity?: CherryVisa01ProofOfIdentity;
}

const FormCherryVisa01ProofOfIdentity: FC<FormCherryVisa01ProofOfIdentityProps> = ({
  id,
  proofOfIdentity,
}: FormCherryVisa01ProofOfIdentityProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { formInput, setFormInput } = useContext(
    FormInputCherryVisa01ProofOfIdentityContext,
  );

  // Method
  const { state, submitProofOfIdentity } = useContext(
    ProofOfIdentitySubmitContext,
  );

  /* *
   *
   *  Form
   *
   * */
  const methods = useForm<FormInputCherryVisa01ProofOfIdentity>({
    mode: 'onChange',
  });
  const { errors, handleSubmit, reset, watch, setError } = methods;

  /* *
   *
   *  DefaultValues
   *
   * */

  // Set DefaultValue
  useEffect(() => {
    if (formInput) reset(formInput);
    // eslint-disable-next-line
  }, [formInput]);

  // Set Default Value from ProofOfIdentity
  useEffect(() => {
    if (!formInput && proofOfIdentity) {
      const defaultValues = getDefaultValues(proofOfIdentity);

      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [formInput, proofOfIdentity]);

  useEffect(() => {
    if (ERROR_CODES_HANDLED.includes(state as ErrorCodeType)) {
      setError('addressLine1', {
        type: '',
        message: t(`validation.${state}`),
      });
      setErrorMessage(t(`${FORM_ID}.error.addressLine1`));
    }
  }, [state, setError, t]);

  useEffect(() => {
    if (errorMessage && !errors.addressLine1) {
      setErrorMessage('');
    }
  }, [
    errors,
    errorMessage,
    // eslint-disable-next-line
    watch([
      'country',
      'addressLine1',
      'addressLine2',
      'state',
      'city',
      'postalCode',
    ]),
  ]);

  /* *
   *
   *  Event
   *
   * */

  const onSubmit = useCallback(
    async (input: FormInputCherryVisa01ProofOfIdentity) => {
      setFormInput(input);

      await submitProofOfIdentity(input, () => {
        history.push(`${ROUTE_DEBIT_CARD_REQUEST_CHERRY_VISA_01_STATUS}/${id}`);
      });
    },
    // eslint-disable-next-line
    [submitProofOfIdentity],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <FormCherryVisa01ProofOfIdentityLayout
          showErrorMessage={!!errorMessage}
        >
          <FormPersonalInformationInput key="personalInformation" />
          <SelectDocumentType key="documentType" />
          <FormIdDocumentUpload
            key="idDocument"
            proofOfIdentity={proofOfIdentity}
          />
          <FormPassportInput key="passportInput" />
          <FormPassportUpload
            key="passport"
            proofOfIdentity={proofOfIdentity}
          />
          <FormSelfieUpload key="selfie" proofOfIdentity={proofOfIdentity} />
          <FormSignatureExplanation key="signatureExplanation" />
          <FormSignatureUpload
            key="signature"
            proofOfIdentity={proofOfIdentity}
          />
          <FormShippingAddress key="shippingAddress" />
          <FormCardOptions key="cardOptions" />
          <TextSecondary key="errorMessage" theme={ErrorMessageStyle}>
            {errorMessage}
          </TextSecondary>
          <SubmitButton
            key="button"
            disabled={!isValid(errors, watch, proofOfIdentity)}
            isLoading={state === IS_LOADING}
            height={75}
            width={'100%'}
          >
            <LabelSendIcon
              iconSize={26}
              iconStyle={{ color: 'white' }}
              label={t(`atoms.button.submit`)}
              marginBetween={8}
              textStyle={DefaultButtonTextStyle}
            />
          </SubmitButton>
        </FormCherryVisa01ProofOfIdentityLayout>
      </FormProvider>
    </form>
  );
};

export default FormCherryVisa01ProofOfIdentity;

// Error
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import { UserStatusType } from '../../../../types/User.type';

const STATUS_TRANSITIONS: {
  [key in UserStatusType]: UserStatusType[];
} = {
  [UserStatusType.initial]: [UserStatusType.ready],
  [UserStatusType.ready]: [UserStatusType.investing],
  [UserStatusType.cancelInvesting]: [UserStatusType.investing],
  [UserStatusType.investing]: [],
};

class UserStatus {
  private status: UserStatusType;

  constructor(status: UserStatusType) {
    this.status = status;
  }

  public getStatus = () => this.status;

  public changeStatus = (changeTo: UserStatusType) => {
    return new UserStatus(changeTo);
  };

  public canChangeTo = (changeTo: UserStatusType) => {
    const transitions = STATUS_TRANSITIONS[this.status];

    if (!transitions)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_SWITCH,
        {
          value: this.status,
          place: `UserStatus.canChangeTo`,
        },
      );

    return transitions.includes(changeTo);
  };

  public isStatus = (status: UserStatusType) => status === this.status;
}
export default UserStatus;

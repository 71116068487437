import React, { FC } from 'react';
import ContractTimeDepositRenewalReservationEditPage from './ContractTimeDepositRenewalReservationEditPage';
import ContractTimeDepositRenewalReservationViewProvider from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationViewProvider';
import ContractTimeDepositRenewalReservationUpdateProvider from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationUpdateProvider';

const EnhancedContractTimeDepositRenewalReservationEditPage: FC = () => {
  return (
    <ContractTimeDepositRenewalReservationViewProvider>
      <ContractTimeDepositRenewalReservationUpdateProvider>
        <ContractTimeDepositRenewalReservationEditPage />
      </ContractTimeDepositRenewalReservationUpdateProvider>
    </ContractTimeDepositRenewalReservationViewProvider>
  );
};

export default EnhancedContractTimeDepositRenewalReservationEditPage;

// Lib
import { omit } from 'lodash';

// Functions
import FirebasePrivateFunctions from '../../../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// Types
import { BankFutureTransactionRawDataType } from '../../../../../domain/transaction/bankTransaction/bankFutureTransaction/bankFutureTransaction/BankFutureTransaction/BankFutureTransaction.type';

// Constant
import { TRANSACTION_FETCH_FUTURE_COMMISSION_TIME_DEPOSIT } from '../../../../../infrastructure/firebase/firebaseFunctions/endpoint';

// IRepository
import IBankFutureDepositRepository from '../../../../../domain/transaction/bankTransaction/bankFutureTransaction/bankFutureDeposit/IBankFutureDepositRepository';

// DomainObject
import BankFutureDeposits from '../../../../../domain/transaction/bankTransaction/bankFutureTransaction/bankFutureDeposit/bankFutureDeposit/BankFutureDeposits/BankFutureDeposits';
import BankFutureCommissionTimeDepositFactory from '../../../../../domain/transaction/bankTransaction/bankFutureTransaction/bankFutureDeposit/bankFutureCommissonTimeDeposit/BankFutureCommissonTimeDeposit/BankFutureCommissionTimeDepositFactory';
import BankTransactionIdFactory from '../../../../../domain/idManager/transactionId/BankTransactionId/BankTransactionIdFactory';

class BankFutureDepositFunctions
  extends FirebasePrivateFunctions
  implements IBankFutureDepositRepository {
  public findFutureCommissionsTimeDepositByUserId = async (userId: string) => {
    const endpoint = TRANSACTION_FETCH_FUTURE_COMMISSION_TIME_DEPOSIT.replace(
      /:userId/,
      userId,
    );

    const response = await this.privateGET(endpoint);

    const rows = response.data.data as Array<BankFutureTransactionRawDataType>;

    if (!rows) return new BankFutureDeposits();

    return rows.reduce((objs, row) => {
      const bankTransactionId = BankTransactionIdFactory.createFromRawId(
        row.id,
        row.props.commonId as string,
      );
      const props = omit(row.props, ['commonId', 'type']);

      objs.add(
        BankFutureCommissionTimeDepositFactory.create(bankTransactionId, {
          ...props,
          bankFutureDepositType: row.props.type,
        }),
      );
      return objs;
    }, new BankFutureDeposits());
  };
}
export default BankFutureDepositFunctions;

import React, { FC } from 'react';
import QuestionIcon from '../../../../assets/images/questionIcon.png';

export interface QuestionIconImageProps {
  width?: string | number;
  height?: string | number;
}

const QuestionIconImage: FC<QuestionIconImageProps> = ({
  width = '100%',
  height = '100%',
}: QuestionIconImageProps) => {
  return (
    <div>
      <img src={QuestionIcon} alt="?" width={width} height={height} />
    </div>
  );
};
export default QuestionIconImage;

import React, { FC, useContext } from 'react';
import { VerifyResetPasswordContext } from '../../../enhancers/front/VerifyResetPassword/VerifyResetPasswordContext';
import { ResetPasswordContext } from '../../../enhancers/front/ResetPassword/ResetPasswordContext';
import ResetPasswordTemplate from '../../../components/templates/front/ResetPasswordTemplate';
import FrontPageTemplate from '../../../components/templates/front/FrontPageTemplate';
import ResetPasswordCompleteTemplate from '../../../components/templates/front/ResetPasswordCompleteTemplate';
import { SUCCESS } from '../../../config/constants/requestState';

const ResetPasswordPage: FC = () => {
  const { verifyResetPasswordState } = useContext(VerifyResetPasswordContext);
  const { resetPasswordState } = useContext(ResetPasswordContext);

  return (
    <FrontPageTemplate>
      {(() => {
        if (verifyResetPasswordState !== SUCCESS) return <></>;
        if (resetPasswordState === SUCCESS)
          return <ResetPasswordCompleteTemplate />;
        return <ResetPasswordTemplate />;
      })()}
    </FrontPageTemplate>
  );
};
export default ResetPasswordPage;

import BankDeposit from '../../bankDeposit/BankDeposit/BankDeposit';
import BankDeposits from '../../bankDeposit/BankDeposits/BankDeposits';
import BankWithdrawal from '../../bankWithdrawal/BankWithdrawal/BankWithdrawal';
import BankWithdrawals from '../../bankWithdrawal/BankWithdrawals/BankWithdrawals';
import BankSpotTransactions from './BankSpotTransactions';

class BankSpotTransactionsFactory {
  static createByMerge = (
    bankDeposits: BankDeposits,
    bankWithdrawals: BankWithdrawals,
  ) => {
    const bankSpotTransactions = new BankSpotTransactions();

    bankDeposits.forEach((bankDeposit: BankDeposit) => {
      bankSpotTransactions.add(bankDeposit);
    });

    bankWithdrawals.forEach((bankWithdrawal: BankWithdrawal) => {
      bankSpotTransactions.add(bankWithdrawal);
    });

    return bankSpotTransactions;
  };
}

export default BankSpotTransactionsFactory;

// React
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle, ImportantStyle } from './style';

// Type
import { CryptoCurrencyTransactionStatusType } from '../../../../types/CryptoCurrencyTransaction.type';

// Component
import { TextSecondary } from '../../../atoms/text2/Text2';

export interface CryptoCurrencyDepositNotificationDescriptionProps {
  status: CryptoCurrencyTransactionStatusType;
}

const ORGANISM_ID = 'organisms.notification.CryptoCurrencyDepositNotification';

const CryptoCurrencyDepositNotification: FC<CryptoCurrencyDepositNotificationDescriptionProps> = ({
  status,
}: CryptoCurrencyDepositNotificationDescriptionProps) => {
  const { t } = useTranslation();

  if (status === CryptoCurrencyTransactionStatusType.unconfirmed)
    return (
      <>
        <TextSecondary theme={DescriptionStyle}>
          {t(`${ORGANISM_ID}.${status}.description.line2`)}
        </TextSecondary>
        <TextSecondary theme={ImportantStyle}>
          {t(`${ORGANISM_ID}.${status}.description.pending`)}
          <br />
        </TextSecondary>
        <TextSecondary theme={DescriptionStyle}>
          {t(`${ORGANISM_ID}.${status}.description.line3`)}
        </TextSecondary>
      </>
    );

  if (status === CryptoCurrencyTransactionStatusType.confirmed)
    return (
      <>
        <TextSecondary theme={DescriptionStyle}>
          {t(`${ORGANISM_ID}.${status}.description.line2`)}
        </TextSecondary>
      </>
    );

  throw new Error('error');
};

export default CryptoCurrencyDepositNotification;

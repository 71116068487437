import React from 'react';
import Inviter from './Inviter';

export interface InviterContextProps {
  inviter: Inviter | undefined;
}

const InviterContext = React.createContext<InviterContextProps>({
  inviter: undefined,
});

export default InviterContext;

import React, { FC, useContext } from 'react';
import { useVerifyAuthenticator } from './useVerifyAuthenticator';
import VerifyAuthenticatorContext from './VerifyAuthenticatorContext';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type VerifyAuthenticatorProps = {
  children: React.ReactNode;
};

const VerifyAuthenticator: FC<VerifyAuthenticatorProps> = ({
  children,
}: VerifyAuthenticatorProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { state, setState, verify } = useVerifyAuthenticator(userAuthorized);

  return (
    <VerifyAuthenticatorContext.Provider
      value={{
        verifyAuthenticatorState: state,
        setVerifyAuthenticatorState: setState,
        verify,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </VerifyAuthenticatorContext.Provider>
  );
};
export default VerifyAuthenticator;

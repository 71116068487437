// IService
import IUserAuthenticationService from '../../useCases/authentication/IUserAuthenticationService';

// IRepository
import IUserAuthentication from '../../domain/public/user/User/IUserAuthentication';

class UserAuthenticationService implements IUserAuthenticationService {
  private userAuthentication: IUserAuthentication;

  constructor(userAuthentication: IUserAuthentication) {
    this.userAuthentication = userAuthentication;
  }

  public signIn = async (email: string, password: string) => {
    const staff = this.userAuthentication.signIn(email, password);
    return staff;
  };
}
export default UserAuthenticationService;

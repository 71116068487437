// Error
import InvalidDataFoundError from '../../../utils/errors/InvalidDataFoundError';

// Types
import { UseStateType } from '../../../types/typeof/UseState';
import { CryptoCurrencyType } from '../../../types/CryptoCurrency.type';
import { LTCTWalletDocumentData } from '../../../infrastructure/firebase/firestore/collections/cryptoCurrency/wallets/LTCTWallets/LTCTWalletDocumentData.type';

// Collection
import LTCTWalletsCollection from '../../../infrastructure/firebase/firestore/collections/cryptoCurrency/wallets/LTCTWallets/LTCTWalletsCollection';

// IRepository
import ILitecoinTestnetWalletRepository from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/LitecoinTestnetWallet/ILitecoinTestnetWalletRepository';
import CryptoCurrencyWalletRepository from './CryptoCurrencyWalletRepository';

// DomainObject
import LitecoinTestnetWallet from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/LitecoinTestnetWallet/LitecoinTestnetWallet/LitecoinTestnetWallet';
import LitecoinTestnetWallets from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/LitecoinTestnetWallet/LitecoinTestnetWallets/LitecoinTestnetWallets';

class LitecoinTestnetWalletFirestore
  extends CryptoCurrencyWalletRepository
  implements ILitecoinTestnetWalletRepository {
  public findByUserId = async (userId: string) => {
    const docs = await new LTCTWalletsCollection(userId).fetchAll();

    if (docs.length === 0)
      throw new InvalidDataFoundError(
        `LitecoinTestnetWallet for user '${userId}' is not found.`,
      );

    return docs.reduce<LitecoinTestnetWallets>((wallets, doc) => {
      wallets.add(
        new LitecoinTestnetWallet(doc.id, {
          ...(doc.data() as LTCTWalletDocumentData),
          userId,
          currency: CryptoCurrencyType.LTCT,
        }),
      );
      return wallets;
    }, new LitecoinTestnetWallets());
  };

  public subscribeByUserId = async (
    userId: string,
    setter: UseStateType<LitecoinTestnetWallet | undefined>,
  ) => {
    const collectionRef = new LTCTWalletsCollection(
      userId,
    ).getCollectionReference();

    // fetchAll
    const unsubscribe = collectionRef.onSnapshot((querySnapshot) => {
      if (querySnapshot.size === 0) {
        unsubscribe();
        throw new InvalidDataFoundError(
          `LitecoinTestnetWallet for user '${userId}' is not found.`,
        );
      }
      // head
      const doc = querySnapshot.docs[0];

      const litecoinTestnetWallet = new LitecoinTestnetWallet(doc.id, {
        ...(doc.data() as LTCTWalletDocumentData),
        userId,
        currency: CryptoCurrencyType.LTCT,
      });
      setter(litecoinTestnetWallet);
    });

    return unsubscribe;
  };

  public getInterface = () => 'ILitecoinTestnetWalletRepository';
}
export default LitecoinTestnetWalletFirestore;

import styled from 'styled-components';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  width: 100%;
  height: ${85 / 16}rem;
  border-bottom: ${1 / 16}rem solid #f0f3f8;
`;

export const CurrencyNameStyle = {
  fontSize: 41,
  color: color.primary,
  fontWeight: fontWeight.bold,
};

export const CurrencySymbolStyle = {
  fontSize: 29,
  color: color.primary,
  fontWeight: fontWeight.medium,
  opacity: 0.5,
};

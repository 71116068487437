import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import CherryVisa01ProofOfReceiptTemplate from '../../../components/templates/debitCardRequest/CherryVisa01ProofOfReceiptTemplate';

const CherryVisa01ProofOfReceiptPage: FC = () => {
  return (
    <DashboardTemplate>
      <CherryVisa01ProofOfReceiptTemplate />
    </DashboardTemplate>
  );
};

export default CherryVisa01ProofOfReceiptPage;

import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Constant
import { ROUTE_TIME_DEPOSIT_ADD } from '../../../../../config/constants/routing';

// Components
import { PageTitle } from '../../../../atoms/headLine/HeadLine';
import { ActionButton } from '../../../../atoms/button/DomainButton';
import HeaderContentTitleLayout from '../HeaderContentTitleLayout';

const ORGANISM_ID = 'organisms.header.contract.HeaderContractsTimeDeposit';

const HeaderContractsTimeDeposit: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <HeaderContentTitleLayout>
      <PageTitle key="left">{t(`${ORGANISM_ID}.title`)}</PageTitle>
      <ActionButton
        key="right"
        name={t(`atoms.button.timeDepositAdd`)}
        width={`${270 / 16}rem `}
        height={`${50 / 16}rem `}
        borderRadius={`${5 / 16}rem`}
        textSize={20}
        backgroundColor="#15609B"
        boxShadow="0px 5px 0px #81818141"
        onClick={() => {
          history.push(ROUTE_TIME_DEPOSIT_ADD);
        }}
      />
    </HeaderContentTitleLayout>
  );
};

export default HeaderContractsTimeDeposit;

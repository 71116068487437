import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressStepCircleStatusType } from '../../../../atoms/progressStep/ProgressStepCircle/ProgressStepCircle/ProgressStepCircle.type';
import ProgressStepBar from '../ProgressStepBar';
import { CircleStyle, IconStyle, TextStyle } from './style';

export type StepBankTransaction = 'input' | 'confirm' | 'complete';

export interface ProgressStepBarBankTransactionProps {
  currentStep: StepBankTransaction;
}

const ORGANISM_ID = 'organisms.progressStep';

const ProgressStepBarBankTransaction: FC<ProgressStepBarBankTransactionProps> = ({
  currentStep,
}: ProgressStepBarBankTransactionProps) => {
  const { t } = useTranslation();

  let progressSteps = [{ stepName: '', status: '' }];

  const input = t(`${ORGANISM_ID}.input`);
  const confirm = t(`${ORGANISM_ID}.confirm`);
  const complete = t(`${ORGANISM_ID}.complete`);

  switch (currentStep) {
    case 'input':
      progressSteps = [
        { stepName: input, status: 'current' },
        { stepName: confirm, status: 'close' },
        { stepName: complete, status: 'close' },
      ];
      break;
    case 'confirm':
      progressSteps = [
        { stepName: input, status: 'done' },
        { stepName: confirm, status: 'current' },
        { stepName: complete, status: 'close' },
      ];
      break;
    case 'complete':
      progressSteps = [
        { stepName: input, status: 'done' },
        { stepName: confirm, status: 'done' },
        { stepName: complete, status: 'done' },
      ];
      break;
    default: {
      // eslint-disable-next-line
      const _exhaustiveCheck: never = currentStep;
    }
  }

  return (
    <ProgressStepBar
      bridgeBorderWidth={6}
      diameterLargeCircle={35}
      diameterSmallCircle={7}
      marginBetween={9}
      progressSteps={
        progressSteps as Array<{
          stepName: string;
          status: ProgressStepCircleStatusType;
        }>
      }
      theme={{
        circleStyle: CircleStyle,
        iconStyle: IconStyle,
        textStyle: TextStyle,
      }}
      width={554}
    />
  );
};
export default ProgressStepBarBankTransaction;

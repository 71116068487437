import React from 'react';
import DebitCardV2 from '../../../../../../domain/debitCard/debitCardV2/DebitCardV2/DebitCardV2';

export interface DebitCardOptionContextProps {
  debitCardOption?: DebitCardV2;
  setDebitCardOption: (debitCard: DebitCardV2) => void;
}

const DebitCardOptionContext = React.createContext<DebitCardOptionContextProps>(
  {
    debitCardOption: undefined,
    setDebitCardOption: () => undefined,
  },
);

export default DebitCardOptionContext;

import { useState } from 'react';

export const useProofOfResidencyFile = () => {
  const [proofOfDocument1, setProofOfDocument1] = useState<File | undefined>();
  const [proofOfDocument2, setProofOfDocument2] = useState<File | undefined>();

  return {
    proofOfDocument1,
    setProofOfDocument1,
    proofOfDocument2,
    setProofOfDocument2,
  };
};

import styled from 'styled-components';
import { convertRem } from '../helper/helper';

interface MarginProps {
  left?: string | number;
  top?: string | number;
  bottom?: string | number;
  right?: string | number;
}

const Margin = styled.div`
  margin-top: ${(props: MarginProps) =>
    `${props.top ? convertRem(props.top) : 0}`};
  margin-right: ${(props: MarginProps) =>
    `${props.right ? convertRem(props.right) : 0}`};
  margin-bottom: ${(props: MarginProps) =>
    `${props.bottom ? convertRem(props.bottom) : 0}`};
  margin-left: ${(props: MarginProps) =>
    `${props.left ? convertRem(props.left) : 0}`};
`;

export default Margin;

// Type
import { BankFutureTransactionStatusType } from '../../../../../../types/BankAccountTransaction.type';
import { BankFutureTransactionProps } from './BankFutureTransaction.type';

// DomainObject
import BankTransaction from '../../../bankTransaction/BankTransaction/BankTransaction';
import BankTransactionId from '../../../../../idManager/transactionId/BankTransactionId/BankTransactionId';

abstract class BankFutureTransaction extends BankTransaction {
  protected scheduledDate: string; // YYYY/MM/DD

  protected scheduledTime: number; // timestamp

  protected status: BankFutureTransactionStatusType;

  constructor(
    bankTransactionId: BankTransactionId,
    props: BankFutureTransactionProps,
  ) {
    super(bankTransactionId, props);
    this.scheduledDate = props.scheduledDate;
    this.scheduledTime = props.scheduledTime;
    this.status = props.status;
  }

  public getProps() {
    return {
      ...super.getProps(),
      scheduledDate: this.scheduledDate,
      scheduledTime: this.scheduledTime,
      status: this.status,
    };
  }
}
export default BankFutureTransaction;

// FirebaseFunctions
import FirebasePublicFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePublicFunctions';
import { USER } from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// IRepository
import IUserCreator from '../../../domain/public/user/User/IUserCreator';

class UserFunctions extends FirebasePublicFunctions implements IUserCreator {
  public register = async (
    username: string,
    password: string,
    oobCode: string,
  ) => {
    await this.publicPOST(USER, {
      username,
      password,
      oobCode,
    });
  };
}
export default UserFunctions;

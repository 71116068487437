// Type
import { TypeOfT } from '../../../types/typeof/Translation.type';

// IService
import ISignUpRequestQueryService from '../../../useCases/userRequest/signUpRequest/ISignUpRequestQueryService';

// IRepository
import ISignUpRequestRepository from '../../../domain/userRequest/signUpRequest/ISignUpRequestRepository';

// Error
import ErrorFactory from '../../../utils/errors/ErrorFactory';
import { ErrorCodeType } from '../../../utils/errors/ErrorHandler/ErrorCode.type';

// Error Handler
import InvalidDataFoundError from '../../../utils/errors/InvalidDataFoundError';

class SignUpRequestQueryService implements ISignUpRequestQueryService {
  private signUpRequestRepository: ISignUpRequestRepository;

  constructor(signUpRequestRepository: ISignUpRequestRepository) {
    this.signUpRequestRepository = signUpRequestRepository;
  }

  public findByOobCode = async (oobCode: string, t: TypeOfT) => {
    try {
      const signUpRequest = await this.signUpRequestRepository.findByPrimaryKey(
        {
          oobCode,
        },
      );

      if (signUpRequest.isExpired()) {
        throw ErrorFactory.create(
          ErrorCodeType.AUTHENTICATION_SIGN_UP_REQUEST_NOT_FOUND,
          t(
            `validation.${ErrorCodeType.AUTHENTICATION_SIGN_UP_REQUEST_NOT_FOUND}`,
          ),
        );
      }

      return signUpRequest;
    } catch (error) {
      if (error instanceof InvalidDataFoundError)
        throw ErrorFactory.create(
          ErrorCodeType.AUTHENTICATION_SIGN_UP_REQUEST_NOT_FOUND,
          t(
            `validation.${ErrorCodeType.AUTHENTICATION_SIGN_UP_REQUEST_NOT_FOUND}`,
          ),
        );
      throw error;
    }
  };
}

export default SignUpRequestQueryService;

import React, { FC } from 'react';
import ProofOfResidencyViewProvider from '../../../enhancers/useCase/verification/kycRequest/ProofOfResidencyViewProvider';
import ProofOfResidencySubmitProvider from '../../../enhancers/useCase/verification/kycRequest/KycRequestSubmitProvider/ProofOfResidencySubmitProvider';
import ProofOfResidencyPage from './ProofOfResidencyPage';

const EnhancedProofOfResidencyPage: FC = () => {
  return (
    <ProofOfResidencyViewProvider>
      <ProofOfResidencySubmitProvider>
        <ProofOfResidencyPage />
      </ProofOfResidencySubmitProvider>
    </ProofOfResidencyViewProvider>
  );
};

export default EnhancedProofOfResidencyPage;

import { UserRequestStatusType } from '../../../../types/UserRequest.type';
import { SignUpRequestProps } from './SignUpRequest.type';
import InvitationCode from '../../../invite/invitationCode/InvitationCode/InvitationCode';

class SignUpRequest {
  private email: string;

  private oobCode: string;

  private expiredAt: number;

  private status: UserRequestStatusType;

  private invitationCode: InvitationCode;

  private inviterId: string;

  private createdAt?: number;

  private updatedAt?: number;

  private props: SignUpRequestProps;

  constructor(email: string, props: SignUpRequestProps) {
    this.props = props;
    this.email = email;
    this.oobCode = props.oobCode;
    this.inviterId = props.inviterId;
    this.expiredAt = props.expiredAt;
    this.status = props.status;
    this.invitationCode = new InvitationCode(props.rank, { code: props.code });
    this.createdAt = props.createdAt || Date.now();
    this.updatedAt = props.updatedAt || Date.now();
  }

  public getId = () => this.email;

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public isExpired = () =>
    this.expiredAt < Date.now() ||
    this.status !== UserRequestStatusType.unconfirmed;
}

export default SignUpRequest;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormCorporateInformationLayoutProps {
  children: React.ReactElement[];
}

const FormCorporateInformationLayout: FC<FormCorporateInformationLayoutProps> = ({
  children,
}: FormCorporateInformationLayoutProps) => {
  const elements = ['corporateInformation', 'corporateRegistry', 'button'];
  const [corporateInformation, corporateRegistry, button] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn width={1200} height="auto">
      {corporateInformation}
      <Margin top={60} />
      {corporateRegistry}
      <Margin top={60} />
      {button}
      <Margin top={83} />
    </FlexBoxColumn>
  );
};
export default FormCorporateInformationLayout;

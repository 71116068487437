import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { LabelStyle, IDTextStyle } from './style';

// Component
import { TextPrimary, TextSecondary } from '../../../../../atoms/text2/Text2';
import ContractTimeDepositCardTitleLayout from './ContractTimeDepositCardTitleLayout';
import PayOutBadge from '../../../../../molecules/badge/PayOutBadge';

// DomainObject
import ContractTimeDeposit from '../../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';

const ORGANISM_ID = `organisms.card.ContractTimeDepositCard`;

export interface ContractTimeDepositCardTitleProps {
  contractTimeDeposit?: ContractTimeDeposit;
}

const ContractTimeDepositCardTitle: FC<ContractTimeDepositCardTitleProps> = ({
  contractTimeDeposit,
}: ContractTimeDepositCardTitleProps) => {
  const { t } = useTranslation();
  const formattedId = contractTimeDeposit?.getFormattedSerialNumber();
  const isAnnualPayOut = contractTimeDeposit?.getProps().isAnnualPayOut;

  return (
    <ContractTimeDepositCardTitleLayout>
      <TextPrimary key="label" theme={LabelStyle}>
        {t(`${ORGANISM_ID}.label.id`)}
      </TextPrimary>
      <TextSecondary key="id" theme={IDTextStyle}>
        {formattedId ?? ''}
      </TextSecondary>
      <PayOutBadge
        key="badge"
        payOutType={isAnnualPayOut ? 'annual' : 'maturity'}
      />
    </ContractTimeDepositCardTitleLayout>
  );
};

export default ContractTimeDepositCardTitle;

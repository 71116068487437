// React
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCryptoCurrencyOption } from '../../../../components/molecules/selectBox/option/option/CryptoCurrencyOption/useCryptoCurrencyOption';
import { useCryptoCurrencyHistoryOption } from '../../../../components/molecules/selector/tabBar/UnderBarTabBar/CryptoCurrencyHistoryTabBar/useCryptoCurrencyHistoryOption';

// ErrorHandler
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';

// UseCase
import CryptoCurrencyWalletViewUseCase from '../../../../useCases/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWalletViewUseCase';

// Repository
import CryptoCurrencyAvailableFirestore from '../../../../repository/settings/CryptoCurrencyAvailableFirestore';
import CryptoCurrencyWalletFirestoreFactory from '../../../../repository/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWalletFirestoreFactory';

// DomainObject
import { useCryptoCurrenciesAvailable } from '../../../../domain/settings/cryptoCurrencyAvailable/CryptoCurrenciesAvailable/useCryptoCurrenciesAvailable';
import { useCryptoCurrencyWallet } from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/useCryptoCurrencyWallet';
import UserAuthorized from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useCryptoCurrencyWalletViewUseCase = (user?: UserAuthorized) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  DomainObject
   *
   * */
  const {
    cryptoCurrenciesAvailable,
    setCryptoCurrenciesAvailable,
  } = useCryptoCurrenciesAvailable();

  const {
    cryptoCurrencyWallet,
    setCryptoCurrencyWallet,
  } = useCryptoCurrencyWallet();

  /* *
   *
   *  SelectBox
   *
   * */
  const {
    cryptoCurrencyOption,
    setCryptoCurrencyOption,
  } = useCryptoCurrencyOption();
  const { historyOption, setHistoryOption } = useCryptoCurrencyHistoryOption();

  /* *
   *
   *  Repository
   *
   * */
  const cryptoCurrencyAvailableRepository = new CryptoCurrencyAvailableFirestore();
  const cryptoCurrencyWalletRepository = useMemo(
    () => CryptoCurrencyWalletFirestoreFactory.create(cryptoCurrencyOption),
    [cryptoCurrencyOption],
  );

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = useMemo(
    () => {
      return new CryptoCurrencyWalletViewUseCase(
        cryptoCurrencyAvailableRepository,
        cryptoCurrencyWalletRepository,
      );
    },
    // eslint-disable-next-line
    [cryptoCurrencyAvailableRepository, cryptoCurrencyWalletRepository],
  );

  /* *
   *
   *  Methods
   *
   * */
  const openCryptoCurrencyWallet = useCallback(async () => {
    if (!user || !cryptoCurrencyOption) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const outputs = await useCase.open();

      setCryptoCurrenciesAvailable(outputs.cryptoCurrenciesAvailable);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }

    // eslint-disable-next-line
  }, [user, cryptoCurrencyOption]);

  // Select CryptoCurrency
  const selectCryptoCurrency = useCallback(async () => {
    if (!user) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const outputs = await useCase.selectCryptoCurrency(user);

      setCryptoCurrencyWallet(outputs.cryptoCurrencyWallet);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [user, useCase]);

  /* *
   *
   *  UseEffect
   *
   * */
  useEffect(() => {
    let isMounted = true;

    if (isMounted && user && cryptoCurrencyOption) openCryptoCurrencyWallet();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [user, cryptoCurrencyOption]);

  // UseCase.selectCryptoCurrency
  useEffect(() => {
    let isMounted = true;

    if (user && cryptoCurrencyOption && isMounted) selectCryptoCurrency();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [user, cryptoCurrencyOption]);

  return {
    state,
    setState,
    cryptoCurrenciesAvailable,
    cryptoCurrencyWallet,
    cryptoCurrencyOption,
    setCryptoCurrencyOption,
    historyOption,
    setHistoryOption,
  };
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Layout
import { FlexBoxRow } from '../../../../atoms/utils/layout/Box/FlexBox';
import Box from '../../../../atoms/utils/layout/Box/Box';
import ContentBox from '../../../../atoms/utils/layout/Box/Box/ContentBox';
import Margin from '../../../../atoms/utils/layout/Margin';

// Style
import { OrderTextStyle, TitleStyle } from './style';

// Component
import { TextPrimary } from '../../../../atoms/text2/Text2';

export interface KycFormContainerProps {
  children: React.ReactNode;
  order: number;
  formId: string;
}

const KycFormContainer: FC<KycFormContainerProps> = ({
  children,
  order,
  formId,
}: KycFormContainerProps) => {
  const { t } = useTranslation();

  return (
    <ContentBox
      padding="80"
      textAlign="left"
      style={{ borderRadius: `${10 / 16}rem` }}
    >
      <FlexBoxRow alignItems="start">
        <TextPrimary theme={OrderTextStyle}>{order}.</TextPrimary>
        <Margin left={12} />
        <Box paddingTop={10} textAlign="left">
          <TextPrimary theme={TitleStyle}>{t(`${formId}.title`)}</TextPrimary>
        </Box>
      </FlexBoxRow>
      <Margin top={60} />
      {children}
    </ContentBox>
  );
};

export default KycFormContainer;

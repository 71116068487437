import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useResetAuthenticatorUseCase } from './useResetAuthenticatorUseCase';
import ResetAuthenticatorContext from './ResetAuthenticatorContext';

// Component
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type ResetAuthenticatorProviderProps = {
  children: React.ReactNode;
};

const ResetAuthenticatorProvider: FC<ResetAuthenticatorProviderProps> = ({
  children,
}: ResetAuthenticatorProviderProps) => {
  const { oobCode } = useParams<{ oobCode: string }>();

  const { state, setState, resetAuthenticator } = useResetAuthenticatorUseCase(
    oobCode,
  );

  return (
    <ResetAuthenticatorContext.Provider value={{ state, resetAuthenticator }}>
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </ResetAuthenticatorContext.Provider>
  );
};
export default ResetAuthenticatorProvider;

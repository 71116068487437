import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

// Lib
import { deleteFullWidth } from '../../../../../utils/helpers/stringHelper';
import { addressValidation } from '../../../../../utils/validation/registers';

// Component
import FormInputCountry from '../../formInput/selectBox/verification/FormInputCountry';
import FormInputText from '../../formInput/textField/FormInputText';
import TextField from '../../TextField2';
import FormInputAddressLayout from './FormInputAddressLayout';

const MOLECULE_ID = `molecules.formInputGroup.formInputAddress`;

export interface FormInputAddressKeys {
  country: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface FormInputAddressProps {
  addressKeys: FormInputAddressKeys;
  onChange?: () => void;
}

const FormInputAddress: FC<FormInputAddressProps> = ({
  addressKeys,
  onChange = () => undefined,
}: FormInputAddressProps) => {
  const { t } = useTranslation();
  const { register, setValue } = useFormContext();

  const {
    country,
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
  } = addressKeys;

  return (
    <FormInputAddressLayout>
      <FormInputCountry
        key="country"
        label={t(`${MOLECULE_ID}.country.label`)}
        inputWidth={400}
        name={country}
      />
      <FormInputText
        key="addressLine1"
        inputWidth={840}
        label={t(`${MOLECULE_ID}.address.label`)}
        maxLength={50}
        name={addressLine1}
        onChange={(text: string) => {
          onChange();
          setValue(addressLine1, deleteFullWidth(text));
        }}
        placeholder={t(`${MOLECULE_ID}.address.placeholder.addressLine1`)}
        register={register(addressValidation(t).addressLine1)}
      />
      <TextField
        key="addressLine2"
        maxLength={50}
        name={addressLine2}
        onChange={(text: string) => {
          onChange();
          setValue(addressLine2, deleteFullWidth(text));
        }}
        placeholder={t(`${MOLECULE_ID}.address.placeholder.addressLine2`)}
        register={register(addressValidation(t).addressLine2)}
        textInputWidth={840}
      />
      <TextField
        key="city"
        maxLength={30}
        name={city}
        onChange={(text: string) => {
          onChange();
          setValue(city, deleteFullWidth(text));
        }}
        placeholder={t(`${MOLECULE_ID}.address.placeholder.city`)}
        register={register(addressValidation(t).city)}
        textInputWidth={400}
      />
      <TextField
        key="state"
        maxLength={30}
        name={state}
        onChange={(text: string) => {
          onChange();
          setValue(state, deleteFullWidth(text));
        }}
        placeholder={t(`${MOLECULE_ID}.address.placeholder.state`)}
        register={register(addressValidation(t).state)}
        textInputWidth={400}
      />
      <TextField
        key="postalCode"
        maxLength={10}
        name={postalCode}
        onChange={(text: string) => {
          onChange();
          setValue(postalCode, deleteFullWidth(text));
        }}
        placeholder={t(`${MOLECULE_ID}.address.placeholder.postalCode`)}
        register={register(addressValidation(t).postalCode)}
        textInputWidth={400}
      />
    </FormInputAddressLayout>
  );
};

export default FormInputAddress;

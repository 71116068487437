import { useState } from 'react';
import { TransferDebitCardType } from '../../../../../types/Transfer.type';

export const useTransferDebitCardTypeOption = (
  type?: TransferDebitCardType,
) => {
  const [transferTypeOption, setTransferTypeOption] = useState<
    TransferDebitCardType
  >(type || TransferDebitCardType.savingsAccountToCard);

  return {
    transferTypeOption,
    setTransferTypeOption,
  };
};

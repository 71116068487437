import React, { FC, useContext } from 'react';
import { useModal } from 'react-modal-hook';
import SelectRowTableTopUpContext from 'components/organisms/table/selectRow/SelectRowTableTopUp/SelectRowTableTopUpContext';
import ModalTransferDebitCardTransactionV2sContext from 'components/organisms/modal/TransferDebitCardTransactionCancelModal/TransferDebitCardTransactionCancelModalContext';
import DebitCardRequestCherryVisa01Context from 'domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Context';
import TransferDebitCardCancelProvider from 'enhancers/useCase/transfer/debitCard/TransferDebitCardCancelProvider';

// Component
import ModalTransferDebitCardTransactionV2s from 'components/organisms/modal/TransferDebitCardTransactionCancelModal';

export type ModalTransferDebitCardTransactionV2sProviderProps = {
  children: React.ReactNode;
};

const ModalTransferDebitCardTransactionV2sProvider: FC<ModalTransferDebitCardTransactionV2sProviderProps> = ({
  children,
}: ModalTransferDebitCardTransactionV2sProviderProps) => {
  const { debitCardRequestCherryVisa01 } = useContext(
    DebitCardRequestCherryVisa01Context,
  );
  const { selectRowTableTopUp, setSelectRowTableTopUp } = useContext(
    SelectRowTableTopUpContext,
  );

  const [showModal, hideModal] = useModal(
    () => (
      <ModalTransferDebitCardTransactionV2sContext.Provider
        value={{ showModal, hideModal }}
      >
        <SelectRowTableTopUpContext.Provider
          value={{ selectRowTableTopUp, setSelectRowTableTopUp }}
        >
          <TransferDebitCardCancelProvider>
            <ModalTransferDebitCardTransactionV2s />
          </TransferDebitCardCancelProvider>
        </SelectRowTableTopUpContext.Provider>
      </ModalTransferDebitCardTransactionV2sContext.Provider>
    ),
    [debitCardRequestCherryVisa01, selectRowTableTopUp, setSelectRowTableTopUp],
  );

  return (
    <ModalTransferDebitCardTransactionV2sContext.Provider
      value={{ showModal, hideModal }}
    >
      {children}
    </ModalTransferDebitCardTransactionV2sContext.Provider>
  );
};
export default ModalTransferDebitCardTransactionV2sProvider;

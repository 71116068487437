import React, { FC } from 'react';
import { BlackButton } from './Button';

export interface CloseButtonProps {
  children: React.ReactNode;
  width?: string | number;
  height?: string | number;
  onClick?: () => void;
  theme?: {
    borderRadius?: string | number;
  };
}

const CloseButton: FC<CloseButtonProps> = ({
  children,
  width = '100%',
  height = '100%',
  onClick = () => undefined,
  theme,
}: CloseButtonProps) => {
  return (
    <BlackButton width={width} height={height} theme={theme} onClick={onClick}>
      {children}
    </BlackButton>
  );
};

export default CloseButton;

import { ReferralTimeDepositProps } from './ReferralTimeDeposit.type';
import { CurrencyType } from '../../../../../types/Currency.type';

// Value
import SerialNumberContractTimeDeposit from '../../../../idManager/productSerialNumber/ProductSerialNumber/SerialNumber/concreteSerialNumber/SerialNumberContractTimeDeposit';

abstract class ReferralTimeDeposit {
  protected contractTimeDepositId: SerialNumberContractTimeDeposit;

  protected topLevelInviterId: string;

  protected topLevelInviterContractName: string;

  // protected topLevelInviterSavingsAccountNumber: string;

  protected depositAmount: number;

  protected depositCurrency: CurrencyType;

  protected interestRate: number;

  protected term: number;

  protected userId: string;

  protected userContractName: string;

  protected commissionTotal: number;

  protected commissionPaid: number;

  protected countOfMaturity: number;

  protected createdAt: number;

  protected updatedAt: number;

  protected props: ReferralTimeDepositProps;

  constructor(id: string, props: ReferralTimeDepositProps) {
    this.contractTimeDepositId = SerialNumberContractTimeDeposit.createNumberFromRawId(
      id,
    );
    this.topLevelInviterId = props.topLevelInviterId;
    this.topLevelInviterContractName = props.topLevelInviterContractName;
    // this.topLevelInviterSavingsAccountNumber =
    //   props.topLevelInviterSavingsAccountNumber;
    this.depositAmount = props.depositAmount;
    this.depositCurrency = props.depositCurrency;
    this.interestRate = props.interestRate;
    this.term = props.term;
    this.userId = props.userId;
    this.userContractName = props.userContractName;
    this.commissionTotal = props.commissionTotal;
    this.commissionPaid = props.commissionPaid;
    this.countOfMaturity = props.countOfMaturity;
    this.createdAt = props.createdAt || Date.now();
    this.updatedAt = props.updatedAt || Date.now();
    this.props = props;
  }

  public getId = () => this.contractTimeDepositId.getRawId();

  public getFormattedId = () => this.contractTimeDepositId.getFormattedId();

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public abstract getAffiliatorContractName: () => string | undefined;
}

export default ReferralTimeDeposit;

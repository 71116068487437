import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const EditButtonStyle = {
  borderRadius: `${5 / 16}rem`,
  backgroundColor: color.background.primary,
  border: `${1 / 16}rem solid ${color.primary}`,
};

export const CancelButtonStyle = {
  borderRadius: `${5 / 16}rem`,
  backgroundColor: color.background.froly,
  border: `${1 / 16}rem solid ${color.background.froly}`,
};

export const CurrencyAmountStyle = {
  currency: {
    fontSize: 31,
    color: color.text.primary,
    fontWeight: fontWeight.bold,
    opacity: 0.5,
  },
  amount: {
    fontSize: 31,
    color: color.text.primary,
    fontWeight: fontWeight.bold,
  },
};

export const ButtonTextStyle = {
  fontSize: 18,
  color: color.text.white,
  fontWeight: fontWeight.regular,
};

export const TitleStyle = {
  fontSize: 24,
  color: color.primary,
  fontWeight: fontWeight.regular,
  opacity: 0.5,
};

export const TermTextStyle = {
  fontSize: 17,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const ContainerStyle = {
  backgroundColor: color.background.ghostWhite,
  border: `${1 / 16}rem solid ${color.border.lightBlue}`,
};

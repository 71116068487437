import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  ContractTimeDepositSeedProps,
  ContractTimeDepositSeedPropsFormat,
} from './ContractTimeDepositSeed.type';

// DomainObject
import ContractTimeDepositSeed from './ContractTimeDepositSeed';

class ContractTimeDepositSeedFactory {
  static create = (props: ContractTimeDepositSeedProps) => {
    const result = Joi.object(ContractTimeDepositSeedPropsFormat).validate(
      props,
    );

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: ContractTimeDepositSeed.name,
          reason: `${result.error.details[0].message}`,
        },
      );

    return new ContractTimeDepositSeed(props);
  };
}

export default ContractTimeDepositSeedFactory;

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import { AssetCryptoCurrencyProps } from './AssetCryptoCurrency.type';
import { CryptoCurrencyType } from '../../../../types/CryptoCurrency.type';

// DomainObject
import Asset from '../../asset/Asset/Asset';

class AssetCryptoCurrency extends Asset {
  constructor(currency: CryptoCurrencyType, props: AssetCryptoCurrencyProps) {
    super(currency, props);

    // TYPE GUARD
    if (!Object.keys(CryptoCurrencyType).includes(currency))
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
        {
          type: 'CryptoCurrencyType',
          place: 'AssetCryptoCurrency.constructor',
        },
      );
  }

  public getCurrency = () => this.currency as CryptoCurrencyType;
}
export default AssetCryptoCurrency;

import React, { FC } from 'react';
import Logo from '../../../../assets/images/logo.svg';
import LogoNoName from '../../../../assets/images/logo-no-name.svg';
import LogoWithSymbol from '../../../../assets/images/logo-with-symbol.svg';
import LogoPrimary from '../../../../assets/images/logo-primary.svg';
import Box from '../../utils/layout/Box/Box';
import InvalidDataFoundError from '../../../../utils/errors/InvalidDataFoundError';

export interface LogoImageProps {
  width?: string | number;
  height?: string | number;
  logoType:
    | 'textLogo'
    | 'textLogoWithName'
    | 'textLogoWithSymbol'
    | 'logoPrimary';
}

const LogoImage: FC<LogoImageProps> = ({
  width = '100%',
  height = '100%',
  logoType,
}: LogoImageProps) => {
  let source;

  switch (logoType) {
    case 'textLogo':
      source = LogoNoName;
      break;
    case 'textLogoWithName':
      source = Logo;
      break;
    case 'textLogoWithSymbol':
      source = LogoWithSymbol;
      break;
    case 'logoPrimary':
      source = LogoPrimary;
      break;
    default:
      throw new InvalidDataFoundError(
        `Unknown logoImage type '${logoType}' is found at LogoImage`,
      );
  }

  return (
    <Box width={width} height={height}>
      <img src={source} alt="MONACO" width="100%" height="100%" />
    </Box>
  );
};
export default LogoImage;

import { required, min, max, enoughBalance } from '../validator';
import { TypeOfT } from '../../../../types/typeof/Translation.type';
import { minusByDecimal } from '../../../helpers/calculationHelper';

export const transferDebitCardValidation = (t: TypeOfT) => ({
  amount: (
    balance: number,
    fee: number,
    minAmount: number,
    maxAmount: number,
  ) => ({
    required: required('Amount', t),
    validate: {
      enoughBalance: enoughBalance(minusByDecimal(balance, fee), t),
      min: min('Amount', minAmount, t),
      max: max('Amount', maxAmount, t),
    },
  }),
});

import React, { FC, useContext } from 'react';
import { useNotificationLogViewUseCase } from './useNotificationLogViewUseCase';
import NotificationLogContext from '../../../../domain/notification/notificationLog/NotificationLog/NotificationLogContext';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type NotificationLogViewProviderProps = {
  children: React.ReactNode;
};

const NotificationLogViewProvider: FC<NotificationLogViewProviderProps> = ({
  children,
}: NotificationLogViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const { state, setState, notificationLog } = useNotificationLogViewUseCase(
    userAuthorized,
  );

  return (
    <NotificationLogContext.Provider value={{ notificationLog }}>
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </NotificationLogContext.Provider>
  );
};

export default NotificationLogViewProvider;

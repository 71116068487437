import styled from 'styled-components';
import fontSize from '../../../../fonts/fontSize.json';

export const StyledButton = styled.button`
  width: 100%;
  height: 100%;
  border-radius: ${8 / 16}rem;
  outline: none;
  border-style: none;
  letter-spacing: ${fontSize.mediumPx20 * 0.02};

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

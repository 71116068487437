import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import Margin from '../../../../atoms/utils/layout/Margin';
import { FlexRow } from '../../../../atoms/utils/layout/Flex';

export interface FormRegisterLayoutProps {
  children: React.ReactElement[];
}

const FormRegisterLayout: FC<FormRegisterLayoutProps> = ({
  children,
}: FormRegisterLayoutProps) => {
  const elements = [
    'contractName',
    'userType',
    'email',
    'password',
    'confirmPassword',
    'button',
  ];
  const [
    contractName,
    userType,
    email,
    password,
    confirmPassword,
    button,
  ] = getElementsFromKeys(children, elements);

  return (
    <div>
      <FlexRow justifyContent="flex-start">
        {contractName}
        <Margin left={60} />
        {userType}
      </FlexRow>
      <Margin top={40} />
      {email}
      <Margin top={40} />
      {password}
      <Margin top={40} />
      {confirmPassword}
      <Margin top={70} />
      {button}
    </div>
  );
};

export default FormRegisterLayout;

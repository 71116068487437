/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { SignInPageState } from './useSignInPageState';

export interface SignInPageStateContextProps {
  pageState: SignInPageState;
  setPageState: (state: SignInPageState) => void;
}

const SignInPageStateContext = React.createContext<SignInPageStateContextProps>(
  {
    pageState: 'initial',
    setPageState: (state: SignInPageState) => undefined,
  },
);

export default SignInPageStateContext;

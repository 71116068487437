import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Error
import { ErrorIdType } from '../../../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../../../errors/ErrorFactory/SystemErrorFactory';

// Constant
import {
  ROUTE_TIME_DEPOSIT_RENEWAL_RESERVATION_DELETE,
  ROUTE_TIME_DEPOSIT_RENEWAL_RESERVATION_EDIT,
} from '../../../../../../config/constants/routing';

// Style
import {
  CancelButtonStyle,
  CurrencyAmountStyle,
  EditButtonStyle,
  ButtonTextStyle,
  TitleStyle,
  TermTextStyle,
} from './style';

// Component
import { TextPrimary, TextSecondary } from '../../../../../atoms/text2/Text2';
import Button from '../../../../../atoms/button/Button2/Button';
import CurrencyAmount from '../../../../../molecules/textGroup/CurrencyAmount';
import ContractTimeDepositRenewalReservationEditCardLayout from './ContractTimeDepositRenewalReservationEditCardLayout';

// DomainObject
import ContractTimeDeposit from '../../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';
import PayOutBadge from '../../../../../molecules/badge/PayOutBadge';

const ORGANISM_ID =
  'organisms.card.ContractTimeDepositRenewalReservationCard.ContractTimeDepositRenewalReservationEditCard';

export type ContractTimeDepositRenewalReservationCardProps = {
  contractTimeDeposit: ContractTimeDeposit;
};

const ContractTimeDepositRenewalReservationEditCard: FC<ContractTimeDepositRenewalReservationCardProps> = ({
  contractTimeDeposit,
}: ContractTimeDepositRenewalReservationCardProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const contractTimeDepositId = contractTimeDeposit.getRawId();
  const {
    renewalReservation,
    depositCurrency,
  } = contractTimeDeposit.getProps();

  const principalAfterMatured = contractTimeDeposit.getPrincipalAfterMatured();
  const termAfterMatured = contractTimeDeposit.getTermAfterMatured();

  if (!renewalReservation)
    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
      {
        type: 'Not null (ContractTimeDepositRenewalReservation)',
        place: 'ContractTimeDepositRenewalReservationEditCard',
      },
    );

  return (
    <ContractTimeDepositRenewalReservationEditCardLayout
      contractTimeDeposit={contractTimeDeposit}
    >
      <TextPrimary key="title" theme={TitleStyle}>
        {t(`${ORGANISM_ID}.title`)}
      </TextPrimary>
      <CurrencyAmount
        key="amount"
        amount={principalAfterMatured}
        currency={depositCurrency}
        marginBetween={10}
        theme={CurrencyAmountStyle}
        isSymbol
      />
      <TextSecondary
        key="term"
        theme={TermTextStyle}
        style={{ whiteSpace: 'nowrap' }}
      >
        {`${t(`general.unit.months`, { months: termAfterMatured })}`}
      </TextSecondary>
      <PayOutBadge
        key="payOut"
        payOutType={renewalReservation.isAnnualPayOut ? 'annual' : 'maturity'}
      />
      <Button
        key="editButton"
        width={83}
        height={35}
        theme={EditButtonStyle}
        onClick={() =>
          history.push(
            `${ROUTE_TIME_DEPOSIT_RENEWAL_RESERVATION_EDIT}/${contractTimeDepositId}`,
          )
        }
      >
        <TextSecondary theme={ButtonTextStyle}>
          {t(`atoms.button.edit`)}
        </TextSecondary>
      </Button>
      <Button
        key="cancelButton"
        width={83}
        height={35}
        theme={CancelButtonStyle}
        onClick={() => {
          return history.push(
            `${ROUTE_TIME_DEPOSIT_RENEWAL_RESERVATION_DELETE}/${contractTimeDepositId}`,
          );
        }}
      >
        <TextSecondary theme={ButtonTextStyle}>
          {t(`atoms.button.cancel`)}
        </TextSecondary>
      </Button>
    </ContractTimeDepositRenewalReservationEditCardLayout>
  );
};

export default ContractTimeDepositRenewalReservationEditCard;

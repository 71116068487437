import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProofOfResidencySubmitContext from '../../../../../../enhancers/useCase/verification/kycRequest/KycRequestSubmitProvider/ProofOfResidencySubmitProvider/ProofOfResidencySubmitContext';
import UserAuthorizedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Lib
import { isValid, getDefaultValues } from './helper';

// Type
import {
  FormInputProofOfResidency,
  FormInputProofOfResidencyContext,
} from './FormProofOfResidency.type';
import { FormInputShippingAddress } from '../FormCorporateAddress/FormCorporateAddress.type';
import { UserType } from '../../../../../../types/User.type';

// Constant
import { IS_LOADING } from '../../../../../../config/constants/requestState';
import { ROUTE_KYC_STATUS } from '../../../../../../config/constants/routing';

// Component
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../../atoms/button/Button2';
import LabelSendIcon from '../../../../../atoms/button/ButtonLabel/LabelSendIcon';
import FormResidentialAddressInput from './FormResidentialAddressInput';
import SelectDocumentType from './SelectDocumentType';
import FormResidencyProofUpload from './FormResidencyProofUpload';
import FormShippingAddress from '../FormCorporateAddress/FormShippingAddress';
import FormProofOfResidencyLayout from './FormProofOfResidencyLayout';

// ValueObject
import ProofOfResidency from '../../../../../../value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidency';

export interface ProofOfResidencyFormProps {
  proofOfResidency?: ProofOfResidency;
}

const ProofOfResidencyForm: FC<ProofOfResidencyFormProps> = ({
  proofOfResidency,
}: ProofOfResidencyFormProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { formInput, setFormInput } = useContext(
    FormInputProofOfResidencyContext,
  );

  // DomainObject
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // Method
  const { state, submitProofOfResidency } = useContext(
    ProofOfResidencySubmitContext,
  );

  /* *
   *
   *  Form
   *
   * */
  const methods = useForm<FormInputProofOfResidency & FormInputShippingAddress>(
    {
      mode: 'onChange',
    },
  );
  const { errors, handleSubmit, reset, setValue, watch } = methods;

  /* *
   *
   *  DefaultValues
   *
   * */

  // Set DefaultValue
  useEffect(() => {
    if (formInput) reset(formInput);
  }, [formInput, reset]);

  // Set Default Value from ProofOfResidency
  useEffect(() => {
    if (!formInput && proofOfResidency && userAuthorized) {
      const defaultValues = getDefaultValues(proofOfResidency, userAuthorized);

      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [formInput, proofOfResidency, setValue, userAuthorized]);

  /* *
   *
   *  Event
   *
   * */

  const onSubmit = useCallback(
    async (input: FormInputProofOfResidency & FormInputShippingAddress) => {
      setFormInput(input);

      submitProofOfResidency(input, () => {
        history.push(ROUTE_KYC_STATUS);
      });
    },
    // eslint-disable-next-line
    [submitProofOfResidency],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <FormProofOfResidencyLayout
          isDisplayShippingAddress={
            !!userAuthorized?.isUserType(UserType.individual)
          }
        >
          <FormResidentialAddressInput key="residentialAddress" />
          <SelectDocumentType key="documentType" />
          <FormResidencyProofUpload
            key="documents"
            proofOfResidency={proofOfResidency}
          />
          <FormShippingAddress key="shippingAddress" />
          <SubmitButton
            key="button"
            disabled={!isValid(errors, watch)}
            isLoading={state === IS_LOADING}
            height={75}
            width={'100%'}
          >
            <LabelSendIcon
              iconSize={26}
              iconStyle={{ color: 'white' }}
              label={t(`atoms.button.submit`)}
              marginBetween={8}
              textStyle={DefaultButtonTextStyle}
            />
          </SubmitButton>
        </FormProofOfResidencyLayout>
      </FormProvider>
    </form>
  );
};

export default ProofOfResidencyForm;

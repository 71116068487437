// Types
import { TransferSavingsAccountType } from '../../types/Transfer.type';

// IService
import IFeeTransferSavingsAccountQueryService from '../../useCases/settings/IFeeTransferSavingsAccountQueryService';

// IRepository
import IFeeTransferSavingsAccountRepository from '../../domain/settings/transactionFee/FeeTransferSavingsAccount/IFeeTransferSavingsAccountRepository';

class FeeTransferSavingsAccountQueryService
  implements IFeeTransferSavingsAccountQueryService {
  private feeTransferSavingsAccountRepository: IFeeTransferSavingsAccountRepository;

  constructor(
    feeTransferSavingsAccountRepository: IFeeTransferSavingsAccountRepository,
  ) {
    this.feeTransferSavingsAccountRepository = feeTransferSavingsAccountRepository;
  }

  public findById = async (transferType: TransferSavingsAccountType) => {
    const fee = await this.feeTransferSavingsAccountRepository.findById(
      transferType as string,
    );

    return fee;
  };
}

export default FeeTransferSavingsAccountQueryService;

import {
  DATE_FORMAT_HYPHEN,
  DATE_FORMAT_SLASH,
} from '../../config/constants/regularExpression';

import TimeZonedTime from './TimeZonedTime';

class TimeZonedTimeFactory {
  static create = (time?: number | string) => {
    return new TimeZonedTime(time);
  };

  static createZeroOClock = (date: string) => {
    const replaced = date.match(DATE_FORMAT_SLASH)
      ? date.replace(/\//g, '-')
      : date;

    if (!replaced.match(DATE_FORMAT_HYPHEN))
      throw new Error(`Invalid date ${date} at TimeZonedTimeFactory`);

    return new TimeZonedTime(`${replaced}T00:00:00`);
  };

  static createZeroOClockFromTimestamp = (timestamp: number) => {
    const dateString = TimeZonedTimeFactory.create(timestamp).format(
      'yyyy-mm-dd',
    );

    return new TimeZonedTime(`${dateString}T00:00:00`);
  };
}
export default TimeZonedTimeFactory;

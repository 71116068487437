// Type
import { UseStateType } from '../../../types/typeof/UseState';
import Maintenance from '../../../domain/settings/maintenance/Maintenance/Maintenance/Maintenance';

// IService
import IMaintenanceQueryService from '../../../useCases/settings/maintenance/IMaintenanceQueryService';

// IRepository
import { IMaintenanceQueryRepository } from '../../../domain/settings/maintenance/Maintenance/IMaintenanceRepository';

class MaintenanceQueryService implements IMaintenanceQueryService {
  private maintenanceQueryRepository: IMaintenanceQueryRepository;

  private unsubscribe: Array<() => void> = [];

  constructor(maintenanceQueryRepository: IMaintenanceQueryRepository) {
    this.maintenanceQueryRepository = maintenanceQueryRepository;
  }

  public setIsMaintenance = async (
    setter: UseStateType<Maintenance | undefined>,
    setState: UseStateType<string>,
  ) => {
    const unsubscribe = await this.maintenanceQueryRepository.subscribeById(
      'isMaintenance',
      setter,
      setState,
    );

    this.unsubscribe.push(unsubscribe);
  };

  public executeUnsubscribe = () => {
    this.unsubscribe.forEach((unsubscribe) => unsubscribe());
  };
}

export default MaintenanceQueryService;

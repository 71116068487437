import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LinkTextButton = styled.button`
  background-color: transparent;
  border: none;
`;

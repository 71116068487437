import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  CherryVisa01ProofOfIdentityProps,
  CherryVisa01ProofOfIdentityPropsFormat,
} from './CherryVisa01ProofOfIdentity.type';

// ValueObject
import CherryVisa01ProofOfIdentity from './CherryVisa01ProofOfIdentity';

class CherryVisa01ProofOfIdentityFactory {
  static create = (props: CherryVisa01ProofOfIdentityProps) => {
    const result = Joi.object(CherryVisa01ProofOfIdentityPropsFormat).validate(
      props,
    );

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: CherryVisa01ProofOfIdentity.name,
          reason: result.error.details[0].message,
        },
      );

    return new CherryVisa01ProofOfIdentity(props);
  };
}

export default CherryVisa01ProofOfIdentityFactory;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import fontSize from '../../../../fonts/fontSizePixel.json';
import fontWeight from '../../../../fonts/fontWeight.json';
import EllipseWrappedText from './EllipseWrappedText';
import { GradeType } from '../../../../types/Invite.type';

export interface InvitationGradeTypeProps {
  grade: GradeType;
}

const InvitationGradeType: FC<InvitationGradeTypeProps> = ({
  grade,
}: InvitationGradeTypeProps) => {
  const { t } = useTranslation();

  let [backgroundColor, color] = ['', '', ''];

  switch (grade) {
    case GradeType.priority:
      [backgroundColor, color] = ['#FFE6E9', '#D06E77'];
      break;
    case GradeType.general:
      [backgroundColor, color] = ['#EEF8EE', '#5CB85C'];
      break;
    default:
      [backgroundColor, color] = ['', '', ''];
  }

  return (
    <EllipseWrappedText
      text={t(`invitation.gradeType.${grade}`)}
      color={color}
      textSize={fontSize.pixel18}
      textFontWeight={fontWeight.medium}
      textFontFamilyType="sub"
      wrapSize={`${29 / 16}rem`}
      borderRadius={`${25 / 16}rem`}
      backgroundColor={backgroundColor}
      padding={`${12 / 16}rem`}
    />
  );
};
export default InvitationGradeType;

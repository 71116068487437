import { omit } from 'lodash';
import {
  getDayFromyyyymmdd,
  getMonthFromyyyymmdd,
  getYearFromyyyymmdd,
} from '../../../utils/helpers/datetimeHelper';
import { generateFileByInteger8Array } from '../../../utils/helpers/fileHelper';

// Functions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// Type
import {
  DebitCardRequestCherryVisa01RawDataType,
  DebitCardRequestCherryVisa01RawDataTypeWithProofOfIdentityFiles,
  DebitCardRequestCherryVisa01RawDataTypeWithProofOfReceiptFiles,
} from '../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01.type';
import { ExtensionTypeFileTypeMapper } from '../../../types/File.type';

// IRepository
import IDebitCardRequestCherryVisa01Repository from '../../../domain/debitCard/debitCardRequest/IDebitCardRequestCherryVisa01Repository';

// Constant
import {
  DEBIT_CARD_REQUEST_CHERRY_VISA_01_GET_RAW_DATA_BY_ID,
  DEBIT_CARD_REQUEST_CHERRY_VISA_01_GET_RAW_DATA_BY_ID_WITH_PROOF_OF_IDENTITY_FILES,
  DEBIT_CARD_REQUEST_CHERRY_VISA_01_GET_RAW_DATA_BY_ID_WITH_PROOF_OF_RECEIPT_FILES,
  DEBIT_CARD_REQUEST_CHERRY_VISA_01_PAY_ISSUANCE_FEE,
  DEBIT_CARD_REQUEST_CHERRY_VISA_01_UPDATE_PROOF_OF_IDENTITY,
  DEBIT_CARD_REQUEST_CHERRY_VISA_01_UPDATE_PROOF_OF_RECEIPT,
} from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// DomainObject
import DebitCardRequestCherryVisa01 from '../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01';
import DebitCardRequestCherryVisa01Factory from '../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Factory';

// ValueObject
import CherryVisa01ProofOfIdentityFactory from '../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentityFactory';
import CherryVisa01ProofOfReceiptFactory from '../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfReceipt/CherryVisa01ProofOfReceiptFactory';

class DebitCardRequestCherryVisa01Functions
  extends FirebasePrivateFunctions
  implements IDebitCardRequestCherryVisa01Repository {
  public findById = async (id: string) => {
    // Replace id
    const endpoint = DEBIT_CARD_REQUEST_CHERRY_VISA_01_GET_RAW_DATA_BY_ID.replace(
      /:id/,
      id,
    );

    const response = await this.privateGET(endpoint);

    const row = response.data.data as DebitCardRequestCherryVisa01RawDataType;

    return DebitCardRequestCherryVisa01Factory.createFromRawData(row);
  };

  public findByIdWithProofOfIdentityFiles = async (id: string) => {
    // Replace id
    const endpoint = DEBIT_CARD_REQUEST_CHERRY_VISA_01_GET_RAW_DATA_BY_ID_WITH_PROOF_OF_IDENTITY_FILES.replace(
      /:id/,
      id,
    );

    const response = await this.privateGET(endpoint);

    const row = response.data
      .data as DebitCardRequestCherryVisa01RawDataTypeWithProofOfIdentityFiles;

    const proofOfIdentityProps = row.props.proofOfIdentity;

    if (!proofOfIdentityProps) {
      return DebitCardRequestCherryVisa01Factory.create(row.id, {
        ...row.props,
        proofOfIdentity: undefined,
      });
    }

    const document1 =
      proofOfIdentityProps.document.documentImage1 &&
      generateFileByInteger8Array(
        proofOfIdentityProps.document.documentImage1.name,
        ExtensionTypeFileTypeMapper[
          proofOfIdentityProps.document.documentImage1.type
        ],
        proofOfIdentityProps.document.documentImage1.data.data,
      );

    const document2 =
      proofOfIdentityProps.document.documentImage2 &&
      generateFileByInteger8Array(
        proofOfIdentityProps.document.documentImage2.name,
        ExtensionTypeFileTypeMapper[
          proofOfIdentityProps.document.documentImage2.type
        ],
        proofOfIdentityProps.document.documentImage2.data.data,
      );

    const passport =
      proofOfIdentityProps.passport.passportImage &&
      generateFileByInteger8Array(
        proofOfIdentityProps.passport.passportImage.name,
        ExtensionTypeFileTypeMapper[
          proofOfIdentityProps.passport.passportImage.type
        ],
        proofOfIdentityProps.passport.passportImage.data.data,
      );

    const selfie =
      proofOfIdentityProps.passport.selfieImage &&
      generateFileByInteger8Array(
        proofOfIdentityProps.passport.selfieImage.name,
        ExtensionTypeFileTypeMapper[
          proofOfIdentityProps.passport.selfieImage.type
        ],
        proofOfIdentityProps.passport.selfieImage.data.data,
      );

    const signature =
      proofOfIdentityProps.signatureImage &&
      generateFileByInteger8Array(
        proofOfIdentityProps.signatureImage.name,
        ExtensionTypeFileTypeMapper[proofOfIdentityProps.signatureImage.type],
        proofOfIdentityProps.signatureImage.data.data,
      );

    const proofOfIdentity =
      proofOfIdentityProps &&
      CherryVisa01ProofOfIdentityFactory.create({
        ...omit(proofOfIdentityProps, ['signatureImage']),
        document: {
          ...omit(proofOfIdentityProps.document, [
            'documentImage1',
            'documentImage2',
          ]),
          document1,
          document2,
        },
        passport: {
          ...omit(proofOfIdentityProps.passport, [
            'passportImage',
            'selfieImage',
          ]),
          passport,
          selfie,
        },
        signature,
        shippingAddress: {
          ...proofOfIdentityProps.shippingAddress,
          addressLine2: proofOfIdentityProps.shippingAddress.addressLine2 ?? '',
        },
      });

    return DebitCardRequestCherryVisa01Factory.create(row.id, {
      ...row.props,
      proofOfIdentity,
    });
  };

  public findByIdWithProofOfReceiptFiles = async (id: string) => {
    // Replace id
    const endpoint = DEBIT_CARD_REQUEST_CHERRY_VISA_01_GET_RAW_DATA_BY_ID_WITH_PROOF_OF_RECEIPT_FILES.replace(
      /:id/,
      id,
    );

    const response = await this.privateGET(endpoint);

    const row = response.data
      .data as DebitCardRequestCherryVisa01RawDataTypeWithProofOfReceiptFiles;

    const proofOfReceiptProps = row.props.proofOfReceipt;

    if (!proofOfReceiptProps) {
      return DebitCardRequestCherryVisa01Factory.create(row.id, {
        ...row.props,
        proofOfReceipt: undefined,
      });
    }

    const selfie =
      proofOfReceiptProps.selfieImage &&
      generateFileByInteger8Array(
        proofOfReceiptProps.selfieImage.name,
        ExtensionTypeFileTypeMapper[proofOfReceiptProps.selfieImage.type],
        proofOfReceiptProps.selfieImage.data.data,
      );

    const proofOfReceipt =
      proofOfReceiptProps &&
      CherryVisa01ProofOfReceiptFactory.create({
        ...omit(proofOfReceiptProps, ['selfieImage']),
        selfie,
      });

    return DebitCardRequestCherryVisa01Factory.create(row.id, {
      ...row.props,
      proofOfReceipt,
    });
  };

  public updateProofOfIdentity = async (
    debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01,
  ) => {
    const id = debitCardRequestCherryVisa01.getId();

    const proofOfIdentity = debitCardRequestCherryVisa01.getDefinedProofOfIdentity();

    const props = proofOfIdentity.getProps();

    await this.privatePUT(
      DEBIT_CARD_REQUEST_CHERRY_VISA_01_UPDATE_PROOF_OF_IDENTITY.replace(
        /:id/,
        id,
      ),
      {
        country: props.shippingAddress.country,
        addressLine1: props.shippingAddress.addressLine1,
        addressLine2: props.shippingAddress.addressLine2,
        city: props.shippingAddress.city,
        state: props.shippingAddress.state,
        postalCode: props.shippingAddress.postalCode,
        documentType: props.document.type,
        documentId1: props.document.documentId1,
        documentId2: props.document.documentId2,
        countryCode: props.countryCode,
        phoneNumber: props.phoneNumber,
        gender: props.gender,
        isInscribed: props.isInscribed,
        dateOfBirthYear: getYearFromyyyymmdd(props.dateOfBirth, '/'),
        dateOfBirthMonth: getMonthFromyyyymmdd(props.dateOfBirth, '/'),
        dateOfBirthDay: getDayFromyyyymmdd(props.dateOfBirth, '/'),
        passportNo: props.passport.passportNo,
        passportId: props.passport.passportId,
        issuedDateYear: getYearFromyyyymmdd(props.passport.issuedAt, '/'),
        issuedDateMonth: getMonthFromyyyymmdd(props.passport.issuedAt, '/'),
        issuedDateDay: getDayFromyyyymmdd(props.passport.issuedAt, '/'),
        expiredDateYear: getYearFromyyyymmdd(props.passport.expiredAt, '/'),
        expiredDateMonth: getMonthFromyyyymmdd(props.passport.expiredAt, '/'),
        expiredDateDay: getDayFromyyyymmdd(props.passport.expiredAt, '/'),
        issuingCountry: props.document.issuingCountry,
        nationality: props.nationality,
        selfieId: props.passport.selfieId,
        signatureId: props.signatureId,
        occupation: props.occupation,
      },
    );

    return debitCardRequestCherryVisa01;
  };

  public updateProofOfReceipt = async (
    debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01,
  ) => {
    const id = debitCardRequestCherryVisa01.getId();

    const proofOfReceipt = debitCardRequestCherryVisa01.getDefinedProofOfReceipt();

    const props = proofOfReceipt.getProps();

    await this.privatePUT(
      DEBIT_CARD_REQUEST_CHERRY_VISA_01_UPDATE_PROOF_OF_RECEIPT.replace(
        /:id/,
        id,
      ),
      {
        selfieId: props.selfieId,
      },
    );

    return debitCardRequestCherryVisa01;
  };

  public payIssuance = async (
    debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01,
  ) => {
    const id = debitCardRequestCherryVisa01.getId();

    const endpoint = DEBIT_CARD_REQUEST_CHERRY_VISA_01_PAY_ISSUANCE_FEE;

    await this.privatePOST(endpoint, { id });

    return debitCardRequestCherryVisa01;
  };
}
export default DebitCardRequestCherryVisa01Functions;

import { required, onlyNumbers, validPhoneNumber } from '../validator';

// Type
import { TypeOfT } from '../../../../types/typeof/Translation.type';

const shippingAddressValidation = (t: TypeOfT) => ({
  receiverName: {
    required: required('Receiver Name', t),
  },
  phoneNumber: (countryCode?: string) => ({
    required: required('Phone Number', t),
    validate: {
      onlyNumbers: onlyNumbers('Phone Number', t),
      validPhoneNumber: validPhoneNumber(countryCode || '', t),
    },
  }),
});

export default shippingAddressValidation;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import Margin from '../../../atoms/utils/layout/Margin';
import { Container } from './style';

export interface ReregistrationTemplateLayoutProps {
  children: React.ReactElement[];
}

const ReregistrationTemplateLayout: FC<ReregistrationTemplateLayoutProps> = ({
  children,
}: ReregistrationTemplateLayoutProps) => {
  const elements = ['title', 'description', 'form'];
  const [title, description, form] = getElementsFromKeys(children, elements);

  return (
    <Container>
      {title}
      <Margin top={20} />
      {description}
      <Margin top={50} />
      {form}
      <Margin bottom={120} />
    </Container>
  );
};
export default ReregistrationTemplateLayout;

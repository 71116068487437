import React, { FC } from 'react';

// Component
import { CheckIconLarge } from '../../../atoms/icon/CheckIcon';
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import { TextSecondary, TextThemeProps } from '../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';
import NotificationSuccessLayout from './NotificationSuccessLayout';

export interface NotificationSuccessProps {
  title: string;
  titleStyle: { color: string };
  description: string;
  descriptionStyle: TextThemeProps;
}

const NotificationSuccess: FC<NotificationSuccessProps> = ({
  title,
  titleStyle,
  description,
  descriptionStyle,
}: NotificationSuccessProps) => {
  return (
    <NotificationSuccessLayout>
      <CheckIconLarge key="icon" type="success" />
      <PageTitle key="title" theme={titleStyle}>
        {title}
      </PageTitle>
      <TextSecondary key="description" theme={descriptionStyle}>
        <LineBreakReplaced text={description} />
      </TextSecondary>
    </NotificationSuccessLayout>
  );
};

export default NotificationSuccess;

// Helper
import { convertMoneyFormat } from '../../../../../../utils/helpers/currencyHelper';

// Type
import { TypeOfT } from '../../../../../../types/typeof/Translation.type';

// DomainObject
import CryptoCurrencySpotTransactions from '../../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransactions/CryptoCurrencySpotTransactions';
import CryptoCurrencySpotTransaction from '../../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction';
import CryptoCurrencyDeposit from '../../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/CryptoCurrencyDeposit/CryptoCurrencyDeposit';

// ValueObject
import TimeZonedTime from '../../../../../../value/datetime/TimeZonedTime';

// Constant
const ORGANISM_ID = `organisms.table.TableCryptoCurrencyTransactionHistory`;

type Object = { [key: string]: string };

export interface TdCryptoCurrencySpotTransactionHistory extends Object {
  createdAt: string;
  transactionType: string;
  deposit: string;
  withdrawal: string;
  fee: string;
  status: string;
  txid: string;
}

class TableCryptoCurrencySpotTransactionHistoryMapper {
  static domainToTd = (
    transactions: CryptoCurrencySpotTransactions,
    t: TypeOfT,
  ) => {
    const converter = (
      cryptoCurrencySpotTransaction: CryptoCurrencySpotTransaction,
    ) => {
      // Preparation
      const {
        createdAt,
        amount,
        currency,
        fee,
        status,
      } = cryptoCurrencySpotTransaction.getProps();

      const transactionType = cryptoCurrencySpotTransaction.getTransactionType();

      // Conversion
      const tdCreatedAt = new TimeZonedTime(createdAt).getTwoLinesDateTime();
      const tdTransactionType = t(
        `${ORGANISM_ID}.td.transactionType.${transactionType}`,
      );
      const [tdDeposit, tdWithdrawal] =
        cryptoCurrencySpotTransaction instanceof CryptoCurrencyDeposit
          ? [convertMoneyFormat(amount, currency), '']
          : ['', convertMoneyFormat(amount, currency)];

      const tdFee = convertMoneyFormat(fee, currency);

      const txid =
        cryptoCurrencySpotTransaction instanceof CryptoCurrencyDeposit
          ? cryptoCurrencySpotTransaction.getProps().txid
          : '';

      // Mapping
      return {
        createdAt: tdCreatedAt,
        transactionType: tdTransactionType,
        deposit: tdDeposit,
        withdrawal: tdWithdrawal,
        fee: tdFee,
        status,
        txid,
      } as TdCryptoCurrencySpotTransactionHistory;
    };

    return transactions.map<TdCryptoCurrencySpotTransactionHistory>(converter);
  };
}

export default TableCryptoCurrencySpotTransactionHistoryMapper;

// React
import React, { FC } from 'react';

// Components
import Box from '../../../../atoms/utils/layout/Box/Box';
import LineBreakReplaced from '../../../../atoms/text2/LineBreakReplaced';

export interface NumberTdBoxProps {
  text: string;
}

const NumberTdBox: FC<NumberTdBoxProps> = ({ text }: NumberTdBoxProps) => {
  return (
    <Box
      width="100%"
      height="100%"
      textAlign="right"
      paddingRight={13}
      paddingTop={13}
    >
      <LineBreakReplaced text={text} />
    </Box>
  );
};

export default NumberTdBox;

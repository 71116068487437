// React
import React, { FC, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DebitCardSpotTransactionV2sContext from '../../../../../domain/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardSpotTransactionV2/DebitCardSpotTransactionV2s/DebitCardSpotTransactionV2sContext';
import DebitCardOptionContext from '../../../../molecules/selectBox/option/option/DebitCardOption/DebitCardOptionContext';

// Lib
import { getSymbolFromCurrency } from '../../../../../utils/helpers/currencyHelper';

// Mapper
import TableDebitCardTransactionHistoryMapper, {
  TdDebitCardTransactionHistory,
} from './TableDebitCardTransactionHistoryMapper';

// Constant
import { FILTER_RULE } from '../../../../../config/constants/business';

// Components
import {
  HiddenFilter,
  SelectColumnFilter,
  TextInputFilter,
} from '../../../../atoms/table/filter';
import DefaultTdBox from '../../../../molecules/table/td/DefaultTdBox';
import NumberTdBox from '../../../../molecules/table/td/NumberTdBox';
import TableFunctional from '../../../../molecules/table/table/TableFunctional';

// ID
const ORGANISM_ID = `organisms.table.TableDebitCardTransactionHistory`;

const TableDebitCardTransactionHistory: FC = () => {
  const { t } = useTranslation();
  const { debitCardSpotTransactions } = useContext(
    DebitCardSpotTransactionV2sContext,
  );
  const { debitCardOption } = useContext(DebitCardOptionContext);

  const currency = useMemo(
    () =>
      debitCardOption
        ? getSymbolFromCurrency(debitCardOption.getProps().currency)
        : '',
    [debitCardOption],
  );

  const data = useMemo(
    () =>
      TableDebitCardTransactionHistoryMapper.domainToTd(
        debitCardSpotTransactions,
        t,
      ),
    [debitCardSpotTransactions, t],
  );

  const columns = useMemo(
    () => [
      {
        Header: t(`${ORGANISM_ID}.th.date`),
        accessor: (values: TdDebitCardTransactionHistory) =>
          values.createdAtString,
        Cell: (value: { row: { original: TdDebitCardTransactionHistory } }) =>
          DefaultTdBox({ text: value.row.original.createdAtDisplay }),
        Filter: HiddenFilter,
        filter: FILTER_RULE,
        minWidth: `${160 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.yearMonth`),
        accessor: (values: TdDebitCardTransactionHistory) => values.yearMonth,
        Cell: (value: { value: string }) => DefaultTdBox({ text: value.value }),
        Filter: TextInputFilter,
        filter: FILTER_RULE,
        minWidth: `${140 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.transactionType`),
        accessor: (values: TdDebitCardTransactionHistory) =>
          values.transactionType,
        Cell: (value: { value: string }) => DefaultTdBox({ text: value.value }),
        Filter: SelectColumnFilter,
        filter: FILTER_RULE,
        minWidth: `${190 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.description`),
        accessor: (values: TdDebitCardTransactionHistory) => values.description,
        Cell: (value: { row: { original: TdDebitCardTransactionHistory } }) =>
          DefaultTdBox({ text: value.row.original.description }),
        Filter: TextInputFilter,
        filter: FILTER_RULE,
        minWidth: `${250 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.deposit`, { currency }),
        accessor: (values: TdDebitCardTransactionHistory) => values.deposit,
        Cell: (value: { row: { original: TdDebitCardTransactionHistory } }) =>
          NumberTdBox({ text: value.row.original.deposit }),
        Filter: TextInputFilter,
        filter: FILTER_RULE,
        minWidth: `${182 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.withdrawal`, { currency }),
        accessor: (values: TdDebitCardTransactionHistory) => values.withdrawal,
        Cell: (value: { row: { original: TdDebitCardTransactionHistory } }) =>
          NumberTdBox({ text: value.row.original.withdrawal }),
        Filter: TextInputFilter,
        filter: FILTER_RULE,
        minWidth: `${182 / 16}rem`,
      },
    ],
    // eslint-disable-next-line
    [],
  );

  return <TableFunctional data={data} columns={columns} width={1110} />;
};

export default TableDebitCardTransactionHistory;

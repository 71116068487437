import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Component
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import SavingsAccountCardList from '../../../organisms/list/SavingsAccountCardList';
import SavingsAccountsTemplateLayout from './SavingsAccountsTemplateLayout';

const TEMPLATE_ID = `templates.bankAccount.SavingsAccountsTemplate`;

const SavingsAccountsTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <SavingsAccountsTemplateLayout>
      <PageTitle key="title">{t(`${TEMPLATE_ID}.title`)}</PageTitle>
      <SavingsAccountCardList key="cardList" />
    </SavingsAccountsTemplateLayout>
  );
};
export default SavingsAccountsTemplate;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import TransferTemplateContainer from '../../common/TransferTemplateContainer';
import TransferTemplateContent from '../../common/TransferTemplateContent';
import Margin from '../../../../atoms/utils/layout/Margin';
import { FlexRow } from '../../../../atoms/utils/layout/Flex';

export interface TransferSavingsAccountTemplateLayoutProps {
  children: React.ReactElement[];
}

const TransferSavingsAccountTemplateLayout: FC<TransferSavingsAccountTemplateLayoutProps> = ({
  children,
}: TransferSavingsAccountTemplateLayoutProps) => {
  const elements = ['header', 'progressStep', 'form'];
  const [header, progressStep, form] = getElementsFromKeys(children, elements);

  return (
    <TransferTemplateContainer>
      {header}
      <Margin top={50}>
        <FlexRow justifyContent="center">{progressStep}</FlexRow>
      </Margin>
      <Margin top={100}>
        <TransferTemplateContent>{form}</TransferTemplateContent>
      </Margin>
    </TransferTemplateContainer>
  );
};
export default TransferSavingsAccountTemplateLayout;

import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  InviterRankAProps,
  InviterRankAPropsFormat,
} from './InviterRankA.type';

// DomainObject
import InviterRankA from './InviterRankA';

class InviterRankAFactory {
  static create = (id: string, props: InviterRankAProps) => {
    const result = Joi.object(InviterRankAPropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: InviterRankA.name,
          reason: result.error.details[0].message,
        },
      );

    return new InviterRankA(id, props);
  };
}

export default InviterRankAFactory;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { FormInputContractTimeDepositAdd } from '../../../../../components/organisms/form/contract/contractTimeDeposit/FormContractTimeDepositAdd/FormInputContractTimeDepositAdd.type';
import { INITIAL } from '../../../../../config/constants/requestState';
import SavingsAccount from '../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';
import TimeDeposit from '../../../../../domain/product/timeDeposit/TimeDeposit/TimeDeposit';

export interface ContractTimeDepositCreateContextProps {
  requestState: string;

  createContractTimeDeposit: (
    params: FormInputContractTimeDepositAdd,
    savingsAccount: SavingsAccount,
    timeDeposit: TimeDeposit,
    callback?: () => void,
  ) => void;

  executeCreateContractTimeDeposit: (
    code: string,
    callback: () => void,
  ) => Promise<void>;
}

const ContractTimeDepositCreateContext = React.createContext<
  ContractTimeDepositCreateContextProps
>({
  requestState: INITIAL,

  createContractTimeDeposit: (
    params: FormInputContractTimeDepositAdd,
    savingsAccount: SavingsAccount,
    timeDeposit: TimeDeposit,
    callback?: () => void,
  ) => undefined,

  executeCreateContractTimeDeposit: (code: string, callback: () => void) =>
    new Promise((resolve) => resolve()),
});

export default ContractTimeDepositCreateContext;

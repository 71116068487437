import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Type
import { KycStatusType } from '../../../../types/KycRequest.type';

// Style
import { HeadLineStyle } from './style';

// Component
import { TextPrimary } from '../../../atoms/text2/Text2';
import { RejectedReason } from '../../../molecules/label/LabeledText';
import KycStatusBadge from '../../../molecules/badge/KycStatusBadge';
import HeaderKycStatusLayout from './HeaderKycStatusLayout';

export interface HeaderKycStatusProps {
  status?: KycStatusType;
  rejectedReason?: string;
}

const ORGANISM_ID = `organisms.header.headerKycStatus`;

const HeaderKycStatus: FC<HeaderKycStatusProps> = ({
  status,
  rejectedReason,
}: HeaderKycStatusProps) => {
  const { t } = useTranslation();
  if (
    !status ||
    ![KycStatusType.submitted, KycStatusType.rejected].includes(status)
  )
    return <></>;

  return (
    <HeaderKycStatusLayout
      kycStatus={status as KycStatusType.submitted | KycStatusType.rejected}
    >
      <TextPrimary key="title" theme={HeadLineStyle}>
        {t(`${ORGANISM_ID}.label`)}
      </TextPrimary>
      <KycStatusBadge
        key="status"
        status={status}
        layout={{
          height: 36,
          iconWidth: 20,
          iconHeight: 20,
        }}
      />
      <RejectedReason
        key="rejectedReason"
        label={t(`${ORGANISM_ID}.rejectedReason`)}
        value={rejectedReason || ''}
        labelWidth={`${170 / 16}rem`}
      />
    </HeaderKycStatusLayout>
  );
};

export default HeaderKycStatus;

import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const ExampleImageBoxStyle = {
  backgroundColor: color.background.aliceBlue2,
};

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  lineHeight: 44,
};

export const TitleSmallStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

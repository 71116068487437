import React, { FC, useContext } from 'react';
import HTMLElementsAdjustContext from '../../../../enhancers/screen/HTMLElementsAdjustProvider/HTMLElementsAdjustContext';

// Type
import { FooterMenuType } from '../../../../config/menu/footer/Footer.type';

// Component
import LogoImage from '../../../atoms/image/LogoImage';
import MenuList from '../../list/MenuList';
import MenuItem from '../../../molecules/item/MenuItem';
import MenuSidebarLayout from './MenuSidebarLayout';

const MenuSidebar: FC = () => {
  const { element2 } = useContext(HTMLElementsAdjustContext);

  return (
    <MenuSidebarLayout divRef={element2.ref}>
      <LogoImage key="logo" height={30} logoType="textLogo" />
      <MenuList key="menuList" />
      <MenuItem key="signout" type={FooterMenuType.signOut} />
    </MenuSidebarLayout>
  );
};

export default MenuSidebar;

// React
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import CardIssuanceFeePaymentModalContext from './CardIssuanceFeePaymentModalContext';
import DebitCardRequestCherryVisa01PayIssuanceFeeContext from '../../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01PayIssuanceFeeProvider/DebitCardRequestCherryVisa01PayIssuanceFeeProvider/DebitCardRequestCherryVisa01PayIssuanceFeeContext';
import SavingsAccountContext from '../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccountContext';

// Constant
import { DEBIT_CARD_ISSUANCE_FEE } from '../../../../config/constants/business';
import { ROUTE_DEBIT_CARD } from '../../../../config/constants/routing';
import { IS_LOADING } from '../../../../config/constants/requestState';

// Style
import {
  CancelButtonTextStyle,
  contentStyle,
  ErrorMessageStyle,
  MessageStyle,
  overlayStyle,
  TitleStyle,
} from './style';

// Components
import { TextPrimary, TextSecondary } from '../../../atoms/text2/Text2';
import {
  CancelButton,
  DefaultButtonTextStyle,
  SubmitButton,
} from '../../../atoms/button/Button2';
import CircleSpinLoader from '../../../atoms/spinner/CircleSpinLoader';
import TableCardIssuanceFeePayment from '../../table/confirm/TableCardIssuanceFeePayment';
import CardIssuanceFeePaymentModalLayout from './CardIssuanceFeePaymentModalLayout';

const ORGANISM_ID = `organisms.modal.CardIssuanceFeePaymentModal`;

interface CardIssuanceFeePaymentModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any;
}

const CardIssuanceFeePaymentModal = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  history,
}: CardIssuanceFeePaymentModalProps) => {
  const { t } = useTranslation();
  const { hideModal } = useContext(CardIssuanceFeePaymentModalContext);

  const { savingsAccount } = useContext(SavingsAccountContext);
  const { state, submit } = useContext(
    DebitCardRequestCherryVisa01PayIssuanceFeeContext,
  );

  const balance = savingsAccount?.getProps().balance;

  const hasSufficientBalance = balance && balance >= DEBIT_CARD_ISSUANCE_FEE;

  return (
    <ReactModal
      isOpen={true}
      style={{ content: contentStyle, overlay: overlayStyle }}
      ariaHideApp={false}
    >
      <CardIssuanceFeePaymentModalLayout isLoading={!savingsAccount}>
        <TextPrimary key="title" theme={TitleStyle}>
          {t(`${ORGANISM_ID}.title`)}
        </TextPrimary>
        <TableCardIssuanceFeePayment key="table" />
        <CircleSpinLoader key="loader" />
        <TextSecondary
          key="message"
          theme={hasSufficientBalance ? MessageStyle : ErrorMessageStyle}
        >
          {t(
            hasSufficientBalance
              ? `${ORGANISM_ID}.message`
              : `${ORGANISM_ID}.errorMessage`,
          )}
        </TextSecondary>
        <SubmitButton
          key="submitButton"
          disabled={!hasSufficientBalance}
          isLoading={state === IS_LOADING}
          theme={{ borderRadius: 30 }}
          height={60}
          width={200}
          sizeProgress={20}
          onClick={() => {
            submit(() => {
              hideModal();
              history.push(ROUTE_DEBIT_CARD);
            });
          }}
        >
          <TextPrimary theme={DefaultButtonTextStyle}>
            {t(`atoms.button.yes`)}
          </TextPrimary>
        </SubmitButton>
        <CancelButton
          key="cancelButton"
          theme={{ borderRadius: 30 }}
          height={60}
          width={200}
          onClick={hideModal}
        >
          <TextPrimary theme={CancelButtonTextStyle}>
            {t(`atoms.button.cancel`)}
          </TextPrimary>
        </CancelButton>
      </CardIssuanceFeePaymentModalLayout>
    </ReactModal>
  );
};

export default CardIssuanceFeePaymentModal;

import React from 'react';
import UserVerified from './UserVerified';

export interface UserVerifiedContextProps {
  user?: UserVerified;
}

const UserVerifiedContext = React.createContext<UserVerifiedContextProps>({
  user: undefined,
});

export default UserVerifiedContext;

import CryptoCurrencySpotTransactions from './CryptoCurrencySpotTransactions';
import CryptoCurrencyDeposits from '../../CryptoCurrencyDeposit/CryptoCurrencyDeposits/CryptoCurrencyDeposits';
import CryptoCurrencyDeposit from '../../CryptoCurrencyDeposit/CryptoCurrencyDeposit/CryptoCurrencyDeposit';
import CryptoCurrencyWithdrawals from '../../CryptoCurrencyWithdrawal/CryptoCurrencyWithdrawals/CryptoCurrencyWithdrawals';
import CryptoCurrencyWithdrawal from '../../CryptoCurrencyWithdrawal/CryptoCurrencyWithdrawal/CryptoCurrencyWithdrawal';

class CryptoCurrencySpotTransactionsFactory {
  static createByMerge = (
    cryptoCurrencyDeposits: CryptoCurrencyDeposits,
    cryptoCurrencyWithdrawals: CryptoCurrencyWithdrawals,
  ) => {
    const cryptoCurrencySpotTransactions = new CryptoCurrencySpotTransactions();

    cryptoCurrencyDeposits.forEach(
      (cryptoCurrencyDeposit: CryptoCurrencyDeposit) => {
        cryptoCurrencySpotTransactions.add(cryptoCurrencyDeposit);
      },
    );

    cryptoCurrencyWithdrawals.forEach(
      (cryptoCurrencyWithdrawal: CryptoCurrencyWithdrawal) => {
        cryptoCurrencySpotTransactions.add(cryptoCurrencyWithdrawal);
      },
    );

    return cryptoCurrencySpotTransactions;
  };
}

export default CryptoCurrencySpotTransactionsFactory;

import { useState } from 'react';
import InvitationCodes from './InvitationCodes';

export const useInvitationCodes = (defaultValue?: InvitationCodes) => {
  const [invitationCodes, setInvitationCodes] = useState<InvitationCodes>(
    defaultValue || new InvitationCodes(),
  );

  return {
    invitationCodes,
    setInvitationCodes,
  };
};

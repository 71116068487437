import styled from 'styled-components';
import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-top: ${40 / 16}rem;
  padding-bottom: ${40 / 16}rem;
  border-radius: ${10 / 16}rem;
  background-color: #eff9ff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

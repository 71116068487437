import React, { FC } from 'react';

// Lib
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';
import { convertRem } from '../../../../../atoms/utils/helper/helper';

// Style
import { ErrorMessageContainer } from './style';

// LayoutComponent
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormInputTransactionAmountLayoutProps {
  children: React.ReactElement[];
  inputWidth: string | number;
}

const FormInputTransactionAmountLayout: FC<FormInputTransactionAmountLayoutProps> = (
  props: FormInputTransactionAmountLayoutProps,
) => {
  const { children, inputWidth } = props;
  const elements = ['label', 'currency', 'input', 'errorMessage'];
  const [label, currency, input, errorMessage] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn>
      <FlexBoxRow alignItems="center" justifyContent="space-between">
        {label}
        <FlexBoxRow alignItems="center" width={inputWidth}>
          {currency}
          <Margin right={25} />
          {input}
        </FlexBoxRow>
      </FlexBoxRow>
      <Margin top={10} />
      <FlexBoxRow alignItems="center" justifyContent="flex-end">
        <ErrorMessageContainer style={{ width: convertRem(inputWidth) }}>
          {errorMessage}
        </ErrorMessageContainer>
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
export default FormInputTransactionAmountLayout;

import firebase from '../client/firebaseClient';
import { executeWithTimeout } from '../../../utils/helpers/timeout';
import { FILE_UPLOAD_TIMEOUT } from '../../../config/constants/business';

const { auth } = firebase;

export default auth();

export const firebaseSignOut = () => {
  auth().signOut();
};

export const firebaseReauth = async (email: string, password: string) => {
  const cred = auth.EmailAuthProvider.credential(email, password);

  if (!auth().currentUser) throw new Error('auth/invalid-credential');

  const authUser = auth().currentUser as firebase.User;

  await executeWithTimeout(
    authUser.reauthenticateWithCredential(cred),
    FILE_UPLOAD_TIMEOUT / 2,
  );
};

export const reauthByToken = async (token: string) => {
  await auth().signInWithCustomToken(token);
};

export const firebaseUpdatePassword = async (newPassword: string) => {
  if (!auth().currentUser) throw new Error('auth/invalid-credential');

  const authUser = auth().currentUser as firebase.User;

  await executeWithTimeout(
    authUser.updatePassword(newPassword),
    FILE_UPLOAD_TIMEOUT / 2,
  );
};

export const getRefreshToken = async () => {
  const { currentUser } = auth();

  if (!currentUser) return 'noToken';

  const newToken = await currentUser.getIdToken(true);

  return newToken;
};

import ConsentFormStepStatus from '../ConsentFormStepStatus/ConsentFormStepStatus';
import { KycStatusType } from '../../../../../../types/KycRequest.type';

class StatusConsentForm extends ConsentFormStepStatus {
  // Review is done or not
  public isReviewed = () => this.isApproved() || this.isRejected();

  public submitConsentForm = () => {
    return new StatusConsentForm(KycStatusType.submitted);
  };
}
export default StatusConsentForm;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// LayoutComponent
import Margin from '../../../atoms/utils/layout/Margin';
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';

export interface KycRequestTemplateLayoutProps {
  children: React.ReactElement[];
}

const KycRequestTemplateLayout: FC<KycRequestTemplateLayoutProps> = ({
  children,
}: KycRequestTemplateLayoutProps) => {
  const elements = ['header', 'description', 'button'];
  const [header, description, button] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn
      alignItems="center"
      justifyContent="center"
      paddingTop={`${(138 / 890) * 100}vh`}
    >
      {header}
      <Margin top={60} />
      {description}
      <Margin top={90} />
      {button}
    </FlexBoxColumn>
  );
};
export default KycRequestTemplateLayout;

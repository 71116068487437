import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ForgotPasswordContext } from '../../../../../enhancers/front/ForgotPassword/ForgotPasswordContext';
import { forgotPasswordRegister } from '../../../../../utils/validation/registers';
import { deleteFullWidth } from '../../../../../utils/helpers/stringHelper';
import { IS_LOADING } from '../../../../../config/constants/requestState';
import { FORGOT_PASSWORD } from '../../../../../config/constants/pageId.json';
import fontColor from '../../../../../fonts/color.json';
import { Container, SubmitButtonContainer } from './style';
import TextField from '../../../../molecules/form/TextField/TextField';
import { SubmitButton } from '../../../../atoms/button/DomainButton';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface ForgotPasswordFormInput {
  email: string;
}
const ForgotPasswordForm: FC = () => {
  const { t } = useTranslation();
  const { forgotPasswordState, forgotPassword } = useContext(
    ForgotPasswordContext,
  );

  const {
    errors,
    formState: { isValid },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<ForgotPasswordFormInput>({
    mode: 'onChange',
  });

  return (
    <Container>
      <form
        onSubmit={handleSubmit((formInput) => {
          forgotPassword(formInput.email);
        })}
      >
        <TextField
          name="email"
          value={watch('email')}
          label={t(`${FORGOT_PASSWORD}.form.email.label`)}
          labelColor={fontColor.textDark}
          placeholder={t(`${FORGOT_PASSWORD}.form.email.placeholder`)}
          errorMessage={errors.email && errors.email.message}
          onChange={(text: string) => {
            setValue('email', deleteFullWidth(text));
          }}
          maxLength={255}
          register={register(forgotPasswordRegister('email', t))}
        />
        <Margin top={20} />
        <SubmitButtonContainer>
          <SubmitButton
            name={t(`atoms.button.submit`)}
            width={`${162 / 16}rem `}
            height={`${50 / 16}rem `}
            borderRadius={`${25 / 16}rem`}
            textSize={20}
            sizeProgress={`${20 / 16}rem`}
            disabled={!isValid}
            isLoading={forgotPasswordState === IS_LOADING}
          />
        </SubmitButtonContainer>
      </form>
    </Container>
  );
};

export default ForgotPasswordForm;

import styled from 'styled-components';
import { ColorTranslator } from 'colortranslator';
import fontWeight from '../const/fontWeight';
import { getLineHeight } from '../const/lineHeight';

export const Text = styled.span`
  font-size: ${(props) => `${props.theme.fontSize / 16}rem`};
  font-weight: ${(props) => props.theme.fontWeight};
  display: ${(props) => props.theme.display || 'inline'};
  color: ${(props) => {
    const rgb = new ColorTranslator(props.theme.color);
    return `rgb(${rgb.R}, ${rgb.G}, ${rgb.B}, ${props.theme.opacity || 1})`;
  }};
  text-align: ${(props) => props.theme.textAlign || 'left'};
  line-height: ${(props) => `${getLineHeight(props.theme.fontSize) / 16}rem`};
  letter-spacing: ${(props) => `${(props.theme.fontSize / 16) * 0.02}rem`};
`;

export const TextPrimary = styled(Text)`
  font-family: ${(props) => {
    return props.theme.fontWeight === fontWeight.bold
      ? 'PlayFairDisplayBold'
      : 'PlayFairDisplayRegular';
  }};
`;

export const TextSecondary = styled(Text)`
  font-family: ${(props) => {
    if (props.theme.fontWeight === fontWeight.light) return 'LatoLight';
    if (props.theme.fontWeight === fontWeight.regular) return 'LatoRegular';
    if (props.theme.fontWeight === fontWeight.medium) return 'LatoMedium';
    if (props.theme.fontWeight === fontWeight.semibold) return 'LatoSemibold';
    return 'Lato';
  }};
`;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface ManualListLayoutProps {
  children: React.ReactElement[];
}

const ManualListLayout: FC<ManualListLayoutProps> = ({
  children,
}: ManualListLayoutProps) => {
  const elements = ['icon', 'subTitle', 'list'];
  const [icon, subTitle, list] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn justifyContent="flex-start">
      <FlexBoxRow width="auto" alignItems="center">
        {icon}
        <Margin left={20} />
        {subTitle}
      </FlexBoxRow>
      <Margin top={20} />
      {list}
    </FlexBoxColumn>
  );
};
export default ManualListLayout;

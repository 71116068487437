// React
import { UseStateType } from '../../../../types/typeof/UseState';

// IService
import IBitcoinWalletQueryService from '../../../../useCases/cryptoCurrency/cryptoCurrencyWallet/interface/IBitcoinWalletQueryService';

// IRepository
import IBitcoinWalletRepository from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/BitcoinWallet/IBitcoinWalletRepository';

// DomainObject
import BitcoinWallet from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/BitcoinWallet/BitcoinWallet/BitcoinWallet';

class BitcoinWalletQueryService implements IBitcoinWalletQueryService {
  private bitcoinWalletRepository: IBitcoinWalletRepository;

  constructor(bitcoinWalletRepository: IBitcoinWalletRepository) {
    this.bitcoinWalletRepository = bitcoinWalletRepository;
  }

  public findMainWallet = async (userId: string) => {
    const wallets = await this.bitcoinWalletRepository.findByUserId(userId);

    return wallets.head();
  };

  public subscribeMainWallet = (
    userId: string,
    setter: UseStateType<BitcoinWallet | undefined>,
    setState: UseStateType<string>,
  ) => {
    this.bitcoinWalletRepository.subscribeByUserId(userId, setter, setState);
  };
}
export default BitcoinWalletQueryService;

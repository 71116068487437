import { useState } from 'react';

// Domain Object
import DebitCardRequest from './DebitCardRequest';

export const useDebitCardRequest = (initial?: DebitCardRequest) => {
  const [debitCardRequest, setDebitCardRequest] = useState<
    DebitCardRequest | undefined
  >(initial);

  return {
    debitCardRequest,
    setDebitCardRequest,
  };
};

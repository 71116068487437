import React, { FC } from 'react';
import { useTransferDebitCardCancelUseCase } from 'enhancers/useCase/transfer/debitCard/TransferDebitCardCancelProvider/useTransferDebitCardCancelUseCase';
import TransferDebitCardCancelContext from 'enhancers/useCase/transfer/debitCard/TransferDebitCardCancelProvider/TransferDebitCardCancelContext';

// Error
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';

export type TransferDebitCardCreateProviderProps = {
  children: React.ReactNode;
};

const TransferDebitCardCancelProvider: FC<TransferDebitCardCreateProviderProps> = ({
  children,
}: TransferDebitCardCreateProviderProps) => {
  const { state, setState, cancelTopUp } = useTransferDebitCardCancelUseCase();

  return (
    <TransferDebitCardCancelContext.Provider
      value={{
        state,
        cancelTopUp,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </TransferDebitCardCancelContext.Provider>
  );
};
export default TransferDebitCardCancelProvider;

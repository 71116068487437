// Type
import { FiatCurrencyType } from '../../../../../types/FiatCurrency.type';
import { BankTransactionProps } from './BankTransaction.type';

// DomainObject
import BankTransactionId from '../../../../idManager/transactionId/BankTransactionId/BankTransactionId';
import SavingsAccountNumber from '../../../../../value/id/SavingsAccountNumber';
import Transaction from '../../../transaction/Transaction/Transaction';

abstract class BankTransaction extends Transaction {
  protected savingsAccountNumber: SavingsAccountNumber;

  protected amount: number;

  protected currency: FiatCurrencyType;

  protected descriptionParameter: string;

  protected createdAt: number;

  protected updatedAt: number;

  protected props: BankTransactionProps;

  constructor(
    bankTransactionId: BankTransactionId,
    props: BankTransactionProps,
  ) {
    super(bankTransactionId);
    this.props = props;
    this.savingsAccountNumber = new SavingsAccountNumber(
      props.savingsAccountNumber,
    );
    this.amount = props.amount;
    this.currency = props.currency;
    this.descriptionParameter = props.descriptionParameter;
    this.createdAt = props.createdAt || Date.now();
    this.updatedAt = props.updatedAt || Date.now();
  }

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public getCommandParameter() {
    return {
      amount: this.amount,
      commonId: this.transactionId.getCommonId(),
      currency: this.currency,
      descriptionParameter: this.descriptionParameter,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public getSavingsAccountNumber = () => this.savingsAccountNumber;

  public getRawSavingsAccountNumber = () =>
    this.savingsAccountNumber.getAccountNumber();

  public getHalfFacedNumber = () =>
    this.savingsAccountNumber.getHalfFacedNumber();

  public getAmount = () => this.amount;
}
export default BankTransaction;

import Joi from '@hapi/joi';

// Type
import { Gender, KycStatusType } from '../../../../../types/KycRequest.type';
import {
  KycRequestContentProps,
  KycRequestContentPropsFormat,
} from '../KycRequestContent/KycRequestContent.type';

export interface PersonalInformationUserInput extends KycRequestContentProps {
  firstName: string;

  middleName?: string;

  lastName: string;

  fullName?: string;

  gender: Gender;

  dateOfBirth: string;

  passport: {
    passportNo: string;
    expiredAt: string;
    passportUrl: string;
  };

  selfieUrl: string;
}

export const PersonalInformationPropsFormat = {
  ...KycRequestContentPropsFormat,

  firstName: Joi.string().allow(''),

  middleName: Joi.string().empty(''),

  lastName: Joi.string().allow(''),

  fullName: Joi.string().empty(''),

  gender: Object.keys(Gender)
    .reduce((joi, gender) => joi.valid(gender), Joi.string())
    .allow(''),

  dateOfBirth: Joi.string()
    .pattern(/[0-9]{4}\/[0-9]{2}\/[0-9]{2}/)
    .allow(''),

  passport: {
    passportNo: Joi.string().allow(''),
    expiredAt: Joi.string()
      .pattern(/[0-9]{4}\/[0-9]{2}\/[0-9]{2}/)
      .allow(''),
    passportUrl: Joi.string().allow(''),
  },

  selfieUrl: Joi.string().allow(''),
};

export type PersonalInformationProps = PersonalInformationUserInput &
  KycRequestContentProps;

export const PersonalInformationInitialProps = {
  firstName: '',

  lastName: '',

  gender: Gender.male,

  dateOfBirth: '',

  passport: {
    passportNo: '',

    expiredAt: '',

    passportUrl: '',
  },

  selfieUrl: '',

  status: KycStatusType.disabled,
};

// Types
import { CryptoCurrencyType } from '../../../types/CryptoCurrency.type';
import { UseStateType } from '../../../types/typeof/UseState';

// IService
import IAssetCryptoCurrencyService from '../../../useCases/asset/assetSummary/IAssetCryptoCurrencyQueryService';

// IRepository
import IAssetCryptoCurrencyRepository from '../../../domain/asset/assetCryptoCurrency/IAssetCryptoCurrencyRepository';

// DomainObject
import AssetsCryptoCurrency from '../../../domain/asset/assetCryptoCurrency/AssetsCryptoCurrency/AssetsCryptoCurrency';

class AssetCryptoCurrencyQueryService implements IAssetCryptoCurrencyService {
  private assetCryptoCurrencyRepository: IAssetCryptoCurrencyRepository;

  constructor(assetCryptoCurrencyRepository: IAssetCryptoCurrencyRepository) {
    this.assetCryptoCurrencyRepository = assetCryptoCurrencyRepository;
  }

  public setByUserId = async (
    userId: string,
    setter: UseStateType<AssetsCryptoCurrency>,
    setState: UseStateType<string>,
  ) => {
    this.assetCryptoCurrencyRepository.subscribeByUserId(
      userId,
      setter,
      setState,
    );
  };

  public findUserCurrencyAsset = async (
    userId: string,
    cryptoCurrency: CryptoCurrencyType,
  ) => {
    const assetCryptoCurrency = this.assetCryptoCurrencyRepository.findByPrimaryKey(
      {
        userId,
        currency: cryptoCurrency,
      },
    );

    return assetCryptoCurrency;
  };
}
export default AssetCryptoCurrencyQueryService;

import React, { FC } from 'react';

// Type
import { CurrencyType } from '../../../../../types/Currency.type';

// Component
import TrTableVerticalCurrency from '../../tr/TrTableVerticalCurrency';
import Table from '../../../../atoms/table/Table/Table';

export interface TableVerticalCurrencyProps {
  layout: {
    row: {
      width: string | number;
      height: string | number;
    };
    th: {
      width: string | number;
      padding?: string;
    };
    tdCurrency: {
      width: string | number;
      padding?: string;
    };
    tdAmount: {
      width: string | number;
      padding?: string;
    };
  };
  rows: { header: string; currency?: CurrencyType; data: string }[];
  theme: {
    th: {
      backgroundColor: string;
      borderBottom?: string;
      textAlign?: string;
    };
    tdCurrency: {
      backgroundColor: string;
      borderBottom?: string;
      textAlign?: string;
    };
    tdAmount: {
      backgroundColor: string;
      borderBottom?: string;
      textAlign?: string;
    };
  };
}

const TableVerticalCurrency: FC<TableVerticalCurrencyProps> = ({
  layout,
  rows,
  theme,
}: TableVerticalCurrencyProps) => {
  return (
    <Table width={layout.row.width}>
      <tbody style={{ width: '100%' }}>
        {rows.map((row, i) => (
          <TrTableVerticalCurrency
            key={`item${i}`}
            currency={row.currency}
            data={row.data}
            header={row.header}
            rowHeight={layout.row.height}
            theme={theme}
            layout={layout}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default TableVerticalCurrency;

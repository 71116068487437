import { useState } from 'react';
import TimeDeposit from './TimeDeposit';

export const useTimeDeposit = (defaultValue?: TimeDeposit) => {
  const [timeDeposit, setTimeDeposit] = useState<TimeDeposit | undefined>(
    defaultValue,
  );

  return {
    timeDeposit,
    setTimeDeposit,
  };
};

import React, { FC } from 'react';
import TwoFactorAuthAppSettingsPage from './TwoFactorAuthAppSettingsPage';
import TwoFactorAuthAppSettingsViewProvider from '../../../enhancers/useCase/verification/consentForm/TwoFactorAuthAppSettingsViewProvider';
import TwoFactorAuthAppSettingsSubmitProvider from '../../../enhancers/useCase/verification/consentForm/TwoFactorAuthAppSettingsSubmitProvider';

const EnhancedTwoFactorAuthAppSettingsPage: FC = () => {
  return (
    <TwoFactorAuthAppSettingsViewProvider>
      <TwoFactorAuthAppSettingsSubmitProvider>
        <TwoFactorAuthAppSettingsPage />
      </TwoFactorAuthAppSettingsSubmitProvider>
    </TwoFactorAuthAppSettingsViewProvider>
  );
};

export default EnhancedTwoFactorAuthAppSettingsPage;

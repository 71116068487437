// Type
import { CryptoCurrencyType } from '../../../types/CryptoCurrency.type';
import { CurrencyType } from '../../../types/Currency.type';
import { CurrencyPairFactory } from '../../../types/CurrencyPair.type';
import { UseStateType } from '../../../types/typeof/UseState';

// IUseCase
import ICryptoCurrencyRateFetchUseCase from '../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyRateFetchProvider/ICryptoCurrencyRateFetchUseCase';

// IService
import ICryptoCurrencyRateQueryService from './ICryptoCurrencyRateQueryService';
import ICryptoCurrencyTradeFeeRateQueryService from '../../settings/ICryptoCurrencyTradeFeeRateQueryService';

// Service
import CryptoCurrencyRateQueryService from '../../../service/cryptocurrency/cryptoCurrencyRate/CryptoCurrencyRateQueryService';
import CryptoCurrencyTradeFeeRateQueryService from '../../../service/settings/CryptoCurrencyTradeFeeRateQueryService';

// IRepository
import ICryptoCurrencyTradeFeeRateRepository from '../../../domain/settings/cryptoCurrencyTradeFeeRate/ICryptoCurrencyTradeFeeRateRepository';

// DomainObject
import CryptoCurrencyTradeFeeRate from '../../../domain/settings/cryptoCurrencyTradeFeeRate/CryptoCurrencyTradeFeeRate/CryptoCurrencyTradeFeeRate';
import ICryptoCurrencyRateRepository from '../../../domain/cryptoCurrency/cryptoCurrencyRate/CryptoCurrencyRate/ICryptoCurrencyRateRepository';

class CryptoCurrencyRateFetchUseCase
  implements ICryptoCurrencyRateFetchUseCase {
  private cryptoCurrencyRateQueryService: ICryptoCurrencyRateQueryService;

  private cryptoCurrencyTradeFeeRateQueryService: ICryptoCurrencyTradeFeeRateQueryService;

  constructor(
    cryptoCurrencyTradeFeeRateRepository: ICryptoCurrencyTradeFeeRateRepository,
    cryptoCurrencyRateRepository: ICryptoCurrencyRateRepository,
  ) {
    this.cryptoCurrencyRateQueryService = new CryptoCurrencyRateQueryService(
      cryptoCurrencyRateRepository,
    );

    this.cryptoCurrencyTradeFeeRateQueryService = new CryptoCurrencyTradeFeeRateQueryService(
      cryptoCurrencyTradeFeeRateRepository,
    );
  }

  public getTradeFeeRate = async (
    targetCurrency: CryptoCurrencyType,
    baseCurrency: CurrencyType,
    setter: UseStateType<CryptoCurrencyTradeFeeRate | undefined>,
    setState: UseStateType<string>,
  ) => {
    const currencyPair = CurrencyPairFactory.create(
      baseCurrency,
      targetCurrency,
    );

    await this.cryptoCurrencyTradeFeeRateQueryService.findById(
      currencyPair,
      setter,
      setState,
    );
  };

  public getTradeRate = async (
    cryptoCurrencyTradeFeeRate: CryptoCurrencyTradeFeeRate,
  ) => {
    const cryptoCurrencyRate = await this.cryptoCurrencyRateQueryService.getCurrencyRate(
      cryptoCurrencyTradeFeeRate,
    );

    return { cryptoCurrencyRate };
  };
}
export default CryptoCurrencyRateFetchUseCase;

import React, { FC, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import PersonalInformationFileContext from '../../../../../../../enhancers/fileHandler/verification/kycRequest/PersonalInformationFileProvider/PersonalInformationFileContext';
import UserAuthenticatedContext from '../../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// CloudStorage
import { useStorageImage } from '../../../../../../../infrastructure/firebase/cloudStorage/useStorageImage';
import { getUploadFileUrl } from '../../../../../../../utils/helpers/fileHelper';

// Type
import { FileType } from '../../../../../../../types/File.type';
import { KycRequestUploadFileType } from '../../../../../../../types/KycRequest.type';

// Style
import { DescriptionStyle, TitleSmallStyle } from './style';

// Component
import { TextSecondary } from '../../../../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../../../../atoms/text2/LineBreakReplaced';
import CheckPointList from '../../../../../list/CheckPointList';
import DropzoneArea from '../../../../../../molecules/dropzone2/DropzoneArea';
import ExampleImage from '../../../../../../atoms/image/ExampleImage';
import KycFormContainer from '../../../../common/KycFormContainer';
import FormPassportUploadLayout from './FormPassportUploadLayout';

// ValueObject
import PersonalInformation from '../../../../../../../value/verification/kycRequest/KycRequestContent/PersonalInformation/PersonalInformation';

const FORM_ID = `organisms.form.verification.FormPersonalInformation.passportUpload`;

export interface PassportUploadFormProps {
  personalInformation?: PersonalInformation;
}

const PassportUploadForm: FC<PassportUploadFormProps> = ({
  personalInformation,
}: PassportUploadFormProps) => {
  const { t } = useTranslation();
  const { userAuthorized } = useContext(UserAuthenticatedContext);

  // Form
  const { setValue } = useFormContext();

  // FileHandler
  const { passport, setPassport } = useContext(PersonalInformationFileContext);

  // Storage
  const passportUrl = useStorageImage(
    personalInformation?.getProps().passport.passportUrl,
  ).storageUrl;

  /* *
   *
   *  UseEffect
   *
   * */

  // Set passportUrl when File is uploaded
  useEffect(() => {
    if (passport && userAuthorized) {
      const storageFileUrl = getUploadFileUrl(
        userAuthorized,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (passport as any).path,
        KycRequestUploadFileType.passport,
      );
      setValue('passportUrl', storageFileUrl);
    }
    // eslint-disable-next-line
  }, [passport]);

  return (
    <KycFormContainer order={3} formId={FORM_ID}>
      <FormPassportUploadLayout>
        <TextSecondary key="description" theme={DescriptionStyle}>
          <LineBreakReplaced text={t(`${FORM_ID}.description`)} />
        </TextSecondary>
        <DropzoneArea
          key="dropzone"
          name="passport"
          acceptFileTypes={[FileType.png, FileType.jpeg]}
          defaultFilePath={passportUrl || ''}
          maxFileSize={2}
          setFile={setPassport}
          width={667}
        />
        <TextSecondary key="exampleTitle" theme={TitleSmallStyle}>
          {t(`words.Example`)}
        </TextSecondary>
        <ExampleImage key="exampleImage" type="passport" />
        <CheckPointList
          key="checkPoint"
          title={t(`organisms.CheckPointList.title`)}
          checkPoints={[
            t(`organisms.CheckPointList.text.complete`),
            t(`organisms.CheckPointList.text.clear`),
            t(`organisms.CheckPointList.text.valid`),
          ]}
        />
      </FormPassportUploadLayout>
    </KycFormContainer>
  );
};

export default PassportUploadForm;

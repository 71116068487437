import React, { FC, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import AuthenticatorContext from '../../../../domain/authenticator/authenticator/Authenticator/AuthenticatorContext';
import ReregistrationContext from './ReregisterContext';
import ReregistrationPageStateContext from '../../../pageState/authentication/ReregistrationPageState/ReregistrationPageStateContext';

import UserReregistrationContext from '../../../../domain/public/user/User/UserReregistration/UserReregistrationContext';

// Type
import { GlobalStateType } from '../../../../redux/store';

// Constant
import { ErrorCodeType } from '../../../../utils/errors/ErrorHandler/ErrorCode.type';
import {
  ROUTE_AUTH_ERROR,
  ROUTE_ASSET_SUMMARY,
} from '../../../../config/constants/routing';

// Hook
import { useReregisterUseCase } from './useReregisterUseCase';

// Component
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type ReregistrationProviderProps = {
  children: React.ReactNode;
};

const ReregistrationProvider: FC<ReregistrationProviderProps> = ({
  children,
}: ReregistrationProviderProps) => {
  const { oobCode } = useParams<{ oobCode: string }>();
  const { user } = useContext(UserReregistrationContext);
  const { pageState } = useContext(ReregistrationPageStateContext);
  const {
    authenticator,
    createAuthenticator,
    reregister,
    setState,
    state,
  } = useReregisterUseCase(oobCode, user);

  const history = useHistory();

  const signInUser = useSelector(
    (globalState: GlobalStateType) => globalState.signInUserStore,
  );

  // 401 Error Redirect
  useEffect(() => {
    if (state === ErrorCodeType.AUTHENTICATION_RE_SIGN_UP_REQUEST_EXPIRED)
      history.replace(ROUTE_AUTH_ERROR);
  }, [state, history]);

  // SUCCESS
  useEffect(() => {
    if (!isEmpty(signInUser.id) && pageState === 'complete')
      history.replace(ROUTE_ASSET_SUMMARY);
  }, [signInUser, history, pageState]);

  return (
    <AuthenticatorContext.Provider value={{ authenticator }}>
      <ReregistrationContext.Provider
        value={{ createAuthenticator, reregister, state }}
      >
        <SystemErrorHandler state={state} setState={setState}>
          {children}
        </SystemErrorHandler>
      </ReregistrationContext.Provider>
    </AuthenticatorContext.Provider>
  );
};
export default ReregistrationProvider;

import Joi from '@hapi/joi';
import { CurrencyPairType } from '../../../../../types/CurrencyPair.type';

export interface CryptoCurrencyRateProps {
  buyRate: {
    original: number;
    feeIncluded: number;
  };

  expiredAt: number;

  last: number;

  sellRate: {
    original: number;
    feeIncluded: number;
  };

  tradeFeeRate: number;
}

export const CryptoCurrencyRatePropsFormat = {
  id: Object.keys(CurrencyPairType)
    .reduce((joi, currencyPair) => joi.valid(currencyPair), Joi.string())
    .required(),

  buyRate: Joi.object({
    original: Joi.number().greater(0).required(),
    feeIncluded: Joi.number().greater(0).required(),
  }),

  last: Joi.number().min(0).required(),

  sellRate: Joi.object({
    original: Joi.number().greater(0).required(),
    feeIncluded: Joi.number().greater(0).required(),
  }),

  tradeFeeRate: Joi.number().required(),

  // >= 1/Jan/2021 00:00:00
  expiredAt: Joi.number().min(1609426800).required(),
};

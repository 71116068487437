import { useState } from 'react';
import ContractsTimeDepositHistories from './ContractTimeDepositHistories';

export const useContractTimeDepositHistories = (
  defaultValue?: ContractsTimeDepositHistories,
) => {
  const [
    contractTimeDepositHistories,
    setContractTimeDepositHistories,
  ] = useState<ContractsTimeDepositHistories>(
    defaultValue || new ContractsTimeDepositHistories(),
  );

  return {
    contractTimeDepositHistories,
    setContractTimeDepositHistories,
  };
};

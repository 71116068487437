import color from 'components/atoms/utils/const/color';
import fontWeight from 'components/atoms/utils/const/fontWeight';

export const CancelButtonStyle = {
  backgroundColor: `${color.background.endeavour} !important`,
  borderRadius: 5,
};

export const TextStyle = {
  color: color.text.white,
  fontSize: 16,
  fontWeight: fontWeight.regular,
};

// Type
import { TypeOfT } from '../../../../../types/typeof/Translation.type';

// DomainObject
import ReferralTimeDeposit from '../../../../../domain/invite/referralTimeDeposit/referralTimeDeposit/ReferralTimeDeposit/ReferralTimeDeposit';
import ReferralsTimeDeposit from '../../../../../domain/invite/referralTimeDeposit/referralTimeDeposit/ReferralsTimeDeposit/ReferralsTimeDeposit';

// Value
import TimeZonedTimeFactory from '../../../../../value/datetime/TimeZonedTimeFactory';
import { FiatCurrencyType } from '../../../../../types/FiatCurrency.type';

type Object = {
  [key: string]: string | number | undefined;
};

export interface TdReferralsTimeDeposit extends Object {
  id: string;
  username: string;
  affiliatorName: string | undefined;
  depositAmount: number;
  interestRate: number;
  depositDate: string;
  term: number;
  commissionTotal: number;
  commissionPaid: number;
  countOfMaturity: number;
  currency: FiatCurrencyType;
}

class TableReferralsTimeDepositMapper {
  static domainToTd = (
    referralsTimeDeposit: ReferralsTimeDeposit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    t: TypeOfT,
  ) => {
    const converter = (referralTimeDeposit: ReferralTimeDeposit) => {
      const id = referralTimeDeposit.getFormattedId();
      const props = referralTimeDeposit.getProps();

      const affiliatorName = referralTimeDeposit.getAffiliatorContractName();
      const depositDate = TimeZonedTimeFactory.create(props.createdAt).format(
        'DD/MMM/YYYY',
      );

      return {
        ...props,
        id,
        username: props.userContractName,
        currency: props.depositCurrency,
        affiliatorName,
        depositDate,
      } as TdReferralsTimeDeposit;
    };

    return referralsTimeDeposit.map<TdReferralsTimeDeposit>(converter);
  };
}

export default TableReferralsTimeDepositMapper;

import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import { BankDepositProps, BankDepositPropsFormat } from './BankDeposit.type';

// DomainObject
import BankDeposit from './BankDeposit';
import BankTransactionId from '../../../../../idManager/transactionId/BankTransactionId/BankTransactionId';

class BankDepositFactory {
  static create = (id: BankTransactionId, props: BankDepositProps) => {
    const result = Joi.object(BankDepositPropsFormat).validate(props);

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: BankDeposit.name,
          reason: result.error.details[0].message,
        },
      );

    return new BankDeposit(id, props);
  };
}
export default BankDepositFactory;

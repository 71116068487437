/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export interface SignInTimeOutContextProps {
  remainingTime: number;
  refreshSignInTimeOut: () => void;
}

const SignInTimeOutContext = React.createContext<SignInTimeOutContextProps>({
  remainingTime: 0,
  refreshSignInTimeOut: () => undefined,
});

export default SignInTimeOutContext;

import styled from 'styled-components';
import color from '../../../atoms/utils/const/color';

export const Container = styled.div`
  box-sizing: border-box;
  position: relative;

  padding-left: ${330 / 16}rem;
  min-height: 100vh;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media print {
    padding-left: 0;
  }
`;

export const MenuBackBoard = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${color.background.primary};
  width: ${330 / 16}rem;
  height: 100%;

  @media print {
    background: none;
  }
`;

export const RightContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f0f1f7;
  @media print {
    background: none;
  }
`;

export const Main = styled.div`
  box-sizing: border-box;
  min-width: 100%;
`;

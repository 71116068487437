import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';

// Style
import { ContainerStyle } from './style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface CryptoCurrencyRatePriceCardLayoutProps {
  children: React.ReactElement[];
  borderBottomColor: string;
}

const CryptoCurrencyRatePriceCardLayout: FC<CryptoCurrencyRatePriceCardLayoutProps> = ({
  children,
  borderBottomColor,
}: CryptoCurrencyRatePriceCardLayoutProps) => {
  const elements = ['label', 'currency', 'price'];

  const [label, currency, price] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn
      alignItems="center"
      width={470}
      height={197}
      padding={45}
      theme={ContainerStyle}
      style={{
        borderBottom: `${5 / 16}rem solid ${borderBottomColor}`,
      }}
    >
      {label}
      <Margin top={20} />
      <FlexBoxRow justifyContent="center" alignItems="center">
        {currency}
        <Margin left={10} />
        {price}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};

export default CryptoCurrencyRatePriceCardLayout;

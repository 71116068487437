import React, { FC, useContext } from 'react';
import TransferDebitCardTypeOptionContext from './TransferDebitCardTypeOptionContext';
import { convertRem } from '../../../../atoms/utils/helper/helper';

// Constant
import TransferDebitCardTab from '../../../../../config/menu/tab/TransferDebitCardTab';

// Style
import { Container, SelectedStyle, TextStyle, UnSelectedStyle } from './style';

// Component
import TransferDebitCardTypeTabItem from '../../../item/TabItem/TransferDebitCardTypeTabItem';

const TransferDebitCardTabBar: FC = () => {
  const { transferTypeOption, setTransferTypeOption } = useContext(
    TransferDebitCardTypeOptionContext,
  );

  return (
    <Container style={{ height: convertRem(80) }}>
      {TransferDebitCardTab.map((item, i) => {
        return (
          <TransferDebitCardTypeTabItem
            key={`items${i}`}
            item={item}
            onClick={setTransferTypeOption}
            selected={transferTypeOption}
            theme={{
              text: TextStyle,
              selected: SelectedStyle,
              unSelected: UnSelectedStyle,
            }}
            width={'50%'}
          />
        );
      })}
    </Container>
  );
};

export default TransferDebitCardTabBar;

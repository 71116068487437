import React, { FC } from 'react';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

// Constant
import color from '../../../atoms/utils/const/color';

// Style
import { TitleStyle, DescriptionStyle, TitleMiddleStyle } from './style';

// Component
import { TextPrimary, TextSecondary } from '../../../atoms/text2/Text2';
import NotificationImportantLayout from './NotificationImportantLayout';
import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';

const ORGANISM_ID = `organisms.notification.NotificationImportant`;

const NotificationImportant: FC = () => {
  const { t } = useTranslation();

  return (
    <NotificationImportantLayout>
      <BsExclamationCircleFill
        key="icon"
        size={`${66 / 16}rem`}
        color={color.icon.froly}
      />
      <TextPrimary key="title" theme={TitleStyle}>
        {t(`${ORGANISM_ID}.title`)}
      </TextPrimary>
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${ORGANISM_ID}.description`)}
      </TextSecondary>
      <TextPrimary key="custodyTitle" theme={TitleMiddleStyle}>
        {t(`${ORGANISM_ID}.custodyTitle`)}
      </TextPrimary>
      <TextSecondary key="custodyDescription" theme={DescriptionStyle}>
        {t(`${ORGANISM_ID}.custodyDescription`)}
      </TextSecondary>
      <TextPrimary key="topUpFeeTitle" theme={TitleMiddleStyle}>
        {t(`${ORGANISM_ID}.topUpFeeTitle`)}
      </TextPrimary>
      <TextSecondary key="topUpFeeDescription" theme={DescriptionStyle}>
        <LineBreakReplaced text={t(`${ORGANISM_ID}.topUpFeeDescription`)} />
      </TextSecondary>
    </NotificationImportantLayout>
  );
};

export default NotificationImportant;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import KycRequestCorporateContext from '../../../../domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporateContext';

// Component
import FormCorporateAddress from '../../../organisms/form/verification/kycRequest/FormCorporateAddress';
import KycStepTemplate from '../KycStepTemplate';

const TEMPLATE_ID = `templates.verification.CorporateAddressTemplate`;

const CorporateAddressTemplate: FC = () => {
  const { t } = useTranslation();
  const { kycRequest } = useContext(KycRequestCorporateContext);

  // First Level Property
  const corporateAddress = kycRequest?.getCorporateAddress();
  const kycStepStatus = kycRequest?.getKycStepStatus();

  // Second Level Property
  const rejectedReason = corporateAddress?.getProps().rejectedReason;
  const status = kycStepStatus?.getProps().corporateAddress;

  return (
    <KycStepTemplate
      status={status}
      title={t(`${TEMPLATE_ID}.title`)}
      rejectedReason={rejectedReason}
    >
      <FormCorporateAddress corporateAddress={corporateAddress} />
    </KycStepTemplate>
  );
};

export default CorporateAddressTemplate;

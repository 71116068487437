import React, { FC } from 'react';
import Loader from 'react-loader-spinner';
import { Container, MessageContainer } from './style';
import TextFonted from '../../text/TextFonted';

export interface TextSpinLoaderProps {
  text: string;
  color: string;
  textSize: number;
  textFontWeight: number;
  textFontFamilyType?: 'primary' | 'sub';
  spinnerSize: number;
}

const TextSpinLoader: FC<TextSpinLoaderProps> = ({
  text,
  color,
  textSize,
  textFontWeight,
  textFontFamilyType = 'sub',
  spinnerSize,
}: TextSpinLoaderProps) => {
  return (
    <Container>
      <MessageContainer>
        <TextFonted
          pixel={textSize}
          color={color}
          fontWeight={textFontWeight}
          fontFamilyType={textFontFamilyType}
        >
          {text}
        </TextFonted>
      </MessageContainer>
      <Loader
        type="TailSpin"
        color={color}
        width={spinnerSize}
        height={spinnerSize}
      />
    </Container>
  );
};
export default TextSpinLoader;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TransferSavingsAccountTypeOptionContext from '../../../../selectBox/option/option/TransferSavingsAccountTypeOption/TransferSavingsAccountTypeOptionContext';

// Type
import { TransferSavingsAccountType } from '../../../../../../types/Transfer.type';

// Style
import { LabelStyle } from './style';

// Component
import { TextPrimary } from '../../../../../atoms/text2/Text2';
import FormInputTransferForbesAccount from '../FormInputTransferForbesAccount';
import FormInputTransferSavingsAccountDetailLayout from './FormInputTransferSavingsAccountDetailLayout';

// DomainObject
import SavingsAccount from '../../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

const MOLECULE_ID = `molecules.formInputGroup.formInputTransferSavingsAccountDetail`;

export interface FormInputTransferSavingsAccountDetailProps {
  savingsAccount?: SavingsAccount;
}

const FormInputTransferSavingsAccountDetail: FC<FormInputTransferSavingsAccountDetailProps> = ({
  savingsAccount,
}: FormInputTransferSavingsAccountDetailProps) => {
  const { t } = useTranslation();
  const { transferSavingsAccountTypeOption } = useContext(
    TransferSavingsAccountTypeOptionContext,
  );

  return (
    <FormInputTransferSavingsAccountDetailLayout>
      <TextPrimary key="header" theme={LabelStyle}>
        {t(`${MOLECULE_ID}.header`)}
      </TextPrimary>
      <div key="inputGroup">
        {(() => {
          switch (transferSavingsAccountTypeOption) {
            case TransferSavingsAccountType.forbes:
              return (
                <FormInputTransferForbesAccount
                  savingsAccount={savingsAccount}
                />
              );
            case TransferSavingsAccountType.ecb:
              return (
                <FormInputTransferForbesAccount
                  savingsAccount={savingsAccount}
                />
              );
            default:
              throw new Error(
                `Unknown ${transferSavingsAccountTypeOption} is found at ${FormInputTransferSavingsAccountDetail}`,
              );
          }
        })()}
      </div>
    </FormInputTransferSavingsAccountDetailLayout>
  );
};

export default FormInputTransferSavingsAccountDetail;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import ResignUpContext from '../../../../../enhancers/useCase/authentication/ResignUpProvider/ResignUpContext';
import LinksSignUpContext from '../../../../../enhancers/storage/context/LinksSignUpContext';

// Lib
import { deleteFullWidth } from '../../../../../utils/helpers/stringHelper';
import { resignUpRegister } from '../../../../../utils/validation/registers';

// Constant
import { ErrorCodeType } from '../../../../../utils/errors/ErrorHandler/ErrorCode.type';

// Constant
import { RE_SIGN_UP } from '../../../../../config/constants/pageId.json';
import { IS_LOADING } from '../../../../../config/constants/requestState';

// Style
import {
  LabelStyle,
  CheckBoxStyle,
  CheckboxTextStyle,
  CheckboxLinkStyle,
} from './style';

// Component
import FormInputTextLabelColumn from '../../../../molecules/form/formInput/textField/FormInputTextLabelColumn';
import Checkbox from '../../../../atoms/checkbox/Checkbox';
import LinkTextSentence from '../../../../molecules/link/LinkTextSentence';
import { TextPrimary } from '../../../../atoms/text2/Text2';
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../atoms/button/Button2';
import FormResignUpLayout from './FormResignUpLayout';

export interface FormResignUpInput {
  email: string;
  reregistrationCode: string;
  agreed: boolean;
}

const FormResignUp: FC = () => {
  const { t } = useTranslation();
  const { state, resignUp } = useContext(ResignUpContext);
  const { termsAndConditionsUrl, privacyPolicyUrl } = useContext(
    LinksSignUpContext,
  );

  const methods = useForm<
    FormResignUpInput & {
      emailClientError: string;
      reregistrationCodeClientError: string;
    }
  >({
    mode: 'onChange',
  });

  const {
    clearErrors,
    errors,
    formState: { isValid },
    setValue,
    handleSubmit,
    register,
    watch,
    setError,
  } = methods;

  useEffect(() => {
    const emailClientErrors = [
      ErrorCodeType.AUTHENTICATION_RE_SIGN_UP_USER_NOT_FOUND,
      ErrorCodeType.AUTHENTICATION_RE_SIGN_UP_REQUEST_NOT_FOUND,
      ErrorCodeType.AUTHENTICATION_RE_SIGN_UP_ALREADY_CONFIRMED,
    ];

    if (emailClientErrors.includes(state as ErrorCodeType))
      setError('emailClientError', {
        type: '',
        message: t(`validation.${state}`),
      });

    if (
      state ===
      ErrorCodeType.AUTHENTICATION_RE_SIGN_UP_INVALID_REREGISTRATION_CODE
    )
      setError('reregistrationCodeClientError', {
        type: '',
        message: t(`validation.${state}`),
      });
  }, [state, setError, t]);

  const clearAPIError = () => {
    clearErrors('emailClientError');
    clearErrors('reregistrationCodeClientError');
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit((formInput) => {
          resignUp(formInput);
        })}
      >
        <FormResignUpLayout>
          <FormInputTextLabelColumn
            key="email"
            label={t(`${RE_SIGN_UP}.form.email.label`)}
            labelStyle={LabelStyle}
            placeholder={t(`${RE_SIGN_UP}.form.email.placeholder`)}
            marginBetween={12}
            maxLength={255}
            name="email"
            errorMessage={
              (errors.email && errors.email.message) ||
              (errors.emailClientError && errors.emailClientError.message)
            }
            onChange={(text: string) => {
              clearAPIError();
              setValue('email', deleteFullWidth(text));
            }}
            register={register(resignUpRegister('email', t))}
            width={400}
          />
          <FormInputTextLabelColumn
            key="reregistrationCode"
            label={t(`${RE_SIGN_UP}.form.reregistrationCode.label`)}
            labelStyle={LabelStyle}
            placeholder={t(`${RE_SIGN_UP}.form.reregistrationCode.placeholder`)}
            marginBetween={12}
            maxLength={50}
            name="reregistrationCode"
            errorMessage={
              (errors.reregistrationCode &&
                errors.reregistrationCode.message) ||
              (errors.reregistrationCodeClientError &&
                errors.reregistrationCodeClientError.message)
            }
            onChange={(text: string) => {
              clearAPIError();
              setValue('reregistrationCode', deleteFullWidth(text));
            }}
            register={register(resignUpRegister('reregistrationCode', t))}
            width={400}
          />
          <Checkbox
            key="checkbox"
            checked={watch('agreed')}
            height={30}
            iconSize={15}
            onClick={() => {
              setValue('agreed', !watch('agreed'));
            }}
            theme={CheckBoxStyle}
            width={30}
          />
          <LinkTextSentence
            key="checkboxText"
            text={t(`${RE_SIGN_UP}.form.checkbox.label`)}
            urlList={[
              {
                replaceText: t(`${RE_SIGN_UP}.link.termsOfUse`),
                url: termsAndConditionsUrl || '',
                target: '_blank',
              },
              {
                replaceText: t(`${RE_SIGN_UP}.link.privacyPolicy`),
                url: privacyPolicyUrl || '',
                target: '_blank',
              },
            ]}
            theme={{
              text: CheckboxTextStyle,
              link: CheckboxLinkStyle,
            }}
          />
          <SubmitButton
            key="button"
            disabled={!(isValid && watch('agreed'))}
            height={50}
            isLoading={state === IS_LOADING}
            theme={{ borderRadius: 25 }}
            sizeProgress={20}
            width={165}
          >
            <TextPrimary theme={DefaultButtonTextStyle}>
              {t(`atoms.button.submit`)}
            </TextPrimary>
          </SubmitButton>
        </FormResignUpLayout>
      </form>
    </FormProvider>
  );
};

export default FormResignUp;

import Joi from '@hapi/joi';
import { UserProps, UserPropsFormat } from '../../User/User.type';

export interface UserAuthorizedProps extends UserProps {
  token: string;
}

export const UserAuthorizedPropsFormat = {
  ...UserPropsFormat,

  token: Joi.string().min(1).required(),
};

import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Layout
import { FlexBoxRow } from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface SecureBadgeLayoutProps {
  children: React.ReactElement[];
  backgroundColor: string;
}

const SecureBadgeLayout: FC<SecureBadgeLayoutProps> = ({
  children,
  backgroundColor,
}: SecureBadgeLayoutProps) => {
  const elements = ['icon', 'text'];
  const [icon, text] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow
      alignItems="center"
      justifyContent="center"
      padding="11"
      style={{
        backgroundColor,
        borderRadius: `${25 / 16}rem`,
      }}
      width="auto"
    >
      {text}
      <Margin left={10} />
      {icon}
    </FlexBoxRow>
  );
};

export default SecureBadgeLayout;

import styled from 'styled-components';
import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const Content = styled.div`
  box-sizing: border-box;
  padding: ${100 / 16}rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CheckMarkIconStyle = {
  color: color.white,
  backgroundColor: color.blue,
};

export const OrderIdLabelStyle = {
  fontSize: 21,
  color: color.primary,
  fontWeight: fontWeight.medium,
};
export const OrderIdStyle = {
  fontSize: 20,
  color: color.text.zambezi,
  fontWeight: fontWeight.medium,
};

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const TableTitleStyle = {
  fontSize: 21,
  color: color.primary,
  fontWeight: fontWeight.bold,
};

import React from 'react';

export interface LinkConsentFormDownloadContextProps {
  consentFormDownloadUrl?: string;
}

const LinkConsentFormDownloadContext = React.createContext<
  LinkConsentFormDownloadContextProps
>({
  consentFormDownloadUrl: undefined,
});

export default LinkConsentFormDownloadContext;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SubmitButton,
  CancelButton,
  DefaultButtonTextStyle,
  CancelButtonTextStyle,
} from '../../../../atoms/button/Button2';
import { TextPrimary } from '../../../../atoms/text2/Text2';

import TransferSavingsAccountConfirmButtonGroupLayout from './TransferSavingsAccountConfirmButtonGroupLayout';

export type TransferSavingsAccountConfirmButtonGroupProps = {
  leftButtonDisabled: boolean;
  isLoading: boolean;
  onClickLeft: () => void;
  onClickRight: () => void;
};

const TransferSavingsAccountConfirmButtonGroup: FC<TransferSavingsAccountConfirmButtonGroupProps> = ({
  leftButtonDisabled,
  isLoading,
  onClickLeft = () => undefined,
  onClickRight = () => undefined,
}: TransferSavingsAccountConfirmButtonGroupProps) => {
  const { t } = useTranslation();

  return (
    <TransferSavingsAccountConfirmButtonGroupLayout>
      <SubmitButton
        key="left"
        width={510}
        height={75}
        isLoading={isLoading}
        disabled={leftButtonDisabled}
        onClick={onClickLeft}
      >
        <TextPrimary theme={DefaultButtonTextStyle}>
          {t(`atoms.button.submit`)}
        </TextPrimary>
      </SubmitButton>
      <CancelButton
        key="right"
        width={510}
        height={75}
        onClick={onClickRight}
        disabled={isLoading}
      >
        <TextPrimary theme={CancelButtonTextStyle}>
          {t(`atoms.button.cancel`)}
        </TextPrimary>
      </CancelButton>
    </TransferSavingsAccountConfirmButtonGroupLayout>
  );
};
export default TransferSavingsAccountConfirmButtonGroup;

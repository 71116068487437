// Type
import {
  DebitCardSpotTransactionType,
  DebitCardTransactionStatusType,
} from '../../../../../../types/DebitCardTransaction.type';
import { DebitCardSpotTransactionV2Props } from './DebitCardSpotTransactionV2.type';
import { FiatCurrencyType } from '../../../../../../types/FiatCurrency.type';
import { TypeOfT } from '../../../../../../types/typeof/Translation.type';

// ValueObject
import DebitCardTransactionId from '../../../../../idManager/transactionId/DebitCardTransactionId/DebitCardTransactionId';
import Transaction from '../../../../transaction/Transaction/Transaction';

abstract class DebitCardSpotTransactionV2 extends Transaction {
  protected debitCardId: string;

  protected amount: number;

  protected currency: FiatCurrencyType;

  protected descriptionParameter: string;

  protected commonId: string;

  protected status: DebitCardTransactionStatusType;

  protected createdAt: number;

  protected updatedAt: number;

  protected props: DebitCardSpotTransactionV2Props;

  constructor(
    debitCardTransactionId: DebitCardTransactionId,
    props: DebitCardSpotTransactionV2Props,
  ) {
    super(debitCardTransactionId);
    this.debitCardId = props.debitCardId;
    this.amount = props.amount;
    this.currency = props.currency;
    this.descriptionParameter = props.descriptionParameter;
    this.commonId = props.commonId;
    this.status = props.status;
    this.createdAt = props.createdAt || Date.now();
    this.updatedAt = props.updatedAt || Date.now();
    this.props = props;
  }

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public getCommandParameter() {
    return {
      amount: this.amount,
      currency: this.currency,
      descriptionParameter: this.descriptionParameter,
      commonId: this.commonId,
      status: this.status,
    };
  }

  public abstract createDescription: (t: TypeOfT) => string;

  public abstract getTransactionType: () => DebitCardSpotTransactionType;
}

export default DebitCardSpotTransactionV2;

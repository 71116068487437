import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const StepNoContainerStyle = {
  borderRight: `${1 / 16}rem solid #d5d0da`,
};

export const ContainerDisabledStyle = {
  backgroundColor: color.background.quartz,
  opacity: 0.3,
};

export const ContainerStyle = {
  backgroundColor: color.background.white,
};

export const StepStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const StepNumberStyle = {
  fontSize: 36,
  color: color.text.zambezi,
  fontWeight: fontWeight.medium,
};

export const TitleStyle = {
  fontSize: 28,
  color: color.text.primary,
  fontWeight: fontWeight.bold,
};

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  lineHeight: 44,
};

export const ButtonTextStyle = {
  fontSize: 26,
  color: color.text.white,
  fontWeight: fontWeight.regular,
};

export const IconStyle = {
  color: color.icon.white,
  size: 24,
};

import React, { FC } from 'react';

// Component
import LinearProgressBar from './LinearProgressBar';

// DomainObject
import ContractTimeDeposit from '../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';

export interface TimeDepositProgressBarProps {
  contractTimeDeposit?: ContractTimeDeposit;
}

const TimeDepositProgressBar: FC<TimeDepositProgressBarProps> = ({
  contractTimeDeposit,
}: TimeDepositProgressBarProps) => {
  const progressRate = contractTimeDeposit?.getProgressRate() ?? 0;

  return (
    <div style={{ width: '100%' }}>
      <LinearProgressBar progress={progressRate} />
    </div>
  );
};
export default TimeDepositProgressBar;

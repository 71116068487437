import React, { FC, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import SavingsAccountsContext from '../../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccountsContext';

// LayoutComponent
import Box from '../../../../../atoms/utils/layout/Box/Box';

// Component
import {
  SavingsAccountOptionContainer,
  SavingsAccountSingleValueContainer,
} from '../../../option/optionContainer/SavingsAccountOptionContainer';
import SelectBox from '../../../../../atoms/select/SelectBox';
import HiddenInput from '../../../../../atoms/form/HiddenInput';
import DomainObjectMapper from '../../../option/option/DomainOptionsMapper';

export interface SavingAccountSelectBoxProps {
  name: string;
  disabled?: boolean;
}

const SavingAccountSelectBox: FC<SavingAccountSelectBoxProps> = ({
  name,
  disabled = false,
}: SavingAccountSelectBoxProps) => {
  const { savingsAccounts } = useContext(SavingsAccountsContext);

  const { setValue, watch } = useFormContext();

  const options = DomainObjectMapper.savingsAccountsToOption(savingsAccounts);

  return (
    <Box>
      <SelectBox
        borderColor="#d6ddec"
        dropdownColor="#212F41"
        isSearchable={false}
        marginLeft={`${0 / 16}rem`}
        minHeight={`${70 / 16}rem`}
        onSelect={(accountNumber: string) => {
          setValue(
            name,
            savingsAccounts.filterById(accountNumber).getRawAccountNumber(),
          );
        }}
        options={options || []}
        placeholderColor="#D5D0DA"
        value={watch(name)}
        disabled={disabled}
        SingleValueComponent={SavingsAccountSingleValueContainer}
        OptionComponent={SavingsAccountOptionContainer}
      />
      <HiddenInput name={name} value={watch(name) || ''} />
    </Box>
  );
};

export default SavingAccountSelectBox;

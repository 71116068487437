import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FaQuestionCircle } from 'react-icons/fa';

// Style
import { SubTitleStyle, TextStyle } from './style';

// Component
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import { TextPrimary, TextSecondary } from '../../../atoms/text2/Text2';
import ManualList from '../../../organisms/list/ManualList';
import ManualTemplateLayout from './ManualTemplateLayout';

const TEMPLATE_ID = `templates.manual.ManualTemplate`;

const ManualTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <ManualTemplateLayout>
      <PageTitle key="title">{t(`${TEMPLATE_ID}.title`)}</PageTitle>
      <ManualList key="bankManuals" fileKey="00bank" />
      <ManualList key="debitCardManuals" fileKey="01debitCard" />
      <ManualList key="userManuals" fileKey="02user" />
      <ManualList key="faq" fileKey="03faq" />
      <FaQuestionCircle key="inquiryIcon" color={`#000`} size={20} />
      <TextPrimary key="inquiryTitle" theme={SubTitleStyle}>
        {t(`${TEMPLATE_ID}.inquiry.title`)}
      </TextPrimary>
      <TextPrimary key="inquiryTitle" theme={SubTitleStyle}>
        {t(`${TEMPLATE_ID}.inquiry.title`)}
      </TextPrimary>
      <TextSecondary key="bankInquiry" theme={TextStyle}>
        {t(`${TEMPLATE_ID}.inquiry.bank`)}
        <a href="mailto:info@forbesprivatebank.com">
          info@forbesprivatebank.com
        </a>
      </TextSecondary>
      <TextSecondary key="cardInquiry" theme={TextStyle}>
        {t(`${TEMPLATE_ID}.inquiry.card`)}
        <a href="mailto:card_support@forbesprivatebank.com">
          card_support@forbesprivatebank.com
        </a>
      </TextSecondary>
    </ManualTemplateLayout>
  );
};
export default ManualTemplate;

import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const ContentStyle = {
  border: `${1 / 16}rem solid ${color.border.lightBlue}`,
};

export const LeftContainerStyle = {
  backgroundColor: color.background.ghostWhite,
};

export const RemainingTextStyle = {
  fontSize: 26,
  color: color.text.primary,
  fontWeight: fontWeight.regular,
};

export const LabeledTextStyle = {
  label: {
    fontSize: 22,
    fontWeight: fontWeight.regular,
    color: color.text.primary,
    opacity: 0.5,
  },
  value: {
    fontSize: 26,
    fontWeight: fontWeight.regular,
    color: color.text.primary,
  },
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Constant
import { ROUTE_TIME_DEPOSIT_RENEWAL_RESERVATION_ADD } from '../../../../../../config/constants/routing';
import { HOURS_TIME_LIMIT_RESERVATION_CHANGE_CONTRACT_TIME_DEPOSIT } from '../../../../../../config/constants/business';

// Style
import { ButtonStyle, ButtonTextStyle, DescriptionStyle } from './style';

// Component
import { SolidButton } from '../../../../../atoms/button/Button2/Button';
import { TextSecondary } from '../../../../../atoms/text2/Text2';
import ContractTimeDepositRenewalReservationAddCardLayout from './ContractTimeDepositRenewalReservationAddCardLayout';

// DomainObject
import ContractTimeDeposit from '../../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';

const ORGANISM_ID =
  'organisms.card.ContractTimeDepositRenewalReservationCard.ContractTimeDepositRenewalReservationAddCard';

export interface ContractTimeDepositRenewalReservationAddCardProps {
  contractTimeDeposit: ContractTimeDeposit;
}

const ContractTimeDepositRenewalReservationAddCard: FC<ContractTimeDepositRenewalReservationAddCardProps> = ({
  contractTimeDeposit,
}: ContractTimeDepositRenewalReservationAddCardProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  if (
    contractTimeDeposit.isMaturedWithInHours(
      HOURS_TIME_LIMIT_RESERVATION_CHANGE_CONTRACT_TIME_DEPOSIT,
    )
  )
    return <></>;

  return (
    <ContractTimeDepositRenewalReservationAddCardLayout>
      <SolidButton
        key="button"
        width={400}
        height={58}
        theme={ButtonStyle}
        onClick={() =>
          history.push(
            `${ROUTE_TIME_DEPOSIT_RENEWAL_RESERVATION_ADD}/${contractTimeDeposit.getRawId()}`,
          )
        }
      >
        <TextSecondary theme={ButtonTextStyle}>
          {t(`${ORGANISM_ID}.addButton`)}
        </TextSecondary>
      </SolidButton>
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${ORGANISM_ID}.description`)}
      </TextSecondary>
    </ContractTimeDepositRenewalReservationAddCardLayout>
  );
};

export default ContractTimeDepositRenewalReservationAddCard;

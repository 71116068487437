import styled from 'styled-components';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelStyle = {
  fontSize: 24,
  color: color.primary,
  fontWeight: fontWeight.regular,
  opacity: 0.5,
};

export const CurrencyStyle = {
  fontSize: 31,
  color: color.primary,
  fontWeight: fontWeight.bold,
  opacity: 0.5,
};

export const BalanceStyle = {
  fontSize: 31,
  color: color.primary,
  fontWeight: fontWeight.bold,
};

// IUseCase
import IKycStatusViewUseCase from '../../../enhancers/useCase/verification/kycRequest/KycStatusViewProvider/IKycStatusViewUseCase';

// IService
import IKycRequestQueryService from './IKycRequestQueryService';
import IConsentFormQueryService from '../consentForm/IConsentFormQueryService';

// Service
import KycRequestQueryService from '../../../service/verification/kycRequest/KycRequestQueryService';
import ConsentFormQueryService from '../../../service/verification/consentForm/ConsentFormQueryService';

// IRepository
import { IKycRequestQueryRepository } from '../../../domain/verification/kycRequest/KycRequest/IKycRequestRepository';
import { IConsentFormQueryRepository } from '../../../domain/verification/consentForm/IConsentFormRepository';

// Domain
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class KycStatusViewUseCase implements IKycStatusViewUseCase {
  private kycRequestQueryService: IKycRequestQueryService;

  private consentFormQueryService: IConsentFormQueryService;

  constructor(
    kycRequestQueryRepository: IKycRequestQueryRepository,
    consentFormQueryRepository: IConsentFormQueryRepository,
  ) {
    this.kycRequestQueryService = new KycRequestQueryService(
      kycRequestQueryRepository,
    );

    this.consentFormQueryService = new ConsentFormQueryService(
      consentFormQueryRepository,
    );
  }

  public open = async (user: UserAuthorized) => {
    const id = user.getId();

    const [kycRequest, consentForm] = await Promise.all([
      this.kycRequestQueryService.findById(id),
      this.consentFormQueryService.findById(id),
    ]);

    return { kycRequest, consentForm };
  };
}

export default KycStatusViewUseCase;

// Error
import SystemErrorFactory from 'errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from 'errors/ErrorMessage/ErrorMessage';

// Functions
import FirebasePrivateFunctions from 'infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';
import {
  SAVINGS_ACCOUNT_GET_CONTRACT_NAME,
  SAVINGS_ACCOUNT_GET_RAW_DATA_BY_ID,
  SAVINGS_ACCOUNT_GET_RAW_DATA_ALL_BY_USER_ID,
  SAVINGS_ACCOUNT_FETCH_CONTRACT_NAME_ECB_SAVINGS_ACCOUNT,
} from 'infrastructure/firebase/firebaseFunctions/endpoint';

// Type
import {
  SavingsAccountProps,
  SavingsAccountRawDataType,
} from 'domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount.type';
import { SavingsAccountState } from 'redux/reducers/savingsAccountsReducer';

// Interface
import ISavingsAccountQueryAPI from 'api/interface/savingsAccount/ISavingsAccountQueryAPI';
import ISavingsAccountRepository from 'domain/bankAccount/savingsAccount/ISavingsAccountRepository';

// DomainObject
import SavingsAccountFactory from 'domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccountFactory';
import SavingsAccounts from 'domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccounts';

// ValueObject
import SerialNumberSavingsAccount from 'domain/idManager/productSerialNumber/ProductSerialNumber/SerialNumber/concreteSerialNumber/SerialNumberSavingsAccount';

class SavingsAccountFunctions
  extends FirebasePrivateFunctions
  implements ISavingsAccountQueryAPI, ISavingsAccountRepository {
  public getContractNameFromAccountNumber = async (
    savingsAccountNumber: string,
  ) => {
    const endpoint = `${SAVINGS_ACCOUNT_GET_CONTRACT_NAME}/${savingsAccountNumber}`;

    const response = await this.privateGET(endpoint);

    return response.data.data;
  };

  public getContractNameFromAccountNumberECB = async (
    savingsAccountNumber: string,
  ) => {
    const endpoint = SAVINGS_ACCOUNT_FETCH_CONTRACT_NAME_ECB_SAVINGS_ACCOUNT.replace(
      /:id/,
      savingsAccountNumber,
    );

    const response = await this.privateGET(endpoint);

    return response.data.data;
  };

  public findAllByUserId = async (userId: string) => {
    const endpoint = SAVINGS_ACCOUNT_GET_RAW_DATA_ALL_BY_USER_ID.replace(
      /:userId/,
      userId,
    );

    const response = await this.privateGET(endpoint);

    const rows = response.data.data as Array<SavingsAccountRawDataType>;

    return rows.reduce((objs, row) => {
      const savingsAccountNumber = SerialNumberSavingsAccount.createNumberFromRawId(
        row.accountNumber,
      );

      objs.add(
        SavingsAccountFactory.create(savingsAccountNumber, {
          ...row.props,
        } as SavingsAccountProps),
      );
      return objs;
    }, new SavingsAccounts());
  };

  public findById = async (id: string) => {
    const endpoint = SAVINGS_ACCOUNT_GET_RAW_DATA_BY_ID.replace(/:id/, id);

    const response = await this.privateGET(endpoint);

    const row = response.data.data as SavingsAccountRawDataType;

    if (!row)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.DATABASE_DATA_NOT_FOUND_BY_ID,
        { dataSource: `SavingsAccount`, id },
      );

    const savingsAccountNumber = SerialNumberSavingsAccount.createNumberFromRawId(
      row.accountNumber,
    );

    return SavingsAccountFactory.create(savingsAccountNumber, {
      ...row.props,
    } as SavingsAccountProps);
  };

  public findRawAllByUserId = async (userId: string) => {
    const endpoint = SAVINGS_ACCOUNT_GET_RAW_DATA_ALL_BY_USER_ID.replace(
      /:userId/,
      userId,
    );
    const response = await this.privateGET(endpoint);

    const rows = response.data.data as Array<SavingsAccountRawDataType>;

    return rows.reduce<SavingsAccountState>((objs, row) => {
      Object.assign(objs, { [row.accountNumber]: row.props });
      return objs;
    }, {});
  };
}
export default SavingsAccountFunctions;

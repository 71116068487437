import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  ConsentFormStepsStatusProps,
  ConsentFormStepsStatusPropsFormat,
  ConsentFormStepsStatusInitialProps,
} from './ConsentFormStepsStatus.type';

// DomainObject
import ConsentFormStepsStatus from './ConsentFormStepsStatus';

class ConsentFormStepsStatusFactory {
  static create = (props: ConsentFormStepsStatusProps) => {
    const result = Joi.object(ConsentFormStepsStatusPropsFormat).validate(
      props,
    );

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: ConsentFormStepsStatus.name,
          reason: result.error.details[0].message,
        },
      );

    return new ConsentFormStepsStatus(props);
  };

  static createInitial = () => {
    return ConsentFormStepsStatusFactory.create(
      ConsentFormStepsStatusInitialProps,
    );
  };
}

export default ConsentFormStepsStatusFactory;

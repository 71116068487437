// Types
import { InvitationCodeProps } from '../../../domain/invite/invitationCode/InvitationCode/InvitationCode.type';
import { UseStateType } from '../../../types/typeof/UseState';
import { RankType } from '../../../types/Invite.type';

// Collection
import firestore from '../../../infrastructure/firebase/firestore/client/firestoreClient';
import InvitationCodesCollection from '../../../infrastructure/firebase/firestore/collections/invite/users/InvitationCodesCollection';

// IRepository
import IInvitationCodeRepository from '../../../domain/invite/invitationCode/IInvitationCodeRepository';

// DomainObject
import InvitationCodes from '../../../domain/invite/invitationCode/InvitationCodes/InvitationCodes';
import InvitationCodeFactory from '../../../domain/invite/invitationCode/InvitationCode/InvitationCodeFactory';

class InvitationCodeFirestore implements IInvitationCodeRepository {
  public subscribeByUserId = async (
    userId: string,
    setter: UseStateType<InvitationCodes>,
    setState: UseStateType<string>,
  ) => {
    const invitationCodesCollection = new InvitationCodesCollection(userId);

    const converter = (docs: firestore.DocumentSnapshot[]) => {
      return docs.reduce<InvitationCodes>((invitationCodes, doc) => {
        const data = doc.data() as InvitationCodeProps;

        invitationCodes.add(
          InvitationCodeFactory.create(
            doc.id as RankType,
            data as InvitationCodeProps,
          ),
        );
        return invitationCodes;
      }, new InvitationCodes());
    };

    return invitationCodesCollection.subscribeAll(setter, converter, setState);
  };
}
export default InvitationCodeFirestore;

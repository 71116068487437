import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CountdownContext from '../../../../enhancers/utils/Countdown/CountdownContext';

// Constant
import { RESEND_CONFIRMATION_MAIL_TIME } from '../../../../config/constants/business';

// Style
import { Container, DescriptionStyle, LinkTextStyle } from './style';

// Component
import { TextSecondary } from '../../../atoms/text2/Text2';
import Display from '../../../atoms/div/Display';
import CountdownTimer from '../../timer/CountdownTimer';
import LinkButtonTextSentence from '../../../molecules/link/LinkButtonTextSentence';

export interface SwitchResendEmailCountdownProps {
  margin?: string;
  onClickResend: () => void;
}

const ORGANISM_ID = `organisms.SwitchResendEmailCountdown`;

const SwitchResendEmailCountdown: FC<SwitchResendEmailCountdownProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  margin = `${0 / 16}rem`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onClickResend,
}: SwitchResendEmailCountdownProps) => {
  const { t } = useTranslation();
  const { count, setCount } = useContext(CountdownContext);

  return (
    <Container>
      <Display isDisplay={count === 0}>
        <TextSecondary theme={DescriptionStyle}>
          {t(`${ORGANISM_ID}.description.line1`)}
        </TextSecondary>
        <br />
        <LinkButtonTextSentence
          text={t(`${ORGANISM_ID}.description.line2`)}
          targets={[
            {
              replaceText: t(`words.Resend email`),
              onClick: () => {
                onClickResend();
                setCount(RESEND_CONFIRMATION_MAIL_TIME);
              },
            },
          ]}
          theme={{ text: DescriptionStyle, link: LinkTextStyle }}
        />
      </Display>
      <Display isDisplay={count !== 0}>
        <CountdownTimer
          time={count}
          description={t(`${ORGANISM_ID}.description.countdown`)}
          width={800}
          height={120}
        />
      </Display>
    </Container>
  );
};

export default SwitchResendEmailCountdown;

import { TransferSavingsAccountTransactionSeedProps } from './TransferSavingsAccountTransactionSeed.type';

// DomainObject
import TransferSavingsAccountTransaction from '../../TransferSavingsAccountTransaction/TransferForbesAccountTransaction/TransferForbesAccountTransaction';

// ValueObject
import TransferSavingsAccountTransactionIdFactory from '../../../../idManager/transactionId/TransferSavingsAccountTransactionId/TransferSavingsAccountTransactionIdFactory';

abstract class TransferSavingsAccountTransactionSeed extends TransferSavingsAccountTransaction {
  constructor(props: TransferSavingsAccountTransactionSeedProps) {
    super(
      TransferSavingsAccountTransactionIdFactory.createFromRawId(
        'TSAFUSD20210101999999111111',
        '20210101000000111111',
      ),
      props,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public abstract getRequestParameterCreate: () => { [key: string]: any };
}

export default TransferSavingsAccountTransactionSeed;

import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ImportantNoticeModalContext from '../../../modal/ImportantNoticeModal/ImportantNoticeModalContext';
import NotificationLogContext from '../../../../../domain/notification/notificationLog/NotificationLog/NotificationLogContext';
import NotificationLogCreateReadLogContext from '../../../../../enhancers/useCase/notification/NotificationLogCreateReadLogProvider/NotificationLogCreateReadLogContext';

// Type
import { NotificationLogType } from '../../../../../types/Notification.type';

// Constant
import { IS_LOADING } from '../../../../../config/constants/requestState';

// Style
import { CheckBoxStyle, DescriptionStyle } from './style';

// Component
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../atoms/button/Button2';
import { TextPrimary, TextSecondary } from '../../../../atoms/text2/Text2';
import Checkbox from '../../../../atoms/checkbox/Checkbox';
import FormNotificationReadStatusHandledLayout from './FormNotificationReadStatusHandledLayout';

const ORGANISM_ID = `organisms.form.notification.FormNotificationReadStatusHandled`;

const FormNotificationReadStatusHandled: FC = () => {
  const { t } = useTranslation();
  const { notificationLog } = useContext(NotificationLogContext);
  const { hideModal } = useContext(ImportantNoticeModalContext);
  const { requestState, createReadLog } = useContext(
    NotificationLogCreateReadLogContext,
  );

  /**
   *
   *  React Hook Form
   *
   */

  const methods = useForm<{ isRead: boolean }>({
    mode: 'onChange',
  });

  const { handleSubmit, setValue, watch } = methods;

  /* *
   *
   *  Default Variable
   *
   * */

  // Set DefaultValue
  useEffect(() => {
    if (notificationLog) setValue('isRead', notificationLog.getProps().isRead);
    // eslint-disable-next-line
  }, [notificationLog]);

  /**
   *
   *  Event
   *
   */

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={handleSubmit((formInput: { isRead: boolean }) => {
        if (formInput.isRead)
          createReadLog(NotificationLogType.custodyFedAndTopUpFee, () => {
            hideModal();
          });
        else hideModal();
      })}
    >
      <FormNotificationReadStatusHandledLayout>
        <Checkbox
          key="checkBox"
          checked={watch('isRead')}
          height={30}
          iconSize={15}
          onClick={() => {
            setValue('isRead', !watch('isRead'));
          }}
          theme={CheckBoxStyle}
          width={30}
        />
        <TextSecondary key="checkBoxDescription" theme={DescriptionStyle}>
          {t(`${ORGANISM_ID}.checkBox.description`)}
        </TextSecondary>
        <SubmitButton
          key="button"
          height={50}
          isLoading={requestState === IS_LOADING}
          theme={{ borderRadius: 25 }}
          sizeProgress={20}
          width={165}
        >
          <TextPrimary theme={DefaultButtonTextStyle}>
            {t(`atoms.button.ok`)}
          </TextPrimary>
        </SubmitButton>
      </FormNotificationReadStatusHandledLayout>
    </form>
  );
};

export default FormNotificationReadStatusHandled;

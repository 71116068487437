// React
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle } from './style';
import {
  CloseButton,
  DefaultButtonTextStyle,
} from '../../../atoms/button/Button2';

// Component
import AttentionIcon from '../../../atoms/text/CircledText/AttentionIcon';
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import { TextPrimary, TextSecondary } from '../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';
import ErrorMessageNotificationLayout from './ErrorMessageNotificationLayout';

export interface ErrorMessageNotificationProps {
  closeAction: () => void;
  title: string;
  line: string;
}

const ErrorMessageNotification: FC<ErrorMessageNotificationProps> = ({
  closeAction,
  title,
  line,
}: ErrorMessageNotificationProps) => {
  const { t } = useTranslation();
  return (
    <ErrorMessageNotificationLayout>
      <AttentionIcon
        key="icon"
        size={`${66 / 16}rem`}
        textSize={`${33 / 16}rem`}
      />
      <PageTitle key="title">{title}</PageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced text={line} />
      </TextSecondary>
      <CloseButton
        key="button"
        width={140}
        height={50}
        theme={{ borderRadius: 25 }}
        onClick={() => closeAction()}
      >
        <TextPrimary theme={DefaultButtonTextStyle}>
          {t(`atoms.button.close`)}
        </TextPrimary>
      </CloseButton>
    </ErrorMessageNotificationLayout>
  );
};

export default ErrorMessageNotification;

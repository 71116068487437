import { RankDType, RankType } from '../../../types/Invite.type';
import Rank from './Rank';

class RankD extends Rank {
  constructor(rank: RankDType) {
    super(rank);
    if (rank !== RankType.D01 && rank !== RankType.D02)
      throw new Error(`Invalid Rank ${rank}`);
  }

  public isRankD = () => true;

  public isRankA = () => false;

  public isRankC = () => false;
}
export default RankD;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Constant
import {
  TIMEZONE_SHORT,
  TIMEZONE_SHORT_SUMMER_TIME,
} from '../../../../../config/constants/business';

// Style
import { DescriptionStyle } from './style';

// Component
import { PageTitleSub } from '../../../../atoms/headLine/HeadLine';
import { TextSecondary } from '../../../../atoms/utils/component/Text';
import HeaderCryptoCurrencyWallet from '../../../../organisms/header/cryptoCurrency/HeaderCryptoCurrencyWallet';
import CryptoCurrencyHistoryTabBar from '../../../../molecules/selector/tabBar/UnderBarTabBar/CryptoCurrencyHistoryTabBar';
import CryptoCurrencyWalletCard from '../../../../molecules/card/cryptoCurrency/CryptoCurrencyWalletCard';
import CryptoCurrencyWalletTemplateLayout from './CryptoCurrencyWalletTemplateLayout';
import TableCryptoCurrencyTransactionHistory from '../../../../organisms/table/history/TableCryptoCurrencyTransactionHistory/TableCryptoCurrencyTransactionHistory';

// ValueObject
import TimeZonedTime from '../../../../../value/datetime/TimeZonedTime';

const TEMPLATE_ID = `templates.cryptoCurrency.CryptoCurrencyWalletTemplate`;

const CryptoCurrencyWalletTemplate: FC = () => {
  const { t } = useTranslation();

  const timezone = new TimeZonedTime().isSummerTime()
    ? TIMEZONE_SHORT_SUMMER_TIME
    : TIMEZONE_SHORT;

  return (
    <CryptoCurrencyWalletTemplateLayout>
      <HeaderCryptoCurrencyWallet key="header" />
      <CryptoCurrencyWalletCard key="wallet" />
      <TextSecondary key="custodyFeeDescription" theme={DescriptionStyle}>
        {t(`${TEMPLATE_ID}.custodyFeeDescription`, { timezone })}
      </TextSecondary>
      <PageTitleSub key="historyHeader">
        {t(`${TEMPLATE_ID}.title.sub`)}
      </PageTitleSub>
      <CryptoCurrencyHistoryTabBar key="historyTabBar" />
      <TableCryptoCurrencyTransactionHistory key="table" />
    </CryptoCurrencyWalletTemplateLayout>
  );
};

export default CryptoCurrencyWalletTemplate;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export interface CorporateAddressFileContextProps {
  proofOfDocument1: File | undefined;
  setProofOfDocument1: (file: File | undefined) => void;
}

const FileContext = React.createContext<CorporateAddressFileContextProps>({
  proofOfDocument1: undefined,
  setProofOfDocument1: (file: File | undefined) => undefined,
});

export default FileContext;

import IStorageHandler from '../IStorageHandler';
import storage from '../../infrastructure/firebase/cloudStorage/storage';
import { executeWithTimeout } from '../../utils/helpers/timeout';
import { FILE_UPLOAD_TIMEOUT } from '../../config/constants/business';

class CloudStorage implements IStorageHandler {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public upload = async (path: string, f: any) => {
    const ref = storage.ref();
    const fileRef = ref.child(path);

    const promiseUpload = new Promise((resolve, reject) => {
      fileRef
        .put(f)
        .then(() => {
          resolve(undefined);
        })
        .catch((error) => {
          reject(error);
        });
    });

    await executeWithTimeout(promiseUpload, FILE_UPLOAD_TIMEOUT);
  };

  public delete = async (path: string) => {
    const ref = storage.ref();
    const fileRef = ref.child(path);

    const promiseDelete = new Promise((resolve, reject) => {
      fileRef
        .delete()
        .then(() => {
          resolve(undefined);
        })
        .catch((error) => {
          reject(error);
        });
    });

    await executeWithTimeout(promiseDelete, FILE_UPLOAD_TIMEOUT);
  };
}

export default CloudStorage;

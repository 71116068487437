import React, { useState } from 'react';

export interface FormInputPersonalInformation {
  firstName: string;

  middleName: string;

  lastName: string;

  gender: string;

  dateOfBirthYear: string;

  dateOfBirthMonth: string;

  dateOfBirthDay: string;

  passportNo: string;

  passportUrl: string;

  expiredDateYear: string;

  expiredDateMonth: string;

  expiredDateDay: string;

  selfieUrl: string;
}

export const useFormInputPersonalInformation = () => {
  const [formInput, setFormInput] = useState<FormInputPersonalInformation>();

  return {
    formInput,
    setFormInput,
  };
};

export const FormInputPersonalInformationContext = React.createContext<{
  formInput?: FormInputPersonalInformation;
  setFormInput: (formInput?: FormInputPersonalInformation) => void;
}>({
  formInput: undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFormInput: (formInput?: FormInputPersonalInformation) => undefined,
});

import { useCallback, useState, useEffect } from 'react';
import { checkInternetConnection } from '../../../../../utils/helpers/connection';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import TwoFactorAuthAppSettingsViewUseCase from '../../../../../useCases/verification/consentForm/TwoFactorAuthAppSettingsViewUseCase';

// Repository
import AuthenticatorFunctions from '../../../../../repository/authenticator/AuthenticatorFunctions';
import ConsentFormFirestore from '../../../../../repository/verification/consentForm/ConsentFormFirestore';

// DomainObject
import { useAuthenticator } from '../../../../../domain/authenticator/authenticator/Authenticator/useAuthenticator';
import { useConsentForm } from '../../../../../domain/verification/consentForm/ConsentForm/useConsentForm';
import UserAuthorized from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useTwoFactorAuthAppSettingsViewUseCase = (
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  DomainObject
   *
   * */
  const { authenticator, setAuthenticator } = useAuthenticator();
  const { consentForm, setConsentForm } = useConsentForm();

  /* *
   *
   *  Repository
   *
   * */
  const authenticatorCreateRepository = new AuthenticatorFunctions();
  const consentFormRepository = new ConsentFormFirestore();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new TwoFactorAuthAppSettingsViewUseCase(
    authenticatorCreateRepository,
    consentFormRepository,
  );

  /* *
   *
   * Method
   *
   * */
  const openTwoFactorAuthAppSettings = useCallback(async () => {
    if (!userAuthorized) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const output = await useCase.open(userAuthorized);

      setAuthenticator(output.authenticator);
      setConsentForm(output.consentForm);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized]);

  /* *
   *
   * UseEffect
   *
   * */
  useEffect(() => {
    let isMounted = true;

    if (userAuthorized && isMounted) openTwoFactorAuthAppSettings();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [openTwoFactorAuthAppSettings]);

  return {
    state,
    setState,
    authenticator,
    consentForm,
  };
};

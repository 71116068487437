import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import VerifyAuthenticatorContext from '../../../../../../enhancers/auth/authenticator/VerifyAuthenticator/VerifyAuthenticatorContext';
import UpdateAuthenticatorContext from '../../../../../../enhancers/auth/authenticator/UpdateAuthenticator/UpdateAuthenticatorContext';

// Constant
import { INITIAL } from '../../../../../../config/constants/requestState';

// Style
import { NavigationTextStyle } from './style';

// Component
import { PageTitle } from '../../../../../atoms/headLine/HeadLine';
import Breadcrumb from '../../../../../molecules/link/Breadcrumb';
import HeaderTwoFactorAuthAppEditCompleteTemplateLayout from './HeaderTwoFactorAuthAppEditCompleteTemplateLayout';

const ORGANISM_ID = `organisms.header.userSettings.HeaderTwoFactorAuthAppEditCompleteTemplate`;

const HeaderTwoFactorAuthAppEditCompleteTemplate: FC = () => {
  const { t } = useTranslation();
  const { setVerifyAuthenticatorState } = useContext(
    VerifyAuthenticatorContext,
  );
  const { setUpdateAuthenticatorState } = useContext(
    UpdateAuthenticatorContext,
  );

  return (
    <HeaderTwoFactorAuthAppEditCompleteTemplateLayout>
      <PageTitle key="left">{t(`${ORGANISM_ID}.title`)}</PageTitle>
      <Breadcrumb
        key="right"
        onClick={() => {
          setVerifyAuthenticatorState(INITIAL);
          setUpdateAuthenticatorState(INITIAL);
        }}
        iconSize={10}
        marginBetween={13}
        text={t(`atoms.link.back`)}
        theme={{ text: NavigationTextStyle }}
      />
    </HeaderTwoFactorAuthAppEditCompleteTemplateLayout>
  );
};

export default HeaderTwoFactorAuthAppEditCompleteTemplate;

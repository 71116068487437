import {
  DebitCardRequestCherryVisa01StatusType,
  DebitCardRequestCherryVisa01StepStatusType,
  DebitCardRequestCherryVisa01StepType,
} from '../../../../../../types/DebitCardRequestCherryVisa01.type';

export const debitCardRequestCherryVisa01StatusMapper = {
  [DebitCardRequestCherryVisa01StatusType.initial]: {
    [DebitCardRequestCherryVisa01StepType.proofOfIdentity]:
      DebitCardRequestCherryVisa01StepStatusType.initial,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfIdentityReview]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.preparingCardShipment]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.cardDelivery]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.proofOfReceipt]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfReceiptReview]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.payForCardIssuanceFee]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
  },
  [DebitCardRequestCherryVisa01StatusType.proofOfIdentityWaitingReview]: {
    [DebitCardRequestCherryVisa01StepType.proofOfIdentity]:
      DebitCardRequestCherryVisa01StepStatusType.reviewing,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfIdentityReview]:
      DebitCardRequestCherryVisa01StepStatusType.reviewing,
    [DebitCardRequestCherryVisa01StepType.preparingCardShipment]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.cardDelivery]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.proofOfReceipt]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfReceiptReview]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.payForCardIssuanceFee]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
  },
  [DebitCardRequestCherryVisa01StatusType.proofOfIdentityRejected]: {
    [DebitCardRequestCherryVisa01StepType.proofOfIdentity]:
      DebitCardRequestCherryVisa01StepStatusType.rejected,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfIdentityReview]:
      DebitCardRequestCherryVisa01StepStatusType.rejected,
    [DebitCardRequestCherryVisa01StepType.preparingCardShipment]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.cardDelivery]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.proofOfReceipt]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfReceiptReview]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.payForCardIssuanceFee]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
  },
  [DebitCardRequestCherryVisa01StatusType.waitingForShipmentInstruction]: {
    [DebitCardRequestCherryVisa01StepType.proofOfIdentity]:
      DebitCardRequestCherryVisa01StepStatusType.approved,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfIdentityReview]:
      DebitCardRequestCherryVisa01StepStatusType.approved,
    [DebitCardRequestCherryVisa01StepType.preparingCardShipment]:
      DebitCardRequestCherryVisa01StepStatusType.waiting,
    [DebitCardRequestCherryVisa01StepType.cardDelivery]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.proofOfReceipt]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfReceiptReview]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.payForCardIssuanceFee]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
  },
  [DebitCardRequestCherryVisa01StatusType.waitingForShipment]: {
    [DebitCardRequestCherryVisa01StepType.proofOfIdentity]:
      DebitCardRequestCherryVisa01StepStatusType.approved,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfIdentityReview]:
      DebitCardRequestCherryVisa01StepStatusType.approved,
    [DebitCardRequestCherryVisa01StepType.preparingCardShipment]:
      DebitCardRequestCherryVisa01StepStatusType.waiting,
    [DebitCardRequestCherryVisa01StepType.cardDelivery]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.proofOfReceipt]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfReceiptReview]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.payForCardIssuanceFee]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
  },
  [DebitCardRequestCherryVisa01StatusType.proofOfReceiptRequested]: {
    [DebitCardRequestCherryVisa01StepType.proofOfIdentity]:
      DebitCardRequestCherryVisa01StepStatusType.approved,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfIdentityReview]:
      DebitCardRequestCherryVisa01StepStatusType.approved,
    [DebitCardRequestCherryVisa01StepType.preparingCardShipment]:
      DebitCardRequestCherryVisa01StepStatusType.done,
    [DebitCardRequestCherryVisa01StepType.cardDelivery]:
      DebitCardRequestCherryVisa01StepStatusType.onDelivery,
    [DebitCardRequestCherryVisa01StepType.proofOfReceipt]:
      DebitCardRequestCherryVisa01StepStatusType.initial,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfReceiptReview]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRequestCherryVisa01StepType.payForCardIssuanceFee]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
  },
  [DebitCardRequestCherryVisa01StatusType.proofOfReceiptWaitingReview]: {
    [DebitCardRequestCherryVisa01StepType.proofOfIdentity]:
      DebitCardRequestCherryVisa01StepStatusType.approved,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfIdentityReview]:
      DebitCardRequestCherryVisa01StepStatusType.approved,
    [DebitCardRequestCherryVisa01StepType.preparingCardShipment]:
      DebitCardRequestCherryVisa01StepStatusType.done,
    [DebitCardRequestCherryVisa01StepType.cardDelivery]:
      DebitCardRequestCherryVisa01StepStatusType.done,
    [DebitCardRequestCherryVisa01StepType.proofOfReceipt]:
      DebitCardRequestCherryVisa01StepStatusType.reviewing,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfReceiptReview]:
      DebitCardRequestCherryVisa01StepStatusType.reviewing,
    [DebitCardRequestCherryVisa01StepType.payForCardIssuanceFee]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
  },
  [DebitCardRequestCherryVisa01StatusType.proofOfReceiptRejected]: {
    [DebitCardRequestCherryVisa01StepType.proofOfIdentity]:
      DebitCardRequestCherryVisa01StepStatusType.approved,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfIdentityReview]:
      DebitCardRequestCherryVisa01StepStatusType.approved,
    [DebitCardRequestCherryVisa01StepType.preparingCardShipment]:
      DebitCardRequestCherryVisa01StepStatusType.done,
    [DebitCardRequestCherryVisa01StepType.cardDelivery]:
      DebitCardRequestCherryVisa01StepStatusType.done,
    [DebitCardRequestCherryVisa01StepType.proofOfReceipt]:
      DebitCardRequestCherryVisa01StepStatusType.rejected,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfReceiptReview]:
      DebitCardRequestCherryVisa01StepStatusType.rejected,
    [DebitCardRequestCherryVisa01StepType.payForCardIssuanceFee]:
      DebitCardRequestCherryVisa01StepStatusType.disabled,
  },
  [DebitCardRequestCherryVisa01StatusType.issuanceFeeRequested]: {
    [DebitCardRequestCherryVisa01StepType.proofOfIdentity]:
      DebitCardRequestCherryVisa01StepStatusType.approved,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfIdentityReview]:
      DebitCardRequestCherryVisa01StepStatusType.approved,
    [DebitCardRequestCherryVisa01StepType.preparingCardShipment]:
      DebitCardRequestCherryVisa01StepStatusType.done,
    [DebitCardRequestCherryVisa01StepType.cardDelivery]:
      DebitCardRequestCherryVisa01StepStatusType.done,
    [DebitCardRequestCherryVisa01StepType.proofOfReceipt]:
      DebitCardRequestCherryVisa01StepStatusType.approved,
    [DebitCardRequestCherryVisa01StepType.waitingProofOfReceiptReview]:
      DebitCardRequestCherryVisa01StepStatusType.approved,
    [DebitCardRequestCherryVisa01StepType.payForCardIssuanceFee]:
      DebitCardRequestCherryVisa01StepStatusType.initial,
  },
} as {
  [key in DebitCardRequestCherryVisa01StatusType]: {
    [DebitCardRequestCherryVisa01StepType.proofOfIdentity]: DebitCardRequestCherryVisa01StepStatusType;
    [DebitCardRequestCherryVisa01StepType.waitingProofOfIdentityReview]: DebitCardRequestCherryVisa01StepStatusType;
    [DebitCardRequestCherryVisa01StepType.preparingCardShipment]: DebitCardRequestCherryVisa01StepStatusType;
    [DebitCardRequestCherryVisa01StepType.cardDelivery]: DebitCardRequestCherryVisa01StepStatusType;
    [DebitCardRequestCherryVisa01StepType.proofOfReceipt]: DebitCardRequestCherryVisa01StepStatusType;
    [DebitCardRequestCherryVisa01StepType.waitingProofOfReceiptReview]: DebitCardRequestCherryVisa01StepStatusType;
    [DebitCardRequestCherryVisa01StepType.payForCardIssuanceFee]: DebitCardRequestCherryVisa01StepStatusType;
  };
};

import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

// Original Lib
import { getSymbolFromCurrency } from '../../../../../../utils/helpers/currencyHelper';

// Type
import { CurrencyType } from '../../../../../../types/Currency.type';

// Style
import { LabelStyle, ErrorMessageStyle } from './style';

// Component
import { TextPrimary, TextSecondary } from '../../../../../atoms/text2/Text2';
import TextInput from '../../../../../atoms/form/TextInput';
import FormInputTransactionAmountLayout from './FormInputTransactionAmountLayout';

interface FormInputTransactionAmountProps {
  currency: CurrencyType;
  disabled?: boolean;
  errorMessage?: string;
  label: string;
  inputWidth: string | number;
  name: string;
  onChange?: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation?: { [key: string]: any };
  value?: string;
}

const FormInputTransactionAmount: FC<FormInputTransactionAmountProps> = ({
  currency,
  disabled = false,
  errorMessage,
  label,
  inputWidth,
  name,
  onChange,
  validation,
  value,
}: FormInputTransactionAmountProps) => {
  const { errors, register, watch } = useFormContext();

  return (
    <FormInputTransactionAmountLayout inputWidth={inputWidth}>
      <TextPrimary key="label" theme={LabelStyle}>
        {label}
      </TextPrimary>
      <TextSecondary key="currency" theme={LabelStyle}>
        {getSymbolFromCurrency(currency)}
      </TextSecondary>
      <TextInput
        key="input"
        disabled={disabled}
        maxLength={15}
        name={name || 'amount'}
        onChange={onChange}
        placeholder={'0'}
        register={validation ? register(validation) : register}
        textAlign={'right'}
        value={value || watch(name)}
      />
      <TextSecondary key="errorMessage" theme={ErrorMessageStyle}>
        {errors[name]?.message || errorMessage}
      </TextSecondary>
    </FormInputTransactionAmountLayout>
  );
};

export default FormInputTransactionAmount;

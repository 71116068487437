import React, { FC } from 'react';
import ResetAuthenticatorViewProvider from '../../../enhancers/useCase/authentication/ResetAuthenticatorViewProvider';
import ResetAuthenticatorProvider from '../../../enhancers/useCase/authentication/ResetAuthenticatorProvider';
import ResetAuthenticatorPage from './ResetAuthenticatorPage';

const EnhancedResetAuthenticatorPage: FC = () => {
  return (
    <ResetAuthenticatorViewProvider>
      <ResetAuthenticatorProvider>
        <ResetAuthenticatorPage />
      </ResetAuthenticatorProvider>
    </ResetAuthenticatorViewProvider>
  );
};

export default EnhancedResetAuthenticatorPage;

import color from '../../../../atoms/utils/const/color';

export const TableVerticalStyle = {
  th: {
    backgroundColor: color.background.ghostWhite,
    borderBottom: `${1 / 16}rem solid ${color.border.white}`,
  },
  td: {
    backgroundColor: color.background.white,
    borderBottom: `${1 / 16}rem solid ${color.border.gray}`,
  },
};

export const TableVerticalLayout = {
  rowWidth: 1040,
  rowHeight: 80,
  thWidth: 370,
  tdWidth: 670,
};

export enum DebitCardRequestCherryVisa01StepStatusType {
  initial = 'initial',
  disabled = 'disabled',
  reviewing = 'reviewing',
  approved = 'approved',
  done = 'done',
  rejected = 'rejected',
  waiting = 'waiting',
  onDelivery = 'onDelivery',
}

export enum DebitCardRequestCherryVisa01StatusType {
  initial = 'initial',
  proofOfIdentityWaitingReview = 'proofOfIdentityWaitingReview',
  proofOfIdentityRejected = 'proofOfIdentityRejected',
  waitingForShipmentInstruction = 'waitingForShipmentInstruction',
  waitingForShipment = 'waitingForShipment',
  proofOfReceiptRequested = 'proofOfReceiptRequested',
  cardDeliveryFailed = 'cardDeliveryFailed',
  proofOfReceiptWaitingReview = 'proofOfReceiptWaitingReview',
  proofOfReceiptRejected = 'proofOfReceiptRejected',
  issuanceFeeRequested = 'issuanceFeeRequested',
  active = 'active',
  canceled = 'canceled',
}

export enum DeliveryTrackingStatusType {
  pending = 'pending',
  shipped = 'shipped',
  returned = 'returned',
  failure = 'failure',
  canceled = 'canceled',
}

export enum ProofOfIdentityReviewDebitCardRequestCherryVisa01Status {
  requiresVerification = DebitCardRequestCherryVisa01StatusType.proofOfIdentityRejected,
  requiresShippingAction = DebitCardRequestCherryVisa01StatusType.waitingForShipment,
}

export enum DebitCardRequestCherryVisa01StepType {
  proofOfIdentity = 'proofOfIdentity',
  waitingProofOfIdentityReview = 'waitingProofOfIdentityReview',
  preparingCardShipment = 'preparingCardShipment',
  cardDelivery = 'cardDelivery',
  proofOfReceipt = 'proofOfReceipt',
  waitingProofOfReceiptReview = 'waitingProofOfReceiptReview',
  payForCardIssuanceFee = 'payForCardIssuanceFee',
}

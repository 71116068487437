/* eslint-disable import/no-cycle */

// Types
import { ContractTimeDepositHistoryProps } from './ContractTimeDepositHistory.type';

// Value Object
import SavingsAccountNumber from '../../../../value/id/SavingsAccountNumber';
import SavingsAccount from '../../../bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

class ContractTimeDepositHistory {
  private id: string;

  private savingsAccountNumber: SavingsAccountNumber;

  private amount: number;

  private createdAt: number;

  private props: ContractTimeDepositHistoryProps;

  constructor(id: string, props: ContractTimeDepositHistoryProps) {
    this.id = id;
    this.savingsAccountNumber = new SavingsAccountNumber(
      props.savingsAccountNumber,
    );
    this.amount = props.amount;
    this.createdAt = props.createdAt ?? Date.now();
    this.props = props;
  }

  public tmp = () => ({
    amount: this.amount,
  });

  public getId = () => this.id;

  public getProps = () => {
    return {
      ...this.props,
      createdAt: this.createdAt,
    };
  };

  public isSavingsAccount = (savingsAccount: SavingsAccount) => {
    const savingsAccountNumber = savingsAccount.getRawAccountNumber();

    return (
      this.savingsAccountNumber.getAccountNumber() === savingsAccountNumber
    );
  };
}
export default ContractTimeDepositHistory;

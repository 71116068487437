import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { Container, UserNameBoxStyle } from './style';
import Box from '../../../atoms/utils/layout/Box/Box';

export interface HeaderDashboardLayoutProps {
  children: React.ReactElement[];
}

const HeaderDashboardLayout: FC<HeaderDashboardLayoutProps> = ({
  children,
}: HeaderDashboardLayoutProps) => {
  const elements = ['datetime', 'userName'];
  const [datetime, userName] = getElementsFromKeys(children, elements);

  return (
    <Container>
      <Box width="auto" height="auto" paddingRight={20}>
        {datetime}
      </Box>
      <Box width="auto" height="auto" paddingLeft={21} theme={UserNameBoxStyle}>
        {userName}
      </Box>
    </Container>
  );
};
export default HeaderDashboardLayout;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxRow } from '../../../../../../atoms/utils/layout/Box/FlexBox';

export interface FormInputTimeDepositSelectBoxLayoutProps {
  children: React.ReactElement[];
  inputWidth: string | number;
}

const FormInputTimeDepositSelectBoxLayout: FC<FormInputTimeDepositSelectBoxLayoutProps> = ({
  children,
  inputWidth,
}: FormInputTimeDepositSelectBoxLayoutProps) => {
  const elements = ['label', 'input'];
  const [label, input] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow justifyContent="space-between" alignItems="center">
      {label}
      <FlexBoxRow width={inputWidth}>{input}</FlexBoxRow>
    </FlexBoxRow>
  );
};
export default FormInputTimeDepositSelectBoxLayout;

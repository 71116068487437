import React from 'react';

export interface SignInTimeOutModalContextProps {
  showSignInTimeOutModal: () => void;
  hideSignInTimeOutModal: () => void;
}

const SignInTimeOutModalContext = React.createContext<
  SignInTimeOutModalContextProps
>({
  showSignInTimeOutModal: () => undefined,
  hideSignInTimeOutModal: () => undefined,
});

export default SignInTimeOutModalContext;

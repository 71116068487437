// Type
import { BankFutureDepositType } from '../../../../../../../types/BankAccountTransaction.type';
import { BankFutureDepositProps } from './BankFutureDeposit.type';

// DomainObject
import BankFutureTransaction from '../../../bankFutureTransaction/BankFutureTransaction/BankFutureTransaction';
import BankTransactionId from '../../../../../../idManager/transactionId/BankTransactionId/BankTransactionId';

/**
 *
 *  @extends BankFutureTransaction
 *  @param {TransactionId}                   transactionId inherited
 *  @param {SavingsAccountNumber}            savingsAccountNumber inherited
 *  @param {number}                          amount inherited
 *  @param {string}                          commonId inherited
 *  @param {FiatCurrencyType}                currency inherited
 *  @param {string}                          descriptionParameter inherited
 *  @param {number}                          createdAt inherited
 *  @param {number}                          updatedAt inherited
 *  @param {string}                          scheduledDate inherited
 *  @param {string}                          scheduledTime inherited
 *  @param {BankFutureTransactionStatusType} status inherited
 *  @param {BankFutureDepositType}           bankFutureDepositType
 *
 */
class BankFutureDeposit extends BankFutureTransaction {
  private bankFutureDepositType: BankFutureDepositType;

  constructor(
    bankTransactionId: BankTransactionId,
    props: BankFutureDepositProps,
  ) {
    super(bankTransactionId, props);
    this.bankFutureDepositType = props.bankFutureDepositType;
  }

  public getProps = () => ({
    ...super.getProps(),
    bankFutureDepositType: this.bankFutureDepositType,
  });
}
export default BankFutureDeposit;

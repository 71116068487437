import styled from 'styled-components';

export const LabelContainer = styled.div`
  margin-bottom: ${12 / 16}rem;
`;
export const InputWithError = styled.div`
  overflow: visible;
`;

export const InputContainer = styled.div`
  height: ${50 / 16}rem;
`;
export const ErrorMessageContainer = styled.div`
  height: ${40 / 16}rem;
  top: 0;
  width: 200%;
  display: flex;
  align-items: center;
`;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import Margin from '../../../atoms/utils/layout/Margin';
import { FlexRow } from '../../../atoms/utils/layout/Flex';

export interface BreadCrumbLinkLayoutProps {
  children: React.ReactElement[];
}

const BreadCrumbLinkLayout: FC<BreadCrumbLinkLayoutProps> = ({
  children,
}: BreadCrumbLinkLayoutProps) => {
  const elements = ['arrow', 'link'];
  const [arrow, link] = getElementsFromKeys(children, elements);

  return (
    <FlexRow>
      {arrow}
      <Margin left={12}>{link}</Margin>
    </FlexRow>
  );
};
export default BreadCrumbLinkLayout;

import React, { FC } from 'react';
import { BiCheck } from 'react-icons/bi';

export interface CheckIconProps {
  color: string;
  size: string | number;
}

const CheckIcon: FC<CheckIconProps> = ({ color, size }: CheckIconProps) => (
  <BiCheck color={color} size={size} />
);
export default CheckIcon;

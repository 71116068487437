import React, { FC, useContext } from 'react';
import ReregistrationPageStateContext from '../../../enhancers/pageState/authentication/ReregistrationPageState/ReregistrationPageStateContext';

// Component
import SignUpPageGroupTemplate from '../../../components/templates/front/SignUpPageGroupTemplate';
import ReregistrationTemplate from '../../../components/templates/front/ReregistrationTemplate';
import ReregistrationTwoFactorAuthTemplate from '../../../components/templates/front/ReregistrationTwoFactorAuthTemplate';

const ReregistrationPage: FC = () => {
  const { pageState } = useContext(ReregistrationPageStateContext);

  return (
    <SignUpPageGroupTemplate>
      {(() => {
        if (pageState === 'twoFactorAuth')
          return <ReregistrationTwoFactorAuthTemplate />;
        return <ReregistrationTemplate />;
      })()}
    </SignUpPageGroupTemplate>
  );
};

export default ReregistrationPage;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxRow } from '../../../../../../atoms/utils/layout/Box/FlexBox';

export interface FormInputDateSelectBoxLayoutProps {
  children: React.ReactElement[];
  labelWidth: string | number;
}

const FormInputDateSelectBoxLayout: FC<FormInputDateSelectBoxLayoutProps> = ({
  children,
  labelWidth,
}: FormInputDateSelectBoxLayoutProps) => {
  const elements = ['label', 'input'];
  const [label, input] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow alignItems="center">
      <FlexBoxRow width={labelWidth}>{label}</FlexBoxRow>
      {input}
    </FlexBoxRow>
  );
};
export default FormInputDateSelectBoxLayout;

import { CurrencyPairType } from '../../../../../types/CurrencyPair.type';
import { CryptoCurrencyRateProps } from './CryptoCurrencyRate.type';

class CryptoCurrencyRate {
  private currencyPair: CurrencyPairType;

  private buyRate: {
    original: number;
    feeIncluded: number;
  };

  private expiredAt: number;

  private last: number;

  private sellRate: {
    original: number;
    feeIncluded: number;
  };

  private tradeFeeRate: number;

  private props: CryptoCurrencyRateProps;

  constructor(currencyPair: CurrencyPairType, props: CryptoCurrencyRateProps) {
    this.currencyPair = currencyPair;
    this.buyRate = props.buyRate;
    this.expiredAt = props.expiredAt;
    this.last = props.last;
    this.sellRate = props.sellRate;
    this.tradeFeeRate = props.tradeFeeRate;
    this.props = props;
  }

  public getId = () => this.currencyPair;

  public getProps = () => this.props;

  public getRemainingTime = () => this.expiredAt - Date.now();
}

export default CryptoCurrencyRate;

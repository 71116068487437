/* eslint-disable import/no-cycle */

// DomainObject
import ContractTimeDepositHistory from '../ContractTimeDepositHistory/ContractTimeDepositHistory';
import DomainObjects from '../../../interface/Iterator/DomainObjects';
import SavingsAccount from '../../../bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

class ContractsTimeDepositHistories extends DomainObjects<
  ContractTimeDepositHistory
> {
  public getMaxAmount = () =>
    (this.items.length &&
      Math.max(...this.items.map((o) => o.getProps()?.amount))) ||
    0;

  public filterBySavingsAccount = (savingsAccount: SavingsAccount) => {
    if (!savingsAccount) return new ContractsTimeDepositHistories();

    const filtered = this.items.filter((item) =>
      item.isSavingsAccount(savingsAccount),
    );
    return new ContractsTimeDepositHistories(filtered);
  };
}

export default ContractsTimeDepositHistories;

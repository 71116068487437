import CryptoCurrencySellTransaction from '../CryptoCurrencySellTransaction/CryptoCurrencySellTransaction';
import { IIterable } from '../../../../../../interface/Iterator/IIterable';

class CryptoCurrencySellTransactions
  implements IIterable<CryptoCurrencySellTransaction> {
  private cryptoCurrencySellTransactions: CryptoCurrencySellTransaction[] = [];

  public add = (
    cryptoCurrencySellTransaction: CryptoCurrencySellTransaction,
  ) => {
    this.cryptoCurrencySellTransactions.push(cryptoCurrencySellTransaction);
  };

  public head = () => this.cryptoCurrencySellTransactions[0];

  public map = <T>(
    callback: (
      cryptoCurrencySellTransaction: CryptoCurrencySellTransaction,
    ) => T,
  ) => {
    return this.cryptoCurrencySellTransactions.map(
      (cryptoCurrencySellTransaction) =>
        callback(cryptoCurrencySellTransaction),
    );
  };

  public forEach = (
    callback: (
      cryptoCurrencySellTransaction: CryptoCurrencySellTransaction,
    ) => void,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const cryptoCurrencySellTransaction of this
      .cryptoCurrencySellTransactions) {
      // eslint-disable-next-line no-await-in-loop
      callback(cryptoCurrencySellTransaction);
    }
  };

  public forEachAsync = async (
    callback: (
      CryptoCurrencySellTransaction: CryptoCurrencySellTransaction,
    ) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const cryptoCurrencySellTransaction of this
      .cryptoCurrencySellTransactions) {
      // eslint-disable-next-line no-await-in-loop
      await callback(cryptoCurrencySellTransaction);
    }
  };

  public length = () => this.cryptoCurrencySellTransactions.length;
}
export default CryptoCurrencySellTransactions;

import { useState } from 'react';

export type SignInPageState = 'initial' | 'twoFactorAuth';

export const useSignInPageState = () => {
  const [pageState, setPageState] = useState<SignInPageState>('initial');

  return {
    pageState,
    setPageState,
  };
};

// DomainObject
import SavingsAccount from '../SavingsAccount/SavingsAccount';
import DomainObjects from '../../../interface/Iterator/DomainObjects';

class SavingsAccounts extends DomainObjects<SavingsAccount> {
  public filterById = (id: string) => {
    const filtered = this.items.filter(
      (item) => item.getRawAccountNumber() === id,
    );
    return filtered[0];
  };

  public mapKey = <T>(
    callback: (savingsAccount: SavingsAccount, key: number) => T,
  ) => {
    return this.items.map((item, key) => callback(item, key));
  };
}
export default SavingsAccounts;

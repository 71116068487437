import styled from 'styled-components';

export const Container = styled.div`
  border-radius: ${10 / 16}rem;
  width: ${79 / 16}rem;
  height: ${41 / 16}rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

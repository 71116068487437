// IUseCase
import ICryptoCurrencyTransactionViewUseCase from '../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyTransactionViewProvider/ICryptoCurrencyTransactionViewUseCase';

// IService
import ICryptoCurrencySellTransactionQueryService from '../../transaction/cryptoCurrencyTransaction/cryptoCurrencySellTransaction/ICryptoCurrencySellTransactionQueryService';
import ICryptoCurrencyBuyTransactionQueryService from '../../transaction/cryptoCurrencyTransaction/cryptoCurrencyBuyTransaction/ICryptoCurrencyBuyTransactionQueryService';
import ICryptoCurrencyDepositQueryService from '../../transaction/cryptoCurrencyTransaction/cryptoCurrencyDeposit/ICryptoCurrencyDepositQueryService';
import ICryptoCurrencyWithdrawalQueryService from '../../transaction/cryptoCurrencyTransaction/cryptoCurrencyWithdrawal/ICryptoCurrencyWithdrawalQueryService';

// Service
import CryptoCurrencySellTransactionQueryService from '../../../service/transaction/cryptoCurrencyTransaction/CryptoCurrencySell/CryptoCurrencySellTransactionQueryService';
import CryptoCurrencyBuyTransactionQueryService from '../../../service/transaction/cryptoCurrencyTransaction/CryptoCurrencyBuy/CryptoCurrencyBuyTransactionQueryService';
import CryptoCurrencyDepositQueryService from '../../../service/transaction/cryptoCurrencyTransaction/CryptoCurrencyDeposit/CryptoCurrencyDepositQueryService';
import CryptoCurrencyWithdrawalQueryService from '../../../service/transaction/cryptoCurrencyTransaction/CryptoCurrencyWithdrawal/CryptoCurrencyWithdrawalQueryService';

// IRepository
import ICryptoCurrencySellTransactionRepository from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencySellTransaction/ICryptoCurrencySellTransactionRepository';
import ICryptoCurrencyBuyTransactionRepository from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyBuyTransaction/ICryptoCurrencyBuyTransactionRepository';
import ICryptoCurrencyDepositRepository from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/ICryptoCurrencyDepositRepository';
import ICryptoCurrencyWithdrawalRepository from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyWithdrawal/ICryptoCurrencyWithdrawalRepository';

// DomainObject
import CryptoCurrencyWallet from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet';

class CryptoCurrencyTransactionViewUseCase
  implements ICryptoCurrencyTransactionViewUseCase {
  private cryptoCurrencySellTransactionQueryService: ICryptoCurrencySellTransactionQueryService;

  private cryptoCurrencyBuyTransactionQueryService: ICryptoCurrencyBuyTransactionQueryService;

  private cryptoCurrencyDepositQueryService: ICryptoCurrencyDepositQueryService;

  private cryptoCurrencyWithdrawalQueryService: ICryptoCurrencyWithdrawalQueryService;

  constructor(
    cryptoCurrencySellTransactionRepository: ICryptoCurrencySellTransactionRepository,
    cryptoCurrencyBuyTransactionRepository: ICryptoCurrencyBuyTransactionRepository,
    cryptoCurrencyDepositRepository: ICryptoCurrencyDepositRepository,
    cryptoCurrencyWithdrawalRepository: ICryptoCurrencyWithdrawalRepository,
  ) {
    this.cryptoCurrencySellTransactionQueryService = new CryptoCurrencySellTransactionQueryService(
      cryptoCurrencySellTransactionRepository,
    );

    this.cryptoCurrencyBuyTransactionQueryService = new CryptoCurrencyBuyTransactionQueryService(
      cryptoCurrencyBuyTransactionRepository,
    );

    this.cryptoCurrencyDepositQueryService = new CryptoCurrencyDepositQueryService(
      cryptoCurrencyDepositRepository,
    );

    this.cryptoCurrencyWithdrawalQueryService = new CryptoCurrencyWithdrawalQueryService(
      cryptoCurrencyWithdrawalRepository,
    );
  }

  public open = async (cryptoCurrencyWallet: CryptoCurrencyWallet) => {
    const [
      cryptoCurrencySellTransactions,
      cryptoCurrencyBuyTransactions,
      cryptoCurrencyDeposits,
      cryptoCurrencyWithdrawals,
    ] = await Promise.all([
      this.cryptoCurrencySellTransactionQueryService.findByWallet(
        cryptoCurrencyWallet,
      ),
      this.cryptoCurrencyBuyTransactionQueryService.findByWallet(
        cryptoCurrencyWallet,
      ),
      this.cryptoCurrencyDepositQueryService.findByWallet(cryptoCurrencyWallet),
      this.cryptoCurrencyWithdrawalQueryService.findByWallet(
        cryptoCurrencyWallet,
      ),
    ]);

    return {
      cryptoCurrencySellTransactions,
      cryptoCurrencyBuyTransactions,
      cryptoCurrencyDeposits,
      cryptoCurrencyWithdrawals,
    };
  };
}
export default CryptoCurrencyTransactionViewUseCase;

import axios from '../axios';

class FirebasePublicFunctions {
  public publicGET = async (requestUrl: string) => {
    const response = await axios.get(requestUrl);

    return response;
  };

  public publicPOST = async (
    requestUrl: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    objects: { [key: string]: any },
  ) => {
    const response = await axios.post(requestUrl, objects);

    return response;
  };
}

export default FirebasePublicFunctions;

import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const OrderTextStyle = {
  fontSize: 50,
  color: color.primary,
  fontWeight: fontWeight.bold,
  lineHeight: 50,
};

export const TitleStyle = {
  fontSize: 32,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

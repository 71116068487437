import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import TransferTemplateContainer from '../../common/TransferTemplateContainer';
import TransferTemplateContent from '../../common/TransferTemplateContent';
import Margin from '../../../../atoms/utils/layout/Margin';
import { FlexRow } from '../../../../atoms/utils/layout/Flex';

export interface TransferDebitCardConfirmTemplateLayoutProps {
  children: React.ReactElement[];
}

const TransferDebitCardConfirmTemplateLayout: FC<TransferDebitCardConfirmTemplateLayoutProps> = ({
  children,
}: TransferDebitCardConfirmTemplateLayoutProps) => {
  const elements = ['header', 'progressStep', 'description', 'table', 'form'];
  const [header, progressStep, description, table, form] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <TransferTemplateContainer>
      {header}
      <Margin top={50} />
      <FlexRow justifyContent="center">{progressStep}</FlexRow>
      <Margin top={100} />
      <TransferTemplateContent>
        {description}
        <Margin top={30} />
        {table}
        <Margin top={50} />
        {form}
      </TransferTemplateContent>
    </TransferTemplateContainer>
  );
};
export default TransferDebitCardConfirmTemplateLayout;

// Type
import { DebitCardV2RawDataType } from '../../../domain/debitCard/debitCardV2/DebitCardV2/DebitCardV2.type';

// Functions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// Constant
import {
  DEBIT_CARD_V2_GET_RAW_DATA_ALL_BY_SAVING_ACCOUNT_NUMBER,
  DEBIT_CARD_V2_GET_RAW_DATA_ALL_BY_USER_ID,
} from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// IRepository
import IDebitCardV2Repository from '../../../domain/debitCard/debitCardV2/IDebitCardV2Repository';

// DomainObject
import DebitCardV2s from '../../../domain/debitCard/debitCardV2/DebitCardV2s/DebitCardV2s';
import DebitCardV2Factory from '../../../domain/debitCard/debitCardV2/DebitCardV2/DebitCardV2Factory';
import { DebitCardV2State } from '../../../redux/reducers/debitCardV2sReducer';

class DebitCardV2Functions
  extends FirebasePrivateFunctions
  implements IDebitCardV2Repository {
  public findAllByUserId = async (userId: string) => {
    const endpoint = DEBIT_CARD_V2_GET_RAW_DATA_ALL_BY_USER_ID.replace(
      /:userId/,
      userId,
    );

    const response = await this.privateGET(endpoint);

    const rows = response.data.data as Array<DebitCardV2RawDataType>;

    return rows.reduce((debitCards, row) => {
      debitCards.add(DebitCardV2Factory.create(row.id, row.props));
      return debitCards;
    }, new DebitCardV2s());
  };

  public findAllBySavingsAccountNumber = async (
    savingsAccountNumber: string,
  ) => {
    const endpoint = DEBIT_CARD_V2_GET_RAW_DATA_ALL_BY_SAVING_ACCOUNT_NUMBER.replace(
      /:savingsAccountNumber/,
      savingsAccountNumber,
    );

    const response = await this.privateGET(endpoint);

    const rows = response.data.data as Array<DebitCardV2RawDataType>;

    return rows.reduce((debitCards, row) => {
      debitCards.add(
        DebitCardV2Factory.create(row.id, {
          ...row.props,
        }),
      );

      return debitCards;
    }, new DebitCardV2s());
  };

  public findRawAllByUserId = async (userId: string) => {
    const endpoint = DEBIT_CARD_V2_GET_RAW_DATA_ALL_BY_USER_ID.replace(
      /:userId/,
      userId,
    );

    const response = await this.privateGET(endpoint);

    const rows = response.data.data as Array<DebitCardV2RawDataType>;

    return rows.reduce<DebitCardV2State>((objs, row) => {
      Object.assign(objs, { [row.id]: row.props });
      return objs;
    }, {});
  };
}
export default DebitCardV2Functions;

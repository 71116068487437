import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle, TitleStyle, TitleSmallStyle } from './style';

// Component
import { TextSecondary } from '../../../../../../atoms/text2/Text2';
import ExampleImage from '../../../../../../atoms/image/ExampleImage';
import KycFormContainer from '../../../../common/KycFormContainer';
import ConsentFormSignatureExplanationLayout from './ConsentFormSignatureExplanationLayout';

const FORM_ID = `organisms.form.verification.FormConsentForm.signatureExplanation`;

const ConsentFormSignatureExplanation: FC = () => {
  const { t } = useTranslation();

  return (
    <KycFormContainer order={2} formId={FORM_ID}>
      <ConsentFormSignatureExplanationLayout>
        <TextSecondary key="description" theme={DescriptionStyle}>
          {t(`${FORM_ID}.description.line1`)}
        </TextSecondary>
        <TextSecondary key="subTitleStep1" theme={TitleStyle}>
          {t(`${FORM_ID}.subTitle.step1`)}
        </TextSecondary>
        <TextSecondary key="exampleText1" theme={TitleSmallStyle}>
          {t(`words.Example`)}
        </TextSecondary>
        <ExampleImage key="exampleImage1" type="signatureExample1" />
        <TextSecondary key="subTitleStep2" theme={TitleStyle}>
          {t(`${FORM_ID}.subTitle.step2`)}
        </TextSecondary>
        <TextSecondary key="exampleText2" theme={TitleSmallStyle}>
          {t(`words.Example`)}
        </TextSecondary>
        <ExampleImage key="exampleImage2" type="signatureExample2" />
      </ConsentFormSignatureExplanationLayout>
    </KycFormContainer>
  );
};

export default ConsentFormSignatureExplanation;

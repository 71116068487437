import { useState } from 'react';
import { CryptoCurrencyType } from '../../../../../../types/CryptoCurrency.type';

export const useCryptoCurrencyOption = (
  cryptoCurrency?: CryptoCurrencyType,
) => {
  const defaultCryptoCurrency = cryptoCurrency || CryptoCurrencyType.BTC;

  const [cryptoCurrencyOption, setCryptoCurrencyOption] = useState<
    CryptoCurrencyType
  >(defaultCryptoCurrency);

  return { cryptoCurrencyOption, setCryptoCurrencyOption };
};

// IRepository
import IUserAuthentication from '../../../domain/public/user/User/IUserAuthentication';

// Error
import UnAuthorizedError from '../../../utils/errors/UnAuthorizedError';
import InvalidDataFoundError from '../../../utils/errors/InvalidDataFoundError';
import { ErrorCodeType } from '../../../utils/errors/ErrorHandler/ErrorCode.type';

// Auth
import auth from '../../../infrastructure/firebase/firebaseAuthentication/firebaseAuth';

// Collection
import UsersCollection from '../../../infrastructure/firebase/firestore/collections/public/UsersCollection';

// DomainObject
import UserAuthorizedFactory from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedFactory';
import { UserAuthorizedProps } from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized.type';

class UserFirebaseAuth implements IUserAuthentication {
  public signIn = async (email: string, password: string) => {
    const authUser = await auth.signInWithEmailAndPassword(email, password);

    const { uid } = authUser.user as firebase.User;

    const userDoc = await new UsersCollection().fetchSpecific(uid);

    if (!userDoc)
      throw new InvalidDataFoundError(`User with '${uid}' is not found.`);

    const token = await auth.currentUser?.getIdTokenResult();

    if (!token || token.claims.admin)
      throw new UnAuthorizedError(
        ErrorCodeType.AUTHENTICATION_LOGIN_INVALID_ROLE,
        '',
      );

    const user = UserAuthorizedFactory.create(userDoc.id, {
      ...userDoc.data(),
      token: token.token || '',
    } as UserAuthorizedProps);

    return user;
  };
}
export default UserFirebaseAuth;

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import { InviterProps } from './Inviter.type';
import { InviterRankAProps } from '../InviterRankA/InviterRankA.type';
import { InviterRankDProps } from '../InviterRankD/InviterRankD.type';

// Factory
import InviterRankAFactory from '../InviterRankA/InviterRankAFactory';
import InviterRankDFactory from '../InviterRankD/InviterRankDFactory';

class InviterFactory {
  static create = (id: string, props: InviterProps) => {
    if ('commissionRate' in props)
      return InviterRankDFactory.create(id, props as InviterRankDProps);
    // totalReferralTimeDepositAmount: 0,
    // totalCommission: 0,
    // totalCommissionReceived: 0,
    // totalReferralChildrenTimeDepositAmount: 0,
    // );
    if ('rank' in props)
      return InviterRankAFactory.create(id, props as InviterRankAProps);

    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_SWITCH,
      {
        value: JSON.stringify(props),
        place: 'InviterFactory.create',
      },
    );
  };
}

export default InviterFactory;

import { DocumentTypeProps } from './DocumentType.type';

class DocumentType {
  private label: string;

  private value: string;

  private priority: number;

  private props: DocumentTypeProps;

  constructor(props: DocumentTypeProps) {
    this.label = props.label;
    this.value = props.value;
    this.priority = props.priority;
    this.props = props;
  }

  public getProps() {
    return this.props;
  }
}
export default DocumentType;

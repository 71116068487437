// Type
import { TypeOfT } from '../../../../../types/typeof/Translation.type';
import { BankFutureTransactionStatusType } from '../../../../../types/BankAccountTransaction.type';

// Constant
import { LENGTH_SAVINGS_ACCOUNT_NUMBER } from '../../../../../config/constants/business';

// DomainObject
import BankFutureDeposits from '../../../../../domain/transaction/bankTransaction/bankFutureTransaction/bankFutureDeposit/bankFutureDeposit/BankFutureDeposits/BankFutureDeposits';
import BankFutureDeposit from '../../../../../domain/transaction/bankTransaction/bankFutureTransaction/bankFutureDeposit/bankFutureDeposit/BankFutureDeposit/BankFutureDeposit';

// Value
import { FiatCurrencyType } from '../../../../../types/FiatCurrency.type';
import TimeZonedTimeFactory from '../../../../../value/datetime/TimeZonedTimeFactory';

type Object = {
  [key: string]: string | number | BankFutureTransactionStatusType;
};

export interface TdCommissionsTimeDeposit extends Object {
  id: string;
  amount: number;
  currency: FiatCurrencyType;
  timeDepositId: string;
  scheduledDate: string;
  status: BankFutureTransactionStatusType;
  statusString: string;
}

class TableCommissionsTimeDepositMapper {
  static domainToTd = (
    bankFutureDeposits: BankFutureDeposits,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    t: TypeOfT,
  ) => {
    const converter = (bankFutureDeposit: BankFutureDeposit) => {
      // Props
      const id = bankFutureDeposit.getFormattedId();
      const props = bankFutureDeposit.getProps();

      // Edit Data
      const timeDepositId = props.descriptionParameter.substr(
        0,
        LENGTH_SAVINGS_ACCOUNT_NUMBER + 1,
      );

      const scheduledDate = TimeZonedTimeFactory.createZeroOClock(
        props.scheduledDate,
      ).format('DD/MMM/YYYY');

      const statusString =
        BankFutureTransactionStatusType.paid === props.status
          ? 'Paid'
          : 'Unpaid';

      return {
        ...props,
        id,
        scheduledDate,
        timeDepositId,
        statusString,
      } as TdCommissionsTimeDeposit;
    };

    return bankFutureDeposits.map<TdCommissionsTimeDeposit>(converter);
  };
}

export default TableCommissionsTimeDepositMapper;

import React, { FC } from 'react';
import {
  Container,
  TransferDetailContainer,
  FormInputGroupContainer,
} from './style';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormInputTransferSavingsAccountDetailLayoutProps {
  children: React.ReactElement[];
}

const FormInputTransferSavingsAccountDetailLayout: FC<FormInputTransferSavingsAccountDetailLayoutProps> = ({
  children,
}: FormInputTransferSavingsAccountDetailLayoutProps) => {
  const elements = ['header', 'inputGroup'];
  const [header, inputGroup] = getElementsFromKeys(children, elements);

  return (
    <Container>
      {header}
      <Margin top={30}>
        <TransferDetailContainer>
          <FormInputGroupContainer>{inputGroup}</FormInputGroupContainer>
        </TransferDetailContainer>
      </Margin>
    </Container>
  );
};

export default FormInputTransferSavingsAccountDetailLayout;

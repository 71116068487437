// type
import { TransferDebitCardTransactionV2SeedProps } from './TransferDebitCardTransactionV2Seed.type';

// DomainObject
import TransferDebitCardTransactionV2 from '../TransferDebitCardTransactionV2/TransferDebitCardTransactionV2';

// ValueObject
import TransferDebitCardTransactionIdFactory from '../../../idManager/transactionId/TransferDebitCardTransactionId/TransferDebitCardTransactionIdFactory';

class TransferDebitCardTransactionV2Seed extends TransferDebitCardTransactionV2 {
  constructor(props: TransferDebitCardTransactionV2SeedProps) {
    super(
      TransferDebitCardTransactionIdFactory.createFromRawId(
        'TSTDUSD20210101000001000000',
        '',
      ),
      props,
    );
  }

  public getRequestParameterCreate = () => ({
    debitCardId: this.debitCardId,
    amount: this.amount,
    currency: this.currency,
    transferType: this.transferType,
  });
}

export default TransferDebitCardTransactionV2Seed;

import { FC } from 'react';
import { IIterable } from '../../../../interface/Iterator/IIterable';

// DomainObject
import DocumentType from '../DocumentType/DocumentType';

class DocumentTypes implements IIterable<DocumentType> {
  private items: DocumentType[] = [];

  constructor(items?: DocumentType[]) {
    this.items = items || [];
  }

  public add = (item: DocumentType) => {
    this.items.push(item);
  };

  public head = () => this.items[0];

  public map = <T>(callback: (documentType: DocumentType) => T) => {
    return this.items.map((documentType) => callback(documentType));
  };

  public forEach = (callback: (documentType: DocumentType) => void) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const documentType of this.items) {
      // eslint-disable-next-line no-await-in-loop
      callback(documentType);
    }
  };

  public forEachAsync = async (
    callback: (documentType: DocumentType) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const documentType of this.items) {
      // eslint-disable-next-line no-await-in-loop
      await callback(documentType);
    }
  };

  public mapComponent = (
    item: FC<{ documentType: DocumentType; i: number }>,
  ) => {
    return this.items.map((documentType, i) => item({ documentType, i }));
  };

  public sortAscByPriority = () => {
    const compare = (a: DocumentType, b: DocumentType) => {
      const aPriority = a.getProps().priority;
      const bPriority = b.getProps().priority;

      return aPriority > bPriority ? 1 : -1;
    };
    return new DocumentTypes(this.items.sort(compare));
  };
}
export default DocumentTypes;

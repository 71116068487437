import React, { FC, useContext } from 'react';
import CryptoCurrencyTradePageStateContext from '../../../enhancers/pageState/cryptoCurrency/CryptoCurrencyTradePageState/CryptoCurrencyTradePageStateContext';

// Error
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';

// Component
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import CryptoCurrencyTradeTemplate from '../../../components/templates/cryptonCurrency/trade/CryptoCurrencyTradeTemplate';
import CryptoCurrencyTradeConfirmTemplate from '../../../components/templates/cryptonCurrency/trade/CryptoCurrencyTradeConfirmTemplate';
import CryptoCurrencyTradeCompleteTemplate from '../../../components/templates/cryptonCurrency/trade/CryptoCurrencyTradeCompleteTemplate';

const CryptoCurrencyTradePage: FC = () => {
  const { pageState } = useContext(CryptoCurrencyTradePageStateContext);

  const TemplateComponent: FC = () => {
    switch (pageState) {
      case 'initial':
        return <CryptoCurrencyTradeTemplate />;
      case 'confirm':
        return <CryptoCurrencyTradeConfirmTemplate />;
      case 'complete':
        return <CryptoCurrencyTradeCompleteTemplate />;
      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_SWITCH,
          {
            value: pageState,
            place: `CryptoCurrencyTradePage`,
          },
        );
    }
  };

  return (
    <DashboardTemplate>
      <TemplateComponent />
    </DashboardTemplate>
  );
};

export default CryptoCurrencyTradePage;

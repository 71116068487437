import React, { FC, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import CorporateInformationFileContext from '../../../../../../../enhancers/fileHandler/verification/kycRequest/CorporateInformationFileProvider/CorporateInformationFileContext';
import UserAuthorizedContext from '../../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Cloud Storage
import { useStorageImage } from '../../../../../../../infrastructure/firebase/cloudStorage/useStorageImage';
import { getUploadFileUrl } from '../../../../../../../utils/helpers/fileHelper';

// Type
import { KycRequestUploadFileType } from '../../../../../../../types/KycRequest.type';
import { FileType } from '../../../../../../../types/File.type';

// Style
import { DescriptionStyle } from './style';

// Component
import { TextSecondary } from '../../../../../../atoms/text2/Text2';
import CheckPointList from '../../../../../list/CheckPointList';
import DropzoneArea from '../../../../../../molecules/dropzone2/DropzoneArea';
import KycFormContainer from '../../../../common/KycFormContainer';
import FormCorporateRegistryUploadLayout from './FormCorporateRegistryUploadLayout';

// ValueObject
import CorporateInformation from '../../../../../../../value/verification/kycRequest/KycRequestContent/CorporateInformation/CorporateInformation';

const FORM_ID = `organisms.form.verification.FormCorporateInformation.corporateRegistry`;

export interface FormCorporateRegistryUploadProps {
  corporateInformation?: CorporateInformation;
}

const FormCorporateRegistryUpload: FC<FormCorporateRegistryUploadProps> = ({
  corporateInformation,
}: FormCorporateRegistryUploadProps) => {
  const { t } = useTranslation();
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // Form
  const { setValue } = useFormContext();

  // FileHandler
  const { corporateRegistry, setCorporateRegistry } = useContext(
    CorporateInformationFileContext,
  );

  // Storage
  const corporateRegistryUrl = useStorageImage(
    corporateInformation?.getProps().corporateRegistryUrl,
  ).storageUrl;

  /* *
   *
   *  UseEffect
   *
   * */

  // Set corporateRegistryUrl when File is uploaded
  useEffect(() => {
    if (corporateRegistry && userAuthorized) {
      const storageFileUrl = getUploadFileUrl(
        userAuthorized,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (corporateRegistry as any).path,
        KycRequestUploadFileType.corporateRegistry,
      );
      setValue('corporateRegistryUrl', storageFileUrl);
    }
    // eslint-disable-next-line
  }, [corporateRegistry]);

  return (
    <KycFormContainer key="corporateRegistry" order={2} formId={FORM_ID}>
      <FormCorporateRegistryUploadLayout>
        <TextSecondary key="description" theme={DescriptionStyle}>
          {t(`${FORM_ID}.description`)}
        </TextSecondary>
        <DropzoneArea
          key="dropzone"
          name="corporateRegistry"
          acceptFileTypes={[FileType.pdf]}
          defaultFilePath={corporateRegistryUrl || ''}
          fileType={FileType.pdf}
          maxFileSize={10}
          setFile={setCorporateRegistry}
          width={667}
        />
        <CheckPointList
          key="checkPoint"
          title={t(`organisms.CheckPointList.title`)}
          checkPoints={[
            t(`organisms.CheckPointList.text.complete`),
            t(`organisms.CheckPointList.text.clear`),
            t(`organisms.CheckPointList.text.valid`),
          ]}
        />
      </FormCorporateRegistryUploadLayout>
    </KycFormContainer>
  );
};

export default FormCorporateRegistryUpload;

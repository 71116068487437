// IUseCase
import IDebitCardRequestCherryVisa01PayIssuanceFeeUseCase from '../../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01PayIssuanceFeeProvider/IDebitCardRequestCherryVisa01PayIssuanceFeeUseCase';

// IService
import IDebitCardRequestCherryVisa01UpdateService from './IDebitCardRequestCherryVisa01UpdateService';

// Service
import DebitCardRequestCherryVisa01UpdateService from '../../../../service/debitCard/debitCardRequest/debitCardRequestCherryVisa01/DebitCardRequestCherryVisa01UpdateService';

import { IDebitCardRequestCherryVisa01UpdateRepository } from '../../../../domain/debitCard/debitCardRequest/IDebitCardRequestCherryVisa01Repository';

// DomainObject
import DebitCardRequestCherryVisa01 from '../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01';

class DebitCardRequestCherryVisa01PayIssuanceFeeUseCase
  implements IDebitCardRequestCherryVisa01PayIssuanceFeeUseCase {
  private debitCardRequestCherryVisa01UpdateService: IDebitCardRequestCherryVisa01UpdateService;

  constructor(
    debitCardRequestCherryVisa01Repository: IDebitCardRequestCherryVisa01UpdateRepository,
  ) {
    this.debitCardRequestCherryVisa01UpdateService = new DebitCardRequestCherryVisa01UpdateService(
      debitCardRequestCherryVisa01Repository,
    );
  }

  executeUpdate = async (
    debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01,
  ) => {
    const updatedDebitCardRequestCherryVisa01 = await this.debitCardRequestCherryVisa01UpdateService.executePayIssuance(
      debitCardRequestCherryVisa01,
    );

    return {
      debitCardRequestCherryVisa01: updatedDebitCardRequestCherryVisa01,
    };
  };
}

export default DebitCardRequestCherryVisa01PayIssuanceFeeUseCase;

import { useState } from 'react';

export const useConsentFormFile = () => {
  const [consentFormFile, setConsentFormFile] = useState<File | undefined>();

  return {
    consentFormFile,
    setConsentFormFile,
  };
};

import React, { FC, useContext } from 'react';
import InvitationCodesContext from '../../../../domain/invite/invitationCode/InvitationCodes/InvitationCodesContext';

import InvitationCodeCard from '../../../molecules/card/invite/invitationCode/InvitationCodeCard';

// Layout
import Margin from '../../../atoms/utils/layout/Margin';

// DomainObject
import InvitationCode from '../../../../domain/invite/invitationCode/InvitationCode/InvitationCode';

const InvitationCardCodeList: FC = () => {
  const { invitationCodes } = useContext(InvitationCodesContext);

  const getListItem: FC<{ invitationCode: InvitationCode; i: number }> = ({
    invitationCode,
    i,
  }) => (
    <Margin bottom={60} key={`item${i}`}>
      <InvitationCodeCard invitationCode={invitationCode} />
    </Margin>
  );
  return <>{invitationCodes.mapComponent(getListItem)}</>;
};

export default InvitationCardCodeList;

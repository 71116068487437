import React, { FC } from 'react';
import KycStatusTemplate from '../../../components/templates/kycRequest/KycStatusTemplate';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';

const KycStatusPage: FC = () => {
  return (
    <DashboardTemplate>
      <KycStatusTemplate />
    </DashboardTemplate>
  );
};

export default KycStatusPage;

import Joi from '@hapi/joi';

// Type
import { BankFutureDepositType } from '../../../../../../../types/BankAccountTransaction.type';
import {
  BankFutureTransactionProps,
  BankFutureTransactionPropsFormat,
} from '../../../bankFutureTransaction/BankFutureTransaction/BankFutureTransaction.type';

export interface BankFutureDepositProps extends BankFutureTransactionProps {
  bankFutureDepositType: BankFutureDepositType;
}

export const BankFutureDepositPropsFormat = {
  ...BankFutureTransactionPropsFormat,

  bankFutureDepositType: Object.keys(BankFutureDepositType)
    .reduce((joi, type) => joi.valid(type), Joi.string())
    .required(),
};

// IUseCase
import IContractTimeDepositCreateUseCase, {
  ContractTimeDepositCreateParameter,
} from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositCreateProvider/IContractTimeDepositCreateUseCase';

// IService
import IAuthenticatorVerifyService from '../../authenticator/authenticator/interface/IAuthenticatorVerifyService';
import IContractTimeDepositCreateService from './interface/IContractTimeDepositCraeteService';

// Service
import ContractTimeDepositCreateService from '../../../service/contract/contractTimeDeposit/ContractTimeDepositCreateService';
import AuthenticatorVerifyService from '../../../service/authenticator/AuthenticatorVerifyService';

// ICreator
import { IContractTimeDepositCreateRepository } from '../../../domain/contract/contractTimeDeposit/IContractTimeDepositRepository';

// DomainObject
import ContractTimedDepositSeed from '../../../domain/contract/contractTimeDeposit/ContractTimeDepositSeed/ContractTimeDepositSeed';
import UserVerified from '../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';

class ContractTimeDepositCreateUseCase
  implements IContractTimeDepositCreateUseCase {
  private contractTimeDepositCreateService: IContractTimeDepositCreateService;

  private authenticatorVerifyService: IAuthenticatorVerifyService;

  constructor(
    authenticatorVerifyService: IAuthenticatorVerifyService,
    contractTimeDepositCreateRepository: IContractTimeDepositCreateRepository,
  ) {
    this.contractTimeDepositCreateService = new ContractTimeDepositCreateService(
      contractTimeDepositCreateRepository,
    );

    this.authenticatorVerifyService = new AuthenticatorVerifyService(
      authenticatorVerifyService,
    );
  }

  public create = (params: ContractTimeDepositCreateParameter) => {
    const contractTimeDepositSeed = this.contractTimeDepositCreateService.create(
      params,
    );

    return { contractTimeDepositSeed };
  };

  public executeCreate = async (
    user: UserVerified,
    contractTimeDepositSeed: ContractTimedDepositSeed,
    code: string,
  ) => {
    // Authenticator
    await this.authenticatorVerifyService.verify(user, code);

    // Create ContractTimeDeposit
    const contractTimeDeposit = await this.contractTimeDepositCreateService.executeCreate(
      contractTimeDepositSeed,
    );

    return { contractTimeDeposit };
  };
}
export default ContractTimeDepositCreateUseCase;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getSymbolFromCurrency,
  convertMoneyFormat,
} from '../../../../utils/helpers/currencyHelper';
import { CurrencyType } from '../../../../types/Currency.type';
import {
  CurrencyPairType,
  CurrencyPairProps,
} from '../../../../types/CurrencyPair.type';
import { LabelStyle, CurrencyStyle, RateStyle } from './style';
import { TextPrimary, TextSecondary } from '../../../atoms/text2/Text2';
import CurrencyExchangeRateLayout from './CurrencyExchangeRateLayout';

export interface CurrencyExchangeRateProps {
  baseCurrency: CurrencyType;
  unitCurrency?: CurrencyType;
  rate?: number;
}

const CurrencyExchangeRate: FC<CurrencyExchangeRateProps> = ({
  baseCurrency,
  unitCurrency,
  rate,
}: CurrencyExchangeRateProps) => {
  const { t } = useTranslation();
  const exchangeMarket = unitCurrency && `${unitCurrency}${baseCurrency}`;

  return (
    <CurrencyExchangeRateLayout>
      <TextPrimary key="label" theme={LabelStyle}>
        {t(`molecules.textGroup.currencyExchangeRate.label`, {
          rateUnit:
            exchangeMarket && CurrencyPairProps[exchangeMarket].rateUnit,
        })}
      </TextPrimary>
      <TextSecondary key="currency" theme={CurrencyStyle}>
        {getSymbolFromCurrency(baseCurrency)}
      </TextSecondary>
      <TextSecondary key="rate" theme={RateStyle}>
        {rate && convertMoneyFormat(rate, exchangeMarket as CurrencyPairType)}
      </TextSecondary>
    </CurrencyExchangeRateLayout>
  );
};

export default CurrencyExchangeRate;

import { AssetProps } from './Asset.type';
import { CurrencyType } from '../../../../types/Currency.type';

abstract class Asset {
  protected currency: CurrencyType;

  protected single: {
    balance: number;
    pendingBalance: number;
  };

  protected createdAt: number;

  protected updatedAt: number;

  protected props: AssetProps;

  constructor(currency: CurrencyType, props: AssetProps) {
    this.currency = currency;
    this.single = props.single;
    this.createdAt = props.createdAt || Date.now();
    this.updatedAt = props.updatedAt || Date.now();
    this.props = props;
  }

  public getCurrency = () => this.currency;

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}
export default Asset;

import React, { FC } from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export interface ArrowIconProps {
  type: 'forward' | 'back';
  size?: string | number;
  color?: string;
  onClick?: () => void;
}

const ArrowIcon: FC<ArrowIconProps> = ({
  type,
  size = 16,
  color = '#000000',
  onClick,
}: ArrowIconProps) => {
  switch (type) {
    case 'forward':
      return (
        <ArrowForwardIosIcon
          style={{ fontSize: size, color }}
          onClick={onClick}
        />
      );
    case 'back':
      return (
        <ArrowBackIosIcon style={{ fontSize: size, color }} onClick={onClick} />
      );
    default:
      return <></>;
  }
};
export default ArrowIcon;

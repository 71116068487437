import { useCallback, useEffect, useMemo, useState } from 'react';
import { checkInternetConnection } from '../../../../../utils/helpers/connection';

// Error
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from '../../../../../utils/errors/ErrorHandler/IErrorHandler';

// Type
import { ContractTimeDepositStatusType } from '../../../../../types/ContractTimeDeposit.type';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';

// UseCase
import ContractTimeDepositViewUseCase from '../../../../../useCases/contract/contractTimeDeposit/ContractTimeDepositViewUseCase';

// Repository
import ContractTimeDepositFunctions from '../../../../../repository/contract/contractTimeDeposit/ContractTimeDepositFunctions';
import SavingsAccountFunctions from '../../../../../repository/bankAccount/savingsAccount/SavingsAccountFunctions';

// DomainObject
import { useContractsTimeDeposit } from '../../../../../domain/contract/contractTimeDeposit/ContractsTimeDeposit/useContractsTimeDeposit';
import { useSavingsAccounts } from '../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/useSavingsAccounts';
import UserAuthorized from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

// SelectBox
import { useSavingsAccountOption } from '../../../../../components/molecules/selectBox/option/option/SavingsAccountOption/useSavingsAccountOption';

export const useContractTimeDepositViewUseCase = (
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  SelectBox
   *
   * */
  const {
    savingsAccountOption,
    setSavingsAccountOption,
  } = useSavingsAccountOption();

  /* *
   *
   *  Domain Object
   *
   * */
  const { savingsAccounts, setSavingsAccounts } = useSavingsAccounts();
  const {
    contractsTimeDeposit,
    setContractsTimeDeposit,
  } = useContractsTimeDeposit();

  // Filtered
  const contractsTimeDepositFiltered = useMemo(
    () =>
      contractsTimeDeposit
        .filterBySavingsAccount(savingsAccountOption)
        .filterByStatus(ContractTimeDepositStatusType.active)
        .sortAscByMaturityDate(),
    [contractsTimeDeposit, savingsAccountOption],
  );

  /* *
   *
   *  Repository
   *
   * */
  const contractTimeDepositRepository = new ContractTimeDepositFunctions();
  const savingsAccountRepository = new SavingsAccountFunctions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new ContractTimeDepositViewUseCase(
    contractTimeDepositRepository,
    savingsAccountRepository,
  );

  /* *
   *
   *  Methods
   *
   * */
  const openContractTimeDeposit = useCallback(async () => {
    if (!userAuthorized) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const output = await useCase.open(userAuthorized.getId());

      // Setter
      setContractsTimeDeposit(output.contractsTimeDeposit);
      setSavingsAccounts(output.savingsAccounts);

      setState(SUCCESS);
    } catch (error) {
      const handler: IErrorHandler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized]);

  /* *
   *
   *  UseEffect
   *
   * */

  // OpenPage
  useEffect(() => {
    let isMounted = true;

    if (userAuthorized && isMounted) openContractTimeDeposit();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [userAuthorized]);

  // Initial setup (SavingsAccounts is fetched once)
  useEffect(() => {
    setSavingsAccountOption(savingsAccounts.head());
    // eslint-disable-next-line
  }, [savingsAccounts]);

  return {
    state,
    setState,
    contractsTimeDepositFiltered,
    setContractsTimeDeposit,
    savingsAccounts,
    savingsAccountOption,
    setSavingsAccountOption,
  };
};

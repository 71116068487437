import React, { FC, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import CherryVisa01ProofOfIdentityFileContext from '../../../../../../enhancers/fileHandler/debitCard/debitCardRequest/ProofOfIdentityFileProvider/CherryVisa01ProofOfIdentityFileContext';
import UserAuthenticatedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Type
import { FileType } from '../../../../../../types/File.type';

// Style
import { DescriptionStyle, TitleSmallStyle } from './style';

// Component
import { TextSecondary } from '../../../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../../../atoms/text2/LineBreakReplaced';
import CheckPointList from '../../../../list/CheckPointList';
import DropzoneArea from '../../../../../molecules/dropzone2/DropzoneArea';
import ExampleImage from '../../../../../atoms/image/ExampleImage';
import DebitCardRequestFormContainer from '../../../common/DebitCardRequestFormContainer';
import FormPassportUploadLayout from './FormPassportUploadLayout';

// ValueObject
import CherryVisa01ProofOfIdentity from '../../../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentity';

const FORM_ID = `organisms.form.debitCards.FormProofOfIdentity.passportUpload`;

export interface PassportUploadFormProps {
  proofOfIdentity?: CherryVisa01ProofOfIdentity;
}

const FormPassportUpload: FC<PassportUploadFormProps> = ({
  proofOfIdentity,
}: PassportUploadFormProps) => {
  const { t } = useTranslation();
  const { userAuthorized } = useContext(UserAuthenticatedContext);

  // Form
  const { setValue } = useFormContext();

  // FileHandler
  const { passport, setPassport } = useContext(
    CherryVisa01ProofOfIdentityFileContext,
  );

  const defaultFilePath = useMemo(() => {
    const uploadedFile = proofOfIdentity?.getProps().passport.passport;

    const url = uploadedFile ? URL.createObjectURL(uploadedFile) : '';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return uploadedFile && `${url}`;
  }, [proofOfIdentity]);

  /* *
   *
   *  UseEffect
   *
   * */

  useEffect(() => {
    if (passport && userAuthorized) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const uploadedFilePath = (passport as any).path;

      setValue('passportUrl', uploadedFilePath);
    }
    // eslint-disable-next-line
  }, [passport, userAuthorized]);

  return (
    <DebitCardRequestFormContainer order={5} formId={FORM_ID}>
      <FormPassportUploadLayout>
        <TextSecondary key="description" theme={DescriptionStyle}>
          <LineBreakReplaced text={t(`${FORM_ID}.description`)} />
        </TextSecondary>
        <DropzoneArea
          key="dropzone"
          name="passport"
          urlName="passportUrl"
          acceptFileTypes={[FileType.png, FileType.jpeg]}
          defaultFilePath={defaultFilePath ?? ''}
          maxFileSize={2}
          setFile={setPassport}
          width={667}
        />
        <TextSecondary key="exampleTitle" theme={TitleSmallStyle}>
          {t(`words.Example`)}
        </TextSecondary>
        <ExampleImage key="exampleImage" type="passportBrown" />
        <CheckPointList
          key="checkPoint"
          title={t(`organisms.CheckPointList.title`)}
          checkPoints={[
            t(`organisms.CheckPointList.text.complete`),
            t(`organisms.CheckPointList.text.clear`),
            t(`organisms.CheckPointList.text.valid`),
          ]}
        />
      </FormPassportUploadLayout>
    </DebitCardRequestFormContainer>
  );
};

export default FormPassportUpload;

import React, { useState } from 'react';

export interface FormInputEmailEditConfirm {
  code: string;
}

export const useFormInputEmailEditConfirm = () => {
  const [formInput, setFormInput] = useState<FormInputEmailEditConfirm>();

  return {
    formInput,
    setFormInput,
  };
};

export const FormInputEmailEditConfirmContext = React.createContext<{
  formInput?: FormInputEmailEditConfirm;
  setFormInput: (formInput?: FormInputEmailEditConfirm) => void;
}>({
  formInput: undefined,

  setFormInput: () => undefined,
});

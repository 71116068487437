import { useState } from 'react';

// Lib
import { executeWithTimeout } from '../../../../../utils/helpers/timeout';
import { checkInternetConnection } from '../../../../../utils/helpers/connection';

// Error
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// Types
import {
  FormInputTransferDebitCard,
  useFormInputTransferDebitCard,
} from '../../../../../components/organisms/form/transfer/debitCard/FormTransferDebitCard/FormInputTransferDebitCard.type';

// Constant
import { REQUEST_TIMEOUT } from '../../../../../config/constants/business';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';

// UseCase
import TransferDebitCardCreateUseCase from '../../../../../useCases/transfer/debitCard/TransferDebitCardCreateUseCase';

// Repository & API
import AuthenticatorFunctions from '../../../../../repository/authenticator/AuthenticatorFunctions';
import TransferDebitCardTransactionV2Functions from '../../../../../repository/transfer/TransferDebitCardTransactionV2Functions';

// DomainObject
import { useTransferDebitCardTransactionV2Seed } from '../../../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2Seed/useTransferDebitCardTransactionV2Seed';
import { useTransferDebitCardTransactionV2 } from '../../../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2/useTransferDebitCardTransactionV2';
import DebitCardV2 from '../../../../../domain/debitCard/debitCardV2/DebitCardV2/DebitCardV2';
import SavingsAccount from '../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';
import UserVerified from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';

// Option
import { useDebitCardOption } from '../../../../../components/molecules/selectBox/option/option/DebitCardOption/useDebitCardOption';

export const useTransferDebitCardCreateUseCase = (user?: UserVerified) => {
  const [state, setState] = useState<string>(INITIAL);
  const formInputTransferDebitCardContext = useFormInputTransferDebitCard();

  /* *
   *
   *  DomainObject
   *
   * */
  const {
    transferDebitCardTransactionV2Seed,
    setTransferDebitCardTransactionV2Seed,
  } = useTransferDebitCardTransactionV2Seed();
  const {
    transferDebitCardTransactionV2,
    setTransferDebitCardTransactionV2,
  } = useTransferDebitCardTransactionV2();

  // Option
  const { debitCardOption, setDebitCardOption } = useDebitCardOption();

  /* *
   *
   *  Repository
   *
   * */
  const authenticatorVerifier = new AuthenticatorFunctions();
  const transferDebitCardTransactionV2Repository = new TransferDebitCardTransactionV2Functions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new TransferDebitCardCreateUseCase(
    authenticatorVerifier,
    transferDebitCardTransactionV2Repository,
  );

  /* *
   *
   *  Methods
   *
   * */
  const createTransferDebitCard = async (
    formInput: FormInputTransferDebitCard,
    savingsAccount: SavingsAccount,
    debitCard: DebitCardV2,
    callback?: () => void,
  ) => {
    if (!user) return;

    setState(IS_LOADING);

    try {
      checkInternetConnection();

      const { transferDebitCardType } = formInput;

      // Execute
      const output = await useCase.create({
        ...formInput,
        user,
        savingsAccount,
        debitCard,
        amount: Number(formInput.amount.replace(/,/g, '')),
        transferDebitCardType,
      });

      setTransferDebitCardTransactionV2Seed(
        output.transferDebitCardTransactionSeed,
      );

      if (callback) callback();

      setDebitCardOption(debitCard);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  // UseCase.executeCreate
  const executeCreateTransferDebitCard = async (
    code: string,
    callback?: () => void,
  ) => {
    setState(IS_LOADING);

    try {
      checkInternetConnection();

      const output = await executeWithTimeout(
        useCase.executeCreate(user!, transferDebitCardTransactionV2Seed!, code),
        REQUEST_TIMEOUT,
      );

      setTransferDebitCardTransactionV2(output.transferDebitCardTransaction);

      if (callback) callback();

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  return {
    state,
    setState,
    createTransferDebitCard,
    executeCreateTransferDebitCard,
    transferDebitCardTransactionSeed: transferDebitCardTransactionV2Seed,
    transferDebitCardTransaction: transferDebitCardTransactionV2,
    debitCardOptionContext: {
      debitCardOption,
      setDebitCardOption,
    },
    formInputTransferDebitCardContext,
  };
};

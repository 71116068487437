import color from '../../../../atoms/utils/const/color';

export const TableVerticalStyle = {
  th: {
    backgroundColor: color.background.ghostWhite,
    borderBottom: `${1 / 16}rem solid ${color.border.white}`,
  },
  td: {
    backgroundColor: color.background.white,
    borderBottom: `${1 / 16}rem solid ${color.border.gray}`,
    textAlign: 'right',
  },
};

export const TableVerticalLayout = {
  rowWidth: 775,
  rowHeight: 20,
  thWidth: 500,
  tdWidth: 275,
  tdPadding: 0,
  thPadding: 0,
};

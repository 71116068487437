import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormCorporateAddressLayoutProps {
  children: React.ReactElement[];
}

const FormCorporateAddressLayout: FC<FormCorporateAddressLayoutProps> = ({
  children,
}: FormCorporateAddressLayoutProps) => {
  const elements = [
    'address',
    'documentType',
    'documents',
    'shippingAddress',
    'button',
  ];
  const [
    address,
    documentType,
    documents,
    shippingAddress,
    button,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn width={1200} height="auto">
      {address}
      <Margin top={60} />
      {documentType}
      <Margin top={60} />
      {documents}
      <Margin top={60} />
      {shippingAddress}
      <Margin top={60} />
      {button}
      <Margin top={83} />
    </FlexBoxColumn>
  );
};
export default FormCorporateAddressLayout;

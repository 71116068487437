// Type
import { ContractTimeDepositRenewalReservationProps } from './ContractTimeDepositRenewalReservation.type';

class ContractTimeDepositRenewalReservation {
  private props: ContractTimeDepositRenewalReservationProps;

  private createdAt: number;

  private updatedAt: number;

  constructor(props: ContractTimeDepositRenewalReservationProps) {
    this.props = props;
    this.createdAt = props.createdAt ?? Date.now();
    this.updatedAt = props.updatedAt ?? Date.now();
  }

  public getProps = () => {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  };
}
export default ContractTimeDepositRenewalReservation;

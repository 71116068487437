// Constant
export const KEY_CODE_BACKSPACE = 8;
export const KEY_CODE_TAB = 9;
export const KEY_CODE_SHIFT = 16;

/* *
 *
 *  isBackspace
 *
 *  @param keyboardInput number
 *
 *  @return boolean
 *
 * */

export const isBackSpace = (keyBoardInput: number) =>
  keyBoardInput === KEY_CODE_BACKSPACE;

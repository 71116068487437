// Types
import { TransferSavingsAccountType } from '../../../types/Transfer.type';
import { CurrencyType } from '../../../types/Currency.type';

// IRepository
import IFeeTransferSavingsAccountRepository from '../../../domain/settings/transactionFee/FeeTransferSavingsAccount/IFeeTransferSavingsAccountRepository';

// Collection
import FeesTransferSavingsAccountCollection from '../../../infrastructure/firebase/firestore/collections/settings/TransactionFees/FeesTransferSavingsAccountCollection';

// DomainObject
import FeeTransferSavingsAccount from '../../../domain/settings/transactionFee/FeeTransferSavingsAccount/FeeTransferSavingsAccount';

// Others
import InvalidDataFoundError from '../../../utils/errors/InvalidDataFoundError';

class FeeTransferSavingsAccountFirestore
  implements IFeeTransferSavingsAccountRepository {
  public findById = async (transferType: string) => {
    const feesTransferSavingsAccountCollection = new FeesTransferSavingsAccountCollection();

    const doc = await feesTransferSavingsAccountCollection.fetchSpecific(
      transferType,
    );

    if (!doc)
      throw new InvalidDataFoundError(
        `FeeTransferSavingsAccount for type '${transferType}' is not found.`,
      );

    return new FeeTransferSavingsAccount(
      transferType as TransferSavingsAccountType,
      {
        ...(doc.data() as { currency: CurrencyType; fee: number }),
      },
    );
  };
}
export default FeeTransferSavingsAccountFirestore;

// Lib
import { enoughBalance, min, required } from '../validator';

// Type
import { TypeOfT } from '../../../../types/typeof/Translation.type';

const contractTimeDepositAddValidation = (t: TypeOfT) => ({
  amount: (minDeposit: number, balance: number) => ({
    required: required(`Amount`, t),
    validate: {
      enoughBalance: enoughBalance(balance, t),
      min: min(`Deposit Amount`, minDeposit ?? Number.MIN_VALUE, t),
    },
  }),
});

export default contractTimeDepositAddValidation;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Lib
import { isEmpty } from 'lodash';
import { convertRem } from '../../utils/helper/helper';

// Type
import { DebitCardRequestCherryVisa01StepStatusType } from '../../../../types/DebitCardRequestCherryVisa01.type';

// Constant
import color from '../../utils/const/color';

// LayoutComponent
import { FlexBoxRow } from '../../utils/layout/Box/FlexBox';
import Margin from '../../utils/layout/Margin';

// Component
import { TextSecondary, TextThemeProps } from '../../text2/Text2';
import ArrowIcon from '../../icon/ArrowIcon';
import Display from '../../div/Display';

export interface DebitCardRequestStatusSquareButtonProps {
  height: string | number;
  marginBetween: string | number;
  onClick?: () => void;
  status: DebitCardRequestCherryVisa01StepStatusType;
  theme: {
    text: TextThemeProps;
    icon: {
      color: string;
      size: string | number;
    };
  };
  width: string | number;
}

const DebitCardRequestStatusSquareButton: FC<DebitCardRequestStatusSquareButtonProps> = ({
  height,
  marginBetween,
  onClick = () => undefined,
  status,
  theme,
  width,
}: DebitCardRequestStatusSquareButtonProps) => {
  const { t } = useTranslation();
  let [buttonText, backgroundColor] = ['', ''];
  let disabled = false;

  switch (status) {
    case DebitCardRequestCherryVisa01StepStatusType.initial:
      [buttonText, backgroundColor] = [
        t(`atoms.button.debitCardRequestStepStatus.${status}`),
        '#15609B',
      ];
      break;
    case DebitCardRequestCherryVisa01StepStatusType.disabled:
      [buttonText, backgroundColor] = ['', color.background.quartz];
      disabled = true;
      break;
    case DebitCardRequestCherryVisa01StepStatusType.rejected:
      [buttonText, backgroundColor] = [
        t(`atoms.button.debitCardRequestStepStatus.${status}`),
        '#299ADF',
      ];
      break;
    case DebitCardRequestCherryVisa01StepStatusType.onDelivery:
      [buttonText, backgroundColor] = [
        t(`atoms.button.debitCardRequestStepStatus.${status}`),
        '#2C8A9D',
      ];
      break;
    default:
      [buttonText, backgroundColor] = ['', ''];
      disabled = true;
  }

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        height: convertRem(height),
        outline: 'none',
        width: convertRem(width),
      }}
    >
      <FlexBoxRow
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor }}
      >
        <FlexBoxRow height="auto" width="auto" alignItems="center">
          <TextSecondary theme={theme.text}>{buttonText}</TextSecondary>
          <Margin left={marginBetween} />
          <Display
            isDisplay={!isEmpty(buttonText)}
            style={{
              paddingTop: `${5 / 16}rem`,
            }}
          >
            <ArrowIcon
              type="forward"
              color={theme.icon.color}
              size={convertRem(theme.icon.size)}
            />
          </Display>
        </FlexBoxRow>
      </FlexBoxRow>
    </button>
  );
};

export default DebitCardRequestStatusSquareButton;

import { useState } from 'react';
import { TdTransferDebitCardTransactionHistory } from '../../history/TableTransferDebitCardTransactionHistory/TableTransferDebitCardTransactionHistoryMapper';

export const useSelectRowTableTopUp = (
  row?: TdTransferDebitCardTransactionHistory,
) => {
  const [selectRowTableTopUp, setSelectRowTableTopUp] = useState<
    TdTransferDebitCardTransactionHistory | undefined
  >(row);

  return {
    selectRowTableTopUp,
    setSelectRowTableTopUp,
  };
};

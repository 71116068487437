import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexRow } from '../../../../atoms/utils/layout/Flex';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface FormSignUpLayoutProps {
  children: React.ReactElement[];
}

const FormSignUpLayout: FC<FormSignUpLayoutProps> = ({
  children,
}: FormSignUpLayoutProps) => {
  const elements = [
    'email',
    'invitationCode',
    'checkbox',
    'checkboxText',
    'button',
  ];
  const [
    email,
    invitationCode,
    checkbox,
    checkboxText,
    button,
  ] = getElementsFromKeys(children, elements);

  return (
    <>
      {email}
      <Margin top={60}> {invitationCode}</Margin>
      <Margin top={60}>
        <FlexRow alignItems="center">
          {checkbox}
          <Margin left={12}>
            <FlexRow>{checkboxText}</FlexRow>
          </Margin>
        </FlexRow>
      </Margin>
      <Margin top={50}>{button}</Margin>
    </>
  );
};

export default FormSignUpLayout;

// IUseCase
import { ContractTimeDepositRenewalReservationCreateParameter } from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationCreateProvider/IContractTimeDepositRenewalReservationCreateUseCase';
import { ContractTimeDepositRenewalReservationUpdateParameter } from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationUpdateProvider/IContractTimeDepositRenewalReservationUpdateUseCase';
import { ContractTimeDepositRenewalReservationDeleteParameter } from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationDeleteProvider/IContractTimeDepositRenewalReservationDeleteUseCase';

// IService
import IContractTimeDepositUpdateService from '../../../useCases/contract/contractTimeDeposit/interface/IContractTimeDepositUpdateService';

// IRepository
import { IContractTimeDepositUpdateRepository } from '../../../domain/contract/contractTimeDeposit/IContractTimeDepositRepository';

// DomainObject
import ContractTimeDeposit from '../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';

class ContractTimeDepositUpdateService
  implements IContractTimeDepositUpdateService {
  private contractTimeDepositUpdateRepository: IContractTimeDepositUpdateRepository;

  constructor(
    contractTimeDepositUpdateRepository: IContractTimeDepositUpdateRepository,
  ) {
    this.contractTimeDepositUpdateRepository = contractTimeDepositUpdateRepository;
  }

  public createRenewalReservation = (
    params: ContractTimeDepositRenewalReservationCreateParameter,
  ) => {
    const {
      contractTimeDeposit,
      timeDeposit,
      amountIncreased,
      isAnnualPayOut,
    } = params;

    const { term } = timeDeposit.getProps();

    const { renewalReservation } = contractTimeDeposit.getProps();

    return renewalReservation
      ? contractTimeDeposit.updateRenewalReservation(
          term,
          amountIncreased,
          isAnnualPayOut,
        )
      : contractTimeDeposit.createRenewalReservation(
          term,
          amountIncreased,
          isAnnualPayOut,
        );
  };

  public updateRenewalReservation = (
    params: ContractTimeDepositRenewalReservationUpdateParameter,
  ) => {
    const {
      contractTimeDeposit,
      timeDeposit,
      amountIncreased,
      isAnnualPayOut,
    } = params;

    const { term } = timeDeposit.getProps();

    return contractTimeDeposit.updateRenewalReservation(
      term,
      amountIncreased,
      isAnnualPayOut,
    );
  };

  public deleteRenewalReservation = (
    params: ContractTimeDepositRenewalReservationDeleteParameter,
  ) => {
    const { contractTimeDeposit } = params;

    return contractTimeDeposit.deleteRenewalReservation();
  };

  public executeCreateRenewalReservation = async (
    contractTimeDeposit: ContractTimeDeposit,
  ) => {
    const contractTimeDepositUpdated = await this.contractTimeDepositUpdateRepository.createRenewalReservation(
      contractTimeDeposit,
    );

    return contractTimeDepositUpdated;
  };

  public executeUpdateRenewalReservation = async (
    contractTimeDeposit: ContractTimeDeposit,
  ) => {
    const contractTimeDepositUpdated = await this.contractTimeDepositUpdateRepository.updateRenewalReservation(
      contractTimeDeposit,
    );

    return contractTimeDepositUpdated;
  };

  public executeDeleteRenewalReservation = async (
    contractTimeDeposit: ContractTimeDeposit,
  ) => {
    const contractTimeDepositUpdated = await this.contractTimeDepositUpdateRepository.deleteRenewalReservation(
      contractTimeDeposit,
    );

    return contractTimeDepositUpdated;
  };
}

export default ContractTimeDepositUpdateService;

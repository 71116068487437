import Joi from '@hapi/joi';

// Type
import { CryptoCurrencyType } from '../../../../types/CryptoCurrency.type';
import { AssetProps, AssetPropsFormat } from '../../asset/Asset/Asset.type';

export type AssetCryptoCurrencyProps = AssetProps;

export const assetCryptoCurrencyInitialProps: AssetCryptoCurrencyProps = {
  single: {
    balance: 0,
    pendingBalance: 0,
  },
  createdAt: Date.now(),
  updatedAt: Date.now(),
};

export interface AssetCryptoCurrencyPrimaryKey {
  userId: string;
  currency: CryptoCurrencyType;
}

export const AssetCryptoCurrencyPropsFormat = {
  ...AssetPropsFormat,

  id: Object.keys(CryptoCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),
};

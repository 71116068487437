import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  AssetCryptoCurrencyProps,
  AssetCryptoCurrencyPropsFormat,
} from './AssetCryptoCurrency.type';
import { CryptoCurrencyType } from '../../../../types/CryptoCurrency.type';

// DomainObject
import AssetCryptoCurrency from './AssetCryptoCurrency';

class AssetCryptoCurrencyFactory {
  static create = (id: string, props: AssetCryptoCurrencyProps) => {
    const result = Joi.object(AssetCryptoCurrencyPropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: AssetCryptoCurrency.name,
          reason: `${result.error.details[0].message}`,
        },
      );

    return new AssetCryptoCurrency(id as CryptoCurrencyType, props);
  };
}

export default AssetCryptoCurrencyFactory;

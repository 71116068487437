export enum UserType {
  individual = 'individual',
  corporate = 'corporate',
}

export enum UserStatusType {
  initial = 'initial',
  ready = 'ready',
  investing = 'investing',
  cancelInvesting = 'cancelInvesting',
}

export enum VerifiedUserStatusType {
  ready = UserStatusType.initial,
  investing = UserStatusType.investing,
  cancelInvesting = UserStatusType.cancelInvesting,
}

export enum UnverifiedUserStatusType {
  initial = UserStatusType.initial,
}

export enum InvestingUserStatusType {
  investing = UserStatusType.investing,
}

export enum UserRoleType {
  user = 'user',
  staff = 'staff',
  admin = 'admin',
}

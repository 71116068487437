import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// LayoutComponent
import Margin from '../../../atoms/utils/layout/Margin';
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../atoms/utils/layout/Box/FlexBox';

export interface KycStepTemplateLayoutProps {
  children: React.ReactElement[];
}

const KycStepTemplateLayout: FC<KycStepTemplateLayoutProps> = ({
  children,
}: KycStepTemplateLayoutProps) => {
  const elements = ['header', 'title', 'link', 'childNode'];
  const [header, title, link, childNode] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn>
      {header}
      <Margin top={80} />
      <FlexBoxColumn paddingLeft={85}>
        <FlexBoxRow width={1195} justifyContent="space-between">
          {title}
          {link}
        </FlexBoxRow>
        <Margin top={46} />
        {childNode}
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};
export default KycStepTemplateLayout;

import React, { FC, useContext } from 'react';
import DebitCardRequestCherryVisa01Context from '../../../../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Context';
import { useDebitCardRequestCherryVisa01ProofOfIdentityUpdateUseCase } from './useDebitCardRequestCherryVisa01ProofOfIdentityUpdateUseCase';
import ProofOfIdentityFileContext from '../../../../../../fileHandler/debitCard/debitCardRequest/ProofOfIdentityFileProvider/CherryVisa01ProofOfIdentityFileContext';
import DebitCardRequestCherryVisa01ProofOfIdentityUpdateContext from './DebitCardRequestCherryVisa01ProofOfIdentityUpdateContext';
import UserAuthorizedContext from '../../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import SystemErrorHandler from '../../../../../../errorHandler/SystemErrorHandler';

// Type
import { FormInputCherryVisa01ProofOfIdentityContext } from '../../../../../../../components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/FormCherryVisa01ProofOfIdentity.type';

export type DebitCardRequestCherryVisa01ProofOfIdentityUpdateProviderProps = {
  children: React.ReactNode;
};

const DebitCardRequestCherryVisa01ProofOfIdentityUpdateProvider: FC<DebitCardRequestCherryVisa01ProofOfIdentityUpdateProviderProps> = ({
  children,
}: DebitCardRequestCherryVisa01ProofOfIdentityUpdateProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { debitCardRequestCherryVisa01 } = useContext(
    DebitCardRequestCherryVisa01Context,
  );

  const {
    state,
    setState,
    submitProofOfIdentity,
    formInputProofOfIdentityContext,
    proofOfIdentityFileContext,
  } = useDebitCardRequestCherryVisa01ProofOfIdentityUpdateUseCase(
    debitCardRequestCherryVisa01,
    userAuthorized,
  );

  return (
    <FormInputCherryVisa01ProofOfIdentityContext.Provider
      value={formInputProofOfIdentityContext}
    >
      <DebitCardRequestCherryVisa01ProofOfIdentityUpdateContext.Provider
        value={{
          state,
          submitProofOfIdentity,
        }}
      >
        <ProofOfIdentityFileContext.Provider value={proofOfIdentityFileContext}>
          <SystemErrorHandler state={state} setState={setState}>
            {children}
          </SystemErrorHandler>
        </ProofOfIdentityFileContext.Provider>
      </DebitCardRequestCherryVisa01ProofOfIdentityUpdateContext.Provider>
    </FormInputCherryVisa01ProofOfIdentityContext.Provider>
  );
};
export default DebitCardRequestCherryVisa01ProofOfIdentityUpdateProvider;

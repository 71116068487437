/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { TransferSavingsAccountType } from '../../../../../../types/Transfer.type';

export interface TransferSavingsAccountTypeOptionContextProps {
  transferSavingsAccountTypeOption: TransferSavingsAccountType;
  setTransferSavingsAccountTypeOption: (
    transferType: TransferSavingsAccountType,
  ) => void;
}

const TransferSavingsAccountTypeOptionContext = React.createContext<
  TransferSavingsAccountTypeOptionContextProps
>({
  transferSavingsAccountTypeOption: TransferSavingsAccountType.forbes,
  setTransferSavingsAccountTypeOption: (
    transferType: TransferSavingsAccountType,
  ) => undefined,
});

export default TransferSavingsAccountTypeOptionContext;

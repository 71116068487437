// Type
import {
  AssetTimeDepositRawDataType,
  AssetTimeDepositPrimaryKey,
} from '../../../domain/asset/assetTimeDeposit/AssetTimeDeposit/AssetTimeDeposit.type';
import { FiatCurrencyType } from '../../../types/FiatCurrency.type';

// Functions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';
import { ASSET_GET_TIME_DEPOSIT_RAW_DATA_BY_USER_ID_CURRENCY } from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// IRepository
import IAssetTimeDepositRepository from '../../../domain/asset/assetTimeDeposit/IAssetTimeDepositRepository';

// DomainObject
import AssetTimeDepositFactory from '../../../domain/asset/assetTimeDeposit/AssetTimeDeposit/AssetTimeDepositFactory';

class AssetTimeDepositFunctions
  extends FirebasePrivateFunctions
  implements IAssetTimeDepositRepository {
  public findByPrimaryKey = async (params: AssetTimeDepositPrimaryKey) => {
    const { userId, currency } = params;

    const endpoint = ASSET_GET_TIME_DEPOSIT_RAW_DATA_BY_USER_ID_CURRENCY.replace(
      /:userId/,
      userId,
    ).replace(/:currency/, currency);

    const response = await this.privateGET(endpoint);

    const row = response.data.data as AssetTimeDepositRawDataType;

    return AssetTimeDepositFactory.create(
      row.currency as FiatCurrencyType,
      row.props,
    );
  };
}
export default AssetTimeDepositFunctions;

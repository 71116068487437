import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  CorporateInformationProps,
  CorporateInformationPropsFormat,
  CorporateInformationInitialProps,
} from './CorporateInformation.type';

// DomainObject
import CorporateInformation from './CorporateInformation';

class CorporateInformationFactory {
  static create = (props: CorporateInformationProps) => {
    const result = Joi.object(CorporateInformationPropsFormat).validate(props);

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: CorporateInformation.name,
          reason: result.error.details[0].message,
        },
      );

    return new CorporateInformation(props);
  };

  static createInitial = () => {
    return CorporateInformationFactory.create(CorporateInformationInitialProps);
  };
}

export default CorporateInformationFactory;

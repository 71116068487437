import styled from 'styled-components';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const CountdownContainer = styled.div`
  display: flex;
  height: ${29 / 16}rem;
  flex-direction: rem;
  align-items: center;
`;

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const CountdownTextStyle = {
  fontSize: 32,
  color: color.primary,
  fontWeight: fontWeight.regular,
};

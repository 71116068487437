export default {
  thin: 100,
  ultraLight: 200,
  light: 300,
  book: 350,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  heavy: 800,
  black: 900,
};

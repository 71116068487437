export const contentStyle = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  padding: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(92, 92, 92, 0.75)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const overlayStyle = {
  backgroundColor: 'transparent',
  zIndex: 1000,
};

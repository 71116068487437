import React, { FC } from 'react';
import ContractTimeDepositPage from './ContractsTimeDepositPage';
import ContractTimeDepositViewProvider from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositViewProvider';

const EnhancedTimeDepositPage: FC = () => {
  return (
    <ContractTimeDepositViewProvider>
      <ContractTimeDepositPage />
    </ContractTimeDepositViewProvider>
  );
};

export default EnhancedTimeDepositPage;

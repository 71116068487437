import Joi from '@hapi/joi';

export interface DebitCardRequestContentProps {
  rejectedReason?: string;

  updatedAt?: number;
}

export const DebitCardRequestContentPropsFormat = {
  rejectedReason: Joi.string().allow('').allow(null),

  updatedAt: Joi.number().empty(),
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash';
import { getShippingAddressDefaultValues } from '../FormCorporateAddress/helper';
import {
  TypeOfErrors,
  TypeOfWatch,
} from '../../../../../../types/typeof/ReactHookForm.type';

// DomainObject
import UserAuthorized from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

// ValueObject
import ProofOfResidency from '../../../../../../value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidency';

export const isValid = (errors: TypeOfErrors, watch: TypeOfWatch) => {
  return (
    isEmpty(errors) &&
    !isEmpty(watch('country')) &&
    !isEmpty(watch('addressLine1')) &&
    !isEmpty(watch('city')) &&
    !isEmpty(watch('state')) &&
    !isEmpty(watch('postalCode')) &&
    !isEmpty(watch('documentType')) &&
    !isEmpty(watch('proofOfDocumentUrl1')) &&
    !isEmpty(watch('shippingCountry')) &&
    !isEmpty(watch('shippingAddressLine1')) &&
    !isEmpty(watch('shippingCity')) &&
    !isEmpty(watch('shippingState')) &&
    !isEmpty(watch('shippingPostalCode')) &&
    !isEmpty(watch('receiverName')) &&
    !isEmpty(watch('countryCode')) &&
    !isEmpty(watch('phoneNumber'))
  );
};

export const getDefaultValues = (
  proofOfResidency: ProofOfResidency,
  user: UserAuthorized,
) => {
  const proofOfResidencyProps = proofOfResidency.getProps();
  const shippingAddressDefaultValues = getShippingAddressDefaultValues(user);

  return {
    country: proofOfResidencyProps.country,
    addressLine1: proofOfResidencyProps.addressLine1,
    addressLine2: proofOfResidencyProps.addressLine2,
    city: proofOfResidencyProps.city,
    state: proofOfResidencyProps.state,
    postalCode: proofOfResidencyProps.postalCode,
    documentType: proofOfResidencyProps.document.type,
    proofOfDocumentUrl1: proofOfResidencyProps.document.documentUrl1,
    proofOfDocumentUrl2: proofOfResidencyProps.document.documentUrl2,
    ...shippingAddressDefaultValues,
  };
};

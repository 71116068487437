import { useState } from 'react';

// OriginalLib
import { executeWithTimeout } from '../../../../../utils/helpers/timeout';
import { checkInternetConnection } from '../../../../../utils/helpers/connection';
import { minusByDecimal } from '../../../../../utils/helpers/calculationHelper';

// Constant
import { REQUEST_TIMEOUT } from '../../../../../config/constants/business';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';

// Type
import {
  FormInputContractTimeDepositRenewalReservation,
  useFormInputContractTimeDepositRenewalReservation,
} from '../../../../../components/organisms/form/contract/contractTimeDeposit/FormContractTimeDepositRenewalReservation/FormContractTimeDepositRenewalReservation.type';

// Error
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import ContractTimeDepositRenewalReservationUpdateUseCase from '../../../../../useCases/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationUpdateUseCase';

// Repository
import AuthenticatorFunctions from '../../../../../repository/authenticator/AuthenticatorFunctions';
import ContractTimeDepositFunctions from '../../../../../repository/contract/contractTimeDeposit/ContractTimeDepositFunctions';

// DomainObject
import TimeDeposit from '../../../../../domain/product/timeDeposit/TimeDeposit/TimeDeposit';
import { useTimeDeposit } from '../../../../../domain/product/timeDeposit/TimeDeposit/useTimeDeposit';
import UserVerified from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';
import ContractTimeDeposit from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';

export const useContractTimeDepositRenewalReservationUpdateUseCase = (
  user: UserVerified | undefined,
  contractTimeDeposit: ContractTimeDeposit | undefined,
  setContractTimeDeposit: (contractTimeDeposit: ContractTimeDeposit) => void,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const formInputContractTimeDepositRenewalReservationContext = useFormInputContractTimeDepositRenewalReservation();

  /**
   *
   *  DomainObject
   *
   */
  const { timeDeposit, setTimeDeposit } = useTimeDeposit();

  /**
   *
   *  Repository
   *
   */
  const authenticatorVerifier = new AuthenticatorFunctions();
  const contractTimeDepositRepository = new ContractTimeDepositFunctions();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new ContractTimeDepositRenewalReservationUpdateUseCase(
    authenticatorVerifier,
    contractTimeDepositRepository,
  );

  /**
   *
   *  Methods
   *
   */

  // UseCase.update
  const updateContractTimeDepositRenewalReservation = (
    params: FormInputContractTimeDepositRenewalReservation,
    timeDepositUpdated: TimeDeposit,
    callback?: () => void,
  ) => {
    if (!user || !contractTimeDeposit) return;

    setState(INITIAL);

    try {
      const { principalIncreased, amount, isAnnualPayOut } = params;

      const output = useCase.update({
        contractTimeDeposit,
        timeDeposit: timeDepositUpdated,
        amountIncreased: minusByDecimal(
          Number(principalIncreased.replace(/,/g, '')),
          Number(amount.replace(/,/g, '')),
        ),
        isAnnualPayOut: isAnnualPayOut === 'true',
      });

      setTimeDeposit(timeDepositUpdated);
      setContractTimeDeposit(output.contractTimeDeposit);

      if (callback) callback();

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  // UseCase.executeUpdate
  const executeUpdateContractTimeDepositRenewalReservation = async (
    code: string,
    callback?: () => void,
  ) => {
    if (!user || !contractTimeDeposit) return;
    setState(IS_LOADING);

    try {
      checkInternetConnection();

      await executeWithTimeout(
        useCase.executeUpdate(user, contractTimeDeposit, code),
        REQUEST_TIMEOUT,
      );

      if (callback) callback();

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  return {
    state,
    setState,
    timeDeposit,
    formInputContractTimeDepositRenewalReservationContext,
    updateContractTimeDepositRenewalReservation,
    executeUpdateContractTimeDepositRenewalReservation,
  };
};

// IUseCase
import IConsentFormViewUseCase from '../../../enhancers/useCase/verification/consentForm/ConsentFormViewProvider/IConsentFormViewUseCase';

// IService
import IConsentFormQueryService from './IConsentFormQueryService';

// Service
import ConsentFormQueryService from '../../../service/verification/consentForm/ConsentFormQueryService';

// IRepository
import { IConsentFormQueryRepository } from '../../../domain/verification/consentForm/IConsentFormRepository';

// Domain
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class ConsentFormViewUseCase implements IConsentFormViewUseCase {
  private consentFormQueryService: IConsentFormQueryService;

  constructor(consentFormQueryRepository: IConsentFormQueryRepository) {
    this.consentFormQueryService = new ConsentFormQueryService(
      consentFormQueryRepository,
    );
  }

  public open = async (user: UserAuthorized) => {
    const id = user.getId();

    const consentForm = await this.consentFormQueryService.findById(id);

    return { consentForm };
  };
}

export default ConsentFormViewUseCase;

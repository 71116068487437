import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import SignUpRequestContext from '../../../../domain/userRequest/signUpRequest/SignUpRequest/SignUpRequestContext';
import RegisterPageStateContext from '../../../pageState/authentication/RegisterPage/RegisterPageStateContext';

// Constant
import { ErrorCodeType } from '../../../../utils/errors/ErrorHandler/ErrorCode.type';
import { ROUTE_AUTH_ERROR } from '../../../../config/constants/routing';
import { IS_LOADING } from '../../../../config/constants/requestState';

// Hook
import { useRegisterViewUseCase } from './useRegisterViewUseCase';

// Component
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';
import LoadingModal from '../../../../components/organisms/modal/LoadingModal';

export type RegisterViewProviderProps = {
  children: React.ReactNode;
};

const RegisterViewProvider: FC<RegisterViewProviderProps> = ({
  children,
}: RegisterViewProviderProps) => {
  const { oobCode } = useParams<{ oobCode: string }>();
  const { state, setState, signUpRequest } = useRegisterViewUseCase(
    oobCode as string,
  );
  const history = useHistory();

  // 401 Error Redirect
  useEffect(() => {
    const redirectState = [
      ErrorCodeType.AUTHENTICATION_SIGN_UP_REQUEST_NOT_FOUND,
      ErrorCodeType.AUTHENTICATION_SIGN_UP_REQUEST_EXPIRED,
      ErrorCodeType.AUTHENTICATION_SIGN_UP_INVALID_INVITER,
    ];
    if (redirectState.indexOf(state as ErrorCodeType) > -1)
      history.replace(ROUTE_AUTH_ERROR);
  }, [state, history]);

  const [showModal, hideModal] = useModal(() => <LoadingModal />);

  useEffect(() => {
    if (state === IS_LOADING) showModal();

    if (state !== IS_LOADING) hideModal();
  }, [state, showModal, hideModal]);

  return (
    <RegisterPageStateContext.Provider
      value={{ pageState: state, setPageState: setState }}
    >
      <SignUpRequestContext.Provider value={{ signUpRequest }}>
        <SystemErrorHandler state={state} setState={setState}>
          {children}
        </SystemErrorHandler>
      </SignUpRequestContext.Provider>
    </RegisterPageStateContext.Provider>
  );
};
export default RegisterViewProvider;

import React, { FC } from 'react';
import ContractTimeDepositAddPage from './ContractTimeDepositAddPage';
import ContractTimeDepositAddViewProvider from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositAddViewProvider';
import ContractTimeDepositCreateProvider from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositCreateProvider';

const EnhancedContractTimeDepositAddPage: FC = () => {
  return (
    <ContractTimeDepositAddViewProvider>
      <ContractTimeDepositCreateProvider>
        <ContractTimeDepositAddPage />
      </ContractTimeDepositCreateProvider>
    </ContractTimeDepositAddViewProvider>
  );
};

export default EnhancedContractTimeDepositAddPage;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface NotificationSuccessLayoutProps {
  children: React.ReactElement[];
}

const NotificationSuccessLayout: FC<NotificationSuccessLayoutProps> = ({
  children,
}: NotificationSuccessLayoutProps) => {
  const elements = [
    'icon',
    'title',
    'description',
    'custodyTitle',
    'custodyDescription',
    'topUpFeeTitle',
    'topUpFeeDescription',
  ];
  const [
    icon,
    title,
    description,
    custodyTitle,
    custodyDescription,
    topUpFeeTitle,
    topUpFeeDescription,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn height="auto">
      <FlexBoxColumn alignItems="center" height="auto">
        {icon}
        <Margin top={20} />
        {title}
      </FlexBoxColumn>
      <Margin top={30} />
      {description}
      <Margin top={30} />
      {custodyTitle}
      <Margin top={10} />
      {custodyDescription}
      <Margin top={30} />
      {topUpFeeTitle}
      <Margin top={10} />
      {topUpFeeDescription}
    </FlexBoxColumn>
  );
};
export default NotificationSuccessLayout;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../../../../atoms/utils/layout/Box/FlexBox';
import Box from '../../../../../../atoms/utils/layout/Box/Box';
import Margin from '../../../../../../atoms/utils/layout/Margin';

export interface ConsentFormSignatureExplanationLayoutProps {
  children: React.ReactElement[];
}

const ConsentFormSignatureExplanationLayout: FC<ConsentFormSignatureExplanationLayoutProps> = ({
  children,
}: ConsentFormSignatureExplanationLayoutProps) => {
  const elements = [
    'description',
    'subTitleStep1',
    'exampleText1',
    'exampleImage1',
    'subTitleStep2',
    'exampleText2',
    'exampleImage2',
  ];
  const [
    description,
    subTitleStep1,
    exampleText1,
    exampleImage1,
    subTitleStep2,
    exampleText2,
    exampleImage2,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn width={1200} height="auto">
      {description}
      <Margin top={50} />
      {subTitleStep1}
      <Margin top={20} />
      {exampleText1}
      <Margin top={20} />
      <Box width={849.69} height={290}>
        {exampleImage1}
      </Box>
      <Margin top={50} />
      {subTitleStep2}
      <Margin top={20} />
      {exampleText2}
      <Margin top={20} />
      <Box width={849.69} height={290}>
        {exampleImage2}
      </Box>
    </FlexBoxColumn>
  );
};
export default ConsentFormSignatureExplanationLayout;

import React from 'react';

export interface DebitCardMaintenanceModalContextProps {
  showModal: () => void;
  hideModal: () => void;
}

const DebitCardMaintenanceModalContext = React.createContext<
  DebitCardMaintenanceModalContextProps
>({
  showModal: () => undefined,
  hideModal: () => undefined,
});

export default DebitCardMaintenanceModalContext;

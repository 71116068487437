// React
import React, { FC, useContext } from 'react';
import ReactModal from 'react-modal';
import CryptoCurrencyDepositModalContext from './CryptoCurrencyDepositModalContext';
import CryptoCurrencyDepositContext from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/CryptoCurrencyDeposit/CryptoCurrencyDepositContext';

// Style
import { contentStyle, overlayStyle } from './style';

// Components
import CryptoCurrencyDepositNotification from '../../notification/CryptoCurrencyDepositNotification';
import CryptoCurrencyDeposit from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/CryptoCurrencyDeposit/CryptoCurrencyDeposit';

export interface CryptoCurrencyDepositModalProps {
  cryptoCurrencyDeposit?: CryptoCurrencyDeposit;
}

const CryptoCurrencyDepositModal: FC<CryptoCurrencyDepositModalProps> = ({
  cryptoCurrencyDeposit,
}: CryptoCurrencyDepositModalProps) => {
  const { hideModal } = useContext(CryptoCurrencyDepositModalContext);
  const { setCryptoCurrencyDeposit } = useContext(CryptoCurrencyDepositContext);

  return (
    <ReactModal
      isOpen={true}
      style={{ content: contentStyle, overlay: overlayStyle }}
      ariaHideApp={false}
    >
      <CryptoCurrencyDepositNotification
        cryptoCurrencyDeposit={cryptoCurrencyDeposit}
        closeAction={() => {
          hideModal();
          setCryptoCurrencyDeposit(undefined);
        }}
      />
    </ReactModal>
  );
};

export default CryptoCurrencyDepositModal;

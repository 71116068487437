import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';
import { ContainerBoxStyle } from './style';

export interface CardShippingInformationModalLayoutProps {
  children: React.ReactElement[];
}

const CardShippingInformationModalLayout: FC<CardShippingInformationModalLayoutProps> = ({
  children,
}: CardShippingInformationModalLayoutProps) => {
  const elements = ['title', 'table', 'button'];
  const [title, table, button] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn
      alignItems="center"
      width={1200}
      height="auto"
      padding={50}
      theme={ContainerBoxStyle}
    >
      {title}
      <Margin top={60} />
      {table}
      <Margin top={60} />
      {button}
    </FlexBoxColumn>
  );
};
export default CardShippingInformationModalLayout;

import React, { FC, useMemo, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useCorporateAddressViewUseCase } from './useCorporateAddressViewUseCase';
import KycRequestCorporateContext from '../../../../../domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporateContext';
import DocumentTypesContext from '../../../../../domain/settings/documentType/DocumentType/DocumentTypes/DocumentTypesContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Type
import { KycStatusType } from '../../../../../types/KycRequest.type';

// Constant
import { ROUTE_KYC_STATUS } from '../../../../../config/constants/routing';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

export type CorporateAddressViewProviderProps = {
  children: React.ReactNode;
};

const CorporateAddressViewProvider: FC<CorporateAddressViewProviderProps> = ({
  children,
}: CorporateAddressViewProviderProps) => {
  const history = useHistory();

  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    kycRequest,
    documentTypes,
  } = useCorporateAddressViewUseCase(userAuthorized);

  /* *
   *
   *  useEffect (Redirect)
   *
   * */

  // Status of PersonalInformation
  const status = useMemo(
    () => kycRequest?.getKycStepStatus().getProps().corporateAddress,
    [kycRequest],
  );

  // Redirect if status is invalid
  useEffect(() => {
    if (
      status &&
      ![
        KycStatusType.initial,
        KycStatusType.submitted,
        KycStatusType.rejected,
      ].includes(status)
    )
      history.push(ROUTE_KYC_STATUS);
    // eslint-disable-next-line
  }, [status]);

  return (
    <KycRequestCorporateContext.Provider value={{ kycRequest }}>
      <DocumentTypesContext.Provider value={{ documentTypes }}>
        <SystemErrorHandler state={state} setState={setState}>
          {children}
        </SystemErrorHandler>
      </DocumentTypesContext.Provider>
    </KycRequestCorporateContext.Provider>
  );
};

export default CorporateAddressViewProvider;

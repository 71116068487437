import React, { FC } from 'react';
import { CryptoCurrencyType } from '../../../../types/CryptoCurrency.type';
import CryptoCurrencyIcon from '../../../atoms/icon/CryptoCurrencyIcon';
import TextFonted from '../../../atoms/text/TextFonted';
import { Container } from './style';

export interface IconLabelTextProps {
  iconType: CryptoCurrencyType;
  text: string;
  iconSize: string | number;
  textSize: number;
  textColor: string;
  textFontWeight: number;
  textFontFamilyType: 'primary' | 'sub';
  marginBetween: string;
}

const IconLabelText: FC<IconLabelTextProps> = ({
  iconType,
  text,
  iconSize,
  textSize,
  textColor,
  textFontWeight,
  textFontFamilyType,
  marginBetween,
}: IconLabelTextProps) => {
  return (
    <Container>
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CryptoCurrencyIcon
          type={iconType}
          width={iconSize as string}
          height={iconSize as string}
        />
      </div>
      <div
        style={{
          marginLeft: marginBetween,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TextFonted
          pixel={textSize}
          color={textColor}
          fontWeight={textFontWeight}
          fontFamilyType={textFontFamilyType}
        >
          {text}
        </TextFonted>
      </div>
    </Container>
  );
};

export default IconLabelText;

import React, { FC, useContext, useEffect } from 'react';
import { useModal } from 'react-modal-hook';
import CryptoCurrencyDepositModalContext from './CryptoCurrencyDepositModalContext';
import CryptoCurrencyDepositContext from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/CryptoCurrencyDeposit/CryptoCurrencyDepositContext';
import CryptoCurrencyDepositModal from '.';

export type CryptoCurrencyDepositModalProviderProps = {
  children: React.ReactNode;
};

const CryptoCurrencyDepositModalProvider: FC<CryptoCurrencyDepositModalProviderProps> = ({
  children,
}: CryptoCurrencyDepositModalProviderProps) => {
  const { cryptoCurrencyDeposit } = useContext(CryptoCurrencyDepositContext);

  const [showModal, hideModal] = useModal(
    () => (
      <CryptoCurrencyDepositModalContext.Provider
        value={{ showModal, hideModal }}
      >
        <CryptoCurrencyDepositModal
          cryptoCurrencyDeposit={cryptoCurrencyDeposit}
        />
      </CryptoCurrencyDepositModalContext.Provider>
    ),
    [cryptoCurrencyDeposit],
  );

  useEffect(() => {
    if (cryptoCurrencyDeposit) showModal();

    if (!cryptoCurrencyDeposit) hideModal();
  }, [cryptoCurrencyDeposit, showModal, hideModal]);

  return (
    <CryptoCurrencyDepositModalContext.Provider
      value={{ showModal, hideModal }}
    >
      {children}
    </CryptoCurrencyDepositModalContext.Provider>
  );
};
export default CryptoCurrencyDepositModalProvider;

import React, { FC } from 'react';
import CryptoCurrencyRateFetchProvider from '../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyRateFetchProvider';
import CryptoCurrencyTransactionViewProvider from '../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyTransactionViewProvider';
import CryptoCurrencyWalletPage from './CryptoCurrencyWalletPage';
import CryptoCurrencyWalletViewProvider from '../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyWalletViewProvider';

const EnhancedCryptoCurrencyWalletPage: FC = () => {
  return (
    <CryptoCurrencyWalletViewProvider>
      <CryptoCurrencyRateFetchProvider>
        <CryptoCurrencyTransactionViewProvider>
          <CryptoCurrencyWalletPage />
        </CryptoCurrencyTransactionViewProvider>
      </CryptoCurrencyRateFetchProvider>
    </CryptoCurrencyWalletViewProvider>
  );
};

export default EnhancedCryptoCurrencyWalletPage;

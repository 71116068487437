import { useCallback, useState, useEffect, useMemo } from 'react';

// Lib
import { checkInternetConnection } from '../../../../../utils/helpers/connection';

import { useContractTimeDepositRenewalReservationAddState } from '../../../../pageState/contract/ContractTimeDepositRenewalReservationPageState/useContractTimeDepositRenewalReservationPageState';

// Error
import ErrorHandler, {
  ErrorCodeType,
} from '../../../../../utils/errors/ErrorHandler';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';

// UseCase
import ContractTimeDepositRenewalReservationViewUseCase from '../../../../../useCases/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationViewUseCase';

// Repository
import ContractTimeDepositFunctions from '../../../../../repository/contract/contractTimeDeposit/ContractTimeDepositFunctions';

// DomainObject
import { useContractTimeDeposit } from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/useContractTimeDeposit';
import { useSavingsAccounts } from '../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/useSavingsAccounts';
import { useTimeDeposits } from '../../../../../domain/product/timeDeposit/TimeDeposits/useTimeDeposits';
import { useUserVerified } from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/useUserVerified';
import UserAuthorized from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import SavingsAccountFunctions from '../../../../../repository/bankAccount/savingsAccount/SavingsAccountFunctions';
import TimeDepositFirestore from '../../../../../repository/product/timeDeposit/TimeDepositFirestore';

export const useContractTimeDepositRenewalReservationViewUseCase = (
  id: string | null,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const {
    pageState,
    setPageState,
  } = useContractTimeDepositRenewalReservationAddState();

  /* *
   *
   *  DomainObject
   *
   * */
  const {
    contractTimeDeposit,
    setContractTimeDeposit,
  } = useContractTimeDeposit();
  const { savingsAccounts, setSavingsAccounts } = useSavingsAccounts();
  const { timeDeposits, setTimeDeposits } = useTimeDeposits();
  const { userVerified, setUserVerified } = useUserVerified();

  // Filtered
  const timeDepositsFiltered = useMemo(() => {
    if (!userVerified) return timeDeposits;

    return timeDeposits
      .filterByUser(userVerified)
      .sortForSelector()
      .filterByActive();
  }, [timeDeposits, userVerified]);

  /* *
   *
   *  Repository
   *
   * */
  const contractTimeDepositRepository = new ContractTimeDepositFunctions();
  const savingsAccountRepository = new SavingsAccountFunctions();
  const timeDepositRepository = new TimeDepositFirestore();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new ContractTimeDepositRenewalReservationViewUseCase(
    contractTimeDepositRepository,
    timeDepositRepository,
    savingsAccountRepository,
  );

  /* *
   *
   *  Method
   *
   * */
  const openContractTimeDeposit = useCallback(async () => {
    if (!userAuthorized || !id) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // setters
      const setters = {
        timeDeposits: setTimeDeposits,
      };

      // UseCase
      const output = await useCase.open(id, userAuthorized, setters, setState);

      // Setter
      setContractTimeDeposit(output.contractTimeDeposit);
      setSavingsAccounts(output.savingsAccounts);
      setUserVerified(output.userVerified);

      setState(SUCCESS);
    } catch (error) {
      if (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error as any).response.data.code ===
        ErrorCodeType.CONTRACT_TIME_DEPOSIT_FORBIDDEN_READ_DEPOSIT_INFO
      ) {
        setState(
          ErrorCodeType.CONTRACT_TIME_DEPOSIT_FORBIDDEN_READ_DEPOSIT_INFO,
        );
        return;
      }

      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized]);

  /* *
   *
   *  UseCase
   *
   * */
  useEffect(() => {
    let isMounted = true;

    if (userAuthorized && isMounted) openContractTimeDeposit();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [userAuthorized]);

  return {
    state,
    setState,
    pageState,
    setPageState,
    userVerified,
    timeDeposits: timeDepositsFiltered,
    savingsAccounts,
    contractTimeDeposit,
    setContractTimeDeposit,
  };
};

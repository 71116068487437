import Joi from '@hapi/joi';

// Error
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import { UserProps, UserPropsFormat } from './User.type';

// DomainObject
import User from './User';

class UserFactory {
  static create = (id: string, props: UserProps) => {
    const result = Joi.object(UserPropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: User.name,
          reason: result.error.details[0].message,
        },
      );

    return new User(id, props);
  };
}

export default UserFactory;

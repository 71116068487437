import React, { FC } from 'react';
import { components } from 'react-select';
import { Hover, OptionTextStyle } from './style';
import { TextSecondary } from '../../../../../atoms/text2/Text2';
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DefaultOptionContent: FC = (props: any) => {
  const { label } = props.data as { label: string; value: string };

  return (
    <FlexBoxRow alignItems="center" padding="15 15 15 0">
      <TextSecondary theme={OptionTextStyle}>{label}</TextSecondary>
    </FlexBoxRow>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DefaultSingleValueContainer: FC = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <FlexBoxRow alignItems="center">
        <DefaultOptionContent {...props} />
      </FlexBoxRow>
    </components.SingleValue>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DefaultOptionContainer: FC = (props: any) => {
  return (
    <Hover>
      <components.Option {...props}>
        <FlexBoxRow alignItems="center">
          <DefaultOptionContent {...props} />
        </FlexBoxRow>
      </components.Option>
    </Hover>
  );
};

export { DefaultOptionContainer, DefaultSingleValueContainer };

import React, { FC } from 'react';
import ReactModal from 'react-modal';
import { LoadingSpinLoader } from '../../../atoms/spinner/TextSpinLoader';
import { Container } from './style';

type Props = {
  style?: ReactModal.Styles;
};
const modalStyle = {
  content: {
    width: '100%',
    height: '100%',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: '#5C5C5C 0% 0% no-repeat padding-box',
    opacity: 0.75,
  },
  overlay: {
    backgroundColor: 'transparent',
  },
};

const LoadingModal: FC<Props> = ({ style = {} }: Props) => {
  return (
    <ReactModal
      isOpen={true}
      style={{ ...modalStyle, ...style }}
      ariaHideApp={false}
    >
      <Container>
        <LoadingSpinLoader />
      </Container>
    </ReactModal>
  );
};

export default LoadingModal;

import React, { FC } from 'react';

// Type
import { UserType } from '../../../../types/User.type';

// Image
import IndividualIconImage from '../../../../assets/images/individual.png';
import CorporateIconImage from '../../../../assets/images/corporate.png';

// Lib
import { convertRem } from '../../utils/helper/helper';

// Style
import { Container, ImageWrap } from './style';

export interface UserTypeIconProps {
  type: UserType;
  width?: string | number;
  height?: string | number;
  isSelected: boolean;
}

const UserTypeIcon: FC<UserTypeIconProps> = ({
  type,
  width = '100%',
  height = '100%',
  isSelected = false,
}: UserTypeIconProps) => {
  const icon =
    type === UserType.individual ? IndividualIconImage : CorporateIconImage;

  const background = isSelected
    ? '#465b76'
    : 'transparent linear-gradient(180deg, #30A3EA 0%, #005F9A 100%) 0% 0% no-repeat padding-box';

  const iconSize = type === 'individual' ? '40%' : '50%';

  return (
    <Container
      style={{
        width: convertRem(width),
        height: convertRem(height),
        background,
      }}
    >
      <ImageWrap style={{ width: iconSize, height: iconSize }}>
        <img src={icon} alt="UserType" width="100%" height="100%" />
      </ImageWrap>
    </Container>
  );
};
export default UserTypeIcon;

import React, { FC, useContext } from 'react';
import { ForgotPasswordContext } from '../../../enhancers/front/ForgotPassword/ForgotPasswordContext';
import { ErrorCodeType } from '../../../utils/errors/ErrorHandler';
import { SUCCESS } from '../../../config/constants/requestState';
import FrontPageTemplate from '../../../components/templates/front/FrontPageTemplate';
import ForgotPasswordTemplate from '../../../components/templates/front/ForgotPasswordTemplate';
import ForgotPasswordCompleteTemplate from '../../../components/templates/front/ForgotPasswordCompleteTemplate';

const ForgotPasswordPage: FC = () => {
  const { forgotPasswordState } = useContext(ForgotPasswordContext);

  const statusSuccess = [
    SUCCESS,
    ErrorCodeType.AUTHENTICATION_FORGOT_PASSWORD_EMAIL_NOT_FOUND,
    ErrorCodeType.AUTHENTICATION_FORGOT_PASSWORD_INVALID_ROLE,
    ErrorCodeType.AUTHENTICATION_FORGOT_PASSWORD_USER_DELETED,
  ];

  return (
    <FrontPageTemplate>
      {(() => {
        if (statusSuccess.indexOf(forgotPasswordState) > -1)
          return <ForgotPasswordCompleteTemplate />;
        return <ForgotPasswordTemplate />;
      })()}
    </FrontPageTemplate>
  );
};

export default ForgotPasswordPage;

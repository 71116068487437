import React, { FC } from 'react';
import SignUpContext from './SignUpContext';
import { useSignUpUseCase } from './useSignUpUseCase';
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type SignUpProviderProps = {
  children: React.ReactNode;
};

const SignUpProvider: FC<SignUpProviderProps> = ({
  children,
}: SignUpProviderProps) => {
  const {
    state,
    setState,
    signUp,
    email,
    invitationCode,
    resendSignUpComplete,
  } = useSignUpUseCase();

  return (
    <SignUpContext.Provider
      value={{ state, signUp, email, invitationCode, resendSignUpComplete }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </SignUpContext.Provider>
  );
};
export default SignUpProvider;

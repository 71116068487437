import { useCallback, useEffect, useMemo, useState } from 'react';

// Original Lib
import { executeWithTimeout } from '../../../../utils/helpers/timeout';
import { checkInternetConnection } from '../../../../utils/helpers/connection';

// Type
import { useFormInputCryptoCurrencyTrade } from '../../../../components/organisms/form/cryptoCurrency/cryptoCurrencyTrade/FormCryptoCurrencyTrade/FormInputCryptoCurrencyTrade.type';
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';
import { REQUEST_TIMEOUT } from '../../../../config/constants/business';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import CryptoCurrencyTradeViewUseCase from '../../../../useCases/cryptoCurrency/cryptoCurrencyTrade/CryptoCurrencyTradeViewUseCase';

// Repository & API
import AssetCryptoCurrencyFirestore from '../../../../repository/asset/assetCryptoCurrency/AssetCryptoCurrencyFirestore';
import SavingsAccountFunctions from '../../../../repository/bankAccount/savingsAccount/SavingsAccountFunctions';
import CryptoCurrencyTradeLimitFunctions from '../../../../repository/cryptoCurrency/cryptoCurrencyTransaction/CryptoCurrencyTradeLimitFunctions';
import CryptoCurrencyWalletFirestoreFactory from '../../../../repository/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWalletFirestoreFactory';

// DomainObject
import { useAssetsCryptoCurrency } from '../../../../domain/asset/assetCryptoCurrency/AssetsCryptoCurrency/useAssetsCryptoCurrency';
import { useCryptoCurrencyWallet } from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/useCryptoCurrencyWallet';
import { useSavingsAccounts } from '../../../../domain/bankAccount/savingsAccount/SavingsAccounts/useSavingsAccounts';
import { useUserVerified } from '../../../../domain/public/user/User/UserAuthorized/UserVerified/useUserVerified';
import { useCryptoCurrencyTradeLimit } from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeLimit/CryptoCurrencyTradeLimit/useCryptoCurrencyTradeLimit';
import UserAuthorized from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

// SelectBox
import { useCryptoCurrencyOption } from '../../../../components/molecules/selectBox/option/option/CryptoCurrencyOption/useCryptoCurrencyOption';

export const useCryptoCurrencyTradeViewUseCase = (
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const formInputCryptoCurrencyTradeContext = useFormInputCryptoCurrencyTrade();

  /* *
   *
   *  DomainObject
   *
   * */
  const {
    assetsCryptoCurrency,
    setAssetsCryptoCurrency,
  } = useAssetsCryptoCurrency();

  const {
    cryptoCurrencyWallet,
    setCryptoCurrencyWallet,
  } = useCryptoCurrencyWallet();

  const {
    cryptoCurrencyTradeLimit,
    setCryptoCurrencyTradeLimit,
  } = useCryptoCurrencyTradeLimit();

  const { savingsAccounts, setSavingsAccounts } = useSavingsAccounts();

  const { userVerified, setUserVerified } = useUserVerified();

  /* *
   *
   *  Options
   *
   * */
  const {
    cryptoCurrencyOption,
    setCryptoCurrencyOption,
  } = useCryptoCurrencyOption();

  /* *
   *
   *  Repository
   *
   * */
  const assetCryptoCurrencyRepository = new AssetCryptoCurrencyFirestore();
  const cryptoCurrencyWalletRepository = useMemo(
    () => CryptoCurrencyWalletFirestoreFactory.create(cryptoCurrencyOption),
    [cryptoCurrencyOption],
  );
  const savingsAccountRepository = new SavingsAccountFunctions();
  const cryptoCurrencyTradeLimitRepository = new CryptoCurrencyTradeLimitFunctions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = useMemo(
    () => {
      return new CryptoCurrencyTradeViewUseCase(
        assetCryptoCurrencyRepository,
        cryptoCurrencyWalletRepository,
        savingsAccountRepository,
        cryptoCurrencyTradeLimitRepository,
      );
    },
    // eslint-disable-next-line
    [cryptoCurrencyOption],
  );

  /* *
   *
   *  Methods
   *
   * */

  // Open
  const openCryptoCurrencyTrade = useCallback(async () => {
    if (!userAuthorized) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // Setter Group
      const setters = {
        cryptoCurrencyWallet: setCryptoCurrencyWallet,
        assetsCryptoCurrency: setAssetsCryptoCurrency,
      };

      // UseCase
      const output = await executeWithTimeout(
        useCase.open(
          userAuthorized,
          cryptoCurrencyOption,
          FiatCurrencyType.USD,
          setters,
          setState,
        ),
        REQUEST_TIMEOUT,
      );

      // Setter (DomainObject)
      setUserVerified(output.user);
      setSavingsAccounts(output.savingsAccounts);
      setCryptoCurrencyTradeLimit(output.cryptoCurrencyTradeLimit);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized, useCase]);

  const selectCryptoCurrency = useCallback(async () => {
    if (!userAuthorized) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      await useCase.selectCryptoCurrency(
        userAuthorized,
        setCryptoCurrencyWallet,
        setState,
      );

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized]);

  /* *
   *
   *  UseEffect
   *
   * */

  // Loading state management
  useEffect(() => {
    setState(cryptoCurrencyWallet ? SUCCESS : IS_LOADING);
  }, [cryptoCurrencyWallet]);

  // UseCase.open
  useEffect(
    () => {
      let isMounted = true;

      if (isMounted && userAuthorized) openCryptoCurrencyTrade();

      return () => {
        isMounted = false;
      };
    },
    // eslint-disable-next-line
    [openCryptoCurrencyTrade],
  );

  // UseCase.selectCryptoCurrency
  useEffect(() => {
    let isMounted = true;

    if (isMounted && userAuthorized) selectCryptoCurrency();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [useCase, cryptoCurrencyOption]);

  return {
    state,
    setState,
    userVerified,
    assetsCryptoCurrency,
    cryptoCurrencyWallet,
    cryptoCurrencyOption,
    savingsAccounts,
    setCryptoCurrencyOption,
    formInputCryptoCurrencyTradeContext,
    cryptoCurrencyTradeLimit,
  };
};

// Lib
import { ceil } from 'lodash';
import {
  divideByDecimal,
  timesByDecimal,
} from '../../../../../utils/helpers/calculationHelper';
import { convertMoneyRoundDown } from '../../../../../utils/helpers/currencyHelper';
import { getDecimalDigit } from '../../../../../utils/helpers/numberHelper';

// Type
import {
  CurrencyPairType,
  CurrencyPairProps,
} from '../../../../../types/CurrencyPair.type';
import { CryptoCurrencyTradeLimitProps } from './CryptoCurrencyTradeLimit.type';

class CryptoCurrencyTradeLimit {
  private id: CurrencyPairType;

  private minOrderQty: number | undefined;

  private maxOrderQty: number | undefined;

  private minValue: number | undefined;

  private maxValue: number | undefined;

  private stepSize: number | undefined;

  private createdAt: number | undefined;

  private updatedAt: number | undefined;

  private minOrderQtyDigit: number;

  private props: CryptoCurrencyTradeLimitProps;

  constructor(id: CurrencyPairType, props: CryptoCurrencyTradeLimitProps) {
    this.id = id;
    this.props = props;
    this.maxOrderQty = props.maxOrderQty;
    this.maxValue = props.maxValue;
    this.minOrderQty = props.minOrderQty;
    this.minValue = props.minValue;
    this.stepSize = props.stepSize;

    // MinOrderQtyDigit
    const minDigitBank = getDecimalDigit(
      CurrencyPairProps[id].minTradeAmountTarget,
    );
    const minDigitStepSize = props.stepSize
      ? getDecimalDigit(props.stepSize)
      : minDigitBank;

    this.minOrderQtyDigit = Math.min(minDigitBank, minDigitStepSize);
  }

  public getProps = () => ({
    ...this.props,
    minOrderQtyDigit: this.minOrderQtyDigit,
    createdAt: this.createdAt,
    updatedAt: this.updatedAt,
  });

  public getMinimumSellPayAmount = (rate: number) => {
    if (!this.minOrderQty || !this.minValue || rate <= 0) return 0;

    const { targetCurrency } = CurrencyPairProps[this.id];

    const minOrderQtyByMinValue = convertMoneyRoundDown(
      divideByDecimal(this.minValue, rate),
      targetCurrency,
    );

    const minOrderCalculated = ceil(
      Math.max(this.minOrderQty, minOrderQtyByMinValue),
      this.minOrderQtyDigit,
    );

    const minOrderAmount =
      minOrderCalculated === this.stepSize
        ? this.stepSize * 2
        : minOrderCalculated;

    return timesByDecimal(minOrderAmount, 5);
  };

  public getMinimumBuyPayAmount = (rate: number) => {
    if (!this.minOrderQty || !this.minValue || rate <= 0) return 0;

    const { baseCurrency } = CurrencyPairProps[this.id];

    const minValueByMinOrderQty = convertMoneyRoundDown(
      timesByDecimal(this.minOrderQty, rate),
      baseCurrency,
    );

    return ceil(
      Math.max(minValueByMinOrderQty, this.minValue),
      this.minOrderQtyDigit,
    );
  };
}

export default CryptoCurrencyTradeLimit;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Style
import { Container } from './style';

// Layout Component
import Margin from '../../../atoms/utils/layout/Margin';

export interface SignUpTemplateLayoutLayoutProps {
  children: React.ReactElement[];
}

const SignUpTemplateLayoutLayout: FC<SignUpTemplateLayoutLayoutProps> = ({
  children,
}: SignUpTemplateLayoutLayoutProps) => {
  const elements = ['title', 'form', 'linkSignIn', 'linkTop'];
  const [title, form, linkSignIn, linkTop] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <Container>
      {title}
      <Margin top={50} />
      {form}
      <Margin top={90} />
      {linkSignIn}
      <Margin top={20} />
      {linkTop}
    </Container>
  );
};
export default SignUpTemplateLayoutLayout;

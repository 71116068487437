export enum MenuType {
  assets = 'assets',
  cryptocurrencies = 'cryptocurrencies',
  savingsAccounts = 'savingsAccounts',
  timeDeposits = 'timeDeposits',
  debitCards = 'debitCards',
  transfer = 'transfer',
  jointAccounts = 'jointAccounts',
  settings = 'settings',
  manual = 'manual',
  invitations = 'invitations',
  verification = 'verification',
}

import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  ReferralTimeDepositByRankDProps,
  ReferralTimeDepositByRankDPropsFormat,
} from './ReferralTimeDepositByRankD.type';

// DomainObject
import ReferralTimeDepositByRankD from './ReferralTimeDepositByRankD';

class ReferralTimeDepositByRankDFactory {
  static create = (id: string, props: ReferralTimeDepositByRankDProps) => {
    const result = Joi.object(ReferralTimeDepositByRankDPropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: ReferralTimeDepositByRankD.name,
          reason: result.error.details[0].message,
        },
      );

    return new ReferralTimeDepositByRankD(id, props);
  };
}

export default ReferralTimeDepositByRankDFactory;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export interface SignOutContextProps {
  signOut: () => Promise<void>;
}

const SignOutContext = React.createContext<SignOutContextProps>({
  signOut: () => new Promise((resolve) => resolve()),
});

export default SignOutContext;

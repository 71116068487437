import Joi from '@hapi/joi';
import { NotificationLogType } from '../../../../types/Notification.type';

// Type
import {
  NotificationLogProps,
  NotificationLogPropsFormat,
} from '../NotificationLog/NotificationLog.type';

export type NotificationLogCustodyFeeAndTopUpFeeProps = NotificationLogProps;

export const NotificationLogCustodyFeeAndTopUpFeeInitialProps = {
  type: NotificationLogType.custodyFedAndTopUpFee,

  isRead: false,
};

export const NotificationLogCustodyFeeAndTopUpFeePropsFormat = {
  ...NotificationLogPropsFormat,

  type: Joi.valid(NotificationLogType.custodyFedAndTopUpFee).required(),
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';
import Box from '../../../../../atoms/utils/layout/Box/Box';
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';
import { FlexColumn } from '../../../../../atoms/utils/layout/Flex';
import Margin from '../../../../../atoms/utils/layout/Margin';
import { QrCodeContainerStyle, AccountKeyContainerStyle } from './style';

export interface AccountKeyDetailLayoutProps {
  children: React.ReactElement[];
}

const AccountKeyDetailLayout: FC<AccountKeyDetailLayoutProps> = ({
  children,
}: AccountKeyDetailLayoutProps) => {
  const elements = ['qrcode', 'description', 'accountKey', 'attention'];
  const [qrcode, description, accountKey, attention] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexColumn>
      <FlexBoxRow
        width={270}
        height={270}
        justifyContent="center"
        alignItems="center"
        theme={QrCodeContainerStyle}
      >
        {qrcode}
      </FlexBoxRow>
      <Margin top={60}>{description}</Margin>
      <Margin top={30}>
        <FlexBoxRow
          alignItems="center"
          padding="10 20"
          justifyContent="center"
          theme={AccountKeyContainerStyle}
        >
          {accountKey}
        </FlexBoxRow>
      </Margin>
      <Margin top={40}>
        <Box textAlign="left">{attention}</Box>
      </Margin>
    </FlexColumn>
  );
};

export default AccountKeyDetailLayout;

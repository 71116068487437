import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// Style
import { ExampleImageBoxStyle } from './style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormPassportUploadLayoutProps {
  children: React.ReactElement[];
}

const FormPassportUploadLayout: FC<FormPassportUploadLayoutProps> = ({
  children,
}: FormPassportUploadLayoutProps) => {
  const elements = [
    'description',
    'dropzone',
    'exampleTitle',
    'exampleImage',
    'checkPoint',
  ];
  const [
    description,
    dropzone,
    exampleTitle,
    exampleImage,
    checkPoint,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn>
      {description}
      <Margin top={60} />
      <FlexBoxRow>
        {dropzone}
        <Margin left={60} />
        <FlexBoxColumn
          width={282}
          height="auto"
          padding="7 11 12 11"
          theme={ExampleImageBoxStyle}
        >
          {exampleTitle}
          <Margin top={20} />
          <FlexBoxRow justifyContent="center">{exampleImage}</FlexBoxRow>
        </FlexBoxColumn>
      </FlexBoxRow>
      <Margin top={40} />
      {checkPoint}
    </FlexBoxColumn>
  );
};
export default FormPassportUploadLayout;

import React from 'react';

export interface ManualFilesContextProps {
  files: {
    [key: string]: Array<{
      fileName: string;
      url: string;
    }>;
  };
}

const ManualFilesContext = React.createContext<ManualFilesContextProps>({
  files: {},
});

export default ManualFilesContext;

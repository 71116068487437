import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Type
import { KycStatusType } from '../../../../types/KycRequest.type';

// LayoutComponent
import Margin from '../../../atoms/utils/layout/Margin';
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';

// Component
import Display from '../../../atoms/div/Display';

export interface KycStatusTemplateLayoutProps {
  children: React.ReactElement[];
  status?: KycStatusType;
}

const KycStatusTemplateLayout: FC<KycStatusTemplateLayoutProps> = ({
  children,
  status,
}: KycStatusTemplateLayoutProps) => {
  const elements = ['header', 'description', 'navigation', 'list'];
  const [header, description, navigation, list] = getElementsFromKeys(
    children,
    elements,
  );

  const isBackLinkDisplay = !status
    ? false
    : ![KycStatusType.approved, KycStatusType.reviewing].includes(status);

  return (
    <FlexBoxColumn paddingLeft={85} paddingTop={`${(80 / 890) * 100}vh`}>
      {header}
      <Margin top={26} />
      {description}
      <Margin top={26} />
      <Display isDisplay={isBackLinkDisplay}>{navigation}</Display>
      <Margin top={74} />
      {list}
    </FlexBoxColumn>
  );
};
export default KycStatusTemplateLayout;

import axios from '../axios';
import { getRefreshToken } from '../../firebaseAuthentication/firebaseAuth';
import FirebasePublicFunctions from './FirebasePublicFunctions';

class FirebasePrivateFunctions extends FirebasePublicFunctions {
  private refreshToken = async () => {
    const newToken = await getRefreshToken();
    return newToken;
  };

  private getAuthorization = async () => {
    const token = await this.refreshToken();

    return {
      // Bearer
      headers: { Authorization: `Bearer ${token}` },
    };
  };

  public privateGET = async (requestUrl: string) => {
    const config = await this.getAuthorization();

    const response = await axios.get(requestUrl, config);

    return response;
  };

  public privatePOST = async (
    requestUrl: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    objects: { [key: string]: any },
  ) => {
    const config = await this.getAuthorization();

    const response = await axios.post(requestUrl, objects, config);

    return response;
  };

  public privatePUT = async (
    requestUrl: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    objects: { [key: string]: any },
  ) => {
    const config = await this.getAuthorization();

    const response = await axios.put(requestUrl, objects, config);

    return response;
  };
}

export default FirebasePrivateFunctions;

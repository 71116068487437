import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../atoms/utils/layout/Box/FlexBox';
import ContentBox from '../../../atoms/utils/layout/Box/Box/ContentBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface TwoFactorAuthAppEditTemplateLayoutProps {
  children: React.ReactElement[];
}

const TwoFactorAuthAppEditTemplateLayout: FC<TwoFactorAuthAppEditTemplateLayoutProps> = ({
  children,
}: TwoFactorAuthAppEditTemplateLayoutProps) => {
  const elements = ['header', 'description', 'form'];
  const [header, description, form] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn height="auto" padding="80 0 80 85">
      {header}
      <Margin top={50} />
      <ContentBox width={1200} padding="80">
        <FlexBoxRow justifyContent="flex-start">{description}</FlexBoxRow>
        <Margin top={35} />
        {form}
      </ContentBox>
    </FlexBoxColumn>
  );
};
export default TwoFactorAuthAppEditTemplateLayout;

import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const CheckMarkIconStyle = {
  color: color.white,
  backgroundColor: color.blue,
};

export const OrderIdLabelStyle = {
  fontSize: 21,
  color: color.primary,
  fontWeight: fontWeight.medium,
};
export const OrderIdStyle = {
  fontSize: 20,
  color: color.text.zambezi,
  fontWeight: fontWeight.medium,
};

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  lineHeight: 44,
  textAlign: 'center',
};

export const TableTitleStyle = {
  fontSize: 21,
  color: color.primary,
  fontWeight: fontWeight.bold,
};

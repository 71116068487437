import styled from 'styled-components';
import Box from '../Box';

interface FlexProps {
  justifyContent?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'start' | 'end' | 'stretch' | 'center';
}

export const FlexBoxRow = styled(Box)`
  display: flex;
  justify-content: ${(props: FlexProps) =>
    `${props.justifyContent || 'flex-start'}`};
  align-items: ${(props: FlexProps) => `${props.alignItems || 'start'}`};
`;

export const FlexBoxColumn = styled(FlexBoxRow)`
  flex-direction: column;
`;

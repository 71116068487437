import React, { useState } from 'react';

export interface FormInputEmailEdit {
  currentEmail: string;
  newEmail: string;
}

export const useFormInputEmailEdit = () => {
  const [formInput, setFormInput] = useState<FormInputEmailEdit>();

  return {
    formInput,
    setFormInput,
  };
};

export const FormInputEmailEditContext = React.createContext<{
  formInput?: FormInputEmailEdit;
  setFormInput: (formInput?: FormInputEmailEdit) => void;
}>({
  formInput: undefined,

  setFormInput: () => undefined,
});

// Error
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';

// IService
import IReferralTimeDepositQueryService from '../../../useCases/invite/referralTimeDeposit/IReferralTimeDepositQueryService';

// IRepository
import { IReferralTimeDepositQueryRepository } from '../../../domain/invite/referralTimeDeposit/IReferralTimeDepositRepository';

// DomainObject
import UserVerified from '../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';

class ReferralTimeDepositQueryService
  implements IReferralTimeDepositQueryService {
  private referralTimeDepositQueryRepository: IReferralTimeDepositQueryRepository;

  constructor(
    referralTimeDepositQueryRepository: IReferralTimeDepositQueryRepository,
  ) {
    this.referralTimeDepositQueryRepository = referralTimeDepositQueryRepository;
  }

  public findRawAllByUser = async (user: UserVerified) => {
    const userId = user.getId();

    if (user.isRankD()) {
      const referralsTimeDeposit = await this.findRawAllByRankDUserId(userId);
      return referralsTimeDeposit;
    }

    if (user.isRankA()) {
      const referralsTimeDeposit = await this.findRawAllByRankAUserId(userId);
      return referralsTimeDeposit;
    }

    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
      {
        type: 'RankD or RankA',
        place: 'ReferralTimeDepositQueryService.findRawAllByUser',
      },
    );
  };

  public findRawAllByRankDUserId = async (userId: string) => {
    const referralsTimeDeposit = await this.referralTimeDepositQueryRepository.findRawAllByRankDUserId(
      userId,
    );

    return referralsTimeDeposit;
  };

  public findRawAllByRankAUserId = async (userId: string) => {
    const referralsTimeDeposit = await this.referralTimeDepositQueryRepository.findRawAllByRankAUserId(
      userId,
    );

    return referralsTimeDeposit;
  };
}

export default ReferralTimeDepositQueryService;

import { useState } from 'react';
import CryptoCurrencyWallet from './CryptoCurrencyWallet';

export const useCryptoCurrencyWallet = (
  defaultValue?: CryptoCurrencyWallet,
) => {
  const [cryptoCurrencyWallet, setCryptoCurrencyWallet] = useState<
    CryptoCurrencyWallet | undefined
  >(defaultValue);

  return {
    cryptoCurrencyWallet,
    setCryptoCurrencyWallet,
  };
};

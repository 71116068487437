import { ShippingAddressProps } from './ShippingAddress.type';
import Address from '../Address/Address';

class ShippingAddress extends Address {
  private receiverName: string;

  private contactNumber: {
    countryCode: string;
    number: string;
  };

  constructor(props: ShippingAddressProps) {
    super(props);
    this.receiverName = props.receiverName;
    this.contactNumber = props.contactNumber;
  }

  public getProps = () => ({
    ...super.getProps(),
    receiverName: this.receiverName,
    contactNumber: this.contactNumber,
  });
}
export default ShippingAddress;

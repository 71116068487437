import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Constant
import { IS_LOADING } from '../../../../config/constants/requestState';

// LayoutComponent
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';

// Component
import ContentBox from '../../../atoms/utils/layout/Box/Box/ContentBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface DebitCardTemplateLayoutProps {
  children: React.ReactElement[];
  state: string;
}

const DebitCardTemplateLayout: FC<DebitCardTemplateLayoutProps> = ({
  children,
  state,
}: DebitCardTemplateLayoutProps) => {
  const elements = ['title', 'tabBar', 'content', 'loading'];
  const [title, tabBar, content, loading] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn padding="85 0 0 80">
      {title}
      <Margin top={50} />
      {tabBar}
      <ContentBox
        width={1200}
        padding={50}
        alignItems="center"
        justifyContent="center"
      >
        {state === IS_LOADING ? loading : content}
      </ContentBox>
      <Margin bottom={50} />
    </FlexBoxColumn>
  );
};
export default DebitCardTemplateLayout;

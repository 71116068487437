import React, { FC, useContext } from 'react';
import { useModal } from 'react-modal-hook';
import { useHistory } from 'react-router-dom';
import CardIssuanceFeePaymentModalContext from './CardIssuanceFeePaymentModalContext';
import DebitCardRequestCherryVisa01Context from '../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Context';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Component
import CardIssuanceFeePaymentModal from '.';
import DebitCardRequestCherryVisa01PayIssuanceFeeViewProvider from '../../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01PayIssuanceFeeViewProvider';
import DebitCardRequestCherryVisa01PayIssuanceFeeProvider from '../../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01PayIssuanceFeeProvider/DebitCardRequestCherryVisa01PayIssuanceFeeProvider';

export type CardIssuanceFeePaymentModalProviderProps = {
  children: React.ReactNode;
};

const CardIssuanceFeePaymentModalProvider: FC<CardIssuanceFeePaymentModalProviderProps> = ({
  children,
}: CardIssuanceFeePaymentModalProviderProps) => {
  const history = useHistory();
  const { debitCardRequestCherryVisa01 } = useContext(
    DebitCardRequestCherryVisa01Context,
  );

  const { userAuthorized, setUserAuthorized } = useContext(
    UserAuthorizedContext,
  );

  const [showModal, hideModal] = useModal(
    () => (
      <CardIssuanceFeePaymentModalContext.Provider
        value={{ showModal, hideModal }}
      >
        <DebitCardRequestCherryVisa01Context.Provider
          value={{ debitCardRequestCherryVisa01 }}
        >
          <UserAuthorizedContext.Provider
            value={{ userAuthorized, setUserAuthorized }}
          >
            <DebitCardRequestCherryVisa01PayIssuanceFeeViewProvider>
              <DebitCardRequestCherryVisa01PayIssuanceFeeProvider>
                <CardIssuanceFeePaymentModal history={history} />
              </DebitCardRequestCherryVisa01PayIssuanceFeeProvider>
            </DebitCardRequestCherryVisa01PayIssuanceFeeViewProvider>
          </UserAuthorizedContext.Provider>
        </DebitCardRequestCherryVisa01Context.Provider>
      </CardIssuanceFeePaymentModalContext.Provider>
    ),
    [debitCardRequestCherryVisa01, history],
  );

  return (
    <CardIssuanceFeePaymentModalContext.Provider
      value={{ showModal, hideModal }}
    >
      {children}
    </CardIssuanceFeePaymentModalContext.Provider>
  );
};
export default CardIssuanceFeePaymentModalProvider;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../../atoms/utils/layout/Margin';

export interface FormCorporateRegistryUploadLayoutProps {
  children: React.ReactElement[];
}

const FormCorporateRegistryUploadLayout: FC<FormCorporateRegistryUploadLayoutProps> = ({
  children,
}: FormCorporateRegistryUploadLayoutProps) => {
  const elements = ['description', 'dropzone', 'checkPoint'];
  const [description, dropzone, checkPoint] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn>
      {description}
      <Margin top={60} />
      {dropzone}
      <Margin top={40} />
      {checkPoint}
    </FlexBoxColumn>
  );
};
export default FormCorporateRegistryUploadLayout;

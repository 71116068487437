import React, { FC } from 'react';
import KycStatusViewProvider from '../../../enhancers/useCase/verification/kycRequest/KycStatusViewProvider';
import KycStatusPage from './KycStatusPage';

const EnhancedKycStatusPage: FC = () => {
  return (
    <KycStatusViewProvider>
      <KycStatusPage />
    </KycStatusViewProvider>
  );
};

export default EnhancedKycStatusPage;

import { useState, useEffect, useCallback } from 'react';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { executeWithTimeout } from '../../../../utils/helpers/timeout';
import { checkInternetConnection } from '../../../../utils/helpers/connection';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';
import {
  BASE_CURRENCY_SYMBOL,
  BASE_CRYPTO_CURRENCY_SYMBOL,
  REQUEST_TIMEOUT,
} from '../../../../config/constants/business';

// UseCase
import AssetSummaryViewUseCase from '../../../../useCases/asset/assetSummary/AssetSummaryViewUseCase';

// Repository (Firestore)
import AssetCryptoCurrencyFirestore from '../../../../repository/asset/assetCryptoCurrency/AssetCryptoCurrencyFirestore';
import AssetSavingsAccountFunctions from '../../../../repository/asset/assetSavingsAccount/AssetSavingsAccountFunctions';
import AssetDebitCardFunctions from '../../../../repository/asset/assetDebitCard/AssetDebitCardFunctions';
import AssetTimeDepositFunctions from '../../../../repository/asset/assetTimeDeposit/AssetTimeDepositFunctions';

// DomainObject
import { useAssetCryptoCurrency } from '../../../../domain/asset/assetCryptoCurrency/AssetCryptoCurrency/useAssetCryptoCurrency';
import { useAssetDebitCard } from '../../../../domain/asset/assetDebitCard/AssetDebitCard/useAssetDebitCard';
import { useAssetTimeDeposit } from '../../../../domain/asset/assetTimeDeposit/AssetTimeDeposit/useAssetTimeDeposit';
import { useAssetSavingsAccount } from '../../../../domain/asset/assetSavingsAccount/AssetSavingsAccount/useAssetSavingsAccount';
import UserAuthorized from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useAssetSummaryViewUseCase = (user?: UserAuthorized) => {
  const [state, setState] = useState<string>(INITIAL);
  /**
   *
   *  DomainObject
   *
   */

  // Asset
  const {
    assetCryptoCurrency,
    setAssetCryptoCurrency,
  } = useAssetCryptoCurrency();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { assetDebitCard, setAssetDebitCard } = useAssetDebitCard();
  const {
    assetSavingsAccount,
    setAssetSavingsAccount,
  } = useAssetSavingsAccount();
  const { assetTimeDeposit, setAssetTimeDeposit } = useAssetTimeDeposit();

  /**
   *
   *  Repository
   *
   */
  const assetCryptoCurrencyRepository = new AssetCryptoCurrencyFirestore();
  const assetSavingsAccountRepository = new AssetSavingsAccountFunctions();
  const assetDebitCardRepository = new AssetDebitCardFunctions();
  const assetTimeDepositRepository = new AssetTimeDepositFunctions();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new AssetSummaryViewUseCase(
    assetCryptoCurrencyRepository,
    assetDebitCardRepository,
    assetTimeDepositRepository,
    assetSavingsAccountRepository,
  );

  /**
   *
   *  Method
   *
   */
  const openAssetSummary = useCallback(async () => {
    if (!user) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const output = await executeWithTimeout(
        useCase.open(user, BASE_CURRENCY_SYMBOL, BASE_CRYPTO_CURRENCY_SYMBOL),
        REQUEST_TIMEOUT,
      );
      // Setter (DomainObject)
      setAssetCryptoCurrency(output.assetCryptoCurrency);
      setAssetTimeDeposit(output.assetTimeDeposit);
      setAssetSavingsAccount(output.assetSavingsAccount);
      // setAssetDebitCard(output.assetDebitCard);

      if (state === IS_LOADING) setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [user]);

  /**
   *
   *  UseEffect
   *
   */
  useEffect(
    () => {
      let isMounted = true;

      if (isMounted && user) openAssetSummary();

      return () => {
        isMounted = false;
      };
    },
    // eslint-disable-next-line
    [user],
  );

  return {
    state,
    setState,
    assetCryptoCurrency,
    assetTimeDeposit,
    assetSavingsAccount,
    assetDebitCard,
  };
};

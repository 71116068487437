import { IIterable } from '../../../interface/Iterator/IIterable';
import CryptoCurrencyAvailable from '../CryptoCurrencyAvailable/CryptoCurrencyAvailable';

class CryptoCurrenciesAvailable implements IIterable<CryptoCurrencyAvailable> {
  private cryptoCurrenciesAvailable: CryptoCurrencyAvailable[] = [];

  public add = (cryptoCurrencyAvailable: CryptoCurrencyAvailable) => {
    this.cryptoCurrenciesAvailable.push(cryptoCurrencyAvailable);
  };

  public head = () => this.cryptoCurrenciesAvailable[0];

  public length = () => this.cryptoCurrenciesAvailable.length;

  public map = <T>(
    callback: (cryptoCurrencyAvailable: CryptoCurrencyAvailable) => T,
  ) => {
    return this.cryptoCurrenciesAvailable.map((cryptoCurrencyAvailable) =>
      callback(cryptoCurrencyAvailable),
    );
  };

  public forEach = (
    callback: (cryptoCurrencyAvailable: CryptoCurrencyAvailable) => void,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const cryptoCurrencyAvailable of this.cryptoCurrenciesAvailable) {
      // eslint-disable-next-line no-await-in-loop
      callback(cryptoCurrencyAvailable);
    }
  };

  public forEachAsync = async (
    callback: (
      cryptoCurrencyAvailable: CryptoCurrencyAvailable,
    ) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const cryptoCurrencyAvailable of this.cryptoCurrenciesAvailable) {
      // eslint-disable-next-line no-await-in-loop
      await callback(cryptoCurrencyAvailable);
    }
  };
}
export default CryptoCurrenciesAvailable;

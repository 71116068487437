// Type
import { TypeOfT } from '../../../types/typeof/Translation.type';

// IUseCase
import IRegisterViewUseCase from '../../../enhancers/useCase/authentication/RegisterViewProvider/IRegisterViewUseCase';

// IService
import ISignUpRequestQueryService from '../../userRequest/signUpRequest/ISignUpRequestQueryService';

// Service
import SignUpRequestQueryService from '../../../service/userRequest/signUpRequest/SignUpRequestQueryService';

// IRepository
import ISignUpRequestRepository from '../../../domain/userRequest/signUpRequest/ISignUpRequestRepository';

class RegisterViewUseCase implements IRegisterViewUseCase {
  private signUpRequestQueryService: ISignUpRequestQueryService;

  constructor(signUpRequestRepository: ISignUpRequestRepository) {
    this.signUpRequestQueryService = new SignUpRequestQueryService(
      signUpRequestRepository,
    );
  }

  public open = async (oobCode: string, t: TypeOfT) => {
    const signUpRequest = await this.signUpRequestQueryService.findByOobCode(
      oobCode,
      t,
    );

    return { signUpRequest };
  };
}
export default RegisterViewUseCase;

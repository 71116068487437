import React, { FC, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ContractTimeDepositContext from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDepositContext';

// Style
import { TableVerticalLayout, TableVerticalStyle } from './style';

// Mapper
import TableContractTimeDepositMapper from './TableContractTimeDepositMapper';

// Component
import TableVertical from '../../../../molecules/table/table/TableVertical';

const PropertyListContractTimeDeposit: FC = () => {
  const { t } = useTranslation();
  const { contractTimeDeposit } = useContext(ContractTimeDepositContext);

  const rows = useMemo(
    () =>
      contractTimeDeposit &&
      TableContractTimeDepositMapper.domainToTd(contractTimeDeposit, t),
    [contractTimeDeposit, t],
  );

  return (
    <TableVertical
      isTableBorder
      layout={TableVerticalLayout}
      rows={rows}
      theme={TableVerticalStyle}
    />
  );
};

export default PropertyListContractTimeDeposit;

import React, { FC, useContext } from 'react';
import { useTransferDebitCardViewUseCase } from './useTransferDebitCardViewUseCase';
import { useTransferDebitCardPageState } from '../../../../pageState/transfer/TransferDebitCardPageState/useTransferDebitCardPageState';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

// Context
import DebitCardV2sContext from '../../../../../domain/debitCard/debitCardV2/DebitCardV2s/DebitCardV2sContext';
import PageRequestStateContext from '../../../../pageState/general/PageRequestState/PageRequestStateContext';
import SavingsAccountsContext from '../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccountsContext';
import TransferDebitCardTypeOptionContext from '../../../../../components/molecules/selector/tabBar/TransferDebitCardTabBar/TransferDebitCardTypeOptionContext';
import TransferDebitCardTransactionV2sContext from '../../../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2s/TransferDebitCardTransactionV2sContext';
import TransferDebitCardPageStateContext from '../../../../pageState/transfer/TransferDebitCardPageState/TransferDebitCardPageStateContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import UserVerifiedContext from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedContext';
import ContractTimeDepositHistoriesContext from '../../../../../domain/contract/contractTimeDepositHistory/ContractTimeDepositHistories/ContractTimeDepositHistoriesContext';

export type TransferDebitCardViewProviderProps = {
  children: React.ReactNode;
};

const TransferDebitCardViewProvider: FC<TransferDebitCardViewProviderProps> = ({
  children,
}: TransferDebitCardViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // PageState
  const { pageState, setPageState } = useTransferDebitCardPageState();

  const {
    state,
    setState,
    contractTimeDepositHistories,
    debitCards,
    savingsAccounts,
    transferTypeOption,
    setTransferTypeOption,
    userVerified,
    transferDebitCardTransactions,
  } = useTransferDebitCardViewUseCase(userAuthorized);

  return (
    <TransferDebitCardPageStateContext.Provider
      value={{ pageState, setPageState }}
    >
      <PageRequestStateContext.Provider value={{ state, setState }}>
        <UserVerifiedContext.Provider value={{ user: userVerified }}>
          <TransferDebitCardTypeOptionContext.Provider
            value={{
              transferTypeOption,
              setTransferTypeOption,
            }}
          >
            <ContractTimeDepositHistoriesContext.Provider
              value={{ contractTimeDepositHistories }}
            >
              <DebitCardV2sContext.Provider value={{ debitCards }}>
                <SavingsAccountsContext.Provider value={{ savingsAccounts }}>
                  <TransferDebitCardTransactionV2sContext.Provider
                    value={{ transferDebitCardTransactions }}
                  >
                    <SystemErrorHandler state={state} setState={setState}>
                      {children}
                    </SystemErrorHandler>
                  </TransferDebitCardTransactionV2sContext.Provider>
                </SavingsAccountsContext.Provider>
              </DebitCardV2sContext.Provider>
            </ContractTimeDepositHistoriesContext.Provider>
          </TransferDebitCardTypeOptionContext.Provider>
        </UserVerifiedContext.Provider>
      </PageRequestStateContext.Provider>
    </TransferDebitCardPageStateContext.Provider>
  );
};
export default TransferDebitCardViewProvider;

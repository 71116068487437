/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Type
import { FormInputCryptoCurrencyTrade } from '../../../../components/organisms/form/cryptoCurrency/cryptoCurrencyTrade/FormCryptoCurrencyTrade/FormInputCryptoCurrencyTrade.type';

// Constant
import { INITIAL } from '../../../../config/constants/requestState';

// DomainObject
import CryptoCurrencyTradeLimit from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeLimit/CryptoCurrencyTradeLimit/CryptoCurrencyTradeLimit';

export interface CryptoCurrencyTradeCreateContextProps {
  requestState: string;
  createCryptoCurrencyTrade: (
    params: FormInputCryptoCurrencyTrade,
    cryptoCurrencyTradeLimit: CryptoCurrencyTradeLimit,
  ) => void;
  executeCreateCryptoCurrencyTrade: (
    code: string,
    callback: () => void,
  ) => Promise<void>;
}

const CryptoCurrencyTradeCreateContext = React.createContext<
  CryptoCurrencyTradeCreateContextProps
>({
  requestState: INITIAL,
  createCryptoCurrencyTrade: (params: FormInputCryptoCurrencyTrade) =>
    undefined,
  executeCreateCryptoCurrencyTrade: (code: string, callback: () => void) =>
    new Promise((resolve) => resolve()),
});

export default CryptoCurrencyTradeCreateContext;

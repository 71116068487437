// IService
import IAuthenticatorCreateService from '../../useCases/authenticator/authenticator/interface/IAuthenticatorCreateService';

// IRepository
import { IAuthenticatorCreateRepository } from '../../domain/authenticator/authenticator/IAuthenticatorRepository';

// DomainObject
import UserAuthorized from '../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import UserReregistration from '../../domain/public/user/User/UserReregistration/UserReregistration';

class AuthenticatorCreateService implements IAuthenticatorCreateService {
  private authenticatorCreateRepository: IAuthenticatorCreateRepository;

  constructor(authenticatorCreateRepository: IAuthenticatorCreateRepository) {
    this.authenticatorCreateRepository = authenticatorCreateRepository;
  }

  public executeCreate = async (user: UserAuthorized) => {
    const authenticator = await this.authenticatorCreateRepository.create(user);
    return authenticator;
  };

  public executeCreateForReregistration = async (
    user: UserReregistration,
    oobCode: string,
  ) => {
    const authenticator = await this.authenticatorCreateRepository.createForReregistration(
      user,
      oobCode,
    );
    return authenticator;
  };
}

export default AuthenticatorCreateService;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

// Style
import {
  LabelStyle,
  DescriptionStyle,
  RadioButtonLayout,
  RadioButtonTheme,
} from './style';

// Component
import { TextSecondary } from '../../../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../../../atoms/text2/LineBreakReplaced';
import FormInputRadioButton from '../../../../../molecules/form/formInput/radioButton/FormInputRadioButton';
import DebitCardRequestFormContainer from '../../../common/DebitCardRequestFormContainer';
import FormCardOptionsLayout from './FormCardOptionsLayout';

const FORM_ID = `organisms.form.debitCards.FormProofOfIdentity.cardOptions`;

const FormCardOptions: FC = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  return (
    <DebitCardRequestFormContainer order={10} formId={FORM_ID}>
      <FormCardOptionsLayout>
        <TextSecondary key="description" theme={DescriptionStyle}>
          <LineBreakReplaced text={t(`${FORM_ID}.description`)} />
        </TextSecondary>
        <FormInputRadioButton
          key="isInscribed"
          labelWidth={250}
          labelProps={{
            label: t(`${FORM_ID}.isInscribed.label`),
            theme: LabelStyle,
          }}
          radioButtonProps={{
            displayItems: [
              t(`${FORM_ID}.isInscribed.uninscribed`),
              t(`${FORM_ID}.isInscribed.inscribed`),
            ],
            layout: RadioButtonLayout,
            items: ['false', 'true'],
            name: 'isInscribed',
            selected: watch('isInscribed'),
            theme: RadioButtonTheme,
          }}
        />
      </FormCardOptionsLayout>
    </DebitCardRequestFormContainer>
  );
};

export default FormCardOptions;

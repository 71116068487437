// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import { CorporateInformationProps } from '../../../../value/verification/kycRequest/KycRequestContent/CorporateInformation/CorporateInformation.type';
import { CorporateAddressProps } from '../../../../value/verification/kycRequest/KycRequestContent/CorporateAddress/CorporateAddress.type';
import { KycRequestCorporateProps } from '../../../../domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporate.type';
import { KycRequestIndividualProps } from '../../../../domain/verification/kycRequest/KycRequest/KycRequestIndividual/KycRequestIndividual.type';
import {
  KycRequestDocumentData,
  KycRequestCorporateDocumentData,
  KycRequestIndividualDocumentData,
} from '../../../../infrastructure/firebase/firestore/collections/verification/kycRequest/KycRequestDocumentData.type';
import { PersonalInformationProps } from '../../../../value/verification/kycRequest/KycRequestContent/PersonalInformation/PersonalInformation.type';
import { ProofOfResidencyProps } from '../../../../value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidency.type';
import { UserType } from '../../../../types/User.type';

// Mapper
import KycStepStatusMapperFirestore from './KycStepStatusMapperFirestore';

class KycRequestMapperFirestore {
  static documentToProps = (documentData: KycRequestDocumentData) => {
    const { type } = documentData;

    return KycRequestMapperFirestore.documentToPropsByUserType(
      documentData,
      type,
    );
  };

  static documentToPropsByUserType = (
    documentData: KycRequestDocumentData,
    userType: UserType,
  ) => {
    const commonProps = {
      username: documentData.username,
      type: userType,
      createdAt: documentData.createdAt,
      updatedAt: documentData.updatedAt,
    };

    switch (userType) {
      case UserType.corporate: {
        const corporateDocumentData = documentData as KycRequestCorporateDocumentData;

        const kycStepStatus = KycStepStatusMapperFirestore.convertCorporateDocDataToDomain(
          corporateDocumentData,
        );
        const corporateInformation = {
          ...corporateDocumentData.corporate.corporateInformation,
          status: kycStepStatus.corporateInformation,
        } as CorporateInformationProps;
        const corporateAddress = {
          ...corporateDocumentData.corporate.corporateAddress,
          status: kycStepStatus.corporateInformation,
        } as CorporateAddressProps;
        const personalInformation = {
          ...corporateDocumentData.corporate.personalInformation,
          status: kycStepStatus.personalInformation,
        } as PersonalInformationProps;
        const proofOfResidency = {
          ...corporateDocumentData.corporate.proofOfResidency,
          status: kycStepStatus.proofOfResidency,
        } as ProofOfResidencyProps;

        return {
          ...commonProps,
          corporateInformation,
          corporateAddress,
          personalInformation,
          proofOfResidency,
          kycStepStatus,
        } as KycRequestCorporateProps;
      }
      case UserType.individual: {
        const individualDocumentData = documentData as KycRequestIndividualDocumentData;

        const kycStepStatus = KycStepStatusMapperFirestore.convertIndividualDocDataToDomain(
          individualDocumentData,
        );
        const personalInformation = {
          ...individualDocumentData.individual.personalInformation,
          status: kycStepStatus.personalInformation,
        } as PersonalInformationProps;
        const proofOfResidency = {
          ...individualDocumentData.individual.proofOfResidency,
          status: kycStepStatus.proofOfResidency,
        } as ProofOfResidencyProps;

        return {
          ...commonProps,
          personalInformation,
          proofOfResidency,
          kycStepStatus,
        } as KycRequestIndividualProps;
      }
      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_SWITCH,
          {
            value: userType,
            place: `KycRequestMapperFirestore.documentToPropsByUserType`,
          },
        );
    }
  };
}

export default KycRequestMapperFirestore;

// IService
import ISignInRequestCreateService from '../../useCases/authentication/signIn/ISignInRequestCreateService';

// IRepository
import { ISignInRequestCreateRepository } from '../../domain/userRequest/signInRequest/ISignInRequestRepository';

// DomainObject
import UserAuthorized from '../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class SignInRequestCreateService implements ISignInRequestCreateService {
  private signInRequestCreator: ISignInRequestCreateRepository;

  constructor(signInRequestCreator: ISignInRequestCreateRepository) {
    this.signInRequestCreator = signInRequestCreator;
  }

  public executeCreate = async (userAuthorized: UserAuthorized) => {
    await this.signInRequestCreator.create(userAuthorized);
  };
}

export default SignInRequestCreateService;

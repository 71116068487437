import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Constant
import { INVITE_INVITATIONS } from '../../../../config/constants/pageId.json';

// Style
// import { DescriptionStyle } from './style';
//
// Component
import { PageTitle } from '../../../atoms/headLine/HeadLine';
// import { TextSecondary } from '../../../atoms/text2/Text2';
// import InvitationCodeCardList from '../../../organisms/list/InvitationCodeCardList';
// import InvitationsTemplateLayout from './InvitationsTemplateLayout';

const InvitationsTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    // <InvitationsTemplateLayout>
    <PageTitle key="title">{t(`${INVITE_INVITATIONS}.title`)}</PageTitle>
    // <TextSecondary key="description" theme={DescriptionStyle}>
    //   {t(`${INVITATION_CODES}.description`)}
    // </TextSecondary>
    // <InvitationCodeCardList key="codes" />
    // </InvitationsTemplateLayout>
  );
};

export default InvitationsTemplate;

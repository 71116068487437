import React, { FC } from 'react';

// Style
import { LabelStyle } from './style';

import { TextPrimary } from '../../../../../../atoms/text2/Text2';
import CherryVisa01DocumentTypeSelectBox from '../../../../../selectBox/selectBox/verification/CherryVisa01DocumentTypeSelectBox';
import FormInputCherryVisa01DocumentTypeLayout from './FormInputCherryVisa01DocumentTypeLayout';

interface FormInputCherryVisa01DocumentTypeProps {
  label: string;
  labelWidth: string | number;
  inputWidth: string | number;
  name: string;
}

const FormInputCherryVisa01DocumentTypeSelectBox: FC<FormInputCherryVisa01DocumentTypeProps> = ({
  label,
  labelWidth,
  inputWidth,
  name,
}: FormInputCherryVisa01DocumentTypeProps) => {
  return (
    <FormInputCherryVisa01DocumentTypeLayout
      inputWidth={inputWidth}
      labelWidth={labelWidth}
    >
      <TextPrimary key="label" theme={LabelStyle}>
        {label}
      </TextPrimary>
      <CherryVisa01DocumentTypeSelectBox key="input" name={name} />
    </FormInputCherryVisa01DocumentTypeLayout>
  );
};

export default FormInputCherryVisa01DocumentTypeSelectBox;

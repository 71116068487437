// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useContext } from 'react';
import ResignUpContext from '../../../enhancers/useCase/authentication/ResignUpProvider/ResignUpContext';

// Constant
import { SUCCESS } from '../../../config/constants/requestState';

// Components
import ResignUpTemplate from '../../../components/templates/front/ResignUpTemplate';
import SignUpPageGroupTemplate from '../../../components/templates/front/SignUpPageGroupTemplate';
import ResignUpCompleteTemplate from '../../../components/templates/front/ResignUpCompleteTemplate';

const ResignUpPage: FC = () => {
  const { state } = useContext(ResignUpContext);

  return (
    <SignUpPageGroupTemplate>
      {(() => {
        if (state === SUCCESS) return <ResignUpCompleteTemplate />;
        return <ResignUpTemplate />;
      })()}
    </SignUpPageGroupTemplate>
  );
};

export default ResignUpPage;

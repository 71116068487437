// eslint-disable-next-line @typescript-eslint/no-unused-vars
import react from 'react';
import firestore from '../firestore';
import { UseStateType } from '../../../../types/typeof/UseState';

class Collection {
  collection: firestore.CollectionReference;

  constructor() {
    this.collection = firestore().collection('collection');
  }

  public fetchAll = async (): Promise<firestore.QueryDocumentSnapshot[]> => {
    const snapshot = await this.collection.get();
    return snapshot.docs;
  };

  public fetchSpecific = async (
    id: string,
    t?: firestore.Transaction,
  ): Promise<firestore.DocumentSnapshot | null> => {
    const reference = this.collection.doc(id);

    const document = t ? await t.get(reference) : await reference.get();

    if (!document.exists) return null;

    return document;
  };

  public fetchOneByUniqueField = async <T extends string>(
    key: T,
    value: string,
  ): Promise<firestore.QueryDocumentSnapshot | null> => {
    const query = this.collection.where(key, '==', value);

    const snapshot = await query.get();

    if (snapshot.size !== 1) return null;

    return snapshot.docs.pop() as firestore.QueryDocumentSnapshot;
  };

  public fetchByFields = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: { [key: string]: any },
  ): Promise<firestore.QueryDocumentSnapshot[]> => {
    const queryRef = Object.keys(params).reduce<firestore.Query>((ref, key) => {
      return ref.where(key, '==', params[key]);
    }, this.collection);

    const snapshot = await queryRef.get();

    return snapshot.docs;
  };

  public create = async <T>(
    id: string | null,
    fields: Partial<T>,
    atomic?: firestore.Transaction | firestore.WriteBatch,
  ) => {
    const reference = id ? this.collection.doc(id) : this.collection.doc();

    if (!atomic) {
      const writeResult = await reference.set(fields);
      return writeResult;
    }

    if (atomic instanceof firestore.Transaction) {
      const t = atomic as firestore.Transaction;
      return t.set(reference, fields);
    }

    if (atomic instanceof firestore.WriteBatch) {
      const batch = atomic as firestore.WriteBatch;
      return batch.set(reference, fields);
    }

    return null;
  };

  public update = async <T>(
    id: string,
    fields: T,
    atomic?: firestore.Transaction | firestore.WriteBatch,
  ): Promise<firestore.Transaction | firestore.WriteBatch | void> => {
    const docRef = this.collection.doc(id);

    if (!atomic) {
      // eslint-disable-next-line no-return-await
      return await docRef.set(fields, { merge: true });
    }

    if (atomic instanceof firestore.Transaction) {
      const t = atomic as firestore.Transaction;
      const updateResult = t.set(
        docRef as firestore.DocumentReference,
        fields,
        {
          merge: true,
        },
      );
      return updateResult;
    }

    const batch = atomic as firestore.WriteBatch;
    const updateResult = batch.set(
      docRef as firestore.DocumentReference,
      fields,
      { merge: true },
    );
    return updateResult;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public query = async <T extends Array<{ key: string; value: any }>>(
    params: T,
    // t?: firestore.Transaction,
  ): Promise<firestore.QueryDocumentSnapshot[] | undefined> => {
    const queryRef = params.reduce<firestore.Query | undefined>(
      (ref, param) => {
        if (!ref) return this.collection.where(param.key, '==', param.value);
        return (ref as firestore.Query).where(param.key, '==', param.value);
      },
      undefined,
    );
    if (!queryRef) return undefined;
    const snapshot = await queryRef.get();
    return snapshot.docs;
  };

  // Deprecated
  public subscribeAllWithArray = <T>(
    setter: React.Dispatch<React.SetStateAction<T[]>>,
    converter: (docs: firestore.QueryDocumentSnapshot[]) => T[],
  ) => {
    return this.collection.onSnapshot((querySnapshot) => {
      setter(converter(querySnapshot.docs));
    });
  };

  public subscribeAll = <T>(
    setter: UseStateType<T>,
    converter: (docs: firestore.QueryDocumentSnapshot[]) => T,
  ) => {
    this.collection.onSnapshot((querySnapshot) => {
      setter(converter(querySnapshot.docs));
    });
  };

  subscribeSpecific = <T>(
    id: string,
    setter: UseStateType<T>,
    converter: (doc: firestore.DocumentSnapshot) => T,
  ) => {
    this.collection.doc(id).onSnapshot((doc) => {
      setter(converter(doc));
    });
  };
}

export default Collection;

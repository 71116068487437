import ConsentFormStepStatus from '../ConsentFormStepStatus/ConsentFormStepStatus';
// import { KycStatusType } from '../../../../../../types/KycRequest.type';
// import SeverErrorFactory from '../../../../../../errors/errors_old/ErrorFactory/ServerErrorFactory';

class StatusTwoFactorAuthenticationAppSettings extends ConsentFormStepStatus {
  // Review is done or not
  // public isReviewed = () => this.isApproved() || this.isReviewing();
  // public submitConsentForm = () => {
  //   switch (this.status) {
  //     case KycStatusType.disabled:
  //       return new StatusTwoFactorAuthenticationAppSettings(
  //         KycStatusType.initial,
  //       );
  //     case KycStatusType.initial:
  //     case KycStatusType.approved:
  //       return this;
  //     default:
  //       throw new SeverErrorFactory().create(
  //         '500',
  //         `Invalid status ${this.status} is found at submitConsentForm on ${this.constructor.name}`,
  //       );
  //   }
  // };
}
export default StatusTwoFactorAuthenticationAppSettings;

import React, { FC } from 'react';

// Component
import HeaderTransferSavingsAccount from '../../../../organisms/header/HeaderContentTitle/transfer/HeaderTransferSavingsAccount';
import ProgressStepBarBankTransaction from '../../../../molecules/progressStep/ProgressStepBar/ProgressStepBarBankTransaction';
import FormTransferSavingsAccount from '../../../../organisms/form/transfer/savingsAccount/FormTransferSavingsAccount';
import TransferSavingsAccountTemplateLayout from './TransferSavingsAccountTemplateLayout';

const TransferSavingsAccountTemplate: FC = () => {
  return (
    <TransferSavingsAccountTemplateLayout>
      <HeaderTransferSavingsAccount key="header" />
      <ProgressStepBarBankTransaction key="progressStep" currentStep="input" />
      <FormTransferSavingsAccount key="form" />
    </TransferSavingsAccountTemplateLayout>
  );
};

export default React.memo(TransferSavingsAccountTemplate);

// Type
import { RankType } from '../../../../types/Invite.type';
import { TimeDepositProps } from './TimeDeposit.type';
import { UserType } from '../../../../types/User.type';
import UserVerified from '../../../public/user/User/UserAuthorized/UserVerified/UserVerified';

class TimeDeposit {
  private id: string;

  private createdAt: number;

  private updatedAt: number;

  private props: TimeDepositProps;

  constructor(id: string, props: TimeDepositProps) {
    this.id = id;
    this.createdAt = props.createdAt ?? Date.now();
    this.updatedAt = props.updatedAt ?? Date.now();
    this.props = props;
  }

  public getId = () => this.id;

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public isMatchUser = (userType: UserType, rank: RankType) =>
    this.props.userType === userType &&
    this.isRankMatch(rank) &&
    this.props.VIP === false;

  public isMatchVIPUser = (userType: UserType) =>
    this.props.userType === userType && this.props.VIP === true;

  public isMatchAnnualPayOut = (isAnnualPayOut: boolean) =>
    this.props.isAnnualPayOut === isAnnualPayOut;

  public isRankMatch = (rank: RankType) => {
    switch (rank) {
      case RankType.D01:
        return this.props.D01;
      case RankType.D02:
        return this.props.D02;
      case RankType.A01:
        return this.props.A01;
      case RankType.A02:
        return this.props.A02;
      case RankType.C01:
        return this.props.C01;
      case RankType.C02:
        return this.props.C02;
      default:
        return false;
    }
  };

  public isLimited = (user: UserVerified) =>
    this.props.limitedCustomers.includes(user.getId()) &&
    this.props.userType === user.getProps().type;
}
export default TimeDeposit;

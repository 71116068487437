// IUseCase
import ISignInTwoFactorAuthUseCase from '../../../enhancers/useCase/authentication/SignInTwoFactorAuthProvider/ISignInTwoFactorAuthUseCase';

// IService
import ISignInRequestUpdateService from './ISignInRequestUpdateService';

// Service
import SignInRequestUpdateService from '../../../service/authentication/SignInRequestUpdateService';

// IRepository
import { ISignInRequestUpdateRepository } from '../../../domain/userRequest/signInRequest/ISignInRequestRepository';

// DomainObject
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class SignInTwoFactorAuthUseCase implements ISignInTwoFactorAuthUseCase {
  private signInRequestUpdateService: ISignInRequestUpdateService;

  constructor(signInRequestUpdateRepository: ISignInRequestUpdateRepository) {
    this.signInRequestUpdateService = new SignInRequestUpdateService(
      signInRequestUpdateRepository,
    );
  }

  public signInTwoFactorAuth = async (user: UserAuthorized, code: string) => {
    await this.signInRequestUpdateService.executeUpdate(user, code);
  };
}
export default SignInTwoFactorAuthUseCase;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Type
import { FormInputCherryVisa01ProofOfIdentity } from '../../../../../../../components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/FormCherryVisa01ProofOfIdentity.type';

// Constant
import { INITIAL } from '../../../../../../../config/constants/requestState';

export interface DebitCardRequestCherryVisa01ProofOfIdentityUpdateContextProps {
  state: string;
  submitProofOfIdentity: (
    content: FormInputCherryVisa01ProofOfIdentity,
    callback?: () => void,
  ) => void;
}

const DebitCardRequestCherryVisa01ProofOfIdentityUpdateContext = React.createContext<
  DebitCardRequestCherryVisa01ProofOfIdentityUpdateContextProps
>({
  state: INITIAL,
  submitProofOfIdentity: (
    content: FormInputCherryVisa01ProofOfIdentity,
    callback?: () => void,
  ) => undefined,
});

export default DebitCardRequestCherryVisa01ProofOfIdentityUpdateContext;

import { OptionObject } from '../../../../../atoms/select/SelectBox/Option.type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMonthOptions = (t: any, numArray: Array<number>) => {
  const array = numArray;

  return array.reduce<Array<OptionObject>>((options, current) => {
    options.push({
      value: String(current),
      label: t(`general.date.month.${current}`),
    });
    return options;
  }, []);
};

export const getDayOptions = (numArray: Array<number>) => {
  const array = numArray;

  return array.reduce<Array<OptionObject>>((options, current) => {
    options.push({
      value: String(current),
      label: String(current),
    });
    return options;
  }, []);
};

export const getYearOptions = (numArray: Array<number>, isOlympic = false) => {
  const array = isOlympic
    ? numArray.filter((value) => value % 4 === 0)
    : numArray;

  return array.reduce<Array<OptionObject>>((options, current) => {
    options.push({
      value: String(current),
      label: String(current),
    });
    return options;
  }, []);
};

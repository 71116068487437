import Joi from '@hapi/joi';

// Type
import { NotificationLogType } from '../../../../types/Notification.type';

export interface NotificationLogProps {
  isRead: boolean;

  type: NotificationLogType;

  createdAt?: number;

  updatedAt?: number;
}

export const NotificationLogPropsFormat = {
  id: Joi.string().required(),

  isRead: Joi.boolean().required(),

  type: Object.keys(NotificationLogType).reduce(
    (joi, type) => joi.valid(type),
    Joi.string(),
  ),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

import React, { FC } from 'react';

// Style
import { UnderConstructionTextStyle } from './style';

// Component
import UnderConstruction from '../../../../organisms/notification/UnderConstruction';

const CryptoCurrencyWithdrawalTemplate: FC = () => {
  return (
    <UnderConstruction
      width="100%"
      height={855}
      iconSize={150}
      descriptionStyle={UnderConstructionTextStyle}
    />
  );
};

export default CryptoCurrencyWithdrawalTemplate;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { INITIAL } from '../../../../config/constants/requestState';

export interface ReregisterContextProps {
  createAuthenticator: (password: string, callback?: () => void) => void;

  reregister: (
    formInput: { code: string },
    callback?: () => void,
  ) => Promise<void>;

  state: string;
}

const ReregisterContext = React.createContext<ReregisterContextProps>({
  createAuthenticator: (password: string, callback?: () => void) => undefined,

  reregister: (formInput: { code: string }) =>
    new Promise((resolve) => resolve(undefined)),

  state: INITIAL,
});

export default ReregisterContext;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import Globe from '../../../../../../assets/images/globe.png';

// JSON
import countryCodeOptions from './countryCodeOptions.json';

// LayoutComponent
import Box from '../../../../../atoms/utils/layout/Box/Box';
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';

// Component
import {
  CountryCodeOptionContainer,
  CountryCodeSingleValueContainer,
} from '../../../option/optionContainer/CountryCodeContainer';
import SelectBox from '../../../../../atoms/select/SelectBox';
import HiddenInput from '../../../../../atoms/form/HiddenInput';

export interface CountryCodeSelectBoxProps {
  name: string;
}

const CountryCodeSelectBox: FC<CountryCodeSelectBoxProps> = ({
  name,
}: CountryCodeSelectBoxProps) => {
  const { t } = useTranslation();
  const { setValue, trigger, watch } = useFormContext();

  return (
    <Box>
      <SelectBox
        borderColor="#d6ddec"
        dropdownColor="#212F41"
        isSearchable={true}
        marginLeft={`${0 / 16}rem`}
        minHeight={`${50 / 16}rem`}
        options={countryCodeOptions}
        onSelect={(value: string) => {
          trigger(['phoneNumber']);
          setValue(name, value);
        }}
        placeholder={
          <FlexBoxRow alignItems="center">
            <img
              src={Globe}
              alt={t(`molecules.select.placeholder.CountryCode`)}
            />
          </FlexBoxRow>
        }
        placeholderColor="#D5D0DA"
        value={watch(name)}
        OptionComponent={CountryCodeOptionContainer}
        SingleValueComponent={CountryCodeSingleValueContainer}
      />
      <HiddenInput name={name} value={watch(name) || ''} />
    </Box>
  );
};

export default CountryCodeSelectBox;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Constant
import { ROUTE_SELECT_USER_TYPE } from '../../../../config/constants/routing';

// Style
import { DescriptionStyle } from './style';

// Component
import {
  ActionButton,
  DefaultButtonTextStyle,
} from '../../../atoms/button/Button2';
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import { TextPrimary, TextSecondary } from '../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';
import KycRequestTemplateLayout from './KycRequestTemplateLayout';

const TEMPLATE_ID = `templates.verification.KycRequestTemplate`;

const KycRequestTemplate: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <KycRequestTemplateLayout>
      <PageTitle key="header">{t(`${TEMPLATE_ID}.title`)}</PageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced text={t(`${TEMPLATE_ID}.description`)} />
      </TextSecondary>
      <ActionButton
        key="button"
        height={50}
        onClick={() => history.push(ROUTE_SELECT_USER_TYPE)}
        theme={{ borderRadius: 25 }}
        width={346}
      >
        <TextPrimary theme={DefaultButtonTextStyle}>
          {t(`atoms.button.startKycProcess`)}
        </TextPrimary>
      </ActionButton>
    </KycRequestTemplateLayout>
  );
};

export default KycRequestTemplate;

import { KycStatusType } from '../../../../types/KycRequest.type';

abstract class KycStatus {
  protected status: KycStatusType;

  constructor(status: KycStatusType) {
    this.status = status;
  }

  public getStatus = () => this.status;

  public isReviewing = () => this.status === KycStatusType.reviewing;

  public isApproved = () => this.status === KycStatusType.approved;

  public isRejected = () => this.status === KycStatusType.rejected;

  public isSubmitted = () => this.status === KycStatusType.submitted;

  public isDisabled = () => this.status === KycStatusType.disabled;
}
export default KycStatus;

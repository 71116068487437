import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormCardOptionsLayoutProps {
  children: React.ReactElement[];
}

const FormCardOptionsLayout: FC<FormCardOptionsLayoutProps> = ({
  children,
}: FormCardOptionsLayoutProps) => {
  const elements = ['description', 'isInscribed'];
  const [description, isInscribed] = getElementsFromKeys(children, elements);
  return (
    <FlexBoxColumn height="auto">
      {description}
      <Margin top={60} />
      {isInscribed}
      <Margin top={40} />
    </FlexBoxColumn>
  );
};
export default FormCardOptionsLayout;

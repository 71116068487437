// Lib
import { isEqualArbitraryOrder } from '../../../utils/helpers/arrayHelper';

// Error Handler
import InvalidDataFoundError from '../../../utils/errors/InvalidDataFoundError';

// Firestore
import SignUpRequestsCollection from '../../../infrastructure/firebase/firestore/collections/userRequest/SignUpRequestsCollection';

// Types
import { SignUpRequestProps } from '../../../domain/userRequest/signUpRequest/SignUpRequest/SignUpRequest.type';

// IRepository
import ISignUpRequestRepository from '../../../domain/userRequest/signUpRequest/ISignUpRequestRepository';
import SignUpRequestFactory from '../../../domain/userRequest/signUpRequest/SignUpRequest/SignUpRequestFactory';

class SignUpRequestFirestore implements ISignUpRequestRepository {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public findByPrimaryKey = async (params: { [key: string]: any }) => {
    if (isEqualArbitraryOrder(Object.keys(params), ['oobCode'])) {
      const signUpRequestParams = params as { oobCode: string };

      const signUpRequest = await this.findByOobCode(signUpRequestParams);

      return signUpRequest;
    }

    throw new InvalidDataFoundError(
      `Invalid parameter ${JSON.stringify(params)} is specified at ${
        this.constructor.name
      }.findByPrimaryKey`,
    );
  };

  private findByOobCode = async (params: { oobCode: string }) => {
    const signUpRequestsCollection = new SignUpRequestsCollection();

    const document = await signUpRequestsCollection.fetchOneByUniqueField(
      params,
    );

    if (!document)
      throw new InvalidDataFoundError(
        `SignUpRequest with oobCode '${params.oobCode}' is not found.`,
      );

    return SignUpRequestFactory.create(
      document.id,
      document.data() as SignUpRequestProps,
    );
  };
}
export default SignUpRequestFirestore;

import styled from 'styled-components';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: #f0f1f7;
  padding: ${10 / 16}rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InnerFrame = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: dashed ${2 / 16}rem #afafaf;

  padding-top: ${60.21 / 16}rem;
  padding-bottom: ${40.73 / 16}rem;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FileUploadButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FileUploadButtonWrap = styled.div`
  width: ${196 / 16}rem;
  height: ${50 / 16}rem;
  display: flex;
  justify-content: center;
`;

export const ButtonStyle = {
  borderRadius: `${8 / 16}rem`,
};

export const ButtonTextStyle = {
  fontSize: 20,
  color: color.white,
  fontWeight: fontWeight.medium,
};

export const ErrorMessageStyle = {
  fontSize: 18,
  color: color.errorMessage,
  fontWeight: fontWeight.regular,
};

export const ExtensionsAllowedTextStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  lineHeight: 36,
};

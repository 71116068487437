import React, { FC } from 'react';

// Component
import AssetEmptyCardLayout from './AssetEmptyCardLayout';

export interface AssetEmptyCardProps {
  layout: {
    width: string | number;
    height: string | number;
    padding: string | number;
  };
}

const AssetEmptyCard: FC<AssetEmptyCardProps> = ({
  layout,
}: AssetEmptyCardProps) => {
  return (
    <AssetEmptyCardLayout layout={layout}>
      <div key="title" />
      <div key="balance" />
    </AssetEmptyCardLayout>
  );
};

export default AssetEmptyCard;

import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import ProofOfResidencyTemplate from '../../../components/templates/kycRequest/ProofOfResidencyTemplate';

const ProofOfResidencyPage: FC = () => {
  return (
    <DashboardTemplate>
      <ProofOfResidencyTemplate />
    </DashboardTemplate>
  );
};

export default ProofOfResidencyPage;

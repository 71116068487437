import React, { FC } from 'react';

// Style
import { HeaderTextStyle, DataTextStyle } from './style';

// Component
import { TextPrimary, TextSecondary } from '../../../../atoms/text2/Text2';
import TrTableVerticalLayout from './TrTableVerticalLayout';

export interface TrTableVerticalProps {
  data: string;
  header: string;
  isBordered: boolean;
  layout: {
    rowHeight: number | string;
    thWidth: number | string;
    tdWidth: number | string;
  };
  theme: {
    td: {
      backgroundColor: string;
      borderBottom?: string;
      textAlign?: string;
    };
    th: {
      backgroundColor: string;
      borderBottom?: string;
      textAlign?: string;
    };
  };
}

const TrTableVertical: FC<TrTableVerticalProps> = ({
  data,
  header,
  isBordered,
  layout,
  theme,
}: TrTableVerticalProps) => {
  return (
    <TrTableVerticalLayout
      isBordered={isBordered}
      layout={layout}
      theme={theme}
    >
      <TextPrimary key="header" theme={HeaderTextStyle}>
        {header}
      </TextPrimary>
      <TextSecondary key="data" theme={DataTextStyle}>
        {data}
      </TextSecondary>
    </TrTableVerticalLayout>
  );
};

export default TrTableVertical;

// Types
import { CryptoCurrencySpotTransactionProps } from './CryptoCurrencySpotTransaction.type';
import { CryptoCurrencyType } from '../../../../../../../types/CryptoCurrency.type';
import {
  CryptoCurrencyTransactionStatusType,
  CryptoCurrencyTransactionType,
} from '../../../../../../../types/CryptoCurrencyTransaction.type';

// DomainObject
import CryptoCurrencyTransactionId from '../../../../../../idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionId';
import CryptoCurrencyTransaction from '../../../../cryptoCurrencyTransaction/CryptoCurrencyTransaction/CryptoCurrencyTransaction';

abstract class CryptoCurrencySpotTransaction extends CryptoCurrencyTransaction {
  protected currency: CryptoCurrencyType;

  protected commonId: string;

  protected fee: number;

  protected status: CryptoCurrencyTransactionStatusType;

  constructor(
    cryptoCurrencyTransactionId: CryptoCurrencyTransactionId,
    props: CryptoCurrencySpotTransactionProps,
  ) {
    super(cryptoCurrencyTransactionId, props);
    this.currency = props.currency;
    this.commonId = props.commonId;
    this.fee = props.fee;
    this.status = props.status;
  }

  public getProps() {
    return {
      ...super.getProps(),
      currency: this.currency,
      commonId: this.commonId,
      fee: this.fee,
      status: this.status,
    };
  }

  public abstract getTransactionType: () => CryptoCurrencyTransactionType;

  public getStatus = () => this.status;

  public getFee = () => this.fee;
}

export default CryptoCurrencySpotTransaction;

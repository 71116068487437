import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  CryptoCurrencyBuyTransactionSeedProps,
  CryptoCurrencyBuyTransactionSeedPropsFormat,
} from './CryptoCurrencyBuyTransactionSeed.type';

// DomainObject
import CryptoCurrencyBuyTransactionSeed from './CryptoCurrencyBuyTransactionSeed';

class CryptoCurrencyBuyTransactionSeedFactory {
  static create = (props: CryptoCurrencyBuyTransactionSeedProps) => {
    const result = Joi.object(
      CryptoCurrencyBuyTransactionSeedPropsFormat,
    ).validate(props);

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: CryptoCurrencyBuyTransactionSeed.name,
          reason: `${result.error.details[0].message}`,
        },
      );

    return new CryptoCurrencyBuyTransactionSeed(props);
  };
}

export default CryptoCurrencyBuyTransactionSeedFactory;

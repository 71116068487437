import React, { FC } from 'react';

// Component
import DebitCardNumberOneDigit from '../../../atoms/image/DebitCard/DebitCardNumberOneDigit';
import DebitCardNumberLayout from './DebitCardNumberLayout';

// DomainObject
import DebitCardV2 from '../../../../domain/debitCard/debitCardV2/DebitCardV2/DebitCardV2';

export interface DebitCardNumberProps {
  debitCardV2: DebitCardV2;
  layout: {
    cardNumber: {
      width4digits: string | number;
      height: string | number;
    };
    oneDigit: {
      width: string | number;
      height: string | number;
    };
  };
}

const DebitCardNumber: FC<DebitCardNumberProps> = ({
  debitCardV2,
  layout,
}: DebitCardNumberProps) => {
  const cardNumber = debitCardV2.getFacedCardNumber();
  const cardNumberDigits = cardNumber.split('');

  return (
    <DebitCardNumberLayout layout={layout.cardNumber}>
      {cardNumberDigits.map((digit, i) => (
        <DebitCardNumberOneDigit
          key={`number${`00${i + 1}`.slice(-2)}`}
          layout={layout.oneDigit}
          digit={
            digit as
              | '0'
              | '1'
              | '2'
              | '3'
              | '4'
              | '5'
              | '6'
              | '7'
              | '8'
              | '9'
              | '*'
          }
        />
      ))}
    </DebitCardNumberLayout>
  );
};

export default DebitCardNumber;

import { useState } from 'react';

export const useCorporateInformationFile = () => {
  const [corporateRegistry, setCorporateRegistry] = useState<
    File | undefined
  >();

  return {
    corporateRegistry,
    setCorporateRegistry,
  };
};

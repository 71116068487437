import React, { FC } from 'react';
import { FaCheck } from 'react-icons/fa';
import { CircleBox } from '../../utils/layout/Box/Box/CircleBox';

export interface IconCheckMarkCircleProps {
  theme: {
    color: string;
    backgroundColor: string;
  };
  size: string | number;
  diameter: string | number;
}

const IconCheckMarkCircle: FC<IconCheckMarkCircleProps> = ({
  theme,
  size,
  diameter,
}: IconCheckMarkCircleProps) => {
  return (
    <CircleBox
      width={diameter}
      height={diameter}
      theme={{ backgroundColor: theme.backgroundColor }}
    >
      <FaCheck color={theme.color} size={size} />
    </CircleBox>
  );
};
export default IconCheckMarkCircle;

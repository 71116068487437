import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Component
import { TextSecondary, TextThemeProps } from '../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';
import IconUnderConstruction from '../../../atoms/icon/UnderConstructionIcon';
import UnderConstructionLayout from './UnderConstructionLayout';

export interface UnderConstructionProps {
  width: string | number;
  height: string | number;
  iconSize: string | number;
  descriptionStyle: TextThemeProps;
}

const ORGANISM_ID = `organisms.notification.UnderConstruction`;

const UnderConstruction: FC<UnderConstructionProps> = ({
  width,
  height,
  iconSize,
  descriptionStyle,
}: UnderConstructionProps) => {
  const { t } = useTranslation();

  return (
    <UnderConstructionLayout width={width} height={height}>
      <IconUnderConstruction key="icon" width={iconSize} height={iconSize} />
      <TextSecondary key="description" theme={descriptionStyle}>
        <LineBreakReplaced text={t(`${ORGANISM_ID}.description`)} />
      </TextSecondary>
    </UnderConstructionLayout>
  );
};

export default UnderConstruction;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState, useEffect } from 'react';

// Lib
import { checkInternetConnection } from '../../../../../../utils/helpers/connection';

// Error
import { ErrorCodeType } from '../../../../../../utils/errors/ErrorHandler/ErrorCode.type';
import ErrorHandler from '../../../../../../utils/errors/ErrorHandler/ErrorHandler';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../../config/constants/requestState';

// UseCase
import ProofOfIdentityViewUseCase from '../../../../../../useCases/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01ProofOfIdentityViewUseCase';

// Repository
import DebitCardRequestCherryVisa01Functions from '../../../../../../repository/debitCard/debitCardRequest/DebitCardRequestCherryVisa01Functions';

// DomainObject
import UserAuthorized from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import { useDebitCardRequestCherryVisa01 } from '../../../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/useDebitCardRequestCherryVisa01';

export const useDebitCardRequestCherryVisa01ProofOfIdentityViewUseCase = (
  id: string,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /**
   *
   *  DomainObject
   *
   */
  const {
    debitCardRequestCherryVisa01,
    setDebitCardRequestCherryVisa01,
  } = useDebitCardRequestCherryVisa01();

  /**
   *
   *  Repository
   *
   */
  const debitCardRequestCherryVisa01Repository = new DebitCardRequestCherryVisa01Functions();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new ProofOfIdentityViewUseCase(
    debitCardRequestCherryVisa01Repository,
  );

  /**
   *
   *  Method
   *
   */
  const open = useCallback(async () => {
    if (!userAuthorized) return;

    setState(IS_LOADING);
    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const output = await useCase.open(id);

      setDebitCardRequestCherryVisa01(output.debitCardRequestCherryVisa01);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized]);

  /**
   *
   *  UseCase
   *
   */
  useEffect(() => {
    let isMounted = true;

    if (userAuthorized && isMounted) open();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [userAuthorized]);

  return {
    state,
    setState,
    debitCardRequestCherryVisa01,
  };
};

import { FC } from 'react';
import InvitationCode from '../InvitationCode/InvitationCode';
import { IIterable } from '../../../interface/Iterator/IIterable';

// Error
import InvalidDataFoundError from '../../../../utils/errors/InvalidDataFoundError';

class InvitationCodes implements IIterable<InvitationCode> {
  private invitationCodes: InvitationCode[] = [];

  constructor(invitationCodes?: InvitationCode[]) {
    this.invitationCodes = invitationCodes || [];
  }

  public add = (invitationCode: InvitationCode) => {
    this.invitationCodes.push(invitationCode);
  };

  public head = () => this.invitationCodes[0];

  public map = <T>(callback: (invitationCode: InvitationCode) => T) => {
    return this.invitationCodes.map((invitationCode) =>
      callback(invitationCode),
    );
  };

  public forEach = (callback: (invitationCode: InvitationCode) => void) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const invitationCode of this.invitationCodes) {
      // eslint-disable-next-line no-await-in-loop
      callback(invitationCode);
    }
  };

  public forEachAsync = async (
    callback: (invitationCode: InvitationCode) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const invitationCode of this.invitationCodes) {
      // eslint-disable-next-line no-await-in-loop
      await callback(invitationCode);
    }
  };

  public mapComponent = (
    item: FC<{ invitationCode: InvitationCode; i: number }>,
  ) => {
    return this.invitationCodes.map((invitationCode, i) =>
      item({ invitationCode, i }),
    );
  };

  public sortByRank = () => {
    const compare = (a: InvitationCode, b: InvitationCode) => {
      const aRank = a.getRawRank();
      const bRank = b.getRawRank();

      return aRank > bRank ? 1 : -1;
    };

    if (this.invitationCodes.length === 0) return this;

    if (
      this.invitationCodes.length === 1 ||
      this.invitationCodes.length === 2 ||
      this.invitationCodes.length === 4
    )
      return new InvitationCodes(this.invitationCodes.sort(compare));

    throw new InvalidDataFoundError(
      `InvitationCodes which has ${this.invitationCodes.length} codes is detected at ${this.constructor.name}.sortByRank`,
    );
  };
}
export default InvitationCodes;

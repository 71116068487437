import React from 'react';
import KycRequestCorporate from './KycRequestCorporate';

export interface KycRequestCorporateContextProps {
  kycRequest?: KycRequestCorporate;
}

const KycRequestCorporateContext = React.createContext<
  KycRequestCorporateContextProps
>({
  kycRequest: undefined,
});

export default KycRequestCorporateContext;

import styled from 'styled-components';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-left: ${33 / 16}rem;
  height: ${80 / 16}rem;
  width: 100%;
  display: flex;
  align-items: center;

  :hover {
    border-left: ${4 / 16}rem solid #299adf;
    background-color: #115386;
  }
`;

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.primary,
  fontWeight: fontWeight.regular,
};

import Joi from '@hapi/joi';

// Type
import { KycStatusType } from '../../../../../types/KycRequest.type';
import {
  KycStepStatusPropsFormat,
  KycStepStatusProps,
  KycStepStatusInitialProps,
} from '../KycStepStatus/KycStepStatus.type';

export interface KycStepStatusCorporateProps extends KycStepStatusProps {
  corporateInformation: KycStatusType;

  corporateAddress: KycStatusType;
}

export const KycStepStatusCorporatePropsFormat = {
  ...KycStepStatusPropsFormat,

  corporateInformation: Object.keys(KycStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),

  corporateAddress: Object.keys(KycStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),
};

export const KycStepStatusCorporateInitialProps = {
  ...KycStepStatusInitialProps,

  corporateInformation: KycStatusType.initial,

  corporateAddress: KycStatusType.disabled,

  personalInformation: KycStatusType.disabled,
};

import React from 'react';
import ResetAuthenticatorRequest from './ResetAuthenticatorRequest';

export interface ResetAuthenticatorRequestContextProps {
  resetAuthenticatorRequest?: ResetAuthenticatorRequest;
}

const ResetAuthenticatorRequestContext = React.createContext<
  ResetAuthenticatorRequestContextProps
>({
  resetAuthenticatorRequest: undefined,
});

export default ResetAuthenticatorRequestContext;

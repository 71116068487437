import React, { FC } from 'react';
import { useModal } from 'react-modal-hook';
import { useHistory } from 'react-router-dom';
import DebitCardMaintenanceModalContext from './DebitCardMaintenanceModalContext';

// Component
import DebitCardMaintenanceModal from './index';

export type DebitCardMaintenanceModalProviderProps = {
  children: React.ReactNode;
};

const DebitCardMaintenanceModalProvider: FC<DebitCardMaintenanceModalProviderProps> = ({
  children,
}: DebitCardMaintenanceModalProviderProps) => {
  const history = useHistory();

  const [showModal, hideModal] = useModal(
    () => <DebitCardMaintenanceModal history={history} />,
    [history],
  );

  return (
    <DebitCardMaintenanceModalContext.Provider value={{ showModal, hideModal }}>
      {children}
    </DebitCardMaintenanceModalContext.Provider>
  );
};
export default DebitCardMaintenanceModalProvider;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import Margin from '../../../atoms/utils/layout/Margin';

export interface SignUpCompleteTemplateLayoutLayoutProps {
  children: React.ReactElement[];
}

const SignUpCompleteTemplateLayoutLayout: FC<SignUpCompleteTemplateLayoutLayoutProps> = ({
  children,
}: SignUpCompleteTemplateLayoutLayoutProps) => {
  const elements = ['title', 'description1', 'email', 'description2', 'link'];
  const [title, description1, email, description2, link] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <>
      {title}
      <Margin top={50}>{description1}</Margin>
      <Margin top={50}>{email}</Margin>
      <Margin top={50}>{description2}</Margin>
      <Margin top={100}>{link}</Margin>
    </>
  );
};
export default SignUpCompleteTemplateLayoutLayout;

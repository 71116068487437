import React, { FC } from 'react';
import { MenuType } from '../../../../config/menu/main/Menu.type';
import { TransferMenuType } from '../../../../config/menu/sub/TransferMenu.type';
import MenuItemWithSub from '../MenuItemWithSub';

const MAIN_MENU_TYPE = MenuType.transfer;

const TransferMenu: FC = () => {
  const TransferMenuItems = Object.keys(TransferMenuType);

  return (
    <MenuItemWithSub
      key={MAIN_MENU_TYPE}
      type={MAIN_MENU_TYPE}
      menuItems={TransferMenuItems}
    />
  );
};

export default TransferMenu;

import React, { FC } from 'react';

// Lib
import { getSymbolFromCurrency } from '../../../../../../utils/helpers/currencyHelper';

// Type
import { CurrencyType } from '../../../../../../types/Currency.type';

// Style
import { LabelStyle } from './style';

// Component
import { TextPrimary, TextSecondary } from '../../../../../atoms/text2/Text2';
import FormInputTransactionAmountLayout from './FormInputTransactionAmountLayout';
import TextField from '../../../TextField2';

interface FormInputTransactionAmountProps {
  label: string;
  currency: CurrencyType;
  inputWidth: string | number;
  name?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any;
}

const FormInputTransactionAmount: FC<FormInputTransactionAmountProps> = ({
  label,
  currency,
  inputWidth,
  name,
  onChange,
  disabled = false,
  register,
}: FormInputTransactionAmountProps) => {
  return (
    <FormInputTransactionAmountLayout inputWidth={inputWidth}>
      <TextPrimary key="label" theme={LabelStyle}>
        {label}
      </TextPrimary>
      <TextSecondary key="currency" theme={LabelStyle}>
        {getSymbolFromCurrency(currency)}
      </TextSecondary>
      <TextField
        key="input"
        name={name || 'amount'}
        disabled={disabled}
        placeholder={'0'}
        textAlign={'right'}
        textInputWidth="100%"
        maxLength={15}
        onChange={onChange}
        register={register}
      />
    </FormInputTransactionAmountLayout>
  );
};

export default FormInputTransactionAmount;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// LayoutComponent
import Margin from '../../../atoms/utils/layout/Margin';
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../atoms/utils/layout/Box/FlexBox';

export interface AssetSummaryTemplateLayoutProps {
  children: React.ReactElement[];
}

const AssetSummaryTemplateLayout: FC<AssetSummaryTemplateLayoutProps> = ({
  children,
}: AssetSummaryTemplateLayoutProps) => {
  const elements = [
    'title',
    'crypto',
    'timeDeposit',
    'savingsAccount',
    'debitCard',
  ];
  const [
    title,
    crypto,
    timeDeposit,
    savingsAccount,
    debitCard,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn width={1200} height="auto" padding="100 0 0 83">
      {title}
      <Margin top={53} />
      {crypto}
      <Margin top={40} />
      <FlexBoxRow height="auto" justifyContent="space-between">
        {timeDeposit}
        {savingsAccount}
        {debitCard}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
export default AssetSummaryTemplateLayout;

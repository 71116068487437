/* eslint-disable @typescript-eslint/no-explicit-any */
// React
import React, { FC, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CryptoCurrencyTransactionsContext from '../../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTransaction/CryptoCurrencyTransactions/CryptoCurrencyTransactionsContext';
import CryptoCurrencyWalletContext from '../../../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWalletContext';

// Mapper
import TableCryptoCurrencyAllHistoryMapper, {
  TdCryptoCurrencyAllHistory,
} from './TableCryptoCurrencyAllHistoryMapper';

// Layout
import DefaultTdBox from '../../../../../molecules/table/td/DefaultTdBox';
import NumberTdBox from '../../../../../molecules/table/td/NumberTdBox';

// Components
import HistoryTable from '../../../../../molecules/table/table/HistoryTable';

// ID
const ORGANISM_ID = `organisms.table.TableCryptoCurrencyTransactionHistory`;

const TableCryptoCurrencyAllHistory: FC = () => {
  const { t } = useTranslation();
  const { cryptoCurrencyTransactions } = useContext(
    CryptoCurrencyTransactionsContext,
  );
  const { cryptoCurrencyWallet } = useContext(CryptoCurrencyWalletContext);

  const currency = useMemo(
    () => cryptoCurrencyWallet?.getProps().currency || '',
    [cryptoCurrencyWallet],
  );

  const data = useMemo(
    () =>
      TableCryptoCurrencyAllHistoryMapper.domainToTd(
        cryptoCurrencyTransactions,
        currency,
        t,
      ),
    [cryptoCurrencyTransactions, currency, t],
  );

  const columns = useMemo(
    () => [
      {
        Header: t(`${ORGANISM_ID}.th.date`),
        accessor: (values: TdCryptoCurrencyAllHistory) => values.createdAt,
        Cell: (value: any) =>
          DefaultTdBox({ text: value.row.original.createdAt }),
        minWidth: `${150 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.transactionType`),
        accessor: (values: TdCryptoCurrencyAllHistory) =>
          values.transactionType,
        Cell: (value: any) =>
          DefaultTdBox({ text: value.row.original.transactionType }),
        minWidth: `${135 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.deposit`, { currency }),
        accessor: (values: TdCryptoCurrencyAllHistory) => values.deposit,
        Cell: (value: any) => NumberTdBox({ text: value.row.original.deposit }),
        minWidth: `${195 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.withdrawal`, { currency }),
        accessor: (values: TdCryptoCurrencyAllHistory) => values.withdrawal,
        Cell: (value: any) =>
          NumberTdBox({ text: value.row.original.withdrawal }),
        minWidth: `${195 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.fee`, { currency }),
        accessor: (values: TdCryptoCurrencyAllHistory) => values.fee,
        Cell: (value: any) => NumberTdBox({ text: value.row.original.fee }),
        minWidth: `${120 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.status`),
        accessor: (values: TdCryptoCurrencyAllHistory) => values.status,
        Cell: (value: any) => DefaultTdBox({ text: value.row.original.status }),
        minWidth: `${165 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.balance`, { currency }),
        accessor: (values: TdCryptoCurrencyAllHistory) => values.balance,
        Cell: (value: any) => NumberTdBox({ text: value.row.original.balance }),
        minWidth: `${165 / 16}rem`,
      },
    ],
    [t, currency],
  );

  return <HistoryTable data={data} columns={columns} width={945} />;
};

export default TableCryptoCurrencyAllHistory;

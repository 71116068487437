import React, { FC } from 'react';
import { BsFillClockFill } from 'react-icons/bs';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { convertRem } from '../../utils/helper/helper';
import { CryptoCurrencyTransactionStatusType } from '../../../../types/CryptoCurrencyTransaction.type';
import color from '../../utils/const/color';

// Error
import InvalidDataFoundError from '../../../../utils/errors/InvalidDataFoundError';

export interface CryptoCurrencyDepositNotificationIconProps {
  status: CryptoCurrencyTransactionStatusType;
  size: string | number;
}

const CryptoCurrencyDepositNotificationIcon: FC<CryptoCurrencyDepositNotificationIconProps> = ({
  status,
  size,
}: CryptoCurrencyDepositNotificationIconProps) => {
  switch (status) {
    case CryptoCurrencyTransactionStatusType.unconfirmed:
      return <BsFillClockFill color={color.orange} size={convertRem(size)} />;
    case CryptoCurrencyTransactionStatusType.confirmed:
      return (
        <RiCheckboxCircleFill color={color.blue} size={convertRem(size)} />
      );
    default:
      throw new InvalidDataFoundError(
        `Invalid status ${status} is found at CryptoCurrencyDepositNotificationIcon`,
      );
  }
};
export default CryptoCurrencyDepositNotificationIcon;

import React, { FC } from 'react';
import { MenuType } from '../../../../config/menu/main/Menu.type';
import { InvitationMenuType } from '../../../../config/menu/sub/InvitationsMenu.type';
import MenuItemWithSub from '../MenuItemWithSub';

const MAIN_MENU_TYPE = MenuType.invitations;

const InvitationMenu: FC = () => {
  const InvitationMenuItems = Object.keys(InvitationMenuType);

  return (
    <MenuItemWithSub
      key={MAIN_MENU_TYPE}
      type={MAIN_MENU_TYPE}
      menuItems={InvitationMenuItems}
    />
  );
};

export default InvitationMenu;

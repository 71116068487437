// IUseCase
import ITransferDebitCardViewUseCase from '../../../enhancers/useCase/transfer/debitCard/TransferDebitCardViewProvider/ITransferDebitCardViewUseCase';

// IService
import IDebitCardV2QueryService from '../../debitCard/debitCard/IDebitCardV2QueryService';
import ISavingsAccountQueryService from '../../bankAccount/savingsAccount/ISavingsAccountQueryService';
import ITransferDebitCardTransactionV2QueryService from './ITransferDebitCardTransactionV2QueryService';
import IContractTimeDepositHistoryQueryService from '../../contract/contractTimeDepositHistory/interfaces/IContractTimeDepositHistoryQueryService';

// Service
import DebitCardV2QueryService from '../../../service/debitCard/debitCard/DebitCardV2QueryService';
import SavingsAccountQueryService from '../../../service/bankAccount/savingsAccount/SavingsAccountQueryService';
import TransferDebitCardTransactionV2QueryService from '../../../service/transfer/debitCard/TransferDebitCardTransactionV2QueryService';
import ContractTimeDepositHistoryQueryService from '../../../service/contract/contractTimeDepositHistory/ContractTimeDepositHistoryQueryService';

// IRepository
import { ITransferDebitCardTransactionV2QueryRepository } from '../../../domain/transaction/transferDebitCardTransaction/ITransferDebitCardTransactionV2Repository';
import IDebitCardV2Repository from '../../../domain/debitCard/debitCardV2/IDebitCardV2Repository';
import ISavingsAccountRepository from '../../../domain/bankAccount/savingsAccount/ISavingsAccountRepository';
import IContractTimeDepositHistoryRepository from '../../../domain/contract/contractTimeDepositHistory/IContractTimeDepositHistoryRepository';

// DomainObject
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import UserVerifiedFactory from '../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedFactory';

class TransferDebitCardViewUseCase implements ITransferDebitCardViewUseCase {
  private contractTimeDepositHistoryQueryService: IContractTimeDepositHistoryQueryService;

  private debitCardV2QueryService: IDebitCardV2QueryService;

  private savingsAccountQueryService: ISavingsAccountQueryService;

  private transferDebitCardQueryService: ITransferDebitCardTransactionV2QueryService;

  constructor(
    contractTimeDepositHistoryQueryRepository: IContractTimeDepositHistoryRepository,
    debitCardV2Repository: IDebitCardV2Repository,
    savingsAccountRepository: ISavingsAccountRepository,
    transferDebitCardTransactionQueryRepository: ITransferDebitCardTransactionV2QueryRepository,
  ) {
    this.contractTimeDepositHistoryQueryService = new ContractTimeDepositHistoryQueryService(
      contractTimeDepositHistoryQueryRepository,
    );

    this.debitCardV2QueryService = new DebitCardV2QueryService(
      debitCardV2Repository,
    );

    this.savingsAccountQueryService = new SavingsAccountQueryService(
      savingsAccountRepository,
    );

    this.transferDebitCardQueryService = new TransferDebitCardTransactionV2QueryService(
      transferDebitCardTransactionQueryRepository,
    );
  }

  public open = async (user: UserAuthorized) => {
    const userId = user.getId();

    // UserAuthorized -> UserVerified
    const userVerified = UserVerifiedFactory.createByUserAuthorized(user);

    // SavingsAccounts
    const [
      debitCards,
      savingsAccounts,
      transferDebitCardTransactions,
      contractTimeDepositHistories,
    ] = await Promise.all([
      this.debitCardV2QueryService.findAllByUserId(userId),
      this.savingsAccountQueryService.findAllByUserId(userId),
      this.transferDebitCardQueryService.findAllByUserId(userId),
      this.contractTimeDepositHistoryQueryService.findAllByUserId(userId),
    ]);

    return {
      contractTimeDepositHistories,
      debitCards,
      savingsAccounts,
      transferDebitCardTransactions,
      userVerified,
    };
  };
}
export default TransferDebitCardViewUseCase;

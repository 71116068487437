// React
import { useState, useEffect, useMemo } from 'react';

// ErrorHandler
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';

// UseCase
import InvitationCodeViewUseCase from '../../../../useCases/invite/invitationCode/InvitationCodesViewUseCase';

// Repository
import InvitationCodeFirestore from '../../../../repository/invite/invitationCode/InvitationCodeFirestore';

// DomainObject
import UserAuthorized from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import { useInvitationCodes } from '../../../../domain/invite/invitationCode/InvitationCodes/useInvitationCodes';
import { useUserVerified } from '../../../../domain/public/user/User/UserAuthorized/UserVerified/useUserVerified';

export const useInvitationCodeViewUseCase = (user?: UserAuthorized) => {
  const [state, setState] = useState<string>(INITIAL);

  // DomainObject
  const { invitationCodes, setInvitationCodes } = useInvitationCodes();
  const { userVerified, setUserVerified } = useUserVerified();

  // Sort
  const invitationCodesSorted = useMemo(() => invitationCodes.sortByRank(), [
    invitationCodes,
  ]);

  // Repository
  const invitationCodeRepository = new InvitationCodeFirestore();

  // UseCase
  const useCase = new InvitationCodeViewUseCase(invitationCodeRepository);

  // UseCase.open
  useEffect(() => {
    const openInvitationCodes = async () => {
      setState(IS_LOADING);

      try {
        // Is ONLINE?
        checkInternetConnection();

        // Setters
        const setters = {
          invitationCodes: setInvitationCodes,
          userVerified: setUserVerified,
        };

        // execute
        await useCase.open(user!, setters, setState);
        setState(SUCCESS);
      } catch (error) {
        const handler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    };
    if (user) openInvitationCodes();
    // eslint-disable-next-line
  }, [user]);

  return {
    state,
    setState,
    invitationCodes: invitationCodesSorted,
    setInvitationCodes,
    userVerified,
  };
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import { FlexRow } from '../../../../atoms/utils/layout/Flex';
import { Container } from './style';

export interface CryptoCurrencyTradeButtonGroupLayoutProps {
  children: React.ReactElement[];
  width?: string | number;
}

const CryptoCurrencyTradeButtonGroupLayout: FC<CryptoCurrencyTradeButtonGroupLayoutProps> = ({
  children,
  width = '100%',
}: CryptoCurrencyTradeButtonGroupLayoutProps) => {
  const elements = ['left', 'right'];
  const [left, right] = getElementsFromKeys(children, elements);

  return (
    <Container>
      <FlexRow justifyContent="space-between" style={{ width }}>
        {left}
        {right}
      </FlexRow>
    </Container>
  );
};
export default CryptoCurrencyTradeButtonGroupLayout;

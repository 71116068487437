/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';

// Style
import { OptionTextStyle, BalanceTextStyle, Hover } from './style';

// LayoutComponent
import Margin from '../../../../../atoms/utils/layout/Margin';
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../../../atoms/utils/layout/Box/FlexBox';
import LabeledCurrencyAmount from '../../../../textGroup/LabeledCurrencyAmount';

// Component
import { TextSecondary } from '../../../../../atoms/text2/Text2';

const MOLECULES_ID = `molecules.select.debitCard`;

const DebitCardBalanceOptionContent: FC = (props: any) => {
  const { balance, currency, label, type } = props.data;
  const { t } = useTranslation();

  return (
    <FlexBoxColumn padding="15 0 15 0">
      <FlexBoxRow alignItems="center">
        <TextSecondary theme={OptionTextStyle}>
          {t(`${MOLECULES_ID}.type`, { type })}
        </TextSecondary>
        <Margin left={5} />
        <TextSecondary theme={OptionTextStyle}>{`${label}`}</TextSecondary>
      </FlexBoxRow>
      <FlexBoxRow
        justifyContent="flex-end"
        textAlign="right"
        width="100%"
        paddingRight={65}
      >
        <div>
          <LabeledCurrencyAmount
            amount={balance}
            currency={currency}
            direction="row"
            isSymbol
            label={t(`${MOLECULES_ID}.balance`)}
            labelFontFamily="secondary"
            marginBetween={5}
            theme={{
              amount: BalanceTextStyle,
              currency: BalanceTextStyle,
              label: BalanceTextStyle,
            }}
          />
        </div>
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};

const DebitCardBalanceSingleValueContainer: FC = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <FlexBoxRow alignItems="center" paddingRight={15}>
        <DebitCardBalanceOptionContent {...props} />
      </FlexBoxRow>
    </components.SingleValue>
  );
};

const DebitCardBalanceOptionContainer: FC = (props: any) => {
  return (
    <Hover>
      <components.Option {...props}>
        <FlexBoxRow alignItems="center">
          <DebitCardBalanceOptionContent {...props} />
        </FlexBoxRow>
      </components.Option>
    </Hover>
  );
};

export {
  DebitCardBalanceSingleValueContainer,
  DebitCardBalanceOptionContainer,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  required,
  onlyNumbers,
  // validAddress,
  validPhoneNumber,
} from '../validator';

// Type
import { TypeOfT } from '../../../../types/typeof/Translation.type';

const shippingAddressPhoneRegister = (
  t: TypeOfT,
  params?: { [key: string]: any },
) => {
  return {
    required: required('Phone Number', t),
    validate: {
      onlyNumbers: onlyNumbers('Phone Number', t),
      validPhoneNumber: validPhoneNumber(params ? params.watch : '', t),
    },
  };
};

const shippingAddressReceiverRegister = (t: TypeOfT) => {
  return {
    required: required('Receiver Name', t),
  };
};

export { shippingAddressPhoneRegister, shippingAddressReceiverRegister };

import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  padding: ${30 / 16}rem;
  width: 100%;
  background-color: #ffedef;
  display: flex;
  flex-direction: column;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
`;

import Collection from '../../../collection/Collection';
import firestore from '../../../firestore';
import { FIRESTORE_DATA_VERSION } from '../../../../../../config/constants/firebase';

class LogsUpdatePassword extends Collection {
  collection: firestore.CollectionReference;

  constructor(id: string) {
    super();
    this.collection = firestore()
      .collection('logs')
      .doc(FIRESTORE_DATA_VERSION)
      .collection('users')
      .doc(id)
      .collection('updatePassword');
  }
}

export default LogsUpdatePassword;

// Type
import { UseStateType } from '../../../types/typeof/UseState';

// IUseCase
import IMaintenanceUseCase, {
  SettersMaintenance,
} from '../../../enhancers/useCase/setting/maintenance/MaintenanceProvider/IMaintenanceUseCase';

// IService
import IMaintenanceQueryService from './IMaintenanceQueryService';

// Service
import MaintenanceQueryService from '../../../service/settings/maintenance/MaintenanceQueryService';

// IRepository
import IMaintenanceRepository from '../../../domain/settings/maintenance/Maintenance/IMaintenanceRepository';

class MaintenanceUseCase implements IMaintenanceUseCase {
  private maintenanceQueryService: IMaintenanceQueryService;

  constructor(maintenanceRepository: IMaintenanceRepository) {
    this.maintenanceQueryService = new MaintenanceQueryService(
      maintenanceRepository,
    );
  }

  public open = async (
    setters: SettersMaintenance,
    setState: UseStateType<string>,
  ) => {
    await this.maintenanceQueryService.setIsMaintenance(
      setters.maintenance,
      setState,
    );
  };

  public executeUnsubscribe = () => {
    this.maintenanceQueryService.executeUnsubscribe();
  };
}
export default MaintenanceUseCase;

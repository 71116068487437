import React, { FC } from 'react';
import { CurrencyType } from '../../../../types/Currency.type';
import { CurrencyNameStyle, CurrencyShortNameStyle } from './style';
import {
  getSymbolFromCurrency,
  getNameFromCurrency,
} from '../../../../utils/helpers/currencyHelper';
import { TextPrimary } from '../../../atoms/text2/Text2';
import CurrencyNameWithShortNameLayout from './CurrencyNameWithShortNameLayout';

export interface CurrencyNameWithShortNameProps {
  currency: CurrencyType;
}

const CurrencyNameWithShortName: FC<CurrencyNameWithShortNameProps> = ({
  currency,
}: CurrencyNameWithShortNameProps) => {
  return (
    <CurrencyNameWithShortNameLayout>
      <TextPrimary key="name" theme={CurrencyNameStyle}>
        {getNameFromCurrency(currency)}
      </TextPrimary>
      <TextPrimary key="shortName" theme={CurrencyShortNameStyle}>
        {`(${getSymbolFromCurrency(currency)})`}
      </TextPrimary>
    </CurrencyNameWithShortNameLayout>
  );
};

export default CurrencyNameWithShortName;

import styled from 'styled-components';
import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const ThStyle = {
  backgroundColor: color.background.periwinkle,
};

export const ThTextStyle = {
  fontSize: 18,
  color: color.text.primary,
  fontWeight: fontWeight.medium,
};

export const TdTextStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.medium,
};

export const TableStyle = styled.div`
  thead {
    th {
      border-right: ${0.55 / 16}rem solid #fff;
    }
    th:last-child {
      border-right: none;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: ${color.background.ghostWhite};
    }
    tr:nth-child(even) {
      background-color: ${color.background.white};
    }
    td {
      word-wrap: break-word;
      border-left: ${1.5 / 16}rem solid #cddae8;
      border-bottom: ${1.5 / 16}rem solid #cddae8;
    }
    td: last-child {
      border-right: ${1.5 / 16}rem solid #cddae8;
    }
  }
`;

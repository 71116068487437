import React, { FC } from 'react';
import ContractTimeDepositRenewalReservationAddPage from './ContractTimeDepositRenewalReservationAddPage';
import ContractTimeDepositRenewalReservationViewProvider from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationViewProvider';
import ContractTimeDepositRenewalReservationCreateProvider from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationCreateProvider';

const EnhancedContractTimeDepositRenewalReservationAddPage: FC = () => {
  return (
    <ContractTimeDepositRenewalReservationViewProvider>
      <ContractTimeDepositRenewalReservationCreateProvider>
        <ContractTimeDepositRenewalReservationAddPage />
      </ContractTimeDepositRenewalReservationCreateProvider>
    </ContractTimeDepositRenewalReservationViewProvider>
  );
};

export default EnhancedContractTimeDepositRenewalReservationAddPage;

import React, { FC } from 'react';
import { TextSubRegular18 } from '../../TextSub';
import { DomainTextProps } from '../DomainText.type';

const TextErrorMessage: FC<DomainTextProps> = ({
  children,
  color = '#d06e77',
}: DomainTextProps) => (
  <TextSubRegular18 color={color}>{children}</TextSubRegular18>
);
export default TextErrorMessage;

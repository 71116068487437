import React, { FC } from 'react';
import VerifyAuthenticator from '../../../enhancers/auth/authenticator/VerifyAuthenticator';
import UpdateAuthenticator from '../../../enhancers/auth/authenticator/UpdateAuthenticator';
import TwoFactorAuthAppEditPage from './TwoFactorAuthAppEditPage';

const EnhancedTwoFactorAuthAppEditPage: FC = () => {
  return (
    <VerifyAuthenticator>
      <UpdateAuthenticator>
        <TwoFactorAuthAppEditPage />
      </UpdateAuthenticator>
    </VerifyAuthenticator>
  );
};

export default EnhancedTwoFactorAuthAppEditPage;

import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthError from '../containers/error/AuthError';
import { ROUTE_AUTH_ERROR } from '../config/constants/routing';

interface ErrorRouterProps {
  match: { url: string };
}

const ErrorRouter: FC<ErrorRouterProps> = () => {
  return (
    <Switch>
      <Route path={ROUTE_AUTH_ERROR}>
        <AuthError />
      </Route>
    </Switch>
  );
};

export default ErrorRouter;

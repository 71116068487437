import React, { FC } from 'react';
import PaginationUI from '@material-ui/lab/Pagination';
import { Container } from './style';

export interface PaginationProps {
  pageCount: number;
  gotoPage: (page: number) => void;
  pageIndex: number;
}

const Pagination: FC<PaginationProps> = ({
  pageCount,
  gotoPage,
  pageIndex,
}: PaginationProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const gotoSpecificPage = (event: any, page: number) => {
    gotoPage(page - 1);
  };

  return (
    <Container>
      <PaginationUI
        count={pageCount}
        shape="rounded"
        page={pageIndex + 1}
        onChange={gotoSpecificPage}
      />
    </Container>
  );
};

export default Pagination;

// Type
import { UseStateType } from '../../../types/typeof/UseState';

// IUseCase
import ICryptoCurrencyDepositWithdrawalViewUseCase from '../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyDepositWithdrawalViewProvider/ICryptoCurrencyDepositWithdrawalViewUseCase';

// IService
import IAssetCryptoCurrencyQueryService from '../../asset/assetSummary/IAssetCryptoCurrencyQueryService';
import ICryptoCurrencyWalletQueryService from '../cryptoCurrencyWallet/interface/ICryptoCurrencyWalletQueryService';
import ICryptoCurrencyDepositQueryService from '../../transaction/cryptoCurrencyTransaction/cryptoCurrencyDeposit/ICryptoCurrencyDepositQueryService';

// Service
import AssetCryptoCurrencyQueryService from '../../../service/asset/assetCurrency/AssetCryptoCurrencyQueryService';
import CryptoCurrencyWalletQueryServiceFactory from '../../../service/cryptocurrency/cryptoCurrencyWallet/cryptoCurrencyWallet/CryptoCurrencyWalletQueryServiceFactory';
import CryptoCurrencyDepositQueryService from '../../../service/transaction/cryptoCurrencyTransaction/CryptoCurrencyDeposit/CryptoCurrencyDepositQueryService';

// IRepository
import IAssetCryptoCurrencyRepository from '../../../domain/asset/assetCryptoCurrency/IAssetCryptoCurrencyRepository';
import ICryptoCurrencyWalletRepository from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/ICryptoCurrencyWalletRepository';
import ICryptoCurrencyDepositRepository from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/ICryptoCurrencyDepositRepository';

// DomainObject
import AssetsCryptoCurrency from '../../../domain/asset/assetCryptoCurrency/AssetsCryptoCurrency/AssetsCryptoCurrency';
import CryptoCurrencyDeposit from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/CryptoCurrencyDeposit/CryptoCurrencyDeposit';
import CryptoCurrencyWallet from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet';
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class CryptoCurrencyDepositWithdrawalViewUseCase
  implements ICryptoCurrencyDepositWithdrawalViewUseCase {
  private assetCryptoCurrencyQueryService: IAssetCryptoCurrencyQueryService;

  private cryptoCurrencyWalletQueryService: ICryptoCurrencyWalletQueryService;

  private cryptoCurrencyDepositQueryService: ICryptoCurrencyDepositQueryService;

  constructor(
    assetCryptoCurrencyRepository: IAssetCryptoCurrencyRepository,
    cryptoCurrencyWalletRepository: ICryptoCurrencyWalletRepository,
    cryptoCurrencyDepositRepository: ICryptoCurrencyDepositRepository,
  ) {
    this.assetCryptoCurrencyQueryService = new AssetCryptoCurrencyQueryService(
      assetCryptoCurrencyRepository,
    );
    this.cryptoCurrencyWalletQueryService = CryptoCurrencyWalletQueryServiceFactory.create(
      cryptoCurrencyWalletRepository,
    );
    this.cryptoCurrencyDepositQueryService = new CryptoCurrencyDepositQueryService(
      cryptoCurrencyDepositRepository,
    );
  }

  public open = async (
    user: UserAuthorized,
    setter: UseStateType<AssetsCryptoCurrency>,
    setState: UseStateType<string>,
  ) => {
    const userId = user.getId();
    await this.assetCryptoCurrencyQueryService.setByUserId(
      userId,
      setter,
      setState,
    );
  };

  public selectCryptoCurrency = async (
    user: UserAuthorized,
    setter: UseStateType<CryptoCurrencyWallet | undefined>,
    setState: UseStateType<string>,
  ) => {
    const userId = user.getId();
    const subscribeCryptoCurrencyWallet = this.cryptoCurrencyWalletQueryService.subscribeMainWallet(
      userId,
      setter,
      setState,
    );

    await Promise.all([subscribeCryptoCurrencyWallet]);
  };

  public subscribeNewCryptoCurrencyDeposit = async (
    cryptoCurrencyWallet: CryptoCurrencyWallet,
    setter: UseStateType<CryptoCurrencyDeposit | undefined>,
    setState: UseStateType<string>,
  ) => {
    const subscribeNewDeposit = this.cryptoCurrencyDepositQueryService.subscribeNewDeposit(
      cryptoCurrencyWallet,
      setter,
      setState,
    );

    await Promise.all([subscribeNewDeposit]);
  };
}
export default CryptoCurrencyDepositWithdrawalViewUseCase;

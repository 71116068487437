// Type
import { CurrencyPairFactory } from '../../../types/CurrencyPair.type';
import { UseStateType } from '../../../types/typeof/UseState';

// IUseCase
import ICryptoCurrencyTradeViewUseCase, {
  SettersCryptoCurrencyTradeView,
} from '../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyTradeViewProvider/ICryptoCurrencyTradeViewUseCase';

// IService
import IAssetCryptoCurrencyQueryService from '../../asset/assetSummary/IAssetCryptoCurrencyQueryService';
import ICryptoCurrencyWalletQueryService from '../cryptoCurrencyWallet/interface/ICryptoCurrencyWalletQueryService';
import ISavingsAccountQueryService from '../../bankAccount/savingsAccount/ISavingsAccountQueryService';
import ICryptoCurrencyTradeLimitService from '../cryptoCurrencyTradeLimit/interface/ICryptoCurrencyTradeLimitService';

// Service
import AssetCryptoCurrencyQueryService from '../../../service/asset/assetCurrency/AssetCryptoCurrencyQueryService';
import CryptoCurrencyWalletQueryServiceFactory from '../../../service/cryptocurrency/cryptoCurrencyWallet/cryptoCurrencyWallet/CryptoCurrencyWalletQueryServiceFactory';
import SavingsAccountQueryService from '../../../service/bankAccount/savingsAccount/SavingsAccountQueryService';
import CryptoCurrencyTradeLimitQueryService from '../../../service/cryptocurrency/cryptoCurrencyTradeLimit/CryptoCurrencyTradeLimitQueryService';

// IRepository
import IAssetCryptoCurrencyRepository from '../../../domain/asset/assetCryptoCurrency/IAssetCryptoCurrencyRepository';
import ICryptoCurrencyTradeLimitRepository from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeLimit/ICryptoCurrencyTradeLimitRepository';
import ICryptoCurrencyWalletRepository from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/ICryptoCurrencyWalletRepository';
import ISavingsAccountRepository from '../../../domain/bankAccount/savingsAccount/ISavingsAccountRepository';

// DomainObject
import CryptoCurrencyWallet from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet';
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import UserVerifiedFactory from '../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedFactory';

import { CryptoCurrencyType } from '../../../types/CryptoCurrency.type';
import { FiatCurrencyType } from '../../../types/FiatCurrency.type';

class CryptoCurrencyTradeViewUseCase
  implements ICryptoCurrencyTradeViewUseCase {
  private assetCryptoCurrencyQueryService: IAssetCryptoCurrencyQueryService;

  private cryptoCurrencyWalletQueryService: ICryptoCurrencyWalletQueryService;

  private savingsAccountQueryService: ISavingsAccountQueryService;

  private cryptoCurrencyTradeLimitQueryService: ICryptoCurrencyTradeLimitService;

  constructor(
    assetCryptoCurrencyRepository: IAssetCryptoCurrencyRepository,
    cryptoCurrencyWalletRepository: ICryptoCurrencyWalletRepository,
    savingsAccountRepository: ISavingsAccountRepository,
    cryptoCurrencyTradeLimitRepository: ICryptoCurrencyTradeLimitRepository,
  ) {
    this.assetCryptoCurrencyQueryService = new AssetCryptoCurrencyQueryService(
      assetCryptoCurrencyRepository,
    );

    this.cryptoCurrencyWalletQueryService = CryptoCurrencyWalletQueryServiceFactory.create(
      cryptoCurrencyWalletRepository,
    );

    this.savingsAccountQueryService = new SavingsAccountQueryService(
      savingsAccountRepository,
    );

    this.cryptoCurrencyTradeLimitQueryService = new CryptoCurrencyTradeLimitQueryService(
      cryptoCurrencyTradeLimitRepository,
    );
  }

  public open = async (
    user: UserAuthorized,
    cryptoCurrency: CryptoCurrencyType,
    fiatCurrency: FiatCurrencyType,
    setters: SettersCryptoCurrencyTradeView,
    setState: UseStateType<string>,
  ) => {
    const userId = user.getId();

    // UserAuthorized -> UserVerified
    const userVerified = UserVerifiedFactory.createByUserAuthorized(user);
    const currencyPair = CurrencyPairFactory.create(
      fiatCurrency,
      cryptoCurrency,
    );

    // Fetch Assets
    await this.assetCryptoCurrencyQueryService.setByUserId(
      userId,
      setters.assetsCryptoCurrency,
      setState,
    );

    // Fetch Wallet
    this.cryptoCurrencyWalletQueryService.subscribeMainWallet(
      userId,
      setters.cryptoCurrencyWallet,
      setState,
    );

    const [savingsAccounts, cryptoCurrencyTradeLimit] = await Promise.all([
      // Fetch SavingsAccount
      this.savingsAccountQueryService.findAllByUserId(userId),
      this.cryptoCurrencyTradeLimitQueryService.findByCurrencyPair(
        currencyPair,
      ),
    ]);

    return {
      user: userVerified,
      savingsAccounts,
      cryptoCurrencyTradeLimit,
    };
  };

  public selectCryptoCurrency = async (
    user: UserAuthorized,
    setter: UseStateType<CryptoCurrencyWallet | undefined>,
    setState: UseStateType<string>,
  ) => {
    const userId = user.getId();

    await this.cryptoCurrencyWalletQueryService.subscribeMainWallet(
      userId,
      setter,
      setState,
    );
  };
}
export default CryptoCurrencyTradeViewUseCase;

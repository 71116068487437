import React, { FC } from 'react';
import ProgressStepCircle from '../../../atoms/progressStep/ProgressStepCircle/ProgressStepCircle';
import ProgressStepPointLayout from './ProgressStepPointLayout';
import { ProgressStepCircleStatusType } from '../../../atoms/progressStep/ProgressStepCircle/ProgressStepCircle/ProgressStepCircle.type';
import { TextPrimary } from '../../../atoms/text2/Text2';

export interface ProgressStepPointProps {
  stepName: string;
  status: ProgressStepCircleStatusType;
  diameterLargeCircle: string | number;
  diameterSmallCircle: string | number;
  marginBetween: number;
  theme: {
    circleStyle: {
      backgroundColorLarge: string;
      backgroundColorSmall: string;
      backgroundColorDisabled: string;
    };
    iconStyle: {
      iconColor: string;
      iconSize: string;
    };
    textStyle: {
      fontSize: string | number;
      color: string;
      fontWeight: number;
    };
  };
}

const ProgressStepPoint: FC<ProgressStepPointProps> = ({
  diameterLargeCircle,
  diameterSmallCircle,
  status,
  stepName,
  marginBetween,
  theme,
}: ProgressStepPointProps) => {
  return (
    <ProgressStepPointLayout
      marginBetween={marginBetween}
      diameterCircle={diameterLargeCircle}
    >
      <ProgressStepCircle
        key="circle"
        diameterLargeCircle={diameterLargeCircle}
        diameterSmallCircle={diameterSmallCircle}
        status={status}
        theme={theme}
      />
      <TextPrimary key="text" theme={theme.textStyle}>
        {stepName}
      </TextPrimary>
    </ProgressStepPointLayout>
  );
};
export default ProgressStepPoint;

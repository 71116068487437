import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Constant
import { ROUTE_PREFIX_CONTRACT_TIME_DEPOSIT } from '../../../../../config/constants/routing';

// Component
import { PageTitle } from '../../../../atoms/headLine/HeadLine';
import BreadCrumbLink from '../../../../molecules/navigation/BreadCrumbLink';
import HeaderContentTitleLayout from '../HeaderContentTitleLayout';

const ORGANISM_ID = `organisms.header.contract.HeaderContractTimeDepositRenewalReservationEdit`;

const HeaderContractTimeDepositRenewalReservationEditComplete: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <HeaderContentTitleLayout>
      <PageTitle key="left">{t(`${ORGANISM_ID}.title`)}</PageTitle>
      <BreadCrumbLink
        key="right"
        onClick={() => {
          history.push(ROUTE_PREFIX_CONTRACT_TIME_DEPOSIT);
        }}
        text={t(`atoms.button.back`)}
      />
    </HeaderContentTitleLayout>
  );
};

export default HeaderContractTimeDepositRenewalReservationEditComplete;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// Original Lib
import { convertRem } from '../../../../../atoms/utils/helper/helper';

// Style
import { ErrorMessageContainer } from './style';

// LayoutComponent
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormInputTextFieldLayoutProps {
  children: React.ReactElement[];
  layoutProps: {
    width: string | number;
    inputWidth: string | number;
  };
}

const FormInputTextFieldLayout: FC<FormInputTextFieldLayoutProps> = (
  props: FormInputTextFieldLayoutProps,
) => {
  const {
    children,
    layoutProps: { width, inputWidth },
  } = props;

  const elements = ['label', 'input', 'errorMessage'];
  const [label, input, errorMessage] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn width={width}>
      <FlexBoxRow alignItems="center" justifyContent="space-between">
        {label}
        <FlexBoxRow alignItems="center" width={inputWidth}>
          {input}
        </FlexBoxRow>
      </FlexBoxRow>
      <Margin top={10} />
      <FlexBoxRow alignItems="center" justifyContent="flex-end">
        <ErrorMessageContainer style={{ width: convertRem(inputWidth) }}>
          {errorMessage}
        </ErrorMessageContainer>
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
export default FormInputTextFieldLayout;

// IService
import ICryptoCurrencyBuyTransactionQueryService from '../../../../useCases/transaction/cryptoCurrencyTransaction/cryptoCurrencyBuyTransaction/ICryptoCurrencyBuyTransactionQueryService';

// IRepository
import ICryptoCurrencyBuyTransactionRepository from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyBuyTransaction/ICryptoCurrencyBuyTransactionRepository';

// DomainObject
import CryptoCurrencyWallet from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet';

class CryptoCurrencyBuyTransactionQueryService
  implements ICryptoCurrencyBuyTransactionQueryService {
  private cryptoCurrencyBuyTransactionRepository: ICryptoCurrencyBuyTransactionRepository;

  constructor(
    cryptoCurrencyBuyTransactionRepository: ICryptoCurrencyBuyTransactionRepository,
  ) {
    this.cryptoCurrencyBuyTransactionRepository = cryptoCurrencyBuyTransactionRepository;
  }

  public findByWallet = async (cryptoCurrencyWallet: CryptoCurrencyWallet) => {
    const address = cryptoCurrencyWallet.getAddress();

    const { currency } = cryptoCurrencyWallet.getProps();

    const cryptoCurrencyBuyTransactions = await this.cryptoCurrencyBuyTransactionRepository.findAllByFields(
      {
        address,
        currency,
      },
    );

    return cryptoCurrencyBuyTransactions;
  };
}

export default CryptoCurrencyBuyTransactionQueryService;

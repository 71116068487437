class Authenticator {
  private userId: string;

  private accountKey: string;

  constructor(userId: string, accountKey: string) {
    this.userId = userId;
    this.accountKey = accountKey;
  }

  public getId = () => this.userId;

  public getProps = () => ({
    accountKey: this.accountKey,
  });
}
export default Authenticator;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';
import DropzoneAreaContext from '../DropzoneArea/DropzoneAreaContext';

// Type
import { FileType } from '../../../../types/File.type';

// Constant
import {
  DeleteButtonStyle,
  DeleteButtonTextStyle,
  FileNameTextStyle,
} from './style';

// Component
import { TextSecondary } from '../../../atoms/text2/Text2';
import { FileDeleteButton } from '../../../atoms/button/Button2';
import Box from '../../../atoms/utils/layout/Box/Box';
import Image from '../../../atoms/image/Image/Image';
import PDFPreview from '../../../../assets/images/pdfIcon.svg';
import FileUploadPreviewLayout from './FileUploadPreviewLayout';

export interface FileUploadPreviewProps {
  name: string;
  urlName?: string;
  fileType?: string;
}

const FileUploadPreview: FC<FileUploadPreviewProps> = ({
  name,
  urlName,
  fileType,
}: FileUploadPreviewProps) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const { preview, setPreview, tmpFileName, setFile } = useContext(
    DropzoneAreaContext,
  );

  const [imageSource, width, height] =
    fileType === FileType.pdf && !isEmpty(preview)
      ? [PDFPreview, 200, 200]
      : [preview, 250, 250];

  return (
    <FileUploadPreviewLayout>
      <Box key="image" width={width} height={height}>
        <Image width="100%" height="100%" src={imageSource} />
      </Box>
      <TextSecondary key="fileName" theme={FileNameTextStyle}>
        {tmpFileName}
      </TextSecondary>
      <FileDeleteButton
        width={75}
        height={27}
        key="deleteButton"
        theme={DeleteButtonStyle}
        onClick={() => {
          setFile(undefined);
          setValue(name, undefined);
          if (urlName) setValue(urlName, '');
          setPreview(undefined);
        }}
      >
        <TextSecondary theme={DeleteButtonTextStyle}>
          {t(`atoms.button.delete`)}
        </TextSecondary>
      </FileDeleteButton>
    </FileUploadPreviewLayout>
  );
};
export default FileUploadPreview;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import TransferSavingsAccountTransactionSeedContext from '../../../../../../domain/transaction/transferSavingsAccountTransaction/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeedContext';

// Mapper
import TransferSavingsAccountTransactionTableMapper from '../TransferSavingsAccountTransactionTableMapper';

// Style
import { TableVerticalLayout, TableVerticalStyle } from './style';

// Component
import TableVertical from '../../../../../molecules/table/table/TableVertical';

const TableConfirmTransferSavingsAccount: FC = () => {
  const { t } = useTranslation();

  const { transferSavingsAccountTransactionSeed } = useContext(
    TransferSavingsAccountTransactionSeedContext,
  );

  if (!transferSavingsAccountTransactionSeed) return <></>;

  const rows = TransferSavingsAccountTransactionTableMapper.toTableData(
    transferSavingsAccountTransactionSeed,
    t,
  );

  return (
    <TableVertical
      isTableBorder
      rows={rows}
      layout={TableVerticalLayout}
      theme={TableVerticalStyle}
    />
  );
};

export default TableConfirmTransferSavingsAccount;

import React, { FC, useContext, useEffect } from 'react';
import { useConfirmUpdateEmail } from './useConfirmUpdateEmail';
import { FormInputEmailEditConfirmContext } from '../../../components/organisms/form/settings/FormEmailEditConfirm/FormEmailEditConfirm.type';
import UserAuthorizedContext from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import UpdateEmailContext from '../UpdateEmailProvider/UpdateEmailContext';
import ConfirmUpdateEmailContext from './ConfirmUpdateEmailContext';

// Error
import { ErrorCodeType } from '../../../utils/errors/ErrorHandler/ErrorCode.type';
import SystemErrorHandler from '../../errorHandler/SystemErrorHandler';

// Constant
import { INITIAL } from '../../../config/constants/requestState';

export type ConfirmUpdateEmailProviderProps = {
  children: React.ReactNode;
};

const ConfirmUpdateEmailProvider: FC<ConfirmUpdateEmailProviderProps> = ({
  children,
}: ConfirmUpdateEmailProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const id = userAuthorized?.getId() || '';
  const { setUpdateEmailState } = useContext(UpdateEmailContext);
  const {
    state,
    setState,
    confirmUpdateEmail,
    formInputEmailEditConfirmContext,
  } = useConfirmUpdateEmail(id);

  useEffect(() => {
    if (state === ErrorCodeType.SETTINGS_EMAIL_UPDATE_EXPIRED)
      setUpdateEmailState(INITIAL);
  }, [state, setUpdateEmailState]);

  return (
    <FormInputEmailEditConfirmContext.Provider
      value={formInputEmailEditConfirmContext}
    >
      <ConfirmUpdateEmailContext.Provider
        value={{
          confirmUpdateEmailState: state,
          confirmUpdateEmail,
          setConfirmUpdateEmailState: setState,
        }}
      >
        <SystemErrorHandler state={state} setState={setState}>
          {children}
        </SystemErrorHandler>
      </ConfirmUpdateEmailContext.Provider>
    </FormInputEmailEditConfirmContext.Provider>
  );
};
export default ConfirmUpdateEmailProvider;

// Error
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import { KycRequestProps } from './KycRequest.type';
import { KycRequestIndividualProps } from '../KycRequestIndividual/KycRequestIndividual.type';
import { KycRequestCorporateProps } from '../KycRequestCorporate/KycRequestCorporate.type';
import { UserType } from '../../../../../types/User.type';

// DomainObject
import KycRequestIndividualFactory from '../KycRequestIndividual/KycRequestIndividualFactory';
import KycRequestCorporateFactory from '../KycRequestCorporate/KycRequestCorporateFactory';
import UserAuthorized from '../../../../public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class KycRequestFactory {
  static create = (id: string, props: KycRequestProps) => {
    const { type } = props;

    switch (type) {
      case UserType.individual:
        return KycRequestIndividualFactory.create(
          id,
          props as KycRequestIndividualProps,
        );
      case UserType.corporate:
        return KycRequestCorporateFactory.create(
          id,
          props as KycRequestCorporateProps,
        );
      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_SWITCH,
          {
            value: type,
            place: `KycRequestFactory.create`,
          },
        );
    }
  };

  static createInitialByUserType = (
    user: UserAuthorized,
    userType: UserType,
  ) => {
    switch (userType) {
      case UserType.individual:
        return KycRequestIndividualFactory.createInitial(user);
      case UserType.corporate:
        return KycRequestCorporateFactory.createInitial(user);
      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_SWITCH,
          {
            value: userType,
            place: `KycRequestFactory.createInitialByUserType`,
          },
        );
    }
  };
}

export default KycRequestFactory;

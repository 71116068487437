import { useState } from 'react';
import TransferDebitCardTransactionV2Seed from './TransferDebitCardTransactionV2Seed';

export const useTransferDebitCardTransactionV2Seed = (
  defaultValue?: TransferDebitCardTransactionV2Seed,
) => {
  const [
    transferDebitCardTransactionV2Seed,
    setTransferDebitCardTransactionV2Seed,
  ] = useState<TransferDebitCardTransactionV2Seed | undefined>(defaultValue);

  return {
    transferDebitCardTransactionV2Seed,
    setTransferDebitCardTransactionV2Seed,
  };
};

import { FC } from 'react';

import { IIterable } from '../../../../../../interface/Iterator/IIterable';

// DomainObject
import BankFutureDeposit from '../BankFutureDeposit/BankFutureDeposit';

class BankFutureDeposits implements IIterable<BankFutureDeposit> {
  private items: BankFutureDeposit[] = [];

  constructor(items?: BankFutureDeposit[]) {
    this.items = items || [];
  }

  public add = (item: BankFutureDeposit) => {
    this.items.push(item);
  };

  public head = () => this.items[0];

  public map = <U>(callback: (item: BankFutureDeposit) => U) => {
    return this.items.map((item) => callback(item));
  };

  public forEach = (callback: (item: BankFutureDeposit) => void) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.items) {
      // eslint-disable-next-line no-await-in-loop
      callback(item);
    }
  };

  public forEachAsync = async (
    callback: (item: BankFutureDeposit) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.items) {
      // eslint-disable-next-line no-await-in-loop
      await callback(item);
    }
  };

  public mapComponent = (
    item: FC<{ object: BankFutureDeposit; i: number }>,
  ) => {
    return this.items.map((object, i) => item({ object, i }));
  };

  public sortByCreatedAt = () => {
    const compare = (a: BankFutureDeposit, b: BankFutureDeposit) => {
      const [aProps, bProps] = [a.getProps(), b.getProps()];

      return aProps.createdAt < bProps.createdAt ? 1 : -1;
    };
    return new BankFutureDeposits(this.items.sort(compare));
  };

  public sortAscByScheduleDate = () => {
    const compare = (a: BankFutureDeposit, b: BankFutureDeposit) => {
      const [aProps, bProps] = [a.getProps(), b.getProps()];

      return aProps.scheduledTime > bProps.scheduledTime ? 1 : -1;
    };
    return new BankFutureDeposits(this.items.sort(compare));
  };
}
export default BankFutureDeposits;

import { useState } from 'react';
import ContractTimeDepositSeed from './ContractTimeDepositSeed';

export const useContractTimeDepositSeed = (
  defaultValue?: ContractTimeDepositSeed,
) => {
  const [contractTimeDepositSeed, setContractTimeDepositSeed] = useState<
    ContractTimeDepositSeed | undefined
  >(defaultValue);

  return {
    contractTimeDepositSeed,
    setContractTimeDepositSeed,
  };
};

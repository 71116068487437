import styled from 'styled-components';

interface FlexProps {
  justifyContent?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'start' | 'end' | 'stretch' | 'center';
}

export const FlexRow = styled.div`
  display: flex;
  justify-content: ${(props: FlexProps) =>
    `${props.justifyContent || 'flex-start'}`};
  align-items: ${(props: FlexProps) => `${props.alignItems || 'start'}`};
`;

export const FlexColumn = styled(FlexRow)`
  flex-direction: column;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

import { isEmpty } from 'lodash';
import { includeFullWidth } from '../../../../../../utils/helpers/stringHelper';
import { ROUND_DOWN_TIME_DEPOSIT_AMOUNT } from '../../../../../../config/constants/business';
import {
  roundDownTimeDepositAmount,
  commaSeparatedFormat,
} from '../../../../../../utils/helpers/numberHelper';
import ContractTimeDeposit from '../../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';

export const getDepositAmount = (value: string) => {
  const numberInputted = value.replace(/,/g, '');

  // 全角 or 数字以外の文字
  if (
    includeFullWidth(numberInputted) ||
    Number.isNaN(Number(numberInputted))
  ) {
    const res = numberInputted.replace(/[^0-9]/g, '');
    return res;
  }

  // 0が4文字以上含まれていて、0のみで構成されている文字列
  if (
    numberInputted.indexOf('0000') > -1 &&
    numberInputted.search(/[1-9]/g) === -1
  )
    return numberInputted;

  const amount = Number(numberInputted);

  // 10 未満
  if (amount < 10)
    return commaSeparatedFormat(amount * ROUND_DOWN_TIME_DEPOSIT_AMOUNT);

  // 余り
  const mod = amount % ROUND_DOWN_TIME_DEPOSIT_AMOUNT;

  // add digit or not
  const rounded = roundDownTimeDepositAmount(amount);

  if (mod > 0 && mod < 10)
    return commaSeparatedFormat(rounded + mod * ROUND_DOWN_TIME_DEPOSIT_AMOUNT);

  if (mod > 9 && mod < 1000) return commaSeparatedFormat(rounded / 10);

  return commaSeparatedFormat(rounded);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValid = (errors: any, watch: any) => {
  return (
    isEmpty(errors) &&
    !isEmpty(watch('savingsAccountNumber')) &&
    !isEmpty(watch('interestRate')) &&
    !isEmpty(watch('term')) &&
    !isEmpty(watch('amount')) &&
    !isEmpty(watch('renewalTerm')) &&
    !isEmpty(watch('amountIncreased')) &&
    !isEmpty(watch('timeDepositId'))
  );
};

export const getDefaultValues = (contractTimeDeposit: ContractTimeDeposit) => {
  const {
    savingsAccountNumber,
    interestRate,
    term,
    depositAmount,
    isAnnualPayOut,
    renewalReservation,
  } = contractTimeDeposit.getProps();

  const valuesWithoutRenewalReservation = {
    savingsAccountNumber,
    interestRate,
    term,
    amount: getDepositAmount(depositAmount.toString()),
    isAnnualPayOut: isAnnualPayOut ? 'true' : 'false',
  };

  if (!renewalReservation) return valuesWithoutRenewalReservation;

  const principalIncreased = contractTimeDeposit.getPrincipalAfterMatured();
  const temAfterMatured = contractTimeDeposit.getTermAfterMatured();
  const isAnnualPayOutAfterMatured = contractTimeDeposit.getIsAnnualPayOutAfterMatured();

  return {
    ...valuesWithoutRenewalReservation,
    term: temAfterMatured,
    principalIncreased: getDepositAmount(principalIncreased.toString()),
    isAnnualPayOut: isAnnualPayOutAfterMatured ? 'true' : 'false',
  };
};

import React from 'react';
import CryptoCurrencySpotTransactions from './CryptoCurrencySpotTransactions';

export interface CryptoCurrencyTradeTransactionContextProps {
  cryptoCurrencySpotTransactions: CryptoCurrencySpotTransactions;
}

const CryptoCurrencySpotTransactionsContext = React.createContext<
  CryptoCurrencyTradeTransactionContextProps
>({
  cryptoCurrencySpotTransactions: new CryptoCurrencySpotTransactions(),
});

export default CryptoCurrencySpotTransactionsContext;

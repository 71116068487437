import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';

// Style
import CryptoCurrencyTemplateContainer from '../../common/CryptoCurrencyTemplateContainer';

// LayoutComponent
import { FlexBoxRow } from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';
import Box from '../../../../atoms/utils/layout/Box/Box';

export interface CryptoCurrencyWalletTemplateLayoutProps {
  children: React.ReactElement[];
}

const CryptoCurrencyWalletTemplateLayout: FC<CryptoCurrencyWalletTemplateLayoutProps> = ({
  children,
}: CryptoCurrencyWalletTemplateLayoutProps) => {
  const elements = [
    'header',
    'wallet',
    'custodyFeeDescription',
    'historyHeader',
    'historyTabBar',
    'table',
  ];

  const [
    header,
    wallet,
    custodyFeeDescription,
    historyHeader,
    historyTabBar,
    table,
  ] = getElementsFromKeys(children, elements);
  return (
    <CryptoCurrencyTemplateContainer>
      {header}
      <Margin top={35} />
      {wallet}
      <Margin top={20} />
      <FlexBoxRow justifyContent="flex-end">{custodyFeeDescription}</FlexBoxRow>
      <Margin top={100} />
      {historyHeader}
      <Margin top={30} />
      {historyTabBar}
      <Margin top={30} />
      <Box height={800}>{table}</Box>
    </CryptoCurrencyTemplateContainer>
  );
};
export default CryptoCurrencyWalletTemplateLayout;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DescriptionStyle, CountdownTextStyle } from './style';
import { TextSecondary } from '../../../atoms/text2/Text2';
import CountdownTimerLayout from './CountdownTimerLayout';

export interface CountdownTimerProps {
  time: number;
  description: string;
  width: string | number;
  height: string | number;
}

const CountdownTimer: FC<CountdownTimerProps> = ({
  time,
  description,
  width,
  height,
}: CountdownTimerProps) => {
  const { t } = useTranslation();
  return (
    <CountdownTimerLayout width={width} height={height}>
      <TextSecondary key="description" theme={DescriptionStyle}>
        {description}
      </TextSecondary>
      <TextSecondary key="seconds" theme={CountdownTextStyle}>
        {t(`organisms.CountdownTimer.remainingTime`, { time })}
      </TextSecondary>
    </CountdownTimerLayout>
  );
};

export default CountdownTimer;

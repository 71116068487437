import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

// Lib
import { getSymbolFromCurrency } from '../../../../../../utils/helpers/currencyHelper';

// Type
import { CurrencyType } from '../../../../../../types/Currency.type';

// Style
import { LabelStyle, ErrorMessageStyle } from './style';

// Component
import { TextPrimary, TextSecondary } from '../../../../../atoms/text2/Text2';
import TextInput from '../../../../../atoms/form/TextInput';
import FormInputCurrencyAmountLayout from './FormInputCurrencyAmountLayout';

interface FormInputCurrencyAmountProps {
  currency: CurrencyType;
  disabled?: boolean;
  inputWidth: string | number;
  label: string;
  name: string;
  onChange?: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any;
}

const FormInputCurrencyAmount: FC<FormInputCurrencyAmountProps> = ({
  currency,
  disabled = false,
  inputWidth,
  label,
  name,
  onChange,
  register,
}: FormInputCurrencyAmountProps) => {
  const watch = useFormContext()?.watch;
  const errors = useFormContext()?.errors;

  return (
    <FormInputCurrencyAmountLayout inputWidth={inputWidth}>
      <TextPrimary key="label" theme={LabelStyle}>
        {label}
      </TextPrimary>
      <TextSecondary key="currency" theme={LabelStyle}>
        {getSymbolFromCurrency(currency)}
      </TextSecondary>
      <TextInput
        key="input"
        disabled={disabled}
        disabledBackgroundColor="#DBDDE8"
        name={name}
        maxLength={20}
        onChange={onChange}
        register={register}
        textAlign="right"
        value={name ? watch && watch(name) : ''}
      />
      <TextSecondary key="errorMessage" theme={ErrorMessageStyle}>
        {errors && errors[name] && errors[name].message}
      </TextSecondary>
    </FormInputCurrencyAmountLayout>
  );
};

export default FormInputCurrencyAmount;

/* eslint-disable @typescript-eslint/no-explicit-any */
// React
import React, { FC, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReferralsTimeDepositContext from '../../../../../domain/invite/referralTimeDeposit/referralTimeDeposit/ReferralsTimeDeposit/ReferralsTimeDepositContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Constant
import {
  BASE_CURRENCY_UNIT,
  FILTER_RULE,
} from '../../../../../config/constants/business';

// Mapper
import TableReferralTimeDepositsMapper, {
  TdReferralsTimeDeposit,
} from './TableReferralsTimeDepositMapper';

// Components
import { TextInputFilter } from '../../../../atoms/table/filter';
import DefaultTdBox from '../../../../molecules/table/td/DefaultTdBox';
import CurrencyAmountTdBox from '../../../../molecules/table/td/CurrencyAmountTdBox';
import NumberTdBox from '../../../../molecules/table/td/NumberTdBox2';
import TableFunctional from '../../../../molecules/table/table/TableFunctional';

// ID
const ORGANISM_ID = `organisms.table.TableReferralsTimeDeposit`;

const TableReferralsTimeDeposit: FC = () => {
  /* *
   *
   *  Variables
   *
   * */

  const { t } = useTranslation();

  // DomainObject
  const { referralsTimeDeposit } = useContext(ReferralsTimeDepositContext);
  const { userAuthorized } = useContext(UserAuthorizedContext);

  /* *
   *
   *  Mapping
   *
   * */
  const data = useMemo(
    () => TableReferralTimeDepositsMapper.domainToTd(referralsTimeDeposit, t),
    [referralsTimeDeposit, t],
  );

  /* *
   *
   *  Table
   *
   * */
  const columns = useMemo(
    () => {
      const cols = [
        {
          Header: t(`${ORGANISM_ID}.th.id`),
          accessor: (values: TdReferralsTimeDeposit) => values.id,
          Cell: (value: { cell: { value: string } }) =>
            DefaultTdBox({ text: value.cell.value }),
          Filter: TextInputFilter,
          filter: FILTER_RULE,
          minWidth: `${150 / 16}rem`,
        },
        {
          Header: t(`${ORGANISM_ID}.th.username`),
          accessor: (values: TdReferralsTimeDeposit) => values.username,
          Cell: (value: { cell: { value: string } }) =>
            DefaultTdBox({ text: value.cell.value }),
          Filter: TextInputFilter,
          filter: FILTER_RULE,
          minWidth: `${175 / 16}rem`,
        },
        {
          Header: t(`${ORGANISM_ID}.th.depositAmount`, {
            unit: BASE_CURRENCY_UNIT,
          }),
          accessor: (values: TdReferralsTimeDeposit) => values.depositAmount,
          Cell: (value: any) => {
            return CurrencyAmountTdBox({
              currency: value.row.original.currency,
              number: value.row.original.depositAmount,
            });
          },
          Filter: TextInputFilter,
          filter: FILTER_RULE,
          minWidth: `${200 / 16}rem`,
        },
        {
          Header: t(`${ORGANISM_ID}.th.interestRate`, {
            unit: BASE_CURRENCY_UNIT,
          }),
          accessor: (values: TdReferralsTimeDeposit) => values.interestRate,
          Cell: (value: { cell: { value: number } }) =>
            NumberTdBox({ number: value.cell.value, precision: 1 }),
          Filter: TextInputFilter,
          filter: FILTER_RULE,
          minWidth: `${80 / 16}rem`,
        },
        {
          Header: t(`${ORGANISM_ID}.th.depositDate`),
          accessor: (values: TdReferralsTimeDeposit) =>
            String(values.depositDate),
          Cell: (value: { cell: { value: string } }) =>
            DefaultTdBox({ text: value.cell.value }),
          Filter: TextInputFilter,
          filter: FILTER_RULE,
          minWidth: `${140 / 16}rem`,
        },
        {
          Header: t(`${ORGANISM_ID}.th.term`),
          accessor: (values: TdReferralsTimeDeposit) => values.term,
          Cell: (value: { cell: { value: number } }) =>
            NumberTdBox({ number: value.cell.value }),
          Filter: TextInputFilter,
          filter: FILTER_RULE,
          minWidth: `${100 / 16}rem`,
        },
      ];

      if (cols.length < 8 && userAuthorized && userAuthorized.isRankD()) {
        cols.splice(2, 0, {
          Header: t(`${ORGANISM_ID}.th.affiliatorName`),
          accessor: (values: TdReferralsTimeDeposit) =>
            values.affiliatorName as string,
          Cell: (value: { cell: { value: string } }) =>
            DefaultTdBox({ text: value.cell.value }),
          Filter: TextInputFilter,
          filter: FILTER_RULE,
          minWidth: `${150 / 16}rem`,
        });
        cols.splice(7, 0, {
          Header: t(`${ORGANISM_ID}.th.countOfMaturity`, {
            unit: BASE_CURRENCY_UNIT,
          }),
          accessor: (values: TdReferralsTimeDeposit) => values.countOfMaturity,
          Cell: (value: any) =>
            NumberTdBox({ number: value.cell.value, precision: 0 }),
          Filter: TextInputFilter,
          filter: FILTER_RULE,
          minWidth: `${120 / 16}rem`,
        });
        cols.splice(8, 0, {
          Header: t(`${ORGANISM_ID}.th.totalCommission`, {
            unit: BASE_CURRENCY_UNIT,
          }),
          accessor: (values: TdReferralsTimeDeposit) => values.commissionTotal,
          Cell: (value: any) =>
            CurrencyAmountTdBox({
              currency: value.row.original.currency,
              number: value.row.original.commissionTotal,
            }),
          Filter: TextInputFilter,
          filter: FILTER_RULE,
          minWidth: `${150 / 16}rem`,
        });
        cols.splice(9, 0, {
          Header: t(`${ORGANISM_ID}.th.totalCommissionReceived`, {
            unit: BASE_CURRENCY_UNIT,
          }),
          accessor: (values: TdReferralsTimeDeposit) => values.commissionPaid,
          Cell: (value: any) =>
            CurrencyAmountTdBox({
              currency: value.row.original.currency,
              number: value.row.original.commissionPaid,
            }),
          Filter: TextInputFilter,
          filter: FILTER_RULE,
          minWidth: `${200 / 16}rem`,
        });
      }
      return cols;
    },
    // eslint-disable-next-line
    [userAuthorized],
  );

  const width = useMemo(
    () => (userAuthorized && userAuthorized.isRankD() ? 1450 : 900),
    [userAuthorized],
  );

  return <TableFunctional data={data} columns={columns} width={width} />;
};

export default TableReferralsTimeDeposit;

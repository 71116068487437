import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isValid, getDefaultValues } from './helper';
import CorporateInformationSubmitContext from '../../../../../../enhancers/useCase/verification/kycRequest/KycRequestSubmitProvider/CorporateInformationSubmitProvider/CorporateInformationSubmitContext';

// Type
import {
  FormInputCorporateInformation,
  FormInputCorporateInformationContext,
} from './FormCorporateInformation.type';

// Constant
import { IS_LOADING } from '../../../../../../config/constants/requestState';
import { ROUTE_KYC_STATUS } from '../../../../../../config/constants/routing';

// Component
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../../atoms/button/Button2';
import LabelSendIcon from '../../../../../atoms/button/ButtonLabel/LabelSendIcon';
import FormCorporateInformationInput from './FormCorporateInformationInput';
import FormCorporateRegistryUpload from './FormCorporateRegistryUpload';
import FormCorporateInformationLayout from './FormCorporateInformationLayout';

// ValueObject
import CorporateInformation from '../../../../../../value/verification/kycRequest/KycRequestContent/CorporateInformation/CorporateInformation';

export interface FormCorporateInformationProps {
  corporateInformation?: CorporateInformation;
}

const FormCorporateInformation: FC<FormCorporateInformationProps> = ({
  corporateInformation,
}: FormCorporateInformationProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { formInput, setFormInput } = useContext(
    FormInputCorporateInformationContext,
  );

  // Method
  const { state, submitCorporateInformation } = useContext(
    CorporateInformationSubmitContext,
  );

  /* *
   *
   *  Form
   *
   * */
  const methods = useForm<FormInputCorporateInformation>({
    mode: 'onChange',
  });
  const { errors, handleSubmit, reset, setValue, watch } = methods;

  /* *
   *
   *  DefaultValues
   *
   * */

  // Set DefaultValue
  useEffect(() => {
    if (formInput) reset(formInput);
    // eslint-disable-next-line
  }, [formInput]);

  // Set Default Value from CorporateInformation
  useEffect(() => {
    if (!formInput && corporateInformation) {
      const defaultValues = getDefaultValues(corporateInformation);

      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [formInput, corporateInformation, setValue]);

  /* *
   *
   *  Event
   *
   * */

  const onSubmit = useCallback(
    async (input: FormInputCorporateInformation) => {
      setFormInput(input);

      submitCorporateInformation(input, () => {
        history.push(ROUTE_KYC_STATUS);
      });
      // eslint-disable-next-line
  }, [submitCorporateInformation]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <FormCorporateInformationLayout>
          <FormCorporateInformationInput key="corporateInformation" />
          <FormCorporateRegistryUpload
            key="corporateRegistry"
            corporateInformation={corporateInformation}
          />
          <SubmitButton
            key="button"
            disabled={!isValid(errors, watch)}
            isLoading={state === IS_LOADING}
            height={75}
            width={'100%'}
          >
            <LabelSendIcon
              iconSize={26}
              iconStyle={{ color: 'white' }}
              label={t(`atoms.button.submit`)}
              marginBetween={8}
              textStyle={DefaultButtonTextStyle}
            />
          </SubmitButton>
        </FormCorporateInformationLayout>
      </FormProvider>
    </form>
  );
};

export default FormCorporateInformation;

import React, { FC } from 'react';

// Style
import { LabelStyle } from './style';

// DomainObject
import { TextPrimary } from '../../../../../../atoms/text2/Text2';
import OccupationSelectBox from '../../../../../selectBox/selectBox/verification/OccupationSelectBox';
import FormInputOccupationLayout from './FormInputOccupationLayout';

interface FormInputOccupationProps {
  label: string;
  labelWidth: string | number;
  inputWidth: string | number;
  name: string;
}

const FormInputOccupation: FC<FormInputOccupationProps> = ({
  label,
  labelWidth,
  inputWidth,
  name,
}: FormInputOccupationProps) => {
  return (
    <FormInputOccupationLayout inputWidth={inputWidth} labelWidth={labelWidth}>
      <TextPrimary key="label" theme={LabelStyle}>
        {label}
      </TextPrimary>
      <OccupationSelectBox key="input" name={name} />
    </FormInputOccupationLayout>
  );
};

export default FormInputOccupation;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { Container } from './style';
import Margin from '../../../atoms/utils/layout/Margin';
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';

export interface ErrorMessageNotificationLayoutProps {
  children: React.ReactElement[];
}

const ErrorMessageNotificationLayout: FC<ErrorMessageNotificationLayoutProps> = ({
  children,
}: ErrorMessageNotificationLayoutProps) => {
  const elements = ['icon', 'title', 'description', 'button'];
  const [icon, title, description, button] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <Container>
      {icon}
      <Margin top={30}>{title}</Margin>
      <Margin top={20}>
        <FlexBoxColumn
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <div>{description}</div>
        </FlexBoxColumn>
      </Margin>
      <Margin top={40}> {button}</Margin>
    </Container>
  );
};
export default ErrorMessageNotificationLayout;

import { RankAType, RankType } from '../../../types/Invite.type';
import Rank from './Rank';

class RankA extends Rank {
  constructor(rank: RankAType) {
    super(rank);
    if (rank !== RankType.A01 && rank !== RankType.A02)
      throw new Error(`Invalid Rank ${rank} at ${this.constructor.name}`);
  }

  public isRankD = () => false;

  public isRankA = () => true;

  public isRankC = () => false;
}
export default RankA;

import React, { FC, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDebitCardRequestCreateUseCase } from './useDebitCardRequestCreateUseCase';
import DebitCardRequestCreateContext from './DebitCardRequestCreateContext';
import UserAuthorizedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import SystemErrorHandler from '../../../../../errorHandler/SystemErrorHandler';

export type DebitCardRequestCreateProviderProps = {
  children: React.ReactNode;
};

const DebitCardRequestCreateProvider: FC<DebitCardRequestCreateProviderProps> = ({
  children,
}: DebitCardRequestCreateProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const history = useHistory();

  // Get Parameter
  const { search } = useLocation();
  const savingsAccountNumber = new URLSearchParams(search).get(
    'savingsAccountNumber',
  );
  const issuer = new URLSearchParams(search).get('issuer');

  const {
    state,
    setState,
    createDebitCardRequest,
    debitCardRequest,
  } = useDebitCardRequestCreateUseCase(
    savingsAccountNumber,
    issuer,
    userAuthorized,
  );

  /**
   *
   * UseEffect
   *
   */
  useEffect(() => {
    if (debitCardRequest) history.push(debitCardRequest.getStatusPageUrl());
  }, [debitCardRequest, history]);

  return (
    <DebitCardRequestCreateContext.Provider
      value={{ state, createDebitCardRequest }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </DebitCardRequestCreateContext.Provider>
  );
};

export default DebitCardRequestCreateProvider;

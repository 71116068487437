import React from 'react';

export interface ImportantNoticeModalContextProps {
  showModal: () => void;
  hideModal: () => void;
}

const ImportantNoticeModalContext = React.createContext<
  ImportantNoticeModalContextProps
>({
  showModal: () => undefined,
  hideModal: () => undefined,
});

export default ImportantNoticeModalContext;

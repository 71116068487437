import { includeFullWidth } from '../../../../../../utils/helpers/stringHelper';
import { commaSeparatedFormat } from '../../../../../../utils/helpers/numberHelper';

export const getAmount = (value: string) => {
  const numValue = value.replace(/,/g, '');

  // 全角 or 数字以外の文字
  if (includeFullWidth(value) || Number.isNaN(Number(numValue))) {
    const res = value.replace(/[^0-9]/g, '');

    return res;
  }

  if (value.slice(-1) === '.') return value;

  if (value.indexOf('.') === -1)
    return commaSeparatedFormat(Number(numValue), false);

  const decimal = value.substr(numValue.indexOf('.') - numValue.length + 1);
  const integerNum = numValue.substr(0, numValue.indexOf('.'));

  if (
    Number(numValue.substr(numValue.indexOf('.') - numValue.length + 1)) === 0
  )
    return `${commaSeparatedFormat(Number(integerNum), false)}.${decimal}`;

  return commaSeparatedFormat(Number(numValue), false);
};

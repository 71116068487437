// React
import { useCallback, useMemo, useEffect, useState } from 'react';

// ErrorHandler
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';

// Constant
import { INITIAL, SUCCESS } from '../../../../config/constants/requestState';

// UseCase
import ReferralResultsViewUseCase from '../../../../useCases/invite/referralTimeDeposit/ReferralResultsViewUseCase';

// Repository
import BankFutureDepositFunctions from '../../../../repository/transaction/bankTransaction/bankFutureTransaction/bankFutureDeposit/BankFutureDepositFunctions';
import InviterFirestore from '../../../../repository/invite/inviter/InviterFirestore';
import ReferralTimeDepositFunctions from '../../../../repository/invite/referralTimeDeposit/ReferralTimeDepositFunctions';

// DomainObject
import { useBankFutureDeposits } from '../../../../domain/transaction/bankTransaction/bankFutureTransaction/bankFutureDeposit/bankFutureDeposit/BankFutureDeposits/useBankFutureDeposits';
import { useInviter } from '../../../../domain/invite/inviter/Inviter/useInviter';
import { useReferralsTimeDeposit } from '../../../../domain/invite/referralTimeDeposit/referralTimeDeposit/ReferralsTimeDeposit/useReferralsTimeDeposit';
import UserAuthorized from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import UserVerifiedFactory from '../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedFactory';

// Tab
import { useReferralResultTabBarOption } from '../../../../components/organisms/tabBar/ReferralResultTabBar/useReferralResultTabBarOption';

export const useReferralResultsViewUseCase = (user?: UserAuthorized) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   * Domain Object
   *
   * */
  const { bankFutureDeposits, setBankFutureDeposits } = useBankFutureDeposits();

  const { inviter, setInviter } = useInviter();

  const {
    referralsTimeDeposit,
    setReferralsTimeDeposit,
  } = useReferralsTimeDeposit();

  const userVerified = useMemo(
    () => user && UserVerifiedFactory.createByUserAuthorized(user),
    [user],
  );

  // sort
  const referralsTimeDepositSorted = useMemo(
    () => referralsTimeDeposit.sortByCreatedAt(),
    [referralsTimeDeposit],
  );

  const bankFutureDepositsSorted = useMemo(
    () => bankFutureDeposits.sortAscByScheduleDate(),
    [bankFutureDeposits],
  );

  const inviterByReferralTimeDeposit = useMemo(
    () =>
      inviter &&
      userVerified &&
      referralsTimeDeposit.createInviter(userVerified, inviter),
    [userVerified, inviter, referralsTimeDeposit],
  );

  /* *
   *
   *  Options
   *
   * */
  const referralResultTabBarOptionContext = useReferralResultTabBarOption();

  /* *
   *
   *  Repository
   *
   * */
  const bankFutureDepositRepository = new BankFutureDepositFunctions();
  const inviterRepository = new InviterFirestore();
  const referralTimeDepositRepository = new ReferralTimeDepositFunctions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new ReferralResultsViewUseCase(
    bankFutureDepositRepository,
    inviterRepository,
    referralTimeDepositRepository,
  );

  /* *
   *
   *  Method
   *
   * */
  const open = useCallback(async () => {
    if (!userVerified) return;

    try {
      // Is ONLINE?
      checkInternetConnection();

      const setters = { inviter: setInviter };

      // execute
      const outputs = await useCase.open(userVerified, setters, setState);

      setReferralsTimeDeposit(outputs.referralsTimeDeposit);
      setBankFutureDeposits(outputs.bankFutureDeposits);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userVerified]);

  /* *
   *
   *  UseEffect
   *
   * */

  // Open
  useEffect(() => {
    let isMounted = true;

    if (isMounted && user) open();

    return () => {
      isMounted = false;
      useCase.executeUnsubscribe();
    };
    // eslint-disable-next-line
  }, [open]);

  return {
    state,
    setState,
    bankFutureDeposits: bankFutureDepositsSorted,
    inviter: inviterByReferralTimeDeposit,
    referralResultTabBarOptionContext,
    referralsTimeDeposit: referralsTimeDepositSorted,
  };
};

import React, { FC } from 'react';

// Component
import { TextPrimary } from '../../../../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../../../../atoms/text2/LineBreakReplaced';
import TimeDepositFilteredSelectBox from '../../../../../selectBox/selectBox/timeDeposit/TimeDepositFilteredSelectBox';
import FormInputTimeDepositFilteredSelectBoxLayout from './FormInputTimeDepositFilteredSelectBoxLayout';

// DomainObject
import TimeDeposits from '../../../../../../../domain/product/timeDeposit/TimeDeposits/TimeDeposits';

interface FormInputTimeDepositFilteredSelectBoxProps {
  timeDeposits: TimeDeposits;
  inputWidth: string | number;
  labelProps: {
    label: string;
    theme: {
      fontSize: string | number;
      color: string;
      fontWeight: number;
    };
  };
  name: string;
}

const FormInputTimeDepositFilteredSelectBox: FC<FormInputTimeDepositFilteredSelectBoxProps> = ({
  timeDeposits,
  inputWidth,
  labelProps,
  name,
}: FormInputTimeDepositFilteredSelectBoxProps) => {
  return (
    <FormInputTimeDepositFilteredSelectBoxLayout inputWidth={inputWidth}>
      <TextPrimary key="label" theme={labelProps.theme}>
        <LineBreakReplaced text={labelProps.label} />
      </TextPrimary>
      <TimeDepositFilteredSelectBox
        key="input"
        name={name}
        timeDepositsFiltered={timeDeposits}
      />
    </FormInputTimeDepositFilteredSelectBoxLayout>
  );
};

export default FormInputTimeDepositFilteredSelectBox;

import React, { FC } from 'react';
import TextSub from './TextSub';
import fontWeight from '../../../../fonts/fontWeight.json';
import fontSize from '../../../../fonts/fontSizePixel.json';

export interface TextSubRegular18Props {
  children: React.ReactNode;
  color: string;
}

const TextSubRegular18: FC<TextSubRegular18Props> = ({
  children,
  color,
}: TextSubRegular18Props) => {
  const pixel = fontSize.pixel18;
  const textFontWeight = fontWeight.regular;

  return (
    <TextSub pixel={pixel} color={color} fontWeight={textFontWeight}>
      {children}
    </TextSub>
  );
};
export default TextSubRegular18;

import React, { FC, useContext } from 'react';
import SignUpContext from '../../../enhancers/useCase/authentication/SignUpProvider/SignUpContext';
import SignUpPageGroupTemplate from '../../../components/templates/front/SignUpPageGroupTemplate';
import SignUpTemplate from '../../../components/templates/front/SignUpTemplate';
import SignUpCompleteTemplate from '../../../components/templates/front/SignUpCompleteTemplate';
import { SUCCESS } from '../../../config/constants/requestState';

const SignUpPage: FC = () => {
  const { state } = useContext(SignUpContext);

  return (
    <SignUpPageGroupTemplate>
      {(() => {
        if (state === SUCCESS) return <SignUpCompleteTemplate />;
        return <SignUpTemplate />;
      })()}
    </SignUpPageGroupTemplate>
  );
};

export default SignUpPage;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

// OriginalLib
import { deleteFullWidth } from '../../../../../../../utils/helpers/stringHelper';
import { personalInformationValidation } from '../../../../../../../utils/validation/registers';

// Constant
import {
  MIN_MONTH_PASSPORT_EXPIRED,
  MAX_YEAR_PASSPORT_EXPIRED,
} from '../../../../../../../config/constants/business';

// Component
import KycFormContainer from '../../../../common/KycFormContainer';
import FormInputText from '../../../../../../molecules/form/formInput/textField/FormInputText';
import FormInputDateSelectBox from '../../../../../../molecules/form/formInput/selectBox/verification/FormInputDateSelectBox';
import FormPassportInputLayout from './FormPassportInputLayout';

const FORM_ID = `organisms.form.verification.FormPersonalInformation.passport`;

const PassportInputForm: FC = () => {
  const { t } = useTranslation();
  const { register, setValue } = useFormContext();

  return (
    <KycFormContainer order={2} formId={FORM_ID}>
      <FormPassportInputLayout>
        <FormInputText
          key="passportNo"
          name="passportNo"
          inputWidth={400}
          label={t(`${FORM_ID}.passportNo.label`)}
          maxLength={20}
          onChange={(text: string) => {
            setValue('passportNo', deleteFullWidth(text));
          }}
          placeholder={t(`${FORM_ID}.passportNo.placeholder.passportNo`)}
          register={register(personalInformationValidation(t).passportNo)}
        />
        <FormInputDateSelectBox
          key="expiredDate"
          name="expiredDate"
          labelWidth={200}
          label={t(`${FORM_ID}.expiredDate.label`)}
          dateStart={{
            year: MAX_YEAR_PASSPORT_EXPIRED,
            month: 0,
            day: 0,
          }}
          dateEnd={{
            year: 0,
            month: MIN_MONTH_PASSPORT_EXPIRED,
            day: 0,
          }}
        />
      </FormPassportInputLayout>
    </KycFormContainer>
  );
};

export default PassportInputForm;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';
import Margin from '../../../../../atoms/utils/layout/Margin';
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../../../atoms/utils/layout/Box/FlexBox';
import Box from '../../../../../atoms/utils/layout/Box/Box';

export interface FormInputTransactionAmountLayoutProps {
  children: React.ReactElement[];
  inputWidth: string | number;
}

const FormInputTransactionAmountLayout: FC<FormInputTransactionAmountLayoutProps> = (
  props: FormInputTransactionAmountLayoutProps,
) => {
  const { children, inputWidth } = props;
  const elements = ['label', 'currency', 'input', 'errorMessage'];
  const [label, currency, input, errorMessage] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxRow justifyContent="space-between">
      <FlexBoxRow justifyContent="space-between" alignItems="center">
        {label}
        <FlexBoxColumn
          width="auto"
          height="auto"
          style={{ position: 'relative' }}
        >
          <FlexBoxRow alignItems="center">
            {currency}
            <Margin left={15} />
            <Box width={inputWidth}>{input}</Box>
          </FlexBoxRow>
          <FlexBoxRow
            height={40}
            alignItems="center"
            style={{ position: 'absolute', top: `${50 / 16}rem` }}
          >
            {errorMessage}
          </FlexBoxRow>
        </FlexBoxColumn>
      </FlexBoxRow>
    </FlexBoxRow>
  );
};
export default FormInputTransactionAmountLayout;

import React, { FC } from 'react';

// Lib
import { authenticator } from 'otplib';

// Constant
import { SERVICE_NAME } from '../../../../config/constants/business';

// Component
import QRCodeImage from './QRCodeImage';

export interface QRCodeImageProps {
  accountName?: string;
  accountKey?: string;
}

const GoogleAuthenticatorAccountKeyQRCode: FC<QRCodeImageProps> = ({
  accountName,
  accountKey,
}: QRCodeImageProps) => {
  const otpauth =
    accountName &&
    accountKey &&
    authenticator.keyuri(accountName, SERVICE_NAME, accountKey);

  return <QRCodeImage width={230} height={230} value={otpauth} />;
};

export default GoogleAuthenticatorAccountKeyQRCode;

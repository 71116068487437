import Joi from '@hapi/joi';

// Error
import { ErrorIdType } from '../../../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import {
  DebitCardDepositV2Props,
  DebitCardDepositV2PropsFormat,
} from './DebitCardDepositV2.type';

// DomainObject
import DebitCardDepositV2 from './DebitCardDepositV2';

// ValueObject
import DebitCardTransactionId from '../../../../../idManager/transactionId/DebitCardTransactionId/DebitCardTransactionId';

class DebitCardDepositV2Factory {
  static create = (
    id: DebitCardTransactionId,
    props: DebitCardDepositV2Props,
  ) => {
    const result = Joi.object(DebitCardDepositV2PropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: DebitCardDepositV2.name,
          reason: result.error.details[0].message,
        },
      );

    return new DebitCardDepositV2(id, props);
  };
}

export default DebitCardDepositV2Factory;

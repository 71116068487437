import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  ResignUpRequestProps,
  ResignUpRequestPropsFormat,
} from './ResignUpRequest.type';

// DomainObject
import ResignUpRequest from './ResignUpRequest';

class ResignUpRequestFactory {
  static create = (id: string, props: ResignUpRequestProps) => {
    const result = Joi.object(ResignUpRequestPropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: ResignUpRequest.name,
          reason: result.error.details[0].message,
        },
      );

    return new ResignUpRequest(id, props);
  };
}

export default ResignUpRequestFactory;

// Type
import { ShippingAddressProps } from '../../../value/personalInformation/address/Address/ShippingAddress/ShippingAddress.type';
import { UserType } from '../../../types/User.type';

// IService
import IUserUpdateService from '../../../useCases/public/user/interface/IUserUpdateService';

// IRepository
import { IUserUpdateRepository } from '../../../domain/public/user/User/IUserRepository';

// DomainObject
import User from '../../../domain/public/user/User/User/User';
import UserVerified from '../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class UserUpdateService implements IUserUpdateService {
  private userUpdateRepository: IUserUpdateRepository;

  constructor(userUpdateRepository: IUserUpdateRepository) {
    this.userUpdateRepository = userUpdateRepository;
  }

  public updateShippingAddress = (
    user: UserVerified,
    shippingAddressProps: ShippingAddressProps,
  ) => {
    const userUpdated = user.updateShippingAddress(shippingAddressProps);
    return userUpdated;
  };

  public updateUserType = (user: UserAuthorized, userType: UserType) => {
    return user.updateUserType(userType);
  };

  public executeUpdate = async (user: User) => {
    await this.userUpdateRepository.update(user);
    return user;
  };
}

export default UserUpdateService;

// IUseCase
import ITwoFactorAuthAppSettingsViewUseCase from '../../../enhancers/useCase/verification/consentForm/TwoFactorAuthAppSettingsViewProvider/ITwoFactorAuthAppSettingsViewUseCase';

// IService
import IAuthenticatorCreateService from '../../authenticator/authenticator/interface/IAuthenticatorCreateService';
import IConsentFormQueryService from './IConsentFormQueryService';

// Service
import AuthenticatorCreateService from '../../../service/authenticator/AuthenticatorCreateService';
import ConsentFormQueryService from '../../../service/verification/consentForm/ConsentFormQueryService';

// IRepository
import { IAuthenticatorCreateRepository } from '../../../domain/authenticator/authenticator/IAuthenticatorRepository';
import { IConsentFormQueryRepository } from '../../../domain/verification/consentForm/IConsentFormRepository';

// DomainObject
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class TwoFactorAuthAppSettingsViewUseCase
  implements ITwoFactorAuthAppSettingsViewUseCase {
  private authenticatorCreateService: IAuthenticatorCreateService;

  private consentFormQueryService: IConsentFormQueryService;

  constructor(
    authenticatorCreateRepository: IAuthenticatorCreateRepository,
    consentFormQueryRepository: IConsentFormQueryRepository,
  ) {
    this.authenticatorCreateService = new AuthenticatorCreateService(
      authenticatorCreateRepository,
    );

    this.consentFormQueryService = new ConsentFormQueryService(
      consentFormQueryRepository,
    );
  }

  public open = async (user: UserAuthorized) => {
    const [authenticator, consentForm] = await Promise.all([
      this.authenticatorCreateService.executeCreate(user),
      this.consentFormQueryService.findById(user.getId()),
    ]);

    return { authenticator, consentForm };
  };
}
export default TwoFactorAuthAppSettingsViewUseCase;

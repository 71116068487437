/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import UserAuthorized from './UserAuthorized';

export interface UserAuthorizedContextProps {
  userAuthorized?: UserAuthorized;
  setUserAuthorized: (userAuthorized: UserAuthorized) => void;
}

const UserAuthorizedContext = React.createContext<UserAuthorizedContextProps>({
  userAuthorized: undefined,
  setUserAuthorized: (userAuthorized: UserAuthorized) => undefined,
});

export default UserAuthorizedContext;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormContractTimeDepositRenewalReservationConfirmLayoutProps {
  children: React.ReactElement[];
}

const FormContractTimeDepositRenewalReservationConfirmLayout: FC<FormContractTimeDepositRenewalReservationConfirmLayoutProps> = ({
  children,
}: FormContractTimeDepositRenewalReservationConfirmLayoutProps) => {
  const elements = ['twoFactorAuth', 'button'];
  const [twoFactorAuth, button] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn height="auto" alignItems="center">
      {twoFactorAuth}
      <Margin top={50} />
      {button}
    </FlexBoxColumn>
  );
};

export default FormContractTimeDepositRenewalReservationConfirmLayout;

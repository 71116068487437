import React from 'react';
import TransferSavingsAccountTransactionSeed from './TransferSavingsAccountTransactionSeed';

export interface TransferSavingsAccountTransactionSeedContextProps {
  transferSavingsAccountTransactionSeed?: TransferSavingsAccountTransactionSeed;
}

const TransferSavingsAccountTransactionSeedContext = React.createContext<
  TransferSavingsAccountTransactionSeedContextProps
>({
  transferSavingsAccountTransactionSeed: undefined,
});

export default TransferSavingsAccountTransactionSeedContext;

// React
import React, { FC, useMemo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BankSpotTransactionsContext from '../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankSpotTransaction/BankSpotTransactions/BankSpotTransactionsContext';

// Constant
import { FILTER_RULE } from '../../../../../config/constants/business';

// Mapper
import TableBankTransactionHistoryMapper, {
  TdBankTransactionHistory,
} from './TableBankTransactionHistoryMapper';

// Components
import DefaultTdBox from '../../../../molecules/table/td/DefaultTdBox';
import CurrencyAmountTdBox from '../../../../molecules/table/td/CurrencyAmountTdBox';
import TableFunctional from '../../../../molecules/table/table/TableFunctional';

// Table
import { TextInputFilter } from '../../../../atoms/table/filter';
// CSV
import CSVDataContext from '../../../csv/CSVData/CSVDataContext';

// ID
const ORGANISM_ID = `organisms.table.TableBankTransactionHistory`;

const TableBankTransactionHistory: FC = () => {
  const { t } = useTranslation();
  const { bankSpotTransactions } = useContext(BankSpotTransactionsContext);
  const { setCSVData } = useContext(CSVDataContext);

  const data = useMemo(
    () => TableBankTransactionHistoryMapper.domainToTd(bankSpotTransactions, t),
    [bankSpotTransactions, t],
  );

  useEffect(() => {
    setCSVData(data);
    // eslint-disable-next-line
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: t(`${ORGANISM_ID}.th.date`),
        accessor: (values: TdBankTransactionHistory) =>
          values.createdAt.getTableAccessorValue(),
        Cell: (value: { row: { original: TdBankTransactionHistory } }) =>
          DefaultTdBox({
            text: value.row.original.createdAt.getTwoLinesDateTime(),
          }),
        Filter: TextInputFilter,
        filter: FILTER_RULE,
        minWidth: `${160 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.description`),
        accessor: (values: TdBankTransactionHistory) => values.description,
        Cell: (value: { value: string }) => DefaultTdBox({ text: value.value }),
        Filter: TextInputFilter,
        filter: FILTER_RULE,
        minWidth: `${320 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.deposit`),
        accessor: (values: TdBankTransactionHistory) => values.deposit,
        Cell: (value: { row: { original: TdBankTransactionHistory } }) =>
          CurrencyAmountTdBox({
            currency: value.row.original.currency,
            number: value.row.original.deposit,
          }),
        Filter: TextInputFilter,
        filter: FILTER_RULE,
        minWidth: `${182 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.withdrawal`),
        accessor: (values: TdBankTransactionHistory) => values.withdrawal,
        Cell: (value: { row: { original: TdBankTransactionHistory } }) =>
          CurrencyAmountTdBox({
            currency: value.row.original.currency,
            number: value.row.original.withdrawal,
          }),
        Filter: TextInputFilter,
        filter: FILTER_RULE,
        minWidth: `${182 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.balance`),
        accessor: (values: TdBankTransactionHistory) => values.balance,
        Cell: (value: { row: { original: TdBankTransactionHistory } }) =>
          CurrencyAmountTdBox({
            currency: value.row.original.currency,
            number: value.row.original.balance,
          }),
        Filter: TextInputFilter,
        filter: FILTER_RULE,
        minWidth: `${192 / 16}rem`,
      },
    ],
    // eslint-disable-next-line
    [],
  );
  return <TableFunctional data={data} columns={columns} />;
};

export default TableBankTransactionHistory;

import React, { FC } from 'react';
import TextSub from './TextSub';
import fontWeight from '../../../../fonts/fontWeight.json';
import fontSize from '../../../../fonts/fontSizePixel.json';

export interface TextSubRegular22Props {
  children: React.ReactNode;
  color: string;
}

const TextSubRegular22: FC<TextSubRegular22Props> = ({
  children,
  color,
}: TextSubRegular22Props) => {
  const pixel = fontSize.pixel22;
  const textFontWeight = fontWeight.regular;

  return (
    <TextSub pixel={pixel} color={color} fontWeight={textFontWeight}>
      {children}
    </TextSub>
  );
};
export default TextSubRegular22;

import React, { FC } from 'react';
import { FlexBoxRow } from '../../utils/layout/Box/FlexBox';
import color from '../../utils/const/color';

export interface FileDeleteButtonProps {
  children: React.ReactNode;
  width?: string | number;
  height?: string | number;
  onClick?: () => void;
  theme?: {
    borderRadius?: string | number;
  };
}

const FileDeleteButton: FC<FileDeleteButtonProps> = ({
  children,
  width = '100%',
  height = '100%',
  onClick = () => undefined,
  theme,
}: FileDeleteButtonProps) => {
  return (
    <FlexBoxRow
      width={width}
      height={height}
      justifyContent="center"
      alignItems="center"
      theme={{
        ...theme,
        backgroundColor: color.red,
      }}
      style={{
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {children}
    </FlexBoxRow>
  );
};

export default FileDeleteButton;

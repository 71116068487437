// Type
import { DebitCardOptionObject } from '../option/DebitCardOption/DebitCardOption.type';

// DomainObject
import DebitCardV2 from '../../../../../domain/debitCard/debitCardV2/DebitCardV2/DebitCardV2';
import DebitCardV2s from '../../../../../domain/debitCard/debitCardV2/DebitCardV2s/DebitCardV2s';

class DebitCardsOptionsMapper {
  static domainToOptions = (debitCards: DebitCardV2s) => {
    const getOption = (debitCard: DebitCardV2): DebitCardOptionObject => {
      const { balance, currency, type } = debitCard.getProps();

      return {
        label: debitCard.getFormattedFacedNumber(),
        value: debitCard.getId(),
        balance,
        cardNumber: debitCard.getFormattedFacedNumber(),
        currency,
        type,
      };
    };
    return debitCards.map<DebitCardOptionObject>(getOption);
  };
}

export default DebitCardsOptionsMapper;

import React from 'react';
import ContractTimeDepositSeed from './ContractTimeDepositSeed';

export interface ContractTimeDepositSeedContextProps {
  contractTimeDepositSeed?: ContractTimeDepositSeed;
}

const ContractTimeDepositSeedContext = React.createContext<
  ContractTimeDepositSeedContextProps
>({
  contractTimeDepositSeed: undefined,
});

export default ContractTimeDepositSeedContext;

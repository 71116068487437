import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessHandlerUserStatusProvider from '../enhancers/routing/AccessHandlerUserStatusProvider';
import AuthenticatedPageVewProvider from '../enhancers/useCase/authentication/AuthenticatedPageViewProvider';

// Type
import { MenuType } from '../config/menu/main/Menu.type';

// Constant
import { ALLOWED_USER_STATUS } from './accessHandler/userStatusPageAccessMapper';
import {
  ROUTE_DEBIT_CARD,
  ROUTE_DEBIT_CARD_HISTORY,
} from '../config/constants/routing';

// Component
import { EnhancedDebitCardPage } from '../containers/debitCard/DebitCardPage';
import { EnhancedDebitCardHistoryPage } from '../containers/debitCard/DebitCardHistoryPage';

const DebitCardRouter: FC = () => {
  return (
    <Switch>
      <AuthenticatedPageVewProvider>
        <AccessHandlerUserStatusProvider
          statusAllowed={ALLOWED_USER_STATUS[MenuType.debitCards]}
        >
          <Route exact path={ROUTE_DEBIT_CARD}>
            <EnhancedDebitCardPage />
          </Route>
          <Route exact path={ROUTE_DEBIT_CARD_HISTORY}>
            <EnhancedDebitCardHistoryPage />
          </Route>
        </AccessHandlerUserStatusProvider>
      </AuthenticatedPageVewProvider>
    </Switch>
  );
};

export default DebitCardRouter;

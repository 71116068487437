import { useState } from 'react';
import BankFutureDeposits from './BankFutureDeposits';

export const useBankFutureDeposits = (defaultValue?: BankFutureDeposits) => {
  const [bankFutureDeposits, setBankFutureDeposits] = useState<
    BankFutureDeposits
  >(defaultValue || new BankFutureDeposits());

  return {
    bankFutureDeposits,
    setBankFutureDeposits,
  };
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle } from './style';

// Component
import { TextSecondary } from '../../../../../atoms/text2/Text2';
import DebitCardRequestFormContainer from '../../../common/DebitCardRequestFormContainer';
import FormShippingAddressLayout from './FormShippingAddressLayout';
import FormInputCherryVisa01ShippingAddress from '../../../../../molecules/form/formInputGroup/FormInputCherryVisa01ShippingAddress';

const FORM_ID = `organisms.form.debitCards.FormProofOfIdentity.shippingAddress`;

const FormShippingAddress: FC = () => {
  const { t } = useTranslation();

  return (
    <DebitCardRequestFormContainer order={9} formId={FORM_ID}>
      <FormShippingAddressLayout>
        <TextSecondary key="description" theme={DescriptionStyle}>
          {t(`${FORM_ID}.description`)}
        </TextSecondary>
        <FormInputCherryVisa01ShippingAddress
          key="shippingAddress"
          addressKeys={{
            country: 'country',
            addressLine1: 'addressLine1',
            addressLine2: 'addressLine2',
            city: 'city',
            state: 'state',
            postalCode: 'postalCode',
          }}
        />
      </FormShippingAddressLayout>
    </DebitCardRequestFormContainer>
  );
};

export default FormShippingAddress;

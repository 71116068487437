import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Constant
import { INVITE_INVITATION_CODES } from '../../../../config/constants/pageId.json';

// Style
import { DescriptionStyle } from './style';

// Component
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import { TextSecondary } from '../../../atoms/text2/Text2';
import InvitationCodeCardList from '../../../organisms/list/InvitationCodeCardList';
import InvitationCodesTemplateLayout from './InvitationCodesTemplateLayout';

const InvitationCodesTemplate: FC = () => {
  const { t } = useTranslation();
  return (
    <InvitationCodesTemplateLayout>
      <PageTitle key="title">{t(`${INVITE_INVITATION_CODES}.title`)}</PageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${INVITE_INVITATION_CODES}.description`)}
      </TextSecondary>
      <InvitationCodeCardList key="codes" />
    </InvitationCodesTemplateLayout>
  );
};

export default InvitationCodesTemplate;

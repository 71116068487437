import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Error
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import { SavingsAccountType } from '../../../../types/SavingsAccount.type';

// Constant
import color from '../../../atoms/utils/const/color';

// Component
import { TextSecondary, TextThemeProps } from '../../../atoms/text2/Text2';
import SavingsAccountTypeIcon from '../../../atoms/icon/SavingsAccountTypeIcon';
import SavingsAccountTypeBadgeLayout from './SavingsAccountTypeBadgeLayout';

export interface SavingsAccountTypeBadgeProps {
  savingsAccountType: SavingsAccountType;
  layout: {
    iconSize: number | string;
  };
  theme: {
    text: TextThemeProps;
  };
}

const SavingsAccountTypeBadge: FC<SavingsAccountTypeBadgeProps> = ({
  savingsAccountType,
  layout,
  theme,
}: SavingsAccountTypeBadgeProps) => {
  const { t } = useTranslation();

  let [backgroundColor, textColor] = ['', ''];

  switch (savingsAccountType) {
    case SavingsAccountType.single:
      [backgroundColor, textColor] = [
        color.background.aliceBlue,
        color.text.summerSky,
      ];
      break;
    case SavingsAccountType.joint:
      [backgroundColor, textColor] = [
        color.background.pattensBlue,
        color.text.scooter,
      ];
      break;
    default: {
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_SWITCH,
        {
          value: savingsAccountType,
          place: `SavingsAccountTypeBadge`,
        },
      );
    }
  }

  return (
    <SavingsAccountTypeBadgeLayout backgroundColor={backgroundColor}>
      <SavingsAccountTypeIcon
        key="icon"
        type={savingsAccountType}
        size={layout.iconSize}
      />
      <TextSecondary theme={{ ...theme.text, color: textColor }} key="text">
        {t(`molecules.badge.savingsAccountType.${savingsAccountType}`)}
      </TextSecondary>
    </SavingsAccountTypeBadgeLayout>
  );
};

export default SavingsAccountTypeBadge;

// IUseCase
import IDebitCardRequestCherryVisa01StatusViewUseCase from '../../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01StatusViewProvider/IDebitCardRequestCherryVisa01StatusViewUseCase';

// IService
import IDebitCardRequestCherryVisa01QueryService from './IDebitCardRequestCherryVisa01QueryService';

// Service
import DebitCardRequestCherryVisa01QueryService from '../../../../service/debitCard/debitCardRequest/debitCardRequestCherryVisa01/DebitCardRequestCherryVisa01QueryService';

// IRepository
import { IDebitCardRequestCherryVisa01QueryRepository } from '../../../../domain/debitCard/debitCardRequest/IDebitCardRequestCherryVisa01Repository';

class DebitCardRequestCherryVisa01StatusViewUseCase
  implements IDebitCardRequestCherryVisa01StatusViewUseCase {
  private debitCardRequestCherryVisa01QueryService: IDebitCardRequestCherryVisa01QueryService;

  constructor(
    debitCardRequestCherryVisa01QueryRepository: IDebitCardRequestCherryVisa01QueryRepository,
  ) {
    this.debitCardRequestCherryVisa01QueryService = new DebitCardRequestCherryVisa01QueryService(
      debitCardRequestCherryVisa01QueryRepository,
    );
  }

  public open = async (id: string) => {
    const debitCardRequestCherryVisa01 = await this.debitCardRequestCherryVisa01QueryService.findById(
      id,
    );

    return { debitCardRequestCherryVisa01 };
  };
}

export default DebitCardRequestCherryVisa01StatusViewUseCase;

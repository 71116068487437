import React, { FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useKycRequestViewUseCase } from 'enhancers/useCase/verification/kycRequest/KycRequestViewProvider/useKycRequestViewUseCase';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';

// Constant
import { KycStatusType } from 'types/KycRequest.type';
import { ROUTE_KYC_STATUS } from 'config/constants/routing';
import { SUCCESS } from 'config/constants/requestState';

// Comonent
import DashboardTemplate from 'components/templates/common/DashboardTemplate';

export type KycRequestViewProviderProps = {
  children: React.ReactNode;
};

const KycRequestViewProvider: FC<KycRequestViewProviderProps> = ({
  children,
}: KycRequestViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const { state, setState, kycRequest } = useKycRequestViewUseCase(
    userAuthorized,
  );

  const history = useHistory();

  // KYC
  const kycStepStatus = kycRequest?.getKycStepStatus();
  const kycRequestStatus = kycStepStatus?.getKycRequestStatus();
  const loadedUndefinedKycRequest = state === SUCCESS && !kycRequest;

  /**
   *
   *  UseEffect
   *
   */

  // Routing
  useEffect(() => {
    if (kycRequestStatus && kycRequestStatus !== KycStatusType.initial) {
      history.push(ROUTE_KYC_STATUS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, kycRequestStatus]);

  const showInitialPage =
    loadedUndefinedKycRequest || kycRequestStatus === KycStatusType.initial;

  return (
    <SystemErrorHandler state={state} setState={setState}>
      <DashboardTemplate>{showInitialPage && children}</DashboardTemplate>
    </SystemErrorHandler>
  );
};

export default KycRequestViewProvider;

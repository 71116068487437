import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormPassportInputLayoutProps {
  children: React.ReactElement[];
}

const FormPassportInputLayout: FC<FormPassportInputLayoutProps> = ({
  children,
}: FormPassportInputLayoutProps) => {
  const elements = [
    'passportNo',
    'issuedDate',
    'expiredDate',
    'expiredDateDescription',
  ];
  const [
    passportNo,
    issuedDate,
    expiredDate,
    expiredDateDescription,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn height="auto">
      <FlexBoxRow width={600}>{passportNo}</FlexBoxRow>
      <Margin top={60} />
      {issuedDate}
      <Margin top={60} />
      {expiredDate}
      <FlexBoxRow>
        <FlexBoxRow width={200}></FlexBoxRow>
        {expiredDateDescription}
      </FlexBoxRow>
      <Margin top={40} />
    </FlexBoxColumn>
  );
};
export default FormPassportInputLayout;

import styled from 'styled-components';
import fontWeight from '../../../atoms/utils/const/fontWeight';
import color from '../../../atoms/utils/const/color';

export const Container = styled.div`
  width: ${1200 / 16}rem;
  box-sizing: border-box;
  padding-top: ${100 / 16}rem;
  padding-left: ${83 / 16}rem;
  margin-bottom: ${50 / 16}rem;
`;

export const Content = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: ${50 / 16}rem;
  background-color: #fff;
`;

export const LabeledCurrencyAmountTheme = {
  label: {
    fontSize: 24,
    fontWeight: fontWeight.regular,
    color: color.text.primary,
    opacity: 0.5,
  },
  currency: {
    fontSize: 31,
    fontWeight: fontWeight.bold,
    color: color.text.primary,
    opacity: 0.5,
  },
  amount: {
    fontSize: 31,
    fontWeight: fontWeight.bold,
    color: color.text.primary,
  },
};

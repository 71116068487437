import Joi from '@hapi/joi';

export interface DocumentTypeProps {
  label: string;

  value: string;

  priority: number;
}

export const DocumentTypePropsFormat = {
  label: Joi.string().required(),

  value: Joi.string().required(),

  priority: Joi.number().required(),
};

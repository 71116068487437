// Types
import { ReferralTimeDepositRawDataType } from '../../../domain/invite/referralTimeDeposit/referralTimeDeposit/ReferralTimeDeposit/ReferralTimeDeposit.type';
import { ReferralTimeDepositByChildrenRawDataType } from '../../../domain/invite/referralTimeDeposit/referralTimeDepositByChildren/ReferralTimeDepositByChildren/ReferralTimeDepositByChildren.type';

// Functions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';
import {
  INVITE_REFERRALS_TIME_DEPOSIT_FETCH_ALL_RANK_D,
  INVITE_REFERRALS_TIME_DEPOSIT_FETCH_ALL_RANK_A,
} from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// IRepository
import IReferralTimeDepositRepository from '../../../domain/invite/referralTimeDeposit/IReferralTimeDepositRepository';

// DomainObject
import ReferralsTimeDeposit from '../../../domain/invite/referralTimeDeposit/referralTimeDeposit/ReferralsTimeDeposit/ReferralsTimeDeposit';
import ReferralTimeDepositFactory from '../../../domain/invite/referralTimeDeposit/referralTimeDeposit/ReferralTimeDeposit/ReferralTimeDepositFactory';
import ReferralTimeDepositByChildrenFactory from '../../../domain/invite/referralTimeDeposit/referralTimeDepositByChildren/ReferralTimeDepositByChildren/ReferralTimeDepositByChildrenFactory';

class ReferralTimeDepositFunctions
  extends FirebasePrivateFunctions
  implements IReferralTimeDepositRepository {
  public findRawAllByRankDUserId = async (userId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const endpoint = INVITE_REFERRALS_TIME_DEPOSIT_FETCH_ALL_RANK_D.replace(
      /:userId/,
      userId,
    );

    const response = await this.privateGET(endpoint);

    const rows = response.data.data as Array<ReferralTimeDepositRawDataType>;

    return rows.reduce((objs, row) => {
      objs.add(ReferralTimeDepositFactory.createByProps(row.id, row.props));
      return objs;
    }, new ReferralsTimeDeposit());
  };

  public findRawAllByRankAUserId = async (userId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const endpoint = INVITE_REFERRALS_TIME_DEPOSIT_FETCH_ALL_RANK_A.replace(
      /:userId/,
      userId,
    );

    const response = await this.privateGET(endpoint);

    const rows = response.data.data as Array<
      ReferralTimeDepositByChildrenRawDataType
    >;

    return rows.reduce((objs, row) => {
      objs.add(ReferralTimeDepositByChildrenFactory.create(row.id, row.props));
      return objs;
    }, new ReferralsTimeDeposit());
  };
}
export default ReferralTimeDepositFunctions;

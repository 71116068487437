export const FILEPATH_CONSENT_FORM =
  'document/(Consent Form)Terms & Conditions Governing Electronic Services.pdf';

export const FILEPATH_EXAMPLE_SIGNATURE1 =
  'https://storage.cloud.google.com/forbes-private-bank-develop.appspot.com/example/signature_example1.png';
export const FILEPATH_EXAMPLE_SIGNATURE2 =
  'https://storage.cloud.google.com/forbes-private-bank-develop.appspot.com/example/signature_example2.png';

export const FILEPATH_SELFIE_EXAMPLE_NG1 =
  'https://storage.cloud.google.com/forbes-private-bank-develop.appspot.com/example/selfie_ng1.png';

// Verification
export const STORAGE_PATH_CORPORATE_REGISTRY =
  'verification/corporate_registry';

export const STORAGE_PATH_CORPORATE_ADDRESS = 'verification/corporate_address';

export const STORAGE_PATH_PASSPORT = 'verification/passport';

export const STORAGE_PATH_SELFIE = 'verification/selfie';

export const STORAGE_PATH_PROOF_OF_RESIDENCY =
  'verification/proof_of_residency';

export const STORAGE_PATH_CONSENT_FORM = 'verification/consent_form';

// Manual
export const STORAGE_PATH_MANUALS = `manual/v2`;

export const MANUAL_DIRECTORY_PATHS = [
  '00bank',
  '01debitCard',
  '02user',
  '03faq',
];

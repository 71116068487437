import React, { FC } from 'react';
import PDFPageViewer from './PDFPageViewer';

export interface ConsentFormViewerProps {
  filepath: string;
}

const ConsentFormViewer: FC<ConsentFormViewerProps> = ({
  filepath,
}: ConsentFormViewerProps) => {
  return <PDFPageViewer filepath={filepath} />;
};
export default ConsentFormViewer;

import Joi from '@hapi/joi';
import {
  UserRoleType,
  UserType,
  UserStatusType,
} from '../../../../../types/User.type';
import { RankType } from '../../../../../types/Invite.type';
import {
  ShippingAddressProps,
  ShippingAddressPropsFormat,
} from '../../../../../value/personalInformation/address/Address/ShippingAddress/ShippingAddress.type';

export interface UserProps {
  id: string;

  token?: string;

  canInvite: boolean;

  contractName?: string;

  representativeName?: string;

  email: string;

  isDeleted: boolean;

  isVIP: boolean;

  rank: RankType;

  role: UserRoleType;

  secondLevelInviterId?: string;

  shippingAddress?: ShippingAddressProps;

  status: UserStatusType;

  type?: UserType;

  topLevelInviterId?: string;

  username: string;

  createdAt?: number;

  updatedAt?: number;
}

export const UserPropsFormat = {
  id: Joi.string().min(1).required(),

  canInvite: Joi.boolean().required(),

  token: Joi.string().min(1).empty(''),

  contractName: Joi.string().min(1).max(255).empty(''),

  representativeName: Joi.string().min(1).max(255).empty(''),

  email: Joi.string()
    .email({
      allowUnicode: false,
      tlds: { allow: false },
    })
    .required(),

  isDeleted: Joi.boolean().required(),

  isVIP: Joi.boolean().required(),

  rank: Object.entries(RankType)
    .reduce((joi, [, value]) => joi.valid(value), Joi.string())
    .required(),

  role: Joi.string().valid(UserRoleType.user),

  secondLevelInviterId: Joi.string().min(1).empty(''),

  shippingAddress: Joi.object(ShippingAddressPropsFormat),

  status: Object.keys(UserStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),

  type: Object.keys(UserType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .empty(''),

  topLevelInviterId: Joi.string().min(1).empty(''),

  username: Joi.string().min(2).max(20).required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

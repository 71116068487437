import React from 'react';

// DomainObject
import DebitCardRequests from './DebitCardRequests';

export interface DebitCardRequestsContextProps {
  debitCardRequests: DebitCardRequests;
}

const DebitCardRequestsContext = React.createContext<
  DebitCardRequestsContextProps
>({
  debitCardRequests: new DebitCardRequests(),
});

export default DebitCardRequestsContext;

import { useState } from 'react';

export type ContractTimeDepositRenewalReservationPageState =
  | 'initial'
  | 'confirm'
  | 'complete';

export const useContractTimeDepositRenewalReservationAddState = () => {
  const [pageState, setPageState] = useState<
    ContractTimeDepositRenewalReservationPageState
  >('initial');

  return {
    pageState,
    setPageState,
  };
};

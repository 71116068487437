import React, { FC, useContext } from 'react';
import { useTransferDebitCardCreateUseCase } from './useTransferDebitCardCreateUseCase';
import DebitCardOptionContext from '../../../../../components/molecules/selectBox/option/option/DebitCardOption/DebitCardOptionContext';
import TransferDebitCardTransactionV2Context from '../../../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2/TransferDebitCardTransactionV2Context';
import TransferDebitCardTransactionV2SeedContext from '../../../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2Seed/TransferDebitCardTransactionV2SeedContext';
import TransferDebitCardCreateContext from './TransferDebitCardCreateContext';
import UserVerifiedContext from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedContext';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

// Type
import { FormInputTransferDebitCardContext } from '../../../../../components/organisms/form/transfer/debitCard/FormTransferDebitCard/FormInputTransferDebitCard.type';

export type TransferDebitCardCreateProviderProps = {
  children: React.ReactNode;
};

const TransferDebitCardCreateProvider: FC<TransferDebitCardCreateProviderProps> = ({
  children,
}: TransferDebitCardCreateProviderProps) => {
  const { user } = useContext(UserVerifiedContext);

  const {
    state,
    setState,
    createTransferDebitCard,
    executeCreateTransferDebitCard,
    transferDebitCardTransaction,
    transferDebitCardTransactionSeed,
    debitCardOptionContext,
    formInputTransferDebitCardContext,
  } = useTransferDebitCardCreateUseCase(user);

  return (
    <FormInputTransferDebitCardContext.Provider
      value={formInputTransferDebitCardContext}
    >
      <TransferDebitCardTransactionV2Context.Provider
        value={{ transferDebitCardTransaction }}
      >
        <TransferDebitCardCreateContext.Provider
          value={{
            requestState: state,
            createTransferDebitCard,
            executeCreateTransferDebitCard,
          }}
        >
          <TransferDebitCardTransactionV2SeedContext.Provider
            value={{ transferDebitCardTransactionSeed }}
          >
            <DebitCardOptionContext.Provider value={debitCardOptionContext}>
              <SystemErrorHandler state={state} setState={setState}>
                {children}
              </SystemErrorHandler>
            </DebitCardOptionContext.Provider>
          </TransferDebitCardTransactionV2SeedContext.Provider>
        </TransferDebitCardCreateContext.Provider>
      </TransferDebitCardTransactionV2Context.Provider>
    </FormInputTransferDebitCardContext.Provider>
  );
};
export default TransferDebitCardCreateProvider;

import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  ROUTE_GOOGLE_AUTHENTICATOR_APP_STORE,
  ROUTE_GOOGLE_AUTHENTICATOR_GOOGLE_PLAY,
} from '../../../../config/constants/routing';
import GooglePlayDownload from '../../../../assets/images/googlePlay.png';
import AppStoreDownload from '../../../../assets/images/appStore.png';
import Box from '../../utils/layout/Box/Box';

export interface MobileAppDownloadBadgeProps {
  type: 'android' | 'iOS';
  width?: string | number;
  height?: string | number;
  noName?: boolean;
}

const MobileAppDownloadBadge: FC<MobileAppDownloadBadgeProps> = ({
  type,
  width = '100%',
  height = '100%',
}: MobileAppDownloadBadgeProps) => {
  const [downloadImage, href] =
    type === 'android'
      ? [GooglePlayDownload, ROUTE_GOOGLE_AUTHENTICATOR_GOOGLE_PLAY]
      : [AppStoreDownload, ROUTE_GOOGLE_AUTHENTICATOR_APP_STORE];

  return (
    <Link to={href} target="_blank">
      <Box width={width} height={height}>
        <img src={downloadImage} alt={''} width="100%" height="100%" />
      </Box>
    </Link>
  );
};
export default MobileAppDownloadBadge;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DebitCardRequestViewContext from '../../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequest/DebitCardRequestViewProvider/DebitCardRequestViewContext';
import DebitCardRequestCreateContext from '../../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequest/DebitCardRequestCreateProvider/DebitCardRequestCreateContext';

// Constant
import { IS_LOADING } from '../../../../config/constants/requestState';

// Style
import { DescriptionStyle } from './style';

// Component
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../atoms/button/Button2';
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import { TextPrimary, TextSecondary } from '../../../atoms/text2/Text2';
import CircleSpinLoader from '../../../atoms/spinner/CircleSpinLoader';

import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';
import DebitCardRequestTemplateLayout from './DebitCardRequestTemplateLayout';

const TEMPLATE_ID = `templates.debitCards.DebitCardRequestTemplate`;

const DebitCardRequestTemplate: FC = () => {
  const { t } = useTranslation();

  const { state: viewState } = useContext(DebitCardRequestViewContext);
  const { createDebitCardRequest, state: createState } = useContext(
    DebitCardRequestCreateContext,
  );

  return (
    <DebitCardRequestTemplateLayout viewState={viewState}>
      <PageTitle key="header">{t(`${TEMPLATE_ID}.title`)}</PageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced text={t(`${TEMPLATE_ID}.description`)} />
      </TextSecondary>
      <SubmitButton
        key="button"
        height={50}
        onClick={() => {
          createDebitCardRequest();
        }}
        isLoading={createState === IS_LOADING}
        theme={{ borderRadius: 25 }}
        width={346}
      >
        <TextPrimary theme={DefaultButtonTextStyle}>
          {t(`atoms.button.startDebitCardRequestProcess`)}
        </TextPrimary>
      </SubmitButton>
      <CircleSpinLoader key="loader" />
    </DebitCardRequestTemplateLayout>
  );
};

export default DebitCardRequestTemplate;

// Type
import { UserStatusType } from '../../../types/User.type';
import { MenuType } from './Menu.type';

// Error
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';

// DomainObject
import User from '../../../domain/public/user/User/User/User';

class MenuFactory {
  static createUserMenu = (user: User): MenuType[] => {
    const { canInvite, status } = user.getProps();

    switch (status) {
      case UserStatusType.initial:
        return [MenuType.verification, MenuType.settings];

      case UserStatusType.ready: {
        const menus = [
          MenuType.assets,
          MenuType.cryptocurrencies,
          MenuType.savingsAccounts,
          MenuType.timeDeposits,
        ];
        if (canInvite) menus.push(MenuType.invitations);

        return menus.concat([MenuType.settings, MenuType.manual]);
      }

      case UserStatusType.cancelInvesting: {
        const menus = [
          MenuType.assets,
          MenuType.savingsAccounts,
          MenuType.timeDeposits,
          MenuType.debitCards,
          MenuType.transfer,
        ];

        if (canInvite) menus.push(MenuType.invitations);

        return menus.concat([MenuType.settings, MenuType.manual]);
      }
      case UserStatusType.investing: {
        const menus = [
          MenuType.assets,
          MenuType.cryptocurrencies,
          MenuType.savingsAccounts,
          MenuType.timeDeposits,
          MenuType.debitCards,
          MenuType.transfer,
        ];

        if (canInvite) menus.push(MenuType.invitations);

        return menus.concat([MenuType.settings, MenuType.manual]);
      }

      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_SWITCH,
          {
            value: status,
            place: 'MenuFactory.createUserMenu',
          },
        );
    }
  };
}

export default MenuFactory;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface FormInputAddressLayoutProps {
  children: React.ReactElement[];
}

const FormInputAddressLayout: FC<FormInputAddressLayoutProps> = ({
  children,
}: FormInputAddressLayoutProps) => {
  const elements = [
    'country',
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'postalCode',
  ];
  const [
    country,
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn>
      <FlexBoxRow width={600}>{country}</FlexBoxRow>
      <Margin top={80} />
      <FlexBoxRow width={1040}>{addressLine1}</FlexBoxRow>
      <Margin top={40} left={200}>
        <FlexBoxColumn>
          {addressLine2}
          <Margin top={40} />
          <FlexBoxRow justifyContent="space-between">
            {city}
            {state}
          </FlexBoxRow>
          <Margin top={40} />
          {postalCode}
        </FlexBoxColumn>
      </Margin>
    </FlexBoxColumn>
  );
};

export default FormInputAddressLayout;

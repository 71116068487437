import React, { FC, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ResetPasswordContext } from '../../../../../enhancers/front/ResetPassword/ResetPasswordContext';
import {
  resetPasswordPasswordRegister,
  resetPasswordConfirmPasswordRegister,
} from '../../../../../utils/validation/registers';
import { deleteFullWidth } from '../../../../../utils/helpers/stringHelper';
import { RESET_PASSWORD } from '../../../../../config/constants/pageId.json';
import { IS_LOADING } from '../../../../../config/constants/requestState';
import fontColor from '../../../../../fonts/color.json';
import { Container, SubmitButtonContainer } from './style';
import TextField from '../../../../molecules/form/TextField/TextField';
import { SubmitButton } from '../../../../atoms/button/DomainButton';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface ResetPasswordFormInput {
  password: string;
  confirmPassword: string;
}

const ResetPasswordForm: FC = () => {
  const { t } = useTranslation();
  const { resetPasswordState, resetPassword } = useContext(
    ResetPasswordContext,
  );
  const {
    errors,
    formState: { isValid },
    handleSubmit,
    trigger,
    setValue,
    register,
    watch,
  } = useForm<ResetPasswordFormInput>({
    mode: 'onChange',
  });

  return (
    <Container>
      <form
        onSubmit={handleSubmit((formInput) =>
          resetPassword(formInput.password),
        )}
      >
        <div>
          <TextField
            name="password"
            value={watch('password')}
            type="password"
            label={t(`${RESET_PASSWORD}.form.password.label`)}
            labelColor={fontColor.textDark}
            errorMessage={errors.password && errors.password.message}
            onChange={(text: string) => {
              trigger(['password', 'confirmPassword']);
              setValue('password', deleteFullWidth(text));
            }}
            register={register(resetPasswordPasswordRegister(t))}
            maxLength={20}
          />
        </div>
        <Margin top={20} />
        <div>
          <TextField
            name="confirmPassword"
            type="password"
            value={watch('confirmPassword')}
            label={t(`${RESET_PASSWORD}.form.confirmPassword.label`)}
            labelColor={fontColor.textDark}
            errorMessage={
              errors.confirmPassword && errors.confirmPassword.message
            }
            onChange={(text: string) => {
              trigger(['password', 'confirmPassword']);
              setValue('confirmPassword', deleteFullWidth(text));
            }}
            register={register(
              resetPasswordConfirmPasswordRegister(t, watch(`password`)),
            )}
            maxLength={20}
          />
        </div>
        <Margin top={20} />
        <SubmitButtonContainer>
          <SubmitButton
            name={t(`${RESET_PASSWORD}.form.button.submit`)}
            width={`${265 / 16}rem `}
            height={`${50 / 16}rem `}
            borderRadius={`${25 / 16}rem`}
            textSize={20}
            sizeProgress={`${20 / 16}rem`}
            disabled={!isValid}
            isLoading={resetPasswordState === IS_LOADING}
          />
        </SubmitButtonContainer>
      </form>
    </Container>
  );
};

export default ResetPasswordForm;

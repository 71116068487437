import { required, alphaNumeric } from '../validator';

// Type
import { TypeOfT } from '../../../../types/typeof/Translation.type';

const personalInformationValidation = (t: TypeOfT) => ({
  passportNo: {
    required: required('Passport No', t),
    validate: {
      alphaNumeric: alphaNumeric('Passport No', t),
    },
  },
});

export default personalInformationValidation;

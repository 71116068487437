// IUseCase
import IContractTimeDepositRenewalReservationDeleteUseCase, {
  ContractTimeDepositRenewalReservationDeleteParameter,
} from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationDeleteProvider/IContractTimeDepositRenewalReservationDeleteUseCase';

// IService
import IAuthenticatorVerifyService from '../../authenticator/authenticator/interface/IAuthenticatorVerifyService';
import IContractTimeDepositUpdateService from './interface/IContractTimeDepositUpdateService';

// Service
import AuthenticatorVerifyService from '../../../service/authenticator/AuthenticatorVerifyService';
import ContractTimeDepositUpdateService from '../../../service/contract/contractTimeDeposit/ContractTimeDepositUpdateService';

// IRepository
import { IContractTimeDepositUpdateRepository } from '../../../domain/contract/contractTimeDeposit/IContractTimeDepositRepository';

// DomainObject
import ContractTimeDeposit from '../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';
import UserVerified from '../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';

class ContractTimeDepositRenewalReservationDeleteUseCase
  implements IContractTimeDepositRenewalReservationDeleteUseCase {
  private contractTimeDepositUpdateService: IContractTimeDepositUpdateService;

  private authenticatorVerifyService: IAuthenticatorVerifyService;

  constructor(
    authenticatorVerifyService: IAuthenticatorVerifyService,
    contractTimeDepositUpdateRepository: IContractTimeDepositUpdateRepository,
  ) {
    this.contractTimeDepositUpdateService = new ContractTimeDepositUpdateService(
      contractTimeDepositUpdateRepository,
    );

    this.authenticatorVerifyService = new AuthenticatorVerifyService(
      authenticatorVerifyService,
    );
  }

  public delete = (
    params: ContractTimeDepositRenewalReservationDeleteParameter,
  ) => {
    const contractTimeDepositUpdated = this.contractTimeDepositUpdateService.deleteRenewalReservation(
      params,
    );

    return { contractTimeDeposit: contractTimeDepositUpdated };
  };

  public executeDelete = async (
    user: UserVerified,
    contractTimeDeposit: ContractTimeDeposit,
    code: string,
  ) => {
    // Authenticator
    await this.authenticatorVerifyService.verify(user, code);

    // Update ContractTimeDeposit
    await this.contractTimeDepositUpdateService.executeDeleteRenewalReservation(
      contractTimeDeposit,
    );

    return { contractTimeDeposit };
  };
}
export default ContractTimeDepositRenewalReservationDeleteUseCase;

import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  PersonalInformationProps,
  PersonalInformationPropsFormat,
  PersonalInformationInitialProps,
} from './PersonalInformation.type';

// ValueObject
import PersonalInformation from './PersonalInformation';

class PersonalInformationFactory {
  static create = (props: PersonalInformationProps) => {
    const result = Joi.object(PersonalInformationPropsFormat).validate(props);

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: PersonalInformation.name,
          reason: result.error.details[0].message,
        },
      );

    return new PersonalInformation(props);
  };

  static createInitial = () => {
    return PersonalInformationFactory.create(PersonalInformationInitialProps);
  };
}

export default PersonalInformationFactory;

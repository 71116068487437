import React, { FC } from 'react';

// Image
import DebitCardNumberDigit0 from '../../../../../assets/images/debitCard_number_0.svg';
import DebitCardNumberDigit1 from '../../../../../assets/images/debitCard_number_1.svg';
import DebitCardNumberDigit2 from '../../../../../assets/images/debitCard_number_2.svg';
import DebitCardNumberDigit3 from '../../../../../assets/images/debitCard_number_3.svg';
import DebitCardNumberDigit4 from '../../../../../assets/images/debitCard_number_4.svg';
import DebitCardNumberDigit5 from '../../../../../assets/images/debitCard_number_5.svg';
import DebitCardNumberDigit6 from '../../../../../assets/images/debitCard_number_6.svg';
import DebitCardNumberDigit7 from '../../../../../assets/images/debitCard_number_7.svg';
import DebitCardNumberDigit8 from '../../../../../assets/images/debitCard_number_8.svg';
import DebitCardNumberDigit9 from '../../../../../assets/images/debitCard_number_9.svg';
import DebitCardNumberDigitAsterisk from '../../../../../assets/images/debitCard_number_asterisk.svg';

// Error
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';

// LayoutComponent
import Box from '../../../utils/layout/Box/Box';

export interface DebitCardNumberOneDigitProps {
  layout: {
    width: string | number;
    height: string | number;
  };
  digit: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '*';
}

const DebitCardNumberOneDigit: FC<DebitCardNumberOneDigitProps> = ({
  layout,
  digit,
}: DebitCardNumberOneDigitProps) => {
  let src;

  switch (digit) {
    case '0':
      src = DebitCardNumberDigit0;
      break;
    case '1':
      src = DebitCardNumberDigit1;
      break;
    case '2':
      src = DebitCardNumberDigit2;
      break;
    case '3':
      src = DebitCardNumberDigit3;
      break;
    case '4':
      src = DebitCardNumberDigit4;
      break;
    case '5':
      src = DebitCardNumberDigit5;
      break;
    case '6':
      src = DebitCardNumberDigit6;
      break;
    case '7':
      src = DebitCardNumberDigit7;
      break;
    case '8':
      src = DebitCardNumberDigit8;
      break;
    case '9':
      src = DebitCardNumberDigit9;
      break;
    case '*':
      src = DebitCardNumberDigitAsterisk;
      break;
    default:
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_SWITCH,
        {
          value: digit,
          place: `DebitCardNumberOneDigit`,
        },
      );
  }

  return (
    <Box {...layout}>
      <img
        src={src}
        alt={`${digit}`}
        width={'100%'}
        height={'100%'}
        style={{ margin: 0, padding: 0 }}
      />
    </Box>
  );
};
export default DebitCardNumberOneDigit;

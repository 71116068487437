import React, { FC, useContext } from 'react';
import VerifyAuthenticatorContext from '../../../enhancers/auth/authenticator/VerifyAuthenticator/VerifyAuthenticatorContext';
import UpdateAuthenticatorContext from '../../../enhancers/auth/authenticator/UpdateAuthenticator/UpdateAuthenticatorContext';

// Constant
import { SUCCESS } from '../../../config/constants/requestState';

// Component
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import TwoFactorAuthAppEditTemplate from '../../../components/templates/settings/TwoFactorAuthAppEditTemplate';
import TwoFactorAuthNewAccountKeyTemplate from '../../../components/templates/settings/TwoFactorAuthNewAccountKeyTemplate';
import TwoFactorAuthAppEditCompleteTemplate from '../../../components/templates/settings/TwoFactorAuthAppEditCompleteTemplate';

const TwoFactorAuthAppEditPage: FC = () => {
  const { verifyAuthenticatorState } = useContext(VerifyAuthenticatorContext);
  const { updateAuthenticatorState } = useContext(UpdateAuthenticatorContext);

  return (
    <DashboardTemplate>
      {(() => {
        if (updateAuthenticatorState === SUCCESS)
          return <TwoFactorAuthAppEditCompleteTemplate />;
        if (verifyAuthenticatorState === SUCCESS)
          return <TwoFactorAuthNewAccountKeyTemplate />;
        return <TwoFactorAuthAppEditTemplate />;
      })()}
    </DashboardTemplate>
  );
};

export default TwoFactorAuthAppEditPage;

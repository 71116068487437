// IService
import IAuthenticatorVerifyService from '../../useCases/authenticator/authenticator/interface/IAuthenticatorVerifyService';

// IRepository
import IAuthenticatorVerifier from '../../domain/authenticator/authenticator/IAuthenticatorVerifier';

// DomainObject
import UserVerified from '../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';

class AuthenticatorVerifyService implements IAuthenticatorVerifyService {
  private authenticatorVerifier: IAuthenticatorVerifier;

  constructor(authenticatorVerifier: IAuthenticatorVerifier) {
    this.authenticatorVerifier = authenticatorVerifier;
  }

  public verify = async (user: UserVerified, code: string) => {
    await this.authenticatorVerifier.verify(user, code);
  };
}

export default AuthenticatorVerifyService;

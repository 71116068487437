import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxRow } from '../../../../../../atoms/utils/layout/Box/FlexBox';

export interface FormInputOccupationLayoutProps {
  children: React.ReactElement[];
  labelWidth: string | number;
  inputWidth: string | number;
}

const FormInputOccupationLayout: FC<FormInputOccupationLayoutProps> = ({
  children,
  labelWidth,
  inputWidth,
}: FormInputOccupationLayoutProps) => {
  const elements = ['label', 'input'];
  const [label, input] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow alignItems="center">
      <FlexBoxRow width={labelWidth}>{label}</FlexBoxRow>
      <FlexBoxRow width={inputWidth}>{input}</FlexBoxRow>
    </FlexBoxRow>
  );
};
export default FormInputOccupationLayout;

import React, { FC } from 'react';
import styled from 'styled-components';
import { bold, regular } from '../../../../fonts/fontWeight.json';

export interface FontFamilyPlayfairDisplayProps {
  children: React.ReactNode;
  fontWeight?: number;
}

const FontFamilyPlayfairDisplaySpan = styled.span`
  font-family: ${(props) => {
    return props.theme.fontWeight === bold
      ? 'PlayFairDisplayBold'
      : 'PlayFairDisplayRegular';
  }};
  font-weight: ${(props) => (props.theme.fontWeight === bold ? bold : regular)};
`;

const FontFamilyPlayfairDisplay: FC<FontFamilyPlayfairDisplayProps> = ({
  children,
  fontWeight = bold,
}: FontFamilyPlayfairDisplayProps) => (
  <FontFamilyPlayfairDisplaySpan theme={{ fontWeight }}>
    {children}
  </FontFamilyPlayfairDisplaySpan>
);
export default FontFamilyPlayfairDisplay;

// IService
import IDebitCardRequestCherryVisa01QueryService from '../../../../useCases/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/IDebitCardRequestCherryVisa01QueryService';

// IRepository
import { IDebitCardRequestCherryVisa01QueryRepository } from '../../../../domain/debitCard/debitCardRequest/IDebitCardRequestCherryVisa01Repository';

class DebitCardRequestCherryVisa01QueryService
  implements IDebitCardRequestCherryVisa01QueryService {
  private debitCardRequestCherryVisa01QueryRepository: IDebitCardRequestCherryVisa01QueryRepository;

  constructor(
    debitCardRequestCherryVisa01QueryRepository: IDebitCardRequestCherryVisa01QueryRepository,
  ) {
    this.debitCardRequestCherryVisa01QueryRepository = debitCardRequestCherryVisa01QueryRepository;
  }

  public findById = async (id: string) => {
    const debitCardRequest = await this.debitCardRequestCherryVisa01QueryRepository.findById(
      id,
    );

    return debitCardRequest;
  };

  public findByIdWithProofOfIdentityFiles = async (id: string) => {
    const debitCardRequest = await this.debitCardRequestCherryVisa01QueryRepository.findByIdWithProofOfIdentityFiles(
      id,
    );

    return debitCardRequest;
  };

  public findByIdWithProofOfReceiptFiles = async (id: string) => {
    const debitCardRequest = await this.debitCardRequestCherryVisa01QueryRepository.findByIdWithProofOfReceiptFiles(
      id,
    );

    return debitCardRequest;
  };
}

export default DebitCardRequestCherryVisa01QueryService;

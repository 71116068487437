// Type
import { TimeDepositProps } from '../../../domain/product/timeDeposit/TimeDeposit/TimeDeposit.type';
import { UseStateType } from '../../../types/typeof/UseState';

// Firestore
import TimeDepositsCollection from '../../../infrastructure/firebase/firestore/collections/product/timeDeposits/TimeDepositsCollection';
import firestore from '../../../infrastructure/firebase/firestore/client/firestoreClient';

// IRepository
import ITimeDepositRepository from '../../../domain/product/timeDeposit/ITimeDepositRepository';

// DomainObject
import TimeDeposits from '../../../domain/product/timeDeposit/TimeDeposits/TimeDeposits';
import TimeDepositFactory from '../../../domain/product/timeDeposit/TimeDeposit/TimeDepositFactory';

// Error
import InvalidDataFoundError from '../../../utils/errors/InvalidDataFoundError';

class TimeDepositFirestore implements ITimeDepositRepository {
  public subscribeAll = async (
    setter: UseStateType<TimeDeposits>,
    setState: UseStateType<string>,
  ) => {
    const converter = (docs: firestore.DocumentSnapshot[]) => {
      // TimeDeposits
      return docs.reduce((timeDeposits, doc) => {
        const timeDeposit = TimeDepositFactory.create(
          doc.id,
          doc.data() as TimeDepositProps,
        );

        timeDeposits.add(timeDeposit);

        return timeDeposits;
      }, new TimeDeposits());
    };

    new TimeDepositsCollection().subscribeAll(setter, converter, setState);
  };

  public findAll = async (): Promise<TimeDeposits> => {
    const docs = await new TimeDepositsCollection().fetchAll();

    if (docs.length === 0)
      throw new InvalidDataFoundError(`TimeDeposits data is not found.`);

    return docs.reduce<TimeDeposits>((timeDeposits, doc) => {
      timeDeposits.add(
        TimeDepositFactory.create(doc.id, {
          ...(doc.data() as TimeDepositProps),
        }),
      );
      return timeDeposits;
    }, new TimeDeposits());
  };
}
export default TimeDepositFirestore;

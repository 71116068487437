import React, { FC } from 'react';
import fontColor from '../../../../../fonts/color.json';
import { DescriptionSubRegular22 } from '../../DescriptionSub';
import { DomainDescriptionProps } from '../DomainDescription.type';

const DescriptionSettings: FC<DomainDescriptionProps> = ({
  lines = [],
  color = fontColor.textDark,
  marginBottom = `${16 / 16}rem`,
}: DomainDescriptionProps) => {
  return (
    <DescriptionSubRegular22
      lines={lines}
      color={color}
      marginBottom={marginBottom}
    />
  );
};

export default DescriptionSettings;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;
export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

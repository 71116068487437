// Lib
import { convertMoneyRoundDown } from '../../../../../../utils/helpers/currencyHelper';
import {
  addByDecimal,
  minusByDecimal,
  timesByDecimal,
} from '../../../../../../utils/helpers/calculationHelper';

// Type
import { FiatCurrencyType } from '../../../../../../types/FiatCurrency.type';

// Constant
import { FEE_RATE_TOP_UP_DEBIT_CARD } from '../../../../../../config/constants/app';

export const calculateTopUpFee = (
  totalToppedUpAmount: number,
  maxAmountTotalContractTimeDeposit: number,
  topUpAmount: number,
  currency: FiatCurrencyType,
) => {
  const totalToppedUpAmountUpdated = addByDecimal(
    totalToppedUpAmount,
    topUpAmount,
  );

  if (totalToppedUpAmountUpdated <= maxAmountTotalContractTimeDeposit) return 0;

  return convertMoneyRoundDown(
    timesByDecimal(
      // (total > max) => totalToppedUpAmountUpdated - maxAmountT
      Math.min(
        minusByDecimal(
          totalToppedUpAmountUpdated,
          maxAmountTotalContractTimeDeposit,
        ),
        Number(topUpAmount || 0),
      ),
      FEE_RATE_TOP_UP_DEBIT_CARD,
    ),
    currency,
  );
};

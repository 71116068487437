// IService
import IKycRequestUpdateService from '../../../useCases/verification/kycRequest/IKycRequestUpdateService';

// IRepository
import { IKycRequestUpdateRepository } from '../../../domain/verification/kycRequest/KycRequest/IKycRequestRepository';

// DomainObject
import KycRequest from '../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequest';
import KycRequestCorporate from '../../../domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporate';

// Value
import ShippingAddress from '../../../value/personalInformation/address/Address/ShippingAddress/ShippingAddress';

class KycRequestUpdateService implements IKycRequestUpdateService {
  private kycRequestUpdateRepository: IKycRequestUpdateRepository;

  constructor(kycRequestUpdateRepository: IKycRequestUpdateRepository) {
    this.kycRequestUpdateRepository = kycRequestUpdateRepository;
  }

  public updateCorporateInformation = async (
    kycRequest: KycRequestCorporate,
  ) => {
    await this.kycRequestUpdateRepository.updateCorporateInformation(
      kycRequest,
    );

    return kycRequest;
  };

  public updateCorporateAddress = async (
    kycRequest: KycRequestCorporate,
    shippingAddress: ShippingAddress,
  ) => {
    await this.kycRequestUpdateRepository.updateCorporateAddress(
      kycRequest,
      shippingAddress,
    );

    return kycRequest;
  };

  public updatePersonalInformation = async (kycRequest: KycRequest) => {
    await this.kycRequestUpdateRepository.updatePersonalInformation(kycRequest);

    return kycRequest;
  };

  public updateProofOfResidency = async (
    kycRequest: KycRequest,
    shippingAddress: ShippingAddress,
  ) => {
    await this.kycRequestUpdateRepository.updateProofOfResidency(
      kycRequest,
      shippingAddress,
    );

    return kycRequest;
  };
}

export default KycRequestUpdateService;

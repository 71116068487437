import React, { FC } from 'react';
import { Container } from './style';
import UnderBarTabItem from '../../../../item/TabItem/UnderBarTabItem';
import { TextThemeProps } from '../../../../../atoms/text2/Text2';
import { convertRem } from '../../../../../atoms/utils/helper/helper';

export interface UnderBarTabBarProps {
  height: string | number;
  items: Array<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (type: any) => void;
  selected?: string;
  theme: {
    text: TextThemeProps;
    selected: {
      borderBottom: string;
    };
    unSelected: {
      opacity: number;
    };
  };
  widthItem: string | number;
}

const UnderBarTabBar: FC<UnderBarTabBarProps> = ({
  height,
  items,
  onClick,
  selected,
  theme,
  widthItem,
}: UnderBarTabBarProps) => {
  return (
    <Container style={{ height: convertRem(height) }}>
      {items.map((item, i) => {
        return (
          <UnderBarTabItem
            key={`items${i}`}
            item={item}
            onClick={onClick}
            selected={selected}
            theme={theme}
            width={widthItem}
          />
        );
      })}
    </Container>
  );
};

export default UnderBarTabBar;

import React from 'react';
import SignUpRequest from './SignUpRequest';

export interface SignUpRequestContextProps {
  signUpRequest?: SignUpRequest;
}

const SignUpRequestContext = React.createContext<SignUpRequestContextProps>({
  signUpRequest: undefined,
});

export default SignUpRequestContext;

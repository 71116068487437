import Joi from '@hapi/joi';

// Type
import { UserProps } from '../../User/User.type';
import {
  UserVerifiedProps,
  UserVerifiedPropsFormat,
} from './UserVerified.type';

// Domain Object
import User from '../../User/User';
import UserAuthorized from '../UserAuthorized/UserAuthorized';
import UserVerified from './UserVerified';

// Error
import InvalidDataFoundError from '../../../../../../utils/errors/InvalidDataFoundError';

class UserVerifiedFactory {
  static create = (id: string, props: UserProps) => {
    const result = Joi.object(UserVerifiedPropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw new InvalidDataFoundError(
        `Creation of UserVerified is rejected. The reason is ${result.error.details[0].message}`,
      );

    return new UserVerified(id, props as UserVerifiedProps);
  };

  static createByUserAuthorized = (user: UserAuthorized) => {
    return UserVerifiedFactory.create(user.getId(), user.getProps());
  };

  static createByUser = (user: User) => {
    return UserVerifiedFactory.create(user.getId(), user.getProps());
  };
}

export default UserVerifiedFactory;

import React, { FC } from 'react';
import { Container } from './style';
import Box from '../../utils/layout/Box/Box';

export interface ProgressStepCircleBridgeProps {
  theme: {
    backgroundColor: string;
  };
  width: string | number;
  height: string | number;
  bridgeBorderWidth: string | number;
}

const ProgressStepCircleBridge: FC<ProgressStepCircleBridgeProps> = ({
  theme,
  width,
  height,
  bridgeBorderWidth,
}: ProgressStepCircleBridgeProps) => {
  return (
    <Container width="100%" height={height}>
      <Box theme={theme} width={width} height={bridgeBorderWidth} />
    </Container>
  );
};
export default ProgressStepCircleBridge;

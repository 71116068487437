import React from 'react';
import TransferDebitCardTransactionV2 from './TransferDebitCardTransactionV2';

export interface TransferDebitCardTransactionV2ContextProps {
  transferDebitCardTransaction?: TransferDebitCardTransactionV2;
}

const TransferDebitCardTransactionV2Context = React.createContext<
  TransferDebitCardTransactionV2ContextProps
>({
  transferDebitCardTransaction: undefined,
});

export default TransferDebitCardTransactionV2Context;

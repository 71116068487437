import SerialNumber from '../ProductSerialNumber';
import { ProductSerialNumberType } from '../../../../../../types/ProductSerialNumber.type';

class SerialNumberSavingsAccount extends SerialNumber {
  public createNextNumber = () => {
    const newId = `0000000${Number(this.serialNumber) + 1}`.slice(-7);

    return new SerialNumberSavingsAccount(this.type, {
      prefix: this.prefix,
      serialNumber: newId,
    });
  };

  public getFormattedId = () => {
    return `${this.prefix}-${this.serialNumber}`;
  };

  public getRawId = () => {
    return `${this.prefix}${this.serialNumber}`;
  };

  static createNumberFromRawId = (rawId: string) => {
    return new SerialNumberSavingsAccount(
      ProductSerialNumberType.savingsAccount,
      {
        prefix: rawId.substr(0, 3),
        serialNumber: rawId.substr(3, 7),
      },
    );
  };
}
export default SerialNumberSavingsAccount;

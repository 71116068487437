import Joi from '@hapi/joi';
import {
  AddressPropsAllowEmptyFormat,
  AddressInitialProps,
} from '../../../../personalInformation/address/Address/Address/Address.type';
import {
  CorporateAddressDocumentType,
  KycStatusType,
} from '../../../../../types/KycRequest.type';
import {
  KycRequestContentProps,
  KycRequestContentPropsFormat,
} from '../KycRequestContent/KycRequestContent.type';

export interface CorporateAddressProps extends KycRequestContentProps {
  country: string;

  addressLine1: string;

  addressLine2?: string;

  city: string;

  state: string;

  postalCode: string;

  document: {
    type: CorporateAddressDocumentType;

    documentUrl1: string;
  };
}

export const CorporateAddressPropsFormat = {
  ...KycRequestContentPropsFormat,

  ...AddressPropsAllowEmptyFormat,

  document: Joi.object({
    type: Object.entries(CorporateAddressDocumentType)
      .reduce((joi, [, value]) => joi.valid(value), Joi.string())
      .allow(''),

    documentUrl1: Joi.string().required().allow(''),
  }),
};

export const CorporateAddressInitialProps = {
  ...AddressInitialProps,

  document: {
    type: CorporateAddressDocumentType.utilityBill,

    documentUrl1: '',
  },

  status: KycStatusType.disabled,
};

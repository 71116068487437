import React, { FC } from 'react';
import { Container, ButtonWrap } from './style';
// import SelectorToggleButton from '../../../atoms/select/SelectorToggleButton';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HeaderAuthPageLanguageSelectorProps {}

// eslint-disable-next-line no-empty-pattern
const HeaderAuthPageLanguageSelector: FC<HeaderAuthPageLanguageSelectorProps> = ({}: HeaderAuthPageLanguageSelectorProps) => (
  <Container>
    <ButtonWrap>
      {/* <SelectorToggleButton
        name="language"
        onClick={() => {
          // eslint-disable-next-line no-alert
          alert('言語切替!!!');
        }}
      /> */}
    </ButtonWrap>
  </Container>
);

export default HeaderAuthPageLanguageSelector;

import React, { FC } from 'react';
import { Container } from './style';
import fontSize from '../../../../fonts/fontSizePixel.json';
import fontColor from '../../../../fonts/color.json';
import fontWeight from '../../../../fonts/fontWeight.json';
import TextPrimary from '../../../atoms/text/TextPrimary';
import BreadcrumbActionLink from '../../navigation/BreadcrumbActionLink';

export interface TitleWithActionLinkProps {
  title: string;
  titlePixel?: number;
  titleColor?: string;
  titleFontWeight?: number;
  linkText: string;
  linkTextPixel?: number;
  linkTextColor?: string;
  linkTextFontWeight?: number;
  onClick: () => void;
}

const TitleWithActionLink: FC<TitleWithActionLinkProps> = ({
  title,
  titlePixel = fontSize.pixel33,
  titleColor = fontColor.pageTitle,
  titleFontWeight = fontWeight.bold,
  linkText,
  linkTextPixel = fontSize.pixel18,
  linkTextColor = fontColor.textDark,
  linkTextFontWeight = fontWeight.regular,
  onClick,
}: TitleWithActionLinkProps) => (
  <Container>
    <TextPrimary
      pixel={titlePixel}
      color={titleColor}
      fontWeight={titleFontWeight}
    >
      {title}
    </TextPrimary>
    <BreadcrumbActionLink
      text={linkText}
      textPixel={linkTextPixel}
      textColor={linkTextColor}
      textFontWeight={linkTextFontWeight}
      onClick={onClick}
    />
  </Container>
);

export default TitleWithActionLink;

import React from 'react';

export interface CardShippingInformationModalContextProps {
  showModal: () => void;
  hideModal: () => void;
}

const CardShippingInformationModalContext = React.createContext<
  CardShippingInformationModalContextProps
>({
  showModal: () => undefined,
  hideModal: () => undefined,
});

export default CardShippingInformationModalContext;

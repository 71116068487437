import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { Container, TextInputContainer, ErrorMessageContainer } from './style';
import { convertRem } from '../../../atoms/utils/helper/helper';

export interface TextFieldLayoutProps {
  children: React.ReactElement[];
  textInputWidth: string | number;
}

const TextFieldLayout: FC<TextFieldLayoutProps> = ({
  children,
  textInputWidth,
}: TextFieldLayoutProps) => {
  const elements = ['textInput', 'errorMessage'];
  const [textInput, errorMessage] = getElementsFromKeys(children, elements);

  return (
    <Container>
      <TextInputContainer style={{ width: convertRem(textInputWidth) }}>
        {textInput}
      </TextInputContainer>
      <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
    </Container>
  );
};
export default TextFieldLayout;

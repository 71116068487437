// Error
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';

// Type
import { contractTimeDepositSeedInitialProps } from '../../../domain/contract/contractTimeDeposit/ContractTimeDepositSeed/ContractTimeDepositSeed.type';

// Constant
import { DATE_FORMAT } from '../../../config/constants/business';

// IUseCase
import { ContractTimeDepositCreateParameter } from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositCreateProvider/IContractTimeDepositCreateUseCase';

// IService
import IContractTimeDepositCreateService from '../../../useCases/contract/contractTimeDeposit/interface/IContractTimeDepositCraeteService';

// IRepository
import { IContractTimeDepositCreateRepository } from '../../../domain/contract/contractTimeDeposit/IContractTimeDepositRepository';

// DomainObject
import ContractTimeDepositSeed from '../../../domain/contract/contractTimeDeposit/ContractTimeDepositSeed/ContractTimeDepositSeed';
import ContractTimeDepositSeedFactory from '../../../domain/contract/contractTimeDeposit/ContractTimeDepositSeed/ContractTimeDepositSeedFactory';

// ValueObject
import TimeZonedTime from '../../../value/datetime/TimeZonedTime';

class ContractTimeDepositCreateService
  implements IContractTimeDepositCreateService {
  private contractTimeDepositCreateRepository: IContractTimeDepositCreateRepository;

  constructor(
    contractTimeDepositCreateRepository: IContractTimeDepositCreateRepository,
  ) {
    this.contractTimeDepositCreateRepository = contractTimeDepositCreateRepository;
  }

  public create = (params: ContractTimeDepositCreateParameter) => {
    const {
      amount,
      savingsAccount,
      timeDeposits,
      timeDeposit,
      user,
      isAnnualPayOut,
    } = params;

    const { term } = timeDeposit.getProps();
    const { type } = user.getProps();

    const timeDepositBestRate = timeDeposits.getBestRateProduct(
      term,
      Number(amount),
      type,
      isAnnualPayOut,
    );

    if (!timeDepositBestRate)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.TIME_DEPOSIT_PRODUCT_NO_MATCH_PRODUCT,
      );

    const depositDateTZ = new TimeZonedTime();
    const maturityDateTZ = depositDateTZ.addMonth(term);

    return ContractTimeDepositSeedFactory.create({
      ...contractTimeDepositSeedInitialProps,
      userId: user.getId(),
      savingsAccountNumber: savingsAccount.getRawAccountNumber(),
      depositAmount: Number(amount),
      commissionRate: timeDepositBestRate.getProps().commissionRate,
      term,
      interestRate: timeDepositBestRate.getProps().interestRate,
      depositDate: depositDateTZ.format(DATE_FORMAT),
      maturityDate: maturityDateTZ.format(DATE_FORMAT),
      bankTransactionCommonId: 'tmpId',
      isAnnualPayOut,
    });
  };

  public executeCreate = async (
    contractTimeDepositSeed: ContractTimeDepositSeed,
  ) => {
    const contractTimeDeposit = await this.contractTimeDepositCreateRepository.create(
      contractTimeDepositSeed,
    );
    return contractTimeDeposit;
  };
}

export default ContractTimeDepositCreateService;

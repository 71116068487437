// Lib
import { padStart } from 'lodash';

// Error
import SystemErrorFactory from '../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../errors/ErrorMessage/ErrorMessage';

// Constant
import { ROUND_DOWN_TIME_DEPOSIT_AMOUNT } from '../../config/constants/business';

// Function
import { timesByDecimal, divideByDecimal } from './calculationHelper';

/**
 *
 *  function roundDown
 *
 *  Number
 *    Return number rounded down by divide
 *    ex) roundDown(5500,1000) => 5000
 *
 */
export const roundDown = (baseNumber: number, divide: number): number => {
  // eslint-disable-next-line radix
  return timesByDecimal(
    Math.floor(divideByDecimal(baseNumber, divide)),
    divide,
  );
};

/**
 *
 * function roundDownTimeDepositAmount
 *
 * return
 *   string (str) after fullWidth characters are removed
 *
 */
export const roundDownTimeDepositAmount = (number: number): number => {
  return roundDown(number, ROUND_DOWN_TIME_DEPOSIT_AMOUNT);
};

/**
 *
 * function zeroPadding
 *
 * return
 *   string : string after zeroPadding
 *
 */
export const zeroPadding = (number: number, digit: number): string => {
  return padStart(String(number), digit, '0');
};

/**
 *
 * function commaSeparatedFormat
 *
 * return
 *   string : number separated by common per 3 digits
 *
 */
export const commaSeparatedFormat = (
  number: number,
  isRounded?: boolean,
): string =>
  isRounded
    ? number.toLocaleString()
    : number.toLocaleString(undefined, { maximumSignificantDigits: 20 });

/**
 *
 * function commaSeparatedToNumber
 *
 * return number (not commaSeparated)
 *
 */
export const commaSeparatedToNumber = (numString: string): number => {
  const num = numString.replace(/,/g, '');

  if (Number.isNaN(Number(num)))
    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_NUM_STRING,
      {
        numString,
      },
    );

  return Number(num);
};

/**
 *
 * function getDecimalDigit
 *
 * return number (digit)
 *
 */
export const getDecimalDigit = (number: number) => {
  const numbers = String(number).split('.');

  return numbers[1] ? numbers[1].length : 0;
};

// React
import React, { FC, useContext } from 'react';
import DebitCardRequestsContext from '../../../../domain/debitCard/debitCardRequest/DebitCardRequest/DebitCardRequests/DebitCardRequestsContext';
import SavingsAccountsContext from '../../../../domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccountsContext';

// Layout
import Margin from '../../../atoms/utils/layout/Margin';

// Components
import SavingsAccount from '../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';
import SavingsAccountCard from '../../card/bankAccount/savingsAccount/SavingsAccountCard';

const SavingsAccountCardList: FC = () => {
  const { savingsAccounts } = useContext(SavingsAccountsContext);
  const { debitCardRequests } = useContext(DebitCardRequestsContext);

  const getListItem: FC<{ item: SavingsAccount; i: number }> = ({
    item,
    i,
  }) => {
    const debitCardRequestsFiltered = debitCardRequests.filterBySavingsAccountNumber(
      item.getRawAccountNumber(),
    );

    return (
      <Margin bottom={50} key={`item${i}`}>
        <SavingsAccountCard
          savingsAccount={item}
          debitCardRequests={debitCardRequestsFiltered}
        />
      </Margin>
    );
  };

  return <>{savingsAccounts.mapComponent(getListItem)}</>;
};

export default SavingsAccountCardList;

import { useCallback, useState } from 'react';

// Error
import ErrorHandler from '../../../../../../../utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from '../../../../../../../utils/errors/ErrorHandler/IErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../../../../utils/helpers/connection';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../../../config/constants/requestState';

// Repository
import DebitCardRequestCherryVisa01Functions from '../../../../../../../repository/debitCard/debitCardRequest/DebitCardRequestCherryVisa01Functions';

// UseCase
import DebitCardRequestCherryVisa01PayIssuanceFeeUseCase from '../../../../../../../useCases/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01PayIssuanceFeeUseCase';

// DomainObject
import DebitCardRequestCherryVisa01 from '../../../../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01';
import UserAuthorized from '../../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

// File

export const useDebitCardRequestCherryVisa01PayIssuanceFeeUseCase = (
  debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01 | undefined,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /**
   *
   *  Repository
   *
   */
  const debitCardRequestCherryVisa01Functions = new DebitCardRequestCherryVisa01Functions();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new DebitCardRequestCherryVisa01PayIssuanceFeeUseCase(
    debitCardRequestCherryVisa01Functions,
  );

  /**
   *
   *  Method
   *
   */
  const submit = useCallback(
    async (callback?: () => void) => {
      if (!userAuthorized || !debitCardRequestCherryVisa01) return;

      setState(IS_LOADING);

      try {
        // ---check onLine ---- //
        checkInternetConnection();

        await useCase.executeUpdate(debitCardRequestCherryVisa01);

        setState(SUCCESS);

        if (callback) callback();
      } catch (error) {
        const handler: IErrorHandler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [userAuthorized, debitCardRequestCherryVisa01],
  );

  return {
    state,
    setState,
    submit,
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { required } from '../validator';

const authenticatorCodeInputRegister = (field: string, t: any) => {
  switch (field) {
    case 'code':
      return {
        required: required('Code', t),
      };
    default:
      return undefined;
  }
};

export { authenticatorCodeInputRegister };

import { CryptoCurrencyType } from '../../../../types/CryptoCurrency.type';
import { CryptoCurrencyTransactionIdProps } from './CryptoCurrencyTransactionId.type';
import TransactionId from '../TransactionId/TransactionId';

class CryptoCurrencyTransactionId extends TransactionId {
  private currency: CryptoCurrencyType;

  constructor(props: CryptoCurrencyTransactionIdProps) {
    super(props);
    this.currency = props.currency;
  }

  protected getPropsOrderForId = () => [
    this.prefix,
    this.currency,
    this.date,
    this.serialNumber.getSerialNumber(),
    this.target.substr(-6),
  ];

  static createNumberFromRawId = (rawId: string) => {
    return new CryptoCurrencyTransactionId({
      prefix: rawId.substr(0, 3),
      currency: rawId.substr(3, 3) as CryptoCurrencyType,
      date: rawId.substr(6, 8),
      serialNumber: rawId.substr(14, 6),
      target: rawId.substr(20, 6),
      commonId: '',
    });
  };
}
export default CryptoCurrencyTransactionId;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useMemo } from 'react';
import fontSize from '../../../../fonts/fontSizePixel.json';
import fontColor from '../../../../fonts/color.json';
import fontWeight from '../../../../fonts/fontWeight.json';
import {
  LabelContainer,
  InputWithError,
  InputContainer,
  ErrorMessageContainer,
} from './style';
import Display from '../../../atoms/div/Display';
import TextFonted from '../../../atoms/text/TextFonted';
import TextInput from '../../../atoms/form/TextInput';
import { TextErrorMessage } from '../../../atoms/text/DomainText';

export interface TextFieldProps {
  name: string;
  value?: string;
  type?: 'number' | 'password' | 'tel' | 'text';
  pattern?: string;
  label?: string;
  labelColor?: string;
  labelPixel?: number;
  labelFontFamily?: string;
  labelFontWeight?: number;
  labelFontFamilyType?: 'primary' | 'sub';
  labelLetterSpacing?: string;
  placeholder?: string;
  maxLength: number;
  errorMessage?: string;
  disabled?: boolean;
  disabledBackgroundColor?: string;
  textInputWidth?: string | number;
  register?: any;
  onChange?: (text: string) => void;
}

const TextField: FC<TextFieldProps> = ({
  name = '',
  value = '',
  type = 'text',
  pattern,
  label = '',
  labelColor = fontColor.textBright,
  labelPixel = fontSize.pixel22,
  labelFontWeight = fontWeight.regular,
  labelFontFamilyType = 'primary' as 'primary' | 'sub',
  placeholder = '',
  maxLength = 50,
  errorMessage = '',
  disabled = false,
  disabledBackgroundColor = '#DBDDE8',
  textInputWidth = `${400 / 16}rem`,
  register = null,
  onChange = () => undefined,
}: TextFieldProps) => {
  const displayErrMsg = useMemo(() => errorMessage || '', [errorMessage]);

  return (
    <div>
      <Display isDisplay={!!label}>
        <LabelContainer>
          <TextFonted
            pixel={labelPixel}
            color={labelColor}
            fontWeight={labelFontWeight}
            fontFamilyType={labelFontFamilyType}
          >
            {label}
          </TextFonted>
        </LabelContainer>
      </Display>
      <InputWithError style={{ width: textInputWidth }}>
        <InputContainer>
          <TextInput
            name={name}
            type={type}
            value={value}
            pattern={pattern}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            disabledBackgroundColor={disabledBackgroundColor}
            register={register}
            onChange={onChange}
          />
        </InputContainer>
        <ErrorMessageContainer>
          <TextErrorMessage>{displayErrMsg}</TextErrorMessage>
        </ErrorMessageContainer>
      </InputWithError>
    </div>
  );
};

export default TextField;

import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const PayOutBadgeStyle = {
  fontSize: 18,
  color: color.text.primary,
  fontWeight: fontWeight.medium,
};

export const PayOutBadgeLayout = {
  border: `solid ${color.text.primary} 1px`,
  borderRadius: 3,
};

import React, { FC } from 'react';
import ApprovedImage from '../../../../assets/images/approved.png';
import WaitingImage from '../../../../assets/images/waiting.png';
import ReviewImage from '../../../../assets/images/reviewing.png';
import RejectedImage from '../../../../assets/images/rejected.png';

// Type
import { DebitCardRequestCherryVisa01StepStatusType } from '../../../../types/DebitCardRequestCherryVisa01.type';

export interface DebitCardRequestStatusIconProps {
  status?: DebitCardRequestCherryVisa01StepStatusType;
  width?: string | number;
  height?: string | number;
}

const DebitCardRequestStatusIcon: FC<DebitCardRequestStatusIconProps> = ({
  status,
  width = '100%',
  height = '100%',
}: DebitCardRequestStatusIconProps) => {
  switch (status) {
    case DebitCardRequestCherryVisa01StepStatusType.approved:
    case DebitCardRequestCherryVisa01StepStatusType.done:
      return <img src={ApprovedImage} alt="✓" width={width} height={height} />;
    case DebitCardRequestCherryVisa01StepStatusType.waiting:
      return <img src={WaitingImage} alt="-" width={width} height={height} />;
    case DebitCardRequestCherryVisa01StepStatusType.reviewing:
      return <img src={ReviewImage} alt="|_" width={width} height={height} />;
    case DebitCardRequestCherryVisa01StepStatusType.rejected:
      return <img src={RejectedImage} alt="x" width={width} height={height} />;
    default:
      return <></>;
  }
};
export default DebitCardRequestStatusIcon;

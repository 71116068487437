import { useState } from 'react';

// DomainObject
import TransferDebitCardTransactionV2s from './TransferDebitCardTransactionV2s';

export const useTransferDebitCardTransactionV2s = (
  defaultValue?: TransferDebitCardTransactionV2s,
) => {
  const [
    transferDebitCardTransactions,
    setTransferDebitCardTransactions,
  ] = useState<TransferDebitCardTransactionV2s>(
    defaultValue || new TransferDebitCardTransactionV2s(),
  );

  return {
    transferDebitCardTransactions,
    setTransferDebitCardTransactions,
  };
};

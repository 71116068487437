import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

// OriginalLib
import { TextSecondary } from 'components/atoms/text2/Text2';
import { deleteFullWidth } from '../../../../../../utils/helpers/stringHelper';
import { personalInformationValidation } from '../../../../../../utils/validation/registers';

// Constant
import {
  MAX_YEAR_PASSPORT_EXPIRED,
  MIN_YEAR_PASSPORT_ISSUED,
  MIN_DAYS_PASSPORT_EXPIRED,
} from '../../../../../../config/constants/business';

// Style
import { DescriptionStyle } from './style';

// Component
import DebitCardRequestFormContainer from '../../../common/DebitCardRequestFormContainer';
import FormInputText from '../../../../../molecules/form/formInput/textField/FormInputText';
import FormInputDateSelectBox from '../../../../../molecules/form/formInput/selectBox/verification/FormInputDateSelectBox';
import FormPassportInputLayout from './FormPassportInputLayout';

const FORM_ID = `organisms.form.debitCards.FormProofOfIdentity.passport`;

const FormPassportInput: FC = () => {
  const { t } = useTranslation();
  const { register, setValue } = useFormContext();

  return (
    <DebitCardRequestFormContainer order={4} formId={FORM_ID}>
      <FormPassportInputLayout>
        <FormInputText
          key="passportNo"
          name="passportNo"
          inputWidth={400}
          label={t(`${FORM_ID}.passportNo.label`)}
          maxLength={20}
          onChange={(text: string) => {
            setValue('passportNo', deleteFullWidth(text));
          }}
          placeholder={t(`${FORM_ID}.passportNo.placeholder.passportNo`)}
          register={register(personalInformationValidation(t).passportNo)}
        />
        <FormInputDateSelectBox
          key="issuedDate"
          name="issuedDate"
          labelWidth={200}
          label={t(`${FORM_ID}.issuedDate.label`)}
          dateStart={{
            year: MIN_YEAR_PASSPORT_ISSUED,
            month: 0,
            day: 0,
          }}
          dateEnd={{
            year: 0,
            month: 0,
            day: 0,
          }}
        />
        <FormInputDateSelectBox
          key="expiredDate"
          name="expiredDate"
          labelWidth={200}
          label={t(`${FORM_ID}.expiredDate.label`)}
          dateStart={{
            year: MAX_YEAR_PASSPORT_EXPIRED,
            month: 0,
            day: 0,
          }}
          dateEnd={{
            year: 0,
            month: 0,
            day: MIN_DAYS_PASSPORT_EXPIRED,
          }}
        />
        <TextSecondary key="expiredDateDescription" theme={DescriptionStyle}>
          {t(`${FORM_ID}.expiredDate.description`)}
        </TextSecondary>
      </FormPassportInputLayout>
    </DebitCardRequestFormContainer>
  );
};

export default FormPassportInput;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ConsentFormDataContext from '../../../../enhancers/verification/consentForm/ConsentFormData/ConsentFormDataContext';
import { Container } from './style';
import { PageTitle } from '../../../atoms/headLine/HeadLine';

import { DescriptionSettings } from '../../../molecules/text/DomainDescription';
import { ConsentFormDoc } from '../../../../utils/firebase/firestore/verification/ConsentForm.type';
import { ConsentFormViewer } from '../../../organisms/viewer/PDFPageViewer';

const ConsentFormSettingTemplate: FC = () => {
  const { t } = useTranslation();
  const { consentFormData } = useContext(ConsentFormDataContext);

  if (!consentFormData) return <></>;

  const definedConsentFormData = consentFormData as ConsentFormDoc;
  const { documentUrl } = definedConsentFormData.consentForm;

  return (
    <Container>
      <PageTitle>{t(`userSettings.ConsentFormSetting.title`)}</PageTitle>
      <div style={{ marginTop: `${50 / 16}rem` }}>
        <DescriptionSettings
          lines={[t(`userSettings.ConsentFormSetting.description.line1`)]}
        />
      </div>
      <div style={{ marginTop: `${50 / 16}rem` }}>
        <ConsentFormViewer filepath={documentUrl} />
      </div>
    </Container>
  );
};
export default ConsentFormSettingTemplate;

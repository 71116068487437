import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import { MaintenanceProps, MaintenancePropsFormat } from './Maintenance.type';

// DomainObject
import Maintenance from './Maintenance';

class MaintenanceFactory {
  static create = (props: MaintenanceProps) => {
    const result = Joi.object(MaintenancePropsFormat).validate(props);

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: Maintenance.name,
          reason: result.error.details[0].message,
        },
      );

    return new Maintenance(props);
  };
}

export default MaintenanceFactory;

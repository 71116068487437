// Type
import { TimeDepositOptionObject } from '../option/TimeDepositOption/TimeDepositOption.type';

// DomainObject
import TimeDeposits from '../../../../../domain/product/timeDeposit/TimeDeposits/TimeDeposits';
import TimeDeposit from '../../../../../domain/product/timeDeposit/TimeDeposit/TimeDeposit';

class TimeDepositsOptionsMapper {
  static domainToOption = (timeDeposits: TimeDeposits) => {
    const getOption = (timeDeposit: TimeDeposit): TimeDepositOptionObject => {
      const props = timeDeposit.getProps();
      const { term, interestRate, minDeposit } = props;

      return {
        label: timeDeposit.getId(),
        value: timeDeposit.getId(),
        term,
        interestRate,
        minDeposit,
      };
    };
    return timeDeposits.map<TimeDepositOptionObject>(getOption);
  };
}

export default TimeDepositsOptionsMapper;

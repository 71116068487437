import { useState } from 'react';
import ResignUpRequest from './ResignUpRequest';

export const useResignUpRequest = () => {
  const [resignUpRequest, setResignUpRequest] = useState<ResignUpRequest>();

  return {
    resignUpRequest,
    setResignUpRequest,
  };
};

import User from '../../User/User';
import { UserAuthorizedProps } from './UserAuthorized.type';

class UserAuthorized extends User {
  protected token: string;

  constructor(id: string, props: UserAuthorizedProps) {
    super(id, props);
    this.token = props.token;
  }

  public getToken = () => this.token;

  public refreshToken = (newToken: string) =>
    new UserAuthorized(this.id, {
      ...this.getProps(),
      token: newToken,
    });
}
export default UserAuthorized;

import React, { FC } from 'react';

// Component
import ContractTimeDepositRenewalReservationAddCard from './ContractTimeDepositRenewalReservationAddCard';
import ContractTimeDepositRenewalReservationEditCard from './ContractTimeDepositRenewalReservationEditCard';

// DomainObject
import ContractTimeDeposit from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';

export interface ContractTimeDepositRenewalReservationCardProps {
  contractTimeDeposit: ContractTimeDeposit;
}

const ContractTimeDepositRenewalReservationCard: FC<ContractTimeDepositRenewalReservationCardProps> = ({
  contractTimeDeposit,
}: ContractTimeDepositRenewalReservationCardProps) => {
  const { renewalReservation } = contractTimeDeposit.getProps();

  if (!renewalReservation)
    return (
      <ContractTimeDepositRenewalReservationAddCard
        contractTimeDeposit={contractTimeDeposit}
      />
    );

  return (
    <ContractTimeDepositRenewalReservationEditCard
      contractTimeDeposit={contractTimeDeposit}
    />
  );
};

export default ContractTimeDepositRenewalReservationCard;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AssetCryptoCurrencyContext from '../../../../../domain/asset/assetCryptoCurrency/AssetCryptoCurrency/AssetCryptoCurrencyContext';
import CryptoCurrencyRateContext from '../../../../../domain/cryptoCurrency/cryptoCurrencyRate/CryptoCurrencyRate/CryptoCurrencyRate/CryptoCurrencyRateContext';

// Type
import { CurrencyType } from '../../../../../types/Currency.type';

// Style
import { LabelStyle, CurrencyAmountStyle } from './style';

// Constant
import {
  BASE_CURRENCY_SYMBOL,
  BASE_CRYPTO_CURRENCY_SYMBOL,
  NO_DATA,
} from '../../../../../config/constants/business';

// Component
import { AssetCryptoCurrencyIconText } from '../../../../molecules/text/IconLabelText';
import { TextPrimary } from '../../../../atoms/text2/Text2';
import CurrencyAmount from '../../../../molecules/textGroup/CurrencyAmount';
import AssetCryptoCurrencyCardLayout from './AssetCryptoCurrencyCardLayout';

const ORGANISM_ID = `organisms.card.AssetCryptoCurrencyCard`;

const AssetCryptoCurrencyCard: FC = () => {
  const { t } = useTranslation();
  const { assetCryptoCurrency } = useContext(AssetCryptoCurrencyContext);
  const { cryptoCurrencyRate } = useContext(CryptoCurrencyRateContext);

  // AssetCryptoCurrency
  const balance =
    assetCryptoCurrency && assetCryptoCurrency?.getProps().single.balance;
  const currency = assetCryptoCurrency?.getCurrency();

  // CryptoCurrencyRate
  const conversionRate =
    cryptoCurrencyRate && cryptoCurrencyRate.getProps().last;

  return (
    <AssetCryptoCurrencyCardLayout>
      <AssetCryptoCurrencyIconText
        key="currency"
        iconType={currency || BASE_CRYPTO_CURRENCY_SYMBOL}
        currencyName={currency || BASE_CRYPTO_CURRENCY_SYMBOL}
      />
      <TextPrimary key="balanceLabel" theme={LabelStyle}>
        {t(`${ORGANISM_ID}.label.balance`)}
      </TextPrimary>
      <CurrencyAmount
        key="balance"
        amount={balance === undefined ? NO_DATA : balance}
        currency={currency || BASE_CRYPTO_CURRENCY_SYMBOL}
        marginBetween={10}
        theme={CurrencyAmountStyle}
      />
      <TextPrimary key="conversionLabel" theme={LabelStyle}>
        {t(`${ORGANISM_ID}.label.conversion`, {
          currency: BASE_CURRENCY_SYMBOL as CurrencyType,
        })}
      </TextPrimary>
      <CurrencyAmount
        key="conversion"
        amount={
          balance === undefined || conversionRate === undefined
            ? NO_DATA
            : balance * conversionRate
        }
        currency={BASE_CURRENCY_SYMBOL as CurrencyType}
        marginBetween={10}
        theme={CurrencyAmountStyle}
      />
    </AssetCryptoCurrencyCardLayout>
  );
};

export default AssetCryptoCurrencyCard;

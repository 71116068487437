import Joi from '@hapi/joi';

// Type
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
import {
  TransferDebitCardType,
  TransferDebitCardStatusType,
} from '../../../../types/Transfer.type';

export interface TransferDebitCardTransactionV2Props {
  debitCardId: string;

  savingsAccountNumber: string;

  amount: number;

  commonId: string;

  currency: FiatCurrencyType;

  fee: number;

  maxTotalTimeDeposit: number;

  totalToppedUpAmount: number;

  rejectedReason?: string;

  status: TransferDebitCardStatusType;

  transferType: TransferDebitCardType;

  createdAt?: number;

  updatedAt?: number;
}

export interface TransferDebitCardTransactionV2RawDataType {
  id: string;

  props: TransferDebitCardTransactionV2Props;
}

export const TransferDebitCardTransactionV2PropsFormat = {
  debitCardId: Joi.string().required(),

  savingsAccountNumber: Joi.string().required(),

  amount: Joi.number().required(),

  commonId: Joi.string().required(),

  currency: Object.keys(FiatCurrencyType)
    .reduce((joi, type) => joi.valid(type), Joi.string())
    .required(),

  fee: Joi.number().min(0).required(),

  maxTotalTimeDeposit: Joi.number().min(0).required(),

  totalToppedUpAmount: Joi.number().min(0).required(),

  rejectedReason: Joi.string().empty(''),

  status: Object.keys(TransferDebitCardStatusType)
    .reduce((joi, type) => joi.valid(type), Joi.string())
    .required(),

  transferType: Object.keys(TransferDebitCardType)
    .reduce((joi, type) => joi.valid(type), Joi.string())
    .required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

// Types
import { CurrencyPairType } from '../../types/CurrencyPair.type';
import { UseStateType } from '../../types/typeof/UseState';

// IRepository
import IConfigCryptoCurrencyTradeFeeRateRepository from '../../domain/settings/cryptoCurrencyTradeFeeRate/ICryptoCurrencyTradeFeeRateRepository';

// Collection
import firestore from '../../infrastructure/firebase/firestore/client/firestoreClient';
import CryptCurrencyTradeFeeCollection from '../../infrastructure/firebase/firestore/collections/settings/CryptoCurrenciesTradeFeeCollection';

// DomainObject
import CryptoCurrencyTradeFeeRate from '../../domain/settings/cryptoCurrencyTradeFeeRate/CryptoCurrencyTradeFeeRate/CryptoCurrencyTradeFeeRate';
import { CryptoCurrencyTradeFeeRateProps } from '../../domain/settings/cryptoCurrencyTradeFeeRate/CryptoCurrencyTradeFeeRate/CryptoCurrencyTradeFeeRate.type';
import CryptoCurrencyTradeFeeRateFactory from '../../domain/settings/cryptoCurrencyTradeFeeRate/CryptoCurrencyTradeFeeRate/CryptoCurrencyTradeFeeRateFactory';

// Others
import InvalidDataFoundError from '../../utils/errors/InvalidDataFoundError';

class CryptoCurrencyTradeFeeRateFirestore
  implements IConfigCryptoCurrencyTradeFeeRateRepository {
  public findById = async (currencyPair: string) => {
    const cryptoCurrenciesTradeFeeRateCollection = new CryptCurrencyTradeFeeCollection();

    const doc = await cryptoCurrenciesTradeFeeRateCollection.fetchSpecific(
      currencyPair,
    );

    if (!doc)
      throw new InvalidDataFoundError(
        `CryptoCurrencyTradeFeeRate '${currencyPair}' is not found.`,
      );

    return CryptoCurrencyTradeFeeRateFactory.create(
      currencyPair as CurrencyPairType,
      doc.data() as CryptoCurrencyTradeFeeRateProps,
    );
  };

  public subscribeById = async (
    currencyPair: string,
    setter: UseStateType<CryptoCurrencyTradeFeeRate | undefined>,
    setState: UseStateType<string>,
  ) => {
    const cryptoCurrenciesTradeFeeRateCollection = new CryptCurrencyTradeFeeCollection();

    const converter = (doc: firestore.DocumentSnapshot) => {
      return CryptoCurrencyTradeFeeRateFactory.create(
        doc.id as CurrencyPairType,
        doc.data() as CryptoCurrencyTradeFeeRateProps,
      );
    };

    return cryptoCurrenciesTradeFeeRateCollection.subscribeSpecific(
      currencyPair,
      setter,
      converter,
      setState,
    );
  };
}
export default CryptoCurrencyTradeFeeRateFirestore;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Error
import { CheckIcon } from 'components/atoms/icon/CheckIcon';

// Constant
import color from 'components/atoms/utils/const/color';

// Style
import {
  IconContainer,
  TextStyle,
} from 'components/molecules/badge/SecureBadge/style';

// Component
import { TextSecondary } from 'components/atoms/text2/Text2';
import SecureBadgeLayout from './SecureBadgeLayout';

const MOLECULES_ID = `molecules.badge.3DSecure`;

const SecureBadge: FC = () => {
  const { t } = useTranslation();

  return (
    <SecureBadgeLayout backgroundColor={color.background.ghostWhite}>
      <IconContainer key="icon">
        <CheckIcon color={color.white} size={18} />
      </IconContainer>
      <TextSecondary
        key="text"
        theme={{
          ...TextStyle,
          color: color.primary,
        }}
      >
        {t(`${MOLECULES_ID}.label`)}
      </TextSecondary>
    </SecureBadgeLayout>
  );
};

export default SecureBadge;

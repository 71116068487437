import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import SavingsAccountHistoryTemplate from '../../../components/templates/savingsAccount/SavingsAccountHistoryTemplate';

const SavingsAccountHistoryPage: FC = () => {
  return (
    <DashboardTemplate>
      <SavingsAccountHistoryTemplate />
    </DashboardTemplate>
  );
};

export default SavingsAccountHistoryPage;

import { useState, useEffect, useMemo } from 'react';
import { checkInternetConnection } from '../../../../utils/helpers/connection';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import CryptoCurrencyDepositWithdrawalViewUseCase from '../../../../useCases/cryptoCurrency/cryptoCurrencyDepositWithdrawal/CryptoCurrencyDepositWithdrawalViewUseCase';

// SelectBox
import { useCryptoCurrencyOption } from '../../../../components/molecules/selectBox/option/option/CryptoCurrencyOption/useCryptoCurrencyOption';
import { useBankTransactionOption } from '../../../../components/molecules/selector/tabBar/UnderBarTabBar/BankTransactionTabBar/useBankTransaction';

// Repository
import AssetCryptoCurrencyFirestore from '../../../../repository/asset/assetCryptoCurrency/AssetCryptoCurrencyFirestore';
import CryptoCurrencyWalletFirestoreFactory from '../../../../repository/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWalletFirestoreFactory';
import CryptoCurrencyDepositFirestore from '../../../../repository/transaction/cryptoCurrencyTransaction/spot/cryptoCurrencyDeposit/CryptoCurrencyDepositFirestore';

// DomainObject
import AssetsCryptoCurrency from '../../../../domain/asset/assetCryptoCurrency/AssetsCryptoCurrency/AssetsCryptoCurrency';
import CryptoCurrencyDeposit from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/CryptoCurrencyDeposit/CryptoCurrencyDeposit';
import CryptoCurrencyWallet from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet';
import UserAuthorized from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useCryptoCurrencyDepositWithdrawalViewUseCase = (
  user?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);

  // SelectBox & Tab
  const {
    cryptoCurrencyOption,
    setCryptoCurrencyOption,
  } = useCryptoCurrencyOption();

  const {
    transactionOption,
    setTransactionOption,
  } = useBankTransactionOption();

  // DomainObject
  const [assetsCryptoCurrency, setAssetsCryptoCurrency] = useState<
    AssetsCryptoCurrency
  >(new AssetsCryptoCurrency());
  const [cryptoCurrencyWallet, setCryptoCurrencyWallet] = useState<
    CryptoCurrencyWallet
  >();
  const [cryptoCurrencyDeposit, setCryptoCurrencyDeposit] = useState<
    CryptoCurrencyDeposit
  >();

  // Repository
  const assetCryptoCurrencyRepository = new AssetCryptoCurrencyFirestore();
  const cryptoCurrencyDepositRepository = new CryptoCurrencyDepositFirestore();
  const cryptoCurrencyWalletRepository = useMemo(
    () => CryptoCurrencyWalletFirestoreFactory.create(cryptoCurrencyOption),
    [cryptoCurrencyOption],
  );

  // UseCase
  const useCase = useMemo(
    () => {
      return new CryptoCurrencyDepositWithdrawalViewUseCase(
        assetCryptoCurrencyRepository,
        cryptoCurrencyWalletRepository,
        cryptoCurrencyDepositRepository,
      );
    },
    // eslint-disable-next-line
    [cryptoCurrencyWalletRepository],
  );

  // UseCase.open
  useEffect(() => {
    const openCryptoCurrencyWallet = async () => {
      setState(IS_LOADING);

      try {
        // Is ONLINE?
        checkInternetConnection();

        // UseCase
        await useCase.open(user!, setAssetsCryptoCurrency, setState);

        setState(SUCCESS);
      } catch (error) {
        const handler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    };
    if (user) openCryptoCurrencyWallet();
  }, [user, useCase, setCryptoCurrencyOption]);

  // UseCase.selectCryptoCurrency
  useEffect(() => {
    const selectCryptoCurrency = async () => {
      setState(IS_LOADING);

      try {
        // Is ONLINE?
        checkInternetConnection();

        // UseCase
        useCase.selectCryptoCurrency(user!, setCryptoCurrencyWallet, setState);

        setState(SUCCESS);
      } catch (error) {
        const handler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    };
    if (user) selectCryptoCurrency();
    // eslint-disable-next-line
  }, [user, cryptoCurrencyOption]);

  // UseCase.subscribeNewCryptoCurrencyDeposit
  useEffect(() => {
    const subscribeCryptoCurrencyDeposit = async () => {
      setState(IS_LOADING);

      try {
        // Is ONLINE?
        checkInternetConnection();

        // UseCase
        useCase.subscribeNewCryptoCurrencyDeposit(
          cryptoCurrencyWallet!,
          setCryptoCurrencyDeposit,
          setState,
        );

        setState(SUCCESS);
      } catch (error) {
        const handler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    };
    if (cryptoCurrencyWallet) subscribeCryptoCurrencyDeposit();
    // eslint-disable-next-line
  }, [cryptoCurrencyWallet]);

  return {
    state,
    setState,
    assetsCryptoCurrency,
    cryptoCurrencyDeposit,
    setCryptoCurrencyDeposit,
    cryptoCurrencyWallet,
    transactionOption,
    setTransactionOption,
    cryptoCurrencyOption,
    setCryptoCurrencyOption,
  };
};

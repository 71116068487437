import React, { useState } from 'react';

export interface FormInputConsentForm {
  documentUrl: string;
}

export const useFormInputConsentForm = () => {
  const [formInput, setFormInput] = useState<FormInputConsentForm>();

  return {
    formInput,
    setFormInput,
  };
};

export const FormInputConsentFormContext = React.createContext<{
  formInput?: FormInputConsentForm;
  setFormInput: (formInput?: FormInputConsentForm) => void;
}>({
  formInput: undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFormInput: (formInput?: FormInputConsentForm) => undefined,
});

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import {
  Container,
  RightContainer,
  Content,
  Main,
  LeftImageContainer,
} from './style';
import Margin from '../../../atoms/utils/layout/Margin';

export interface SignUpPageGroupTemplateLayoutProps {
  children: React.ReactElement[];
}

const SignUpPageGroupTemplateLayout: FC<SignUpPageGroupTemplateLayoutProps> = ({
  children,
}: SignUpPageGroupTemplateLayoutProps) => {
  const elements = ['header', 'leftImage', 'content', 'footer'];
  const [header, leftImage, content, footer] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <Container>
      {header}
      <LeftImageContainer>{leftImage}</LeftImageContainer>
      <Margin left={`${(587 / 1920) * 100}%`} />
      <RightContainer>
        <Content>
          <Main>{content}</Main>
        </Content>
        {footer}
      </RightContainer>
    </Container>
  );
};
export default SignUpPageGroupTemplateLayout;

import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const AnnualFeeLabeledTextStyle = {
  label: {
    fontSize: 18,
    color: color.primary,
    fontWeight: fontWeight.regular,
    opacity: 0.5,
  },
  value: {
    fontSize: 20,
    color: color.primary,
    fontWeight: fontWeight.regular,
  },
};

export const BalanceStyle = {
  fontSize: 33,
  color: color.primary,
  fontWeight: fontWeight.bold,
};

export const ButtonTextStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const CurrencyStyle = {
  fontSize: 33,
  color: color.primary,
  fontWeight: fontWeight.medium,
  opacity: 0.5,
};

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const DescriptionImpactStyle = {
  fontSize: 26,
  color: color.text.froly,
  fontWeight: fontWeight.bold,
};

export const DebitCardImageLayout = {
  body: {
    width: 243,
    height: 150,
  },
  cardNumber: {
    width4digits: '22%',
    height: '23%',
  },
  oneDigit: {
    width: '100%',
    height: '75%',
  },
  cardTypeLogo: {
    width: '30%',
    height: '80%',
  },
  cardValidThruLogo: {
    width: '100%',
    height: '100%',
  },
  cardSlashChar: {
    width: '10%',
    height: '80%',
  },
};

export const IconStyle = {
  color: color.icon.black,
  size: `${22 / 16}rem`,
};

export const HolderNameTextStyle = {
  fontSize: 28,
  color: color.primary,
  fontWeight: fontWeight.regular,
};

export const LabelStyle = {
  fontSize: 24,
  color: color.primary,
  fontWeight: fontWeight.regular,
  opacity: 0.5,
};

export const LinkTextStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  textDecoration: 'underline',
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// Style
import {
  PendingErrorAreaStyle,
  TransactionFeeNoticeContainer,
  PropertyListTransferDebitCardFeeContainer,
  FeeBoxContainerStyle,
} from './style';

// Layout
import Margin from '../../../../../atoms/utils/layout/Margin';
import Box from '../../../../../atoms/utils/layout/Box/Box';
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../../atoms/utils/layout/Box/FlexBox';

export interface FormTransferDebitCardLayoutProps {
  children: React.ReactElement[];
  hasPending: boolean;
  isNotAllowed: boolean;
  isDisplayFeeTable: boolean;
}

const FormTransferDebitCardLayout: FC<FormTransferDebitCardLayoutProps> = ({
  children,
  hasPending,
  isNotAllowed,
  isDisplayFeeTable,
}: FormTransferDebitCardLayoutProps) => {
  const elements = [
    'attention',
    'cardNotice',
    'feesTable',
    'fundSource',
    'amount',
    'pendingError',
    'notAllowedError',
    'button',
    'labelFee',
    'fee',
  ];
  const [
    attention,
    cardNotice,
    feesTable,
    fundSource,
    amount,
    pendingError,
    notAllowedError,
    button,
    labelFee,
    fee,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn alignItems="center">
      <FlexBoxColumn alignItems="start" textAlign="left" padding="0 60">
        {attention}
      </FlexBoxColumn>
      <Margin top={30} />
      <TransactionFeeNoticeContainer>
        {cardNotice}
        {isDisplayFeeTable && (
          <PropertyListTransferDebitCardFeeContainer>
            {feesTable}
          </PropertyListTransferDebitCardFeeContainer>
        )}
      </TransactionFeeNoticeContainer>
      <Margin top={50} />
      {fundSource}
      <Margin top={80} />
      {amount}
      <Margin top={50} />
      <FlexBoxRow width="100%" justifyContent="flex-end" alignItems="center">
        {labelFee}
        <Margin right={25} />
        <FlexBoxRow justifyContent="flex-end" style={FeeBoxContainerStyle}>
          {fee}
        </FlexBoxRow>
      </FlexBoxRow>
      <Margin top={55} />
      <Box
        width="80%"
        height={40}
        padding={5}
        theme={hasPending || isNotAllowed ? PendingErrorAreaStyle : {}}
      >
        {hasPending && <>{pendingError}</>}
        {!hasPending && isNotAllowed && <>{notAllowedError}</>}
      </Box>
      <Margin top={15} />
      {button}
    </FlexBoxColumn>
  );
};

export default FormTransferDebitCardLayout;

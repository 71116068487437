// Type
import { ResetAuthenticatorRequestStatusType } from '../../../../types/UserRequest.type';
import { ResetAuthenticatorRequestProps } from './ResetAuthenticatorRequest.type';

class ResetAuthenticatorRequest {
  protected id: string;

  protected oobCode: string;

  protected accountKey: string;

  protected email: string;

  protected status: ResetAuthenticatorRequestStatusType;

  protected expiredAt: number;

  protected createdAt: number;

  protected updatedAt: number;

  private props: ResetAuthenticatorRequestProps;

  constructor(id: string, props: ResetAuthenticatorRequestProps) {
    this.id = id;
    this.oobCode = props.oobCode;
    this.accountKey = props.accountKey;
    this.email = props.email;
    this.status = props.status;
    this.expiredAt = props.expiredAt;
    this.createdAt = props.createdAt ?? Date.now();
    this.updatedAt = props.updatedAt ?? Date.now();
    this.props = props;
  }

  public getId = () => this.id;

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}
export default ResetAuthenticatorRequest;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';

// Style
import { OptionTextStyle, BalanceTextStyle, Hover } from './style';
import { withHyphenFormat } from '../../../../../../utils/helpers/stringHelper';

// Component
import { TextSecondary } from '../../../../../atoms/text2/Text2';
import LabeledCurrencyAmount from '../../../../textGroup/LabeledCurrencyAmount';

// Layout
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../../../atoms/utils/layout/Box/FlexBox';

const MOLECULES_ID = `molecules.select.savingsAccount`;

const SavingsAccountOptionContent: FC = (props: any) => {
  const { t } = useTranslation();

  const accountNumber = withHyphenFormat(props.data.value, 3);
  const { accountType, balance, currency } = props.data;

  return (
    <FlexBoxColumn padding="15 0 15 0">
      <FlexBoxRow alignItems="center">
        <TextSecondary theme={OptionTextStyle}>
          {t(`${MOLECULES_ID}.${accountType}Account`, {
            accountNumber,
          })}
        </TextSecondary>
      </FlexBoxRow>
      <FlexBoxRow
        justifyContent="flex-end"
        textAlign="right"
        width="100%"
        paddingRight={65}
      >
        <div>
          <LabeledCurrencyAmount
            amount={balance}
            currency={currency}
            direction="row"
            isSymbol
            label={t(`${MOLECULES_ID}.balance`)}
            labelFontFamily="secondary"
            marginBetween={5}
            theme={{
              amount: BalanceTextStyle,
              currency: BalanceTextStyle,
              label: BalanceTextStyle,
            }}
          />
        </div>
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};

const SavingsAccountSingleValueContainer: FC = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <FlexBoxRow alignItems="center" paddingRight={15}>
        <SavingsAccountOptionContent {...props} />
      </FlexBoxRow>
    </components.SingleValue>
  );
};

const SavingsAccountOptionContainer: FC = (props: any) => {
  return (
    <Hover>
      <components.Option {...props}>
        <FlexBoxRow alignItems="center">
          <SavingsAccountOptionContent {...props} />
        </FlexBoxRow>
      </components.Option>
    </Hover>
  );
};

export { SavingsAccountSingleValueContainer, SavingsAccountOptionContainer };

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

// Lib
import { deleteFullWidth } from '../../../../../utils/helpers/stringHelper';
import { shippingAddressValidation } from '../../../../../utils/validation/registers';

// Component
import FormInputCherryVisa01Address, {
  FormInputCherryVisa01AddressKeys,
} from '../FormInputCherryVisa01Address';
import FormInputPhoneNumber from '../../formInput/textField/FormInputPhoneNumber';
import FormInputCherryVisa01ShippingAddressLayout from './FormInputCherryVisa01ShippingAddressLayout';

const MOLECULE_ID = `molecules.formInputGroup.formInputShippingAddress`;

export interface FormInputCherryVisa01ShippingAddressProps {
  addressKeys: FormInputCherryVisa01AddressKeys;
  onChange?: () => void;
}

const FormInputCherryVisa01ShippingAddress: FC<FormInputCherryVisa01ShippingAddressProps> = ({
  addressKeys,
  onChange = () => undefined,
}: FormInputCherryVisa01ShippingAddressProps) => {
  const { t } = useTranslation();
  const { register, setValue, watch } = useFormContext();

  return (
    <FormInputCherryVisa01ShippingAddressLayout>
      <FormInputCherryVisa01Address
        key="address"
        addressKeys={addressKeys}
        onChange={onChange}
      />
      <FormInputPhoneNumber
        key="phoneNumber"
        label={t(`${MOLECULE_ID}.phoneNumber.label`)}
        inputWidth={400}
        onChange={(text: string) => {
          onChange();
          setValue('phoneNumber', deleteFullWidth(text));
        }}
        name={{ phoneNumber: 'phoneNumber', countryCode: 'countryCode' }}
        register={register(
          shippingAddressValidation(t).phoneNumber(watch('countryCode')),
        )}
      />
    </FormInputCherryVisa01ShippingAddressLayout>
  );
};

export default FormInputCherryVisa01ShippingAddress;

import React, { FC, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import ProofOfResidencyFileContext from '../../../../../../../enhancers/fileHandler/verification/kycRequest/ProofOfResidencyFileProvider/ProofOfResidencyFileContext';
import UserAuthorizedContext from '../../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// CloudStorage
import { useStorageImage } from '../../../../../../../infrastructure/firebase/cloudStorage/useStorageImage';
import { getUploadFileUrl } from '../../../../../../../utils/helpers/fileHelper';

// Type
import { AddressDocumentType } from '../../../../../../../types/AddressDocumentType.type';
import { FileType } from '../../../../../../../types/File.type';
import { KycRequestUploadFileType } from '../../../../../../../types/KycRequest.type';

// Style
import { DescriptionStyle } from './style';

// Component
import { TextSecondary } from '../../../../../../atoms/text2/Text2';
import CheckPointList from '../../../../../list/CheckPointList';
import DropzoneArea from '../../../../../../molecules/dropzone2/DropzoneArea';
import KycFormContainer from '../../../../common/KycFormContainer';
import FormResidencyProofUploadLayout from './FormResidencyProofUploadLayout';

// ValueObject
import ProofOfResidency from '../../../../../../../value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidency';

const FORM_ID = `organisms.form.verification.FormProofOfResidency.residencyProofUpload`;

export interface ResidencyProofUploadFormProps {
  proofOfResidency?: ProofOfResidency;
}

const ResidencyProofUploadForm: FC<ResidencyProofUploadFormProps> = ({
  proofOfResidency,
}: ResidencyProofUploadFormProps) => {
  const { t } = useTranslation();
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // Form
  const { setValue, watch } = useFormContext();

  // FileHandler
  const {
    proofOfDocument1,
    setProofOfDocument1,
    proofOfDocument2,
    setProofOfDocument2,
  } = useContext(ProofOfResidencyFileContext);

  // Storage
  const proofOfDocumentUrl1 = useStorageImage(
    proofOfResidency?.getProps().document.documentUrl1,
  ).storageUrl;

  const proofOfDocumentUrl2 = useStorageImage(
    proofOfResidency?.getProps().document.documentUrl2,
  ).storageUrl;

  /* *
   *
   *  UseEffect
   *
   * */

  // Set proofOfDocumentUrl1 when File is uploaded
  useEffect(() => {
    if (proofOfDocument1 && userAuthorized) {
      const storageFileUrl = getUploadFileUrl(
        userAuthorized,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (proofOfDocument1 as any).path,
        KycRequestUploadFileType.proofOfResidency1,
      );
      setValue('proofOfDocumentUrl1', storageFileUrl);
    }
    // eslint-disable-next-line
  }, [proofOfDocument1]);

  // Set proofOfDocumentUrl2 when File is uploaded
  useEffect(() => {
    if (proofOfDocument2 && userAuthorized) {
      const storageFileUrl = getUploadFileUrl(
        userAuthorized,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (proofOfDocument2 as any).path,
        KycRequestUploadFileType.proofOfResidency2,
      );
      setValue('proofOfDocumentUrl2', storageFileUrl);
    }
    // eslint-disable-next-line
  }, [proofOfDocument2]);

  return (
    <KycFormContainer order={3} formId={FORM_ID}>
      <FormResidencyProofUploadLayout
        isDisplaySecondDropzone={
          watch('documentType') === AddressDocumentType.driverLicense
        }
      >
        <TextSecondary key="description" theme={DescriptionStyle}>
          {t(`${FORM_ID}.description`)}
        </TextSecondary>
        <DropzoneArea
          key="dropzone1"
          name="proofOfDocument1"
          acceptFileTypes={[FileType.png, FileType.jpeg]}
          defaultFilePath={proofOfDocumentUrl1 || ''}
          maxFileSize={2}
          setFile={setProofOfDocument1}
          width={476}
        />
        <DropzoneArea
          key="dropzone2"
          name="proofOfDocument2"
          acceptFileTypes={[FileType.png, FileType.jpeg]}
          defaultFilePath={proofOfDocumentUrl2 || ''}
          maxFileSize={2}
          setFile={setProofOfDocument2}
          width={476}
        />
        <CheckPointList
          key="checkPoint"
          title={t(`organisms.CheckPointList.title`)}
          checkPoints={[
            t(`organisms.CheckPointList.text.complete`),
            t(`organisms.CheckPointList.text.clear`),
            t(`organisms.CheckPointList.text.valid`),
          ]}
        />
      </FormResidencyProofUploadLayout>
    </KycFormContainer>
  );
};

export default ResidencyProofUploadForm;

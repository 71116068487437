import React, { FC, useContext } from 'react';
import { useUpdateAuthenticator } from './useUpdateAuthenticator';
import UpdateAuthenticatorContext from './UpdateAuthenticatorContext';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// ErrorHandler
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type UpdateAuthenticatorProps = {
  children: React.ReactNode;
};

const UpdateAuthenticator: FC<UpdateAuthenticatorProps> = ({
  children,
}: UpdateAuthenticatorProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const { state, update, setState } = useUpdateAuthenticator(userAuthorized);

  return (
    <UpdateAuthenticatorContext.Provider
      value={{
        updateAuthenticatorState: state,
        setUpdateAuthenticatorState: setState,
        update,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </UpdateAuthenticatorContext.Provider>
  );
};
export default UpdateAuthenticator;

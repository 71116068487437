import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface FormRegisterLayoutProps {
  children: React.ReactElement[];
}

const FormRegisterLayout: FC<FormRegisterLayoutProps> = ({
  children,
}: FormRegisterLayoutProps) => {
  const elements = [
    'email',
    'username',
    'password',
    'confirmPassword',
    'button',
  ];
  const [
    email,
    username,
    password,
    confirmPassword,
    button,
  ] = getElementsFromKeys(children, elements);

  return (
    <>
      {email}
      <Margin top={40}>{username}</Margin>
      <Margin top={40}>{password}</Margin>
      <Margin top={40}>{confirmPassword}</Margin>
      <Margin top={70}>{button}</Margin>
    </>
  );
};

export default FormRegisterLayout;

import React, { FC } from 'react';
import TransferDebitCardPage from 'containers/transfer/TransferDebitCardPage/TransferDebitCardPage';
import TransferDebitCardCancelProvider from 'enhancers/useCase/transfer/debitCard/TransferDebitCardCancelProvider';
import TransferDebitCardViewProvider from 'enhancers/useCase/transfer/debitCard/TransferDebitCardViewProvider';
import TransferDebitCardCreateProvider from 'enhancers/useCase/transfer/debitCard/TransferDebitCardCreateProvider';
import DebitCardMaintenanceModalProvider from 'components/organisms/modal/DebitCardMaintenanceModal/DebitCardMaintenanceModalProvider';

const EnhancedTransferDebitCardPage: FC = () => {
  return (
    <TransferDebitCardViewProvider>
      <TransferDebitCardCancelProvider>
        <TransferDebitCardCreateProvider>
          <DebitCardMaintenanceModalProvider>
            <TransferDebitCardPage />
          </DebitCardMaintenanceModalProvider>
        </TransferDebitCardCreateProvider>
      </TransferDebitCardCancelProvider>
    </TransferDebitCardViewProvider>
  );
};

export default React.memo(EnhancedTransferDebitCardPage);

export enum FiatCurrencyType {
  USD = 'USD',
}

export type FiatCurrencyPropsType = {
  name: string;
  symbol: string;
  precision: number;
  minTransferAmount: number;
};

export const FiatCurrencyProps: { [key: string]: FiatCurrencyPropsType } = {
  [FiatCurrencyType.USD]: {
    name: 'US Dollar',
    symbol: 'US$',
    precision: 2,
    minTransferAmount: 0.01,
  },
};

export const isFiat = (currency: string) => {
  const fiats = Object.keys(FiatCurrencyType);
  return fiats.indexOf(currency) > -1;
};

import { useState } from 'react';
import Maintenance from './Maintenance';

export const useMaintenance = (defaultValue?: Maintenance) => {
  const [maintenance, setMaintenance] = useState<Maintenance | undefined>(
    defaultValue,
  );

  return {
    maintenance,
    setMaintenance,
  };
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { Container, Content } from './style';
import Margin from '../../../atoms/utils/layout/Margin';

export interface SignInTwoFactorAuthTemplateLayoutProps {
  children: React.ReactElement[];
}

const SignInTwoFactorAuthTemplateLayout: FC<SignInTwoFactorAuthTemplateLayoutProps> = ({
  children,
}: SignInTwoFactorAuthTemplateLayoutProps) => {
  const elements = ['header', 'description', 'form', 'link', 'copyright'];
  const [header, description, form, link, copyright] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <Container>
      {header}
      <Content>
        <div style={{ textAlign: 'center' }}>{description}</div>
        {form}
        <Margin top={146}>{link}</Margin>
      </Content>
      {copyright}
    </Container>
  );
};
export default SignInTwoFactorAuthTemplateLayout;

// Type
import { CorporateInformationProps } from './CorporateInformation.type';

// ValueObject
import KycRequestContent from '../KycRequestContent/KycRequestContent';

class CorporateInformation extends KycRequestContent {
  private corporateName: string;

  private corporateRegistryUrl: string;

  constructor(props: CorporateInformationProps) {
    super(props);
    this.corporateName = props.corporateName;
    this.corporateRegistryUrl = props.corporateRegistryUrl;
  }

  public getProps() {
    return {
      ...super.getProps(),
      corporateName: this.corporateName,
      corporateRegistryUrl: this.corporateRegistryUrl,
    };
  }

  public getCommandParameter = () => ({
    ...super.getCommandParameter(),
    corporateName: this.corporateName,
    corporateRegistryUrl: this.corporateRegistryUrl,
  });

  public updateProps = (props: CorporateInformationProps) => {
    return new CorporateInformation({ ...this.getProps(), ...props });
  };
}
export default CorporateInformation;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  FormContainer,
  Content,
  Main,
  CopyRightTextStyle,
} from './style';
import AuthPageLanguageSelectorHeader from '../../../organisms/header/HeaderAuthPageLanguageSelector';
import LogoHeader from '../../../organisms/header/HeaderLogo';
import CopyRightFooter from '../../../organisms/footer/CopyRightFooter';
import BreadcrumbList from '../../../organisms/list/BreadcrumbList';
import SignInForm from '../../../organisms/form/front/SignInForm';
import { SIGN_IN } from '../../../../config/constants/pageId.json';
import {
  ROUTE_SIGN_UP,
  ROUTE_HOME,
} from '../../../../config/constants/routing';

const SignInTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <AuthPageLanguageSelectorHeader />
      <Content>
        <Main>
          <div style={{ marginTop: `${(100 / 1079) * 100}vh` }}>
            <LogoHeader />
          </div>
          <div style={{ marginTop: `${65 / 16}rem` }}>
            <FormContainer>
              <SignInForm />
            </FormContainer>
          </div>
          <div style={{ marginTop: `${30 / 16}rem` }}>
            <BreadcrumbList
              linkedTexts={[
                {
                  text: t(`${SIGN_IN}.navigation.signup`),
                  urlList: [
                    {
                      replaceText: t(`${SIGN_IN}.link.signUp`),
                      url: ROUTE_SIGN_UP,
                    },
                  ],
                },
                {
                  text: t(`${SIGN_IN}.link.top`),
                  urlList: [
                    {
                      replaceText: t(`${SIGN_IN}.link.top`),
                      url: ROUTE_HOME,
                    },
                  ],
                },
              ]}
            />
          </div>
        </Main>
        <CopyRightFooter theme={CopyRightTextStyle} />
      </Content>
    </Container>
  );
};

export default SignInTemplate;

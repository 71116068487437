import React, { FC, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useDebitCardRequestViewUseCase } from './useDebitCardRequestViewUseCase';
import UserAuthorizedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import SystemErrorHandler from '../../../../../errorHandler/SystemErrorHandler';
import { ErrorCodeType } from '../../../../../../utils/errors/ErrorHandler/ErrorCode.type';

// Type
import { DebitCardIssuerType } from '../../../../../../types/DebitCard.type';

// Constant
import { ROUTE_SAVINGS_ACCOUNT } from '../../../../../../config/constants/routing';

// DomainObject
import DebitCardRequestViewContext from './DebitCardRequestViewContext';

// For Redirect
const REDIRECT_ERROR_CODES = [
  ErrorCodeType.DEBIT_CARD_REQUEST_INVALID_ISSUER,
  ErrorCodeType.SAVINGS_ACCOUNT_INVALID_ACCOUNT_NUMBER_BY_CLIENT,
  ErrorCodeType.SAVINGS_ACCOUNT_FORBIDDEN_READ_SAVINGS_INFO,
];

export type DebitCardRequestViewProviderProps = {
  children: React.ReactNode;
};

const DebitCardRequestViewProvider: FC<DebitCardRequestViewProviderProps> = ({
  children,
}: DebitCardRequestViewProviderProps) => {
  const { search } = useLocation();
  const history = useHistory();

  // DomainObject
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // Get Parameters
  const savingsAccountNumber = new URLSearchParams(search).get(
    'savingsAccountNumber',
  );
  const issuer = new URLSearchParams(search).get('issuer');

  const { state, setState, debitCardRequest } = useDebitCardRequestViewUseCase(
    savingsAccountNumber,
    issuer as DebitCardIssuerType,
    userAuthorized,
  );

  /**
   *
   *  UseEffect
   *
   */

  // issuer && savingsAccountNumber
  useEffect(() => {
    if (isEmpty(issuer) || isEmpty(savingsAccountNumber))
      history.push(ROUTE_SAVINGS_ACCOUNT);
    // eslint-disable-next-line
  }, [issuer, savingsAccountNumber]);

  // state (client error)
  useEffect(() => {
    if (REDIRECT_ERROR_CODES.includes(state as ErrorCodeType))
      history.push(ROUTE_SAVINGS_ACCOUNT);
    // eslint-disable-next-line
  }, [state]);

  // Routing
  useEffect(() => {
    if (debitCardRequest) history.push(debitCardRequest.getStatusPageUrl());
    // eslint-disable-next-line
  }, [state, debitCardRequest]);

  return (
    <DebitCardRequestViewContext.Provider value={{ state }}>
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </DebitCardRequestViewContext.Provider>
  );
};

export default DebitCardRequestViewProvider;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import { FlexBoxRow } from '../../../utils/layout/Box/FlexBox';
import Margin from '../../../utils/layout/Margin';

export interface LabelSendIconLayoutProps {
  children: React.ReactElement[];
  marginBetween: string | number;
}

const LabelSendIconLayout: FC<LabelSendIconLayoutProps> = ({
  children,
  marginBetween,
}: LabelSendIconLayoutProps) => {
  const elements = ['icon', 'label'];
  const [icon, label] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow width="100%" alignItems="center" justifyContent="center">
      {icon}
      <Margin left={marginBetween}>{label}</Margin>
    </FlexBoxRow>
  );
};
export default LabelSendIconLayout;

import Joi from '@hapi/joi';

// Error
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import { DebitCardV2Props, DebitCardV2PropsFormat } from './DebitCardV2.type';

// DomainObject
import DebitCardV2 from './DebitCardV2';

class DebitCardV2Factory {
  static create = (id: string, props: DebitCardV2Props) => {
    const result = Joi.object(DebitCardV2PropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: DebitCardV2.name,
          reason: result.error.details[0].message,
        },
      );

    return new DebitCardV2(id, props);
  };
}

export default DebitCardV2Factory;

import React, { FC, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import DebitCardV2sContext from '../../../../../../domain/debitCard/debitCardV2/DebitCardV2s/DebitCardV2sContext';
import DebitCardOptionContext from '../../../option/option/DebitCardOption/DebitCardOptionContext';

// LayoutComponent
import SelectBox from '../../../../../atoms/select/SelectBox';
import Box from '../../../../../atoms/utils/layout/Box/Box';

// Mapper
import DebitCardsOptionsMapper from '../../../option/mapper/DebitCardsOptionsMapper';

// Component
import {
  DebitCardSingleValueContainer,
  DebitCardOptionContainer,
} from '../../../option/optionContainer/DebitCardOptionContainer';
import {
  DebitCardBalanceSingleValueContainer,
  DebitCardBalanceOptionContainer,
} from '../../../option/optionContainer/DebitCardBalanceOptionContainer';

export interface DebitCardSelectBoxProps {
  name: string;
  withBalance?: boolean;
}

const DebitCardSelectBox: FC<DebitCardSelectBoxProps> = ({
  name,
  withBalance = false,
}: DebitCardSelectBoxProps) => {
  const { debitCards } = useContext(DebitCardV2sContext);
  const { debitCardOption, setDebitCardOption } = useContext(
    DebitCardOptionContext,
  );

  const setValue = useFormContext()?.setValue;

  if (!debitCards) return <></>;

  const options = DebitCardsOptionsMapper.domainToOptions(debitCards);

  // Display Balance or not
  const [singleValueComponent, optionComponent, minHeight] = withBalance
    ? [
        DebitCardBalanceSingleValueContainer,
        DebitCardBalanceOptionContainer,
        `${70 / 16}rem`,
      ]
    : [
        DebitCardSingleValueContainer,
        DebitCardOptionContainer,
        `${50 / 16}rem`,
      ];

  return (
    <Box>
      <SelectBox
        borderColor="#d6ddec"
        dropdownColor="#212F41"
        isSearchable={false}
        marginLeft={`${0 / 16}rem`}
        minHeight={minHeight}
        options={options || []}
        onSelect={(debitCardId: string) => {
          if (setValue) setValue(name, debitCardOption);
          setDebitCardOption(debitCards.filterById(debitCardId));
        }}
        placeholderColor="#D5D0DA"
        value={debitCardOption?.getId()}
        OptionComponent={optionComponent}
        SingleValueComponent={singleValueComponent}
      />
    </Box>
  );
};

export default DebitCardSelectBox;

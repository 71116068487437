// IUseCase
import IRegisterUseCase from '../../../enhancers/useCase/authentication/RegisterProvider/IRegisterUseCase';

// IService
import IUserCreateService from '../../public/user/interface/IUserCreateService';
import IUserAuthenticationService from '../IUserAuthenticationService';

// Service
import UserCreateService from '../../../service/public/user/UserCreateService';
import UserAuthenticationService from '../../../service/authentication/UserAuthenticationService';

// IRepository
import IUserCreator from '../../../domain/public/user/User/IUserCreator';
import IUserAuthentication from '../../../domain/public/user/User/IUserAuthentication';

class RegisterUseCase implements IRegisterUseCase {
  private userCreateService: IUserCreateService;

  private userAuthenticationService: IUserAuthenticationService;

  constructor(
    userCreator: IUserCreator,
    userAuthentication: IUserAuthentication,
  ) {
    this.userCreateService = new UserCreateService(userCreator);
    this.userAuthenticationService = new UserAuthenticationService(
      userAuthentication,
    );
  }

  public register = async (
    username: string,
    password: string,
    oobCode: string,
    email: string,
  ) => {
    await this.userCreateService.register(username, password, oobCode);

    const userAuthorized = await this.userAuthenticationService.signIn(
      email,
      password,
    );

    return userAuthorized;
  };
}
export default RegisterUseCase;

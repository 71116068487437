import React, { FC, useContext } from 'react';
import { useModal } from 'react-modal-hook';
import CardShippingInformationModalContext from './CardShippingInformationModalContext';
import DebitCardRequestCherryVisa01Context from '../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Context';

// Component
import CardShippingInformationModal from '.';

export type CardShippingInformationModalProviderProps = {
  children: React.ReactNode;
};

const CardShippingInformationModalProvider: FC<CardShippingInformationModalProviderProps> = ({
  children,
}: CardShippingInformationModalProviderProps) => {
  const { debitCardRequestCherryVisa01 } = useContext(
    DebitCardRequestCherryVisa01Context,
  );

  const [showModal, hideModal] = useModal(
    () => (
      <CardShippingInformationModalContext.Provider
        value={{ showModal, hideModal }}
      >
        <DebitCardRequestCherryVisa01Context.Provider
          value={{ debitCardRequestCherryVisa01 }}
        >
          <CardShippingInformationModal />
        </DebitCardRequestCherryVisa01Context.Provider>
      </CardShippingInformationModalContext.Provider>
    ),
    [debitCardRequestCherryVisa01],
  );

  return (
    <CardShippingInformationModalContext.Provider
      value={{ showModal, hideModal }}
    >
      {children}
    </CardShippingInformationModalContext.Provider>
  );
};
export default CardShippingInformationModalProvider;

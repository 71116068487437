import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import TransferTemplateContainer from '../../common/TransferTemplateContainer';
import TransferTemplateContent from '../../common/TransferTemplateContent';
import Margin from '../../../../atoms/utils/layout/Margin';
import { FlexRow } from '../../../../atoms/utils/layout/Flex';

export interface TransferSavingsAccountConfirmTemplateLayoutProps {
  children: React.ReactElement[];
}

const TransferSavingsAccountConfirmTemplateLayout: FC<TransferSavingsAccountConfirmTemplateLayoutProps> = ({
  children,
}: TransferSavingsAccountConfirmTemplateLayoutProps) => {
  const elements = ['header', 'progressStep', 'description', 'table', 'form'];
  const [header, progressStep, description, table, form] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <TransferTemplateContainer>
      {header}
      <Margin top={50}>
        <FlexRow justifyContent="center">{progressStep}</FlexRow>
      </Margin>
      <Margin top={100}>
        <TransferTemplateContent>
          {description}
          <Margin top={30}>{table}</Margin>
          <Margin top={50}>{form}</Margin>
        </TransferTemplateContent>
      </Margin>
    </TransferTemplateContainer>
  );
};
export default TransferSavingsAccountConfirmTemplateLayout;

import React, { FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useContractTimeDepositRenewalReservationUpdateUseCase } from './useContractTimeDepositUpdateRenewalReservationUseCase';

// Context
import ContractTimeDepositRenewalReservationUpdateContext from './ContractTimeDepositRenewalReservationUpdateContext';
import ContractTimeDepositContext from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDepositContext';
import { FormInputContractTimeDepositRenewalReservationContext } from '../../../../../components/organisms/form/contract/contractTimeDeposit/FormContractTimeDepositRenewalReservation/FormContractTimeDepositRenewalReservation.type';
import TimeDepositContext from '../../../../../domain/product/timeDeposit/TimeDeposit/TimeDepositContext';
import UserVerifiedContext from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedContext';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

// Constant
import { ROUTE_TIME_DEPOSIT } from '../../../../../config/constants/routing';
import { HOURS_TIME_LIMIT_RESERVATION_CHANGE_CONTRACT_TIME_DEPOSIT } from '../../../../../config/constants/business';

export type ContractTimeDepositRenewalReservationUpdateProviderProps = {
  children: React.ReactNode;
};

const ContractTimeDepositRenewalReservationUpdateProvider: FC<ContractTimeDepositRenewalReservationUpdateProviderProps> = ({
  children,
}: ContractTimeDepositRenewalReservationUpdateProviderProps) => {
  const { user } = useContext(UserVerifiedContext);
  const history = useHistory();
  const { contractTimeDeposit, setContractTimeDeposit } = useContext(
    ContractTimeDepositContext,
  );

  const {
    state,
    setState,
    timeDeposit,
    formInputContractTimeDepositRenewalReservationContext,
    updateContractTimeDepositRenewalReservation,
    executeUpdateContractTimeDepositRenewalReservation,
  } = useContractTimeDepositRenewalReservationUpdateUseCase(
    user,
    contractTimeDeposit,
    setContractTimeDeposit,
  );

  /* *
   *
   *  Redirect
   *
   * */
  useEffect(() => {
    if (!contractTimeDeposit) return;

    // Redirect if unauthorized Access occurs
    if (
      contractTimeDeposit.isMaturedWithInHours(
        HOURS_TIME_LIMIT_RESERVATION_CHANGE_CONTRACT_TIME_DEPOSIT,
      )
    )
      history.push(ROUTE_TIME_DEPOSIT);
  }, [contractTimeDeposit, history]);

  return (
    <FormInputContractTimeDepositRenewalReservationContext.Provider
      value={formInputContractTimeDepositRenewalReservationContext}
    >
      <ContractTimeDepositRenewalReservationUpdateContext.Provider
        value={{
          requestState: state,
          updateContractTimeDepositRenewalReservation,
          executeUpdateContractTimeDepositRenewalReservation,
        }}
      >
        <TimeDepositContext.Provider value={{ timeDeposit }}>
          <SystemErrorHandler state={state} setState={setState}>
            {children}
          </SystemErrorHandler>
        </TimeDepositContext.Provider>
      </ContractTimeDepositRenewalReservationUpdateContext.Provider>
    </FormInputContractTimeDepositRenewalReservationContext.Provider>
  );
};

export default ContractTimeDepositRenewalReservationUpdateProvider;

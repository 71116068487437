import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';

export interface FormInputRadioButtonLayoutProps {
  children: React.ReactElement[];
  labelWidth: string | number;
}

const FormInputRadioButtonLayout: FC<FormInputRadioButtonLayoutProps> = ({
  children,
  labelWidth,
}: FormInputRadioButtonLayoutProps) => {
  const elements = ['label', 'input'];
  const [label, input] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow alignItems="center">
      <FlexBoxRow width={labelWidth}>{label}</FlexBoxRow>
      {input}
    </FlexBoxRow>
  );
};
export default FormInputRadioButtonLayout;

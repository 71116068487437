import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

// Lib
import { deleteFullWidth } from '../../../../../../utils/helpers/stringHelper';
import { twoFactorAuthCodeRegister } from '../../../../../../utils/validation/registers';

// Type
import { ErrorCodeType } from '../../../../../../utils/errors/ErrorHandler/ErrorCode.type';

// Component
import TextField from '../../../../../molecules/form/TextField2';
import KycFormContainer from '../../../common/KycFormContainer';

const FORM_ID =
  'organisms.form.verification.FormTwoFactorAuthAppSettings.formTwoFactorAuthCode';

export interface FormTwoFactorAuthCodeProps {
  state: string;
}

const FormTwoFactorAuthCode: FC<FormTwoFactorAuthCodeProps> = ({
  state,
}: FormTwoFactorAuthCodeProps) => {
  const { t } = useTranslation();
  const {
    setValue,
    watch,
    register,
    errors,
    setError,
    clearErrors,
  } = useFormContext();

  useEffect(() => {
    if (state === ErrorCodeType.AUTHENTICATION_AUTHENTICATOR_INVALID_CODE) {
      const errorCode = ErrorCodeType.AUTHENTICATION_AUTHENTICATOR_INVALID_CODE;
      setError('validCode', {
        type: '',
        message: t(`validation.${errorCode}`),
      });
    }
  }, [state, setError, t]);

  return (
    <KycFormContainer order={3} formId={FORM_ID}>
      <TextField
        errorMessage={
          (errors.code && errors.code.message) ||
          (errors.validCode && errors.validCode.message)
        }
        name="code"
        maxLength={20}
        onChange={(text: string) => {
          if (errors.validCode) clearErrors('validCode');
          setValue('code', deleteFullWidth(text));
        }}
        register={register(twoFactorAuthCodeRegister('code', t))}
        textInputWidth={400}
        value={watch(`code`)}
      />
    </KycFormContainer>
  );
};

export default FormTwoFactorAuthCode;

import React from 'react';
import { INITIAL } from '../../../config/constants/requestState';

export interface ConfirmUpdateEmailProps {
  confirmUpdateEmailState: string;

  confirmUpdateEmail: (code: string, isResend?: boolean) => void;

  setConfirmUpdateEmailState: (state: string) => void;
}

const ConfirmUpdateEmailContext = React.createContext<ConfirmUpdateEmailProps>({
  confirmUpdateEmailState: INITIAL,
  confirmUpdateEmail: () => undefined,
  setConfirmUpdateEmailState: () => undefined,
});

export default ConfirmUpdateEmailContext;

import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

// LayoutComponent
import { FlexBoxRow } from '../../utils/layout/Box/FlexBox';
import { CircleBox } from '../../utils/layout/Box/Box/CircleBox';
import Margin from '../../utils/layout/Margin';

// Component
import { TextSecondary, TextThemeProps } from '../../text2/Text2';
import HiddenInput from '../HiddenInput';

export interface RadioButtonProps {
  displayItems: Array<string>;
  items: Array<string>;
  layout: {
    circle: {
      diameter: string | number;
    };
    smallCircle: {
      diameter: string | number;
    };
    margin: {
      item: string | number;
      label: string | number;
    };
  };
  name: string;
  selected: string;
  theme: {
    text: TextThemeProps;
    border: string;
    circle: {
      backgroundColorSelected: string;
      backgroundColorUnselected: string;
    };
    smallCircle: {
      backgroundColor: string;
    };
  };
}

const RadioButton: FC<RadioButtonProps> = ({
  items = [],
  displayItems = [],
  layout,
  name,
  selected,
  theme,
}: RadioButtonProps) => {
  const { setValue, watch } = useFormContext();

  const getCircle = (itemName: string, selectedItemName: string) => {
    if (itemName === selectedItemName) {
      return (
        <CircleBox
          theme={{
            backgroundColor: theme.circle.backgroundColorSelected,
            border: theme.border,
          }}
          style={{ cursor: 'pointer' }}
          width={layout.circle.diameter}
          height={layout.circle.diameter}
        >
          <CircleBox
            theme={{
              backgroundColor: theme.smallCircle.backgroundColor,
              border: theme.border,
            }}
            width={layout.smallCircle.diameter}
            height={layout.smallCircle.diameter}
          />
        </CircleBox>
      );
    }
    return (
      <CircleBox
        theme={{
          backgroundColor: theme.circle.backgroundColorUnselected,
          border: theme.border,
        }}
        style={{ cursor: 'pointer' }}
        width={layout.circle.diameter}
        height={layout.circle.diameter}
        onClick={() => {
          setValue(name, itemName);
        }}
      />
    );
  };

  return (
    <>
      <FlexBoxRow alignItems="center" width="auto">
        {items.map((item, i) => {
          return (
            <FlexBoxRow alignItems="center" key={`${i}`}>
              {getCircle(item, selected)}
              <Margin left={layout.margin.label} />
              <TextSecondary theme={theme.text}>
                {displayItems[i]}
              </TextSecondary>
              <Margin right={layout.margin.item} />
            </FlexBoxRow>
          );
        })}
      </FlexBoxRow>
      <HiddenInput name={name} value={watch(name) || ''} />
    </>
  );
};

export default RadioButton;

import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PersonalInformationSubmitContext from '../../../../../../enhancers/useCase/verification/kycRequest/KycRequestSubmitProvider/PersonalInformationSubmitProvider/PersonalInformationSubmitContext';

// Lib
import { isValid, getDefaultValues } from './helper';

// Type
import {
  FormInputPersonalInformation,
  FormInputPersonalInformationContext,
} from './FormPersonalInformation.type';

// Constant
import { IS_LOADING } from '../../../../../../config/constants/requestState';
import { ROUTE_KYC_STATUS } from '../../../../../../config/constants/routing';

// Component
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../../atoms/button/Button2';
import LabelSendIcon from '../../../../../atoms/button/ButtonLabel/LabelSendIcon';
import FormPersonalInformationInput from './FormPersonalInformationInput';
import FormPassportInput from './FormPassportInput';
import FormPassportUpload from './FormPassportUpload';
import FormSelfieUpload from './FormSelfieUpload';
import FormPersonalInformationLayout from './FormPersonalInformationLayout';

// ValueObject
import PersonalInformation from '../../../../../../value/verification/kycRequest/KycRequestContent/PersonalInformation/PersonalInformation';

export interface FormPersonalInformationProps {
  personalInformation?: PersonalInformation;
}

const FormPersonalInformation: FC<FormPersonalInformationProps> = ({
  personalInformation,
}: FormPersonalInformationProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { formInput, setFormInput } = useContext(
    FormInputPersonalInformationContext,
  );

  // Method
  const { state, submitPersonalInformation } = useContext(
    PersonalInformationSubmitContext,
  );

  /* *
   *
   *  Form
   *
   * */
  const methods = useForm<FormInputPersonalInformation>({
    mode: 'onChange',
  });
  const { errors, handleSubmit, reset, setValue, watch } = methods;

  /* *
   *
   *  DefaultValues
   *
   * */

  // Set DefaultValue
  useEffect(() => {
    if (formInput) reset(formInput);
    // eslint-disable-next-line
  }, [formInput]);

  // Set Default Value from PersonalInformation
  useEffect(() => {
    if (!formInput && personalInformation) {
      const defaultValues = getDefaultValues(personalInformation);

      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [formInput, personalInformation, setValue]);

  /* *
   *
   *  Event
   *
   * */

  const onSubmit = useCallback(
    async (input: FormInputPersonalInformation) => {
      setFormInput(input);

      await submitPersonalInformation(input, () => {
        history.push(ROUTE_KYC_STATUS);
      });
    },
    // eslint-disable-next-line
    [submitPersonalInformation],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <FormPersonalInformationLayout>
          <FormPersonalInformationInput key="personalInformation" />
          <FormPassportInput key="passportInput" />
          <FormPassportUpload
            key="passport"
            personalInformation={personalInformation}
          />
          <FormSelfieUpload
            key="selfie"
            personalInformation={personalInformation}
          />
          <SubmitButton
            key="button"
            disabled={!isValid(errors, watch)}
            isLoading={state === IS_LOADING}
            height={75}
            width={'100%'}
          >
            <LabelSendIcon
              iconSize={26}
              iconStyle={{ color: 'white' }}
              label={t(`atoms.button.submit`)}
              marginBetween={8}
              textStyle={DefaultButtonTextStyle}
            />
          </SubmitButton>
        </FormPersonalInformationLayout>
      </FormProvider>
    </form>
  );
};

export default FormPersonalInformation;

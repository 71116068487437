import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import color from '../../../../atoms/utils/const/color';

// Style
import { ContainerStyle } from './style';

// Layout Component
import { FlexBoxColumn } from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface UserTypeCardLayoutProps {
  children: React.ReactElement[];
  isSelected: boolean;
  onClick: () => void;
}

const UserTypeCardLayout: FC<UserTypeCardLayoutProps> = ({
  children,
  isSelected,
  onClick,
}: UserTypeCardLayoutProps) => {
  const elements = ['icon', 'title', 'description'];
  const [icon, title, description] = getElementsFromKeys(children, elements);

  const backgroundColor = isSelected
    ? color.background.primary
    : color.background.white;

  return (
    <FlexBoxColumn
      alignItems="center"
      justifyContent="center"
      onClick={onClick}
      theme={ContainerStyle}
      width={292}
      height={364}
      style={{ backgroundColor }}
    >
      {icon}
      <Margin top={20} />
      {title}
      <Margin top={22} />
      {description}
    </FlexBoxColumn>
  );
};

export default UserTypeCardLayout;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, TextStyle } from './style';

import { TextSecondary } from '../../../atoms/text2/Text2';

const ORGANISM_ID = `organisms.footer.FooterCopyRight`;

export interface CopyRightFooterProps {
  theme?: {
    fontSize: number | string;
    color: string;
    fontWeight: number;
  };
}

const CopyRightFooter: FC<CopyRightFooterProps> = ({
  theme,
}: CopyRightFooterProps) => {
  const { t } = useTranslation();
  const copyRightTextStyle = theme || TextStyle;

  return (
    <Container>
      <TextSecondary theme={copyRightTextStyle}>
        {t(`${ORGANISM_ID}.copyright`)}
      </TextSecondary>
      <span style={{ fontFamily: 'Times', ...copyRightTextStyle }}>
        &nbsp;&copy;&nbsp;
      </span>
      <TextSecondary theme={copyRightTextStyle}>
        {t(`${ORGANISM_ID}.companyName`)}
      </TextSecondary>
    </Container>
  );
};

export default CopyRightFooter;

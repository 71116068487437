import React, { FC } from 'react';
import Margin from '../../../../../atoms/utils/layout/Margin';

import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

export interface FormTransferSavingsAccountLayoutProps {
  children: React.ReactElement[];
}

const FormTransferSavingsAccountLayout: FC<FormTransferSavingsAccountLayoutProps> = ({
  children,
}: FormTransferSavingsAccountLayoutProps) => {
  const elements = ['savingsAccount', 'transferType', 'detail', 'button'];
  const [savingsAccount, transferType, detail, button] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <>
      {savingsAccount}
      <Margin top={90}>{transferType}</Margin>
      <Margin top={80}>{detail}</Margin>
      <Margin top={80}>{button}</Margin>
    </>
  );
};

export default FormTransferSavingsAccountLayout;

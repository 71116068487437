import React, { FC, useContext } from 'react';
import TransferDebitCardPageStateContext from '../../../enhancers/pageState/transfer/TransferDebitCardPageState/TransferDebitCardPageStateContext';

// Error
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';

// Component
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import TransferDebitCardTemplate from '../../../components/templates/transfer/debitCard/TransferDebitCardTemplate';
import TransferDebitCardConfirmTemplate from '../../../components/templates/transfer/debitCard/TransferDebitCardConfirmTemplate';
import TransferDebitCardCompleteTemplate from '../../../components/templates/transfer/debitCard/TransferDebitCardCompleteTemplate';

const TransferDebitCardPage: FC = () => {
  const { pageState } = useContext(TransferDebitCardPageStateContext);

  const TemplateComponent: FC = () => {
    switch (pageState) {
      case 'initial':
        return <TransferDebitCardTemplate />;
      case 'confirm':
        return <TransferDebitCardConfirmTemplate />;
      case 'complete':
        return <TransferDebitCardCompleteTemplate />;
      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_SWITCH,
          {
            value: pageState,
            place: `TransferDebitCardPage`,
          },
        );
    }
  };

  return (
    <DashboardTemplate>
      <TemplateComponent />
    </DashboardTemplate>
  );
};

export default React.memo(TransferDebitCardPage);

import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const LabelStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const CheckBoxStyle = {
  unChecked: {
    border: `${1 / 16}rem solid ${color.border.lightBlue}`,
    backgroundColor: color.white,
  },
  checked: {
    border: `${1 / 16}rem solid ${color.check.checkedBlue}`,
    backgroundColor: color.check.checkedBlue,
    color: color.white,
  },
};

export const CheckboxTextStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const CheckboxLinkStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  textDecoration: 'underline',
};

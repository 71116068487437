// IService
import ICryptoCurrencyRateQueryService from '../../../useCases/cryptoCurrency/cryptoCurrencyRate/ICryptoCurrencyRateQueryService';

// IRepository
import ICryptoCurrencyRateRepository from '../../../domain/cryptoCurrency/cryptoCurrencyRate/CryptoCurrencyRate/ICryptoCurrencyRateRepository';

// IAPI
import CryptoCurrencyTradeFeeRate from '../../../domain/settings/cryptoCurrencyTradeFeeRate/CryptoCurrencyTradeFeeRate/CryptoCurrencyTradeFeeRate';

class CryptoCurrencyRateQueryService
  implements ICryptoCurrencyRateQueryService {
  private cryptoCurrencyRateQueryRepository: ICryptoCurrencyRateRepository;

  constructor(
    cryptoCurrencyRateQueryRepository: ICryptoCurrencyRateRepository,
  ) {
    this.cryptoCurrencyRateQueryRepository = cryptoCurrencyRateQueryRepository;
  }

  public getCurrencyRate = async (
    cryptoCurrencyTradeFeeRate: CryptoCurrencyTradeFeeRate,
  ) => {
    const cryptoCurrencyRate = await this.cryptoCurrencyRateQueryRepository.findById(
      cryptoCurrencyTradeFeeRate,
    );

    return cryptoCurrencyRate;
  };
}

export default CryptoCurrencyRateQueryService;

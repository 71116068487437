import CryptoCurrencyWallet from '../../CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet';
import { LitecoinTestnetWalletProps } from './LitecoinTestnetWallet.type';

class LitecoinTestnetWallet extends CryptoCurrencyWallet {
  constructor(address: string, props: LitecoinTestnetWalletProps) {
    super(address, props);
    this.props = props;
  }
}

export default LitecoinTestnetWallet;

import { useState } from 'react';
import BankSpotTransactions from './BankSpotTransactions';

export const useBankSpotTransactions = (
  defaultValue?: BankSpotTransactions,
) => {
  const [bankSpotTransactions, setBankSpotTransactions] = useState<
    BankSpotTransactions
  >(defaultValue || new BankSpotTransactions());

  return {
    bankSpotTransactions,
    setBankSpotTransactions,
  };
};

import TextSub from './TextSub';
import TextSubRegular18 from './TextSubRegular18';
import TextSubRegular20 from './TextSubRegular20';
import TextSubRegular22 from './TextSubRegular22';
import TextSubRegular26 from './TextSubRegular26';
import TextSubRegular28 from './TextSubRegular28';
import TextSubRegular32 from './TextSubRegular32';
import TextSubMedium20 from './TextSubMedium20';
import TextSubMedium22 from './TextSubMedium22';
import TextSubMedium31 from './TextSubMedium31';
import TextSubMedium36 from './TextSubMedium36';
import TextSubSemibold22 from './TextSubSemibold22';
import TextSubBold31 from './TextSubBold31';
import TextSubBold52 from './TextSubBold52';

export default TextSub;
export {
  TextSubRegular18,
  TextSubRegular20,
  TextSubRegular22,
  TextSubRegular26,
  TextSubRegular28,
  TextSubRegular32,
  TextSubMedium20,
  TextSubMedium22,
  TextSubMedium31,
  TextSubMedium36,
  TextSubSemibold22,
  TextSubBold31,
  TextSubBold52,
};

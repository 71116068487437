import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessHandlerUserStatusProvider from '../enhancers/routing/AccessHandlerUserStatusProvider';
import AuthenticatedPageVewProvider from '../enhancers/useCase/authentication/AuthenticatedPageViewProvider';

// Type
import { MenuType } from '../config/menu/main/Menu.type';

// Constant
import { ALLOWED_USER_STATUS } from './accessHandler/userStatusPageAccessMapper';
import {
  ROUTE_CRYPTO_CURRENCY_WALLET,
  ROUTE_CRYPTO_CURRENCY_TRADE,
  ROUTE_CRYPTO_CURRENCY_DEPOSITS_WITHDRAWALS,
} from '../config/constants/routing';

// Component
import { EnhancedCryptoCurrencyWalletPage } from '../containers/cryptoCurrency/CryptoCurrencyWalletPage';
import { EnhancedCryptoCurrencyTradePage } from '../containers/cryptoCurrency/CryptoCurrencyTragePage';
import { EnhancedCryptoCurrencyDepositWithdrawalPage } from '../containers/cryptoCurrency/CryptonCurrencyDepositWithdrawalPage';

const CryptoCurrencyRouter: FC = () => {
  return (
    <Switch>
      <AuthenticatedPageVewProvider>
        <AccessHandlerUserStatusProvider
          statusAllowed={ALLOWED_USER_STATUS[MenuType.cryptocurrencies]}
        >
          <Route exact path={ROUTE_CRYPTO_CURRENCY_WALLET}>
            <EnhancedCryptoCurrencyWalletPage />
          </Route>
          <Route exact path={ROUTE_CRYPTO_CURRENCY_DEPOSITS_WITHDRAWALS}>
            <EnhancedCryptoCurrencyDepositWithdrawalPage />
          </Route>
          <Route exact path={ROUTE_CRYPTO_CURRENCY_TRADE}>
            <EnhancedCryptoCurrencyTradePage />
          </Route>
        </AccessHandlerUserStatusProvider>
      </AuthenticatedPageVewProvider>
    </Switch>
  );
};

export default CryptoCurrencyRouter;

import Joi from '@hapi/joi';
import { InviterRankDType, RankType } from '../../../../types/Invite.type';
import { InviterProps, InviterPropsFormat } from '../Inviter/Inviter.type';

export interface InviterRankDProps extends InviterProps {
  rank: InviterRankDType;

  commissionRate: number;

  totalCommission: number;

  totalCommissionReceived: number;

  totalReferralChildrenTimeDepositAmount: number;
}

export const InviterRankDPropsFormat = {
  ...InviterPropsFormat,

  rank: Joi.valid(RankType.D01).valid(RankType.D02),

  commissionRate: Joi.number().min(0).required(),

  totalCommission: Joi.number().min(0).required(),

  totalCommissionReceived: Joi.number().min(0).required(),

  totalReferralChildrenTimeDepositAmount: Joi.number().min(0).required(),
};

import Joi from '@hapi/joi';

// Error
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import {
  TransferDebitCardTransactionV2Props,
  TransferDebitCardTransactionV2PropsFormat,
} from './TransferDebitCardTransactionV2.type';
import { TransferDebitCardStatusType } from '../../../../types/Transfer.type';

// DomainObject
import TransferDebitCardTransactionV2 from './TransferDebitCardTransactionV2';

// ValueObject
import TransferDebitCardTransactionId from '../../../idManager/transactionId/TransferDebitCardTransactionId/TransferDebitCardTransactionId';
import TransferDebitCardTransactionV2Seed from '../TransferDebitCardTransactionV2Seed/TransferDebitCardTransactionV2Seed';

class TransferDebitCardTransactionV2Factory {
  static create = (
    id: TransferDebitCardTransactionId,
    props: TransferDebitCardTransactionV2Props,
  ) => {
    const result = Joi.object(
      TransferDebitCardTransactionV2PropsFormat,
    ).validate(props);

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: TransferDebitCardTransactionV2.name,
          reason: result.error.details[0].message,
        },
      );

    return new TransferDebitCardTransactionV2(id, props);
  };

  static createFromSeed = (
    transferDebitCardTransactionSeed: TransferDebitCardTransactionV2Seed,
    newId: string,
  ) => {
    const transferDebitCardTransactionId = TransferDebitCardTransactionId.createNumberFromRawId(
      newId,
    );

    return TransferDebitCardTransactionV2Factory.create(
      transferDebitCardTransactionId,
      {
        ...transferDebitCardTransactionSeed.getProps(),
        status: TransferDebitCardStatusType.unconfirmed,
        commonId: 'tmpCommonId',
      },
    );
  };
}

export default TransferDebitCardTransactionV2Factory;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';
import Box from '../../../../../atoms/utils/layout/Box/Box';

export interface DateSelectBoxLayoutProps {
  children: React.ReactElement[];
}

const DateSelectBoxLayout: FC<DateSelectBoxLayoutProps> = ({
  children,
}: DateSelectBoxLayoutProps) => {
  const elements = ['day', 'month', 'year'];
  const [day, month, year] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow alignItems="center" width="auto">
      <Box width={138}>{day}</Box>
      <Margin left={20} />
      <Box width={151.5}>{month}</Box>
      <Margin left={20} />
      <Box width={165}>{year}</Box>
    </FlexBoxRow>
  );
};
export default DateSelectBoxLayout;

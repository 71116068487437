import React, { FC } from 'react';
import ContractTimeDepositRenewalReservationDeletePage from './ContractTimeDepositRenewalReservationDeletePage';
import ContractTimeDepositRenewalReservationViewProvider from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationViewProvider';
import ContractTimeDepositRenewalReservationDeleteProvider from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationDeleteProvider';

const EnhancedContractTimeDepositRenewalReservationDeletePage: FC = () => {
  return (
    <ContractTimeDepositRenewalReservationViewProvider>
      <ContractTimeDepositRenewalReservationDeleteProvider>
        <ContractTimeDepositRenewalReservationDeletePage />
      </ContractTimeDepositRenewalReservationDeleteProvider>
    </ContractTimeDepositRenewalReservationViewProvider>
  );
};

export default EnhancedContractTimeDepositRenewalReservationDeletePage;

import React from 'react';

// DomainObject
import TransferDebitCardTransactionV2Seed from './TransferDebitCardTransactionV2Seed';

export interface TransferDebitCardTransactionV2SeedContextProps {
  transferDebitCardTransactionSeed?: TransferDebitCardTransactionV2Seed;
}

const TransferDebitCardTransactionV2SeedContext = React.createContext<
  TransferDebitCardTransactionV2SeedContextProps
>({
  transferDebitCardTransactionSeed: undefined,
});

export default TransferDebitCardTransactionV2SeedContext;

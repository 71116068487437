import React, { FC, useContext } from 'react';
import BankFutureDepositsContext from '../../../../domain/transaction/bankTransaction/bankFutureTransaction/bankFutureDeposit/bankFutureDeposit/BankFutureDeposits/BankFutureDepositsContext';
import InviterContext from '../../../../domain/invite/inviter/Inviter/InviterContext';
import ReferralResultTabBarOptionContext from '../../../../components/organisms/tabBar/ReferralResultTabBar/ReferralResultTabBarOptionContext';
import ReferralsTimeDepositContext from '../../../../domain/invite/referralTimeDeposit/referralTimeDeposit/ReferralsTimeDeposit/ReferralsTimeDepositContext';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Hook
import { useReferralResultsViewUseCase } from './useReferralResultsViewUseCase';

// Component
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type InvitationCodeViewProviderProps = {
  children: React.ReactNode;
};

const InvitationCodesViewProvider: FC<InvitationCodeViewProviderProps> = ({
  children,
}: InvitationCodeViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    bankFutureDeposits,
    inviter,
    referralResultTabBarOptionContext,
    referralsTimeDeposit,
  } = useReferralResultsViewUseCase(userAuthorized);

  return (
    <BankFutureDepositsContext.Provider value={{ bankFutureDeposits }}>
      <InviterContext.Provider value={{ inviter }}>
        <ReferralResultTabBarOptionContext.Provider
          value={referralResultTabBarOptionContext}
        >
          <ReferralsTimeDepositContext.Provider
            value={{ referralsTimeDeposit }}
          >
            <SystemErrorHandler state={state} setState={setState}>
              {children}
            </SystemErrorHandler>
          </ReferralsTimeDepositContext.Provider>
        </ReferralResultTabBarOptionContext.Provider>
      </InviterContext.Provider>
    </BankFutureDepositsContext.Provider>
  );
};
export default InvitationCodesViewProvider;

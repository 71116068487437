// Error
import InvalidDataFoundError from '../../../utils/errors/InvalidDataFoundError';

// Types
import { UseStateType } from '../../../types/typeof/UseState';
import { CryptoCurrencyType } from '../../../types/CryptoCurrency.type';
import { BTCWalletDocumentData } from '../../../infrastructure/firebase/firestore/collections/cryptoCurrency/wallets/BTCWallets/BTCWalletDocumentData.type';
import { ErrorCodeType } from '../../../utils/errors/ErrorHandler/ErrorCode.type';

// Collection
import BTCWalletsCollection from '../../../infrastructure/firebase/firestore/collections/cryptoCurrency/wallets/BTCWallets/BTCWalletsCollection';

// IRepository
import IBitcoinWalletRepository from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/BitcoinWallet/IBitcoinWalletRepository';
import CryptoCurrencyWalletRepository from './CryptoCurrencyWalletRepository';

// DomainObject
import BitcoinWallet from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/BitcoinWallet/BitcoinWallet/BitcoinWallet';
import BitcoinWallets from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/BitcoinWallet/BitcoinWallets/BitcoinWallets';

class BitcoinWalletFirestore
  extends CryptoCurrencyWalletRepository
  implements IBitcoinWalletRepository {
  public findByUserId = async (userId: string) => {
    const docs = await new BTCWalletsCollection(userId).fetchAll();

    if (docs.length === 0)
      throw new InvalidDataFoundError(
        `BitcoinWallet for user '${userId}' is not found.`,
      );

    return docs.reduce<BitcoinWallets>((wallets, doc) => {
      wallets.add(
        new BitcoinWallet(doc.id, {
          ...(doc.data() as BTCWalletDocumentData),
          userId,
          currency: CryptoCurrencyType.BTC,
        }),
      );
      return wallets;
    }, new BitcoinWallets());
  };

  public subscribeByUserId = async (
    userId: string,
    setter: UseStateType<BitcoinWallet | undefined>,
    setState: UseStateType<string>,
  ) => {
    const collectionRef = new BTCWalletsCollection(
      userId,
    ).getCollectionReference();

    // fetchAll
    const unsubscribe = collectionRef.onSnapshot((querySnapshot) => {
      try {
        if (querySnapshot.size === 0) {
          unsubscribe();
          throw new InvalidDataFoundError(
            `BitcoinWallet for user '${userId}' is not found.`,
          );
        }
        // head
        const doc = querySnapshot.docs[0];

        const bitcoinWallet = new BitcoinWallet(doc.id, {
          ...(doc.data() as BTCWalletDocumentData),
          userId,
          currency: CryptoCurrencyType.BTC,
        });
        setter(bitcoinWallet);
      } catch (error) {
        if (process.env.NODE_ENV !== 'production')
          // eslint-disable-next-line no-console
          console.log(error);
        setState(ErrorCodeType.SERVER_ERROR);
      }
    });

    return unsubscribe;
  };

  public getInterface = () => 'IBitcoinWalletRepository';
}
export default BitcoinWalletFirestore;

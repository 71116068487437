// DomainObject
import DomainObjects from '../../../../interface/Iterator/DomainObjects';
import DebitCardRequest from '../DebitCardRequest/DebitCardRequest';

class DebitCardRequests extends DomainObjects<DebitCardRequest> {
  public filterBySavingsAccountNumber = (savingsAccountNumber: string) => {
    const filtered = this.items.filter(
      (item) => item.getProps().savingsAccountNumber === savingsAccountNumber,
    );
    return new DebitCardRequests(filtered);
  };

  public hasNotCompleted = () => {
    const filtered = this.items.filter((item) => !item.getProps().isCompleted);
    return filtered.length > 0;
  };

  public isAllowNewRequest = () =>
    this.hasNotCompleted() || this.items.length === 0;
}

export default DebitCardRequests;

import { getCurrentTimeTimezone } from '../../../../utils/helpers/datetimeHelper';
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
import TransferSavingsAccountTransactionId from './TransferSavingsAccountTransactionId';
import { PrefixTransferSavingsAccountTransactionId } from './TransferSavingsAccountTransactionId.type';
import { TransferSavingsAccountType } from '../../../../types/Transfer.type';

// Error
import InvalidDataFoundError from '../../../../utils/errors/InvalidDataFoundError';

const TRANSACTION_ID_LENGTH = 27;

class TransferSavingsAccountTransactionIdFactory {
  static create = (
    transferSavingsAccountType: TransferSavingsAccountType,
    commonId: string,
    currency: FiatCurrencyType,
    target: string,
    serialNumber: string,
  ): TransferSavingsAccountTransactionId => {
    const prefix =
      PrefixTransferSavingsAccountTransactionId[transferSavingsAccountType];
    const now = getCurrentTimeTimezone();
    const date = now.format('YYYYMMDD');

    if (!prefix)
      throw new Error(`Prefix of ${transferSavingsAccountType} is not found.`);

    return new TransferSavingsAccountTransactionId({
      prefix,
      currency,
      date,
      target,
      commonId,
      serialNumber,
    });
  };

  static createFromRawId = (id: string, commonId: string) => {
    if (id.length !== TRANSACTION_ID_LENGTH)
      throw new InvalidDataFoundError(
        `TransactionId with invalid length '${id}' is  found.`,
      );
    return new TransferSavingsAccountTransactionId({
      prefix: id.substr(0, 4),
      currency: id.substr(4, 3) as FiatCurrencyType,
      date: id.substr(7, 8),
      serialNumber: id.substr(15, 6),
      target: id.substr(21, 6),
      commonId,
    });
  };
}
export default TransferSavingsAccountTransactionIdFactory;

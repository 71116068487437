import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  AssetSavingsAccountProps,
  AssetSavingsAccountPropsFormat,
} from './AssetSavingsAccount.type';
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';

// DomainObject
import AssetSavingsAccount from './AssetSavingsAccount';

class AssetSavingsAccountFactory {
  static create = (id: string, props: AssetSavingsAccountProps) => {
    const result = Joi.object(AssetSavingsAccountPropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: AssetSavingsAccount.name,
          reason: `${result.error.details[0].message}`,
        },
      );

    return new AssetSavingsAccount(id as FiatCurrencyType, props);
  };
}

export default AssetSavingsAccountFactory;

import React, { FC } from 'react';
import { Container, Content } from './style';

export interface SettingsTemplateContainerProps {
  children: React.ReactNode;
}

const SettingsTemplateContainer: FC<SettingsTemplateContainerProps> = ({
  children,
}: SettingsTemplateContainerProps) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  );
};

export default SettingsTemplateContainer;

import { useState, useEffect, useCallback } from 'react';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';

// UseCase
import AuthenticatedPageViewUseCase from '../../../../useCases/authentication/authUser/AuthenticatedPageViewUseCase';

// Repository
import UserFirestore from '../../../../repository/public/user/UserFirestore';

// DomainObject
import UserAuthorizedFactory from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedFactory';
import { useUserAuthorized } from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/useUserAuthorized';
import { useUser } from '../../../../domain/public/user/User/User/useUser';

export const useAuthenticatedPageViewUseCase = (
  userId: string,
  token: string | undefined,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  DomainObject
   *
   * */
  const { user, setUser } = useUser();
  const { userAuthorized, setUserAuthorized } = useUserAuthorized();

  /* *
   *
   *  Repository
   *
   * */
  const userRepository = new UserFirestore();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new AuthenticatedPageViewUseCase(userRepository);

  /* *
   *
   *  Method
   *
   * */
  const openAuthenticatedPage = useCallback(async () => {
    if (!userId || !token) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      await useCase.open(userId, setUser, setState);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [token, userId]);

  /* *
   *
   * UseEffect
   *
   * */

  // UseCase.open
  useEffect(() => {
    let isMounted = true;

    if (isMounted) openAuthenticatedPage();

    return () => {
      isMounted = false;
    };
  }, [openAuthenticatedPage]);

  // SetUserAuthorized
  useEffect(() => {
    if (user && token)
      setUserAuthorized(UserAuthorizedFactory.createByUser(user, token));
    // eslint-disable-next-line
  }, [user, token]);

  return {
    state,
    setState,
    userAuthorized,
    setUserAuthorized,
  };
};

import { FC } from 'react';

// Type
import {
  InviterRankDType,
  InviterRankAType,
} from '../../../../../types/Invite.type';

// Interface
import { IIterable } from '../../../../interface/Iterator/IIterable';
import UserVerified from '../../../../public/user/User/UserAuthorized/UserVerified/UserVerified';

// DomainObject
import ReferralTimeDeposit from '../ReferralTimeDeposit/ReferralTimeDeposit';
import Inviter from '../../../inviter/Inviter/Inviter';
import InviterRankD from '../../../inviter/InviterRankD/InviterRankD';
import InviterRankA from '../../../inviter/InviterRankA/InviterRankA';
import InviterRankDFactory from '../../../inviter/InviterRankD/InviterRankDFactory';
import InviterRankAFactory from '../../../inviter/InviterRankA/InviterRankAFactory';

class ReferralsTimeDeposit implements IIterable<ReferralTimeDeposit> {
  private items: ReferralTimeDeposit[] = [];

  constructor(items?: ReferralTimeDeposit[]) {
    this.items = items || [];
  }

  public add = (item: ReferralTimeDeposit) => {
    this.items.push(item);
  };

  public head = () => this.items[0];

  public map = <U>(callback: (item: ReferralTimeDeposit) => U) => {
    return this.items.map((item) => callback(item));
  };

  public forEach = (callback: (item: ReferralTimeDeposit) => void) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.items) {
      // eslint-disable-next-line no-await-in-loop
      callback(item);
    }
  };

  public forEachAsync = async (
    callback: (item: ReferralTimeDeposit) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.items) {
      // eslint-disable-next-line no-await-in-loop
      await callback(item);
    }
  };

  public mapComponent = (
    item: FC<{ referralTimeDeposit: ReferralTimeDeposit; i: number }>,
  ) => {
    return this.items.map((referralTimeDeposit, i) =>
      item({ referralTimeDeposit, i }),
    );
  };

  public sortByCreatedAt = () => {
    const compare = (a: ReferralTimeDeposit, b: ReferralTimeDeposit) => {
      const [aProps, bProps] = [a.getProps(), b.getProps()];

      return aProps.createdAt < bProps.createdAt ? 1 : -1;
    };
    return new ReferralsTimeDeposit(this.items.sort(compare));
  };

  public createInviter = (userVerified: UserVerified, inviter: Inviter) => {
    if (inviter instanceof InviterRankA) {
      // eslint-disable-next-line no-underscore-dangle
      const inviterRankA = this._createInviterRankA(userVerified);
      return inviterRankA;
    }

    if (inviter instanceof InviterRankD) {
      // eslint-disable-next-line no-underscore-dangle
      const inviterRankD = this._createInviterRankD(userVerified, inviter);
      return inviterRankD;
    }

    throw new Error(`Invalid Inviter found.`);
  };

  private _createInviterRankA = (userVerified: UserVerified) => {
    const userId = userVerified.getId();
    const { username, isDeleted, rank } = userVerified.getProps();

    const totalReferralTimeDepositAmount = this.items.reduce((stats, item) => {
      const { depositAmount } = item.getProps();

      return stats + depositAmount;
    }, 0);

    return InviterRankAFactory.create(userId, {
      username,
      isDeleted,
      rank: rank as InviterRankAType,
      totalReferralTimeDepositAmount,
    });
  };

  private _createInviterRankD = (
    userVerified: UserVerified,
    inviter: InviterRankD,
  ) => {
    const userId = userVerified.getId();
    const { username, isDeleted, rank } = userVerified.getProps();

    const [
      totalCommission,
      totalCommissionReceived,
      totalReferralTimeDepositAmount,
    ] = this.items.reduce(
      (stats, item) => {
        const {
          commissionTotal,
          commissionPaid,
          depositAmount,
        } = item.getProps();

        return [
          stats[0] + commissionTotal,
          stats[1] + commissionPaid,
          stats[2] + depositAmount,
        ];
      },
      [0, 0, 0],
    );

    return InviterRankDFactory.create(userId, {
      username,
      isDeleted,
      rank: rank as InviterRankDType,
      commissionRate: inviter.getProps().commissionRate,
      totalReferralTimeDepositAmount,
      totalCommission,
      totalCommissionReceived,
      totalReferralChildrenTimeDepositAmount: 0,
    });
  };
}
export default ReferralsTimeDeposit;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormPersonalInformationLayoutProps {
  children: React.ReactElement[];
}

const FormPersonalInformationLayout: FC<FormPersonalInformationLayoutProps> = ({
  children,
}: FormPersonalInformationLayoutProps) => {
  const elements = [
    'personalInformation',
    'passportInput',
    'passport',
    'selfie',
    'button',
  ];
  const [
    personalInformation,
    passportInput,
    passport,
    selfie,
    button,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn width={1200} height="auto">
      {personalInformation}
      <Margin top={60} />
      {passportInput}
      <Margin top={60} />
      {passport}
      <Margin top={60} />
      {selfie}
      <Margin top={60} />
      {button}
      <Margin top={83} />
    </FlexBoxColumn>
  );
};
export default FormPersonalInformationLayout;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { Container, IconWrap } from './style';

export interface SavingsAccountTypeBadgeLayoutProps {
  children: React.ReactElement[];
  backgroundColor: string;
}

const SavingsAccountTypeBadgeLayout: FC<SavingsAccountTypeBadgeLayoutProps> = ({
  children,
  backgroundColor,
}: SavingsAccountTypeBadgeLayoutProps) => {
  const elements = ['icon', 'text'];
  const [icon, text] = getElementsFromKeys(children, elements);

  return (
    <Container style={{ backgroundColor }}>
      <IconWrap>{icon}</IconWrap>
      <div style={{ marginLeft: `${10 / 16}rem` }}>{text}</div>
    </Container>
  );
};

export default SavingsAccountTypeBadgeLayout;

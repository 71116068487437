import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Style
import { FormContainerStyle } from './style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface ContractTimeDepositAddCompleteTemplateLayoutProps {
  children: React.ReactElement[];
}

const ContractTimeDepositAddCompleteTemplateLayout: FC<ContractTimeDepositAddCompleteTemplateLayoutProps> = ({
  children,
}: ContractTimeDepositAddCompleteTemplateLayoutProps) => {
  const elements = [
    'header',
    'progressStep',
    'icon',
    'contentTitle',
    'description',
    'orderIdLabel',
    'orderIdValue',
    'propertyList',
  ];
  const [
    header,
    progressStep,
    icon,
    contentTitle,
    description,
    orderIdLabel,
    orderIdValue,
    propertyList,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn
      alignItems="center"
      textAlign="left"
      padding="85 0 0 80"
      width={1280}
      height="auto"
    >
      {header}
      <Margin top={50} />
      <FlexBoxRow justifyContent="center" height="auto">
        {progressStep}
      </FlexBoxRow>
      <Margin top={100} />
      <FlexBoxColumn
        alignItems="center"
        justifyContent="center"
        width={1200}
        height="auto"
        padding="80"
        theme={FormContainerStyle}
      >
        {icon}
        <Margin top={30} />
        {contentTitle}
        <Margin top={20} />
        {description}
        <Margin top={60} />
        <FlexBoxRow alignItems="center" height="auto">
          {orderIdLabel}
          <Margin left={20} />
          {orderIdValue}
        </FlexBoxRow>
        <Margin top={30} />
        {propertyList}
      </FlexBoxColumn>
      <Margin top={100} />
    </FlexBoxColumn>
  );
};
export default ContractTimeDepositAddCompleteTemplateLayout;

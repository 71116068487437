// Type
import { ConsentFormDocumentData } from '../../../../infrastructure/firebase/firestore/collections/verification/consentForm/documentData/ConsentFormDocumentData.type';

class ConsentFormMapperFirestore {
  static documentToProps = (documentData: ConsentFormDocumentData) => {
    const consentFormStepsStatus = {
      status: documentData.status,
      statusConsentForm: documentData.consentForm.status,
      statusTwoFactorAuthenticationAppSettings:
        documentData.twoFactorAuthenticationAppSettings.status,
      statusWaitingConsentFormReview:
        documentData.waitingConsentFormReview.status,
    };

    return {
      username: documentData.username,
      type: documentData.type,
      createdAt: documentData.createdAt,
      updatedAt: documentData.updatedAt,
      consentFormContent: documentData.consentForm,
      consentFormStepsStatus,
    };
  };
}

export default ConsentFormMapperFirestore;

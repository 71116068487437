/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { components } from 'react-select';

// Type
import { CryptoCurrencyType } from '../../../../../../types/CryptoCurrency.type';

// Style
import { Hover } from './style';

// LayoutComponent
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';

// Component
import { CryptoCurrencyIconOptionText } from '../../../../text/IconLabelText';

const CryptoCurrencyOptionContent: FC = (props: any) => (
  <CryptoCurrencyIconOptionText
    iconType={props.data.value as CryptoCurrencyType}
    currencyName={props.data.label}
  />
);

const CryptoCurrencySingleValueContainer: FC = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <FlexBoxRow alignItems="center">
        <CryptoCurrencyOptionContent {...props} />
      </FlexBoxRow>
    </components.SingleValue>
  );
};

const CryptoCurrencyOptionContainer: FC = (props: any) => {
  return (
    <Hover>
      <components.Option {...props}>
        <FlexBoxRow alignItems="center">
          <CryptoCurrencyOptionContent {...props} />
        </FlexBoxRow>
      </components.Option>
    </Hover>
  );
};

export { CryptoCurrencyOptionContainer, CryptoCurrencySingleValueContainer };

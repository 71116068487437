import React, { FC } from 'react';
import { TextThemeProps } from '../../../atoms/text2/Text2';
import ArrowIcon from '../../../atoms/icon/ArrowIcon';
import { LinkTextSecondary } from '../../../atoms/text2/LinkText';
import { TextButtonSecondary } from '../../../atoms/text2/TextButton';

import BreadcrumbLayout from './BreadcrumbLayout';
import color from '../../../atoms/utils/const/color';

export interface BreadcrumbProps {
  href?: string;
  iconSize: string | number;
  marginBetween: string | number;
  text: string;
  theme: {
    icon?: { color: string };
    text: TextThemeProps;
  };
  onClick?: () => void;
}

const iconDefaultColor = color.text.zambezi;

const Breadcrumb: FC<BreadcrumbProps> = ({
  href,
  iconSize,
  onClick,
  marginBetween,
  text,
  theme,
}: BreadcrumbProps) => {
  const linkTextStyle = theme.text.textDecoration
    ? theme.text
    : { ...theme.text, textDecoration: 'underLine' };

  return (
    <BreadcrumbLayout marginBetween={marginBetween}>
      <ArrowIcon
        key="icon"
        type="forward"
        size={iconSize}
        color={theme.icon?.color || iconDefaultColor}
      />
      <span key="link">
        {(() => {
          if (href)
            return (
              <LinkTextSecondary href={href} theme={linkTextStyle}>
                {text}
              </LinkTextSecondary>
            );
          return (
            <TextButtonSecondary onClick={onClick} theme={linkTextStyle}>
              {text}
            </TextButtonSecondary>
          );
        })()}
      </span>
    </BreadcrumbLayout>
  );
};
export default Breadcrumb;

import React, { FC } from 'react';
import { FilterInput } from './style';

export interface TextInputFilterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: { filterValue: any; preFilteredRows: any; setFilter: any };
}

const TextInputFilter: FC<TextInputFilterProps> = ({
  column: { filterValue, preFilteredRows, setFilter },
}: TextInputFilterProps) => {
  const count = preFilteredRows?.length;

  return (
    <FilterInput
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
};

export default TextInputFilter;

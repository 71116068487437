import Joi from '@hapi/joi';

// Constant
import { LOWER_LIMIT_BALANCE_SAVINGS_ACCOUNT_TMP } from '../../../../config/constants/business';

// Type
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
import { SavingsAccountType } from '../../../../types/SavingsAccount.type';

export interface SavingsAccountProps {
  holderUserId: string;

  accountType: SavingsAccountType;

  balance: number;

  currency: FiatCurrencyType;

  pendingBalance: number;

  timeDepositTotal: number;

  createdAt?: number;

  updatedAt?: number;
}

export interface SavingsAccountRawDataType {
  accountNumber: string;

  props: SavingsAccountProps;
}

export const SavingsAccountPropsFormat = {
  holderUserId: Joi.string().min(1).required(),

  accountType: Object.keys(SavingsAccountType)
    .reduce((joi, type) => joi.valid(type), Joi.string())
    .required(),

  balance: Joi.number().min(LOWER_LIMIT_BALANCE_SAVINGS_ACCOUNT_TMP).required(),

  currency: Object.keys(FiatCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),

  pendingBalance: Joi.number().min(0).required(),

  timeDepositTotal: Joi.number().min(0).required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

import React, { FC } from 'react';
import { CryptoCurrencyType } from '../../../../../types/CryptoCurrency.type';
import IconLabeledText from '../IconLabelText';

export interface CryptoCurrencyIconHeaderProps {
  iconType: CryptoCurrencyType;
  currencyName: string;
  iconSize: string;
  textPixel: number;
  textColor: string;
  textFontWeight: number;
  textFontFamilyType: 'primary' | 'sub';
  marginBetween: string;
}

const CryptoCurrencyIconLabelText: FC<CryptoCurrencyIconHeaderProps> = ({
  iconType,
  currencyName,
  iconSize,
  textPixel,
  textColor,
  textFontWeight,
  textFontFamilyType,
  marginBetween,
}: CryptoCurrencyIconHeaderProps) => {
  return (
    <IconLabeledText
      iconType={iconType}
      text={currencyName}
      iconSize={iconSize}
      textSize={textPixel}
      textColor={textColor}
      textFontWeight={textFontWeight}
      textFontFamilyType={textFontFamilyType}
      marginBetween={marginBetween}
    />
  );
};

export default CryptoCurrencyIconLabelText;

import { useState } from 'react';
import SignUpRequest from './SignUpRequest';

export const useSignUpRequest = () => {
  const [signUpRequest, setSignUpRequest] = useState<SignUpRequest>();

  return {
    signUpRequest,
    setSignUpRequest,
  };
};

import { useState } from 'react';
import AssetTimeDeposit from './AssetTimeDeposit';

export const useAssetTimeDeposit = (defaultValue?: AssetTimeDeposit) => {
  const [assetTimeDeposit, setAssetTimeDeposit] = useState<
    AssetTimeDeposit | undefined
  >(defaultValue);

  return {
    assetTimeDeposit,
    setAssetTimeDeposit,
  };
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import Margin from '../../../atoms/utils/layout/Margin';
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';
import ContentBox from '../../../atoms/utils/layout/Box/Box/ContentBox';

export interface NotificationSuccessLayoutProps {
  children: React.ReactElement[];
}

const NotificationSuccessLayout: FC<NotificationSuccessLayoutProps> = ({
  children,
}: NotificationSuccessLayoutProps) => {
  const elements = ['icon', 'title', 'description'];
  const [icon, title, description] = getElementsFromKeys(children, elements);

  return (
    <ContentBox padding="100" height="auto">
      <FlexBoxColumn alignItems="center" textAlign="center">
        {icon}
        <Margin top={30} />
        {title}
        <Margin top={20} />
        {description}
      </FlexBoxColumn>
    </ContentBox>
  );
};
export default NotificationSuccessLayout;

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { firebaseSignOut } from '../../../../infrastructure/firebase/firebaseAuthentication/firebaseAuth';
import { ROUTE_SIGN_IN } from '../../../../config/constants/routing';
import { initializeState } from '../../../../redux/actions/rootActions';

export const useSignOut = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const signOut = async () => {
    try {
      firebaseSignOut();
    } finally {
      dispatch(initializeState());
      history.push(ROUTE_SIGN_IN);
    }
  };

  return { signOut };
};

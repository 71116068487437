// Type
import { KycStatusType } from '../../../../../types/KycRequest.type';
import { KycStepStatusProps } from './KycStepStatus.type';

abstract class KycStepStatus {
  protected status: KycStatusType;

  protected personalInformation: KycStatusType;

  protected proofOfResidency: KycStatusType;

  protected waitingReview: KycStatusType;

  constructor(props: KycStepStatusProps) {
    this.status = props.status;
    this.personalInformation = props.personalInformation;
    this.proofOfResidency = props.proofOfResidency;
    this.waitingReview = props.waitingReview;
  }

  public getKycRequestStatus = () => this.status;

  public getProps() {
    return {
      status: this.status,
      personalInformation: this.personalInformation,
      proofOfResidency: this.proofOfResidency,
      waitingReview: this.waitingReview,
    };
  }

  public isReviewing = () => this.status === KycStatusType.reviewing;
}
export default KycStepStatus;

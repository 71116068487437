import color from 'components/atoms/utils/const/color';
import fontWeight from 'components/atoms/utils/const/fontWeight';

export const ErrorMessageStyle = {
  fontSize: 22,
  color: color.text.error,
  fontWeight: fontWeight.bold,
  textAlign: 'center',
  display: 'block',
};

// Type
import { CurrencyPairType } from '../../../types/CurrencyPair.type';

// IService
import ICryptoCurrencyTradeLimitService from '../../../useCases/cryptoCurrency/cryptoCurrencyTradeLimit/interface/ICryptoCurrencyTradeLimitService';

// IRepository
import ICryptoCurrencyTradeLimitRepository from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeLimit/ICryptoCurrencyTradeLimitRepository';

class CryptoCurrencyTradeLimitQueryService
  implements ICryptoCurrencyTradeLimitService {
  private cryptoCurrencyTradeLimitQueryRepository: ICryptoCurrencyTradeLimitRepository;

  constructor(
    cryptoCurrencyTradeLimitQueryRepository: ICryptoCurrencyTradeLimitRepository,
  ) {
    this.cryptoCurrencyTradeLimitQueryRepository = cryptoCurrencyTradeLimitQueryRepository;
  }

  public findByCurrencyPair = async (currencyPairType: CurrencyPairType) => {
    const cryptoCurrencyRate = await this.cryptoCurrencyTradeLimitQueryRepository.findByCurrencyPair(
      currencyPairType,
    );

    return cryptoCurrencyRate;
  };
}

export default CryptoCurrencyTradeLimitQueryService;

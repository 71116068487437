import React, { FC, useContext, useMemo } from 'react';
import CryptoCurrencyOptionContext from '../../../option/option/CryptoCurrencyOption/CryptoCurrencyOptionContext';
import CryptoCurrenciesAvailableContext from '../../../../../../domain/settings/cryptoCurrencyAvailable/CryptoCurrenciesAvailable/CryptoCurrenciesAvailableContext';
import AssetsCryptoCurrencyContext from '../../../../../../domain/asset/assetCryptoCurrency/AssetsCryptoCurrency/AssetsCryptoCurrencyContext';

// Type
import { CryptoCurrencyType } from '../../../../../../types/CryptoCurrency.type';

// Mapper
import DomainOptionsMapper from '../../../option/option/DomainOptionsMapper';

// Component
import {
  CryptoCurrencyOptionContainer,
  CryptoCurrencySingleValueContainer,
} from '../../../option/optionContainer/CryptoCurrencyOptionContainer';
import SelectBox from '../../../../../atoms/select/SelectBox';

const CryptoCurrencySelectBox: FC = () => {
  // Hooks
  const { cryptoCurrencyOption, setCryptoCurrencyOption } = useContext(
    CryptoCurrencyOptionContext,
  );

  // Domain Object
  const { cryptoCurrenciesAvailable } = useContext(
    CryptoCurrenciesAvailableContext,
  );
  const { assetsCryptoCurrency } = useContext(AssetsCryptoCurrencyContext);

  // Options for SelectBox
  const options = useMemo(
    () =>
      assetsCryptoCurrency.length() > 0
        ? DomainOptionsMapper.assetsCryptoCurrencyToOptions(
            assetsCryptoCurrency,
          )
        : DomainOptionsMapper.cryptoCurrenciesAvailableToOptions(
            cryptoCurrenciesAvailable,
          ),
    [cryptoCurrenciesAvailable, assetsCryptoCurrency],
  );

  return (
    <SelectBox
      borderColor="#d6ddec"
      dropdownColor="#212F41"
      isSearchable={false}
      marginLeft={`${0 / 16}rem`}
      minHeight={`${70 / 16}rem`}
      onSelect={(value: string) => {
        setCryptoCurrencyOption(value as CryptoCurrencyType);
      }}
      options={options || []}
      placeholderColor="#D5D0DA"
      value={cryptoCurrencyOption}
      OptionComponent={CryptoCurrencyOptionContainer}
      SingleValueComponent={CryptoCurrencySingleValueContainer}
    />
  );
};

export default CryptoCurrencySelectBox;

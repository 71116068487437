import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import SavingsAccountTemplate from '../../../components/templates/savingsAccount/SavingsAccountsTemplate';

const SavingsAccountPage: FC = () => {
  return (
    <DashboardTemplate>
      <SavingsAccountTemplate />
    </DashboardTemplate>
  );
};

export default SavingsAccountPage;

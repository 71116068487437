import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../../utils/helpers/reactHelper';
import { FlexRow } from '../../../../../../atoms/utils/layout/Flex';
import { Width } from '../../../../../../atoms/utils/layout/Width';

export interface FormInputSavingsAccountLayoutProps {
  children: React.ReactElement[];
  inputWidth: string | number;
}

const FormInputSavingsAccountLayout: FC<FormInputSavingsAccountLayoutProps> = ({
  children,
  inputWidth,
}: FormInputSavingsAccountLayoutProps) => {
  const elements = ['label', 'input'];
  const [label, input] = getElementsFromKeys(children, elements);

  return (
    <Width widthValue="100%">
      <FlexRow justifyContent="space-between" alignItems="center">
        {label}
        <Width widthValue={inputWidth}>{input}</Width>
      </FlexRow>
    </Width>
  );
};
export default FormInputSavingsAccountLayout;

import React, { FC, useContext } from 'react';
import { useKycRequestCreateUseCase } from './useKycRequestCreateUseCase';
import KycRequestCreateContext from './KycRequestCreateContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

export type KycRequestCreateProviderProps = {
  children: React.ReactNode;
};

const KycRequestCreateProvider: FC<KycRequestCreateProviderProps> = ({
  children,
}: KycRequestCreateProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const { state, setState, createKycRequest } = useKycRequestCreateUseCase(
    userAuthorized,
  );

  return (
    <KycRequestCreateContext.Provider value={{ state, createKycRequest }}>
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </KycRequestCreateContext.Provider>
  );
};

export default KycRequestCreateProvider;

/* eslint-disable @typescript-eslint/no-unused-vars */
// React
import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import ImportantNoticeModalContext from './ImportantNoticeModalContext';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Style
import { contentStyle, overlayStyle } from './style';

// Components
import FormNotificationReadStatusHandled from '../../form/notification/FormNotificationReadStatusHandled';
import ImportantNoticeModalLayout from './ImportantNoticeModalLayout';
import NotificationImportant from '../../notification/NotificationImportant';

const ImportantNoticeModal = () => {
  const { t } = useTranslation();
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const { hideModal } = useContext(ImportantNoticeModalContext);

  if (!userAuthorized) return <></>;

  return (
    <ReactModal
      isOpen={true}
      style={{ content: contentStyle, overlay: overlayStyle }}
      ariaHideApp={false}
    >
      <ImportantNoticeModalLayout>
        <NotificationImportant key="notification" />
        <FormNotificationReadStatusHandled key="form" />
      </ImportantNoticeModalLayout>
    </ReactModal>
  );
};

export default ImportantNoticeModal;

// Type
import { ReferralTimeDepositProps } from './ReferralTimeDeposit.type';
import { ReferralTimeDepositByRankAProps } from '../../referralTimeDepositByRankA/ReferralTimeDepositByRankA/ReferralTimeDepositByRankA.type';
import { ReferralTimeDepositByRankDProps } from '../../referralTimeDepositByRankD/ReferralTimeDepositByRankD/ReferralTimeDepositByRankD.type';

// DomainObject
import ReferralTimeDepositByRankDFactory from '../../referralTimeDepositByRankD/ReferralTimeDepositByRankD/ReferralTimeDepositByRankDFactory';
import ReferralTimeDepositByRankAFactory from '../../referralTimeDepositByRankA/ReferralTimeDepositByRankA/ReferralTimeDepositByRankAFactory';

class ReferralTimeDepositFactory {
  static createByProps = (id: string, props: ReferralTimeDepositProps) => {
    if ('secondLevelInviterId' in props)
      return ReferralTimeDepositByRankAFactory.create(
        id,
        props as ReferralTimeDepositByRankAProps,
      );

    return ReferralTimeDepositByRankDFactory.create(
      id,
      props as ReferralTimeDepositByRankDProps,
    );
  };
}

export default ReferralTimeDepositFactory;

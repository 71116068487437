// Type
import { DebitCardIssuerType } from '../../../../types/DebitCard.type';

// IRepository
import IDebitCardRequestRepository from '../../../../domain/debitCard/debitCardRequest/IDebitCardRequestRepository';

class DebitCardRequestCreateUseCase {
  private debitCardRequestRepository: IDebitCardRequestRepository;

  constructor(debitCardRequestRepository: IDebitCardRequestRepository) {
    this.debitCardRequestRepository = debitCardRequestRepository;
  }

  public executeCreateInitial = async (
    savingsAccountNumber: string,
    issuer: DebitCardIssuerType,
  ) => {
    const createDebitCardRequest = this.debitCardRequestRepository.create(
      savingsAccountNumber,
      issuer,
    );

    const [debitCardRequestCreated] = await Promise.all([
      createDebitCardRequest,
    ]);

    return { debitCardRequest: debitCardRequestCreated };
  };
}

export default DebitCardRequestCreateUseCase;

import React, { FC } from 'react';

// Error
import InvalidDataFoundError from '../../../../../utils/errors/InvalidDataFoundError';

// Type
import { ProgressStepCircleStatusType } from './ProgressStepCircle.type';

// Component
import ProgressStepCurrent from '../ProgressStepCurrent';
import ProgressStepDone from '../ProgressStepDone';
import ProgressStepClose from '../ProgressStepClose';

export interface ProgressStepCircleProps {
  diameterLargeCircle: string | number;
  diameterSmallCircle: string | number;
  theme: {
    circleStyle: {
      backgroundColorLarge: string;
      backgroundColorSmall: string;
      backgroundColorDisabled: string;
    };
    iconStyle: {
      iconColor: string;
      iconSize: string;
    };
  };
  status: ProgressStepCircleStatusType;
}

const ProgressStepCircle: FC<ProgressStepCircleProps> = ({
  diameterLargeCircle,
  diameterSmallCircle,
  status,
  theme,
}: ProgressStepCircleProps) => {
  switch (status) {
    case 'current':
      return (
        <ProgressStepCurrent
          diameterLargeCircle={diameterLargeCircle}
          diameterSmallCircle={diameterSmallCircle}
          theme={theme.circleStyle}
        />
      );
    case 'done':
      return (
        <ProgressStepDone
          diameterCircle={diameterLargeCircle}
          theme={{
            iconStyle: theme.iconStyle,
            circleStyle: {
              backgroundColor: theme.circleStyle.backgroundColorLarge,
            },
          }}
        />
      );
    case 'close':
      return (
        <ProgressStepClose
          diameterCircle={diameterLargeCircle}
          theme={{
            backgroundColor: theme.circleStyle.backgroundColorDisabled,
          }}
        />
      );
    default:
      throw new InvalidDataFoundError(
        `Invalid progressStep ${status} is found at ProgressStepCircle`,
      );
  }
};
export default ProgressStepCircle;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle } from './style';

// Component
import { FrontPageTitle } from '../../../atoms/headLine/HeadLine';
import { TextSecondary } from '../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';
import FormReregistrationTwoFactorAuth from '../../../organisms/form/front/FormReregistrationTwoFactorAuth';
import ReregistrationTwoFactorAuthTemplateLayout from './ReregistrationTwoFactorAuthTemplateLayout';

const TEMPLATE_ID = `templates.authentication.ReregistrationTwoFactorAuthTemplate`;

const ReregistrationTwoFactorAuthTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <ReregistrationTwoFactorAuthTemplateLayout>
      <FrontPageTitle key="title">{t(`${TEMPLATE_ID}.title`)}</FrontPageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced text={t(`${TEMPLATE_ID}.description`)} />
      </TextSecondary>
      <FormReregistrationTwoFactorAuth key="form" />
    </ReregistrationTwoFactorAuthTemplateLayout>
  );
};

export default ReregistrationTwoFactorAuthTemplate;

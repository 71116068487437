import { CurrencyPairType } from '../../../../types/CurrencyPair.type';
import { CryptoCurrencyTradeFeeRateProps } from './CryptoCurrencyTradeFeeRate.type';

class CryptoCurrencyTradeFee {
  private currencyPair: CurrencyPairType;

  private rate: number;

  private createdAt: number;

  private updatedAt: number;

  private props: CryptoCurrencyTradeFeeRateProps;

  constructor(
    currencyPair: CurrencyPairType,
    props: CryptoCurrencyTradeFeeRateProps,
  ) {
    this.currencyPair = currencyPair;
    this.rate = props.rate;
    this.createdAt = props.createdAt || Date.now();
    this.updatedAt = props.updatedAt || Date.now();
    this.props = props;
  }

  public getCurrencyPair = () => this.currencyPair;

  public getProps() {
    return this.props;
  }

  public getRate = () => this.rate;
}
export default CryptoCurrencyTradeFee;

import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  ContractTimeDepositProps,
  ContractTimeDepositPropsFormat,
} from './ContractTimeDeposit.type';

// DomainObject
import ContractTimeDepositSeed from '../ContractTimeDepositSeed/ContractTimeDepositSeed';
import ContractTimeDeposit from './ContractTimeDeposit';
import SerialNumberContractTimeDeposit from '../../../idManager/productSerialNumber/ProductSerialNumber/SerialNumber/concreteSerialNumber/SerialNumberContractTimeDeposit';

class ContractsTimeDepositFactory {
  static create = (
    id: SerialNumberContractTimeDeposit,
    props: ContractTimeDepositProps,
  ) => {
    const result = Joi.object(ContractTimeDepositPropsFormat).validate({
      id: id.getRawId(),
      ...props,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: ContractTimeDeposit.name,
          reason: result.error.details[0].message,
        },
      );

    return new ContractTimeDeposit(id, props);
  };

  static createFromSeed = (
    contractTimeDepositSeed: ContractTimeDepositSeed,
    newId: string,
  ) => {
    const contractTimeDepositId = SerialNumberContractTimeDeposit.createNumberFromRawId(
      newId,
    );
    return new ContractTimeDeposit(
      contractTimeDepositId,
      contractTimeDepositSeed.getProps(),
    );
  };
}
export default ContractsTimeDepositFactory;

// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Type
import { DebitCardV2Props } from '../../domain/debitCard/debitCardV2/DebitCardV2/DebitCardV2.type';

export interface DebitCardV2State {
  [id: string]: DebitCardV2Props;
}

interface DebitCardV2sState {
  id: DebitCardV2State;
  userId: {
    [userId: string]: DebitCardV2State;
  };
}

const initialState: DebitCardV2sState = {
  id: {},
  userId: {},
};

const debitCardSlice = createSlice({
  name: 'debitCardV2s',
  initialState,
  reducers: {
    setDebitCardV2s: (
      state: DebitCardV2sState,
      action: PayloadAction<DebitCardV2State>,
    ) => ({
      ...state,
      id: {
        ...action.payload,
      },
    }),
    setDebitCardV2sByUserId: (
      state: DebitCardV2sState,
      action: PayloadAction<{
        userId: string;
        state: DebitCardV2State;
      }>,
    ) => ({
      ...state,
      userId: {
        [action.payload.userId]: { ...action.payload.state },
      },
    }),
  },
});

export const {
  setDebitCardV2s,
  setDebitCardV2sByUserId,
} = debitCardSlice.actions;
export default debitCardSlice.reducer;

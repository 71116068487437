import React, { FC } from 'react';
import { MenuType } from '../../../../config/menu/main/Menu.type';
import { DebitCardMenuType } from '../../../../config/menu/sub/DebitCardMenu.type';
import MenuItemWithSub from '../MenuItemWithSub';

const MAIN_MENU_TYPE = MenuType.debitCards;

const DebitCardMenu: FC = () => {
  const DebitCardMenuItems = Object.keys(DebitCardMenuType);

  return (
    <MenuItemWithSub
      key={MAIN_MENU_TYPE}
      type={MAIN_MENU_TYPE}
      menuItems={DebitCardMenuItems}
    />
  );
};

export default DebitCardMenu;

// Type
import { UserType } from '../../../types/User.type';

// IUseCase
import IKycRequestCreateUseCase, {
  OutputKycRequestCreate,
} from '../../../enhancers/useCase/verification/kycRequest/KycRequestCreateProvider/IKycRequestCreateUseCase';

// IService
import IKycRequestQueryService from './IKycRequestQueryService';
import IKycRequestUpsertService from './IKycRequestUpsertService';
import IConsentFormUpsertService from '../consentForm/IConsentFormUpsertService';
import IUserUpdateService from '../../public/user/interface/IUserUpdateService';

// Service
import KycRequestUpsertService from '../../../service/verification/kycRequest/KycRequestUpsertService';
import ConsentFormUpsertService from '../../../service/verification/consentForm/ConsentFormUpsertService';

// IRepository
import IKycRequestRepository from '../../../domain/verification/kycRequest/KycRequest/IKycRequestRepository';
import IConsentFormRepository from '../../../domain/verification/consentForm/IConsentFormRepository';
import IUserRepository from '../../../domain/public/user/User/IUserRepository';

// DomainObject
import UserUpdateService from '../../../service/public/user/UserUpdateService';
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import KycRequestQueryService from '../../../service/verification/kycRequest/KycRequestQueryService';

class KycRequestCreateUserCase implements IKycRequestCreateUseCase {
  private kycRequestQueryService: IKycRequestQueryService;

  private kycRequestUpsertService: IKycRequestUpsertService;

  private consentFormUpsertService: IConsentFormUpsertService;

  private userUpdateService: IUserUpdateService;

  constructor(
    kycRequestRepository: IKycRequestRepository,
    consentFormRepository: IConsentFormRepository,
    userRepository: IUserRepository,
  ) {
    this.kycRequestQueryService = new KycRequestQueryService(
      kycRequestRepository,
    );

    this.kycRequestUpsertService = new KycRequestUpsertService(
      kycRequestRepository,
    );

    this.consentFormUpsertService = new ConsentFormUpsertService(
      consentFormRepository,
    );

    this.userUpdateService = new UserUpdateService(userRepository);
  }

  public createInitial = async (user: UserAuthorized, userType: UserType) => {
    const id = user.getId();

    /* *
     *
     *  QueryService
     *
     * */

    const kycRequest = await this.kycRequestQueryService.findByIdAndUserType(
      id,
      userType,
    );

    /* *
     *
     *  CreateService
     *
     * */
    const kycRequestInitial =
      kycRequest ||
      this.kycRequestUpsertService.createInitialByUserType(user, userType);

    const consentForm = this.consentFormUpsertService.createInitial(
      user,
      userType,
    );

    const userUpdated = this.userUpdateService.updateUserType(user, userType);

    return {
      kycRequest: kycRequestInitial,
      consentForm,
      user: userUpdated,
    };
  };

  public executeCreate = async (objects: OutputKycRequestCreate) => {
    const { kycRequest, consentForm, user } = objects;

    const upsertKycRequest = this.kycRequestUpsertService.executeUpsert(
      kycRequest,
    );

    const upsertConsentForm = this.consentFormUpsertService.executeUpsert(
      consentForm,
    );

    const updateUser = this.userUpdateService.executeUpdate(user);

    await Promise.all([upsertKycRequest, upsertConsentForm, updateUser]);

    return objects;
  };
}

export default KycRequestCreateUserCase;

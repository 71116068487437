import React, { FC, useEffect, useState, useContext } from 'react';
import speakeasy from 'speakeasy';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import UpdateAuthenticatorContext from '../../../../../enhancers/auth/authenticator/UpdateAuthenticator/UpdateAuthenticatorContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Type
import { FormInputTwoFactorAuthNewAccountKey } from './FormTwoFactorAuthNewAccountKey.type';

// Constant
import { ACCOUNT_KEY_LENGTH } from '../../../../../config/constants/business';
import { IS_LOADING } from '../../../../../config/constants/requestState';
import { ROUTE_KYC_STATUS } from '../../../../../config/constants/routing';

// Component
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../atoms/button/Button2';
import LabelSendIcon from '../../../../atoms/button/ButtonLabel/LabelSendIcon';
import AccountKeyDetail from '../../twoFactorAuthAppSettings/FormTwoFactorAuthAppSettings/AccountKeyDetail';
import AttentionEditSetting from '../../twoFactorAuthAppSettings/AttentionEditSetting';
import FormTwoFactorAuthCode from '../../twoFactorAuthAppSettings/FormTwoFactorAuthAppSettings/FormTwoFactorAuthCode';
import FormTwoFactorAuthNewAccountKeyLayout from './FormTwoFactorAuthNewAccountKeyLayout';

const FormTwoFactorAuthNewAccountKey: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [accountKey, setAccountKey] = useState<string | undefined>();

  // DomainObject
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // Method
  const { updateAuthenticatorState, update } = useContext(
    UpdateAuthenticatorContext,
  );

  /* *
   *
   *  Form
   *
   * */
  const methods = useForm<
    FormInputTwoFactorAuthNewAccountKey & { validCode: string }
  >({
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  /* *
   *
   *  UseEffect
   *
   * */
  useEffect(() => {
    setAccountKey(
      speakeasy.generateSecret({ length: ACCOUNT_KEY_LENGTH }).base32,
    );
  }, []);

  // Props
  const accountName = userAuthorized?.getProps().email;

  return (
    <form
      onSubmit={handleSubmit(
        (formInput: FormInputTwoFactorAuthNewAccountKey) => {
          if (accountKey)
            update(accountKey, formInput.code, () => {
              history.push(ROUTE_KYC_STATUS);
            });
        },
      )}
    >
      <FormProvider {...methods}>
        <FormTwoFactorAuthNewAccountKeyLayout>
          <AttentionEditSetting key="attention" />
          <AccountKeyDetail
            key="accountKey"
            accountKey={accountKey}
            accountName={accountName}
          />
          <FormTwoFactorAuthCode key="form" state={updateAuthenticatorState} />
          <SubmitButton
            key="button"
            disabled={!isValid}
            height={75}
            isLoading={updateAuthenticatorState === IS_LOADING}
            theme={{ borderRadius: `${10 / 16}rem` }}
            width={'100%'}
          >
            <LabelSendIcon
              iconSize={26}
              iconStyle={{ color: 'white' }}
              label={t(`atoms.button.submit`)}
              marginBetween={8}
              textStyle={DefaultButtonTextStyle}
            />
          </SubmitButton>
        </FormTwoFactorAuthNewAccountKeyLayout>
      </FormProvider>
    </form>
  );
};

export default FormTwoFactorAuthNewAccountKey;

import React, { useState } from 'react';

export interface FormInputResetAuthenticatorTwoFactorAuth {
  code: string;
}

export const useFormInputResetAuthenticatorTwoFactorAuth = () => {
  const [formInput, setFormInput] = useState<
    FormInputResetAuthenticatorTwoFactorAuth
  >();

  return {
    formInput,
    setFormInput,
  };
};

export const FormInputResetAuthenticatorTwoFactorAuthContext = React.createContext<{
  formInput?: FormInputResetAuthenticatorTwoFactorAuth;
  setFormInput: (formInput?: FormInputResetAuthenticatorTwoFactorAuth) => void;
}>({
  formInput: undefined,

  setFormInput: () => undefined,
});

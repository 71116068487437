import styled from 'styled-components';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  box-sizing: border-box;
  width: ${688 / 16}rem;
  padding: ${60 / 16}rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DepositResultContainer = styled.div`
  box-sizing: border-box;
  padding: ${10 / 16}rem ${20 / 16}rem;
  border: ${1 / 16}rem solid #707070;
`;

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.book,
  lineHeight: 44,
};

export const DepositResultTextStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.bold,
  lineHeight: 44,
};

export const ImportantStyle = {
  fontSize: 28,
  color: color.red,
  fontWeight: fontWeight.semibold,
  lineHeight: 44,
};

// Error
import SystemErrorFactory from '../../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  CryptoCurrencyType,
  CryptoCurrencyTradeType,
} from '../../../../../../types/CryptoCurrency.type';
import { CryptoCurrencyTradeTransactionSeedProps } from './CryptoCurrencyTradeTransactionSeed.type';
import { FiatCurrencyType } from '../../../../../../types/FiatCurrency.type';

// DomainObject
import CryptoCurrencyBuyTransactionSeedFactory from '../CryptoCurrencyBuyTransactionSeed/CryptoCurrencyBuyTransactionSeedFactory';
import CryptoCurrencySellTransactionSeedFactory from '../CryptoCurrencySellTransactionSeed/CryptoCurrencySellTransactionSeedFactory';

class CryptoCurrencyTradeTransactionSeedFactory {
  static create(props: CryptoCurrencyTradeTransactionSeedProps) {
    switch (props.tradeType) {
      case CryptoCurrencyTradeType.buy:
        return CryptoCurrencyBuyTransactionSeedFactory.create({
          ...props,
          payCurrency: props.payCurrency as FiatCurrencyType,
          receiveCurrency: props.receiveCurrency as CryptoCurrencyType,
        });
      case CryptoCurrencyTradeType.sell:
        return CryptoCurrencySellTransactionSeedFactory.create({
          ...props,
          payCurrency: props.payCurrency as CryptoCurrencyType,
          receiveCurrency: props.receiveCurrency as FiatCurrencyType,
        });
      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
          {
            type: 'CryptoCurrencyTradeType',
            place: 'CryptoCurrencyTradeTransactionSeedFactory.create',
          },
        );
    }
  }
}

export default CryptoCurrencyTradeTransactionSeedFactory;

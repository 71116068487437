import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  ReferralTimeDepositByChildrenProps,
  ReferralTimeDepositByChildrenPropsFormat,
} from './ReferralTimeDepositByChildren.type';

// DomainObject
import ReferralTimeDepositByChildren from './ReferralTimeDepositByChildren';

class ReferralTimeDepositByChildrenFactory {
  static create = (id: string, props: ReferralTimeDepositByChildrenProps) => {
    const result = Joi.object(
      ReferralTimeDepositByChildrenPropsFormat,
    ).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: ReferralTimeDepositByChildren.name,
          reason: result.error.details[0].message,
        },
      );

    return new ReferralTimeDepositByChildren(id, props);
  };
}

export default ReferralTimeDepositByChildrenFactory;

import React, { FC } from 'react';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InvitationRankIconProps {}

const Container = styled.div`
  width: ${71 / 16}rem;
  height: ${71 / 16}rem;
  background-color: #afafaf;
  border-radius: 50%;
`;

// eslint-disable-next-line no-empty-pattern
const InvitationRankIcon: FC<InvitationRankIconProps> = ({}: InvitationRankIconProps) => (
  <Container />
);
export default InvitationRankIcon;

import React, { FC } from 'react';
import CircledText from './CircledText';
import fontColor from '../../../../fonts/color.json';
import fontWeight from '../../../../fonts/fontWeight.json';

export interface AttentionIconProps {
  size?: string;
  textSize?: string;
}

const AttentionIcon: FC<AttentionIconProps> = ({
  size = `${32 / 16}rem`,
  textSize = `${16 / 16}rem`,
}: AttentionIconProps) => (
  <CircledText
    text="!"
    textColor={fontColor.textBright}
    size={size}
    textSize={textSize}
    backgroundColor="#D06E77"
    textFontWeight={fontWeight.bold}
  />
);
export default AttentionIcon;

// IService
import ICryptoCurrencyAvailableQueryService from '../../useCases/settings/ICryptoCurrencyAvailableQueryService';

// IRepository
import { ICryptoCurrencyAvailableQueryRepository } from '../../domain/settings/cryptoCurrencyAvailable/ICryptoCurrencyAvailableRepository';

class CryptoCurrencyAvailableQueryService
  implements ICryptoCurrencyAvailableQueryService {
  private cryptoCurrencyAvailableQueryRepository: ICryptoCurrencyAvailableQueryRepository;

  constructor(
    cryptoCurrencyAvailableQueryRepository: ICryptoCurrencyAvailableQueryRepository,
  ) {
    this.cryptoCurrencyAvailableQueryRepository = cryptoCurrencyAvailableQueryRepository;
  }

  public findAll = async () => {
    const cryptoCurrenciesAvailable = await this.cryptoCurrencyAvailableQueryRepository.findAll();

    return cryptoCurrenciesAvailable;
  };
}

export default CryptoCurrencyAvailableQueryService;

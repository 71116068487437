import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle } from './style';

// LayoutComponent
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';

// Component
import { TextPrimary } from '../../../../../atoms/text2/Text2';

const TEMPLATE_ID = `templates.debitCards.DebitCardTemplate`;

const NoDebitCardTemplateContent: FC = () => {
  const { t } = useTranslation();

  return (
    <FlexBoxRow alignItems="center" height={432} justifyContent="center">
      <TextPrimary theme={DescriptionStyle}>
        {t(`${TEMPLATE_ID}.description.noCard`)}
      </TextPrimary>
    </FlexBoxRow>
  );
};
export default NoDebitCardTemplateContent;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';

// Style
import { Content } from './style';

// LayoutComponent
import Margin from '../../../../atoms/utils/layout/Margin';
import { FlexBoxRow } from '../../../../atoms/utils/layout/Box/FlexBox';

// Component
import TransferTemplateContainer from '../../common/TransferTemplateContainer';

export interface TransferSavingsAccountConfirmTemplateLayoutProps {
  children: React.ReactElement[];
}

const TransferSavingsAccountConfirmTemplateLayout: FC<TransferSavingsAccountConfirmTemplateLayoutProps> = ({
  children,
}: TransferSavingsAccountConfirmTemplateLayoutProps) => {
  const elements = [
    'header',
    'progressStep',
    'icon',
    'contentTitle',
    'description',
    'orderIdLabel',
    'orderIdValue',
    'table',
  ];
  const [
    header,
    progressStep,
    icon,
    contentTitle,
    description,
    orderIdLabel,
    orderIdValue,
    table,
  ] = getElementsFromKeys(children, elements);

  return (
    <TransferTemplateContainer>
      {header}
      <Margin top={50} />
      <FlexBoxRow justifyContent="center">{progressStep}</FlexBoxRow>
      <Margin top={100} />
      <Content>
        {icon}
        <Margin top={30} />
        {contentTitle}
        <Margin top={20} />
        {description}
        <Margin top={60} style={{ width: '100%' }} />
        <FlexBoxRow justifyContent="flex-start">
          {orderIdLabel}
          <Margin left={20} />
          {orderIdValue}
        </FlexBoxRow>
        <Margin top={30} />
        {table}
      </Content>
    </TransferTemplateContainer>
  );
};
export default TransferSavingsAccountConfirmTemplateLayout;

import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import DebitCardOptionContext from '../../../../molecules/selectBox/option/option/DebitCardOption/DebitCardOptionContext';
import TransferDebitCardTransactionV2SeedContext from '../../../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2Seed/TransferDebitCardTransactionV2SeedContext';

// Mapper
import TransferDebitCardTransactionTableMapper from './TransferDebitCardTransactionTableMapper';

// Style
import { TableVerticalLayout, TableVerticalStyle } from './style';

// Component
import TableVertical from '../../../../molecules/table/table/TableVertical';

const TableConfirmTransferDebitCard: FC = () => {
  const { t } = useTranslation();

  const { transferDebitCardTransactionSeed } = useContext(
    TransferDebitCardTransactionV2SeedContext,
  );
  const { debitCardOption } = useContext(DebitCardOptionContext);

  const rows = useMemo(
    () =>
      !transferDebitCardTransactionSeed || !debitCardOption
        ? []
        : TransferDebitCardTransactionTableMapper.toTableData(
            debitCardOption,
            transferDebitCardTransactionSeed,
            t,
          ),
    [debitCardOption, transferDebitCardTransactionSeed, t],
  );

  return (
    <TableVertical
      isTableBorder
      rows={rows}
      layout={TableVerticalLayout}
      theme={TableVerticalStyle}
    />
  );
};

export default TableConfirmTransferDebitCard;

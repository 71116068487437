import { useState } from 'react';

// Error
import ErrorHandler from '../../../utils/errors/ErrorHandler/ErrorHandler';

// Functions
import FirebasePublicFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePublicFunctions';

// Lib
import { checkInternetConnection } from '../../../utils/helpers/connection';

// Constant
import { USER_REQUEST_FORGOT_PASSWORD } from '../../../infrastructure/firebase/firebaseFunctions/endpoint';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../config/constants/requestState';

export const useForgotPassword = () => {
  const [state, setState] = useState<string>(INITIAL);

  const forgotPassword = async (email: string) => {
    setState(IS_LOADING);

    try {
      // ---check onLine ---- //
      checkInternetConnection();

      await new FirebasePublicFunctions().publicPOST(
        USER_REQUEST_FORGOT_PASSWORD,
        { email },
      );

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  return {
    state,
    setState,
    forgotPassword,
  };
};

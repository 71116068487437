import styled from 'styled-components';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  min-height: 100%;
  box-sizing: border-box;
  padding-top: ${50 / 16}rem;
`;

export const Content = styled.div`
  overflow: scroll;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Main = styled.div`
  height: 100%;
  margin-top: ${70 / 16}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  lineHeight: 44,
};

import React, { FC } from 'react';
import { TextComponentProps } from '../TextComponent.type';
import { FontFamilyLato } from '../../textFont/fontFamily';
import FontSize from '../../textFont/fontSize';
import Text from '../Text';

const TextSub: FC<TextComponentProps> = ({
  children,
  pixel,
  color,
  fontWeight,
  textDecoration,
  opacity = 1,
}: TextComponentProps) => (
  <FontFamilyLato fontWeight={fontWeight}>
    <FontSize pixel={pixel}>
      <Text color={color} textDecoration={textDecoration} opacity={opacity}>
        {children}
      </Text>
    </FontSize>
  </FontFamilyLato>
);
export default TextSub;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import VerifyAuthenticatorContext from '../../../../../enhancers/auth/authenticator/VerifyAuthenticator/VerifyAuthenticatorContext';
import { FormInputTwoFactorAuthAppEdit } from './FormTwoFactorAuthAppEdit.type';
import { IS_LOADING } from '../../../../../config/constants/requestState';
import { Container, ButtonContainer } from './style';
import FormInputAuthenticator from '../../../../molecules/form/formInput/textField/FormInputAuthenticator';
import { SubmitButton } from '../../../../atoms/button/DomainButton';

const FormTwoFactorAuthAppEdit: FC = () => {
  const { t } = useTranslation();

  const { verifyAuthenticatorState, verify } = useContext(
    VerifyAuthenticatorContext,
  );

  const methods = useForm<FormInputTwoFactorAuthAppEdit>({
    mode: 'onChange',
  });

  const {
    formState: { isValid },
    handleSubmit,
    watch,
  } = methods;

  return (
    <Container>
      <FormProvider {...methods}>
        <div style={{ width: '100%' }}>
          <form
            onSubmit={handleSubmit(() => {
              verify(watch('code'));
            })}
          >
            <FormInputAuthenticator state={verifyAuthenticatorState} />
            <div style={{ marginTop: `${50 / 16}rem` }}>
              <ButtonContainer>
                <SubmitButton
                  name={t(`atoms.button.continue`)}
                  width={`${470 / 16}rem `}
                  height={`${74 / 16}rem `}
                  borderRadius={`${10 / 16}rem `}
                  disabled={!isValid}
                  isLoading={verifyAuthenticatorState === IS_LOADING}
                />
              </ButtonContainer>
            </div>
          </form>
        </div>
      </FormProvider>
    </Container>
  );
};
export default FormTwoFactorAuthAppEdit;

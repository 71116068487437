import Joi from '@hapi/joi';
import { BankDepositType } from '../../../../../../types/BankAccountTransaction.type';
import {
  BankSpotTransactionProps,
  BankSpotTransactionPropsFormat,
} from '../../bankSpotTransaction/BankSpotTransaction/BankSpotTransaction.type';

export interface BankDepositProps extends BankSpotTransactionProps {
  bankDepositType: BankDepositType;
}

export const BankDepositPropsFormat = {
  ...BankSpotTransactionPropsFormat,

  bankDepositType: Object.keys(BankDepositType)
    .reduce((joi, bankDepositType) => joi.valid(bankDepositType), Joi.string())
    .required(),
};

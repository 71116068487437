import React, { FC } from 'react';

// Lib
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface ContractTimeDepositAddFormLayoutProps {
  children: React.ReactElement[];
}

const ContractTimeDepositAddFormLayout: FC<ContractTimeDepositAddFormLayoutProps> = ({
  children,
}: ContractTimeDepositAddFormLayoutProps) => {
  const elements = [
    'savingsAccount',
    'savingsAccountError',
    'payOut',
    'timeDeposit',
    'amount',
    'button',
  ];
  const [
    savingsAccount,
    savingsAccountError,
    payOut,
    timeDeposit,
    amount,
    button,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn height="auto">
      {savingsAccount}
      <FlexBoxRow paddingLeft={1200 - 80 - 80 - 600}>
        {savingsAccountError}
      </FlexBoxRow>
      <Margin top={80} />
      {payOut}
      <Margin top={80} />
      {timeDeposit}
      <Margin top={80} />
      {amount}
      <Margin top={80} />
      {button}
    </FlexBoxColumn>
  );
};

export default ContractTimeDepositAddFormLayout;

// Type
import {
  KycStepStatusProps,
  KycStepStatusPropsFormat,
  KycStepStatusInitialProps,
} from '../KycStepStatus/KycStepStatus.type';

export type KycStepStatusIndividualProps = KycStepStatusProps;

export const KycStepStatusIndividualPropsFormat = KycStepStatusPropsFormat;

export const KycStepStatusIndividualInitialProps = KycStepStatusInitialProps;

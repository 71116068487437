import React from 'react';
import AssetTimeDeposit from './AssetTimeDeposit';

export interface AssetTimeDepositContextProps {
  assetTimeDeposit?: AssetTimeDeposit;
}

const AssetTimeDepositContext = React.createContext<
  AssetTimeDepositContextProps
>({
  assetTimeDeposit: undefined,
});

export default AssetTimeDepositContext;

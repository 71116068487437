import React, { FC } from 'react';
import ReregistrationPage from './ReregistrationPage';
import ReregistrationViewProvider from '../../../enhancers/useCase/authentication/ReregistrationViewProvider';
import ReregisterProvider from '../../../enhancers/useCase/authentication/ReregisterProvider';

const EnhancedRegisterPage: FC = () => {
  return (
    <ReregistrationViewProvider>
      <ReregisterProvider>
        <ReregistrationPage />
      </ReregisterProvider>
    </ReregistrationViewProvider>
  );
};

export default EnhancedRegisterPage;

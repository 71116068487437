import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  CryptoCurrencyAvailableProps,
  CryptoCurrencyAvailablePropsFormat,
} from './CryptoCurrencyAvailable.type';
import { CryptoCurrencyType } from '../../../../types/CryptoCurrency.type';

// DomainObject
import CryptoCurrencyAvailable from './CryptoCurrencyAvailable';

class CryptoCurrencyAvailableFactory {
  static create = (
    id: CryptoCurrencyType,
    props: CryptoCurrencyAvailableProps,
  ) => {
    const result = Joi.object(CryptoCurrencyAvailablePropsFormat).validate({
      id,
      ...props,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: CryptoCurrencyAvailable.name,
          reason: result.error.details[0].message,
        },
      );

    return new CryptoCurrencyAvailable(id, props);
  };
}

export default CryptoCurrencyAvailableFactory;

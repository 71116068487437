import { useState } from 'react';
import AssetsCryptoCurrency from './AssetsCryptoCurrency';

export const useAssetsCryptoCurrency = (
  defaultValue?: AssetsCryptoCurrency,
) => {
  const [assetsCryptoCurrency, setAssetsCryptoCurrency] = useState<
    AssetsCryptoCurrency
  >(defaultValue || new AssetsCryptoCurrency());

  return {
    assetsCryptoCurrency,
    setAssetsCryptoCurrency,
  };
};

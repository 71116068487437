import { useState } from 'react';

export const usePersonalInformationFile = () => {
  const [passport, setPassport] = useState<File | undefined>();
  const [selfie, setSelfie] = useState<File | undefined>();

  return {
    passport,
    setPassport,
    selfie,
    setSelfie,
  };
};

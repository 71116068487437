/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { INITIAL } from '../../../../config/constants/requestState';

export interface ResignUpContextProps {
  email: string;
  reregistrationCode: string;
  resendResignUpComplete: () => Promise<void>;
  resignUp: (formInput: {
    email: string;
    reregistrationCode: string;
  }) => Promise<void>;
  setState: (state: string) => void;
  state: string;
}

const ResignUpContext = React.createContext<ResignUpContextProps>({
  email: '',
  reregistrationCode: '',
  resendResignUpComplete: () => new Promise(() => undefined),
  resignUp: (formInput: { email: string; reregistrationCode: string }) =>
    new Promise(() => undefined),
  setState: (state: string) => undefined,
  state: INITIAL,
});

export default ResignUpContext;

import { InvitationCodeProps } from './InvitationCode.type';
import { RankType } from '../../../../types/Invite.type';
import Rank from '../../../../value/invite/rank/Rank';
import RankFactory from '../../../../value/invite/rank/RankFactory';

class InvitationCode {
  private rank: Rank;

  private code: string;

  constructor(rank: RankType, props: InvitationCodeProps) {
    this.rank = RankFactory.create(rank);
    this.code = props.code;
  }

  public getRawRank = () => this.rank.getRank();

  public getRawCode = () => this.code;

  public isRankD = () => this.rank.isRankD();

  public isRankA = () => this.rank.isRankA();

  public isRankC = () => this.rank.isRankC();
}
export default InvitationCode;

/* eslint-disable @typescript-eslint/no-explicit-any */
// React
import React, { FC, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import BankFutureDepositsContext from '../../../../../domain/transaction/bankTransaction/bankFutureTransaction/bankFutureDeposit/bankFutureDeposit/BankFutureDeposits/BankFutureDepositsContext';

// Constant
import {
  BASE_CURRENCY_UNIT,
  FILTER_RULE,
  FILTER_RULE_EQUALS,
} from '../../../../../config/constants/business';

// Mapper
import TableCommissionsTimeDepositMapper, {
  TdCommissionsTimeDeposit,
} from './TableCommissionsTimeDepositMapper';

// Components
import {
  TextInputFilter,
  SelectColumnFilter,
} from '../../../../atoms/table/filter';
import DefaultTdBox from '../../../../molecules/table/td/DefaultTdBox';
import CurrencyAmountTdBox from '../../../../molecules/table/td/CurrencyAmountTdBox';
import BankFutureTransactionStatusTdBox from '../../../../molecules/table/td/BankFutureTransactionStatusTdBox';
import TableFunctional from '../../../../molecules/table/table/TableFunctional';

// ID
const ORGANISM_ID = `organisms.table.TableCommissionsTimeDeposit`;

const TableCommissionsTimeDeposit: FC = () => {
  /* *
   *
   *  Variables
   *
   * */

  const { t } = useTranslation();

  // DomainObject
  const { bankFutureDeposits } = useContext(BankFutureDepositsContext);

  /* *
   *
   *  Mapping
   *
   * */
  const data = useMemo(
    () => TableCommissionsTimeDepositMapper.domainToTd(bankFutureDeposits, t),
    [bankFutureDeposits, t],
  );

  /* *
   *
   *  Table
   *
   * */
  const columns = useMemo(
    () => [
      {
        Header: t(`${ORGANISM_ID}.th.scheduledDate`),
        accessor: (values: TdCommissionsTimeDeposit) => values.scheduledDate,
        Cell: (value: { cell: { value: string } }) =>
          DefaultTdBox({ text: value.cell.value }),
        Filter: TextInputFilter,
        filter: FILTER_RULE,
        minWidth: `${250 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.amount`, {
          unit: BASE_CURRENCY_UNIT,
        }),
        accessor: (values: TdCommissionsTimeDeposit) => values.amount,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Cell: (value: any) => {
          return CurrencyAmountTdBox({
            currency: value.row.original.currency,
            number: value.row.original.amount,
          });
        },
        Filter: TextInputFilter,
        filter: FILTER_RULE,
        minWidth: `${200 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.timeDepositId`),
        accessor: (values: TdCommissionsTimeDeposit) => values.timeDepositId,
        Cell: (value: { cell: { value: string } }) =>
          DefaultTdBox({ text: value.cell.value }),
        Filter: TextInputFilter,
        filter: FILTER_RULE,
        minWidth: `${200 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.status`),
        accessor: (values: TdCommissionsTimeDeposit) => values.statusString,
        Cell: (value: any) =>
          BankFutureTransactionStatusTdBox({
            status: value.row.original.status,
          }),
        Filter: SelectColumnFilter,
        filter: FILTER_RULE_EQUALS,
        minWidth: `${150 / 16}rem`,
      },
    ],
    // eslint-disable-next-line
    [],
  );

  return <TableFunctional data={data} columns={columns} width={750} />;
};

export default TableCommissionsTimeDeposit;

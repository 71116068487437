import React, { FC } from 'react';

// Component
import HeaderContractTimeDepositAdd from '../../../organisms/header/HeaderContentTitle/contract/HeaderContractTimeDepositAdd';
import ContractTimeDepositAddTemplateLayout from './ContractTimeDepositAddTemplateLayout';
import FormContractTimeDepositAdd from '../../../organisms/form/contract/contractTimeDeposit/FormContractTimeDepositAdd';
import ProgressStepBarBankTransaction from '../../../molecules/progressStep/ProgressStepBar/ProgressStepBarBankTransaction';

const ContractTimeDepositAddTemplate: FC = () => {
  return (
    <ContractTimeDepositAddTemplateLayout>
      <HeaderContractTimeDepositAdd key="header" />
      <ProgressStepBarBankTransaction key="progressStep" currentStep="input" />
      <FormContractTimeDepositAdd key="form" />
    </ContractTimeDepositAddTemplateLayout>
  );
};
export default ContractTimeDepositAddTemplate;

// IUseCase
import ISignUpUseCase from '../../../enhancers/useCase/authentication/SignUpProvider/ISignUpUseCase';

// IService
import ISignUpRequestCreateService from './ISignUpRequestCreateService';

// Service
import SignUpRequestCreateService from '../../../service/authentication/SignUpRequestCreateService';

// IRepository
import ISignUpRequestCreator from '../../../domain/userRequest/signUpRequest/ISignUpRequestCreator';

class SignUpUseCase implements ISignUpUseCase {
  private signUpRequestCreateService: ISignUpRequestCreateService;

  constructor(signUpRequestCreator: ISignUpRequestCreator) {
    this.signUpRequestCreateService = new SignUpRequestCreateService(
      signUpRequestCreator,
    );
  }

  public signUp = async (email: string, invitationCode: string) => {
    await this.signUpRequestCreateService.executeCreate(email, invitationCode);
  };
}
export default SignUpUseCase;

// React
import { useCallback, useEffect, useState } from 'react';
import useInterval from 'use-interval';

// Repository & API
import CryptoCurrencyTradeFeeRateFirestore from 'repository/settings/CryptoCurrencyTradeFeeRateFirestore';
// import CryptoCurrencyRateBinanceExchangeAPI from 'repository/cryptoCurrency/cryptoCurrencyRate/CryptoCurrencyRateBinanceExchangeAPI';
import CryptoCurrencyRateOSLExchangeAPI from 'repository/cryptoCurrency/cryptoCurrencyRate/CryptoCurrencyRateOSLExchangeAPI';

// Type
import { CryptoCurrencyType } from '../../../../types/CryptoCurrency.type';

// ErrorHandler
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';
import { BASE_CURRENCY_SYMBOL } from '../../../../config/constants/business';

// UseCase
import CryptoCurrencyRateFetchUseCase from '../../../../useCases/cryptoCurrency/cryptoCurrencyRate/CrytpoCorrencyRateFetchUseCase';

// DomainObject
import { useCryptoCurrencyRate } from '../../../../domain/cryptoCurrency/cryptoCurrencyRate/CryptoCurrencyRate/CryptoCurrencyRate/useCryptoCurrencyRate';
import { useCryptoCurrencyTradeFeeRate } from '../../../../domain/settings/cryptoCurrencyTradeFeeRate/CryptoCurrencyTradeFeeRate/useCryptoCurrencyTradeFeeRate';

// Value
// import { useCountdown } from '../../../utils/Countdown/useCountdown';

export const useCryptoCurrencyRateFetchUseCase = (
  currency: CryptoCurrencyType,
) => {
  const [state, setState] = useState<string>(INITIAL);

  // Countdown
  const [count, setCount] = useState<number>(0);

  /* *
   *
   *  DomainObject
   *
   * */
  const { cryptoCurrencyRate, setCryptoCurrencyRate } = useCryptoCurrencyRate();
  const {
    cryptoCurrencyTradeFeeRate,
    setCryptoCurrencyTradeFeeRate,
  } = useCryptoCurrencyTradeFeeRate();

  /* *
   *
   *  Repository
   *
   * */
  const cryptoCurrencyTradeFeeRateRepository = new CryptoCurrencyTradeFeeRateFirestore();
  const cryptoCurrencyRateRepository = new CryptoCurrencyRateOSLExchangeAPI();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new CryptoCurrencyRateFetchUseCase(
    cryptoCurrencyTradeFeeRateRepository,
    cryptoCurrencyRateRepository,
  );

  /* *
   *
   * Methods
   *
   * */

  // Fetch TradeFeeRate
  const fetchCryptoCurrencyTradeFeeRate = useCallback(async () => {
    setState(IS_LOADING);

    try {
      checkInternetConnection();

      await useCase.getTradeFeeRate(
        currency,
        BASE_CURRENCY_SYMBOL,
        setCryptoCurrencyTradeFeeRate,
        setState,
      );

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [currency]);

  // Fetch Rate
  const fetchRate = useCallback(async () => {
    if (!cryptoCurrencyTradeFeeRate) return;

    setState(IS_LOADING);

    try {
      checkInternetConnection();

      const outputs = await useCase.getTradeRate(cryptoCurrencyTradeFeeRate);

      setCryptoCurrencyRate(outputs.cryptoCurrencyRate);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [cryptoCurrencyTradeFeeRate]);

  /* *
   *
   * UseEffect
   *
   * */

  // (1) Fetch FeeRate
  useEffect(() => {
    let isMounted = true;

    if (isMounted) fetchCryptoCurrencyTradeFeeRate();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [fetchCryptoCurrencyTradeFeeRate]);

  // (2) Fetch Rate when cryptoCurrencyTradeFeeRate is updated
  // Fetch Rate is count is expired
  useEffect(() => {
    let isMounted = true;

    if (isMounted && count <= 0) fetchRate();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [count]);

  // When FetchRate is changed
  useEffect(() => {
    let isMounted = true;

    if (isMounted) fetchRate();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [fetchRate]);

  // FetchTradeRate when cryptoCurrencyTradeFeeRate is updated
  useInterval(() => {
    if (cryptoCurrencyRate && count >= 0) {
      setCount(Math.round(cryptoCurrencyRate.getRemainingTime() / 1000));
    }
  }, 1000);

  return {
    count,
    setCount,
    cryptoCurrencyRate,
    state,
    setState,
  };
};

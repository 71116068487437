import React, { FC, useEffect } from 'react';
import { useModal } from 'react-modal-hook';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAuthenticatedPageViewUseCase } from './useAuthenticatedPageViewUseCase';
import { useSignOut } from '../../../auth/authenticated/SignOut/useSignOut';
import HTMLElementsAdjustProvider from '../../../screen/HTMLElementsAdjustProvider';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import SignInTimeOutProvider from '../../../auth/authenticated/SignInTimeOut/SignInTimeOutProvider';
import SignOutContext from '../../../auth/authenticated/SignOut/SignOutContext';

// Type
import { GlobalStateType } from '../../../../redux/store';

// Constant
import { ROUTE_SIGN_IN } from '../../../../config/constants/routing';
import { IS_LOADING } from '../../../../config/constants/requestState';

// Component
import LoadingModal from '../../../../components/organisms/modal/LoadingModal';
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type AuthenticatedPageViewProviderProps = {
  children: React.ReactNode;
};
const AuthenticatedPageViewProvider: FC<AuthenticatedPageViewProviderProps> = ({
  children,
}: AuthenticatedPageViewProviderProps) => {
  const { id, signInUserProps } = useSelector(
    (globalState: GlobalStateType) => globalState.signInUserStore,
  );
  const history = useHistory();
  const { signOut } = useSignOut();
  const {
    state,
    setState,
    userAuthorized,
    setUserAuthorized,
  } = useAuthenticatedPageViewUseCase(id, signInUserProps?.token);

  const [showModal, hideModal] = useModal(() => <LoadingModal />);

  /* *
   *
   * UseEffect
   *
   * */

  // Loading
  useEffect(() => {
    if (state === IS_LOADING) showModal();
    else hideModal();
  }, [state, showModal, hideModal]);

  // UnAuthorized
  useEffect(() => {
    let isMounted = true;

    if ((!id || !signInUserProps) && isMounted) {
      // eslint-disable-next-line no-console
      history.push(ROUTE_SIGN_IN);
    }

    return () => {
      isMounted = false;
    };
  }, [history, id, signInUserProps]);

  return (
    <UserAuthorizedContext.Provider
      value={{ userAuthorized, setUserAuthorized }}
    >
      <SignOutContext.Provider value={{ signOut }}>
        <HTMLElementsAdjustProvider>
          <SystemErrorHandler state={state} setState={setState}>
            <SignInTimeOutProvider>{children}</SignInTimeOutProvider>
          </SystemErrorHandler>
        </HTMLElementsAdjustProvider>
      </SignOutContext.Provider>
    </UserAuthorizedContext.Provider>
  );
};
export default AuthenticatedPageViewProvider;

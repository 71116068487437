import { useState } from 'react';

// DomainObject
import CryptoCurrencyTradeTransaction from './CryptoCurrencyTradeTransaction';

export const useCryptoCurrencyTradeTransaction = (
  defaultValue?: CryptoCurrencyTradeTransaction,
) => {
  const [
    cryptoCurrencyTradeTransaction,
    setCryptoCurrencyTradeTransaction,
  ] = useState<CryptoCurrencyTradeTransaction | undefined>(defaultValue);

  return {
    cryptoCurrencyTradeTransaction,
    setCryptoCurrencyTradeTransaction,
  };
};

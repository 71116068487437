import React from 'react';
import ConsentForm from './ConsentForm';

export interface ConsentFormContextProps {
  consentForm?: ConsentForm;
}

const ConsentFormContext = React.createContext<ConsentFormContextProps>({
  consentForm: undefined,
});

export default ConsentFormContext;

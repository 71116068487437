// React
import { useState, useCallback, useEffect } from 'react';
import { useFormInputResetAuthenticatorTwoFactorAuth } from '../../../../components/organisms/form/front/FormResetAuthenticatorTwoFactorAuth/FormResetAuthenticatorTwoFactorAuthInput';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';
import { executeWithTimeout } from '../../../../utils/helpers/timeout';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';
import { REQUEST_TIMEOUT } from '../../../../config/constants/business';

// UseCase
import ResetAuthenticatorViewUseCase from '../../../../useCases/userRequest/resetAuthenticatorRequest/ResetAuthenticatorViewUseCase';

// Repository
import ResetAuthenticatorRequestFunctions from '../../../../repository/userRequest/resetAuthenticatorRequest/ResetAuthenticatorFunctions';

// DomainObject
import { useResetAuthenticatorRequest } from '../../../../domain/userRequest/resetAuthenticatorRequest/ResetAuthenticatorRequest/useResetAuthenticatorRequest';

export const useResetAuthenticatorViewUseCase = (oobCode: string) => {
  const [state, setState] = useState<string>(INITIAL);
  const formInputResetAuthenticatorTwoFactorAuthContext = useFormInputResetAuthenticatorTwoFactorAuth();

  /* *
   *
   *  DomainObject
   *
   * */
  const {
    resetAuthenticatorRequest,
    setResetAuthenticatorRequest,
  } = useResetAuthenticatorRequest();

  /* *
   *
   *  Repository
   *
   * */
  const resetAuthenticatorRequestRepository = new ResetAuthenticatorRequestFunctions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new ResetAuthenticatorViewUseCase(
    resetAuthenticatorRequestRepository,
  );

  /* *
   *
   *  Methods
   *
   * */

  const openResetAuthenticatorPage = useCallback(async () => {
    if (!oobCode) return;

    setState(IS_LOADING);

    try {
      checkInternetConnection();

      // UseCase.open
      const output = await executeWithTimeout(
        useCase.open(oobCode),
        REQUEST_TIMEOUT,
      );

      setResetAuthenticatorRequest(output.resetAuthenticatorRequest);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [oobCode]);

  /* *
   *
   *  useEffect
   *
   * */
  useEffect(() => {
    let isMounted = true;

    if (oobCode && isMounted) openResetAuthenticatorPage();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [oobCode]);

  return {
    state,
    setState,
    resetAuthenticatorRequest,
    formInputResetAuthenticatorTwoFactorAuthContext,
  };
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import Margin from '../../../../atoms/utils/layout/Margin';
import Box from '../../../../atoms/utils/layout/Box/Box';
import { FlexColumn } from '../../../../atoms/utils/layout/Flex';

export interface FormSignInTwoFactorAuthLayoutProps {
  children: React.ReactElement[];
}

const FormSignInTwoFactorAuthLayout: FC<FormSignInTwoFactorAuthLayoutProps> = ({
  children,
}: FormSignInTwoFactorAuthLayoutProps) => {
  const elements = ['input', 'errorMessage', 'button'];
  const [input, errorMessage, button] = getElementsFromKeys(children, elements);

  return (
    <FlexColumn alignItems="center">
      <Margin top={30}>
        <Box height={78}>{input}</Box>
      </Margin>
      <Margin top={20}>
        <Box height={40}>{errorMessage}</Box>
      </Margin>
      <Margin top={15}>{button}</Margin>
    </FlexColumn>
  );
};

export default FormSignInTwoFactorAuthLayout;

import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
`;

export const RightContainer = styled.div`
  overflow-x: hidden;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  padding-left: ${(190 / 1920) * 100}vw;
  background: #f0f1f7 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color; gold;
`;

export const Main = styled.div`
  height: 100%;
  margin-top: ${70 / 16}rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const LeftImageContainer = styled.div`
  position: fixed;
  width: ${(587 / 1920) * 100}%;
  height: 100%;
  display: flex;
`;

import { useState } from 'react';
import Authenticator from './Authenticator';

export const useAuthenticator = () => {
  const [authenticator, setAuthenticator] = useState<
    Authenticator | undefined
  >();

  return {
    authenticator,
    setAuthenticator,
  };
};

import React, { FC } from 'react';
import {
  getSymbolFromCurrency,
  convertMoneyFormat,
} from '../../../../utils/helpers/currencyHelper';
import { CurrencyType } from '../../../../types/Currency.type';
import { TextSecondary, TextThemeProps } from '../../../atoms/text2/Text2';
import CurrencyAmountLayout from './CurrencyAmountLayout';

export interface CurrencyAmountProps {
  amount: number | string;
  currency: CurrencyType;
  isSymbol?: boolean;
  marginBetween: number | string;
  theme: {
    currency: TextThemeProps;
    amount: TextThemeProps;
  };
}

const CurrencyAmount: FC<CurrencyAmountProps> = ({
  amount,
  currency,
  isSymbol = false,
  marginBetween,
  theme,
}: CurrencyAmountProps) => {
  return (
    <CurrencyAmountLayout marginBetween={marginBetween}>
      <TextSecondary key="currency" theme={theme.currency}>
        {isSymbol ? getSymbolFromCurrency(currency) : currency}
      </TextSecondary>
      <TextSecondary key="amount" theme={theme.amount}>
        {Number.isNaN(Number(amount))
          ? amount
          : convertMoneyFormat(Number(amount), currency)}
      </TextSecondary>
    </CurrencyAmountLayout>
  );
};

export default CurrencyAmount;

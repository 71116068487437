// Lib
import { convertMoneyRounded } from '../../../../utils/helpers/currencyHelper';

// Type
import { SavingsAccountProps } from './SavingsAccount.type';

// ValueObject
import SerialNumberSavingsAccount from '../../../idManager/productSerialNumber/ProductSerialNumber/SerialNumber/concreteSerialNumber/SerialNumberSavingsAccount';

class SavingsAccount {
  private accountNumber: SerialNumberSavingsAccount;

  private createdAt: number;

  private updatedAt: number;

  private props: SavingsAccountProps;

  constructor(
    savingsAccountNumber: SerialNumberSavingsAccount,
    props: SavingsAccountProps,
  ) {
    this.accountNumber = savingsAccountNumber;
    this.createdAt = props.createdAt ?? Date.now();
    this.updatedAt = props.updatedAt ?? Date.now();
    this.props = props;
  }

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public getFormattedAccountNumber = () => this.accountNumber.getFormattedId();

  public getRawAccountNumber = () => this.accountNumber.getRawId();

  public getBalanceRounded = () =>
    convertMoneyRounded(this.props.balance, this.props.currency);
}
export default SavingsAccount;

import React, { FC } from 'react';
import DebitCardRequestPage from './DebitCardRequestPage';
import DebitCardRequestCreateProvider from '../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequest/DebitCardRequestCreateProvider';
import DebitCardRequestViewProvider from '../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequest/DebitCardRequestViewProvider';

const EnhancedDebitCardRequestPage: FC = () => {
  return (
    <DebitCardRequestViewProvider>
      <DebitCardRequestCreateProvider>
        <DebitCardRequestPage />
      </DebitCardRequestCreateProvider>
    </DebitCardRequestViewProvider>
  );
};

export default EnhancedDebitCardRequestPage;

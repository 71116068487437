import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../../atoms/utils/layout/Margin';

export interface FormShippingAddressLayoutProps {
  children: React.ReactElement[];
}

const FormShippingAddressLayout: FC<FormShippingAddressLayoutProps> = ({
  children,
}: FormShippingAddressLayoutProps) => {
  const elements = [
    'description',
    'checkBox',
    'checkBoxDescription',
    'shippingAddress',
  ];
  const [
    description,
    checkBox,
    checkBoxDescription,
    shippingAddress,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn width={1200} height="auto">
      {description}
      <Margin top={60} />
      <FlexBoxRow alignItems="center">
        {checkBox}
        <Margin left={10} />
        {checkBoxDescription}
      </FlexBoxRow>
      <Margin top={60} />
      {shippingAddress}
      <Margin top={40} />
    </FlexBoxColumn>
  );
};
export default FormShippingAddressLayout;

import styled from 'styled-components';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  box-sizing: border-box;
  width: ${668 / 16}rem;
  padding: ${60 / 16}rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BoxStyle = {
  backgroundColor: color.background.white,
};

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.book,
  lineHeight: 44,
};

export const RemainingTimeTextStyle = {
  fontSize: 24,
  color: color.text.zambezi,
  fontWeight: fontWeight.bold,
};

export const SignOutButtonTextStyle = {
  fontSize: 24,
  color: color.text.summerSky,
  fontWeight: fontWeight.medium,
};

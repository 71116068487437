/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export interface PersonalInformationFileContextProps {
  proofOfDocument1: File | undefined;
  setProofOfDocument1: (file: File | undefined) => void;
  proofOfDocument2: File | undefined;
  setProofOfDocument2: (file: File | undefined) => void;
}

const PersonalInformationFileContext = React.createContext<
  PersonalInformationFileContextProps
>({
  proofOfDocument1: undefined,
  setProofOfDocument1: (file: File | undefined) => undefined,
  proofOfDocument2: undefined,
  setProofOfDocument2: (file: File | undefined) => undefined,
});

export default PersonalInformationFileContext;

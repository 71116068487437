import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Type
import { DebitCardRequestCherryVisa01StepStatusType } from '../../../../types/DebitCardRequestCherryVisa01.type';

// Style
import { TextStyle } from './style';

// DomainObject
import { TextSecondary } from '../../../atoms/text2/Text2';
import DebitCardRequestStatusBadgeLayout from './DebitCardRequestStatusBadgeLayout';
import DebitCardRequestStatusIcon from '../../../atoms/icon/DebitCardRequestStatusIcon';

export interface DebitCardRequestStepStatusTypeBadgeProps {
  status?: DebitCardRequestCherryVisa01StepStatusType;
  layout: {
    height: string | number;
    iconWidth: string | number;
    iconHeight: string | number;
  };
}

const DebitCardRequestStepStatusTypeBadge: FC<DebitCardRequestStepStatusTypeBadgeProps> = ({
  status,
  layout,
}: DebitCardRequestStepStatusTypeBadgeProps) => {
  const { t } = useTranslation();

  const displayBadgeStatus = [
    DebitCardRequestCherryVisa01StepStatusType.approved,
    DebitCardRequestCherryVisa01StepStatusType.rejected,
    DebitCardRequestCherryVisa01StepStatusType.reviewing,
    DebitCardRequestCherryVisa01StepStatusType.waiting,
    DebitCardRequestCherryVisa01StepStatusType.done,
  ];

  if (status && !displayBadgeStatus.includes(status)) return <></>;

  let [backgroundColor, color] = ['', ''];

  switch (status) {
    case DebitCardRequestCherryVisa01StepStatusType.approved:
    case DebitCardRequestCherryVisa01StepStatusType.done:
      [backgroundColor, color] = ['#EAF8D7', '#95CB4B'];
      break;
    case DebitCardRequestCherryVisa01StepStatusType.reviewing:
      [backgroundColor, color] = ['#FFF1D1', '#F5A026'];
      break;
    case DebitCardRequestCherryVisa01StepStatusType.waiting:
      [backgroundColor, color] = ['#F0DEFF', '#9B68C7'];
      break;
    case DebitCardRequestCherryVisa01StepStatusType.rejected:
      [backgroundColor, color] = ['#FFE4E2', '#E44838'];
      break;
    default:
      [backgroundColor, color] = ['', ''];
  }

  return (
    <DebitCardRequestStatusBadgeLayout
      backgroundColor={backgroundColor}
      height={layout.height}
    >
      <DebitCardRequestStatusIcon
        key="icon"
        status={status}
        height={layout.iconHeight}
        width={layout.iconWidth}
      />
      <TextSecondary
        key="text"
        theme={{
          ...TextStyle,
          color,
        }}
      >
        {t(`debitCardRequestStepStatusType.${status}`)}
      </TextSecondary>
    </DebitCardRequestStatusBadgeLayout>
  );
};

export default DebitCardRequestStepStatusTypeBadge;

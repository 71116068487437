import Joi from '@hapi/joi';

export interface CryptoCurrencyTransactionProps {
  address: string;

  userId: string;

  amount: number;

  balance: number;

  commonId: string;

  descriptionParameter: string;

  createdAt?: number;

  updatedAt?: number;
}

export const CryptoCurrencyTransactionPropsFormat = {
  address: Joi.string().min(1).empty(''),

  userId: Joi.string().min(1).empty(''),

  amount: Joi.number().min(0).required(),

  balance: Joi.number().required(),

  commonId: Joi.string().empty(''),

  descriptionParameter: Joi.string().required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

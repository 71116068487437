import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import ConsentFormTemplate from '../../../components/templates/kycRequest/ConsentFormTemplate';

const ConsentFormPage: FC = () => {
  return (
    <DashboardTemplate>
      <ConsentFormTemplate />
    </DashboardTemplate>
  );
};

export default ConsentFormPage;

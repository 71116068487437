import Joi from '@hapi/joi';

// Type
import { UserType } from '../../../../../types/User.type';
import {
  KycRequestProps,
  KycRequestPropsFormat,
  KycRequestInitialProps,
} from '../KycRequest/KycRequest.type';

export type KycRequestIndividualProps = KycRequestProps;

export const KycRequestIndividualPropsFormat = {
  ...KycRequestPropsFormat,

  type: Joi.string().valid(UserType.individual),
};

export const KycRequestIndividualInitialProps = KycRequestInitialProps;

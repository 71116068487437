import React, { FC, useContext } from 'react';
import SystemErrorHandler from '../../errorHandler/SystemErrorHandler';
import { ResetPasswordContext } from './ResetPasswordContext';
import { VerifyResetPasswordContext } from '../VerifyResetPassword/VerifyResetPasswordContext';
import { useResetPassword } from './useResetPassword';

export type ResetPasswordProps = {
  children: React.ReactNode;
};

const ResetPassword: FC<ResetPasswordProps> = ({
  children,
}: ResetPasswordProps) => {
  const { oobCode } = useContext(VerifyResetPasswordContext);

  const { state, setState, resetPassword } = useResetPassword(oobCode);

  return (
    <ResetPasswordContext.Provider
      value={{
        resetPasswordState: state,
        setResetPasswordState: setState,
        resetPassword,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </ResetPasswordContext.Provider>
  );
};
export default ResetPassword;

import numeral from 'numeral';
import {
  CryptoCurrencyType,
  CryptoCurrencyProps,
} from '../../../../../types/CryptoCurrency.type';
import { CryptoCurrencyWalletProps } from './CryptoCurrencyWallet.type';

abstract class CryptoCurrencyWallet {
  protected address: string;

  protected userId: string;

  protected currency: CryptoCurrencyType;

  protected balance: number;

  protected pendingBalance: number;

  protected createdAt: number;

  protected updatedAt: number;

  protected props: CryptoCurrencyWalletProps;

  constructor(address: string, props: CryptoCurrencyWalletProps) {
    this.address = address;
    this.props = props;
    this.userId = props.userId;
    this.currency = props.currency;
    this.balance = props.balance;
    this.pendingBalance = props.pendingBalance;
    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
  }

  public getAddress = () => this.address;

  public getProps = () => this.props;

  public getUserId = () => this.userId;

  public getCurrency = () => this.currency;

  public getBalance = () => this.balance;

  public getCommandParameter() {
    return {
      balance: this.balance,
      pendingBalance: this.pendingBalance,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public getFormattedBalance = () => {
    const { precision } = CryptoCurrencyProps[this.currency];

    let format = precision > 0 ? '0,0.' : '0,0';
    for (let i = 0; i < precision; i += 1) format += '0';

    return numeral(this.balance).format(format);
  };
}

export default CryptoCurrencyWallet;

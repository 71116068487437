import React from 'react';
import AssetDebitCard from './AssetDebitCard';

export interface AssetDebitCardContextProps {
  assetDebitCard?: AssetDebitCard;
}

const AssetDebitCardContext = React.createContext<AssetDebitCardContextProps>({
  assetDebitCard: undefined,
});

export default AssetDebitCardContext;

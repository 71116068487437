// FirebaseFunctions
import { TransferSavingsAccountType } from 'types/Transfer.type';
import FirebasePrivateFunctions from 'infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';
import {
  TRANSFER_ECB_BANK_ACCOUNT,
  TRANSFER_SAVINGS_ACCOUNT,
} from 'infrastructure/firebase/firebaseFunctions/endpoint';

// IRepository
import { ITransferSavingsAccountTransactionCreateRepository } from 'domain/transaction/transferSavingsAccountTransaction/ITransferSavingsAccountTransactionRepository';

// DomainObject
import TransferSavingsAccountTransactionSeed from 'domain/transaction/transferSavingsAccountTransaction/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed';
import TransferSavingsAccountTransactionFactory from 'domain/transaction/transferSavingsAccountTransaction/TransferSavingsAccountTransaction/TransferSavingsAccountTransaction/TransferSavingsAccountTransactionFactory';

class TransferSavingsAccountTransactionFunctions
  extends FirebasePrivateFunctions
  implements ITransferSavingsAccountTransactionCreateRepository {
  public create = async (
    transferSavingsAccountTransactionSeed: TransferSavingsAccountTransactionSeed,
  ) => {
    const props = transferSavingsAccountTransactionSeed.getRequestParameterCreate();

    const endpoint =
      props.transferType === TransferSavingsAccountType.ecb
        ? TRANSFER_ECB_BANK_ACCOUNT
        : TRANSFER_SAVINGS_ACCOUNT;

    const response = await this.privatePOST(endpoint, props);

    return TransferSavingsAccountTransactionFactory.createFromSeed(
      transferSavingsAccountTransactionSeed,
      response.data.transferId,
    );
  };
}
export default TransferSavingsAccountTransactionFunctions;

import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  background: #f0f1f7 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  overflow: scroll;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Main = styled.div`
  height: 100%;
  margin-top: ${70 / 16}rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle } from './style';

// Component
import { TextSecondary } from '../../../../../atoms/text2/Text2';
import DebitCardRequestFormContainer from '../../../common/DebitCardRequestFormContainer';
import LineBreakReplaced from '../../../../../atoms/text2/LineBreakReplaced';
import SelectDocumentTypeLayout from './SelectDocumentTypeLayout';
import FormInputCherryVisa01DocumentTypeSelectBox from '../../../../../molecules/form/formInput/selectBox/verification/FormInputCherryVisa01DocumentTypeSelectBox';

const FORM_ID = `organisms.form.debitCards.FormProofOfIdentity.selectDocumentType`;

const SelectDocumentType: FC = () => {
  const { t } = useTranslation();

  return (
    <DebitCardRequestFormContainer order={2} formId={FORM_ID}>
      <SelectDocumentTypeLayout>
        <TextSecondary key="description" theme={DescriptionStyle}>
          <LineBreakReplaced text={t(`${FORM_ID}.description`)} />
        </TextSecondary>
        <FormInputCherryVisa01DocumentTypeSelectBox
          key="selectBox"
          labelWidth={200}
          inputWidth={400}
          label={t(`${FORM_ID}.documentType.label`)}
          name="documentType"
        />
      </SelectDocumentTypeLayout>
    </DebitCardRequestFormContainer>
  );
};

export default SelectDocumentType;

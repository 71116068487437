import { useState } from 'react';
import auth from '../../../infrastructure/firebase/firebaseAuthentication/firebaseAuth';
import { executeWithTimeout } from '../../../utils/helpers/timeout';
import { checkInternetConnection } from '../../../utils/helpers/connection';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../config/constants/requestState';
import { REQUEST_TIMEOUT } from '../../../config/constants/business';
import ErrorHandler from '../../../utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from '../../../utils/errors/ErrorHandler/IErrorHandler';

export const useResetPassword = (oobCode: string) => {
  const [state, setState] = useState<string>(INITIAL);

  const resetPassword = async (password: string) => {
    setState(IS_LOADING);

    try {
      // ---check onLine ---- //
      checkInternetConnection();

      const promiseResetPassword = auth.confirmPasswordReset(oobCode, password);

      await executeWithTimeout(promiseResetPassword, REQUEST_TIMEOUT);

      setState(SUCCESS);
    } catch (error) {
      const handler: IErrorHandler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  return {
    state,
    setState,
    resetPassword,
  };
};

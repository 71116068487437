import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import Margin from '../../../atoms/utils/layout/Margin';
import { FlexRow } from '../../../atoms/utils/layout/Flex';

export interface CountdownDescriptionLayoutProps {
  children: React.ReactElement[];
  marginBetween: string | number;
}

const CountdownDescriptionLayout: FC<CountdownDescriptionLayoutProps> = ({
  children,
  marginBetween,
}: CountdownDescriptionLayoutProps) => {
  const elements = ['text', 'timer'];
  const [text, timer] = getElementsFromKeys(children, elements);

  return (
    <FlexRow>
      <Margin>{text}</Margin>
      <Margin left={marginBetween}>{timer}</Margin>
    </FlexRow>
  );
};
export default CountdownDescriptionLayout;

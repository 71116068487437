/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { INITIAL } from '../../../../../config/constants/requestState';

export interface TwoFactorAuthAppSettingsSubmitContextProps {
  submitTwoFactorAuthAppSettings: (
    code: string,
    callback: () => void,
  ) => Promise<void>;
  state: string;
}

const TwoFactorAuthAppSettingsSubmitContext = React.createContext<
  TwoFactorAuthAppSettingsSubmitContextProps
>({
  state: INITIAL,
  submitTwoFactorAuthAppSettings: (code: string, callback: () => void) =>
    new Promise(() => undefined),
});

export default TwoFactorAuthAppSettingsSubmitContext;

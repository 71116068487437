import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SavingsAccountContext from '../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccountContext';

// Style
import { TableVerticalLayout, TableVerticalStyle } from './style';

// Mapper
import TableCardIssuanceFeePaymentMapper from './TableCardIssuanceFeePaymentMapper';

// Component
import TableVertical from '../../../../molecules/table/table/TableVertical';

const TableCardIssuanceFeePayment: FC = () => {
  const { t } = useTranslation();
  const { savingsAccount } = useContext(SavingsAccountContext);

  if (!savingsAccount) return <></>;

  const rows = TableCardIssuanceFeePaymentMapper.toTableData(savingsAccount, t);

  return (
    <TableVertical
      isTableBorder
      layout={TableVerticalLayout}
      rows={rows}
      theme={TableVerticalStyle}
    />
  );
};

export default TableCardIssuanceFeePayment;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Component
import { TitleStyle, DescriptionStyle } from './style';
import HeaderTwoFactorAuthAppEditCompleteTemplate from '../../../organisms/header/HeaderContentTitle/settings/HeaderTwoFactorAuthAppEditComplete';
import NotificationSuccess from '../../../organisms/notification/NotificationSuccess';
import TwoFactorAuthAppEditCompleteTemplateLayout from './TwoFactorAuthAppEditCompleteTemplateLayout';

const TEMPLATE_ID = `templates.userSettings.TwoFactorAuthAppEditCompleteTemplate`;

const TwoFactorAuthAppEditCompleteTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <TwoFactorAuthAppEditCompleteTemplateLayout>
      <HeaderTwoFactorAuthAppEditCompleteTemplate key="header" />
      <NotificationSuccess
        key="notification"
        description={t(`${TEMPLATE_ID}.notification.description`)}
        title={t(`${TEMPLATE_ID}.notification.title`)}
        descriptionStyle={DescriptionStyle}
        titleStyle={TitleStyle}
      />
    </TwoFactorAuthAppEditCompleteTemplateLayout>
  );
};

export default TwoFactorAuthAppEditCompleteTemplate;

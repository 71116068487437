import React from 'react';
import AssetCryptoCurrency from './AssetCryptoCurrency';

export interface AssetCryptoCurrencyContextProps {
  assetCryptoCurrency?: AssetCryptoCurrency;
}

const AssetCryptoCurrencyContext = React.createContext<
  AssetCryptoCurrencyContextProps
>({
  assetCryptoCurrency: undefined,
});

export default AssetCryptoCurrencyContext;

import React, { FC } from 'react';
import { IoIosSettings, IoIosMailOpen } from 'react-icons/io';
import { FaCommentDots, FaBitcoin, FaExchangeAlt } from 'react-icons/fa';
import { AiFillBank, AiFillCreditCard } from 'react-icons/ai';
import { MdSupervisorAccount } from 'react-icons/md';
import { GiReceiveMoney } from 'react-icons/gi';
import { RiTimeFill, RiLogoutBoxRLine, RiBookOpenFill } from 'react-icons/ri';

// Type
import { MenuType } from '../../../../config/menu/main/Menu.type';
import { FooterMenuType } from '../../../../config/menu/footer/Footer.type';

export interface MenuIconProps {
  type: MenuType | FooterMenuType;
}

const MenuIcon: FC<MenuIconProps> = ({ type }: MenuIconProps) => {
  const color = '#fff';
  const size = `16px`;

  switch (type) {
    case MenuType.assets:
      return <AiFillBank color={color} size={size} />;
    case MenuType.cryptocurrencies:
      return <FaBitcoin color={color} size={size} />;
    case MenuType.savingsAccounts:
      return <GiReceiveMoney color={color} size={size} />;
    case MenuType.timeDeposits:
      return <RiTimeFill color={color} size={size} />;
    case MenuType.debitCards:
      return <AiFillCreditCard color={color} size={size} />;
    case MenuType.transfer:
      return <FaExchangeAlt color={color} size={size} />;
    case MenuType.jointAccounts:
      return <MdSupervisorAccount color={color} size={size} />;
    case MenuType.verification:
      return <FaCommentDots color={color} size={size} />;
    case MenuType.settings:
      return <IoIosSettings color={color} size={size} />;
    case MenuType.manual:
      return <RiBookOpenFill color={color} size={size} />;
    case MenuType.invitations:
      return <IoIosMailOpen color={color} size={size} />;
    case FooterMenuType.signOut:
      return <RiLogoutBoxRLine color="white" size="16px" />;

    default:
      return <></>;
  }
};
export default MenuIcon;

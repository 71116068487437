import React, { FC } from 'react';
import { TextPrimary, TextSecondary, TextThemeProps } from '../Text2';

export interface LinkTextProps {
  children: React.ReactNode;
  href?: string;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  target?: string;
  theme: TextThemeProps;
}

export const LinkTextPrimary: FC<LinkTextProps> = ({
  children,
  href,
  onClick = () => undefined,
  target,
  theme,
}: LinkTextProps) => {
  return (
    <a
      href={href}
      onClick={onClick}
      target={target || ''}
      style={{
        textDecoration: theme.textDecoration || 'none',
        cursor: 'pointer',
      }}
    >
      <TextPrimary theme={theme}>{children}</TextPrimary>
    </a>
  );
};

export const LinkTextSecondary: FC<LinkTextProps> = ({
  children,
  href,
  onClick = () => undefined,
  onMouseOver = () => undefined,
  onMouseLeave = () => undefined,
  target,
  theme,
}: LinkTextProps) => {
  return (
    <a
      href={href}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      target={target || ''}
      style={{
        textDecoration: theme.textDecoration || 'none',
        cursor: 'pointer',
      }}
    >
      <TextSecondary theme={theme}>{children}</TextSecondary>
    </a>
  );
};

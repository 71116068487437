import React, { FC } from 'react';
import { MdSupervisorAccount, MdPerson } from 'react-icons/md';
import { convertRem } from '../../utils/helper/helper';

// Error
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import { SavingsAccountType } from '../../../../types/SavingsAccount.type';

export interface SavingsAccountTypeIconProps {
  type: SavingsAccountType;
  size: string | number;
}

const SavingsAccountTypeIcon: FC<SavingsAccountTypeIconProps> = ({
  type,
  size,
}: SavingsAccountTypeIconProps) => {
  const color = type === SavingsAccountType.single ? '#299ADF' : '#2C8A9D';
  const iconSize = convertRem(size);

  switch (type) {
    case SavingsAccountType.single:
      return <MdPerson color={color} size={iconSize} />;
    case SavingsAccountType.joint:
      return <MdSupervisorAccount color={color} size={iconSize} />;
    default:
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_SWITCH,
        {
          value: type,
          place: `SavingsAccountTypeIcon`,
        },
      );
  }
};
export default SavingsAccountTypeIcon;

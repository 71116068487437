// React
import React, { FC } from 'react';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Component
import { TextPrimary, TextSecondary, TextThemeProps } from '../Text2';

export interface TextFontedProps {
  children: React.ReactNode;
  fontFamilyType: 'primary' | 'secondary';
  theme: TextThemeProps;
}
const TextFonted: FC<TextFontedProps> = ({
  children,
  fontFamilyType,
  theme,
}: TextFontedProps) => {
  switch (fontFamilyType) {
    case 'primary':
      return <TextPrimary theme={theme}>{children}</TextPrimary>;
    case 'secondary':
      return <TextSecondary theme={theme}>{children}</TextSecondary>;
    default:
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_SWITCH,
        {
          value: fontFamilyType,
          place: 'TextFonted',
        },
      );
  }
};
export default TextFonted;

import React, { FC, useContext } from 'react';
import { CryptoCurrencyProps } from '../../../../types/CryptoCurrency.type';
import CryptoCurrencyIcon from '../../../atoms/icon/CryptoCurrencyIcon';
import { TextPrimary } from '../../../atoms/text2/Text2';
import LabelCryptoCurrencyTradeType from '../../../atoms/label/LabelCryptoCurrencyTradeType';
import HeaderCryptoCurrencyTradeTypeLayout from './HeaderCryptoCurrencyTradeTypeLayout';
import { CurrencyNameStyle, CurrencySymbolStyle } from './style';
import CryptoCurrencyTradeTransactionSeedContext from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeedContext';

const HeaderCryptoCurrencyTradeType: FC = () => {
  const { cryptoCurrencyTradeTransactionSeed } = useContext(
    CryptoCurrencyTradeTransactionSeedContext,
  );

  if (!cryptoCurrencyTradeTransactionSeed) return <></>;

  const { tradeType } = cryptoCurrencyTradeTransactionSeed.getProps();
  const targetCurrency = cryptoCurrencyTradeTransactionSeed.getTargetCurrency();

  return (
    <HeaderCryptoCurrencyTradeTypeLayout>
      <CryptoCurrencyIcon
        key="icon"
        type={targetCurrency}
        width={`${53 / 16}rem`}
        height={`${53 / 16}rem`}
      />
      <TextPrimary key="name" theme={CurrencyNameStyle}>
        {`${CryptoCurrencyProps[targetCurrency].name}`}
      </TextPrimary>
      <TextPrimary key="symbol" theme={CurrencySymbolStyle}>
        {`(${CryptoCurrencyProps[targetCurrency].symbol})`}
      </TextPrimary>
      <LabelCryptoCurrencyTradeType key="label" tradeType={tradeType} />
    </HeaderCryptoCurrencyTradeTypeLayout>
  );
};
export default HeaderCryptoCurrencyTradeType;

import Joi from '@hapi/joi';

export interface ContractTimeDepositHistoryProps {
  savingsAccountNumber: string;

  amount: number;

  createdAt?: number;
}

export interface ContractTimeDepositHistoryRawDataType {
  id: string;
  props: ContractTimeDepositHistoryProps;
}

export const ContractTimeDepositHistoryPropsFormat = {
  id: Joi.string().required(),

  savingsAccountNumber: Joi.string().required(),

  amount: Joi.number().min(0).required(),

  createdAt: Joi.number().empty('')
};

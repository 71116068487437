import { useState } from 'react';

// OriginalLib
import { executeWithTimeout } from '../../../../../utils/helpers/timeout';
import { checkInternetConnection } from '../../../../../utils/helpers/connection';

// Constant
import { REQUEST_TIMEOUT } from '../../../../../config/constants/business';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';

// Type
import { useFormInputContractTimeDepositRenewalReservation } from '../../../../../components/organisms/form/contract/contractTimeDeposit/FormContractTimeDepositRenewalReservation/FormContractTimeDepositRenewalReservation.type';

// Error
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import ContractTimeDepositRenewalReservationDeleteUseCase from '../../../../../useCases/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationDeleteUseCase';

// Repository
import AuthenticatorFunctions from '../../../../../repository/authenticator/AuthenticatorFunctions';
import ContractTimeDepositFunctions from '../../../../../repository/contract/contractTimeDeposit/ContractTimeDepositFunctions';

// DomainObject
import { useTimeDeposit } from '../../../../../domain/product/timeDeposit/TimeDeposit/useTimeDeposit';
import UserVerified from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';
import ContractTimeDeposit from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';

export const useContractTimeDepositRenewalReservationDeleteUseCase = (
  user: UserVerified | undefined,
  contractTimeDeposit: ContractTimeDeposit | undefined,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const formInputContractTimeDepositRenewalReservationContext = useFormInputContractTimeDepositRenewalReservation();

  /* *
   *
   *  DomainObject
   *
   * */
  const { timeDeposit } = useTimeDeposit();

  /* *
   *
   *  Repository
   *
   * */
  const authenticatorVerifier = new AuthenticatorFunctions();
  const contractTimeDepositRepository = new ContractTimeDepositFunctions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new ContractTimeDepositRenewalReservationDeleteUseCase(
    authenticatorVerifier,
    contractTimeDepositRepository,
  );

  /* *
   *
   *  Methods
   *
   * */

  // UseCase.delete
  const deleteContractTimeDepositRenewalReservation = async (
    code: string,
    callback?: () => void,
  ) => {
    if (!user || !contractTimeDeposit) return;

    setState(IS_LOADING);

    try {
      checkInternetConnection();

      const output = useCase.delete({
        contractTimeDeposit,
      });

      await executeWithTimeout(
        useCase.executeDelete(user, output.contractTimeDeposit, code),
        REQUEST_TIMEOUT,
      );

      if (callback) callback();

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  return {
    state,
    setState,
    timeDeposit,
    formInputContractTimeDepositRenewalReservationContext,
    deleteContractTimeDepositRenewalReservation,
  };
};

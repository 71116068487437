import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TransferSavingsAccountTransactionContext from '../../../../../domain/transaction/transferSavingsAccountTransaction/TransferSavingsAccountTransaction/TransferSavingsAccountTransaction/TransferSavingsAccountTransactionContext';

// Style
import {
  DescriptionStyle,
  CheckMarkIconStyle,
  OrderIdLabelStyle,
  OrderIdStyle,
} from './style';

// Component
import { PageTitle } from '../../../../atoms/headLine/HeadLine';
import { TextSecondary, TextPrimary } from '../../../../atoms/text2/Text2';
import HeaderTransferSavingsAccountComplete from '../../../../organisms/header/HeaderContentTitle/transfer/HeaderTransferSavingsAccountComplete';
import IconCheckMarkCircle from '../../../../atoms/icon/IconCheckMarkCircle';
import ProgressStepBarBankTransaction from '../../../../molecules/progressStep/ProgressStepBar/ProgressStepBarBankTransaction';
import TableConfirmTransferSavingsAccount from '../../../../organisms/table/confirm/TableTransferSavingsAccount/TableConfirmTransferSavingsAccount';
import TransferSavingsAccountCompleteTemplateLayout from './TransferSavingsAccountCompleteTemplateLayout';
import LineBreakReplaced from '../../../../atoms/text2/LineBreakReplaced';

const TEMPLATE_ID = `templates.transfers.TransferSavingsAccountCompleteTemplate`;

const TransferSavingsAccountCompleteTemplate: FC = () => {
  const { t } = useTranslation();

  const { transferSavingsAccountTransaction } = useContext(
    TransferSavingsAccountTransactionContext,
  );

  if (!transferSavingsAccountTransaction) return <></>;

  return (
    <TransferSavingsAccountCompleteTemplateLayout>
      <HeaderTransferSavingsAccountComplete key="header" />
      <ProgressStepBarBankTransaction
        key="progressStep"
        currentStep="complete"
      />
      <IconCheckMarkCircle
        key="icon"
        theme={CheckMarkIconStyle}
        size={`${35 / 16}rem`}
        diameter={73}
      />
      <PageTitle key="contentTitle">
        {t(`${TEMPLATE_ID}.contentTitle`)}
      </PageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced text={t(`${TEMPLATE_ID}.description`)} />
      </TextSecondary>
      <TextPrimary key="orderIdLabel" theme={OrderIdLabelStyle}>
        {t(`${TEMPLATE_ID}.orderId.label`)}
      </TextPrimary>
      <TextPrimary key="orderIdValue" theme={OrderIdStyle}>
        {transferSavingsAccountTransaction.getRawId()}
      </TextPrimary>
      <TableConfirmTransferSavingsAccount key="table" />
    </TransferSavingsAccountCompleteTemplateLayout>
  );
};

export default TransferSavingsAccountCompleteTemplate;

import React, { FC, useCallback, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import UserAuthorizedContext from '../../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Style
import { DescriptionStyle, CheckBoxStyle } from './style';

// Component
import { TextSecondary } from '../../../../../../atoms/text2/Text2';
import Checkbox from '../../../../../../atoms/checkbox/Checkbox';
import FormInputShippingAddress from '../../../../../../molecules/form/formInputGroup/FormInputShippingAddress';
import KycFormContainer from '../../../../common/KycFormContainer';
import FormShippingAddressLayout from './FormShippingAddressLayout';

const FORM_ID = `organisms.form.verification.FormCorporateAddress.shippingAddress`;

const FormShippingAddress: FC = () => {
  const [isAddressSame, setIsAddressSame] = useState(false);
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const { userAuthorized } = useContext(UserAuthorizedContext);

  /* *
   *
   *  useEffect
   *
   * */
  useEffect(() => {
    if (isAddressSame) {
      setValue('shippingCountry', watch('country'));
      setValue('shippingAddressLine1', watch('addressLine1'));
      setValue('shippingAddressLine2', watch('addressLine2'));
      setValue('shippingCity', watch('city'));
      setValue('shippingState', watch('state'));
      setValue('shippingPostalCode', watch('postalCode'));
    }
    // eslint-disable-next-line
  }, [isAddressSame]);

  /* *
   *
   *  Event
   *
   * */
  const onClickCheckBox = useCallback(() => {
    setIsAddressSame(!isAddressSame);
    // eslint-disable-next-line
  }, [isAddressSame]);

  if (!userAuthorized || !userAuthorized?.getProps().type) return <></>;

  return (
    <KycFormContainer order={4} formId={FORM_ID}>
      <FormShippingAddressLayout>
        <TextSecondary key="description" theme={DescriptionStyle}>
          {t(`${FORM_ID}.description`)}
        </TextSecondary>
        <Checkbox
          key="checkBox"
          checked={isAddressSame}
          height={40}
          iconSize={20}
          onClick={onClickCheckBox}
          theme={CheckBoxStyle}
          width={40}
        />
        <TextSecondary key="checkBoxDescription" theme={DescriptionStyle}>
          {t(
            `${FORM_ID}.checkbox.description.${userAuthorized.getProps().type}`,
          )}
        </TextSecondary>
        <FormInputShippingAddress
          key="shippingAddress"
          addressKeys={{
            country: 'shippingCountry',
            addressLine1: 'shippingAddressLine1',
            addressLine2: 'shippingAddressLine2',
            city: 'shippingCity',
            state: 'shippingState',
            postalCode: 'shippingPostalCode',
          }}
        />
      </FormShippingAddressLayout>
    </KycFormContainer>
  );
};

export default FormShippingAddress;

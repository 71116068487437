import React, { FC } from 'react';
import { MenuType } from '../../../../config/menu/main/Menu.type';
import { JointAccountMenuType } from '../../../../config/menu/sub/JointAccountMenu.type';
import MenuItemWithSub from '../MenuItemWithSub';

const MAIN_MENU_TYPE = MenuType.jointAccounts;

const JointAccountMenu: FC = () => {
  const JointAccountMenuItems = Object.keys(JointAccountMenuType);

  return (
    <MenuItemWithSub
      key={MAIN_MENU_TYPE}
      type={MAIN_MENU_TYPE}
      menuItems={JointAccountMenuItems}
    />
  );
};

export default JointAccountMenu;

import React from 'react';
import CryptoCurrencyRate from './CryptoCurrencyRate';

export interface CryptoCurrencyRateContextProps {
  cryptoCurrencyRate?: CryptoCurrencyRate;
}

const CryptoCurrencyRateContext = React.createContext<
  CryptoCurrencyRateContextProps
>({
  cryptoCurrencyRate: undefined,
});

export default CryptoCurrencyRateContext;

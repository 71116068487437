// Type
import { KycRequestIndividualProps } from './KycRequestIndividual.type';
import { PersonalInformationProps } from '../../../../../value/verification/kycRequest/KycRequestContent/PersonalInformation/PersonalInformation.type';
import { ProofOfResidencyProps } from '../../../../../value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidency.type';

// DomainObject
import KycRequest from '../KycRequest/KycRequest';
import KycStepStatusIndividual from '../../../../../value/verification/kycRequest/KycStepStatus/KycStepStatusIndividual/KycStepStatusIndividual';

class KycRequestIndividual extends KycRequest {
  protected kycStepStatus: KycStepStatusIndividual;

  constructor(id: string, props: KycRequestIndividualProps) {
    super(id, props);
    this.kycStepStatus = new KycStepStatusIndividual(props.kycStepStatus);
  }

  public getProps() {
    return {
      ...super.getProps(),
      kycStepStatus: this.kycStepStatus.getProps(),
    };
  }

  public getKycStepStatus = () => this.kycStepStatus;

  public getCommandParameter = () => {
    const allStatus = this.kycStepStatus.getProps();

    return {
      ...super.getCommandParameter(),
      status: allStatus.status,
      individual: {
        personalInformation: {
          ...this.personalInformation.getCommandParameter(),
          status: allStatus.personalInformation,
        },
        proofOfResidency: {
          ...this.proofOfResidency.getCommandParameter(),
          status: allStatus.proofOfResidency,
        },
        waitingReview: {
          status: allStatus.waitingReview,
        },
      },
    };
  };

  public updatePersonalInformation = (props: PersonalInformationProps) => {
    const personalInformation = this.personalInformation.updateProps(props);

    return new KycRequestIndividual(this.id, {
      ...this.getProps(),
      personalInformation: personalInformation.getProps(),
    });
  };

  public updateProofOfResidency = (props: ProofOfResidencyProps) => {
    const proofOfResidency = this.proofOfResidency.updateProps(props);

    return new KycRequestIndividual(this.id, {
      ...this.getProps(),
      proofOfResidency: proofOfResidency.getProps(),
    });
  };
}
export default KycRequestIndividual;

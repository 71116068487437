import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedPageVewProvider from '../enhancers/useCase/authentication/AuthenticatedPageViewProvider';
import AccessHandlerUserStatusProvider from '../enhancers/routing/AccessHandlerUserStatusProvider';

// Type
import { MenuType } from '../config/menu/main/Menu.type';

// Constant
import { ALLOWED_USER_STATUS } from './accessHandler/userStatusPageAccessMapper';
import {
  ROUTE_KYC_REQUEST,
  ROUTE_SELECT_USER_TYPE,
  ROUTE_KYC_STATUS,
  ROUTE_CORPORATE_INFORMATION,
  ROUTE_CORPORATE_ADDRESS,
  ROUTE_PERSONAL_INFORMATION,
  ROUTE_PROOF_OF_RESIDENCY,
  ROUTE_CONSENT_FORM,
  ROUTE_TWO_FACTOR_AUTH_APP_SETTINGS,
  ROUTE_GOOGLE_AUTHENTICATOR_GOOGLE_PLAY,
  ROUTE_GOOGLE_AUTHENTICATOR_APP_STORE,
  URL_GOOGLE_AUTHENTICATOR_GOOGLE_PLAY,
  URL_GOOGLE_AUTHENTICATOR_APP_STORE,
} from '../config/constants/routing';

// Component
import { EnhancedKycRequestPage } from '../containers/kycRequest/KycRequestPage';
import { EnhancedSelectUserTypePage } from '../containers/kycRequest/SelectUserTypePage';
import { EnhancedKycStatusPage } from '../containers/kycRequest/KycStatusPage';
import { EnhancedCorporateInformationPage } from '../containers/kycRequest/CorporateInformationPage';
import { EnhancedCorporateAddressPage } from '../containers/kycRequest/CorporateAddressPage';
import { EnhancedPersonalInformationPage } from '../containers/kycRequest/PersonalInformationPage';
import { EnhancedProofOfResidencyPage } from '../containers/kycRequest/ProofOfResidencyPage';
import { EnhancedConsentFormPage } from '../containers/kycRequest/ConsentFormPage';
import { EnhancedTwoFactorAuthAppSettingsPage } from '../containers/kycRequest/TwoFactorAuthAppSettingsPage';

const KycRequestRouter: FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTE_GOOGLE_AUTHENTICATOR_GOOGLE_PLAY}
        component={() => {
          window.location.href = URL_GOOGLE_AUTHENTICATOR_GOOGLE_PLAY;
          return null;
        }}
      />
      <Route
        exact
        path={ROUTE_GOOGLE_AUTHENTICATOR_APP_STORE}
        component={() => {
          window.location.href = URL_GOOGLE_AUTHENTICATOR_APP_STORE;
          return null;
        }}
      />
      <AuthenticatedPageVewProvider>
        <AccessHandlerUserStatusProvider
          statusAllowed={ALLOWED_USER_STATUS[MenuType.verification]}
        >
          <Route exact path={ROUTE_KYC_REQUEST}>
            <EnhancedKycRequestPage />
          </Route>
          <Route path={ROUTE_SELECT_USER_TYPE}>
            <EnhancedSelectUserTypePage />
          </Route>
          <Route path={ROUTE_KYC_STATUS}>
            <EnhancedKycStatusPage />
          </Route>
          <Route path={ROUTE_CORPORATE_INFORMATION}>
            <EnhancedCorporateInformationPage />
          </Route>
          <Route path={ROUTE_CORPORATE_ADDRESS}>
            <EnhancedCorporateAddressPage />
          </Route>
          <Route path={ROUTE_PERSONAL_INFORMATION}>
            <EnhancedPersonalInformationPage />
          </Route>
          <Route path={ROUTE_PROOF_OF_RESIDENCY}>
            <EnhancedProofOfResidencyPage />
          </Route>
          <Route path={ROUTE_CONSENT_FORM}>
            <EnhancedConsentFormPage />
          </Route>
          <Route path={ROUTE_TWO_FACTOR_AUTH_APP_SETTINGS}>
            <EnhancedTwoFactorAuthAppSettingsPage />
          </Route>
        </AccessHandlerUserStatusProvider>
      </AuthenticatedPageVewProvider>
    </Switch>
  );
};

export default KycRequestRouter;

// Types
import { CurrencyType } from '../../../../../../../types/Currency.type';
import {
  CryptoCurrencyType,
  CryptoCurrencyTradeType,
} from '../../../../../../../types/CryptoCurrency.type';
import {
  CryptoCurrencyTransactionStatusType,
  CryptoCurrencyTransactionType,
} from '../../../../../../../types/CryptoCurrencyTransaction.type';
import { CryptoCurrencyTradeTransactionProps } from './CryptoCurrencyTradeTransaction.type';
import { CurrencyPairType } from '../../../../../../../types/CurrencyPair.type';

// Object
import SavingsAccountNumber from '../../../../../../../value/id/SavingsAccountNumber';
import CryptoCurrencyTransactionId from '../../../../../../idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionId';
import CryptoCurrencyTransaction from '../../../../cryptoCurrencyTransaction/CryptoCurrencyTransaction/CryptoCurrencyTransaction';

abstract class CryptoCurrencyTradeTransaction extends CryptoCurrencyTransaction {
  protected savingsAccountNumber: SavingsAccountNumber;

  protected payAmount: number;

  protected payCurrency: CurrencyType;

  protected rate: number;

  protected receiveAmount: number;

  protected receiveCurrency: CurrencyType;

  protected tradeType: CryptoCurrencyTradeType;

  constructor(
    cryptoCurrencyTransactionId: CryptoCurrencyTransactionId,
    props: CryptoCurrencyTradeTransactionProps,
  ) {
    super(cryptoCurrencyTransactionId, props);
    this.savingsAccountNumber = new SavingsAccountNumber(
      props.savingsAccountNumber,
    );
    this.payCurrency = props.payCurrency;
    this.payAmount = props.payAmount;
    this.receiveAmount = props.receiveAmount;
    this.receiveCurrency = props.receiveCurrency;
    this.rate = props.rate;
    this.tradeType = props.tradeType;
  }

  public getProps() {
    return {
      ...super.getProps(),
      savingsAccountNumber: this.savingsAccountNumber.getAccountNumber(),
      payAmount: this.payAmount,
      payCurrency: this.payCurrency,
      receiveAmount: this.receiveAmount,
      receiveCurrency: this.receiveCurrency,
      rate: this.rate,
      tradeType: this.tradeType,
    };
  }

  public abstract getTradeDetail: () => {
    payAccount: string;
    payAmount: number;
    payCurrency: CurrencyType;
    receiveAccount: string;
    receiveAmount: number;
    receiveCurrency: CurrencyType;
  };

  public abstract getTargetCurrency: () => CryptoCurrencyType;

  public abstract getCurrencyPair: () => CurrencyPairType;

  public abstract getTransactionType: () => CryptoCurrencyTransactionType;

  public getFee = () => 0;

  public getStatus = () => CryptoCurrencyTransactionStatusType.confirmed;
}

export default CryptoCurrencyTradeTransaction;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { Container, DescriptionStyle } from './style';

// Component
import { TextSecondary } from '../../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../../atoms/text2/LineBreakReplaced';
import AttentionMessageBox from '../../../notification/AttentionMessageBox';
import KycFormContainer from '../../common/KycFormContainer';

const FORM_ID =
  'organisms.form.verification.FormTwoFactorAuthAppSettings.attentionEditSetting';

const AttentionEditSetting: FC = () => {
  const { t } = useTranslation();

  return (
    <KycFormContainer order={1} formId={`${FORM_ID}`}>
      <Container>
        <AttentionMessageBox>
          <TextSecondary key="description" theme={DescriptionStyle}>
            <LineBreakReplaced text={t(`${FORM_ID}.attentionMessage`)} />
          </TextSecondary>
        </AttentionMessageBox>
      </Container>
    </KycFormContainer>
  );
};

export default AttentionEditSetting;

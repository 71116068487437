/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Type
import { FormInputPersonalInformation } from '../../../../../../components/organisms/form/verification/kycRequest/FormPersonalInformation/FormPersonalInformation.type';

// Constant
import { INITIAL } from '../../../../../../config/constants/requestState';

export interface PersonalInformationSubmitContextProps {
  state: string;
  submitPersonalInformation: (
    content: FormInputPersonalInformation,
    callback?: () => void,
  ) => void;
}

const PersonalInformationSubmitContext = React.createContext<
  PersonalInformationSubmitContextProps
>({
  state: INITIAL,
  submitPersonalInformation: (
    content: FormInputPersonalInformation,
    callback?: () => void,
  ) => undefined,
});

export default PersonalInformationSubmitContext;

import axios from 'axios';
import { REQUEST_TIMEOUT } from '../../../config/constants/business';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = true;
axios.defaults.timeout = REQUEST_TIMEOUT;

const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
const FUNCTIONS_REGION = process.env.REACT_APP_FUNCTIONS_REGION;
const FUNCTIONS_NAME = process.env.REACT_APP_FUNCTIONS_NAME;

let apiUrl = `http://localhost:5001/${PROJECT_ID}/us-central1/${FUNCTIONS_NAME}`;

switch (process.env.NODE_ENV) {
  case 'development':
    apiUrl = `http://localhost:5001/${PROJECT_ID}/us-central1/${FUNCTIONS_NAME}`;
    break;
  case 'production':
    apiUrl = `https://${FUNCTIONS_REGION}-${PROJECT_ID}.cloudfunctions.net/${FUNCTIONS_NAME}`;
    break;

  default:
    break;
}

axios.defaults.baseURL = apiUrl;

export default axios;

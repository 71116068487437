import React, { FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import LoadingModal from 'components/organisms/modal/LoadingModal';
import { ROUTE_SIGN_IN } from '../../../config/constants/routing';
import UserAuthorizedContext from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import { UserStatusType } from '../../../types/User.type';
import { UserStatusTopPage } from './userStatusTopPage';

export type AccessHandlerUserStatusProps = {
  children: React.ReactNode;
  statusAllowed: UserStatusType[];
};

const customLoadingStyle = {
  overlay: {
    backgroundColor: 'white',
  },
};

const AccessHandlerUserStatusProvider: FC<AccessHandlerUserStatusProps> = ({
  children,
  statusAllowed,
}: AccessHandlerUserStatusProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const history = useHistory();
  const [showModal, hideModal] = useModal(() => (
    <LoadingModal style={customLoadingStyle} />
  ));

  useEffect(() => {
    if (userAuthorized) {
      const { status } = userAuthorized.getProps();
      const topPage = UserStatusTopPage[status];

      if (!statusAllowed.includes(status)) {
        history.push(topPage || ROUTE_SIGN_IN);
      }
      hideModal();
    } else {
      showModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuthorized, history, statusAllowed]);

  return <>{children}</>;
};
export default AccessHandlerUserStatusProvider;

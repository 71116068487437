import React, { FC } from 'react';
import ReactModal from 'react-modal';

// Style
import { contentStyle, overlayStyle } from './style';

// Component
import NotificationSignInTimeOut from '../../notification/NotificationSignInTimeOut';

const SignInTimeOutModal: FC = () => {
  const modalStyle = {
    content: contentStyle,
    overlay: overlayStyle,
  };

  return (
    <ReactModal isOpen={true} style={modalStyle} ariaHideApp={false}>
      <NotificationSignInTimeOut />
    </ReactModal>
  );
};

export default SignInTimeOutModal;

import { useMemo } from 'react';
import { PATH_CONSENT_FORM } from '../../../../../config/constants/routing';

export const useConsentFormDownloadFile = () => {
  const consentFormDownloadUrl = useMemo(() => PATH_CONSENT_FORM, []);

  return {
    consentFormDownloadUrl,
  };
};

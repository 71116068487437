import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import KycRequestCreateContext from '../../../../../../enhancers/useCase/verification/kycRequest/KycRequestCreateProvider/KycRequestCreateContext';

// Type
import { FormInputSelectUserType } from './FormSelectUserType.type';
import { UserType } from '../../../../../../types/User.type';

// Constant
import { IS_LOADING } from '../../../../../../config/constants/requestState';

// Component
import { TextPrimary } from '../../../../../atoms/text2/Text2';
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../../atoms/button/Button2';
import UserTypeCard from '../../../../card/verification/UserTypeCard';
import FormSelectUserTypeLayout from './FormSelectUserTypeLayout';
import { ROUTE_KYC_STATUS } from '../../../../../../config/constants/routing';

const FormSelectUserType: FC = () => {
  const { t } = useTranslation();
  const { state, createKycRequest } = useContext(KycRequestCreateContext);
  const history = useHistory();

  /* *
   *
   *  Form
   *
   * */
  const { handleSubmit, setValue, watch } = useForm<FormInputSelectUserType>({
    mode: 'onChange',
  });

  /* *
   *
   *  Event
   *
   * */
  const onClickType = (type: UserType) => {
    setValue('userType', type);
  };

  return (
    <form
      onSubmit={handleSubmit(() => {
        createKycRequest(watch(`userType`) as UserType, () => {
          history.push(ROUTE_KYC_STATUS);
        });
      })}
    >
      <FormSelectUserTypeLayout>
        <UserTypeCard
          key="individual"
          type={UserType.individual}
          isSelected={watch('userType') === UserType.individual}
          onClick={onClickType}
        />
        <UserTypeCard
          key="corporate"
          type={UserType.corporate}
          isSelected={watch('userType') === UserType.corporate}
          onClick={onClickType}
        />
        <SubmitButton
          key="button"
          disabled={!watch('userType')}
          height={50}
          isLoading={state === IS_LOADING}
          theme={{
            borderRadius: 25,
          }}
          sizeProgress={20}
          width={143}
        >
          <TextPrimary theme={DefaultButtonTextStyle}>
            {t(`atoms.button.next`)}
          </TextPrimary>
        </SubmitButton>
      </FormSelectUserTypeLayout>
    </form>
  );
};

export default FormSelectUserType;

import LitecoinTestnetWallet from '../LitecoinTestnetWallet/LitecoinTestnetWallet';
import CryptoCurrencyWallets from '../../CryptoCurrencyWallet/CryptoCurrencyWallets/CryptoCurrencyWallets';

class LitecoinTestnetWallets extends CryptoCurrencyWallets {
  private litecoinTestnetWallets: LitecoinTestnetWallet[] = [];

  public add = (litecoinTestnetWallet: LitecoinTestnetWallet) => {
    this.litecoinTestnetWallets.push(litecoinTestnetWallet);
  };

  public head = () => {
    const litecoinTestnetWallet = this.litecoinTestnetWallets[0];
    return litecoinTestnetWallet;
  };

  public map = <T>(
    callback: (litecoinTestnetWallet: LitecoinTestnetWallet) => T,
  ) => {
    return this.litecoinTestnetWallets.map((litecoinTestnetWallet) =>
      callback(litecoinTestnetWallet),
    );
  };

  public forEach = (
    callback: (litecoinTestnetWallet: LitecoinTestnetWallet) => void,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const litecoinTestnetWallet of this.litecoinTestnetWallets) {
      // eslint-disable-next-line no-await-in-loop
      callback(litecoinTestnetWallet);
    }
  };

  public forEachAsync = async (
    callback: (LitecoinTestnetWallet: LitecoinTestnetWallet) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const litecoinTestnetWallet of this.litecoinTestnetWallets) {
      // eslint-disable-next-line no-await-in-loop
      await callback(litecoinTestnetWallet);
    }
  };
}

export default LitecoinTestnetWallets;

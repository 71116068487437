// FirebaseFunctions
import FirebasePrivateFunctions from '../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// axios
import {
  AUTHENTICATOR_CREATE,
  AUTHENTICATOR_CREATE_RE_REGISTRATION,
  AUTHENTICATOR_VERIFY,
} from '../../infrastructure/firebase/firebaseFunctions/endpoint';

// IRepository
import IAuthenticatorVerifier from '../../domain/authenticator/authenticator/IAuthenticatorVerifier';
import { IAuthenticatorCreateRepository } from '../../domain/authenticator/authenticator/IAuthenticatorRepository';

// DomainObject
import Authenticator from '../../domain/authenticator/authenticator/Authenticator/Authenticator';
import UserAuthorized from '../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import UserVerified from '../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';
import UserReregistration from '../../domain/public/user/User/UserReregistration/UserReregistration';

class AuthenticatorFunctions
  extends FirebasePrivateFunctions
  implements IAuthenticatorVerifier, IAuthenticatorCreateRepository {
  public verify = async (user: UserVerified, code: string) => {
    await this.privatePOST(AUTHENTICATOR_VERIFY, {
      id: user.getId(),
      code,
    });
  };

  public create = async (user: UserAuthorized) => {
    const response = await this.privatePOST(`${AUTHENTICATOR_CREATE}`, {
      id: user.getId(),
    });

    return new Authenticator(user.getId(), response.data.accountKey);
  };

  public createForReregistration = async (
    user: UserReregistration,
    oobCode: string,
  ) => {
    const response = await this.publicPOST(
      `${AUTHENTICATOR_CREATE_RE_REGISTRATION}`,
      {
        oobCode,
      },
    );

    return new Authenticator(user.getId(), response.data.accountKey);
  };
}
export default AuthenticatorFunctions;

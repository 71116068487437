import React, { FC } from 'react';
import { LinkTextButton } from './style';
import TextSub from '../../../atoms/text/TextSub';

export interface LinkActionTextProps {
  text: string;
  textPixel: number;
  textColor: string;
  textFontWeight: number;
  onClick: () => void;
}

const LinkActionText: FC<LinkActionTextProps> = ({
  text,
  textPixel,
  textColor,
  textFontWeight,
  onClick,
}: LinkActionTextProps) => {
  return (
    <LinkTextButton onClick={onClick}>
      <TextSub
        pixel={textPixel}
        color={textColor}
        fontWeight={textFontWeight}
        textDecoration="underline"
      >
        {text}
      </TextSub>
    </LinkTextButton>
  );
};
export default LinkActionText;

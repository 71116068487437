import styled from 'styled-components';
import leftLogoBg from '../../../../assets/images/left-logo-bg.png';

export const Container = styled.div`
  width: 100%;
  z-index: 1;
  height: 100%;
  align-items: center;
  background-image: url(${leftLogoBg});
  background-size: cover;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  width: ${403 / 16}rem;
  height: ${96.51 / 16}rem;
`;

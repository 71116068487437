import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

// Lib
import { deleteFullWidth } from '../../../../../utils/helpers/stringHelper';
import { nameValidation } from '../../../../../utils/validation/registers';

// Component
import { TextPrimary, TextThemeProps } from '../../../../atoms/text2/Text2';
import TextField from '../../TextField2';
import FormInputFullNameLayout from './FormInputFullNameLayout';

const MOLECULE_ID = `molecules.formInputGroup.formInputFullName`;

export interface FormInputFullNameProps {
  label: string;
  theme: {
    text: TextThemeProps;
  };
  textInputWidth: string | number;
}

const FormInputFullName: FC<FormInputFullNameProps> = ({
  label,
  theme,
  textInputWidth,
}: FormInputFullNameProps) => {
  const { t } = useTranslation();
  const { register, setValue } = useFormContext();

  return (
    <FormInputFullNameLayout textInputWidth={textInputWidth}>
      <TextPrimary key="label" theme={theme.text}>
        {label}
      </TextPrimary>
      <TextField
        key="firstName"
        maxLength={50}
        name="firstName"
        onChange={(text: string) => {
          setValue('firstName', deleteFullWidth(text));
        }}
        placeholder={t(`${MOLECULE_ID}.firstName.placeholder`)}
        register={register(nameValidation(t).firstName)}
        textInputWidth={textInputWidth}
      />
      <TextField
        key="middleName"
        maxLength={50}
        name="middleName"
        onChange={(text: string) => {
          setValue('middleName', deleteFullWidth(text));
        }}
        placeholder={t(`${MOLECULE_ID}.middleName.placeholder`)}
        register={register(nameValidation(t).middleName)}
        textInputWidth={textInputWidth}
      />
      <TextField
        key="lastName"
        maxLength={50}
        name="lastName"
        onChange={(text: string) => {
          setValue('lastName', deleteFullWidth(text));
        }}
        placeholder={t(`${MOLECULE_ID}.lastName.placeholder`)}
        register={register(nameValidation(t).lastName)}
        textInputWidth={textInputWidth}
      />
    </FormInputFullNameLayout>
  );
};

export default FormInputFullName;

import Joi from '@hapi/joi';
import { KycStepStatusIndividualInitialProps } from '../../../../../value/verification/kycRequest/KycStepStatus/KycStepStatusIndividual/KycStepStatusIndividual.type';

// Type
import { KycStepStatusProps } from '../../../../../value/verification/kycRequest/KycStepStatus/KycStepStatus/KycStepStatus.type';
import { PersonalInformationProps } from '../../../../../value/verification/kycRequest/KycRequestContent/PersonalInformation/PersonalInformation.type';
import { ProofOfResidencyProps } from '../../../../../value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidency.type';
import { UserType } from '../../../../../types/User.type';

// DomainObject
import PersonalInformationFactory from '../../../../../value/verification/kycRequest/KycRequestContent/PersonalInformation/PersonalInformationFactory';
import ProofOfResidencyFactory from '../../../../../value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidencyFactory';

export interface KycRequestProps {
  username: string;

  type: UserType;

  personalInformation: PersonalInformationProps;

  proofOfResidency: ProofOfResidencyProps;

  kycStepStatus: KycStepStatusProps;

  createdAt?: number;

  updatedAt?: number;
}

export const KycRequestPropsFormat = {
  id: Joi.string().min(1).required(),

  type: Object.keys(UserType)
    .reduce((joi, type) => joi.valid(type), Joi.string())
    .required(),

  username: Joi.string().min(2).max(20).required(),

  personalInformation: Joi.any().required(),

  proofOfResidency: Joi.any().required(),

  kycStepStatus: Joi.any().required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

export const KycRequestInitialProps = {
  personalInformation: PersonalInformationFactory.createInitial().getProps(),

  proofOfResidency: ProofOfResidencyFactory.createInitial().getProps(),

  kycStepStatus: KycStepStatusIndividualInitialProps,
};

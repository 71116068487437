import color from 'components/atoms/utils/const/color';
import styled from 'styled-components';
import fontWeight from 'components/atoms/utils/const/fontWeight';

export const TextStyle = {
  fontSize: 20,
  fontWeight: fontWeight.medium,
};

export const IconContainer = styled.div`
  background-color: ${color.background.summerSky};
  border-radius: 16px;
`;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ContractTimeDepositRenewalReservationPageState } from './useContractTimeDepositRenewalReservationPageState';

export interface ContractTimeDepositRenewalReservationPageStateContextProps {
  pageState: ContractTimeDepositRenewalReservationPageState;
  setPageState: (state: ContractTimeDepositRenewalReservationPageState) => void;
}

const ContractTimeDepositRenewalReservationPageStateContext = React.createContext<
  ContractTimeDepositRenewalReservationPageStateContextProps
>({
  pageState: 'initial',
  setPageState: (state: ContractTimeDepositRenewalReservationPageState) =>
    undefined,
});

export default ContractTimeDepositRenewalReservationPageStateContext;

// IUseCase
import IDebitCardRequestCherryVisa01PayIssuanceFeeViewUseCase from '../../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01PayIssuanceFeeViewProvider/IDebitCardRequestCherryVisa01PayIssuanceFeeViewUseCase';

// IService
import ISavingsAccountQueryService from '../../../bankAccount/savingsAccount/ISavingsAccountQueryService';

// Service
import SavingsAccountQueryService from '../../../../service/bankAccount/savingsAccount/SavingsAccountQueryService';

// IRepository
import { ISavingsAccountQueryRepository } from '../../../../domain/bankAccount/savingsAccount/ISavingsAccountRepository';

class DebitCardRequestCherryVisa01PayIssuanceFeeViewUseCase
  implements IDebitCardRequestCherryVisa01PayIssuanceFeeViewUseCase {
  private savingsAccountQueryService: ISavingsAccountQueryService;

  constructor(savingsAccountQueryRepository: ISavingsAccountQueryRepository) {
    this.savingsAccountQueryService = new SavingsAccountQueryService(
      savingsAccountQueryRepository,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public open = async (savingsAccountNumber: string) => {
    const savingsAccount = await this.savingsAccountQueryService.findById(
      savingsAccountNumber,
    );

    return { savingsAccount };
  };
}

export default DebitCardRequestCherryVisa01PayIssuanceFeeViewUseCase;

import React from 'react';
import { INITIAL } from '../../../config/constants/requestState';

export interface RequestStateContextProps {
  requestState: string;
}

const RequestStateContext = React.createContext<RequestStateContextProps>({
  requestState: INITIAL,
});

export default RequestStateContext;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { PayOutBadgeStyle, PayOutBadgeLayout } from './style';

// Component
import { TextSecondary } from '../../../atoms/text2/Text2';

// LayoutComponent
import { FlexBoxRow } from '../../../atoms/utils/layout/Box/FlexBox';

export interface PayOutBadgeProps {
  payOutType: 'maturity' | 'annual';
}

const MOLECULES_ID = `molecules.badge.payOut`;

const PayOutBadge: FC<PayOutBadgeProps> = ({
  payOutType,
}: PayOutBadgeProps) => {
  const { t } = useTranslation();

  return (
    <FlexBoxRow
      width="auto"
      height="auto"
      padding={3}
      theme={PayOutBadgeLayout}
    >
      <TextSecondary theme={PayOutBadgeStyle} style={{ whiteSpace: 'nowrap' }}>
        {t(`${MOLECULES_ID}.${payOutType}`)}
      </TextSecondary>
    </FlexBoxRow>
  );
};

export default PayOutBadge;

import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  width: ${500 / 16}rem;
  // width: ${540 / 16}rem;
  padding: ${50 / 16}rem;
  // padding: ${70 / 16}rem;
  border-radius: ${25 / 16}rem;
  background: #304258 0% 0% no-repeat padding-box;
`;

export const LinkTextContainer = styled.div`
  position: relative;
  top: -${40 / 16}rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SubmitButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ErrorMessageContainer = styled.div`
  position: relative;
  top: -${5 / 16}rem;
  width: 100%;
  height: ${20 / 16}rem;
  display: flex;
  justify-content: center;
`;

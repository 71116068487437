import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../../atoms/headLine/HeadLine';

import PasswordEditForm from '../../../organisms/form/settings/PasswordEditForm';
import SettingsTemplate from '../SettingsTemplateContainer';
import SettingTemplateFormContent from '../SettingTemplateFormContent';

const PasswordEditTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <SettingsTemplate>
      <PageTitle>{t(`userSettings.PasswordEdit.title`)}</PageTitle>
      <div style={{ marginTop: `${50 / 16}rem` }}>
        <SettingTemplateFormContent>
          <PasswordEditForm />
        </SettingTemplateFormContent>
      </div>
    </SettingsTemplate>
  );
};

export default PasswordEditTemplate;

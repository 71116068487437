import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import CryptoCurrencyTemplateContainer from '../../common/CryptoCurrencyTemplateContainer';
import { Content } from './style';
import Margin from '../../../../atoms/utils/layout/Margin';
import { FlexBoxRow } from '../../../../atoms/utils/layout/Box/FlexBox';

export interface CryptoCurrencyTradeConfirmTemplateLayoutProps {
  children: React.ReactElement[];
}

const CryptoCurrencyTradeConfirmTemplateLayout: FC<CryptoCurrencyTradeConfirmTemplateLayoutProps> = ({
  children,
}: CryptoCurrencyTradeConfirmTemplateLayoutProps) => {
  const elements = [
    'header',
    'contentHeader',
    'description',
    'tableTitle',
    'rateUpdateInterval',
    'table',
    'form',
  ];
  const [
    header,
    contentHeader,
    description,
    tableTitle,
    rateUpdateInterval,
    table,
    form,
  ] = getElementsFromKeys(children, elements);

  return (
    <CryptoCurrencyTemplateContainer>
      {header}
      <Margin top={50}>
        <Content>
          {contentHeader}
          <Margin top={30}>{description}</Margin>
          <Margin top={30}>{tableTitle}</Margin>
          <FlexBoxRow width="100%" justifyContent="flex-end">
            {rateUpdateInterval}
          </FlexBoxRow>
          <Margin top={10}>{table}</Margin>
          <Margin top={30}>{form}</Margin>
        </Content>
      </Margin>
    </CryptoCurrencyTemplateContainer>
  );
};
export default CryptoCurrencyTradeConfirmTemplateLayout;

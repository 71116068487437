// Lib
import { isEqualArbitraryOrder } from '../../../../../utils/helpers/arrayHelper';

// Error
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';

// Types
import { CryptoCurrencySellTransactionProps } from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencySellTransaction/CryptoCurrencySellTransaction/CryptoCurrencySellTransaction.type';
import { CryptoCurrencyTradeType } from '../../../../../types/CryptoCurrency.type';

// Collection
import CryptoCurrencySellsCollection from '../../../../../infrastructure/firebase/firestore/collections/transaction/cryptoCurrencyTransaction/addresses/CryptoCurrencySellsCollection';

// IRepository
import ICryptoCurrencySellTransactionRepository from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencySellTransaction/ICryptoCurrencySellTransactionRepository';

// DomainObject
import CryptoCurrencySellTransactions from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencySellTransaction/CryptoCurrencySellTransactions/CryptoCurrencySellTransactions';
import CryptoCurrencySellTransactionFactory from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencySellTransaction/CryptoCurrencySellTransaction/CryptoCurrencySellTransactionFactory';
import CryptoCurrencyTransactionIdFactory from '../../../../../domain/idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionIdFactory';

class CryptoCurrencySellTransactionFirestore
  implements ICryptoCurrencySellTransactionRepository {
  public findAllByFields = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: { [key: string]: any },
  ) => {
    if (isEqualArbitraryOrder(Object.keys(params), ['address', 'currency'])) {
      const transactionParams = params as { address: string; currency: string };

      const cryptoCurrencySellTransaction = this.findByCurrencyAndAddress(
        transactionParams,
      );

      return cryptoCurrencySellTransaction;
    }

    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_SWITCH,
      {
        value: JSON.stringify(params),
        place: `CryptoCurrencySellTransactionFirestore.findAllByFields`,
      },
    );
  };

  private findByCurrencyAndAddress = async (params: {
    address: string;
    currency: string;
  }) => {
    const cryptoCurrencySellsCollection = new CryptoCurrencySellsCollection(
      params.currency,
      params.address,
    );

    const docs = await cryptoCurrencySellsCollection.fetchAll();

    return docs.reduce<CryptoCurrencySellTransactions>(
      (cryptoCurrencySellTransactions, doc) => {
        const transactionId = CryptoCurrencyTransactionIdFactory.createFromRawId(
          doc.id,
          doc.data()!.commonId,
        );

        cryptoCurrencySellTransactions.add(
          CryptoCurrencySellTransactionFactory.create(transactionId, {
            ...doc.data(),
            userId: '',
            savingsAccountNumber: '',
            tradeType: CryptoCurrencyTradeType.sell,
          } as CryptoCurrencySellTransactionProps),
        );
        return cryptoCurrencySellTransactions;
      },
      new CryptoCurrencySellTransactions(),
    );
  };
}
export default CryptoCurrencySellTransactionFirestore;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Constant
import { ROUTE_HOME } from '../../../../config/constants/routing';
import { RE_SIGN_UP } from '../../../../config/constants/pageId.json';

// Style
import { NavigationTextStyle, NoticeTextStyle } from './style';

// Component
import { FrontPageTitle } from '../../../atoms/headLine/HeadLine';
import { TextSecondary } from '../../../atoms/text2/Text2';
import FormResignUp from '../../../organisms/form/front/FormResignUp';
import Breadcrumb from '../../../molecules/link/Breadcrumb';
import ResignUpTemplateLayout from './ResignUpTemplateLayout';

const ResignUpTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <ResignUpTemplateLayout>
      <FrontPageTitle key="title">{t(`${RE_SIGN_UP}.title`)}</FrontPageTitle>
      <TextSecondary key="notice" theme={NoticeTextStyle}>
        {t(`${RE_SIGN_UP}.notice`)}
      </TextSecondary>
      <FormResignUp key="form" />
      <Breadcrumb
        key="linkTop"
        href={ROUTE_HOME}
        iconSize={10}
        marginBetween={13}
        text={t(`${RE_SIGN_UP}.link.top`)}
        theme={{ text: NavigationTextStyle }}
      />
    </ResignUpTemplateLayout>
  );
};

export default ResignUpTemplate;

import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { deleteFullWidth } from '../../../../../../utils/helpers/stringHelper';
import { authenticatorCodeInputRegister } from '../../../../../../utils/validation/registers';
import { ErrorCodeType } from '../../../../../../utils/errors/ErrorHandler/ErrorCode.type';
import { TextFieldAuthenticator } from '../../../TextField';
import FormInputAuthenticatorLayout from './FormInputAuthenticatorLayout';
import { TextSecondary } from '../../../../../atoms/text2/Text2';
import { DescriptionStyle } from './style';

const MOLECULE_ID = 'molecules.formInput.FormInputAuthenticator';

export interface FormInputAuthenticatorProps {
  state: string;
}

const FormInputAuthenticator: FC<FormInputAuthenticatorProps> = ({
  state,
}: FormInputAuthenticatorProps) => {
  const { t } = useTranslation();
  const {
    clearErrors,
    errors,
    setError,
    setValue,
    watch,
    register,
  } = useFormContext();

  useEffect(() => {
    if (state === ErrorCodeType.AUTHENTICATION_AUTHENTICATOR_INVALID_CODE) {
      const errorCode = ErrorCodeType.AUTHENTICATION_AUTHENTICATOR_INVALID_CODE;
      setError('validCode', {
        type: '',
        message: t(`validation.${errorCode}`),
      });
    }
  }, [state, setError, t]);

  return (
    <FormInputAuthenticatorLayout>
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${MOLECULE_ID}.description.line1`)}
      </TextSecondary>
      <TextFieldAuthenticator
        key="textField"
        name="code"
        value={watch(`code`)}
        errorMessage={errors?.code?.message || errors?.validCode?.message}
        onChange={(text: string) => {
          if (errors.validCode) clearErrors('validCode');
          setValue('code', deleteFullWidth(text));
        }}
        register={register(authenticatorCodeInputRegister('code', t))}
        maxLength={10}
        textInputWidth={`${400 / 16}rem`}
      />
    </FormInputAuthenticatorLayout>
  );
};
export default FormInputAuthenticator;

// IService
import ISignInRequestUpdateService from '../../useCases/authentication/signInTwoFactorAuth/ISignInRequestUpdateService';

// IRepository
import { ISignInRequestUpdateRepository } from '../../domain/userRequest/signInRequest/ISignInRequestRepository';

// DomainObject
import UserAuthorized from '../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class SignInRequestUpdateService implements ISignInRequestUpdateService {
  private signInRequestUpdateRepository: ISignInRequestUpdateRepository;

  constructor(signInRequestUpdateRepository: ISignInRequestUpdateRepository) {
    this.signInRequestUpdateRepository = signInRequestUpdateRepository;
  }

  public executeUpdate = async (
    userAuthorized: UserAuthorized,
    code: string,
  ) => {
    await this.signInRequestUpdateRepository.update(userAuthorized, code);
  };
}

export default SignInRequestUpdateService;

import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const CautionStyle = {
  fontSize: 26,
  color: color.text.red,
  fontWeight: fontWeight.bold,
};

export const FormContainerStyle = {
  backgroundColor: color.background.white,
};

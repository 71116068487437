/* eslint-disable no-control-regex */

/*
 * function includeFullWidth
 *
 * True
 *   If {str} includes Full width character
 *
 */
export const includeFullWidth = (str: string): boolean => {
  return !!str.match(/[^\x01-\x7E\xA1-\xDF]/);
};

/*
 * function deleteFullWidth
 *
 * return
 *   string (str) after fullWidth characters are removed
 *
 */
export const deleteFullWidth = (str: string): string => {
  return str.replace(/[^\x01-\x7E\xA1-\xDF]/g, '');
};

/*
 * function withHyphenFormat
 *
 * return
 *   insert hyphen(-) at @position on @str
 *
 */
export const withHyphenFormat = (str: string, position: number): string => {
  const front = str.substr(0, position);
  const back = str.substr(position, str.length - position);

  return `${front}-${back}`;
};

/*
 * Format ECB Bank Account Number
 */
export const formattedBankAccountNumber = (id: string) => {
  const idBeforeFormatted = id.replace(/[^A-Za-z0-9]/g, '');
  return `${idBeforeFormatted.substring(0, 3)}-${idBeforeFormatted.substring(
    3,
    6,
  )}-${idBeforeFormatted.slice(6, 13)}`;
};

// Lib
import numeral from 'numeral';
import { round, floor } from 'lodash';

// Error
import SystemErrorFactory from '../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../errors/ErrorMessage/ErrorMessage';

// Type
import { CurrencyType } from '../../types/Currency.type';
import {
  CryptoCurrencyType,
  CryptoCurrencyProps,
} from '../../types/CryptoCurrency.type';
import {
  FiatCurrencyType,
  FiatCurrencyProps,
} from '../../types/FiatCurrency.type';
import {
  CurrencyPairType,
  CurrencyPairProps,
} from '../../types/CurrencyPair.type';

/**
 *
 * function getSymbolFromCurrency
 *
 * return Currency Symbol (string)
 *
 */
export const getSymbolFromCurrency = (currency: CurrencyType): string => {
  // CryptoCurrency
  if (Object.keys(CryptoCurrencyType).includes(currency))
    return CryptoCurrencyProps[currency].symbol;

  // FiatCurrency
  if (Object.keys(FiatCurrencyType).includes(currency))
    return FiatCurrencyProps[currency].symbol;

  throw SystemErrorFactory.createByErrorId(
    ErrorIdType.INVALID_ARGUMENT_SWITCH,
    {
      value: currency,
      place: `getSymbolFromCurrency`,
    },
  );
};

/**
 *
 * function getNameFromCurrency
 *
 * return Currency Symbol (string)
 *
 */
export const getNameFromCurrency = (currency: CurrencyType): string => {
  // CryptoCurrency
  if (Object.keys(CryptoCurrencyType).includes(currency))
    return CryptoCurrencyProps[currency].name;

  // FiatCurrency
  if (Object.keys(FiatCurrencyType).includes(currency))
    return FiatCurrencyProps[currency].name;

  throw SystemErrorFactory.createByErrorId(
    ErrorIdType.INVALID_ARGUMENT_SWITCH,
    {
      value: currency,
      place: `getNameFromCurrency`,
    },
  );
};

/**
 *
 * function convertMoneyFormat
 *
 * return Formatted Number (#,###.##)
 *
 */

export const convertMoneyFormat = (
  amount: number,
  currency: CurrencyType | CurrencyPairType,
  callback?: (num: number, precision: number) => number,
): string => {
  let precision = 0;

  if (Object.keys(CryptoCurrencyType).includes(currency))
    precision = CryptoCurrencyProps[currency].precision;
  else if (Object.keys(FiatCurrencyType).includes(currency))
    precision = FiatCurrencyProps[currency].precision;
  else if (Object.keys(CurrencyPairType).includes(currency))
    precision = CurrencyPairProps[currency].precision;
  else
    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_SWITCH,
      {
        value: currency,
        place: 'convertMoneyFormat',
      },
    );

  let format = precision > 0 ? '0,0.' : '0,0';
  for (let i = 0; i < precision; i += 1) format += '0';

  const rounded = callback ? callback(amount, precision) : amount;

  return numeral(rounded).format(format);
};

/**
 *
 *  function convertMoneyRounded
 *
 *  return Rounded Number
 *
 */
export const convertMoneyRounded = (
  amount: number,
  currency: CurrencyType | CurrencyPairType,
): number => {
  let precision = 0;

  if (Object.keys(CryptoCurrencyType).includes(currency))
    precision = CryptoCurrencyProps[currency].precision;
  else if (Object.keys(FiatCurrencyType).includes(currency))
    precision = FiatCurrencyProps[currency].precision;
  else if (Object.keys(CurrencyPairType).includes(currency))
    precision = CurrencyPairProps[currency].precision;
  else
    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_SWITCH,
      {
        value: currency,
        place: `convertMoneyRounded`,
      },
    );

  return round(amount, precision);
};

/**
 *
 *  function convertMoneyRoundedDown
 *
 *  return Rounded Number
 *
 */

export const convertMoneyRoundDown = (
  amount: number,
  currency: CurrencyType | CurrencyPairType,
): number => {
  let precision = 0;

  if (Object.keys(CryptoCurrencyType).includes(currency))
    precision = CryptoCurrencyProps[currency].precision;
  else if (Object.keys(FiatCurrencyType).includes(currency))
    precision = FiatCurrencyProps[currency].precision;
  else if (Object.keys(CurrencyPairType).includes(currency))
    precision = CurrencyPairProps[currency].precision;
  else
    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_SWITCH,
      {
        value: currency,
        place: `convertMoneyRoundDown`,
      },
    );

  return floor(amount, precision);
};

/**
 *
 *  function getMinTransferAmount
 *
 *  return minTransferAmount
 *
 */
export const getMinTransferAmount = (currency: CurrencyType) => {
  switch (currency) {
    case FiatCurrencyType.USD:
      return FiatCurrencyProps[currency].minTransferAmount;
    default:
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_SWITCH,
        {
          value: currency,
          place: `getMinTransferAmount`,
        },
      );
  }
};

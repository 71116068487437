import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessHandlerUserStatusProvider from '../enhancers/routing/AccessHandlerUserStatusProvider';
import AuthenticatedPageViewProvider from '../enhancers/useCase/authentication/AuthenticatedPageViewProvider';

// Type
import { MenuType } from '../config/menu/main/Menu.type';

// Constant
import { ALLOWED_USER_STATUS } from './accessHandler/userStatusPageAccessMapper';
import {
  ROUTE_SAVINGS_ACCOUNT,
  ROUTE_SAVINGS_ACCOUNT_HISTORY,
} from '../config/constants/routing';

// Component
import { EnhancedSavingsAccountPage } from '../containers/savingsAccount/SavingsAccountPage';
import { EnhancedSavingsAccountHistoryPage } from '../containers/savingsAccount/SavingsAccountHistoryPage';

const SavingsAccountRouter: FC = () => {
  return (
    <Switch>
      <AuthenticatedPageViewProvider>
        <AccessHandlerUserStatusProvider
          statusAllowed={ALLOWED_USER_STATUS[MenuType.savingsAccounts]}
        >
          <Route exact path={ROUTE_SAVINGS_ACCOUNT}>
            <EnhancedSavingsAccountPage />
          </Route>
          <Route exact path={`${ROUTE_SAVINGS_ACCOUNT_HISTORY}/:accountNumber`}>
            <EnhancedSavingsAccountHistoryPage />
          </Route>
        </AccessHandlerUserStatusProvider>
      </AuthenticatedPageViewProvider>
    </Switch>
  );
};

export default SavingsAccountRouter;

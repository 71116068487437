import React, { FC } from 'react';
import { GradationBlueButton } from './Button';

export interface CopyButtonProps {
  children: React.ReactNode;
  width?: string | number;
  height?: string | number;
  copyText: string;
  callbackAfterCopy?: () => void;
  theme?: {
    backgroundColor?: string;
    borderRadius?: string | number;
  };
}

const CopyButton: FC<CopyButtonProps> = ({
  children,
  width = '100%',
  height = '100%',
  copyText,
  callbackAfterCopy = () => undefined,
  theme,
}: CopyButtonProps) => {
  const copyToClipboard = () => {
    const textField = document.createElement('textarea');
    textField.innerText = copyText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  return (
    <GradationBlueButton
      width={width}
      height={height}
      theme={theme}
      onClick={() => {
        copyToClipboard();
        callbackAfterCopy();
      }}
    >
      {children}
    </GradationBlueButton>
  );
};

export default CopyButton;

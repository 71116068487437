import React, { FC, useMemo } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Constant
import { SUCCESS } from '../../../../config/constants/requestState';

// LayoutComponent
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface DebitCardRequestTemplateLayoutProps {
  children: React.ReactElement[];
  viewState: string;
}

const DebitCardRequestTemplateLayout: FC<DebitCardRequestTemplateLayoutProps> = ({
  children,
  viewState,
}: DebitCardRequestTemplateLayoutProps) => {
  const elements = ['header', 'description', 'button', 'loader'];
  const [header, description, button, loader] = getElementsFromKeys(
    children,
    elements,
  );

  // Variable
  const isPageLoading = useMemo(() => viewState !== SUCCESS, [viewState]);
  const paddingTop = useMemo(
    () => (isPageLoading ? `${(400 / 890) * 100}vh` : `${(138 / 890) * 100}vh`),
    [isPageLoading],
  );

  return (
    <FlexBoxColumn
      alignItems="center"
      justifyContent="center"
      paddingTop={paddingTop}
    >
      {!isPageLoading && (
        <>
          {header}
          <Margin top={60} />
          {description}
          <Margin top={90} />
          {button}
        </>
      )}
      {isPageLoading && <>{loader}</>}
    </FlexBoxColumn>
  );
};
export default DebitCardRequestTemplateLayout;

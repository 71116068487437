import React from 'react';

// Constant
import { INITIAL } from '../../../../config/constants/requestState';

export interface ResetAuthenticatorContextProps {
  resetAuthenticator: (
    formInput: { code: string },
    id: string,
    callback?: () => void,
  ) => Promise<void>;

  state: string;
}

const ResetAuthenticatorContext = React.createContext<
  ResetAuthenticatorContextProps
>({
  resetAuthenticator: () =>
    new Promise((resolve) => {
      resolve();
    }),

  state: INITIAL,
});

export default ResetAuthenticatorContext;

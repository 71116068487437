import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorTranslator } from 'colortranslator';
import { CryptoCurrencyTradeType } from '../../../../types/CryptoCurrency.type';
import color from '../../utils/const/color';
import fontWeight from '../../utils/const/fontWeight';
import { Container } from './style';
import { TextPrimary } from '../../utils/component/Text';

export interface LabelCryptoCurrencyTradeTypeProps {
  tradeType: CryptoCurrencyTradeType;
}

const LabelCryptoCurrencyTradeType: FC<LabelCryptoCurrencyTradeTypeProps> = ({
  tradeType,
}: LabelCryptoCurrencyTradeTypeProps) => {
  const { t } = useTranslation();

  const [textColor, text] =
    tradeType === CryptoCurrencyTradeType.buy
      ? [color.buy.text, t(`atoms.label.buy`)]
      : [color.sell.text, t(`atoms.label.sell`)];

  const rgb = new ColorTranslator(textColor);
  const backgroundColor = `rgba(${rgb.R}, ${rgb.G}, ${rgb.B}, 0.2)`;

  return (
    <Container style={{ backgroundColor }}>
      <TextPrimary
        theme={{
          fontSize: 28,
          color: textColor,
          fontWeight: fontWeight.bold,
        }}
      >
        {text}
      </TextPrimary>
    </Container>
  );
};
export default LabelCryptoCurrencyTradeType;

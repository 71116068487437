import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// Layout Component
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormSelectUserTypeLayoutProps {
  children: React.ReactElement[];
}

const FormSelectUserTypeLayout: FC<FormSelectUserTypeLayoutProps> = ({
  children,
}: FormSelectUserTypeLayoutProps) => {
  const elements = ['individual', 'corporate', 'button'];
  const [individual, corporate, button] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn alignItems="center">
      <FlexBoxRow
        height="auto"
        justifyContent="space-between"
        width={292 + 50 + 292}
      >
        {individual}
        {corporate}
      </FlexBoxRow>
      <Margin top={60} />
      {button}
    </FlexBoxColumn>
  );
};

export default FormSelectUserTypeLayout;

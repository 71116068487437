import Joi from '@hapi/joi';

// Type
import { CurrencyPairType } from '../../../../../types/CurrencyPair.type';

export interface CryptoCurrencyTradeLimitProps {
  maxOrderQty?: number;

  maxValue?: number;

  minOrderQty?: number;

  minValue?: number;

  stepSize?: number;

  createdAt?: number;

  updatedAt?: number;
}

export interface CryptoCurrencyTradeLimitRawDataType {
  id: CurrencyPairType;

  props: CryptoCurrencyTradeLimitProps;
}

export const CryptoCurrencyTradeLimitPropsFormat = {
  id: Object.entries(CurrencyPairType)
    .reduce((joi, [, value]) => joi.valid(value), Joi.string())
    .required(),

  maxOrderQty: Joi.number().empty(''),

  maxValue: Joi.number().empty(''),

  minOrderQty: Joi.number().min(0).empty(''),

  minValue: Joi.number().min(0).empty(''),

  stepSize: Joi.number().min(0).empty(''),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

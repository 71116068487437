/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { INITIAL } from '../../../../config/constants/requestState';

export interface UpdateAuthenticatorContextProps {
  updateAuthenticatorState: string;
  setUpdateAuthenticatorState: (state: string) => void;
  update: (accountKey: string, code: string, callback?: () => void) => void;
}

const UpdateAuthenticatorContext = React.createContext<
  UpdateAuthenticatorContextProps
>({
  updateAuthenticatorState: INITIAL,
  setUpdateAuthenticatorState: (state: string) => undefined,
  update: (accountKey: string, code: string, callback?: () => void) =>
    undefined,
});

export default UpdateAuthenticatorContext;

import React, { useState } from 'react';

export interface FormInputCorporateAddress {
  country: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
  documentType: string;
  proofOfDocumentUrl1: string;
  proofOfDocumentUrl2?: string;
}

export interface FormInputShippingAddress {
  shippingCountry: string;
  shippingAddressLine1: string;
  shippingAddressLine2?: string;
  shippingCity: string;
  shippingState: string;
  shippingPostalCode: string;
  receiverName: string;
  countryCode: string;
  phoneNumber: string;
}

export const useFormInputCorporateAddress = () => {
  const [formInput, setFormInput] = useState<
    FormInputCorporateAddress & FormInputShippingAddress
  >();

  return {
    formInput,
    setFormInput,
  };
};

export const FormInputCorporateAddressContext = React.createContext<{
  formInput?: FormInputCorporateAddress & FormInputShippingAddress;
  setFormInput: (
    formInput?: FormInputCorporateAddress & FormInputShippingAddress,
  ) => void;
}>({
  formInput: undefined,
  setFormInput: (
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    formInput?: FormInputCorporateAddress & FormInputShippingAddress,
  ) => undefined,
});

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Error
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import { TransferDebitCardStatusType } from '../../../../types/Transfer.type';

// Constant
import color from '../../../atoms/utils/const/color';

// Style
import { TextStyle } from './style';

// DomainObject
import { TextSecondary } from '../../../atoms/text2/Text2';
import StatusIcon from '../../../atoms/icon/StatusIcon';
import KycStatusBadgeLayout from './KycStatusBadgeLayout';

export interface StatusBadgeProps {
  status: TransferDebitCardStatusType;
  layout: {
    height: string | number;
    iconWidth: string | number;
    iconHeight: string | number;
  };
}

const StatusBadge: FC<StatusBadgeProps> = ({
  status,
  layout,
}: StatusBadgeProps) => {
  const { t } = useTranslation();

  if (!Object.keys(TransferDebitCardStatusType).includes(status as string))
    return <></>;

  let [backgroundColor, textColor, statusType] = ['', '', ''];

  switch (status) {
    case TransferDebitCardStatusType.unconfirmed:
      [backgroundColor, textColor, statusType] = [
        color.background.varden,
        color.text.lightningYellow,
        'debitCardTransaction',
      ];
      break;
    case TransferDebitCardStatusType.confirmed:
      [backgroundColor, textColor, statusType] = [
        '#EAF8D7',
        '#95CB4B',
        'debitCardTransaction',
      ];
      break;
    case TransferDebitCardStatusType.rejected:
      [backgroundColor, textColor, statusType] = [
        '#FFE4E2',
        '#E44838',
        'debitCardTransaction',
      ];
      break;
    case TransferDebitCardStatusType.canceled:
      [backgroundColor, textColor, statusType] = [
        '#E1E3ED',
        '#A9A9A9',
        'debitCardTransaction',
      ];
      break;
    default:
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_SWITCH,
        {
          value: status,
          place: `StatusBadge`,
        },
      );
  }

  return (
    <KycStatusBadgeLayout
      backgroundColor={backgroundColor}
      height={layout.height}
    >
      <StatusIcon
        key="icon"
        status={status}
        height={layout.iconHeight}
        width={layout.iconWidth}
      />
      <TextSecondary
        key="text"
        theme={{
          ...TextStyle,
          color: textColor,
        }}
      >
        {t(`status.${statusType}.${status}`)}
      </TextSecondary>
    </KycStatusBadgeLayout>
  );
};

export default StatusBadge;

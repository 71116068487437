import styled from 'styled-components';
import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const LabelContainer = styled.div`
  height: ${75 / 16}rem;
  display: flex;
  align-items: center;
`;

export const LabelStyle = {
  fontSize: 21,
  color: color.primary,
  fontWeight: fontWeight.medium,
  lineHeight: 42,
};

import { useState } from 'react';

export const useCherryVisa01ProofOfReceiptFile = () => {
  const [selfie, setSelfie] = useState<File | undefined>();

  return {
    selfie,
    setSelfie,
  };
};

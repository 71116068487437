import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Constant
import { INTEREST_RATE_PRECISION } from 'config/constants/business';

// Style
import { LabeledTextStyle, RemainingTextStyle } from './style';

// Component
import { TextSecondary } from '../../../../atoms/text2/Text2';
import { TimeDepositProgressBar } from '../../../../atoms/progressBar/LinerProgressBar';
import ContractTimeDepositCardTitle from './ContractTimeDepositCardTitle';
import ContractTimeDepositCardTerm from './ContractTimeDepositCardTerm';
import ContractTimeDepositCardAmountInformation from './ContractTimeDepositCardAmountInformation';
import LabeledText from '../../../../molecules/textGroup/LabeledText';
import ContractTimeDepositCardLayout from './ContractTimeDepositCardLayout';

// DomainObject
import ContractTimeDeposit from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';

const ORGANISM_ID = `organisms.card.ContractTimeDepositCard`;

export interface ContractTimeDepositCardProps {
  contractTimeDeposit: ContractTimeDeposit;
}

const ContractTimeDepositCard: FC<ContractTimeDepositCardProps> = ({
  contractTimeDeposit,
}: ContractTimeDepositCardProps) => {
  const { t } = useTranslation();
  const interestRate = contractTimeDeposit?.getProps().interestRate;
  const remainingDaysWithUnit = contractTimeDeposit?.getRemainingDaysWithUnit(
    t,
  );

  return (
    <ContractTimeDepositCardLayout>
      <ContractTimeDepositCardTitle
        key="title"
        contractTimeDeposit={contractTimeDeposit}
      />
      <ContractTimeDepositCardAmountInformation
        key="amount"
        contractTimeDeposit={contractTimeDeposit}
      />
      <ContractTimeDepositCardTerm
        key="term"
        contractTimeDeposit={contractTimeDeposit}
      />
      <TimeDepositProgressBar
        key="progressBar"
        contractTimeDeposit={contractTimeDeposit}
      />
      <LabeledText
        key="interestRate"
        direction="row"
        label={t(`${ORGANISM_ID}.label.interestRate`)}
        marginBetween={10}
        value={t('general.unit.rateYearly', {
          rate: interestRate.toFixed(INTEREST_RATE_PRECISION) ?? '',
        })}
        theme={LabeledTextStyle}
      />
      <TextSecondary key="remainingDays" theme={RemainingTextStyle}>
        {remainingDaysWithUnit ?? ''}
      </TextSecondary>
    </ContractTimeDepositCardLayout>
  );
};

export default ContractTimeDepositCard;

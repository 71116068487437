import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const ContainerStyle = {
  backgroundColor: color.background.white,
  borderRadius: `${10 / 16}rem`,
};

export const TitleTextStyle = {
  fontSize: 27,
  color: color.primary,
  fontWeight: fontWeight.bold,
};

export const AmountTextStyle = {
  fontSize: 27,
  color: color.primary,
  fontWeight: fontWeight.bold,
};

export const CurrencySymbolTextStyle = {
  fontSize: 26,
  color: color.primary,
  fontWeight: fontWeight.bold,
  opacity: 0.5,
};

export const LabelTextStyle = {
  fontSize: 18,
  color: color.primary,
  fontWeight: fontWeight.regular,
};

// React
import { UseStateType } from '../../../types/typeof/UseState';

import ICryptoCurrencyWalletRepository from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/ICryptoCurrencyWalletRepository';
import CryptoCurrencyWallets from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallets/CryptoCurrencyWallets';
import CryptoCurrencyWallet from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet';

abstract class CryptoCurrencyWalletRepository
  implements ICryptoCurrencyWalletRepository {
  public abstract findByUserId: (
    userId: string,
  ) => Promise<CryptoCurrencyWallets>;

  public abstract subscribeByUserId: (
    userId: string,
    setter: UseStateType<CryptoCurrencyWallet | undefined>,
    setState: UseStateType<string>,
  ) => Promise<() => void>;

  public abstract getInterface: () => string;
}
export default CryptoCurrencyWalletRepository;

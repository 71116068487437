import { useCallback, useState } from 'react';

// Error
import ErrorHandler from '../../../../../../utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from '../../../../../../utils/errors/ErrorHandler/IErrorHandler';

// Helper
import { separateFormInput, convertFormInputToProps } from './helper';

// Lib
import { checkInternetConnection } from '../../../../../../utils/helpers/connection';

// Type
import {
  useFormInputProofOfResidency,
  FormInputProofOfResidency,
} from '../../../../../../components/organisms/form/verification/kycRequest/FormProofOfResidency/FormProofOfResidency.type';
import { FormInputShippingAddress } from '../../../../../../components/organisms/form/verification/kycRequest/FormCorporateAddress/FormCorporateAddress.type';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../../config/constants/requestState';

// Repository
import KycRequestFunctions from '../../../../../../repository/verification/kycRequest/KycRequestFunctions';
import CloudStorage from '../../../../../../storage/cloudStorage/CloudStorage';

// DomainObject
import KycRequest from '../../../../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequest';
import UserAuthorized from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import KycRequestSubmitUseCase from '../../../../../../useCases/verification/kycRequest/KycRequestSubmitUseCase';

// ValueObject
import ShippingAddressFactory from '../../../../../../value/personalInformation/address/Address/ShippingAddress/ShippingAddressFactory';

// File
import { useProofOfResidencyFile } from '../../../../../fileHandler/verification/kycRequest/ProofOfResidencyFileProvider/useProofOfResidencyFile';

export const useProofOfResidencySubmitUseCase = (
  kycRequest: KycRequest | undefined,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const formInputProofOfResidencyContext = useFormInputProofOfResidency();

  // File
  const {
    proofOfDocument1,
    setProofOfDocument1,
    proofOfDocument2,
    setProofOfDocument2,
  } = useProofOfResidencyFile();

  /* *
   *
   *  Repository
   *
   * */
  const kycRequestFunctions = new KycRequestFunctions();
  const storageHandler = new CloudStorage();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new KycRequestSubmitUseCase(
    kycRequestFunctions,
    storageHandler,
  );

  /* *
   *
   *  Method
   *
   * */
  const submitProofOfResidency = useCallback(
    async (
      formInput: FormInputProofOfResidency & FormInputShippingAddress,
      callback?: () => void,
    ) => {
      if (!userAuthorized || !kycRequest) return;

      setState(IS_LOADING);

      try {
        // ---check onLine ---- //
        checkInternetConnection();

        // [Conversion] FormInput -> Props
        const {
          proofOfResidencyProps,
          shippingAddressProps,
        } = convertFormInputToProps(separateFormInput(formInput));

        // [DomainObject]
        const kycRequestUpdated = kycRequest.updateProofOfResidency({
          ...proofOfResidencyProps,
          status: kycRequest.getProofOfResidency().getProps().status,
        });
        const shippingAddress = ShippingAddressFactory.create(
          shippingAddressProps,
        );

        await useCase.submitProofOfResidency(
          kycRequestUpdated,
          shippingAddress,
          proofOfDocument1,
          proofOfDocument2,
        );

        setState(SUCCESS);

        if (callback) callback();
      } catch (error) {
        const handler: IErrorHandler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [userAuthorized, kycRequest, proofOfDocument1, proofOfDocument2],
  );

  return {
    state,
    setState,
    submitProofOfResidency,
    formInputProofOfResidencyContext,
    proofOfResidencyFileContext: {
      proofOfDocument1,
      setProofOfDocument1,
      proofOfDocument2,
      setProofOfDocument2,
    },
  };
};

import { useState } from 'react';
import CryptoCurrencySpotTransactions from './CryptoCurrencySpotTransactions';

export const useCryptoCurrencySpotTransactions = (
  defaultValue?: CryptoCurrencySpotTransactions,
) => {
  const [
    cryptoCurrencySpotTransactions,
    setCryptoCurrencySpotTransactions,
  ] = useState<CryptoCurrencySpotTransactions>(
    defaultValue || new CryptoCurrencySpotTransactions(),
  );

  return {
    cryptoCurrencySpotTransactions,
    setCryptoCurrencySpotTransactions,
  };
};

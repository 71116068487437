import React, { FC } from 'react';

// Style
import { OneDigitLayoutStyle, SlashLayoutStyle } from './style';

// Component
import DebitCardNumberOneDigit from '../../../atoms/image/DebitCard/DebitCardNumberOneDigit';
import DebitCardSlashImage from '../../../atoms/image/DebitCard/DebitCardSlashImage';
import DebitCardValidThruImage from '../../../atoms/image/DebitCard/DebitCardValidThruImage';
import DebitCardValidThroughLayout from './DebitCardValidThroughLayout';

// DomainObject
import DebitCardV2 from '../../../../domain/debitCard/debitCardV2/DebitCardV2/DebitCardV2';

export interface DebitCardValidThroughProps {
  debitCardV2: DebitCardV2;
}

const DebitCardValidThrough: FC<DebitCardValidThroughProps> = ({
  debitCardV2,
}: DebitCardValidThroughProps) => {
  const validThrough4Digits = debitCardV2.getValidThrough4Digits();

  return (
    <DebitCardValidThroughLayout>
      <DebitCardValidThruImage
        key="title"
        layout={{
          width: '30%',
          height: '50%',
        }}
      />
      <DebitCardNumberOneDigit
        key="month01"
        layout={OneDigitLayoutStyle}
        digit={validThrough4Digits.month01}
      />
      <DebitCardNumberOneDigit
        key="month02"
        layout={OneDigitLayoutStyle}
        digit={validThrough4Digits.month02}
      />
      <DebitCardSlashImage key="slash" layout={SlashLayoutStyle} />
      <DebitCardNumberOneDigit
        key="year01"
        layout={OneDigitLayoutStyle}
        digit={validThrough4Digits.year01}
      />
      <DebitCardNumberOneDigit
        key="year02"
        layout={OneDigitLayoutStyle}
        digit={validThrough4Digits.year02}
      />
    </DebitCardValidThroughLayout>
  );
};

export default DebitCardValidThrough;

import { useState } from 'react';
import CryptoCurrencyTransactions from './CryptoCurrencyTransactions';

export const useCryptoCurrencyTransactions = (
  defaultValue?: CryptoCurrencyTransactions,
) => {
  const [cryptoCurrencyTransactions, setCryptoCurrencyTransactions] = useState<
    CryptoCurrencyTransactions
  >(defaultValue || new CryptoCurrencyTransactions());

  return {
    cryptoCurrencyTransactions,
    setCryptoCurrencyTransactions,
  };
};

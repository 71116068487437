import Joi from '@hapi/joi';

// Type
import {
  CryptoCurrencyTradeTransactionSeedProps,
  CryptoCurrencyTradeTransactionSeedPropsFormat,
} from '../CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed.type';
import { CryptoCurrencyType } from '../../../../../../types/CryptoCurrency.type';
import { FiatCurrencyType } from '../../../../../../types/FiatCurrency.type';

export interface CryptoCurrencyBuyTransactionSeedProps
  extends CryptoCurrencyTradeTransactionSeedProps {
  payCurrency: FiatCurrencyType;

  receiveCurrency: CryptoCurrencyType;
}

export const CryptoCurrencyBuyTransactionSeedPropsFormat = {
  ...CryptoCurrencyTradeTransactionSeedPropsFormat,

  payCurrency: Object.keys(FiatCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),

  receiveCurrency: Object.keys(CryptoCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),
};

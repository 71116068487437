import React, { FC } from 'react';
import { components } from 'react-select';

// Style
import { Hover, OptionTextStyle } from './style';

// LayoutComponent
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';

// Component
import { TextSecondary } from '../../../../../atoms/text2/Text2';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CountryCodeSingleValueContainer: FC = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <FlexBoxRow alignItems="center">
        <TextSecondary theme={OptionTextStyle}>
          {props.data.value}
        </TextSecondary>
      </FlexBoxRow>
    </components.SingleValue>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CountryCodeOptionContainer: FC = (props: any) => {
  return (
    <Hover>
      <components.Option {...props}>
        <FlexBoxRow alignItems="center" padding="15 15 15 0">
          <TextSecondary theme={OptionTextStyle}>
            {props.data.label}
          </TextSecondary>
        </FlexBoxRow>
      </components.Option>
    </Hover>
  );
};

export { CountryCodeOptionContainer, CountryCodeSingleValueContainer };

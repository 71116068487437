import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';
import Display from '../../../atoms/div/Display';
import { InnerFrame } from './style';

export interface DropzoneAreaLayoutProps {
  children: React.ReactElement[];
  isErrorDisplay: boolean;
}

const DropzoneAreaLayout: FC<DropzoneAreaLayoutProps> = ({
  children,
  isErrorDisplay,
}: DropzoneAreaLayoutProps) => {
  const elements = ['preview', 'button', 'extensionsAllowed', 'errorMessage'];
  const [
    preview,
    button,
    extensionsAllowed,
    errorMessage,
  ] = getElementsFromKeys(children, elements);

  return (
    <InnerFrame>
      <FlexBoxColumn alignItems="center">
        {preview}
        <Margin top={25} />
        {button}
        <Margin top={15} />
        <FlexBoxRow width="auto" alignItems="center">
          <Display isDisplay={!isErrorDisplay}>{extensionsAllowed}</Display>
          <Display isDisplay={isErrorDisplay}>{errorMessage}</Display>
        </FlexBoxRow>
      </FlexBoxColumn>
    </InnerFrame>
  );
};
export default DropzoneAreaLayout;

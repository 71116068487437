import React, { FC } from 'react';
import TextFonted from '../../../atoms/text/TextFonted';
import ComponentConnector from '../../common/ComponentConnector';

export interface LabeledTextOldProps {
  label: string;
  value: string;
  labelPixel: number;
  labelColor: string;
  labelFontWeight: number;
  labelFontFamilyType: 'primary' | 'sub';
  labelWidth?: string;
  labelOpacity?: number;
  valuePixel: number;
  valueColor: string;
  valueFontWeight: number;
  valueFontFamilyType: 'primary' | 'sub';
  valueWidth?: string;
  valueOpacity?: number;
  marginBetween: string;
  direction?: 'row' | 'column';
}

const LabeledTextOld: FC<LabeledTextOldProps> = ({
  label,
  value,
  labelPixel,
  labelColor,
  labelFontWeight,
  labelFontFamilyType,
  labelOpacity = 1,
  labelWidth,
  valuePixel,
  valueColor,
  valueFontWeight,
  valueFontFamilyType,
  valueOpacity = 1,
  marginBetween,
  direction = 'row',
}: LabeledTextOldProps) => {
  return (
    <ComponentConnector direction={direction} marginBetween={marginBetween}>
      <div key="left" style={{ width: labelWidth }}>
        <TextFonted
          pixel={labelPixel}
          color={labelColor}
          fontWeight={labelFontWeight}
          fontFamilyType={labelFontFamilyType}
          opacity={labelOpacity}
        >
          {label}
        </TextFonted>
      </div>
      <div key="right">
        <TextFonted
          pixel={valuePixel}
          color={valueColor}
          fontWeight={valueFontWeight}
          fontFamilyType={valueFontFamilyType}
          opacity={valueOpacity}
        >
          {value}
        </TextFonted>
      </div>
    </ComponentConnector>
  );
};

export default LabeledTextOld;

// Error
import { ErrorIdType } from '../../../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import { DebitCardSpotTransactionV2Props } from './DebitCardSpotTransactionV2.type';
import {
  DebitCardSpotTransactionType,
  DebitCardDepositType,
  DebitCardWithdrawalType,
} from '../../../../../../types/DebitCardTransaction.type';

// ValueObject
import DebitCardTransactionId from '../../../../../idManager/transactionId/DebitCardTransactionId/DebitCardTransactionId';
import DebitCardDepositV2Factory from '../../DebitCardDepositV2/DebitCardDepositV2/DebitCardDepositV2Factory';
import DebitCardWithdrawalV2Factory from '../../DebitCardWithdrawalV2/DebitCardWithdrawalV2/DebitCardWithdrawalV2Factory';

class DebitCardSpotTransactionV2Factory {
  static createByTransactionType = (
    id: DebitCardTransactionId,
    props: DebitCardSpotTransactionV2Props,
    type: DebitCardSpotTransactionType,
  ) => {
    if (Object.keys(DebitCardDepositType).includes(type))
      return DebitCardDepositV2Factory.create(id, {
        ...props,
        type: type as DebitCardDepositType,
      });

    if (Object.keys(DebitCardWithdrawalType).includes(type))
      return DebitCardWithdrawalV2Factory.create(id, {
        ...props,
        type: type as DebitCardWithdrawalType,
      });

    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
      {
        type: 'DebitCardDepositType or DebitCardWithdrawalType',
        place: 'DebitCardSpotTransactionV2Factory.create',
      },
    );
  };
}

export default DebitCardSpotTransactionV2Factory;

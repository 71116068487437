import React, { FC } from 'react';
import { MenuType } from '../../../../config/menu/main/Menu.type';
import { CryptoCurrencyMenuType } from '../../../../config/menu/sub/CryptoCurrencyMenu.type';
import MenuItemWithSub from '../MenuItemWithSub';

const MAIN_MENU_TYPE = MenuType.cryptocurrencies;

const CryptoCurrencyMenu: FC = () => {
  const CryptoCurrencyMenuItems = Object.keys(CryptoCurrencyMenuType);

  return (
    <MenuItemWithSub
      key={MAIN_MENU_TYPE}
      type={MAIN_MENU_TYPE}
      menuItems={CryptoCurrencyMenuItems}
    />
  );
};

export default CryptoCurrencyMenu;

import React, { FC, useContext } from 'react';
import ContractTimeDepositAddPageStateContext from '../../../enhancers/pageState/contract/ContractTimeDepositAddPageState/ContractTimeDepositAddPageStateContext';

// Error
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';

// Component
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import ContractTimeDepositAddTemplate from '../../../components/templates/contractTimeDeposit/ContractTimeDepositAddTemplate';
import ContractTimeDepositAddConfirmTemplate from '../../../components/templates/contractTimeDeposit/ContractTimeDepositAddConfirmTemplate';
import ContractTimeDepositAddCompleteTemplate from '../../../components/templates/contractTimeDeposit/ContractTimeDepositAddCompleteTemplate';

const ContractTimeDepositAddPage: FC = () => {
  const { pageState } = useContext(ContractTimeDepositAddPageStateContext);

  const TemplateComponent: FC = () => {
    switch (pageState) {
      case 'initial':
        return <ContractTimeDepositAddTemplate />;
      case 'confirm':
        return <ContractTimeDepositAddConfirmTemplate />;
      case 'complete':
        return <ContractTimeDepositAddCompleteTemplate />;
      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_SWITCH,
          {
            value: pageState,
            place: `ContractTimeDepositAddPage`,
          },
        );
    }
  };

  return (
    <DashboardTemplate>
      <TemplateComponent />
    </DashboardTemplate>
  );
};

export default ContractTimeDepositAddPage;

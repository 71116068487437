/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export interface CherryVisa01ProofOfIdentityFileContextProps {
  document1: File | undefined;
  setDocument1: (file: File | undefined) => void;
  document2: File | undefined;
  setDocument2: (file: File | undefined) => void;
  passport: File | undefined;
  setPassport: (file: File | undefined) => void;
  selfie: File | undefined;
  setSelfie: (file: File | undefined) => void;
  signature: File | undefined;
  setSignature: (file: File | undefined) => void;
}

const CherryVisa01ProofOfIdentityFileContext = React.createContext<
  CherryVisa01ProofOfIdentityFileContextProps
>({
  document1: undefined,
  setDocument1: (file: File | undefined) => undefined,
  document2: undefined,
  setDocument2: (file: File | undefined) => undefined,
  passport: undefined,
  setPassport: (file: File | undefined) => undefined,
  selfie: undefined,
  setSelfie: (file: File | undefined) => undefined,
  signature: undefined,
  setSignature: (file: File | undefined) => undefined,
});

export default CherryVisa01ProofOfIdentityFileContext;

// Type
import { UseStateType } from '../../../types/typeof/UseState';

// IService
import ITimeDepositQueryService from '../../../useCases/product/timeDeposit/interface/ITimeDepositQueryService';

// IRepository
import ITimeDepositRepository from '../../../domain/product/timeDeposit/ITimeDepositRepository';

// DomainObject
import TimeDeposits from '../../../domain/product/timeDeposit/TimeDeposits/TimeDeposits';
import UserVerified from '../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';

class TimeDepositQueryService implements ITimeDepositQueryService {
  private timeDepositRepository: ITimeDepositRepository;

  constructor(timeDepositRepository: ITimeDepositRepository) {
    this.timeDepositRepository = timeDepositRepository;
  }

  public setAll = async (
    setter: UseStateType<TimeDeposits>,
    setState: UseStateType<string>,
  ) => {
    await this.timeDepositRepository.subscribeAll(setter, setState);
  };

  public findProductsForUser = async (user: UserVerified) => {
    const timeDeposits = await this.timeDepositRepository.findAll();

    const productsForUser = timeDeposits.filterByUser(user);

    const sortedProductsForUser = productsForUser.sortForSelector();

    return sortedProductsForUser;
  };
}

export default TimeDepositQueryService;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import CryptoCurrencyTemplateContainer from '../../common/CryptoCurrencyTemplateContainer';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface CryptoCurrencyTradeTemplateLayoutProps {
  children: React.ReactElement[];
}

const CryptoCurrencyTradeTemplateLayout: FC<CryptoCurrencyTradeTemplateLayoutProps> = ({
  children,
}: CryptoCurrencyTradeTemplateLayoutProps) => {
  const elements = ['header', 'form'];
  const [header, form] = getElementsFromKeys(children, elements);

  return (
    <CryptoCurrencyTemplateContainer>
      {header}
      <Margin top={50}>{form}</Margin>
    </CryptoCurrencyTemplateContainer>
  );
};
export default CryptoCurrencyTradeTemplateLayout;

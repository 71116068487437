// Type
import { UseStateType } from '../../../types/typeof/UseState';

// IUseCase
import IContractTimeDepositRenewalReservationViewUseCase, {
  SettersContractTimeDepositRenewalReservationView,
} from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationViewProvider/IContractTimeDepositRenewalReservationViewUseCase';

// IService
import IContractTimeDepositQueryService from './interface/IContractTimeDepositQueryService';
import ISavingsAccountQueryService from '../../bankAccount/savingsAccount/ISavingsAccountQueryService';
import ITimeDepositQueryService from '../../product/timeDeposit/interface/ITimeDepositQueryService';

// Service
import ContractTimeDepositQueryService from '../../../service/contract/contractTimeDeposit/ContractTimeDepositQueryService';
import TimeDepositQueryService from '../../../service/product/timeDeposit/TimeDepositQueryService';
import SavingsAccountQueryService from '../../../service/bankAccount/savingsAccount/SavingsAccountQueryService';

// IRepository
import { IContractTimeDepositQueryRepository } from '../../../domain/contract/contractTimeDeposit/IContractTimeDepositRepository';
import ISavingsAccountRepository from '../../../domain/bankAccount/savingsAccount/ISavingsAccountRepository';
import ITimeDepositRepository from '../../../domain/product/timeDeposit/ITimeDepositRepository';

// DomainObject
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import UserVerifiedFactory from '../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedFactory';

class ContractTimeDepositRenewalReservationViewUseCase
  implements IContractTimeDepositRenewalReservationViewUseCase {
  private contractTimeDepositQueryService: IContractTimeDepositQueryService;

  private timeDepositQueryService: ITimeDepositQueryService;

  private savingsAccountQueryService: ISavingsAccountQueryService;

  constructor(
    contractTimeDepositQueryRepository: IContractTimeDepositQueryRepository,
    timeDepositRepository: ITimeDepositRepository,
    savingsAccountRepository: ISavingsAccountRepository,
  ) {
    this.contractTimeDepositQueryService = new ContractTimeDepositQueryService(
      contractTimeDepositQueryRepository,
    );
    this.timeDepositQueryService = new TimeDepositQueryService(
      timeDepositRepository,
    );
    this.savingsAccountQueryService = new SavingsAccountQueryService(
      savingsAccountRepository,
    );
  }

  public open = async (
    id: string,
    userAuthorized: UserAuthorized,
    setters: SettersContractTimeDepositRenewalReservationView,
    setState: UseStateType<string>,
  ) => {
    // UserAuthorized -> UserVerified
    const userVerified = UserVerifiedFactory.createByUserAuthorized(
      userAuthorized,
    );

    const [contractTimeDeposit, , savingsAccounts] = await Promise.all([
      this.contractTimeDepositQueryService.findById(id),
      this.timeDepositQueryService.setAll(setters.timeDeposits, setState),
      this.savingsAccountQueryService.findAllByUserId(userVerified.getId()),
    ]);

    return { userVerified, contractTimeDeposit, savingsAccounts };
  };
}

export default ContractTimeDepositRenewalReservationViewUseCase;

import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import PersonalInformationTemplate from '../../../components/templates/kycRequest/PersonalInformationTemplate';

const PersonalInformationPage: FC = () => {
  return (
    <DashboardTemplate>
      <PersonalInformationTemplate />
    </DashboardTemplate>
  );
};

export default PersonalInformationPage;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle } from './style';

// Component
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import { TextSecondary } from '../../../atoms/text2/Text2';
import LogoImage from '../../../atoms/image/LogoImage';
import MaintenanceSchedule from '../../../organisms/notification/MaintenanceSchedule';
import MaintenanceTemplateLayout from './MaintenanceTemplateLayout';

const TEMPLATE_ID = 'templates.maintenance.MaintenanceTemplate';

const MaintenanceTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <MaintenanceTemplateLayout>
      <PageTitle key="title">{t(`${TEMPLATE_ID}.title`)}</PageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${TEMPLATE_ID}.description`)}
      </TextSecondary>
      <MaintenanceSchedule key="schedule" />
      <LogoImage
        key="logo"
        width={175.48}
        height={25.6}
        logoType="logoPrimary"
      />
    </MaintenanceTemplateLayout>
  );
};

export default MaintenanceTemplate;

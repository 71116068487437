import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Style
import {
  Container,
  RightContainer,
  Content,
  Main,
  MenuBackBoard,
} from './style';

export interface DashboardTemplateLayoutProps {
  children: React.ReactElement[];
  content: React.ReactNode;
  containerHeight: number;
  divRef?: React.RefObject<HTMLDivElement>;
}

const DashboardTemplateLayout: FC<DashboardTemplateLayoutProps> = ({
  children,
  content,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  containerHeight,
  divRef,
}: DashboardTemplateLayoutProps) => {
  const elements = ['menu', 'header', 'footer'];
  const [menu, header, footer] = getElementsFromKeys(children, elements);

  return (
    <>
      {menu}
      <Container
        ref={divRef}
        style={{
          height: '100%',
          // containerHeight
        }}
      >
        <MenuBackBoard />
        <RightContainer>
          {header}
          <Content>
            <Main>{content}</Main>
            {footer}
          </Content>
        </RightContainer>
      </Container>
    </>
  );
};
export default DashboardTemplateLayout;

import React from 'react';
import NotificationLog from './NotificationLog';

export interface NotificationLogContextProps {
  notificationLog?: NotificationLog;
}

const NotificationLogContext = React.createContext<NotificationLogContextProps>(
  {
    notificationLog: undefined,
  },
);

export default NotificationLogContext;

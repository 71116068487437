import { required } from '../validator';

// Type
import { TypeOfT } from '../../../../types/typeof/Translation.type';

const corporateInformationValidation = (t: TypeOfT) => ({
  corporateName: {
    required: required('Corporate Name', t),
  },
});

export default corporateInformationValidation;

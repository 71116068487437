export enum RankType {
  D01 = 'D-01',
  D02 = 'D-02',
  A01 = 'A-01',
  A02 = 'A-02',
  C01 = 'C-01',
  C02 = 'C-02',
}

export enum RankInvited {
  A01 = 'A-01',
  C01 = 'C-01',
  A02 = 'A-02',
  C02 = 'C-02',
}

export enum InviterType {
  affiliate = 'affiliate',
  general = 'general',
}
export enum GradeType {
  priority = 'priority',
  general = 'general',
}

export type RankDType = RankType.D01 | RankType.D02;

export type RankAType = RankType.A01 | RankType.A02;

export type RankCType = RankType.C01 | RankType.C02;

export type InviterRankType = InviterRankDType | InviterRankAType;

export type InviterRankDType = RankType.D01 | RankType.D02;

export type InviterRankAType = RankType.A01 | RankType.A02;

export type InviterRankCType = RankType.C01 | RankType.C02;

export enum TopLevelInviterRankType {
  D01 = RankType.D01,
}

export const TopLevelInviterRankTypes = [RankType.D01];

export const rankKeyMapper = {
  'A-01': {
    inviterType: InviterType.affiliate,
    gradeType: GradeType.priority,
  },
  'A-02': {
    inviterType: InviterType.affiliate,
    gradeType: GradeType.general,
  },
  'C-01': {
    inviterType: InviterType.general,
    gradeType: GradeType.priority,
  },
  'C-02': {
    inviterType: InviterType.general,
    gradeType: GradeType.general,
  },
};

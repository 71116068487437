import React, { FC } from 'react';
import { Container } from './style';
import TextFonted from '../TextFonted';

export interface EllipseWrappedTextProps {
  text: string;
  color: string;
  textSize: number;
  textFontWeight: number;
  textFontFamilyType: 'primary' | 'sub';
  textDecoration?: string;
  wrapSize: string;
  borderRadius: string;
  backgroundColor: string;
  padding: string;
}

const EllipseWrappedText: FC<EllipseWrappedTextProps> = ({
  text,
  padding,
  color,
  wrapSize,
  borderRadius,
  backgroundColor,
  textSize,
  textFontWeight,
  textFontFamilyType = 'sub',
  textDecoration = 'none',
}: EllipseWrappedTextProps) => (
  <Container
    style={{
      boxSizing: 'border-box',
      height: wrapSize,
      backgroundColor,
      borderRadius,
      padding,
    }}
  >
    <TextFonted
      pixel={textSize}
      color={color}
      fontWeight={textFontWeight}
      fontFamilyType={textFontFamilyType}
      textDecoration={textDecoration}
    >
      {text}
    </TextFonted>
  </Container>
);
export default EllipseWrappedText;

import { useState } from 'react';
import ContractsTimeDeposit from './ContractsTimeDeposit';

export const useContractsTimeDeposit = (
  defaultValue?: ContractsTimeDeposit,
) => {
  const [contractsTimeDeposit, setContractsTimeDeposit] = useState<
    ContractsTimeDeposit
  >(defaultValue || new ContractsTimeDeposit());

  return {
    contractsTimeDeposit,
    setContractsTimeDeposit,
  };
};

// React
import React from 'react';
import ReactModal from 'react-modal';

// Constant
import { ROUTE_DEBIT_CARD } from '../../../../config/constants/routing';

// Style
import { contentStyle, overlayStyle } from './style';

// Components
import DebitCardMaintenanceNotification from '../../notification/DebitCardMaintenanceNotification';

interface DebitCardMaintenanceModalModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any;
}

const DebitCardMaintenanceModalModal = ({
  history,
}: DebitCardMaintenanceModalModalProps) => {
  return (
    <ReactModal
      isOpen={true}
      style={{ content: contentStyle, overlay: overlayStyle }}
      ariaHideApp={false}
    >
      <DebitCardMaintenanceNotification
        closeAction={() => {
          history.push(ROUTE_DEBIT_CARD);
        }}
      />
    </ReactModal>
  );
};

export default DebitCardMaintenanceModalModal;

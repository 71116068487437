import { useState } from 'react';

// Domain Object
import DebitCardRequestCherryVisa01 from './DebitCardRequestCherryVisa01';

export const useDebitCardRequestCherryVisa01 = (
  initial?: DebitCardRequestCherryVisa01,
) => {
  const [
    debitCardRequestCherryVisa01,
    setDebitCardRequestCherryVisa01,
  ] = useState<DebitCardRequestCherryVisa01 | undefined>(initial);

  return {
    debitCardRequestCherryVisa01,
    setDebitCardRequestCherryVisa01,
  };
};

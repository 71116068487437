import React, { FC } from 'react';
import DebitCardRequestCherryVisa01StatusTemplate from '../../../components/templates/debitCardRequest/DebitCardRequestCherryVisa01StatusTemplate';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';

const DebitCardRequestCherryVisa01StatusPage: FC = () => {
  return (
    <DashboardTemplate>
      <DebitCardRequestCherryVisa01StatusTemplate />
    </DashboardTemplate>
  );
};

export default DebitCardRequestCherryVisa01StatusPage;

import React, { FC, useContext } from 'react';

// Context
import AssetsCryptoCurrencyContext from '../../../../domain/asset/assetCryptoCurrency/AssetsCryptoCurrency/AssetsCryptoCurrencyContext';
import BankTransactionOptionContext from '../../../../components/molecules/selector/tabBar/UnderBarTabBar/BankTransactionTabBar/BankTransactionOptionContext';
import CryptoCurrencyWalletContext from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWalletContext';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import RequestStateContext from '../../../../value/view/RequestState/RequestStateContext';
import CryptoCurrencyOptionContext from '../../../../components/molecules/selectBox/option/option/CryptoCurrencyOption/CryptoCurrencyOptionContext';
import CryptoCurrencyDepositContext from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/CryptoCurrencyDeposit/CryptoCurrencyDepositContext';

// CustomHook
import { useCryptoCurrencyDepositWithdrawalViewUseCase } from './useCryptoCurrencyDepositWithdrawalViewUseCase';

// Components
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type CryptoCurrencyDepositWithdrawalViewProviderProps = {
  children: React.ReactNode;
};

const CryptoCurrencyDepositWithdrawalViewProvider: FC<CryptoCurrencyDepositWithdrawalViewProviderProps> = ({
  children,
}: CryptoCurrencyDepositWithdrawalViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    assetsCryptoCurrency,
    transactionOption,
    setTransactionOption,
    cryptoCurrencyWallet,
    cryptoCurrencyOption,
    cryptoCurrencyDeposit,
    setCryptoCurrencyDeposit,
    setCryptoCurrencyOption,
  } = useCryptoCurrencyDepositWithdrawalViewUseCase(userAuthorized);

  return (
    <RequestStateContext.Provider value={{ requestState: state }}>
      <AssetsCryptoCurrencyContext.Provider value={{ assetsCryptoCurrency }}>
        <CryptoCurrencyDepositContext.Provider
          value={{ cryptoCurrencyDeposit, setCryptoCurrencyDeposit }}
        >
          <CryptoCurrencyWalletContext.Provider
            value={{ cryptoCurrencyWallet }}
          >
            <BankTransactionOptionContext.Provider
              value={{ transactionOption, setTransactionOption }}
            >
              <CryptoCurrencyOptionContext.Provider
                value={{ cryptoCurrencyOption, setCryptoCurrencyOption }}
              >
                <SystemErrorHandler state={state} setState={setState}>
                  {children}
                </SystemErrorHandler>
              </CryptoCurrencyOptionContext.Provider>
            </BankTransactionOptionContext.Provider>
          </CryptoCurrencyWalletContext.Provider>
        </CryptoCurrencyDepositContext.Provider>
      </AssetsCryptoCurrencyContext.Provider>
    </RequestStateContext.Provider>
  );
};
export default CryptoCurrencyDepositWithdrawalViewProvider;

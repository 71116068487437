import React, { FC, useContext } from 'react';

import { useContractTimeDepositRenewalReservationCreateUseCase } from './useContractTimeDepositCreateRenewalReservationUseCase';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

// Context
import ContractTimeDepositRenewalReservationCreateContext from './ContractTimeDepositRenewalReservationCreateContext';
import { FormInputContractTimeDepositRenewalReservationContext } from '../../../../../components/organisms/form/contract/contractTimeDeposit/FormContractTimeDepositRenewalReservation/FormContractTimeDepositRenewalReservation.type';
import TimeDepositContext from '../../../../../domain/product/timeDeposit/TimeDeposit/TimeDepositContext';
import UserVerifiedContext from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedContext';
import ContractTimeDepositContext from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDepositContext';

export type ContractTimeDepositRenewalReservationCreateProviderProps = {
  children: React.ReactNode;
};

const ContractTimeDepositRenewalReservationCreateProvider: FC<ContractTimeDepositRenewalReservationCreateProviderProps> = ({
  children,
}: ContractTimeDepositRenewalReservationCreateProviderProps) => {
  const { user } = useContext(UserVerifiedContext);
  const { contractTimeDeposit, setContractTimeDeposit } = useContext(
    ContractTimeDepositContext,
  );

  const {
    state,
    setState,
    timeDeposit,
    formInputContractTimeDepositRenewalReservationContext,
    createContractTimeDepositRenewalReservation,
    executeCreateContractTimeDepositRenewalReservation,
  } = useContractTimeDepositRenewalReservationCreateUseCase(
    user,
    contractTimeDeposit,
    setContractTimeDeposit,
  );

  return (
    <FormInputContractTimeDepositRenewalReservationContext.Provider
      value={formInputContractTimeDepositRenewalReservationContext}
    >
      <ContractTimeDepositRenewalReservationCreateContext.Provider
        value={{
          requestState: state,
          createContractTimeDepositRenewalReservation,
          executeCreateContractTimeDepositRenewalReservation,
        }}
      >
        <TimeDepositContext.Provider value={{ timeDeposit }}>
          <SystemErrorHandler state={state} setState={setState}>
            {children}
          </SystemErrorHandler>
        </TimeDepositContext.Provider>
      </ContractTimeDepositRenewalReservationCreateContext.Provider>
    </FormInputContractTimeDepositRenewalReservationContext.Provider>
  );
};

export default ContractTimeDepositRenewalReservationCreateProvider;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';

// Layout Component
import { FlexBoxColumn } from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface DebitCardRequestExampleImageDescriptionCardLayoutProps {
  children: React.ReactElement[];
}

const DebitCardRequestExampleImageDescriptionCardLayout: FC<DebitCardRequestExampleImageDescriptionCardLayoutProps> = ({
  children,
}: DebitCardRequestExampleImageDescriptionCardLayoutProps) => {
  const elements = ['image', 'description'];
  const [image, description] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn>
      {image}
      <Margin top={20} />
      {description}
    </FlexBoxColumn>
  );
};

export default DebitCardRequestExampleImageDescriptionCardLayout;

import React, { FC } from 'react';
import ManualPage from './ManualPage';
import ManualViewProvider from '../../../enhancers/useCase/manual/ManualViewProvider';

const EnhancedManualPage: FC = () => {
  return (
    <ManualViewProvider>
      <ManualPage />
    </ManualViewProvider>
  );
};

export default EnhancedManualPage;

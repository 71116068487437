// Type
import { MaintenanceProps } from './Maintenance.type';

// ValueObject
import TimeZonedTimeFactory from '../../../../../value/datetime/TimeZonedTimeFactory';

class Maintenance {
  private props: MaintenanceProps;

  constructor(props: MaintenanceProps) {
    this.props = props;
  }

  public getProps() {
    return this.props;
  }

  public getSystemMaintenanceStartTimeZoned = () => {
    const start = TimeZonedTimeFactory.create(this.props.system.schedule.start);
    return start.getSystemMaintenanceDatetimeFormat();
  };

  public getSystemMaintenanceEndTimeZoned = (estimateString: string) => {
    if (!this.props.system.schedule.end) return undefined;

    const end = TimeZonedTimeFactory.create(this.props.system.schedule.end);

    const estimate = this.props.system.schedule.isEstimate
      ? estimateString
      : '';

    return `${end.getSystemMaintenanceDatetimeFormat()} ${estimate}`;
  };

  public isCurrentlySystemMaintenance = () => {
    const startTimestamp = this.props.system.schedule.start
      ? TimeZonedTimeFactory.create(
          this.props.system.schedule.start,
        ).getTimestamp()
      : 0;

    const endTimestamp = this.props.system.schedule.end
      ? TimeZonedTimeFactory.create(
          this.props.system.schedule.end,
        ).getTimestamp()
      : Number.MAX_SAFE_INTEGER;

    const now = Date.now();

    return (
      this.props.system.isMaintenance &&
      now >= startTimestamp &&
      now <= endTimestamp
    );
  };

  public isCurrentlyDebitCardTopUpMaintenance = () => {
    const startTimestamp = this.props.debitCardTopUp.schedule.start
      ? TimeZonedTimeFactory.create(
          this.props.debitCardTopUp.schedule.start,
        ).getTimestamp()
      : 0;

    const endTimestamp = this.props.debitCardTopUp.schedule.end
      ? TimeZonedTimeFactory.create(
          this.props.debitCardTopUp.schedule.end,
        ).getTimestamp()
      : Number.MAX_SAFE_INTEGER;

    const now = Date.now();

    return (
      this.props.debitCardTopUp.isMaintenance &&
      now >= startTimestamp &&
      now <= endTimestamp
    );
  };
}

export default Maintenance;

// React
import React, { FC, useContext } from 'react';
import SavingsAccountContext from '../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccountContext';

// Components
import HeaderSavingsAccountHistory from '../../../organisms/header/HeaderContentTitle/savingsAccount/HeaderSavingsAccountHistory';
import SavingsAccountCard from '../../../organisms/card/bankAccount/savingsAccount/SavingsAccountCard';
import TableBankTransactionHistory from '../../../organisms/table/history/TableBankTransactionHistory';
import SavingsAccountHistoryTemplateLayout from './SavingsAccountHistoryTemplateLayout';

const SavingsAccountHistoryTemplate: FC = () => {
  const { savingsAccount } = useContext(SavingsAccountContext);

  return (
    <SavingsAccountHistoryTemplateLayout>
      <HeaderSavingsAccountHistory key="header" />
      <div key="card">
        {savingsAccount && (
          <SavingsAccountCard
            key="card"
            isHistory
            savingsAccount={savingsAccount}
            isCSVDownloadButtonVisible
          />
        )}
      </div>
      <TableBankTransactionHistory key="table" />
    </SavingsAccountHistoryTemplateLayout>
  );
};
export default SavingsAccountHistoryTemplate;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationContainer, DescriptionStyle, TitleStyle } from './style';
import BreadcrumbList from '../../../organisms/list/BreadcrumbList';
import NotificationSuccess from '../../../organisms/notification/NotificationSuccess';
import { RESET_PASSWORD_COMPLETE } from '../../../../config/constants/pageId.json';
import {
  ROUTE_HOME,
  ROUTE_SIGN_IN,
} from '../../../../config/constants/routing';
import fontColor from '../../../../fonts/color.json';

const ResetPasswordCompleteTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <NotificationContainer>
        <NotificationSuccess
          title={t(`${RESET_PASSWORD_COMPLETE}.title`)}
          titleStyle={TitleStyle}
          descriptionStyle={DescriptionStyle}
          description={t(`${RESET_PASSWORD_COMPLETE}.description`)}
        />
      </NotificationContainer>
      <div style={{ marginTop: `${60 / 16}rem` }}>
        <BreadcrumbList
          color={fontColor.textDark}
          linkedTexts={[
            {
              text: t(`${RESET_PASSWORD_COMPLETE}.navigation.signIn`),
              urlList: [
                {
                  replaceText: t(`${RESET_PASSWORD_COMPLETE}.link.signIn`),
                  url: ROUTE_SIGN_IN,
                },
              ],
            },
            {
              text: t(`${RESET_PASSWORD_COMPLETE}.link.top`),
              urlList: [
                {
                  replaceText: t(`${RESET_PASSWORD_COMPLETE}.link.top`),
                  url: ROUTE_HOME,
                },
              ],
            },
          ]}
        />
      </div>
    </>
  );
};

export default ResetPasswordCompleteTemplate;

import React, { FC } from 'react';
import ConsentFormData from '../../../enhancers/verification/consentForm/ConsentFormData';
import ConsentFormSettingPage from './ConsentFormSettingPage';

const EnhancedConsentFormSettingPage: FC = () => {
  return (
    <ConsentFormData>
      <ConsentFormSettingPage />
    </ConsentFormData>
  );
};

export default EnhancedConsentFormSettingPage;

import React, { FC } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }),
)(LinearProgress);

export interface LinerProgressBarProps {
  progress: number;
}

const LinearProgressBar: FC<LinerProgressBarProps> = ({
  progress,
}: LinerProgressBarProps) => {
  return <BorderLinearProgress variant="determinate" value={progress} />;
};
export default LinearProgressBar;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle } from './style';

// Component
import { TextSecondary } from '../../../../../atoms/text2/Text2';
import ExampleImage from '../../../../../atoms/image/ExampleImage';
import DebitCardRequestFormContainer from '../../../common/DebitCardRequestFormContainer';
import FormSignatureExplanationLayout from './FormSignatureExplanationLayout';

const FORM_ID = `organisms.form.debitCards.FormProofOfIdentity.signatureExplanation`;

const FormSignatureExplanation: FC = () => {
  const { t } = useTranslation();

  return (
    <DebitCardRequestFormContainer order={7} formId={FORM_ID}>
      <FormSignatureExplanationLayout>
        <TextSecondary key="description" theme={DescriptionStyle}>
          {t(`${FORM_ID}.description`)}
        </TextSecondary>
        <ExampleImage key="exampleImage" type="plainSignatureExample" />
      </FormSignatureExplanationLayout>
    </DebitCardRequestFormContainer>
  );
};

export default FormSignatureExplanation;

import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import CherryVisa01ProofOfIdentityTemplate from '../../../components/templates/debitCardRequest/CherryVisa01ProofOfIdentityTemplate';

const CherryVisa01ProofOfIdentityPage: FC = () => {
  return (
    <DashboardTemplate>
      <CherryVisa01ProofOfIdentityTemplate />
    </DashboardTemplate>
  );
};

export default CherryVisa01ProofOfIdentityPage;

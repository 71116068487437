import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isValid, getDefaultValues } from './helper';
import CorporateAddressSubmitContext from '../../../../../../enhancers/useCase/verification/kycRequest/KycRequestSubmitProvider/CorporateAddressSubmitProvider/CorporateAddressSubmitContext';
import UserAuthorizedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Type
import {
  FormInputCorporateAddress,
  FormInputShippingAddress,
  FormInputCorporateAddressContext,
} from './FormCorporateAddress.type';

// Constant
import { IS_LOADING } from '../../../../../../config/constants/requestState';
import { ROUTE_KYC_STATUS } from '../../../../../../config/constants/routing';

// Component
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../../atoms/button/Button2';
import LabelSendIcon from '../../../../../atoms/button/ButtonLabel/LabelSendIcon';
import FormCorporateAddressInput from './FormCorporateAddressInput';
import SelectDocumentType from './SelectDocumentType';
import FormCorporateAddressProofUpload from './FormCorporateAddressProofUpload';
import FormShippingAddress from './FormShippingAddress';
import FormCorporateAddressLayout from './FormCorporateAddressLayout';

// ValueObject
import CorporateAddress from '../../../../../../value/verification/kycRequest/KycRequestContent/CorporateAddress/CorporateAddress';

export interface FormCorporateAddressProps {
  corporateAddress?: CorporateAddress;
}

const FormCorporateAddress: FC<FormCorporateAddressProps> = ({
  corporateAddress,
}: FormCorporateAddressProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { formInput, setFormInput } = useContext(
    FormInputCorporateAddressContext,
  );

  // DomainObject
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // Method
  const { state, submitCorporateAddress } = useContext(
    CorporateAddressSubmitContext,
  );

  /* *
   *
   *  Form
   *
   * */
  const methods = useForm<FormInputCorporateAddress & FormInputShippingAddress>(
    {
      mode: 'onChange',
    },
  );
  const { errors, handleSubmit, reset, setValue, watch } = methods;

  /* *
   *
   *  DefaultValues
   *
   * */

  // Set DefaultValue
  useEffect(() => {
    if (formInput) reset(formInput);
  }, [formInput, reset]);

  // Set Default Value from CorporateInformation
  useEffect(() => {
    if (!formInput && corporateAddress && userAuthorized) {
      const defaultValues = getDefaultValues(corporateAddress, userAuthorized);

      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [formInput, corporateAddress, setValue, userAuthorized]);

  /* *
   *
   *  Event
   *
   * */

  const onSubmit = useCallback(
    async (input: FormInputCorporateAddress & FormInputShippingAddress) => {
      setFormInput(input);

      submitCorporateAddress(input, () => {
        history.push(ROUTE_KYC_STATUS);
      });
      // eslint-disable-next-line
  }, [submitCorporateAddress]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <FormCorporateAddressLayout>
          <FormCorporateAddressInput key="address" />
          <SelectDocumentType key="documentType" />
          <FormCorporateAddressProofUpload
            key="documents"
            corporateAddress={corporateAddress}
          />
          <FormShippingAddress key="shippingAddress" />
          <SubmitButton
            key="button"
            disabled={!isValid(errors, watch)}
            isLoading={state === IS_LOADING}
            height={75}
            width={'100%'}
          >
            <LabelSendIcon
              iconSize={26}
              iconStyle={{ color: 'white' }}
              label={t(`atoms.button.submit`)}
              marginBetween={8}
              textStyle={DefaultButtonTextStyle}
            />
          </SubmitButton>
        </FormCorporateAddressLayout>
      </FormProvider>
    </form>
  );
};

export default FormCorporateAddress;

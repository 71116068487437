/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export interface CountdownContextProps {
  count: number;
  setCount: (second: number) => void;
}

const CountdownContext = React.createContext<CountdownContextProps>({
  count: 0,
  setCount: (second: number) => undefined,
});

export default CountdownContext;

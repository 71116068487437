import React from 'react';
import ContractsTimeDepositHistories from './ContractTimeDepositHistories';

export interface ContractTimeDepositHistoriesContextProps {
  contractTimeDepositHistories: ContractsTimeDepositHistories;
}

const ContractTimeDepositHistoriesContext = React.createContext<
  ContractTimeDepositHistoriesContextProps
>({
  contractTimeDepositHistories: new ContractsTimeDepositHistories(),
});

export default ContractTimeDepositHistoriesContext;

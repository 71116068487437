import React, { FC } from 'react';
import { TextThemeProps } from '../../../atoms/text2/Text2';
import color from '../../../atoms/utils/const/color';
import ArrowIcon from '../../../atoms/icon/ArrowIcon';
import BreadcrumbSentenceLayout from './BreadcrumbSentenceLayout';
import LinkTextSentence from '../LinkTextSentence';

export interface BreadcrumbSentenceProps {
  iconSize: string | number;
  marginBetween: string | number;
  text: string;
  theme: {
    text: TextThemeProps;
    link: TextThemeProps;
    icon?: { color: string };
  };
  urlList: Array<{ replaceText: string; url: string; target?: string }>;
}

const iconDefaultColor = color.text.zambezi;

const BreadcrumbSentence: FC<BreadcrumbSentenceProps> = ({
  iconSize,
  marginBetween,
  text,
  theme,
  urlList,
}: BreadcrumbSentenceProps) => {
  const linkTextStyle = theme.text.textDecoration
    ? theme.link
    : { ...theme.link, textDecoration: 'underLine' };

  return (
    <BreadcrumbSentenceLayout marginBetween={marginBetween}>
      <ArrowIcon
        key="icon"
        type="forward"
        size={iconSize}
        color={theme.icon?.color || iconDefaultColor}
      />
      <LinkTextSentence
        key="sentence"
        text={text}
        theme={{ ...theme, link: linkTextStyle }}
        urlList={urlList}
      />
    </BreadcrumbSentenceLayout>
  );
};
export default BreadcrumbSentence;

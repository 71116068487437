// Type
import { IssuedDebitCardInformationProps } from './IssuedDebitCardInformation.type';

class IssuedDebitCardInformation {
  private props: IssuedDebitCardInformationProps;

  constructor(props: IssuedDebitCardInformationProps) {
    this.props = props;
  }

  public getProps = () => this.props;
}

export default IssuedDebitCardInformation;

// Lib
import { getSymbolFromCurrency } from '../../../../../utils/helpers/currencyHelper';

// Type
import { TypeOfT } from '../../../../../types/typeof/Translation.type';

// Constant
import { BASE_CURRENCY_SYMBOL } from '../../../../../config/constants/business';

const CSV_LABEL_ID =
  'organisms.csv.bankSpotTransactions.BankSpotTransactions.label';

const CSV_HEADER_KEYS = [
  'createdAtCSV',
  'description',
  'depositCSV',
  'withdrawalCSV',
  'balanceCSV',
];

const BankSpotTransactionsCSVScheme = (t: TypeOfT) => ({
  fileName: 'savings-account-transaction-history.csv',
  headers: CSV_HEADER_KEYS.map((key) => ({
    key,
    label: t(`${CSV_LABEL_ID}.${key}`, {
      currency: getSymbolFromCurrency(BASE_CURRENCY_SYMBOL),
    }),
  })),
});

export default BankSpotTransactionsCSVScheme;

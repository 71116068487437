import React from 'react';

// Constant
import { INITIAL } from '../../../../../../config/constants/requestState';

export interface DebitCardRequestCreateContextProps {
  state: string;
  createDebitCardRequest: (callback?: () => void) => void;
}

const DebitCardRequestCreateContext = React.createContext<
  DebitCardRequestCreateContextProps
>({
  state: INITIAL,
  createDebitCardRequest: () => undefined,
});

export default DebitCardRequestCreateContext;

import CryptoCurrencyDeposit from '../CryptoCurrencyDeposit/CryptoCurrencyDeposit';
import { IIterable } from '../../../../../../interface/Iterator/IIterable';

class CryptoCurrencyDeposits implements IIterable<CryptoCurrencyDeposit> {
  private cryptoCurrencyDeposits: CryptoCurrencyDeposit[] = [];

  public add = (cryptoCurrencyDeposit: CryptoCurrencyDeposit) => {
    this.cryptoCurrencyDeposits.push(cryptoCurrencyDeposit);
  };

  public head = () => this.cryptoCurrencyDeposits[0];

  public map = <T>(
    callback: (CryptoCurrencyDeposit: CryptoCurrencyDeposit) => T,
  ) => {
    return this.cryptoCurrencyDeposits.map((cryptoCurrencyDeposit) =>
      callback(cryptoCurrencyDeposit),
    );
  };

  public forEach = (
    callback: (CryptoCurrencyDeposit: CryptoCurrencyDeposit) => void,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const cryptoCurrencyDeposit of this.cryptoCurrencyDeposits) {
      // eslint-disable-next-line no-await-in-loop
      callback(cryptoCurrencyDeposit);
    }
  };

  public forEachAsync = async (
    callback: (CryptoCurrencyDeposit: CryptoCurrencyDeposit) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const cryptoCurrencyDeposit of this.cryptoCurrencyDeposits) {
      // eslint-disable-next-line no-await-in-loop
      await callback(cryptoCurrencyDeposit);
    }
  };
}
export default CryptoCurrencyDeposits;

import React, { FC, useContext } from 'react';
import DebitCardHistoryOptionContext from './DebitCardHistoryOptionContext';

// Type
import { DebitCardHistoryTabs } from '../../../../../../config/menu/tab/DebitCardHistoryTab';

// Style
import { SelectedStyle, TextStyle, UnSelectedStyle } from './style';

// Component
import UnderBarTabBar from '../UnderBarTabBar';

const DebitCardHistoryTabBar: FC = () => {
  const { historyOption, setHistoryOption } = useContext(
    DebitCardHistoryOptionContext,
  );

  return (
    <UnderBarTabBar
      height={55}
      items={DebitCardHistoryTabs}
      onClick={setHistoryOption}
      selected={historyOption}
      theme={{
        text: TextStyle,
        selected: SelectedStyle,
        unSelected: UnSelectedStyle,
      }}
      widthItem={250}
    />
  );
};

export default DebitCardHistoryTabBar;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { Container } from './style';
import ContentBox from '../../../atoms/utils/layout/Box/Box/ContentBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface SavingsAccountHistoryTemplateLayoutProps {
  children: React.ReactElement[];
}

const SavingsAccountHistoryTemplateLayout: FC<SavingsAccountHistoryTemplateLayoutProps> = ({
  children,
}: SavingsAccountHistoryTemplateLayoutProps) => {
  const elements = ['header', 'card', 'table'];
  const [header, card, table] = getElementsFromKeys(children, elements);

  return (
    <Container>
      {header}
      <Margin top={50} bottom={50}>
        <ContentBox padding={50} width="100%">
          {card}
          <Margin top={35}>{table}</Margin>
        </ContentBox>
      </Margin>
    </Container>
  );
};
export default SavingsAccountHistoryTemplateLayout;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { LabeledTextStyle } from './style';

// DomainObject
import ContractTimeDeposit from '../../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';

// Component
import LabeledText from '../../../../../molecules/textGroup/LabeledText';
import ContractTimeDepositCardTermLayout from './ContractTimeDepositCardTermLayout';

export interface ContractTimeDepositCardTermProps {
  contractTimeDeposit?: ContractTimeDeposit;
}

const DATE_FORMAT = 'DD/MMM/YYYY';
const ORGANISM_ID = `organisms.card.ContractTimeDepositCard`;

const ContractTimeDepositCardTerm: FC<ContractTimeDepositCardTermProps> = ({
  contractTimeDeposit,
}: ContractTimeDepositCardTermProps) => {
  const { t } = useTranslation();

  const depositDateFormatted = contractTimeDeposit?.getDepositDateFormatted(
    DATE_FORMAT,
  );

  const maturityDateFormatted = contractTimeDeposit?.getMaturityDateFormatted(
    DATE_FORMAT,
  );

  return (
    <ContractTimeDepositCardTermLayout>
      <LabeledText
        key="depositDate"
        direction="column"
        label={t(`${ORGANISM_ID}.label.depositDate`)}
        marginBetween={4}
        value={depositDateFormatted ?? ''}
        theme={LabeledTextStyle}
      />
      <LabeledText
        key="maturityDate"
        direction="column"
        label={t(`${ORGANISM_ID}.label.maturityDate`)}
        marginBetween={4}
        theme={LabeledTextStyle}
        value={maturityDateFormatted ?? ''}
      />
    </ContractTimeDepositCardTermLayout>
  );
};

export default ContractTimeDepositCardTerm;

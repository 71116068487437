import Joi from '@hapi/joi';
import { CryptoCurrencyType } from '../../../../types/CryptoCurrency.type';

export interface CryptoCurrencyAvailableProps {
  isAvailable: boolean;
}

export const CryptoCurrencyAvailablePropsFormat = {
  id: Object.keys(CryptoCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),

  isAvailable: Joi.boolean(),
};

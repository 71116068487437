import BitcoinWallet from '../BitcoinWallet/BitcoinWallet';
import CryptoCurrencyWallets from '../../CryptoCurrencyWallet/CryptoCurrencyWallets/CryptoCurrencyWallets';

class BitcoinWallets extends CryptoCurrencyWallets {
  private bitcoinWallets: BitcoinWallet[] = [];

  public add = (bitcoinWallet: BitcoinWallet) => {
    this.bitcoinWallets.push(bitcoinWallet);
  };

  public head = () => {
    const bitcoinWallet = this.bitcoinWallets[0];
    return bitcoinWallet;
  };

  public map = <T>(callback: (bitcoinWallet: BitcoinWallet) => T) => {
    return this.bitcoinWallets.map((bitcoinWallet) => callback(bitcoinWallet));
  };

  public forEach = (callback: (bitcoinWallet: BitcoinWallet) => void) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const bitcoinWallet of this.bitcoinWallets) {
      // eslint-disable-next-line no-await-in-loop
      callback(bitcoinWallet);
    }
  };

  public forEachAsync = async (
    callback: (bitcoinWallet: BitcoinWallet) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const bitcoinWallet of this.bitcoinWallets) {
      // eslint-disable-next-line no-await-in-loop
      await callback(bitcoinWallet);
    }
  };
}

export default BitcoinWallets;

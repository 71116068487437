import React from 'react';
import DebitCardSpotTransactionV2s from './DebitCardSpotTransactionV2s';

export interface DebitCardSpotTransactionV2sContextProps {
  debitCardSpotTransactions: DebitCardSpotTransactionV2s;
}

const DebitCardSpotTransactionV2sContext = React.createContext<
  DebitCardSpotTransactionV2sContextProps
>({
  debitCardSpotTransactions: new DebitCardSpotTransactionV2s(),
});

export default DebitCardSpotTransactionV2sContext;

import React, { FC } from 'react';

// Type
import { CurrencyType } from '../../../../../types/Currency.type';

// Constant
import {
  BASE_CURRENCY_SYMBOL,
  NO_DATA,
} from '../../../../../config/constants/business';

// Style
import {
  AmountTextStyle,
  CurrencySymbolTextStyle,
  LabelTextStyle,
  TitleTextStyle,
} from './style';

// Component
import { TextPrimary } from '../../../../atoms/text2/Text2';
import LabeledCurrencyAmount from '../../../textGroup/LabeledCurrencyAmount';
import AssetFiatCurrencyCardLayout from './AssetFiatCurrencyCardLayout';

export interface AssetFiatCurrencyCardProps {
  balance: number | undefined;
  label: string;
  title: string;
  layout: {
    width: string | number;
    height: string | number;
    padding: string | number;
  };
}

const AssetFiatCurrencyCard: FC<AssetFiatCurrencyCardProps> = ({
  balance,
  label,
  title,
  layout,
}: AssetFiatCurrencyCardProps) => {
  return (
    <AssetFiatCurrencyCardLayout layout={layout}>
      <TextPrimary key="title" theme={TitleTextStyle}>
        {title}
      </TextPrimary>
      <LabeledCurrencyAmount
        key="balance"
        amount={balance === undefined ? NO_DATA : balance}
        currency={BASE_CURRENCY_SYMBOL as CurrencyType}
        direction={'column'}
        isSymbol
        label={label}
        labelFontFamily="primary"
        marginBetween={10}
        theme={{
          amount: AmountTextStyle,
          currency: CurrencySymbolTextStyle,
          label: LabelTextStyle,
        }}
      />
    </AssetFiatCurrencyCardLayout>
  );
};

export default AssetFiatCurrencyCard;

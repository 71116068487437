import React, { FC } from 'react';
import { SuccessCheckIcon, CheckMarkLeft, CheckMarkRight } from './style';

export interface CheckIconLargeProps {
  type: 'success';
}

const CheckIconLarge: FC<CheckIconLargeProps> = ({
  type,
}: CheckIconLargeProps) => {
  switch (type) {
    case 'success':
      return (
        <SuccessCheckIcon>
          <CheckMarkLeft />
          <CheckMarkRight />
        </SuccessCheckIcon>
      );

    default:
      return <></>;
  }
};
export default CheckIconLarge;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { CircleBox } from '../../../utils/layout/Box/Box/CircleBox';
import { IconContainer } from './style';
import { CheckIcon } from '../../../icon/CheckIcon';

export interface ProgressStepDoneProps {
  diameterCircle: string | number;
  theme: {
    iconStyle: {
      iconColor: string;
      iconSize: string;
    };
    circleStyle: {
      backgroundColor: string;
    };
  };
}

const ProgressStepDone: FC<ProgressStepDoneProps> = ({
  diameterCircle,
  theme,
}: ProgressStepDoneProps) => {
  return (
    <CircleBox
      theme={{ backgroundColor: theme.circleStyle.backgroundColor }}
      width={diameterCircle}
      height={diameterCircle}
    >
      <IconContainer>
        <CheckIcon
          color={theme.iconStyle.iconColor}
          size={theme.iconStyle.iconSize}
        />
      </IconContainer>
    </CircleBox>
  );
};
export default ProgressStepDone;

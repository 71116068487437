// Type
import { OptionObject } from '../../../../atoms/select/SelectBox/Option.type';

// DomainObject
import DocumentType from '../../../../../domain/settings/documentType/DocumentType/DocumentType/DocumentType';
import DocumentTypes from '../../../../../domain/settings/documentType/DocumentType/DocumentTypes/DocumentTypes';

class DocumentTypesOptionsMapper {
  static domainToOptions = (documentTypes: DocumentTypes) => {
    const getOption = (documentType: DocumentType): OptionObject => {
      const { label, value } = documentType.getProps();

      return {
        label,
        value,
      };
    };

    return documentTypes.map<OptionObject>(getOption);
  };
}

export default DocumentTypesOptionsMapper;

import { useState } from 'react';

// Lib
import { checkInternetConnection } from 'utils/helpers/connection';

// Error
import ErrorHandler from 'utils/errors/ErrorHandler';

// Constants
import { INITIAL, IS_LOADING, SUCCESS } from 'config/constants/requestState';

// Repository
import TransferDebitCardTransactionV2Functions from 'repository/transfer/TransferDebitCardTransactionV2Functions';

export const useTransferDebitCardCancelUseCase = () => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  Repository
   *
   * */
  const transferDebitCardTransactionV2Repository = new TransferDebitCardTransactionV2Functions();

  /* *
   *
   *  Methods
   *
   * */
  const cancelTopUp = async (id: string, callback?: () => void) => {
    setState(IS_LOADING);

    try {
      checkInternetConnection();

      // Execute
      await transferDebitCardTransactionV2Repository.cancelTopUp(id);

      if (callback) callback();

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  return {
    state,
    setState,
    cancelTopUp,
  };
};

import React, { FC, useEffect } from 'react';
import { ForgotPasswordContext } from './ForgotPasswordContext';
import { useForgotPassword } from './useForgotPassword';

// Error
import { ErrorCodeType } from '../../../utils/errors/ErrorHandler/ErrorCode.type';
import SystemErrorHandler from '../../errorHandler/SystemErrorHandler';
import { SUCCESS } from '../../../config/constants/requestState';

export type ForgotPasswordProps = {
  children: React.ReactNode;
};

const ERROR_CODES_HANDLED = [
  ErrorCodeType.GENERAL_DATA_NOT_FOUND_BY_FIELDS_CLIENT,
  ErrorCodeType.AUTHENTICATION_LOGIN_USER_INACTIVE,
] as Array<string>;

const ForgotPassword: FC<ForgotPasswordProps> = ({
  children,
}: ForgotPasswordProps) => {
  const { state, setState, forgotPassword } = useForgotPassword();

  useEffect(() => {
    if (ERROR_CODES_HANDLED.includes(state)) setState(SUCCESS);
    // eslint-disable-next-line
  }, [state]);

  return (
    <ForgotPasswordContext.Provider
      value={{
        forgotPasswordState: state,
        setForgotPasswordState: setState,
        forgotPassword,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </ForgotPasswordContext.Provider>
  );
};
export default ForgotPassword;

import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import { useResetAuthenticatorViewUseCase } from './useResetAuthenticatorViewUseCase';
import { FormInputResetAuthenticatorTwoFactorAuthContext } from '../../../../components/organisms/form/front/FormResetAuthenticatorTwoFactorAuth/FormResetAuthenticatorTwoFactorAuthInput';

// Constant
import { IS_LOADING } from '../../../../config/constants/requestState';
import { ErrorCodeType } from '../../../../utils/errors/ErrorHandler/ErrorCode.type';
import { ROUTE_AUTH_ERROR } from '../../../../config/constants/routing';

// DomainObject
import ResetAuthenticatorRequestContext from '../../../../domain/userRequest/resetAuthenticatorRequest/ResetAuthenticatorRequest/ResetAuthenticatorRequestContext';

// Component
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';
import LoadingModal from '../../../../components/organisms/modal/LoadingModal';

export type ResetAuthenticatorViewProviderProps = {
  children: React.ReactNode;
};

const ResetAuthenticatorViewProvider: FC<ResetAuthenticatorViewProviderProps> = ({
  children,
}: ResetAuthenticatorViewProviderProps) => {
  const { oobCode } = useParams<{ oobCode: string }>();
  const history = useHistory();

  const {
    state,
    setState,
    resetAuthenticatorRequest,
    formInputResetAuthenticatorTwoFactorAuthContext,
  } = useResetAuthenticatorViewUseCase(oobCode);

  const [showModal, hideModal] = useModal(() => <LoadingModal />);

  /* *
   *
   *  UseEffect
   *
   * */

  // Loading Modal
  useEffect(() => {
    if (state === IS_LOADING) showModal();

    if (state !== IS_LOADING) hideModal();
  }, [state, showModal, hideModal]);

  // 401 Error Redirect
  useEffect(() => {
    const redirectState = [
      ErrorCodeType.AUTHENTICATION_RESET_AUTHENTICATOR_EXPIRED,
      ErrorCodeType.AUTHENTICATION_RESET_AUTHENTICATOR_NOT_FOUND,
    ];
    if (redirectState.indexOf(state as ErrorCodeType) > -1)
      history.replace(ROUTE_AUTH_ERROR);
  }, [state, history]);

  return (
    <FormInputResetAuthenticatorTwoFactorAuthContext.Provider
      value={formInputResetAuthenticatorTwoFactorAuthContext}
    >
      <ResetAuthenticatorRequestContext.Provider
        value={{ resetAuthenticatorRequest }}
      >
        <SystemErrorHandler state={state} setState={setState}>
          {children}
        </SystemErrorHandler>
      </ResetAuthenticatorRequestContext.Provider>
    </FormInputResetAuthenticatorTwoFactorAuthContext.Provider>
  );
};
export default ResetAuthenticatorViewProvider;

import styled from 'styled-components';
import fontWeight from '../../../utils/const/fontWeight';

export const FilterInput = styled.input`
  box-sizing: border-box;
  opacity: 1;
  width: 100%;
  height: ${30 / 16}rem;
  padding: ${2 / 16}rem 0 ${2 / 16}rem ${2 / 16}rem;
  font: ${fontWeight.regular} ${15 / 16}rem LatoRegular;
  letter-spacing: ${(15 / 16) * 0.02}rem;
  border: 1px solid #D6DDEC;

  :focus {
    outline: none;
    box-shadow: 0px 0px 7px #0093EE45;
    border: 1px solid #D6DDEC; !important
  }

  :disabled {
    outline: none;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #D5D0DA;
  }
  ::-ms-input-placeholder {
    color: #D5D0DA;
  }
`;

// Type
import {
  KycStepType,
  KycStatusType,
} from '../../../../../types/KycRequest.type';
import { TypeOfHistory } from '../../../../../types/typeof/History.type';

// // Constant
import {
  ROUTE_CORPORATE_INFORMATION,
  ROUTE_CORPORATE_ADDRESS,
  ROUTE_PERSONAL_INFORMATION,
  ROUTE_PROOF_OF_RESIDENCY,
  ROUTE_CONSENT_FORM,
  ROUTE_TWO_FACTOR_AUTH_APP_SETTINGS,
} from '../../../../../config/constants/routing';

// DomainObject
import KycRequest from '../../../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequest';
import ConsentForm from '../../../../../domain/verification/consentForm/ConsentForm/ConsentForm';
import KycRequestIndividual from '../../../../../domain/verification/kycRequest/KycRequest/KycRequestIndividual/KycRequestIndividual';
import KycRequestCorporate from '../../../../../domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporate';

class KycStepsMapper {
  static kycRequestToSteps = (
    kycRequest: KycRequest,
    history: TypeOfHistory,
  ) => {
    if (kycRequest instanceof KycRequestIndividual)
      return KycStepsMapper.kycRequestIndividualToSteps(kycRequest, history);
    if (kycRequest instanceof KycRequestCorporate)
      return KycStepsMapper.kycRequestCorporateToSteps(kycRequest, history);
    return [];
  };

  static kycRequestIndividualToSteps = (
    kycRequest: KycRequestIndividual,
    history: TypeOfHistory,
  ) => {
    const kycStepStatus = kycRequest.getKycStepStatus();
    const isReviewing = kycStepStatus.isReviewing();

    return [
      {
        kycStepType: KycStepType.personalInformation,
        status: isReviewing
          ? KycStatusType.reviewing
          : kycStepStatus.getProps().personalInformation,
        onClick: () => {
          history.push(ROUTE_PERSONAL_INFORMATION);
        },
      },
      {
        kycStepType: KycStepType.proofOfResidency,
        status: isReviewing
          ? KycStatusType.reviewing
          : kycStepStatus.getProps().proofOfResidency,
        onClick: () => {
          history.push(ROUTE_PROOF_OF_RESIDENCY);
        },
      },
      {
        kycStepType: KycStepType.waitingReview,
        status: kycStepStatus.getProps().waitingReview,
      },
    ];
  };

  static kycRequestCorporateToSteps = (
    kycRequest: KycRequestCorporate,
    history: TypeOfHistory,
  ) => {
    const kycStepStatus = kycRequest.getKycStepStatus();
    const isReviewing = kycStepStatus.isReviewing();

    return [
      {
        kycStepType: KycStepType.corporateInformation,
        status: isReviewing
          ? KycStatusType.reviewing
          : kycStepStatus.getProps().corporateInformation,
        onClick: () => {
          history.push(ROUTE_CORPORATE_INFORMATION);
        },
      },
      {
        kycStepType: KycStepType.corporateAddress,
        status: isReviewing
          ? KycStatusType.reviewing
          : kycStepStatus.getProps().corporateAddress,
        onClick: () => {
          history.push(ROUTE_CORPORATE_ADDRESS);
        },
      },
      {
        kycStepType: KycStepType.personalInformation,
        status: isReviewing
          ? KycStatusType.reviewing
          : kycStepStatus.getProps().personalInformation,
        onClick: () => {
          history.push(ROUTE_PERSONAL_INFORMATION);
        },
      },
      {
        kycStepType: KycStepType.proofOfResidency,
        status: isReviewing
          ? KycStatusType.reviewing
          : kycStepStatus.getProps().proofOfResidency,
        onClick: () => {
          history.push(ROUTE_PROOF_OF_RESIDENCY);
        },
      },
      {
        kycStepType: KycStepType.waitingReview,
        status: kycStepStatus.getProps().waitingReview,
      },
    ];
  };

  static consentFormToSteps = (
    consentForm: ConsentForm,
    history: TypeOfHistory,
  ) => {
    const consentFormStepsStatus = consentForm.getConsentFormStepsStatus();
    const isReviewing = consentFormStepsStatus.isReviewing();

    return [
      {
        kycStepType: KycStepType.consentForm,
        status: isReviewing
          ? KycStatusType.reviewing
          : consentFormStepsStatus.getProps().statusConsentForm,
        onClick: () => {
          history.push(ROUTE_CONSENT_FORM);
        },
      },
      {
        kycStepType: KycStepType.twoFactorAuthenticationAppSettings,
        status: isReviewing
          ? KycStatusType.reviewing
          : consentFormStepsStatus.getProps()
              .statusTwoFactorAuthenticationAppSettings,
        onClick: () => {
          history.push(ROUTE_TWO_FACTOR_AUTH_APP_SETTINGS);
        },
      },
      {
        kycStepType: KycStepType.waitingConsentFormReview,
        status: consentFormStepsStatus.getProps()
          .statusWaitingConsentFormReview,
      },
    ];
  };
}

export default KycStepsMapper;

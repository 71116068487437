import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import DebitCardTemplate from '../../../components/templates/debitCard/DebitCardTemplate';

const DebitCardPage: FC = () => {
  return (
    <DashboardTemplate>
      <DebitCardTemplate />
    </DashboardTemplate>
  );
};

export default DebitCardPage;

import React from 'react';
import ContractsTimeDeposit from './ContractsTimeDeposit';

export interface ContractsTimeDepositContextProps {
  contractsTimeDeposit: ContractsTimeDeposit;
}

const ContractsTimeDepositContext = React.createContext<
  ContractsTimeDepositContextProps
>({
  contractsTimeDeposit: new ContractsTimeDeposit(),
});

export default ContractsTimeDepositContext;

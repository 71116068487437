import Joi from '@hapi/joi';
import { CryptoCurrencyType } from '../../../../../../../types/CryptoCurrency.type';
import { CryptoCurrencyTransactionStatusType } from '../../../../../../../types/CryptoCurrencyTransaction.type';
import {
  CryptoCurrencyTransactionProps,
  CryptoCurrencyTransactionPropsFormat,
} from '../../../../cryptoCurrencyTransaction/CryptoCurrencyTransaction/CryptoCurrencyTransaction.type';

export interface CryptoCurrencySpotTransactionProps
  extends CryptoCurrencyTransactionProps {
  commonId: string;
  currency: CryptoCurrencyType;
  fee: number;
  status: CryptoCurrencyTransactionStatusType;
}

export const CryptoCurrencySpotTransactionPropsFormat = {
  ...CryptoCurrencyTransactionPropsFormat,

  descriptionParameter: Joi.string().empty(''),

  currency: Object.keys(CryptoCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),

  commonId: Joi.string().required().empty(''),

  fee: Joi.number().required(),

  status: Object.keys(CryptoCurrencyTransactionStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),
};

import React, { FC } from 'react';

// Error
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';

// Assets (Image)
import ApprovedImage from '../../../../assets/images/approved.png';
import SubmittedImage from '../../../../assets/images/submitted.png';
import ReviewImage from '../../../../assets/images/reviewing.png';
import RejectedImage from '../../../../assets/images/rejected.png';
import InitialImage from '../../../../assets/images/initial.svg';

// Type
import { KycStatusType } from '../../../../types/KycRequest.type';
import { TransferDebitCardStatusType } from '../../../../types/Transfer.type';

export interface StatusIconProps {
  status?: KycStatusType | TransferDebitCardStatusType;
  width?: string | number;
  height?: string | number;
}

const StatusIcon: FC<StatusIconProps> = ({
  status,
  width = '100%',
  height = '100%',
}: StatusIconProps) => {
  switch (status) {
    case KycStatusType.approved:
    case TransferDebitCardStatusType.confirmed:
      return <img src={ApprovedImage} alt="✓" width={width} height={height} />;

    case KycStatusType.submitted:
      return <img src={SubmittedImage} alt="-" width={width} height={height} />;

    case KycStatusType.reviewing:
    case TransferDebitCardStatusType.unconfirmed:
      return <img src={ReviewImage} alt="|_" width={width} height={height} />;
    case KycStatusType.rejected:
    case TransferDebitCardStatusType.rejected:
      return <img src={RejectedImage} alt="x" width={width} height={height} />;
    case TransferDebitCardStatusType.canceled:
      return <img src={InitialImage} alt="-" width={width} height={height} />;
    default:
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_SWITCH,
        {
          value: status as string,
          place: `StatusBadge`,
        },
      );
  }
};
export default StatusIcon;

// Lib
import { omit } from 'lodash';

// Type
import { ContractTimeDepositStatusType } from '../../../../types/ContractTimeDeposit.type';
import {
  ContractTimeDepositProps,
  ContractTimeDepositPropsFormat,
} from '../ContractTimeDeposit/ContractTimeDeposit.type';
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';

export type ContractTimeDepositSeedProps = ContractTimeDepositProps;

export const contractTimeDepositSeedInitialProps = {
  userId: '',
  savingsAccountNumber: '',
  depositAmount: 0,
  depositCurrency: FiatCurrencyType.USD,
  interestRate: 0,
  term: 0,
  depositDate: '', // YYYY/MM/DD
  maturityDate: '', // YYYY/MM/DD
  status: ContractTimeDepositStatusType.active,
  isAutoRenewal: true,
  createdAt: Date.now(),
  updatedAt: Date.now(),
};

export const ContractTimeDepositSeedPropsFormat = omit(
  ContractTimeDepositPropsFormat,
  ['id'],
);

import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  background: #f0f1f7 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
`;

export const Main = styled.div`
  height: 100%;
  margin-top: ${70 / 16}rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const IconWrap = styled.div`
  width: ${94.49 / 16}rem;
  height: ${108.46 / 16}rem;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

import React, { FC, useContext } from 'react';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

// Context
import FeeTransferSavingsAccountContext from '../../../../../domain/settings/transactionFee/FeeTransferSavingsAccount/FeeTransferSavingsAccountContext';
import RequestStateContext from '../../../../../value/view/RequestState/RequestStateContext';
import SavingsAccountsContext from '../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccountsContext';
import SavingsAccountOptionContext from '../../../../../components/molecules/selectBox/option/option/SavingsAccountOption/SavingsAccountOptionContext';
import TransferSavingsAccountTypeOptionContext from '../../../../../components/molecules/selectBox/option/option/TransferSavingsAccountTypeOption/TransferSavingsAccountTypeOptionContext';
import TransferSavingsAccountPageStateContext from '../../../../pageState/transfer/TransferSavingsAccountPageState/TransferSavingsAccountPageStateContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import UserVerifiedContext from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedContext';

// Hook
import { useTransferSavingsAccountViewUseCase } from './useTransferSavingsAccountViewUseCase';
import { useTransferSavingsAccountPageState } from '../../../../pageState/transfer/TransferSavingsAccountPageState/useTransferSavingsAccountPageState';

export type TransferSavingsAccountViewProviderProps = {
  children: React.ReactNode;
};

const TransferSavingsAccountViewProvider: FC<TransferSavingsAccountViewProviderProps> = ({
  children,
}: TransferSavingsAccountViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // PageState
  const { pageState, setPageState } = useTransferSavingsAccountPageState();

  const {
    state,
    setState,
    userVerified,
    savingsAccounts,
    savingsAccountOption,
    setSavingsAccountOption,
    transferSavingsAccountTypeOption,
    setTransferSavingsAccountTypeOption,
    feeTransferSavingsAccount,
  } = useTransferSavingsAccountViewUseCase(userAuthorized);

  return (
    <TransferSavingsAccountPageStateContext.Provider
      value={{ pageState, setPageState }}
    >
      <RequestStateContext.Provider value={{ requestState: state }}>
        <UserVerifiedContext.Provider value={{ user: userVerified }}>
          <SavingsAccountsContext.Provider value={{ savingsAccounts }}>
            <SavingsAccountOptionContext.Provider
              value={{
                savingsAccountOption,
                setSavingsAccountOption,
              }}
            >
              <TransferSavingsAccountTypeOptionContext.Provider
                value={{
                  transferSavingsAccountTypeOption,
                  setTransferSavingsAccountTypeOption,
                }}
              >
                <FeeTransferSavingsAccountContext.Provider
                  value={{
                    feeTransferSavingsAccount,
                  }}
                >
                  <SystemErrorHandler state={state} setState={setState}>
                    {children}
                  </SystemErrorHandler>
                </FeeTransferSavingsAccountContext.Provider>
              </TransferSavingsAccountTypeOptionContext.Provider>
            </SavingsAccountOptionContext.Provider>
          </SavingsAccountsContext.Provider>
        </UserVerifiedContext.Provider>
      </RequestStateContext.Provider>
    </TransferSavingsAccountPageStateContext.Provider>
  );
};
export default TransferSavingsAccountViewProvider;

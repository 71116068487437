import Joi from '@hapi/joi';

// Type
import {
  DebitCardSpotTransactionV2Props,
  DebitCardSpotTransactionV2PropsFormat,
} from '../../DebitCardSpotTransactionV2/DebitCardSpotTransactionV2/DebitCardSpotTransactionV2.type';
import { DebitCardDepositType } from '../../../../../../types/DebitCardTransaction.type';

export interface DebitCardDepositV2Props
  extends DebitCardSpotTransactionV2Props {
  type: DebitCardDepositType;
}

export const DebitCardDepositV2PropsFormat = {
  ...DebitCardSpotTransactionV2PropsFormat,

  type: Object.keys(DebitCardDepositType)
    .reduce((joi, type) => joi.valid(type), Joi.string())
    .required(),
};

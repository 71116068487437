import { useState } from 'react';
import KycRequestCorporate from './KycRequestCorporate';

export const useKycRequestCorporate = (initial?: KycRequestCorporate) => {
  const [kycRequestCorporate, setKycRequestCorporate] = useState<
    KycRequestCorporate | undefined
  >(initial);

  return {
    kycRequestCorporate,
    setKycRequestCorporate,
  };
};

interface SelectBoxStyleProps {
  minHeight?: string;
  marginLeft?: string;
  borderColor?: string;
  placeholderColor?: string;
  dropdownColor?: string;
  disabled?: boolean;
}

type DisplayType =
  | 'absolute'
  | 'fixed'
  | '-moz-initial'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset'
  | '-webkit-sticky'
  | 'relative'
  | 'static'
  | 'sticky'
  | undefined;

type BoxSizingType =
  | '-moz-initial'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset'
  | 'border-box'
  | 'content-box'
  | undefined;

export const selectBoxStyle = ({
  minHeight,
  borderColor,
  placeholderColor,
  dropdownColor,
  marginLeft,
  disabled,
}: SelectBoxStyleProps) => {
  return {
    control: () => ({
      display: `flex`,
      boxSizing: `border-box` as `border-box`,
      justifyContent: `space-between`,
      border: `1px solid ${borderColor}`,
      width: '100%',
      minHeight,
      paddingLeft: marginLeft,
      backgroundColor: disabled ? '#DBDDE8' : '#fff',
      zIndex: 1,
    }),
    singleValue: () => ({
      position: 'absolute' as DisplayType,
      display: 'flex',
      alignItems: 'center',
      marginLeft: `${15 / 16}rem`,
      fontFamily: `LatoRegular`,
      width: '100%',
      height: '100%',
    }),
    option: () => ({
      boxSizing: 'border-box' as BoxSizingType,
      position: 'relative' as const,
      fontFamily: `LatoRegular`,
      display: 'flex',
      alignItems: `center`,
      justifyContent: 'center',
      paddingLeft: `${15 / 16}rem`,
      minHeight,
      borderBottom: `${0.5 / 16}rem solid #cdd0dd`,
      height: '100%',
      width: '100%',
    }),
    valueContainer: () => ({
      boxSizing: 'border-box' as BoxSizingType,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    }),
    placeholder: () => ({
      position: 'absolute' as DisplayType,
      top: `50%`,
      transform: `translateY(-50%)`,
      marginRight: `2px`,
      marginLeft: `${15 / 16}rem`,
      fontSize: `${22 / 16}rem`,
      color: placeholderColor,
      fontFamily: `LatoMedium`,
      letterSpacing: `${(22 / 16) * 0.02}rem`,
    }),
    dropdownIndicator: () => ({
      paddingRight: `${5 / 16}rem`,
      color: dropdownColor,
    }),
    indicatorSeparator: () => ({ width: 0 }),
  };
};

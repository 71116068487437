// IUseCase
import IResetAuthenticatorUseCase from '../../../enhancers/useCase/authentication/ResetAuthenticatorProvider/IResetAuthenticatorUseCase';

// IService
import IResetAuthenticatorRequestUpdateService from './IResetAuthenticatorRequestUpdateService';

// Service
import ResetAuthenticatorRequestUpdateService from '../../../service/userRequest/resetAuthenticatorRequest/ResetAuthenticatorRequestUpdateService';

// IRepository
import IResetAuthenticatorRequestRepository from '../../../domain/userRequest/resetAuthenticatorRequest/IResetAuthenticatorRequestRepository';

class ResetAuthenticatorUseCase implements IResetAuthenticatorUseCase {
  private resetAuthenticatorRequestUpdateService: IResetAuthenticatorRequestUpdateService;

  constructor(
    resetAuthenticatorRepository: IResetAuthenticatorRequestRepository,
  ) {
    this.resetAuthenticatorRequestUpdateService = new ResetAuthenticatorRequestUpdateService(
      resetAuthenticatorRepository,
    );
  }

  public resetAuthenticator = async (
    id: string,
    oobCode: string,
    code: string,
  ) => {
    await this.resetAuthenticatorRequestUpdateService.executeResetAuthenticator(
      id,
      oobCode,
      code,
    );
  };
}
export default ResetAuthenticatorUseCase;

import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  height: 100%;

  :hover {
    background-color: #dbf1ff;
  }
`;

import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  SavingsAccountProps,
  SavingsAccountPropsFormat,
} from './SavingsAccount.type';

// DomainObject
import SavingsAccount from './SavingsAccount';

// ValueObject
import SerialNumberSavingsAccount from '../../../idManager/productSerialNumber/ProductSerialNumber/SerialNumber/concreteSerialNumber/SerialNumberSavingsAccount';

class SavingsAccountFactory {
  static create = (
    id: SerialNumberSavingsAccount,
    props: SavingsAccountProps,
  ) => {
    const result = Joi.object(SavingsAccountPropsFormat).validate(props);

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: SavingsAccount.name,
          reason: result.error.details[0].message,
        },
      );

    return new SavingsAccount(id, props);
  };
}

export default SavingsAccountFactory;

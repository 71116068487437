import React, { FC } from 'react';
import { DomainTextProps } from '../DomainText.type';
import fontColor from '../../../../../fonts/color.json';
import { TextPrimaryRegular21 } from '../../TextPrimary';

const LabelFormHorizontal: FC<DomainTextProps> = ({
  children,
  color = fontColor.pageTitle,
}: DomainTextProps) => (
  <TextPrimaryRegular21 color={color}>{children}</TextPrimaryRegular21>
);
export default LabelFormHorizontal;

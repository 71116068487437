import {
  DIGIT_TRANSACTION_SERIAL_NUMBER,
  MAX_NUMBER_TRANSACTION_SERIAL_NUMBER,
  MIN_NUMBER_TRANSACTION_SERIAL_NUMBER,
} from '../../../config/constants/business';
import SerialNumber from './SerialNumber';

class TransactionSerialNumber extends SerialNumber {
  constructor(serialNumber: string) {
    super(serialNumber);

    if (Number(serialNumber) > MAX_NUMBER_TRANSACTION_SERIAL_NUMBER)
      throw new Error(
        `Argument number exceed max number at ${this.constructor.name}`,
      );

    if (Number(serialNumber) < MIN_NUMBER_TRANSACTION_SERIAL_NUMBER)
      throw new Error(
        `Argument number fall behind min number at ${this.constructor.name}`,
      );
  }

  static addSerialNumber = (serialNumber: string, added: number) => {
    const zeroPadding = '0'.repeat(DIGIT_TRANSACTION_SERIAL_NUMBER);
    const base = 10 ** DIGIT_TRANSACTION_SERIAL_NUMBER;

    const nextNumber = `${zeroPadding}${
      (Number(serialNumber) % base) + added
    }`.slice(DIGIT_TRANSACTION_SERIAL_NUMBER * -1);

    const newSerialNumber =
      Number(nextNumber) % base === 0 ? `${zeroPadding}${1}` : nextNumber;

    return newSerialNumber;
  };
}
export default TransactionSerialNumber;

import { useState } from 'react';
import ContractTimeDeposit from './ContractTimeDeposit';

export const useContractTimeDeposit = (defaultValue?: ContractTimeDeposit) => {
  const [contractTimeDeposit, setContractTimeDeposit] = useState<
    ContractTimeDeposit | undefined
  >(defaultValue);

  return {
    contractTimeDeposit,
    setContractTimeDeposit,
  };
};

import React, { FC } from 'react';
import styled from 'styled-components';
import {
  light,
  regular,
  medium,
  semibold,
  bold,
} from '../../../../fonts/fontWeight.json';

export interface FontFamilyLatoProps {
  children: React.ReactNode;
  fontWeight?: number;
}

const FontFamilyLatoSpan = styled.span`
  font-family: ${(props) => {
    if (props.theme.fontWeight === light) return 'LatoLight';
    if (props.theme.fontWeight === regular) return 'LatoRegular';
    if (props.theme.fontWeight === medium) return 'LatoMedium';
    if (props.theme.fontWeight === semibold) return 'LatoSemibold';
    return 'Lato';
  }};
  font-weight: ${(props) => (props.theme.fontWeight === bold ? bold : regular)};
`;

const FontFamilyLato: FC<FontFamilyLatoProps> = ({
  children,
  fontWeight,
}: FontFamilyLatoProps) => (
  <FontFamilyLatoSpan theme={{ fontWeight }}>{children}</FontFamilyLatoSpan>
);
export default FontFamilyLato;

import React, { FC, useEffect } from 'react';
import { useSignInUseCase } from './useSignInUseCase';
import SignInContext from './SignInContext';
import SignInPageStateContext from '../../../pageState/authentication/SignInPageState/SignInPageStateContext';
import { useSignInPageState } from '../../../pageState/authentication/SignInPageState/useSignInPageState';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Constant
import { SUCCESS } from '../../../../config/constants/requestState';

// Component
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type SignInProviderProps = {
  children: React.ReactNode;
};

const SignInProvider: FC<SignInProviderProps> = ({
  children,
}: SignInProviderProps) => {
  const { pageState, setPageState } = useSignInPageState();

  const {
    state,
    setState,
    signIn,
    userAuthorized,
    setUserAuthorized,
  } = useSignInUseCase();

  useEffect(() => {
    if (state === SUCCESS) setPageState('twoFactorAuth');
  }, [state, setPageState]);

  return (
    <SignInPageStateContext.Provider value={{ pageState, setPageState }}>
      <SignInContext.Provider value={{ state, setState, signIn }}>
        <UserAuthorizedContext.Provider
          value={{ userAuthorized, setUserAuthorized }}
        >
          <SystemErrorHandler state={state} setState={setState}>
            {children}
          </SystemErrorHandler>
        </UserAuthorizedContext.Provider>
      </SignInContext.Provider>
    </SignInPageStateContext.Provider>
  );
};
export default SignInProvider;

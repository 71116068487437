import React, { FC, useContext } from 'react';
import { useCryptoCurrencyWalletViewUseCase } from './useCryptoCurrencyWalletViewUseCase';
import CryptoCurrenciesAvailableContext from '../../../../domain/settings/cryptoCurrencyAvailable/CryptoCurrenciesAvailable/CryptoCurrenciesAvailableContext';
import CryptoCurrencyWalletContext from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWalletContext';
import CryptoCurrencyHistoryOptionContext from '../../../../components/molecules/selector/tabBar/UnderBarTabBar/CryptoCurrencyHistoryTabBar/CryptoCurrencyHistoryOptionContext';
import CryptoCurrencyOptionContext from '../../../../components/molecules/selectBox/option/option/CryptoCurrencyOption/CryptoCurrencyOptionContext';
import RequestStateContext from '../../../../value/view/RequestState/RequestStateContext';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Component
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type CryptoCurrencyWalletViewProviderProps = {
  children: React.ReactNode;
};

const CryptoCurrencyWalletViewProvider: FC<CryptoCurrencyWalletViewProviderProps> = ({
  children,
}: CryptoCurrencyWalletViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    cryptoCurrenciesAvailable,
    cryptoCurrencyWallet,
    cryptoCurrencyOption,
    setCryptoCurrencyOption,
    historyOption,
    setHistoryOption,
  } = useCryptoCurrencyWalletViewUseCase(userAuthorized);

  return (
    <RequestStateContext.Provider value={{ requestState: state }}>
      <CryptoCurrenciesAvailableContext.Provider
        value={{ cryptoCurrenciesAvailable }}
      >
        <CryptoCurrencyWalletContext.Provider value={{ cryptoCurrencyWallet }}>
          <CryptoCurrencyOptionContext.Provider
            value={{ cryptoCurrencyOption, setCryptoCurrencyOption }}
          >
            <CryptoCurrencyHistoryOptionContext.Provider
              value={{
                historyOption,
                setHistoryOption,
              }}
            >
              <SystemErrorHandler state={state} setState={setState}>
                {children}
              </SystemErrorHandler>
            </CryptoCurrencyHistoryOptionContext.Provider>
          </CryptoCurrencyOptionContext.Provider>
        </CryptoCurrencyWalletContext.Provider>
      </CryptoCurrenciesAvailableContext.Provider>
    </RequestStateContext.Provider>
  );
};
export default CryptoCurrencyWalletViewProvider;

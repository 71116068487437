import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { Container } from './style';
import Margin from '../../../atoms/utils/layout/Margin';

export interface CurrencyExchangeLayoutProps {
  children: React.ReactElement[];
}

const CurrencyExchangeLayout: FC<CurrencyExchangeLayoutProps> = ({
  children,
}: CurrencyExchangeLayoutProps) => {
  const elements = ['label', 'currency', 'rate'];
  const [label, currency, rate] = getElementsFromKeys(children, elements);

  return (
    <Container>
      {label}
      <Margin left={20}>{currency}</Margin>
      <Margin left={10}>{rate}</Margin>
    </Container>
  );
};
export default CurrencyExchangeLayout;

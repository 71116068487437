import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import ContractTimeDepositContext from '../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDepositContext';
import ContractTimeDepositRenewalReservationCreateContext from '../../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationCreateProvider/ContractTimeDepositRenewalReservationCreateContext';

// Style
import {
  DescriptionStyle,
  OrderIdLabelStyle,
  OrderIdStyle,
  SectionTitleStyle,
} from './style';

// Component
import { TextPrimary, TextSecondary } from '../../../atoms/text2/Text2';
import HeaderContractTimeDepositRenewalReservationAdd from '../../../organisms/header/HeaderContentTitle/contract/HeaderContractTimeDepositRenewalReservationAdd';
import ProgressStepBarBankTransaction from '../../../molecules/progressStep/ProgressStepBar/ProgressStepBarBankTransaction';
import FormContractTimeDepositRenewalReservation from '../../../organisms/form/contract/contractTimeDeposit/FormContractTimeDepositRenewalReservation';
import AttentionMessageBox from '../../../organisms/notification/AttentionMessageBox';
import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';
import PropertyListContractTimeDeposit from '../../../organisms/propertyList/contract/PropertyListContractTimeDeposit';
import ContractTimeDepositRenewalReservationAddTemplateLayout from './ContractTimeDepositRenewalReservationAddTemplateLayout';

const TEMPLATE_ID =
  'templates.contract.ContractTimeDepositRenewalReservationAddTemplate';

const ContractTimeDepositRenewalReservationAddTemplate: FC = () => {
  const { t } = useTranslation();
  const { contractTimeDeposit } = useContext(ContractTimeDepositContext);

  // Method
  const { createContractTimeDepositRenewalReservation } = useContext(
    ContractTimeDepositRenewalReservationCreateContext,
  );

  return (
    <ContractTimeDepositRenewalReservationAddTemplateLayout>
      <HeaderContractTimeDepositRenewalReservationAdd key="header" />
      <AttentionMessageBox key="attention">
        <TextSecondary theme={DescriptionStyle}>
          <LineBreakReplaced text={t(`${TEMPLATE_ID}.attentionMessage`)} />
        </TextSecondary>
      </AttentionMessageBox>
      <ProgressStepBarBankTransaction key="progressStep" currentStep="input" />
      <TextPrimary key="section1" theme={SectionTitleStyle}>
        {t(`${TEMPLATE_ID}.section.currentContract`)}
      </TextPrimary>
      <TextPrimary key="orderIdLabel" theme={OrderIdLabelStyle}>
        {t(`${TEMPLATE_ID}.label.orderId`)}
      </TextPrimary>
      <TextPrimary key="orderIdValue" theme={OrderIdStyle}>
        {contractTimeDeposit?.getFormattedSerialNumber() || ''}
      </TextPrimary>
      <PropertyListContractTimeDeposit key="propertyList" />
      <TextPrimary key="section2" theme={SectionTitleStyle}>
        {t(`${TEMPLATE_ID}.section.changeOfContract`)}
      </TextPrimary>
      <FormContractTimeDepositRenewalReservation
        key="form"
        onSubmit={createContractTimeDepositRenewalReservation}
      />
    </ContractTimeDepositRenewalReservationAddTemplateLayout>
  );
};
export default ContractTimeDepositRenewalReservationAddTemplate;

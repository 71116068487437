import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  CherryVisa01ProofOfReceiptProps,
  CherryVisa01ProofOfReceiptPropsFormat,
} from './CherryVisa01ProofOfReceipt.type';

// ValueObject
import CherryVisa01ProofOfReceipt from './CherryVisa01ProofOfReceipt';

class CherryVisa01ProofOfReceiptFactory {
  static create = (props: CherryVisa01ProofOfReceiptProps) => {
    const result = Joi.object(CherryVisa01ProofOfReceiptPropsFormat).validate(
      props,
    );

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: CherryVisa01ProofOfReceipt.name,
          reason: result.error.details[0].message,
        },
      );

    return new CherryVisa01ProofOfReceipt(props);
  };
}

export default CherryVisa01ProofOfReceiptFactory;

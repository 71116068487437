import fontWeight from '../../../../../atoms/utils/const/fontWeight';
import color from '../../../../../atoms/utils/const/color';

export const LabeledCurrencyAmountStyle = {
  label: {
    fontSize: 24,
    fontWeight: fontWeight.regular,
    color: color.text.primary,
    opacity: 0.5,
  },
  currency: {
    fontSize: 31,
    fontWeight: fontWeight.bold,
    color: color.text.primary,
    opacity: 0.5,
  },
  amount: {
    fontSize: 31,
    fontWeight: fontWeight.bold,
    color: color.text.primary,
  },
};

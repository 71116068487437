/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Type
import { FormInputContractTimeDepositRenewalReservation } from '../../../../../components/organisms/form/contract/contractTimeDeposit/FormContractTimeDepositRenewalReservation/FormContractTimeDepositRenewalReservation.type';

// Constant
import { INITIAL } from '../../../../../config/constants/requestState';

// DomainObject
import ContractTimeDeposit from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';
import TimeDeposit from '../../../../../domain/product/timeDeposit/TimeDeposit/TimeDeposit';

export interface ContractTimeDepositRenewalReservationUpdateContextProps {
  requestState: string;

  updateContractTimeDepositRenewalReservation: (
    params: FormInputContractTimeDepositRenewalReservation,
    timeDeposit: TimeDeposit,
    callback?: () => void,
  ) => void;

  executeUpdateContractTimeDepositRenewalReservation: (
    code: string,
    callback: () => void,
  ) => Promise<void>;
}

const ContractTimeDepositRenewalReservationUpdateContext = React.createContext<
  ContractTimeDepositRenewalReservationUpdateContextProps
>({
  requestState: INITIAL,

  updateContractTimeDepositRenewalReservation: (
    params: FormInputContractTimeDepositRenewalReservation,
    timeDeposit: TimeDeposit,
    callback?: () => void,
  ) => undefined,

  executeUpdateContractTimeDepositRenewalReservation: (
    code: string,
    callback: () => void,
  ) => new Promise((resolve) => resolve()),
});

export default ContractTimeDepositRenewalReservationUpdateContext;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import SignOutContext from '../../../../enhancers/auth/authenticated/SignOut/SignOutContext';

// Type
import { MenuType } from '../../../../config/menu/main/Menu.type';
import { FooterMenuType } from '../../../../config/menu/footer/Footer.type';

// Constant
import {
  ROUTE_ASSET_SUMMARY,
  ROUTE_INVITATION_CODES,
  ROUTE_KYC_REQUEST,
  ROUTE_MANUAL,
  ROUTE_SETTINGS,
} from '../../../../config/constants/routing';

// Style
import { MenuTextStyle } from './style';

// Component
import MenuIcon, { MenuIconProps } from '../../../atoms/icon/MenuIcon';
import { TextPrimary } from '../../../atoms/text2/Text2';
import MenuItemLayout from './MenuItemLayout';

export type MenuItemProps = MenuIconProps;

const MenuItem: FC<MenuItemProps> = ({ type }: MenuItemProps) => {
  const { t } = useTranslation();
  const { signOut } = useContext(SignOutContext);
  const history = useHistory();
  const location = useLocation();

  const MenuItemContent: FC = () => (
    <MenuItemLayout>
      <MenuIcon key="icon" type={type} />
      <TextPrimary key="menu" theme={MenuTextStyle}>
        {t(`menu.${type}`)}
      </TextPrimary>
    </MenuItemLayout>
  );

  let to = '';
  switch (type) {
    case MenuType.assets:
      to = ROUTE_ASSET_SUMMARY;
      break;
    case MenuType.verification:
      to = ROUTE_KYC_REQUEST;
      break;
    case MenuType.invitations:
      to = ROUTE_INVITATION_CODES;
      break;
    case MenuType.settings:
      to = ROUTE_SETTINGS;
      break;
    case MenuType.manual:
      to = ROUTE_MANUAL;
      break;
    default:
      to = '';
  }

  if (type === FooterMenuType.signOut)
    return (
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          signOut();
        }}
      >
        <MenuItemContent />
      </div>
    );
  return (
    <div
      onClick={() => {
        if (location.pathname === to) history.go(0);
        history.push(to);
      }}
      style={{ width: '100%', cursor: 'pointer' }}
    >
      <MenuItemContent />
    </div>
  );
};

export default MenuItem;

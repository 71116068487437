import React, { FC } from 'react';
import PersonalInformationPage from './PersonalInformationPage';
import PersonalInformationViewProvider from '../../../enhancers/useCase/verification/kycRequest/PersonalInformationViewProvider';
import PersonalInformationSubmitProvider from '../../../enhancers/useCase/verification/kycRequest/KycRequestSubmitProvider/PersonalInformationSubmitProvider';

const EnhancedPersonalInformationPage: FC = () => {
  return (
    <PersonalInformationViewProvider>
      <PersonalInformationSubmitProvider>
        <PersonalInformationPage />
      </PersonalInformationSubmitProvider>
    </PersonalInformationViewProvider>
  );
};

export default EnhancedPersonalInformationPage;

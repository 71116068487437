// IUseCase
import ITransferSavingsAccountCreateUseCase, {
  TransferSavingsAccountCreateParameter,
} from 'enhancers/useCase/transfer/savingsAccount/TransferSavingsAccountCreateProvider/ITransferSavingsAccountCreateUseCase';

// IService
import ITransferSavingsAccountCreateService from 'useCases/transfer/savingsAccount/interface/ITransferSavingsAccountCreateService';
import IAuthenticatorVerifyService from 'useCases/authenticator/authenticator/interface/IAuthenticatorVerifyService';

// IAPI
import ISavingsAccountQueryAPI from 'api/interface/savingsAccount/ISavingsAccountQueryAPI';

// Service
import AuthenticatorVerifyService from 'service/authenticator/AuthenticatorVerifyService';
import TransferSavingsAccountCreateService from 'service/transfer/savingsAccount/TransferSavingsAccountCreateService';

// IRepository
import {
  ITransferSavingsAccountTransactionCreateRepository,
  ITransferSavingsAccountTransactionCreateToECBRepository,
} from 'domain/transaction/transferSavingsAccountTransaction/ITransferSavingsAccountTransactionRepository';

// DomainObject
import UserVerified from 'domain/public/user/User/UserAuthorized/UserVerified/UserVerified';
import TransferSavingsAccountTransactionSeed from 'domain/transaction/transferSavingsAccountTransaction/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed';

class TransferSavingsAccountCreateUseCase
  implements ITransferSavingsAccountCreateUseCase {
  private transferSavingsAccountCreateService: ITransferSavingsAccountCreateService;

  private authenticatorVerifyService: IAuthenticatorVerifyService;

  constructor(
    transferSavingsAccountRepository: ITransferSavingsAccountTransactionCreateRepository,
    transferSavingsAccountECBRepository: ITransferSavingsAccountTransactionCreateToECBRepository,
    savingsAccountQueryAPI: ISavingsAccountQueryAPI,
    authenticatorVerifyService: IAuthenticatorVerifyService,
  ) {
    this.transferSavingsAccountCreateService = new TransferSavingsAccountCreateService(
      transferSavingsAccountRepository,
      transferSavingsAccountECBRepository,
      savingsAccountQueryAPI,
    );

    this.authenticatorVerifyService = new AuthenticatorVerifyService(
      authenticatorVerifyService,
    );
  }

  public create = async (params: TransferSavingsAccountCreateParameter) => {
    const transferSavingsAccountTransactionSeed = await this.transferSavingsAccountCreateService.create(
      params,
    );

    return { transferSavingsAccountTransactionSeed };
  };

  public executeCreate = async (
    user: UserVerified,
    transferSavingsAccountTransactionSeed: TransferSavingsAccountTransactionSeed,
    code: string,
  ) => {
    // Authenticator
    await this.authenticatorVerifyService.verify(user, code);

    // Create TransferSavingsAccount
    const transferSavingsAccountTransaction = await this.transferSavingsAccountCreateService.executeCreate(
      transferSavingsAccountTransactionSeed,
    );

    return { transferSavingsAccountTransaction };
  };
}

export default TransferSavingsAccountCreateUseCase;

import { useState, useEffect, useRef, useMemo } from 'react';

export const useHTMLElementsAdjust = () => {
  /* *
   *
   * Initialization
   *
   * */

  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  const getElementDimension = (ref: React.RefObject<HTMLDivElement>) => {
    if (!ref.current) return { width: 0, height: 0 };

    const { width, height } = ref.current?.getBoundingClientRect();

    return {
      width,
      height,
    };
  };

  const [elementDimension1, setElementDimension1] = useState(
    getElementDimension(ref1),
  );
  const [elementDimension2, setElementDimension2] = useState(
    getElementDimension(ref2),
  );

  /* *
   *
   * Methods (adjustHeightLonger)
   *
   * */

  // Resize
  const onResize = (newHeight: number) => {
    setElementDimension1({
      ...getElementDimension(ref1),
      height: newHeight,
    });
    setElementDimension2({
      ...getElementDimension(ref2),
      height: newHeight,
    });
  };

  /* *
   *
   * Variable
   *
   * */

  const heightLonger = useMemo(() => {
    const dimension1 = getElementDimension(ref1);
    const dimension2 = getElementDimension(ref2);

    return Math.max(dimension1.height, dimension2.height);
    // eslint-disable-next-line
  }, [ref1.current?.clientHeight, ref2.current?.clientHeight]);

  /* *
   *
   * useEffect
   *
   * */
  useEffect(() => {
    window.addEventListener('resize', () => {
      onResize(heightLonger);
    });
    // Destructor
    return () =>
      window.removeEventListener('resize', () => {
        onResize(heightLonger);
      });
    // eslint-disable-next-line
  }, [ref1.current?.clientHeight, ref2.current?.clientHeight]);

  return {
    element1: {
      ref: ref1,
      ...elementDimension1,
    },
    element2: {
      ref: ref2,
      ...elementDimension2,
    },
    heightLonger,
  };
};

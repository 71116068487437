import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import InvitationCodesTemplate from '../../../components/templates/invitations/InvitationCodesTemplate';

const InvitationCodesPage: FC = () => {
  return (
    <DashboardTemplate>
      <InvitationCodesTemplate />
    </DashboardTemplate>
  );
};

export default InvitationCodesPage;

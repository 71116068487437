import React from 'react';
import AssetsCryptoCurrency from './AssetsCryptoCurrency';

export interface AssetsCryptoCurrencyContextProps {
  assetsCryptoCurrency: AssetsCryptoCurrency;
}

const AssetsCryptoCurrencyContext = React.createContext<
  AssetsCryptoCurrencyContextProps
>({
  assetsCryptoCurrency: new AssetsCryptoCurrency(),
});

export default AssetsCryptoCurrencyContext;

import Joi from '@hapi/joi';

// Type
import {
  BankFutureTransactionStatusType,
  BankFutureTransactionType,
} from '../../../../../../types/BankAccountTransaction.type';
import {
  BankTransactionProps,
  BankTransactionPropsFormat,
} from '../../../bankTransaction/BankTransaction/BankTransaction.type';

export interface BankFutureTransactionProps extends BankTransactionProps {
  scheduledDate: string; // YYYY/MM/DD

  scheduledTime: number; // Timestamp

  status: BankFutureTransactionStatusType;
}

export interface BankFutureTransactionRawDataType {
  id: string;

  props: BankFutureTransactionProps & {
    commonId: string;

    type: BankFutureTransactionType;
  };
}

export const BankFutureTransactionPropsFormat = {
  ...BankTransactionPropsFormat,

  scheduledDate: Joi.string()
    .length(10)
    .pattern(/[0-9]{4}\/[0-9]{2}\/[0-9]{2}/)
    .required(),

  scheduledTime: Joi.number().required(),

  status: Object.keys(BankFutureTransactionStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),
};

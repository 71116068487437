// IService
import IContractTimeDepositQueryService from '../../../useCases/contract/contractTimeDeposit/interface/IContractTimeDepositQueryService';

// IRepository
import { IContractTimeDepositQueryRepository } from '../../../domain/contract/contractTimeDeposit/IContractTimeDepositRepository';

class ContractTimeDepositQueryService
  implements IContractTimeDepositQueryService {
  private contractTimeDepositQueryRepository: IContractTimeDepositQueryRepository;

  constructor(
    contractTimeDepositQueryRepository: IContractTimeDepositQueryRepository,
  ) {
    this.contractTimeDepositQueryRepository = contractTimeDepositQueryRepository;
  }

  public findById = async (id: string) => {
    const contractTimeDeposit = this.contractTimeDepositQueryRepository.findById(
      id,
    );

    return contractTimeDeposit;
  };

  public findAllByUserId = async (userId: string) => {
    const contractsTimeDeposit = await this.contractTimeDepositQueryRepository.findAllByUserId(
      userId,
    );
    return contractsTimeDeposit;
  };
}

export default ContractTimeDepositQueryService;

// React
import React, { FC } from 'react';
import { useTable, usePagination } from 'react-table';

// Const
import { HISTORY_TABLE_PAGE_SIZE } from '../../../../../config/constants/business';

// Style
import { ThStyle, ThTextStyle, TdTextStyle } from './style';

// Components
import Pagination from '../../../../atoms/table/pagination/Pagination';
import { TextPrimary, TextSecondary } from '../../../../atoms/text2/Text2';
import Td from '../../../../atoms/table/Table/Td';
import Th from '../../../../atoms/table/Table/Th';
import Tr from '../../../../atoms/table/Table/Tr';
import HistoryTableLayout from './HistoryTableLayout';

export interface HistoryTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { [key: string]: any }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Array<{ Header: string; accessor: any }>;
  width?: string | number;
}

const HistoryTable: FC<HistoryTableProps> = ({
  data,
  columns,
  width,
}: HistoryTableProps) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: HISTORY_TABLE_PAGE_SIZE },
    },
    usePagination,
  );

  const {
    gotoPage,
    headerGroups,
    page,
    pageCount,
    prepareRow,
    state: { pageIndex },
  } = tableInstance;

  const headerGroup = headerGroups[0];
  const thHeight = 68;
  const tdHeight = 70;

  return (
    <HistoryTableLayout pageCount={pageCount} width={width}>
      <thead key="thead">
        <Tr>
          {headerGroup.headers.map((column, j) => (
            <Th
              key={`th${j}`}
              height={thHeight}
              paddingTop={12.63}
              paddingLeft={14.38}
              paddingRight={14.38}
              paddingBottom={12.63}
              textAlign="left"
              verticalAlign="top"
              minWidth={column.minWidth}
              theme={ThStyle}
            >
              <TextPrimary theme={ThTextStyle}>
                {column.render('Header')}
              </TextPrimary>
            </Th>
          ))}
        </Tr>
      </thead>
      <tbody key="tbody">
        {page.map((row, k) => {
          prepareRow(row);
          return (
            <Tr key={`tbodyTr${k}`}>
              {row.cells.map((cell, l) => {
                return (
                  <Td key={`tbodyTd${k}-${l}`} height={tdHeight}>
                    <TextSecondary theme={TdTextStyle}>
                      {cell.render('Cell')}
                    </TextSecondary>
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </tbody>
      <Pagination
        key="pagination"
        pageCount={pageCount}
        gotoPage={gotoPage}
        pageIndex={pageIndex}
      />
    </HistoryTableLayout>
  );
};

export default HistoryTable;

// Type
import { TypeOfT } from '../../../../../types/typeof/Translation.type';

// Lib
import {
  convertMoneyFormat,
  getSymbolFromCurrency,
} from '../../../../../utils/helpers/currencyHelper';

// Value
import { FiatCurrencyType } from '../../../../../types/FiatCurrency.type';

const ORGANISM_ID = `organisms.propertyList.debitCard.PropertyListTransferDebitCardFee`;

class TableTransferDebitCardFeeMapper {
  static domainToTd = (
    maxAmountTotalContractTimeDeposit: number,
    totalToppedUpAmount: number,
    topUpAmountWithoutFee: number,
    currency: FiatCurrencyType,
    t: TypeOfT,
  ) => [
    {
      header: t(`${ORGANISM_ID}.th.maxAmountContractTimeDepositHistory`),
      data: `${getSymbolFromCurrency(currency)} ${convertMoneyFormat(
        maxAmountTotalContractTimeDeposit,
        currency,
      )}`,
    },
    {
      header: t(`${ORGANISM_ID}.th.totalToppedUpAmount`),
      data: `${getSymbolFromCurrency(currency)} ${convertMoneyFormat(
        totalToppedUpAmount,
        currency,
      )}`,
    },
    {
      header: t(`${ORGANISM_ID}.th.topUpAmountWithoutFee`),
      data: `${getSymbolFromCurrency(currency)} ${convertMoneyFormat(
        topUpAmountWithoutFee,
        currency,
      )}`,
    },
  ];
}

export default TableTransferDebitCardFeeMapper;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

// Lib
import { deleteFullWidth } from '../../../../../utils/helpers/stringHelper';
import {
  shippingAddressReceiverRegister,
  shippingAddressPhoneRegister,
} from '../../../../../utils/validation/registers';

// Component
import FormInputAddress, { FormInputAddressKeys } from '../FormInputAddress';
import FormInputText from '../../formInput/textField/FormInputText';
import FormInputPhoneNumber from '../../formInput/textField/FormInputPhoneNumber';
import FormInputShippingAddressLayout from './FormInputShippingAddressLayout';

const MOLECULE_ID = `molecules.formInputGroup.formInputShippingAddress`;

export interface FormInputShippingAddressProps {
  addressKeys: FormInputAddressKeys;
  onChange?: () => void;
}

const FormInputShippingAddress: FC<FormInputShippingAddressProps> = ({
  addressKeys,
  onChange = () => undefined,
}: FormInputShippingAddressProps) => {
  const { t } = useTranslation();
  const { register, setValue, watch } = useFormContext();

  return (
    <FormInputShippingAddressLayout>
      <FormInputAddress
        key="address"
        addressKeys={addressKeys}
        onChange={onChange}
      />
      <FormInputText
        key="receiverName"
        inputWidth={400}
        label={t(`${MOLECULE_ID}.receiverName.label`)}
        maxLength={50}
        name="receiverName"
        onChange={(text: string) => {
          onChange();
          setValue('receiverName', deleteFullWidth(text));
        }}
        placeholder={t(`${MOLECULE_ID}.receiverName.placeholder`)}
        register={register(shippingAddressReceiverRegister(t))}
      />
      <FormInputPhoneNumber
        key="phoneNumber"
        label={t(`${MOLECULE_ID}.phoneNumber.label`)}
        inputWidth={400}
        onChange={(text: string) => {
          onChange();
          setValue('phoneNumber', deleteFullWidth(text));
        }}
        name={{ phoneNumber: 'phoneNumber', countryCode: 'countryCode' }}
        register={register(
          shippingAddressPhoneRegister(t, { watch: watch('countryCode') }),
        )}
      />
    </FormInputShippingAddressLayout>
  );
};

export default FormInputShippingAddress;

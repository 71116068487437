import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  KycStepStatusCorporateProps,
  KycStepStatusCorporatePropsFormat,
  KycStepStatusCorporateInitialProps,
} from './KycStepStatusCorporate.type';

// DomainObject
import KycStepStatusCorporate from './KycStepStatusCorporate';

class KycStepStatusCorporateFactory {
  static create = (props: KycStepStatusCorporateProps) => {
    const result = Joi.object(KycStepStatusCorporatePropsFormat).validate(
      props,
    );

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: KycStepStatusCorporate.name,
          reason: result.error.details[0].message,
        },
      );

    return new KycStepStatusCorporate(props);
  };

  static createInitial = () => {
    return KycStepStatusCorporateFactory.create(
      KycStepStatusCorporateInitialProps,
    );
  };
}

export default KycStepStatusCorporateFactory;

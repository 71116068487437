export type CryptoCurrencyTransactionType =
  | CryptoCurrencySellType
  | CryptoCurrencyBuyType
  | CryptoCurrencyDepositType
  | CryptoCurrencyWithdrawalType;

export enum CryptoCurrencySellType {
  cryptoCurrencySell = 'cryptoCurrencySell',
}

export enum CryptoCurrencyBuyType {
  cryptoCurrencyBuy = 'cryptoCurrencyBuy',
}

export enum CryptoCurrencyDepositType {
  cryptoCurrencyDeposit = 'cryptoCurrencyDeposit',
}

export enum CryptoCurrencyWithdrawalType {
  cryptoCurrencyWithdrawal = 'cryptoCurrencyWithdrawal',
  cryptoCurrencyCustodyFee = 'cryptoCurrencyCustodyFee',
}

export enum CryptoCurrencyTransactionStatusType {
  unconfirmed = 'unconfirmed',
  confirmed = 'confirmed',
  canceled = 'canceled',
}

import React, { FC, useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

// Lib
import { isBackSpace } from '../../../../utils/helpers/keyBoardHelper';

// Style
import { StyledTextInput } from './style';

export interface OneDigitInputProps {
  name: string;
  digit: number;
  type?: '' | 'password';
  position: number;
  currentPosition: number;
  onChange?: (name: string, codeNumber: string, position: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any;
}

const OneDigitInput: FC<OneDigitInputProps> = ({
  name,
  type = '',
  currentPosition,
  onChange = () => {
    return null;
  },
  register,
  position,
}: OneDigitInputProps) => {
  let textInput: HTMLElement | null = null;

  const { setValue } = useFormContext();

  useEffect(() => {
    if (position === currentPosition) (textInput as HTMLElement).focus();
    // eslint-disable-next-line
  }, [position, currentPosition]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (isBackSpace(e.keyCode)) {
        setValue(name, '');
        setValue('currentPosition', position - 1);
      }
    },
    // eslint-disable-next-line
    [],
  );

  return (
    <StyledTextInput
      name={name}
      id={`test${position}`}
      type={type}
      onChange={(e) => onChange(name, e.target.value, position)}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyDown(e)}
      maxLength={1}
      ref={(input) => {
        register(input);
        textInput = input;
      }}
    />
  );
};

export default OneDigitInput;

import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessHandlerUserStatusProvider from '../enhancers/routing/AccessHandlerUserStatusProvider';
import AuthenticatedPageVewProvider from '../enhancers/useCase/authentication/AuthenticatedPageViewProvider';

// Type
import { MenuType } from '../config/menu/main/Menu.type';

// Const
import { ALLOWED_USER_STATUS } from './accessHandler/userStatusPageAccessMapper';
import { ROUTE_DEBIT_CARD_REQUEST } from '../config/constants/routing';

// Component
import { EnhancedDebitCardRequestPage } from '../containers/debitCardRequest/DebitCardRequestPage';

const DebitCardRequestRouter: FC = () => {
  return (
    <Switch>
      <AuthenticatedPageVewProvider>
        <AccessHandlerUserStatusProvider
          statusAllowed={ALLOWED_USER_STATUS[MenuType.debitCards]}
        >
          <Route exact path={ROUTE_DEBIT_CARD_REQUEST}>
            <EnhancedDebitCardRequestPage />
          </Route>
        </AccessHandlerUserStatusProvider>
      </AuthenticatedPageVewProvider>
    </Switch>
  );
};

export default DebitCardRequestRouter;

import React, { FC } from 'react';

// Lib
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface ContractTimeDepositCardAmountInformationLayoutProps {
  children: React.ReactElement[];
}

const ContractTimeDepositCardAmountInformationLayout: FC<ContractTimeDepositCardAmountInformationLayoutProps> = ({
  children,
}: ContractTimeDepositCardAmountInformationLayoutProps) => {
  const elements = ['depositAmount', 'amountIncreased'];

  const [depositAmount, amountIncreased] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn height="auto" justifyContent="center">
      {depositAmount}
      <Margin top={18} />
      {amountIncreased}
    </FlexBoxColumn>
  );
};

export default ContractTimeDepositCardAmountInformationLayout;

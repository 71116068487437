import Joi from '@hapi/joi';

// Type
import {
  BankTransactionProps,
  BankTransactionPropsFormat,
} from '../../../bankTransaction/BankTransaction/BankTransaction.type';
import { BankSpotTransactionType } from '../../../../../../types/BankAccountTransaction.type';

export interface BankSpotTransactionProps extends BankTransactionProps {
  balance: number;
}

export interface BankSpotTransactionRawDataType {
  id: string;

  props: BankTransactionProps & {
    commonId: string;

    type: BankSpotTransactionType;
  };
}

export const BankSpotTransactionPropsFormat = {
  ...BankTransactionPropsFormat,

  balance: Joi.number().required(),
};

// React
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Lib
import { convertMoneyFormat } from '../../../../../utils/helpers/currencyHelper';

// Type
import { CryptoCurrencyType } from '../../../../../types/CryptoCurrency.type';

// Components
import CurrencyAmount from '../../../textGroup/CurrencyAmount';
import { TextSecondary, TextThemeProps } from '../../../../atoms/text2/Text2';
import CryptoCurrencyDepositResultCardLayout from './CryptoCurrencyDepositResultCardLayout';

export interface CryptoCurrencyDepositResultCardProps {
  currency: CryptoCurrencyType;
  depositAmount: number;
  fee: number;
  marginBetweenCurrencyAmount: number | string;
  marginBetweenLabelAmount: number | string;
  theme: {
    label: TextThemeProps;
    currency: TextThemeProps;
    amount: TextThemeProps;
  };
}

const MOLECULE_ID = `molecules.card.CryptoCurrencyDepositResultCard`;

const CryptoCurrencyDepositResultCard: FC<CryptoCurrencyDepositResultCardProps> = ({
  currency,
  depositAmount,
  fee,
  marginBetweenCurrencyAmount,
  marginBetweenLabelAmount,
  theme,
}: CryptoCurrencyDepositResultCardProps) => {
  const { t } = useTranslation();
  return (
    <CryptoCurrencyDepositResultCardLayout
      marginBetween={marginBetweenLabelAmount}
    >
      <TextSecondary key="amountLabel" theme={theme.label}>
        {t(`${MOLECULE_ID}.label.amount`)}
      </TextSecondary>
      <CurrencyAmount
        key="amount"
        amount={convertMoneyFormat(depositAmount, currency)}
        currency={currency}
        marginBetween={marginBetweenCurrencyAmount}
        theme={theme}
      />
      <TextSecondary key="feeLabel" theme={theme.label}>
        {t(`${MOLECULE_ID}.label.fee`)}
      </TextSecondary>
      <CurrencyAmount
        key="fee"
        amount={convertMoneyFormat(fee, currency)}
        currency={currency}
        marginBetween={marginBetweenCurrencyAmount}
        theme={theme}
      />
    </CryptoCurrencyDepositResultCardLayout>
  );
};

export default CryptoCurrencyDepositResultCard;

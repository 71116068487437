import Joi from '@hapi/joi';
import CryptoCurrencyTransactionId from '../../../../../../idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionId';
import CryptoCurrencyDeposit from './CryptoCurrencyDeposit';
import {
  CryptoCurrencyDepositProps,
  CryptoCurrencyDepositPropsFormat,
} from './CryptoCurrencyDeposit.type';

// Error
import InvalidDataFoundError from '../../../../../../../utils/errors/InvalidDataFoundError';

class CryptoCurrencyDepositFactory {
  static create = (
    cryptoCurrencyTransactionId: CryptoCurrencyTransactionId,
    props: CryptoCurrencyDepositProps,
  ) => {
    const result = Joi.object(CryptoCurrencyDepositPropsFormat).validate({
      ...props,
    });

    if (result.error)
      throw new InvalidDataFoundError(
        `Creation of CryptoCurrencyDeposit is rejected. The reason is ${result.error.details[0].message}`,
      );

    return new CryptoCurrencyDeposit(cryptoCurrencyTransactionId, props);
  };
}

export default CryptoCurrencyDepositFactory;

import Joi from '@hapi/joi';

// Type
import {
  CryptoCurrencySellTransactionProps,
  CryptoCurrencySellTransactionPropsFormat,
} from './CryptoCurrencySellTransaction.type';

// Error
import InvalidDataFoundError from '../../../../../../../utils/errors/InvalidDataFoundError';

// DomainObject
import CryptoCurrencySellTransaction from './CryptoCurrencySellTransaction';
import CryptoCurrencyTransactionId from '../../../../../../idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionId';

class CryptoCurrencySellTransactionFactory {
  static create = (
    cryptoCurrencyTransactionId: CryptoCurrencyTransactionId,
    props: CryptoCurrencySellTransactionProps,
  ) => {
    const result = Joi.object(
      CryptoCurrencySellTransactionPropsFormat,
    ).validate(props);

    if (result.error)
      throw new InvalidDataFoundError(
        `Creation of CryptoCurrencySellTransaction is rejected. The reason is ${result.error.details[0].message}`,
      );

    return new CryptoCurrencySellTransaction(
      cryptoCurrencyTransactionId,
      props,
    );
  };
}

export default CryptoCurrencySellTransactionFactory;

import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const QrCodeContainerStyle = {
  border: `${1 / 16}rem solid #d6ddec`,
};

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  lineHeight: 44,
};

export const AccountKeyStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const AccountKeyContainerStyle = {
  border: `${1 / 16}rem solid #d6ddec`,
};

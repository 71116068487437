import firestore from '../../../client/firestoreClient';
import Collection from '../../collection/Collection';
import {
  FirestoreDomain,
  CollectionName,
} from '../../collection/Collection.type';
import { FIRESTORE_DATA_VERSION } from '../../../../../../config/constants/firebase';

class MaintenancesCollection extends Collection {
  collection: firestore.CollectionReference;

  constructor() {
    super();
    this.collection = firestore()
      .collection(FirestoreDomain.settings)
      .doc(FIRESTORE_DATA_VERSION)
      .collection(CollectionName.maintenances);
  }
}

export default MaintenancesCollection;

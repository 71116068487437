// Type
import { TransferDebitCardStatusType } from '../../../types/Transfer.type';

// IUseCase
import { TransferDebitCardCreateParameter } from '../../../enhancers/useCase/transfer/debitCard/TransferDebitCardCreateProvider/ITransferDebitCardCreateUseCase';

// IService
import ITransferDebitCardTransactionV2CreateService from '../../../useCases/transfer/debitCard/ITransferDebitCardTransactionV2CreateService';

// IRepository
import { ITransferDebitCardTransactionV2CreateRepository } from '../../../domain/transaction/transferDebitCardTransaction/ITransferDebitCardTransactionV2Repository';

// Domain
import TransferDebitCardTransactionV2SeedFactory from '../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2Seed/TransferDebitCardTransactionV2SeedFactory';
import TransferDebitCardTransactionV2Seed from '../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2Seed/TransferDebitCardTransactionV2Seed';

class TransferDebitCardTransactionV2CreateService
  implements ITransferDebitCardTransactionV2CreateService {
  private transferDebitCardTransactionV2CreateRepository: ITransferDebitCardTransactionV2CreateRepository;

  constructor(
    transferDebitCardTransactionV2CreateRepository: ITransferDebitCardTransactionV2CreateRepository,
  ) {
    this.transferDebitCardTransactionV2CreateRepository = transferDebitCardTransactionV2CreateRepository;
  }

  public create = (params: TransferDebitCardCreateParameter) => {
    const {
      debitCard,
      savingsAccount,
      amount,
      fee,
      transferDebitCardType,
    } = params;

    const { currency } = savingsAccount.getProps();

    const transferDebitCardTransactionSeed = TransferDebitCardTransactionV2SeedFactory.create(
      {
        debitCardId: debitCard.getId(),
        savingsAccountNumber: savingsAccount.getRawAccountNumber(),
        amount,
        transferType: transferDebitCardType,
        currency,
        fee,
        maxTotalTimeDeposit: 0, // tmpValue
        totalToppedUpAmount: 0, // tmpValue
        commonId: 'tmpCommonId123456789',
        status: TransferDebitCardStatusType.unconfirmed,
      },
    );

    return transferDebitCardTransactionSeed;
  };

  public executeCreate = async (
    transferDebitCardTransactionSeed: TransferDebitCardTransactionV2Seed,
  ) => {
    const transferDebitCardTransaction = await this.transferDebitCardTransactionV2CreateRepository.create(
      transferDebitCardTransactionSeed,
    );

    return transferDebitCardTransaction;
  };
}

export default TransferDebitCardTransactionV2CreateService;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ConsentFormContext from '../../../../domain/verification/consentForm/ConsentForm/ConsentFormContext';

// Component
import FormConsentForm from '../../../organisms/form/verification/consentForm/FormConsentForm';
import KycStepTemplate from '../KycStepTemplate';

const TEMPLATE_ID = `templates.verification.ConsentFormTemplate`;

const ConsentFormTemplate: FC = () => {
  const { t } = useTranslation();
  const { consentForm } = useContext(ConsentFormContext);

  // First Level Property
  const consentFormContent = consentForm?.getConsentFormContent();
  const consentFormStepStatus = consentForm?.getConsentFormStepsStatus();

  // Second Level Property
  const rejectedReason = consentFormContent?.getProps().rejectedReason;
  const status = consentFormStepStatus?.getProps().statusConsentForm;

  return (
    <KycStepTemplate
      status={status}
      title={t(`${TEMPLATE_ID}.title`)}
      rejectedReason={rejectedReason}
    >
      <FormConsentForm consentFormContent={consentFormContent} />
    </KycStepTemplate>
  );
};

export default ConsentFormTemplate;

import React, { FC } from 'react';
import TextPrimary from './TextPrimary';
import fontWeight from '../../../../fonts/fontWeight.json';
import fontSize from '../../../../fonts/fontSizePixel.json';

export interface TextPrimaryRegular21Props {
  children: React.ReactNode;
  color: string;
}

const TextPrimaryRegular21: FC<TextPrimaryRegular21Props> = ({
  children,
  color,
}: TextPrimaryRegular21Props) => {
  const pixel = fontSize.pixel21;
  const textFontWeight = fontWeight.regular;

  return (
    <TextPrimary pixel={pixel} color={color} fontWeight={textFontWeight}>
      {children}
    </TextPrimary>
  );
};
export default TextPrimaryRegular21;

export type DebitCardSpotTransactionType =
  | DebitCardDepositType
  | DebitCardWithdrawalType;

export enum DebitCardDepositType {
  chargeFromSavingsAccount = 'chargeFromSavingsAccount',
  cherryDepositCapture = 'cherryDepositCapture',
  cherryDepositRefund = 'cherryDepositRefund',
  cherryDepositInternalReversal = 'cherryDepositInternalReversal',
}

export enum DebitCardWithdrawalType {
  withdrawToSavingsAccount = 'withdrawToSavingsAccount',
  cherryWithdrawalAnnualFee = 'cherryWithdrawalAnnualFee',
  cherryWithdrawalCapture = 'cherryWithdrawalCapture',
  cherryWithdrawalFee = 'cherryWithdrawalFee',
  cherryWithdrawalRefund = 'cherryWithdrawalRefund',
}

export enum DebitCardTransactionStatusType {
  unconfirmed = 'unconfirmed',
  confirmed = 'confirmed',
  canceled = 'canceled',
  rejected = 'rejected',
}

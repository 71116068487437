// IService
import IResignUpRequestUpdateService from '../../../useCases/authentication/resignUp/IResignUpRequestUpdateService';

// IRepository
import { IResignUpRequestUpdateRepository } from '../../../domain/userRequest/resignUpRequest/IResignUpRequestRepository';

class ResignUpRequestUpdateService implements IResignUpRequestUpdateService {
  private resignUpRequestUpdateRepository: IResignUpRequestUpdateRepository;

  constructor(
    resignUpRequestUpdateRepository: IResignUpRequestUpdateRepository,
  ) {
    this.resignUpRequestUpdateRepository = resignUpRequestUpdateRepository;
  }

  public executeConfirm = async (
    oobCode: string,
    password: string,
    code: string,
  ) => {
    await this.resignUpRequestUpdateRepository.confirm(oobCode, password, code);
  };
}

export default ResignUpRequestUpdateService;

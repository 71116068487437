import styled from 'styled-components';
import Box from '../../../atoms/utils/layout/Box/Box';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  position: relative;
`;

export const TextInputContainer = styled(Box)``;

export const ErrorMessageContainer = styled.div`
  position: absolute;
  top: ${85 / 16}rem;
  display: flex;
  align-items: center;
  display: overflow;
`;

export const ErrorMessageStyle = {
  fontSize: 18,
  color: color.errorMessage,
  fontWeight: fontWeight.regular,
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxRow } from '../../../atoms/utils/layout/Box/FlexBox';

export interface HeaderContentTitleLayoutProps {
  children: React.ReactElement[];
}

const HeaderContentTitleLayout: FC<HeaderContentTitleLayoutProps> = ({
  children,
}: HeaderContentTitleLayoutProps) => {
  const elements = ['left', 'right'];
  const [left, right] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow
      alignItems="center"
      justifyContent="space-between"
      height="auto"
    >
      {left}
      {right}
    </FlexBoxRow>
  );
};
export default HeaderContentTitleLayout;

import firestore from '../../../../client/firestoreClient';
import Collection from '../../../collection/Collection';
import { FIRESTORE_DATA_VERSION } from '../../../../../../../config/constants/firebase';

class BTCWalletsCollection extends Collection {
  collection: firestore.CollectionReference;

  constructor(userId: string) {
    super();
    this.collection = firestore()
      .collection('cryptoCurrency')
      .doc(FIRESTORE_DATA_VERSION)
      .collection('wallets')
      .doc(userId)
      .collection('BTCWallets');
  }
}

export default BTCWalletsCollection;

import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Context
import ContractTimeDepositCreateContext from '../../../../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositCreateProvider/ContractTimeDepositCreateContext';
import ContractTimeDepositAddPageStateContext from '../../../../../../enhancers/pageState/contract/ContractTimeDepositAddPageState/ContractTimeDepositAddPageStateContext';
import SavingsAccountsContext from '../../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccountsContext';
import TimeDepositsContext from '../../../../../../domain/product/timeDeposit/TimeDeposits/TimeDepositsContext';

// Lib
import { getDepositAmount } from './helper';
import { contractTimeDepositAddValidation } from '../../../../../../utils/validation/registers';

// Type
import {
  FormInputContractTimeDepositAdd,
  FormInputContractTimeDepositAddContext,
} from './FormInputContractTimeDepositAdd.type';

// Constant
import { BASE_CURRENCY_SYMBOL } from '../../../../../../config/constants/business';

// Style
import {
  ErrorMessageStyle,
  LabelStyle,
  RadioButtonLayout,
  RadioButtonStyle,
} from './style';

// Component
import {
  ConfirmButton,
  DefaultButtonTextStyle,
} from '../../../../../atoms/button/Button2';
import { TextPrimary, TextSecondary } from '../../../../../atoms/text2/Text2';
import { FormInputAuthenticatorType } from '../../../../../molecules/form/formInput/textField/FormInputAuthenticator/FormInputAuthenticator.type';
import FormInputSavingsAccountSelectBox from '../../../../../molecules/form/formInput/selectBox/bankAccount/FormInputSavingsAccountSelectBox';
import FormInputRadioButton from '../../../../../molecules/form/formInput/radioButton/FormInputRadioButton';
import FormInputTimeDepositFilteredSelectBox from '../../../../../molecules/form/formInput/selectBox/product/FormInputTimeDepositFilteredSelectBox';
import FormInputCurrencyAmount from '../../../../../molecules/form/formInput/textField/FormInputCurrencyAmount';
import FormContractTimeDepositAddLayout from './FormContractTimeDepositAddLayout';

// DomainObject
import TimeDeposits from '../../../../../../domain/product/timeDeposit/TimeDeposits/TimeDeposits';

const FORM_ID = `organisms.form.contract.FormContractTimeDepositAdd`;

const FormContractTimeDepositAdd: FC = () => {
  const { t } = useTranslation();
  const { formInput, setFormInput } = useContext(
    FormInputContractTimeDepositAddContext,
  );

  // PageState
  const { pageState, setPageState } = useContext(
    ContractTimeDepositAddPageStateContext,
  );

  // Method
  const { createContractTimeDeposit } = useContext(
    ContractTimeDepositCreateContext,
  );

  // Options
  const { savingsAccounts } = useContext(SavingsAccountsContext);
  const { timeDeposits } = useContext(TimeDepositsContext);

  /**
   *
   *  React Hook Form
   *
   */

  const methods = useForm<
    FormInputContractTimeDepositAdd & FormInputAuthenticatorType
  >({
    mode: 'onChange',
  });

  const {
    clearErrors,
    errors,
    formState: { isValid },
    handleSubmit,
    setError,
    setValue,
    register,
    reset,
    trigger,
    watch,
  } = methods;

  /**
   *
   *  DomainObject
   *
   */
  const savingsAccount = savingsAccounts.filterById(
    watch(`savingsAccountNumber`),
  );

  const timeDeposit = timeDeposits.filterById(watch(`timeDepositId`));

  const timeDepositsFiltered = useMemo(
    () =>
      timeDeposits.filterByIsAnnualPayOut(
        watch('isAnnualPayOut') === 'true',
      ) as TimeDeposits,
    // eslint-disable-next-line
    [watch('isAnnualPayOut'), timeDeposits],
  );

  /**
   *
   *  Dependent value
   *
   */

  // Minimum Deposit in all products for the user
  const userMinDeposit = useMemo(() => timeDepositsFiltered.getMinDeposit(), [
    timeDepositsFiltered,
  ]);

  /**
   *
   *  Default Variable
   *
   */

  // Set DefaultValue
  useEffect(() => {
    if (formInput) reset(formInput);
    // eslint-disable-next-line
  }, [formInput, pageState]);

  // SavingsAccountNumber
  useEffect(() => {
    if (!formInput)
      setValue(
        `savingsAccountNumber`,
        savingsAccounts.head()?.getRawAccountNumber(),
      );
    // eslint-disable-next-line
  }, [savingsAccounts]);

  // TimeDeposit
  useEffect(() => {
    setValue(`timeDepositId`, timeDepositsFiltered.head()?.getId());
    // eslint-disable-next-line
  }, [timeDepositsFiltered]);

  /**
   *
   *  Error Handling
   *
   */

  // Balance validation when user changes Savings Account.
  useEffect(() => {
    if (watch('amount')) trigger(['amount']);

    if (savingsAccount && userMinDeposit) {
      if (savingsAccount.getProps().balance < userMinDeposit)
        setError('savingsAccountNumber', {
          type: 'not_enough_balance',
          message: t(`validation.enoughBalance`),
        });
      else clearErrors('savingsAccountNumber');
    }
    // eslint-disable-next-line
  }, [savingsAccount, userMinDeposit]);

  /**
   *
   *  Event
   *
   */

  const onSubmit = useCallback(
    async (input: FormInputContractTimeDepositAdd) => {
      setFormInput(input);

      if (savingsAccount && timeDeposit) {
        createContractTimeDeposit(input, savingsAccount, timeDeposit, () => {
          setPageState('confirm');
        });
      }
    },
    // eslint-disable-next-line
    [savingsAccount, timeDeposit, createContractTimeDeposit],
  );

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <FormContractTimeDepositAddLayout>
          <FormInputSavingsAccountSelectBox
            key="savingsAccount"
            name="savingsAccountNumber"
            labelProps={{
              label: t(`${FORM_ID}.label.savingsAccount`),
              theme: LabelStyle,
            }}
            inputWidth={600}
          />
          <FormInputRadioButton
            key="payOut"
            labelWidth={440}
            labelProps={{
              label: t(`${FORM_ID}.label.isAnnualPayOut`),
              theme: LabelStyle,
            }}
            radioButtonProps={{
              displayItems: [
                t(`${FORM_ID}.payOut.maturity`),
                t(`${FORM_ID}.payOut.annual`),
              ],
              items: ['false', 'true'],
              layout: RadioButtonLayout,
              name: 'isAnnualPayOut',
              selected: watch('isAnnualPayOut') === 'true' ? 'true' : 'false',
              theme: RadioButtonStyle,
            }}
          />
          <FormInputTimeDepositFilteredSelectBox
            key="timeDeposit"
            name="timeDepositId"
            labelProps={{
              label: t(`${FORM_ID}.label.timeDeposit`),
              theme: LabelStyle,
            }}
            inputWidth={600}
            timeDeposits={timeDepositsFiltered}
          />
          <TextSecondary key="savingsAccountError" theme={ErrorMessageStyle}>
            {errors?.savingsAccountNumber?.message}
          </TextSecondary>
          <FormInputCurrencyAmount
            key="amount"
            currency={BASE_CURRENCY_SYMBOL}
            inputWidth={534}
            label={t(`${FORM_ID}.label.amount`)}
            name="amount"
            onChange={(value: string) => {
              setValue('amount', getDepositAmount(value));
              trigger(['amount']);
            }}
            register={register(
              contractTimeDepositAddValidation(t).amount(
                timeDeposit?.getProps().minDeposit, // minDeposit
                savingsAccount?.getProps().balance, // balance
              ),
            )}
          />
          <ConfirmButton
            key="button"
            width={'100%'}
            height={75}
            disabled={!isValid}
          >
            <TextPrimary theme={DefaultButtonTextStyle}>
              {t(`atoms.button.confirm`)}
            </TextPrimary>
          </ConfirmButton>
        </FormContractTimeDepositAddLayout>
      </FormProvider>
    </form>
  );
};

export default FormContractTimeDepositAdd;

import React, { FC, useContext } from 'react';
import SavingsAccountsContext from '../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccountsContext';
import SavingsAccountOptionContext from '../../../selectBox/option/option/SavingsAccountOption/SavingsAccountOptionContext';

// Mapper
import DomainObjectMapper from '../../../selectBox/option/option/DomainOptionsMapper';

// LayoutComponent
import { FlexBoxRow } from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';

// DomainObject
import SavingsAccount from '../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';
import SavingsAccountTabItem from '../../../item/TabItem/SavingsAccountTabItem';

export interface SavingsAccountTabBarProps {
  height?: string | number;
}

const SavingsAccountTabBar: FC<SavingsAccountTabBarProps> = ({
  height = 75,
}: SavingsAccountTabBarProps) => {
  const { savingsAccounts } = useContext(SavingsAccountsContext);
  const { savingsAccountOption, setSavingsAccountOption } = useContext(
    SavingsAccountOptionContext,
  );

  if (!savingsAccounts) return <></>;

  const options: SavingsAccount[] = DomainObjectMapper.savingsAccountsToTabItem(
    savingsAccounts,
  );

  return (
    <FlexBoxRow height={height}>
      {options.map((item, i) => {
        return (
          <FlexBoxRow width="auto" key={`item${i}`}>
            <SavingsAccountTabItem
              item={item}
              onClick={() => {
                setSavingsAccountOption(item);
              }}
              selected={savingsAccountOption!}
              width={185}
            />
            <Margin right={10} />
          </FlexBoxRow>
        );
      })}
    </FlexBoxRow>
  );
};

export default SavingsAccountTabBar;

// Type
import { UserType } from '../../../../types/User.type';

// DomainObjects
import DomainObjects from '../../../interface/Iterator/DomainObjects';
import TimeDeposit from '../TimeDeposit/TimeDeposit';
import UserVerified from '../../../public/user/User/UserAuthorized/UserVerified/UserVerified';

class TimeDeposits extends DomainObjects<TimeDeposit> {
  public filterById = (id: string) => {
    const filtered = this.items.filter((item) => item.getId() === id);
    return filtered[0];
  };

  public filterByUser = (user: UserVerified) => {
    const { type } = user.getProps();

    // Filter by Rank
    const generalProducts = this.items.filter((item) =>
      item.isMatchUser(type, user.getRawRank()),
    );

    // Filter by VIPFlag
    const vipProducts = !user.isVIPUser()
      ? []
      : this.items.filter((item) => item.isMatchVIPUser(type));

    // Filter by LimitedProducts
    const limitedProducts = this.items.filter((item) => item.isLimited(user));

    return new TimeDeposits(
      generalProducts.concat(vipProducts).concat(limitedProducts),
    );
  };

  public getBestRateProduct = (
    term: number,
    amount: number,
    userType: UserType,
    isAnnualPayOut: boolean,
  ) => {
    const filtered = this.items.filter((item) => {
      const props = item.getProps();

      return (
        props.term === term &&
        props.userType === userType &&
        props.minDeposit <= amount &&
        props.isAnnualPayOut === isAnnualPayOut
      );
    });

    const sorted = filtered.sort((a, b) =>
      a.getProps().interestRate < b.getProps().interestRate ? 1 : -1,
    );
    return sorted[0];
  };

  public sortForSelector = () => {
    const compare = (a: TimeDeposit, b: TimeDeposit) => {
      const [aProps, bProps] = [a.getProps(), b.getProps()];

      if (aProps.minDeposit !== bProps.minDeposit)
        return aProps.minDeposit > bProps.minDeposit ? 1 : -1;
      return aProps.interestRate > bProps.interestRate ? 1 : -1;
    };
    return new TimeDeposits(this.items.sort(compare));
  };

  public filterByActive = () => {
    const timeDepositsActive = this.items.filter(
      (item) => item.getProps().isActive,
    );
    return new TimeDeposits(timeDepositsActive);
  };

  public getMinDeposit = () => {
    return this.items.reduce((minDeposit, timeDeposit) => {
      return Math.min(minDeposit, timeDeposit.getProps().minDeposit);
    }, Number.MAX_SAFE_INTEGER);
  };

  public filterByIsAnnualPayOut = (isAnnualPayOut: boolean) =>
    new TimeDeposits(
      this.items.filter((item) => item.isMatchAnnualPayOut(isAnnualPayOut)),
    );
}
export default TimeDeposits;

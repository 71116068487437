import React, { FC } from 'react';
import TextSub from './TextSub';
import fontWeight from '../../../../fonts/fontWeight.json';
import fontSize from '../../../../fonts/fontSizePixel.json';

export interface TextSubMedium31Props {
  children: React.ReactNode;
  color: string;
}

const TextSubMedium31: FC<TextSubMedium31Props> = ({
  children,
  color,
}: TextSubMedium31Props) => {
  const pixel = fontSize.pixel31;
  const textFontWeight = fontWeight.medium;

  return (
    <TextSub pixel={pixel} color={color} fontWeight={textFontWeight}>
      {children}
    </TextSub>
  );
};
export default TextSubMedium31;

// IService
import ITwoFactorAuthAppSettingsUpdateService from '../../../useCases/verification/consentForm/ITwoFactorAuthAppSettingsUpdateService';

// IRepository
import { IConsentFormUpdateRepository } from '../../../domain/verification/consentForm/IConsentFormRepository';

// Domain
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class TwoFactorAuthAppSettingsUpdateService
  implements ITwoFactorAuthAppSettingsUpdateService {
  private consentFormUpdateRepository: IConsentFormUpdateRepository;

  constructor(consentFormUpdateRepository: IConsentFormUpdateRepository) {
    this.consentFormUpdateRepository = consentFormUpdateRepository;
  }

  public update = async (user: UserAuthorized, code: string) => {
    await this.consentFormUpdateRepository.updateTwoFactorAuthAppSettings(
      user,
      code,
    );
  };
}

export default TwoFactorAuthAppSettingsUpdateService;

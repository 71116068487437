import Joi from '@hapi/joi';

// Error
import InvalidDataFoundError from '../../../../../../utils/errors/InvalidDataFoundError';

// Type
import {
  BankWithdrawalProps,
  BankWithdrawalPropsFormat,
} from './BankWithdrawal.type';

// DomainObject
import BankWithdrawal from './BankWithdrawal';
import BankTransactionId from '../../../../../idManager/transactionId/BankTransactionId/BankTransactionId';

class BankWithdrawalFactory {
  static create = (id: BankTransactionId, props: BankWithdrawalProps) => {
    const result = Joi.object(BankWithdrawalPropsFormat).validate(props);

    if (result.error)
      throw new InvalidDataFoundError(
        `Creation of BankWithdrawal is rejected. The reason is ${result.error.details[0].message}`,
      );

    return new BankWithdrawal(id, props);
  };
}
export default BankWithdrawalFactory;

import styled from 'styled-components';
import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const ThStyle = {
  backgroundColor: '#cdd0dd',
};

export const ThTextStyle = {
  fontSize: 18,
  color: color.primary,
  fontWeight: fontWeight.medium,
};

export const TdTextStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.medium,
};

export const TableStyle = styled.div`
  thead {
    th {
      border-right: ${0.55 / 16}rem solid #fff;
    }
    th:last-child {
      border-right: none;
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: #f8f9fb;
    }
    td {
      border-right: ${0.55 / 16}rem solid #d6ddec;
      word-wrap: break-word;
    }
    td: last-child {
      border-right: none;
    }
  }
`;

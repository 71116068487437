import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import Margin from '../../../../atoms/utils/layout/Margin';
// import { FlexRow } from '../../../../atoms/utils/layout/Flex';
import { FlexBoxColumn } from '../../../../atoms/utils/layout/Box/FlexBox';

export interface FormRegisterTwoFactorAuthLayoutProps {
  children: React.ReactElement[];
}

const FormRegisterTwoFactorAuthLayout: FC<FormRegisterTwoFactorAuthLayoutProps> = ({
  children,
}: FormRegisterTwoFactorAuthLayoutProps) => {
  const elements = ['downloadApp', 'accountKey', 'form', 'button'];
  const [downloadApp, accountKey, form, button] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn width={1000}>
      {downloadApp}
      <Margin top={60} />
      {accountKey}
      <Margin top={60} />
      {form}
      <Margin top={60} />
      {button}
      <Margin bottom={100} />
    </FlexBoxColumn>
  );
};

export default FormRegisterTwoFactorAuthLayout;

import { ProductSerialNumberProps } from './ProductSerialNumber.type';
import { ProductSerialNumberType } from '../../../../../types/ProductSerialNumber.type';
import IProductSerialNumber from './IProductSerialNumber';

abstract class ProductSerialNumber implements IProductSerialNumber {
  protected type: ProductSerialNumberType;

  protected prefix: string;

  protected serialNumber: string;

  constructor(id: ProductSerialNumberType, props: ProductSerialNumberProps) {
    this.type = id;
    this.prefix = props.prefix;
    this.serialNumber = props.serialNumber;
  }

  public getProps = (key: string) => {
    switch (key) {
      case 'type':
        return this.type;
      case 'prefix':
        return this.prefix;
      case 'serialNumber':
        return this.serialNumber;
      default:
        throw new Error(
          `Invalid argument '${key}' as ${this.constructor.name}.getProps`,
        );
    }
  };

  public abstract createNextNumber: () => IProductSerialNumber;

  public abstract getFormattedId: () => string;

  public abstract getRawId: () => string;

  public getId = () => this.type;

  public getCommandParameter() {
    return {
      serialNumber: this.serialNumber,
      prefix: this.prefix,
    };
  }
}
export default ProductSerialNumber;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ArrowIconContainer = styled.div`
  margin-right: ${12 / 16}rem;
  display: flex;
  align-items: center;
`;
export const NavigationTextContainer = styled.div``;

import React, { FC } from 'react';
import { convertMoneyFormat } from '../../../../../utils/helpers/currencyHelper';

// Type
import { CurrencyType } from '../../../../../types/Currency.type';
import { CurrencyPairType } from '../../../../../types/CurrencyPair.type';
import { CryptoCurrencyTradeType } from '../../../../../types/CryptoCurrency.type';
import { FiatCurrencyProps } from '../../../../../types/FiatCurrency.type';

// Style
import { CurrencySymbolStyle, PriceStyle } from './style';

// Component
import {
  TextPrimary,
  TextSecondary,
  TextThemeProps,
} from '../../../../atoms/text2/Text2';
import LabelCryptoCurrencyTrade from '../../../textGroup/LabelCryptoCurrencyTrade';
import CryptoCurrencyRatePriceCardLayout from './CryptoCurrencyRatePriceCardLayout';

// const ORGANISMS_ID = `organisms.CryptoCurrencyRatePriceCard`;

export interface CryptoCurrencyRatePriceCardProps {
  tradeType: CryptoCurrencyTradeType;
  rate: number | undefined;
  targetCurrency: CurrencyType;
  baseCurrency: CurrencyType;
  theme: {
    label: TextThemeProps;
    arrow: TextThemeProps;
  };
}

const CryptoCurrencyRatePriceCard: FC<CryptoCurrencyRatePriceCardProps> = (
  props: CryptoCurrencyRatePriceCardProps,
) => {
  const { baseCurrency, targetCurrency, tradeType, rate } = props;
  const currencyPair = `${targetCurrency}${baseCurrency}` as CurrencyPairType;

  const borderBottomColor =
    tradeType === CryptoCurrencyTradeType.sell ? '#15609B' : '#D06E77';

  return (
    <CryptoCurrencyRatePriceCardLayout borderBottomColor={borderBottomColor}>
      <LabelCryptoCurrencyTrade key="label" {...props} />
      <TextPrimary key="currency" theme={CurrencySymbolStyle}>
        {FiatCurrencyProps[baseCurrency].symbol}
      </TextPrimary>
      <TextSecondary key="price" theme={PriceStyle}>
        {convertMoneyFormat(rate || 0, currencyPair)}
      </TextSecondary>
    </CryptoCurrencyRatePriceCardLayout>
  );
};

export default CryptoCurrencyRatePriceCard;

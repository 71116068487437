import firestore from '../../client/firestoreClient';
import { UseStateType } from '../../../../../types/typeof/UseState';
import { ErrorCodeType } from '../../../../../utils/errors/ErrorHandler/ErrorCode.type';

class Collection {
  collection: firestore.CollectionReference;

  constructor() {
    this.collection = firestore().collection('collection');
  }

  public getCollectionReference = (): firestore.CollectionReference =>
    this.collection;

  public fetchAll = async (): Promise<firestore.QueryDocumentSnapshot[]> => {
    const snapshot = await this.collection.get();
    return snapshot.docs;
  };

  public fetchSpecific = async (
    id: string,
    t?: firestore.Transaction,
  ): Promise<firestore.DocumentSnapshot | null> => {
    const reference = this.collection.doc(id);

    const document = t ? await t.get(reference) : await reference.get();

    if (!document.exists) return null;

    return document;
  };

  public fetchOneByUniqueField = async (params: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }): Promise<firestore.QueryDocumentSnapshot | undefined> => {
    const query = Object.keys(params).reduce<firestore.Query>((ref, key) => {
      return ref.where(key, '==', params[key]);
    }, this.collection);

    const snapshot = await query.get();

    if (snapshot.size !== 1) return undefined;

    return snapshot.docs.pop() as firestore.QueryDocumentSnapshot;
  };

  public fetchByFields = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: { [key: string]: any },
  ): Promise<firestore.QueryDocumentSnapshot[]> => {
    const queryRef = Object.keys(params).reduce<firestore.Query>((ref, key) => {
      return ref.where(key, '==', params[key]);
    }, this.collection);

    const snapshot = await queryRef.get();

    return snapshot.docs;
  };

  public create = async <T>(
    id: string | null,
    fields: Partial<T>,
    atomic?: firestore.Transaction | firestore.WriteBatch,
  ) => {
    const reference = id ? this.collection.doc(id) : this.collection.doc();

    if (!atomic) {
      const writeResult = await reference.set(fields);
      return writeResult;
    }

    if (atomic instanceof firestore.Transaction) {
      const t = atomic as firestore.Transaction;
      return t.set(reference, fields);
    }

    if (atomic instanceof firestore.WriteBatch) {
      const batch = atomic as firestore.WriteBatch;
      return batch.set(reference, fields);
    }

    return null;
  };

  public update = async <T>(
    id: string,
    fields: T,
    atomic?: firestore.Transaction | firestore.WriteBatch,
  ): Promise<firestore.Transaction | firestore.WriteBatch | void> => {
    const docRef = this.collection.doc(id);

    if (!atomic) {
      // eslint-disable-next-line no-return-await
      return await docRef.set(fields, { merge: true });
    }

    if (atomic instanceof firestore.Transaction) {
      const t = atomic as firestore.Transaction;
      const updateResult = t.set(
        docRef as firestore.DocumentReference,
        fields,
        {
          merge: true,
        },
      );
      return updateResult;
    }

    const batch = atomic as firestore.WriteBatch;
    const updateResult = batch.set(
      docRef as firestore.DocumentReference,
      fields,
      { merge: true },
    );
    return updateResult;
  };

  public set = async <T>(
    id: string,
    fields: T,
    atomic?: firestore.Transaction | firestore.WriteBatch,
  ): Promise<firestore.Transaction | firestore.WriteBatch | void> => {
    const docRef = this.collection.doc(id);

    if (!atomic) {
      // eslint-disable-next-line no-return-await
      return await docRef.set(fields, { merge: true });
    }

    if (atomic instanceof firestore.Transaction) {
      const t = atomic as firestore.Transaction;
      const updateResult = t.set(
        docRef as firestore.DocumentReference,
        fields,
        {
          merge: true,
        },
      );
      return updateResult;
    }

    const batch = atomic as firestore.WriteBatch;
    const updateResult = batch.set(
      docRef as firestore.DocumentReference,
      fields,
      { merge: true },
    );
    return updateResult;
  };

  public query = async <T extends string>(
    params: Array<{ key: T; value: string }>,
  ): Promise<firestore.QueryDocumentSnapshot[] | undefined> => {
    const queryRef = params.reduce<firestore.Query | undefined>(
      (ref, param) => {
        if (!ref) return this.collection.where(param.key, '==', param.value);
        return (ref as firestore.Query).where(param.key, '==', param.value);
      },
      undefined,
    );
    if (!queryRef) return undefined;
    const snapshot = await queryRef.get();
    return snapshot.docs;
  };

  public subscribeAll = <T>(
    setter: UseStateType<T>,
    converter: (docs: firestore.QueryDocumentSnapshot[]) => T,
    setState: UseStateType<string>,
  ) => {
    return this.collection.onSnapshot((querySnapshot) => {
      try {
        setter(converter(querySnapshot.docs));
      } catch (error) {
        if (process.env.NODE_ENV !== 'production')
          // eslint-disable-next-line no-console
          console.log(error);
        setState(ErrorCodeType.SERVER_ERROR);
      }
    });
  };

  public subscribeSpecific = <T>(
    id: string,
    setter: UseStateType<T | undefined>,
    converter: (doc: firestore.DocumentSnapshot) => T,
    setState: UseStateType<string>,
  ) => {
    return this.collection.doc(id).onSnapshot((doc) => {
      try {
        setter(converter(doc));
      } catch (error) {
        if (process.env.NODE_ENV !== 'production')
          // eslint-disable-next-line no-console
          console.log(error);
        setState(ErrorCodeType.SERVER_ERROR);
      }
    });
  };

  public subscribeNewData = <T>(
    setter: UseStateType<T | undefined>,
    converter: (doc: firestore.QueryDocumentSnapshot) => T,
    setState: UseStateType<string>,
  ) => {
    let state = 'initial';
    this.collection.onSnapshot((querySnapshot) => {
      if (state === 'initial') {
        state = 'ready';
        return;
      }
      querySnapshot.docChanges().forEach((change) => {
        try {
          if (change.type === 'added') setter(converter(change.doc));
        } catch (error) {
          if (process.env.NODE_ENV !== 'production')
            // eslint-disable-next-line no-console
            console.log(error);
          setState(ErrorCodeType.SERVER_ERROR);
        }
      });
    });
  };

  public subscribeOneByUniqueField = <T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: { [key: string]: any },
    setter: UseStateType<T | undefined>,
    converter: (doc: firestore.DocumentSnapshot) => T,
    setState: UseStateType<string>,
  ) => {
    const query = Object.keys(params).reduce<firestore.Query>((ref, key) => {
      return ref.where(key, '==', params[key]);
    }, this.collection);

    return query.onSnapshot((docs) => {
      try {
        setter(converter(docs[0]));
      } catch (error) {
        if (process.env.NODE_ENV !== 'production')
          // eslint-disable-next-line no-console
          console.log(error);
        setState(ErrorCodeType.SERVER_ERROR);
      }
    });
  };

  subscribeSpecificWithNoData = <T>(
    id: string,
    setter: UseStateType<T | undefined>,
    converter: (doc: firestore.DocumentSnapshot) => T | undefined,
    setState: UseStateType<string>,
  ) => {
    this.collection.doc(id).onSnapshot((doc: firestore.DocumentSnapshot) => {
      try {
        setter(converter(doc));
      } catch (error) {
        if (process.env.NODE_ENV !== 'production')
          // eslint-disable-next-line no-console
          console.log(error);
        setState(ErrorCodeType.SERVER_ERROR);
      }
      setter(converter(doc));
    });
  };

  public subscribeByFields = <T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: { [key: string]: any },
    setter: UseStateType<T>,
    converter: (docs: firestore.QueryDocumentSnapshot[]) => T,
    setState: UseStateType<string>,
  ) => {
    const query = Object.keys(params).reduce<firestore.Query>((ref, key) => {
      return ref.where(key, '==', params[key]);
    }, this.collection);

    return query.onSnapshot((snapshot) => {
      try {
        setter(converter(snapshot.docs));
      } catch (error) {
        if (process.env.NODE_ENV !== 'production')
          // eslint-disable-next-line no-console
          console.log(error);
        setState(ErrorCodeType.SERVER_ERROR);
      }
    });
  };
}

export default Collection;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LinkConsentFormDownloadContext from '../../../../../../../enhancers/fileHandler/verification/consentForm/LinkConsentFormDownloadFileProvider/LinkConsentFormDownloadContext';

// Style
import { ButtonStyle, ButtonIconLayout, ButtonIconStyle } from './style';

// Component
import FileDownloadButton from '../../../../../../atoms/button/FileDownloadButton';
import LabelDownloadIcon from '../../../../../../atoms/button/ButtonLabel/LabelDownloadIcon';
import KycFormContainer from '../../../../common/KycFormContainer';

const FORM_ID = `organisms.form.verification.FormConsentForm.download`;

const ConsentFormDownload: FC = () => {
  const { t } = useTranslation();
  const { consentFormDownloadUrl } = useContext(LinkConsentFormDownloadContext);

  return (
    <KycFormContainer order={1} formId={`${FORM_ID}`}>
      <FileDownloadButton
        filePath={consentFormDownloadUrl || ''}
        height={50}
        theme={ButtonStyle}
        width={291.9}
      >
        <LabelDownloadIcon
          label={t(`atoms.button.downloadConsentForm`)}
          layout={ButtonIconLayout}
          theme={ButtonIconStyle}
        />
      </FileDownloadButton>
    </KycFormContainer>
  );
};

export default ConsentFormDownload;

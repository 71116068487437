// Types
import { firestore } from 'firebase';
import { MaintenanceProps } from '../../../domain/settings/maintenance/Maintenance/Maintenance/Maintenance.type';
import { UseStateType } from '../../../types/typeof/UseState';

// Collection
import MaintenancesCollection from '../../../infrastructure/firebase/firestore/collections/settings/maintenances/MaintenancesCollection';

// IRepository
import IMaintenanceRepository from '../../../domain/settings/maintenance/Maintenance/IMaintenanceRepository';

// DomainObject
import Maintenance from '../../../domain/settings/maintenance/Maintenance/Maintenance/Maintenance';
import MaintenanceFactory from '../../../domain/settings/maintenance/Maintenance/Maintenance/MaintenanceFactory';

class MaintenanceFirestore implements IMaintenanceRepository {
  public subscribeById = async (
    id: string,
    setter: UseStateType<Maintenance | undefined>,
    setState: UseStateType<string>,
  ) => {
    const maintenancesCollection = new MaintenancesCollection();

    const converter = (doc: firestore.DocumentSnapshot) => {
      const props = doc.data();

      return MaintenanceFactory.create(props as MaintenanceProps);
    };

    return maintenancesCollection.subscribeSpecific(
      id,
      setter,
      converter,
      setState,
    );
  };
}
export default MaintenanceFirestore;

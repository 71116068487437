// Lib
import { yyyymmdd } from '../../../../utils/helpers/datetimeHelper';

// Type
import { FormInputCherryVisa01ProofOfIdentity } from '../../../../components/organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity/FormCherryVisa01ProofOfIdentity.type';
import { CherryVisa01ProofOfIdentityDocumentType } from '../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentity.type';

// IService
import IDebitCardRequestCherryVisa01UpdateService from '../../../../useCases/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/IDebitCardRequestCherryVisa01UpdateService';

// IRepository
import { IDebitCardRequestCherryVisa01UpdateRepository } from '../../../../domain/debitCard/debitCardRequest/IDebitCardRequestCherryVisa01Repository';

// DomainObject
import DebitCardRequestCherryVisa01 from '../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01';

class DebitCardRequestCherryVisa01UpdateService
  implements IDebitCardRequestCherryVisa01UpdateService {
  private debitCardRequestCherryVisa01UpdateRepository: IDebitCardRequestCherryVisa01UpdateRepository;

  constructor(
    debitCardRequestCherryVisa01UpdateRepository: IDebitCardRequestCherryVisa01UpdateRepository,
  ) {
    this.debitCardRequestCherryVisa01UpdateRepository = debitCardRequestCherryVisa01UpdateRepository;
  }

  public updateProofOfIdentity = (
    debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01,
    formInputProofOfIdentity: FormInputCherryVisa01ProofOfIdentity,
    documentId1?: string,
    documentId2?: string,
    passportId?: string,
    selfieId?: string,
    signatureId?: string,
  ) => {
    const { proofOfIdentity } = debitCardRequestCherryVisa01.getProps();

    const documentId1Updated =
      documentId1 ?? proofOfIdentity?.getProps().document.documentId1;
    const documentId2Updated =
      documentId2 ?? proofOfIdentity?.getProps().document.documentId2;
    const passportIdUpdated =
      passportId ?? proofOfIdentity?.getProps().passport.passportId;
    const selfieIdUpdated =
      selfieId ?? proofOfIdentity?.getProps().passport.selfieId;
    const signatureIdUpdated =
      signatureId ?? proofOfIdentity?.getProps().signatureId;

    const updatedDebitCardRequestCherryVisa01 = debitCardRequestCherryVisa01.updateProofOfIdentity(
      {
        email: '',
        firstName: '',
        lastName: '',
        gender: formInputProofOfIdentity.gender,
        nationality: formInputProofOfIdentity.nationality,
        countryCode: formInputProofOfIdentity.countryCode,
        phoneNumber: formInputProofOfIdentity.phoneNumber,
        occupation: formInputProofOfIdentity.occupation,
        isInscribed: formInputProofOfIdentity.isInscribed,
        dateOfBirth: yyyymmdd(
          formInputProofOfIdentity.dateOfBirthYear,
          formInputProofOfIdentity.dateOfBirthMonth,
          formInputProofOfIdentity.dateOfBirthDay,
          '/',
        ),
        document: {
          type: formInputProofOfIdentity.documentType as CherryVisa01ProofOfIdentityDocumentType,
          issuingCountry: formInputProofOfIdentity.issuingCountry,
          documentId1: documentId1Updated ?? '',
          documentId2: documentId2Updated ?? '',
        },
        passport: {
          passportNo: formInputProofOfIdentity.passportNo,
          issuedAt: yyyymmdd(
            formInputProofOfIdentity.issuedDateYear,
            formInputProofOfIdentity.issuedDateMonth,
            formInputProofOfIdentity.issuedDateDay,
            '/',
          ),
          expiredAt: yyyymmdd(
            formInputProofOfIdentity.expiredDateYear,
            formInputProofOfIdentity.expiredDateMonth,
            formInputProofOfIdentity.expiredDateDay,
            '/',
          ),
          passportId: passportIdUpdated ?? '',
          selfieId: selfieIdUpdated ?? '',
        },
        signatureId: signatureIdUpdated ?? '',
        shippingAddress: {
          country: formInputProofOfIdentity.country,
          addressLine1: formInputProofOfIdentity.addressLine1,
          addressLine2: formInputProofOfIdentity.addressLine2,
          city: formInputProofOfIdentity.city,
          state: formInputProofOfIdentity.state,
          postalCode: formInputProofOfIdentity.postalCode,
        },
      },
    );

    return updatedDebitCardRequestCherryVisa01;
  };

  public updateProofOfReceipt = (
    debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01,
    selfieId?: string,
  ) => {
    const updatedDebitCardRequestCherryVisa01 = debitCardRequestCherryVisa01.updateProofOfReceipt(
      {
        selfieId: selfieId ?? '',
      },
    );

    return updatedDebitCardRequestCherryVisa01;
  };

  public executeUpdateProofOfIdentity = async (
    debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01,
  ) => {
    await this.debitCardRequestCherryVisa01UpdateRepository.updateProofOfIdentity(
      debitCardRequestCherryVisa01,
    );

    return debitCardRequestCherryVisa01;
  };

  public executeUpdateProofOfReceipt = async (
    debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01,
  ) => {
    await this.debitCardRequestCherryVisa01UpdateRepository.updateProofOfReceipt(
      debitCardRequestCherryVisa01,
    );

    return debitCardRequestCherryVisa01;
  };

  public executePayIssuance = async (
    debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01,
  ) => {
    await this.debitCardRequestCherryVisa01UpdateRepository.payIssuance(
      debitCardRequestCherryVisa01,
    );

    return debitCardRequestCherryVisa01;
  };
}

export default DebitCardRequestCherryVisa01UpdateService;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { Container } from './style';
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface MenuSidebarLayoutProps {
  children: React.ReactElement[];
  divRef?: React.RefObject<HTMLDivElement>;
}

const MenuSidebarLayout: FC<MenuSidebarLayoutProps> = ({
  children,
  divRef,
}: MenuSidebarLayoutProps) => {
  const elements = ['logo', 'menuList', 'signout'];
  const [logo, menuList, signout] = getElementsFromKeys(children, elements);

  return (
    <Container ref={divRef}>
      <FlexBoxColumn width={330} height="auto" textAlign="left">
        <FlexBoxRow height={120} padding="68 42 80 42">
          {logo}
        </FlexBoxRow>
        {menuList}
      </FlexBoxColumn>
      <Margin top={70} />
      {signout}
    </Container>
  );
};
export default MenuSidebarLayout;

// IService
import IContractTimeDepositHistoryQueryService from '../../../useCases/contract/contractTimeDepositHistory/interfaces/IContractTimeDepositHistoryQueryService';

// IRepository
import IContractTimeDepositHistoryRepository from '../../../domain/contract/contractTimeDepositHistory/IContractTimeDepositHistoryRepository';

class ContractTimeDepositHistoryQueryService
  implements IContractTimeDepositHistoryQueryService {
  private contractTimeDepositHistoryQueryRepository: IContractTimeDepositHistoryRepository;

  constructor(
    contractTimeDepositHistoryQueryRepository: IContractTimeDepositHistoryRepository,
  ) {
    this.contractTimeDepositHistoryQueryRepository = contractTimeDepositHistoryQueryRepository;
  }

  public findAllByUserId = async (userId: string) => {
    const contractTimeDepositHistories = await this.contractTimeDepositHistoryQueryRepository.findAllByUserId(
      userId,
    );
    return contractTimeDepositHistories;
  };
}

export default ContractTimeDepositHistoryQueryService;

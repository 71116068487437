import Joi from '@hapi/joi';

// Type
import { ConsentFormContentProps } from '../../../../value/verification/consentForm/ConsentFormContent/ConsentFormContent.type';
import { ConsentFormStepsStatusProps } from '../../../../value/verification/consentForm/ConsentFormStepsStatus/ConsentFormStepsStatus/ConsentFormStepsStatus.type';
import { UserType } from '../../../../types/User.type';

// ValueObject
import ConsentFormStepsStatusFactory from '../../../../value/verification/consentForm/ConsentFormStepsStatus/ConsentFormStepsStatus/ConsentFormStepsStatusFactory';
import ConsentFormContentFactory from '../../../../value/verification/consentForm/ConsentFormContent/ConsentFormContentFactory';

export interface ConsentFormProps {
  username: string;

  type: UserType;

  consentFormContent: ConsentFormContentProps;

  consentFormStepsStatus: ConsentFormStepsStatusProps;

  createdAt?: number;

  updatedAt?: number;
}

export const ConsentFormPropsFormat = {
  id: Joi.string().min(1).required(),

  type: Object.keys(UserType)
    .reduce((joi, type) => joi.valid(type), Joi.string())
    .required(),

  username: Joi.string().min(2).max(20).required(),

  consentFormContent: Joi.any().required(),

  consentFormStepsStatus: Joi.any().required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

export const ConsentFormInitialProps = {
  username: 'username',

  type: UserType.individual,

  consentFormStepsStatus: ConsentFormStepsStatusFactory.createInitial().getProps(),

  consentFormContent: ConsentFormContentFactory.createInitial().getProps(),
};

import React, { FC, useContext, useMemo } from 'react';
import ReferralResultOptionContext from './ReferralResultTabBarOptionContext';
import InviterContext from '../../../../domain/invite/inviter/Inviter/InviterContext';

// Tabs
import {
  ReferralResultRankDTabs,
  ReferralResultRankATabs,
} from '../../../../config/menu/tab/ReferralResultTab';

// Style
import { SelectedStyle, TextStyle, UnSelectedStyle } from './style';

// Component
import UnderBarTabBar from '../../../molecules/selector/tabBar/UnderBarTabBar/UnderBarTabBar';

// DomainObject
import InviterRankD from '../../../../domain/invite/inviter/InviterRankD/InviterRankD';

const ReferralResultTabBar: FC = () => {
  const { referralResultOption, setReferralResultOption } = useContext(
    ReferralResultOptionContext,
  );
  const { inviter } = useContext(InviterContext);

  const referralResultTabs = useMemo(
    () =>
      inviter instanceof InviterRankD
        ? ReferralResultRankDTabs
        : ReferralResultRankATabs,
    [inviter],
  );

  return (
    <UnderBarTabBar
      height={50}
      items={referralResultTabs}
      onClick={setReferralResultOption}
      selected={referralResultOption}
      theme={{
        text: TextStyle,
        selected: SelectedStyle,
        unSelected: UnSelectedStyle,
      }}
      widthItem={250}
    />
  );
};

export default ReferralResultTabBar;

import React, { FC, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ContractTimeDepositCreateContext from '../../../../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositCreateProvider/ContractTimeDepositCreateContext';
import { FormInputAuthenticatorType } from '../../../../../molecules/form/formInput/textField/FormInputAuthenticator/FormInputAuthenticator.type';
import { IS_LOADING } from '../../../../../../config/constants/requestState';
import { SubmitButton } from '../../../../../atoms/button/DomainButton';
import FormInputAuthenticator from '../../../../../molecules/form/formInput/textField/FormInputAuthenticator';
import FormTimeDepositAddConfirmLayout from './FormContractTimeDepositAddConfirmLayout';
import ContractTimeDepositAddPageStateContext from '../../../../../../enhancers/pageState/contract/ContractTimeDepositAddPageState/ContractTimeDepositAddPageStateContext';

const FormContractTimeDepositAddConfirm: FC = () => {
  const { t } = useTranslation();

  // PageState
  const { setPageState } = useContext(ContractTimeDepositAddPageStateContext);

  // Execute Create
  const { requestState, executeCreateContractTimeDeposit } = useContext(
    ContractTimeDepositCreateContext,
  );

  /* *
   *
   *  Form
   *
   * */
  const methods = useForm<FormInputAuthenticatorType>({
    mode: 'onChange',
  });
  const { handleSubmit, watch } = methods;

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={handleSubmit((formInput: FormInputAuthenticatorType) => {
        const { code } = formInput;
        executeCreateContractTimeDeposit(code, () => {
          setPageState('complete');
        });
      })}
    >
      <FormProvider {...methods}>
        <FormTimeDepositAddConfirmLayout>
          <FormInputAuthenticator key="twoFactorAuth" state={requestState} />
          <SubmitButton
            key="button"
            name={t(`atoms.button.submit`)}
            width={`${470 / 16}rem `}
            height={`${74 / 16}rem `}
            borderRadius={`${10 / 16}rem `}
            disabled={!watch('code')}
            isLoading={requestState === IS_LOADING}
          />
        </FormTimeDepositAddConfirmLayout>
      </FormProvider>
    </form>
  );
};

export default FormContractTimeDepositAddConfirm;

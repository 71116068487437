// IService
import IBankFutureDepositQueryService from '../../../../../useCases/transaction/bankTransaction/bankFutureTransaction/bankFutureDeposit/IBankFutureDepositQueryService';

// IRepository
import { IBankFutureDepositQueryRepository } from '../../../../../domain/transaction/bankTransaction/bankFutureTransaction/bankFutureDeposit/IBankFutureDepositRepository';

class BankFutureDepositQueryService implements IBankFutureDepositQueryService {
  private bankFutureDepositQueryRepository: IBankFutureDepositQueryRepository;

  constructor(
    bankFutureDepositQueryRepository: IBankFutureDepositQueryRepository,
  ) {
    this.bankFutureDepositQueryRepository = bankFutureDepositQueryRepository;
  }

  public findFutureCommissionsTimeDepositByUserId = async (userId: string) => {
    const bankFutureDeposits = await this.bankFutureDepositQueryRepository.findFutureCommissionsTimeDepositByUserId(
      userId,
    );

    return bankFutureDeposits;
  };
}

export default BankFutureDepositQueryService;

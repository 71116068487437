import styled from 'styled-components';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  height: ${120 / 16}rem;
`;

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  lineHeight: 44,
};

export const EmailStyle = {
  fontSize: 31,
  color: color.text.zambezi,
  fontWeight: fontWeight.medium,
};

export const NavigationTextStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';

// Style
import { ContainerStyle } from './style';

// LayoutComponent
import { FlexBoxColumn } from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface AssetEmptyCardLayoutProps {
  children: React.ReactElement[];
  layout: {
    width: number | string;
    height: number | string;
    padding: number | string;
  };
}

const AssetEmptyCardLayout: FC<AssetEmptyCardLayoutProps> = ({
  children,
  layout,
}: AssetEmptyCardLayoutProps) => {
  const elements = ['title', 'balance'];

  const [title, balance] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn {...layout} theme={ContainerStyle}>
      {title}
      <Margin top={25} />
      <FlexBoxColumn width="auto" height={64}>
        {balance}
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export default AssetEmptyCardLayout;

import React, { FC } from 'react';

// Style
import { Container } from './style';

// Component
import OneDigitInput from '../../../../atoms/form/OneDigitInput';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface DigitInputProps {
  currentPosition: number;
  digit: number;
  onChange?: (name: string, codeNumber: string, position: number) => void;
  type?: '' | 'password';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any;
}

const SeparatedDigitInput: FC<DigitInputProps> = ({
  currentPosition,
  digit,
  onChange = () => undefined,
  type = '',
  register,
}: DigitInputProps) => {
  const counter: Array<number> = [];

  let i = 0;
  while (i < digit) {
    counter.push(i + 1);
    i += 1;
  }

  return (
    <Container>
      {counter.map((num) => {
        return (
          <Margin key={`input${num}`} left={13}>
            <OneDigitInput
              name={`digit${num}`}
              digit={digit}
              type={type}
              position={num}
              onChange={onChange}
              currentPosition={currentPosition}
              register={register({ required: true })}
            />
          </Margin>
        );
      })}
    </Container>
  );
};

export default SeparatedDigitInput;

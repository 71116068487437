import styled from 'styled-components';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  min-height: 100%;
  box-sizing: border-box;
  padding-top: ${35 / 16}rem;
`;

export const NavigationTextStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

import Joi from '@hapi/joi';

// Type
import { CryptoCurrencyDepositType } from '../../../../../../../types/CryptoCurrencyTransaction.type';
import {
  CryptoCurrencySpotTransactionProps,
  CryptoCurrencySpotTransactionPropsFormat,
} from '../../CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction.type';

export interface CryptoCurrencyDepositProps
  extends CryptoCurrencySpotTransactionProps {
  ipnId: string;

  actualFee: number;

  txid: string;

  type: CryptoCurrencyDepositType;
}

export const CryptoCurrencyDepositPropsFormat = {
  ...CryptoCurrencySpotTransactionPropsFormat,
  ipnId: Joi.string().min(1).required(),

  actualFee: Joi.number().required(),

  txid: Joi.string().min(1).required(),

  type: Object.keys(CryptoCurrencyDepositType)
    .reduce((joi, type) => joi.valid(type), Joi.string())
    .required(),
};

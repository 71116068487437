import Joi from '@hapi/joi';

// Type
import { CurrencyType } from '../../../../../types/Currency.type';
import { FiatCurrencyType } from '../../../../../types/FiatCurrency.type';

export interface ReferralTimeDepositByChildrenProps {
  topLevelInviterId: string;

  topLevelInviterContractName: string;

  secondLevelInviterId: string;

  secondLevelInviterContractName: string;

  depositAmount: number;

  depositCurrency: CurrencyType;

  userId: string;

  userContractName: string;

  interestRate: number;

  term: number;

  createdAt?: number;

  updatedAt?: number;
}

export interface ReferralTimeDepositByChildrenRawDataType {
  id: string;

  props: ReferralTimeDepositByChildrenProps;
}

export const ReferralTimeDepositByChildrenPropsFormat = {
  id: Joi.string().required(),

  topLevelInviterId: Joi.string().required(),

  topLevelInviterContractName: Joi.string().required(),

  secondLevelInviterId: Joi.string().required(),

  secondLevelInviterContractName: Joi.string().required(),

  userId: Joi.string().required(),

  userContractName: Joi.string().required(),

  depositAmount: Joi.number().required(),

  depositCurrency: Object.keys(FiatCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),

  interestRate: Joi.number().min(0).required(),

  term: Joi.number().min(0).required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

// Types
import { UseStateType } from '../../../types/typeof/UseState';

// IService
import IInvitationCodeQueryService from '../../../useCases/invite/invitationCode/IInvitationCodeQueryService';

// IRepository
import { IInvitationCodeQueryRepository } from '../../../domain/invite/invitationCode/IInvitationCodeRepository';

// DomainObject
import InvitationCodes from '../../../domain/invite/invitationCode/InvitationCodes/InvitationCodes';
import UserVerified from '../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';

class InvitationCodeQueryService implements IInvitationCodeQueryService {
  private invitationCodeQueryRepository: IInvitationCodeQueryRepository;

  constructor(invitationCodeQueryRepository: IInvitationCodeQueryRepository) {
    this.invitationCodeQueryRepository = invitationCodeQueryRepository;
  }

  public setByUser = async (
    user: UserVerified,
    setter: UseStateType<InvitationCodes>,
    setState: UseStateType<string>,
  ) => {
    const userId = user.getId();
    await this.invitationCodeQueryRepository.subscribeByUserId(
      userId,
      setter,
      setState,
    );
  };
}

export default InvitationCodeQueryService;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';
// import Display from '../../../atoms/div/Display';

export interface FileUploadPreviewLayoutProps {
  children: React.ReactElement[];
  // isImageDisplay: boolean;
}

const FileUploadPreviewLayout: FC<FileUploadPreviewLayoutProps> = ({
  children,
}: // isImageDisplay,
FileUploadPreviewLayoutProps) => {
  const elements = ['image', 'fileName', 'deleteButton'];
  const [image, fileName, deleteButton] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn alignItems="center">
      {image}
      <Margin top={23} />
      <FlexBoxRow alignItems="center" justifyContent="center">
        {fileName}
        <Margin left={15} />
        {deleteButton}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
export default FileUploadPreviewLayout;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Type
import { TransferDebitCardType } from '../../../../../types/Transfer.type';

// Component
import { TextPrimary, TextThemeProps } from '../../../../atoms/text2/Text2';
import TransferDebitCardIconGroup from '../../../image/TransferDebitCardIconGroup';
import TransferDebitCardTypeTabItemLayout from './TransferDebitCardTypeTabItemLayout';

export type TransferDebitCardTypeTabItemProps = {
  item: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (type: any) => void;
  selected?: string;
  theme: {
    text: TextThemeProps;
    selected: {
      borderBottom: string;
    };
    unSelected: {
      opacity: number;
    };
  };
  width: string | number;
};

const TransferDebitCardTypeTabItem: FC<TransferDebitCardTypeTabItemProps> = ({
  item,
  onClick,
  selected,
  theme,
  width,
}: TransferDebitCardTypeTabItemProps) => {
  const { t } = useTranslation();

  const containerStyle = item === selected ? theme.selected : theme.unSelected;

  return (
    <TransferDebitCardTypeTabItemLayout
      onClick={() => onClick(item)}
      theme={containerStyle}
      width={width}
    >
      <TextPrimary key="type" theme={theme.text}>
        {t(`molecules.tabItem.${item}`)}
      </TextPrimary>
      <TransferDebitCardIconGroup
        key="icon"
        transferDebitCardType={item as TransferDebitCardType}
      />
    </TransferDebitCardTypeTabItemLayout>
  );
};
export default TransferDebitCardTypeTabItem;

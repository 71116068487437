import Joi from '@hapi/joi';

// Error
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import { TimeDepositProps, TimeDepositPropsFormat } from './TimeDeposit.type';

// DomainObject
import TimeDeposit from './TimeDeposit';

class TimeDepositFactory {
  static create = (id: string, props: TimeDepositProps) => {
    const result = Joi.object(TimeDepositPropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: TimeDeposit.name,
          reason: result.error.details[0].message,
        },
      );

    return new TimeDeposit(id, props);
  };
}

export default TimeDepositFactory;

import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import CryptoCurrencyWalletTemplate from '../../../components/templates/cryptonCurrency/wallet/CryptoCurrencyWalletTemplate';

const CryptoCurrencyWalletPage: FC = () => {
  return (
    <DashboardTemplate>
      <CryptoCurrencyWalletTemplate />
    </DashboardTemplate>
  );
};

export default CryptoCurrencyWalletPage;

import React, { FC } from 'react';

// Image
import VISA from '../../../../../assets/images/visa.svg';

// Error
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import { DebitCardType } from '../../../../../types/DebitCard.type';

// LayoutOmponent
import Box from '../../../utils/layout/Box/Box';

export interface DebitCardTypeImageProps {
  type: DebitCardType;
  layout: {
    height: number | string;
    width: number | string;
  };
}

const DebitCardTypeImage: FC<DebitCardTypeImageProps> = ({
  type,
  layout,
}: DebitCardTypeImageProps) => {
  let src;

  switch (type) {
    case DebitCardType.VISA:
      src = VISA;
      break;
    default:
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_SWITCH,
        {
          value: type,
          place: `DebitCardTypeImage`,
        },
      );
  }

  return (
    <Box {...layout}>
      <img src={src} alt={`${type}`} width="100%" height="100%" />
    </Box>
  );
};
export default DebitCardTypeImage;

import IErrorHandler from './IErrorHandler';
import { ErrorCodeType } from './ErrorCode.type';
import { UseStateType } from '../../../types/typeof/UseState';

class ErrorHandler implements IErrorHandler {
  private code: string;

  private message: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(error: any, public setState: UseStateType<string>) {
    const data = error?.response?.data;
    this.code = data?.code || error?.code;
    this.message = data?.message || error?.message;

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log({ code: this.code });
      // eslint-disable-next-line no-console
      console.log({ error: this.message });
    }
  }

  public setErrorState = () => {
    if (process.env.NODE_ENV !== 'production')
      // eslint-disable-next-line no-console
      console.log(`Error(${this.code}) : ${this.message}`);

    switch (this.code) {
      // Invalid code (authenticator)
      case ErrorCodeType.AUTHENTICATION_AUTHENTICATOR_INVALID_CODE:
        this.setState(this.code);
        break;
      // 40x-00xx Authentication
      case ErrorCodeType.AUTHENTICATION_LOGIN_INVALID_CODE:
      case ErrorCodeType.AUTHENTICATION_LOGIN_REQUEST_EXPIRED:
      case ErrorCodeType.AUTHENTICATION_FORGOT_PASSWORD_EMAIL_NOT_FOUND:
      case ErrorCodeType.AUTHENTICATION_FORGOT_PASSWORD_INVALID_ROLE:
      case ErrorCodeType.AUTHENTICATION_FORGOT_PASSWORD_USER_DELETED:
      case ErrorCodeType.AUTHENTICATION_SIGN_UP_USER_EXISTED:
      case ErrorCodeType.AUTHENTICATION_SIGN_UP_INVALID_INVITATION_CODE:
      case ErrorCodeType.AUTHENTICATION_SIGN_UP_INVALID_INVITER:
      case ErrorCodeType.AUTHENTICATION_SIGN_UP_REQUEST_NOT_FOUND:
      case ErrorCodeType.AUTHENTICATION_SIGN_UP_REQUEST_EXPIRED:
      case ErrorCodeType.AUTHENTICATION_SIGN_UP_USERNAME_EXISTED:
      case ErrorCodeType.AUTHENTICATION_SIGN_UP_EMAIL_EXISTED:
      case ErrorCodeType.AUTHENTICATION_RE_SIGN_UP_USER_NOT_FOUND:
      case ErrorCodeType.AUTHENTICATION_RE_SIGN_UP_INVALID_REREGISTRATION_CODE:
      case ErrorCodeType.AUTHENTICATION_RE_SIGN_UP_REQUEST_NOT_FOUND:
      case ErrorCodeType.AUTHENTICATION_RE_SIGN_UP_ALREADY_CONFIRMED:
      case ErrorCodeType.AUTHENTICATION_RE_SIGN_UP_REQUEST_EXPIRED:
      case ErrorCodeType.AUTHENTICATION_RESET_AUTHENTICATOR_EXPIRED:
      case ErrorCodeType.AUTHENTICATION_RESET_AUTHENTICATOR_NOT_FOUND:
      case ErrorCodeType.AUTHENTICATION_LOGIN_USER_INACTIVE:
        this.setState(this.code);
        break;
      // 40x-04xx Settings
      case ErrorCodeType.SETTINGS_EMAIL_UPDATE_ALREADY_IN_USE:
      case ErrorCodeType.SETTINGS_EMAIL_UPDATE_EXPIRED:
      case ErrorCodeType.SETTINGS_EMAIL_UPDATE_INVALID_OTP:
        this.setState(this.code);
        break;
      // 40x-08xx SavingsAccount
      case ErrorCodeType.SAVINGS_ACCOUNT_INVALID_BENEFICIARY_ACCOUNT_NUMBER:
      case ErrorCodeType.SAVINGS_ACCOUNT_ACCOUNT_HOLDER_WITH_NO_CONTRACT_NAME:
      case ErrorCodeType.SAVINGS_ACCOUNT_INVALID_ACCOUNT_NUMBER_BY_CLIENT:
      case ErrorCodeType.SAVINGS_ACCOUNT_INVALID_ACCOUNT_NUMBER_ECB_BY_CLIENT:
        this.setState(this.code);
        break;
      // 40x-09xx DebitCard
      case ErrorCodeType.DEBIT_CARD_REQUEST_INVALID_ISSUER:
        this.setState(this.code);
        break;
      case ErrorCodeType.DEBIT_CARD_REQUEST_INVALID_MAP_ADDRESS:
        this.setState(this.code);
        break;
      // 40x-9903 General
      case ErrorCodeType.GENERAL_DATA_NOT_FOUND_BY_FIELDS_CLIENT:
        this.setState(this.code);
        break;
      // 40x-12xx Staff
      case ErrorCodeType.STAFF_EMAIL_ALREADY_EXISTED:
        this.setState(this.code);
        break;
      // Invalid password ()
      case ErrorCodeType.FIREBASE_AUTH_WRONG_PASSWORD:
        this.setState(ErrorCodeType.FIREBASE_AUTH_WRONG_PASSWORD);
        break;
      // Login
      case ErrorCodeType.FIREBASE_AUTH_USER_NOT_FOUND:
      case ErrorCodeType.AUTHENTICATION_LOGIN_INVALID_ROLE:
        this.setState(this.code);
        break;
      // 401
      case ErrorCodeType.AUTHENTICATION_LOGIN_LOGIN_FAILED:
      case ErrorCodeType.AUTHENTICATION_LOGIN_INACTIVE:
        this.setState(ErrorCodeType.UNAUTHORIZED_ERROR);
        break;
      // 408
      case ErrorCodeType.REQUEST_TIMEOUT_ERROR:
      case ErrorCodeType.AXIOS_CONNECTION_TIMEOUT:
      case ErrorCodeType.FIREBASE_AUTH_NETWORK_FAILED:
        this.setState(ErrorCodeType.REQUEST_TIMEOUT_ERROR);
        break;
      // 400 & 500
      case ErrorCodeType.CLIENT_ERROR:
      case ErrorCodeType.SERVER_ERROR:
        this.setState(ErrorCodeType.SERVER_ERROR);
        break;
      default:
        this.setState(ErrorCodeType.SERVER_ERROR);
    }
  };
}

export default ErrorHandler;

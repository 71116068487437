import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../../atoms/utils/layout/Box/FlexBox';
import Display from '../../../../../atoms/div/Display';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormIdDocumentUploadLayoutProps {
  children: React.ReactElement[];
  isDisplaySecondDropzone: boolean;
}

const FormIdDocumentUploadLayout: FC<FormIdDocumentUploadLayoutProps> = ({
  children,
  isDisplaySecondDropzone,
}: FormIdDocumentUploadLayoutProps) => {
  const elements = [
    'description',
    'dropzone1',
    'dropzone2',
    'checkPoint',
    'issuingCountry',
  ];
  const [
    description,
    dropzone1,
    dropzone2,
    checkPoint,
    issuingCountry,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn>
      {description}
      <Margin top={60} />
      <FlexBoxRow>
        {dropzone1}
        <Margin left={51} />
        <Display isDisplay={isDisplaySecondDropzone}>{dropzone2}</Display>
      </FlexBoxRow>
      <Margin top={40} />
      {checkPoint}
      <Margin top={60} />
      {issuingCountry}
    </FlexBoxColumn>
  );
};
export default FormIdDocumentUploadLayout;

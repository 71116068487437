import React from 'react';
import CryptoCurrencyTradeTransactions from './CryptoCurrencyTradeTransactions';

export interface CryptoCurrencyTradeTransactionContextProps {
  cryptoCurrencyTradeTransactions: CryptoCurrencyTradeTransactions;
}

const CryptoCurrencyTradeTransactionsContext = React.createContext<
  CryptoCurrencyTradeTransactionContextProps
>({
  cryptoCurrencyTradeTransactions: new CryptoCurrencyTradeTransactions(),
});

export default CryptoCurrencyTradeTransactionsContext;

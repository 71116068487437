/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { INITIAL } from '../../../../config/constants/requestState';

export interface VerifyAuthenticatorContextProps {
  verifyAuthenticatorState: string;
  setVerifyAuthenticatorState: (state: string) => void;
  verify: (code: string) => void;
}

const VerifyAuthenticatorContext = React.createContext<
  VerifyAuthenticatorContextProps
>({
  verifyAuthenticatorState: INITIAL,
  setVerifyAuthenticatorState: (state: string) => undefined,
  verify: (code: string) => undefined,
});

export default VerifyAuthenticatorContext;

import React, { FC } from 'react';
import Box from '../../utils/layout/Box/Box';
import ArrowTransferImage from '../../../../assets/images/arrowTransfer.svg';
import BankImage from '../../../../assets/images/bank.svg';
import CardImage from '../../../../assets/images/card.svg';

export interface DebitCardIconProps {
  width?: number | string;
  height?: number | string;
}

const ArrowTransferIcon: FC<DebitCardIconProps> = ({
  width = '100%',
  height = '100%',
}: DebitCardIconProps) => (
  <Box width={width} height={height}>
    <img
      src={ArrowTransferImage}
      alt="onlineBanking"
      width="100%"
      height="100%"
    />
  </Box>
);

const BankIcon: FC<DebitCardIconProps> = ({
  width = '100%',
  height = '100%',
}: DebitCardIconProps) => (
  <Box width={width} height={height}>
    <img src={BankImage} alt="onlineBanking" width="100%" height="100%" />
  </Box>
);

const CardIcon: FC<DebitCardIconProps> = ({
  width = '100%',
  height = '100%',
}: DebitCardIconProps) => (
  <Box width={width} height={height}>
    <img src={CardImage} alt="onlineBanking" width="100%" height="100%" />
  </Box>
);

export { ArrowTransferIcon, BankIcon, CardIcon };

import { TypeOfT } from '../../../../../types/typeof/Translation.type';

// ValueObject
import DeliveryTrackingInformation from '../../../../../value/debitCard/DebitCardRequestContent/DeliveryTrackingInformation/DeliveryTrackingInformation';

const ORGANISM_ID = `organisms.table.TableCardDeliveryStatus`;

class TableCardDeliveryStatusMapper {
  static toTableData = (
    deliveryTrackingInformation: DeliveryTrackingInformation,
    t: TypeOfT,
  ) => {
    const props = deliveryTrackingInformation.getProps();

    return [
      {
        header: t(`${ORGANISM_ID}.th.carrier`),
        data: props.carrier,
      },
      {
        header: t(`${ORGANISM_ID}.th.trackingNumber`),
        data: props.trackingNumber,
      },
      {
        header: t(`${ORGANISM_ID}.th.trackingUrl`),
        data: props.trackingUrl,
      },
    ];
  };
}

export default TableCardDeliveryStatusMapper;

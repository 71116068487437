import React, { FC, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import ConsentFormFileContext from '../../../../../../../enhancers/fileHandler/verification/consentForm/ConsentFormFileProvider/ConsentFormFileContext';
import UserAuthorizedContext from '../../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// CloudStorage
import { useStorageImage } from '../../../../../../../infrastructure/firebase/cloudStorage/useStorageImage';
import { getUploadFileUrl } from '../../../../../../../utils/helpers/fileHelper';

// Type
import { KycRequestUploadFileType } from '../../../../../../../types/KycRequest.type';
import { FileType } from '../../../../../../../types/File.type';

// Style
import { DescriptionStyle } from './style';

// Component
import { TextSecondary } from '../../../../../../atoms/text2/Text2';
import KycFormContainer from '../../../../common/KycFormContainer';
import CheckPointList from '../../../../../list/CheckPointList';
import DropzoneArea from '../../../../../../molecules/dropzone2/DropzoneArea';
import FormConsentFormUploadLayout from './FormConsentFormUploadLayout';

// ValueObject
import ConsentFormContent from '../../../../../../../value/verification/consentForm/ConsentFormContent/ConsentFormContent';

const FORM_ID = `organisms.form.verification.FormConsentForm.consentFormUpload`;

export interface FormConsentFormUploadProps {
  consentFormContent?: ConsentFormContent;
}

const FormConsentFormUpload: FC<FormConsentFormUploadProps> = ({
  consentFormContent,
}: FormConsentFormUploadProps) => {
  const { t } = useTranslation();
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // Form
  const { setValue } = useFormContext();

  // FileHandler
  const { consentFormFile, setConsentFormFile } = useContext(
    ConsentFormFileContext,
  );

  // Storage
  const consentFormUrl = useStorageImage(
    consentFormContent?.getProps().documentUrl,
  ).storageUrl;

  /* *
   *
   *  UseEffect
   *
   * */

  // Set consentFormUrl when File is uploaded
  useEffect(() => {
    if (consentFormFile && userAuthorized) {
      const storageFileUrl = getUploadFileUrl(
        userAuthorized,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (consentFormFile as any).path,
        KycRequestUploadFileType.consentForm,
      );
      setValue('documentUrl', storageFileUrl);
    }
    // eslint-disable-next-line
  }, [consentFormFile]);

  return (
    <KycFormContainer order={3} formId={FORM_ID}>
      <FormConsentFormUploadLayout>
        <TextSecondary key="description" theme={DescriptionStyle}>
          {t(`${FORM_ID}.description`)}
        </TextSecondary>
        <DropzoneArea
          key="dropzone"
          name="document"
          acceptFileTypes={[FileType.pdf]}
          defaultFilePath={consentFormUrl || ''}
          fileType={FileType.pdf}
          maxFileSize={10}
          setFile={setConsentFormFile}
          width={667}
        />
        <CheckPointList
          key="checkPoint"
          title={t(`organisms.CheckPointList.title`)}
          checkPoints={[
            t(`organisms.CheckPointList.text.signatureSame`),
            t(`organisms.CheckPointList.text.allPageSigned`),
            t(`organisms.CheckPointList.text.clear`),
          ]}
        />
      </FormConsentFormUploadLayout>
    </KycFormContainer>
  );
};

export default FormConsentFormUpload;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface FormProofOfIdentityLayoutProps {
  children: React.ReactElement[];
  showErrorMessage: boolean;
}

const FormProofOfIdentityLayout: FC<FormProofOfIdentityLayoutProps> = ({
  children,
  showErrorMessage,
}: FormProofOfIdentityLayoutProps) => {
  const elements = [
    'personalInformation',
    'documentType',
    'idDocument',
    'passportInput',
    'passport',
    'selfie',
    'signatureExplanation',
    'signature',
    'shippingAddress',
    'cardOptions',
    'errorMessage',
    'button',
  ];
  const [
    personalInformation,
    documentType,
    idDocument,
    passportInput,
    passport,
    selfie,
    signatureExplanation,
    signature,
    shippingAddress,
    cardOptions,
    errorMessage,
    button,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn width={1200} height="auto">
      {personalInformation}
      <Margin top={60} />
      {documentType}
      <Margin top={60} />
      {idDocument}
      <Margin top={60} />
      {passportInput}
      <Margin top={60} />
      {passport}
      <Margin top={60} />
      {selfie}
      <Margin top={60} />
      {signatureExplanation}
      <Margin top={60} />
      {signature}
      <Margin top={60} />
      {shippingAddress}
      <Margin top={60} />
      {cardOptions}
      <Margin top={30} />
      <FlexBoxRow justifyContent="center">
        {showErrorMessage && errorMessage}
      </FlexBoxRow>
      <Margin top={30} />
      {button}
      <Margin top={83} />
    </FlexBoxColumn>
  );
};
export default FormProofOfIdentityLayout;

// React
import React, { FC } from 'react';
import { TextPrimary, TextSecondary, TextThemeProps } from '../Text2';

// Error
import InvalidDataFoundError from '../../../../utils/errors/InvalidDataFoundError';

export interface TextStyledProps {
  children: React.ReactNode;
  fontFamilyType: 'primary' | 'secondary';
  theme: TextThemeProps;
}

const TextStyled: FC<TextStyledProps> = ({
  children,
  fontFamilyType,
  theme,
}: TextStyledProps) => {
  switch (fontFamilyType) {
    case 'primary':
      return <TextPrimary theme={theme}>{children}</TextPrimary>;
    case 'secondary':
      return <TextSecondary theme={theme}>{children}</TextSecondary>;
    default:
      throw new InvalidDataFoundError(
        `Invalid fontFamilyType '${fontFamilyType}' is specified at TextStyled`,
      );
  }
};

export default TextStyled;

import Joi from '@hapi/joi';

// Error
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';

// Lib
import { convertMoneyRoundDown } from '../../../../../utils/helpers/currencyHelper';
import {
  addByDecimal,
  minusByDecimal,
  timesByDecimal,
  divideByDecimal,
} from '../../../../../utils/helpers/calculationHelper';

// Type
import { CurrencyPairType } from '../../../../../types/CurrencyPair.type';
import {
  CryptoCurrencyRateProps,
  CryptoCurrencyRatePropsFormat,
} from './CryptoCurrencyRate.type';
import CryptoCurrencyRate from './CryptoCurrencyRate';

class CryptoCurrencyRateFactory {
  static create = (id: CurrencyPairType, props: CryptoCurrencyRateProps) => {
    // RoundedDown
    const propsRoundedDown = CryptoCurrencyRateFactory.convertRoundDownProps(
      id,
      props,
    );

    const result = Joi.object(CryptoCurrencyRatePropsFormat).validate({
      ...propsRoundedDown,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: CryptoCurrencyRate.name,
          reason: result.error.details[0].message,
        },
      );

    return new CryptoCurrencyRate(id, propsRoundedDown);
  };

  static convertRoundDownProps = (
    id: CurrencyPairType,
    props: CryptoCurrencyRateProps,
  ) => {
    const buyRate = props.buyRate.original;
    const sellRate = props.buyRate.original;
    const { last, tradeFeeRate } = props;

    // Rate RoundDown
    const buyRateRoundedDown = convertMoneyRoundDown(buyRate, id);
    const sellRateRoundedDown = convertMoneyRoundDown(sellRate, id);
    const lastRoundedDown = convertMoneyRoundDown(last, id);

    // Rate Fee Included
    const buyRateFeeIncluded = timesByDecimal(
      buyRateRoundedDown,
      minusByDecimal(1, divideByDecimal(tradeFeeRate, 100)),
    );
    // buyRateRoundedDown * (1 - tradeFeeRate / 100);
    const sellRateFeeIncluded = timesByDecimal(
      sellRateRoundedDown,
      addByDecimal(1, divideByDecimal(tradeFeeRate, 100)),
    );

    // Rate Fee Included RoundDown
    const buyRateFeeIncludedRoundDown = convertMoneyRoundDown(
      buyRateFeeIncluded,
      id,
    );
    const sellRateFeeIncludedRoundDown = convertMoneyRoundDown(
      sellRateFeeIncluded,
      id,
    );

    return {
      ...props,
      buyRate: {
        original: buyRateRoundedDown,
        feeIncluded: buyRateFeeIncludedRoundDown,
      },
      sellRate: {
        original: sellRateRoundedDown,
        feeIncluded: sellRateFeeIncludedRoundDown,
      },
      last: lastRoundedDown,
    };
  };
}

export default CryptoCurrencyRateFactory;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Style
import { BoxStyle } from './style';

// Layout
import Margin from '../../../atoms/utils/layout/Margin';
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../atoms/utils/layout/Box/FlexBox';

export interface NotificationSignInTimeOutLayoutProps {
  children: React.ReactElement[];
}

const NotificationSignInTimeOutLayout: FC<NotificationSignInTimeOutLayoutProps> = ({
  children,
}: NotificationSignInTimeOutLayoutProps) => {
  const elements = [
    'icon',
    'title',
    'description',
    'stayButton',
    'signOutButton',
  ];
  const [
    icon,
    title,
    description,
    stayButton,
    signOutButton,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn
      alignItems="center"
      height="auto"
      padding={60}
      theme={BoxStyle}
      width={668}
    >
      <FlexBoxRow justifyContent="center" alignItems="center">
        {icon}
        <Margin left={15} />
        {title}
      </FlexBoxRow>
      <Margin top={15} />
      {description}
      <Margin top={30} />
      <FlexBoxRow
        alignItems="center"
        justifyContent="space-between"
        width="80%"
      >
        {stayButton}
        {signOutButton}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
export default NotificationSignInTimeOutLayout;

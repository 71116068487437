import { useCallback, useEffect, useState } from 'react';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from '../../../../utils/errors/ErrorHandler/IErrorHandler';

// Functions
import FirebasePrivateFunctions from '../../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// Firestore
import firestore from '../../../../infrastructure/firebase/firestore/client/firestoreClient';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';
import { uploadFile } from '../../../../utils/firebase/cloudStorage/upload';

// Type
import { ConsentFormDoc } from '../../../../utils/firebase/firestore/verification/ConsentForm.type';
import { FormInputConsentForm } from '../../../../components/organisms/form/verification/consentForm/FormConsentForm/FormConsentForm.type';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';
import { CONSENT_FORM_UPDATE_CONSENT_FORM } from '../../../../infrastructure/firebase/firebaseFunctions/endpoint';

// DomainObject
import ConsentForms from '../../../../utils/firebase/firestore/verification/ConsentForms';
import UserAuthorized from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useConsentForm = (userAuthorized?: UserAuthorized) => {
  const [state, setState] = useState<string>(INITIAL);
  const [consentFormDoc, setConsentFormDoc] = useState<ConsentFormDoc>();

  /* *
   *
   * Methods
   *
   * */
  const updateConsentForm = useCallback(
    async (
      content: FormInputConsentForm & { document?: File },
      callback?: () => void,
    ) => {
      if (!userAuthorized) return;

      setState(IS_LOADING);

      try {
        // ---check onLine ---- //
        checkInternetConnection();

        const definedContent = content;
        if (definedContent) {
          if (definedContent.document)
            await uploadFile(
              definedContent.document as File,
              definedContent.documentUrl,
            );
          delete definedContent.document;

          await new FirebasePrivateFunctions().privatePUT(
            `${CONSENT_FORM_UPDATE_CONSENT_FORM}/${userAuthorized.getId()}`,
            definedContent,
          );

          setState(SUCCESS);

          if (callback) callback();
        }
      } catch (error) {
        const handler: IErrorHandler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    [userAuthorized],
  );

  const fetchConsentFormDoc = useCallback(async () => {
    if (!userAuthorized) return;

    // production
    new ConsentForms().subscribeSpecific<ConsentFormDoc | undefined>(
      userAuthorized.getId(),
      setConsentFormDoc,
      (doc: firestore.DocumentSnapshot) => doc.data() as ConsentFormDoc,
    );
  }, [userAuthorized]);

  /* *
   *
   * UseEffect
   *
   * */
  useEffect(() => {
    let isMounted = true;

    if (isMounted && userAuthorized) fetchConsentFormDoc();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [userAuthorized]);

  return {
    consentFormDoc,
    updateConsentForm,
    state,
    setState,
  };
};

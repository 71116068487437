import Joi from '@hapi/joi';

// Type
import {
  DebitCardSpotTransactionV2Props,
  DebitCardSpotTransactionV2PropsFormat,
} from '../../DebitCardSpotTransactionV2/DebitCardSpotTransactionV2/DebitCardSpotTransactionV2.type';
import { DebitCardWithdrawalType } from '../../../../../../types/DebitCardTransaction.type';

export interface DebitCardWithdrawalV2Props
  extends DebitCardSpotTransactionV2Props {
  type: DebitCardWithdrawalType;
}

export const DebitCardWithdrawalV2PropsFormat = {
  ...DebitCardSpotTransactionV2PropsFormat,

  type: Object.keys(DebitCardWithdrawalType)
    .reduce((joi, type) => joi.valid(type), Joi.string())
    .required(),
};

import { CurrencyType } from '../../../../types/Currency.type';
import { TransferSavingsAccountType } from '../../../../types/Transfer.type';

class FeeTransferSavingsAccount {
  private transferType: TransferSavingsAccountType;

  private currency: CurrencyType;

  private fee: number;

  constructor(
    transferType: TransferSavingsAccountType,
    props: {
      currency: CurrencyType;
      fee: number;
    },
  ) {
    this.transferType = transferType;
    this.currency = props.currency;
    this.fee = props.fee;
  }

  public getProps = () => ({
    currency: this.currency,
    fee: this.fee,
  });
}
export default FeeTransferSavingsAccount;

import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessHandlerUserStatusProvider from '../enhancers/routing/AccessHandlerUserStatusProvider';
import AuthenticatedPageVewProvider from '../enhancers/useCase/authentication/AuthenticatedPageViewProvider';

// Type
import { MenuType } from '../config/menu/main/Menu.type';

// Constant
import { ROUTE_ASSET_SUMMARY } from '../config/constants/routing';
import { ALLOWED_USER_STATUS } from './accessHandler/userStatusPageAccessMapper';

// Component
import { EnhancedAssetSummaryPage } from '../containers/asset/AssetSummaryPage';

const AssetRouter: FC = () => {
  return (
    <Switch>
      <AuthenticatedPageVewProvider>
        <AccessHandlerUserStatusProvider
          statusAllowed={ALLOWED_USER_STATUS[MenuType.assets]}
        >
          <Route exact path={ROUTE_ASSET_SUMMARY}>
            <EnhancedAssetSummaryPage />
          </Route>
        </AccessHandlerUserStatusProvider>
      </AuthenticatedPageVewProvider>
    </Switch>
  );
};

export default AssetRouter;

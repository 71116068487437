import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';
import Margin from '../../../../../atoms/utils/layout/Margin';
import { Width } from '../../../../../atoms/utils/layout/Width';
import { Container } from './style';

export interface FormInputTransferForbesAccountLayoutProps {
  children: React.ReactElement[];
}

const FormInputTransferForbesAccountLayout: FC<FormInputTransferForbesAccountLayoutProps> = ({
  children,
}: FormInputTransferForbesAccountLayoutProps) => {
  const elements = ['bankName', 'accountNumber', 'amount', 'purpose'];
  const [bankName, accountNumber, amount, purpose] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <Container>
      {bankName}
      <Margin top={65} />
      {accountNumber}
      <Margin top={65} />
      <Width widthValue="100%">{amount}</Width>
      <Margin top={65} />
      {purpose}
    </Container>
  );
};

export default FormInputTransferForbesAccountLayout;

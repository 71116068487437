import React, { FC } from 'react';
import { Container, Content } from './style';

export interface TransferTemplateContainerProps {
  children: React.ReactNode;
}

const TransferTemplateContainer: FC<TransferTemplateContainerProps> = ({
  children,
}: TransferTemplateContainerProps) => (
  <Container>
    <Content>{children}</Content>
  </Container>
);

export default TransferTemplateContainer;

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { ModalProvider } from 'react-modal-hook';
import Modal from 'react-modal';
import RootRouter from './routers/rootRouter';

Modal.setAppElement('#root');

const App = () => (
  <ModalProvider>
    <Router>
      <RootRouter />
    </Router>
  </ModalProvider>
);

export default App;

import { useState } from 'react';

export const useCherryVisa01ProofOfIdentityFile = () => {
  const [document1, setDocument1] = useState<File | undefined>();
  const [document2, setDocument2] = useState<File | undefined>();
  const [passport, setPassport] = useState<File | undefined>();
  const [selfie, setSelfie] = useState<File | undefined>();
  const [signature, setSignature] = useState<File | undefined>();

  return {
    document1,
    setDocument1,
    document2,
    setDocument2,
    passport,
    setPassport,
    selfie,
    setSelfie,
    signature,
    setSignature,
  };
};

import React, { FC } from 'react';
import { MenuType } from '../../../../config/menu/main/Menu.type';
import { TimeDepositMenuType } from '../../../../config/menu/sub/TimeDepositMenu.type';
import MenuItemWithSub from '../MenuItemWithSub';

const MAIN_MENU_TYPE = MenuType.timeDeposits;

const TimeDepositMenu: FC = () => {
  const timeDepositMenuItems = Object.keys(TimeDepositMenuType);

  return (
    <MenuItemWithSub
      key={MAIN_MENU_TYPE}
      type={MAIN_MENU_TYPE}
      menuItems={timeDepositMenuItems}
    />
  );
};

export default TimeDepositMenu;

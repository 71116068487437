import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { CautionStyle, DescriptionStyle } from './style';

// Context
import ContractTimeDepositRenewalReservationDeleteContext from '../../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositRenewalReservationDeleteProvider/ContractTimeDepositRenewalReservationDeleteContext';

// Component
import { TextSecondary } from '../../../atoms/text2/Text2';
import HeaderContractTimeDepositRenewalReservationDeleteConfirm from '../../../organisms/header/HeaderContentTitle/contract/HeaderContractTimeDepositRenewalReservationDeleteConfirm';
import ProgressStepBarBankTransaction from '../../../molecules/progressStep/ProgressStepBar/ProgressStepBarBankTransaction';
import PropertyListContractTimeDepositAfterMatured from '../../../organisms/propertyList/contract/PropertyListContractTimeDepositAfterMatured';
import FormContractTimeDepositRenewalReservationConfirm from '../../../organisms/form/contract/contractTimeDeposit/FormContractTimeDepositRenewalReservationConfirm';
import ContractTimeDepositRenewalReservationDeleteConfirmTemplateLayout from './ContractTimeDepositRenewalReservationDeleteConfirmTemplateLayout';

const TEMPLATE_ID =
  'templates.contract.ContractTimeDepositRenewalReservationDeleteConfirmTemplate';

const ContractTimeDepositRenewalReservationDeleteConfirmTemplate: FC = () => {
  const { t } = useTranslation();

  // Execute Update
  const {
    requestState,
    deleteContractTimeDepositRenewalReservation,
  } = useContext(ContractTimeDepositRenewalReservationDeleteContext);

  return (
    <ContractTimeDepositRenewalReservationDeleteConfirmTemplateLayout>
      <HeaderContractTimeDepositRenewalReservationDeleteConfirm key="header" />
      <ProgressStepBarBankTransaction
        key="progressStep"
        currentStep="confirm"
      />
      <TextSecondary key="description" theme={DescriptionStyle}>
        <TextSecondary theme={CautionStyle}>
          {t(`${TEMPLATE_ID}.cancel`)}
        </TextSecondary>
        &nbsp;
        {t(`${TEMPLATE_ID}.description`)}
      </TextSecondary>
      <FormContractTimeDepositRenewalReservationConfirm
        key="form"
        requestState={requestState}
        onSubmit={deleteContractTimeDepositRenewalReservation}
      />
      <PropertyListContractTimeDepositAfterMatured key="propertyList" />
    </ContractTimeDepositRenewalReservationDeleteConfirmTemplateLayout>
  );
};
export default ContractTimeDepositRenewalReservationDeleteConfirmTemplate;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { StyledSelect } from './style';

export interface SelectColumnFilterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: { filterValue: any; preFilteredRows: any; setFilter: any; id: any };
}

const SelectColumnFilter: FC<SelectColumnFilterProps> = ({
  // eslint-disable-next-line react/prop-types
  column: { filterValue, preFilteredRows, setFilter, id },
}: // width,
SelectColumnFilterProps) => {
  const options = React.useMemo(() => {
    const optionsInMemo = new Set<any>();
    preFilteredRows.forEach((row: any) => {
      optionsInMemo.add(row.values[id]);
    });
    return [...optionsInMemo.values()];
  }, [id, preFilteredRows]);

  return (
    <StyledSelect
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.sort().map((option: any, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </StyledSelect>
  );
};

export default SelectColumnFilter;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { UseStateType } from '../../../../types/typeof/UseState';
import { Container, MenuItemContainer } from './style';
import Margin from '../../../atoms/utils/layout/Margin';
import { FlexBoxRow } from '../../../atoms/utils/layout/Box/FlexBox';

export interface MenuItemWithSubLayoutProps {
  children: React.ReactElement[];
  setIsSubMenuVisible: UseStateType<boolean>;
}

const MenuItemWithSubLayout: FC<MenuItemWithSubLayoutProps> = ({
  children,
  setIsSubMenuVisible,
}: MenuItemWithSubLayoutProps) => {
  const elements = ['icon', 'menu', 'subMenu'];
  const [icon, menu, subMenu] = getElementsFromKeys(children, elements);

  return (
    <Container
      onMouseEnter={() => setIsSubMenuVisible(true)}
      onMouseLeave={() => setIsSubMenuVisible(false)}
    >
      <MenuItemContainer>
        <FlexBoxRow width={35} height={30} alignItems="center">
          {icon}
        </FlexBoxRow>
        <Margin left={12} />
        {menu}
      </MenuItemContainer>
      {subMenu}
    </Container>
  );
};
export default MenuItemWithSubLayout;

import { useState } from 'react';
import ReferralsTimeDeposit from './ReferralsTimeDeposit';

export const useReferralsTimeDeposit = (
  defaultValue?: ReferralsTimeDeposit,
) => {
  const [referralsTimeDeposit, setReferralsTimeDeposit] = useState<
    ReferralsTimeDeposit
  >(defaultValue || new ReferralsTimeDeposit());

  return {
    referralsTimeDeposit,
    setReferralsTimeDeposit,
  };
};

import React, { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { throttle } from 'lodash';
import { GradationBlueButton, DisabledButton } from './Button';

export interface SubmitButtonProps {
  children: React.ReactNode;
  height?: string | number;
  sizeProgress?: number;
  onClick?: (e?: React.MouseEvent) => void;
  disabled?: boolean;
  isLoading?: boolean;
  theme?: {
    borderRadius?: string | number;
  };
  width?: string | number;
}

const SubmitButton: FC<SubmitButtonProps> = ({
  children,
  width = '100%',
  height = '100%',
  sizeProgress = 30,
  disabled = false,
  isLoading = false,
  onClick = () => undefined,
  theme,
}: SubmitButtonProps) => {
  if (disabled)
    return (
      <DisabledButton
        disabled={disabled}
        height={height}
        theme={theme}
        width={width}
      >
        {children}
      </DisabledButton>
    );

  const throttledOnClick = throttle((e) => onClick(e), 1500);

  return (
    <GradationBlueButton
      height={height}
      onClick={throttledOnClick}
      theme={theme}
      width={width}
      disabled={isLoading}
    >
      {(() => {
        if (isLoading)
          return <CircularProgress size={sizeProgress} color="primary" />;
        return <>{children}</>;
      })()}
    </GradationBlueButton>
  );
};

export default SubmitButton;

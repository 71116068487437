import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormSelfieUploadLayoutProps {
  children: React.ReactElement[];
}

const FormSelfieUploadLayout: FC<FormSelfieUploadLayoutProps> = ({
  children,
}: FormSelfieUploadLayoutProps) => {
  const elements = [
    'description',
    'dropzone',
    'exampleOK',
    'exampleNG1',
    'exampleNG2',
    'exampleNG3',
    'checkPoint',
  ];
  const [
    description,
    dropzone,
    exampleOK,
    exampleNG1,
    exampleNG2,
    exampleNG3,
    checkPoint,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn>
      {description}
      <Margin top={60} />
      {dropzone}
      <Margin top={72} />
      <FlexBoxRow>
        {exampleOK}
        <Margin left={43} />
        {exampleNG1}
        <Margin left={43} />
        {exampleNG2}
        <Margin left={43} />
        {exampleNG3}
      </FlexBoxRow>
      <Margin top={60} />
      {checkPoint}
    </FlexBoxColumn>
  );
};
export default FormSelfieUploadLayout;

import React, { FC } from 'react';

// Component
import DebitCardNumber from '../DebitCardNumber';
import DebitCardValidThrough from '../DebitCardValidThrough';
import DebitCardTypeImage from '../../../atoms/image/DebitCard/DebitCardTypeImage';
import DebitCardImageLayout from './DebitCardImageLayout';

// DomainObject
import DebitCardV2 from '../../../../domain/debitCard/debitCardV2/DebitCardV2/DebitCardV2';

export interface DebitCardImageProps {
  debitCardV2: DebitCardV2;
  layout: {
    body: {
      width: string | number;
      height: string | number;
    };
    cardNumber: {
      width4digits: string | number;
      height: string | number;
    };
    oneDigit: {
      width: string | number;
      height: string | number;
    };
    cardTypeLogo: {
      width: string | number;
      height: string | number;
    };
    cardValidThruLogo: {
      width: string | number;
      height: string | number;
    };
    cardSlashChar: {
      width: string | number;
      height: string | number;
    };
  };
}

const DebitCardImage: FC<DebitCardImageProps> = ({
  debitCardV2,
  layout,
}: DebitCardImageProps) => {
  const { type } = debitCardV2.getProps();

  return (
    <DebitCardImageLayout layout={layout}>
      <DebitCardNumber key="number" debitCardV2={debitCardV2} layout={layout} />
      <DebitCardTypeImage
        key="type"
        type={type}
        layout={{ width: '100%', height: '100%' }}
      />
      <DebitCardValidThrough key="validThru" debitCardV2={debitCardV2} />
    </DebitCardImageLayout>
  );
};

export default DebitCardImage;

import { CurrencyType } from '../../types/Currency.type';
import {
  CryptoCurrencyType,
  CryptoCurrencyProps,
} from '../../types/CryptoCurrency.type';

/*
 * function getAddressQRCodeValue
 *
 * return value of QRCode for cryptocurrency
 */
export const getAddressQRCodeValue = (
  currency: CurrencyType,
  address: string,
): string => {
  if (Object.keys(CryptoCurrencyType).includes(currency))
    return `${CryptoCurrencyProps[currency].qrCodePrefix}${address}?amount=`;
  throw new Error(
    `Invalid Currency ${currency} is found at getAddressQRCodeValue`,
  );
};

/*
 * function getExplorerUrl
 *
 * return explorerUrl
 */
export const getExplorerUrl = (
  currency: CryptoCurrencyType,
  txid: string,
): string => {
  if (Object.keys(CryptoCurrencyType).includes(currency))
    return `${CryptoCurrencyProps[currency].explorerUrl}${txid}`;
  throw new Error(
    `Invalid Currency ${currency} is found at getAddressQRCodeValue`,
  );
};

// Type
import { CherryAPIFilePurposeType } from '../../../../types/CherryAPIFilePurposeType';

// IUseCase
import IDebitCardRequestCherryVisa01ProofOfReceiptUpdateUseCase from '../../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01ProofOfReceiptUpdateProvider/IDebitCardRequestCherryVisa01ProofOfReceiptUpdateUseCase';

// IService
import ICherryStorageService from '../../../storage/ICherryStorageService';
import IDebitCardRequestCherryVisa01UpdateService from './IDebitCardRequestCherryVisa01UpdateService';

// Service
import DebitCardRequestCherryVisa01UpdateService from '../../../../service/debitCard/debitCardRequest/debitCardRequestCherryVisa01/DebitCardRequestCherryVisa01UpdateService';
import CherryStorageService from '../../../../service/storage/CherryStorageService';

// IRepository
import ICherryStorageHandler from '../../../../storage/cherryStorageHandler/ICherryStorageHandler';
import { IDebitCardRequestCherryVisa01UpdateRepository } from '../../../../domain/debitCard/debitCardRequest/IDebitCardRequestCherryVisa01Repository';

// DomainObject
import DebitCardRequestCherryVisa01 from '../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01';

class DebitCardRequestCherryVisa01ProofOfReceiptUpdateUseCase
  implements IDebitCardRequestCherryVisa01ProofOfReceiptUpdateUseCase {
  private debitCardRequestCherryVisa01UpdateService: IDebitCardRequestCherryVisa01UpdateService;

  private storageService: ICherryStorageService;

  constructor(
    debitCardRequestCherryVisa01Repository: IDebitCardRequestCherryVisa01UpdateRepository,
    storageHandler: ICherryStorageHandler,
  ) {
    this.debitCardRequestCherryVisa01UpdateService = new DebitCardRequestCherryVisa01UpdateService(
      debitCardRequestCherryVisa01Repository,
    );

    this.storageService = new CherryStorageService(storageHandler);
  }

  public update = async (
    debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01,
    selfie: File | undefined,
  ) => {
    // Upload Selfie
    const uploadSelfie = async () => {
      if (selfie) {
        return this.storageService.upload(
          selfie,
          CherryAPIFilePurposeType.selfie,
        );
      }

      return undefined;
    };

    // UploadFile
    const selfieId = await uploadSelfie();

    const updatedDebitCardRequestCherryVisa01 = await this.debitCardRequestCherryVisa01UpdateService.updateProofOfReceipt(
      debitCardRequestCherryVisa01,
      selfieId,
    );

    return updatedDebitCardRequestCherryVisa01;
  };

  executeUpdate = async (
    debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01,
  ) => {
    const updatedDebitCardRequestCherryVisa01 = await this.debitCardRequestCherryVisa01UpdateService.executeUpdateProofOfReceipt(
      debitCardRequestCherryVisa01,
    );

    return {
      debitCardRequestCherryVisa01: updatedDebitCardRequestCherryVisa01,
    };
  };
}

export default DebitCardRequestCherryVisa01ProofOfReceiptUpdateUseCase;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { FlexBoxRow } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface TransferDebitCardIconGroupLayoutProps {
  children: React.ReactElement[];
}

const TransferDebitCardIconGroupLayout: FC<TransferDebitCardIconGroupLayoutProps> = ({
  children,
}: TransferDebitCardIconGroupLayoutProps) => {
  const elements = ['left', 'center', 'right'];
  const [left, center, right] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow width="100%" justifyContent="center" alignItems="center">
      {left}
      <Margin left={10} />
      {center}
      <Margin left={10} />
      {right}
    </FlexBoxRow>
  );
};
export default TransferDebitCardIconGroupLayout;

import React from 'react';
import TimeDeposit from './TimeDeposit';

export interface TimeDepositContextProps {
  timeDeposit?: TimeDeposit;
}

const TimeDepositContext = React.createContext<TimeDepositContextProps>({
  timeDeposit: undefined,
});

export default TimeDepositContext;

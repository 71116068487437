import React, { FC } from 'react';
import fontColor from '../../../../fonts/color.json';
import fontSize from '../../../../fonts/fontSizePixel.json';
import fontWeight from '../../../../fonts/fontWeight.json';
import TextField from './TextField';

export interface LabeledTextFieldProps {
  name: string;
  value?: string;
  type?: 'password';
  label?: string;
  labelColor?: string;
  labelPixel?: number;
  labelFontWeight?: number;
  labelFontFamilyType?: 'primary' | 'sub';
  placeholder?: string;
  maxLength: number;
  errorMessage?: string;
  disabled?: boolean;
  disabledBackgroundColor?: string;
  textInputWidth?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any;
  onChange?: (text: string) => void;
}

const LabeledTextField: FC<LabeledTextFieldProps> = ({
  name = '',
  value = '',
  type,
  label = '',
  labelColor = fontColor.textBright,
  labelPixel = fontSize.pixel22,
  labelFontWeight = fontWeight.regular,
  labelFontFamilyType = 'primary' as 'primary' | 'sub',
  placeholder = '',
  maxLength = 50,
  errorMessage = '',
  disabled = false,
  disabledBackgroundColor = '#DBDDE8',
  textInputWidth = `${400 / 16}rem`,
  register = null,
  onChange = () => undefined,
}: LabeledTextFieldProps) => (
  <TextField
    name={name}
    type={type}
    value={value}
    label={label}
    labelColor={labelColor}
    labelPixel={labelPixel}
    labelFontWeight={labelFontWeight}
    labelFontFamilyType={labelFontFamilyType}
    errorMessage={errorMessage}
    placeholder={placeholder}
    maxLength={maxLength}
    disabled={disabled}
    disabledBackgroundColor={disabledBackgroundColor}
    textInputWidth={textInputWidth}
    register={register}
    onChange={onChange}
  />
);

export default LabeledTextField;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormSignatureExplanationLayoutProps {
  children: React.ReactElement[];
}

const FormSignatureExplanationLayout: FC<FormSignatureExplanationLayoutProps> = ({
  children,
}: FormSignatureExplanationLayoutProps) => {
  const elements = ['description', 'exampleImage'];
  const [description, exampleImage] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn justifyContent="start">
      {description}
      <Margin top={50} />
      <FlexBoxRow width={'50%'}>{exampleImage}</FlexBoxRow>
    </FlexBoxColumn>
  );
};
export default FormSignatureExplanationLayout;

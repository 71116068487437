import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import BankTransactionOptionContext from '../../../../molecules/selector/tabBar/UnderBarTabBar/BankTransactionTabBar/BankTransactionOptionContext';

// Type
import { TransactionType } from '../../../../../types/Transaction.type';

// Constant
import { CRYPTO_CURRENCY_DEPOSIT_WITHDRAWAL } from '../../../../../config/constants/pageId.json';

// Component
import { PageTitle } from '../../../../atoms/headLine/HeadLine';
import DepositWithdrawalTabBar from '../../../../molecules/selector/tabBar/UnderBarTabBar/BankTransactionTabBar';
import CryptoCurrencyDepositTemplate from '../CryptoCurrencyDepositTemplate';
import CryptoCurrencyWithdrawalTemplate from '../CryptoCurrencyWithdrawalTemplate';
import CryptoCurrencyDepositWithdrawalTemplateLayout from './CryptoCurrencyDepositWithdrawalTemplateLayout';

const CryptoCurrencyDepositWithdrawalTemplate: FC = () => {
  const { t } = useTranslation();
  const { transactionOption } = useContext(BankTransactionOptionContext);
  const isDeposit = transactionOption === TransactionType.deposit;

  return (
    <CryptoCurrencyDepositWithdrawalTemplateLayout isDeposit={isDeposit}>
      <PageTitle key="header">
        {t(`${CRYPTO_CURRENCY_DEPOSIT_WITHDRAWAL}.title`)}
      </PageTitle>
      <DepositWithdrawalTabBar key="tabBar" />
      <CryptoCurrencyDepositTemplate key="deposit" />
      <CryptoCurrencyWithdrawalTemplate key="withdrawal" />
    </CryptoCurrencyDepositWithdrawalTemplateLayout>
  );
};

export default CryptoCurrencyDepositWithdrawalTemplate;

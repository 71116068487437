import React, { FC } from 'react';
import SavingsAccountHistoryPage from './SavingsAccountHistoryPage';
import SavingsAccountHistoryViewProvider from '../../../enhancers/useCase/bankAccount/savingsAccount/SavingsAccountHistoryViewProvider';

const EnhancedSavingsAccountHistoryPage: FC = () => {
  return (
    <SavingsAccountHistoryViewProvider>
      <SavingsAccountHistoryPage />
    </SavingsAccountHistoryViewProvider>
  );
};

export default EnhancedSavingsAccountHistoryPage;

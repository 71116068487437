import React, { FC, useContext } from 'react';
import ContractTimeDepositRenewalReservationPageStateContext from '../../../enhancers/pageState/contract/ContractTimeDepositRenewalReservationPageState/ContractTimeDepositRenewalReservationPageStateContext';

// Error
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';

// Component
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import ContractTimeDepositRenewalReservationAddTemplate from '../../../components/templates/contractTimeDeposit/ContractTimeDepositRenewalReservationAddTemplate';
import ContractTimeDepositRenewalReservationAddConfirmTemplate from '../../../components/templates/contractTimeDeposit/ContractTimeDepositRenewalReservationAddConfirmTemplate';
import ContractTimeDepositRenewalReservationAddCompleteTemplate from '../../../components/templates/contractTimeDeposit/ContractTimeDepositRenewalReservationAddCompleteTemplate';

const ContractTimeDepositRenewalReservationAddPage: FC = () => {
  const { pageState } = useContext(
    ContractTimeDepositRenewalReservationPageStateContext,
  );

  const TemplateComponent: FC = () => {
    switch (pageState) {
      case 'initial':
        return <ContractTimeDepositRenewalReservationAddTemplate />;
      case 'confirm':
        return <ContractTimeDepositRenewalReservationAddConfirmTemplate />;
      case 'complete':
        return <ContractTimeDepositRenewalReservationAddCompleteTemplate />;
      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_SWITCH,
          {
            value: pageState,
            place: `ContractTimeDepositRenewalReservationAddPage`,
          },
        );
    }
  };

  return (
    <DashboardTemplate>
      <TemplateComponent />
    </DashboardTemplate>
  );
};

export default ContractTimeDepositRenewalReservationAddPage;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import fontSize from '../../../../fonts/fontSizePixel.json';
import fontColor from '../../../../fonts/color.json';
import fontWeight from '../../../../fonts/fontWeight.json';
import TextSpinLoader from './TextSpinLoader';

const LoadingSpinLoader: FC = () => {
  const { t } = useTranslation();
  return (
    <TextSpinLoader
      text={t(`atoms.spinner.loadingSpinLoader.text`)}
      color={fontColor.textBright}
      textSize={fontSize.pixel38}
      textFontWeight={fontWeight.semibold}
      textFontFamilyType="sub"
      spinnerSize={208}
    />
  );
};
export default LoadingSpinLoader;

import React from 'react';

// Types
import { FormInputTransferDebitCard } from '../../../../../components/organisms/form/transfer/debitCard/FormTransferDebitCard/FormInputTransferDebitCard.type';

// Constant
import { INITIAL } from '../../../../../config/constants/requestState';

// DomainObject
import DebitCardV2 from '../../../../../domain/debitCard/debitCardV2/DebitCardV2/DebitCardV2';
import SavingsAccount from '../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

export interface TransferDebitCardCreateContextProps {
  requestState: string;

  createTransferDebitCard: (
    params: FormInputTransferDebitCard,
    savingsAccount: SavingsAccount,
    debitCard: DebitCardV2,
    callback?: () => void,
  ) => void;

  executeCreateTransferDebitCard: (code: string, callback?: () => void) => void;
}

const TransferDebitCardCreateContext = React.createContext<
  TransferDebitCardCreateContextProps
>({
  requestState: INITIAL,

  createTransferDebitCard: () => undefined,

  executeCreateTransferDebitCard: () => undefined,
});

export default TransferDebitCardCreateContext;

import firestore from '../firestore';
import Collection from '../collection/Collection';
import { FIRESTORE_DATA_VERSION } from '../../../../config/constants/firebase';

class ConsentForms extends Collection {
  collection: firestore.CollectionReference;

  constructor() {
    super();
    this.collection = firestore()
      .collection('verification')
      .doc(FIRESTORE_DATA_VERSION)
      .collection('consentForms');
  }
}

export default ConsentForms;

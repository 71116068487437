import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexRow } from '../../../../../atoms/utils/layout/Flex';
import { Width } from '../../../../../atoms/utils/layout/Width';

export interface FormInputTextLayoutProps {
  children: React.ReactElement[];
  inputWidth: string | number;
}

const FormInputTextLayout: FC<FormInputTextLayoutProps> = (
  props: FormInputTextLayoutProps,
) => {
  const { children, inputWidth } = props;
  const elements = ['label', 'input'];
  const [label, input] = getElementsFromKeys(children, elements);

  return (
    <Width widthValue="100%">
      <FlexRow justifyContent="space-between" alignItems="center">
        {label}
        <FlexRow alignItems="center">
          <Width widthValue={inputWidth}>{input}</Width>
        </FlexRow>
      </FlexRow>
    </Width>
  );
};
export default FormInputTextLayout;

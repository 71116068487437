import { useState, useCallback, useEffect } from 'react';

// Lib
import { executeWithTimeout } from '../../../../utils/helpers/timeout';
import { checkInternetConnection } from '../../../../utils/helpers/connection';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';
import { REQUEST_TIMEOUT } from '../../../../config/constants/business';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import NotificationLogViewUseCase from '../../../../useCases/notification/NotificationLogViewUseCaseX';

// Repository
import NotificationLogFirestore from '../../../../repository/notification/NotificationLogFirestore';

// DomainObject
import { useNotificationLogCustodyFeeAndTopUpFee } from '../../../../domain/notification/notificationLog/NotificationLogCustodyFeeAndTopUpFee/useNotificationLogCustodyFeeAndTopUpFee';
import UserAuthorized from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useNotificationLogViewUseCase = (
  userAuthorized: UserAuthorized | undefined,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /**
   *
   *  DomainObject
   *
   */

  // NotificationLog
  const {
    notificationLogCustodyFeeAndTopUpFee,
    setNotificationLogCustodyFeeAndTopUpFee,
  } = useNotificationLogCustodyFeeAndTopUpFee();

  /**
   *
   *  Repository
   *
   */
  const notificationLogRepository = new NotificationLogFirestore();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new NotificationLogViewUseCase(notificationLogRepository);

  /**
   *
   *  Method
   *
   */
  const openNotificationLog = useCallback(async () => {
    if (!userAuthorized) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const outputs = await executeWithTimeout(
        useCase.open(userAuthorized),
        REQUEST_TIMEOUT,
      );
      // Setter (DomainObject)
      setNotificationLogCustodyFeeAndTopUpFee(outputs.notificationLog);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized]);

  /**
   *
   *  UseEffect
   *
   */
  useEffect(
    () => {
      let isMounted = true;

      if (isMounted && userAuthorized) openNotificationLog();

      return () => {
        isMounted = false;
      };
    },
    // eslint-disable-next-line
   [userAuthorized],
  );

  return {
    state,
    setState,
    notificationLog: notificationLogCustodyFeeAndTopUpFee,
  };
};

import Joi from '@hapi/joi';

// Type
import { CherryImage } from '../../../../types/CherryImage.type';
import {
  DebitCardRequestContentProps,
  DebitCardRequestContentPropsFormat,
} from '../DebitCardRequestContent/DebitCardRequestContent.type';

export interface CherryVisa01ProofOfReceiptProps
  extends DebitCardRequestContentProps {
  selfieId: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selfie?: any;
}

export type CherryVisa01ProofOfReceiptRawDataType = CherryVisa01ProofOfReceiptProps;

export type CherryVisa01ProofOfReceiptRawDataTypeWithFiles = CherryVisa01ProofOfReceiptRawDataType & {
  selfieId: string;
  selfieImage?: CherryImage;
};

export const CherryVisa01ProofOfReceiptPropsFormat = {
  ...DebitCardRequestContentPropsFormat,

  selfieId: Joi.string().required(),

  selfie: Joi.any(),
};

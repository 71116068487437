import React, { FC } from 'react';

// Lib
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';

// Constants
import color from '../../../../atoms/utils/const/color';

// Style
import { TableStyle } from './style';

// LayoutComponent
import { TableBordered } from '../../../../atoms/table/Table/Table';
import Margin from '../../../../atoms/utils/layout/Margin';
import Display from '../../../../atoms/div/Display';

export interface HistoryTableLayoutProps {
  children: React.ReactElement[];
  pageCount: number;
  width?: number | string;
}

const HistoryTableLayout: FC<HistoryTableLayoutProps> = ({
  children,
  pageCount,
  width,
}: HistoryTableLayoutProps) => {
  const elements = ['thead', 'tbody', 'pagination'];
  const [thead, tbody, pagination] = getElementsFromKeys(children, elements);

  return (
    <TableStyle>
      <TableBordered
        borderWidth={1}
        borderColor={color.border.gray}
        width={width}
      >
        {thead}
        {tbody}
      </TableBordered>
      <Display isDisplay={pageCount > 1}>
        <Margin top={40}>{pagination}</Margin>
      </Display>
    </TableStyle>
  );
};
export default HistoryTableLayout;

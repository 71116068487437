/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { INITIAL } from '../../../config/constants/requestState';

export interface ForgotPasswordContextProps {
  forgotPasswordState: string;
  setForgotPasswordState: (state: string) => void;
  forgotPassword: (email: string) => void;
}

export const ForgotPasswordContext = React.createContext<
  ForgotPasswordContextProps
>({
  forgotPasswordState: INITIAL,
  setForgotPasswordState: (state: string) => undefined,
  forgotPassword: (email: string) => undefined,
});

import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Context
import ContractTimeDepositContext from '../../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDepositContext';
import ContractTimeDepositRenewalReservationPageStateContext from '../../../../../../enhancers/pageState/contract/ContractTimeDepositRenewalReservationPageState/ContractTimeDepositRenewalReservationPageStateContext';
import TimeDepositsContext from '../../../../../../domain/product/timeDeposit/TimeDeposits/TimeDepositsContext';

// Lib
import { getDefaultValues, getDepositAmount } from './helper';
import { contractTimeDepositRenewalReservationValidation } from '../../../../../../utils/validation/registers';

// Type
import {
  FormInputContractTimeDepositRenewalReservation,
  FormInputContractTimeDepositRenewalReservationContext,
} from './FormContractTimeDepositRenewalReservation.type';
import { UserType } from '../../../../../../types/User.type';

// Constant
import { BASE_CURRENCY_SYMBOL } from '../../../../../../config/constants/business';

// Style
import { LabelStyle, RadioButtonLayout, RadioButtonStyle } from './style';

// Component
import {
  ConfirmButton,
  DefaultButtonTextStyle,
} from '../../../../../atoms/button/Button2';
import { TextPrimary } from '../../../../../atoms/text2/Text2';
import FormInputRadioButton from '../../../../../molecules/form/formInput/radioButton/FormInputRadioButton';
import FormInputCurrencyAmount from '../../../../../molecules/form/formInput/textField/FormInputCurrencyAmount';
import FormInputTimeDepositFilteredSelectBox from '../../../../../molecules/form/formInput/selectBox/product/FormInputTimeDepositFilteredSelectBox';
import FormContractTimeDepositRenewalReservationLayout from './FormContractTimeDepositRenewalReservationLayout';

// DomainObject
import TimeDeposit from '../../../../../../domain/product/timeDeposit/TimeDeposit/TimeDeposit';
import TimeDeposits from '../../../../../../domain/product/timeDeposit/TimeDeposits/TimeDeposits';
import UserAuthorizedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

const FORM_ID = `organisms.form.contract.FormContractTimeDepositRenewalReservation`;

export interface FormContractTimeDepositRenewalReservationProps {
  onSubmit: (
    params: FormInputContractTimeDepositRenewalReservation,
    timeDeposit: TimeDeposit,
    callback?: () => void,
  ) => void;
}

const FormContractTimeDepositRenewalReservation: FC<FormContractTimeDepositRenewalReservationProps> = (
  props: FormContractTimeDepositRenewalReservationProps,
) => {
  const { contractTimeDeposit } = useContext(ContractTimeDepositContext);
  const { t } = useTranslation();
  const { formInput, setFormInput } = useContext(
    FormInputContractTimeDepositRenewalReservationContext,
  );
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // PageState
  const { pageState, setPageState } = useContext(
    ContractTimeDepositRenewalReservationPageStateContext,
  );

  // Options
  const { timeDeposits } = useContext(TimeDepositsContext);

  /**
   *
   *  Form
   *
   */

  const methods = useForm<FormInputContractTimeDepositRenewalReservation>({
    mode: 'onChange',
  });

  const {
    formState: { isValid },
    handleSubmit,
    setValue,
    register,
    reset,
    trigger,
    watch,
  } = methods;

  /**
   *
   *  DomainObject
   *
   */
  const timeDeposit = timeDeposits.filterById(watch(`timeDepositId`));

  const timeDepositsFiltered = useMemo(
    () =>
      timeDeposits.filterByIsAnnualPayOut(
        watch('isAnnualPayOut') === 'true',
      ) as TimeDeposits,
    // eslint-disable-next-line
    [watch('isAnnualPayOut'), timeDeposits],
  );

  /**
   *
   *  Default Variable
   *
   */

  // Set DefaultValue
  useEffect(() => {
    if (formInput) reset(formInput);
    // eslint-disable-next-line
  }, [formInput, pageState]);

  // Set DefaultValue from ContractTimeDeposit
  useEffect(() => {
    if (!formInput && contractTimeDeposit) {
      const defaultValues = getDefaultValues(contractTimeDeposit);
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [formInput, pageState]);

  // TimeDeposit
  useEffect(() => {
    if (!formInput && !contractTimeDeposit)
      setValue(`timeDepositId`, timeDepositsFiltered.head()?.getId());

    if (contractTimeDeposit && userAuthorized) {
      const amount = contractTimeDeposit.getPrincipalAfterMatured();
      const term = contractTimeDeposit.getTermAfterMatured();
      const userType = userAuthorized.getProps().type as UserType;
      const isAnnualPayOut = contractTimeDeposit.getIsAnnualPayOutAfterMatured();

      const timeDepositBestRate = timeDepositsFiltered.getBestRateProduct(
        term,
        amount,
        userType,
        isAnnualPayOut,
      );

      setValue(
        `timeDepositId`,
        timeDepositBestRate
          ? timeDepositBestRate.getId()
          : timeDepositsFiltered.head().getId(),
      );
    }

    // eslint-disable-next-line
  }, [timeDepositsFiltered, contractTimeDeposit, userAuthorized]);

  /**
   *
   *  UseEffect
   *
   */

  // Trigger validate when time deposit changed.
  useEffect(() => {
    if (watch(`principalIncreased`)) trigger(['principalIncreased']);
    // eslint-disable-next-line
  }, [timeDeposit]);

  /**
   *
   *  Event
   *
   */

  const onSubmit = useCallback(
    async (input: FormInputContractTimeDepositRenewalReservation) => {
      setFormInput(input);

      props.onSubmit(input, timeDeposit, () => {
        setPageState('confirm');
      });
    },
    // eslint-disable-next-line
    [props.onSubmit, timeDeposit],
  );

  if (!contractTimeDeposit) return <></>;

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <FormContractTimeDepositRenewalReservationLayout>
          <FormInputRadioButton
            key="payOut"
            labelWidth={440}
            labelProps={{
              label: t(`${FORM_ID}.label.isAnnualPayOut`),
              theme: LabelStyle,
            }}
            radioButtonProps={{
              displayItems: [
                t(`${FORM_ID}.payOut.maturity`),
                t(`${FORM_ID}.payOut.annual`),
              ],
              items: ['false', 'true'],
              layout: RadioButtonLayout,
              name: 'isAnnualPayOut',
              selected: watch('isAnnualPayOut') === 'true' ? 'true' : 'false',
              theme: RadioButtonStyle,
            }}
          />
          <FormInputTimeDepositFilteredSelectBox
            key="timeDeposit"
            name="timeDepositId"
            labelProps={{
              label: t(`${FORM_ID}.label.timeDeposit`),
              theme: LabelStyle,
            }}
            inputWidth={600}
            timeDeposits={timeDepositsFiltered}
          />
          <FormInputCurrencyAmount
            key="principalIncreased"
            currency={BASE_CURRENCY_SYMBOL}
            inputWidth={534}
            label={t(`${FORM_ID}.label.principalIncreased`)}
            name="principalIncreased"
            onChange={(value: string) => {
              setValue('principalIncreased', getDepositAmount(value));
              trigger(['principalIncreased']);
            }}
            register={register(
              contractTimeDepositRenewalReservationValidation(
                t,
              ).principalIncreased(
                timeDeposit?.getProps().minDeposit, // minDeposit
                contractTimeDeposit?.getProps().depositAmount, // depositAmount
              ),
            )}
          />
          <ConfirmButton key="button" height={75} disabled={!isValid}>
            <TextPrimary theme={DefaultButtonTextStyle}>
              {t(`atoms.button.confirm`)}
            </TextPrimary>
          </ConfirmButton>
        </FormContractTimeDepositRenewalReservationLayout>
      </FormProvider>
    </form>
  );
};

export default FormContractTimeDepositRenewalReservation;

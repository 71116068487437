import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Type
import { KycStatusType } from '../../../../types/KycRequest.type';

// Style
import { TextStyle } from './style';

// DomainObject
import { TextSecondary } from '../../../atoms/text2/Text2';
import StatusIcon from '../../../atoms/icon/StatusIcon';
import KycStatusBadgeLayout from './KycStatusBadgeLayout';

export interface KycStatusTypeBadgeProps {
  status?: KycStatusType;
  layout: {
    height: string | number;
    iconWidth: string | number;
    iconHeight: string | number;
  };
}

const KycStatusTypeBadge: FC<KycStatusTypeBadgeProps> = ({
  status,
  layout,
}: KycStatusTypeBadgeProps) => {
  const { t } = useTranslation();

  const displayBadgeStatus = [
    KycStatusType.approved,
    KycStatusType.submitted,
    KycStatusType.rejected,
    KycStatusType.reviewing,
  ];

  if (status && !displayBadgeStatus.includes(status)) return <></>;

  let [backgroundColor, color] = ['', ''];

  switch (status) {
    case KycStatusType.approved:
      [backgroundColor, color] = ['#EAF8D7', '#95CB4B'];
      break;
    case KycStatusType.submitted:
      [backgroundColor, color] = ['#C7EAFF', '#299ADF'];
      break;
    case KycStatusType.reviewing:
      [backgroundColor, color] = ['#FFF1D1', '#F5A026'];
      break;
    case KycStatusType.rejected:
      [backgroundColor, color] = ['#FFE4E2', '#E44838'];
      break;
    default:
      [backgroundColor, color] = ['', ''];
  }

  return (
    <KycStatusBadgeLayout
      backgroundColor={backgroundColor}
      height={layout.height}
    >
      <StatusIcon
        key="icon"
        status={status}
        height={layout.iconHeight}
        width={layout.iconWidth}
      />
      <TextSecondary
        key="text"
        theme={{
          ...TextStyle,
          color,
        }}
      >
        {t(`kycStatusType.${status}`)}
      </TextSecondary>
    </KycStatusBadgeLayout>
  );
};

export default KycStatusTypeBadge;

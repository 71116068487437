import React, { FC, useContext } from 'react';
import { useInvitationCodeViewUseCase } from './useInvitationCodesViewUseCase';
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';
import InvitationCodesContext from '../../../../domain/invite/invitationCode/InvitationCodes/InvitationCodesContext';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import UserVerifiedContext from '../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedContext';

export type InvitationCodeViewProviderProps = {
  children: React.ReactNode;
};

const InvitationCodesViewProvider: FC<InvitationCodeViewProviderProps> = ({
  children,
}: InvitationCodeViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    invitationCodes,
    userVerified,
  } = useInvitationCodeViewUseCase(userAuthorized);

  return (
    <UserVerifiedContext.Provider value={{ user: userVerified }}>
      <InvitationCodesContext.Provider value={{ invitationCodes }}>
        <SystemErrorHandler state={state} setState={setState}>
          {children}
        </SystemErrorHandler>
      </InvitationCodesContext.Provider>
    </UserVerifiedContext.Provider>
  );
};
export default InvitationCodesViewProvider;

import React from 'react';
import DocumentTypes from './DocumentTypes';

export interface DocumentTypesContextProps {
  documentTypes: DocumentTypes;
}

const DocumentTypesContext = React.createContext<DocumentTypesContextProps>({
  documentTypes: new DocumentTypes(),
});

export default DocumentTypesContext;

const PRIMARY = '#212F41';

export default {
  primary: PRIMARY,
  errorMessage: '#d06e77',
  success: '#5CB85C',
  background: {
    aliceBlue: '#EAF7FF',
    aliceBlue2: '#F2F6FF',
    disabled: '#DBDDE8',
    endeavour: '#15609B',
    froly: '#D06E77',
    ghostWhite: '#F0F1F7',
    monaLisa: '#FF928B',
    pattensBlue: '#E2EFF2',
    periwinkle: '#CDD0DD',
    primary: PRIMARY,
    quartz: '#E1E3ED',
    summerSky: '#299ADF',
    success: '#5CB85C',
    threeDFace: 'ThreeDFace',
    varden: '#FFF1D1', // Reviewing
    white: '#FFFFFF',
  },
  border: {
    gray: '#D5D0DA',
    hawkesBlue: '#CDDAE8',
    lightBlue: '#D6DDEC',
    periwinkle: '#CDD0DD',
    primary: '#212F41',
    white: '#FFFFFF',
  },
  buy: {
    text: '#D06E77',
    box: '#D06E77',
  },
  check: {
    checkedBlue: '#1780c1',
  },
  icon: {
    black: '#000',
    froly: '#D06E77',
    white: '#fff',
  },
  messageBox: {
    attention: '#ffedef',
  },
  sell: {
    text: '#15609B',
    box: '#D06E77',
  },
  status: {
    liver: '#4E454A',
    pastelGreen: '#78D06D',
    hawkesBlue: '#E2E3E8',
    mischka: '#A1A4B2',
  },
  text: {
    error: '#D06E77',
    froly: '#D06E77',
    lightningYellow: '#F5A026', // Reviewing
    primary: PRIMARY,
    red: '#FF0000',
    summerSky: '#299adf',
    scooter: '#2C8A9D',
    white: '#FFFFFF',
    zambezi: '#5C5C5C',
    mischka: '#9CA1B9',
  },
  disabled: '#D5D0DA',
  black: '#000',
  blackHome: '#0A0A0A',
  blackRussian: '#000722',
  blue: '#299adf',
  cyanBlue: '#174893',
  darkBlue: '#15609B',
  darkRed: '#8B0000',
  gallery: '#DAD5D0',
  mischka: '#A6A7AD',
  nobel: '#9F9F9F',
  orange: '#F5A026',
  red: '#D06E77',
  white: '#fff',
  whiteHome: '#FEFEFE',
  whiteSmoke: '#F6F6F6',
  whiteSnow: '#F1F1F1',
};

export enum FirestoreDomain {
  invite = 'invite',
  notification = 'notification',
  settings = 'settings',
  verification = 'verification',
}

export enum CollectionName {
  consentForms = 'consentForms',
  corporate = 'corporate',
  documentType = 'documentType',
  kycRequests = 'kycRequests',
  individual = 'individual',
  invitationCodes = 'invitationCodes',
  inviters = 'inviters',
  maintenances = 'maintenances',
  notificationsCustodyFeeAndTopUpFee = 'notificationsCustodyFeeAndTopUpFee',
  referralsTimeDeposit = 'referralsTimeDeposit',
  referralsTimeDepositByRankA = 'referralsTimeDepositByRankA',
  users = 'users',
}

export enum CollectionGroupName {}

import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import {
  TIMEZONE,
  TIMEZONE_SHORT,
  TIMEZONE_SHORT_SUMMER_TIME,
} from '../../config/constants/business';

export const useClock = () => {
  const [datetime, setDatetime] = useState<string>('');

  useEffect(() => {
    let isCancelled = false;

    setInterval(() => {
      const now = moment();
      const convertedTime = now.tz(TIMEZONE);
      const timezone = convertedTime.isDST()
        ? TIMEZONE_SHORT_SUMMER_TIME
        : TIMEZONE_SHORT;
      const ampm = convertedTime.format('a') === 'am' ? 'a.m.' : 'p.m.';
      if (!isCancelled) {
        setDatetime(
          `${convertedTime.format(
            'h:mm ',
          )}${ampm} ${timezone} ${convertedTime.format('on DD MMM YYYY')}`,
        );
      }
    }, 1000);

    return () => {
      isCancelled = true;
    };
  }, []);

  return { datetime, setDatetime };
};

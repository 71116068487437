import React from 'react';

export interface TransferDebitCardTransactionV2ModalContextProps {
  showModal: () => void;
  hideModal: () => void;
}

const TransferDebitCardTransactionV2ModalContext = React.createContext<
  TransferDebitCardTransactionV2ModalContextProps
>({
  showModal: () => undefined,
  hideModal: () => undefined,
});

export default TransferDebitCardTransactionV2ModalContext;

import React, { FC } from 'react';
import ResignUpPage from './ResignUpPage';
import Countdown from '../../../enhancers/utils/Countdown';
import ResignUpProvider from '../../../enhancers/useCase/authentication/ResignUpProvider';
import ResignUpViewProvider from '../../../enhancers/useCase/authentication/ResignUpViewProvider';

const EnhancedResignUpPage: FC = () => {
  return (
    <ResignUpViewProvider>
      <ResignUpProvider>
        <Countdown>
          <ResignUpPage />
        </Countdown>
      </ResignUpProvider>
    </ResignUpViewProvider>
  );
};

export default EnhancedResignUpPage;

import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MaintenanceContext from '../../../../domain/settings/maintenance/Maintenance/Maintenance/MaintenanceContext';

// Style
import { DescriptionStyle } from './style';

// Component
import { TextSecondary, TextThemeProps } from '../../../atoms/text2/Text2';
import MaintenanceScheduleLayout from './MaintenanceScheduleLayout';

export interface MaintenanceScheduleProps {
  width: string | number;
  height: string | number;
  iconSize: string | number;
  descriptionStyle: TextThemeProps;
}

const ORGANISM_ID = `organisms.notification.MaintenanceSchedule`;

const MaintenanceSchedule: FC = () => {
  const { t } = useTranslation();
  const { maintenance } = useContext(MaintenanceContext);

  const toKey = useMemo(
    () => (maintenance?.getProps().system.schedule.end ? 'to' : 'notDecided'),
    [maintenance],
  );

  const estimateString = t(`${ORGANISM_ID}.estimate`);

  return (
    <MaintenanceScheduleLayout>
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${ORGANISM_ID}.description`)}
      </TextSecondary>
      <TextSecondary key="from" theme={DescriptionStyle}>
        {maintenance ? t(`${ORGANISM_ID}.from`) : <br />}
      </TextSecondary>
      <TextSecondary key="fromTime" theme={DescriptionStyle}>
        {maintenance?.getSystemMaintenanceStartTimeZoned() || ''}
      </TextSecondary>
      <TextSecondary key="to" theme={DescriptionStyle}>
        {maintenance ? t(`${ORGANISM_ID}.${toKey}`) : <br />}
      </TextSecondary>
      <TextSecondary key="toTime" theme={DescriptionStyle}>
        {maintenance?.getSystemMaintenanceEndTimeZoned(estimateString) || ''}
      </TextSecondary>
    </MaintenanceScheduleLayout>
  );
};

export default MaintenanceSchedule;

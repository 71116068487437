/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import TextArea from '../../../atoms/form/TextArea';
import { TextSecondary } from '../../../atoms/text2/Text2';
import TextAreaFieldLayout from './TextAreaFieldLayout';
import { ErrorMessageStyle } from './style';

export interface TextAreaFieldProps {
  name: string;
  placeholder?: string;
  maxLength: number;
  textInputWidth?: string | number;
  register?: any;
  onChange?: (text: string) => void;
}

const TextAreaField: FC<TextAreaFieldProps> = ({
  name = '',
  textInputWidth = 400,
  placeholder,
  maxLength,
  register,
  onChange = () => undefined,
}: TextAreaFieldProps) => {
  const { errors, watch } = useFormContext();

  return (
    <TextAreaFieldLayout textInputWidth={textInputWidth}>
      <TextArea
        key="textArea"
        name={name}
        value={watch(name)}
        ref={register}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={(e) => onChange(e.target.value)}
      />
      <TextSecondary key="errorMessage" theme={ErrorMessageStyle}>
        {errors[name]?.message}
      </TextSecondary>
    </TextAreaFieldLayout>
  );
};

export default TextAreaField;

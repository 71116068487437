import React, { FC } from 'react';
import TextPrimary from './TextPrimary';
import fontWeight from '../../../../fonts/fontWeight.json';
import fontSize from '../../../../fonts/fontSizePixel.json';

export interface TextPrimaryBold28Props {
  children: React.ReactNode;
  color: string;
  opacity?: number;
}

const TextPrimaryBold28: FC<TextPrimaryBold28Props> = ({
  children,
  color,
  opacity = 1,
}: TextPrimaryBold28Props) => {
  const pixel = fontSize.pixel28;
  const textFontWeight = fontWeight.bold;

  return (
    <TextPrimary
      pixel={pixel}
      color={color}
      opacity={opacity}
      fontWeight={textFontWeight}
    >
      {children}
    </TextPrimary>
  );
};
export default TextPrimaryBold28;

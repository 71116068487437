import styled from 'styled-components';
import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const Container = styled.div``;

export const ButtonWrap = styled.div`
  width: ${291.9 / 16}rem;
  height: ${50 / 16}rem;
`;

export const OrderTextStyle = {
  fontSize: 50,
  color: color.primary,
  fontWeight: fontWeight.bold,
  lineHeight: 50,
};

export const TitleStyle = {
  fontSize: 32,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedPageVewProvider from '../enhancers/useCase/authentication/AuthenticatedPageViewProvider';
import AccessHandlerUserStatusProvider from '../enhancers/routing/AccessHandlerUserStatusProvider';

// Type
import { MenuType } from '../config/menu/main/Menu.type';

// Constant
import { ALLOWED_USER_STATUS } from './accessHandler/userStatusPageAccessMapper';
import {
  ROUTE_DEBIT_CARD_REQUEST_CHERRY_VISA_01_STATUS,
  ROUTE_CHERRY_VISA_01_PROOF_OF_IDENTITY,
  ROUTE_CHERRY_VISA_01_PROOF_OF_RECEIPT,
} from '../config/constants/routing';

// Component
import { EnhancedDebitCardRequestCherryVisa01StatusPage } from '../containers/debitCardRequest/DebitCardRequestCherryVisa01StatusPage';
import { EnhancedCherryVisa01ProofOfIdentityPage } from '../containers/debitCardRequest/CherryVisa01ProofOfIdentityPage';
import { EnhancedCherryVisa01ProofOfReceiptPage } from '../containers/debitCardRequest/CherryVisa01ProofOfReceiptPage';

const DebitCardRequestCherryVisa01Router: FC = () => {
  return (
    <Switch>
      <AuthenticatedPageVewProvider>
        <AccessHandlerUserStatusProvider
          statusAllowed={ALLOWED_USER_STATUS[MenuType.debitCards]}
        >
          <Route path={`${ROUTE_DEBIT_CARD_REQUEST_CHERRY_VISA_01_STATUS}/:id`}>
            <EnhancedDebitCardRequestCherryVisa01StatusPage />
          </Route>
          <Route path={`${ROUTE_CHERRY_VISA_01_PROOF_OF_IDENTITY}/:id`}>
            <EnhancedCherryVisa01ProofOfIdentityPage />
          </Route>
          <Route path={`${ROUTE_CHERRY_VISA_01_PROOF_OF_RECEIPT}/:id`}>
            <EnhancedCherryVisa01ProofOfReceiptPage />
          </Route>
        </AccessHandlerUserStatusProvider>
      </AuthenticatedPageVewProvider>
    </Switch>
  );
};

export default DebitCardRequestCherryVisa01Router;

import React, { FC, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import DocumentTypesContext from '../../../../../../domain/settings/documentType/DocumentType/DocumentTypes/DocumentTypesContext';

// LayoutComponent
import Box from '../../../../../atoms/utils/layout/Box/Box';

// Mapper
import DocumentTypesOptionsMapper from '../../../option/mapper/DocumentTypesOptionsMapper';

// Component
import {
  DefaultOptionContainer,
  DefaultSingleValueContainer,
} from '../../../option/optionContainer/DefaultOptionContainer';
import HiddenInput from '../../../../../atoms/form/HiddenInput';
import SelectBox from '../../../../../atoms/select/SelectBox';

export interface DocumentTypeSelectBoxProps {
  name: string;
}

const DocumentTypeSelectBox: FC<DocumentTypeSelectBoxProps> = ({
  name,
}: DocumentTypeSelectBoxProps) => {
  const { documentTypes } = useContext(DocumentTypesContext);

  const { setValue, watch } = useFormContext();

  const { t } = useTranslation();

  const options = useMemo(
    () => DocumentTypesOptionsMapper.domainToOptions(documentTypes),
    [documentTypes],
  );

  return (
    <Box>
      <SelectBox
        borderColor="#d6ddec"
        dropdownColor="#212F41"
        isSearchable={false}
        marginLeft={`${0 / 16}rem`}
        minHeight={`${50 / 16}rem`}
        options={options}
        onSelect={(value: string) => {
          setValue(name, value);
        }}
        placeholder={t(`molecules.select.placeholder.default`)}
        placeholderColor="#D5D0DA"
        value={watch(name)}
        OptionComponent={DefaultOptionContainer}
        SingleValueComponent={DefaultSingleValueContainer}
      />
      <HiddenInput name={name} value={watch(name) || ''} />
    </Box>
  );
};

export default DocumentTypeSelectBox;

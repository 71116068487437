import React, { FC } from 'react';
import SubMenuItem from '../../../molecules/item/SubMenuItem';

export interface MenuList {
  menuItems: string[];
}

const SubMenuList: FC<MenuList> = ({ menuItems }: MenuList) => {
  return (
    <>
      {menuItems.map((menu) => (
        <SubMenuItem key={menu} type={menu} />
      ))}
    </>
  );
};

export default SubMenuList;

// Type
import { BankDepositType } from '../../../../../../types/BankAccountTransaction.type';
import { BankSpotTransactionRawDataType } from '../../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankSpotTransaction/BankSpotTransaction/BankSpotTransaction.type';

// DomainObject
import BankDepositFactory from '../../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankDeposit/BankDeposit/BankDepositFactory';
import BankTransactionIdFactory from '../../../../../../domain/idManager/transactionId/BankTransactionId/BankTransactionIdFactory';

class BankDepositFunctionsMapper {
  static rawDataToDomain = (rawData: BankSpotTransactionRawDataType) => {
    const bankTransactionId = BankTransactionIdFactory.createFromRawId(
      rawData.id,
      rawData.props.commonId,
    );

    const { props } = rawData;

    return BankDepositFactory.create(bankTransactionId, {
      amount: props.amount,
      balance: 0,
      bankDepositType: props.type as BankDepositType,
      createdAt: props.createdAt,
      currency: props.currency,
      descriptionParameter: props.descriptionParameter,
      savingsAccountNumber: props.savingsAccountNumber,
      updatedAt: props.updatedAt,
    });
  };
}
export default BankDepositFunctionsMapper;

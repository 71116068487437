import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  InviterRankDProps,
  InviterRankDPropsFormat,
} from './InviterRankD.type';

// DomainObject
import InviterRankD from './InviterRankD';

class InviterRankDFactory {
  static create = (id: string, props: InviterRankDProps) => {
    const result = Joi.object(InviterRankDPropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: InviterRankD.name,
          reason: result.error.details[0].message,
        },
      );

    return new InviterRankD(id, props);
  };
}

export default InviterRankDFactory;

import React from 'react';
import CryptoCurrenciesAvailable from './CryptoCurrenciesAvailable';

export interface CryptoCurrenciesAvailableContextProps {
  cryptoCurrenciesAvailable: CryptoCurrenciesAvailable;
}

const CryptoCurrenciesAvailableContext = React.createContext<
  CryptoCurrenciesAvailableContextProps
>({
  cryptoCurrenciesAvailable: new CryptoCurrenciesAvailable(),
});

export default CryptoCurrenciesAvailableContext;

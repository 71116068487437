import { NotificationLogType } from '../../types/Notification.type';

// IUseCase
import INotificationCreateReadLogUseCase from '../../enhancers/useCase/notification/NotificationLogCreateReadLogProvider/INotificationCreateReadLogUseCase';

// IService
import INotificationLogCreateService from './INotificationLogCreateService';

// Service
import NotificationLogCreateService from '../../service/notification/NotificationLogCreateService';

// IRepository
import INotificationLogRepository from '../../domain/notification/notificationLog/INotificationLogRepository';
import UserAuthorized from '../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class NotificationLogCreateReadLogUseCase
  implements INotificationCreateReadLogUseCase {
  private notificationLogCreateService: INotificationLogCreateService;

  constructor(notificationLogRepository: INotificationLogRepository) {
    this.notificationLogCreateService = new NotificationLogCreateService(
      notificationLogRepository,
    );
  }

  public executeCreateReadLog = async (
    user: UserAuthorized,
    type: NotificationLogType,
  ) => {
    await this.notificationLogCreateService.executeCreateReadLogByIdAndType(
      user.getId(),
      type,
    );
  };
}
export default NotificationLogCreateReadLogUseCase;

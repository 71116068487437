import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';

export interface HeaderTwoFactorAuthAppEditCompleteTemplateLayoutProps {
  children: React.ReactElement[];
}

const HeaderTwoFactorAuthAppEditCompleteTemplateLayout: FC<HeaderTwoFactorAuthAppEditCompleteTemplateLayoutProps> = ({
  children,
}: HeaderTwoFactorAuthAppEditCompleteTemplateLayoutProps) => {
  const elements = ['left', 'right'];
  const [left, right] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow width={1200} justifyContent="space-between">
      {left}
      {right}
    </FlexBoxRow>
  );
};
export default HeaderTwoFactorAuthAppEditCompleteTemplateLayout;

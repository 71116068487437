/* eslint-disable @typescript-eslint/no-explicit-any */
// React
import React, { FC, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CryptoCurrencyTradeTransactionsContext from '../../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactions/CryptoCurrencyTradeTransactionsContext';
import CryptoCurrencyWalletContext from '../../../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWalletContext';

// Constant
import { BASE_CURRENCY_SYMBOL } from '../../../../../../config/constants/business';

// Mapper
import TableCryptoCurrencyTradeHistoryMapper, {
  TdCryptoCurrencyTradeHistory,
} from './TableCryptoCurrencyTradeHistoryMapper';

// Layout
import DefaultTdBox from '../../../../../molecules/table/td/DefaultTdBox';
import NumberTdBox from '../../../../../molecules/table/td/NumberTdBox';

// Components
import HistoryTable from '../../../../../molecules/table/table/HistoryTable';

// ID
const ORGANISM_ID = `organisms.table.TableCryptoCurrencyTransactionHistory`;

const TableCryptoCurrencyTradeHistory: FC = () => {
  const { t } = useTranslation();
  const { cryptoCurrencyTradeTransactions } = useContext(
    CryptoCurrencyTradeTransactionsContext,
  );
  const { cryptoCurrencyWallet } = useContext(CryptoCurrencyWalletContext);

  const currency = useMemo(
    () => cryptoCurrencyWallet?.getProps().currency || '',
    [cryptoCurrencyWallet],
  );
  const market = `${BASE_CURRENCY_SYMBOL}/${currency}`;

  const data = useMemo(
    () =>
      TableCryptoCurrencyTradeHistoryMapper.domainToTd(
        cryptoCurrencyTradeTransactions,
        t,
      ),
    [cryptoCurrencyTradeTransactions, t],
  );

  const columns = useMemo(
    () => [
      {
        Header: t(`${ORGANISM_ID}.th.date`),
        accessor: (values: TdCryptoCurrencyTradeHistory) => values.createdAt,
        Cell: (value: any) =>
          DefaultTdBox({ text: value.row.original.createdAt }),
        minWidth: `${150 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.tradeType`),
        accessor: (values: TdCryptoCurrencyTradeHistory) =>
          values.transactionType,
        Cell: (value: any) =>
          DefaultTdBox({ text: value.row.original.transactionType }),
        minWidth: `${135 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.deposit`, { currency }),
        accessor: (values: TdCryptoCurrencyTradeHistory) => values.deposit,
        Cell: (value: any) => NumberTdBox({ text: value.row.original.deposit }),
        minWidth: `${195 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.withdrawal`, { currency }),
        accessor: (values: TdCryptoCurrencyTradeHistory) => values.withdrawal,
        Cell: (value: any) =>
          NumberTdBox({ text: value.row.original.withdrawal }),
        minWidth: `${195 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.rate`, { market }),
        accessor: (values: TdCryptoCurrencyTradeHistory) => values.rate,
        Cell: (value: any) => NumberTdBox({ text: value.row.original.rate }),
        minWidth: `${120 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.fee`, { currency }),
        accessor: (values: TdCryptoCurrencyTradeHistory) => values.fee,
        Cell: (value: any) => NumberTdBox({ text: value.row.original.fee }),
        minWidth: `${165 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.status`),
        accessor: (values: TdCryptoCurrencyTradeHistory) => values.status,
        Cell: (value: any) => DefaultTdBox({ text: value.row.original.status }),
        minWidth: `${165 / 16}rem`,
      },
    ],
    [t, currency, market],
  );

  return <HistoryTable data={data} columns={columns} width={1110} />;
};

export default TableCryptoCurrencyTradeHistory;

import { useCallback, useState, useEffect } from 'react';

// Lib
import { checkInternetConnection } from '../../../../../../utils/helpers/connection';

// Error
import ErrorHandler from '../../../../../../utils/errors/ErrorHandler/ErrorHandler';

// Type
import { DebitCardIssuerType } from '../../../../../../types/DebitCard.type';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../../config/constants/requestState';

// UseCase
import DebitCardRequestViewUseCase from '../../../../../../useCases/debitCard/debitCardRequest/DebitCardRequest/DebitCardRequestViewUseCase';

// Repository
import DebitCardRequestFunctions from '../../../../../../repository/debitCard/debitCardRequest/DebitCardRequestFunctions';

// DomainObject
import { useDebitCardRequest } from '../../../../../../domain/debitCard/debitCardRequest/DebitCardRequest/DebitCardRequest/useDebitCardRequest';
import UserAuthorized from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useDebitCardRequestViewUseCase = (
  savingsAccountNumber: string | null,
  issuer: DebitCardIssuerType | null,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /**
   *
   *  DomainObject
   *
   */
  const { debitCardRequest, setDebitCardRequest } = useDebitCardRequest();

  /**
   *
   *  Repository
   *
   */
  const debitCardRequestRepository = new DebitCardRequestFunctions();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new DebitCardRequestViewUseCase(debitCardRequestRepository);

  /**
   *
   *  Method
   *
   */
  const openDebitCardRequest = useCallback(async () => {
    if (!userAuthorized || !savingsAccountNumber || !issuer) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const output = await useCase.open(
        savingsAccountNumber || '',
        issuer as DebitCardIssuerType,
      );

      setDebitCardRequest(output.debitCardRequest);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized]);

  /**
   *
   *  UseEffect
   *
   */
  useEffect(() => {
    let isMounted = true;

    if (userAuthorized && isMounted) openDebitCardRequest();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [userAuthorized]);

  return {
    state,
    setState,
    debitCardRequest,
  };
};

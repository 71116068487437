import React, { FC } from 'react';
import { TransferDebitCardType } from '../../../../types/Transfer.type';
import {
  ArrowTransferIcon,
  BankIcon,
  CardIcon,
} from '../../../atoms/icon/DebitCardIcon';
import TransferDebitCardIconGroupLayout from './TransferDebitCardIconGroupLayout';

export interface TransferDebitCardIconGroupProps {
  transferDebitCardType: TransferDebitCardType;
}

const TransferDebitCardIconGroup: FC<TransferDebitCardIconGroupProps> = ({
  transferDebitCardType,
}: TransferDebitCardIconGroupProps) => {
  const [bankKey, cardKey] =
    transferDebitCardType === TransferDebitCardType.savingsAccountToCard
      ? ['left', 'right']
      : ['right', 'left'];

  return (
    <TransferDebitCardIconGroupLayout>
      <BankIcon key={bankKey} width={30} height={30} />
      <ArrowTransferIcon key="center" width={20} height={20} />
      <CardIcon key={cardKey} width={30} height={30} />
    </TransferDebitCardIconGroupLayout>
  );
};

export default TransferDebitCardIconGroup;

import React, { FC, useContext } from 'react';
import { usePersonalInformationSubmitUseCase } from './usePersonalInformationSubmitUseCase';
import KycRequestContext from '../../../../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequestContext';
import PersonalInformationFileContext from '../../../../../fileHandler/verification/kycRequest/PersonalInformationFileProvider/PersonalInformationFileContext';
import PersonalInformationSubmitContext from './PersonalInformationSubmitContext';

// Error
import SystemErrorHandler from '../../../../../errorHandler/SystemErrorHandler';

// Type
import { FormInputPersonalInformationContext } from '../../../../../../components/organisms/form/verification/kycRequest/FormPersonalInformation/FormPersonalInformation.type';

// DomainObject
import UserAuthorizedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

export type PersonalInformationSubmitProviderProps = {
  children: React.ReactNode;
};

const PersonalInformationSubmitProvider: FC<PersonalInformationSubmitProviderProps> = ({
  children,
}: PersonalInformationSubmitProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { kycRequest } = useContext(KycRequestContext);

  const {
    state,
    setState,
    submitPersonalInformation,
    formInputPersonalInformationContext,
    personalInformationFileContext,
  } = usePersonalInformationSubmitUseCase(kycRequest, userAuthorized);

  return (
    <FormInputPersonalInformationContext.Provider
      value={formInputPersonalInformationContext}
    >
      <PersonalInformationSubmitContext.Provider
        value={{
          state,
          submitPersonalInformation,
        }}
      >
        <PersonalInformationFileContext.Provider
          value={personalInformationFileContext}
        >
          <SystemErrorHandler state={state} setState={setState}>
            {children}
          </SystemErrorHandler>
        </PersonalInformationFileContext.Provider>
      </PersonalInformationSubmitContext.Provider>
    </FormInputPersonalInformationContext.Provider>
  );
};
export default PersonalInformationSubmitProvider;

import React, { FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebitCardRequestCherryVisa01PayIssuanceFeeViewUseCase } from './useDebitCardRequestCherryVisa01PayIssuanceFeeViewUseCase';

// Error
import { ErrorCodeType } from '../../../../../../utils/errors/ErrorHandler/ErrorCode.type';
import SystemErrorHandler from '../../../../../errorHandler/SystemErrorHandler';

// Constant
import { ROUTE_DEBIT_CARD } from '../../../../../../config/constants/routing';

// Context
import DebitCardRequestCherryVisa01Context from '../../../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Context';
import UserAuthorizedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import SavingsAccountContext from '../../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccountContext';

// For Redirect
const REDIRECT_ERROR_CODES = [
  ErrorCodeType.DEBIT_CARD_REQUEST_INVALID_ISSUER,
  ErrorCodeType.SAVINGS_ACCOUNT_INVALID_ACCOUNT_NUMBER_BY_CLIENT,
  ErrorCodeType.SAVINGS_ACCOUNT_FORBIDDEN_READ_SAVINGS_INFO,
];

export type DebitCardRequestCherryVisa01PayIssuanceFeeViewProviderProps = {
  children: React.ReactNode;
};

const DebitCardRequestCherryVisa01PayIssuanceFeeViewProvider: FC<DebitCardRequestCherryVisa01PayIssuanceFeeViewProviderProps> = ({
  children,
}: DebitCardRequestCherryVisa01PayIssuanceFeeViewProviderProps) => {
  const history = useHistory();

  // DomainObject
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { debitCardRequestCherryVisa01 } = useContext(
    DebitCardRequestCherryVisa01Context,
  );

  const savingsAccountNumber = debitCardRequestCherryVisa01?.getProps()
    .savingsAccountNumber;

  const {
    state,
    setState,
    savingsAccount,
  } = useDebitCardRequestCherryVisa01PayIssuanceFeeViewUseCase(
    savingsAccountNumber,
    userAuthorized,
  );

  /* *
   *
   *  UseEffect
   *
   * */

  // state (client error)
  useEffect(() => {
    if (REDIRECT_ERROR_CODES.includes(state as ErrorCodeType))
      history.push(ROUTE_DEBIT_CARD);
    // eslint-disable-next-line
  }, [state]);

  return (
    <SavingsAccountContext.Provider value={{ savingsAccount }}>
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </SavingsAccountContext.Provider>
  );
};

export default DebitCardRequestCherryVisa01PayIssuanceFeeViewProvider;

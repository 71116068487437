// Error
import InvalidDataFoundError from '../../../../../../utils/errors/InvalidDataFoundError';

// Type
import { DebitCardWithdrawalV2Props } from './DebitCardWithdrawalV2.type';
import { DebitCardWithdrawalType } from '../../../../../../types/DebitCardTransaction.type';
import { TypeOfT } from '../../../../../../types/typeof/Translation.type';

// DomainObject
import DebitCardSpotTransactionV2 from '../../DebitCardSpotTransactionV2/DebitCardSpotTransactionV2/DebitCardSpotTransactionV2';
import DebitCardTransactionId from '../../../../../idManager/transactionId/DebitCardTransactionId/DebitCardTransactionId';

class DebitCardWithdrawalV2 extends DebitCardSpotTransactionV2 {
  private type: DebitCardWithdrawalType;

  constructor(
    debitCardTransactionId: DebitCardTransactionId,
    props: DebitCardWithdrawalV2Props,
  ) {
    super(debitCardTransactionId, props);
    this.type = props.type;
  }

  public getProps = () => ({
    ...super.getProps(),
    type: this.type,
  });

  public getCommandParameter = () => ({
    ...super.getCommandParameter(),
    type: this.type,
  });

  public createDescription = (t: TypeOfT) => {
    const TRANSLATION_ID =
      'transaction.debitCardTransaction.description.debitCardWithdrawal';

    if (this.type === DebitCardWithdrawalType.withdrawToSavingsAccount) {
      const param =
        this.descriptionParameter.indexOf('-') > -1
          ? this.descriptionParameter
          : `${this.descriptionParameter.substr(
              0,
              3,
            )}-${this.descriptionParameter.substr(3, 7)}`;

      return t(`${TRANSLATION_ID}.${this.type}`, {
        param,
      });
    }

    if (Object.keys(DebitCardWithdrawalType).includes(this.type))
      return this.descriptionParameter;

    throw new InvalidDataFoundError(
      `Unknown debitCardWithdrawalType ${this.type} is found at DebitCardWithdrawal.createDescription`,
    );
  };

  public getTransactionType = () => this.type;
}

export default DebitCardWithdrawalV2;

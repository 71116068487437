import ExampleImageNG1 from '../../../../assets/images/example_selfie_ng1.png';
import ExampleImageNG2 from '../../../../assets/images/example_selfie_ng2.png';
import ExampleImageNG3 from '../../../../assets/images/example_selfie_ng3.png';
import ExampleImageOK from '../../../../assets/images/example_selfie_ok.png';
import ExampleImageNG1Debit from '../../../../assets/images/example_selfie_ng1_debit.svg';
import ExampleImageNG2Debit from '../../../../assets/images/example_selfie_ng2_debit.svg';
import ExampleImageNG3Debit from '../../../../assets/images/example_selfie_ng3_debit.svg';
import ExampleImageOKDebit from '../../../../assets/images/example_selfie_ok_debit.svg';

export enum ImageType {
  exampleImageNG1 = 'exampleImageNG1',
  exampleImageNG2 = 'exampleImageNG2',
  exampleImageNG3 = 'exampleImageNG3',
  exampleImageOK = 'exampleImageOK',
  exampleImageNG1NoCard = 'exampleImageNG1NoCard',
  exampleImageNG2NoCard = 'exampleImageNG2NoCard',
  exampleImageNG3NoCard = 'exampleImageNG3NoCard',
  exampleImageOKNoCard = 'exampleImageOKNoCard',
}

export const KycExampleImageType = {
  [ImageType.exampleImageNG1]: ExampleImageNG1,
  [ImageType.exampleImageNG2]: ExampleImageNG2,
  [ImageType.exampleImageNG3]: ExampleImageNG3,
  [ImageType.exampleImageOK]: ExampleImageOK,
};

export const DebitCardRequestExampleImageType = {
  [ImageType.exampleImageNG1]: ExampleImageNG1Debit,
  [ImageType.exampleImageNG2]: ExampleImageNG2Debit,
  [ImageType.exampleImageNG3]: ExampleImageNG3Debit,
  [ImageType.exampleImageOK]: ExampleImageOKDebit,
  [ImageType.exampleImageNG1NoCard]: ExampleImageNG1,
  [ImageType.exampleImageNG2NoCard]: ExampleImageNG2,
  [ImageType.exampleImageNG3NoCard]: ExampleImageNG3,
  [ImageType.exampleImageOKNoCard]: ExampleImageOK,
};

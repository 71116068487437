import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  KycRequestIndividualProps,
  KycRequestIndividualPropsFormat,
  KycRequestIndividualInitialProps,
} from './KycRequestIndividual.type';
import { UserType } from '../../../../../types/User.type';

// DomainObject
import UserAuthorized from '../../../../public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

// ValueObject
import KycRequestIndividual from './KycRequestIndividual';

class KycRequestIndividualFactory {
  static create = (id: string, props: KycRequestIndividualProps) => {
    const result = Joi.object(KycRequestIndividualPropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: KycRequestIndividual.name,
          reason: result.error.details[0].message,
        },
      );

    return new KycRequestIndividual(id, props);
  };

  static createInitial = (user: UserAuthorized) => {
    const id = user.getId();
    const { username } = user.getProps();

    return KycRequestIndividualFactory.create(id, {
      username,
      type: UserType.individual,
      ...KycRequestIndividualInitialProps,
    });
  };
}

export default KycRequestIndividualFactory;

export const convertRem = (length: string | number) => {
  if (Number.isNaN(Number(length))) return length;

  return `${(length as number) / 16}rem`;
};

export const convertRemWidth = (props: { width: string | number }) => ({
  ...props,
  width: convertRem(props.width),
});

export const paddingToFourParams = (padding: string) => {
  const paddings = padding.split(' ');
  const { length } = paddings;

  switch (length) {
    case 1:
      return `${convertRem(paddings[0])} ${convertRem(
        paddings[0],
      )} ${convertRem(paddings[0])} ${convertRem(paddings[0])}`;
    case 2:
      return `${convertRem(paddings[0])} ${convertRem(
        paddings[1],
      )} ${convertRem(paddings[0])} ${convertRem(paddings[1])}`;
    case 3:
      return `${convertRem(paddings[0])} ${convertRem(
        paddings[1],
      )} ${convertRem(paddings[2])} ${convertRem(paddings[1])}`;
    case 4:
      return `${convertRem(paddings[0])} ${convertRem(
        paddings[1],
      )} ${convertRem(paddings[2])} ${convertRem(paddings[3])}`;
    default:
      return '0';
  }
};

export const getPadding = (paddings: {
  padding?: string | number;
  paddingTop?: string | number;
  paddingRight?: string | number;
  paddingBottom?: string | number;
  paddingLeft?: string | number;
}) => {
  const {
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  } = paddings;

  if (padding) return paddingToFourParams(String(paddings.padding));

  const top = paddingTop ? convertRem(paddingTop) : '0';
  const right = paddingRight ? convertRem(paddingRight) : '0';
  const bottom = paddingBottom ? convertRem(paddingBottom) : '0';
  const left = paddingLeft ? convertRem(paddingLeft) : '0';

  return `${top} ${right} ${bottom} ${left}`;
};

export const convertRemPadding = (props: {
  padding?: string | number;
  paddingTop?: string | number;
  paddingRight?: string | number;
  paddingBottom?: string | number;
  paddingLeft?: string | number;
}) => ({
  ...props,
  padding: getPadding(props),
});

import React, { FC, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSignInTwoFactorAuthUseCase } from './useSignInTwoFactorAuthUseCase';
import SignInTwoFactorAuthContext from './SignInTwoFactorAuthContext';
import SignInPageStateContext from '../../../pageState/authentication/SignInPageState/SignInPageStateContext';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Type
import { GlobalStateType } from '../../../../redux/store';
import { VerifiedUserStatusType } from '../../../../types/User.type';

// Constant
import { INITIAL, SUCCESS } from '../../../../config/constants/requestState';
import {
  ROUTE_TOP_PAGE_UNVERIFIED_USER,
  ROUTE_TOP_PAGE_VERIFIED_USER,
} from '../../../../config/constants/routing';
import { ErrorCodeType } from '../../../../utils/errors/ErrorHandler';

// Component
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type SignInTwoFactorAuthProviderProps = {
  children: React.ReactNode;
};

const SignInTwoFactorAuthProvider: FC<SignInTwoFactorAuthProviderProps> = ({
  children,
}: SignInTwoFactorAuthProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { setPageState } = useContext(SignInPageStateContext);

  const history = useHistory();

  const {
    state,
    setState,
    signInTwoFactorAuth,
  } = useSignInTwoFactorAuthUseCase(userAuthorized!);

  const signInUser = useSelector(
    (globalState: GlobalStateType) => globalState.signInUserStore,
  );

  // Redirect (SUCCESS)
  useEffect(() => {
    if (userAuthorized && signInUser && state === SUCCESS) {
      const redirectTo = Object.keys(VerifiedUserStatusType).includes(
        userAuthorized.getProps().status,
      )
        ? ROUTE_TOP_PAGE_VERIFIED_USER
        : ROUTE_TOP_PAGE_UNVERIFIED_USER;
      history.push(redirectTo);
    }

    if (
      !userAuthorized ||
      state === ErrorCodeType.AUTHENTICATION_LOGIN_REQUEST_EXPIRED
    ) {
      setPageState('initial');
      setState(INITIAL);
    }
  }, [state, signInUser, userAuthorized, history, setPageState, setState]);

  return (
    <SignInTwoFactorAuthContext.Provider value={{ state, signInTwoFactorAuth }}>
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </SignInTwoFactorAuthContext.Provider>
  );
};
export default SignInTwoFactorAuthProvider;

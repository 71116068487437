import React, { FC } from 'react';

// Component
import HeaderTwoFactorAuthNewAccountKeyTemplate from '../../../organisms/header/HeaderContentTitle/settings/HeaderTwoFactorAuthNewAccountKeyTemplate';
import FormTwoFactorAuthNewAccountKey from '../../../organisms/form/settings/FormTwoFactorAuthNewAccountKey';
import TwoFactorAuthNewAccountKeyTemplateLayout from './TwoFactorAuthNewAccountKeyTemplateLayout';

const TwoFactorAuthNewAccountKeyTemplate: FC = () => {
  return (
    <TwoFactorAuthNewAccountKeyTemplateLayout>
      <HeaderTwoFactorAuthNewAccountKeyTemplate key="header" />
      <FormTwoFactorAuthNewAccountKey key="form" />
    </TwoFactorAuthNewAccountKeyTemplateLayout>
  );
};
export default TwoFactorAuthNewAccountKeyTemplate;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import Margin from '../../../atoms/utils/layout/Margin';
import { Container } from './style';
import { FlexBoxRow } from '../../../atoms/utils/layout/Box/FlexBox';

export interface MenuItemLayoutProps {
  children: React.ReactElement[];
}

const MenuItemLayout: FC<MenuItemLayoutProps> = ({
  children,
}: MenuItemLayoutProps) => {
  const elements = ['icon', 'menu'];
  const [icon, menu] = getElementsFromKeys(children, elements);

  return (
    <Container>
      <FlexBoxRow width={35} height={30} alignItems="center">
        {icon}
      </FlexBoxRow>
      <Margin left={12} />
      {menu}
    </Container>
  );
};
export default MenuItemLayout;

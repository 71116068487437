// IUseCase
import IDebitCardTransactionV2ViewUseCase from '../../../enhancers/useCase/debitCard/debitCard/DebitCardTransactionV2ViewProvider/IDebitCardTransactionV2ViewUseCase';

// IService
import IDebitCardSpotTransactionV2QueryService from '../../transaction/debitCardTransaction/debitCardSpotTransaction/IDebitCardSpotTransactionV2QueryService';
import ITransferDebitCardTransactionV2QueryService from '../../transfer/debitCard/ITransferDebitCardTransactionV2QueryService';

// Service
import DebitCardSpotTransactionV2QueryService from '../../../service/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardSpotTransactionV2QueryService';
import TransferDebitCardTransactionV2QueryService from '../../../service/transfer/debitCard/TransferDebitCardTransactionV2QueryService';

// IRepository
import ITransferDebitCardTransactionV2Repository from '../../../domain/transaction/transferDebitCardTransaction/ITransferDebitCardTransactionV2Repository';
import IDebitCardSpotTransactionV2Repository from '../../../domain/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardSpotTransactionV2/IDebitCardSpotTransactionV2Repository';

// DomainObject
import DebitCardV2 from '../../../domain/debitCard/debitCardV2/DebitCardV2/DebitCardV2';

class DebitCardTransactionV2ViewUseCase
  implements IDebitCardTransactionV2ViewUseCase {
  private debitCardSpotTransactionV2QueryService: IDebitCardSpotTransactionV2QueryService;

  private transferDebitCardTransactionV2QueryService: ITransferDebitCardTransactionV2QueryService;

  constructor(
    debitCardSpotTransactionV2Repository: IDebitCardSpotTransactionV2Repository,
    transferDebitCardTransactionV2Repository: ITransferDebitCardTransactionV2Repository,
  ) {
    this.debitCardSpotTransactionV2QueryService = new DebitCardSpotTransactionV2QueryService(
      debitCardSpotTransactionV2Repository,
    );

    this.transferDebitCardTransactionV2QueryService = new TransferDebitCardTransactionV2QueryService(
      transferDebitCardTransactionV2Repository,
    );
  }

  public open = async (debitCard: DebitCardV2) => {
    const debitCardId = debitCard.getId();

    const [
      debitCardSpotTransactionV2s,
      transferDebitCardTransactionV2s,
    ] = await Promise.all([
      this.debitCardSpotTransactionV2QueryService.findAllByDebitCardId(
        debitCardId,
      ),
      this.transferDebitCardTransactionV2QueryService.findAllByDebitCardId(
        debitCardId,
      ),
    ]);

    return {
      debitCardSpotTransactionV2s,
      transferDebitCardTransactionV2s,
    };
  };
}
export default DebitCardTransactionV2ViewUseCase;

// Firestore
import NotificationsCustodyFeeAndTopUpFeeCollection from '../../infrastructure/firebase/firestore/collections/notification/NotificationsCustodyFeeAndTopUpFeeCollection';

// Error
import { ErrorIdType } from '../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../errors/ErrorFactory/SystemErrorFactory';

// Types
import { NotificationLogCustodyFeeAndTopUpFeeProps } from '../../domain/notification/notificationLog/NotificationLogCustodyFeeAndTopUpFee/NotificationLogCustodyFeeAndTopUpFee.type';
import { NotificationLogType } from '../../types/Notification.type';

// IRepository
import INotificationLogRepository from '../../domain/notification/notificationLog/INotificationLogRepository';

// DomainObject
import NotificationLogCustodyFeeAndTopUpFeeFactory from '../../domain/notification/notificationLog/NotificationLogCustodyFeeAndTopUpFee/NotificationLogCustodyFeeAndTopUpFeeFactory';

class NotificationLogFirestore implements INotificationLogRepository {
  public findCustodyFeeAndTopUpFeeById = async (id: string) => {
    const notificationsLogCollection = new NotificationsCustodyFeeAndTopUpFeeCollection();

    const doc = await notificationsLogCollection.fetchSpecific(id);

    return !doc
      ? NotificationLogCustodyFeeAndTopUpFeeFactory.createInitial(id)
      : NotificationLogCustodyFeeAndTopUpFeeFactory.create(id, {
          ...doc.data(),
          type: NotificationLogType.custodyFedAndTopUpFee,
        } as NotificationLogCustodyFeeAndTopUpFeeProps);
  };

  public createReadLogByIdAndType = async (
    id: string,
    type: NotificationLogType,
  ) => {
    if (type !== NotificationLogType.custodyFedAndTopUpFee)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_SWITCH,
        {
          value: type,
          place: `SavingsAccountTypeIcon`,
        },
      );

    const notificationsLogCollection = new NotificationsCustodyFeeAndTopUpFeeCollection();
    const now = Date.now();

    await notificationsLogCollection.create(id, {
      isRead: true,
      createdAt: now,
      updatedAt: now,
    });
  };
}
export default NotificationLogFirestore;

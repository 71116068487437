import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessHandlerUserStatusProvider from '../enhancers/routing/AccessHandlerUserStatusProvider';
import AuthenticatedPageVewProvider from '../enhancers/useCase/authentication/AuthenticatedPageViewProvider';

// Type
import { MenuType } from '../config/menu/main/Menu.type';

// Constant
import {
  ROUTE_TIME_DEPOSIT,
  ROUTE_TIME_DEPOSIT_ADD,
  ROUTE_TIME_DEPOSIT_RENEWAL_RESERVATION_ADD,
  ROUTE_TIME_DEPOSIT_RENEWAL_RESERVATION_DELETE,
  ROUTE_TIME_DEPOSIT_RENEWAL_RESERVATION_EDIT,
} from '../config/constants/routing';
import { ALLOWED_USER_STATUS } from './accessHandler/userStatusPageAccessMapper';

// Component
import { EnhancedContractTimeDepositPage } from '../containers/contractTimeDeposit/ContractsTimeDepositPage';
import { EnhancedContractTimeDepositAddPage } from '../containers/contractTimeDeposit/ContractTimeDepositAddPage';
import { EnhancedContractTimeDepositRenewalReservationAddPage } from '../containers/contractTimeDeposit/ContractTimeDepositRenewalReservationAddPage';
import { EnhancedContractTimeDepositRenewalReservationEditPage } from '../containers/contractTimeDeposit/ContractTimeDepositRenewalReservationEditPage';
import { EnhancedContractTimeDepositRenewalReservationDeletePage } from '../containers/contractTimeDeposit/ContractTimeDepositRenewalReservationDeletePage';

const ContractTimeDepositRouter: FC = () => {
  return (
    <Switch>
      <AuthenticatedPageVewProvider>
        <AccessHandlerUserStatusProvider
          statusAllowed={ALLOWED_USER_STATUS[MenuType.timeDeposits]}
        >
          <Route exact path={ROUTE_TIME_DEPOSIT}>
            <EnhancedContractTimeDepositPage />
          </Route>
          <Route exact path={ROUTE_TIME_DEPOSIT_ADD}>
            <EnhancedContractTimeDepositAddPage />
          </Route>
          <Route
            exact
            path={`${ROUTE_TIME_DEPOSIT_RENEWAL_RESERVATION_ADD}/:id`}
          >
            <EnhancedContractTimeDepositRenewalReservationAddPage />
          </Route>
          <Route
            exact
            path={`${ROUTE_TIME_DEPOSIT_RENEWAL_RESERVATION_EDIT}/:id`}
          >
            <EnhancedContractTimeDepositRenewalReservationEditPage />
          </Route>
          <Route
            exact
            path={`${ROUTE_TIME_DEPOSIT_RENEWAL_RESERVATION_DELETE}/:id`}
          >
            <EnhancedContractTimeDepositRenewalReservationDeletePage />
          </Route>
        </AccessHandlerUserStatusProvider>
      </AuthenticatedPageVewProvider>
    </Switch>
  );
};

export default ContractTimeDepositRouter;

import Joi from '@hapi/joi';

// Type
import { RankType } from '../../../../types/Invite.type';
import {
  InvitationCodeProps,
  InvitationCodePropsFormat,
} from './InvitationCode.type';

// DomainObject
import InvitationCode from './InvitationCode';

// Error
import InvalidDataFoundError from '../../../../utils/errors/InvalidDataFoundError';

class InvitationCodeFactory {
  static create = (id: RankType, props: InvitationCodeProps) => {
    const result = Joi.object(InvitationCodePropsFormat).validate({
      ...props,
      rank: id,
    });

    if (result.error)
      throw new InvalidDataFoundError(
        `Creation of InvitationCode is rejected. The reason is ${result.error.details[0].message}`,
      );

    return new InvitationCode(id, props);
  };
}

export default InvitationCodeFactory;

import { required, emailFormat, noUpperCase } from '../validator';

/* eslint-disable @typescript-eslint/no-explicit-any */
const emailEditRegister = (
  field: string,
  t: any,
  params?: { [key: string]: any },
) => {
  switch (field) {
    case 'newEmail':
      return {
        required: required('New Email', t),
        validate: {
          emailFormat: emailFormat(t),
          noUpperCase: noUpperCase('New Email', t),
          notEqualCurrentEmail: (value: string) => value !== params?.email,
        },
      };
    default:
      return undefined;
  }
};

export default emailEditRegister;

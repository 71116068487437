import { getCurrentTimeTimezone } from '../../../../utils/helpers/datetimeHelper';
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
import TransferDebitCardTransactionId from './TransferDebitCardTransactionId';
import { PrefixTransferDebitCardTransactionId } from './TransferDebitCardTransactionId.type';
import { TransferDebitCardType } from '../../../../types/Transfer.type';

// Error
import InvalidDataFoundError from '../../../../utils/errors/InvalidDataFoundError';

const TRANSACTION_ID_LENGTH = 27;

class TransferDebitCardTransactionIdFactory {
  static create = (
    transferDebitCardType: TransferDebitCardType,
    commonId: string,
    currency: FiatCurrencyType,
    target: string,
    serialNumber: string,
  ): TransferDebitCardTransactionId => {
    const prefix = PrefixTransferDebitCardTransactionId[transferDebitCardType];
    const now = getCurrentTimeTimezone();
    const date = now.format('YYYYMMDD');

    if (!prefix)
      throw new Error(`Prefix of ${TransferDebitCardType} is not found.`);

    return new TransferDebitCardTransactionId({
      prefix,
      currency,
      date,
      target,
      commonId,
      serialNumber,
    });
  };

  static createFromRawId = (id: string, commonId: string) => {
    if (id.substring(0, 3) === 'tu_')
      return new TransferDebitCardTransactionId({
        prefix: 'cher',
        currency: FiatCurrencyType.USD,
        date: '20200101',
        serialNumber: '000001',
        target: '000001',
        commonId,
      });

    if (id.length !== TRANSACTION_ID_LENGTH)
      throw new InvalidDataFoundError(
        `TransactionId with invalid length '${id}' is  found.`,
      );
    return new TransferDebitCardTransactionId({
      prefix: id.substr(0, 4),
      currency: id.substr(4, 3) as FiatCurrencyType,
      date: id.substr(7, 8),
      serialNumber: id.substr(15, 6),
      target: id.substr(21, 6),
      commonId,
    });
  };
}
export default TransferDebitCardTransactionIdFactory;

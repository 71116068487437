import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Style
import { InviterDetailBoxStyle } from './style';

// LayoutComponent
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface ReferralTimeDepositsTemplateLayoutProps {
  children: React.ReactElement[];
}

const ReferralTimeDepositsTemplateLayout: FC<ReferralTimeDepositsTemplateLayoutProps> = ({
  children,
}: ReferralTimeDepositsTemplateLayoutProps) => {
  const elements = ['title', 'inviter', 'tabBar', 'table'];
  const [title, inviter, tabBar, table] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn padding="85 0 0 80" height="auto">
      {title}
      <Margin top={30} />
      <FlexBoxColumn padding="40 80" width="auto" style={InviterDetailBoxStyle}>
        {inviter}
      </FlexBoxColumn>
      <Margin top={100} />
      {tabBar}
      <Margin top={50} />
      <FlexBoxColumn height={1000}>{table}</FlexBoxColumn>
      <Margin top={100} />
    </FlexBoxColumn>
  );
};
export default ReferralTimeDepositsTemplateLayout;

import React from 'react';

// Constant
import { INITIAL } from '../../../config/constants/requestState';

export interface VerifyResetPasswordContextProps {
  verifyResetPasswordState: string;
  oobCode: string;
}

export const VerifyResetPasswordContext = React.createContext<
  VerifyResetPasswordContextProps
>({
  verifyResetPasswordState: INITIAL,
  oobCode: '',
});

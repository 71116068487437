import React, { FC } from 'react';
import { useConsentFormDownloadFile } from './useLinkConsentFormDownload';
import LinkConsentFormDownloadContext from './LinkConsentFormDownloadContext';

export type LinkConsentFormDownloadProviderProps = {
  children: React.ReactNode;
};

const LinkConsentFormDownloadProvider: FC<LinkConsentFormDownloadProviderProps> = ({
  children,
}: LinkConsentFormDownloadProviderProps) => {
  const consentFormFileContext = useConsentFormDownloadFile();

  return (
    <LinkConsentFormDownloadContext.Provider value={consentFormFileContext}>
      {children}
    </LinkConsentFormDownloadContext.Provider>
  );
};

export default LinkConsentFormDownloadProvider;

// Types
import { UseStateType } from '../../../types/typeof/UseState';

// IService
import IInviterQueryService from '../../../useCases/invite/inviter/IInviterQueryService';

// IRepository
import { IInviterQueryRepository } from '../../../domain/invite/inviter/IInviterRepository';

// DomainObject
import Inviter from '../../../domain/invite/inviter/Inviter/Inviter';

class InviterQueryService implements IInviterQueryService {
  private inviterQueryRepository: IInviterQueryRepository;

  private unsubscribe: Array<() => void> = [];

  constructor(inviterQueryRepository: IInviterQueryRepository) {
    this.inviterQueryRepository = inviterQueryRepository;
  }

  public setById = async (
    id: string,
    setter: UseStateType<Inviter | undefined>,
    setState: UseStateType<string>,
  ) => {
    const unsubscribe = await this.inviterQueryRepository.subscribeById(
      id,
      setter,
      setState,
    );

    this.unsubscribe.push(unsubscribe);
  };

  public executeUnsubscribe = () => {
    this.unsubscribe.forEach((unsubscribe) => unsubscribe());
  };
}

export default InviterQueryService;

import { useCallback, useState } from 'react';

// Error
import ErrorHandler from '../../../../../../utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from '../../../../../../utils/errors/ErrorHandler/IErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../../../utils/helpers/connection';
import { yyyymmdd } from '../../../../../../utils/helpers/datetimeHelper';

// Type
import {
  useFormInputPersonalInformation,
  FormInputPersonalInformation,
} from '../../../../../../components/organisms/form/verification/kycRequest/FormPersonalInformation/FormPersonalInformation.type';
import { Gender } from '../../../../../../types/KycRequest.type';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../../config/constants/requestState';

// Repository
import KycRequestFunctions from '../../../../../../repository/verification/kycRequest/KycRequestFunctions';
import CloudStorage from '../../../../../../storage/cloudStorage/CloudStorage';

// DomainObject
import KycRequest from '../../../../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequest';
import UserAuthorized from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import KycRequestSubmitUseCase from '../../../../../../useCases/verification/kycRequest/KycRequestSubmitUseCase';

// File
import { usePersonalInformationFile } from '../../../../../fileHandler/verification/kycRequest/PersonalInformationFileProvider/usePersonalInformationFile';

export const usePersonalInformationSubmitUseCase = (
  kycRequest: KycRequest | undefined,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const formInputPersonalInformationContext = useFormInputPersonalInformation();

  // File
  const {
    passport,
    setPassport,
    selfie,
    setSelfie,
  } = usePersonalInformationFile();

  /* *
   *
   *  Repository
   *
   * */
  const kycRequestFunctions = new KycRequestFunctions();
  const storageHandler = new CloudStorage();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new KycRequestSubmitUseCase(
    kycRequestFunctions,
    storageHandler,
  );

  /* *
   *
   *  Method
   *
   * */
  const submitPersonalInformation = useCallback(
    async (formInput: FormInputPersonalInformation, callback?: () => void) => {
      if (!userAuthorized || !kycRequest) return;

      setState(IS_LOADING);

      try {
        // ---check onLine ---- //
        checkInternetConnection();

        const kycRequestUpdated = kycRequest.updatePersonalInformation({
          firstName: formInput.firstName,
          middleName: formInput.middleName,
          lastName: formInput.lastName,
          gender: formInput.gender as Gender,
          dateOfBirth: yyyymmdd(
            formInput.dateOfBirthYear,
            formInput.dateOfBirthMonth,
            formInput.dateOfBirthDay,
            '/',
          ),
          passport: {
            passportNo: formInput.passportNo,
            expiredAt: yyyymmdd(
              formInput.expiredDateYear,
              formInput.expiredDateMonth,
              formInput.expiredDateDay,
              '/',
            ),
            passportUrl: formInput.passportUrl,
          },
          selfieUrl: formInput.selfieUrl,
          status: kycRequest.getPersonalInformation().getProps().status,
        });

        await useCase.submitPersonalInformation(
          kycRequestUpdated,
          passport,
          selfie,
        );

        setState(SUCCESS);

        if (callback) callback();
      } catch (error) {
        const handler: IErrorHandler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [userAuthorized, kycRequest, passport, selfie],
  );

  return {
    state,
    setState,
    submitPersonalInformation,
    formInputPersonalInformationContext,
    personalInformationFileContext: {
      passport,
      setPassport,
      selfie,
      setSelfie,
    },
  };
};

// Functions
import FirebasePrivateFunctions from '../../../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';
import { TRANSACTION_FETCH_SPOT_RAW_ALL_BY_SAVINGS_ACCOUNT_NUMBER } from '../../../../../infrastructure/firebase/firebaseFunctions/endpoint';

// Type
import { BankSpotTransactionRawDataType } from '../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankSpotTransaction/BankSpotTransaction/BankSpotTransaction.type';

// IRepository
import IBankSpotTransactionRepository from '../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankSpotTransaction/IBankSpotTransactionRepository';

// Mapper
import BankDepositFunctionsMapper from './mapper/BankDepositFunctionsMapper';
import BankWithdrawalFunctionsMapper from './mapper/BankWithdrawalFunctionsMapper';

// DomainObject
import BankDeposits from '../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankDeposit/BankDeposits/BankDeposits';
import BankWithdrawals from '../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankWithdrawal/BankWithdrawals/BankWithdrawals';

class BankSpotTransactionFunctions
  extends FirebasePrivateFunctions
  implements IBankSpotTransactionRepository {
  public findAllBySavingsAccountNumber = async (
    savingsAccountNumber: string,
  ) => {
    const endpoint = TRANSACTION_FETCH_SPOT_RAW_ALL_BY_SAVINGS_ACCOUNT_NUMBER.replace(
      /:savingsAccountNumber/g,
      savingsAccountNumber,
    );

    const response = await this.privateGET(endpoint);

    const rows = response.data.data as {
      bankDeposits: Array<BankSpotTransactionRawDataType>;
      bankWithdrawals: Array<BankSpotTransactionRawDataType>;
    };

    // Create BankDeposits from RawData
    const depositObjs = rows.bankDeposits.reduce((bankDeposits, row) => {
      bankDeposits.add(BankDepositFunctionsMapper.rawDataToDomain(row));
      return bankDeposits;
    }, new BankDeposits());

    // Create BankWithdrawals from RawData
    const withdrawobjs = rows.bankWithdrawals.reduce((bankWithdrawals, row) => {
      bankWithdrawals.add(BankWithdrawalFunctionsMapper.rawDataToDomain(row));
      return bankWithdrawals;
    }, new BankWithdrawals());

    return {
      bankDeposits: depositObjs,
      bankWithdrawals: withdrawobjs,
    };
  };
}
export default BankSpotTransactionFunctions;

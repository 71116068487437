import firestore from '../../../client/firestoreClient';
import Collection from '../../collection/Collection';

// Constant
import {
  CollectionName,
  FirestoreDomain,
} from '../../collection/Collection.type';
import { FIRESTORE_DATA_VERSION } from '../../../../../../config/constants/firebase';

class KycRequestsCollection extends Collection {
  collection: firestore.CollectionReference;

  constructor() {
    super();
    this.collection = firestore()
      .collection(FirestoreDomain.verification)
      .doc(FIRESTORE_DATA_VERSION)
      .collection(CollectionName.kycRequests);
  }
}

export default KycRequestsCollection;

import React, { useState } from 'react';

// Type
import { TransferDebitCardType } from '../../../../../../types/Transfer.type';

export interface FormInputTransferDebitCard {
  amount: string;
  debitCardId: string;
  savingsAccountNumber: string;
  transferDebitCardType: TransferDebitCardType;
  fee: number;
}

export const useFormInputTransferDebitCard = () => {
  const [formInput, setFormInput] = useState<FormInputTransferDebitCard>();

  return {
    formInput,
    setFormInput,
  };
};

export const FormInputTransferDebitCardContext = React.createContext<{
  formInput?: FormInputTransferDebitCard;
  setFormInput: (formInput?: FormInputTransferDebitCard) => void;
}>({
  formInput: undefined,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFormInput: (formInput?: FormInputTransferDebitCard) => undefined,
});

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export interface PersonalInformationFileContextProps {
  passport: File | undefined;
  setPassport: (file: File | undefined) => void;
  selfie: File | undefined;
  setSelfie: (file: File | undefined) => void;
}

const PersonalInformationFileContext = React.createContext<
  PersonalInformationFileContextProps
>({
  passport: undefined,
  setPassport: (file: File | undefined) => undefined,
  selfie: undefined,
  setSelfie: (file: File | undefined) => undefined,
});

export default PersonalInformationFileContext;

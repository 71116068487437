import React, { FC, useMemo } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';

// Type
import {
  KycStatusType,
  KycStepType,
} from '../../../../../types/KycRequest.type';

// Style
import {
  StepNoContainerStyle,
  ContainerStyle,
  ContainerDisabledStyle,
} from './style';

// Layout Component
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';

// Component
import Display from '../../../../atoms/div/Display';

export interface KycStatusCardLayoutProps {
  children: React.ReactElement[];
  status?: KycStatusType;
  kycStepType: KycStepType;
}

const REVIEW_STATUS = [
  KycStepType.waitingReview,
  KycStepType.waitingConsentFormReview,
];

const KycStatusCardLayout: FC<KycStatusCardLayoutProps> = ({
  children,
  status,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  kycStepType,
}: KycStatusCardLayoutProps) => {
  const elements = [
    'step',
    'stepNo',
    'title',
    'statusBadge',
    'description',
    'button',
  ];
  const [
    step,
    stepNo,
    title,
    statusBadge,
    description,
    button,
  ] = getElementsFromKeys(children, elements);

  const containerStyle =
    status === KycStatusType.disabled ? ContainerDisabledStyle : ContainerStyle;

  const isReviewStep = useMemo(() => REVIEW_STATUS.includes(kycStepType), [
    kycStepType,
  ]);

  return (
    <FlexBoxRow
      alignItems="center"
      height={186}
      justifyContent="center"
      theme={containerStyle}
      width={1170}
    >
      <FlexBoxRow alignItems="center" padding="30.5 0" width={944}>
        <FlexBoxColumn
          width={114}
          alignItems="center"
          justifyContent="center"
          style={StepNoContainerStyle}
        >
          {step}
          {stepNo}
        </FlexBoxColumn>
        <FlexBoxColumn width={830} paddingLeft={40.5}>
          <FlexBoxRow alignItems="center" height="auto">
            {title}
            <Margin left={22} />
            {statusBadge}
          </FlexBoxRow>
          <Margin top={10} />
          {description}
        </FlexBoxColumn>
      </FlexBoxRow>
      <Display
        isDisplay={!isReviewStep}
        style={{ width: '100%', height: '100%' }}
      >
        {button}
      </Display>
      <Display
        isDisplay={isReviewStep}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <div />
      </Display>
    </FlexBoxRow>
  );
};

export default KycStatusCardLayout;

abstract class SerialNumber {
  protected serialNumber: string;

  constructor(serialNumber: string) {
    this.serialNumber = serialNumber;
  }

  public getSerialNumber = () => this.serialNumber;
}
export default SerialNumber;

import TestActionType from '../constants/test.constant';
import { TestDoc } from '../../utils/firebase/firestore/Tests';

export interface TestUserAction {
  type: TestActionType;
}

export interface TestActionParams {
  [key: string]: TestDoc;
}

export interface TestSagaAction {
  type: TestActionType;
  params: TestActionParams;
}

export const userClickTestFetchButton = (): TestUserAction => ({
  type: TestActionType.USER_CLICK_TEST_FETCH_BUTTON,
});

export const sagaStoreTest = (params: TestActionParams): TestSagaAction => ({
  type: TestActionType.SAGA_STORE_TEST,
  params,
});

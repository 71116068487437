// IService
import ICryptoCurrencyWithdrawalQueryService from '../../../../useCases/transaction/cryptoCurrencyTransaction/cryptoCurrencyWithdrawal/ICryptoCurrencyWithdrawalQueryService';

// IRepository
import ICryptoCurrencyWithdrawalRepository from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyWithdrawal/ICryptoCurrencyWithdrawalRepository';

// DomainObject
import CryptoCurrencyWallet from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet';

class CryptoCurrencyWithdrawalQueryService
  implements ICryptoCurrencyWithdrawalQueryService {
  private cryptoCurrencyWithdrawalRepository: ICryptoCurrencyWithdrawalRepository;

  constructor(
    cryptoCurrencyWithdrawalRepository: ICryptoCurrencyWithdrawalRepository,
  ) {
    this.cryptoCurrencyWithdrawalRepository = cryptoCurrencyWithdrawalRepository;
  }

  public findByWallet = async (cryptoCurrencyWallet: CryptoCurrencyWallet) => {
    const address = cryptoCurrencyWallet.getAddress();

    const { currency } = cryptoCurrencyWallet.getProps();

    const cryptoCurrencyWithdrawals = await this.cryptoCurrencyWithdrawalRepository.findAllByFields(
      {
        address,
        currency,
      },
    );

    return cryptoCurrencyWithdrawals;
  };
}

export default CryptoCurrencyWithdrawalQueryService;

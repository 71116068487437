import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
import { TransferSavingsAccountTransactionIdProps } from './TransferSavingsAccountTransactionId.type';
import TransactionId from '../TransactionId/TransactionId';

class TransferSavingsAccountTransactionId extends TransactionId {
  private currency: FiatCurrencyType;

  constructor(props: TransferSavingsAccountTransactionIdProps) {
    super(props);
    this.currency = props.currency;
  }

  protected getPropsOrderForId = () => [
    this.prefix,
    this.currency,
    this.date,
    this.serialNumber.getSerialNumber(),
    this.target.substr(-6),
  ];

  static createNumberFromRawId = (rawId: string) => {
    return new TransferSavingsAccountTransactionId({
      prefix: rawId.substr(0, 4),
      currency: rawId.substr(4, 3) as FiatCurrencyType,
      date: rawId.substr(7, 8),
      serialNumber: rawId.substr(15, 6),
      target: rawId.substr(21, 6),
      commonId: '',
    });
  };
}
export default TransferSavingsAccountTransactionId;

import styled from 'styled-components';
import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  border-bottom: ${2 / 16}rem solid #cdd0dd;
`;

export const TextStyle = {
  fontSize: 23,
  color: color.primary,
  fontWeight: fontWeight.regular,
};

export const SelectedStyle = {
  borderBottom: `${6 / 16}rem solid ${color.primary}`,
};

export const UnSelectedStyle = {
  opacity: 0.3,
};

import { useState } from 'react';

export type TransferDebitCardPageState = 'initial' | 'confirm' | 'complete';

export const useTransferDebitCardPageState = () => {
  const [pageState, setPageState] = useState<TransferDebitCardPageState>(
    'initial',
  );

  return {
    pageState,
    setPageState,
  };
};

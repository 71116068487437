import Joi from '@hapi/joi';
import { KycStatusType } from '../../../../../types/KycRequest.type';

export interface ConsentFormStepsStatusProps {
  status: KycStatusType;

  statusConsentForm: KycStatusType;

  statusTwoFactorAuthenticationAppSettings: KycStatusType;

  statusWaitingConsentFormReview: KycStatusType;
}

export const ConsentFormStepsStatusPropsFormat = {
  status: Object.keys(KycStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),

  statusConsentForm: Object.keys(KycStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),

  statusTwoFactorAuthenticationAppSettings: Object.keys(KycStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),

  statusWaitingConsentFormReview: Object.keys(KycStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),
};

export const ConsentFormStepsStatusInitialProps = {
  status: KycStatusType.disabled,
  statusConsentForm: KycStatusType.disabled,
  statusTwoFactorAuthenticationAppSettings: KycStatusType.disabled,
  statusWaitingConsentFormReview: KycStatusType.disabled,
};

import styled from 'styled-components';
import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  width: 100%;
`;

export const LabelStyle = {
  fontSize: 21,
  color: color.primary,
  fontWeight: fontWeight.medium,
};

export const ErrorMessageStyle = {
  fontSize: 18,
  color: color.errorMessage,
  fontWeight: fontWeight.regular,
};

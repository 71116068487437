import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../../atoms/utils/layout/Margin';

export interface SelectDocumentTypeLayoutProps {
  children: React.ReactElement[];
}

const SelectDocumentTypeLayout: FC<SelectDocumentTypeLayoutProps> = ({
  children,
}: SelectDocumentTypeLayoutProps) => {
  const elements = ['description', 'selectBox'];
  const [description, selectBox] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn>
      {description}
      <Margin top={60} />
      <FlexBoxRow height="auto" width={600}>
        {selectBox}
      </FlexBoxRow>
      <Margin top={40} />
    </FlexBoxColumn>
  );
};
export default SelectDocumentTypeLayout;

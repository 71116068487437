import React, { FC } from 'react';
import UploadIconImage from '../../../../assets/images/upload.svg';
import Box from '../../utils/layout/Box/Box';

export interface UploadIconProps {
  width?: string | number;
  height?: string | number;
}

const UploadIcon: FC<UploadIconProps> = ({
  width = '100%',
  height = '100%',
}: UploadIconProps) => {
  return (
    <Box width={width} height={height}>
      <img src={UploadIconImage} alt="Upload" width="100%" height="100%" />
    </Box>
  );
};
export default UploadIcon;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { StyledTextInput } from './style';
import color from '../../utils/const/color';

export interface TextInputProps {
  name: string;
  value?: string;
  type?: 'number' | 'password' | 'tel' | 'text';
  pattern?: string;
  disabled?: boolean;
  disabledBackgroundColor?: string;
  placeholder?: string;
  textAlign?: 'left' | 'center' | 'right';
  maxLength?: number;
  onChange?: (input: string) => void;
  register?: any;
}

const TextInput: FC<TextInputProps> = ({
  name,
  value = '',
  type = 'text',
  pattern,
  disabled = false,
  textAlign = 'left',
  disabledBackgroundColor = color.background.disabled,
  placeholder = '',
  maxLength = 50,
  onChange = () => undefined,
  register = null,
}: TextInputProps) => {
  const backgroundColor = disabled ? disabledBackgroundColor : '#fff';

  return (
    <StyledTextInput
      name={name}
      autoComplete="off"
      disabled={disabled}
      onChange={(e) => onChange(e.target.value)}
      pattern={pattern}
      placeholder={placeholder}
      maxLength={maxLength}
      type={type}
      style={{ backgroundColor, textAlign: textAlign as 'left' | 'right' }}
      ref={register}
      value={value}
    />
  );
};

export default TextInput;

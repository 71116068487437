import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Constant
import { ROUTE_TIME_DEPOSIT } from '../../../../../config/constants/routing';

// Component
import { PageTitle } from '../../../../atoms/headLine/HeadLine';
import BreadCrumbLink from '../../../../molecules/navigation/BreadCrumbLink';
import HeaderContentTitleLayout from '../HeaderContentTitleLayout';

const ORGANISM_ID = `organisms.header.contract.HeaderContractTimeDepositRenewalReservationEdit`;

const HeaderContractTimeDepositRenewalReservationEdit: FC = () => {
  const { t } = useTranslation();

  return (
    <HeaderContentTitleLayout>
      <PageTitle key="left">{t(`${ORGANISM_ID}.title`)}</PageTitle>
      <BreadCrumbLink
        key="right"
        href={ROUTE_TIME_DEPOSIT}
        text={t(`${ORGANISM_ID}.linkText`)}
      />
    </HeaderContentTitleLayout>
  );
};

export default HeaderContractTimeDepositRenewalReservationEdit;

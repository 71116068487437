// IUseCase
import ISavingsAccountHistoryViewUseCase from '../../../../enhancers/useCase/bankAccount/savingsAccount/SavingsAccountHistoryViewProvider/ISavingsAccountHistoryViewUseCase';

// IService
import IBankSpotTransactionQueryService from '../../../transaction/bankTransaction/bankSpotTransaction/bankSpotTransaction/IBankSpotTransactionQueryService';
import ISavingsAccountQueryService from '../ISavingsAccountQueryService';

// Service
import BankSpotTransactionQueryService from '../../../../service/transaction/bankTransaction/bankSpotTransaction/bankSpotTransaction/BankSpotTransactionQueryService';
import SavingsAccountQueryService from '../../../../service/bankAccount/savingsAccount/SavingsAccountQueryService';

// IRepository
import IBankSpotTransactionRepository from '../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankSpotTransaction/IBankSpotTransactionRepository';
import ISavingsAccountRepository from '../../../../domain/bankAccount/savingsAccount/ISavingsAccountRepository';

class SavingsAccountHistoryViewUseCase
  implements ISavingsAccountHistoryViewUseCase {
  private savingsAccountQueryService: ISavingsAccountQueryService;

  private bankSpotTransactionQueryService: IBankSpotTransactionQueryService;

  constructor(
    bankSpotTransactionRepository: IBankSpotTransactionRepository,

    savingsAccountRepository: ISavingsAccountRepository,
  ) {
    this.bankSpotTransactionQueryService = new BankSpotTransactionQueryService(
      bankSpotTransactionRepository,
    );

    this.savingsAccountQueryService = new SavingsAccountQueryService(
      savingsAccountRepository,
    );
  }

  public open = async (savingsAccountNumber: string) => {
    const [savingsAccount, bankTransactions] = await Promise.all([
      this.savingsAccountQueryService.findById(savingsAccountNumber),
      this.bankSpotTransactionQueryService.findAllBySavingsAccountNumber(
        savingsAccountNumber,
      ),
    ]);

    return { savingsAccount, ...bankTransactions };
  };
}
export default SavingsAccountHistoryViewUseCase;

// Type
import { UseStateType } from '../../../types/typeof/UseState';
import User from '../../../domain/public/user/User/User/User';

// IService
import IUserQueryService from '../../../useCases/public/user/interface/IUserQueryService';

// IRepository
import IUserRepository from '../../../domain/public/user/User/IUserRepository';

// DomainObject
import UserReregistrationFactory from '../../../domain/public/user/User/UserReregistration/UserReregistrationFactory';

class UserQueryService implements IUserQueryService {
  private userRepository: IUserRepository;

  constructor(userRepository: IUserRepository) {
    this.userRepository = userRepository;
  }

  public findUserReregistration = async (userId: string) => {
    const user = await this.userRepository.findById(userId);

    const userReregistration = UserReregistrationFactory.createByUser(user);

    return userReregistration;
  };

  public setById = async (
    userId: string,
    setter: UseStateType<User | undefined>,
    setState: UseStateType<string>,
  ) => {
    await this.userRepository.subscribeById(userId, setter, setState);
  };
}

export default UserQueryService;

import React, { FC } from 'react';
import { useManualFiles } from '../../../storage/files/useManualFiles';
import ManualFilesContext from '../../../storage/context/ManualFilesContext';

export type ManualViewProviderProps = {
  children: React.ReactNode;
};

const ManualViewProvider: FC<ManualViewProviderProps> = ({
  children,
}: ManualViewProviderProps) => {
  const files = useManualFiles();

  return (
    <>
      <ManualFilesContext.Provider value={{ files: files.manualFiles }}>
        {children}
      </ManualFilesContext.Provider>
    </>
  );
};
export default ManualViewProvider;

import React, { FC } from 'react';
import KycRequestPage from './KycRequestPage';
import KycRequestViewProvider from '../../../enhancers/useCase/verification/kycRequest/KycRequestViewProvider';

const EnhancedKycRequestPage: FC = () => {
  return (
    <KycRequestViewProvider>
      <KycRequestPage />
    </KycRequestViewProvider>
  );
};

export default EnhancedKycRequestPage;

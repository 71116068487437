import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import ManualTemplate from '../../../components/templates/manual/ManualTemplate';

const ManualPage: FC = () => {
  return (
    <DashboardTemplate>
      <ManualTemplate />
    </DashboardTemplate>
  );
};

export default ManualPage;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DropzoneAreaContext from '../DropzoneArea/DropzoneAreaContext';

// Style
import { DescriptionStyle } from './style';

// Component
import UploadIcon from '../../../atoms/image/UploadIcon';

// Component
import { TextSecondary } from '../../../atoms/text2/Text2';
import FileUploadPreview from '../FileUploadPreview';
import DropzoneAreaLayout from './DropzonePreviewLayout';

const MOLECULE_ID = `molecules.dropzone.DropzonePreview`;
export interface DropzonePreviewProps {
  name: string;
  urlName?: string;
  fileType?: string;
}

const DropzonePreview: FC<DropzonePreviewProps> = ({
  name,
  urlName,
  fileType,
}: DropzonePreviewProps) => {
  const { t } = useTranslation();
  const { preview } = useContext(DropzoneAreaContext);

  return (
    <DropzoneAreaLayout isImageDisplay={!!preview}>
      <UploadIcon key="icon" width={37} height={37} />
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${MOLECULE_ID}.description`)}
      </TextSecondary>
      <FileUploadPreview
        key="image"
        name={name}
        urlName={urlName}
        fileType={fileType}
      />
    </DropzoneAreaLayout>
  );
};

export default DropzonePreview;

import styled from 'styled-components';
import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const ContainerStyle = {
  backgroundColor: color.background.ghostWhite,
};

export const PriceContainer = styled.div``;

export const LabelStyle = {
  fontSize: 27,
  color: color.primary,
  fontWeight: fontWeight.regular,
  opacity: 0.5,
};

export const ArrowStyle = {
  fontSize: 20,
  color: color.primary,
  fontWeight: fontWeight.regular,
  opacity: 0.5,
  textAlign: 'center',
};

export const CurrencySymbolStyle = {
  fontSize: 32,
  color: color.primary,
  fontWeight: fontWeight.regular,
  opacity: 0.5,
};

export const PriceStyle = {
  fontSize: 44,
  color: color.primary,
  fontWeight: fontWeight.bold,
};

import React, { FC } from 'react';
import { TextPrimary } from '../../../../../../atoms/text2/Text2';

// Component
import TransferSavingsAccountSelectBox from '../../../../../selectBox/selectBox/transfer/TransferSavingsAccountTypeSelectBox';
import LineBreakReplaced from '../../../../../../atoms/text2/LineBreakReplaced';
import FormInputTransferSavingsAccountTypeLayout from './FormInputTransferSavingsAccountTypeLayout';

interface FormInputTransferSavingsAccountTypeProps {
  inputWidth: string | number;
  labelProps: {
    label: string;
    theme: {
      fontSize: string | number;
      color: string;
      fontWeight: number;
    };
  };
  name: string;
  onSelect?: (value: string) => void;
}

const FormInputTransferSavingsAccountType: FC<FormInputTransferSavingsAccountTypeProps> = ({
  inputWidth,
  labelProps,
  name,
  onSelect,
}: FormInputTransferSavingsAccountTypeProps) => {
  return (
    <FormInputTransferSavingsAccountTypeLayout inputWidth={inputWidth}>
      <TextPrimary key="label" theme={labelProps.theme}>
        <LineBreakReplaced text={labelProps.label} />
      </TextPrimary>
      <TransferSavingsAccountSelectBox
        key="input"
        name={name}
        onSelect={onSelect}
      />
    </FormInputTransferSavingsAccountTypeLayout>
  );
};

export default FormInputTransferSavingsAccountType;

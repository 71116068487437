import React, { FC } from 'react';

// Type
import { MenuType } from '../../../../config/menu/main/Menu.type';
import {
  VerifiedUserSettingMenuType,
  UnverifiedUserSettingMenuType,
} from '../../../../config/menu/sub/SettingMenu.type';
import { VerifiedUserStatusType } from '../../../../types/User.type';

// Component
import MenuItemWithSub from '../MenuItemWithSub';

// DomainObject
import User from '../../../../domain/public/user/User/User/User';

export interface SettingsMenuProps {
  user: User;
}

const MAIN_MENU_TYPE = MenuType.settings;

const SettingsMenu: FC<SettingsMenuProps> = ({ user }: SettingsMenuProps) => {
  const { status } = user.getProps();

  const isVerified = Object.keys(VerifiedUserStatusType).includes(status);

  const settingsMenuItems = isVerified
    ? VerifiedUserSettingMenuType
    : UnverifiedUserSettingMenuType;

  return (
    <MenuItemWithSub
      key={MAIN_MENU_TYPE}
      type={MAIN_MENU_TYPE}
      menuItems={settingsMenuItems}
    />
  );
};

export default SettingsMenu;

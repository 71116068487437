import styled from 'styled-components';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  width: ${250 / 16}rem;
  height: 53px;
  padding-left: ${20 / 16}rem;
  padding-right: ${20 / 16}rem;
  border: ${0.25 / 16}rem solid #dbf1ff;
  color: #212f41;
  background-color: #fff;
  display: flex;
  align-items: center;

  :hover {
    background-color: #dbf1ff;
  }
`;

export const MenuTextStyle = {
  fontSize: 18,
  color: color.black,
  fontWeight: fontWeight.regular,
};

import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
import {
  DebitCardDepositType,
  DebitCardWithdrawalType,
} from '../../../../types/DebitCardTransaction.type';
import { TransactionIdProps } from '../TransactionId/TransactionId.type';

export interface DebitCardTransactionIdProps extends TransactionIdProps {
  currency: FiatCurrencyType;
}

export const PrefixDebitCardTransactionId: { [key: string]: string } = {
  [DebitCardDepositType.chargeFromSavingsAccount]: 'DTDC', // Deposit : Transfer Debit Card
  [DebitCardWithdrawalType.withdrawToSavingsAccount]: 'WWDC', // Withdrawal : Withdrawal Debit Card
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Type
import { FormInputCorporateInformation } from '../../../../../../components/organisms/form/verification/kycRequest/FormCorporateInformaition/FormCorporateInformation.type';

// Constant
import { INITIAL } from '../../../../../../config/constants/requestState';

export interface CorporateInformationSubmitContextProps {
  state: string;
  submitCorporateInformation: (
    content: FormInputCorporateInformation,
    callback?: () => void,
  ) => void;
}

const CorporateInformationSubmitContext = React.createContext<
  CorporateInformationSubmitContextProps
>({
  state: INITIAL,
  submitCorporateInformation: (
    content: FormInputCorporateInformation,
    callback?: () => void,
  ) => undefined,
});

export default CorporateInformationSubmitContext;

import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  padding-top: ${85 / 16}rem;
  padding-left: ${85 / 16}rem;
  margin-bottom: ${80 / 16}rem;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: ${1200 / 16}rem;
`;

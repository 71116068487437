// IUseCase
import ISignInUseCase from '../../../enhancers/useCase/authentication/SignInProvider/ISignInUseCase';

// IService
import IUserAuthenticationService from '../IUserAuthenticationService';
import ISignInRequestCreateService from './ISignInRequestCreateService';

// Service
import UserAuthenticationService from '../../../service/authentication/UserAuthenticationService';
import SignInRequestCreateService from '../../../service/authentication/SignInRequestCreateService';

// IRepository
import { ISignInRequestCreateRepository } from '../../../domain/userRequest/signInRequest/ISignInRequestRepository';
import IUserAuthentication from '../../../domain/public/user/User/IUserAuthentication';

class SignInUseCase implements ISignInUseCase {
  private userAuthenticationService: IUserAuthenticationService;

  private signInRequestCreateService: ISignInRequestCreateService;

  constructor(
    userAuthentication: IUserAuthentication,
    signInRequestCreateRepository: ISignInRequestCreateRepository,
  ) {
    this.userAuthenticationService = new UserAuthenticationService(
      userAuthentication,
    );
    this.signInRequestCreateService = new SignInRequestCreateService(
      signInRequestCreateRepository,
    );
  }

  public signIn = async (email: string, password: string) => {
    const userAuthorized = await this.userAuthenticationService.signIn(
      email,
      password,
    );

    await this.signInRequestCreateService.executeCreate(userAuthorized);

    return userAuthorized;
  };
}
export default SignInUseCase;

import React, { FC } from 'react';
import { CryptoCurrencyType } from '../../../../../types/CryptoCurrency.type';
import fontSize from '../../../../../fonts/fontSizePixel.json';
import fontColor from '../../../../../fonts/color.json';
import fontWeight from '../../../../../fonts/fontWeight.json';
import CryptoCurrencyIconLabelText from './CryptoCurrencyIconLabelText';

export interface CryptoCurrencyIconOptionTextProps {
  iconType: CryptoCurrencyType;
  currencyName: string;
}

const CryptoCurrencyIconOptionText: FC<CryptoCurrencyIconOptionTextProps> = ({
  iconType,
  currencyName,
}: CryptoCurrencyIconOptionTextProps) => {
  return (
    <CryptoCurrencyIconLabelText
      iconType={iconType}
      currencyName={currencyName}
      iconSize={`${25 / 16}rem`}
      textPixel={fontSize.pixel24}
      textColor={fontColor.textDark}
      textFontWeight={fontWeight.medium}
      textFontFamilyType="sub"
      marginBetween={`${16 / 16}rem`}
    />
  );
};

export default CryptoCurrencyIconOptionText;

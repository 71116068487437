import fontWeight from '../../../atoms/utils/const/fontWeight';
import color from '../../../atoms/utils/const/color';

export const TextStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  textDecoration: 'underline',
};

export const LinkButtonStyle = {
  outline: 'none',
  cursor: 'pointer',
  border: 'none',
};

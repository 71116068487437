import styled from 'styled-components';

export const Container = styled.div`
  width: 208px;
  height: 208px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: 1;
`;

export const MessageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // opacity: 1;
`;

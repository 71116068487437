import { useState } from 'react';
import CryptoCurrencyTradeLimit from './CryptoCurrencyTradeLimit';

export const useCryptoCurrencyTradeLimit = (
  defaultValue?: CryptoCurrencyTradeLimit,
) => {
  const [cryptoCurrencyTradeLimit, setCryptoCurrencyTradeLimit] = useState<
    CryptoCurrencyTradeLimit | undefined
  >(defaultValue);

  return {
    cryptoCurrencyTradeLimit,
    setCryptoCurrencyTradeLimit,
  };
};

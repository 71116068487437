import firestore from '../../../../client/firestoreClient';
import Collection from '../../../collection/Collection';
import { FIRESTORE_DATA_VERSION } from '../../../../../../../config/constants/firebase';

class CryptoCurrencyBuysCollection extends Collection {
  collection: firestore.CollectionReference;

  constructor(currency: string, address: string) {
    super();
    this.collection = firestore()
      .collection('transaction')
      .doc(FIRESTORE_DATA_VERSION)
      .collection('cryptoCurrencyTransactions')
      .doc(currency)
      .collection('addresses')
      .doc(address)
      .collection('cryptoCurrencyBuys');
  }
}

export default CryptoCurrencyBuysCollection;

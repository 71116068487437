import { useState } from 'react';
import UserAuthorized from './UserAuthorized';

export const useUserAuthorized = (initial?: UserAuthorized) => {
  const [userAuthorized, setUserAuthorized] = useState<
    UserAuthorized | undefined
  >(initial);

  return {
    userAuthorized,
    setUserAuthorized,
  };
};

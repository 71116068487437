import React, { FC } from 'react';
import { ProgressStepCircleStatusType } from '../../../../atoms/progressStep/ProgressStepCircle/ProgressStepCircle/ProgressStepCircle.type';
import ProgressStepPoint from '../../ProgressStepPoint';
import { Container } from './style';
import ProgressStepCircleBridge from '../../../../atoms/progressStep/ProgressStepCircleBridge';

export interface ProgressStepBarProps {
  progressSteps: Array<{
    stepName: string;
    status: ProgressStepCircleStatusType;
  }>;
  width: string | number;
  bridgeBorderWidth: string | number;
  marginBetween: number;
  diameterLargeCircle: string | number;
  diameterSmallCircle: string | number;
  theme: {
    circleStyle: {
      backgroundColorLarge: string;
      backgroundColorSmall: string;
      backgroundColorDisabled: string;
    };
    iconStyle: {
      iconSize: string;
      iconColor: string;
    };
    textStyle: {
      fontSize: string | number;
      color: string;
      fontWeight: number;
    };
  };
}

const ProgressStepBar: FC<ProgressStepBarProps> = ({
  progressSteps,
  width,
  marginBetween,
  diameterLargeCircle,
  diameterSmallCircle,
  bridgeBorderWidth,
  theme,
}: ProgressStepBarProps) => {
  return (
    <Container width={width} height={diameterLargeCircle}>
      {progressSteps.map((progressStep, i) => (
        <ProgressStepPoint
          key={`item${i}`}
          diameterLargeCircle={diameterLargeCircle}
          diameterSmallCircle={diameterSmallCircle}
          stepName={progressStep.stepName}
          status={progressStep.status}
          marginBetween={marginBetween}
          theme={theme}
        />
      ))}
      <ProgressStepCircleBridge
        theme={{
          backgroundColor: theme.circleStyle.backgroundColorDisabled,
        }}
        width={width}
        height={diameterLargeCircle}
        bridgeBorderWidth={bridgeBorderWidth}
      />
    </Container>
  );
};

export default ProgressStepBar;

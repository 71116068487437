import React from 'react';

export interface LinksSignUpContextProps {
  termsAndConditionsUrl?: string;
  privacyPolicyUrl?: string;
}

const LinksSignUpContext = React.createContext<LinksSignUpContextProps>({
  termsAndConditionsUrl: undefined,
  privacyPolicyUrl: undefined,
});

export default LinksSignUpContext;

import { useState } from 'react';
import { TransferSavingsAccountType } from '../../../../../../types/Transfer.type';

export const useTransferSavingsAccountTypeOption = () => {
  const [
    transferSavingsAccountTypeOption,
    setTransferSavingsAccountTypeOption,
  ] = useState<TransferSavingsAccountType>(TransferSavingsAccountType.forbes);

  return {
    transferSavingsAccountTypeOption,
    setTransferSavingsAccountTypeOption,
  };
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FORGOT_PASSWORD_COMPLETE,
  FORGOT_PASSWORD,
} from '../../../../config/constants/pageId.json';
import {
  ROUTE_HOME,
  ROUTE_SIGN_IN,
} from '../../../../config/constants/routing';
import fontColor from '../../../../fonts/color.json';
import { DescriptionContainer } from './style';
import { FrontPageTitle } from '../../../atoms/headLine/HeadLine';
import { DescriptionFront } from '../../../molecules/text/DomainDescription';
import BreadcrumbList from '../../../organisms/list/BreadcrumbList';

const ForgotPasswordCompleteTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <FrontPageTitle>{t(`${FORGOT_PASSWORD}.title`)}</FrontPageTitle>
      </div>
      <div style={{ marginTop: `${60 / 16}rem` }}>
        <DescriptionContainer>
          <DescriptionFront
            lines={[
              t(`${FORGOT_PASSWORD_COMPLETE}.description.line1`),
              t(`${FORGOT_PASSWORD_COMPLETE}.description.line2`),
              t(`${FORGOT_PASSWORD_COMPLETE}.description.line3`),
              t(`${FORGOT_PASSWORD_COMPLETE}.description.line4`),
            ]}
            marginBottom={`${25 / 16}rem`}
            color={fontColor.textDark}
          />
        </DescriptionContainer>
      </div>
      <div style={{ marginTop: `${60 / 16}rem` }}>
        <BreadcrumbList
          color={fontColor.textDark}
          linkedTexts={[
            {
              text: t(`${FORGOT_PASSWORD}.navigation.signIn`),
              urlList: [
                {
                  replaceText: t(`${FORGOT_PASSWORD}.link.signIn`),
                  url: ROUTE_SIGN_IN,
                },
              ],
            },
            {
              text: t(`${FORGOT_PASSWORD}.link.top`),
              urlList: [
                {
                  replaceText: t(`${FORGOT_PASSWORD}.link.top`),
                  url: ROUTE_HOME,
                },
              ],
            },
          ]}
        />
      </div>
    </>
  );
};

export default ForgotPasswordCompleteTemplate;

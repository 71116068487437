// Validation
import { min, enoughBalance } from '../validator';

// Type
import { TypeOfT } from '../../../../types/typeof/Translation.type';

const cryptoCurrencyTradeValidation = (t: TypeOfT) => ({
  buyPayAmount: (balance: number, minValue: number) => ({
    validate: {
      enoughBalance: enoughBalance(balance, t),
      greaterThan: min('minUSDTrade', minValue, t),
    },
  }),
  sellPayAmount: (balance: number, minOrderQty: number) => ({
    validate: {
      enoughBalance: enoughBalance(balance, t),
      greaterThanEqual: min('minBTCTrade', minOrderQty, t),
    },
  }),
});

export default cryptoCurrencyTradeValidation;

import React, { FC } from 'react';
import storage from '../../../../utils/firebase/cloudStorage/storage';

// Lib
import { getFilenameFromPath } from '../../../../utils/helpers/fileHelper';
import { convertRem } from '../../utils/helper/helper';

// Style
import { StyledButton } from './style';

export interface FileDownloadButtonProps {
  filePath: string;
  theme: {
    backgroundColor: string;
  };
  height: string | number;
  width: string | number;
  children: React.ReactNode;
}

const FileDownloadButton: FC<FileDownloadButtonProps> = ({
  children,
  filePath,
  height,
  theme,
  width,
}: FileDownloadButtonProps) => {
  const onClick = async () => {
    const filename = getFilenameFromPath(filePath);
    const pathReference = storage.ref(filePath);
    const url = await pathReference.getDownloadURL();

    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const blob = xhr.response;
      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.download = filename;
      document.body.appendChild(element);
      element.click();
    };
    xhr.open('GET', url);
    xhr.send();
  };

  return (
    <StyledButton
      style={{
        ...theme,
        width: convertRem(width),
        height: convertRem(height),
      }}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

export default FileDownloadButton;

import React, { FC, useContext } from 'react';
import SignInPageStateContext from '../../../enhancers/pageState/authentication/SignInPageState/SignInPageStateContext';
import SignInTemplate from '../../../components/templates/front/SignInTemplate';
import SignInTwoFactorAuthTemplate from '../../../components/templates/front/SignInTwoFactorAuthTemplate';

const SignInPage: FC = () => {
  const { pageState } = useContext(SignInPageStateContext);

  if (pageState === 'twoFactorAuth') return <SignInTwoFactorAuthTemplate />;

  return <SignInTemplate />;
};

export default SignInPage;

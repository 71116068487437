import CryptoCurrencyTradeTransaction from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction';
import CryptoCurrencyTradeTransactionSeed from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed';
import { TypeOfT } from '../../../../../types/typeof/Translation.type';
import { CurrencyPairProps } from '../../../../../types/CurrencyPair.type';
import { CryptoCurrencyTradeType } from '../../../../../types/CryptoCurrency.type';
import { convertMoneyFormat } from '../../../../../utils/helpers/currencyHelper';
import InvalidArgumentError from '../../../../../utils/errors/InvalidArgumentError';

const ORGANISM_ID = `organisms.table.TableConfirmCryptoCurrencyTrade`;

class CryptoCurrencyTradeTableMapper {
  static toTableData = (
    cryptoCurrencyTradeTransaction:
      | CryptoCurrencyTradeTransactionSeed
      | CryptoCurrencyTradeTransaction,
    t: TypeOfT,
  ) => {
    const props = cryptoCurrencyTradeTransaction.getProps();
    const tradeDetail = cryptoCurrencyTradeTransaction.getTradeDetail();

    // Rate
    const currencyPair = cryptoCurrencyTradeTransaction.getCurrencyPair();
    const { rateUnit } = CurrencyPairProps[currencyPair];

    // Account
    const {
      payAccountText,
      receiveAccountText,
    } = CryptoCurrencyTradeTableMapper.createAccountText(
      tradeDetail.payAccount,
      tradeDetail.receiveAccount,
      props.tradeType,
      t,
    );

    // Amount
    const {
      payCurrency,
      payAmount,
      receiveCurrency,
      receiveAmount,
    } = tradeDetail;

    return [
      {
        header: t(`${ORGANISM_ID}.th.rate`),
        data: `${convertMoneyFormat(props.rate, currencyPair)} ${rateUnit}`,
      },
      {
        header: t(`${ORGANISM_ID}.th.payAccount`),
        data: payAccountText,
      },
      {
        header: t(`${ORGANISM_ID}.th.payAmount`),
        data: `${payCurrency} ${convertMoneyFormat(payAmount, payCurrency)}`,
      },

      {
        header: t(`${ORGANISM_ID}.th.receiveAccount`),
        data: receiveAccountText,
      },
      {
        header: t(`${ORGANISM_ID}.th.receiveAmount`),
        data: `${receiveCurrency} ${convertMoneyFormat(
          receiveAmount,
          receiveCurrency,
        )}`,
      },
    ];
  };

  static createAccountText = (
    payAccount: string,
    receiveAccount: string,
    tradeType: CryptoCurrencyTradeType,
    t: TypeOfT,
  ) => {
    let payAccountText = '';
    let receiveAccountText = '';

    switch (tradeType) {
      case CryptoCurrencyTradeType.buy:
        payAccountText = t(`${ORGANISM_ID}.td.savingsAccount`, {
          savingsAccount: payAccount,
        });
        receiveAccountText = t(`${ORGANISM_ID}.td.wallet`, {
          currency: receiveAccount,
        });
        break;
      case CryptoCurrencyTradeType.sell:
        receiveAccountText = t(`${ORGANISM_ID}.td.savingsAccount`, {
          savingsAccount: receiveAccount,
        });
        payAccountText = t(`${ORGANISM_ID}.td.wallet`, {
          currency: payAccount,
        });
        break;
      default:
        throw new InvalidArgumentError(
          `Invalid tradeType ${tradeType} is found as CryptoCurrencyTradeTableMapper.`,
        );
    }
    return { payAccountText, receiveAccountText };
  };

  static createReceiveAccountText = (
    receiveAccount: string,
    tradeType: CryptoCurrencyTradeType,
    t: TypeOfT,
  ) => {
    switch (tradeType) {
      case CryptoCurrencyTradeType.buy:
        return t(`${ORGANISM_ID}.td.wallet`, { currency: receiveAccount });
      case CryptoCurrencyTradeType.sell:
        return t(`${ORGANISM_ID}.td.savingsAccount`, {
          savingsAccount: receiveAccount,
        });
      default:
        throw new InvalidArgumentError(
          `Invalid tradeType ${tradeType} is found as CryptoCurrencyTradeTableMapper.`,
        );
    }
  };
}

export default CryptoCurrencyTradeTableMapper;

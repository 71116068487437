import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Constant
import { ASSET_SUMMARY } from '../../../../config/constants/pageId.json';

// Component
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import AssetCryptoCurrencyCard from '../../../organisms/card/asset/AssetCryptoCurrencyCard';
import AssetTimeDepositCard from '../../../organisms/card/asset/AssetTimeDepositCard';
import AssetSavingsAccountCard from '../../../organisms/card/asset/AssetSavingsAccountCard';
import AssetDebitCardCard from '../../../organisms/card/asset/AssetDebitCardCard';
import AssetSummaryTemplateLayout from './AssetSummaryTemplateLayout';

const AssetSummaryTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <AssetSummaryTemplateLayout>
      <PageTitle key="title">{t(`${ASSET_SUMMARY}.title`)}</PageTitle>
      <AssetCryptoCurrencyCard key="crypto" />
      <AssetTimeDepositCard key="timeDeposit" />
      <AssetSavingsAccountCard key="savingsAccount" />
      <AssetDebitCardCard key="debitCard" />
    </AssetSummaryTemplateLayout>
  );
};
export default AssetSummaryTemplate;

import { addByDecimal } from '../../../../../utils/helpers/calculationHelper';
// Type
import { TypeOfT } from '../../../../../types/typeof/Translation.type';
import { TransferDebitCardType } from '../../../../../types/Transfer.type';

// Helper functions
import {
  convertMoneyFormat,
  getSymbolFromCurrency,
} from '../../../../../utils/helpers/currencyHelper';

// DomainObject
import DebitCardV2 from '../../../../../domain/debitCard/debitCardV2/DebitCardV2/DebitCardV2';
import TransferDebitCardTransactionV2Seed from '../../../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2Seed/TransferDebitCardTransactionV2Seed';

const ORGANISM_ID = `organisms.table.TableConfirmTransferDebitCard`;

class TransferDebitCardTransactionTableMapper {
  static toTableData = (
    debitCardV2: DebitCardV2,
    transferDebitCardTransactionV2Seed: TransferDebitCardTransactionV2Seed,
    t: TypeOfT,
  ) => {
    const {
      amount,
      currency,
      transferType,
      fee,
    } = transferDebitCardTransactionV2Seed.getProps();

    const { type } = debitCardV2.getProps();

    // Account
    const debitCardNumber = debitCardV2.getFormattedFacedNumber();
    const savingsAccountNumber = transferDebitCardTransactionV2Seed.getFormattedSavingsAccountNumber();

    // Amount
    const amountFormatted = convertMoneyFormat(amount, currency);
    const feeFormatted = convertMoneyFormat(fee, currency);
    const totalPayment = addByDecimal(amount, fee);
    const totalPaymentFormatted = convertMoneyFormat(totalPayment, currency);
    const currencySymbol = getSymbolFromCurrency(currency);

    // Td
    const tdDebitCard = t(`${ORGANISM_ID}.td.debitCard`, {
      debitCard: debitCardNumber,
      type,
    });
    const tdSavingsAccount = t(`${ORGANISM_ID}.td.savingsAccount`, {
      savingsAccount: savingsAccountNumber,
    });
    const [tdFrom, tdTo] =
      transferType === TransferDebitCardType.savingsAccountToCard
        ? [tdSavingsAccount, tdDebitCard]
        : [tdDebitCard, tdSavingsAccount];

    return [
      {
        header: t(`${ORGANISM_ID}.th.from`),
        data: tdFrom,
      },
      {
        header: t(`${ORGANISM_ID}.th.to`),
        data: tdTo,
      },
      {
        header: t(`${ORGANISM_ID}.th.amount`),
        data: `${currencySymbol} ${amountFormatted}`,
      },
      {
        header: t(`${ORGANISM_ID}.th.fee`),
        data: `${currencySymbol} ${feeFormatted}`,
      },
      {
        header: t(`${ORGANISM_ID}.th.totalPayment`),
        data: `${currencySymbol} ${totalPaymentFormatted}`,
      },
    ];
  };
}

export default TransferDebitCardTransactionTableMapper;

import { useState } from 'react';

export const useCorporateAddressFile = () => {
  const [proofOfDocument1, setProofOfDocument1] = useState<File | undefined>();

  return {
    proofOfDocument1,
    setProofOfDocument1,
  };
};

import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const FileNameTextStyle = {
  fontSize: 20,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  textDecoration: 'underLine',
};

export const DeleteButtonStyle = {
  borderRadius: `${25 / 16}rem`,
};

export const DeleteButtonTextStyle = {
  fontSize: 18,
  color: color.white,
  fontWeight: fontWeight.regular,
};

import { useState } from 'react';

export type CryptoCurrencyTradePageState = 'initial' | 'confirm' | 'complete';

export const useCryptoCurrencyTradePageState = () => {
  const [pageState, setPageState] = useState<CryptoCurrencyTradePageState>(
    'initial',
  );

  return {
    pageState,
    setPageState,
  };
};

// FirebaseFunctions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// Const
import { CRYPTO_CURRENCY_GET_TRADE_LIMIT } from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// Type
import { CurrencyPairType } from '../../../types/CurrencyPair.type';
import { CryptoCurrencyTradeLimitRawDataType } from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeLimit/CryptoCurrencyTradeLimit/CryptoCurrencyTradeLimit.type';

// IRepository
import ICryptoCurrencyTradeLimitRepository from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeLimit/ICryptoCurrencyTradeLimitRepository';

// DomainObject
import CryptoCurrencyTradeLimitFactory from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeLimit/CryptoCurrencyTradeLimit/CryptoCurrencyTradeLimitFactory';

class CryptoCurrencyTradeLimitFunctions
  extends FirebasePrivateFunctions
  implements ICryptoCurrencyTradeLimitRepository {
  public findByCurrencyPair = async (currencyPair: CurrencyPairType) => {
    const targetCurrencyPair =
      currencyPair === CurrencyPairType.BTCUSD
        ? CurrencyPairType.BTCUSDT
        : currencyPair;

    const endpoint = CRYPTO_CURRENCY_GET_TRADE_LIMIT.replace(
      /:currencyPair/,
      targetCurrencyPair,
    );

    const response = await this.privateGET(endpoint);
    const row = response.data.data as CryptoCurrencyTradeLimitRawDataType;

    const cryptoCurrencyTradeLimit = CryptoCurrencyTradeLimitFactory.create(
      row.id,
      row.props,
    );

    return cryptoCurrencyTradeLimit;
  };
}
export default CryptoCurrencyTradeLimitFunctions;

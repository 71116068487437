// Type
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
import {
  TransferDebitCardType,
  TransferDebitCardStatusType,
} from '../../../../types/Transfer.type';
import { TransferDebitCardTransactionV2Props } from './TransferDebitCardTransactionV2.type';

// DomainObject
import Transaction from '../../transaction/Transaction/Transaction';

// ValueObject
import SavingsAccountNumber from '../../../../value/id/SavingsAccountNumber';
import TransferDebitCardTransactionId from '../../../idManager/transactionId/TransferDebitCardTransactionId/TransferDebitCardTransactionId';

class TransferDebitCardTransactionV2 extends Transaction {
  protected debitCardId: string;

  protected savingsAccountNumber: SavingsAccountNumber;

  protected amount: number;

  protected commonId: string;

  protected currency: FiatCurrencyType;

  protected fee: number;

  protected maxTotalTimeDeposit: number;

  protected totalToppedUpAmount: number;

  protected rejectedReason: string | undefined;

  protected status: TransferDebitCardStatusType;

  protected transferType: TransferDebitCardType;

  protected createdAt: number;

  protected updatedAt: number;

  protected props: TransferDebitCardTransactionV2Props;

  constructor(
    id: TransferDebitCardTransactionId,
    props: TransferDebitCardTransactionV2Props,
  ) {
    super(id);
    this.debitCardId = props.debitCardId;
    this.savingsAccountNumber = new SavingsAccountNumber(
      props.savingsAccountNumber,
    );
    this.amount = props.amount;
    this.commonId = props.commonId;
    this.currency = props.currency;
    this.fee = props.fee;
    this.maxTotalTimeDeposit = props.maxTotalTimeDeposit;
    this.totalToppedUpAmount = props.totalToppedUpAmount;
    this.rejectedReason = props.rejectedReason;
    this.status = props.status;
    this.transferType = props.transferType;
    this.createdAt = props.createdAt || Date.now();
    this.updatedAt = props.updatedAt || Date.now();
    this.props = props;
  }

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public getFormattedSavingsAccountNumber = () =>
    this.savingsAccountNumber.getFormattedId();

  public isActive = () =>
    [
      TransferDebitCardStatusType.confirmed,
      TransferDebitCardStatusType.unconfirmed,
    ].includes(this.status);

  public isUnconfirmed = () =>
    [TransferDebitCardStatusType.unconfirmed].includes(this.status);

  public isCreatedAfter = (timestamp: number) => this.createdAt > timestamp;
}

export default TransferDebitCardTransactionV2;

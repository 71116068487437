// Functions
import FirebasePrivateFunctions from '../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';
import {
  CANCEL_TRANSFER_DEBIT_CARD_TRANSACTION_V2_CREATE,
  TRANSFER_DEBIT_CARD_GET_RAW_ALL_BY_USER_ID,
  TRANSFER_DEBIT_CARD_REQUEST_V2,
  TRANSFER_DEBIT_CARD_TRANSACTION_GET_RAW_ALL_BY_CARD_ID,
} from '../../infrastructure/firebase/firebaseFunctions/endpoint';

// Type
import { TransferDebitCardTransactionV2RawDataType } from '../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2/TransferDebitCardTransactionV2.type';

// IRepository
import ITransferDebitCardTransactionV2Repository from '../../domain/transaction/transferDebitCardTransaction/ITransferDebitCardTransactionV2Repository';

// DomainObject
import TransferDebitCardTransactionV2Seed from '../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2Seed/TransferDebitCardTransactionV2Seed';
import TransferDebitCardTransactionV2Factory from '../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2/TransferDebitCardTransactionV2Factory';
import TransferDebitCardTransactionV2s from '../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2s/TransferDebitCardTransactionV2s';
import TransferDebitCardTransactionIdFactory from '../../domain/idManager/transactionId/TransferDebitCardTransactionId/TransferDebitCardTransactionIdFactory';

class TransferDebitCardTransactionV2Functions
  extends FirebasePrivateFunctions
  implements ITransferDebitCardTransactionV2Repository {
  // public findAllUnconfirmedByUserId = async (userId: string) => {
  //   const endpoint = TRANSFER_DEBIT_CARD_TRANSACTION_GET_RAW_UNCONFIRMED_ALL_BY_USER_ID.replace(
  //     /:userId/g,
  //     userId,
  //   );

  //   const response = await this.privateGET(endpoint);

  //   const rows = response.data.data as Array<
  //     TransferDebitCardTransactionV2RawDataType
  //   >;

  //   return rows.reduce((transactions, row) => {
  //     const transferDebitCardTransactionId = TransferDebitCardTransactionIdFactory.createFromRawId(
  //       row.id,
  //       row.props.commonId,
  //     );

  //     const transaction = TransferDebitCardTransactionV2Factory.create(
  //       transferDebitCardTransactionId,
  //       row.props,
  //     );

  //     transactions.add(transaction);

  //     return transactions;
  //   }, new TransferDebitCardTransactionV2s());
  // };

  public findAllByDebitCardId = async (debitCardId: string) => {
    const endpoint = TRANSFER_DEBIT_CARD_TRANSACTION_GET_RAW_ALL_BY_CARD_ID.replace(
      /:cardId/g,
      debitCardId,
    );

    const response = await this.privateGET(endpoint);

    const rows = response.data.data as Array<
      TransferDebitCardTransactionV2RawDataType
    >;

    return rows.reduce((transactions, row) => {
      const transferDebitCardTransactionId = TransferDebitCardTransactionIdFactory.createFromRawId(
        row.id,
        row.props.commonId,
      );

      const transaction = TransferDebitCardTransactionV2Factory.create(
        transferDebitCardTransactionId,
        row.props,
      );

      transactions.add(transaction);

      return transactions;
    }, new TransferDebitCardTransactionV2s());
  };

  public create = async (
    transferDebitCardTransactionSeed: TransferDebitCardTransactionV2Seed,
  ) => {
    const props = transferDebitCardTransactionSeed.getRequestParameterCreate();

    const response = await this.privatePOST(
      `${TRANSFER_DEBIT_CARD_REQUEST_V2}`,
      props,
    );

    const row = response.data.data as TransferDebitCardTransactionV2RawDataType;

    const transferDebitCardTransactionId = TransferDebitCardTransactionIdFactory.createFromRawId(
      row.id,
      row.props.commonId,
    );

    return TransferDebitCardTransactionV2Factory.create(
      transferDebitCardTransactionId,
      row.props,
    );
  };

  public findAllByUserId = async (userId: string) => {
    const endpoint = TRANSFER_DEBIT_CARD_GET_RAW_ALL_BY_USER_ID.replace(
      /:userId/g,
      userId,
    );

    const response = await this.privateGET(endpoint);

    const rows = response.data.data as Array<
      TransferDebitCardTransactionV2RawDataType
    >;

    return rows.reduce((transactions, row) => {
      const transferDebitCardTransactionId = TransferDebitCardTransactionIdFactory.createFromRawId(
        row.id,
        row.props.commonId,
      );

      const transaction = TransferDebitCardTransactionV2Factory.create(
        transferDebitCardTransactionId,
        row.props,
      );

      transactions.add(transaction);

      return transactions;
    }, new TransferDebitCardTransactionV2s());
  };

  public cancelTopUp = async (id: string) => {
    const endpoint = CANCEL_TRANSFER_DEBIT_CARD_TRANSACTION_V2_CREATE;
    await this.privatePOST(endpoint, { id });
  };
}
export default TransferDebitCardTransactionV2Functions;

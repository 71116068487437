import { useState } from 'react';
import DebitCardSpotTransactionV2s from './DebitCardSpotTransactionV2s';

export const useDebitCardSpotTransactionV2s = (
  defaultValue?: DebitCardSpotTransactionV2s,
) => {
  const [
    debitCardSpotTransactionV2s,
    setDebitCardSpotTransactionV2s,
  ] = useState<DebitCardSpotTransactionV2s>(
    defaultValue || new DebitCardSpotTransactionV2s(),
  );

  return {
    debitCardSpotTransactionV2s,
    setDebitCardSpotTransactionV2s,
  };
};

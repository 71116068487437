import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import ResignUpRequestContext from '../../../../domain/userRequest/resignUpRequest/ResignUpRequest/ResignUpRequestContext';
import ReregistrationPageStateContext from '../../../pageState/authentication/ReregistrationPageState/ReregistrationPageStateContext';
import UserReregistrationContext from '../../../../domain/public/user/User/UserReregistration/UserReregistrationContext';
import { useReregistrationPageState } from '../../../pageState/authentication/ReregistrationPageState/useReregistrationPageState';

// Constant
import { ErrorCodeType } from '../../../../utils/errors/ErrorHandler/ErrorCode.type';
import { ROUTE_AUTH_ERROR } from '../../../../config/constants/routing';
import { IS_LOADING } from '../../../../config/constants/requestState';

// Hook
import { useReregistrationViewUseCase } from './useReregistrationViewUseCase';

// Component
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';
import LoadingModal from '../../../../components/organisms/modal/LoadingModal';

export type ReregistrationViewProviderProps = {
  children: React.ReactNode;
};

const ReregistrationViewProvider: FC<ReregistrationViewProviderProps> = ({
  children,
}: ReregistrationViewProviderProps) => {
  const { oobCode } = useParams<{ oobCode: string }>();
  const {
    state,
    setState,
    resignUpRequest,
    user,
  } = useReregistrationViewUseCase(oobCode as string);

  const { pageState, setPageState } = useReregistrationPageState();

  const history = useHistory();

  // LoadingModal
  const [showModal, hideModal] = useModal(() => <LoadingModal />);

  /* *
   *
   *  UseEffect
   *
   * */

  // 401 Error Redirect
  useEffect(() => {
    const redirectState = [
      ErrorCodeType.AUTHENTICATION_RE_SIGN_UP_REQUEST_EXPIRED,
      ErrorCodeType.AUTHENTICATION_RE_SIGN_UP_REQUEST_NOT_FOUND,
    ];

    if (redirectState.includes(state as ErrorCodeType))
      history.replace(ROUTE_AUTH_ERROR);
  }, [state, history]);

  useEffect(() => {
    if (state === IS_LOADING) showModal();

    if (state !== IS_LOADING) hideModal();
  }, [state, showModal, hideModal]);

  return (
    <ReregistrationPageStateContext.Provider
      value={{ pageState, setPageState }}
    >
      <UserReregistrationContext.Provider value={{ user }}>
        <ResignUpRequestContext.Provider value={{ resignUpRequest }}>
          <SystemErrorHandler state={state} setState={setState}>
            {children}
          </SystemErrorHandler>
        </ResignUpRequestContext.Provider>
      </UserReregistrationContext.Provider>
    </ReregistrationPageStateContext.Provider>
  );
};
export default ReregistrationViewProvider;

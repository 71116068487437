import React, { FC } from 'react';
import reactStringReplace from 'react-string-replace';
import { TextSecondary, TextThemeProps } from '../../../atoms/text2/Text2';
import { TextButtonSecondary } from '../../../atoms/text2/TextButton';
import { FlexRow } from '../../../atoms/utils/layout/Flex';

export interface LinkButtonTextSentenceProps {
  text: string;
  targets: Array<{
    replaceText: string;
    onClick?: () => void;
  }>;
  theme: {
    text: TextThemeProps;
    link: TextThemeProps;
  };
}

const LinkButtonTextSentence: FC<LinkButtonTextSentenceProps> = ({
  text,
  targets,
  theme,
}: LinkButtonTextSentenceProps) => {
  let items: string | React.ReactNodeArray = text;

  // eslint-disable-next-line no-restricted-syntax
  for (const replace of targets) {
    items = reactStringReplace(items, replace.replaceText, (match) => {
      return (
        <TextButtonSecondary theme={theme.link} onClick={replace.onClick}>
          {match}
        </TextButtonSecondary>
      );
    });
  }

  return (
    <FlexRow alignItems="center">
      {(items as React.ReactNodeArray).map((item, i) => {
        if (typeof item === 'string') {
          // Replace space to &thinsp if last letter is space;
          if (item.substr(item.length, 1) === '')
            return (
              <TextSecondary key={`item${i}`} theme={theme.text}>
                {item}&thinsp;
              </TextSecondary>
            );

          return (
            <TextSecondary key={`item${i}`} theme={theme.text}>
              {item}
            </TextSecondary>
          );
        }
        return <span key={`item${i}`}>{item}</span>;
      })}
    </FlexRow>
  );
};
export default LinkButtonTextSentence;

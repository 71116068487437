import React, { FC } from 'react';
import VerifyResetPassword from '../../../enhancers/front/VerifyResetPassword';
import ResetPassword from '../../../enhancers/front/ResetPassword';
import ResetPasswordPage from './ResetPasswordPage';

const EnhancedResetPasswordPage: FC = () => {
  return (
    <VerifyResetPassword>
      <ResetPassword>
        <ResetPasswordPage />
      </ResetPassword>
    </VerifyResetPassword>
  );
};

export default EnhancedResetPasswordPage;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextPrimary, TextThemeProps } from '../../../../atoms/text2/Text2';
import { convertRem } from '../../../../atoms/utils/helper/helper';
import { Container } from './style';

export type UnderBarTabItemProps = {
  item: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (type: any) => void;
  selected?: string;
  theme: {
    text: TextThemeProps;
    selected: {
      borderBottom: string;
    };
    unSelected: {
      opacity: number;
    };
  };
  width: string | number;
};

const UnderBarTabItem: FC<UnderBarTabItemProps> = ({
  item,
  onClick,
  selected,
  theme,
  width,
}: UnderBarTabItemProps) => {
  const { t } = useTranslation();

  const containerStyle = item === selected ? theme.selected : theme.unSelected;

  return (
    <Container
      style={{
        ...containerStyle,
        width: convertRem(width),
      }}
      onClick={() => onClick(item)}
    >
      <TextPrimary theme={theme.text}>
        {t(`molecules.tabItem.${item}`)}
      </TextPrimary>
    </Container>
  );
};
export default UnderBarTabItem;

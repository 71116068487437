import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

// LayoutComponent
import Box from '../../../../../atoms/utils/layout/Box/Box';

// Mapper
import TimeDepositOptionsMapper from '../../../option/mapper/TimeDepositsOptionsMapper';

// Component
import {
  TimeDepositOptionContainer,
  TimeDepositSingleValueContainer,
} from '../../../option/optionContainer/TimeDepositOptionContainer';
import HiddenInput from '../../../../../atoms/form/HiddenInput';
import SelectBox from '../../../../../atoms/select/SelectBox';

// DomainObject
import TimeDeposits from '../../../../../../domain/product/timeDeposit/TimeDeposits/TimeDeposits';

export interface TimeDepositFilteredSelectBoxProps {
  name: string;
  timeDepositsFiltered: TimeDeposits;
}

const TimeDepositFilteredSelectBox: FC<TimeDepositFilteredSelectBoxProps> = ({
  name,
  timeDepositsFiltered,
}: TimeDepositFilteredSelectBoxProps) => {
  const { setValue, watch } = useFormContext();

  const options = TimeDepositOptionsMapper.domainToOption(timeDepositsFiltered);

  return (
    <Box>
      <SelectBox
        borderColor="#d6ddec"
        dropdownColor="#212F41"
        isSearchable={false}
        marginLeft={`${0 / 16}rem`}
        minHeight={`${75 / 16}rem`}
        onSelect={(id: string) => {
          setValue(name, timeDepositsFiltered.filterById(id).getId());
        }}
        options={options || []}
        placeholderColor="#D5D0DA"
        value={watch(name)}
        OptionComponent={TimeDepositOptionContainer}
        SingleValueComponent={TimeDepositSingleValueContainer}
      />
      <HiddenInput name={name} value={watch(name) || ''} />
    </Box>
  );
};

export default TimeDepositFilteredSelectBox;

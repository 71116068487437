import { BASE_CURRENCY_SYMBOL } from '../../../../../config/constants/business';
import { TypeOfT } from '../../../../../types/typeof/Translation.type';
import { getSymbolFromCurrency } from '../../../../../utils/helpers/currencyHelper';

const CSV_LABEL_ID = 'organisms.csv.debitCard.DebitCardSpotTransactions.label';

const CSV_HEADER_KEYS = [
  'createdAtCSV',
  'yearMonth',
  'transactionType',
  'description',
  'deposit',
  'withdrawal',
];

const debitCardSpotTransactionsCSVScheme = (t: TypeOfT) => ({
  fileName: 'card-transaction-history.csv',
  headers: CSV_HEADER_KEYS.map((key) => ({
    key,
    label: t(`${CSV_LABEL_ID}.${key}`, {
      currency: getSymbolFromCurrency(BASE_CURRENCY_SYMBOL),
    }),
  })),
});

export default debitCardSpotTransactionsCSVScheme;

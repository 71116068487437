import styled from 'styled-components';
import BackgroundImage from '../../../../assets/images/bg.png';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  height: 100%;
  overflow: hidden;
  background-image: url(${BackgroundImage});
  background-size: cover;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const CopyRightTextStyle = {
  fontSize: 18,
  color: color.white,
  fontWeight: fontWeight.regular,
};

import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// Mapper
import KycStepsMapper from './mapper/KycStepMapper';

// Component
import KycStatusCard from '../../card/verification/KycStatusCard';

// DomainObject
import KycRequest from '../../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequest';
import ConsentForm from '../../../../domain/verification/consentForm/ConsentForm/ConsentForm';

export interface KycStatusListProps {
  kycRequest?: KycRequest;
  consentForm?: ConsentForm;
}

const KycStatusList: FC<KycStatusListProps> = (props: KycStatusListProps) => {
  const { kycRequest, consentForm } = props;
  const history = useHistory();

  const kycSteps = useMemo(
    () =>
      !kycRequest ? [] : KycStepsMapper.kycRequestToSteps(kycRequest, history),
    // eslint-disable-next-line
    [kycRequest],
  );

  const consentFormSteps = useMemo(
    () =>
      !consentForm
        ? []
        : KycStepsMapper.consentFormToSteps(consentForm, history),
    // eslint-disable-next-line
    [consentForm],
  );

  // Concat KycSteps & ConsentFormSteps
  const verificationSteps = kycSteps.concat(consentFormSteps);

  return (
    <>
      {verificationSteps.map((kycStep, i) => {
        return (
          <div key={`status${i}`} style={{ marginBottom: `${41 / 16}rem` }}>
            <KycStatusCard
              order={i + 1}
              userType={kycRequest?.getProps().type}
              kycStepType={kycStep.kycStepType}
              status={kycStep.status}
              onClick={kycStep.onClick ? kycStep.onClick : () => undefined}
            />
          </div>
        );
      })}
    </>
  );
};

export default KycStatusList;

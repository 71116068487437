import React, { FC } from 'react';
import SignUpPage from './SignUpPage';
import Countdown from '../../../enhancers/utils/Countdown';
import SignUpProvider from '../../../enhancers/useCase/authentication/SignUpProvider';
import SignUpPageViewProvider from '../../../enhancers/useCase/authentication/SignUpPageViewProvider';

const EnhancedSignUpPage: FC = () => {
  return (
    <SignUpPageViewProvider>
      <SignUpProvider>
        <Countdown>
          <SignUpPage />
        </Countdown>
      </SignUpProvider>
    </SignUpPageViewProvider>
  );
};

export default EnhancedSignUpPage;

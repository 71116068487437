/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export interface RegisterPageStateContextProps {
  pageState: string;
  setPageState: (state: string) => void;
}

const RegisterPageStateContext = React.createContext<
  RegisterPageStateContextProps
>({
  pageState: 'initial',
  setPageState: (state: string) => undefined,
});

export default RegisterPageStateContext;

import React, { FC } from 'react';

export interface ImageProps {
  width: string;
  height: string;
  src?: string;
  alt?: string;
}

const Image: FC<ImageProps> = ({
  width = '100%',
  height = '100%',
  src = '',
  alt = '',
}: ImageProps) => {
  return (
    <div style={{ width, height }}>
      <img src={src || ''} alt={alt} width="100%" height="100%" />
    </div>
  );
};
export default Image;

// Type
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
import {
  BankAccountTransactionType,
  BankDepositType,
} from '../../../../types/BankAccountTransaction.type';
import { PrefixBankTransactionId } from './BankTransactionId.type';

// DomainObject
import BankTransactionId from './BankTransactionId';

// Value
import TimeZonedTime from '../../../../value/datetime/TimeZonedTime';

// Error
import InvalidDataFoundError from '../../../../utils/errors/InvalidDataFoundError';

const TRANSACTION_ID_LENGTH = 27;

class BankTransactionIdFactory {
  static create = (
    bankTransactionType: BankAccountTransactionType,
    commonId: string,
    currency: FiatCurrencyType,
    target: string,
    serialNumber: string,
  ): BankTransactionId => {
    const prefix = PrefixBankTransactionId[bankTransactionType];
    const now = new TimeZonedTime();
    const date = now.format('YYYYMMDD');

    if (!prefix)
      throw new InvalidDataFoundError(
        `Prefix of ${bankTransactionType} is not found.`,
      );

    return new BankTransactionId({
      prefix,
      currency,
      date,
      target,
      commonId,
      serialNumber,
    });
  };

  static createFromRawId = (id: string, commonId: string) => {
    if (id.length !== TRANSACTION_ID_LENGTH)
      throw new InvalidDataFoundError(
        `TransactionId with invalid length '${id}' is  found.`,
      );

    return new BankTransactionId({
      prefix: id.substr(0, 4),
      currency: id.substr(4, 3) as FiatCurrencyType,
      date: id.substr(7, 8),
      serialNumber: id.substr(15, 6),
      target: id.substr(21, 6),
      commonId,
    });
  };

  static createFromFutureTransactionId = (
    transactionId: BankTransactionId,
    bankDepositType: BankDepositType,
    serialNumber: string,
  ) => {
    const props = transactionId.getAllProps();

    const prefix = PrefixBankTransactionId[bankDepositType];

    if (!prefix)
      throw new InvalidDataFoundError(
        `Prefix of ${bankDepositType} is not found.`,
      );

    const date = new TimeZonedTime().format('YYYYMMDD');

    return new BankTransactionId({
      ...props,
      prefix,
      date,
      serialNumber,
    });
  };
}
export default BankTransactionIdFactory;

import styled from 'styled-components';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  z-index: 99;
  position: relative;
  min-height: ${70 / 16}rem;
  padding-right: ${30 / 16}rem;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media print {
    display: none;
  }
`;

export const DatetimeTextStyle = {
  fontSize: 18,
  color: color.nobel,
  fontWeight: fontWeight.regular,
};

export const UserNameTextStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};
export const UserNameBoxStyle = {
  borderLeft: `${1 / 16}rem solid #ebebf2`,
};

// IService
import ITransferDebitCardTransactionV2QueryService from '../../../useCases/transfer/debitCard/ITransferDebitCardTransactionV2QueryService';

// IRepository
import { ITransferDebitCardTransactionV2QueryRepository } from '../../../domain/transaction/transferDebitCardTransaction/ITransferDebitCardTransactionV2Repository';

class TransferDebitCardTransactionV2QueryService
  implements ITransferDebitCardTransactionV2QueryService {
  private transferDebitCardTransactionV2QueryRepository: ITransferDebitCardTransactionV2QueryRepository;

  constructor(
    transferDebitCardTransactionV2QueryRepository: ITransferDebitCardTransactionV2QueryRepository,
  ) {
    this.transferDebitCardTransactionV2QueryRepository = transferDebitCardTransactionV2QueryRepository;
  }

  public findAllByDebitCardId = async (debitCardId: string) => {
    const transferDebitCardTransactions = await this.transferDebitCardTransactionV2QueryRepository.findAllByDebitCardId(
      debitCardId,
    );

    return transferDebitCardTransactions;
  };

  public findAllByUserId = async (userId: string) => {
    const transferDebitCardTransactions = await this.transferDebitCardTransactionV2QueryRepository.findAllByUserId(
      userId,
    );

    return transferDebitCardTransactions;
  };
}

export default TransferDebitCardTransactionV2QueryService;

// Types
import { CryptoCurrencyType } from '../../types/CryptoCurrency.type';

// IRepository
import ICryptoCurrencyAvailableRepository from '../../domain/settings/cryptoCurrencyAvailable/ICryptoCurrencyAvailableRepository';

// Collection
import CryptoCurrenciesAvailableCollection from '../../infrastructure/firebase/firestore/collections/settings/CryptoCurrenciesAvailableCollection';

// Type
import { CryptoCurrencyAvailableProps } from '../../domain/settings/cryptoCurrencyAvailable/CryptoCurrencyAvailable/CryptoCurrencyAvailable.type';

// DomainObject
import CryptoCurrenciesAvailable from '../../domain/settings/cryptoCurrencyAvailable/CryptoCurrenciesAvailable/CryptoCurrenciesAvailable';
import CryptoCurrencyAvailableFactory from '../../domain/settings/cryptoCurrencyAvailable/CryptoCurrencyAvailable/CryptoCurrencyAvailableFactory';

class CryptoCurrencyAvailableFirestore
  implements ICryptoCurrencyAvailableRepository {
  public findAll = async () => {
    const cryptoCurrenciesAvailableCollection = new CryptoCurrenciesAvailableCollection();

    const docs = await cryptoCurrenciesAvailableCollection.fetchAll();

    return docs.reduce((objs, doc) => {
      objs.add(
        CryptoCurrencyAvailableFactory.create(
          doc.id as CryptoCurrencyType,
          doc.data() as CryptoCurrencyAvailableProps,
        ),
      );
      return objs;
    }, new CryptoCurrenciesAvailable());
  };
}
export default CryptoCurrencyAvailableFirestore;

import React, { FC, useContext } from 'react';
import RequestStateContext from '../../../../../value/view/RequestState/RequestStateContext';
import ContractTimeDepositCreateContext from './ContractTimeDepositCreateContext';
import ContractTimeDepositSeedContext from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDepositSeed/ContractTimeDepositSeedContext';
import ContractTimeDepositContext from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDepositContext';
import { useContractTimeDepositCreateUseCase } from './useContractTimeDepositCreateUseCase';
import UserVerifiedContext from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedContext';
import TimeDepositsContext from '../../../../../domain/product/timeDeposit/TimeDeposits/TimeDepositsContext';
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

export type ContractTimeDepositCreateProviderProps = {
  children: React.ReactNode;
};

const ContractTimeDepositCreateProvider: FC<ContractTimeDepositCreateProviderProps> = ({
  children,
}: ContractTimeDepositCreateProviderProps) => {
  const { user } = useContext(UserVerifiedContext);
  const { timeDeposits } = useContext(TimeDepositsContext);

  const {
    state,
    setState,
    contractTimeDepositSeed,
    contractTimeDeposit,
    setContractTimeDeposit,
    createContractTimeDeposit,
    executeCreateContractTimeDeposit,
  } = useContractTimeDepositCreateUseCase(user, timeDeposits);

  return (
    <RequestStateContext.Provider value={{ requestState: state }}>
      <ContractTimeDepositCreateContext.Provider
        value={{
          requestState: state,
          createContractTimeDeposit,
          executeCreateContractTimeDeposit,
        }}
      >
        <ContractTimeDepositContext.Provider
          value={{ contractTimeDeposit, setContractTimeDeposit }}
        >
          <ContractTimeDepositSeedContext.Provider
            value={{ contractTimeDepositSeed }}
          >
            <SystemErrorHandler state={state} setState={setState}>
              {children}
            </SystemErrorHandler>
          </ContractTimeDepositSeedContext.Provider>
        </ContractTimeDepositContext.Provider>
      </ContractTimeDepositCreateContext.Provider>
    </RequestStateContext.Provider>
  );
};

export default ContractTimeDepositCreateProvider;

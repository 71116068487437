// IService
import IResignUpRequestQueryService from '../../../useCases/userRequest/resignUpRequest/IResignUpRequestQueryService';

// IRepository
import { IResignUpRequestQueryRepository } from '../../../domain/userRequest/resignUpRequest/IResignUpRequestRepository';

class ResignUpRequestQueryService implements IResignUpRequestQueryService {
  private resignUpRequestRepository: IResignUpRequestQueryRepository;

  constructor(resignUpRequestRepository: IResignUpRequestQueryRepository) {
    this.resignUpRequestRepository = resignUpRequestRepository;
  }

  public findByOobCode = async (oobCode: string) => {
    const {
      resignUpRequest,
      user,
    } = await this.resignUpRequestRepository.findByOobCode(oobCode);

    return {
      resignUpRequest,
      user,
    };
  };
}

export default ResignUpRequestQueryService;

import { Reducer, AnyAction } from 'redux';
import { LoginUserAction } from '../actions/loginUserActions';
import LoginUserActionType from '../constants/loginUser.constant';
import { PublicUserDoc } from '../../utils/firebase/firestore/public/PublicUser.type';

export interface LoginUserState {
  id?: string;
  token?: string;
  user?: PublicUserDoc;
}

export const initialState: LoginUserState = {
  id: undefined,
  token: undefined,
  user: undefined,
};

const LoginUserReducer: Reducer<LoginUserState, LoginUserAction> = (
  state: LoginUserState = initialState,
  action: AnyAction,
): LoginUserState => {
  switch (action.type) {
    case LoginUserActionType.SET_LOGIN_USER:
      return {
        ...state,
        ...action.payload,
      };
    case LoginUserActionType.INITIALIZE_LOGIN_USER:
      return initialState;
    default:
      return state;
  }
};

export default LoginUserReducer;

import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

// Type
import { useTranslation } from 'react-i18next';
import { KycStatusType } from '../../../../types/KycRequest.type';

// Constant
import { ROUTE_KYC_STATUS } from '../../../../config/constants/routing';

// Component
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import KycStatusHeader from '../../../organisms/header/HeaderKycStatus';
import BreadCrumbLink from '../../../molecules/navigation/BreadCrumbLink';
import KycStepTemplateLayout from './KycStepTemplateLayout';

export interface KycStepTemplateProps {
  children: React.ReactNode;
  title: string;
  status?: KycStatusType;
  rejectedReason?: string;
}

const KycStepTemplate: FC<KycStepTemplateProps> = ({
  children,
  status,
  title,
  rejectedReason,
}: KycStepTemplateProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <KycStepTemplateLayout>
      <KycStatusHeader
        key="header"
        status={status}
        rejectedReason={rejectedReason}
      />
      <PageTitle key="title">{title}</PageTitle>
      <BreadCrumbLink
        key="link"
        onClick={() => {
          history.push(ROUTE_KYC_STATUS);
        }}
        text={t(`atoms.button.back`)}
      />
      <div key="childNode">{children}</div>
    </KycStepTemplateLayout>
  );
};
export default KycStepTemplate;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export interface CherryVisa01ProofOfReceiptFileContextProps {
  selfie: File | undefined;
  setSelfie: (file: File | undefined) => void;
}

const CherryVisa01ProofOfReceiptFileContext = React.createContext<
  CherryVisa01ProofOfReceiptFileContextProps
>({
  selfie: undefined,
  setSelfie: (file: File | undefined) => undefined,
});

export default CherryVisa01ProofOfReceiptFileContext;

import { UserStatusType } from '../../../types/User.type';
import {
  ROUTE_PREFIX_VERIFICATION,
  ROUTE_PREFIX_ASSET,
} from '../../../config/constants/routing';

export const UserStatusTopPage: { [key: string]: string } = {
  // Initial
  [UserStatusType.initial as string]: ROUTE_PREFIX_VERIFICATION,
  // Ready
  [UserStatusType.ready as string]: ROUTE_PREFIX_ASSET,
  // Investing
  [UserStatusType.investing as string]: ROUTE_PREFIX_ASSET,
  // CancelInvesting
  [UserStatusType.cancelInvesting as string]: ROUTE_PREFIX_ASSET,
};

// IUseCase
import IResetAuthenticatorViewUseCase from '../../../enhancers/useCase/authentication/ResetAuthenticatorViewProvider/IResetAuthenticatorViewUseCase';

// IService
import IResetAuthenticatorRequestQueryService from './IResetAuthenticatorRequestQueryService';

// Service
import ResetAuthenticatorRequestQueryService from '../../../service/userRequest/resetAuthenticatorRequest/ResetAuthenticatorRequestQueryService';

// IRepository
import IResetAuthenticatorRequestRepository from '../../../domain/userRequest/resetAuthenticatorRequest/IResetAuthenticatorRequestRepository';

class ResetAuthenticatorViewUseCase implements IResetAuthenticatorViewUseCase {
  private resetAuthenticatorRequestQueryService: IResetAuthenticatorRequestQueryService;

  constructor(
    resetAuthenticatorRepository: IResetAuthenticatorRequestRepository,
  ) {
    this.resetAuthenticatorRequestQueryService = new ResetAuthenticatorRequestQueryService(
      resetAuthenticatorRepository,
    );
  }

  public open = async (oobCode: string) => {
    const resetAuthenticatorRequest = await this.resetAuthenticatorRequestQueryService.findByOobCode(
      oobCode,
    );

    return { resetAuthenticatorRequest };
  };
}
export default ResetAuthenticatorViewUseCase;

import React, { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

// LayoutComponent
import SelectBox from '../../../../../atoms/select/SelectBox';
import Box from '../../../../../atoms/utils/layout/Box/Box';

// Mapper
import DebitCardsOptionsMapper from '../../../option/mapper/DebitCardsOptionsMapper';

// Component
import {
  DebitCardBalanceSingleValueContainer,
  DebitCardBalanceOptionContainer,
} from '../../../option/optionContainer/DebitCardBalanceOptionContainer';

// DomainObject
import DebitCardV2s from '../../../../../../domain/debitCard/debitCardV2/DebitCardV2s/DebitCardV2s';
import HiddenInput from '../../../../../atoms/form/HiddenInput';

export interface DebitCardFilteredSelectBoxProps {
  name: string;
  debitCardsFiltered: DebitCardV2s;
}

const DebitCardFilteredSelectBox: FC<DebitCardFilteredSelectBoxProps> = ({
  name,
  debitCardsFiltered,
}: DebitCardFilteredSelectBoxProps) => {
  const { setValue, watch } = useFormContext();

  const options = useMemo(
    () => DebitCardsOptionsMapper.domainToOptions(debitCardsFiltered),
    [debitCardsFiltered],
  );

  return (
    <Box>
      <SelectBox
        borderColor="#d6ddec"
        dropdownColor="#212F41"
        isSearchable={false}
        marginLeft={`${0 / 16}rem`}
        minHeight={`${70 / 16}rem`}
        onSelect={(debitCardId: string) => {
          setValue(name, debitCardsFiltered.filterById(debitCardId).getId());
        }}
        options={options || []}
        placeholderColor="#D5D0DA"
        value={watch(name)}
        OptionComponent={DebitCardBalanceOptionContainer}
        SingleValueComponent={DebitCardBalanceSingleValueContainer}
      />
      <HiddenInput name={name} value={watch(name) || ''} />
    </Box>
  );
};

export default DebitCardFilteredSelectBox;

// Type
import { DeliveryTrackingInformationProps } from './DeliveryTrackingInformation.type';
import { DeliveryTrackingStatusType } from '../../../../types/DebitCardRequestCherryVisa01.type';

class DeliveryTrackingInformation {
  private status: DeliveryTrackingStatusType;

  private trackingNumber: string;

  private trackingUrl: string;

  private carrier: string;

  constructor(props: DeliveryTrackingInformationProps) {
    this.status = props.status;
    this.trackingNumber = props.trackingNumber;
    this.trackingUrl = props.trackingUrl;
    this.carrier = props.carrier;
  }

  public getProps() {
    return {
      status: this.status,
      trackingNumber: this.trackingNumber,
      trackingUrl: this.trackingUrl,
      carrier: this.carrier,
    };
  }
}

export default DeliveryTrackingInformation;

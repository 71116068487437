import { useState, useEffect, useCallback } from 'react';
import { checkInternetConnection } from '../../../../utils/helpers/connection';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';

// ErrorHandler
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import CryptoCurrencyTransactionViewUseCase from '../../../../useCases/cryptoCurrency/cryptoCurrencyTransaction/CryptoCurrencyTransactionViewUseCase';

// Repository
import CryptoCurrencyBuyTransactionFirestore from '../../../../repository/transaction/cryptoCurrencyTransaction/trade/cryptoCurrencyBuy/CryptoCurrencyBuyTransactionFirestore';
import CryptoCurrencySellTransactionFirestore from '../../../../repository/transaction/cryptoCurrencyTransaction/trade/cryptoCurrencySell/CryptoCurrencySellTransactionFirestore';
import CryptoCurrencyDepositFirestore from '../../../../repository/transaction/cryptoCurrencyTransaction/spot/cryptoCurrencyDeposit/CryptoCurrencyDepositFirestore';
import CryptoCurrencyWithdrawalFirestore from '../../../../repository/transaction/cryptoCurrencyTransaction/spot/cryptoCurrencyWithdrawal/CryptoCurrencyWithdrawalFirestore';

// DomainObject
import { useCryptoCurrencyBuyTransactions } from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyBuyTransaction/CryptoCurrencyBuyTransactions/useCryptoCurrencyBuyTransactions';
import { useCryptoCurrencyDeposits } from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/CryptoCurrencyDeposits/useCryptoCurrencyDeposits';
import { useCryptoCurrencySellTransactions } from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencySellTransaction/CryptoCurrencySellTransactions/useCryptoCurrencySellTransactions';
import { useCryptoCurrencySpotTransactions } from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransactions/useCryptoCurrencySpotTransactions';
import { useCryptoCurrencyTradeTransactions } from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactions/useCryptoCurrencyTradeTransactions';
import { useCryptoCurrencyTransactions } from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTransaction/CryptoCurrencyTransactions/useCryptoCurrencyTransactions';
import { useCryptoCurrencyWithdrawals } from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyWithdrawal/CryptoCurrencyWithdrawals/useCryptoCurrencyWithdrawals';
import CryptoCurrencySpotTransactionsFactory from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransactions/CryptoCurrencySpotTransactionsFactory';
import CryptoCurrencyTransactionsFactory from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTransaction/CryptoCurrencyTransactions/CryptoCurrencyTransactionsFactory';
import CryptoCurrencyTradeTransactionsFactory from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactions/CryptoCurrencyTradeTransactionsFactory';
import CryptoCurrencyWallet from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet';

export const useCryptoCurrencyTransactionViewUseCase = (
  cryptoCurrencyWallet: CryptoCurrencyWallet | undefined,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /**
   *
   *  DomainObject
   *
   */

  // CryptoCurrencyTransactions
  const {
    cryptoCurrencyTransactions,
    setCryptoCurrencyTransactions,
  } = useCryptoCurrencyTransactions();

  // CryptoCurrencySpotTransactions
  const {
    cryptoCurrencySpotTransactions,
    setCryptoCurrencySpotTransactions,
  } = useCryptoCurrencySpotTransactions();

  // CryptoCurrencyDeposits
  const {
    cryptoCurrencyDeposits,
    setCryptoCurrencyDeposits,
  } = useCryptoCurrencyDeposits();

  // CryptoCurrencyWithdrawals
  const {
    cryptoCurrencyWithdrawals,
    setCryptoCurrencyWithdrawals,
  } = useCryptoCurrencyWithdrawals();

  // CryptoCurrencyTradeTransactions
  const {
    cryptoCurrencyTradeTransactions,
    setCryptoCurrencyTradeTransactions,
  } = useCryptoCurrencyTradeTransactions();

  // CryptoCurrencySellTransactions
  const {
    cryptoCurrencySellTransactions,
    setCryptoCurrencySellTransactions,
  } = useCryptoCurrencySellTransactions();

  // CryptoCurrencyBuyTransactions
  const {
    cryptoCurrencyBuyTransactions,
    setCryptoCurrencyBuyTransactions,
  } = useCryptoCurrencyBuyTransactions();

  /**
   *
   *  Repository
   *
   */
  const cryptoCurrencySellTransactionRepository = new CryptoCurrencySellTransactionFirestore();
  const cryptoCurrencyBuyTransactionRepository = new CryptoCurrencyBuyTransactionFirestore();
  const cryptoCurrencyDepositRepository = new CryptoCurrencyDepositFirestore();
  const cryptoCurrencyWithdrawalRepository = new CryptoCurrencyWithdrawalFirestore();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new CryptoCurrencyTransactionViewUseCase(
    cryptoCurrencySellTransactionRepository,
    cryptoCurrencyBuyTransactionRepository,
    cryptoCurrencyDepositRepository,
    cryptoCurrencyWithdrawalRepository,
  );

  /**
   *
   *  Methods
   *
   */
  const openCryptoCurrencyTransaction = useCallback(async () => {
    if (!cryptoCurrencyWallet) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // Execution
      const outputs = await useCase.open(cryptoCurrencyWallet);

      setCryptoCurrencySellTransactions(outputs.cryptoCurrencySellTransactions);
      setCryptoCurrencyBuyTransactions(outputs.cryptoCurrencyBuyTransactions);
      setCryptoCurrencyDeposits(outputs.cryptoCurrencyDeposits);
      setCryptoCurrencyWithdrawals(outputs.cryptoCurrencyWithdrawals);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [cryptoCurrencyWallet]);

  /**
   *
   *  UseEffect
   *
   */

  // UseCase.open
  useEffect(() => {
    let isMounted = true;

    if (cryptoCurrencyWallet && isMounted) openCryptoCurrencyTransaction();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [cryptoCurrencyWallet]);

  // Merge to CryptoCurrencySpotTransactions
  useEffect(() => {
    const transactions = CryptoCurrencySpotTransactionsFactory.createByMerge(
      cryptoCurrencyDeposits,
      cryptoCurrencyWithdrawals,
    );
    setCryptoCurrencySpotTransactions(transactions.sortDescByCreatedAt());
  }, [
    cryptoCurrencyDeposits,
    cryptoCurrencyWithdrawals,
    setCryptoCurrencySpotTransactions,
  ]);

  // Merge to CryptoCurrencyTradeTransactions
  useEffect(() => {
    const transactions = CryptoCurrencyTradeTransactionsFactory.createByMerge(
      cryptoCurrencySellTransactions,
      cryptoCurrencyBuyTransactions,
    );
    setCryptoCurrencyTradeTransactions(transactions.sortDescByCreatedAt());
  }, [
    cryptoCurrencySellTransactions,
    cryptoCurrencyBuyTransactions,
    setCryptoCurrencyTradeTransactions,
  ]);

  // Merge to CryptoCurrencyTransactions
  useEffect(() => {
    const transactions = CryptoCurrencyTransactionsFactory.createByMerge(
      cryptoCurrencySpotTransactions,
      cryptoCurrencyTradeTransactions,
    );
    setCryptoCurrencyTransactions(transactions.sortDescByCreatedAt());
  }, [
    cryptoCurrencySpotTransactions,
    cryptoCurrencyTradeTransactions,
    setCryptoCurrencyTransactions,
  ]);

  return {
    state,
    setState,
    cryptoCurrencyWallet,
    cryptoCurrencyTransactions,
    cryptoCurrencySpotTransactions,
    cryptoCurrencyTradeTransactions,
  };
};

import React, { FC } from 'react';
import InvitationCodesPage from './InvitationCodesPage';
import InvitationCodeViewProvider from '../../../enhancers/useCase/invite/InvitationCodesViewProvider';

const EnhancedInvitationCodesPage: FC = () => {
  return (
    <InvitationCodeViewProvider>
      <InvitationCodesPage />
    </InvitationCodeViewProvider>
  );
};

export default EnhancedInvitationCodesPage;

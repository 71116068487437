import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle } from './style';

// Component
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import { TextSecondary } from '../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';
import EmailEditForm from '../../../organisms/form/settings/FormEmailEdit';
import EmailEditTemplateLayout from './EmailEditTemplateLayout';

const TEMPLATE_ID = 'templates.userSettings.EmailEditTemplate';

const EmailEditTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <EmailEditTemplateLayout>
      <PageTitle key="title">{t(`${TEMPLATE_ID}.title`)}</PageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced text={t(`${TEMPLATE_ID}.description`)} />
      </TextSecondary>
      <EmailEditForm key="form" />
    </EmailEditTemplateLayout>
  );
};

export default EmailEditTemplate;

import { useState } from 'react';
import UserReregistration from './UserReregistration';

export const useUserReregistration = (initial?: UserReregistration) => {
  const [userReregistration, setUserReregistration] = useState<
    UserReregistration | undefined
  >(initial);

  return {
    userReregistration,
    setUserReregistration,
  };
};

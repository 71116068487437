import React, { FC } from 'react';

// Type
import { CurrencyType } from '../../../../types/Currency.type';
import { CryptoCurrencyTradeType } from '../../../../types/CryptoCurrency.type';

// Component
import {
  TextPrimary,
  TextSecondary,
  TextThemeProps,
} from '../../../atoms/text2/Text2';
import LabelCryptoCurrencyTradeLayout from './LabelCryptoCurrencyTradeLayout';

export interface LabelCryptoCurrencyTradeProps {
  tradeType: CryptoCurrencyTradeType;
  targetCurrency: CurrencyType;
  baseCurrency: CurrencyType;
  theme: {
    label: TextThemeProps;
    arrow: TextThemeProps;
  };
}

const LabelCryptoCurrencyTrade: FC<LabelCryptoCurrencyTradeProps> = ({
  tradeType,
  targetCurrency,
  baseCurrency,
  theme,
}: LabelCryptoCurrencyTradeProps) => {
  const [payCurrency, receiveCurrency] =
    tradeType === CryptoCurrencyTradeType.sell
      ? [targetCurrency, baseCurrency]
      : [baseCurrency, targetCurrency];

  return (
    <LabelCryptoCurrencyTradeLayout>
      <TextPrimary key="payCurrency" theme={theme.label}>
        {payCurrency}
      </TextPrimary>
      <TextSecondary key="arrow" theme={theme.arrow}>
        &nbsp; {'→'} &nbsp;
      </TextSecondary>
      <TextPrimary key="receiveCurrency" theme={theme.label}>
        {receiveCurrency}
      </TextPrimary>
    </LabelCryptoCurrencyTradeLayout>
  );
};

export default LabelCryptoCurrencyTrade;

import Joi from '@hapi/joi';

export interface MaintenanceProps {
  system: {
    isMaintenance: boolean;

    schedule: {
      start: string;

      end: string | undefined;

      isEstimate: boolean | undefined;
    };
  };

  debitCardTopUp: {
    isMaintenance: boolean;

    schedule: {
      start: string;

      end: string | undefined;

      isEstimate: boolean | undefined;
    };
  };
}

export const MaintenancePropsFormat = {
  system: {
    isMaintenance: Joi.boolean().required(),

    schedule: Joi.object({
      start: Joi.string().required(),

      end: Joi.string().empty('').allow(null),

      isEstimate: Joi.boolean().empty(''),
    }),
  },

  debitCardTopUp: {
    isMaintenance: Joi.boolean().required(),

    schedule: Joi.object({
      start: Joi.string().required(),

      end: Joi.string().empty('').allow(null),

      isEstimate: Joi.boolean().empty(''),
    }),
  },
};

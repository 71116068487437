import styled from 'styled-components';

export const Container = styled.div`
  flex: ${(149 + 96) / 1079};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

export const Content = styled.div`
  width: ${400 / 16}rem;
  // height: ${96 / 16}rem;
  height: ${70 / 16}rem;
`;

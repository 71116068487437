import React, { FC } from 'react';

// Constant
import color from '../../utils/const/color';

// Component
import { SolidButton } from './Button';

export interface BankActionButtonProps {
  children: React.ReactNode;
  width?: string | number;
  height?: string | number;
  onClick?: () => void;
  theme?: {
    backgroundColor: string;
    borderRadius: string | number;
    boxShadow: string;
  };
  disabled?: boolean;
}

const DefaultButtonStyle = {
  backgroundColor: color.darkBlue,
  borderRadius: 5,
  boxShadow: '0px 5px 0px #81818141',
};

const DisabledButtonStyle = {
  ...DefaultButtonStyle,
  backgroundColor: color.disabled,
};

const BankActionButton: FC<BankActionButtonProps> = ({
  children,
  width = '100%',
  height = '100%',
  onClick = () => undefined,
  theme = DefaultButtonStyle,
  disabled = false,
}: BankActionButtonProps) => {
  return (
    <SolidButton
      width={width}
      height={height}
      disabled={disabled}
      onClick={onClick}
      theme={disabled ? DisabledButtonStyle : theme}
    >
      {children}
    </SolidButton>
  );
};

export default BankActionButton;

import React, { FC, useContext } from 'react';
import BankTransactionOptionContext from './BankTransactionOptionContext';
import { BankTransactionTabs } from '../../../../../../config/menu/tab/BankTransactionTab';
import { SelectedStyle, TextStyle, UnSelectedStyle } from './style';
import UnderBarTabBar from '../UnderBarTabBar';

const DepositWithdrawalTabBar: FC = () => {
  const { transactionOption, setTransactionOption } = useContext(
    BankTransactionOptionContext,
  );

  return (
    <UnderBarTabBar
      height={55}
      items={BankTransactionTabs}
      onClick={setTransactionOption}
      selected={transactionOption}
      theme={{
        text: TextStyle,
        selected: SelectedStyle,
        unSelected: UnSelectedStyle,
      }}
      widthItem={250}
    />
  );
};

export default DepositWithdrawalTabBar;

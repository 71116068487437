import React from 'react';

// Type
import { TdTransferDebitCardTransactionHistory } from '../../history/TableTransferDebitCardTransactionHistory/TableTransferDebitCardTransactionHistoryMapper';

export interface SelectRowTableTopUpContextProps {
  selectRowTableTopUp: TdTransferDebitCardTransactionHistory | undefined;
  setSelectRowTableTopUp: (
    row: TdTransferDebitCardTransactionHistory | undefined,
  ) => void;
}

const SelectRowTableTopUpContext = React.createContext<
  SelectRowTableTopUpContextProps
>({
  selectRowTableTopUp: undefined,
  setSelectRowTableTopUp: () => undefined,
});

export default SelectRowTableTopUpContext;

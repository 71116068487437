import React, { FC } from 'react';
import Select from 'react-select';
import { selectBoxStyle } from './style';
import { OptionObject } from './Option.type';

export interface SelectBoxProps {
  options?: Array<OptionObject>;
  value?: string;
  borderColor: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  placeholder?: any;
  placeholderColor: string;
  dropdownColor: string;
  minHeight?: string;
  isSearchable: boolean;
  onSelect: (value: string) => void;
  SingleValueComponent?: FC;
  OptionComponent?: FC;
  marginLeft?: string;
  disabled?: boolean;
}

const SelectBox: FC<SelectBoxProps> = ({
  options,
  value,
  placeholder,
  onSelect,
  borderColor,
  dropdownColor,
  placeholderColor,
  isSearchable,
  minHeight = `${20 / 16}rem`,
  SingleValueComponent,
  OptionComponent,
  marginLeft = `${20 / 16}rem`,
  disabled = false,
}: SelectBoxProps) => {
  const convertedValue = options
    ? options.filter((option) => option.value === value)
    : [];

  return (
    <Select
      options={options || []}
      placeholder={placeholder}
      value={value ? convertedValue : undefined}
      components={
        {
          SingleValue: SingleValueComponent,
          Option: OptionComponent,
        } || {}
      }
      isSearchable={isSearchable}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(item: any) => onSelect(item?.value)}
      styles={{
        ...selectBoxStyle({
          borderColor,
          dropdownColor,
          placeholderColor,
          minHeight,
          marginLeft,
          disabled,
        }),
      }}
      isDisabled={disabled}
    />
  );
};

export default SelectBox;

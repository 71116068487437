import React, { FC } from 'react';
import { CryptoCurrencyType } from '../../../../../types/CryptoCurrency.type';
import fontSize from '../../../../../fonts/fontSizePixel.json';
import fontColor from '../../../../../fonts/color.json';
import fontWeight from '../../../../../fonts/fontWeight.json';
import CryptoCurrencyIconLabelText from './CryptoCurrencyIconLabelText';

export interface AssetCryptoCurrencyIconTextProps {
  iconType: CryptoCurrencyType;
  currencyName: string;
}

const AssetCryptoCurrencyIconText: FC<AssetCryptoCurrencyIconTextProps> = ({
  iconType,
  currencyName,
}: AssetCryptoCurrencyIconTextProps) => {
  return (
    <CryptoCurrencyIconLabelText
      iconType={iconType}
      currencyName={currencyName}
      iconSize={`${50 / 16}rem`}
      textPixel={fontSize.pixel27}
      textColor={fontColor.textBright}
      textFontWeight={fontWeight.semibold}
      textFontFamilyType="primary"
      marginBetween={`${13 / 16}rem`}
    />
  );
};

export default AssetCryptoCurrencyIconText;

import React, { FC } from 'react';
import { TextComponentProps } from '../TextComponent.type';
import { FontFamilyPlayfairDisplay } from '../../textFont/fontFamily';
import FontSize from '../../textFont/fontSize';
import Text from '../Text';

const TextPrimary: FC<TextComponentProps> = ({
  children,
  pixel,
  color,
  fontWeight,
  textDecoration,
  opacity = 1,
}: TextComponentProps) => (
  <FontFamilyPlayfairDisplay fontWeight={fontWeight}>
    <FontSize pixel={pixel}>
      <Text color={color} textDecoration={textDecoration} opacity={opacity}>
        {children}
      </Text>
    </FontSize>
  </FontFamilyPlayfairDisplay>
);
export default TextPrimary;

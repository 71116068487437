import React, { FC } from 'react';

// Component
import { TextPrimary } from '../../../../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../../../../atoms/text2/LineBreakReplaced';
import SavingsAccountSelectBox from '../../../../../selectBox/selectBox/bankAccount/SavingsAccountSelectBox';
import FormInputSavingsAccountSelectBoxLayout from './FormInputSavingsAccountSelectBoxLayout';

interface FormInputSavingsAccountSelectBoxProps {
  inputWidth: string | number;
  labelProps: {
    label: string;
    theme: {
      fontSize: string | number;
      color: string;
      fontWeight: number;
    };
  };
  name: string;
  disabled?: boolean;
}

const FormInputSavingsAccountSelectBox: FC<FormInputSavingsAccountSelectBoxProps> = ({
  inputWidth,
  labelProps,
  name,
  disabled = false,
}: FormInputSavingsAccountSelectBoxProps) => {
  return (
    <FormInputSavingsAccountSelectBoxLayout inputWidth={inputWidth}>
      <TextPrimary key="label" theme={labelProps.theme}>
        <LineBreakReplaced text={labelProps.label} />
      </TextPrimary>
      <SavingsAccountSelectBox key="input" name={name} disabled={disabled} />
    </FormInputSavingsAccountSelectBoxLayout>
  );
};

export default FormInputSavingsAccountSelectBox;

import { useState, useCallback } from 'react';

// Functions
import FirebasePrivateFunctions from '../../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from '../../../../utils/errors/ErrorHandler/IErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';
import { AUTHENTICATOR_UPDATE } from '../../../../infrastructure/firebase/firebaseFunctions/endpoint';

// DomainObject
import UserAuthorized from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useUpdateAuthenticator = (userAuthorized?: UserAuthorized) => {
  const [state, setState] = useState<string>(INITIAL);

  const update = useCallback(
    async (accountKey: string, code: string) => {
      if (!userAuthorized) return;

      setState(IS_LOADING);

      try {
        checkInternetConnection();

        // Update authenticator
        await new FirebasePrivateFunctions().privatePUT(
          `${AUTHENTICATOR_UPDATE}/${userAuthorized.getId()}`,
          {
            accountKey,
            code,
          },
        );

        setState(SUCCESS);
      } catch (error) {
        const handler: IErrorHandler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    [userAuthorized],
  );

  return { state, update, setState };
};

// Error
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';

// IUseCase
import IKycRequestSubmitUseCase from '../../../enhancers/useCase/verification/kycRequest/KycRequestSubmitProvider/IKycRequestSubmitUseCase';

// IService
import IKycRequestUpdateService from './IKycRequestUpdateService';
import IStorageService from '../../storage/IStorageService';

// Service
import KycRequestUpdateService from '../../../service/verification/kycRequest/KycRequestUpdateService';
import StorageService from '../../../service/storage/StorageService';

// IRepository
import { IKycRequestUpdateRepository } from '../../../domain/verification/kycRequest/KycRequest/IKycRequestRepository';
import IStorageHandler from '../../../storage/IStorageHandler';

// DomainObject
import KycRequest from '../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequest';
import KycRequestCorporate from '../../../domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporate';

// ValueObject
import ShippingAddress from '../../../value/personalInformation/address/Address/ShippingAddress/ShippingAddress';

class KycRequestSubmitUseCase implements IKycRequestSubmitUseCase {
  private kycRequestUpdateService: IKycRequestUpdateService;

  private storageService: IStorageService;

  constructor(
    kycRequestRepository: IKycRequestUpdateRepository,
    storageHandler: IStorageHandler,
  ) {
    this.kycRequestUpdateService = new KycRequestUpdateService(
      kycRequestRepository,
    );

    this.storageService = new StorageService(storageHandler);
  }

  public submitCorporateInformation = async (
    kycRequest: KycRequestCorporate,
    file: File | undefined,
  ) => {
    if (file) {
      const url = kycRequest.getCorporateInformation().getProps()
        .corporateRegistryUrl;

      await this.storageService.upload(url, file);
    }

    await this.kycRequestUpdateService.updateCorporateInformation(kycRequest);

    return kycRequest;
  };

  public submitCorporateAddress = async (
    kycRequest: KycRequestCorporate,
    shippingAddress: ShippingAddress,
    file: File | undefined,
  ) => {
    if (file) {
      const url = kycRequest.getCorporateAddress().getProps().document
        .documentUrl1;

      await this.storageService.upload(url, file);
    }

    await this.kycRequestUpdateService.updateCorporateAddress(
      kycRequest,
      shippingAddress,
    );

    return kycRequest;
  };

  public submitPersonalInformation = async (
    kycRequest: KycRequest,
    passport: File | undefined,
    selfie: File | undefined,
  ) => {
    // Upload Passport
    const uploadPassport = async () => {
      if (passport) {
        const {
          passportUrl,
        } = kycRequest.getPersonalInformation().getProps().passport;

        await this.storageService.upload(passportUrl, passport);
      }
    };

    // Upload Selfie
    const uploadSelfie = async () => {
      if (selfie) {
        const { selfieUrl } = kycRequest.getPersonalInformation().getProps();

        await this.storageService.upload(selfieUrl, selfie);
      }
    };

    // UploadFile
    await Promise.all([uploadPassport(), uploadSelfie()]);

    await this.kycRequestUpdateService.updatePersonalInformation(kycRequest);

    return kycRequest;
  };

  public submitProofOfResidency = async (
    kycRequest: KycRequest,
    shippingAddress: ShippingAddress,
    proofOfDocument1: File | undefined,
    proofOfDocument2: File | undefined,
  ) => {
    // Upload ProofOfDocument1
    const uploadProofOfDocument1 = async () => {
      if (proofOfDocument1) {
        const {
          documentUrl1,
        } = kycRequest.getProofOfResidency().getProps().document;

        await this.storageService.upload(documentUrl1, proofOfDocument1);
      }
    };

    // Upload ProofOfDocument2
    const uploadProofOfDocument2 = async () => {
      if (proofOfDocument2) {
        const {
          documentUrl2,
        } = kycRequest.getProofOfResidency().getProps().document;

        if (!documentUrl2)
          throw SystemErrorFactory.createByErrorId(
            ErrorIdType.KYC_REQUEST_PROOF_OF_RESIDENCY_DOCUMENT2_NO_URL,
          );

        await this.storageService.upload(documentUrl2, proofOfDocument2);
      }
    };

    // UploadFile
    await Promise.all([uploadProofOfDocument1(), uploadProofOfDocument2()]);

    // Update
    await this.kycRequestUpdateService.updateProofOfResidency(
      kycRequest,
      shippingAddress,
    );

    return kycRequest;
  };
}

export default KycRequestSubmitUseCase;

import { useState } from 'react';
import KycRequest from './KycRequest';

export const useKycRequest = (initial?: KycRequest) => {
  const [kycRequest, setKycRequest] = useState<KycRequest | undefined>(initial);

  return {
    kycRequest,
    setKycRequest,
  };
};

import { required, emailFormat, noUpperCase, checked } from '../validator';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const resignUpRegister = (field: string, t: any) => {
  switch (field) {
    case 'email':
      return {
        required: required('Email Address', t),
        validate: {
          emailFormat: emailFormat(t),
          noUpperCase: noUpperCase('Email Address', t),
        },
      };
    case 'invitationCode':
      return {
        required: required('Invitation Code', t),
      };
    case 'agreed':
      return {
        required: true,
        validate: checked(),
      };
    default:
      return undefined;
  }
};

export default resignUpRegister;

import CounterActionType from '../constants/counter.constant';

export interface CounterUserAction {
  type: CounterActionType;
}

export interface CounterActionParams {
  count: number;
}

export interface CounterSagaAction {
  type: CounterActionType;
  params: CounterActionParams;
}

export const userPressPlusButton = (): CounterUserAction => ({
  type: CounterActionType.USER_PRESS_PLUS_BUTTON,
});
export const userPressMinusButton = (): CounterUserAction => ({
  type: CounterActionType.USER_PRESS_MINUS_BUTTON,
});

export const sagaStoreCounter = (
  params: CounterActionParams,
): CounterSagaAction => ({
  type: CounterActionType.SAGA_STORE_COUNTER,
  params,
});

import React, { FC, useContext } from 'react';
import ContractTimeDepositRenewalReservationEditPageStateContext from '../../../enhancers/pageState/contract/ContractTimeDepositRenewalReservationPageState/ContractTimeDepositRenewalReservationPageStateContext';

// Error
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';

// Component
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import ContractTimeDepositRenewalReservationEditTemplate from '../../../components/templates/contractTimeDeposit/ContractTimeDepositRenewalReservationEditTemplate';
import ContractTimeDepositRenewalReservationEditConfirmTemplate from '../../../components/templates/contractTimeDeposit/ContractTimeDepositRenewalReservationEditConfirmTemplate';
import ContractTimeDepositRenewalReservationEditCompleteTemplate from '../../../components/templates/contractTimeDeposit/ContractTimeDepositRenewalReservationEditCompleteTemplate';

const ContractTimeDepositRenewalReservationEditPage: FC = () => {
  const { pageState } = useContext(
    ContractTimeDepositRenewalReservationEditPageStateContext,
  );

  const TemplateComponent: FC = () => {
    switch (pageState) {
      case 'initial':
        return <ContractTimeDepositRenewalReservationEditTemplate />;
      case 'confirm':
        return <ContractTimeDepositRenewalReservationEditConfirmTemplate />;
      case 'complete':
        return <ContractTimeDepositRenewalReservationEditCompleteTemplate />;
      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_SWITCH,
          {
            value: pageState,
            place: `ContractTimeDepositRenewalReservationEditPage`,
          },
        );
    }
  };

  return (
    <DashboardTemplate>
      <TemplateComponent />
    </DashboardTemplate>
  );
};

export default ContractTimeDepositRenewalReservationEditPage;

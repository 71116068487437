import React, { FC, useContext } from 'react';
import ContractsTimeDepositContext from '../../../../domain/contract/contractTimeDeposit/ContractsTimeDeposit/ContractsTimeDepositContext';

// LayoutComponent
import Margin from '../../../atoms/utils/layout/Margin';

// Component
import ContractTimeDepositCard from '../../card/contract/ContractTimeDepositCard';
import ContractTimeDepositRenewalReservationCard from '../../card/contract/ContractTimeDepositRenewalReservationCard';

// DomainObject
import ContractTimeDeposit from '../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';

const ContractTimeDepositsCardList: FC = () => {
  const { contractsTimeDeposit } = useContext(ContractsTimeDepositContext);

  if (!contractsTimeDeposit) return <></>;

  const getListItem: FC<{
    contractTimeDeposit: ContractTimeDeposit;
    i: number;
  }> = ({ contractTimeDeposit, i }) => (
    <div key={`item${i}`} style={{ marginBottom: `${60 / 16}rem` }}>
      <ContractTimeDepositCard contractTimeDeposit={contractTimeDeposit} />
      <Margin top={10} />
      <ContractTimeDepositRenewalReservationCard
        contractTimeDeposit={contractTimeDeposit}
      />
    </div>
  );

  return <>{contractsTimeDeposit.mapComponent(getListItem)}</>;
};

export default ContractTimeDepositsCardList;

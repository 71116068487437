/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { CryptoCurrencyHistoryType } from './CryptoCurrencyHistory.type';

export interface CryptoCurrencyHistoryOptionContextProps {
  historyOption: CryptoCurrencyHistoryType;
  setHistoryOption: (type: CryptoCurrencyHistoryType) => void;
}

const CryptoCurrencyHistoryOptionContext = React.createContext<
  CryptoCurrencyHistoryOptionContextProps
>({
  historyOption: CryptoCurrencyHistoryType.all,
  setHistoryOption: (type: CryptoCurrencyHistoryType) => undefined,
});

export default CryptoCurrencyHistoryOptionContext;

import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import InvitationsTemplate from '../../../components/templates/invitations/InvitationsTemplate';

const InvitationsPage: FC = () => {
  return (
    <DashboardTemplate>
      <InvitationsTemplate />
    </DashboardTemplate>
  );
};

export default InvitationsPage;

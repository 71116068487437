import React from 'react';

export interface PageRequestStateContextProps {
  state: string;
  setState: (state: string) => void;
}

const PageRequestStateContext = React.createContext<
  PageRequestStateContextProps
>({
  state: 'initial',
  setState: () => undefined,
});

export default PageRequestStateContext;

// Type
import { CryptoCurrencyTradeTransactionSeedProps } from './CryptoCurrencyTradeTransactionSeed.type';

// DomainObject
import CryptoCurrencyTradeTransaction from '../../CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction';
import CryptoCurrencyTransactionIdFactory from '../../../../../idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionIdFactory';

abstract class CryptoCurrencyTradeTransactionSeed extends CryptoCurrencyTradeTransaction {
  constructor(props: CryptoCurrencyTradeTransactionSeedProps) {
    super(CryptoCurrencyTransactionIdFactory.createEmptyId(), props);
  }

  public getRequestParameterCreate = () => ({
    payAmount: this.payAmount,
    payCurrency: this.payCurrency,
    rate: this.rate,
    receiveAmount: this.receiveAmount,
    receiveCurrency: this.receiveCurrency,
    savingsAccountNumber: this.savingsAccountNumber.getAccountNumber(),
    tradeType: this.tradeType,
  });

  public getTradeType = () => this.tradeType;
}

export default CryptoCurrencyTradeTransactionSeed;

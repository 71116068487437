import React from 'react';
import CryptoCurrencyTradeTransaction from './CryptoCurrencyTradeTransaction';

export interface CryptoCurrencyTradeTransactionContextProps {
  cryptoCurrencyTradeTransaction?: CryptoCurrencyTradeTransaction;
}

const CryptoCurrencyTradeTransactionContext = React.createContext<
  CryptoCurrencyTradeTransactionContextProps
>({
  cryptoCurrencyTradeTransaction: undefined,
});

export default CryptoCurrencyTradeTransactionContext;

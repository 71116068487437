// Error
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';

// Firestore
import KycRequestsCollection from '../../../infrastructure/firebase/firestore/collections/verification/kycRequest/KycRequestsCollection';

// Type
import { UserType } from '../../../types/User.type';
import { KycRequestDocumentData } from '../../../infrastructure/firebase/firestore/collections/verification/kycRequest/KycRequestDocumentData.type';

// Mapper
import KycRequestMapperFirestore from './mapper/KycRequestMapperFirestore';

// IRepository
import IKycRequestRepository from '../../../domain/verification/kycRequest/KycRequest/IKycRequestRepository';

// DomainObject
import KycRequestFactory from '../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequestFactory';
import KycRequest from '../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequest';

class KycRequestFirestore implements IKycRequestRepository {
  public findById = async (id: string) => {
    const kycRequest = await this.findByIdOrUndef(id);

    if (!kycRequest)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.DATABASE_DATA_NOT_FOUND_BY_ID,
        { dataSource: `KycRequest`, id },
      );

    return kycRequest;
  };

  public findByIdOrUndef = async (id: string) => {
    const kycRequestsCollection = new KycRequestsCollection();

    const doc = await kycRequestsCollection.fetchSpecific(id);

    if (!doc) return undefined;

    const props = KycRequestMapperFirestore.documentToProps(
      doc.data() as KycRequestDocumentData,
    );

    return KycRequestFactory.create(doc.id, props);
  };

  public findByIdAndUserType = async (id: string, userType: UserType) => {
    const kycRequestsCollection = new KycRequestsCollection();

    const doc = await kycRequestsCollection.fetchSpecific(id);

    if (!doc) return undefined;

    // If individual data is undefined
    if (!doc?.data()?.individual && userType === UserType.individual)
      return undefined;

    // If corporate data is undefined
    if (!doc?.data()?.corporate && userType === UserType.corporate)
      return undefined;

    const props = KycRequestMapperFirestore.documentToPropsByUserType(
      doc.data() as KycRequestDocumentData,
      userType,
    );

    return KycRequestFactory.create(doc.id, props);
  };

  public upsert = async (kycRequest: KycRequest) => {
    const kycRequestsCollection = new KycRequestsCollection();
    const id = kycRequest.getId();
    const props = {
      ...kycRequest.getCommandParameter(),
      updatedAt: Date.now(),
    };

    // Upsert
    await kycRequestsCollection.set(id, props);

    return kycRequest;
  };
}
export default KycRequestFirestore;

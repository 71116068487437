import Joi from '@hapi/joi';

// Error
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import {
  ResetAuthenticatorRequestProps,
  ResetAuthenticatorRequestPropsFormat,
} from './ResetAuthenticatorRequest.type';

// DomainObject
import ResetAuthenticatorRequest from './ResetAuthenticatorRequest';

class ResetAuthenticatorRequestFactory {
  static create = (id: string, props: ResetAuthenticatorRequestProps) => {
    const result = Joi.object(ResetAuthenticatorRequestPropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: ResetAuthenticatorRequest.name,
          reason: result.error.details[0].message,
        },
      );

    return new ResetAuthenticatorRequest(id, props);
  };
}
export default ResetAuthenticatorRequestFactory;

import { useCallback, useEffect, useState } from 'react';
import { checkInternetConnection } from '../../../../../utils/helpers/connection';

// Error
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';

// UseCase
import SavingsAccountsViewUseCase from '../../../../../useCases/bankAccount/savingsAccount/SavingsAccount/SavingsAccountsViewUseCase';

// Repository
import DebitCardRequestFunctions from '../../../../../repository/debitCard/debitCardRequest/DebitCardRequestFunctions';
import SavingsAccountFunctions from '../../../../../repository/bankAccount/savingsAccount/SavingsAccountFunctions';

// DomainObject
import { useDebitCardRequests } from '../../../../../domain/debitCard/debitCardRequest/DebitCardRequest/DebitCardRequests/useDebitCardRequests';
import { useSavingsAccounts } from '../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/useSavingsAccounts';

import UserAuthorized from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useSavingsAccountsViewUseCase = (user?: UserAuthorized) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  DomainObject
   *
   * */
  const { savingsAccounts, setSavingsAccounts } = useSavingsAccounts();
  const { debitCardRequests, setDebitCardRequests } = useDebitCardRequests();

  /* *
   *
   *  Repository
   *
   * */
  const debitCardRequestRepository = new DebitCardRequestFunctions();
  const savingsAccountRepository = new SavingsAccountFunctions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new SavingsAccountsViewUseCase(
    debitCardRequestRepository,
    savingsAccountRepository,
  );

  /* *
   *
   *  Methods
   *
   * */
  const openSavingsAccount = useCallback(async () => {
    if (!user) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const outputs = await useCase.open(user);

      setDebitCardRequests(outputs.debitCardRequests);
      setSavingsAccounts(outputs.savingsAccounts);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [user]);

  /* *
   *
   *  UseEffect
   *
   * */
  useEffect(() => {
    let isMounted = true;

    if (isMounted && user) openSavingsAccount();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [user]);

  return {
    state,
    setState,
    savingsAccounts,
    debitCardRequests,
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash';
import UserAuthorized from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import CorporateAddress from '../../../../../../value/verification/kycRequest/KycRequestContent/CorporateAddress/CorporateAddress';

export const isValid = (errors: any, watch: any) => {
  return (
    isEmpty(errors) &&
    !isEmpty(watch('country')) &&
    !isEmpty(watch('addressLine1')) &&
    !isEmpty(watch('city')) &&
    !isEmpty(watch('state')) &&
    !isEmpty(watch('postalCode')) &&
    !isEmpty(watch('documentType')) &&
    !isEmpty(watch('proofOfDocumentUrl1')) &&
    !isEmpty(watch('shippingCountry')) &&
    !isEmpty(watch('shippingAddressLine1')) &&
    !isEmpty(watch('shippingCity')) &&
    !isEmpty(watch('shippingState')) &&
    !isEmpty(watch('shippingPostalCode')) &&
    !isEmpty(watch('receiverName')) &&
    !isEmpty(watch('countryCode')) &&
    !isEmpty(watch('phoneNumber'))
  );
};

export const getShippingAddressDefaultValues = (user: UserAuthorized) => {
  const shippingAddressProps = user.getProps().shippingAddress;

  return {
    shippingCountry:
      user && (shippingAddressProps ? shippingAddressProps.country : ''),
    shippingAddressLine1:
      user && (shippingAddressProps ? shippingAddressProps.addressLine1 : ''),
    shippingAddressLine2:
      user && (shippingAddressProps ? shippingAddressProps.addressLine2 : ''),
    shippingCity:
      user && (shippingAddressProps ? shippingAddressProps.city : ''),
    shippingState:
      user && (shippingAddressProps ? shippingAddressProps.state : ''),
    shippingPostalCode:
      user && (shippingAddressProps ? shippingAddressProps.postalCode : ''),
    isAddressSame: false,
    receiverName:
      user && (shippingAddressProps ? shippingAddressProps.receiverName : ''),
    countryCode:
      user &&
      (shippingAddressProps
        ? shippingAddressProps.contactNumber.countryCode
        : ''),
    phoneNumber:
      user &&
      (shippingAddressProps ? shippingAddressProps.contactNumber.number : ''),
  };
};

export const getDefaultValues = (
  corporateAddress: CorporateAddress,
  user: UserAuthorized,
) => {
  const corporateAddressProps = corporateAddress.getProps();
  const shippingAddressDefaultValues = getShippingAddressDefaultValues(user);

  return {
    country: corporateAddressProps.country,
    addressLine1: corporateAddressProps.addressLine1,
    addressLine2: corporateAddressProps.addressLine2,
    city: corporateAddressProps.city,
    state: corporateAddressProps.state,
    postalCode: corporateAddressProps.postalCode,
    documentType: corporateAddressProps.document.type,
    proofOfDocumentUrl1: corporateAddressProps.document.documentUrl1,
    ...shippingAddressDefaultValues,
  };
};

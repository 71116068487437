import React, { FC } from 'react';

export interface FontSizeProps {
  children: React.ReactNode;
  pixel: number;
}

const FontSize: FC<FontSizeProps> = ({ children, pixel }: FontSizeProps) => {
  const getLineHeight = (size: number) => {
    switch (size) {
      case 15:
        return 19;
      case 17:
        return 22;
      case 18:
        return 24;
      case 20:
        return 27;
      case 21:
        return 28;
      case 22:
        return 29;
      case 23:
        return 31;
      case 24:
        return 32;
      case 26:
        return 34;
      case 27:
        return 36;
      case 28:
        return 37;
      case 29:
        return 38;
      case 31:
        return 41;
      case 32:
        return 43;
      case 33:
        return 46;
      case 36:
        return 48;
      case 38:
        return 50;
      case 41:
        return 52;
      case 44:
        return 54;
      case 48:
        return 60;
      case 50:
        return 67;
      case 51:
        return 61;
      case 52:
        return 62;
      default:
        throw new Error(`Invalid pixel`);
    }
  };

  const lineHeight = getLineHeight(pixel);

  return (
    <span
      style={{
        fontSize: `${pixel / 16}rem`,
        lineHeight: `${lineHeight / 16}rem`,
        letterSpacing: `${(pixel / 16) * 0.02}rem`,
      }}
    >
      {children}
    </span>
  );
};

export default FontSize;

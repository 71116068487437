import { useState } from 'react';
import AssetSavingsAccount from './AssetSavingsAccount';

export const useAssetSavingsAccount = (defaultValue?: AssetSavingsAccount) => {
  const [assetSavingsAccount, setAssetSavingsAccount] = useState<
    AssetSavingsAccount | undefined
  >(defaultValue);

  return {
    assetSavingsAccount,
    setAssetSavingsAccount,
  };
};

import CryptoCurrencyTradeTransactions from './CryptoCurrencyTradeTransactions';
import CryptoCurrencySellTransactions from '../../CryptoCurrencySellTransaction/CryptoCurrencySellTransactions/CryptoCurrencySellTransactions';
import CryptoCurrencySellTransaction from '../../CryptoCurrencySellTransaction/CryptoCurrencySellTransaction/CryptoCurrencySellTransaction';
import CryptoCurrencyBuyTransactions from '../../CryptoCurrencyBuyTransaction/CryptoCurrencyBuyTransactions/CryptoCurrencyBuyTransactions';
import CryptoCurrencyBuyTransaction from '../../CryptoCurrencyBuyTransaction/CryptoCurrencyBuyTransaction/CryptoCurrencyBuyTransaction';

class CryptoCurrencyTradeTransactionsFactory {
  static createByMerge = (
    cryptoCurrencySellTransactions: CryptoCurrencySellTransactions,
    cryptoCurrencyBuyTransactions: CryptoCurrencyBuyTransactions,
  ) => {
    const cryptoCurrencyTradeTransactions = new CryptoCurrencyTradeTransactions();

    cryptoCurrencySellTransactions.forEach(
      (cryptoCurrencySellTransaction: CryptoCurrencySellTransaction) => {
        cryptoCurrencyTradeTransactions.add(cryptoCurrencySellTransaction);
      },
    );

    cryptoCurrencyBuyTransactions.forEach(
      (cryptoCurrencyBuyTransaction: CryptoCurrencyBuyTransaction) => {
        cryptoCurrencyTradeTransactions.add(cryptoCurrencyBuyTransaction);
      },
    );

    return cryptoCurrencyTradeTransactions;
  };
}

export default CryptoCurrencyTradeTransactionsFactory;

import React from 'react';
import TransferDebitCardTransactionV2s from './TransferDebitCardTransactionV2s';

export interface TransferDebitCardTransactionV2sContextProps {
  transferDebitCardTransactions: TransferDebitCardTransactionV2s;
}

const TransferDebitCardTransactionV2sContext = React.createContext<
  TransferDebitCardTransactionV2sContextProps
>({
  transferDebitCardTransactions: new TransferDebitCardTransactionV2s(),
});

export default TransferDebitCardTransactionV2sContext;

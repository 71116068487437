import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Lib
import { getStorageFiles } from '../../../infrastructure/firebase/cloudStorage/useStorageFiles';

// ReduxAction
import { setManuals } from '../../../redux/reducers/manualsReducer';

// Type
import { GlobalStateType } from '../../../redux/store';

// Constant
import {
  MANUAL_DIRECTORY_PATHS,
  STORAGE_PATH_MANUALS,
} from '../../../config/constants/cloudStorage';

export const useManualFiles = () => {
  const dispatch = useDispatch();
  const [manualFiles, setManualFiles] = useState<{
    [key: string]: Array<{ fileName: string; url: string }>;
  }>({});

  const state = useSelector(
    (globalState: GlobalStateType) => globalState.manualsStore,
  );

  /* *
   *
   *   Methods
   *
   * */
  const setFiles = useCallback(async () => {
    const manuals = {} as {
      [key: string]: Array<{ fileName: string; url: string }>;
    };

    // eslint-disable-next-line no-restricted-syntax
    for await (const directory of MANUAL_DIRECTORY_PATHS) {
      const files = await getStorageFiles(
        `${STORAGE_PATH_MANUALS}/${directory}`,
      );

      Object.assign(manuals, { [directory]: files });
    }

    dispatch(setManuals(manuals));
    // eslint-disable-next-line
  }, []);

  /* *
   *
   *   UseEffect
   *
   * */

  // Set Manual Files
  useEffect(() => {
    let isMounted = true;

    if (isMounted) setFiles();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  // Set Redux
  useEffect(() => {
    let isMounted = true;

    if (isMounted) setManualFiles(state);

    return () => {
      isMounted = false;
    };
  }, [state]);

  return {
    manualFiles,
  };
};

import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ContractTimeDepositSeedContext from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDepositSeed/ContractTimeDepositSeedContext';

// Style
import { TableVerticalLayout, TableVerticalStyle } from './style';

// Mapper
import TableConfirmContractTimeDepositMapper from './TableConfirmContractTimeDepositMapper';

// Component
import TableVertical from '../../../../molecules/table/table/TableVertical';

const TableConfirmTimeDepositAdd: FC = () => {
  const { t } = useTranslation();

  const { contractTimeDepositSeed } = useContext(
    ContractTimeDepositSeedContext,
  );

  const rows = useMemo(
    () =>
      !contractTimeDepositSeed
        ? []
        : TableConfirmContractTimeDepositMapper.domainToTd(
            contractTimeDepositSeed,
            t,
          ),
    [contractTimeDepositSeed, t],
  );

  return (
    <TableVertical
      isTableBorder
      layout={TableVerticalLayout}
      rows={rows}
      theme={TableVerticalStyle}
    />
  );
};

export default TableConfirmTimeDepositAdd;

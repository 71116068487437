import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { FlexRow } from '../../../atoms/utils/layout/Flex';
import Margin from '../../../atoms/utils/layout/Margin';

export interface BreadcrumbSentenceLayoutProps {
  children: React.ReactElement[];
  marginBetween: string | number;
}

const BreadcrumbSentenceLayout: FC<BreadcrumbSentenceLayoutProps> = ({
  children,
  marginBetween,
}: BreadcrumbSentenceLayoutProps) => {
  const elements = ['icon', 'sentence'];
  const [icon, sentence] = getElementsFromKeys(children, elements);

  return (
    <FlexRow alignItems="center">
      {icon}
      <Margin left={marginBetween}>{sentence}</Margin>
    </FlexRow>
  );
};
export default BreadcrumbSentenceLayout;

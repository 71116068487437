import React, { FC } from 'react';
import { FlexBoxRow } from '../../utils/layout/Box/FlexBox';

export interface FileUploadButtonProps {
  children: React.ReactNode;
  width?: string | number;
  height?: string | number;
  onClick?: () => void;
  theme?: {
    borderRadius?: string | number;
  };
}

const FileUploadButton: FC<FileUploadButtonProps> = ({
  children,
  width = '100%',
  height = '100%',
  onClick = () => undefined,
  theme,
}: FileUploadButtonProps) => {
  return (
    <FlexBoxRow
      width={width}
      height={height}
      justifyContent="center"
      alignItems="center"
      theme={{
        ...theme,
        backgroundColor: 'black',
      }}
      style={{
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {children}
    </FlexBoxRow>
  );
};

export default FileUploadButton;

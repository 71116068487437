// React
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserAuthorizedAction } from '../../../../redux/actions/signInUserActions';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';
import { executeWithTimeout } from '../../../../utils/helpers/timeout';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';
import { REQUEST_TIMEOUT } from '../../../../config/constants/business';

// UseCase
import RegisterUseCase from '../../../../useCases/authentication/register/RegisterUseCase';

// Repository, Functions
import UserFunctions from '../../../../repository/public/user/UserFunctions';
import UserFirebaseAuth from '../../../../repository/public/user/UserFirebaseAuth';

// DomainObject
import SignUpRequest from '../../../../domain/userRequest/signUpRequest/SignUpRequest/SignUpRequest';

export const useRegisterUseCase = (signUpRequest?: SignUpRequest) => {
  const [state, setState] = useState<string>(INITIAL);
  const dispatch = useDispatch();

  // Repository, Auth
  const userCreator = new UserFunctions();
  const userAuthentication = new UserFirebaseAuth();

  // UseCase
  const useCase = new RegisterUseCase(userCreator, userAuthentication);

  // UseCase.register
  const registerUser = async (formInput: {
    username: string;
    password: string;
  }) => {
    setState(IS_LOADING);

    try {
      checkInternetConnection();

      const email = signUpRequest!.getId();
      const { oobCode } = signUpRequest!.getProps();

      const userAuthorized = await executeWithTimeout(
        useCase.register(
          formInput.username,
          formInput.password,
          oobCode,
          email,
        ),
        REQUEST_TIMEOUT,
      );

      dispatch(
        setUserAuthorizedAction({
          id: userAuthorized.getId(),
          signInUserProps: {
            ...userAuthorized.getProps(),
            token: userAuthorized.getToken(),
          },
        }),
      );

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  return {
    state,
    setState,
    registerUser,
  };
};

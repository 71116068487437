import React from 'react';
import Authenticator from './Authenticator';

export interface AuthenticatorContextProps {
  authenticator: Authenticator | undefined;
}

const AuthenticatorContext = React.createContext<AuthenticatorContextProps>({
  authenticator: undefined,
});

export default AuthenticatorContext;

// Type
import {
  AssetSavingsAccountRawDataType,
  AssetSavingsAccountPrimaryKey,
} from '../../../domain/asset/assetSavingsAccount/AssetSavingsAccount/AssetSavingsAccount.type';
import { FiatCurrencyType } from '../../../types/FiatCurrency.type';

// Functions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';
import { ASSET_GET_SAVINGS_ACCOUNT_RAW_DATA_BY_USER_ID_CURRENCY } from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// IRepository
import IAssetSavingsAccountRepository from '../../../domain/asset/assetSavingsAccount/IAssetSavingsAccountRepository';

// DomainObject
import AssetSavingsAccountFactory from '../../../domain/asset/assetSavingsAccount/AssetSavingsAccount/AssetSavingsAccountFactory';

class AssetSavingsAccountFunctions
  extends FirebasePrivateFunctions
  implements IAssetSavingsAccountRepository {
  public findByPrimaryKey = async (params: AssetSavingsAccountPrimaryKey) => {
    const { userId, currency } = params;

    const endpoint = ASSET_GET_SAVINGS_ACCOUNT_RAW_DATA_BY_USER_ID_CURRENCY.replace(
      /:userId/,
      userId,
    ).replace(/:currency/, currency);

    const response = await this.privateGET(endpoint);

    const row = response.data.data as AssetSavingsAccountRawDataType;

    return AssetSavingsAccountFactory.create(
      row.currency as FiatCurrencyType,
      row.props,
    );
  };
}
export default AssetSavingsAccountFunctions;

import React from 'react';
import CryptoCurrencyTradeLimit from './CryptoCurrencyTradeLimit';

export interface CryptoCurrencyTradeLimitContextProps {
  cryptoCurrencyTradeLimit?: CryptoCurrencyTradeLimit;
}

const CryptoCurrencyTradeLimitContext = React.createContext<
  CryptoCurrencyTradeLimitContextProps
>({
  cryptoCurrencyTradeLimit: undefined,
});

export default CryptoCurrencyTradeLimitContext;

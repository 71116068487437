import Joi from '@hapi/joi';

// Type
import { ResetAuthenticatorRequestStatusType } from '../../../../types/UserRequest.type';

export interface ResetAuthenticatorRequestProps {
  oobCode: string;

  accountKey: string;

  email: string;

  status: ResetAuthenticatorRequestStatusType;

  expiredAt: number;

  createdAt?: number;

  updatedAt?: number;
}

export interface ResetAuthenticatorRequestRawDataType {
  id: string;

  props: ResetAuthenticatorRequestProps;
}

export const ResetAuthenticatorRequestPropsFormat = {
  id: Joi.string().min(1).required(),

  oobCode: Joi.string().required(),

  accountKey: Joi.string().required(),

  email: Joi.string()
    .email({
      allowUnicode: false,
      tlds: { allow: false },
    })
    .required(),

  status: Object.keys(ResetAuthenticatorRequestStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),

  expiredAt: Joi.number().required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

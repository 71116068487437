import TransferSavingsAccountTransactionSeed from '../TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed';

class TransferForbesAccountTransactionSeed extends TransferSavingsAccountTransactionSeed {
  public getRequestParameterCreate = () => ({
    savingsAccountNumber: this.savingsAccountNumber.getAccountNumber(),
    beneficiaryAccountNumber: this.beneficiaryAccountNumber.getAccountNumber(),
    beneficiaryAccountHolder: this.beneficiaryAccountHolder,
    amount: this.amount,
    currency: this.currency,
    transferType: this.transferSavingsAccountType,
    purpose: this.purpose,
    fee: this.fee,
  });
}

export default TransferForbesAccountTransactionSeed;

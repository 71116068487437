import ReferralTimeDeposit from '../../referralTimeDeposit/ReferralTimeDeposit/ReferralTimeDeposit';
import { ReferralTimeDepositByRankAProps } from './ReferralTimeDepositByRankA.type';

class ReferralTimeDepositByRankA extends ReferralTimeDeposit {
  private secondLevelInviterId: string;

  private secondLevelInviterContractName: string;

  constructor(id: string, props: ReferralTimeDepositByRankAProps) {
    super(id, props);
    this.secondLevelInviterId = props.secondLevelInviterId;
    this.secondLevelInviterContractName = props.secondLevelInviterContractName;
  }

  public getProps() {
    return {
      ...super.getProps(),
      secondLevelInviterId: this.secondLevelInviterId,
      secondLevelInviterContractName: this.secondLevelInviterContractName,
    };
  }

  public getParameterForChildReferral = () => ({
    topLevelInviterId: this.topLevelInviterId,
    topLevelInviterContractName: this.topLevelInviterContractName,
    secondLevelInviterId: this.secondLevelInviterId,
    secondLevelInviterContractName: this.secondLevelInviterContractName,
    depositAmount: this.depositAmount,
    depositCurrency: this.depositCurrency,
    interestRate: this.interestRate,
    term: this.term,
    userId: this.userId,
    userContractName: this.userContractName,
  });

  public addCommissionTotal = (commission: number) => {
    return new ReferralTimeDepositByRankA(
      this.contractTimeDepositId.getRawId(),
      {
        ...(this.props as ReferralTimeDepositByRankAProps),
        commissionTotal: this.commissionTotal + commission,
      },
    );
  };

  public payContractTimeDepositCommission = (amount: number) => {
    return new ReferralTimeDepositByRankA(
      this.contractTimeDepositId.getRawId(),
      {
        ...(this.props as ReferralTimeDepositByRankAProps),
        commissionPaid: this.commissionPaid + amount,
      },
    );
  };

  public getAffiliatorContractName = () => this.secondLevelInviterContractName;
}

export default ReferralTimeDepositByRankA;

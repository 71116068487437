import React from 'react';
import Maintenance from './Maintenance';

export interface MaintenanceContextProps {
  maintenance?: Maintenance;
}

const MaintenanceContext = React.createContext<MaintenanceContextProps>({
  maintenance: undefined,
});

export default MaintenanceContext;

// Types
import { UseStateType } from '../../../types/typeof/UseState';
import { InviterProps } from '../../../domain/invite/inviter/Inviter/Inviter.type';

// Collection
import firestore from '../../../infrastructure/firebase/firestore/client/firestoreClient';
import InvitersCollection from '../../../infrastructure/firebase/firestore/collections/invite/users/InvitersCollection';

// IRepository
import IInviterRepository from '../../../domain/invite/inviter/IInviterRepository';

// DomainObject
import Inviter from '../../../domain/invite/inviter/Inviter/Inviter';
import InviterFactory from '../../../domain/invite/inviter/Inviter/InviterFactory';
import { RankType } from '../../../types/Invite.type';

class InviterFirestore implements IInviterRepository {
  public subscribeById = async (
    id: string,
    setter: UseStateType<Inviter | undefined>,
    setState: UseStateType<string>,
  ) => {
    const inviterCollection = new InvitersCollection();

    const converter = (doc: firestore.DocumentSnapshot) => {
      const props = doc.data();
      const rank = props?.rank;

      // Tmp Code
      Object.assign(props, {
        totalReferralTimeDepositAmount: 0,
      });

      if (
        String(rank) === String(RankType.D01) ||
        String(rank) === String(RankType.D02)
      ) {
        Object.assign(props, {
          totalCommission: 0,
          totalCommissionReceived: 0,
          totalReferralChildrenTimeDepositAmount: 0,
        });
      }

      return InviterFactory.create(
        doc.id,

        props as InviterProps,
      ) as Inviter;
    };

    return inviterCollection.subscribeSpecific(id, setter, converter, setState);
  };
}
export default InviterFirestore;

import React, { FC } from 'react';
import { LabelStyle } from './style';
import { TextPrimary } from '../../../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../../../atoms/text2/LineBreakReplaced';
import TextAreaField from '../../../TextAreaField';
import FormInputTextAreaLayout from './FormInputTextAreaLayout';

interface FormInputTextAreaProps {
  name: string;
  label: string;
  inputWidth: string | number;
  onChange?: (value: string) => void;
  placeholder?: string;
  maxLength: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any;
}

const FormInputTextArea: FC<FormInputTextAreaProps> = ({
  name,
  label,
  inputWidth,
  placeholder,
  maxLength,
  register,
  onChange = () => undefined,
}: FormInputTextAreaProps) => {
  return (
    <FormInputTextAreaLayout inputWidth={inputWidth}>
      <TextPrimary key="label" theme={LabelStyle}>
        <LineBreakReplaced text={label} />
      </TextPrimary>
      <TextAreaField
        key="input"
        name={name}
        placeholder={placeholder}
        textInputWidth={inputWidth}
        maxLength={maxLength}
        onChange={onChange}
        register={register}
      />
    </FormInputTextAreaLayout>
  );
};

export default FormInputTextArea;

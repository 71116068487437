import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import KycRequestContext from '../../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequestContext';

// Component
import FormProofOfResidency from '../../../organisms/form/verification/kycRequest/FormProofOfResidency';
import KycStepTemplate from '../KycStepTemplate';

const TEMPLATE_ID = `templates.verification.ProofOfResidencyTemplate`;

const ProofOfResidencyTemplate: FC = () => {
  const { t } = useTranslation();
  const { kycRequest } = useContext(KycRequestContext);

  // First Level Property
  const proofOfResidency = kycRequest?.getProofOfResidency();
  const kycStepStatus = kycRequest?.getKycStepStatus();

  // Second Level Property
  const rejectedReason = proofOfResidency?.getProps().rejectedReason;
  const status = kycStepStatus?.getProps().proofOfResidency;

  return (
    <KycStepTemplate
      status={status}
      title={t(`${TEMPLATE_ID}.title`)}
      rejectedReason={rejectedReason}
    >
      <FormProofOfResidency proofOfResidency={proofOfResidency} />
    </KycStepTemplate>
  );
};

export default ProofOfResidencyTemplate;

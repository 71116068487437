// Type
import { CherryVisa01ProofOfReceiptProps } from './CherryVisa01ProofOfReceipt.type';

// ValueObject
import DebitCardRequestContent from '../DebitCardRequestContent/DebitCardRequestContent';

class CherryVisa01ProofOfReceipt extends DebitCardRequestContent {
  private selfieId: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private selfie?: any;

  constructor(props: CherryVisa01ProofOfReceiptProps) {
    super(props);
    this.selfieId = props.selfieId;
    this.selfie = props.selfie;
  }

  public getProps = () => ({
    ...super.getProps(),
    selfieId: this.selfieId,
    selfie: this.selfie,
  });

  public updateProps = (props: CherryVisa01ProofOfReceiptProps) => {
    return new CherryVisa01ProofOfReceipt({ ...this.getProps(), ...props });
  };
}

export default CherryVisa01ProofOfReceipt;

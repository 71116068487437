import styled from 'styled-components';
import color from '../../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../../atoms/utils/const/fontWeight';

export const SignatureExampleImageWrap = styled.div`
  width: ${849.69 / 16}rem;
  height: ${290 / 16}rem;
`;

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const TitleStyle = {
  fontSize: 22,
  color: color.text.primary,
  fontWeight: fontWeight.regular,
};

export const TitleSmallStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

import { useCallback, useState } from 'react';

// Error
import ErrorHandler from '../../../../../../utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from '../../../../../../utils/errors/ErrorHandler/IErrorHandler';

// Helper
import { separateFormInput, convertFormInputToProps } from './helper';

// Lib
import { checkInternetConnection } from '../../../../../../utils/helpers/connection';

// Type
import {
  useFormInputCorporateAddress,
  FormInputCorporateAddress,
  FormInputShippingAddress,
} from '../../../../../../components/organisms/form/verification/kycRequest/FormCorporateAddress/FormCorporateAddress.type';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../../config/constants/requestState';

// Repository
import KycRequestFunctions from '../../../../../../repository/verification/kycRequest/KycRequestFunctions';
import CloudStorage from '../../../../../../storage/cloudStorage/CloudStorage';

// DomainObject
import KycRequestCorporate from '../../../../../../domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporate';
import UserAuthorized from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import KycRequestSubmitUseCase from '../../../../../../useCases/verification/kycRequest/KycRequestSubmitUseCase';

// ValueObject
import ShippingAddressFactory from '../../../../../../value/personalInformation/address/Address/ShippingAddress/ShippingAddressFactory';

// File
import { useCorporateAddressFile } from '../../../../../fileHandler/verification/kycRequest/CorporateAddressFileProvider/useCorporateAddressFile';

export const useCorporateAddressSubmitUseCase = (
  kycRequest: KycRequestCorporate | undefined,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const formInputCorporateAddressContext = useFormInputCorporateAddress();

  // File
  const { proofOfDocument1, setProofOfDocument1 } = useCorporateAddressFile();

  /* *
   *
   *  Repository
   *
   * */
  const kycRequestFunctions = new KycRequestFunctions();
  const storageHandler = new CloudStorage();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new KycRequestSubmitUseCase(
    kycRequestFunctions,
    storageHandler,
  );

  /* *
   *
   *  Method
   *
   * */
  const submitCorporateAddress = useCallback(
    async (
      formInput: FormInputCorporateAddress & FormInputShippingAddress,
      callback?: () => void,
    ) => {
      if (!userAuthorized || !kycRequest) return;

      setState(IS_LOADING);

      try {
        // ---check onLine ---- //
        checkInternetConnection();

        // [Conversion] FormInput -> Props
        const {
          corporateAddressProps,
          shippingAddressProps,
        } = convertFormInputToProps(separateFormInput(formInput));

        // [DomainObject]
        const kycRequestUpdated = kycRequest.updateCorporateAddress({
          ...corporateAddressProps,
          status: kycRequest.getCorporateAddress().getProps().status,
        });
        const shippingAddress = ShippingAddressFactory.create(
          shippingAddressProps,
        );

        // [Execution]
        await useCase.submitCorporateAddress(
          kycRequestUpdated,
          shippingAddress,
          proofOfDocument1,
        );

        setState(SUCCESS);

        if (callback) callback();
      } catch (error) {
        const handler: IErrorHandler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [userAuthorized, kycRequest, proofOfDocument1],
  );

  return {
    state,
    setState,
    submitCorporateAddress,
    formInputCorporateAddressContext,
    corporateAddressFileContext: {
      proofOfDocument1,
      setProofOfDocument1,
    },
  };
};

import React from 'react';
import CryptoCurrencyTradeTransactionSeed from './CryptoCurrencyTradeTransactionSeed';

export interface CryptoCurrencyTradeTransactionSeedContextProps {
  cryptoCurrencyTradeTransactionSeed?: CryptoCurrencyTradeTransactionSeed;
}

const CryptoCurrencyTradeTransactionSeedContext = React.createContext<
  CryptoCurrencyTradeTransactionSeedContextProps
>({
  cryptoCurrencyTradeTransactionSeed: undefined,
});

export default CryptoCurrencyTradeTransactionSeedContext;

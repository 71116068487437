import Joi from '@hapi/joi';

// Type
import {
  CryptoCurrencyTradeTransactionProps,
  CryptoCurrencyTradeTransactionPropsFormat,
} from '../../CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction.type';
import { CryptoCurrencyType } from '../../../../../../../types/CryptoCurrency.type';
import { FiatCurrencyType } from '../../../../../../../types/FiatCurrency.type';

export interface CryptoCurrencySellTransactionProps
  extends CryptoCurrencyTradeTransactionProps {
  payCurrency: CryptoCurrencyType;

  receiveCurrency: FiatCurrencyType;
}

export const CryptoCurrencySellTransactionPropsFormat = {
  ...CryptoCurrencyTradeTransactionPropsFormat,

  payCurrency: Object.keys(CryptoCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),

  receiveCurrency: Object.keys(FiatCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),
};

import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import ContractTimeDepositTemplate from '../../../components/templates/contractTimeDeposit/ContractTimeDepositTemplate';

const ContractTimeDepositPage: FC = () => {
  return (
    <DashboardTemplate>
      <ContractTimeDepositTemplate />
    </DashboardTemplate>
  );
};

export default ContractTimeDepositPage;

import React from 'react';
import BankFutureDeposits from './BankFutureDeposits';

export interface BankFutureDepositContextProps {
  bankFutureDeposits: BankFutureDeposits;
}

const BankFutureDepositContext = React.createContext<
  BankFutureDepositContextProps
>({
  bankFutureDeposits: new BankFutureDeposits(),
});

export default BankFutureDepositContext;

import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  CryptoCurrencySellTransactionSeedProps,
  CryptoCurrencySellTransactionSeedPropsFormat,
} from './CryptoCurrencySellTransactionSeed.type';

// DomainObject
import CryptoCurrencySellTransactionSeed from './CryptoCurrencySellTransactionSeed';

class CryptoCurrencySellTransactionSeedFactory {
  static create = (props: CryptoCurrencySellTransactionSeedProps) => {
    const result = Joi.object(
      CryptoCurrencySellTransactionSeedPropsFormat,
    ).validate(props);

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: CryptoCurrencySellTransactionSeed.name,
          reason: `${result.error.details[0].message}`,
        },
      );

    return new CryptoCurrencySellTransactionSeed(props);
  };
}

export default CryptoCurrencySellTransactionSeedFactory;

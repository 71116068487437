// Functions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// IRepository
import { IKycRequestUpdateRepository } from '../../../domain/verification/kycRequest/KycRequest/IKycRequestRepository';

// Constant
import {
  KYC_REQUEST_UPDATE_CORPORATE_INFORMATION,
  KYC_REQUEST_UPDATE_CORPORATE_ADDRESS,
  KYC_REQUEST_UPDATE_PERSONAL_INFORMATION,
  KYC_REQUEST_UPDATE_PROOF_OF_RESIDENCY,
} from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// DomainObject
import KycRequest from '../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequest';
import KycRequestCorporate from '../../../domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporate';

// ValueObject
import ShippingAddress from '../../../value/personalInformation/address/Address/ShippingAddress/ShippingAddress';

class KycRequestFunctions
  extends FirebasePrivateFunctions
  implements IKycRequestUpdateRepository {
  public updateCorporateInformation = async (
    kycRequest: KycRequestCorporate,
  ) => {
    const id = kycRequest.getId();
    const corporateInformation = kycRequest.getCorporateInformation();

    const {
      corporateName,
      corporateRegistryUrl,
    } = corporateInformation.getProps();

    await this.privatePUT(`${KYC_REQUEST_UPDATE_CORPORATE_INFORMATION}/${id}`, {
      corporateName,
      corporateRegistryUrl,
    });

    return kycRequest;
  };

  public updateCorporateAddress = async (
    kycRequest: KycRequestCorporate,
    shippingAddress: ShippingAddress,
  ) => {
    const id = kycRequest.getId();
    const corporateAddress = kycRequest.getCorporateAddress();

    const proofOfResidencyProps = corporateAddress.getProps();
    const shippingAddressProps = shippingAddress.getProps();

    await this.privatePUT(`${KYC_REQUEST_UPDATE_CORPORATE_ADDRESS}/${id}`, {
      country: proofOfResidencyProps.country,
      addressLine1: proofOfResidencyProps.addressLine1,
      addressLine2: proofOfResidencyProps.addressLine2,
      city: proofOfResidencyProps.city,
      state: proofOfResidencyProps.state,
      postalCode: proofOfResidencyProps.postalCode,
      documentType: proofOfResidencyProps.document.type,
      proofOfDocumentUrl1: proofOfResidencyProps.document.documentUrl1,
      shippingCountry: shippingAddressProps.country,
      shippingAddressLine1: shippingAddressProps.addressLine1,
      shippingAddressLine2: shippingAddressProps.addressLine2,
      shippingCity: shippingAddressProps.city,
      shippingState: shippingAddressProps.state,
      shippingPostalCode: shippingAddressProps.postalCode,
      receiverName: shippingAddressProps.receiverName,
      phoneNumber: shippingAddressProps.contactNumber.number,
      countryCode: shippingAddressProps.contactNumber.countryCode,
    });

    return kycRequest;
  };

  public updatePersonalInformation = async (kycRequest: KycRequest) => {
    const id = kycRequest.getId();
    const personalInformation = kycRequest.getPersonalInformation();
    const props = personalInformation.getProps();

    await this.privatePUT(`${KYC_REQUEST_UPDATE_PERSONAL_INFORMATION}/${id}`, {
      firstName: props.firstName,
      middleName: props.middleName,
      lastName: props.lastName,
      gender: props.gender,
      dateOfBirthYear: props.dateOfBirth.substr(0, 4),
      dateOfBirthMonth: props.dateOfBirth.substr(5, 2),
      dateOfBirthDay: props.dateOfBirth.substr(8, 2),
      passportNo: props.passport.passportNo,
      passportUrl: props.passport.passportUrl,
      expiredDateYear: props.passport.expiredAt.substr(0, 4),
      expiredDateMonth: props.passport.expiredAt.substr(5, 2),
      expiredDateDay: props.passport.expiredAt.substr(8, 2),
      selfieUrl: props.selfieUrl,
    });

    return kycRequest;
  };

  public updateProofOfResidency = async (
    kycRequest: KycRequest,
    shippingAddress: ShippingAddress,
  ) => {
    const id = kycRequest.getId();
    const proofOfResidency = kycRequest.getProofOfResidency();

    const proofOfResidencyProps = proofOfResidency.getProps();
    const shippingAddressProps = shippingAddress.getProps();

    await this.privatePUT(`${KYC_REQUEST_UPDATE_PROOF_OF_RESIDENCY}/${id}`, {
      country: proofOfResidencyProps.country,
      addressLine1: proofOfResidencyProps.addressLine1,
      addressLine2: proofOfResidencyProps.addressLine2,
      city: proofOfResidencyProps.city,
      state: proofOfResidencyProps.state,
      postalCode: proofOfResidencyProps.postalCode,
      documentType: proofOfResidencyProps.document.type,
      proofOfDocumentUrl1: proofOfResidencyProps.document.documentUrl1,
      proofOfDocumentUrl2: proofOfResidencyProps.document.documentUrl2,
      shippingCountry: shippingAddressProps.country,
      shippingAddressLine1: shippingAddressProps.addressLine1,
      shippingAddressLine2: shippingAddressProps.addressLine2,
      shippingCity: shippingAddressProps.city,
      shippingState: shippingAddressProps.state,
      shippingPostalCode: shippingAddressProps.postalCode,
      receiverName: shippingAddressProps.receiverName,
      phoneNumber: shippingAddressProps.contactNumber.number,
      countryCode: shippingAddressProps.contactNumber.countryCode,
    });

    return kycRequest;
  };
}
export default KycRequestFunctions;

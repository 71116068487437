import { useState } from 'react';

// DomainObject
import DebitCardV2 from '../../../../../../domain/debitCard/debitCardV2/DebitCardV2/DebitCardV2';

export const useDebitCardOption = () => {
  const [debitCardOption, setDebitCardOption] = useState<DebitCardV2>();

  return {
    debitCardOption,
    setDebitCardOption,
  };
};

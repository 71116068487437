import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { MessageContainer } from './style';
import Margin from '../../../atoms/utils/layout/Margin';

export interface ResignUpTemplateLayoutLayoutProps {
  children: React.ReactElement[];
}

const ResignUpTemplateLayoutLayout: FC<ResignUpTemplateLayoutLayoutProps> = ({
  children,
}: ResignUpTemplateLayoutLayoutProps) => {
  const elements = ['title', 'notice', 'form', 'linkTop'];
  const [title, notice, form, linkTop] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <>
      {title}
      <Margin top={20} />
      <MessageContainer>{notice}</MessageContainer>
      <Margin top={50} />
      {form}
      <Margin top={90} />
      {linkTop}
    </>
  );
};
export default ResignUpTemplateLayoutLayout;

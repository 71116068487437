import { useState } from 'react';
import ConsentForm from './ConsentForm';

export const useConsentForm = (initial?: ConsentForm) => {
  const [consentForm, setConsentForm] = useState<ConsentForm | undefined>(
    initial,
  );

  return {
    consentForm,
    setConsentForm,
  };
};

import { FC } from 'react';

// Lib
import { addByDecimal } from '../../../../utils/helpers/calculationHelper';

// Interface
import { IIterable } from '../../../interface/Iterator/IIterable';

// Type
import { ContractTimeDepositStatusType } from '../../../../types/ContractTimeDeposit.type';

// DomainObject
import ContractTimeDeposit from '../ContractTimeDeposit/ContractTimeDeposit';
import SavingsAccount from '../../../bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

class ContractsTimeDeposit implements IIterable<ContractTimeDeposit> {
  private contractsTimeDeposit: ContractTimeDeposit[] = [];

  constructor(contractsTimeDeposit?: ContractTimeDeposit[]) {
    this.contractsTimeDeposit = contractsTimeDeposit || [];
  }

  public add = (contractTimeDeposit: ContractTimeDeposit) => {
    this.contractsTimeDeposit.push(contractTimeDeposit);
  };

  public head = () => this.contractsTimeDeposit[0];

  public map = <T>(
    callback: (contractTimeDeposit: ContractTimeDeposit) => T,
  ) => {
    return this.contractsTimeDeposit.map((contractTimeDeposit) =>
      callback(contractTimeDeposit),
    );
  };

  public forEach = (
    callback: (contractTimeDeposit: ContractTimeDeposit) => void,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const contractTimeDeposit of this.contractsTimeDeposit) {
      // eslint-disable-next-line no-await-in-loop
      callback(contractTimeDeposit);
    }
  };

  public forEachAsync = async (
    callback: (contractTimeDeposit: ContractTimeDeposit) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const contractTimeDeposit of this.contractsTimeDeposit) {
      // eslint-disable-next-line no-await-in-loop
      await callback(contractTimeDeposit);
    }
  };

  public mapComponent = (
    item: FC<{ contractTimeDeposit: ContractTimeDeposit; i: number }>,
  ) => {
    return this.contractsTimeDeposit.map((contractTimeDeposit, i) =>
      item({ contractTimeDeposit, i }),
    );
  };

  public filterBySavingsAccount = (savingsAccount?: SavingsAccount) => {
    if (!savingsAccount) return this;

    const filtered = this.contractsTimeDeposit.filter((contractTimeDeposit) =>
      contractTimeDeposit.isSavingsAccount(savingsAccount),
    );
    return new ContractsTimeDeposit(filtered);
  };

  public filterByStatus = (status: ContractTimeDepositStatusType) => {
    const filtered = this.contractsTimeDeposit.filter((contractTimeDeposit) =>
      contractTimeDeposit.isStatus(status),
    );
    return new ContractsTimeDeposit(filtered);
  };

  public sortAscByMaturityDate = () => {
    const compare = (a: ContractTimeDeposit, b: ContractTimeDeposit) => {
      if (
        a.getMaturityDateFormatted('YYYY/MM/DD') !==
        b.getMaturityDateFormatted('YYYY/MM/DD')
      )
        return a.getMaturityDateFormatted('YYYY/MM/DD') >
          b.getMaturityDateFormatted('YYYY/MM/DD')
          ? 1
          : -1;
      return a.getRawId() > b.getRawId() ? 1 : -1;
    };
    return new ContractsTimeDeposit(this.contractsTimeDeposit.sort(compare));
  };

  /**
   *
   *  Aggregation
   *
   */
  public getTotalAmount = () => {
    const totalAmount = this.contractsTimeDeposit.reduce(
      (amount, contractTimeDeposit) => {
        const { depositAmount, status } = contractTimeDeposit.getProps();

        return status === ContractTimeDepositStatusType.active
          ? addByDecimal(amount, depositAmount)
          : amount;
      },
      0,
    );

    return totalAmount;
  };
}
export default ContractsTimeDeposit;

import { useState } from 'react';

// OriginalLib
import { executeWithTimeout } from '../../../../../utils/helpers/timeout';
import { checkInternetConnection } from '../../../../../utils/helpers/connection';

// Constant
import { REQUEST_TIMEOUT } from '../../../../../config/constants/business';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';

// Type
import { FormInputContractTimeDepositAdd } from '../../../../../components/organisms/form/contract/contractTimeDeposit/FormContractTimeDepositAdd/FormInputContractTimeDepositAdd.type';

// Error
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import ContractTimeDepositCreateUseCase from '../../../../../useCases/contract/contractTimeDeposit/ContractTimeDepositCreateUseCase';

// Repository
import AuthenticatorFunctions from '../../../../../repository/authenticator/AuthenticatorFunctions';
import ContractTimeDepositFunctions from '../../../../../repository/contract/contractTimeDeposit/ContractTimeDepositFunctions';

// DomainObject
import { useContractTimeDeposit } from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/useContractTimeDeposit';
import { useContractTimeDepositSeed } from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDepositSeed/useContractTimeDepositSeed';
import TimeDeposits from '../../../../../domain/product/timeDeposit/TimeDeposits/TimeDeposits';
import TimeDeposit from '../../../../../domain/product/timeDeposit/TimeDeposit/TimeDeposit';
import UserVerified from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';
import SavingsAccount from '../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

export const useContractTimeDepositCreateUseCase = (
  user: UserVerified | undefined,
  timeDeposits: TimeDeposits,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  DomainObject
   *
   * */
  const {
    contractTimeDepositSeed,
    setContractTimeDepositSeed,
  } = useContractTimeDepositSeed();

  const {
    contractTimeDeposit,
    setContractTimeDeposit,
  } = useContractTimeDeposit();

  /* *
   *
   *  Repository
   *
   * */
  const authenticatorVerifier = new AuthenticatorFunctions();
  const contractTimeDepositCreator = new ContractTimeDepositFunctions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new ContractTimeDepositCreateUseCase(
    authenticatorVerifier,
    contractTimeDepositCreator,
  );

  // UseCase.create
  const createContractTimeDeposit = (
    params: FormInputContractTimeDepositAdd,
    savingsAccount: SavingsAccount,
    timeDeposit: TimeDeposit,
    callback?: () => void,
  ) => {
    if (!user) return;

    setState(INITIAL);

    try {
      const { amount } = params;

      const output = useCase.create({
        user,
        timeDeposit,
        timeDeposits,
        savingsAccount,
        amount: Number(amount.replace(/,/g, '')),
        isAnnualPayOut: params.isAnnualPayOut === 'true',
      });

      setContractTimeDepositSeed(output.contractTimeDepositSeed);

      if (callback) callback();

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  // UseCase.executeCreate
  const executeCreateContractTimeDeposit = async (
    code: string,
    callback?: () => void,
  ) => {
    setState(IS_LOADING);

    try {
      checkInternetConnection();

      const output = await executeWithTimeout(
        useCase.executeCreate(user!, contractTimeDepositSeed!, code),
        REQUEST_TIMEOUT,
      );

      setContractTimeDeposit(output.contractTimeDeposit);

      if (callback) callback();

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  return {
    state,
    setState,
    contractTimeDeposit,
    setContractTimeDeposit,
    contractTimeDepositSeed,
    createContractTimeDeposit,
    executeCreateContractTimeDeposit,
  };
};

import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  DeliveryTrackingInformationProps,
  DeliveryTrackingInformationPropsFormat,
  deliveryTrackingInformationInitialProps,
} from './DeliveryTrackingInformation.type';

// ValueObject
import DeliveryTrackingInformation from './DeliveryTrackingInformation';

class DeliveryTrackingInformationFactory {
  static create = (props: DeliveryTrackingInformationProps) => {
    const result = Joi.object(DeliveryTrackingInformationPropsFormat).validate(
      props,
    );

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: DeliveryTrackingInformation.name,
          reason: result.error.details[0].message,
        },
      );

    return new DeliveryTrackingInformation(props);
  };

  static createInitial = () => {
    return DeliveryTrackingInformationFactory.create(
      deliveryTrackingInformationInitialProps,
    );
  };
}

export default DeliveryTrackingInformationFactory;

import React, { FC } from 'react';
import { TextPrimary } from '../../text2/Text2';
import fontWeight from '../../utils/const/fontWeight';
import color from '../../utils/const/color';

export interface HeadLineProps {
  children: React.ReactNode;
  theme?: {
    color: string;
  };
}

export const FrontPageTitle: FC<HeadLineProps> = ({
  children,
  theme,
}: HeadLineProps) => (
  <TextPrimary
    theme={{
      fontSize: 50,
      color: (theme && theme.color) || color.primary,
      fontWeight: fontWeight.bold,
    }}
  >
    {children}
  </TextPrimary>
);

export const PageTitle: FC<HeadLineProps> = ({
  children,
  theme,
}: HeadLineProps) => (
  <TextPrimary
    theme={{
      fontSize: 33,
      color: (theme && theme.color) || color.primary,
      fontWeight: fontWeight.bold,
    }}
  >
    {children}
  </TextPrimary>
);

export const PageTitleSub: FC<HeadLineProps> = ({
  children,
  theme,
}: HeadLineProps) => (
  <TextPrimary
    theme={{
      fontSize: 33,
      color: (theme && theme.color) || color.primary,
      fontWeight: fontWeight.bold,
    }}
  >
    {children}
  </TextPrimary>
);

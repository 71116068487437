import Joi from '@hapi/joi';

// Type
import {
  CryptoCurrencyBuyTransactionProps,
  CryptoCurrencyBuyTransactionPropsFormat,
} from './CryptoCurrencyBuyTransaction.type';

// Error
import InvalidDataFoundError from '../../../../../../../utils/errors/InvalidDataFoundError';

// DomainObject
import CryptoCurrencyBuyTransaction from './CryptoCurrencyBuyTransaction';
import CryptoCurrencyTransactionId from '../../../../../../idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionId';

class CryptoCurrencyBuyTransactionFactory {
  static create = (
    cryptoCurrencyTransactionId: CryptoCurrencyTransactionId,
    props: CryptoCurrencyBuyTransactionProps,
  ) => {
    const result = Joi.object(CryptoCurrencyBuyTransactionPropsFormat).validate(
      props,
    );

    if (result.error)
      throw new InvalidDataFoundError(
        `Creation of CryptoCurrencyBuyTransaction is rejected. The reason is ${result.error.details[0].message}`,
      );

    return new CryptoCurrencyBuyTransaction(cryptoCurrencyTransactionId, props);
  };
}

export default CryptoCurrencyBuyTransactionFactory;

import React, { FC } from 'react';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import TwoFactorAuthAppSettingsTemplate from '../../../components/templates/kycRequest/TwoFactorAuthAppSettingsTemplate';

const TwoFactorAuthAppSettingsPage: FC = () => {
  return (
    <DashboardTemplate>
      <TwoFactorAuthAppSettingsTemplate />
    </DashboardTemplate>
  );
};

export default TwoFactorAuthAppSettingsPage;

import { useState, useCallback } from 'react';

// FirebaseFunctions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';
import { USER_REQUEST_EMAIL_UPDATE } from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// Type
import { useFormInputEmailEdit } from '../../../components/organisms/form/settings/FormEmailEdit/FormEmailEdit.type';

// Error
import ErrorHandler from '../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../utils/helpers/connection';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../config/constants/requestState';

export const useUpdateEmail = (id: string) => {
  const [state, setState] = useState<string>(INITIAL);
  const [newEmail, setNewEmail] = useState<string>('');
  const formInputEmailEditContext = useFormInputEmailEdit();

  // update
  const updateEmail = useCallback(
    async (emailUpdated: string, resend = false) => {
      if (!id) return;

      if (!resend) setState(IS_LOADING);

      setNewEmail(emailUpdated);

      try {
        // check online or not
        checkInternetConnection();

        // Create "update email" request
        await new FirebasePrivateFunctions().privatePOST(
          USER_REQUEST_EMAIL_UPDATE,
          {
            id,
            newEmail: emailUpdated,
          },
        );

        if (!resend) setState(SUCCESS);
      } catch (error) {
        const handler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [id],
  );

  return {
    state,
    setState,
    newEmail,
    updateEmail,
    formInputEmailEditContext,
  };
};

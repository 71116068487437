import styled from 'styled-components';
import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const ButtonGroupContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ErrorMessageContainer = styled.div`
  box-sizing: border-box;
  height: ${40 / 16}rem;
  display: flex;
  align-items: center;
  padding-left: ${(1200 - 200 - 594) / 16}rem;
`;

export const TradeAmountConversionsContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-left: ${15 / 16}rem;
  padding-right: ${15 / 16}rem;
  display: flex;
  justify-content: center;
`;

export const LabelStyle = {
  fontSize: 21,
  color: color.primary,
  fontWeight: fontWeight.medium,
  lineHeight: 42,
};

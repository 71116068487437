import React, { FC } from 'react';
import CorporateInformationViewProvider from '../../../enhancers/useCase/verification/kycRequest/CorporateInformationViewProvider';
import CorporateInformationSubmitProvider from '../../../enhancers/useCase/verification/kycRequest/KycRequestSubmitProvider/CorporateInformationSubmitProvider';

// Component
import CorporateInformationPage from './CorporateInformationPage';

const EnhancedCorporateInformationPage: FC = () => {
  return (
    <CorporateInformationViewProvider>
      <CorporateInformationSubmitProvider>
        <CorporateInformationPage />
      </CorporateInformationSubmitProvider>
    </CorporateInformationViewProvider>
  );
};

export default EnhancedCorporateInformationPage;

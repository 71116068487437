import styled from 'styled-components';
import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const ButtonGroupContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ErrorMessageContainer = styled.div`
  box-sizing: border-box;
  height: ${40 / 16}rem;
  display: flex;
  align-items: center;
  padding-left: ${(1200 - 200 - 594) / 16}rem;
`;

export const TradeAmountConversionsContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-left: ${15 / 16}rem;
  padding-right: ${15 / 16}rem;
  display: flex;
  justify-content: center;
`;

export const TransactionFeeNoticeContainer = styled.div`
  position: relative;
  text-align: left;
`;

export const PropertyListTransferDebitCardFeeContainer = styled.div`
  position: absolute;
  top: ${47 / 16}rem;
  left: ${270 / 16}rem;
  z-index: 100;
  background-color: red;
`;

export const FeeBoxContainerStyle = {
  padding: `${10 / 16}rem ${20 / 16}rem`,
  backgroundColor: color.background.ghostWhite,
  width: '44%',
};

export const LabelStyle = {
  fontSize: 21,
  color: color.primary,
  fontWeight: fontWeight.medium,
  lineHeight: 42,
};

export const SubLabelStyle = {
  fontSize: 21,
  color: color.text.zambezi,
  fontWeight: fontWeight.medium,
};

export const PendingErrorTextStyle = {
  fontSize: 21,
  color: color.red,
  fontWeight: fontWeight.bold,
};

export const FeeLabelStyle = {
  fontSize: 21,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const FeeTextStyle = {
  fontSize: 21,
  color: color.text.mischka,
};

export const AttentionTextStyle = {
  fontSize: 21,
  color: color.red,
  fontWeight: fontWeight.medium,
  lineHeight: 30,
  textAlign: 'left',
};

export const AttentionTextEmphasizeStyle = {
  fontSize: 24,
  color: color.red,
  fontWeight: fontWeight.bold,
  lineHeight: 30,
  textAlign: 'left',
};

export const DescriptionStyle = {
  fontSize: 20,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const DescriptionImpactStyle = {
  fontSize: 20,
  fontWeight: fontWeight.regular,
  color: color.text.summerSky,
  textDecoration: 'underline',
};

export const PendingErrorAreaStyle = {
  backgroundColor: color.messageBox.attention,
};

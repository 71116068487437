// IUseCase
import ITransferDebitCardCreateUseCase, {
  TransferDebitCardCreateParameter,
} from '../../../enhancers/useCase/transfer/debitCard/TransferDebitCardCreateProvider/ITransferDebitCardCreateUseCase';

// IService
import IAuthenticatorVerifyService from '../../authenticator/authenticator/interface/IAuthenticatorVerifyService';
import ITransferDebitCardTransactionV2CreateService from './ITransferDebitCardTransactionV2CreateService';

// Service
import AuthenticatorVerifyService from '../../../service/authenticator/AuthenticatorVerifyService';
import TransferDebitCardTransactionV2CreateService from '../../../service/transfer/debitCard/TransferDebitCardTransactionV2CreateService';

// IRepository
import ITransferDebitCardTransactionV2Repository from '../../../domain/transaction/transferDebitCardTransaction/ITransferDebitCardTransactionV2Repository';

// DomainObject
import TransferDebitCardTransactionV2Seed from '../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2Seed/TransferDebitCardTransactionV2Seed';
import UserVerified from '../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';

class TransferDebitCardCreateUseCase
  implements ITransferDebitCardCreateUseCase {
  private authenticatorVerifyService: IAuthenticatorVerifyService;

  private transferDebitCardTransactionV2CreateService: ITransferDebitCardTransactionV2CreateService;

  constructor(
    authenticatorVerifyService: IAuthenticatorVerifyService,
    transferDebitCardRepository: ITransferDebitCardTransactionV2Repository,
  ) {
    this.authenticatorVerifyService = new AuthenticatorVerifyService(
      authenticatorVerifyService,
    );

    this.transferDebitCardTransactionV2CreateService = new TransferDebitCardTransactionV2CreateService(
      transferDebitCardRepository,
    );
  }

  public create = async (params: TransferDebitCardCreateParameter) => {
    const transferDebitCardTransactionSeed = this.transferDebitCardTransactionV2CreateService.create(
      params,
    );

    return { transferDebitCardTransactionSeed };
  };

  public executeCreate = async (
    user: UserVerified,
    transferDebitCardTransactionSeed: TransferDebitCardTransactionV2Seed,
    code: string,
  ) => {
    // Authenticator
    await this.authenticatorVerifyService.verify(user, code);

    // Create TransferDebitCard
    const transferDebitCardTransaction = await this.transferDebitCardTransactionV2CreateService.executeCreate(
      transferDebitCardTransactionSeed,
    );

    return { transferDebitCardTransaction };
  };
}

export default TransferDebitCardCreateUseCase;

import React from 'react';

// Constant
import { INITIAL } from '../../../../../../../config/constants/requestState';

export interface DebitCardRequestCherryVisa01PayIssuanceFeeContextProps {
  state: string;
  submit: (callback?: () => void) => void;
}

const DebitCardRequestCherryVisa01PayIssuanceFeeContext = React.createContext<
  DebitCardRequestCherryVisa01PayIssuanceFeeContextProps
>({
  state: INITIAL,
  submit: () => undefined,
});

export default DebitCardRequestCherryVisa01PayIssuanceFeeContext;

import React from 'react';
import BankSpotTransactions from './BankSpotTransactions';

export interface BankSpotTransactionContextProps {
  bankSpotTransactions: BankSpotTransactions;
}

const BankSpotTransactionContext = React.createContext<
  BankSpotTransactionContextProps
>({
  bankSpotTransactions: new BankSpotTransactions(),
});

export default BankSpotTransactionContext;

import styled from 'styled-components';
import Box, { BoxProps } from './index';

export const CircleBox = styled(Box)`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.backgroundColor};
`;

export interface ProgressCircleBoxProps extends BoxProps {
  disabled: boolean;
  theme: {
    backgroundColor: string;
    backgroundColorDisabled: string;
  };
}

export const ProgressCircleBox = styled(CircleBox)`
  background-color: ${(props: ProgressCircleBoxProps) =>
    props.disabled
      ? props.theme.backgroundColorDisabled
      : props.theme.backgroundColor}};
`;

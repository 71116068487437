import React, { FC, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import AuthenticatorContext from '../../../../../domain/authenticator/authenticator/Authenticator/AuthenticatorContext';
import ReregisterContext from '../../../../../enhancers/useCase/authentication/ReregisterProvider/ReregisterContext';
import ReregistrationPageStateContext from '../../../../../enhancers/pageState/authentication/ReregistrationPageState/ReregistrationPageStateContext';
import UserReregistrationContext from '../../../../../domain/public/user/User/UserReregistration/UserReregistrationContext';

// Type
import { FormInputReregistrationTwoFactorAuth } from './FormReregistrationTwoFactorAuthInput';

// Constant
import { ErrorCodeType } from '../../../../../utils/errors/ErrorHandler';
import { IS_LOADING } from '../../../../../config/constants/requestState';

// Component
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../atoms/button/Button2';
import LabelSendIcon from '../../../../atoms/button/ButtonLabel/LabelSendIcon';
import GoogleAuthenticatorDownload from '../../twoFactorAuthAppSettings/FormTwoFactorAuthAppSettings/GoogleAuthenticatorDownload';
import AccountKeyDetail from '../../twoFactorAuthAppSettings/FormTwoFactorAuthAppSettings/AccountKeyDetail';
import FormTwoFactorAuthCode from '../../twoFactorAuthAppSettings/FormTwoFactorAuthAppSettings/FormTwoFactorAuthCode';
import FormReregistrationTwoFactorAuthLayout from './FormReregistrationTwoFactorAuthLayout';

export interface FormReregistrationTwoFactorAuthInput {
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
}

const FormReregistrationTwoFactorAuth: FC = () => {
  const { t } = useTranslation();
  const { setPageState } = useContext(ReregistrationPageStateContext);

  // Method
  const { state, reregister } = useContext(ReregisterContext);

  // Domain Object
  const { authenticator } = useContext(AuthenticatorContext);
  const { user } = useContext(UserReregistrationContext);

  /* *
   *
   *  Form
   *
   * */

  const methods = useForm<
    FormInputReregistrationTwoFactorAuth & { validCode: string }
  >({
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
    setError,
  } = methods;

  /* *
   *
   *  UseEffect
   *
   * */

  useEffect(() => {
    if (state === ErrorCodeType.AUTHENTICATION_SIGN_UP_USERNAME_EXISTED)
      setError('validCode', {
        type: '',
        message: t(`validation.${state}`),
      });
  }, [state, setError, t]);

  // Variable
  const accountName = user?.getProps().email;
  const accountKey = authenticator?.getProps().accountKey;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(
          (formInput: FormInputReregistrationTwoFactorAuth) => {
            reregister(formInput, () => {
              setPageState('complete');
            });
          },
        )}
      >
        <FormReregistrationTwoFactorAuthLayout>
          <GoogleAuthenticatorDownload key="downloadApp" />
          <AccountKeyDetail
            key="accountKey"
            accountKey={accountKey}
            accountName={accountName}
          />
          <FormTwoFactorAuthCode key="form" state={state} />
          <SubmitButton
            key="button"
            disabled={!isValid}
            height={75}
            isLoading={state === IS_LOADING}
            theme={{ borderRadius: `${10 / 16}rem` }}
            width={'100%'}
          >
            <LabelSendIcon
              iconSize={26}
              iconStyle={{ color: 'white' }}
              label={t(`atoms.button.submit`)}
              marginBetween={8}
              textStyle={DefaultButtonTextStyle}
            />
          </SubmitButton>
        </FormReregistrationTwoFactorAuthLayout>
      </form>
    </FormProvider>
  );
};

export default FormReregistrationTwoFactorAuth;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { REGISTER } from '../../../../config/constants/pageId.json';
import { FrontPageTitle } from '../../../atoms/headLine/HeadLine';
import FormRegister from '../../../organisms/form/front/FormRegister';
import RegisterTemplateLayoutLayout from './RegisterTemplateLayout';
import SignUpRequestContext from '../../../../domain/userRequest/signUpRequest/SignUpRequest/SignUpRequestContext';

const RegisterTemplate: FC = () => {
  const { t } = useTranslation();
  const { signUpRequest } = useContext(SignUpRequestContext);

  if (!signUpRequest) return <></>;

  return (
    <RegisterTemplateLayoutLayout>
      <FrontPageTitle key="title">{t(`${REGISTER}.title`)}</FrontPageTitle>
      <FormRegister key="form" />
    </RegisterTemplateLayoutLayout>
  );
};

export default RegisterTemplate;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';
import { Container } from './style';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormInputAuthenticatorLayoutProps {
  children: React.ReactElement[];
}

const FormInputAuthenticatorLayout: FC<FormInputAuthenticatorLayoutProps> = ({
  children,
}: FormInputAuthenticatorLayoutProps) => {
  const elements = ['description', 'textField'];
  const [description, textField] = getElementsFromKeys(children, elements);

  return (
    <Container>
      {description}
      <Margin top={25}>{textField}</Margin>
    </Container>
  );
};
export default FormInputAuthenticatorLayout;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ContractTimeDepositRenewalReservationPageStateContext from '../../../../../enhancers/pageState/contract/ContractTimeDepositRenewalReservationPageState/ContractTimeDepositRenewalReservationPageStateContext';

// Component
import { PageTitle } from '../../../../atoms/headLine/HeadLine';
import BreadCrumbLink from '../../../../molecules/navigation/BreadCrumbLink';
import HeaderContentTitleLayout from '../HeaderContentTitleLayout';

const ORGANISM_ID = `organisms.header.contract.HeaderContractTimeDepositRenewalReservationDelete`;

const HeaderContractTimeRenewalReservationDepositDeleteConfirm: FC = () => {
  const { t } = useTranslation();

  // PageState
  const { setPageState } = useContext(
    ContractTimeDepositRenewalReservationPageStateContext,
  );

  return (
    <HeaderContentTitleLayout>
      <PageTitle key="left">{t(`${ORGANISM_ID}.title`)}</PageTitle>
      <BreadCrumbLink
        key="right"
        onClick={() => {
          setPageState('initial');
        }}
        text={t(`atoms.button.back`)}
      />
    </HeaderContentTitleLayout>
  );
};

export default HeaderContractTimeRenewalReservationDepositDeleteConfirm;

import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const SectionTitleStyle = {
  fontSize: 25,
  color: color.text.white,
  fontWeight: fontWeight.bold,
};

export const SectionBoxStyle = {
  backgroundColor: color.background.endeavour,
  boarderRadius: `${5 / 16}rem`,
};

export const FormContainerStyle = {
  backgroundColor: color.background.white,
};

export const DescriptionStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  lineHeight: 36,
};

export const OrderIdLabelStyle = {
  fontSize: 21,
  color: color.primary,
  fontWeight: fontWeight.medium,
};

export const OrderIdStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

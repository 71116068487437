import { useState } from 'react';

// Firebase Functions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';
import { USER_REQUEST_EMAIL_UPDATE } from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// Firebase Authentication
import { reauthByToken } from '../../../infrastructure/firebase/firebaseAuthentication/firebaseAuth';

// Error
import ErrorHandler from '../../../utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from '../../../utils/errors/ErrorHandler/IErrorHandler';

// Lib
import { checkInternetConnection } from '../../../utils/helpers/connection';

// Type
import { useFormInputEmailEditConfirm } from '../../../components/organisms/form/settings/FormEmailEditConfirm/FormEmailEditConfirm.type';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../config/constants/requestState';

export const useConfirmUpdateEmail = (id: string) => {
  const [state, setState] = useState<string>(INITIAL);
  const formInputEmailEditConfirmContext = useFormInputEmailEditConfirm();

  const confirmUpdateEmail = async (code: string, isResend = false) => {
    if (!isResend) setState(IS_LOADING);

    try {
      // ---check onLine ---- //
      checkInternetConnection();

      // Update Email
      const response = await new FirebasePrivateFunctions().privatePUT(
        `${USER_REQUEST_EMAIL_UPDATE}/${id}`,
        {
          code,
        },
      );

      await reauthByToken(response.data.token);

      if (!isResend) setState(SUCCESS);
    } catch (error) {
      const handler: IErrorHandler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  return {
    state,
    setState,
    confirmUpdateEmail,
    formInputEmailEditConfirmContext,
  };
};

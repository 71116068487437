import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ImageType,
  KycExampleImageType,
} from '../../../../atoms/image/Image/Image.type';

// Style
import { DescriptionStyle } from './style';

// Component
import { TextSecondary } from '../../../../atoms/text2/Text2';
import Image from '../../../../atoms/image/Image';
import LineBreakReplaced from '../../../../atoms/text2/LineBreakReplaced';
import KycExampleImageDescriptionLayout from './KycExampleImageDescriptionLayout';

export interface KycExampleImageDescriptionCardProps {
  imageType: ImageType;
}

const ORGANISM_ID = `organisms.card.KycExampleImageDescriptionCard`;

const KycExampleImageDescriptionCard: FC<KycExampleImageDescriptionCardProps> = ({
  imageType,
}: KycExampleImageDescriptionCardProps) => {
  const { t } = useTranslation();
  const [imageWidth, imageHeight] =
    imageType === ImageType.exampleImageOK
      ? [`${282 / 16}rem`, `${282 / 16}rem`]
      : [`${200 / 16}rem`, `${200 / 16}rem`];

  const description = t(`${ORGANISM_ID}.description.${imageType}`);
  const imageSource = KycExampleImageType[imageType];

  return (
    <KycExampleImageDescriptionLayout>
      <Image
        key="image"
        src={imageSource}
        width={imageWidth}
        height={imageHeight}
      />
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced text={description} />
      </TextSecondary>
    </KycExampleImageDescriptionLayout>
  );
};

export default KycExampleImageDescriptionCard;

import React, { FC } from 'react';
import { BlueBorderButton, DisabledButton } from './Button';

export interface CancelButtonProps {
  children: React.ReactNode;
  height?: string | number;
  onClick?: () => void;
  theme?: {
    borderRadius?: string | number;
  };
  width?: string | number;
  disabled?: boolean;
}

const CancelButton: FC<CancelButtonProps> = (props: CancelButtonProps) => {
  const { children, disabled, ...buttonProps } = props;

  if (disabled)
    return (
      <DisabledButton disabled={disabled} {...buttonProps}>
        {children}
      </DisabledButton>
    );

  return <BlueBorderButton {...buttonProps}>{children}</BlueBorderButton>;
};

export default CancelButton;

import React, { FC } from 'react';
import MaintenanceImageSmall from '../../../../assets/images/maintenance_small.svg';

export interface MaintenanceSmallProps {
  width?: string | number;
  height?: string | number;
}

const MaintenanceSmallIcon: FC<MaintenanceSmallProps> = ({
  width = '100%',
  height = '100%',
}: MaintenanceSmallProps) => {
  return (
    <div>
      <img
        src={MaintenanceImageSmall}
        alt="KEY"
        width={width}
        height={height}
      />
    </div>
  );
};
export default MaintenanceSmallIcon;

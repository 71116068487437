import Joi from '@hapi/joi';

// Error
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import {
  NotificationLogCustodyFeeAndTopUpFeeProps,
  NotificationLogCustodyFeeAndTopUpFeePropsFormat,
  NotificationLogCustodyFeeAndTopUpFeeInitialProps,
} from './NotificationLogCustodyFeeAndTopUpFee.type';

// DomainObject
import NotificationLogCustodyFeeAndTopUpFee from './NotificationLogCustodyFeeAndTopUpFee';

class NotificationLogCustodyFeeAndTopUpFeeFactory {
  static create = (
    id: string,
    props: NotificationLogCustodyFeeAndTopUpFeeProps,
  ) => {
    const result = Joi.object(
      NotificationLogCustodyFeeAndTopUpFeePropsFormat,
    ).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: NotificationLogCustodyFeeAndTopUpFee.name,
          reason: result.error.details[0].message,
        },
      );

    return new NotificationLogCustodyFeeAndTopUpFee(id, props);
  };

  static createInitial = (id: string) =>
    NotificationLogCustodyFeeAndTopUpFeeFactory.create(
      id,
      NotificationLogCustodyFeeAndTopUpFeeInitialProps,
    );
}

export default NotificationLogCustodyFeeAndTopUpFeeFactory;

import Joi from '@hapi/joi';

// Type
import { DebitCardIssuerType } from '../../../../types/DebitCard.type';
import {
  DebitCardRequestProps,
  DebitCardRequestPropsFormat,
  DebitCardRequestRawDataType,
  DebitCardRequestInitialProps,
} from '../DebitCardRequest/DebitCardRequest/DebitCardRequest.type';
import { DebitCardRequestCherryVisa01StatusType } from '../../../../types/DebitCardRequestCherryVisa01.type';
import {
  CherryVisa01ProofOfIdentityRawDataType,
  CherryVisa01ProofOfIdentityRawDataTypeWithFiles,
} from '../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentity.type';
import {
  CherryVisa01ProofOfReceiptRawDataType,
  CherryVisa01ProofOfReceiptRawDataTypeWithFiles,
} from '../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfReceipt/CherryVisa01ProofOfReceipt.type';
import { DeliveryTrackingInformationRawDataType } from '../../../../value/debitCard/DebitCardRequestContent/DeliveryTrackingInformation/DeliveryTrackingInformation.type';
import { IssuedDebitCardInformationRawDataType } from '../../../../value/debitCard/DebitCardRequestContent/IssuedDebitCardInformation/IssuedDebitCardInformation.type';

// ValueObject
import DeliveryTrackingInformation from '../../../../value/debitCard/DebitCardRequestContent/DeliveryTrackingInformation/DeliveryTrackingInformation';
import IssuedDebitCardInformation from '../../../../value/debitCard/DebitCardRequestContent/IssuedDebitCardInformation/IssuedDebitCardInformation';
import CherryVisa01ProofOfIdentity from '../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentity';
import CherryVisa01ProofOfReceipt from '../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfReceipt/CherryVisa01ProofOfReceipt';

export interface DebitCardRequestCherryVisa01Props
  extends DebitCardRequestProps {
  customerId: string;

  proofOfIdentity?: CherryVisa01ProofOfIdentity;

  issuedDebitCardInformation?: IssuedDebitCardInformation;

  deliveryTrackingInformation?: DeliveryTrackingInformation;

  proofOfReceipt?: CherryVisa01ProofOfReceipt;

  status: DebitCardRequestCherryVisa01StatusType;
}

export type DebitCardRequestCherryVisa01RawDataType = DebitCardRequestRawDataType & {
  props: DebitCardRequestCherryVisa01Props & {
    customerId: string;

    status: DebitCardRequestCherryVisa01StatusType;

    proofOfIdentity?: CherryVisa01ProofOfIdentityRawDataType;

    issuedDebitCardInformation?: IssuedDebitCardInformationRawDataType;

    deliveryTrackingInformation?: DeliveryTrackingInformationRawDataType;

    proofOfReceipt?: CherryVisa01ProofOfReceiptRawDataType;
  };
};

export type DebitCardRequestCherryVisa01RawDataTypeWithProofOfIdentityFiles = DebitCardRequestRawDataType & {
  props: DebitCardRequestProps & {
    customerId: string;

    status: DebitCardRequestCherryVisa01StatusType;

    proofOfIdentity?: CherryVisa01ProofOfIdentityRawDataTypeWithFiles;
  };
};

export type DebitCardRequestCherryVisa01RawDataTypeWithProofOfReceiptFiles = DebitCardRequestRawDataType & {
  props: DebitCardRequestProps & {
    customerId: string;

    status: DebitCardRequestCherryVisa01StatusType;

    proofOfReceipt?: CherryVisa01ProofOfReceiptRawDataTypeWithFiles;
  };
};

export const DebitCardRequestCherryVisa01PropsFormat = {
  ...DebitCardRequestPropsFormat,

  status: Object.entries(DebitCardRequestCherryVisa01StatusType)
    .reduce((joi, [, value]) => joi.valid(value), Joi.string())
    .required(),

  customerId: Joi.string().required().allow(''),

  proofOfIdentity: Joi.any(),

  issuedDebitCardInformation: Joi.any(),

  deliveryTrackingInformation: Joi.any(),

  proofOfReceipt: Joi.any(),
};

export const DebitCardRequestCherryVisa01InitialProps = {
  ...DebitCardRequestInitialProps,
  customerId: '',
  issuer: DebitCardIssuerType.cherryVisa01,
  status: DebitCardRequestCherryVisa01StatusType.initial,
  isCompleted: false,
};

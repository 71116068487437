import CryptoCurrencyTradeTransactionSeed from '../../../CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed';
import CryptoCurrencyTransactionId from '../../../../../../idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionId';
import CryptoCurrencyTradeBuyTransaction from '../../CryptoCurrencyBuyTransaction/CryptoCurrencyBuyTransaction/CryptoCurrencyBuyTransaction';
import CryptoCurrencyTradeSellTransaction from '../../CryptoCurrencySellTransaction/CryptoCurrencySellTransaction/CryptoCurrencySellTransaction';
import { CryptoCurrencyTradeType } from '../../../../../../../types/CryptoCurrency.type';

class CryptoCurrencyTradeTransactionFactory {
  static createFromSeed = (
    cryptoCurrencyTradeTransactionSeed: CryptoCurrencyTradeTransactionSeed,
    newId: string,
  ) => {
    const cryptoCurrencyTransactionId = CryptoCurrencyTransactionId.createNumberFromRawId(
      newId,
    );

    const tradeType = cryptoCurrencyTradeTransactionSeed.getTradeType();

    switch (tradeType) {
      case CryptoCurrencyTradeType.buy:
        return new CryptoCurrencyTradeBuyTransaction(
          cryptoCurrencyTransactionId,
          {
            ...cryptoCurrencyTradeTransactionSeed.getProps(),
            balance: 0,
            commonId: '',
            descriptionParameter: '',
            tradeMargin: 0,
          },
        );
      case CryptoCurrencyTradeType.sell:
        return new CryptoCurrencyTradeSellTransaction(
          cryptoCurrencyTransactionId,
          {
            ...cryptoCurrencyTradeTransactionSeed.getProps(),
            balance: 0,
            commonId: '',
            descriptionParameter: '',
            tradeMargin: 0,
          },
        );
      default:
        throw new Error(`Error`);
    }
  };
}

export default CryptoCurrencyTradeTransactionFactory;

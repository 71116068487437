import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const SubTitleStyle = {
  fontSize: 29,
  color: color.text.primary,
  fontWeight: fontWeight.regular,
};

export const TextStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

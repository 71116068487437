import CryptoCurrencyWallet from '../CryptoCurrencyWallet/CryptoCurrencyWallet';
import { IIterable } from '../../../../interface/Iterator/IIterable';

abstract class CryptoCurrencyWallets
  implements IIterable<CryptoCurrencyWallet> {
  public abstract add: (cryptoCurrencyWallet: CryptoCurrencyWallet) => void;

  public abstract head: () => CryptoCurrencyWallet;

  public abstract map: <T>(
    callback: (cryptoCurrencyWallet: CryptoCurrencyWallet) => T,
  ) => T[];

  public abstract forEach: (
    callback: (cryptoCurrencyWallet: CryptoCurrencyWallet) => void,
  ) => void;

  public abstract forEachAsync: (
    callback: (cryptoCurrencyWallet: CryptoCurrencyWallet) => Promise<void>,
  ) => Promise<void>;
}

export default CryptoCurrencyWallets;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { INITIAL } from '../../../config/constants/requestState';

export interface UpdatePasswordProps {
  updatePasswordState: string;
  setUpdatePasswordState: (state: string) => void;
  updatePassword: (
    currentPassword: string,
    newPassword: string,
    code: string,
  ) => void;
}

export const UpdatePasswordContext = React.createContext<UpdatePasswordProps>({
  updatePasswordState: INITIAL,
  setUpdatePasswordState: (state: string) => undefined,
  updatePassword: (
    currentPassword: string,
    newPassword: string,
    code: string,
  ) => undefined,
});

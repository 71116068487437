// FirebaseFunctions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// axios
import { CRYPTO_CURRENCY_TRADE_CREATE } from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// IRepository
import { ICryptoCurrencyTradeCreateRepository } from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/ICryptoCurrencyTradeRepository';

// DomainObject
import CryptoCurrencyTradeTransactionSeed from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed';
import CryptoCurrencyTradeFactory from '../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactionFactory';

class CryptoCurrencyTradeFunctions
  extends FirebasePrivateFunctions
  implements ICryptoCurrencyTradeCreateRepository {
  public create = async (
    cryptoCurrencyTradeTransactionSeed: CryptoCurrencyTradeTransactionSeed,
  ) => {
    const { userId } = cryptoCurrencyTradeTransactionSeed.getProps();

    const response = await this.privatePOST(`${CRYPTO_CURRENCY_TRADE_CREATE}`, {
      id: userId,
      ...cryptoCurrencyTradeTransactionSeed.getRequestParameterCreate(),
    });

    return CryptoCurrencyTradeFactory.createFromSeed(
      cryptoCurrencyTradeTransactionSeed,
      response.data.cryptoCurrencyTradeId,
    );
  };
}
export default CryptoCurrencyTradeFunctions;

import React, { FC, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

// Context
import TransferSavingsAccountCreateContext from '../../../../../../enhancers/useCase/transfer/savingsAccount/TransferSavingsAccountCreateProvider/TransferSavingsAccountCreateContext';
import TransferSavingsAccountTransactionSeedContext from '../../../../../../domain/transaction/transferSavingsAccountTransaction/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeedContext';
import TransferSavingsAccountPageStateContext from '../../../../../../enhancers/pageState/transfer/TransferSavingsAccountPageState/TransferSavingsAccountPageStateContext';

import { FormInputAuthenticatorType } from '../../../../../molecules/form/formInput/textField/FormInputAuthenticator/FormInputAuthenticator.type';

// Constant
import { IS_LOADING } from '../../../../../../config/constants/requestState';

// Components
import FormInputAuthenticator from '../../../../../molecules/form/formInput/textField/FormInputAuthenticator';
import TransferSavingsAccountConfirmButtonGroup from '../../../../../molecules/button/ButtonGroup/TransferSavingsAccountConfirmButtonGroup';
import FormTransferSavingsAccountLayout from './FormTransferSavingsAccountLayout';

const FormTransferSavingsAccountConfirm: FC = () => {
  // PageState
  const { setPageState } = useContext(TransferSavingsAccountPageStateContext);

  // Execute Create
  const { requestState, executeCreateTransferSavingsAccount } = useContext(
    TransferSavingsAccountCreateContext,
  );

  // DomainObject
  const { transferSavingsAccountTransactionSeed } = useContext(
    TransferSavingsAccountTransactionSeedContext,
  );

  /* *
   *
   *  Form
   *
   * */
  const methods = useForm<FormInputAuthenticatorType>({
    mode: 'onChange',
  });

  const {
    formState: { isValid },
    watch,
  } = methods;

  if (!transferSavingsAccountTransactionSeed) return <></>;

  return (
    <FormProvider {...methods}>
      <FormTransferSavingsAccountLayout>
        <FormInputAuthenticator key="twoFactorAuth" state={requestState} />
        <TransferSavingsAccountConfirmButtonGroup
          key="buttonGroup"
          onClickLeft={() => {
            executeCreateTransferSavingsAccount(watch('code'), () => {
              setPageState('complete');
            });
          }}
          leftButtonDisabled={!isValid}
          isLoading={requestState === IS_LOADING}
          onClickRight={() => {
            setPageState('initial');
          }}
        />
      </FormTransferSavingsAccountLayout>
    </FormProvider>
  );
};

export default FormTransferSavingsAccountConfirm;

import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Lib
import { minusByDecimal } from '../../../../../utils/helpers/calculationHelper';

// Type
import { FiatCurrencyType } from '../../../../../types/FiatCurrency.type';

// Style
import { TableVerticalLayout, TableVerticalStyle } from './style';

// Mapper
import TableTransferDebitCardFeeMapper from './TableTransferDebitCardFeeMapper';

// Component
import TableVertical from '../../../../molecules/table/table/TableVertical';

export interface PropertyListTransferDebitCardFeeProps {
  maxAmountTotalContractTimeDeposit: number;
  totalToppedUpAmount: number;
  currency: FiatCurrencyType;
}

const PropertyListTransferDebitCardFee: FC<PropertyListTransferDebitCardFeeProps> = ({
  maxAmountTotalContractTimeDeposit,
  totalToppedUpAmount,
  currency,
}: PropertyListTransferDebitCardFeeProps) => {
  const { t } = useTranslation();

  const topUpAmountWithoutFee = useMemo(
    () =>
      Math.max(
        minusByDecimal(maxAmountTotalContractTimeDeposit, totalToppedUpAmount),
        0,
      ),
    [maxAmountTotalContractTimeDeposit, totalToppedUpAmount],
  );

  const rows = useMemo(
    () =>
      TableTransferDebitCardFeeMapper.domainToTd(
        maxAmountTotalContractTimeDeposit,
        totalToppedUpAmount,
        topUpAmountWithoutFee,
        currency,
        t,
      ),
    [
      maxAmountTotalContractTimeDeposit,
      totalToppedUpAmount,
      topUpAmountWithoutFee,
      currency,
      t,
    ],
  );

  return (
    <TableVertical
      isTableBorder
      layout={TableVerticalLayout}
      rows={rows}
      theme={TableVerticalStyle}
    />
  );
};

export default PropertyListTransferDebitCardFee;

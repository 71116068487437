// Type
import { InviterRankAType } from '../../../../types/Invite.type';
import { InviterRankAProps } from './InviterRankA.type';

// DomainObject
import Inviter from '../Inviter/Inviter';

class InviterRankA extends Inviter {
  protected rank: InviterRankAType;

  constructor(id: string, props: InviterRankAProps) {
    super(id, props);
    this.rank = props.rank;
    this.props = props;
  }

  public getProps = () => {
    return {
      ...super.getProps(),
      rank: this.rank,
    };
  };
}
export default InviterRankA;

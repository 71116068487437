import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

// Type
import { useTranslation } from 'react-i18next';
import { DebitCardRequestCherryVisa01StepStatusType } from '../../../../types/DebitCardRequestCherryVisa01.type';

// Constant
import { ROUTE_DEBIT_CARD_REQUEST_CHERRY_VISA_01_STATUS } from '../../../../config/constants/routing';

// DomainObject
import DebitCardRequestCherryVisa01 from '../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01';

// Component
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import CircleSpinLoader from '../../../atoms/spinner/CircleSpinLoader';
import DebitCardRequestStatusHeader from '../../../organisms/header/HeaderDebitCardRequestStatus';
import BreadCrumbLink from '../../../molecules/navigation/BreadCrumbLink';
import DebitCardRequestCherryVisa01StepTemplateLayout from './DebitCardRequestCherryVisa01StepTemplateLayout';

export interface DebitCardRequestCherryVisa01StepTemplateProps {
  debitCardRequestCherryVisa01?: DebitCardRequestCherryVisa01;
  children: React.ReactNode;
  title: string;
  status?: DebitCardRequestCherryVisa01StepStatusType;
  rejectedReason?: string;
}

const DebitCardRequestCherryVisa01StepTemplate: FC<DebitCardRequestCherryVisa01StepTemplateProps> = ({
  debitCardRequestCherryVisa01,
  children,
  status,
  title,
  rejectedReason,
}: DebitCardRequestCherryVisa01StepTemplateProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <DebitCardRequestCherryVisa01StepTemplateLayout
      isLoading={!debitCardRequestCherryVisa01}
    >
      <DebitCardRequestStatusHeader
        key="header"
        status={status}
        rejectedReason={rejectedReason}
      />
      <PageTitle key="title">{title}</PageTitle>
      <BreadCrumbLink
        key="link"
        onClick={() => {
          history.push(
            `${ROUTE_DEBIT_CARD_REQUEST_CHERRY_VISA_01_STATUS}/${
              debitCardRequestCherryVisa01?.getId() ?? ''
            }`,
          );
        }}
        text={t(`atoms.button.back`)}
      />
      <CircleSpinLoader key="loader" />
      <div key="childNode">{children}</div>
    </DebitCardRequestCherryVisa01StepTemplateLayout>
  );
};
export default DebitCardRequestCherryVisa01StepTemplate;

import React from 'react';
import FeeTransferSavingsAccount from './FeeTransferSavingsAccount';
import { TransferSavingsAccountType } from '../../../../types/Transfer.type';
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';

export interface FeeTransferSavingsAccountContextProps {
  feeTransferSavingsAccount?: FeeTransferSavingsAccount;
}

const FeeTransferSavingsAccountContext = React.createContext<
  FeeTransferSavingsAccountContextProps
>({
  feeTransferSavingsAccount: new FeeTransferSavingsAccount(
    TransferSavingsAccountType.forbes,
    {
      currency: FiatCurrencyType.USD,
      fee: 0,
    },
  ),
});

export default FeeTransferSavingsAccountContext;

import { RankType } from '../../../types/Invite.type';

abstract class Rank {
  protected rank: RankType;

  constructor(rank: RankType) {
    this.rank = rank;
  }

  public getRank = () => this.rank;

  abstract isRankD: () => boolean;

  abstract isRankA: () => boolean;

  abstract isRankC: () => boolean;
}
export default Rank;

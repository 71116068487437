import React, { FC, useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CryptoCurrencyTradeCreateContext from '../../../../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyTradeCreateUseCase/CryptoCurrencyTradeCreateContext';
import CryptoCurrencyTradePageStateContext from '../../../../../../enhancers/pageState/cryptoCurrency/CryptoCurrencyTradePageState/CryptoCurrencyTradePageStateContext';
import CryptoCurrencyRateContext from '../../../../../../domain/cryptoCurrency/cryptoCurrencyRate/CryptoCurrencyRate/CryptoCurrencyRate/CryptoCurrencyRateContext';
import CryptoCurrencyWalletContext from '../../../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWalletContext';
import SavingsAccount from '../../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

// Validation
import { cryptoCurrencyTradeValidation } from '../../../../../../utils/validation/registers';

// Lib
import { getAmount } from '../FormCryptoCurrencyTrade/helper';
import { commaSeparatedToNumber } from '../../../../../../utils/helpers/numberHelper';
import { convertMoneyFormat } from '../../../../../../utils/helpers/currencyHelper';
import {
  timesByDecimal,
  divideByDecimal,
} from '../../../../../../utils/helpers/calculationHelper';

// Type
import {
  CryptoCurrencyType,
  CryptoCurrencyTradeType,
} from '../../../../../../types/CryptoCurrency.type';
import { FiatCurrencyType } from '../../../../../../types/FiatCurrency.type';
import { FormInputCryptoCurrencyTradeContext } from '../FormCryptoCurrencyTrade/FormInputCryptoCurrencyTrade.type';
import CryptoCurrencyTradeLimitContext from '../../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeLimit/CryptoCurrencyTradeLimit/CryptoCurrencyTradeLimitContext';

// Style
import {
  MessageStyle,
  ButtonTextStyle,
} from '../FormCryptoCurrencyTrade/style';

// Component
import { BuyButton } from '../../../../../atoms/button/Button2';
import { TextPrimary } from '../../../../../atoms/text2/Text2';
import LabelCryptoCurrencyTrade from '../../../../../molecules/textGroup/LabelCryptoCurrencyTrade';
import FormInputTransactionAmount from '../../../../../molecules/form/formInput/textField/FormInputTransactionAmount2';
import FormCryptoCurrencyBuyLayout from './FormCryptoCurrencyBuyLayout';

const FORM_ID = `organisms.form.cryptoCurrency.FormCryptoCurrencyTrade`;

const AVAILABLE = false;

export interface FormCryptoCurrencyBuyProps {
  savingsAccount?: SavingsAccount;
}

const FormCryptoCurrencyBuy: FC<FormCryptoCurrencyBuyProps> = ({
  savingsAccount,
}: FormCryptoCurrencyBuyProps) => {
  const { t } = useTranslation();
  const { setFormInput } = useContext(FormInputCryptoCurrencyTradeContext);

  // PageState
  const { setPageState } = useContext(CryptoCurrencyTradePageStateContext);

  // ReactHookForm
  const { errors, setValue, trigger, watch } = useFormContext();

  // DomainObject
  const { cryptoCurrencyWallet } = useContext(CryptoCurrencyWalletContext);
  const { cryptoCurrencyRate } = useContext(CryptoCurrencyRateContext);
  const { cryptoCurrencyTradeLimit } = useContext(
    CryptoCurrencyTradeLimitContext,
  );

  // Method
  const { createCryptoCurrencyTrade } = useContext(
    CryptoCurrencyTradeCreateContext,
  );

  // Value
  const sellRate = useMemo(
    () => cryptoCurrencyRate?.getProps().sellRate.feeIncluded || 0,
    [cryptoCurrencyRate],
  );

  const minBuyAmount = useMemo(
    () => cryptoCurrencyTradeLimit?.getMinimumBuyPayAmount(sellRate) || 0,
    [sellRate, cryptoCurrencyTradeLimit],
  );

  // Button Disabled
  const invalidPayAmount =
    !watch(`buyPayAmount`) ||
    watch(`buyPayAmount`) <= 0 ||
    errors.buyPayAmount?.message;

  const invalidReceiveAmount =
    !watch(`buyReceiveAmount`) || watch(`buyReceiveAmount`) <= 0;

  const buttonDisabled = invalidPayAmount || invalidReceiveAmount;

  return (
    <FormCryptoCurrencyBuyLayout>
      <FormInputTransactionAmount
        key="pay"
        name="buyPayAmount"
        disabled={!AVAILABLE}
        currency={savingsAccount?.getProps().currency || FiatCurrencyType.USD}
        inputWidth={320}
        label={t(`${FORM_ID}.label.pay`)}
        onChange={(value: string) => {
          setValue('buyPayAmount', getAmount(value));

          const numValue = commaSeparatedToNumber(value);

          if (!Number.isNaN(Number(numValue)))
            setValue(
              'buyReceiveAmount',
              convertMoneyFormat(
                divideByDecimal(Number(numValue), sellRate),
                CryptoCurrencyType.BTC,
              ),
            );
        }}
        validation={cryptoCurrencyTradeValidation(t).buyPayAmount(
          savingsAccount?.getProps().balance || 0,
          minBuyAmount,
        )}
      />
      <FormInputTransactionAmount
        key="receive"
        name="buyReceiveAmount"
        disabled={!AVAILABLE}
        currency={cryptoCurrencyWallet?.getCurrency() || CryptoCurrencyType.BTC}
        inputWidth={320}
        label={t(`${FORM_ID}.label.receive`)}
        onChange={(value: string) => {
          setValue('buyReceiveAmount', getAmount(value));

          const numValue = commaSeparatedToNumber(value);

          if (!Number.isNaN(Number(numValue))) {
            setValue(
              'buyPayAmount',
              convertMoneyFormat(
                timesByDecimal(Number(numValue), sellRate),
                FiatCurrencyType.USD,
              ),
            );
            trigger(['buyPayAmount']);
          }
        }}
      />
      <TextPrimary key="message" theme={MessageStyle}>
        {t(`${FORM_ID}.message.unavailable`)}
      </TextPrimary>
      <BuyButton
        key="button"
        disabled={buttonDisabled || !AVAILABLE}
        height={75}
        onClick={() => {
          const input = {
            savingsAccountNumber: watch(`savingsAccountNumber`),
            payAmount: watch(`buyPayAmount`),
            payCurrency: FiatCurrencyType.USD,
            receiveAmount: CryptoCurrencyType.BTC,
            receiveCurrency: CryptoCurrencyType.BTC,
            rate: sellRate,
            tradeType: CryptoCurrencyTradeType.buy,
          };

          setFormInput(input);

          if (cryptoCurrencyTradeLimit)
            createCryptoCurrencyTrade(input, cryptoCurrencyTradeLimit);

          setPageState('confirm');
        }}
        width={470}
      >
        <LabelCryptoCurrencyTrade
          tradeType={CryptoCurrencyTradeType.buy}
          targetCurrency={CryptoCurrencyType.BTC}
          baseCurrency={FiatCurrencyType.USD}
          theme={ButtonTextStyle}
        />
      </BuyButton>
    </FormCryptoCurrencyBuyLayout>
  );
};

export default FormCryptoCurrencyBuy;

// Functions
import FirebasePublicFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePublicFunctions';
import { USER_REQUEST_SIGN_UP } from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// IRepository
import ISignUpRequestCreator from '../../../domain/userRequest/signUpRequest/ISignUpRequestCreator';

class SignUpRequestFunctions implements ISignUpRequestCreator {
  public create = async (email: string, invitationCode: string) => {
    await new FirebasePublicFunctions().publicPOST(USER_REQUEST_SIGN_UP, {
      email,
      invitationCode,
    });
  };
}
export default SignUpRequestFunctions;

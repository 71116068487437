import React, { FC } from 'react';

// Lib
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';

// Constants
import color from '../../../../atoms/utils/const/color';

// Style
import { TableStyle } from './style';

// LayoutComponent
import { TableBordered } from '../../../../atoms/table/Table/Table';
import Display from '../../../../atoms/div/Display';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface TableFunctionalLayoutProps {
  children: React.ReactElement[];
  pageCount: number;
  width?: number | string;
}

// eslint-disable-next-line no-empty-pattern
const TableFunctionalLayout: FC<TableFunctionalLayoutProps> = ({
  children,
  pageCount,
  width,
}: TableFunctionalLayoutProps) => {
  const elements = ['thead', 'tbody', 'pagination'];
  const [thead, tbody, pagination] = getElementsFromKeys(children, elements);

  return (
    <TableStyle>
      <TableBordered
        borderWidth={1}
        borderColor={color.border.white}
        width={width}
      >
        {thead}
        {tbody}
      </TableBordered>
      <Display isDisplay={pageCount > 1}>
        <Margin top={40} />
        {pagination}
      </Display>
    </TableStyle>
  );
};
export default TableFunctionalLayout;

import React, { FC } from 'react';
import CountdownContext from './CountdownContext';
import { useCountdown } from '../../../hooks/common/countdown';

export type CountdownProps = {
  children: React.ReactNode;
};

const Countdown: FC<CountdownProps> = ({ children }: CountdownProps) => {
  const { count, setCount } = useCountdown();

  return (
    <CountdownContext.Provider value={{ count, setCount }}>
      {children}
    </CountdownContext.Provider>
  );
};
export default Countdown;

import Joi from '@hapi/joi';
import { isValidNumber } from 'libphonenumber-js';
import { isEmpty } from 'lodash';

// Lib
import {
  commaSeparatedFormat,
  commaSeparatedToNumber,
} from 'utils/helpers/numberHelper';

// Type
import { TypeOfT } from 'types/typeof/Translation.type';
import { TransferSavingsAccountType } from 'types/Transfer.type';

// Constant
import {
  FORMAT_ECB_SAVINGS_ACCOUNT_NUMBER,
  FORMAT_SAVINGS_ACCOUNT_NUMBER,
} from 'config/constants/regularExpression';

export const required = (field: string, t: TypeOfT) => {
  return t(`validation.isNotNull`, { field: t(`words.${field}`) }) as string;
};

export const emailFormat = (t: TypeOfT) => {
  return (value: string) => {
    const result = Joi.string()
      .email({
        allowUnicode: false,
        tlds: { allow: false },
      })
      .required()
      .validate(value);

    return !result.error ? true : (t('validation.emailFormat') as string);
  };
};

export const accountNumberFormatByBank = (
  field: string,
  bank: TransferSavingsAccountType,
  t: TypeOfT,
) => {
  const validationParams =
    bank === TransferSavingsAccountType.forbes
      ? {
          regex: FORMAT_SAVINGS_ACCOUNT_NUMBER,
          length: 10,
          format: '020-XXXXXXX',
        }
      : {
          regex: FORMAT_ECB_SAVINGS_ACCOUNT_NUMBER,
          length: 13,
          format: 'XXX-XXX-XXXXXXX',
        };

  return (value: string) =>
    validationParams.regex.test(value) ||
    (t('validation.accountNumberFormat', {
      field: t(`words.${field}`),
      length: validationParams.length,
      format: validationParams.format,
    }) as string);
};

export const accountNumberFormat = (field: string, t: TypeOfT) => {
  return (value: string) =>
    /^[0-9-]*$/.test(value) ||
    (t('validation.accountNumberFormat', {
      field: t(`words.${field}`),
    }) as string);
};

export const noUpperCase = (field: string, t: TypeOfT) => {
  return (value: string) =>
    !/[A-Z]/.test(value) ||
    (t('validation.noUpperCaseCharacters', {
      field: t(`words.${field}`),
    }) as string);
};

export const minLength = (field: string, length: number, t: TypeOfT) => {
  return {
    value: Number(length),
    message: t(`validation.lengthGreaterThanEqual`, {
      field: t(`words.${field}`),
      length: t(`words.${length}`),
    }),
  };
};

export const minLengthCallback = (
  field: string,
  length: number,
  t: TypeOfT,
) => {
  return (value: string) =>
    value.length >= length ||
    (t(`validation.lengthGreaterThanEqual`, {
      field: t(`words.${field}`),
      length: t(`words.${length}`),
    }) as string);
};

export const alphaNumeric = (field: string, t: TypeOfT) => {
  return (value: string) =>
    /^[0-9a-zA-Z]*$/.test(value) ||
    (t('validation.alphaNumeric', { field: t(`words.${field}`) }) as string);
};

export const includeLowercase = (field: string, t: TypeOfT) => {
  return (value: string) =>
    /[a-z]/.test(value) ||
    (t('validation.includeLowercase', {
      field: t(`words.${field}`),
    }) as string);
};

export const includeUppercase = (field: string, t: TypeOfT) => {
  return (value: string) =>
    /[A-Z]/.test(value) ||
    (t('validation.includeUppercase', {
      field: t(`words.${field}`),
    }) as string);
};

export const includeNumber = (field: string, t: TypeOfT) => {
  return (value: string) =>
    /[0-9]/.test(value) ||
    (t('validation.includeNumber', { field: t(`words.${field}`) }) as string);
};

export const includeSpecialCharacter = (field: string, t: TypeOfT) => {
  return (value: string) =>
    /[@!#$%*()\-_+={}[\]|\\;:<>,./?]/.test(value) ||
    (t('validation.includeSpecialCharacter', {
      field: t(`words.${field}`),
    }) as string);
};

export const passwordEqualToConfirm = (confirmPassword: string, t: TypeOfT) => {
  return (value: string) =>
    value === confirmPassword ||
    (t(`validation.passwordEqualToConfirm`) as string);
};

export const onlyCharacters = (field: string, t: TypeOfT) => {
  return (value: string) =>
    /^[a-zA-Z]*$/.test(value) ||
    (t('validation.onlyCharacters', { field: t(`words.${field}`) }) as string);
};

export const onlyNumbers = (field: string, t: TypeOfT) => {
  return (value: string) =>
    /^[0-9]*$/.test(value) ||
    (t('validation.onlyNumbers', { field: t(`words.${field}`) }) as string);
};

export const validAddress = (field: string, t: TypeOfT) => {
  return (value: string) =>
    /^[#.0-9a-zA-Z\s,-_/]*$/.test(value) ||
    (t('validation.validAddress', { field: t(`words.${field}`) }) as string);
};

export const validPhoneNumber = (countryCode: string, t: TypeOfT) => {
  return (value: string) => {
    const internationalNumber = countryCode + value;

    if (isEmpty(countryCode))
      return t(`validation.countryCodeRequired`) as string;
    return (
      isValidNumber(internationalNumber) ||
      (t(`validation.validPhoneNumber`) as string)
    );
  };
};

export const min = (field: string, minimum: number, t: TypeOfT) => {
  return (value: string) =>
    Number(value.replace(/,/g, '')) >= minimum ||
    (t('validation.minimum', {
      field: t(`words.${field}`),
      minimum: commaSeparatedFormat(minimum),
    }) as string);
};

export const max = (field: string, maximum: number, t: TypeOfT) => {
  return (value: string) =>
    Number(value.replace(/,/g, '')) <= maximum ||
    (t('validation.maximum', {
      field: t(`words.${field}`),
      maximum: commaSeparatedFormat(maximum),
    }) as string);
};

export const greaterThan = (field: string, number: number, t: TypeOfT) => {
  return (value: string) =>
    Number(value.replace(/,/g, '')) > number ||
    (t('validation.greaterThan', {
      field: t(`words.${field}`),
      number: commaSeparatedFormat(number),
    }) as string);
};

export const enoughBalance = (balance: number, t: TypeOfT) => {
  return (value: string) =>
    commaSeparatedToNumber(value) <= balance ||
    (t('validation.sufficientBalance') as string);
};

export const checked = () => {
  return (value: string) => value === 'checked';
};

export const isNotMySavingsAccountNumber = (
  savingsAccountNumber: string,
  t: TypeOfT,
) => {
  return (value: string) =>
    value.replace('-', '') !== savingsAccountNumber.replace('-', '') ||
    (t('validation.isNotMySavingsAccountNumber') as string);
};

import { useCallback, useState, useEffect } from 'react';
import { checkInternetConnection } from '../../../../../utils/helpers/connection';

// Error
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';

// UseCase
import TransferSavingsAccountViewUseCase from '../../../../../useCases/transfer/savingsAccount/TransferSavingsAccountViewUseCase';

// Repository
import FeeTransferSavingsAccountFirestore from '../../../../../repository/settings/transactionFee/FeesTransferSavingsAccountFirestore';
import SavingsAccountFunctions from '../../../../../repository/bankAccount/savingsAccount/SavingsAccountFunctions';

// DomainObject
import { useSavingsAccounts } from '../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/useSavingsAccounts';
import { useUserVerified } from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/useUserVerified';
import FeeTransferSavingsAccount from '../../../../../domain/settings/transactionFee/FeeTransferSavingsAccount/FeeTransferSavingsAccount';
import UserAuthorized from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

// SelectBox
import { useSavingsAccountOption } from '../../../../../components/molecules/selectBox/option/option/SavingsAccountOption/useSavingsAccountOption';
import { useTransferSavingsAccountTypeOption } from '../../../../../components/molecules/selectBox/option/option/TransferSavingsAccountTypeOption/useTransferSavingsAccountTypeOption';

export const useTransferSavingsAccountViewUseCase = (user?: UserAuthorized) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  DomainObject
   *
   * */
  const { savingsAccounts, setSavingsAccounts } = useSavingsAccounts();
  const [feeTransferSavingsAccount, setFeeTransferSavingsAccount] = useState<
    FeeTransferSavingsAccount
  >();
  const { userVerified, setUserVerified } = useUserVerified();

  // SelectBox
  const {
    savingsAccountOption,
    setSavingsAccountOption,
  } = useSavingsAccountOption();
  const {
    transferSavingsAccountTypeOption,
    setTransferSavingsAccountTypeOption,
  } = useTransferSavingsAccountTypeOption();

  /* *
   *
   *  Repository
   *
   * */
  const savingsAccountRepository = new SavingsAccountFunctions();
  const feeTransferSavingsAccountRepository = new FeeTransferSavingsAccountFirestore();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new TransferSavingsAccountViewUseCase(
    savingsAccountRepository,
    feeTransferSavingsAccountRepository,
  );

  /* *
   *
   *  Methods
   *
   * */
  const openTransferSavingsAccount = useCallback(async () => {
    if (!user) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const output = await useCase.open(user);

      setUserVerified(output.user);
      setSavingsAccounts(output.savingsAccounts);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [user]);

  /* *
   *
   *  UseEffect
   *
   * */

  // UseCase.open
  useEffect(() => {
    let isMounted = true;

    if (isMounted && user) openTransferSavingsAccount();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [user]);

  // UseCase.selectTransferSavingsAccountType
  useEffect(() => {
    const selectTransferSavingsAccountType = async () => {
      const output = await useCase.selectTransferSavingsAccountType(
        transferSavingsAccountTypeOption,
      );
      setFeeTransferSavingsAccount(output.feeTransferSavingsAccount);
    };
    selectTransferSavingsAccountType();
    // eslint-disable-next-line
  }, [transferSavingsAccountTypeOption]);

  // Default SavingsAccount Setting
  useEffect(() => {
    setSavingsAccountOption(savingsAccounts.head());
  }, [savingsAccounts, setSavingsAccountOption]);

  return {
    state,
    setState,
    userVerified,
    savingsAccounts,
    savingsAccountOption,
    setSavingsAccountOption,
    transferSavingsAccountTypeOption,
    setTransferSavingsAccountTypeOption,
    feeTransferSavingsAccount,
  };
};

import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const HeaderTextStyle = {
  fontSize: 24,
  color: color.primary,
  fontWeight: fontWeight.bold,
  textAlign: 'left',
};

export const DataTextStyle = {
  fontSize: 22,
  color: color.primary,
  fontWeight: fontWeight.regular,
  textAlign: 'left',
};

import { useState } from 'react';

export const useDropzoneArea = () => {
  const [preview, setPreview] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [tmpFileName, setTmpFileName] = useState<string>();

  return {
    preview,
    setPreview,
    errorMessage,
    setErrorMessage,
    tmpFileName,
    setTmpFileName,
  };
};

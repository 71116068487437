import React, { FC, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useProofOfResidencyViewUseCase } from './useProofOfResidencyViewUseCase';
import DocumentTypesContext from '../../../../../domain/settings/documentType/DocumentType/DocumentTypes/DocumentTypesContext';
import KycRequestContext from '../../../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequestContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Type
import { KycStatusType } from '../../../../../types/KycRequest.type';

// Constant
import { ROUTE_KYC_STATUS } from '../../../../../config/constants/routing';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

export type ProofOfResidencyViewProviderProps = {
  children: React.ReactNode;
};

const ProofOfResidencyViewProvider: FC<ProofOfResidencyViewProviderProps> = ({
  children,
}: ProofOfResidencyViewProviderProps) => {
  const history = useHistory();

  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    kycRequest,
    documentTypes,
  } = useProofOfResidencyViewUseCase(userAuthorized);

  /* *
   *
   *  useEffect (Redirect)
   *
   * */

  // Status of ProofOfResidency
  const status = useMemo(
    () => kycRequest?.getKycStepStatus().getProps().proofOfResidency,
    [kycRequest],
  );

  // Redirect if status is invalid
  useEffect(() => {
    if (
      status &&
      ![
        KycStatusType.initial,
        KycStatusType.submitted,
        KycStatusType.rejected,
      ].includes(status)
    )
      history.push(ROUTE_KYC_STATUS);
    // eslint-disable-next-line
  }, [status]);

  return (
    <KycRequestContext.Provider value={{ kycRequest }}>
      <DocumentTypesContext.Provider value={{ documentTypes }}>
        <SystemErrorHandler state={state} setState={setState}>
          {children}
        </SystemErrorHandler>
      </DocumentTypesContext.Provider>
    </KycRequestContext.Provider>
  );
};

export default ProofOfResidencyViewProvider;

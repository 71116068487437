import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Style
import { Container } from './style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface DebitCardImageLayoutProps {
  children: React.ReactElement[];
  layout: {
    body: {
      width: string | number;
      height: string | number;
    };
    cardTypeLogo: {
      width: string | number;
      height: string | number;
    };
  };
}

const DebitCardImageLayout: FC<DebitCardImageLayoutProps> = ({
  children,
  layout,
}: DebitCardImageLayoutProps) => {
  const elements = ['number', 'type', 'validThru'];
  const [number, type, validThru] = getElementsFromKeys(children, elements);

  return (
    <Container style={{ ...layout.body }}>
      <FlexBoxColumn padding="42% 10% 0 10%">
        {number}
        <Margin top={'5%'} />
        <Margin left={10} />
        <FlexBoxRow justifyContent="flex-end">
          {validThru}
          <Margin left="5%" />
          <FlexBoxRow
            {...layout.cardTypeLogo}
            style={{ backgroundColor: 'transparent' }}
          >
            {type}
          </FlexBoxRow>
        </FlexBoxRow>
      </FlexBoxColumn>
    </Container>
  );
};
export default DebitCardImageLayout;

import React, { FC } from 'react';
import AssetSummaryPage from './AssetSummaryPage';
import AssetSummaryViewProvider from '../../../enhancers/useCase/asset/AssetSummaryViewProvider';
import CryptoCurrencyRateFetchProvider from '../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyRateFetchProvider';
import NotificationLogViewProvider from '../../../enhancers/useCase/notification/NotificationLogViewProvider';
import ImportantNoticeModalProvider from '../../../components/organisms/modal/ImportantNoticeModal/ImportantNoticeModalProvider';

const EnhancedAssetSummaryPage: FC = () => {
  return (
    <AssetSummaryViewProvider>
      <CryptoCurrencyRateFetchProvider>
        <NotificationLogViewProvider>
          <ImportantNoticeModalProvider>
            <AssetSummaryPage />
          </ImportantNoticeModalProvider>
        </NotificationLogViewProvider>
      </CryptoCurrencyRateFetchProvider>
    </AssetSummaryViewProvider>
  );
};

export default EnhancedAssetSummaryPage;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxRow } from '../../../../../../atoms/utils/layout/Box/FlexBox';

export interface FormInputSavingsAccountLayoutProps {
  children: React.ReactElement[];
  inputWidth: string | number;
}

const FormInputSavingsAccountLayout: FC<FormInputSavingsAccountLayoutProps> = ({
  children,
  inputWidth,
}: FormInputSavingsAccountLayoutProps) => {
  const elements = ['label', 'input'];
  const [label, input] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow alignItems="center" justifyContent="space-between">
      {label}
      <FlexBoxRow width={inputWidth}>{input}</FlexBoxRow>
    </FlexBoxRow>
  );
};
export default FormInputSavingsAccountLayout;

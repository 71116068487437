import React, { FC } from 'react';

import LinksSignUpContext from '../../../storage/context/LinksSignUpContext';
import { useTermsAndConditionsFile } from '../../../storage/files/useTermsAndConditionsFile';
import { usePrivacyPolicyFile } from '../../../storage/files/usePrivacyPolicyFile';

export type SignUpPageViewProviderProps = {
  children: React.ReactNode;
};

const SignUpPageViewProvider: FC<SignUpPageViewProviderProps> = ({
  children,
}: SignUpPageViewProviderProps) => {
  const { termsAndConditionsUrl } = useTermsAndConditionsFile();
  const { privacyPolicyUrl } = usePrivacyPolicyFile();

  return (
    <LinksSignUpContext.Provider
      value={{
        termsAndConditionsUrl,
        privacyPolicyUrl,
      }}
    >
      {children}
    </LinksSignUpContext.Provider>
  );
};
export default SignUpPageViewProvider;

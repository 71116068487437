/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Type
import { FormInputConsentForm } from '../../../../../components/organisms/form/verification/consentForm/FormConsentForm/FormConsentForm.type';

// Constant
import { INITIAL } from '../../../../../config/constants/requestState';

export interface ConsentFormSubmitContextProps {
  state: string;
  submitConsentForm: (
    content: FormInputConsentForm,
    callback?: () => void,
  ) => void;
}

const ConsentFormSubmitContext = React.createContext<
  ConsentFormSubmitContextProps
>({
  state: INITIAL,
  submitConsentForm: (content: FormInputConsentForm, callback?: () => void) =>
    undefined,
});

export default ConsentFormSubmitContext;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

import { FlexBoxRow } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface CheckPointItemLayoutProps {
  children: React.ReactElement[];
  marginBetween: string | number;
}

const CheckPointItemLayout: FC<CheckPointItemLayoutProps> = ({
  children,
  marginBetween,
}: CheckPointItemLayoutProps) => {
  const elements = ['icon', 'text'];
  const [icon, text] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow alignItems="center">
      {icon}
      <Margin left={marginBetween} />
      {text}
    </FlexBoxRow>
  );
};
export default CheckPointItemLayout;

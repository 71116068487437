import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import ContentBox from '../../../../atoms/utils/layout/Box/Box/ContentBox';
import CryptoCurrencyTemplateContainer from '../../common/CryptoCurrencyTemplateContainer';
import Display from '../../../../atoms/div/Display';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface CryptoCurrencyDepositWithdrawalTemplateLayoutProps {
  children: React.ReactElement[];
  isDeposit: boolean;
}

const CryptoCurrencyDepositWithdrawalTemplateLayout: FC<CryptoCurrencyDepositWithdrawalTemplateLayoutProps> = ({
  children,
  isDeposit,
}: CryptoCurrencyDepositWithdrawalTemplateLayoutProps) => {
  const elements = ['header', 'tabBar', 'deposit', 'withdrawal'];
  const [header, tabBar, deposit, withdrawal] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <CryptoCurrencyTemplateContainer>
      {header}
      <Margin top={50}>
        <ContentBox padding="100" width="100%" height="100%">
          {tabBar}
          <Margin top={30} />
          <Display isDisplay={isDeposit}>{deposit}</Display>
          <Display isDisplay={!isDeposit}>{withdrawal}</Display>
        </ContentBox>
      </Margin>
    </CryptoCurrencyTemplateContainer>
  );
};
export default CryptoCurrencyDepositWithdrawalTemplateLayout;

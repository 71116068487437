import styled from 'styled-components';
import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  display: flex;
`;

export const IconContainer = styled.div`
  display: flex;
`;

export const IconWrap = styled.div`
  width: ${187.33 / 16}rem;
  height: ${58.12 / 16}rem;
  margin-right: ${40 / 16}rem;
`;

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  lineHeight: 44,
};

import { RankType } from '../../../types/Invite.type';
import RankD from './RankD';
import RankA from './RankA';
import RankC from './RankC';

class RankFactory {
  static create = (rank: RankType) => {
    switch (rank) {
      case RankType.D01:
      case RankType.D02:
        return new RankD(rank);
      case RankType.A01:
      case RankType.A02:
        return new RankA(rank);
      case RankType.C01:
      case RankType.C02:
        return new RankC(rank);
      default:
        throw new Error(`Invalid rank '${rank}' found at RankFactory.create`);
    }
  };
}
export default RankFactory;

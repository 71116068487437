import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SignUpContext from '../../../../enhancers/useCase/authentication/SignUpProvider/SignUpContext';

// Constant
import { SIGN_UP_COMPLETE } from '../../../../config/constants/pageId.json';
import { ROUTE_SIGN_UP } from '../../../../config/constants/routing';

// Style
import {
  DescriptionStyle,
  EmailStyle,
  NavigationTextStyle,
  SwitchContainer,
} from './style';

// Component
import { FrontPageTitle } from '../../../atoms/headLine/HeadLine';
import { TextSecondary } from '../../../atoms/text2/Text2';
import Breadcrumb from '../../../molecules/link/Breadcrumb';
import SwitchResendEmailCountdown from '../../../organisms/switch/SwitchResendEmailAndCountdown';
import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';
import SignUpCompleteTemplateLayout from './SignUpCompleteTemplateLayout';

const SignUpCompleteTemplate: FC = () => {
  const { t } = useTranslation();
  const { email, resendSignUpComplete } = useContext(SignUpContext);

  return (
    <SignUpCompleteTemplateLayout>
      <FrontPageTitle key="title">
        {t(`${SIGN_UP_COMPLETE}.title`)}
      </FrontPageTitle>
      <TextSecondary key="description1" theme={DescriptionStyle}>
        <LineBreakReplaced text={t(`${SIGN_UP_COMPLETE}.description1`)} />
      </TextSecondary>
      <TextSecondary key="email" theme={EmailStyle}>
        {email}
      </TextSecondary>
      <SwitchContainer key="description2">
        <SwitchResendEmailCountdown
          key="description2"
          margin={`${16 / 16}rem`}
          onClickResend={() => resendSignUpComplete()}
        />
      </SwitchContainer>
      <Breadcrumb
        key="link"
        href={ROUTE_SIGN_UP}
        iconSize={10}
        marginBetween={13}
        text={t(`atoms.button.back`)}
        theme={{ text: NavigationTextStyle }}
      />
    </SignUpCompleteTemplateLayout>
  );
};

export default SignUpCompleteTemplate;

import React, { FC, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import CherryVisa01ProofOfIdentityFileContext from '../../../../../../enhancers/fileHandler/debitCard/debitCardRequest/ProofOfIdentityFileProvider/CherryVisa01ProofOfIdentityFileContext';
import UserAuthorizedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Type
import { FileType } from '../../../../../../types/File.type';
import { CherryVisa01ProofOfIdentityDocumentType } from '../../../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentity.type';

// Style
import { DescriptionStyle } from './style';

// Component
import { TextSecondary } from '../../../../../atoms/text2/Text2';
import CheckPointList from '../../../../list/CheckPointList';
import DropzoneArea from '../../../../../molecules/dropzone2/DropzoneArea';
import DebitCardRequestFormContainer from '../../../common/DebitCardRequestFormContainer';
import FormIdDocumentUploadLayout from './FormIdDocumentUploadLayout';
import FormInputCherryVisa01Country from '../../../../../molecules/form/formInput/selectBox/verification/FormInputCherryVisa01Country';

// ValueObject
import CherryVisa01ProofOfIdentity from '../../../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentity';

const FORM_ID = `organisms.form.debitCards.FormProofOfIdentity.idDocumentUpload`;

export interface FormIdDocumentUploadProps {
  proofOfIdentity?: CherryVisa01ProofOfIdentity;
}

const FormIdDocumentUpload: FC<FormIdDocumentUploadProps> = ({
  proofOfIdentity,
}: FormIdDocumentUploadProps) => {
  const { t } = useTranslation();
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // Form
  const { setValue, watch } = useFormContext();

  // FileHandler
  const { document1, setDocument1 } = useContext(
    CherryVisa01ProofOfIdentityFileContext,
  );
  const { document2, setDocument2 } = useContext(
    CherryVisa01ProofOfIdentityFileContext,
  );

  const defaultFilePath1 = useMemo(() => {
    const uploadedFile = proofOfIdentity?.getProps().document.document1;

    const url = uploadedFile ? URL.createObjectURL(uploadedFile) : '';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return uploadedFile && `${url}`;
  }, [proofOfIdentity]);

  const defaultFilePath2 = useMemo(() => {
    const uploadedFile = proofOfIdentity?.getProps().document.document2;

    const url = uploadedFile ? URL.createObjectURL(uploadedFile) : '';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return uploadedFile && `${url}`;
  }, [proofOfIdentity]);

  /* *
   *
   *  UseEffect
   *
   * */

  // Set documentUrl1 when File is uploaded
  useEffect(() => {
    if (document1 && userAuthorized) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const uploadedFilePath = (document1 as any).path;
      setValue('documentUrl1', uploadedFilePath);
    }
    // eslint-disable-next-line
    }, [document1, userAuthorized]);

  // Set documentUrl2 when File is uploaded
  useEffect(() => {
    if (document2 && userAuthorized) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const uploadedFilePath = (document2 as any).path;
      setValue('documentUrl2', uploadedFilePath);
    }
    // eslint-disable-next-line
    }, [document1, userAuthorized]);

  return (
    <DebitCardRequestFormContainer order={3} formId={FORM_ID}>
      <FormIdDocumentUploadLayout
        isDisplaySecondDropzone={
          watch('documentType') ===
          CherryVisa01ProofOfIdentityDocumentType.drivingLicense
        }
      >
        <TextSecondary key="description" theme={DescriptionStyle}>
          {t(`${FORM_ID}.description`)}
        </TextSecondary>
        <DropzoneArea
          key="dropzone1"
          name="document1"
          urlName="documentUrl1"
          acceptFileTypes={[FileType.png, FileType.jpeg]}
          defaultFilePath={defaultFilePath1 ?? ''}
          maxFileSize={2}
          setFile={setDocument1}
          width={476}
        />
        <DropzoneArea
          key="dropzone2"
          name="document2"
          urlName="documentUrl2"
          acceptFileTypes={[FileType.png, FileType.jpeg]}
          defaultFilePath={defaultFilePath2 ?? ''}
          maxFileSize={2}
          setFile={setDocument2}
          width={476}
        />
        <CheckPointList
          key="checkPoint"
          title={t(`organisms.CheckPointList.title`)}
          checkPoints={[
            t(`organisms.CheckPointList.text.complete`),
            t(`organisms.CheckPointList.text.clear`),
            t(`organisms.CheckPointList.text.valid`),
          ]}
        />
        <FormInputCherryVisa01Country
          key="issuingCountry"
          labelWidth={200}
          label={t(`${FORM_ID}.issuingCountry.label`)}
          inputWidth={600}
          name="issuingCountry"
        />
      </FormIdDocumentUploadLayout>
    </DebitCardRequestFormContainer>
  );
};

export default FormIdDocumentUpload;

// IService
import ICryptoCurrencySellTransactionQueryService from '../../../../useCases/transaction/cryptoCurrencyTransaction/cryptoCurrencySellTransaction/ICryptoCurrencySellTransactionQueryService';

// IRepository
import ICryptoCurrencySellTransactionRepository from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencySellTransaction/ICryptoCurrencySellTransactionRepository';

// DomainObject
import CryptoCurrencyWallet from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet';

class CryptoCurrencySellTransactionQueryService
  implements ICryptoCurrencySellTransactionQueryService {
  private cryptoCurrencySellTransactionRepository: ICryptoCurrencySellTransactionRepository;

  constructor(
    cryptoCurrencySellTransactionRepository: ICryptoCurrencySellTransactionRepository,
  ) {
    this.cryptoCurrencySellTransactionRepository = cryptoCurrencySellTransactionRepository;
  }

  public findByWallet = async (cryptoCurrencyWallet: CryptoCurrencyWallet) => {
    const address = cryptoCurrencyWallet.getAddress();

    const { currency } = cryptoCurrencyWallet.getProps();

    const cryptoCurrencySellTransactions = await this.cryptoCurrencySellTransactionRepository.findAllByFields(
      {
        address,
        currency,
      },
    );

    return cryptoCurrencySellTransactions;
  };
}

export default CryptoCurrencySellTransactionQueryService;

import React, { FC, useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import { UpdatePasswordContext } from '../../../../../enhancers/settings/UpdatePasswordProvider/UpdatePasswordContext';
import {
  passwordEditCurrentPasswordRegister,
  passwordEditNewPasswordRegister,
  passwordEditConfirmPasswordRegister,
} from '../../../../../utils/validation/registers';

import { deleteFullWidth } from '../../../../../utils/helpers/stringHelper';
import { isValid } from './helper';

import { PasswordEditFormInput } from './PasswordEditForm.type';
import { FormInputAuthenticatorType } from '../../../../molecules/form/formInput/textField/FormInputAuthenticator/FormInputAuthenticator.type';
import { UserStatusType } from '../../../../../types/User.type';
import { PASSWORD_EDIT } from '../../../../../config/constants/pageId.json';
import { IS_LOADING } from '../../../../../config/constants/requestState';
import { ErrorCodeType } from '../../../../../utils/errors/ErrorHandler/ErrorCode.type';
import {
  Container,
  LabelGroup,
  LabelContainer,
  FormInputContainer,
  FormInputGroup,
  TextFieldRow,
  ButtonContainer,
} from './style';
import Display from '../../../../atoms/div/Display';
import { LabelFormHorizontal } from '../../../../atoms/text/DomainText';
import { SettingsTextField } from '../../../../molecules/form/TextField';
import FormInputAuthenticator from '../../../../molecules/form/formInput/textField/FormInputAuthenticator';
import { SubmitButton } from '../../../../atoms/button/DomainButton';

const FORM_ID = `${PASSWORD_EDIT}.form`;

const PasswordEditForm: FC = () => {
  const { t } = useTranslation();
  const { updatePasswordState, updatePassword } = useContext(
    UpdatePasswordContext,
  );
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const [isTwoFactor, setIsTwoFactor] = useState<boolean>(true);

  const methods = useForm<
    PasswordEditFormInput &
      FormInputAuthenticatorType & { wrongPassword: string }
  >({
    mode: 'onChange',
  });

  const {
    clearErrors,
    errors,
    handleSubmit,
    register,
    setError,
    setValue,
    trigger,
    watch,
  } = methods;

  useEffect(() => {
    if (updatePasswordState === ErrorCodeType.FIREBASE_AUTH_WRONG_PASSWORD) {
      const errorCode = ErrorCodeType.FIREBASE_AUTH_WRONG_PASSWORD;
      setError('wrongPassword', {
        type: errorCode,
        message: t(`validation.${errorCode}`),
      });
    }
  }, [updatePasswordState, setError, t]);

  useEffect(() => {
    if (userAuthorized?.getProps().status) {
      const isUnverified =
        [UserStatusType.initial].indexOf(userAuthorized.getProps().status) > -1;
      setIsTwoFactor(!isUnverified);
      setValue('code', isUnverified ? 'validCode' : '');
    }
  }, [userAuthorized, setValue, setIsTwoFactor, clearErrors]);

  return (
    <form
      onSubmit={handleSubmit(() => {
        updatePassword(
          watch(`currentPassword`),
          watch(`newPassword`),
          watch(`code`),
        );
      })}
    >
      <Container>
        <FormInputContainer>
          <LabelGroup>
            <div>
              <LabelContainer>
                <LabelFormHorizontal>
                  {t(`${FORM_ID}.currentPassword.label`)}
                </LabelFormHorizontal>
              </LabelContainer>
            </div>
            <div style={{ marginTop: `${40 / 16}rem` }}>
              <LabelContainer>
                <LabelFormHorizontal>
                  {t(`${FORM_ID}.newPassword.label`)}
                </LabelFormHorizontal>
              </LabelContainer>
            </div>
            <div style={{ marginTop: `${40 / 16}rem` }}>
              <LabelContainer>
                <LabelFormHorizontal>
                  {t(`${FORM_ID}.confirmPassword.label`)}
                </LabelFormHorizontal>
              </LabelContainer>
            </div>
          </LabelGroup>
          <FormInputGroup>
            <TextFieldRow>
              <SettingsTextField
                name="currentPassword"
                value={watch(`currentPassword`)}
                type="password"
                errorMessage={
                  (errors.currentPassword && errors.currentPassword.message) ||
                  (errors.wrongPassword && errors.wrongPassword.message)
                }
                onChange={(text: string) => {
                  if (errors.wrongPassword) clearErrors('wrongPassword');
                  setValue('currentPassword', deleteFullWidth(text));
                }}
                maxLength={20}
                register={register(passwordEditCurrentPasswordRegister(t))}
                textInputWidth={`${400 / 16}rem`}
              />
            </TextFieldRow>
            <TextFieldRow>
              <SettingsTextField
                name="newPassword"
                value={watch(`newPassword`)}
                type="password"
                errorMessage={errors.newPassword && errors.newPassword.message}
                onChange={(text: string) => {
                  trigger(['newPassword', 'confirmPassword']);
                  if (errors.wrongPassword) clearErrors('wrongPassword');
                  setValue('newPassword', deleteFullWidth(text));
                }}
                maxLength={20}
                register={register(passwordEditNewPasswordRegister(t))}
                textInputWidth={`${400 / 16}rem`}
              />
            </TextFieldRow>
            <TextFieldRow>
              <SettingsTextField
                name="confirmPassword"
                value={watch(`confirmPassword`)}
                type="password"
                errorMessage={
                  errors.confirmPassword && errors.confirmPassword.message
                }
                onChange={(text: string) => {
                  trigger(['newPassword', 'confirmPassword']);
                  if (errors.wrongPassword) clearErrors('wrongPassword');
                  setValue('confirmPassword', deleteFullWidth(text));
                }}
                maxLength={20}
                register={register(
                  passwordEditConfirmPasswordRegister(t, watch('newPassword')),
                )}
                textInputWidth={`${400 / 16}rem`}
              />
            </TextFieldRow>
          </FormInputGroup>
        </FormInputContainer>
        <Display isDisplay={isTwoFactor}>
          <div style={{ margin: `${20 / 16}rem` }}>
            <FormProvider {...methods}>
              <FormInputAuthenticator state={updatePasswordState} />
            </FormProvider>
          </div>
        </Display>
        <div style={{ marginTop: `${30 / 16}rem` }}>
          <ButtonContainer>
            <SubmitButton
              name={t(`atoms.button.update`)}
              width={`${470 / 16}rem `}
              height={`${74 / 16}rem `}
              borderRadius={`${10 / 16}rem `}
              disabled={!isValid(errors, watch)}
              isLoading={updatePasswordState === IS_LOADING}
            />
          </ButtonContainer>
        </div>
      </Container>
    </form>
  );
};

export default PasswordEditForm;

import React from 'react';

// DomainObject
import DebitCardRequestCherryVisa01 from './DebitCardRequestCherryVisa01';

export interface DebitCardRequestCherryVisa01ContextProps {
  debitCardRequestCherryVisa01?: DebitCardRequestCherryVisa01;
}

const DebitCardRequestCherryVisa01Context = React.createContext<
  DebitCardRequestCherryVisa01ContextProps
>({
  debitCardRequestCherryVisa01: undefined,
});

export default DebitCardRequestCherryVisa01Context;

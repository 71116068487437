import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AssetSavingsAccountContext from '../../../../../domain/asset/assetSavingsAccount/AssetSavingsAccount/AssetSavingsAccountContext';

// Style
import { CardLayout } from './style';

// Component
import AssetFiatCurrencyCard from '../../../../molecules/card/asset/AssetFiatCurrencyCard';

const AssetSavingsAccountCard: FC = () => {
  const { t } = useTranslation();
  const { assetSavingsAccount } = useContext(AssetSavingsAccountContext);

  const props = assetSavingsAccount?.getProps();

  return (
    <AssetFiatCurrencyCard
      // eslint-disable-next-line react/prop-types
      balance={props && props.single.balance}
      label={t(`atoms.label.balance`)}
      title={t(`organisms.AssetCard.savingsAccount`)}
      layout={CardLayout}
    />
  );
};

export default AssetSavingsAccountCard;

import React, { FC } from 'react';

// Component
import HeaderCryptoCurrencyTrade from '../../../../organisms/header/HeaderContentTitle/cryptoCurrency/HeaderCryptoCurrencyTrade';
import FormCryptoCurrencyTrade from '../../../../organisms/form/cryptoCurrency/cryptoCurrencyTrade/FormCryptoCurrencyTrade';
import CryptoCurrencyTradeTemplateLayout from './CryptoCurrencyTradeTemplateLayout';

const CryptoCurrencyTradeTemplate: FC = () => {
  return (
    <CryptoCurrencyTradeTemplateLayout>
      <HeaderCryptoCurrencyTrade key="header" />
      <FormCryptoCurrencyTrade key="form" />
    </CryptoCurrencyTradeTemplateLayout>
  );
};

export default CryptoCurrencyTradeTemplate;

import Joi from '@hapi/joi';

// Type
import { KycStatusType } from '../../../../../types/KycRequest.type';

export interface KycRequestContentProps {
  status: KycStatusType;

  rejectedReason?: string;

  updatedAt?: number;
}

export const KycRequestContentPropsFormat = {
  status: Object.keys(KycStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),

  rejectedReason: Joi.string().allow('').allow(null),

  updatedAt: Joi.number().empty(),
};

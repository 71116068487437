// Error
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import {
  DebitCardIssuerType,
  DebitCardType,
} from '../../../../../types/DebitCard.type';
import { DebitCardRequestProps } from './DebitCardRequest.type';

// Constant
import { ROUTE_DEBIT_CARD_REQUEST_CHERRY_VISA_01_STATUS } from '../../../../../config/constants/routing';

class DebitCardRequest {
  protected id: string;

  protected type: DebitCardType;

  protected savingsAccountNumber: string;

  protected issuer: DebitCardIssuerType;

  protected isCompleted: boolean;

  protected userId: string;

  protected createdAt: number;

  protected updatedAt: number;

  protected props: DebitCardRequestProps;

  constructor(id: string, props: DebitCardRequestProps) {
    this.id = id;
    this.type = props.type;
    this.savingsAccountNumber = props.savingsAccountNumber;
    this.isCompleted = props.isCompleted;
    this.issuer = props.issuer;
    this.userId = props.userId;
    this.createdAt = props.createdAt ?? Date.now();
    this.updatedAt = props.updatedAt ?? Date.now();
    this.props = props;
  }

  public getId = () => this.id;

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public getStatusPageUrl() {
    switch (this.issuer) {
      case DebitCardIssuerType.cherryVisa01:
        return `${ROUTE_DEBIT_CARD_REQUEST_CHERRY_VISA_01_STATUS}/${this.id}`;
      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_SWITCH,
          {
            value: this.issuer,
            place: `DebitCardRequest.getStatusPageUrl`,
          },
        );
    }
  }

  public updateId = (id: string) => new DebitCardRequest(id, this.getProps());
}
export default DebitCardRequest;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { CryptoCurrencyTradePageState } from './useCryptoCurrencyTradePageState';

export interface CryptoCurrencyTradePageStateContextProps {
  pageState: CryptoCurrencyTradePageState;
  setPageState: (state: CryptoCurrencyTradePageState) => void;
}

const CryptoCurrencyTradePageStateContext = React.createContext<
  CryptoCurrencyTradePageStateContextProps
>({
  pageState: 'initial',
  setPageState: (state: CryptoCurrencyTradePageState) => undefined,
});

export default CryptoCurrencyTradePageStateContext;

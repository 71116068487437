import React, { FC } from 'react';
import { throttle } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledButton, ButtonDisabled } from './style';

export interface ButtonProps {
  children: React.ReactNode;
  width?: string;
  height?: string;
  background?: string;
  backgroundColorDefault?: string;
  backgroundColorDisabled?: string;
  backgroundColorLoading?: string;
  borderColorDefault?: string;
  borderColorDisabled?: string;
  borderColorLoading?: string;
  textColorDisabled?: string;
  boxShadow?: string;
  borderRadius?: string;
  sizeProgress?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: (e?: React.MouseEvent) => void;
}
const Button: FC<ButtonProps> = ({
  children,
  width = '100%',
  height = '100%',
  background = `transparent linear-gradient(180deg, #30a3ea 0%, #005f9a 100%) 0% 0% no-repeat padding-box`,
  backgroundColorDefault,
  backgroundColorDisabled,
  backgroundColorLoading = '#77B6DD',
  borderColorDefault = '#30a3ea',
  borderColorDisabled,
  borderColorLoading = '#77B6DD',
  borderRadius = '0rem',
  sizeProgress = `${30 / 16}rem`,
  boxShadow = 'none',
  disabled = false,
  isLoading = false,
  onClick = () => undefined,
}: ButtonProps) => {
  if (disabled)
    return (
      <ButtonDisabled
        disabled={disabled}
        style={{
          width,
          height,
          backgroundColor: backgroundColorDisabled,
          border: `${1 / 16}rem solid ${borderColorDisabled}`,
          borderRadius,
        }}
      >
        {children}
      </ButtonDisabled>
    );

  let backgroundStyle = background;

  if (backgroundColorDefault) backgroundStyle = `${backgroundColorDefault}`;

  if (isLoading) backgroundStyle = `${backgroundColorLoading}`;

  const [borderColor, clickAction] = isLoading
    ? [borderColorLoading, () => undefined]
    : [borderColorDefault, onClick];

  const throttledClickAction = throttle((e) => clickAction(e), 1500);

  return (
    <StyledButton
      onClick={throttledClickAction}
      disabled={isLoading}
      style={{
        width,
        height,
        borderRadius,
        background: backgroundStyle,
        borderColor,
        boxShadow,
      }}
    >
      {(() => {
        if (isLoading)
          return <CircularProgress size={sizeProgress} color="primary" />;
        return <>{children}</>;
      })()}
    </StyledButton>
  );
};

export default Button;

import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ConfirmUpdateEmailContext from '../../../../../enhancers/settings/ConfirmUpdateEmailProvider/ConfirmUpdateEmailContext';

// Error
import { ErrorCodeType } from '../../../../../utils/errors/ErrorHandler/ErrorCode.type';

// Lib
import { emailEditConfirmRegister } from '../../../../../utils/validation/registers';
import { deleteFullWidth } from '../../../../../utils/helpers/stringHelper';

// Type
import {
  FormInputEmailEditConfirm,
  FormInputEmailEditConfirmContext,
} from './FormEmailEditConfirm.type';

// Constant
import { EMAIL_EDIT_CONFIRM } from '../../../../../config/constants/pageId.json';
import { IS_LOADING } from '../../../../../config/constants/requestState';

// Style
import { Container, Content, ButtonContainer } from './style';

// Component
import { DescriptionSettings } from '../../../../molecules/text/DomainDescription';
import { SettingsTextField } from '../../../../molecules/form/TextField';
import { SubmitButton } from '../../../../atoms/button/DomainButton';

const FORM_ID = `${EMAIL_EDIT_CONFIRM}.form`;

const FormEmailEditConfirm: FC = () => {
  const { t } = useTranslation();
  const { formInput, setFormInput } = useContext(
    FormInputEmailEditConfirmContext,
  );

  const { confirmUpdateEmailState, confirmUpdateEmail } = useContext(
    ConfirmUpdateEmailContext,
  );

  const methods = useForm<FormInputEmailEditConfirm & { validCode: string }>({
    mode: 'onChange',
  });

  const {
    clearErrors,
    errors,
    formState: { isValid },
    handleSubmit,
    setError,
    setValue,
    register,
    reset,
    watch,
  } = methods;

  /* *
   *
   *  Default Variable
   *
   * */

  // Set DefaultValue
  useEffect(() => {
    if (formInput) {
      reset(formInput);
    }
    // eslint-disable-next-line
  }, [formInput, confirmUpdateEmailState]);

  /* *
   *
   *  UseEffect
   *
   * */

  useEffect(() => {
    if (
      confirmUpdateEmailState ===
      ErrorCodeType.SETTINGS_EMAIL_UPDATE_INVALID_OTP
    ) {
      const errorCode = ErrorCodeType.SETTINGS_EMAIL_UPDATE_INVALID_OTP;
      setError('validCode', {
        type: '',
        message: t(`validation.${errorCode}`),
      });
    }
  }, [confirmUpdateEmailState, setError, t]);

  return (
    <Container>
      <form
        onSubmit={handleSubmit((input: FormInputEmailEditConfirm) => {
          setFormInput(input);
          confirmUpdateEmail(input.code);
        })}
      >
        <Content>
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <DescriptionSettings lines={[t(`${FORM_ID}.description.line1`)]} />
          </div>
          <div
            style={{
              marginTop: `${25 / 16}rem`,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <SettingsTextField
              name="code"
              value={watch(`code`)}
              errorMessage={errors?.code?.message || errors?.validCode?.message}
              onChange={(text: string) => {
                clearErrors('validCode');
                setValue('code', deleteFullWidth(text));
              }}
              register={register(emailEditConfirmRegister('code', t))}
              maxLength={255}
              textInputWidth={`${400 / 16}rem`}
            />
          </div>
        </Content>
        <div style={{ marginTop: `${50 / 16}rem` }}>
          <ButtonContainer>
            <SubmitButton
              name={t(`atoms.button.submit`)}
              width={`${470 / 16}rem `}
              height={`${74 / 16}rem `}
              borderRadius={`${10 / 16}rem `}
              disabled={!isValid}
              isLoading={confirmUpdateEmailState === IS_LOADING}
            />
          </ButtonContainer>
        </div>
      </form>
    </Container>
  );
};
export default FormEmailEditConfirm;

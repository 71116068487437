import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const HeadLineStyle = {
  fontSize: 28,
  color: color.text.primary,
  fontWeight: fontWeight.bold,
};

export const ContainerRejectedStyle = {
  backgroundColor: color.background.monaLisa,
};

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SignInPageStateContext from '../../../../enhancers/pageState/authentication/SignInPageState/SignInPageStateContext';

// Constant
import { TWO_FACTOR_AUTH } from '../../../../config/constants/pageId.json';

// Style
import { DescriptionStyle, NavigationTextStyle } from './style';

// Component
import AuthPageLanguageSelectorHeader from '../../../organisms/header/HeaderAuthPageLanguageSelector';
import { TextSecondary } from '../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';
import FormSignInTwoFactorAuth from '../../../organisms/form/front/FormSignInTwoFactorAuth';
import Breadcrumb from '../../../molecules/link/Breadcrumb';
import CopyRightFooter from '../../../organisms/footer/CopyRightFooter';
import SignInTwoFactorAuthTemplateLayout from './SignInTwoFactorAuthTemplateLayout';

const SignInTwoFactorAuthTemplate: FC = () => {
  const { t } = useTranslation();
  const { setPageState } = useContext(SignInPageStateContext);

  return (
    <SignInTwoFactorAuthTemplateLayout>
      <AuthPageLanguageSelectorHeader key="header" />
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced text={t(`${TWO_FACTOR_AUTH}.description`)} />
      </TextSecondary>
      <FormSignInTwoFactorAuth key="form" />
      <Breadcrumb
        key="link"
        onClick={() => setPageState('initial')}
        iconSize={10}
        marginBetween={13}
        text={t(`${TWO_FACTOR_AUTH}.link.back`)}
        theme={{ text: NavigationTextStyle }}
      />
      <CopyRightFooter key="copyright" />
    </SignInTwoFactorAuthTemplateLayout>
  );
};

export default SignInTwoFactorAuthTemplate;

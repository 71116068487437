import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CryptoCurrencyOptionContext from '../../../../molecules/selectBox/option/option/CryptoCurrencyOption/CryptoCurrencyOptionContext';
import CryptoCurrencyWalletContext from '../../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWalletContext';

// Constant
import { CRYPTO_CURRENCY_DEPOSIT_WITHDRAWAL } from '../../../../../config/constants/pageId.json';

// Style
import {
  DescriptionContainer,
  BalanceStyle,
  CurrencyStyle,
  DescriptionStyle,
  LabelStyle,
} from './style';

// Component
import { TextSecondary } from '../../../../atoms/text2/Text2';
import CryptoCurrencySelectBox from '../../../../molecules/selectBox/selectBox/cryptoCurrency/CryptoCurrencySelectBox';
import CryptoCurrencyAddressQRCodeCard from '../../../../molecules/card/cryptoCurrency/CryptoCurrencyAddressQRCodeCard';
import LabeledCurrencyAmount from '../../../../molecules/textGroup/LabeledCurrencyAmount';
import LineBreakReplaced from '../../../../atoms/text2/LineBreakReplaced';
import CryptoCurrencyDepositTemplateLayout from './CryptoCurrencyDepositTemplateLayout';

const TEMPLATE_ID = `${CRYPTO_CURRENCY_DEPOSIT_WITHDRAWAL}.deposit`;

const CryptoCurrencyDepositTemplate: FC = () => {
  const { t } = useTranslation();
  const { cryptoCurrencyOption } = useContext(CryptoCurrencyOptionContext);
  const { cryptoCurrencyWallet } = useContext(CryptoCurrencyWalletContext);

  if (!cryptoCurrencyWallet) return <></>;

  // Domain -> ViewValues
  const address = cryptoCurrencyWallet.getAddress();
  const { currency, balance } = cryptoCurrencyWallet.getProps();

  return (
    <CryptoCurrencyDepositTemplateLayout>
      <CryptoCurrencySelectBox key="selectBox" />
      <LabeledCurrencyAmount
        key="balance"
        amount={balance}
        currency={currency}
        direction="row"
        isSymbol
        label={t(`atoms.label.balance`)}
        marginBetween={20}
        theme={{
          amount: BalanceStyle,
          currency: CurrencyStyle,
          label: LabelStyle,
        }}
      />
      <CryptoCurrencyAddressQRCodeCard
        key="qrcode"
        cryptoCurrencyType={cryptoCurrencyOption}
        address={address}
      />
      <DescriptionContainer key="description">
        <TextSecondary theme={DescriptionStyle}>
          <LineBreakReplaced
            text={t(`${TEMPLATE_ID}.description.beforePayment`)}
          />
        </TextSecondary>
      </DescriptionContainer>
    </CryptoCurrencyDepositTemplateLayout>
  );
};

export default CryptoCurrencyDepositTemplate;

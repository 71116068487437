import { omit } from 'lodash';

// Lib
import {
  addByDecimal,
  timesByDecimal,
  divideByDecimal,
} from '../../../../utils/helpers/calculationHelper';
import {
  getDaysDifference,
  getDaysDifferenceFromToday,
} from '../../../../utils/helpers/datetimeHelper';

// Type
import { ContractTimeDepositProps } from './ContractTimeDeposit.type';
import { ContractTimeDepositStatusType } from '../../../../types/ContractTimeDeposit.type';
import { TypeOfT } from '../../../../types/typeof/Translation.type';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// DomainObject
import SavingsAccount from '../../../bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

// ValueObject
import ContractTimeDepositRenewalReservation from '../../../../value/contract/contractTimeDeposit/ContractTimeDepositRenewalReservation/ContractTimeDepositRenewalReservation';
import ContractTimeDepositRenewalReservationFactory from '../../../../value/contract/contractTimeDeposit/ContractTimeDepositRenewalReservation/ContractTimeDepositRenewalReservationFactory';
import SavingsAccountNumber from '../../../../value/id/SavingsAccountNumber';
import SerialNumberContractTimeDeposit from '../../../idManager/productSerialNumber/ProductSerialNumber/SerialNumber/concreteSerialNumber/SerialNumberContractTimeDeposit';
import TimeZonedTime from '../../../../value/datetime/TimeZonedTime';
import TimeZonedTimeFactory from '../../../../value/datetime/TimeZonedTimeFactory';

class ContractTimeDeposit {
  protected serialNumber: SerialNumberContractTimeDeposit;

  protected savingsAccountNumber: SavingsAccountNumber;

  protected renewalReservation?: ContractTimeDepositRenewalReservation;

  protected depositDate: TimeZonedTime; // YYYY/MM/DD

  protected maturityDate: TimeZonedTime; // YYYY/MM/DD

  protected createdAt: number;

  protected updatedAt: number;

  protected props: ContractTimeDepositProps;

  constructor(
    serialNumber: SerialNumberContractTimeDeposit,
    props: ContractTimeDepositProps,
  ) {
    this.serialNumber = serialNumber;
    this.savingsAccountNumber = new SavingsAccountNumber(
      props.savingsAccountNumber,
    );
    this.renewalReservation =
      props.renewalReservation &&
      ContractTimeDepositRenewalReservationFactory.create(
        props.renewalReservation,
      );
    this.depositDate = TimeZonedTimeFactory.createZeroOClock(props.depositDate);
    this.maturityDate = TimeZonedTimeFactory.createZeroOClock(
      props.maturityDate,
    );
    this.createdAt = props.createdAt ?? Date.now();
    this.updatedAt = props.updatedAt ?? Date.now();
    this.props = props;
  }

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  /**
   *
   *  SerialNumber
   *
   */
  public getRawId = () => this.serialNumber.getRawId();

  public getFormattedSerialNumber = () => this.serialNumber.getFormattedId();

  public getSerialNumber = () => this.serialNumber;

  /**
   *
   *  SavingsAccountNumber
   *
   */
  public isSavingsAccount = (savingsAccount: SavingsAccount) =>
    savingsAccount.getRawAccountNumber() ===
    this.savingsAccountNumber.getAccountNumber();

  public getSavingsAccountNumber = () =>
    this.savingsAccountNumber.getAccountNumber();

  public getFormattedSavingsAccountNumber = () =>
    this.savingsAccountNumber.getFormattedId();

  /**
   *
   *  Interest
   *
   */

  public calculateTotalInterest = () => {
    const { depositAmount, interestRate, term } = this.props;

    return timesByDecimal(
      timesByDecimal(depositAmount, divideByDecimal(interestRate, 100)),
      divideByDecimal(term, 12),
    );
  };

  /**
   *
   *  Term to days
   *
   */
  public getTotalContractDays = () => {
    const { depositDate, maturityDate } = this.props;

    return getDaysDifference(depositDate, maturityDate);
  };

  /**
   *
   *  Deposit Date
   *
   */

  public getDepositDateFormatted = (format: string) =>
    this.depositDate.format(format);

  public getOperatedDays = () =>
    getDaysDifferenceFromToday(this.props.depositDate) * -1;

  public getProgressRate = () => {
    const contractDays = this.getTotalContractDays();
    const operatedDays = this.getOperatedDays();

    return (operatedDays / contractDays) * 100;
  };

  /**
   *
   *  Maturity Date
   *
   */
  public getRemainingDays = () => this.maturityDate.diffFromToday();

  public getRemainingDaysWithUnit = (t: TypeOfT) => {
    const remainingDays = this.getRemainingDays();

    return remainingDays.day >= 365
      ? t(`timeDeposit.remainingDays.yearMonth`, {
          year: remainingDays.year,
          month: remainingDays.month % 12,
        })
      : t(`timeDeposit.remainingDays.day`, { day: remainingDays.day });
  };

  public getMaturityDateFormatted = (format: string) =>
    this.maturityDate.format(format);

  public isMaturedWithInHours = (hours: number) => {
    const diff = this.maturityDate.diffTimestampFromCurrentTime(); // millSeconds

    return diff < 60 * 60 * 1000 * hours; // 1 millSeconds * 1000 * 60 sec * 60 min = 1hour
  };

  /**
   *
   *  Status
   *
   */

  public isStatus = (status: ContractTimeDepositStatusType) =>
    this.props.status === status;

  /**
   *
   *  Renewal Reservation
   *
   */
  public hasRenewalReservation = () => !!this.renewalReservation;

  public getPrincipalAfterMatured = () => {
    const amountIncreased = this.renewalReservation?.getProps().amountIncreased;

    return amountIncreased
      ? addByDecimal(this.props.depositAmount, amountIncreased)
      : this.props.depositAmount;
  };

  public getTermAfterMatured = () => {
    const termAfterMatured = this.renewalReservation?.getProps().term;

    return termAfterMatured || this.props.term;
  };

  public getIsAnnualPayOutAfterMatured = () => {
    const isAnnualPayOutAfterMatured = this.renewalReservation?.getProps()
      .isAnnualPayOut;

    return isAnnualPayOutAfterMatured ?? this.props.isAnnualPayOut;
  };

  public createRenewalReservation = (
    term: number,
    amountIncreased: number,
    isAnnualPayOut: boolean,
  ) => {
    if (this.renewalReservation) {
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_ALREADY_EXISTS,
        {
          id: this.getRawId(),
        },
      );
    }

    return new ContractTimeDeposit(this.serialNumber, {
      ...this.getProps(),
      renewalReservation: {
        term,
        amountIncreased,
        isAnnualPayOut,
      },
    });
  };

  public updateRenewalReservation = (
    term: number,
    amountIncreased: number,
    isAnnualPayOut: boolean,
  ) => {
    if (!this.renewalReservation) {
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_NOT_EXISTS,
        {
          id: this.getRawId(),
        },
      );
    }

    return new ContractTimeDeposit(this.serialNumber, {
      ...this.getProps(),
      renewalReservation: {
        term: term ?? this.renewalReservation.getProps().term,
        amountIncreased:
          amountIncreased ?? this.renewalReservation.getProps().amountIncreased,
        isAnnualPayOut:
          isAnnualPayOut === undefined
            ? this.renewalReservation.getProps().isAnnualPayOut
            : isAnnualPayOut,
      },
    });
  };

  public deleteRenewalReservation = () => {
    if (!this.renewalReservation) {
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_NOT_EXISTS,
        {
          id: this.getRawId(),
        },
      );
    }

    const updatedProps = omit(this.getProps(), ['renewalReservation']);

    return new ContractTimeDeposit(this.serialNumber, updatedProps);
  };
}

export default ContractTimeDeposit;

// React
import { useCallback, useState, useEffect } from 'react';

// ErrorHandler
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../../utils/helpers/connection';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';

// UseCase
import MaintenanceUseCase from '../../../../../useCases/settings/maintenance/MaintenanceUseCase';

// Repository
import MaintenanceFirestore from '../../../../../repository/settings/maintenance/MaintenanceFirestore';

// DomainObject
import { useMaintenance } from '../../../../../domain/settings/maintenance/Maintenance/Maintenance/useMaintenance';

export const useMaintenanceUseCase = () => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  DomainObject
   *
   * */
  const { maintenance, setMaintenance } = useMaintenance();

  /* *
   *
   *  Repository
   *
   * */
  const maintenanceRepository = new MaintenanceFirestore();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new MaintenanceUseCase(maintenanceRepository);

  /* *
   *
   *  Methods
   *
   * */
  const openPage = useCallback(async () => {
    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // Setters
      const setters = {
        maintenance: setMaintenance,
      };

      // Execute
      await useCase.open(setters, setState);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, []);

  /* *
   *
   *  UseEffect
   *
   * */

  // UseCase.open
  useEffect(() => {
    let isMounted = true;

    if (isMounted) openPage();

    return () => {
      useCase.executeUnsubscribe();
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  return {
    state,
    setState,
    maintenance,
  };
};

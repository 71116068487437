import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useConsentFormViewUseCase } from './useConsentFormViewUseCase';
import ConsentFormContext from '../../../../../domain/verification/consentForm/ConsentForm/ConsentFormContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import LinkConsentFormDownloadFileProvider from '../../../../fileHandler/verification/consentForm/LinkConsentFormDownloadFileProvider';

// Type
import { KycStatusType } from '../../../../../types/KycRequest.type';

// Constant
import { ROUTE_KYC_STATUS } from '../../../../../config/constants/routing';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

export type ConsentFormViewProviderProps = {
  children: React.ReactNode;
};

const ConsentFormViewProvider: FC<ConsentFormViewProviderProps> = ({
  children,
}: ConsentFormViewProviderProps) => {
  const history = useHistory();

  const { userAuthorized } = useContext(UserAuthorizedContext);

  const { state, setState, consentForm } = useConsentFormViewUseCase(
    userAuthorized,
  );

  /* *
   *
   *  useEffect (Redirect)
   *
   * */

  // Status of ConsentForm
  const status = useMemo(
    () => consentForm?.getConsentFormStepsStatus().getProps().statusConsentForm,
    [consentForm],
  );

  // Redirect if status is invalid
  useEffect(() => {
    if (
      status &&
      ![
        KycStatusType.initial,
        KycStatusType.submitted,
        KycStatusType.rejected,
      ].includes(status)
    )
      history.push(ROUTE_KYC_STATUS);
    // eslint-disable-next-line
  }, [status]);

  return (
    <ConsentFormContext.Provider value={{ consentForm }}>
      <LinkConsentFormDownloadFileProvider>
        <SystemErrorHandler state={state} setState={setState}>
          {children}
        </SystemErrorHandler>
      </LinkConsentFormDownloadFileProvider>
    </ConsentFormContext.Provider>
  );
};

export default ConsentFormViewProvider;

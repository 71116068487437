// React
import { useState, useCallback } from 'react';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';
import { executeWithTimeout } from '../../../../utils/helpers/timeout';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';
import { REQUEST_TIMEOUT } from '../../../../config/constants/business';

// UseCase
import ResetAuthenticatorUseCase from '../../../../useCases/userRequest/resetAuthenticatorRequest/ResetAuthenticatorUseCase';

// Repository
import ResetAuthenticatorRequestFunctions from '../../../../repository/userRequest/resetAuthenticatorRequest/ResetAuthenticatorFunctions';

export const useResetAuthenticatorUseCase = (oobCode: string) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  Repository
   *
   * */
  const resetAuthenticatorRequestRepository = new ResetAuthenticatorRequestFunctions();

  /* *
   *
   *  UseCase
   *
   * */

  const useCase = new ResetAuthenticatorUseCase(
    resetAuthenticatorRequestRepository,
  );

  /* *
   *
   *  Method
   *
   * */
  const resetAuthenticator = useCallback(
    async (formInput: { code: string }, id: string, callback?: () => void) => {
      if (!oobCode) return;

      setState(IS_LOADING);

      const { code } = formInput;

      try {
        checkInternetConnection();

        await executeWithTimeout(
          useCase.resetAuthenticator(id, oobCode, code),
          REQUEST_TIMEOUT,
        );

        if (callback) callback();

        setState(SUCCESS);
      } catch (error) {
        const handler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [oobCode],
  );

  return {
    state,
    setState,
    resetAuthenticator,
  };
};

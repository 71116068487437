import React, { FC } from 'react';
import { DomainTextProps } from '../DomainText.type';
import fontColor from '../../../../../fonts/color.json';
import { TextPrimaryBold28 } from '../../TextPrimary';

const TitleNotification: FC<DomainTextProps> = ({
  children,
  color = fontColor.pageTitle,
}: DomainTextProps) => (
  <TextPrimaryBold28 color={color}>{children}</TextPrimaryBold28>
);
export default TitleNotification;

import React, { FC } from 'react';
import InvitationsPage from './InvitationsPage';
// import InvitationCodeViewProvider from '../../../enhancers/useCase/invite/InvitationCodesViewProvider';

const EnhancedInvitationCodesPage: FC = () => {
  return (
    // <InvitationCodeViewProvider>
    <InvitationsPage />
    // </InvitationCodeViewProvider>
  );
};

export default EnhancedInvitationCodesPage;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { Container } from './style';

export interface SavingsAccountsTemplateLayoutProps {
  children: React.ReactElement[];
}

const SavingsAccountsTemplateLayout: FC<SavingsAccountsTemplateLayoutProps> = ({
  children,
}: SavingsAccountsTemplateLayoutProps) => {
  const elements = ['title', 'cardList'];
  const [title, cardList] = getElementsFromKeys(children, elements);

  return (
    <Container>
      <div>{title}</div>
      <div style={{ marginTop: `${50 / 16}rem` }}>{cardList}</div>
    </Container>
  );
};
export default SavingsAccountsTemplateLayout;

// Types
import { CurrencyPairType } from '../../types/CurrencyPair.type';
import { UseStateType } from '../../types/typeof/UseState';

// IService
import ICryptoCurrencyTradeFeeRateQueryService from '../../useCases/settings/ICryptoCurrencyTradeFeeRateQueryService';

// IRepository
import ICryptoCurrencyTradeFeeRateRepository from '../../domain/settings/cryptoCurrencyTradeFeeRate/ICryptoCurrencyTradeFeeRateRepository';

// DomainObject
import CryptoCurrencyTradeFeeRate from '../../domain/settings/cryptoCurrencyTradeFeeRate/CryptoCurrencyTradeFeeRate/CryptoCurrencyTradeFeeRate';

class CryptoCurrencyTradeFeeRateQueryService
  implements ICryptoCurrencyTradeFeeRateQueryService {
  private cryptoCurrencyTradeFeeRateRepository: ICryptoCurrencyTradeFeeRateRepository;

  constructor(
    cryptoCurrencyTradeFeeRateRepository: ICryptoCurrencyTradeFeeRateRepository,
  ) {
    this.cryptoCurrencyTradeFeeRateRepository = cryptoCurrencyTradeFeeRateRepository;
  }

  public findById = async (
    currencyPair: CurrencyPairType,
    setter: UseStateType<CryptoCurrencyTradeFeeRate | undefined>,
    setState: UseStateType<string>,
  ) => {
    await this.cryptoCurrencyTradeFeeRateRepository.subscribeById(
      currencyPair as string,
      setter,
      setState,
    );
  };
}

export default CryptoCurrencyTradeFeeRateQueryService;

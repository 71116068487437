export enum CryptoCurrencyType {
  BTC = 'BTC',
  LTCT = 'LTCT',
  USDT = 'USDT',
}

export type CryptoCurrencyPropsType = {
  name: string;
  nameLowerCase: string;
  symbol: string;
  precision: number;
  qrCodePrefix: string;
  repositoryInterface: string;
  explorerUrl: string;
};

export const CryptoCurrencyProps: { [key: string]: CryptoCurrencyPropsType } = {
  [CryptoCurrencyType.BTC]: {
    name: 'Bitcoin',
    nameLowerCase: 'bitcoin',
    symbol: 'BTC',
    precision: 6,
    qrCodePrefix: 'bitcoin:',
    repositoryInterface: 'IBitcoinWalletRepository',
    explorerUrl: 'https://bitpay.com/insight/#/BTC/mainnet/tx/',
  },
  [CryptoCurrencyType.LTCT]: {
    name: 'LiteCoin Testnet',
    nameLowerCase: 'test litecoin',
    symbol: 'LTCT',
    precision: 6,
    qrCodePrefix: '',
    repositoryInterface: 'ILitecoinTestnetWalletRepository',
    explorerUrl: 'https://testnet.litecore.io/tx/',
  },
  [CryptoCurrencyType.USDT]: {
    name: 'USD Tether',
    nameLowerCase: 'usd tether',
    symbol: 'USDT',
    precision: 2,
    qrCodePrefix: '',
    repositoryInterface: 'IUSDTWalletRepository',
    explorerUrl: 'none',
  },
};

export enum CryptoCurrencyTradeType {
  sell = 'sell',
  buy = 'buy',
}

export const isCrypto = (currency: string) => {
  const cryptos = Object.keys(CryptoCurrencyType);
  return cryptos.indexOf(currency) > -1;
};

import React, { FC, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import SignUpRequestContext from '../../../../domain/userRequest/signUpRequest/SignUpRequest/SignUpRequestContext';
import RegisterContext from './RegisterContext';

// Type
import { GlobalStateType } from '../../../../redux/store';

// Constant
import { ErrorCodeType } from '../../../../utils/errors/ErrorHandler/ErrorCode.type';
import {
  ROUTE_AUTH_ERROR,
  ROUTE_TOP_PAGE_UNVERIFIED_USER,
} from '../../../../config/constants/routing';
import { SUCCESS } from '../../../../config/constants/requestState';

// Hook
import { useRegisterUseCase } from './useRegisterUseCase';

// Component
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type RegisterProviderProps = {
  children: React.ReactNode;
};

const RegisterProvider: FC<RegisterProviderProps> = ({
  children,
}: RegisterProviderProps) => {
  const { signUpRequest } = useContext(SignUpRequestContext);
  const { state, setState, registerUser } = useRegisterUseCase(signUpRequest);
  const history = useHistory();

  const signInUser = useSelector(
    (globalState: GlobalStateType) => globalState.signInUserStore,
  );

  // 401 Error Redirect
  useEffect(() => {
    const redirectState = [
      ErrorCodeType.AUTHENTICATION_SIGN_UP_REQUEST_NOT_FOUND,
      ErrorCodeType.AUTHENTICATION_SIGN_UP_REQUEST_EXPIRED,
      ErrorCodeType.AUTHENTICATION_SIGN_UP_INVALID_INVITER,
    ];
    if (redirectState.indexOf(state as ErrorCodeType) > -1)
      history.replace(ROUTE_AUTH_ERROR);
  }, [state, history]);

  // SUCCESS
  useEffect(() => {
    if (!isEmpty(signInUser.id) && state === SUCCESS)
      history.replace(ROUTE_TOP_PAGE_UNVERIFIED_USER);
  }, [signInUser, history, state]);

  return (
    <RegisterContext.Provider value={{ registerUser, state }}>
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </RegisterContext.Provider>
  );
};
export default RegisterProvider;

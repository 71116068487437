// Collection
import CorporateAddressDocumentTypesCollection from '../../../infrastructure/firebase/firestore/collections/settings/documentType/address/CorporateAddressDocumentTypesCollection';
import IndividualAddressDocumentTypesCollection from '../../../infrastructure/firebase/firestore/collections/settings/documentType/address/IndividualAddressDocumentTypesCollection';

// Type
import { DocumentTypeProps } from '../../../domain/settings/documentType/DocumentType/DocumentType/DocumentType.type';

// IRepository
import IDocumentTypeRepository from '../../../domain/settings/documentType/DocumentType/IDocumentTypeRepository';

// DomainObject
import DocumentTypeFactory from '../../../domain/settings/documentType/DocumentType/DocumentType/DocumentTypeFactory';
import DocumentTypes from '../../../domain/settings/documentType/DocumentType/DocumentTypes/DocumentTypes';

class DocumentTypeFirestore implements IDocumentTypeRepository {
  public findCorporateAll = async () => {
    const corporateAddressDocumentTypesCollection = new CorporateAddressDocumentTypesCollection();

    const docs = await corporateAddressDocumentTypesCollection.fetchAll();

    return docs.reduce((objs, doc) => {
      objs.add(DocumentTypeFactory.create(doc.data() as DocumentTypeProps));
      return objs;
    }, new DocumentTypes());
  };

  public findIndividualAll = async () => {
    const individualAddressDocumentTypesCollection = new IndividualAddressDocumentTypesCollection();

    const docs = await individualAddressDocumentTypesCollection.fetchAll();

    return docs.reduce((objs, doc) => {
      objs.add(DocumentTypeFactory.create(doc.data() as DocumentTypeProps));
      return objs;
    }, new DocumentTypes());
  };
}
export default DocumentTypeFirestore;

// Type
import { UseStateType } from '../../../types/typeof/UseState';

// IUseCase
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import IInvitationCodesViewUseCase, {
  SettersInvitationCodesView,
} from '../../../enhancers/useCase/invite/InvitationCodesViewProvider/IInvitationCodesViewUseCase';

// IService
import IInvitationCodeQueryService from './IInvitationCodeQueryService';

// Service
import InvitationCodeQueryService from '../../../service/invite/invitationCode/InvitationCodeQueryService';

// IRepository
import { IInvitationCodeQueryRepository } from '../../../domain/invite/invitationCode/IInvitationCodeRepository';

// DomainObject
import UserVerifiedFactory from '../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedFactory';

class InvitationCodesViewUseCase implements IInvitationCodesViewUseCase {
  private invitationCodeQueryService: IInvitationCodeQueryService;

  constructor(invitationCodeRepository: IInvitationCodeQueryRepository) {
    this.invitationCodeQueryService = new InvitationCodeQueryService(
      invitationCodeRepository,
    );
  }

  public open = async (
    user: UserAuthorized,
    setters: SettersInvitationCodesView,
    setState: UseStateType<string>,
  ) => {
    const userVerified = UserVerifiedFactory.createByUserAuthorized(user);

    await this.invitationCodeQueryService.setByUser(
      userVerified,
      setters.invitationCodes,
      setState,
    );

    setters.userVerified(userVerified);
  };
}
export default InvitationCodesViewUseCase;

import Joi from '@hapi/joi';
import { UserRequestStatusType } from '../../../../types/UserRequest.type';
import { RankType } from '../../../../types/Invite.type';

export interface SignUpRequestProps {
  code: string;

  expiredAt: number;

  inviterId: string;

  oobCode: string;

  rank: RankType;

  status: UserRequestStatusType;

  createdAt?: number;

  updatedAt?: number;
}

export const SignUpRequestPropsFormat = {
  email: Joi.string()
    .email({
      allowUnicode: false,
      tlds: { allow: false },
    })
    .required(),

  code: Joi.string().min(1).required(),

  expiredAt: Joi.number().min(0).required(),

  inviterId: Joi.string().min(1).required(),

  oobCode: Joi.string().min(1).required(),

  rank: Object.entries(RankType)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .reduce((joi, [key, value]) => joi.valid(value), Joi.string())
    .required(),

  status: Object.keys(UserRequestStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

import React, { FC } from 'react';

// Component
import KycRequestTemplate from '../../../components/templates/kycRequest/KycRequestTemplate';

const KycRequestPage: FC = () => {
  return <KycRequestTemplate />;
};

export default KycRequestPage;

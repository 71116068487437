import React from 'react';
import { INITIAL } from '../../../config/constants/requestState';

export interface UpdateEmailProps {
  updateEmailState: string;
  setUpdateEmailState: (state: string) => void;
  updateEmail: (newEmail: string, isResend?: boolean) => void;
  newEmail: string;
}

const UpdateEmailContext = React.createContext<UpdateEmailProps>({
  updateEmailState: INITIAL,
  setUpdateEmailState: () => undefined,
  updateEmail: () => undefined,
  newEmail: '',
});

export default UpdateEmailContext;

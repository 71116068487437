import React from 'react';
import ResignUpRequest from './ResignUpRequest';

export interface ResignUpRequestContextProps {
  resignUpRequest?: ResignUpRequest;
}

const ResignUpRequestContext = React.createContext<ResignUpRequestContextProps>(
  {
    resignUpRequest: undefined,
  },
);

export default ResignUpRequestContext;

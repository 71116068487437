import React, { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { throttle } from 'lodash';
import { GradationBlueButton, DisabledButton } from './Button';

export interface SellButtonProps {
  children: React.ReactNode;
  // name: string;
  width?: string | number;
  height?: string | number;
  sizeProgress?: number;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: (e?: React.MouseEvent) => void;
}

const SellButton: FC<SellButtonProps> = ({
  children,
  // name,
  width = '100%',
  height = '100%',
  sizeProgress = 30,
  disabled = false,
  isLoading = false,
  onClick = () => undefined,
}: SellButtonProps) => {
  if (disabled)
    return (
      <DisabledButton disabled={disabled} width={width} height={height}>
        {children}
      </DisabledButton>
    );

  const throttledOnClick = throttle((e) => onClick(e), 1500);

  return (
    <GradationBlueButton
      width={width}
      height={height}
      onClick={throttledOnClick}
      disabled={isLoading}
    >
      {(() => {
        if (isLoading)
          return <CircularProgress size={sizeProgress} color="primary" />;
        return <>{children}</>;
      })()}
    </GradationBlueButton>
  );
};

export default SellButton;

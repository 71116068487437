// IUseCase
import IConsentFormSubmitUseCase from '../../../enhancers/useCase/verification/consentForm/ConsentFormSubmitProvider/IConsentFormSubmitUseCase';

// IService
import IConsentFormUpdateService from './IConsentFormUpdateService';
import IStorageService from '../../storage/IStorageService';

// Service
import ConsentFormUpdateService from '../../../service/verification/consentForm/ConsentFormUpdateService';
import StorageService from '../../../service/storage/StorageService';

// IRepository
import { IConsentFormUpdateRepository } from '../../../domain/verification/consentForm/IConsentFormRepository';
import IStorageHandler from '../../../storage/IStorageHandler';

// DomainObject
import ConsentForm from '../../../domain/verification/consentForm/ConsentForm/ConsentForm';

class ConsentFormSubmitUseCase implements IConsentFormSubmitUseCase {
  private consentFormUpdateService: IConsentFormUpdateService;

  private storageService: IStorageService;

  constructor(
    consentFormRepository: IConsentFormUpdateRepository,
    storageHandler: IStorageHandler,
  ) {
    this.consentFormUpdateService = new ConsentFormUpdateService(
      consentFormRepository,
    );

    this.storageService = new StorageService(storageHandler);
  }

  public submitConsentForm = async (
    consentForm: ConsentForm,
    file: File | undefined,
  ) => {
    if (file) {
      const url = consentForm.getConsentFormContent().getProps().documentUrl;

      await this.storageService.upload(url, file);
    }

    await this.consentFormUpdateService.updateConsentForm(consentForm);

    return consentForm;
  };
}

export default ConsentFormSubmitUseCase;

// Type
import { ReferralTimeDepositByChildrenProps } from './ReferralTimeDepositByChildren.type';

// DomainObject
import ReferralTimeDepositByRankA from '../../referralTimeDepositByRankA/ReferralTimeDepositByRankA/ReferralTimeDepositByRankA';

class ReferralTimeDepositByChildren extends ReferralTimeDepositByRankA {
  constructor(id: string, props: ReferralTimeDepositByChildrenProps) {
    super(id, {
      ...props,
      // topLevelInviterSavingsAccountNumber: '0209999999',
      commissionTotal: 0,
      commissionPaid: 0,
      countOfMaturity: 0,
    });
  }
}

export default ReferralTimeDepositByChildren;

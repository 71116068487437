import React from 'react';
import { useModal } from 'react-modal-hook';

// Component
import EnhancedSignInTimeOutModal from './EnhancedSignInTimeOutModal'; // EnhancedSignInTimeOutModalProps,

export const useSignInTimeOutModal = (
  remainingTime: number | undefined,
  refreshSignInTimeOut: () => void,
  signOut: () => Promise<void>,
) => {
  const [showModal, hideModal] = useModal(() => {
    if (!remainingTime) return <></>;

    return (
      <EnhancedSignInTimeOutModal
        remainingTime={remainingTime}
        refreshSignInTimeOut={refreshSignInTimeOut}
        signOut={signOut}
        showSignInTimeOutModal={showModal}
        hideSignInTimeOutModal={hideModal}
      />
    );
  }, [remainingTime, refreshSignInTimeOut]);

  return {
    showSignInTimeOutModal: showModal,
    hideSignInTimeOutModal: hideModal,
  };
};

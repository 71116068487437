import { useState } from 'react';

// Domain Object
import DebitCardRequests from './DebitCardRequests';

export const useDebitCardRequests = (initial?: DebitCardRequests) => {
  const [debitCardRequests, setDebitCardRequests] = useState<DebitCardRequests>(
    initial ?? new DebitCardRequests(),
  );

  return {
    debitCardRequests,
    setDebitCardRequests,
  };
};

import Joi from '@hapi/joi';

// Type
import { BankFutureDepositType } from '../../../../../../../types/BankAccountTransaction.type';
import {
  BankFutureDepositProps,
  BankFutureDepositPropsFormat,
} from '../../bankFutureDeposit/BankFutureDeposit/BankFutureDeposit.type';

export type BankFutureCommissionTimeDepositProps = BankFutureDepositProps;

export const BankFutureCommissionTimeDepositPropsFormat = {
  ...BankFutureDepositPropsFormat,

  bankFutureDepositType: Joi.string()
    .valid(BankFutureDepositType.futureCommissionTimeDeposit)
    .required(),
};

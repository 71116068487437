import React from 'react';
import CryptoCurrencyTransactions from './CryptoCurrencyTransactions';

export interface CryptoCurrencyTransactionContextProps {
  cryptoCurrencyTransactions: CryptoCurrencyTransactions;
}

const CryptoCurrencyTradeTransactionsContext = React.createContext<
  CryptoCurrencyTransactionContextProps
>({
  cryptoCurrencyTransactions: new CryptoCurrencyTransactions(),
});

export default CryptoCurrencyTradeTransactionsContext;

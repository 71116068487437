import styled from 'styled-components';
import { convertRem } from '../../utils/helper/helper';

interface TableProps {
  width?: string | number;
}

const Table = styled.table`
  width: ${(props: TableProps) =>
    props.width ? convertRem(props.width) : '100%'};
`;

interface TableBorderedProps {
  borderWidth: number;
  borderColor: string;
}

export const TableBordered = styled(Table)<TableBorderedProps>`
  border: ${(props: TableBorderedProps) =>
    `${props.borderWidth / 16}rem solid ${props.borderColor}}`};
  table-layout: fixed;
  border-spacing: 0;
`;

export default Table;

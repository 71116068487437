// IService
import IConsentFormQueryService from '../../../useCases/verification/consentForm/IConsentFormQueryService';

// IRepository
import { IConsentFormQueryRepository } from '../../../domain/verification/consentForm/IConsentFormRepository';

class ConsentFormQueryService implements IConsentFormQueryService {
  private consentFormQueryRepository: IConsentFormQueryRepository;

  constructor(consentFormQueryRepository: IConsentFormQueryRepository) {
    this.consentFormQueryRepository = consentFormQueryRepository;
  }

  public findById = async (id: string) => {
    const consentForm = await this.consentFormQueryRepository.findById(id);

    return consentForm;
  };
}

export default ConsentFormQueryService;

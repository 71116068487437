// React
import React, { FC } from 'react';

// Type
import { TransferDebitCardType } from '../../../../../types/Transfer.type';

// Components
import { FlexBoxRow } from '../../../../atoms/utils/layout/Box/FlexBox';
import TransferDebitCardIconGroup from '../../../image/TransferDebitCardIconGroup';

export interface DebitCardTransferTypeTdBoxProps {
  type: TransferDebitCardType;
}

const DebitCardTransferTypeTdBox: FC<DebitCardTransferTypeTdBoxProps> = ({
  type,
}: DebitCardTransferTypeTdBoxProps) => {
  return (
    <FlexBoxRow justifyContent="center" alignItems="center">
      <TransferDebitCardIconGroup transferDebitCardType={type} />
    </FlexBoxRow>
  );
};

export default DebitCardTransferTypeTdBox;

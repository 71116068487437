import TransactionId from '../../../idManager/transactionId/TransactionId/TransactionId';

abstract class Transaction {
  protected transactionId: TransactionId;

  constructor(transactionId: TransactionId) {
    this.transactionId = transactionId;
  }

  public getFormattedId = () => this.transactionId.getFormattedId();

  public getRawId = () => this.transactionId.getRawId();
}

export default Transaction;

import storage from './storage';

export const getStorageFiles = async (path: string) => {
  /* *
   *
   *  Method
   *
   * */
  const pathReference = storage.ref(path);

  const references = await pathReference.listAll();

  const files = await Promise.all(
    references.items.map(async (item) => {
      const url = await item.getDownloadURL();

      return {
        fileName: item.name,
        url,
      };
    }),
  );

  return files;
};

import Joi from '@hapi/joi';

// Type
import { InviterRankAType, RankType } from '../../../../types/Invite.type';
import { InviterProps, InviterPropsFormat } from '../Inviter/Inviter.type';

export interface InviterRankAProps extends InviterProps {
  rank: InviterRankAType;
}

export const InviterRankAPropsFormat = {
  ...InviterPropsFormat,

  rank: Joi.valid(RankType.A01).valid(RankType.A02),
};

// React
import React, { FC } from 'react';

// Type
import { BankFutureTransactionStatusType } from '../../../../../types/BankAccountTransaction.type';

// Layout
import { FlexBoxColumn } from '../../../../atoms/utils/layout/Box/FlexBox';

// Components
import BankFutureTransactionStatusBadge from '../../../badge/BankFutureTransactionStatusBadge';

export interface BankFutureTransactionStatusTdBoxProps {
  status: BankFutureTransactionStatusType;
}

const BankFutureTransactionStatusTdBox: FC<BankFutureTransactionStatusTdBoxProps> = ({
  status,
}: BankFutureTransactionStatusTdBoxProps) => {
  return (
    <FlexBoxColumn alignItems="center" justifyContent="center">
      <BankFutureTransactionStatusBadge status={status} />
    </FlexBoxColumn>
  );
};

export default BankFutureTransactionStatusTdBox;

import React, { FC } from 'react';
import styled from 'styled-components';
import fontColor from '../../../../fonts/color.json';
import {
  light,
  regular,
  medium,
  semibold,
  bold,
} from '../../../../fonts/fontWeight.json';

export interface LatoProps {
  children: React.ReactNode;
  size?: number | string;
  color?: string;
  fontWeight?: number;
  textDecoration?: string;
  lineHeight?: string;
  letterSpacing?: number | string;
}

const LatoSpan = styled.span`
  font-family: ${(props) => {
    if (props.theme.fontWeight === light) return 'LatoLight';
    if (props.theme.fontWeight === regular) return 'LatoRegular';
    if (props.theme.fontWeight === medium) return 'LatoMedium';
    if (props.theme.fontWeight === semibold) return 'LatoSemibold';
    return 'Lato';
  }};
  font-weight: ${(props) => (props.theme.fontWeight === bold ? bold : regular)};
  font-size: ${(props) => props.theme.size};
  color: ${(props) => props.theme.color};
  text-decoration: ${(props) => props.theme.textDecoration};
  letter-spacing: ${(props) => props.theme.letterSpacing};
  line-height: ${(props) => props.theme.lineHeight};
`;

const Lato: FC<LatoProps> = ({
  children,
  size = '1rem',
  color = fontColor.headlineDark,
  textDecoration = 'none',
  letterSpacing = 0,
  fontWeight = regular,
  lineHeight = `${22 / 16}rem`,
}: LatoProps) => (
  <LatoSpan
    theme={{
      size,
      color,
      textDecoration,
      fontWeight,
      lineHeight,
      letterSpacing,
    }}
  >
    {children}
  </LatoSpan>
);
export default Lato;

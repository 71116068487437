import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormPersonalInformationInputLayoutProps {
  children: React.ReactElement[];
}

const FormPersonalInformationInputLayout: FC<FormPersonalInformationInputLayoutProps> = ({
  children,
}: FormPersonalInformationInputLayoutProps) => {
  const elements = ['gender', 'dateOfBirth', 'nationality', 'occupation'];
  const [gender, dateOfBirth, nationality, occupation] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn height="auto">
      {gender}
      <Margin top={80} />
      {dateOfBirth}
      <Margin top={60} />
      {nationality}
      <Margin top={60} />
      {occupation}
      <Margin top={40} />
    </FlexBoxColumn>
  );
};
export default FormPersonalInformationInputLayout;

import styled from 'styled-components';
import Box from '.';
import color from '../../../const/color';

interface ContentBoxProps {
  flexDirection?: 'row' | 'column';
  justifyContent?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'start' | 'end' | 'stretch' | 'center';
}

const ContentBox = styled(Box)`
  background-color: ${color.white};
  display: flex;
  flex-direction: ${(props: ContentBoxProps) =>
    props.flexDirection || 'column'};
  justify-content: ${(props: ContentBoxProps) =>
    `${props.justifyContent || ''}`};
  align-items: ${(props: ContentBoxProps) => `${props.alignItems || ''}`};
`;

export default ContentBox;

import TextPrimary from './TextPrimary';
import TextPrimaryRegular18 from './TextPrimaryRegular18';
import TextPrimaryRegular21 from './TextPrimaryRegular21';
import TextPrimaryRegular24 from './TextPrimaryRegular24';
import TextPrimaryRegular28 from './TextPrimaryRegular28';
import TextPrimaryRegular29 from './TextPrimaryRegular29';
import TextPrimaryBold21 from './TextPrimaryBold21';
import TextPrimaryBold24 from './TextPrimaryBold24';
import TextPrimaryBold27 from './TextPrimaryBold27';
import TextPrimaryBold28 from './TextPrimaryBold28';
import TextPrimaryBold33 from './TextPrimaryBold33';
import TextPrimaryBold50 from './TextPrimaryBold50';

export default TextPrimary;
export {
  TextPrimaryRegular18,
  TextPrimaryRegular21,
  TextPrimaryRegular24,
  TextPrimaryRegular28,
  TextPrimaryRegular29,
  TextPrimaryBold21,
  TextPrimaryBold24,
  TextPrimaryBold27,
  TextPrimaryBold28,
  TextPrimaryBold33,
  TextPrimaryBold50,
};

// FirebaseFunctions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// Constant
import {
  CONTRACT_TIME_DEPOSIT_GET_RAW_DATA_ALL_BY_USER_ID,
  CONTRACT_TIME_DEPOSIT_CREATE,
  CONTRACT_TIME_DEPOSIT_GET_RAW_DATA_BY_ID,
  CONTRACT_TIME_DEPOSIT_CREATE_RENEWAL_RESERVATION,
  CONTRACT_TIME_DEPOSIT_UPDATE_RENEWAL_RESERVATION,
  CONTRACT_TIME_DEPOSIT_DELETE_RENEWAL_RESERVATION,
} from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// Error
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';

// Type
import { ContractTimeDepositRawDataType } from '../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit.type';

// IRepository
import IContractTimeDepositRepository from '../../../domain/contract/contractTimeDeposit/IContractTimeDepositRepository';

// DomainObject
import ContractTimeDeposit from '../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';
import ContractsTimeDeposit from '../../../domain/contract/contractTimeDeposit/ContractsTimeDeposit/ContractsTimeDeposit';
import ContractTimeDepositSeed from '../../../domain/contract/contractTimeDeposit/ContractTimeDepositSeed/ContractTimeDepositSeed';
import ContractTimeDepositFactory from '../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDepositFactory';
import SerialNumberContractTimeDepositFactory from '../../../domain/idManager/productSerialNumber/ProductSerialNumber/SerialNumber/concreteSerialNumber/SerialNumberContractTimeDeposit';

class ContractTimeDepositFunctions
  extends FirebasePrivateFunctions
  implements IContractTimeDepositRepository {
  public findById = async (id: string) => {
    const endpoint = CONTRACT_TIME_DEPOSIT_GET_RAW_DATA_BY_ID.replace(
      /:id/,
      id,
    );

    const response = await this.privateGET(endpoint);

    const row = response.data.data as ContractTimeDepositRawDataType;

    const contractTimeDeposit = ContractTimeDepositFactory.create(
      SerialNumberContractTimeDepositFactory.createNumberFromRawId(row.id),
      {
        ...row.props,
        renewalReservation: row.props.renewalReservation,
      },
    );
    return contractTimeDeposit;
  };

  public findAllByUserId = async (userId: string) => {
    const endpoint = CONTRACT_TIME_DEPOSIT_GET_RAW_DATA_ALL_BY_USER_ID.replace(
      /:userId/,
      userId,
    );

    const response = await this.privateGET(endpoint);

    const rows = response.data.data as Array<ContractTimeDepositRawDataType>;

    return rows.reduce((contractsTimeDeposit, row) => {
      contractsTimeDeposit.add(
        ContractTimeDepositFactory.create(
          SerialNumberContractTimeDepositFactory.createNumberFromRawId(row.id),
          {
            ...row.props,
            renewalReservation: row.props.renewalReservation,
          },
        ),
      );
      return contractsTimeDeposit;
    }, new ContractsTimeDeposit());
  };

  public create = async (contractTimeDepositSeed: ContractTimeDepositSeed) => {
    const response = await this.privatePOST(
      CONTRACT_TIME_DEPOSIT_CREATE,
      contractTimeDepositSeed.getRequestParameterCreate(),
    );

    return ContractTimeDepositFactory.createFromSeed(
      contractTimeDepositSeed,
      response.data.contractTimeDepositId,
    );
  };

  public createRenewalReservation = async (
    contractTimeDeposit: ContractTimeDeposit,
  ) => {
    const { renewalReservation } = contractTimeDeposit.getProps();

    if (!renewalReservation) {
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
        {
          type: 'ContractTimeDeposit.renewalReservation',
          place: 'ContractTimeDepositFunctions.createRenewalReservation',
        },
      );
    }

    await this.privatePUT(
      CONTRACT_TIME_DEPOSIT_CREATE_RENEWAL_RESERVATION.replace(
        /:id/,
        contractTimeDeposit.getRawId(),
      ),
      renewalReservation,
    );

    return contractTimeDeposit;
  };

  public updateRenewalReservation = async (
    contractTimeDeposit: ContractTimeDeposit,
  ) => {
    const { renewalReservation } = contractTimeDeposit.getProps();

    if (!renewalReservation) {
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
        {
          type: 'ContractTimeDeposit.renewalReservation',
          place: 'ContractTimeDepositFunctions.updateRenewalReservation',
        },
      );
    }

    await this.privatePUT(
      CONTRACT_TIME_DEPOSIT_UPDATE_RENEWAL_RESERVATION.replace(
        /:id/,
        contractTimeDeposit.getRawId(),
      ),
      renewalReservation,
    );

    return contractTimeDeposit;
  };

  public deleteRenewalReservation = async (
    contractTimeDeposit: ContractTimeDeposit,
  ) => {
    await this.privatePUT(
      CONTRACT_TIME_DEPOSIT_DELETE_RENEWAL_RESERVATION.replace(
        /:id/,
        contractTimeDeposit.getRawId(),
      ),
      {},
    );

    return contractTimeDeposit;
  };
}
export default ContractTimeDepositFunctions;

import React, { FC, useContext } from 'react';
import { useAssetSummaryViewUseCase } from './useAssetSummaryViewUseCase';
import AssetCryptoCurrencyContext from '../../../../domain/asset/assetCryptoCurrency/AssetCryptoCurrency/AssetCryptoCurrencyContext';
import AssetDebitCardContext from '../../../../domain/asset/assetDebitCard/AssetDebitCard/AssetDebitCardContext';
import AssetTimeDepositContext from '../../../../domain/asset/assetTimeDeposit/AssetTimeDeposit/AssetTimeDepositContext';
import AssetSavingsAccountContext from '../../../../domain/asset/assetSavingsAccount/AssetSavingsAccount/AssetSavingsAccountContext';
import RequestStateContext from '../../../../value/view/RequestState/RequestStateContext';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// ErrorHandler
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type AssetSummaryViewProviderProps = {
  children: React.ReactNode;
};

const AssetSummaryViewProvider: FC<AssetSummaryViewProviderProps> = ({
  children,
}: AssetSummaryViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    assetCryptoCurrency,
    assetDebitCard,
    assetTimeDeposit,
    assetSavingsAccount,
  } = useAssetSummaryViewUseCase(userAuthorized);

  return (
    <RequestStateContext.Provider value={{ requestState: state }}>
      <AssetCryptoCurrencyContext.Provider value={{ assetCryptoCurrency }}>
        <AssetDebitCardContext.Provider value={{ assetDebitCard }}>
          <AssetTimeDepositContext.Provider value={{ assetTimeDeposit }}>
            <AssetSavingsAccountContext.Provider
              value={{ assetSavingsAccount }}
            >
              <SystemErrorHandler state={state} setState={setState}>
                {children}
              </SystemErrorHandler>
            </AssetSavingsAccountContext.Provider>
          </AssetTimeDepositContext.Provider>
        </AssetDebitCardContext.Provider>
      </AssetCryptoCurrencyContext.Provider>
    </RequestStateContext.Provider>
  );
};
export default AssetSummaryViewProvider;

import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const UnderConstructionTextStyle = {
  fontSize: 24,
  color: color.primary,
  fontWeight: fontWeight.regular,
  lineHeight: 48,
  textAlign: 'center',
};

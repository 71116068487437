import React, { useState } from 'react';

// Type
import { FormInputShippingAddress } from '../FormCorporateAddress/FormCorporateAddress.type';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface FormInputProofOfResidency {
  country: string;

  addressLine1: string;

  addressLine2: string;

  city: string;

  state: string;

  postalCode: string;

  documentType: string;

  proofOfDocumentUrl1: string;

  proofOfDocumentUrl2: string;
}

export const useFormInputProofOfResidency = () => {
  const [formInput, setFormInput] = useState<
    FormInputProofOfResidency & FormInputShippingAddress
  >();

  return {
    formInput,
    setFormInput,
  };
};

export const FormInputProofOfResidencyContext = React.createContext<{
  formInput?: FormInputProofOfResidency & FormInputShippingAddress;
  setFormInput: (
    formInput?: FormInputProofOfResidency & FormInputShippingAddress,
  ) => void;
}>({
  formInput: undefined,
  setFormInput: (
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    formInput?: FormInputProofOfResidency & FormInputShippingAddress,
  ) => undefined,
});

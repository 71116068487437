import React, { FC } from 'react';
// import SignUpContext from './ResignUpContext';
import ResignUpContext from './ResignUpContext';
import { useResignUpUseCase } from './useResignUpUseCase';
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type ResignUpProviderProps = {
  children: React.ReactNode;
};

const ResignUpProvider: FC<ResignUpProviderProps> = ({
  children,
}: ResignUpProviderProps) => {
  const {
    email,
    resendResignUpComplete,
    reregistrationCode,
    resignUp,
    state,
    setState,
  } = useResignUpUseCase();

  return (
    <ResignUpContext.Provider
      value={{
        email,
        resendResignUpComplete,
        reregistrationCode,
        resignUp,
        state,
        setState,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        <>{children}</>
      </SystemErrorHandler>
    </ResignUpContext.Provider>
  );
};
export default ResignUpProvider;

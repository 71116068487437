import firestore from '../../../client/firestoreClient';
import Collection from '../../collection/Collection';
import { FIRESTORE_DATA_VERSION } from '../../../../../../config/constants/firebase';

class TimeDepositsCollection extends Collection {
  collection: firestore.CollectionReference;

  constructor() {
    super();
    this.collection = firestore()
      .collection('product')
      .doc(FIRESTORE_DATA_VERSION)
      .collection('timeDeposits');
  }
}

export default TimeDepositsCollection;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';
import { FlexBoxColumn } from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormInputTextLabelColumnLayoutProps {
  children: React.ReactElement[];
  marginBetween: string | number;
}

const FormInputTextLabelColumnLayout: FC<FormInputTextLabelColumnLayoutProps> = ({
  children,
  marginBetween,
}: FormInputTextLabelColumnLayoutProps) => {
  const elements = ['label', 'input'];
  const [label, input] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn width={'auto'}>
      {label}
      <Margin top={marginBetween}>{input}</Margin>
    </FlexBoxColumn>
  );
};
export default FormInputTextLabelColumnLayout;

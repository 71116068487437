import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  ConsentFormProps,
  ConsentFormPropsFormat,
  ConsentFormInitialProps,
} from './ConsentForm.type';
import { UserType } from '../../../../types/User.type';

// DomainObject
import ConsentForm from './ConsentForm';
import UserAuthorized from '../../../public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class ConsentFormFactory {
  static create = (id: string, props: ConsentFormProps) => {
    const result = Joi.object(ConsentFormPropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: ConsentForm.name,
          reason: result.error.details[0].message,
        },
      );

    return new ConsentForm(id, props);
  };

  static createInitial = (user: UserAuthorized, userType: UserType) => {
    const id = user.getId();
    const { username } = user.getProps();

    const props = {
      ...ConsentFormInitialProps,
      type: userType,
      username,
    };

    return new ConsentForm(id, props);
  };
}

export default ConsentFormFactory;

// Lib
import { getCurrentTimeTimezone } from '../../../../utils/helpers/datetimeHelper';
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
import { DebitCardSpotTransactionType } from '../../../../types/DebitCardTransaction.type';
import { PrefixDebitCardTransactionId } from './DebitCardTransactionId.type';
import DebitCardTransactionId from './DebitCardTransactionId';

// Error
import InvalidDataFoundError from '../../../../utils/errors/InvalidDataFoundError';

const TRANSACTION_ID_LENGTH = 26;

class DebitCardTransactionIdFactory {
  static create = (
    debitCardTransactionType: DebitCardSpotTransactionType,
    commonId: string,
    currency: FiatCurrencyType,
    target: string,
    serialNumber: string,
  ): DebitCardTransactionId => {
    const prefix = PrefixDebitCardTransactionId[debitCardTransactionType];
    const now = getCurrentTimeTimezone();
    const date = now.format('YYYYMMDD');

    if (!prefix)
      throw new InvalidDataFoundError(
        `Prefix of ${debitCardTransactionType} is not found.`,
      );

    return new DebitCardTransactionId({
      prefix,
      currency,
      date,
      target,
      commonId,
      serialNumber,
    });
  };

  static createFromRawId = (id: string, commonId: string) => {
    if (
      id.length !== TRANSACTION_ID_LENGTH &&
      id.length !== TRANSACTION_ID_LENGTH + 1
    )
      throw new InvalidDataFoundError(
        `TransactionId with invalid length '${id}' is  found.`,
      );
    if (id.length === TRANSACTION_ID_LENGTH) {
      return new DebitCardTransactionId({
        prefix: id.substr(0, 3),
        currency: id.substr(3, 3) as FiatCurrencyType,
        date: id.substr(6, 8),
        serialNumber: id.substr(14, 6),
        target: id.substr(20, 6),
        commonId,
      });
    }
    return new DebitCardTransactionId({
      prefix: id.substr(0, 3),
      currency: id.substr(3, 4) as FiatCurrencyType,
      date: id.substr(7, 8),
      serialNumber: id.substr(15, 6),
      target: id.substr(21, 6),
      commonId,
    });
  };
}
export default DebitCardTransactionIdFactory;

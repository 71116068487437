import React, { FC } from 'react';
import CryptoCurrencyDepositWithdrawalPage from './CryptoCurrencyDepositWithdrawalPage';
import CryptoCurrencyDepositWithdrawalViewProvider from '../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyDepositWithdrawalViewProvider';
import CryptoCurrencyDepositModalProvider from '../../../components/organisms/modal/CryptoCurrencyDepositModal/CryptoCurrencyDepositModalProvider';

const EnhancedCryptoCurrencyDepositWithdrawalPage: FC = () => {
  return (
    <CryptoCurrencyDepositWithdrawalViewProvider>
      <CryptoCurrencyDepositModalProvider>
        <CryptoCurrencyDepositWithdrawalPage />
      </CryptoCurrencyDepositModalProvider>
    </CryptoCurrencyDepositWithdrawalViewProvider>
  );
};

export default EnhancedCryptoCurrencyDepositWithdrawalPage;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import Margin from '../../../atoms/utils/layout/Margin';
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';
import ContentBox from '../../../atoms/utils/layout/Box/Box/ContentBox';

export interface UnderConstructionLayoutProps {
  children: React.ReactElement[];
  width: string | number;
  height: string | number;
}

const UnderConstructionLayout: FC<UnderConstructionLayoutProps> = ({
  children,
  width,
  height,
}: UnderConstructionLayoutProps) => {
  const elements = ['icon', 'description'];
  const [icon, description] = getElementsFromKeys(children, elements);

  return (
    <ContentBox padding="100" width={width} height={height}>
      <FlexBoxColumn
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        {icon}
        <Margin top={30} />
        {description}
      </FlexBoxColumn>
    </ContentBox>
  );
};
export default UnderConstructionLayout;

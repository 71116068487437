import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const ContainerStyle = {
  backgroundColor: color.background.white,
  boxShadow: '0px 2px 6px #0000000a',
};

export const DescriptionStyle = {
  fontSize: 22,
  fontWeight: fontWeight.regular,
  lineHeight: 44,
  textAlign: 'center',
};

const TitleStyle = {
  fontSize: 28,
  fontWeight: fontWeight.bold,
};

export const SelectedTitleStyle = {
  ...TitleStyle,
  color: color.text.white,
};

export const SelectedDescriptionStyle = {
  ...DescriptionStyle,
  color: color.text.white,
};

export const UnSelectedTitleStyle = {
  ...TitleStyle,
  color: color.text.primary,
};

export const UnSelectedDescriptionStyle = {
  ...DescriptionStyle,
  color: color.text.zambezi,
};

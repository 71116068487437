import React, { FC, useContext } from 'react';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
// import AuthenticatorContext from '../../../../../domain/authenticator/authenticator/Authenticator/AuthenticatorContext';
import { useTwoFactorAuthAppSettingsSubmitUseCase } from './useTwoFactorAuthAppSettingsSubmitUseCase';
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';
import TwoFactorAuthAppSettingsSubmitContext from './TwoFactorAuthAppSettingsSubmitContext';

export type TwoFactorAuthAppSettingsSubmitProviderProps = {
  children: React.ReactNode;
};

const TwoFactorAuthAppSettingsSubmitProvider: FC<TwoFactorAuthAppSettingsSubmitProviderProps> = ({
  children,
}: TwoFactorAuthAppSettingsSubmitProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    submitTwoFactorAuthAppSettings,
  } = useTwoFactorAuthAppSettingsSubmitUseCase(userAuthorized);

  return (
    <TwoFactorAuthAppSettingsSubmitContext.Provider
      value={{ state, submitTwoFactorAuthAppSettings }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </TwoFactorAuthAppSettingsSubmitContext.Provider>
  );
};

export default TwoFactorAuthAppSettingsSubmitProvider;

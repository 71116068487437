import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import Margin from '../../../atoms/utils/layout/Margin';
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../atoms/utils/layout/Box/FlexBox';

export interface AmountLayoutProps {
  children: React.ReactElement[];
  direction: 'row' | 'column';
  marginBetween: number | string;
}

const AmountLayout: FC<AmountLayoutProps> = ({
  children,
  direction,
  marginBetween,
}: AmountLayoutProps) => {
  const elements = ['label', 'currency', 'amount'];
  const [label, currency, amount] = getElementsFromKeys(children, elements);

  if (direction === 'row')
    return (
      <FlexBoxRow alignItems="center">
        {label}
        <Margin left={marginBetween} />
        {currency}
        <Margin left={10} />
        {amount}
      </FlexBoxRow>
    );

  return (
    <FlexBoxColumn>
      {label}
      <Margin top={marginBetween} />
      <FlexBoxRow alignItems="center">
        {currency}
        <Margin left={10} />
        {amount}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
export default AmountLayout;

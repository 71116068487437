import React, { FC, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useCorporateInformationViewUseCase } from './useCorporateInformationViewUseCase';
import KycRequestCorporateContext from '../../../../../domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporateContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Type
import { KycStatusType } from '../../../../../types/KycRequest.type';

// Constant
import { ROUTE_KYC_STATUS } from '../../../../../config/constants/routing';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

export type CorporateInformationViewProviderProps = {
  children: React.ReactNode;
};

const CorporateInformationViewProvider: FC<CorporateInformationViewProviderProps> = ({
  children,
}: CorporateInformationViewProviderProps) => {
  const history = useHistory();

  const { userAuthorized } = useContext(UserAuthorizedContext);

  const { state, setState, kycRequest } = useCorporateInformationViewUseCase(
    userAuthorized,
  );

  /* *
   *
   *  useEffect (Redirect)
   *
   * */

  // Status of PersonalInformation
  const status = useMemo(
    () => kycRequest?.getKycStepStatus().getProps().corporateInformation,
    [kycRequest],
  );

  // Redirect if status is invalid
  useEffect(() => {
    if (
      status &&
      ![
        KycStatusType.initial,
        KycStatusType.submitted,
        KycStatusType.rejected,
      ].includes(status)
    )
      history.push(ROUTE_KYC_STATUS);
    // eslint-disable-next-line
  }, [status]);

  return (
    <KycRequestCorporateContext.Provider value={{ kycRequest }}>
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </KycRequestCorporateContext.Provider>
  );
};

export default CorporateInformationViewProvider;

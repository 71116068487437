import React, { FC } from 'react';
import CherryVisa01ProofOfReceiptPage from './CherryVisa01ProofOfReceiptPage';
import CherryVisa01ProofOfReceiptViewProvider from '../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01ProofOfReceiptViewProvider';
import CherryVisa01ProofOfReceiptSubmitProvider from '../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01ProofOfReceiptUpdateProvider/DebitCardRequestCherryVisa01ProofOfReceiptUpdateProvider';

const EnhancedCherryVisa01ProofOfReceiptPage: FC = () => {
  return (
    <CherryVisa01ProofOfReceiptViewProvider>
      <CherryVisa01ProofOfReceiptSubmitProvider>
        <CherryVisa01ProofOfReceiptPage />
      </CherryVisa01ProofOfReceiptSubmitProvider>
    </CherryVisa01ProofOfReceiptViewProvider>
  );
};

export default EnhancedCherryVisa01ProofOfReceiptPage;

// Type
import { CurrencyType } from './Currency.type';
import { CryptoCurrencyType } from './CryptoCurrency.type';
import { FiatCurrencyType } from './FiatCurrency.type';

// Error
import { ErrorIdType } from '../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../errors/ErrorFactory/SystemErrorFactory';

export enum CurrencyPairType {
  BTCUSD = 'BTCUSD',
  LTCTUSD = 'LTCTUSD',
  BTCUSDT = 'BTCUSDT',
}

export const CurrencyPairProps = {
  [CurrencyPairType.BTCUSD]: {
    precision: 2,
    minTradeAmountBase: 0.01,
    minTradeAmountTarget: 0.000001,
    rateUnit: 'USD/BTC',
    baseCurrency: FiatCurrencyType.USD,
    targetCurrency: CryptoCurrencyType.BTC,
  },
  [CurrencyPairType.LTCTUSD]: {
    precision: 2,
    minTradeAmountBase: 0.01,
    minTradeAmountTarget: 0.000001,
    rateUnit: 'USD/LTCT',
    baseCurrency: FiatCurrencyType.USD,
    targetCurrency: CryptoCurrencyType.LTCT,
  },
  [CurrencyPairType.BTCUSDT]: {
    precision: 2,
    minTradeAmountBase: 0.01,
    minTradeAmountTarget: 0.000001,
    rateUnit: 'USDT/BTC',
    baseCurrency: CryptoCurrencyType.USDT,
    targetCurrency: CryptoCurrencyType.BTC,
  },
};

export class CurrencyPairFactory {
  static create = (
    baseCurrency: CurrencyType,
    targetCurrency: CurrencyType,
  ) => {
    const currencyPair = `${targetCurrency}${baseCurrency}` as CurrencyPairType;

    if (!CurrencyPairFactory.isExisted(currencyPair))
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_SWITCH,
        {
          value: currencyPair,
          place: `CurrencyPairFactory`,
        },
      );

    return currencyPair;
  };

  static isExisted = (currencyPair: string) => {
    return Object.keys(CurrencyPairType).includes(currencyPair);
  };
}

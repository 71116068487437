import React, { FC, useContext, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DebitCardV2sContext from '../../../../../domain/debitCard/debitCardV2/DebitCardV2s/DebitCardV2sContext';
import DebitCardMaintenanceModalContext from '../../../../organisms/modal/DebitCardMaintenanceModal/DebitCardMaintenanceModalContext';
import MaintenanceContext from '../../../../../domain/settings/maintenance/Maintenance/Maintenance/MaintenanceContext';
import TransferDebitCardTypeOptionContext from '../../../../molecules/selector/tabBar/TransferDebitCardTabBar/TransferDebitCardTypeOptionContext';
import PageRequestStateContext from '../../../../../enhancers/pageState/general/PageRequestState/PageRequestStateContext';

// Style
import { DescriptionStyle, UnderConstructionTextStyle } from './style';

// Component
import { PageTitle } from '../../../../atoms/headLine/HeadLine';
import { TextPrimary } from '../../../../atoms/text2/Text2';
import CircleSpinLoader from '../../../../atoms/spinner/CircleSpinLoader';
import FormTransferDebitCard from '../../../../organisms/form/transfer/debitCard/FormTransferDebitCard';
import ProgressStepBarBankTransaction from '../../../../molecules/progressStep/ProgressStepBar/ProgressStepBarBankTransaction';
import TransferDebitCardTypeTabBar from '../../../../molecules/selector/tabBar/TransferDebitCardTabBar';
import UnderConstruction from '../../../../organisms/notification/UnderConstruction';
import TransferDebitCardTemplateLayout from './TransferDebitCardTemplateLayout';

const TEMPLATE_ID = `templates.transfers.TransferDebitCardTemplate`;

const TransferDebitCardTemplate: FC = () => {
  const { t } = useTranslation();
  const { debitCards } = useContext(DebitCardV2sContext);
  const { transferTypeOption } = useContext(TransferDebitCardTypeOptionContext);
  const { state } = useContext(PageRequestStateContext);
  const { maintenance } = useContext(MaintenanceContext);
  const { showModal } = useContext(DebitCardMaintenanceModalContext);

  const isMaintenance = useMemo(
    () => !!maintenance?.isCurrentlyDebitCardTopUpMaintenance(),
    [maintenance],
  );

  // Maintenance modal
  useEffect(() => {
    if (isMaintenance) showModal();
    // eslint-disable-next-line
  }, [isMaintenance]);

  if (isMaintenance) return <></>;

  return (
    <TransferDebitCardTemplateLayout
      transferType={transferTypeOption}
      hasDebitCard={debitCards.hasItem()}
      state={state}
    >
      <PageTitle key="header">{t(`${TEMPLATE_ID}.title`)}</PageTitle>
      <ProgressStepBarBankTransaction key="progressStep" currentStep="input" />
      <TransferDebitCardTypeTabBar key="transactionType" />
      <FormTransferDebitCard key="savingsAccountToCard" />
      <UnderConstruction
        key="cardToSavingsAccount"
        width="100%"
        height={785}
        iconSize={150}
        descriptionStyle={UnderConstructionTextStyle}
      />
      <TextPrimary key="noCardDescription" theme={DescriptionStyle}>
        {t(`${TEMPLATE_ID}.description`)}
      </TextPrimary>
      <CircleSpinLoader key="loading" />
    </TransferDebitCardTemplateLayout>
  );
};

export default React.memo(TransferDebitCardTemplate);

import React, { FC } from 'react';
import { Container } from './style';

export interface TransferSavingsAccountTemplateContentProps {
  children: React.ReactNode;
}

const TransferSavingsAccountTemplateContent: FC<TransferSavingsAccountTemplateContentProps> = ({
  children,
}: TransferSavingsAccountTemplateContentProps) => (
  <Container>{children}</Container>
);

export default TransferSavingsAccountTemplateContent;

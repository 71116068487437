/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export interface DropzoneAreaContextProps {
  preview?: string;
  setPreview: (url: string | undefined) => void;
  errorMessage?: string;
  setErrorMessage: (message: string | undefined) => void;
  tmpFileName?: string;
  setTmpFileName: (filePath: string | undefined) => void;
  setFile: (file?: File) => void;
}

const DropzoneAreaContext = React.createContext<DropzoneAreaContextProps>({
  preview: undefined,
  setPreview: (url: string | undefined) => undefined,
  errorMessage: undefined,
  setErrorMessage: (message: string | undefined) => undefined,
  tmpFileName: undefined,
  setTmpFileName: (filePath: string | undefined) => undefined,
  setFile: (file?: File) => undefined,
});

export default DropzoneAreaContext;

import React from 'react';
import ReferralsTimeDeposit from './ReferralsTimeDeposit';

export interface ReferralsTimeDepositContextProps {
  referralsTimeDeposit: ReferralsTimeDeposit;
}

const ReferralsTimeDepositContext = React.createContext<
  ReferralsTimeDepositContextProps
>({
  referralsTimeDeposit: new ReferralsTimeDeposit(),
});

export default ReferralsTimeDepositContext;

import styled from 'styled-components';

export const SuccessCheckIcon = styled.div`
  width: ${90 / 16}rem;
  height: ${90 / 16}rem;
  background: transparent linear-gradient(180deg, #30a3ea 0%, #005f9a 100%) 0%
    0% no-repeat padding-box;
  border-radius: ${45 / 16}rem;
`;

export const CheckMarkLeft = styled.div`
  width: ${34 / 16}rem;
  height: ${9 / 16}rem;
  background-color: white;
  border-radius: ${5.92 / 16}rem;
  transform: matrix(0.78, 0.63, -0.63, 0.78, 0, 0);
  position: relative;
  top: ${52.6 / 16}rem;
  left: ${13 / 16}rem;
`;

export const CheckMarkRight = styled.div`
  width: ${55 / 16}rem;
  height: ${9 / 16}rem;
  background-color: white;
  border-radius: ${5.92 / 16}rem;
  transform: matrix(0.62, -0.79, 0.79, 0.62, 0, 0);
  position: relative;
  top: ${33.6 / 16}rem;
  left: ${29.2 / 16}rem;
`;

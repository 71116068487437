import React, { useState } from 'react';

export interface FormInputContractTimeDepositRenewalReservation {
  savingsAccountNumber: string;
  interestRate: number;
  amount: string;
  term: number;
  renewalTerm: number;
  principalIncreased: string;
  isAnnualPayOut: string;
  timeDepositId: string;
}

export const useFormInputContractTimeDepositRenewalReservation = () => {
  const [formInput, setFormInput] = useState<
    FormInputContractTimeDepositRenewalReservation
  >();

  return {
    formInput,
    setFormInput,
  };
};

export const FormInputContractTimeDepositRenewalReservationContext = React.createContext<{
  formInput?: FormInputContractTimeDepositRenewalReservation;
  setFormInput: (
    formInput?: FormInputContractTimeDepositRenewalReservation,
  ) => void;
}>({
  formInput: undefined,
  setFormInput: () => undefined,
});

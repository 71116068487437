import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import UpdateEmailContext from '../../../../../enhancers/settings/UpdateEmailProvider/UpdateEmailContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import { ErrorCodeType } from '../../../../../utils/errors/ErrorHandler/ErrorCode.type';

// Lib
import { deleteFullWidth } from '../../../../../utils/helpers/stringHelper';
import { emailEditRegister } from '../../../../../utils/validation/registers';

// Type
import {
  FormInputEmailEdit,
  FormInputEmailEditContext,
} from './FormEmailEdit.type';

// Constant
import { EMAIL_EDIT } from '../../../../../config/constants/pageId.json';
import { IS_LOADING } from '../../../../../config/constants/requestState';

// Style
import { SubmitButtonWrap } from './style';

// Component
import { SubmitButton } from '../../../../atoms/button/DomainButton';
import { SettingsTextField } from '../../../../molecules/form/TextField';

const FORM_ID = `${EMAIL_EDIT}.form`;

const ERROR_CODES_EMAIL_ALREADY_EXISTED = [
  ErrorCodeType.AUTHENTICATION_SIGN_UP_USER_EXISTED,
  ErrorCodeType.STAFF_EMAIL_ALREADY_EXISTED,
];

const FormEmailEdit: FC = () => {
  const { t } = useTranslation();
  const { formInput, setFormInput } = useContext(FormInputEmailEditContext);

  // Method
  const { updateEmailState, updateEmail } = useContext(UpdateEmailContext);
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const methods = useForm<FormInputEmailEdit & { validEmail: string }>({
    mode: 'onChange',
  });

  const {
    clearErrors,
    errors,
    formState: { isValid },
    handleSubmit,
    setError,
    setValue,
    register,
    reset,
    watch,
  } = methods;

  /* *
   *
   *  Default Variable
   *
   * */

  // Set DefaultValue
  useEffect(() => {
    if (formInput) {
      reset(formInput);
    }
    // eslint-disable-next-line
  }, [formInput, updateEmailState]);

  /* *
   *
   *  UseEffect
   *
   * */
  useEffect(() => {
    if (
      ERROR_CODES_EMAIL_ALREADY_EXISTED.includes(
        updateEmailState as ErrorCodeType,
      )
    ) {
      setError('validEmail', {
        type: '',
        message: t(
          `validation.${ErrorCodeType.AUTHENTICATION_SIGN_UP_USER_EXISTED}`,
        ),
      });
    }
  }, [updateEmailState, setError, t]);

  if (!userAuthorized) return <></>;

  const { email } = userAuthorized.getProps();

  return (
    <form
      onSubmit={handleSubmit((input: FormInputEmailEdit) => {
        setFormInput(input);

        updateEmail(input.newEmail);
      })}
    >
      <FormProvider {...methods}>
        <div>
          <SettingsTextField
            name="currentEmail"
            value={email}
            label={t(`${FORM_ID}.currentEmail.label`)}
            register={register}
            maxLength={255}
            textInputWidth={`${400 / 16}rem`}
            disabled={true}
          />
        </div>
        <div>
          <SettingsTextField
            name="newEmail"
            value={watch(`newEmail`)}
            label={t(`${FORM_ID}.newEmail.label`)}
            errorMessage={
              errors?.newEmail?.message || errors?.validEmail?.message
            }
            onChange={(text: string) => {
              clearErrors('validEmail');
              setValue('newEmail', deleteFullWidth(text));
            }}
            register={register(emailEditRegister('newEmail', t, { email }))}
            maxLength={255}
            textInputWidth={`${400 / 16}rem`}
          />
        </div>
        <div style={{ marginTop: `${30 / 16}rem` }}>
          <SubmitButtonWrap>
            <SubmitButton
              name={t(`atoms.button.sendEmail`)}
              width={`${338 / 16}rem `}
              height={`${50 / 16}rem `}
              borderRadius={`${25 / 16}rem`}
              textSize={20}
              sizeProgress={`${20 / 16}rem`}
              disabled={!isValid}
              isLoading={updateEmailState === IS_LOADING}
            />
          </SubmitButtonWrap>
        </div>
      </FormProvider>
    </form>
  );
};

export default FormEmailEdit;

import Joi from '@hapi/joi';

// Type
import {
  DebitCardSpotTransactionType,
  DebitCardTransactionStatusType,
} from '../../../../../../types/DebitCardTransaction.type';
import { FiatCurrencyType } from '../../../../../../types/FiatCurrency.type';

export interface DebitCardSpotTransactionV2Props {
  debitCardId: string;

  amount: number;

  currency: FiatCurrencyType;

  descriptionParameter: string;

  commonId: string;

  status: DebitCardTransactionStatusType;

  createdAt?: number;

  updatedAt?: number;
}

export interface DebitCardSpotTransactionV2RawDataType {
  id: string;

  props: DebitCardSpotTransactionV2Props & {
    type: DebitCardSpotTransactionType;
  };
}

export const DebitCardSpotTransactionV2PropsFormat = {
  id: Joi.any().required(),

  debitCardId: Joi.string().required(),

  amount: Joi.number().min(0).required(),

  commonId: Joi.string().required(),

  currency: Object.keys(FiatCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),

  descriptionParameter: Joi.string().allow(''),

  status: Object.keys(DebitCardTransactionStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

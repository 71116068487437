import { FC } from 'react';
import { isEmpty } from 'lodash';
import { IIterable } from './IIterable';

abstract class DomainObjects<T> implements IIterable<T> {
  protected items: T[] = [];

  constructor(items?: T[]) {
    this.items = items || [];
  }

  public add = (item: T) => {
    this.items.push(item);
  };

  public isEmpty = () => isEmpty(this.items);

  public head = () => this.items[0];

  public map = <U>(callback: (item: T) => U) => {
    return this.items.map((item) => callback(item));
  };

  public forEach = (callback: (item: T) => void) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.items) {
      // eslint-disable-next-line no-await-in-loop
      callback(item);
    }
  };

  public forEachAsync = async (callback: (item: T) => Promise<void>) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.items) {
      // eslint-disable-next-line no-await-in-loop
      await callback(item);
    }
  };

  public mapComponent = (component: FC<{ item: T; i: number }>) => {
    return this.items.map((item, i) => component({ item, i }));
  };

  public length = () => this.items.length;
}
export default DomainObjects;

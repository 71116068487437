import { required, onlyCharacters } from '../validator';

// Type
import { TypeOfT } from '../../../../types/typeof/Translation.type';

const nameValidation = (t: TypeOfT) => ({
  firstName: {
    required: required('First Name', t),
    validate: {
      onlyCharacters: onlyCharacters('First Name', t),
    },
  },
  middleName: {
    validate: {
      onlyCharacters: onlyCharacters('Middle Name', t),
    },
  },
  lastName: {
    required: required('Last Name', t),
    validate: {
      onlyCharacters: onlyCharacters('Last Name', t),
    },
  },
});

export default nameValidation;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Style
import { FormContainerStyle, SectionBoxStyle } from './style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface ContractTimeDepositRenewalReservationAddTemplateLayoutProps {
  children: React.ReactElement[];
}

const ContractTimeDepositRenewalReservationAddTemplateLayout: FC<ContractTimeDepositRenewalReservationAddTemplateLayoutProps> = ({
  children,
}: ContractTimeDepositRenewalReservationAddTemplateLayoutProps) => {
  const elements = [
    'header',
    'attention',
    'progressStep',
    'section1',
    'orderIdLabel',
    'orderIdValue',
    'propertyList',
    'section2',
    'form',
  ];
  const [
    header,
    attention,
    progressStep,
    section1,
    orderIdLabel,
    orderIdValue,
    propertyList,
    section2,
    form,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn textAlign="left" padding="85 0 0 80" width={1280}>
      {header}
      <Margin top={50} />
      {attention}
      <Margin top={50} />
      <FlexBoxRow height="auto" justifyContent="center">
        {progressStep}
      </FlexBoxRow>
      <Margin top={90} />
      <FlexBoxColumn
        justifyContent="center"
        width={1200}
        height="auto"
        padding="80"
        theme={FormContainerStyle}
      >
        <FlexBoxRow
          height={40}
          justifyContent="center"
          alignItems="center"
          theme={SectionBoxStyle}
        >
          {section1}
        </FlexBoxRow>
        <Margin top={30} />
        <FlexBoxRow>
          {orderIdLabel}
          <Margin left={20} />
          {orderIdValue}
        </FlexBoxRow>
        <Margin top={30} />
        {propertyList}
        <Margin top={70} />
        <FlexBoxRow
          height={40}
          justifyContent="center"
          alignItems="center"
          theme={SectionBoxStyle}
        >
          {section2}
        </FlexBoxRow>
        <Margin top={50} />
        {form}
      </FlexBoxColumn>
      <Margin top={120} />
    </FlexBoxColumn>
  );
};
export default ContractTimeDepositRenewalReservationAddTemplateLayout;

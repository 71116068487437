import React, { useState } from 'react';

export interface FormInputCorporateInformation {
  corporateName: string;
  corporateRegistryUrl: string;
}

export const useFormInputCorporateInformation = () => {
  const [formInput, setFormInput] = useState<FormInputCorporateInformation>();

  return {
    formInput,
    setFormInput,
  };
};

export const FormInputCorporateInformationContext = React.createContext<{
  formInput?: FormInputCorporateInformation;
  setFormInput: (formInput?: FormInputCorporateInformation) => void;
}>({
  formInput: undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFormInput: (formInput?: FormInputCorporateInformation) => undefined,
});

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Type
import { TransferDebitCardType } from '../../../../../types/Transfer.type';

// Component
import {
  TextPrimary,
  TextSecondary,
  TextThemeProps,
} from '../../../../atoms/text2/Text2';
import DebitCardFilteredSelectBox from '../../../selectBox/selectBox/debitCard/DebitCardFilteredSelectBox';
import LineBreakReplaced from '../../../../atoms/text2/LineBreakReplaced';
import SavingsAccountSelectBox from '../../../selectBox/selectBox/bankAccount/SavingsAccountSelectBox';
import FormInputTransferFundSourceLayout from './FormInputTransferFundSourceLayout';

// DomainObject
import DebitCardV2s from '../../../../../domain/debitCard/debitCardV2/DebitCardV2s/DebitCardV2s';

const MOLECULE_ID = `molecules.formInputGroup.FormInputTransferFundSource`;

export interface FormInputTransferFundSourceProps {
  debitCards: DebitCardV2s;
  label: string;
  theme: {
    mainLabel: TextThemeProps;
    subLabel: TextThemeProps;
  };
  transferType: TransferDebitCardType;
}

const FormInputTransferFundSource: FC<FormInputTransferFundSourceProps> = ({
  debitCards,
  label,
  theme,
  transferType,
}: FormInputTransferFundSourceProps) => {
  const { t } = useTranslation();

  const [savingsAccountKey, debitCardKey] =
    transferType === TransferDebitCardType.savingsAccountToCard
      ? ['from', 'to']
      : ['to', 'from'];

  return (
    <FormInputTransferFundSourceLayout>
      <TextPrimary key="label" theme={theme.mainLabel}>
        <LineBreakReplaced text={label} />
      </TextPrimary>
      <TextSecondary key="fromLabel" theme={theme.subLabel}>
        {t(`${MOLECULE_ID}.label.from`)}
      </TextSecondary>
      <TextSecondary key="toLabel" theme={theme.subLabel}>
        {t(`${MOLECULE_ID}.label.to`)}
      </TextSecondary>
      <SavingsAccountSelectBox
        key={savingsAccountKey}
        name="savingsAccountNumber"
      />
      <DebitCardFilteredSelectBox
        key={debitCardKey}
        debitCardsFiltered={debitCards}
        name="debitCardId"
      />
    </FormInputTransferFundSourceLayout>
  );
};

export default FormInputTransferFundSource;

import styled from 'styled-components';
import fontWeight from '../../../utils/const/fontWeight';

export const StyledSelect = styled.select`
  border: 1px solid #d6ddec;
  width: 100%;
  outline: none;
  font: ${fontWeight.regular} ${15 / 16}rem LatoRegular;

  height: ${30 / 16}rem;
`;

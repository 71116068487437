import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  width: ${1200 / 16}rem;
  background-color: #fff;
  padding: ${80 / 16}rem;
  display: flex;
  flex-direction: column;
`;

import Joi from '@hapi/joi';
import { AddressProps, AddressPropsFormat } from '../Address/Address.type';

export interface ShippingAddressProps extends AddressProps {
  receiverName: string;
  contactNumber: {
    countryCode: string;
    number: string;
  };
}

export const ShippingAddressPropsFormat = {
  ...AddressPropsFormat,
  receiverName: Joi.string().min(1).required(),
  contactNumber: Joi.object({
    countryCode: Joi.string().min(2).required(),
    number: Joi.string().min(1).required(),
  }),
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface CryptoCurrencyDepositTemplateLayoutProps {
  children: React.ReactElement[];
}

const CryptoCurrencyDepositTemplateLayout: FC<CryptoCurrencyDepositTemplateLayoutProps> = ({
  children,
}: CryptoCurrencyDepositTemplateLayoutProps) => {
  const elements = ['selectBox', 'balance', 'qrcode', 'description'];
  const [selectBox, balance, qrcode, description] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <>
      {selectBox}
      <Margin top={35}>{balance}</Margin>
      <Margin top={65}>{qrcode}</Margin>
      <Margin top={30}>{description}</Margin>
    </>
  );
};
export default CryptoCurrencyDepositTemplateLayout;

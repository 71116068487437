// IUseCase
import IContractTimeDepositViewUseCase from '../../../enhancers/useCase/contract/contractTimeDeposit/ContractTimeDepositViewProvider/IContractTimeDepositViewUseCase';

// IService
import ISavingsAccountQueryService from '../../bankAccount/savingsAccount/ISavingsAccountQueryService';
import IContractTimeDepositQueryService from './interface/IContractTimeDepositQueryService';

// Service
import SavingsAccountQueryService from '../../../service/bankAccount/savingsAccount/SavingsAccountQueryService';
import ContractTimeDepositQueryService from '../../../service/contract/contractTimeDeposit/ContractTimeDepositQueryService';

// IRepository
import { IContractTimeDepositQueryRepository } from '../../../domain/contract/contractTimeDeposit/IContractTimeDepositRepository';
import ISavingsAccountRepository from '../../../domain/bankAccount/savingsAccount/ISavingsAccountRepository';

class ContractTimeDepositViewUseCase
  implements IContractTimeDepositViewUseCase {
  private contractTimeDepositQueryService: IContractTimeDepositQueryService;

  private savingsAccountQueryService: ISavingsAccountQueryService;

  constructor(
    contractTimeDepositRepository: IContractTimeDepositQueryRepository,
    savingsAccountRepository: ISavingsAccountRepository,
  ) {
    this.contractTimeDepositQueryService = new ContractTimeDepositQueryService(
      contractTimeDepositRepository,
    );
    this.savingsAccountQueryService = new SavingsAccountQueryService(
      savingsAccountRepository,
    );
  }

  public open = async (userId: string) => {
    const [contractsTimeDeposit, savingsAccounts] = await Promise.all([
      this.contractTimeDepositQueryService.findAllByUserId(userId),
      this.savingsAccountQueryService.findAllByUserId(userId),
    ]);

    return { contractsTimeDeposit, savingsAccounts };
  };
}
export default ContractTimeDepositViewUseCase;

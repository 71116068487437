import Joi from '@hapi/joi';
import { CurrencyPairType } from '../../../../types/CurrencyPair.type';

export interface CryptoCurrencyTradeFeeRateProps {
  rate: number;

  createdAt?: number;

  updatedAt?: number;
}

export const CryptoCurrencyTradeFeeRatePropsFormat = {
  id: Object.keys(CurrencyPairType)
    .reduce((joi, currencyPair) => joi.valid(currencyPair), Joi.string())
    .required(),

  rate: Joi.number().min(0).required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

import { useState } from 'react';
import CryptoCurrencyRate from './CryptoCurrencyRate';

export const useCryptoCurrencyRate = (defaultValue?: CryptoCurrencyRate) => {
  const [cryptoCurrencyRate, setCryptoCurrencyRate] = useState<
    CryptoCurrencyRate | undefined
  >(defaultValue);

  return {
    cryptoCurrencyRate,
    setCryptoCurrencyRate,
  };
};

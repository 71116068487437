// Type
import { FormInputProofOfResidency } from '../../../../../../components/organisms/form/verification/kycRequest/FormProofOfResidency/FormProofOfResidency.type';
import { FormInputShippingAddress } from '../../../../../../components/organisms/form/verification/kycRequest/FormCorporateAddress/FormCorporateAddress.type';
import { ProofOfResidencyDocumentType } from '../../../../../../types/KycRequest.type';

export const separateFormInput = (
  formInput: FormInputProofOfResidency & FormInputShippingAddress,
) => {
  const {
    country,
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
    documentType,
    proofOfDocumentUrl1,
    proofOfDocumentUrl2,
    ...formInputShippingAddress
  } = formInput;

  const formInputProofOfResidency = {
    country,
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
    documentType,
    proofOfDocumentUrl1,
    proofOfDocumentUrl2,
  };

  return {
    formInputProofOfResidency,
    formInputShippingAddress,
  };
};

export const convertFormInputToProps = (formInput: {
  formInputProofOfResidency: FormInputProofOfResidency;
  formInputShippingAddress: FormInputShippingAddress;
}) => {
  const { formInputProofOfResidency, formInputShippingAddress } = formInput;

  const proofOfResidencyProps = {
    country: formInputProofOfResidency.country,
    addressLine1: formInputProofOfResidency.addressLine1,
    addressLine2: formInputProofOfResidency.addressLine2,
    city: formInputProofOfResidency.city,
    state: formInputProofOfResidency.state,
    postalCode: formInputProofOfResidency.postalCode,
    document: {
      type: formInputProofOfResidency.documentType as ProofOfResidencyDocumentType,
      documentUrl1: formInputProofOfResidency.proofOfDocumentUrl1,
      documentUrl2: formInputProofOfResidency.proofOfDocumentUrl2,
    },
  };

  const shippingAddressProps = {
    country: formInputShippingAddress.shippingCountry,
    addressLine1: formInputShippingAddress.shippingAddressLine1,
    addressLine2: formInputShippingAddress.shippingAddressLine2,
    city: formInputShippingAddress.shippingCity,
    state: formInputShippingAddress.shippingState,
    postalCode: formInputShippingAddress.shippingPostalCode,
    receiverName: formInputShippingAddress.receiverName,
    contactNumber: {
      countryCode: formInputShippingAddress.countryCode,
      number: formInputShippingAddress.phoneNumber,
    },
  };

  return {
    proofOfResidencyProps,
    shippingAddressProps,
  };
};

import Joi from '@hapi/joi';
import { KycStatusType } from '../../../../types/KycRequest.type';
import {
  KycRequestContentProps,
  KycRequestContentPropsFormat,
} from '../../kycRequest/KycRequestContent/KycRequestContent/KycRequestContent.type';

export interface ConsentFormContentUserInput {
  documentUrl: string;
}

export const ConsentFormContentPropsFormat = {
  ...KycRequestContentPropsFormat,

  documentUrl: Joi.string().allow(''),
};

export type ConsentFormContentProps = ConsentFormContentUserInput &
  KycRequestContentProps;

export const ConsentFormContentInitialProps = {
  status: KycStatusType.disabled,

  documentUrl: '',
};

// Type
import {
  AssetDebitCardRawDataType,
  AssetDebitCardPrimaryKey,
} from '../../../domain/asset/assetDebitCard/AssetDebitCard/AssetDebitCard.type';
import { FiatCurrencyType } from '../../../types/FiatCurrency.type';

// Functions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';
import { ASSET_GET_DEBIT_CARD_RAW_DATA_BY_USER_ID_CURRENCY } from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// IRepository
import IAssetDebitCardRepository from '../../../domain/asset/assetDebitCard/IAssetDebitCardRepository';

// DomainObject
import AssetDebitCardFactory from '../../../domain/asset/assetDebitCard/AssetDebitCard/AssetDebitCardFactory';

class AssetDebitCardFunctions
  extends FirebasePrivateFunctions
  implements IAssetDebitCardRepository {
  public findByPrimaryKey = async (params: AssetDebitCardPrimaryKey) => {
    const { userId, currency } = params;

    const endpoint = ASSET_GET_DEBIT_CARD_RAW_DATA_BY_USER_ID_CURRENCY.replace(
      /:userId/,
      userId,
    ).replace(/:currency/, currency);

    const response = await this.privateGET(endpoint);

    const row = response.data.data as AssetDebitCardRawDataType;

    return AssetDebitCardFactory.create(
      row.currency as FiatCurrencyType,
      row.props,
    );
  };
}
export default AssetDebitCardFunctions;

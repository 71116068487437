import Joi from '@hapi/joi';
import { RankType } from '../../../../types/Invite.type';

export interface InvitationCodeProps {
  code: string;

  createdAt?: number;

  updatedAt?: number;
}

export const InvitationCodePropsFormat = {
  rank: Object.entries(RankType)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .reduce((joi, [key, value]) => joi.valid(value), Joi.string())
    .required(),

  code: Joi.string().required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

import React, { FC } from 'react';
import { throttle } from 'lodash';
import { GradationBlueButton, DisabledButton } from './Button';

export interface ActionButtonProps {
  children: React.ReactNode;
  height?: string | number;
  onClick?: (e?: React.MouseEvent) => void;
  disabled?: boolean;
  theme?: {
    borderRadius?: string | number;
  };
  width?: string | number;
}

const ActionButton: FC<ActionButtonProps> = ({
  children,
  width = '100%',
  height = '100%',
  disabled = false,
  onClick = () => undefined,
  theme,
}: ActionButtonProps) => {
  if (disabled)
    return (
      <DisabledButton
        disabled={disabled}
        height={height}
        theme={theme}
        width={width}
      >
        {children}
      </DisabledButton>
    );

  const throttledOnClick = throttle((e) => onClick(e), 1500);

  return (
    <GradationBlueButton
      height={height}
      onClick={throttledOnClick}
      theme={theme}
      width={width}
    >
      {children}
    </GradationBlueButton>
  );
};

export default ActionButton;

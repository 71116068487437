import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Type

import { DebitCardRequestCherryVisa01StepStatusType } from '../../../../types/DebitCardRequestCherryVisa01.type';
// Style
import { HeadLineStyle } from './style';

// Component
import { TextPrimary } from '../../../atoms/text2/Text2';
import { RejectedReason } from '../../../molecules/label/LabeledText';
import DebitCardRequestStatusBadge from '../../../molecules/badge/DebitCardRequestStatusBadge';
import HeaderDebitCardRequestStatusLayout from './HeaderDebitCardRequestStatusLayout';

export interface HeaderDebitCardRequestStatusProps {
  status?: DebitCardRequestCherryVisa01StepStatusType;
  rejectedReason?: string;
}

const ORGANISM_ID = `organisms.header.debitCard.debitCardRequest.headerDebitCardRequestStatus`;

const HeaderDebitCardRequestStatus: FC<HeaderDebitCardRequestStatusProps> = ({
  status,
  rejectedReason,
}: HeaderDebitCardRequestStatusProps) => {
  const { t } = useTranslation();
  if (!status || status !== DebitCardRequestCherryVisa01StepStatusType.rejected)
    return <></>;

  return (
    <HeaderDebitCardRequestStatusLayout
      debitCardRequestStatus={
        DebitCardRequestCherryVisa01StepStatusType.rejected
      }
    >
      <TextPrimary key="title" theme={HeadLineStyle}>
        {t(`${ORGANISM_ID}.label`)}
      </TextPrimary>
      <DebitCardRequestStatusBadge
        key="status"
        status={status}
        layout={{
          height: 36,
          iconWidth: 20,
          iconHeight: 20,
        }}
      />
      <RejectedReason
        key="rejectedReason"
        label={t(`${ORGANISM_ID}.rejectedReason`)}
        value={rejectedReason || ''}
        labelWidth={`${170 / 16}rem`}
      />
    </HeaderDebitCardRequestStatusLayout>
  );
};

export default HeaderDebitCardRequestStatus;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Type
import { UserType } from '../../../../../types/User.type';
import {
  KycStepType,
  KycStatusType,
} from '../../../../../types/KycRequest.type';

// Style
import {
  StepStyle,
  StepNumberStyle,
  TitleStyle,
  DescriptionStyle,
  ButtonTextStyle,
  IconStyle,
} from './style';

// Component
import { TextPrimary, TextSecondary } from '../../../../atoms/text2/Text2';
import KycStatusSquareButton from '../../../../atoms/button/KycStatusSquareButton';
import KycStatusBadge from '../../../../molecules/badge/KycStatusBadge';
import KycStatusCardLayout from './KycStatusCardLayout';
import LineBreakReplaced from '../../../../atoms/text2/LineBreakReplaced';

const ORGANISM_ID = `organisms.card.KycStatusCard`;

export interface KycStatusCardProps {
  order: number;
  userType?: UserType;
  kycStepType: KycStepType;
  status?: KycStatusType;
  onClick: () => void;
}

const KycStatusCard: FC<KycStatusCardProps> = ({
  order,
  userType,
  kycStepType,
  status,
  onClick,
}: KycStatusCardProps) => {
  const { t } = useTranslation();

  return (
    <KycStatusCardLayout status={status} kycStepType={kycStepType}>
      <TextSecondary key="step" theme={StepStyle}>
        {t(`${ORGANISM_ID}.word.step`)}
      </TextSecondary>
      <TextSecondary key="stepNo" theme={StepNumberStyle}>
        {String(order)}
      </TextSecondary>
      <TextPrimary key="title" theme={TitleStyle}>
        {t(`${ORGANISM_ID}.stepDescription.${userType}.${kycStepType}.title`)}
      </TextPrimary>
      <KycStatusBadge
        key="statusBadge"
        status={status as KycStatusType}
        layout={{
          height: 36,
          iconWidth: 20,
          iconHeight: 20,
        }}
      />
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced
          text={t(
            `${ORGANISM_ID}.stepDescription.${userType}.${kycStepType}.description`,
          )}
        />
      </TextSecondary>
      <KycStatusSquareButton
        key="button"
        height="100%"
        marginBetween={10}
        onClick={onClick}
        status={status as KycStatusType}
        theme={{
          text: ButtonTextStyle,
          icon: IconStyle,
        }}
        width="100%"
      />
    </KycStatusCardLayout>
  );
};
export default KycStatusCard;

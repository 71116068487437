import React, { FC } from 'react';
import { DomainTextProps } from '../DomainText.type';
import fontColor from '../../../../../fonts/color.json';
import { TextSubMedium31 } from '../../TextSub';

const TextEmailSentAuthMail: FC<DomainTextProps> = ({
  children,
  color = fontColor.pageTitle,
}: DomainTextProps) => (
  <TextSubMedium31 color={color}>{children}</TextSubMedium31>
);
export default TextEmailSentAuthMail;

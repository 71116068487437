import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  KycRequestCorporateProps,
  KycRequestCorporatePropsFormat,
  KycRequestCorporateInitialProps,
} from './KycRequestCorporate.type';
import { UserType } from '../../../../../types/User.type';

// DomainObject
import UserAuthorized from '../../../../public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

// ValueObject
import KycRequestCorporate from './KycRequestCorporate';

class KycRequestCorporateFactory {
  static create = (id: string, props: KycRequestCorporateProps) => {
    const result = Joi.object(KycRequestCorporatePropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: KycRequestCorporate.name,
          reason: result.error.details[0].message,
        },
      );

    return new KycRequestCorporate(id, props);
  };

  static createInitial = (user: UserAuthorized) => {
    const id = user.getId();
    const { username } = user.getProps();

    return KycRequestCorporateFactory.create(id, {
      username,
      type: UserType.corporate,
      ...KycRequestCorporateInitialProps,
    });
  };
}

export default KycRequestCorporateFactory;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import Margin from '../../../../atoms/utils/layout/Margin';
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../../atoms/utils/layout/Box/FlexBox';
import { ContainerStyle, QRCodeImageContainerStyle } from './style';

export interface CryptoCurrencyAddressQRCodeCardLayoutProps {
  children: React.ReactElement[];
}

const CryptoCurrencyAddressQRCodeCardLayout: FC<CryptoCurrencyAddressQRCodeCardLayoutProps> = ({
  children,
}: CryptoCurrencyAddressQRCodeCardLayoutProps) => {
  const elements = ['description', 'qrcode', 'address'];
  const [description, qrcode, address] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn
      padding="50 120"
      width="100%"
      alignItems="center"
      theme={ContainerStyle}
    >
      {description}
      <Margin top={30}>
        <FlexBoxRow
          padding={20}
          width={256}
          height={256}
          justifyContent="center"
          alignItems="center"
          theme={QRCodeImageContainerStyle}
        >
          {qrcode}
        </FlexBoxRow>
      </Margin>
      <Margin top={30}>{address}</Margin>
    </FlexBoxColumn>
  );
};
export default CryptoCurrencyAddressQRCodeCardLayout;

import Joi from '@hapi/joi';
import { UserProps, UserPropsFormat } from '../User/User.type';
import {
  UserType,
  VerifiedUserStatusType,
} from '../../../../../types/User.type';

export type UserReregistrationProps = UserProps;

export const UserReregistrationPropsFormat = {
  ...UserPropsFormat,

  contractName: Joi.string().min(1).max(255).required(),

  status: Object.keys(VerifiedUserStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),

  type: Object.keys(UserType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),
};

import Joi from '@hapi/joi';

// Type
import { DeliveryTrackingStatusType } from '../../../../types/DebitCardRequestCherryVisa01.type';

export interface DeliveryTrackingInformationProps {
  status: DeliveryTrackingStatusType;
  trackingNumber: string;
  trackingUrl: string;
  carrier: string;
}

export type DeliveryTrackingInformationRawDataType = DeliveryTrackingInformationProps;

export const DeliveryTrackingInformationPropsFormat = {
  status: Object.keys(DeliveryTrackingStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),
  trackingNumber: Joi.string().empty(''),
  trackingUrl: Joi.string().empty(''),
  carrier: Joi.string().empty(''),
};

export const deliveryTrackingInformationInitialProps = {
  status: DeliveryTrackingStatusType.pending,
  trackingNumber: '',
  trackingUrl: '',
  carrier: '',
};

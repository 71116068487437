import React, { FC } from 'react';
import { Container } from './style';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

export interface TimeDepositAddFormLayoutProps {
  children: React.ReactElement[];
}

const FormContractTimeDepositAddFormLayout: FC<TimeDepositAddFormLayoutProps> = ({
  children,
}: TimeDepositAddFormLayoutProps) => {
  const elements = ['twoFactorAuth', 'button'];
  const [twoFactorAuth, button] = getElementsFromKeys(children, elements);

  return (
    <Container>
      <div style={{ width: '100%' }}>{twoFactorAuth}</div>
      <div style={{ marginTop: `${50 / 16}rem` }}>{button}</div>
    </Container>
  );
};

export default FormContractTimeDepositAddFormLayout;

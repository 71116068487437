import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import {
  convertRemWidth,
  convertRemPadding,
} from '../../../../atoms/utils/helper/helper';

// Component
import Tr from '../../../../atoms/table/Table/Tr';
import Th from '../../../../atoms/table/Table/Th';
import { TdBorderBottom } from '../../../../atoms/table/Table/Td';

export interface TrTableVerticalLayoutProps {
  children: React.ReactElement[];
  rowHeight: number | string;
  layout: {
    th: {
      width: number | string;
      padding?: string;
    };
    tdCurrency: {
      width: number | string;
      padding?: string;
    };
    tdAmount: {
      width: number | string;
      padding?: string;
    };
  };
  theme: {
    th: {
      backgroundColor: string;
      borderBottom?: string;
      textAlign?: string;
    };
    tdCurrency: {
      backgroundColor: string;
      borderBottom?: string;
      textAlign?: string;
    };
    tdAmount: {
      backgroundColor: string;
      borderBottom?: string;
      textAlign?: string;
    };
  };
}

const TrTableVerticalLayout: FC<TrTableVerticalLayoutProps> = ({
  children,
  rowHeight,
  layout,
  theme,
}: TrTableVerticalLayoutProps) => {
  const elements = ['header', 'currency', 'amount'];
  const [header, currency, amount] = getElementsFromKeys(children, elements);

  return (
    <Tr style={{ textAlign: 'right' }}>
      <Th
        minWidth={layout.th.width}
        height={rowHeight}
        theme={{
          ...theme.th,
          ...convertRemWidth(layout.th),
        }}
        style={{ ...convertRemPadding(layout.th) }}
        verticalAlign="middle"
      >
        {header}
      </Th>
      <TdBorderBottom
        theme={{
          ...theme.tdCurrency,
          ...convertRemWidth(layout.tdCurrency),
        }}
        style={{ ...convertRemPadding(layout.tdCurrency) }}
        width={layout.tdCurrency.width}
        verticalAlign="middle"
      >
        {currency}
      </TdBorderBottom>
      <TdBorderBottom
        theme={{
          ...theme.tdAmount,
          ...convertRemWidth(layout.tdAmount),
        }}
        style={{ ...convertRemPadding(layout.tdAmount) }}
        width={layout.tdAmount.width}
        verticalAlign="middle"
      >
        {amount}
      </TdBorderBottom>
    </Tr>
  );
};
export default TrTableVerticalLayout;

import React, { FC } from 'react';

// Style
import { DescriptionStyle } from './style';

// Component
import { TextSecondary } from '../../../atoms/text2/Text2';
import IconCheckMarkCircle, {
  IconCheckMarkCircleProps,
} from '../../../atoms/icon/IconCheckMarkCircle';
import CheckPointItemLayout from './CheckPointItemLayout';

export type CheckPointItemProps = {
  text: string;
  marginBetween: string | number;
  iconProps: IconCheckMarkCircleProps;
};

const CheckPointItem: FC<CheckPointItemProps> = ({
  text,
  marginBetween,
  iconProps,
}: CheckPointItemProps) => {
  return (
    <CheckPointItemLayout marginBetween={marginBetween}>
      <IconCheckMarkCircle key="icon" {...iconProps} />
      <TextSecondary key="text" theme={DescriptionStyle}>
        {text}
      </TextSecondary>
    </CheckPointItemLayout>
  );
};
export default CheckPointItem;

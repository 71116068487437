import React, { FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useContractTimeDepositRenewalReservationDeleteUseCase } from './useContractTimeDepositDeleteRenewalReservationUseCase';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

// Context
import ContractTimeDepositRenewalReservationDeleteContext from './ContractTimeDepositRenewalReservationDeleteContext';
import ContractTimeDepositContext from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDepositContext';
import { FormInputContractTimeDepositRenewalReservationContext } from '../../../../../components/organisms/form/contract/contractTimeDeposit/FormContractTimeDepositRenewalReservation/FormContractTimeDepositRenewalReservation.type';
import TimeDepositContext from '../../../../../domain/product/timeDeposit/TimeDeposit/TimeDepositContext';
import UserVerifiedContext from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedContext';

// Constant
import { HOURS_TIME_LIMIT_RESERVATION_CHANGE_CONTRACT_TIME_DEPOSIT } from '../../../../../config/constants/business';
import { ROUTE_TIME_DEPOSIT } from '../../../../../config/constants/routing';

export type ContractTimeDepositRenewalReservationDeleteProviderProps = {
  children: React.ReactNode;
};

const ContractTimeDepositRenewalReservationDeleteProvider: FC<ContractTimeDepositRenewalReservationDeleteProviderProps> = ({
  children,
}: ContractTimeDepositRenewalReservationDeleteProviderProps) => {
  const { user } = useContext(UserVerifiedContext);
  const { contractTimeDeposit } = useContext(ContractTimeDepositContext);
  const history = useHistory();

  const {
    state,
    setState,
    timeDeposit,
    formInputContractTimeDepositRenewalReservationContext,
    deleteContractTimeDepositRenewalReservation,
  } = useContractTimeDepositRenewalReservationDeleteUseCase(
    user,
    contractTimeDeposit,
  );

  /* *
   *
   *  Redirect
   *
   * */
  useEffect(() => {
    if (!contractTimeDeposit) return;

    // Redirect if unauthorized Access occurs
    if (
      contractTimeDeposit.isMaturedWithInHours(
        HOURS_TIME_LIMIT_RESERVATION_CHANGE_CONTRACT_TIME_DEPOSIT,
      )
    )
      history.push(ROUTE_TIME_DEPOSIT);
  }, [contractTimeDeposit, history]);

  return (
    <FormInputContractTimeDepositRenewalReservationContext.Provider
      value={formInputContractTimeDepositRenewalReservationContext}
    >
      <ContractTimeDepositRenewalReservationDeleteContext.Provider
        value={{
          requestState: state,
          deleteContractTimeDepositRenewalReservation,
        }}
      >
        <TimeDepositContext.Provider value={{ timeDeposit }}>
          <SystemErrorHandler state={state} setState={setState}>
            {children}
          </SystemErrorHandler>
        </TimeDepositContext.Provider>
      </ContractTimeDepositRenewalReservationDeleteContext.Provider>
    </FormInputContractTimeDepositRenewalReservationContext.Provider>
  );
};

export default ContractTimeDepositRenewalReservationDeleteProvider;

import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedPageViewProvider from '../enhancers/useCase/authentication/AuthenticatedPageViewProvider';
import AccessHandlerUserStatusProvider from '../enhancers/routing/AccessHandlerUserStatusProvider';

// Type
import { MenuType } from '../config/menu/main/Menu.type';

// Constant
import { ALLOWED_USER_STATUS } from './accessHandler/userStatusPageAccessMapper';
import {
  ROUTE_TRANSFER_SAVING_ACCOUNT,
  ROUTE_TRANSFER_DEBIT_CARD,
} from '../config/constants/routing';

// Component
import { EnhancedTransferSavingAccountPage } from '../containers/transfer/TransferSavingsAccountPage';
import { EnhancedTransferDebitCardPage } from '../containers/transfer/TransferDebitCardPage';

const TransferRouter: FC = () => {
  return (
    <Switch>
      <AuthenticatedPageViewProvider>
        <AccessHandlerUserStatusProvider
          statusAllowed={ALLOWED_USER_STATUS[MenuType.transfer]}
        >
          <Route exact path={ROUTE_TRANSFER_SAVING_ACCOUNT}>
            <EnhancedTransferSavingAccountPage />
          </Route>
          <Route exact path={ROUTE_TRANSFER_DEBIT_CARD}>
            <EnhancedTransferDebitCardPage />
          </Route>
        </AccessHandlerUserStatusProvider>
      </AuthenticatedPageViewProvider>
    </Switch>
  );
};

export default TransferRouter;

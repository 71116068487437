// Type
import { ConsentFormContentProps } from './ConsentFormContent.type';

// ValueObject
import KycRequestContent from '../../kycRequest/KycRequestContent/KycRequestContent/KycRequestContent';

class ConsentFormContent extends KycRequestContent {
  private documentUrl: string;

  constructor(props: ConsentFormContentProps) {
    super(props);
    this.documentUrl = props.documentUrl;
  }

  public getProps() {
    return {
      ...super.getProps(),
      documentUrl: this.documentUrl,
    };
  }

  public getCommandParameter = () => ({
    ...super.getCommandParameter(),
    documentUrl: this.documentUrl,
  });

  public updateProps = (props: ConsentFormContentProps) => {
    return new ConsentFormContent({
      ...this.getProps(),
      ...props,
    });
  };
}
export default ConsentFormContent;

// FirebaseFunctions
import FirebasePrivateFunctions from 'infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';
import { TRANSFER_ECB_BANK_ACCOUNT } from 'infrastructure/firebase/firebaseFunctions/endpoint';

// IRepository
import { ITransferSavingsAccountTransactionCreateToECBRepository } from 'domain/transaction/transferSavingsAccountTransaction/ITransferSavingsAccountTransactionRepository';

// DomainObject
import TransferSavingsAccountTransactionSeed from 'domain/transaction/transferSavingsAccountTransaction/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed';
import TransferSavingsAccountTransactionFactory from 'domain/transaction/transferSavingsAccountTransaction/TransferSavingsAccountTransaction/TransferSavingsAccountTransaction/TransferSavingsAccountTransactionFactory';

class TransferSavingsAccountTransactionToECBFunctions
  extends FirebasePrivateFunctions
  implements ITransferSavingsAccountTransactionCreateToECBRepository {
  public createECB = async (
    transferSavingsAccountTransactionSeed: TransferSavingsAccountTransactionSeed,
  ) => {
    const props = transferSavingsAccountTransactionSeed.getRequestParameterCreate();

    const endpoint = TRANSFER_ECB_BANK_ACCOUNT;

    const response = await this.privatePOST(endpoint, props);

    return TransferSavingsAccountTransactionFactory.createFromSeed(
      transferSavingsAccountTransactionSeed,
      response.data.transferId,
    );
  };
}
export default TransferSavingsAccountTransactionToECBFunctions;

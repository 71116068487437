import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initializeUserAuthorizedAction } from '../../../../redux/actions/signInUserActions';

export type SignInViewProviderProps = {
  children: React.ReactNode;
};

const SignInViewProvider: FC<SignInViewProviderProps> = ({
  children,
}: SignInViewProviderProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeUserAuthorizedAction());
    // eslint-disable-next-line
  }, []);

  return <>{children}</>;
};
export default SignInViewProvider;

import Joi from '@hapi/joi';

// Error
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import {
  CryptoCurrencyTradeLimitProps,
  CryptoCurrencyTradeLimitPropsFormat,
} from './CryptoCurrencyTradeLimit.type';
import { CurrencyPairType } from '../../../../../types/CurrencyPair.type';

// DomainObject
import CryptoCurrencyTradeLimit from './CryptoCurrencyTradeLimit';

class CryptoCurrencyTradeLimitFactory {
  static create = (
    id: CurrencyPairType,
    props: CryptoCurrencyTradeLimitProps,
  ) => {
    const result = Joi.object(CryptoCurrencyTradeLimitPropsFormat).validate({
      id,
      ...props,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: CryptoCurrencyTradeLimit.name,
          reason: result.error.details[0].message,
        },
      );

    return new CryptoCurrencyTradeLimit(id, props);
  };
}
export default CryptoCurrencyTradeLimitFactory;

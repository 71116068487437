import { useState } from 'react';

// Lib
import { omit } from 'lodash';
import { executeWithTimeout } from 'utils/helpers/timeout';
import { checkInternetConnection } from 'utils/helpers/connection';

// Error
import ErrorHandler from 'utils/errors/ErrorHandler/ErrorHandler';

// Types
import {
  FormInputTransferSavingsAccount,
  useFormInputTransferSavingsAccount,
} from 'components/organisms/form/transfer/savingsAccount/FormTransferSavingsAccount/FormInputTransferSavingsAccount.type';

// Constant
import { REQUEST_TIMEOUT } from 'config/constants/business';
import { INITIAL, IS_LOADING, SUCCESS } from 'config/constants/requestState';

// UseCase
import TransferSavingsAccountCreateUseCase from 'useCases/transfer/savingsAccount/TransferSavingsAccountCreateUseCase';

// Repository & API
import SavingsAccountFunctions from 'repository/bankAccount/savingsAccount/SavingsAccountFunctions';
import AuthenticatorFunctions from 'repository/authenticator/AuthenticatorFunctions';
import TransferSavingsAccountTransactionFunctions from 'repository/transfer/TransferSavingsAccountTransactionFunctions';
import TransferSavingsAccountTransactionToECBFunctions from 'repository/transfer/TransferSavingsAccountTransactionToECBFunctions';

// DomainObject
import { useTransferSavingsAccountTransactionSeed } from 'domain/transaction/transferSavingsAccountTransaction/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed/useTransferSavingsAccountTransactionSeed';
import { useTransferSavingsAccountTransaction } from 'domain/transaction/transferSavingsAccountTransaction/TransferSavingsAccountTransaction/TransferSavingsAccountTransaction/useTransferSavingsAccountTransaction';
import SavingsAccount from 'domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';
import UserVerified from 'domain/public/user/User/UserAuthorized/UserVerified/UserVerified';

export const useTransferSavingsAccountCreateUseCase = (user?: UserVerified) => {
  const [state, setState] = useState<string>(INITIAL);
  const formInputTransferSavingsAccountContext = useFormInputTransferSavingsAccount();

  /* *
   *
   *  DomainObject
   *
   * */
  const {
    transferSavingsAccountTransactionSeed,
    setTransferSavingsAccountTransactionSeed,
  } = useTransferSavingsAccountTransactionSeed();

  const {
    transferSavingsAccountTransaction,
    setTransferSavingsAccountTransaction,
  } = useTransferSavingsAccountTransaction();

  /* *
   *
   *  Repository
   *
   * */
  const savingsAccountQueryAPI = new SavingsAccountFunctions();
  const authenticatorVerifier = new AuthenticatorFunctions();
  const transferSavingsAccountTransactionCreateRepository = new TransferSavingsAccountTransactionFunctions();
  const transferSavingsAccountTransactionToECBCreateRepository = new TransferSavingsAccountTransactionToECBFunctions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new TransferSavingsAccountCreateUseCase(
    transferSavingsAccountTransactionCreateRepository,
    transferSavingsAccountTransactionToECBCreateRepository,
    savingsAccountQueryAPI,
    authenticatorVerifier,
  );

  /* *
   *
   *  Methods
   *
   * */
  const createTransferSavingsAccount = async (
    formInput: FormInputTransferSavingsAccount,
    savingsAccount: SavingsAccount,
    callback?: () => void,
  ) => {
    setState(IS_LOADING);

    try {
      checkInternetConnection();

      const props = omit(formInput, ['savingsAccountNumber']);

      const output = await useCase.create({
        savingsAccount,
        ...props,
        amount: Number(props.amount.replace(/,/g, '')),
        beneficiaryAccountNumber: props.beneficiaryAccountNumber.replace(
          /-/g,
          '',
        ),
      });

      setTransferSavingsAccountTransactionSeed(
        output.transferSavingsAccountTransactionSeed,
      );

      if (callback) callback();

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  // UseCase.executeCreate
  const executeCreateTransferSavingsAccount = async (
    code: string,
    callback?: () => void,
  ) => {
    setState(IS_LOADING);

    try {
      checkInternetConnection();

      const output = await executeWithTimeout(
        useCase.executeCreate(
          user!,
          transferSavingsAccountTransactionSeed!,
          code,
        ),
        REQUEST_TIMEOUT,
      );

      setTransferSavingsAccountTransaction(
        output.transferSavingsAccountTransaction,
      );

      if (callback) callback();

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  return {
    state,
    setState,
    createTransferSavingsAccount,
    executeCreateTransferSavingsAccount,
    transferSavingsAccountTransactionSeed,
    transferSavingsAccountTransaction,
    formInputTransferSavingsAccountContext,
  };
};

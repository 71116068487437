import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  ProofOfResidencyProps,
  ProofOfResidencyPropsFormat,
  ProofOfResidencyInitialProps,
} from './ProofOfResidency.type';

// ValueObject
import ProofOfResidency from './ProofOfResidency';

class ProofOfResidencyFactory {
  static create = (props: ProofOfResidencyProps) => {
    const result = Joi.object(ProofOfResidencyPropsFormat).validate(props);

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: ProofOfResidency.name,
          reason: result.error.details[0].message,
        },
      );

    return new ProofOfResidency(props);
  };

  static createInitial = () => {
    return ProofOfResidencyFactory.create(ProofOfResidencyInitialProps);
  };
}

export default ProofOfResidencyFactory;

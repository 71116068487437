import React from 'react';
import CryptoCurrencyDeposit from './CryptoCurrencyDeposit';
import { UseStateType } from '../../../../../../../types/typeof/UseState';

export interface CryptoCurrencyDepositContextProps {
  cryptoCurrencyDeposit: CryptoCurrencyDeposit | undefined;
  setCryptoCurrencyDeposit: UseStateType<CryptoCurrencyDeposit | undefined>;
}

const CryptoCurrencyDepositContext = React.createContext<
  CryptoCurrencyDepositContextProps
>({
  cryptoCurrencyDeposit: undefined,
  setCryptoCurrencyDeposit: () => undefined,
});

export default CryptoCurrencyDepositContext;

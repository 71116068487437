import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';

// Layout Component
import { FlexBoxColumn } from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface KycExampleImageDescriptionCardLayoutProps {
  children: React.ReactElement[];
}

const KycExampleImageDescriptionCardLayout: FC<KycExampleImageDescriptionCardLayoutProps> = ({
  children,
}: KycExampleImageDescriptionCardLayoutProps) => {
  const elements = ['image', 'description'];
  const [image, description] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn>
      {image}
      <Margin top={20} />
      {description}
    </FlexBoxColumn>
  );
};

export default KycExampleImageDescriptionCardLayout;

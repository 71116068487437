// React
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import {
  TextStyle,
  CancelButtonStyle,
} from 'components/molecules/table/td/TransferDebitCardTransactionHistoryActionTdBox/style';

// Components
import { FlexBoxColumn } from 'components/atoms/utils/layout/Box/FlexBox';
import { CancelButton } from 'components/atoms/button/Button2';
import { TextPrimary } from 'components/atoms/text2/Text2';

export interface TransferDebitCardTransactionHistoryActionTdBoxProps {
  isShowAction: boolean;
  onClick: () => void;
}

const TransferDebitCardTransactionHistoryActionTdBox: FC<TransferDebitCardTransactionHistoryActionTdBoxProps> = ({
  onClick,
  isShowAction,
}: TransferDebitCardTransactionHistoryActionTdBoxProps) => {
  const { t } = useTranslation();

  if (!isShowAction) return <></>;

  return (
    <FlexBoxColumn justifyContent="center" alignItems="center">
      <CancelButton
        key="detail"
        height={40}
        width={40 * 1.618}
        theme={CancelButtonStyle}
        onClick={onClick}
      >
        <TextPrimary theme={TextStyle}>{t(`atoms.button.cancel`)}</TextPrimary>
      </CancelButton>
    </FlexBoxColumn>
  );
};

export default TransferDebitCardTransactionHistoryActionTdBox;

import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  height: ${40 / 16}rem;
  padding: ${10 / 16}rem ${15 / 16}rem;
  border-radius: ${5 / 16}rem;
  display: flex;
  align-items: center;
`;

export const IconWrap = styled.div`
  width: ${23 / 16}rem;
  height: ${23 / 16}rem;
  display: flex;
  align-items: center;
`;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxRow } from '../../../atoms/utils/layout/Box/FlexBox';

export interface LabeledNameLayoutProps {
  children: React.ReactElement[];
}

const LabeledNameLayout: FC<LabeledNameLayoutProps> = ({
  children,
}: LabeledNameLayoutProps) => {
  const elements = ['payCurrency', 'arrow', 'receiveCurrency'];
  const [payCurrency, arrow, receiveCurrency] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxRow alignItems="center" justifyContent="center">
      {payCurrency}
      {arrow}
      {receiveCurrency}
    </FlexBoxRow>
  );
};
export default LabeledNameLayout;

/* eslint-disable @typescript-eslint/no-unused-vars */
// React
import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import CardShippingInformationModalContext from './CardShippingInformationModalContext';

// Style
import {
  CancelButtonTextStyle,
  contentStyle,
  overlayStyle,
  TitleStyle,
} from './style';

// Components
import CardShippingInformationModalLayout from './CardShippingInformationModalLayout';
import { CancelButton } from '../../../atoms/button/Button2';
import { TextPrimary } from '../../../atoms/text2/Text2';
import TableCardDeliveryStatus from '../../table/confirm/TableCardDeliveryStatus';

const ORGANISM_ID = `organisms.modal.CardShippingInformationModal`;

const CardShippingInformationModal = () => {
  const { t } = useTranslation();
  const { hideModal } = useContext(CardShippingInformationModalContext);

  return (
    <ReactModal
      isOpen={true}
      style={{ content: contentStyle, overlay: overlayStyle }}
      ariaHideApp={false}
    >
      <CardShippingInformationModalLayout>
        <TextPrimary key="title" theme={TitleStyle}>
          {t(`${ORGANISM_ID}.title`)}
        </TextPrimary>
        <TableCardDeliveryStatus key="table" />
        <CancelButton key="button" width={470} height={75} onClick={hideModal}>
          <TextPrimary theme={CancelButtonTextStyle}>
            {t(`atoms.button.cancel`)}
          </TextPrimary>
        </CancelButton>
      </CardShippingInformationModalLayout>
    </ReactModal>
  );
};

export default CardShippingInformationModal;

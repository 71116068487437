import CryptoCurrencyTradeTransaction from '../CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction';
import { IIterable } from '../../../../../../interface/Iterator/IIterable';

class CryptoCurrencyTradeTransactions
  implements IIterable<CryptoCurrencyTradeTransaction> {
  private cryptoCurrencyTradeTransactions: CryptoCurrencyTradeTransaction[] = [];

  constructor(
    cryptoCurrencyTradeTransactions?: CryptoCurrencyTradeTransaction[],
  ) {
    this.cryptoCurrencyTradeTransactions =
      cryptoCurrencyTradeTransactions || [];
  }

  public add = (
    cryptoCurrencyTradeTransaction: CryptoCurrencyTradeTransaction,
  ) => {
    this.cryptoCurrencyTradeTransactions.push(cryptoCurrencyTradeTransaction);
  };

  public head = () => this.cryptoCurrencyTradeTransactions[0];

  public map = <T>(
    callback: (
      cryptoCurrencyTradeTransaction: CryptoCurrencyTradeTransaction,
    ) => T,
  ) => {
    return this.cryptoCurrencyTradeTransactions.map(
      (cryptoCurrencyTradeTransaction) =>
        callback(cryptoCurrencyTradeTransaction),
    );
  };

  public forEach = (
    callback: (
      cryptoCurrencyTradeTransaction: CryptoCurrencyTradeTransaction,
    ) => void,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const cryptoCurrencyTradeTransaction of this
      .cryptoCurrencyTradeTransactions) {
      // eslint-disable-next-line no-await-in-loop
      callback(cryptoCurrencyTradeTransaction);
    }
  };

  public forEachAsync = async (
    callback: (
      cryptoCurrencyTradeTransaction: CryptoCurrencyTradeTransaction,
    ) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const cryptoCurrencyTradeTransaction of this
      .cryptoCurrencyTradeTransactions) {
      // eslint-disable-next-line no-await-in-loop
      await callback(cryptoCurrencyTradeTransaction);
    }
  };

  public sortDescByCreatedAt = () => {
    const compare = (
      a: CryptoCurrencyTradeTransaction,
      b: CryptoCurrencyTradeTransaction,
    ) => {
      const aCreatedAt = a.getProps().createdAt!;
      const bCreatedAt = b.getProps().createdAt!;

      return aCreatedAt < bCreatedAt ? 1 : -1;
    };
    return new CryptoCurrencyTradeTransactions(
      this.cryptoCurrencyTradeTransactions.sort(compare),
    );
  };
}
export default CryptoCurrencyTradeTransactions;

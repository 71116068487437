import { useCallback, useEffect, useMemo, useState } from 'react';
import { checkInternetConnection } from '../../../../../utils/helpers/connection';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import ProofOfResidencyViewUseCase from '../../../../../useCases/verification/kycRequest/ProofOfResidencyViewUseCase';

// Repository
import DocumentTypeFirestore from '../../../../../repository/settings/documentType/DocumentTypeFirestore';
import KycRequestFirestore from '../../../../../repository/verification/kycRequest/KycRequestFirestore';

// DomainObject
import { useKycRequest } from '../../../../../domain/verification/kycRequest/KycRequest/KycRequest/useKycRequest';
import { useDocumentTypes } from '../../../../../domain/settings/documentType/DocumentType/DocumentTypes/useDocumentTypes';
import UserAuthorized from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useProofOfResidencyViewUseCase = (
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  DomainObject
   *
   * */
  const { kycRequest, setKycRequest } = useKycRequest();

  const { documentTypes, setDocumentTypes } = useDocumentTypes();

  // Sort
  const documentTypesSorted = useMemo(() => documentTypes.sortAscByPriority(), [
    documentTypes,
  ]);

  /* *
   *
   *  Repository
   *
   * */
  const kycRequestRepository = new KycRequestFirestore();
  const documentTypeRepository = new DocumentTypeFirestore();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new ProofOfResidencyViewUseCase(
    documentTypeRepository,
    kycRequestRepository,
  );

  /* *
   *
   *  Method
   *
   * */
  const open = useCallback(async () => {
    if (!userAuthorized) return;

    setState(IS_LOADING);
    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const output = await useCase.open(userAuthorized);

      setKycRequest(output.kycRequest);
      setDocumentTypes(output.documentTypes);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized]);

  /* *
   *
   *  UseCase
   *
   * */
  useEffect(() => {
    let isMounted = true;

    if (userAuthorized && isMounted) open();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [userAuthorized]);

  return {
    state,
    setState,
    kycRequest,
    documentTypes: documentTypesSorted,
  };
};

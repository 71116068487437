import Joi from '@hapi/joi';
import {
  SignUpRequestProps,
  SignUpRequestPropsFormat,
} from './SignUpRequest.type';
import SignUpRequest from './SignUpRequest';

// Error
import InvalidDataFoundError from '../../../../utils/errors/InvalidDataFoundError';

class SignUpRequestFactory {
  static create = (id: string, props: SignUpRequestProps) => {
    const result = Joi.object(SignUpRequestPropsFormat).validate({
      ...props,
      email: id,
    });

    if (result.error)
      throw new InvalidDataFoundError(
        `Creation of SignUpRequest is rejected. The reason is ${result.error.details[0].message}`,
      );

    return new SignUpRequest(id, props);
  };
}

export default SignUpRequestFactory;

import TransactionSerialNumber from '../../../../value/id/serialNumber/TransactionSerialNumber';
import { TransactionIdProps } from './TransactionId.type';

abstract class TransactionId {
  protected prefix: string;

  protected date: string; // YYMMDD

  protected target: string;

  protected serialNumber: TransactionSerialNumber;

  protected commonId: string;

  protected props: TransactionIdProps;

  constructor(props: TransactionIdProps) {
    this.props = props;
    this.prefix = props.prefix;
    this.date = props.date;
    this.target = props.target;
    this.commonId = props.commonId;
    this.serialNumber = new TransactionSerialNumber(props.serialNumber);
  }

  public getProps() {
    return this.props;
  }

  public getCommonId = () => this.commonId;

  public getSerialNumber = () => this.serialNumber;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected abstract getPropsOrderForId: () => any[];

  // Return `${prefix}-${currency}-${date}-${serialNumber}-${target}
  public getFormattedId = () => {
    const propsOrder = this.getPropsOrderForId();
    const formattedId = propsOrder.reduce<string>((id, prop) => {
      return id === '' ? prop : `${id}-${prop}`;
    }, '');
    return formattedId;
  };

  // Return `${prefix}${currency}${date}${serialNumber}${target}
  public getRawId = () => {
    const propsOrder = this.getPropsOrderForId();
    const formattedId = propsOrder.reduce((id, prop) => {
      return `${id}${prop}`;
    }, '');
    return formattedId;
  };
}
export default TransactionId;

import { useState } from 'react';
import { TransactionType } from '../../../../../../types/Transaction.type';

export const useBankTransactionOption = (type?: TransactionType) => {
  const [transactionOption, setTransactionOption] = useState<TransactionType>(
    type || TransactionType.deposit,
  );

  return {
    transactionOption,
    setTransactionOption,
  };
};

import Joi from '@hapi/joi';

// Type
import { UserProps } from '../User/User.type';
import {
  UserReregistrationProps,
  UserReregistrationPropsFormat,
} from './UserReregistration.type';

// Domain Object
import User from '../User/User';
import UserReregistration from './UserReregistration';

// Error
import InvalidDataFoundError from '../../../../../utils/errors/InvalidDataFoundError';

class UserReregistrationFactory {
  static create = (id: string, props: UserProps) => {
    const result = Joi.object(UserReregistrationPropsFormat).validate({
      ...props,
      id,
    });

    if (result.error)
      throw new InvalidDataFoundError(
        `Creation of UserReregistration is rejected. The reason is ${result.error.details[0].message}`,
      );

    return new UserReregistration(id, props as UserReregistrationProps);
  };

  static createByUser = (user: User) => {
    return UserReregistrationFactory.create(user.getId(), user.getProps());
  };
}

export default UserReregistrationFactory;

import { required } from '../validator';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const twoFactorAuthRegister = (field: string, t: any) => {
  switch (field) {
    case 'code':
      return {
        required: required('Code', t),
      };
    default:
      return undefined;
  }
};

export default twoFactorAuthRegister;

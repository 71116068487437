import React, { FC, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import RegisterContext from '../../../../../enhancers/useCase/authentication/RegisterProvider/RegisterContext';
import SignUpRequestContext from '../../../../../domain/userRequest/signUpRequest/SignUpRequest/SignUpRequestContext';

// Lib
import { deleteFullWidth } from '../../../../../utils/helpers/stringHelper';
import {
  registerUserNameRegister,
  registerPasswordRegister,
  registerConfirmPasswordRegister,
} from '../../../../../utils/validation/registers';

// Constant
import { IS_LOADING } from '../../../../../config/constants/requestState';
import { REGISTER } from '../../../../../config/constants/pageId.json';
import { ErrorCodeType } from '../../../../../utils/errors/ErrorHandler';

// Style
import { LabelStyle } from './style';

// Component
import FormInputTextLabelColumn from '../../../../molecules/form/formInput/textField/FormInputTextLabelColumn';
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../atoms/button/Button2';
import { TextPrimary } from '../../../../atoms/text2/Text2';
import FormRegisterLayout from './FormRegisterLayout';

export interface FormRegisterInput {
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
}

const FormRegister: FC = () => {
  const { t } = useTranslation();
  const { state, registerUser } = useContext(RegisterContext);
  const { signUpRequest } = useContext(SignUpRequestContext);

  const methods = useForm<FormRegisterInput & { usernameClientError: string }>({
    mode: 'onChange',
  });

  const {
    clearErrors,
    errors,
    formState: { isValid },
    handleSubmit,
    setValue,
    register,
    setError,
    trigger,
    watch,
  } = methods;

  useEffect(() => {
    if (state === ErrorCodeType.AUTHENTICATION_SIGN_UP_USERNAME_EXISTED)
      setError('usernameClientError', {
        type: '',
        message: t(`validation.${state}`),
      });
  }, [state, setError, t]);

  if (!signUpRequest) return <></>;

  const email = signUpRequest.getId();

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit((formInput: FormRegisterInput) =>
          registerUser(formInput),
        )}
      >
        <FormRegisterLayout>
          <FormInputTextLabelColumn
            key="email"
            disabled={true}
            label={t(`${REGISTER}.form.email.label`)}
            labelStyle={LabelStyle}
            name="email"
            maxLength={255}
            marginBetween={12}
            width={400}
            value={email}
          />
          <FormInputTextLabelColumn
            key="username"
            errorMessage={
              (errors.username && errors.username.message) ||
              (errors.usernameClientError && errors.usernameClientError.message)
            }
            label={t(`${REGISTER}.form.username.label`)}
            labelStyle={LabelStyle}
            name="username"
            maxLength={20}
            onChange={(text: string) => {
              clearErrors('usernameClientError');
              setValue('username', deleteFullWidth(text));
            }}
            marginBetween={12}
            register={register(registerUserNameRegister(t))}
            width={400}
            value={watch(`username`)}
          />
          <FormInputTextLabelColumn
            key="password"
            errorMessage={errors.password && errors.password.message}
            label={t(`${REGISTER}.form.password.label`)}
            labelStyle={LabelStyle}
            name="password"
            maxLength={20}
            onChange={(text: string) => {
              clearErrors('usernameClientError');
              trigger(['password', 'confirmPassword']);
              setValue('password', deleteFullWidth(text));
            }}
            marginBetween={12}
            register={register(registerPasswordRegister(t))}
            type="password"
            width={400}
            value={watch(`password`)}
          />
          <FormInputTextLabelColumn
            key="confirmPassword"
            errorMessage={
              errors.confirmPassword && errors.confirmPassword.message
            }
            label={t(`${REGISTER}.form.confirmPassword.label`)}
            labelStyle={LabelStyle}
            name="confirmPassword"
            maxLength={20}
            onChange={(text: string) => {
              clearErrors('usernameClientError');
              trigger(['password', 'confirmPassword']);
              setValue('confirmPassword', deleteFullWidth(text));
            }}
            marginBetween={12}
            register={register(
              registerConfirmPasswordRegister(t, watch('password')),
            )}
            type="password"
            width={400}
            value={watch(`confirmPassword`)}
          />
          <SubmitButton
            key="button"
            disabled={!isValid}
            height={50}
            isLoading={state === IS_LOADING}
            theme={{ borderRadius: 25 }}
            sizeProgress={20}
            width={165}
          >
            <TextPrimary theme={DefaultButtonTextStyle}>
              {t(`atoms.button.submit`)}
            </TextPrimary>
          </SubmitButton>
        </FormRegisterLayout>
      </form>
    </FormProvider>
  );
};

export default FormRegister;

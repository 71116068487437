import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import Margin from '../../../../atoms/utils/layout/Margin';
import {
  FlexBoxRow,
  FlexBoxColumn,
} from '../../../../atoms/utils/layout/Box/FlexBox';

export interface CryptoCurrencyDepositResultCardLayoutProps {
  children: React.ReactElement[];
  marginBetween: string | number;
}

const CryptoCurrencyDepositResultCardLayout: FC<CryptoCurrencyDepositResultCardLayoutProps> = ({
  children,
  marginBetween,
}: CryptoCurrencyDepositResultCardLayoutProps) => {
  const elements = ['amountLabel', 'amount', 'feeLabel', 'fee'];
  const [amountLabel, amount, feeLabel, fee] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxRow padding="0" justifyContent="space-between">
      <FlexBoxColumn>
        {amountLabel}
        {feeLabel}
      </FlexBoxColumn>
      <Margin left={marginBetween}>
        <FlexBoxColumn>
          {amount}
          {fee}
        </FlexBoxColumn>
      </Margin>
    </FlexBoxRow>
  );
};
export default CryptoCurrencyDepositResultCardLayout;

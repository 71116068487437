import React, { FC, useContext } from 'react';
import { useDebitCardViewUseCase } from './useDebitCardViewUseCase';
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';
import DebitCardOptionContext from '../../../../../components/molecules/selectBox/option/option/DebitCardOption/DebitCardOptionContext';
import DebitCardV2sContext from '../../../../../domain/debitCard/debitCardV2/DebitCardV2s/DebitCardV2sContext';
import PageRequestStateContext from '../../../../pageState/general/PageRequestState/PageRequestStateContext';
import SavingsAccountOptionContext from '../../../../../components/molecules/selectBox/option/option/SavingsAccountOption/SavingsAccountOptionContext';
import SavingsAccountsContext from '../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccountsContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import { useDebitCardFeeFile } from '../../../../storage/files/useDebitCardFeeFile';
import LinksDebitCardContext from '../../../../storage/context/LinksDebitCardContext';

export type DebitCardViewProviderProps = {
  children: React.ReactNode;
};

const DebitCardViewProvider: FC<DebitCardViewProviderProps> = ({
  children,
}: DebitCardViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    savingsAccounts,
    savingsAccountOption,
    setSavingsAccountOption,
    debitCards,
    debitCardOption,
    setDebitCardOption,
  } = useDebitCardViewUseCase(userAuthorized);

  const { debitCardFeeFileUrl } = useDebitCardFeeFile();

  return (
    <PageRequestStateContext.Provider value={{ state, setState }}>
      <DebitCardV2sContext.Provider value={{ debitCards }}>
        <DebitCardOptionContext.Provider
          value={{
            debitCardOption,
            setDebitCardOption,
          }}
        >
          <SavingsAccountsContext.Provider value={{ savingsAccounts }}>
            <SavingsAccountOptionContext.Provider
              value={{
                savingsAccountOption,
                setSavingsAccountOption,
              }}
            >
              <LinksDebitCardContext.Provider
                value={{
                  debitCardFeeFileUrl,
                }}
              >
                <SystemErrorHandler state={state} setState={setState}>
                  {children}
                </SystemErrorHandler>
              </LinksDebitCardContext.Provider>
            </SavingsAccountOptionContext.Provider>
          </SavingsAccountsContext.Provider>
        </DebitCardOptionContext.Provider>
      </DebitCardV2sContext.Provider>
    </PageRequestStateContext.Provider>
  );
};
export default DebitCardViewProvider;

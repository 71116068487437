import UserAuthorized from '../UserAuthorized/UserAuthorized';
import { ShippingAddressProps } from '../../../../../../value/personalInformation/address/Address/ShippingAddress/ShippingAddress.type';
import { UserType } from '../../../../../../types/User.type';
import { UserVerifiedProps } from './UserVerified.type';

class UserVerified extends UserAuthorized {
  protected contractName: string;

  protected type: UserType;

  constructor(id: string, props: UserVerifiedProps) {
    super(id, props);

    this.contractName = props.contractName;
    this.type = props.type;
  }

  public getProps() {
    return {
      ...super.getProps(),
      contractName: this.contractName,
      type: this.type,
    };
  }

  public updateShippingAddress = (shippingAddress: ShippingAddressProps) => {
    return new UserVerified(this.id, {
      ...this.getProps(),
      shippingAddress,
      token: this.token,
    });
  };
}
export default UserVerified;

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const TextInputContainer = styled.div`
  height: ${50 / 16}rem;
`;
export const ErrorMessageContainer = styled.div`
  overflow: visible;
  position: absolute;
  // height: ${40 / 16}rem;
  padding-top: 0.4rem;
  top: ${50 / 16}rem;
  display: flex;
  align-items: center;
  width: 100%;
`;

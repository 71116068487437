import React, { FC, useEffect } from 'react';
import { useModal } from 'react-modal-hook';
import { useSignOut } from '../../auth/authenticated/SignOut/useSignOut';
import { SystemErrorHandlerContext } from './SystemErrorHandlerContext';
import { ErrorCodeType } from '../../../utils/errors/ErrorHandler/ErrorCode.type';
import { INITIAL } from '../../../config/constants/requestState';
import ErrorMessageModal from '../../../components/organisms/modal/ErrorMessageModal';

export type SystemErrorHandlerProps = {
  children: React.ReactNode;
  state: string;
  setState: (s: string) => void;
};

const SystemErrorHandler: FC<SystemErrorHandlerProps> = ({
  children,
  state,
  setState,
}: SystemErrorHandlerProps) => {
  const { signOut } = useSignOut();

  const [showModal, hideModal] = useModal(
    () => (
      <ErrorMessageModal
        state={state}
        closeAction={() => {
          setState(INITIAL);
          hideModal();
        }}
      />
    ),
    [state],
  );

  useEffect(() => {
    switch (state) {
      case ErrorCodeType.UNAUTHORIZED_ERROR:
        signOut();
        break;
      case ErrorCodeType.SERVER_ERROR:
      case ErrorCodeType.REQUEST_TIMEOUT_ERROR:
        showModal();
        break;
      default:
    }
  }, [state, setState, signOut, showModal]);

  if (
    state === ErrorCodeType.SERVER_ERROR ||
    state === ErrorCodeType.REQUEST_TIMEOUT_ERROR
  )
    return <></>;

  return (
    <SystemErrorHandlerContext.Provider
      value={{
        showModal,
      }}
    >
      {children}
    </SystemErrorHandlerContext.Provider>
  );
};
export default SystemErrorHandler;

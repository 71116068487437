import Joi from '@hapi/joi';

export interface ContractTimeDepositRenewalReservationProps {
  term: number;

  amountIncreased: number;

  isAnnualPayOut: boolean;

  createdAt?: number;

  updatedAt?: number;
}

export type ContractTimeDepositRenewalReservationRawDataType = ContractTimeDepositRenewalReservationProps;

export const contractTimeDepositRenewalReservationInitialProps: ContractTimeDepositRenewalReservationProps = {
  term: 0,
  amountIncreased: 0,
  isAnnualPayOut: false,
};

export const ContractTimeDepositRenewalReservationPropsFormat = {
  term: Joi.number().min(1).required(),

  amountIncreased: Joi.number().min(0).required(),

  isAnnualPayOut: Joi.boolean().required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import { FlexRow } from '../../../../atoms/utils/layout/Flex';
import Margin from '../../../../atoms/utils/layout/Margin';
import { Container } from './style';

export interface TransferSavingsAccountConfirmButtonLayoutProps {
  children: React.ReactElement[];
  width?: string | number;
}

const TransferSavingsAccountConfirmButtonLayout: FC<TransferSavingsAccountConfirmButtonLayoutProps> = ({
  children,
  width = '100%',
}: TransferSavingsAccountConfirmButtonLayoutProps) => {
  const elements = ['left', 'right'];
  const [left, right] = getElementsFromKeys(children, elements);

  return (
    <Container>
      <FlexRow justifyContent="space-between" style={{ width }}>
        {left}
        <Margin left={20}>{right}</Margin>
      </FlexRow>
    </Container>
  );
};
export default TransferSavingsAccountConfirmButtonLayout;

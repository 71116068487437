// Type
import { DebitCardRequestCherryVisa01StepType } from '../../../../../../types/DebitCardRequestCherryVisa01.type';
import { TypeOfHistory } from '../../../../../../types/typeof/History.type';

// Constant
import {
  ROUTE_CHERRY_VISA_01_PROOF_OF_IDENTITY,
  ROUTE_CHERRY_VISA_01_PROOF_OF_RECEIPT,
} from '../../../../../../config/constants/routing';

// DomainObject
import DebitCardRequestCherryVisa01 from '../../../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01';

// Mapper
import { debitCardRequestCherryVisa01StatusMapper } from './debitCardRequestCherryVisa01StepStatusMapper';

class DebitCardRequestCherryVisa01StepMapper {
  static toStepsStatus = (
    debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01,
  ) => {
    const { status } = debitCardRequestCherryVisa01.getProps();

    return debitCardRequestCherryVisa01StatusMapper[status];
  };

  static toSteps = (
    debitCardRequestCherryVisa01: DebitCardRequestCherryVisa01,
    history: TypeOfHistory,
    onClickCardDelivery: () => void,
    onClickPayForCardIssuanceFee: () => void,
  ) => {
    const debitCardRequestStepsStatus = DebitCardRequestCherryVisa01StepMapper.toStepsStatus(
      debitCardRequestCherryVisa01,
    );

    return [
      {
        debitCardRequestStepType:
          DebitCardRequestCherryVisa01StepType.proofOfIdentity,
        status: debitCardRequestStepsStatus.proofOfIdentity,
        onClick: () => {
          history.push(
            `${ROUTE_CHERRY_VISA_01_PROOF_OF_IDENTITY}/${debitCardRequestCherryVisa01.getId()}`,
          );
        },
      },
      {
        debitCardRequestStepType:
          DebitCardRequestCherryVisa01StepType.waitingProofOfIdentityReview,
        status: debitCardRequestStepsStatus.waitingProofOfIdentityReview,
      },
      {
        debitCardRequestStepType:
          DebitCardRequestCherryVisa01StepType.preparingCardShipment,
        status: debitCardRequestStepsStatus.preparingCardShipment,
      },
      {
        debitCardRequestStepType:
          DebitCardRequestCherryVisa01StepType.cardDelivery,
        status: debitCardRequestStepsStatus.cardDelivery,
        onClick: onClickCardDelivery,
      },
      {
        debitCardRequestStepType:
          DebitCardRequestCherryVisa01StepType.proofOfReceipt,
        status: debitCardRequestStepsStatus.proofOfReceipt,
        onClick: () => {
          history.push(
            `${ROUTE_CHERRY_VISA_01_PROOF_OF_RECEIPT}/${debitCardRequestCherryVisa01.getId()}`,
          );
        },
      },
      {
        debitCardRequestStepType:
          DebitCardRequestCherryVisa01StepType.waitingProofOfReceiptReview,
        status: debitCardRequestStepsStatus.waitingProofOfReceiptReview,
      },
      {
        debitCardRequestStepType:
          DebitCardRequestCherryVisa01StepType.payForCardIssuanceFee,
        status: debitCardRequestStepsStatus.payForCardIssuanceFee,
        onClick: onClickPayForCardIssuanceFee,
      },
    ];
  };
}

export default DebitCardRequestCherryVisa01StepMapper;

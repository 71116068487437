// Type
import { TypeOfT } from '../../../../../../types/typeof/Translation.type';
import { BankSpotTransactionProps } from './BankSpotTransaction.type';

// DomainObject
import BankTransactionId from '../../../../../idManager/transactionId/BankTransactionId/BankTransactionId';
import BankTransaction from '../../../bankTransaction/BankTransaction/BankTransaction';

abstract class BankSpotTransaction extends BankTransaction {
  protected balance: number;

  constructor(
    bankTransactionId: BankTransactionId,
    props: BankSpotTransactionProps,
  ) {
    super(bankTransactionId, props);
    this.balance = props.balance;
  }

  public getProps() {
    return {
      ...super.getProps(),
      balance: this.balance,
    };
  }

  public abstract overwriteBalance: (balance: number) => BankSpotTransaction;

  public abstract createDescription: (t: TypeOfT) => string;
}
export default BankSpotTransaction;

// IService
import IBankSpotTransactionQueryService from '../../../../../useCases/transaction/bankTransaction/bankSpotTransaction/bankSpotTransaction/IBankSpotTransactionQueryService';

// IRepository
import { IBankSpotTransactionQueryRepository } from '../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankSpotTransaction/IBankSpotTransactionRepository';

class BankSpotTransactionQueryService
  implements IBankSpotTransactionQueryService {
  private bankSpotTransactionQueryRepository: IBankSpotTransactionQueryRepository;

  constructor(
    bankSpotTransactionQueryRepository: IBankSpotTransactionQueryRepository,
  ) {
    this.bankSpotTransactionQueryRepository = bankSpotTransactionQueryRepository;
  }

  public findAllBySavingsAccountNumber = async (
    savingsAccountNumber: string,
  ) => {
    const bankTransactions = await this.bankSpotTransactionQueryRepository.findAllBySavingsAccountNumber(
      savingsAccountNumber,
    );

    return bankTransactions;
  };
}

export default BankSpotTransactionQueryService;

// IUseCase
import ISavingsAccountsViewUseCase from '../../../../enhancers/useCase/bankAccount/savingsAccount/SavingsAccountsViewProvider/ISavingsAccountsViewUseCase';

// IService
import ISavingsAccountQueryService from '../ISavingsAccountQueryService';

// Service
import SavingsAccountQueryService from '../../../../service/bankAccount/savingsAccount/SavingsAccountQueryService';

// IRepository
import IDebitCardRequestRepository from '../../../../domain/debitCard/debitCardRequest/IDebitCardRequestRepository';
import ISavingsAccountRepository from '../../../../domain/bankAccount/savingsAccount/ISavingsAccountRepository';

// DomainObject
import UserAuthorized from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class SavingsAccountsViewUseCase implements ISavingsAccountsViewUseCase {
  private debitCardRequestRepository: IDebitCardRequestRepository;

  private savingsAccountQueryService: ISavingsAccountQueryService;

  constructor(
    debitCardRequestRepository: IDebitCardRequestRepository,
    savingsAccountRepository: ISavingsAccountRepository,
  ) {
    this.debitCardRequestRepository = debitCardRequestRepository;

    this.savingsAccountQueryService = new SavingsAccountQueryService(
      savingsAccountRepository,
    );
  }

  public open = async (user: UserAuthorized) => {
    const userId = user.getId();

    const [debitCardRequests, savingsAccounts] = await Promise.all([
      this.debitCardRequestRepository.findAllByUserId(userId),
      this.savingsAccountQueryService.findAllByUserId(userId),
    ]);

    return { debitCardRequests, savingsAccounts };
  };
}
export default SavingsAccountsViewUseCase;

import { IIterable } from '../../../../../interface/Iterator/IIterable';

// Lib
import {
  addByDecimal,
  minusByDecimal,
} from '../../../../../../utils/helpers/calculationHelper';

// Error
import SystemErrorFactory from '../../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../../errors/ErrorMessage/ErrorMessage';

// DomainObject
import BankSpotTransaction from '../BankSpotTransaction/BankSpotTransaction';
import BankDeposit from '../../bankDeposit/BankDeposit/BankDeposit';
import BankWithdrawal from '../../bankWithdrawal/BankWithdrawal/BankWithdrawal';
import SavingsAccount from '../../../../../bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

class BankSpotTransactions implements IIterable<BankSpotTransaction> {
  private items: BankSpotTransaction[] = [];

  constructor(items?: BankSpotTransaction[]) {
    this.items = items || [];
  }

  public add = (bankSpotTransaction: BankSpotTransaction) => {
    this.items.push(bankSpotTransaction);
  };

  public head = () => this.items[0];

  public map = <T>(callback: (item: BankSpotTransaction) => T) => {
    return this.items.map((item) => callback(item));
  };

  public forEach = (callback: (items: BankSpotTransaction) => void) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.items) {
      // eslint-disable-next-line no-await-in-loop
      callback(item);
    }
  };

  public forEachAsync = async (
    callback: (item: BankSpotTransaction) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.items) {
      // eslint-disable-next-line no-await-in-loop
      await callback(item);
    }
  };

  public sortDescByCreatedAt = () => {
    const compare = (a: BankSpotTransaction, b: BankSpotTransaction) => {
      const aCreatedAt = a.getProps().createdAt!;
      const bCreatedAt = b.getProps().createdAt!;

      return aCreatedAt < bCreatedAt ? 1 : -1;
    };
    return new BankSpotTransactions(this.items.sort(compare));
  };

  public calculateBalanceHistory = (savingsAccount: SavingsAccount) => {
    const { balance } = savingsAccount.getProps();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let currentBalance = balance;

    return this.items.reduce((bankSpotTransactions, bankSpotTransaction) => {
      const transactionUpdated = bankSpotTransaction.overwriteBalance(
        currentBalance,
      );

      const { amount } = transactionUpdated.getProps();

      if (bankSpotTransaction instanceof BankDeposit)
        currentBalance = minusByDecimal(currentBalance, amount);
      else if (bankSpotTransaction instanceof BankWithdrawal)
        currentBalance = addByDecimal(currentBalance, amount);
      else
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
          {
            type: 'BankSpotTransaction',
            place: 'BankSpotTransaction.calculateBalanceHistory',
          },
        );
      bankSpotTransactions.add(transactionUpdated);

      return bankSpotTransactions;
    }, new BankSpotTransactions());
  };
}
export default BankSpotTransactions;

import { AddressProps } from './Address.type';

class Address {
  protected country: string;

  protected addressLine1: string;

  protected addressLine2?: string;

  protected city: string;

  protected state: string;

  protected postalCode: string;

  protected props: AddressProps;

  constructor(props: AddressProps) {
    this.country = props.country;
    this.addressLine1 = props.addressLine1;
    this.addressLine2 = props.addressLine2;
    this.city = props.city;
    this.state = props.state;
    this.postalCode = props.postalCode;
    this.props = props;
  }

  public getProps() {
    return this.props;
  }
}
export default Address;

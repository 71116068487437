import React, { FC } from 'react';
import fontColor from '../../../../fonts/color.json';
import fontSize from '../../../../fonts/fontSizePixel.json';
import fontWeight from '../../../../fonts/fontWeight.json';
import LabeledText from './LabeledTextOld';

export interface RejectedReasonProps {
  label: string;
  value: string;
  labelWidth: string;
}

const RejectedReason: FC<RejectedReasonProps> = ({
  label,
  value,
  labelWidth,
}: RejectedReasonProps) => {
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <LabeledText
        label={label}
        value={value}
        labelPixel={fontSize.pixel22}
        labelColor={fontColor.textBright}
        labelFontWeight={fontWeight.semibold}
        labelFontFamilyType="sub"
        labelWidth={labelWidth}
        valuePixel={fontSize.pixel22}
        valueColor={fontColor.textBright}
        valueFontWeight={fontWeight.book}
        valueFontFamilyType="sub"
        valueWidth="100%"
        marginBetween={`${30 / 16}rem`}
      />
    </div>
  );
};

export default RejectedReason;

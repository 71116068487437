import React, { FC } from 'react';

// Lib
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// Style
import { ContainerStyle } from './style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface ContractTimeDepositCardTitleLayoutProps {
  children: React.ReactElement[];
}

const ContractTimeDepositCardTitleLayout: FC<ContractTimeDepositCardTitleLayoutProps> = ({
  children,
}: ContractTimeDepositCardTitleLayoutProps) => {
  const elements = ['label', 'id', 'badge'];

  const [label, id, badge] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn height="auto" paddingLeft={12} style={ContainerStyle}>
      {label}
      <Margin top={15} />
      <FlexBoxRow alignItems="center" width="auto" height="auto">
        {id}
        <Margin left={10} />
        {badge}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};

export default ContractTimeDepositCardTitleLayout;

import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  IssuedDebitCardInformationProps,
  issuedDebitCardInformationPropsFormat,
  issuedDebitCardInformationInitialProps,
} from './IssuedDebitCardInformation.type';

// ValueObject
import IssuedDebitCardInformation from './IssuedDebitCardInformation';

class IssuedDebitCardInformationFactory {
  static create = (props: IssuedDebitCardInformationProps) => {
    const result = Joi.object(issuedDebitCardInformationPropsFormat).validate(
      props,
    );

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: IssuedDebitCardInformation.name,
          reason: result.error.details[0].message,
        },
      );

    return new IssuedDebitCardInformation(props);
  };

  static createInitial = () => {
    return IssuedDebitCardInformationFactory.create(
      issuedDebitCardInformationInitialProps,
    );
  };
}

export default IssuedDebitCardInformationFactory;

import { Reducer, AnyAction } from 'redux';
import { TmpLoginUserAction } from '../actions/tmpLoginUserActions';
import TmpLoginUserActionType from '../constants/tmpLoginUser.constant';

export interface TmpLoginUserState {
  id?: string;
  token?: string;
}

export const initialState: TmpLoginUserState = {
  id: undefined,
  token: undefined,
};

const tmpLoginUserReducer: Reducer<TmpLoginUserState, TmpLoginUserAction> = (
  state: TmpLoginUserState = initialState,
  action: AnyAction,
): TmpLoginUserState => {
  switch (action.type) {
    case TmpLoginUserActionType.SET_TMP_LOGIN_USER:
      return {
        ...state,
        ...action.payload,
      };
    case TmpLoginUserActionType.INITIALIZE_TMP_LOGIN_USER:
      return initialState;
    default:
      return state;
  }
};

export default tmpLoginUserReducer;

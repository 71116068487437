// IService
import IDebitCardV2QueryService from '../../../useCases/debitCard/debitCard/IDebitCardV2QueryService';

// IRepository
import IDebitCardV2Repository from '../../../domain/debitCard/debitCardV2/IDebitCardV2Repository';

class DebitCardV2QueryService implements IDebitCardV2QueryService {
  private debitCardRepository: IDebitCardV2Repository;

  constructor(debitCardRepository: IDebitCardV2Repository) {
    this.debitCardRepository = debitCardRepository;
  }

  public findAllByUserId = async (userId: string) => {
    const debitCards = await this.debitCardRepository.findAllByUserId(userId);

    return debitCards;
  };

  public findAllBySavingsAccountNumber = async (
    savingsAccountNumber: string,
  ) => {
    const debitCards = await this.debitCardRepository.findAllBySavingsAccountNumber(
      savingsAccountNumber,
    );

    return debitCards;
  };

  public findRawAllByUserId = async (userId: string) => {
    const debitCardV2s = await this.debitCardRepository.findRawAllByUserId(
      userId,
    );

    return debitCardV2s;
  };
}

export default DebitCardV2QueryService;

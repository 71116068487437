import React, { FC } from 'react';
import SavingsAccountPage from './SavingsAccountPage';
import SavingsAccountsViewProvider from '../../../enhancers/useCase/bankAccount/savingsAccount/SavingsAccountsViewProvider';

const EnhancedSavingsAccountPage: FC = () => {
  return (
    <SavingsAccountsViewProvider>
      <SavingsAccountPage />
    </SavingsAccountsViewProvider>
  );
};

export default EnhancedSavingsAccountPage;

import { useState } from 'react';
import SavingsAccount from '../../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

export const useSavingsAccountOption = () => {
  // SavingsAccount (DomainObject)
  const [savingsAccountOption, setSavingsAccountOption] = useState<
    SavingsAccount
  >();

  return {
    savingsAccountOption,
    setSavingsAccountOption,
  };
};

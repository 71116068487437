// Type
import { DebitCardRequestContentProps } from './DebitCardRequestContent.type';

abstract class DebitCardRequestContent {
  protected rejectedReason?: string;

  protected updatedAt: number;

  protected props: DebitCardRequestContentProps;

  constructor(props: DebitCardRequestContentProps) {
    this.rejectedReason = props.rejectedReason;
    this.updatedAt = props.updatedAt ?? Date.now();
    this.props = props;
  }

  public getProps() {
    const props = {
      updatedAt: this.updatedAt,
    };

    if (this.rejectedReason)
      Object.assign(props, { rejectedReason: this.rejectedReason });

    return props as DebitCardRequestContentProps;
  }

  public getRejectedReason = () => this.rejectedReason;
}

export default DebitCardRequestContent;

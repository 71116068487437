import React, { FC } from 'react';

// Lib
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface FormNotificationReadStatusHandledLayoutProps {
  children: React.ReactElement[];
}

const FormNotificationReadStatusHandledLayout: FC<FormNotificationReadStatusHandledLayoutProps> = ({
  children,
}: FormNotificationReadStatusHandledLayoutProps) => {
  const elements = ['checkBox', 'checkBoxDescription', 'button'];
  const [checkBox, checkBoxDescription, button] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn height="auto">
      <FlexBoxRow alignItems="center">
        {checkBox}
        <Margin left={20} />
        {checkBoxDescription}
      </FlexBoxRow>
      <Margin top={50} />
      <FlexBoxRow justifyContent="center" height="auto">
        {button}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};

export default FormNotificationReadStatusHandledLayout;

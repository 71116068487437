// Type
import { AssetTimeDepositProps } from './AssetTimeDeposit.type';
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';

// DomainObject
class AssetTimeDeposit {
  private currency: FiatCurrencyType;

  private single: {
    balance: number;
    balanceClosed: number;
  };

  private joint: {
    balance: number;
    balanceClosed: number;
  };

  private createdAt: number;

  private updatedAt: number;

  private props: AssetTimeDepositProps;

  constructor(id: FiatCurrencyType, props: AssetTimeDepositProps) {
    this.currency = id;
    this.props = props;
    this.single = props.single;
    this.joint = props.joint;
    this.createdAt = props.createdAt || Date.now();
    this.updatedAt = props.updatedAt || Date.now();
  }

  public getProps = () => {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  };
}
export default AssetTimeDeposit;

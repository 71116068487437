import CryptoCurrencyBuyTransaction from '../CryptoCurrencyBuyTransaction/CryptoCurrencyBuyTransaction';
import { IIterable } from '../../../../../../interface/Iterator/IIterable';

class CryptoCurrencyBuyTransactions
  implements IIterable<CryptoCurrencyBuyTransaction> {
  private cryptoCurrencyBuyTransactions: CryptoCurrencyBuyTransaction[] = [];

  public add = (cryptoCurrencyBuyTransaction: CryptoCurrencyBuyTransaction) => {
    this.cryptoCurrencyBuyTransactions.push(cryptoCurrencyBuyTransaction);
  };

  public head = () => this.cryptoCurrencyBuyTransactions[0];

  public map = <T>(
    callback: (cryptoCurrencyBuyTransaction: CryptoCurrencyBuyTransaction) => T,
  ) => {
    return this.cryptoCurrencyBuyTransactions.map(
      (cryptoCurrencyBuyTransaction) => callback(cryptoCurrencyBuyTransaction),
    );
  };

  public forEach = (
    callback: (
      cryptoCurrencyBuyTransaction: CryptoCurrencyBuyTransaction,
    ) => void,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const cryptoCurrencyBuyTransaction of this
      .cryptoCurrencyBuyTransactions) {
      // eslint-disable-next-line no-await-in-loop
      callback(cryptoCurrencyBuyTransaction);
    }
  };

  public forEachAsync = async (
    callback: (
      CryptoCurrencyBuyTransaction: CryptoCurrencyBuyTransaction,
    ) => Promise<void>,
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const cryptoCurrencyBuyTransaction of this
      .cryptoCurrencyBuyTransactions) {
      // eslint-disable-next-line no-await-in-loop
      await callback(cryptoCurrencyBuyTransaction);
    }
  };
}
export default CryptoCurrencyBuyTransactions;

import React, { FC } from 'react';
import RegisterTemplate from '../../../components/templates/front/RegisterTemplate';
import SignUpPageGroupTemplate from '../../../components/templates/front/SignUpPageGroupTemplate';

const RegisterPage: FC = () => {
  return (
    <SignUpPageGroupTemplate>
      <RegisterTemplate />
    </SignUpPageGroupTemplate>
  );
};
export default RegisterPage;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// Layout Component
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../../atoms/utils/layout/Margin';

export interface FormCryptoCurrencyBuyLayoutProps {
  children: React.ReactElement[];
}

const FormCryptoCurrencyBuyLayout: FC<FormCryptoCurrencyBuyLayoutProps> = ({
  children,
}: FormCryptoCurrencyBuyLayoutProps) => {
  const elements = ['pay', 'receive', 'message', 'button'];
  const [pay, receive, message, button] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <>
      {pay}
      <Margin top={40} />
      {receive}
      <Margin top={30} />
      <FlexBoxRow justifyContent="center">{message}</FlexBoxRow>
      <Margin top={10} />
      {button}
    </>
  );
};

export default FormCryptoCurrencyBuyLayout;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import UpdateEmailContext from '../../../../enhancers/settings/UpdateEmailProvider/UpdateEmailContext';

// Style
import { SwitchContainer } from './style';

// Constant
import { INITIAL } from '../../../../config/constants/requestState';
import {
  EMAIL_EDIT,
  EMAIL_EDIT_CONFIRM,
} from '../../../../config/constants/pageId.json';

// Component
import { DescriptionSettings } from '../../../molecules/text/DomainDescription';
import { TextEmailSentAuthMail } from '../../../atoms/text/DomainText';
import { TitleWithBackLink } from '../../../molecules/text/TitleWithActionLink';
import SettingsTemplateFormContent from '../SettingTemplateFormContent';
import FormEmailEditConfirm from '../../../organisms/form/settings/FormEmailEditConfirm';
import SwitchResendEmailCountdown from '../../../organisms/switch/SwitchResendEmailAndCountdown';
import SettingsTemplate from '../SettingsTemplateContainer';

const EmailEditConfirmTemplate: FC = () => {
  const { t } = useTranslation();
  const { newEmail, setUpdateEmailState, updateEmail } = useContext(
    UpdateEmailContext,
  );

  return (
    <SettingsTemplate>
      <TitleWithBackLink
        title={t(`${EMAIL_EDIT}.title`)}
        onClick={() => {
          setUpdateEmailState(INITIAL);
        }}
      />
      <div style={{ marginTop: `${50 / 16}rem` }}>
        <SettingsTemplateFormContent>
          <div>
            <DescriptionSettings
              lines={[t(`${EMAIL_EDIT_CONFIRM}.description.description.line1`)]}
            />
          </div>
          <div style={{ marginTop: `${35 / 16}rem` }}>
            <TextEmailSentAuthMail>{newEmail}</TextEmailSentAuthMail>
          </div>
          <div style={{ marginTop: `${35 / 16}rem` }}>
            <FormEmailEditConfirm />
          </div>
        </SettingsTemplateFormContent>
        <div
          style={{ marginTop: `${50 / 16}rem`, marginBottom: `${80 / 16}rem` }}
        >
          <SwitchContainer>
            <SwitchResendEmailCountdown
              onClickResend={() => updateEmail(newEmail, true)}
            />
          </SwitchContainer>
        </div>
      </div>
    </SettingsTemplate>
  );
};

export default EmailEditConfirmTemplate;

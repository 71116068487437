// Error
import { COMMISSION_RATE_PRECISION } from 'config/constants/business';
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import { TypeOfT } from '../../../../../types/typeof/Translation.type';

// Lib
import { convertMoneyFormat } from '../../../../../utils/helpers/currencyHelper';

// Value
import { FiatCurrencyType } from '../../../../../types/FiatCurrency.type';

// DomainObject
import InviterRankD from '../../../../../domain/invite/inviter/InviterRankD/InviterRankD';
import InviterRankA from '../../../../../domain/invite/inviter/InviterRankA/InviterRankA';
import Inviter from '../../../../../domain/invite/inviter/Inviter/Inviter';

export type RowInviterDetail = Array<{
  header: string;
  currency: FiatCurrencyType;
  data: string;
}>;

const ORGANISM_ID = `organisms.table.TableInviterDetail`;

class TableInviterDetailMapper {
  static inviterToRow = (
    inviter: Inviter | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    t: TypeOfT,
  ) => {
    const props = inviter && inviter.getProps();

    if (!props)
      return [
        {
          header: t(`${ORGANISM_ID}.totalReferralTimeDeposit`),
          currency: FiatCurrencyType.USD,
          data: '',
        },
      ];

    if (inviter instanceof InviterRankD)
      return TableInviterDetailMapper.inviterRankDToRow(inviter, t);

    if (inviter instanceof InviterRankA)
      return TableInviterDetailMapper.inviterRankAToRow(inviter, t);

    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_SWITCH,
      {
        value: inviter ? inviter.constructor.name : 'undefined',
        place: 'TableInviterDetailMapper.inviterToRow',
      },
    );
  };

  static inviterRankDToRow = (
    inviter: InviterRankD,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    t: TypeOfT,
  ) => {
    const props = inviter.getProps();

    return [
      {
        header: t(`${ORGANISM_ID}.totalReferralTimeDeposit`),
        currency: FiatCurrencyType.USD,
        data: convertMoneyFormat(
          props.totalReferralTimeDepositAmount,
          FiatCurrencyType.USD,
        ),
      },
      {
        header: t(`${ORGANISM_ID}.totalCommission`),
        currency: FiatCurrencyType.USD,
        data: convertMoneyFormat(props.totalCommission, FiatCurrencyType.USD),
      },
      {
        header: t(`${ORGANISM_ID}.totalCommissionReceived`),
        currency: FiatCurrencyType.USD,
        data: convertMoneyFormat(
          props.totalCommissionReceived,
          FiatCurrencyType.USD,
        ),
      },
      {
        header: t(`${ORGANISM_ID}.commissionRate`),
        currency: undefined,
        data: `${props.commissionRate.toFixed(COMMISSION_RATE_PRECISION)} %`,
      },
    ];
  };

  static inviterRankAToRow = (
    inviter: InviterRankA,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    t: TypeOfT,
  ) => {
    const props = inviter.getProps();

    return [
      {
        header: t(`${ORGANISM_ID}.totalReferralTimeDeposit`),
        currency: FiatCurrencyType.USD,
        data: convertMoneyFormat(
          props.totalReferralTimeDepositAmount,
          FiatCurrencyType.USD,
        ),
      },
    ];
  };
}

export default TableInviterDetailMapper;

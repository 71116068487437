// FirebaseFunctions
import FirebasePrivateFunctions from '../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';
import { DEBIT_CARD_REQUEST_CHERRY_VISA_01_UPLOAD_FILE } from '../../infrastructure/firebase/firebaseFunctions/endpoint';

// Type
import { CherryAPIFilePurposeType } from '../../types/CherryAPIFilePurposeType';

// IRepository
import ICherryStorageHandler from './ICherryStorageHandler';

// DomainObject

class CherryStorageFunctions
  extends FirebasePrivateFunctions
  implements ICherryStorageHandler {
  public upload = async (file: File, purpose: CherryAPIFilePurposeType) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const arrayBuffer = await (file as any).arrayBuffer();

    const buffer = Buffer.from(arrayBuffer);
    const response = await this.privatePOST(
      `${DEBIT_CARD_REQUEST_CHERRY_VISA_01_UPLOAD_FILE}`,
      {
        name: file.name,
        file: buffer,
        purpose,
      },
    );

    return response.data.id;
  };
}
export default CherryStorageFunctions;

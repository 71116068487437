/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash';

// ValueObject
import ConsentFormContent from '../../../../../../value/verification/consentForm/ConsentFormContent/ConsentFormContent';

export const isValid = (errors: any, watch: any) =>
  isEmpty(errors) && !isEmpty(watch('documentUrl'));

export const getDefaultValues = (consentFormContent: ConsentFormContent) => {
  const props = consentFormContent.getProps();

  return {
    documentUrl: props.documentUrl,
  };
};

import Collection from '../collection/Collection';
import firestore from '../firestore';
import IMailsShippingAddressEditComplete from './IMailsShippingAddressEditComplete';
import { mailExecutedTimeFormat } from '../../../helpers/datetimeHelper';

class Mails extends Collection implements IMailsShippingAddressEditComplete {
  collection: firestore.CollectionReference;

  constructor() {
    super();
    this.collection = firestore().collection('mails');
  }

  public sendShippingAddressEditComplete = (
    to: string,
    now: moment.Moment,
    atomic?: firestore.Transaction | firestore.WriteBatch,
  ) => {
    const type = 'shippingAddressEditComplete';

    new Mails().create(
      `${type}${now.unix()}`,
      {
        to,
        template: {
          name: type,
          data: { updatedAt: mailExecutedTimeFormat(now) },
        },
      },
      atomic,
    );
  };

  public sendPasswordEditComplete = (
    to: string,
    now: moment.Moment,
    atomic?: firestore.Transaction | firestore.WriteBatch,
  ) => {
    const type = 'passwordEditComplete';

    new Mails().create(
      `${type}${now.unix()}`,
      {
        to,
        template: {
          name: type,
          data: { updatedAt: mailExecutedTimeFormat(now) },
        },
      },
      atomic,
    );
  };
}

export default Mails;

import React, { FC } from 'react';
import { ColorTranslator } from 'colortranslator';

export interface TextProps {
  children: React.ReactNode;
  color: string;
  textDecoration?: string;
  opacity?: number;
}
const Text: FC<TextProps> = ({
  children,
  color,
  textDecoration = 'none',
  opacity = 1,
}: TextProps) => {
  const rgb = new ColorTranslator(color);

  return (
    <span
      style={{
        color: `rgb(${rgb.R}, ${rgb.G}, ${rgb.B}, ${opacity})`,
        textDecoration,
      }}
    >
      {children}
    </span>
  );
};
export default Text;

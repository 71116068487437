// React
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserAuthorizedAction } from '../../../../redux/actions/signInUserActions';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';
import { executeWithTimeout } from '../../../../utils/helpers/timeout';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';

// UseCase
import SignInTwoFactorAuthUseCase from '../../../../useCases/authentication/signInTwoFactorAuth/SignInTwoFactorAuthUseCase';
import { REQUEST_TIMEOUT } from '../../../../config/constants/business';

// Repository, Functions
import SignInRequestFunctions from '../../../../repository/userRequest/signInRequest/SignInRequestFunctions';

// DomainObject
import UserAuthorized from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useSignInTwoFactorAuthUseCase = (
  userAuthorized: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const dispatch = useDispatch();

  // Repository, Auth
  const signInRequestUpdateRepository = new SignInRequestFunctions();

  // UseCase
  const useCase = new SignInTwoFactorAuthUseCase(signInRequestUpdateRepository);

  // UseCase.signInTwoFactorAuth
  const signInTwoFactorAuth = async (formInput: {
    digit1: string;
    digit2: string;
    digit3: string;
    digit4: string;
    digit5: string;
    digit6: string;
  }) => {
    setState(IS_LOADING);

    const code = [1, 2, 3, 4, 5, 6].reduce((cValue, index) => {
      return cValue + formInput[`digit${index}`];
    }, '');

    try {
      //  ---check onLine ---- //
      checkInternetConnection();

      await executeWithTimeout(
        useCase.signInTwoFactorAuth(userAuthorized, code),
        REQUEST_TIMEOUT,
      );

      dispatch(
        setUserAuthorizedAction({
          id: userAuthorized.getId(),
          signInUserProps: {
            ...userAuthorized.getProps(),
            token: userAuthorized.getToken(),
          },
        }),
      );

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  return {
    state,
    setState,
    signInTwoFactorAuth,
  };
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import Margin from '../../../../atoms/utils/layout/Margin';
import { FlexRow } from '../../../../atoms/utils/layout/Flex';

export interface FormResignUpLayoutProps {
  children: React.ReactElement[];
}

const FormResignUpLayout: FC<FormResignUpLayoutProps> = ({
  children,
}: FormResignUpLayoutProps) => {
  const elements = [
    'email',
    'reregistrationCode',
    'checkbox',
    'checkboxText',
    'button',
  ];
  const [
    email,
    reregistrationCode,
    checkbox,
    checkboxText,
    button,
  ] = getElementsFromKeys(children, elements);

  return (
    <>
      {email}
      <Margin top={60} />
      {reregistrationCode}
      <Margin top={60} />
      <FlexRow alignItems="center">
        {checkbox}
        <Margin left={12} />
        <FlexRow>{checkboxText}</FlexRow>
      </FlexRow>
      <Margin top={50} />
      {button}
    </>
  );
};

export default FormResignUpLayout;

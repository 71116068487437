import Joi from '@hapi/joi';
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';

export interface AssetTimeDepositProps {
  single: {
    balance: number;
    balanceClosed: number;
  };
  joint: {
    balance: number;
    balanceClosed: number;
  };
  createdAt?: number;
  updatedAt?: number;
}

export interface AssetTimeDepositRawDataType {
  currency: FiatCurrencyType;

  props: AssetTimeDepositProps;
}

export interface AssetTimeDepositPrimaryKey {
  userId: string;
  currency: FiatCurrencyType;
}

export const assetTimeDepositInitialProps: AssetTimeDepositProps = {
  single: {
    balance: 0,
    balanceClosed: 0,
  },
  joint: {
    balance: 0,
    balanceClosed: 0,
  },
  createdAt: 0,
  updatedAt: 0,
};

export const AssetTimeDepositPropsFormat = {
  id: Object.keys(FiatCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),

  single: {
    balance: Joi.number().required(),
    balanceClosed: Joi.number().required(),
  },

  joint: {
    balance: Joi.number().required(),
    balanceClosed: Joi.number().required(),
  },

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 12px #0000000d;
  box-sizing: border-box;
  border-radius: ${10 / 16}rem;
  width: ${540 / 16}rem;
  padding: ${50 / 16}rem;
  display: flex;
  justify-content: center;
`;

export const SubmitButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

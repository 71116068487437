import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import Margin from '../../../atoms/utils/layout/Margin';

export interface RegisterTemplateLayoutProps {
  children: React.ReactElement[];
}

const RegisterTemplateLayout: FC<RegisterTemplateLayoutProps> = ({
  children,
}: RegisterTemplateLayoutProps) => {
  const elements = ['title', 'form'];
  const [title, form] = getElementsFromKeys(children, elements);

  return (
    <>
      {title}
      <Margin top={50}>{form}</Margin>
    </>
  );
};
export default RegisterTemplateLayout;

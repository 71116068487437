import React, { FC } from 'react';
import fontColor from '../../../../fonts/color.json';
import { TextSubRegular22 } from '../../../atoms/text/TextSub';

export interface DescriptionSubRegular22Props {
  lines: Array<string>;
  color?: string;
  marginBottom?: string;
}

const DescriptionSubRegular22: FC<DescriptionSubRegular22Props> = ({
  lines = [],
  color = fontColor.textDark,
  marginBottom = '0rem',
}: DescriptionSubRegular22Props) => {
  return (
    <>
      {lines.map((line, i) => {
        if (i === lines.length - 1)
          return (
            <div key={`DescriptionSubRegular22${i}`}>
              <TextSubRegular22 color={color}>{line}</TextSubRegular22>
            </div>
          );
        return (
          <div key={`DescriptionSubRegular22${i}`} style={{ marginBottom }}>
            <TextSubRegular22 color={color}>{line}</TextSubRegular22>
          </div>
        );
      })}
    </>
  );
};

export default DescriptionSubRegular22;

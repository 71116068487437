import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getMonthOptions, getDayOptions, getYearOptions } from './dateOptions';
import { rangeArray } from '../../../../../../utils/helpers/arrayHelper';

export const useDateSelect = (
  dateStart: { year: number; month: number; day: number },
  dateEnd: { year: number; month: number; day: number },
) => {
  const { t } = useTranslation();
  const currentYear = Number(moment().format('yyyy'));

  // year, month , day
  const [year, setYear] = useState<string>();
  const [month, setMonth] = useState<string>();
  const [day, setDay] = useState<string>();

  // Timestamp
  const [startTimestamp, endTimestamp] = [
    moment()
      .add(dateStart.year, 'year')
      .add(dateStart.month, 'month')
      .add(dateStart.day, 'day'),
    moment()
      .add(dateEnd.year, 'year')
      .add(dateEnd.month, 'month')
      .add(dateEnd.day, 'day'),
  ];
  const order = startTimestamp < endTimestamp ? 'asc' : 'desc';

  // Convert to DD MM YYYY
  const startFrom = {
    day: Number(startTimestamp.format('DD')),
    month: Number(startTimestamp.format('MM')),
    year: Number(startTimestamp.format('YYYY')),
  };
  const endTo = {
    day: Number(endTimestamp.format('DD')),
    month: Number(endTimestamp.format('MM')),
    year: Number(endTimestamp.format('YYYY')),
  };

  const defaultDateArray = {
    day: rangeArray(1, 31),
    month: rangeArray(1, 12),
    year: rangeArray(startFrom.year, endTo.year),
  };

  // options
  const [dayOptions, setDayOptions] = useState(
    getDayOptions(defaultDateArray.day),
  );
  const [monthOptions, setMonthOptions] = useState(
    getMonthOptions(t, defaultDateArray.month),
  );
  const [yearOptions, setYearOptions] = useState(
    getYearOptions(defaultDateArray.year),
  );

  /* *
   *
   *  UseEffect
   *
   * */

  useEffect(() => {
    let dayArray = defaultDateArray.day;
    let monthArray = defaultDateArray.month;
    let yearArray = defaultDateArray.year;

    // day
    if (!isEmpty(month)) {
      // Not Olympic year
      if (year && Number(year) % 4 !== 0 && month === '2') {
        // remove < 29
        dayArray = dayArray.filter((num: number) => num < 29);
      } else {
        // Olympic year (2000)
        const daysInMonth = moment(`2000-${month}`, 'YYYY-MM').daysInMonth();
        dayArray = dayArray.filter((num: number) => num <= Number(daysInMonth));
      }
    }

    // month
    if (!isEmpty(day)) {
      switch (Number(day)) {
        case 29:
          if (year && Number(year) % 4 !== 0)
            monthArray = monthArray.filter((num) => num !== 2);
          break;
        case 30:
          monthArray = monthArray.filter((num) => num !== 2);
          break;
        case 31: {
          const monthWith31 = [1, 3, 5, 7, 8, 10, 12];
          monthArray = monthArray.filter(
            (num) => monthWith31.indexOf(num) > -1,
          );
          break;
        }
        default:
      }
    }

    // year
    if (month === '2' && day === '29')
      yearArray = yearArray.filter((num) => num % 4 === 0);

    // day for startFrom - endTo
    if (
      ((Number(month) < startFrom.month ||
        (Number(day) < startFrom.day && Number(month) === startFrom.month)) &&
        order === 'asc') ||
      ((Number(month) > startFrom.month ||
        (Number(day) > startFrom.day && Number(month) === startFrom.month)) &&
        order === 'desc')
    ) {
      yearArray = yearArray.filter((num) => num !== startFrom.year);
    }
    // month for startFrom - endTo
    if (Number(year) === startFrom.year && Number(month) === startFrom.month)
      dayArray = dayArray.filter((num) => {
        return order === 'asc' ? num >= startFrom.day : num <= startFrom.day;
      });

    if (Number(year) === endTo.year && Number(month) === endTo.month)
      dayArray = dayArray.filter((num) => {
        return order === 'asc' ? num <= endTo.day : num >= endTo.day;
      });

    // year for startFrom - endTo
    if (Number(year) === startFrom.year) {
      monthArray = monthArray.filter((num) => {
        return order === 'asc'
          ? num >= startFrom.month
          : num <= startFrom.month;
      });

      if (Number(month) === startFrom.month) {
        dayArray = dayArray.filter((num) => {
          return order === 'asc' ? num >= startFrom.day : num <= startFrom.day;
        });
      }
    }

    if (Number(year) === endTo.year) {
      monthArray = monthArray.filter((num) => {
        return order === 'asc' ? num <= endTo.month : num >= endTo.month;
      });

      if (Number(month) === endTo.month) {
        dayArray = dayArray.filter((num) => {
          return order === 'asc' ? num <= endTo.day : num >= endTo.day;
        });
      }
    }

    setDayOptions(getDayOptions(dayArray));
    setMonthOptions(getMonthOptions(t, monthArray));
    setYearOptions(getYearOptions(yearArray));

    // eslint-disable-next-line
  }, [day, month, year, currentYear, t]);

  return {
    setYear,
    setMonth,
    setDay,
    dayOptions,
    monthOptions,
    yearOptions,
  };
};

import React, { FC } from 'react';
import fontColor from '../../../../fonts/color.json';
import fontWeight from '../../../../fonts/fontWeight.json';
import Breadcrumb from '../../../molecules/navigation/Breadcrumb';

export interface BreadcrumbListProps {
  linkedTexts: Array<{
    text: string;
    urlList: Array<{ replaceText: string; url: string }>;
  }>;
  color?: string;
  pixel?: number;
  textFontWeight?: number;
}

const BreadcrumbList: FC<BreadcrumbListProps> = ({
  linkedTexts,
  color = fontColor.textBright,
  pixel = 18,
  textFontWeight = fontWeight.regular,
}: BreadcrumbListProps) => {
  return (
    <>
      {linkedTexts.map((linkedText, i) => {
        return (
          <div key={`breadcrumb${i}`} style={{ marginBottom: `${20 / 16}rem` }}>
            <Breadcrumb
              text={linkedText.text}
              urlList={linkedText.urlList}
              color={color}
              pixel={pixel}
              textFontWeight={textFontWeight}
            />
          </div>
        );
      })}
    </>
  );
};

export default BreadcrumbList;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Style
import {
  // Container,
  // ProgressStepBarContainer,
  FormContainerStyle,
} from './style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface TimeDepositAddTemplateLayoutProps {
  children: React.ReactElement[];
}

const TimeDepositAddTemplateLayout: FC<TimeDepositAddTemplateLayoutProps> = ({
  children,
}: TimeDepositAddTemplateLayoutProps) => {
  const elements = ['header', 'progressStep', 'form'];
  const [header, progressStep, form] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn textAlign="left" padding="85 0 0 80" width={1280}>
      {header}
      <Margin top={50} />
      <FlexBoxRow justifyContent="center">{progressStep}</FlexBoxRow>
      <Margin top={90} />
      <FlexBoxColumn
        justifyContent="center"
        width={1200}
        padding="80"
        theme={FormContainerStyle}
      >
        {form}
      </FlexBoxColumn>
      <Margin top={120} />
    </FlexBoxColumn>
  );
};
export default TimeDepositAddTemplateLayout;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReferralResultOptionContext from '../../../organisms/tabBar/ReferralResultTabBar/ReferralResultTabBarOptionContext';

// Type
import { ReferralResultOptionType } from '../../../organisms/tabBar/ReferralResultTabBar/ReferralResultTabBar.type';

// Constant
import { INVITE_REFERRAL_RESULT } from '../../../../config/constants/pageId.json';

// Component
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import TableInviterDetail from '../../../organisms/table/invite/TableInviterDetail';
import ReferralResultTabBar from '../../../organisms/tabBar/ReferralResultTabBar';
import TableReferralsTimeDeposit from '../../../organisms/table/invite/TableReferralsTimeDeposit';
import TableCommissionsTimeDeposit from '../../../organisms/table/invite/TableCommissionsTimeDeposit';
import ReferralResultsTemplateLayout from './ReferralResultsTemplateLayout';

const ReferralResultsTemplate: FC = () => {
  const { t } = useTranslation();

  const { referralResultOption } = useContext(ReferralResultOptionContext);

  const TableComponent: FC = () => {
    return referralResultOption === ReferralResultOptionType.commission ? (
      <TableCommissionsTimeDeposit />
    ) : (
      <TableReferralsTimeDeposit />
    );
  };

  return (
    <ReferralResultsTemplateLayout>
      <PageTitle key="title">{t(`${INVITE_REFERRAL_RESULT}.title`)}</PageTitle>
      <TableInviterDetail key="inviter" />
      <ReferralResultTabBar key="tabBar" />
      <TableComponent key="table" />
    </ReferralResultsTemplateLayout>
  );
};

export default ReferralResultsTemplate;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ReregistrationPageState } from './useReregistrationPageState';

export interface ReregistrationPageStateContextProps {
  pageState: ReregistrationPageState;
  setPageState: (state: ReregistrationPageState) => void;
}

const ReregistrationPageStateContext = React.createContext<
  ReregistrationPageStateContextProps
>({
  pageState: 'initial',
  setPageState: (state: ReregistrationPageState) => undefined,
});

export default ReregistrationPageStateContext;

import React, { FC } from 'react';
import SignInPage from './SignInPage';
import SignInProvider from '../../../enhancers/useCase/authentication/SignInProvider';
import SignInViewProvider from '../../../enhancers/useCase/authentication/SignInViewProvider';
import SignInTwoFactorAuthProvider from '../../../enhancers/useCase/authentication/SignInTwoFactorAuthProvider';

const EnhancedSignInPage: FC = () => {
  return (
    <SignInViewProvider>
      <SignInProvider>
        <SignInTwoFactorAuthProvider>
          <SignInPage />
        </SignInTwoFactorAuthProvider>
      </SignInProvider>
    </SignInViewProvider>
  );
};

export default EnhancedSignInPage;

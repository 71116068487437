import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface ManualTemplateLayoutProps {
  children: React.ReactElement[];
}

const ManualTemplateLayout: FC<ManualTemplateLayoutProps> = ({
  children,
}: ManualTemplateLayoutProps) => {
  const elements = [
    'title',
    'bankManuals',
    'debitCardManuals',
    'userManuals',
    'faq',
    'inquiryIcon',
    'inquiryTitle',
    'bankInquiry',
    'cardInquiry',
  ];
  const [
    title,
    bankManuals,
    debitCardManuals,
    userManuals,
    faq,
    inquiryIcon,
    inquiryTitle,
    bankInquiry,
    cardInquiry,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn width={1200} height="auto" padding="100 0 0 83">
      {title}
      <Margin top={53} />
      {bankManuals}
      <Margin top={53} />
      {debitCardManuals}
      <Margin top={53} />
      {userManuals}
      <Margin top={53} />
      {faq}
      <Margin top={53} />
      <FlexBoxRow height="auto" alignItems="center">
        {inquiryIcon}
        <Margin left={20} />
        {inquiryTitle}
      </FlexBoxRow>
      <Margin top={15} />
      {bankInquiry}
      <Margin top={15} />
      {cardInquiry}
      <Margin top={53} />
    </FlexBoxColumn>
  );
};
export default ManualTemplateLayout;

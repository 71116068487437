import {
  ProofOfResidencyProps,
  ProofOfResidencyUserInput,
} from './ProofOfResidency.type';
import {
  KycStatusType,
  ProofOfResidencyDocumentType,
} from '../../../../../types/KycRequest.type';

// ValueObject
import Address from '../../../../personalInformation/address/Address/Address/Address';
import KycRequestContent from '../KycRequestContent/KycRequestContent';

class ProofOfResidency extends KycRequestContent {
  private address: Address;

  private document: {
    type: ProofOfResidencyDocumentType;
    documentUrl1: string;
    documentUrl2?: string;
  };

  constructor(props: ProofOfResidencyProps) {
    super(props);
    this.address = new Address(props);
    this.document = props.document;
  }

  public getProps = () => ({
    ...super.getProps(),
    ...this.address.getProps(),
    document: this.document,
  });

  public getCommandParameter = () => {
    const props = {
      ...super.getCommandParameter(),
      ...this.address.getProps(),
    };
    const document = {
      type: this.document.type,
      documentUrl1: this.document.documentUrl1,
    };
    if (this.document.documentUrl2)
      Object.assign(document, { documentUrl2: this.document.documentUrl2 });

    return {
      ...props,
      document,
    };
  };

  public submit = (params: ProofOfResidencyUserInput) => {
    return new ProofOfResidency({
      ...this.props,
      country: params.country,
      addressLine1: params.addressLine1,
      addressLine2: params.addressLine2,
      city: params.city,
      state: params.state,
      postalCode: params.postalCode,
      document: params.document,
      status: KycStatusType.submitted,
      updatedAt: Date.now(),
    });
  };

  public updateProps = (props: ProofOfResidencyProps) => {
    return new ProofOfResidency({ ...this.getProps(), ...props });
  };
}

export default ProofOfResidency;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import KycRequestContext from '../../../../domain/verification/kycRequest/KycRequest/KycRequest/KycRequestContext';
import ConsentFormContext from '../../../../domain/verification/consentForm/ConsentForm/ConsentFormContext';

// Type
import { DescriptionStyle } from './style';

// Constant
import { ROUTE_SELECT_USER_TYPE } from '../../../../config/constants/routing';

// Component
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import { TextSecondary } from '../../../atoms/text2/Text2';
import BreadCrumbLink from '../../../molecules/navigation/BreadCrumbLink';
import KycStatusList from '../../../organisms/list/KycStatusList';
import KycStatusTemplateLayout from './KycStatusTemplateLayout';

const TEMPLATE_ID = `templates.verification.KycStatusTemplate`;

const KycStatusTemplate: FC = () => {
  const { t } = useTranslation();
  const { kycRequest } = useContext(KycRequestContext);
  const { consentForm } = useContext(ConsentFormContext);

  const kycStepStatus = kycRequest?.getKycStepStatus();
  const type = kycRequest?.getProps().type;
  const kycRequestStatus = kycStepStatus?.getKycRequestStatus();

  return (
    <KycStatusTemplateLayout status={kycRequestStatus}>
      <PageTitle key="header">{t(`${TEMPLATE_ID}.title`)}</PageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        {kycRequest ? t(`${TEMPLATE_ID}.description.${type}`) : ''}
      </TextSecondary>
      <BreadCrumbLink
        key="navigation"
        text={t(`${TEMPLATE_ID}.navigation.selectUserType`)}
        href={ROUTE_SELECT_USER_TYPE}
      />
      <KycStatusList
        key="list"
        kycRequest={kycRequest}
        consentForm={consentForm}
      />
    </KycStatusTemplateLayout>
  );
};

export default KycStatusTemplate;

import React, { FC, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Context
import ContractTimeDepositRenewalReservationStateContext from '../../../../../../enhancers/pageState/contract/ContractTimeDepositRenewalReservationPageState/ContractTimeDepositRenewalReservationPageStateContext';

// Type
import { FormInputAuthenticatorType } from '../../../../../molecules/form/formInput/textField/FormInputAuthenticator/FormInputAuthenticator.type';

// Constant
import { IS_LOADING } from '../../../../../../config/constants/requestState';

// Component
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../../atoms/button/Button2';
import { TextPrimary } from '../../../../../atoms/text2/Text2';
import FormInputAuthenticator from '../../../../../molecules/form/formInput/textField/FormInputAuthenticator';
import FormTimeDepositRenewalReservationAddConfirmLayout from './FormContractTimeDepositRenewalReservationConfirmLayout';

export interface FormContractTimeDepositRenewalReservationConfirmProps {
  requestState: string;
  onSubmit: (code: string, callback: () => void) => void;
}

const FormContractTimeDepositRenewalReservationConfirm: FC<FormContractTimeDepositRenewalReservationConfirmProps> = (
  props: FormContractTimeDepositRenewalReservationConfirmProps,
) => {
  const { t } = useTranslation();

  // PageState
  const { setPageState } = useContext(
    ContractTimeDepositRenewalReservationStateContext,
  );

  /* *
   *
   *  Form
   *
   * */
  const methods = useForm<FormInputAuthenticatorType>({
    mode: 'onChange',
  });
  const { handleSubmit, watch } = methods;

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={handleSubmit((formInput: FormInputAuthenticatorType) => {
        const { code } = formInput;
        props.onSubmit(code, () => {
          setPageState('complete');
        });
      })}
    >
      <FormProvider {...methods}>
        <FormTimeDepositRenewalReservationAddConfirmLayout>
          <FormInputAuthenticator
            key="twoFactorAuth"
            state={props.requestState}
          />
          <SubmitButton
            key="button"
            disabled={!watch('code')}
            height={74}
            isLoading={props.requestState === IS_LOADING}
            theme={{ borderRadius: 10 }}
            width={470}
          >
            <TextPrimary theme={DefaultButtonTextStyle}>
              {t(`atoms.button.submit`)}
            </TextPrimary>
          </SubmitButton>
        </FormTimeDepositRenewalReservationAddConfirmLayout>
      </FormProvider>
    </form>
  );
};

export default FormContractTimeDepositRenewalReservationConfirm;

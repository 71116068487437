import React, { FC } from 'react';
import { useSignOut } from '../SignOut/useSignOut';
import { useSignInTimeOut } from './useSignInTimeOut';

export type SignInTimeOutProviderProps = {
  children: React.ReactNode;
};

const SignInTimeOutProvider: FC<SignInTimeOutProviderProps> = ({
  children,
}: SignInTimeOutProviderProps) => {
  const { signOut } = useSignOut();

  /* *
   *
   * Handling SignIn TimeOut
   *
   * */
  useSignInTimeOut(signOut);

  return <>{children}</>;
};
export default SignInTimeOutProvider;

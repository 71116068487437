import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProofOfReceiptSubmitContext from '../../../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01ProofOfReceiptUpdateProvider/DebitCardRequestCherryVisa01ProofOfReceiptUpdateProvider/DebitCardRequestCherryVisa01ProofOfReceiptUpdateContext';

// Lib
import { isValid } from './helper';

// Type
import {
  FormInputCherryVisa01ProofOfReceipt,
  FormInputCherryVisa01ProofOfReceiptContext,
} from './FormCherryVisa01ProofOfReceipt.type';

// Constant
import { IS_LOADING } from '../../../../../config/constants/requestState';
import { ROUTE_DEBIT_CARD_REQUEST_CHERRY_VISA_01_STATUS } from '../../../../../config/constants/routing';

// Component
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../atoms/button/Button2';
import FormCherryVisa01ProofOfReceiptLayout from './FormCherryVisa01ProofOfReceiptLayout';
import FormSelfieUpload from './FormSelfieUpload';
import LabelSendIcon from '../../../../atoms/button/ButtonLabel/LabelSendIcon';

// ValueObject
import CherryVisa01ProofOfReceipt from '../../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfReceipt/CherryVisa01ProofOfReceipt';

export interface FormCherryVisa01ProofOfReceiptProps {
  id: string;
  proofOfReceipt?: CherryVisa01ProofOfReceipt;
}

const FormCherryVisa01ProofOfReceipt: FC<FormCherryVisa01ProofOfReceiptProps> = ({
  id,
  proofOfReceipt,
}: FormCherryVisa01ProofOfReceiptProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { formInput, setFormInput } = useContext(
    FormInputCherryVisa01ProofOfReceiptContext,
  );

  // Method
  const { state, submitProofOfReceipt } = useContext(
    ProofOfReceiptSubmitContext,
  );

  /* *
   *
   *  Form
   *
   * */
  const methods = useForm<FormInputCherryVisa01ProofOfReceipt>({
    mode: 'onChange',
  });
  const { errors, handleSubmit, reset, watch } = methods;

  /* *
   *
   *  DefaultValues
   *
   * */

  // Set DefaultValue
  useEffect(() => {
    if (formInput) reset(formInput);
    // eslint-disable-next-line
  }, [formInput]);

  // Set Default Value from ProofOfReceipt
  useEffect(() => {
    if (!formInput) {
      reset();
    }
    // eslint-disable-next-line
  }, [formInput, proofOfReceipt]);

  /* *
   *
   *  Event
   *
   * */

  const onSubmit = useCallback(
    async (input: FormInputCherryVisa01ProofOfReceipt) => {
      setFormInput(input);

      await submitProofOfReceipt(() => {
        history.push(`${ROUTE_DEBIT_CARD_REQUEST_CHERRY_VISA_01_STATUS}/${id}`);
      });
    },
    // eslint-disable-next-line
    [submitProofOfReceipt],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <FormCherryVisa01ProofOfReceiptLayout>
          <FormSelfieUpload key="selfie" proofOfReceipt={proofOfReceipt} />
          <SubmitButton
            key="button"
            disabled={!isValid(errors, watch)}
            isLoading={state === IS_LOADING}
            height={75}
            width={'100%'}
          >
            <LabelSendIcon
              iconSize={26}
              iconStyle={{ color: 'white' }}
              label={t(`atoms.button.submit`)}
              marginBetween={8}
              textStyle={DefaultButtonTextStyle}
            />
          </SubmitButton>
        </FormCherryVisa01ProofOfReceiptLayout>
      </FormProvider>
    </form>
  );
};

export default FormCherryVisa01ProofOfReceipt;

import React, { FC, useContext } from 'react';
import SelectRowTableTopUpContext from 'components/organisms/table/selectRow/SelectRowTableTopUp/SelectRowTableTopUpContext';
import DebitCardOptionContext from 'components/molecules/selectBox/option/option/DebitCardOption/DebitCardOptionContext';
import DebitCardSpotTransactionV2sContext from 'domain/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardSpotTransactionV2/DebitCardSpotTransactionV2s/DebitCardSpotTransactionV2sContext';
import DebitCardHistoryOptionContext from 'components/molecules/selector/tabBar/UnderBarTabBar/DebitCardHistoryTabBar/DebitCardHistoryOptionContext';
import TransferDebitCardTransactionV2sContext from 'domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2s/TransferDebitCardTransactionV2sContext';
import CSVDataContext from 'components/organisms/csv/CSVData/CSVDataContext';

// Error handling
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';

// Hooks
import { useDebitCardTransactionViewUseCase } from 'enhancers/useCase/debitCard/debitCard/DebitCardTransactionV2ViewProvider/useDebitCardTransactionViewUseCase';

// DomainObject
import { useSelectRowTableTopUp } from 'components/organisms/table/selectRow/SelectRowTableTopUp/useSelectRowTableTopUp';

export type DebitCardTransactionViewProviderProps = {
  children: React.ReactNode;
};

const DebitCardTransactionViewProvider: FC<DebitCardTransactionViewProviderProps> = ({
  children,
}: DebitCardTransactionViewProviderProps) => {
  const { debitCardOption } = useContext(DebitCardOptionContext);

  const {
    selectRowTableTopUp,
    setSelectRowTableTopUp,
  } = useSelectRowTableTopUp();

  const {
    state,
    setState,
    debitCardHistoryOptionContext,
    debitCardSpotTransactions,
    transferDebitCardTransactions,
    csvDataContext,
  } = useDebitCardTransactionViewUseCase(debitCardOption);

  return (
    <DebitCardSpotTransactionV2sContext.Provider
      value={{ debitCardSpotTransactions }}
    >
      <DebitCardHistoryOptionContext.Provider
        value={debitCardHistoryOptionContext}
      >
        <TransferDebitCardTransactionV2sContext.Provider
          value={{ transferDebitCardTransactions }}
        >
          <CSVDataContext.Provider value={csvDataContext}>
            <SelectRowTableTopUpContext.Provider
              value={{ selectRowTableTopUp, setSelectRowTableTopUp }}
            >
              <SystemErrorHandler state={state} setState={setState}>
                {children}
              </SystemErrorHandler>
            </SelectRowTableTopUpContext.Provider>
          </CSVDataContext.Provider>
        </TransferDebitCardTransactionV2sContext.Provider>
      </DebitCardHistoryOptionContext.Provider>
    </DebitCardSpotTransactionV2sContext.Provider>
  );
};
export default DebitCardTransactionViewProvider;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
// import { Container } from './style';
// import Margin from '../../../atoms/utils/layout/Margin';
import { FlexBoxColumn } from '../../../../atoms/utils/layout/Box/FlexBox';
// import { TransferDebitCardType } from '../../../../../types/Transfer.type';
import { convertRem } from '../../../../atoms/utils/helper/helper';

export interface TransferDebitCardTypeTabItemLayoutProps {
  children: React.ReactElement[];
  onClick: (type: any) => void;
  theme: { borderBottom: string } | { opacity: number };
  width: string | number;
}

const TransferDebitCardTypeTabItemLayout: FC<TransferDebitCardTypeTabItemLayoutProps> = ({
  children,
  onClick,
  theme,
  width,
}: TransferDebitCardTypeTabItemLayoutProps) => {
  const elements = ['type', 'icon'];
  const [type, icon] = getElementsFromKeys(children, elements);

  return (
    <div
      onClick={onClick}
      style={{ ...theme, width: convertRem(width), height: '100%' }}
    >
      <FlexBoxColumn alignItems="center" justifyContent="center">
        {type}
        {icon}
      </FlexBoxColumn>
    </div>
  );
};
export default TransferDebitCardTypeTabItemLayout;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface EmailEditTemplateLayoutProps {
  children: React.ReactElement[];
}

const EmailEditTemplateLayout: FC<EmailEditTemplateLayoutProps> = ({
  children,
}: EmailEditTemplateLayoutProps) => {
  const elements = ['title', 'description', 'form'];
  const [title, description, form] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn textAlign="left" padding="80 0 80 85">
      {title}
      <Margin top={50} />
      {description}
      <Margin top={80} />
      {form}
    </FlexBoxColumn>
  );
};
export default EmailEditTemplateLayout;

import { useState, useCallback } from 'react';
import { checkInternetConnection } from '../../../../../utils/helpers/connection';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// Type
import {
  FormInputConsentForm,
  useFormInputConsentForm,
} from '../../../../../components/organisms/form/verification/consentForm/FormConsentForm/FormConsentForm.type';

// UseCase
import ConsentFormSubmitUseCase from '../../../../../useCases/verification/consentForm/ConsentFormSubmitUseCase';

// Repository
import ConsentFormFunctions from '../../../../../repository/verification/consentForm/ConsentFormFunctions';
import CloudStorage from '../../../../../storage/cloudStorage/CloudStorage';

// DomainObject
import ConsentForm from '../../../../../domain/verification/consentForm/ConsentForm/ConsentForm';
import UserAuthorized from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

// File
import { useConsentFormFile } from '../../../../fileHandler/verification/consentForm/ConsentFormFileProvider/useConsentFormFile';

export const useConsentFormSubmitUseCase = (
  consentForm: ConsentForm | undefined,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const formInputConsentFormContext = useFormInputConsentForm();

  // File
  const { consentFormFile, setConsentFormFile } = useConsentFormFile();

  /* *
   *
   *  Repository
   *
   * */
  const consentFormUpdateRepository = new ConsentFormFunctions();
  const storageHandler = new CloudStorage();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new ConsentFormSubmitUseCase(
    consentFormUpdateRepository,
    storageHandler,
  );

  /* *
   *
   *  Method
   *
   * */
  const submitConsentForm = useCallback(
    async (formInput: FormInputConsentForm, callback?: () => void) => {
      if (!userAuthorized || !consentForm) return;

      setState(IS_LOADING);

      try {
        // ---check onLine ---- //
        checkInternetConnection();

        const consentFormUpdated = consentForm.updateConsentForm({
          documentUrl: formInput.documentUrl,
          status: consentForm.getConsentFormContent().getProps().status,
        });

        // UseCase.submit
        await useCase.submitConsentForm(consentFormUpdated, consentFormFile);

        setState(SUCCESS);

        if (callback) callback();
      } catch (error) {
        const handler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
      // eslint-disable-next-line
  },[userAuthorized, consentForm, consentFormFile]);

  return {
    state,
    setState,
    submitConsentForm,
    formInputConsentFormContext,
    consentFormFileContext: {
      consentFormFile,
      setConsentFormFile,
    },
  };
};

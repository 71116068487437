import Joi from '@hapi/joi';

// Type
import {
  ReferralTimeDepositProps,
  ReferralTimeDepositPropsFormat,
} from '../../referralTimeDeposit/ReferralTimeDeposit/ReferralTimeDeposit.type';

export interface ReferralTimeDepositByRankAProps
  extends ReferralTimeDepositProps {
  secondLevelInviterId: string;
  secondLevelInviterContractName: string;
}

export const ReferralTimeDepositByRankAPropsFormat = {
  ...ReferralTimeDepositPropsFormat,

  secondLevelInviterId: Joi.string().required(),

  secondLevelInviterContractName: Joi.string().required(),
};

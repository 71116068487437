import { required, onlyNumbers, validAddress } from '../validator';

// Type
import { TypeOfT } from '../../../../types/typeof/Translation.type';

const addressValidation = (t: TypeOfT) => ({
  addressLine1: {
    required: required('Address', t),
    validate: {
      validAddress: validAddress('Address', t),
    },
  },
  addressLine2: {
    validate: {
      validAddress: validAddress('AddressOptional', t),
    },
  },
  city: {
    required: required('City', t),
    validate: {
      validAddress: validAddress('City', t),
    },
  },
  state: {
    required: required('State', t),
    validate: {
      validAddress: validAddress('State', t),
    },
  },
  postalCode: {
    required: required('Postal Code', t),
    validate: {
      onlyNumbers: onlyNumbers('Postal Code', t),
    },
  },
});

export default addressValidation;

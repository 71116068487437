// IUseCase
import IProofOfResidencyViewUseCase from '../../../enhancers/useCase/verification/kycRequest/ProofOfResidencyViewProvider/IProofOfResidencyViewUseCase';

// IService
import IDocumentTypeQueryService from '../../settings/documentType/IDocumentTypeQueryService';
import IKycRequestQueryService from './IKycRequestQueryService';

// Service
import DocumentTypeQueryService from '../../../service/settings/documentType/DocumentTypeQueryService';
import KycRequestQueryService from '../../../service/verification/kycRequest/KycRequestQueryService';

// IRepository
import { IKycRequestQueryRepository } from '../../../domain/verification/kycRequest/KycRequest/IKycRequestRepository';
import IDocumentTypeRepository from '../../../domain/settings/documentType/DocumentType/IDocumentTypeRepository';

// Domain
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class ProofOfResidencyViewUseCase implements IProofOfResidencyViewUseCase {
  private documentTypeQueryService: IDocumentTypeQueryService;

  private kycRequestQueryService: IKycRequestQueryService;

  constructor(
    documentTypeRepository: IDocumentTypeRepository,
    kycRequestQueryRepository: IKycRequestQueryRepository,
  ) {
    this.documentTypeQueryService = new DocumentTypeQueryService(
      documentTypeRepository,
    );

    this.kycRequestQueryService = new KycRequestQueryService(
      kycRequestQueryRepository,
    );
  }

  public open = async (user: UserAuthorized) => {
    const id = user.getId();

    const [documentTypes, kycRequest] = await Promise.all([
      this.documentTypeQueryService.findIndividualAll(),
      this.kycRequestQueryService.findById(id),
    ]);

    return { documentTypes, kycRequest };
  };
}

export default ProofOfResidencyViewUseCase;

// Lib
import {
  convertMoneyFormat,
  getSymbolFromCurrency,
} from '../../../../../utils/helpers/currencyHelper';

// Error
import SystemErrorFactory from '../../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../../errors/ErrorMessage/ErrorMessage';

// Type
import { TypeOfT } from '../../../../../types/typeof/Translation.type';

// DomainObject
import ContractTimeDeposit from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDeposit';
import TimeDeposits from '../../../../../domain/product/timeDeposit/TimeDeposits/TimeDeposits';
import UserVerified from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';

// ValueObject
import TimeZonedTimeFactory from '../../../../../value/datetime/TimeZonedTimeFactory';

const ORGANISM_ID = `organisms.propertyList.contract.PropertyListContractTimeDeposit`;

class TableContractTimeDepositAfterMaturedMapper {
  static domainToTd = (
    contractTimeDeposit: ContractTimeDeposit,
    timeDeposits: TimeDeposits,
    user: UserVerified,
    t: TypeOfT,
  ) => {
    const {
      depositCurrency,
      depositDate,
      maturityDate,
      renewalReservation,
    } = contractTimeDeposit.getProps();

    const principalAfterMatured = contractTimeDeposit.getPrincipalAfterMatured();

    if (!renewalReservation)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
        {
          type: 'ContractTimeDeposit.renewalReservation',
          place: 'TableContractTimeDepositAfterMaturedMapper',
        },
      );

    const { term, isAnnualPayOut } = renewalReservation;

    const timeDepositProduct = timeDeposits.getBestRateProduct(
      term,
      principalAfterMatured,
      user.getProps().type,
      isAnnualPayOut,
    );

    // Include "Renewal Reservation"
    const depositAmountAfterMatured = contractTimeDeposit.getPrincipalAfterMatured();
    const termAfterMatured = contractTimeDeposit.getTermAfterMatured();
    const isAnnualPayOutAfterMatured = contractTimeDeposit.getIsAnnualPayOutAfterMatured();

    const { interestRate } = timeDepositProduct.getProps();

    const tdSavingsAccountNumber = contractTimeDeposit.getFormattedSavingsAccountNumber();

    const currencySymbol = getSymbolFromCurrency(depositCurrency);

    return [
      {
        header: t(`${ORGANISM_ID}.th.savingsAccountNumber`),
        data: t(`${ORGANISM_ID}.td.savingsAccountNumber`, {
          savingsAccountNumber: tdSavingsAccountNumber,
        }),
      },
      {
        header: t(`${ORGANISM_ID}.th.depositAmount`),
        data: `${currencySymbol} ${convertMoneyFormat(
          depositAmountAfterMatured,
          depositCurrency,
        )}`,
      },
      {
        header: t(`${ORGANISM_ID}.th.term`),
        data: t(`${ORGANISM_ID}.td.term`, { term: String(termAfterMatured) }),
      },
      {
        header: t(`${ORGANISM_ID}.th.interestRate`),
        data: t(`${ORGANISM_ID}.td.interestRate`, {
          interestRate: String(interestRate),
        }),
      },
      {
        header: t(`${ORGANISM_ID}.th.annualPayOut`),
        data: t(
          `${ORGANISM_ID}.td.annualPayOut.${
            isAnnualPayOutAfterMatured ? 'annual' : 'maturity'
          }`,
        ),
      },
      {
        header: t(`${ORGANISM_ID}.th.depositDate`),
        data: TimeZonedTimeFactory.createZeroOClock(depositDate).format(
          'Do MMMM YYYY',
        ),
      },
      {
        header: t(`${ORGANISM_ID}.th.maturityDate`),
        data: TimeZonedTimeFactory.createZeroOClock(maturityDate).format(
          'Do MMMM YYYY',
        ),
      },
    ];
  };
}

export default TableContractTimeDepositAfterMaturedMapper;

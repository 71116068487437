// React
import { useCallback, useState, useEffect } from 'react';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';
import { executeWithTimeout } from '../../../../utils/helpers/timeout';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';
import { REQUEST_TIMEOUT } from '../../../../config/constants/business';

// UseCase
import ReregistrationViewUseCase from '../../../../useCases/authentication/reregistration/ReregistrationViewUseCase';

// Repository
import ResignUpRequestFunctions from '../../../../repository/userRequest/resignUpRequest/ResignUpRequestFunctions';

// DomainObject
import { useResignUpRequest } from '../../../../domain/userRequest/resignUpRequest/ResignUpRequest/useResignUpRequest';
import { useUserReregistration } from '../../../../domain/public/user/User/UserReregistration/useUserReregistration';

export const useReregistrationViewUseCase = (oobCode: string) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  DomainObject
   *
   * */
  const { resignUpRequest, setResignUpRequest } = useResignUpRequest();
  const { userReregistration, setUserReregistration } = useUserReregistration();

  /* *
   *
   *  Repository
   *
   * */
  const resignUpRequestRepository = new ResignUpRequestFunctions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new ReregistrationViewUseCase(resignUpRequestRepository);

  /* *
   *
   *  Method
   *
   * */
  const openRegisterPage = useCallback(async () => {
    setState(IS_LOADING);

    try {
      checkInternetConnection();

      // UseCase.open
      const output = await executeWithTimeout(
        useCase.open(oobCode),
        REQUEST_TIMEOUT,
      );

      // Setter
      setUserReregistration(output.user);
      setResignUpRequest(output.resignUpRequest);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [oobCode]);

  /* *
   *
   *  UseEffect
   *
   * */
  useEffect(() => {
    let isMounted = true;

    if (isMounted) openRegisterPage();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [oobCode]);

  return {
    resignUpRequest,
    state,
    setState,
    user: userReregistration,
  };
};

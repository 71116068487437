import React, { FC } from 'react';
import UpdateEmail from '../../../enhancers/settings/UpdateEmailProvider';
import ConfirmUpdateEmail from '../../../enhancers/settings/ConfirmUpdateEmailProvider';
import Countdown from '../../../enhancers/utils/Countdown';
import EmailEditPage from './EmailEditPage';

const EnhancedEmailEditPage: FC = () => {
  return (
    <UpdateEmail>
      <ConfirmUpdateEmail>
        <Countdown>
          <EmailEditPage />
        </Countdown>
      </ConfirmUpdateEmail>
    </UpdateEmail>
  );
};

export default EnhancedEmailEditPage;

// Error
import { ErrorIdType } from '../../../../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../../../../errors/ErrorFactory/SystemErrorFactory';

// Type
import { BankDepositProps } from './BankDeposit.type';
import { TypeOfT } from '../../../../../../types/typeof/Translation.type';
import {
  BankDepositType,
  DebitCardDescriptionBankTransactionType,
} from '../../../../../../types/BankAccountTransaction.type';

// DomainObject
import BankTransactionId from '../../../../../idManager/transactionId/BankTransactionId/BankTransactionId';
import BankSpotTransaction from '../../bankSpotTransaction/BankSpotTransaction/BankSpotTransaction';

class BankDeposit extends BankSpotTransaction {
  private bankDepositType: BankDepositType;

  constructor(bankTransactionId: BankTransactionId, props: BankDepositProps) {
    super(bankTransactionId, props);
    this.bankDepositType = props.bankDepositType;
  }

  public getProps() {
    return {
      ...super.getProps(),
      bankDepositType: this.bankDepositType,
    };
  }

  public getCommandParameter = () => ({
    ...super.getCommandParameter(),
    type: this.bankDepositType,
  });

  public createDescription = (t: TypeOfT) => {
    const TRANSLATION_ID =
      'transaction.bankTransaction.description.bankDeposit';

    const descriptionParameter = Object.keys(
      DebitCardDescriptionBankTransactionType,
    ).includes(this.bankDepositType)
      ? `****-****-****-${this.descriptionParameter.substr(-4)}`
      : this.descriptionParameter;

    if (Object.keys(BankDepositType).includes(this.bankDepositType))
      return t(`${TRANSLATION_ID}.${this.bankDepositType}`, {
        param: descriptionParameter,
      });

    throw SystemErrorFactory.createByErrorId(
      ErrorIdType.INVALID_ARGUMENT_SWITCH,
      {
        value: this.bankDepositType,
        place: `BankDeposit.createDescription`,
      },
    );
  };

  public overwriteBalance = (balance: number) => {
    return new BankDeposit(this.transactionId as BankTransactionId, {
      ...this.getProps(),
      balance,
    });
  };
}
export default BankDeposit;

import Joi from '@hapi/joi';

// Type
import {
  DebitCardRequestContentProps,
  DebitCardRequestContentPropsFormat,
} from '../DebitCardRequestContent/DebitCardRequestContent.type';

export interface IssuedDebitCardInformationProps
  extends DebitCardRequestContentProps {
  cardId: string;

  cardHolder: string;

  last4digits: string;

  validThrough: {
    month: number;
    year: number;
  };
}

export type IssuedDebitCardInformationRawDataType = IssuedDebitCardInformationProps;

export const issuedDebitCardInformationPropsFormat = {
  ...DebitCardRequestContentPropsFormat,
  cardId: Joi.string().empty(''),
  cardHolder: Joi.string().required(),
  last4digits: Joi.string().length(4).pattern(/[0-9]/).required(),
  validThrough: {
    month: Joi.number().min(1).max(12).required(),
    year: Joi.number().min(2021),
  },
};

export const issuedDebitCardInformationInitialProps = {
  cardId: '0000000000000000',
  cardHolder: ' ',
  last4digits: '0000',
  validThrough: {
    month: 1,
    year: 2021,
  },
};

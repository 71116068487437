import { isEmpty } from 'lodash';
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getDayFromyyyymmdd,
  getMonthFromyyyymmdd,
  getYearFromyyyymmdd,
} from '../../../../../utils/helpers/datetimeHelper';

// ValueObject
import CherryVisa01ProofOfIdentity from '../../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentity';

export const isValid = (
  errors: any,
  watch: any,
  proofOfIdentity: CherryVisa01ProofOfIdentity | undefined,
) => {
  const document1 = proofOfIdentity?.getProps().document.document1;
  const passport = proofOfIdentity?.getProps().passport.passport;
  const selfie = proofOfIdentity?.getProps().passport.selfie;
  const signature = proofOfIdentity?.getProps().signature;

  return (
    isEmpty(errors) &&
    !isEmpty(watch('gender')) &&
    !isEmpty(watch('dateOfBirthYear')) &&
    !isEmpty(watch('dateOfBirthMonth')) &&
    !isEmpty(watch('dateOfBirthDay')) &&
    !isEmpty(watch('nationality')) &&
    !isEmpty(watch('occupation')) &&
    !isEmpty(watch('documentType')) &&
    (!isEmpty(watch('documentUrl1')) || document1) &&
    !isEmpty(watch('issuingCountry')) &&
    !isEmpty(watch('passportNo')) &&
    !isEmpty(watch('issuedDateYear')) &&
    !isEmpty(watch('issuedDateMonth')) &&
    !isEmpty(watch('issuedDateDay')) &&
    !isEmpty(watch('expiredDateYear')) &&
    !isEmpty(watch('expiredDateMonth')) &&
    !isEmpty(watch('expiredDateDay')) &&
    (!isEmpty(watch('passportUrl')) || passport) &&
    (!isEmpty(watch('selfieUrl')) || selfie) &&
    (!isEmpty(watch('signatureUrl')) || signature) &&
    !isEmpty(watch('country')) &&
    !isEmpty(watch('addressLine1')) &&
    !isEmpty(watch('city')) &&
    !isEmpty(watch('state')) &&
    !isEmpty(watch('postalCode')) &&
    !isEmpty(watch('countryCode')) &&
    !isEmpty(watch('phoneNumber')) &&
    !isEmpty(watch('isInscribed'))
  );
};

export const getDefaultValues = (
  proofOfIdentity: CherryVisa01ProofOfIdentity,
) => {
  const props = proofOfIdentity.getProps();

  return {
    gender: props.gender,
    dateOfBirthYear: String(
      Number(getYearFromyyyymmdd(props.dateOfBirth, '/')),
    ),
    dateOfBirthMonth: String(
      Number(getMonthFromyyyymmdd(props.dateOfBirth, '/')),
    ),
    dateOfBirthDay: String(Number(getDayFromyyyymmdd(props.dateOfBirth, '/'))),
    nationality: props.nationality,
    issuingCountry: props.document.issuingCountry,
    occupation: props.occupation,
    documentType: props.document.type,
    passportNo: props.passport.passportNo,
    issuedDateYear: String(
      Number(getYearFromyyyymmdd(props.passport.issuedAt, '/')),
    ),
    issuedDateMonth: String(
      Number(getMonthFromyyyymmdd(props.passport.issuedAt, '/')),
    ),
    issuedDateDay: String(
      Number(getDayFromyyyymmdd(props.passport.issuedAt, '/')),
    ),
    expiredDateYear: String(
      Number(getYearFromyyyymmdd(props.passport.expiredAt, '/')),
    ),
    expiredDateMonth: String(
      Number(getMonthFromyyyymmdd(props.passport.expiredAt, '/')),
    ),
    expiredDateDay: String(
      Number(getDayFromyyyymmdd(props.passport.expiredAt, '/')),
    ),
    country: props.shippingAddress.country,
    addressLine1: props.shippingAddress.addressLine1,
    addressLine2: props.shippingAddress.addressLine2,
    city: props.shippingAddress.city,
    state: props.shippingAddress.state,
    postalCode: props.shippingAddress.postalCode,
    countryCode: props.countryCode,
    phoneNumber: props.phoneNumber,
    isInscribed: props.isInscribed,
  };
};

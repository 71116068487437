import { TypeOfT } from '../../../../types/typeof/Translation.type';
import {
  required,
  minLength,
  alphaNumeric,
  includeLowercase,
  includeUppercase,
  includeNumber,
  includeSpecialCharacter,
  passwordEqualToConfirm,
} from '../validator';

const registerUserNameRegister = (t: TypeOfT) => ({
  required: required('User Name', t),
  minLength: minLength('User Name', 2, t),
  validate: {
    alphaNumeric: alphaNumeric('User Name', t),
  },
});

const registerPasswordRegister = (t: TypeOfT) => {
  return {
    required: required('Password', t),
    minLength: minLength('Password', 10, t),
    validate: {
      includeLowercase: includeLowercase('Password', t),
      includeUppercase: includeUppercase('Password', t),
      includeNumber: includeNumber('Password', t),
      includeSpecialCharacter: includeSpecialCharacter('Password', t),
    },
  };
};

const registerConfirmPasswordRegister = (t: TypeOfT, password: string) => {
  return {
    validate: {
      passwordEqualToConfirm: passwordEqualToConfirm(password, t),
    },
  };
};

export {
  registerUserNameRegister,
  registerPasswordRegister,
  registerConfirmPasswordRegister,
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { CircleBox } from '../../../utils/layout/Box/Box/CircleBox';

export interface ProgressStepCloseProps {
  diameterCircle: string | number;
  theme: {
    backgroundColor: string;
  };
}

const ProgressStepClose: FC<ProgressStepCloseProps> = ({
  diameterCircle,
  theme,
}: ProgressStepCloseProps) => {
  return (
    <CircleBox
      theme={{ backgroundColor: theme.backgroundColor }}
      width={diameterCircle}
      height={diameterCircle}
    ></CircleBox>
  );
};
export default ProgressStepClose;

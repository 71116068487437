/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { TransferDebitCardType } from '../../../../../types/Transfer.type';

export interface TransferDebitCardTypeOptionContextProps {
  transferTypeOption: TransferDebitCardType;
  setTransferTypeOption: (type: TransferDebitCardType) => void;
}

const TransferDebitCardTypeOptionContext = React.createContext<
  TransferDebitCardTypeOptionContextProps
>({
  transferTypeOption: TransferDebitCardType.savingsAccountToCard,
  setTransferTypeOption: (type: TransferDebitCardType) => undefined,
});

export default TransferDebitCardTypeOptionContext;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import VerifyAuthenticatorContext from '../../../../../../enhancers/auth/authenticator/VerifyAuthenticator/VerifyAuthenticatorContext';

// Constant
import { INITIAL } from '../../../../../../config/constants/requestState';

// Style
import { NavigationTextStyle } from './style';

// Component
import { PageTitle } from '../../../../../atoms/headLine/HeadLine';
import Breadcrumb from '../../../../../molecules/link/Breadcrumb';
import HeaderTwoFactorAuthNewAccountKeyTemplateLayout from './HeaderTwoFactorAuthNewAccountKeyTemplateLayout';

const ORGANISM_ID = `organisms.header.userSettings.HeaderTwoFactorAuthNewAccountKeyTemplate`;

const HeaderTwoFactorAuthNewAccountKeyTemplate: FC = () => {
  const { t } = useTranslation();
  const { setVerifyAuthenticatorState } = useContext(
    VerifyAuthenticatorContext,
  );

  return (
    <HeaderTwoFactorAuthNewAccountKeyTemplateLayout>
      <PageTitle key="left">{t(`${ORGANISM_ID}.title`)}</PageTitle>
      <Breadcrumb
        key="right"
        onClick={() => {
          setVerifyAuthenticatorState(INITIAL);
        }}
        iconSize={10}
        marginBetween={13}
        text={t(`atoms.link.back`)}
        theme={{ text: NavigationTextStyle }}
      />
    </HeaderTwoFactorAuthNewAccountKeyTemplateLayout>
  );
};

export default HeaderTwoFactorAuthNewAccountKeyTemplate;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FORGOT_PASSWORD } from '../../../../config/constants/pageId.json';
import {
  ROUTE_SIGN_IN,
  ROUTE_HOME,
} from '../../../../config/constants/routing';
import fontColor from '../../../../fonts/color.json';
import { DescriptionContainer } from './style';
import { FrontPageTitle } from '../../../atoms/headLine/HeadLine';
import { DescriptionFront } from '../../../molecules/text/DomainDescription';
import ForgotPasswordForm from '../../../organisms/form/front/ForgotPasswordForm';
import BreadcrumbList from '../../../organisms/list/BreadcrumbList';

const ForgotPasswordTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <FrontPageTitle>{t(`${FORGOT_PASSWORD}.title`)}</FrontPageTitle>
      </div>
      <div style={{ marginTop: `${60 / 16}rem` }}>
        <DescriptionContainer>
          <DescriptionFront
            lines={[
              t(`${FORGOT_PASSWORD}.description.line1`),
              t(`${FORGOT_PASSWORD}.description.line2`),
            ]}
            marginBottom={`${10 / 16}rem`}
            color={fontColor.textDark}
          />
        </DescriptionContainer>
      </div>
      <div style={{ marginTop: `${60 / 16}rem` }}>
        <ForgotPasswordForm />
      </div>
      <div style={{ marginTop: `${50 / 16}rem` }}>
        <BreadcrumbList
          color={fontColor.textDark}
          linkedTexts={[
            {
              text: t(`${FORGOT_PASSWORD}.navigation.signIn`),
              urlList: [
                {
                  replaceText: t(`${FORGOT_PASSWORD}.link.signIn`),
                  url: ROUTE_SIGN_IN,
                },
              ],
            },
            {
              text: t(`${FORGOT_PASSWORD}.link.top`),
              urlList: [
                {
                  replaceText: t(`${FORGOT_PASSWORD}.link.top`),
                  url: ROUTE_HOME,
                },
              ],
            },
          ]}
        />
      </div>
    </>
  );
};

export default ForgotPasswordTemplate;

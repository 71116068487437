/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { TransferSavingsAccountPageState } from './useTransferSavingsAccountPageState';

export interface TransferSavingsAccountPageStateContextProps {
  pageState: TransferSavingsAccountPageState;
  setPageState: (state: TransferSavingsAccountPageState) => void;
}

const TransferSavingsAccountPageStateContext = React.createContext<
  TransferSavingsAccountPageStateContextProps
>({
  pageState: 'initial',
  setPageState: (state: TransferSavingsAccountPageState) => undefined,
});

export default TransferSavingsAccountPageStateContext;

import { useState } from 'react';

// Type
import { CSVData } from '../../../../types/CSVData.type';

export const useCSVData = () => {
  const [csvData, setCSVData] = useState<CSVData | undefined>();

  return {
    csvData,
    setCSVData,
  };
};

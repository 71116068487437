import React, { FC } from 'react';
import CardIssuanceFeePaymentModalProvider from '../../../components/organisms/modal/CardIssuanceFeePaymentModal/CardIssuanceFeePaymentModalProvider';
import CardShippingInformationModalProvider from '../../../components/organisms/modal/CardShippingInformationModal/CardShippingInformationModalProvider';
import DebitCardRequestCherryVisa01StatusViewProvider from '../../../enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01StatusViewProvider';
import DebitCardRequestCherryVisa01StatusPage from './DebitCardRequestCherryVisa01StatusPage';

const EnhancedDebitCardRequestCherryVisa01StatusPage: FC = () => {
  return (
    <DebitCardRequestCherryVisa01StatusViewProvider>
      <CardShippingInformationModalProvider>
        <CardIssuanceFeePaymentModalProvider>
          <DebitCardRequestCherryVisa01StatusPage />
        </CardIssuanceFeePaymentModalProvider>
      </CardShippingInformationModalProvider>
    </DebitCardRequestCherryVisa01StatusViewProvider>
  );
};

export default EnhancedDebitCardRequestCherryVisa01StatusPage;

import React, { FC, useEffect } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import * as qs from 'query-string';
import { useVerifyResetPassword } from './useVerifyResetPassword';
import { VerifyResetPasswordContext } from './VerifyResetPasswordContext';
import { ErrorCodeType } from '../../../utils/errors/ErrorHandler/ErrorCode.type';
import { ROUTE_AUTH_ERROR } from '../../../config/constants/routing';
import SystemErrorHandler from '../../errorHandler/SystemErrorHandler';

export type VerifyResetPasswordProps = {
  children: React.ReactNode;
};

const VerifyResetPassword: FC<VerifyResetPasswordProps> = ({
  children,
}: VerifyResetPasswordProps) => {
  const location = useLocation();
  const oobCode = qs.parse(location.search).oobCode || '';
  const validOobCode = Array.isArray(oobCode) ? '' : (oobCode as string);

  const { state, setState, verifyResetPassword } = useVerifyResetPassword(
    validOobCode,
  );

  useEffect(() => {
    verifyResetPassword();
    // eslint-disable-next-line
  },[])

  if (state === ErrorCodeType.RESET_PASSWORD_UNAUTHORIZED)
    return <Redirect to={ROUTE_AUTH_ERROR} />;

  return (
    <VerifyResetPasswordContext.Provider
      value={{
        verifyResetPasswordState: state,
        oobCode: validOobCode,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </VerifyResetPasswordContext.Provider>
  );
};
export default VerifyResetPassword;

import React, { FC } from 'react';
import ReferralResultsPage from './ReferralResultsPage';
import ReferralResultsViewProvider from '../../../enhancers/useCase/invite/ReferralResultsViewProvider';

const EnhancedReferralResultsPage: FC = () => {
  return (
    <ReferralResultsViewProvider>
      <ReferralResultsPage />
    </ReferralResultsViewProvider>
  );
};

export default EnhancedReferralResultsPage;

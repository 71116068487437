import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import { convertRem } from '../../../../atoms/utils/helper/helper';
import {
  Container,
  SelectedContainerStyle,
  UnSelectedContainerStyle,
} from './style';

export interface SavingsAccountTabItemLayoutProps {
  children: React.ReactElement[];
  isSelected: boolean;
  onClick: () => void;
  width: number | string;
}

const SavingsAccountTabItemLayout: FC<SavingsAccountTabItemLayoutProps> = ({
  children,
  isSelected,
  onClick,
  width,
}: SavingsAccountTabItemLayoutProps) => {
  const elements = ['label', 'accountNumber'];
  const [label, accountNumber] = getElementsFromKeys(children, elements);

  const containerStyle = isSelected
    ? SelectedContainerStyle
    : UnSelectedContainerStyle;

  return (
    <Container
      onClick={onClick}
      style={{
        ...containerStyle,
        width: convertRem(width),
      }}
    >
      {label}
      {accountNumber}
    </Container>
  );
};
export default SavingsAccountTabItemLayout;

import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const LabelStyle = {
  fontSize: 21,
  color: color.primary,
  fontWeight: fontWeight.medium,
  lineHeight: 42,
};

export const ErrorMessageStyle = {
  fontSize: 18,
  color: color.text.error,
  fontWeight: fontWeight.regular,
};

export const RadioButtonStyle = {
  text: {
    fontSize: 22,
    color: color.text.zambezi,
    fontWeight: fontWeight.regular,
  },
  border: '1px solid #d6ddec',
  circle: {
    backgroundColorSelected: '#d6ddec',
    backgroundColorUnselected: 'transparent',
  },
  smallCircle: {
    backgroundColor: color.background.primary,
  },
};

export const RadioButtonLayout = {
  circle: {
    diameter: 40,
  },
  smallCircle: {
    diameter: 18,
  },
  margin: {
    item: 100,
    label: 15,
  },
};

// Type
import { UseStateType } from '../../../../types/typeof/UseState';

// IService
import ICryptoCurrencyDepositQueryService from '../../../../useCases/transaction/cryptoCurrencyTransaction/cryptoCurrencyDeposit/ICryptoCurrencyDepositQueryService';

// IRepository
import ICryptoCurrencyDepositRepository from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/ICryptoCurrencyDepositRepository';

// DomainObject
import CryptoCurrencyDeposit from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencyDeposit/CryptoCurrencyDeposit/CryptoCurrencyDeposit';
import CryptoCurrencyWallet from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet';

class CryptoCurrencyDepositQueryService
  implements ICryptoCurrencyDepositQueryService {
  private cryptoCurrencyDepositRepository: ICryptoCurrencyDepositRepository;

  constructor(
    cryptoCurrencyDepositRepository: ICryptoCurrencyDepositRepository,
  ) {
    this.cryptoCurrencyDepositRepository = cryptoCurrencyDepositRepository;
  }

  public findByWallet = async (cryptoCurrencyWallet: CryptoCurrencyWallet) => {
    const address = cryptoCurrencyWallet.getAddress();

    const { currency } = cryptoCurrencyWallet.getProps();

    const cryptoCurrencyDeposits = await this.cryptoCurrencyDepositRepository.findAllByFields(
      {
        address,
        currency,
      },
    );

    return cryptoCurrencyDeposits;
  };

  public subscribeNewDeposit = async (
    cryptoCurrencyWallet: CryptoCurrencyWallet,
    setter: UseStateType<CryptoCurrencyDeposit | undefined>,
    setState: UseStateType<string>,
  ) => {
    const address = cryptoCurrencyWallet.getAddress();
    const { currency } = cryptoCurrencyWallet.getProps();

    // eslint-disable-next-line no-useless-catch
    try {
      this.cryptoCurrencyDepositRepository.subscribeNewData(
        { currency, address },
        setter,
        setState,
      );
    } catch (error) {
      throw error;
    }
  };
}

export default CryptoCurrencyDepositQueryService;

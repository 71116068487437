import styled from 'styled-components';
import Box, { BoxProps } from '../../utils/layout/Box/Box';
import { convertRem } from '../../utils/helper/helper';

interface ThProps extends BoxProps {
  textAlign?: 'left' | 'center' | 'right';
  verticalAlign?: 'top' | 'middle' | 'bottom';
  minWidth?: string | number;
  theme?: {
    backgroundColor: string;
    borderBottom?: string;
  };
}

const Th = styled(Box.withComponent('th'))<ThProps>`
  text-align: ${(props: ThProps) => props.textAlign || 'left'};
  vertical-align: ${(props: ThProps) => props.verticalAlign || 'middle'};
  width: ${(props: ThProps) =>
    props.minWidth ? convertRem(props.minWidth) : '100%'};
  border-bottom: ${(props: ThProps) => props.theme?.borderBottom || 'none'};
`;

export default Th;

import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  min-height: ${70 / 16}rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ButtonWrap = styled.div`
  position: relative;
  right: ${30 / 16}rem;
  top: ${30 / 16}rem;
  width: ${173 / 16}rem;
  height: ${40 / 16}rem;
`;

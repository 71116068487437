import React, { FC, useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CryptoCurrencyWalletContext from '../../../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWalletContext';
import CryptoCurrencyRateContext from '../../../../../../domain/cryptoCurrency/cryptoCurrencyRate/CryptoCurrencyRate/CryptoCurrencyRate/CryptoCurrencyRateContext';
import CryptoCurrencyTradeCreateContext from '../../../../../../enhancers/useCase/cryptoCurrency/CryptoCurrencyTradeCreateUseCase/CryptoCurrencyTradeCreateContext';
import CryptoCurrencyTradePageStateContext from '../../../../../../enhancers/pageState/cryptoCurrency/CryptoCurrencyTradePageState/CryptoCurrencyTradePageStateContext';
import SavingsAccount from '../../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

// Validation
import { cryptoCurrencyTradeValidation } from '../../../../../../utils/validation/registers';

// Lib
import { getAmount } from '../FormCryptoCurrencyTrade/helper';
import { commaSeparatedToNumber } from '../../../../../../utils/helpers/numberHelper';
import { convertMoneyFormat } from '../../../../../../utils/helpers/currencyHelper';
import {
  timesByDecimal,
  divideByDecimal,
} from '../../../../../../utils/helpers/calculationHelper';

// Type
import {
  CryptoCurrencyType,
  CryptoCurrencyTradeType,
} from '../../../../../../types/CryptoCurrency.type';
import { FiatCurrencyType } from '../../../../../../types/FiatCurrency.type';
import { FormInputCryptoCurrencyTradeContext } from '../FormCryptoCurrencyTrade/FormInputCryptoCurrencyTrade.type';
import CryptoCurrencyTradeLimitContext from '../../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeLimit/CryptoCurrencyTradeLimit/CryptoCurrencyTradeLimitContext';

// Style
import {
  MessageStyle,
  ButtonTextStyle,
} from '../FormCryptoCurrencyTrade/style';

// Component
import { SellButton } from '../../../../../atoms/button/Button2';
import { TextPrimary } from '../../../../../atoms/text2/Text2';
import LabelCryptoCurrencyTrade from '../../../../../molecules/textGroup/LabelCryptoCurrencyTrade';
import FormInputTransactionAmount from '../../../../../molecules/form/formInput/textField/FormInputTransactionAmount2';
import FormCryptoCurrencySellLayout from './FormCryptoCurrencySellLayout';

const FORM_ID = `organisms.form.cryptoCurrency.FormCryptoCurrencyTrade`;

export interface FormCryptoCurrencySellProps {
  savingsAccount?: SavingsAccount;
}

const FormCryptoCurrencySell: FC<FormCryptoCurrencySellProps> = ({
  savingsAccount,
}: FormCryptoCurrencySellProps) => {
  const { t } = useTranslation();
  const { setFormInput } = useContext(FormInputCryptoCurrencyTradeContext);

  // PageState
  const { setPageState } = useContext(CryptoCurrencyTradePageStateContext);

  // ReactHookForm
  const { errors, setValue, trigger, watch } = useFormContext();

  // DomainObject
  const { cryptoCurrencyWallet } = useContext(CryptoCurrencyWalletContext);
  const { cryptoCurrencyTradeLimit } = useContext(
    CryptoCurrencyTradeLimitContext,
  );
  const { cryptoCurrencyRate } = useContext(CryptoCurrencyRateContext);

  // Method
  const { createCryptoCurrencyTrade } = useContext(
    CryptoCurrencyTradeCreateContext,
  );

  // Value
  const buyRate = useMemo(
    () => cryptoCurrencyRate?.getProps().buyRate.feeIncluded || 0,
    [cryptoCurrencyRate],
  );

  const minSellAmount = useMemo(
    () => cryptoCurrencyTradeLimit?.getMinimumSellPayAmount(buyRate) || 0,
    [buyRate, cryptoCurrencyTradeLimit],
  );

  // Button Disabled
  const invalidPayAmount =
    !watch(`sellPayAmount`) ||
    watch(`sellPayAmount`) <= 0 ||
    errors.sellPayAmount?.message;

  const invalidReceiveAmount =
    !watch(`sellReceiveAmount`) || watch(`sellReceiveAmount`) <= 0;

  const buttonDisabled = invalidPayAmount || invalidReceiveAmount;

  return (
    <FormCryptoCurrencySellLayout>
      <FormInputTransactionAmount
        key="pay"
        name="sellPayAmount"
        currency={cryptoCurrencyWallet?.getCurrency() || CryptoCurrencyType.BTC}
        inputWidth={320}
        label={t(`${FORM_ID}.label.pay`)}
        onChange={(value: string) => {
          setValue('sellPayAmount', getAmount(value));

          const numValue = value.replace(/,/g, '');

          if (!Number.isNaN(Number(numValue)))
            setValue(
              'sellReceiveAmount',
              convertMoneyFormat(
                timesByDecimal(Number(numValue), buyRate),
                FiatCurrencyType.USD,
              ),
            );
        }}
        validation={cryptoCurrencyTradeValidation(t).sellPayAmount(
          cryptoCurrencyWallet?.getProps().balance || 0,
          minSellAmount,
        )}
      />
      <FormInputTransactionAmount
        key="receive"
        name="sellReceiveAmount"
        currency={savingsAccount?.getProps().currency || FiatCurrencyType.USD}
        inputWidth={320}
        label={t(`${FORM_ID}.label.receive`)}
        onChange={(value: string) => {
          setValue('sellReceiveAmount', getAmount(value));

          const numValue = commaSeparatedToNumber(value);

          if (!Number.isNaN(Number(numValue))) {
            setValue(
              'sellPayAmount',
              convertMoneyFormat(
                divideByDecimal(Number(numValue), buyRate),
                CryptoCurrencyType.BTC,
              ),
            );
            trigger(['sellPayAmount']);
          }
        }}
      />
      <TextPrimary key="message" theme={MessageStyle}>
        &nbsp;
      </TextPrimary>
      <SellButton
        key="button"
        disabled={buttonDisabled}
        height={75}
        onClick={() => {
          const input = {
            savingsAccountNumber: watch(`savingsAccountNumber`),
            payAmount: watch(`sellPayAmount`),
            payCurrency: CryptoCurrencyType.BTC,
            receiveAmount: watch(`sellReceiveAmount`),
            receiveCurrency: FiatCurrencyType.USD,
            rate: buyRate,
            tradeType: CryptoCurrencyTradeType.sell,
          };

          setFormInput(input);

          if (cryptoCurrencyTradeLimit)
            createCryptoCurrencyTrade(input, cryptoCurrencyTradeLimit);

          setPageState('confirm');
        }}
        width={470}
      >
        <LabelCryptoCurrencyTrade
          tradeType={CryptoCurrencyTradeType.sell}
          targetCurrency={CryptoCurrencyType.BTC}
          baseCurrency={FiatCurrencyType.USD}
          theme={ButtonTextStyle}
        />
      </SellButton>
    </FormCryptoCurrencySellLayout>
  );
};

export default FormCryptoCurrencySell;

import React, { FC, useContext } from 'react';
import CryptoCurrencyTradeCreateContext from './CryptoCurrencyTradeCreateContext';
import CryptoCurrencyTradeTransactionContext from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactionContext';
import CryptoCurrencyTradeTransactionSeedContext from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeedContext';
import CryptoCurrencyWalletContext from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWalletContext';
import RequestStateContext from '../../../../value/view/RequestState/RequestStateContext';
import UserVerifiedContext from '../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedContext';
import { useCryptoCurrencyTradeCreateUseCase } from './useCryptoCurrencyTradeCreateUseCase';
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type CryptoCurrencyTradeCreateUseCaseProps = {
  children: React.ReactNode;
};

const CryptoCurrencyTradeCreateUseCase: FC<CryptoCurrencyTradeCreateUseCaseProps> = ({
  children,
}: CryptoCurrencyTradeCreateUseCaseProps) => {
  const { user } = useContext(UserVerifiedContext);
  const { cryptoCurrencyWallet } = useContext(CryptoCurrencyWalletContext);

  const {
    state,
    setState,
    cryptoCurrencyTradeTransaction,
    cryptoCurrencyTradeTransactionSeed,
    createCryptoCurrencyTrade,
    executeCreateCryptoCurrencyTrade,
  } = useCryptoCurrencyTradeCreateUseCase(cryptoCurrencyWallet, user);

  return (
    <RequestStateContext.Provider value={{ requestState: state }}>
      <CryptoCurrencyTradeCreateContext.Provider
        value={{
          requestState: state,
          createCryptoCurrencyTrade,
          executeCreateCryptoCurrencyTrade,
        }}
      >
        <CryptoCurrencyTradeTransactionContext.Provider
          value={{
            cryptoCurrencyTradeTransaction,
          }}
        >
          <CryptoCurrencyTradeTransactionSeedContext.Provider
            value={{ cryptoCurrencyTradeTransactionSeed }}
          >
            <SystemErrorHandler state={state} setState={setState}>
              {children}
            </SystemErrorHandler>
          </CryptoCurrencyTradeTransactionSeedContext.Provider>
        </CryptoCurrencyTradeTransactionContext.Provider>
      </CryptoCurrencyTradeCreateContext.Provider>
    </RequestStateContext.Provider>
  );
};
export default CryptoCurrencyTradeCreateUseCase;

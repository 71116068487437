// IUseCase
import IReregistrationViewUseCase from '../../../enhancers/useCase/authentication/ReregistrationViewProvider/IReregistrationViewUseCase';

// IService
import IResignUpRequestQueryService from '../../userRequest/resignUpRequest/IResignUpRequestQueryService';

// Service
import ResignUpRequestQueryService from '../../../service/userRequest/resignUpRequest/ResignUpRequestQueryService';

// IRepository
import IResignUpRequestRepository from '../../../domain/userRequest/resignUpRequest/IResignUpRequestRepository';

class ReregistrationViewUseCase implements IReregistrationViewUseCase {
  private resignUpRequestQueryService: IResignUpRequestQueryService;

  constructor(resignUpRequestRepository: IResignUpRequestRepository) {
    this.resignUpRequestQueryService = new ResignUpRequestQueryService(
      resignUpRequestRepository,
    );
  }

  public open = async (oobCode: string) => {
    const {
      resignUpRequest,
      user,
    } = await this.resignUpRequestQueryService.findByOobCode(oobCode);

    return {
      user,
      resignUpRequest,
    };
  };
}
export default ReregistrationViewUseCase;

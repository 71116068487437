import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// Style
import { ContainerSubmittedStyle, ContainerRejectedStyle } from './style';

// Type
import { KycStatusType } from '../../../../types/KycRequest.type';

// LayoutComponent
import { FlexBoxRow } from '../../../atoms/utils/layout/Box/FlexBox';
import Display from '../../../atoms/div/Display';
import Margin from '../../../atoms/utils/layout/Margin';

export interface HeaderKycStatusLayoutProps {
  children: React.ReactElement[];
  kycStatus: KycStatusType.submitted | KycStatusType.rejected;
}

const HeaderKycStatusLayout: FC<HeaderKycStatusLayoutProps> = ({
  children,
  kycStatus,
}: HeaderKycStatusLayoutProps) => {
  const elements = ['title', 'status', 'rejectedReason'];
  const [title, status, rejectedReason] = getElementsFromKeys(
    children,
    elements,
  );

  const containerStyle =
    kycStatus === KycStatusType.submitted
      ? ContainerSubmittedStyle
      : ContainerRejectedStyle;

  return (
    <FlexBoxRow
      alignItems="center"
      paddingLeft={85}
      height={89}
      theme={containerStyle}
    >
      {title}
      <Margin left={40} />
      {status}
      <Margin left={40} />
      <Display isDisplay={kycStatus === KycStatusType.rejected}>
        {rejectedReason}
      </Display>
    </FlexBoxRow>
  );
};
export default HeaderKycStatusLayout;

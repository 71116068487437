import { ErrorCodeType } from '../ErrorHandler/ErrorCode.type';

export enum ExceptionType {
  // 400
  badRequestError = 'badRequestError',

  // 401
  unauthorizedError = 'unauthorizedError',

  // 500

  invalidDataFoundError = 'invalidDataFoundError',
  invalidArgumentError = 'invalidArgumentError',
}

export enum ErrorIdType {
  // 40x-01xx
  // AUTHENTICATION_USER_ID_NOT_MATCH = 'AUTHENTICATION_USER_ID_NOT_MATCH',
  // AUTHENTICATION_TOKEN_NOT_FOUND = 'AUTHENTICATION_TOKEN_NOT_FOUND',
  // AUTHENTICATION_USER_ALREADY_DELETED = 'AUTHENTICATION_USER_ALREADY_DELETED',
  // AUTHENTICATION_USER_INVALID_ROLE = 'AUTHENTICATION_USER_INVALID_ROLE',
  // AUTHENTICATION_USER_NOT_FOUND = 'AUTHENTICATION_USER_NOT_FOUND',

  // // 40x-03xx
  // COURSE_INVALID_COURSE_CODE = 'COURSE_INVALID_COURSE_CODE',
  // COURSE_INVALID_LECTURE_CODE = 'COURSE_INVALID_LECTURE_CODE',
  // COURSE_INVALID_MY_COURSE_CODE = 'COURSE_INVALID_MY_COURSE_CODE',

  // 40x-07xx ContractTimeDeposit
  CONTRACT_TIME_DEPOSIT_FORBIDDEN_READ_DEPOSIT_INFO = 'CONTRACT_TIME_DEPOSIT_FORBIDDEN_READ_DEPOSIT_INFO',
  CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_ALREADY_EXISTS = 'CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_ALREADY_EXISTS',
  CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_NOT_EXISTS = 'CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_NOT_EXISTS',

  // 50x-01xx
  KYC_REQUEST_PROOF_OF_RESIDENCY_DOCUMENT2_NO_URL = 'KYC_REQUEST_PROOF_OF_RESIDENCY_DOCUMENT2_NO_URL',

  // 50x-07xx
  TIME_DEPOSIT_PRODUCT_NO_MATCH_PRODUCT = 'TIME_DEPOSIT_PRODUCT_NO_MATCH_PRODUCT',

  // 500
  DATABASE_DATA_NOT_FOUND_BY_ID = 'DATABASE_DATA_NOT_FOUND_BY_ID',
  DATABASE_DATA_NOT_FOUND_UNIQUE_KEY = 'DATABASE_DATA_NOT_FOUND_UNIQUE_KEY',
  DATABASE_INVALID_ARGUMENT_FIND_BY_FIELDS = 'DATABASE_INVALID_ARGUMENT_FIND_BY_FIELDS',
  GENERAL_INVALID_PROPS_DOMAIN_OBJECT_FACTORY = 'GENERAL_INVALID_PROPS_DOMAIN_OBJECT_FACTORY',
  DATABASE_INVALID_DATA_VIOLATE_UNIQUE_CONSTRAINT = 'DATABASE_INVALID_DATA_VIOLATE_UNIQUE_CONSTRAINT',
  INVALID_PROPS_DOMAIN_OBJECT_FACTORY = 'INVALID_PROPS_DOMAIN_OBJECT_FACTORY',
  INVALID_ARGUMENT_SWITCH = 'INVALID_ARGUMENT_SWITCH',
  INVALID_ARGUMENT_TYPE_GUARD = 'INVALID_ARGUMENT_TYPE_GUARD',
  INVALID_ARGUMENT_NUM_STRING = 'INVALID_ARGUMENT_NUM_STRING',
}

export const ERROR_MESSAGE: {
  [id: string]: { code: string; message: string; type: ExceptionType };
} = {
  // /* *
  //  *
  //  * 40x-01xx Authentication (Client Error)
  //  *
  //  * */

  // // 401-0103 AUTHENTICATION_USER_ID_NOT_MATCH
  // AUTHENTICATION_USER_ID_NOT_MATCH: {
  //   code: ErrorCodeType.AUTHENTICATION_USER_ID_NOT_MATCH,
  //   message: `User id is not the same as authorizedUser.`,
  //   type: ExceptionType.invalidArgumentError,
  // },

  // // 401-0104 AUTHENTICATION_TOKEN_NOT_FOUND
  // AUTHENTICATION_TOKEN_NOT_FOUND: {
  //   code: ErrorCodeType.AUTHENTICATION_TOKEN_NOT_FOUND,
  //   message: `Token is not found.`,
  //   type: ExceptionType.invalidArgumentError,
  // },

  // // 401-0105
  // AUTHENTICATION_USER_ALREADY_DELETED: {
  //   code: ErrorCodeType.AUTHENTICATION_USER_ALREADY_DELETED,
  //   message: `User with %%id%% is already deleted.`,
  //   type: ExceptionType.unauthorizedError,
  // },

  // // 401-0106 AUTHENTICATION_USER_INVALID_ROLE
  // AUTHENTICATION_USER_INVALID_ROLE: {
  //   code: ErrorCodeType.AUTHENTICATION_USER_INVALID_ROLE,
  //   message: `Invalid role.`,
  //   type: ExceptionType.unauthorizedError,
  // },

  // // 401-0107 AUTHENTICATION_USER_NOT_FOUND
  // AUTHENTICATION_USER_NOT_FOUND: {
  //   code: ErrorCodeType.AUTHENTICATION_USER_NOT_FOUND,
  //   message: `User not found.`,
  //   type: ExceptionType.unauthorizedError,
  // },

  // /* *
  //  *
  //  * 40x-03xx COURSE (Client Error)
  //  *
  //  * */

  // // 400-0301 COURSE_INVALID_COURSE_CODE
  // COURSE_INVALID_COURSE_CODE: {
  //   code: ErrorCodeType.COURSE_INVALID_COURSE_CODE,
  //   message: `Invalid course code is specified.`,
  //   type: ExceptionType.badRequestError,
  // },

  // // 400-0302 COURSE_INVALID_LECTURE_CODE
  // COURSE_INVALID_LECTURE_CODE: {
  //   code: ErrorCodeType.COURSE_INVALID_LECTURE_CODE,
  //   message: `Invalid lecture code is specified.`,
  //   type: ExceptionType.badRequestError,
  // },

  // // 400-0303 COURSE_INVALID_MY_COURSE_CODE
  // COURSE_INVALID_MY_COURSE_CODE: {
  //   code: ErrorCodeType.COURSE_INVALID_MY_COURSE_CODE,
  //   message: `Invalid my course code is specified.`,
  //   type: ExceptionType.badRequestError,
  // },

  // // 400-0905
  // COURSE_INVALID_MY_COURSE_CODE: {
  //   code: ErrorCodeType.COURSE_INVALID_MY_COURSE_CODE,
  //   message: `Invalid my course code is specified.`,
  //   type: ExceptionType.badRequestError,
  // },

  /* *
   *
   * 400-01xx / 500-01xx
   *  KycRequest (Individual)
   *
   * */
  KYC_REQUEST_PROOF_OF_RESIDENCY_DOCUMENT2_NO_URL: {
    code: ErrorCodeType.SERVER_ERROR,
    message: `Url of document2 is not found even if File of kycRequest2 is found. `,
    type: ExceptionType.invalidDataFoundError,
  },

  /* *
   *
   * 400-07xx / 500-07xx
   *  TimeDeposit (Server Error)
   *
   * */

  // // 403-0702 CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_ALREADY_EXISTS
  CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_ALREADY_EXISTS: {
    code:
      ErrorCodeType.CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_ALREADY_EXISTS,
    message:
      'ContractTimeDeposit RenewalReservation with %%id%% is already exist.',
    type: ExceptionType.badRequestError,
  },

  // // 403-0703 CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_NOT_EXISTS
  CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_NOT_EXISTS: {
    code: ErrorCodeType.CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_NOT_EXISTS,
    message: 'ContractTimeDeposit RenewalReservation with %%id%% is not exist.',
    type: ExceptionType.badRequestError,
  },

  // 500-0701
  TIME_DEPOSIT_PRODUCT_NO_MATCH_PRODUCT: {
    code: ErrorCodeType.TIME_DEPOSIT_PRODUCT_NO_MATCH_PRODUCT,
    message: `Requested contract time deposit doesn't match any products.`,
    type: ExceptionType.invalidDataFoundError,
  },

  /* *
   *
   * 50x-00xx General (Server Error)
   *
   * */

  // 500
  INVALID_PROPS_DOMAIN_OBJECT_FACTORY: {
    code: ErrorCodeType.SERVER_ERROR,
    message: `Creation of %%domain%% is rejected. The reason is %%reason%%`,
    type: ExceptionType.invalidArgumentError,
  },

  DATABASE_DATA_NOT_FOUND_BY_ID: {
    code: ErrorCodeType.SERVER_ERROR,
    message: `%%dataSource%% with %%id%% is not found.`,
    type: ExceptionType.invalidDataFoundError,
  },

  DATABASE_DATA_NOT_FOUND_UNIQUE_KEY: {
    code: ErrorCodeType.SERVER_ERROR,
    message: `%%dataSource%% where %%params%% is not found at %%place%%.`,
    type: ExceptionType.invalidDataFoundError,
  },

  DATABASE_INVALID_ARGUMENT_FIND_BY_FIELDS: {
    code: ErrorCodeType.SERVER_ERROR,
    message: `Invalid parameter %%params%% is specified at %%place%%.`,
    type: ExceptionType.invalidArgumentError,
  },

  DATABASE_INVALID_DATA_VIOLATE_UNIQUE_CONSTRAINT: {
    code: ErrorCodeType.SERVER_ERROR,
    message: `More than one %%name%% whose %%key%% is %%value%% are detected.`,
    type: ExceptionType.invalidDataFoundError,
  },

  INVALID_DATA_VIOLATE_UNIQUE_CONSTRAINT: {
    code: ErrorCodeType.SERVER_ERROR,
    message: `More than one %%name%% whose %%key%% is %%value%% are detected.`,
    type: ExceptionType.invalidDataFoundError,
  },

  INVALID_ARGUMENT_SWITCH: {
    code: ErrorCodeType.SERVER_ERROR,
    message: `Invalid argument %%value%% is specified at %%place%%.`,
    type: ExceptionType.invalidArgumentError,
  },

  INVALID_ARGUMENT_TYPE_GUARD: {
    code: ErrorCodeType.SERVER_ERROR,
    message: `Argument should be type %%type%%, but an invalid argument is specified at %%place%%.`,
    type: ExceptionType.invalidArgumentError,
  },

  INVALID_ARGUMENT_NUM_STRING: {
    code: ErrorCodeType.SERVER_ERROR,
    message: `Invalid numString '%%numString%%' is specified for commaSeparatedToNumber .`,
    type: ExceptionType.invalidArgumentError,
  },
};

import styled from 'styled-components';
import Box, { BoxProps } from '../../utils/layout/Box/Box';
import color from '../../utils/const/color';

interface ButtonProps extends BoxProps {
  isLoading?: boolean;
  borderRadius?: number;
  theme?: {
    backgroundColor?: string;
    borderRadius?: string | number;
    boxShadow?: string;
    border?: string;
  };
}

const Button = styled(Box.withComponent('button'))`
  font-size: ${24 / 16}rem;
  border-radius: ${(props: ButtonProps) =>
    (props.theme?.borderRadius &&
      (Number.isNaN(Number(props.theme?.borderRadius))
        ? props.theme?.borderRadius
        : `${(props.theme?.borderRadius as number) / 16}rem`)) ||
    `${10 / 16}rem`};
  background-color: ${(props: ButtonProps) =>
    props.theme?.backgroundColor || 'none'};
  box-shadow: ${(props: ButtonProps) => props.theme?.boxShadow || 'none'};
  border: ${(props: ButtonProps) =>
    props.theme?.border ||
    (props.theme?.backgroundColor &&
      `${1 / 16}rem solid ${props.theme?.backgroundColor}`) ||
    'none'};
  outline: none;
  cursor: pointer;
`;

export const SolidButton = styled(Button)``;

export const DisabledButton = styled(Button)`
  background-color: #dbdde8;
  border: ${1 / 16}rem solid #dbdde8;
`;

export const GradationBlueButton = styled(Button)`
  background: ${(props: ButtonProps) =>
    props.isLoading
      ? '#77B6DD'
      : 'transparent linear-gradient(180deg, #30a3ea 0%, #005f9a 100%) 0% 0% no-repeat padding-box'};
  border: ${(props: ButtonProps) =>
    props.isLoading
      ? `${1 / 16}rem solid #77B6DD`
      : `${1 / 16}rem solid #005F9A`};
`;

export const GradationRedButton = styled(Button)`
  background: ${(props: ButtonProps) =>
    props.isLoading
      ? '#D06E77'
      : 'transparent linear-gradient(180deg, #D06E77 0%, #96353E 100%) 0% 0% no-repeat padding-box'};
  border: ${(props: ButtonProps) =>
    props.isLoading
      ? `${1 / 16}rem solid #D06E77`
      : `${1 / 16}rem solid #96353E`};
`;

export const BlueBorderButton = styled(Button)`
  background: #fff;
  border: ${1 / 16}rem solid ${color.blue};
`;

export const BlackButton = styled(Button)`
  background: #000;
`;

export default Button;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DebitCardRequestCherryVisa01Context from '../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Context';

// Mapper
import DebitCardRequestCherryVisa01StepMapper from '../../../organisms/list/DebitCardRequestCherryVisa01StatusList/mapper/DebitCardRequestCherryVisa01StepMapper/DebitCardRequestCherryVisa01StepMapper';

// Component
import FormCherryVisa01ProofOfIdentity from '../../../organisms/form/debitCardRequests/FormCherryVisa01ProofOfIdentity';
import DebitCardRequestCherryVisa01StepTemplate from '../DebitCardRequestCherryVisa01StepTemplate';

const TEMPLATE_ID = `templates.debitCards.CherryVisa01ProofOfIdentityTemplate`;

const CherryVisa01ProofOfIdentityTemplate: FC = () => {
  const { t } = useTranslation();
  const { debitCardRequestCherryVisa01 } = useContext(
    DebitCardRequestCherryVisa01Context,
  );

  // First Level Property
  const proofOfIdentity = debitCardRequestCherryVisa01?.getProps()
    .proofOfIdentity;

  // Second Level Property
  const rejectedReason = proofOfIdentity?.getProps().rejectedReason;
  const status =
    debitCardRequestCherryVisa01 &&
    DebitCardRequestCherryVisa01StepMapper.toStepsStatus(
      debitCardRequestCherryVisa01,
    )?.proofOfIdentity;

  return (
    <DebitCardRequestCherryVisa01StepTemplate
      debitCardRequestCherryVisa01={debitCardRequestCherryVisa01}
      status={status}
      title={t(`${TEMPLATE_ID}.title`)}
      rejectedReason={rejectedReason}
    >
      <FormCherryVisa01ProofOfIdentity
        id={debitCardRequestCherryVisa01?.getId() || ''}
        proofOfIdentity={proofOfIdentity}
      />
    </DebitCardRequestCherryVisa01StepTemplate>
  );
};

export default CherryVisa01ProofOfIdentityTemplate;

// Type
import { CryptoCurrencyWithdrawalType } from '../../../../../../../types/CryptoCurrencyTransaction.type';
import { CryptoCurrencyWithdrawalProps } from './CryptoCurrencyWithdrawal.type';

// DomainObject
import CryptoCurrencySpotTransaction from '../../CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction';

// ValueObject
import CryptoCurrencyTransactionId from '../../../../../../idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionId';

class CryptoCurrencyWithdrawal extends CryptoCurrencySpotTransaction {
  private type: CryptoCurrencyWithdrawalType;

  constructor(
    cryptoCurrencyTransactionId: CryptoCurrencyTransactionId,
    props: CryptoCurrencyWithdrawalProps,
  ) {
    super(cryptoCurrencyTransactionId, props);
    this.type = props.type;
  }

  public getTransactionType = () => this.type;
}

export default CryptoCurrencyWithdrawal;

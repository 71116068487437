import React, { FC, useMemo } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// Type
import { UserStatusType } from '../../../../../../types/User.type';

// LayoutComponent
import ContentBox from '../../../../../atoms/utils/layout/Box/Box/ContentBox';
import Margin from '../../../../../atoms/utils/layout/Margin';
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../../atoms/utils/layout/Box/FlexBox';
import { UpperContainer, LowerContainer } from './style';

// DomainObject
import DebitCardRequests from '../../../../../../domain/debitCard/debitCardRequest/DebitCardRequest/DebitCardRequests/DebitCardRequests';
import UserAuthorized from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export interface SavingsAccountCardLayoutProps {
  children: React.ReactElement[];
  debitCardRequests?: DebitCardRequests;
  userAuthorized: UserAuthorized;
  isHistory: boolean;
  isCSVDownloadButtonVisible?: boolean;
}

const SavingsAccountCardLayout: FC<SavingsAccountCardLayoutProps> = ({
  children,
  debitCardRequests,
  userAuthorized,
  isHistory,
  isCSVDownloadButtonVisible = false,
}: SavingsAccountCardLayoutProps) => {
  const elements = [
    'accountNumber',
    'badge',
    'balance',
    'accountHolder',
    'action',
    'link',
    'csvDownloadButton',
  ];
  const [
    accountNumber,
    badge,
    balance,
    accountHolder,
    action,
    link,
    csvDownloadButton,
  ] = getElementsFromKeys(children, elements);

  const contentBoxPadding = isHistory ? 0 : 50;

  const isDisplayDebitCardRequestLink = useMemo(
    () =>
      debitCardRequests &&
      debitCardRequests.isAllowNewRequest() &&
      userAuthorized.isStatus(UserStatusType.investing),
    [debitCardRequests, userAuthorized],
  );

  return (
    <ContentBox padding={contentBoxPadding} width="100%" height="100%">
      <UpperContainer>
        <FlexBoxRow>
          {accountNumber}
          <Margin left={20} />
          {badge}
        </FlexBoxRow>
        <div>{balance}</div>
      </UpperContainer>
      <LowerContainer>
        {accountHolder}
        <FlexBoxColumn width="300" alignItems="end">
          {isCSVDownloadButtonVisible && <Margin top={25} />}
          {csvDownloadButton}
          {action}
          {isDisplayDebitCardRequestLink && (
            <>
              <Margin top={30} />
              {link}
            </>
          )}
        </FlexBoxColumn>
      </LowerContainer>
    </ContentBox>
  );
};

export default SavingsAccountCardLayout;

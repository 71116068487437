import React, { FC } from 'react';
import { useHTMLElementsAdjust } from './useHTMLElementsAdjustProvider';
import HTMLElementsAdjustContext from './HTMLElementsAdjustContext';

export type HTMLElementsAdjustProviderProps = {
  children: React.ReactNode;
};

const HTMLElementsAdjustProvider: FC<HTMLElementsAdjustProviderProps> = ({
  children,
}: HTMLElementsAdjustProviderProps) => {
  const { element1, element2, heightLonger } = useHTMLElementsAdjust();

  return (
    <HTMLElementsAdjustContext.Provider
      value={{ element1, element2, heightLonger }}
    >
      {children}
    </HTMLElementsAdjustContext.Provider>
  );
};

export default HTMLElementsAdjustProvider;

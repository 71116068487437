import React, { FC, useContext, useMemo } from 'react';
import CountdownContext from '../../../../enhancers/utils/Countdown/CountdownContext';

// Lib
import { zeroPadding } from '../../../../utils/helpers/numberHelper';

// Component
import {
  TextPrimary,
  TextSecondary,
  TextThemeProps,
} from '../../../atoms/text2/Text2';
import CountdownDescriptionLayout from './CountdownDescriptionLayout';

export interface CountdownDescriptionProps {
  text: string;
  marginBetween: string | number;
  theme: {
    textStyle: TextThemeProps;
    timerStyle: TextThemeProps;
  };
}

const CountdownDescription: FC<CountdownDescriptionProps> = ({
  text,
  marginBetween,
  theme,
}: CountdownDescriptionProps) => {
  const { count } = useContext(CountdownContext);

  const remainingTime = useMemo(
    () =>
      count < 0
        ? '00 : 00'
        : `${zeroPadding(Math.floor(count / 60), 2)} : ${zeroPadding(
            count % 60,
            2,
          )}`,
    [count],
  );

  return (
    <CountdownDescriptionLayout marginBetween={marginBetween}>
      <TextPrimary key="text" theme={theme.textStyle}>
        {text}
      </TextPrimary>
      <TextSecondary key="timer" theme={theme.timerStyle}>
        {remainingTime}
      </TextSecondary>
    </CountdownDescriptionLayout>
  );
};

export default CountdownDescription;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { FlexRow } from '../../../atoms/utils/layout/Flex';
import { FlexBoxRow } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';
// import { TextContainerStyle } from './style';

export interface TextCopyButtonGroupLayoutProps {
  children: React.ReactElement[];
  buttonMarginLeft: string | number;
  buttonMarginRight: string | number;
  textContainerWidth: string | number;
  textContainerHeight: string | number;
  textContainerStyle: {
    border?: string;
    backgroundColor?: string;
  };
}

const TextCopyButtonGroupLayout: FC<TextCopyButtonGroupLayoutProps> = ({
  children,
  buttonMarginLeft,
  buttonMarginRight,
  textContainerWidth,
  textContainerHeight,
  textContainerStyle,
}: TextCopyButtonGroupLayoutProps) => {
  const elements = ['text', 'button', 'result'];
  const [text, button, result] = getElementsFromKeys(children, elements);

  return (
    <FlexRow alignItems="center">
      <FlexBoxRow
        width={textContainerWidth}
        height={textContainerHeight}
        // width={560}
        // height={50}
        paddingLeft={20}
        alignItems="center"
        theme={textContainerStyle}
      >
        {text}
      </FlexBoxRow>
      <Margin left={buttonMarginLeft}>{button}</Margin>
      <Margin left={buttonMarginRight}>
        <div style={{ width: `${20 / 16}rem` }}>{result}</div>
      </Margin>
    </FlexRow>
  );
};
export default TextCopyButtonGroupLayout;

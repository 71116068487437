/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { INITIAL } from '../../../../config/constants/requestState';

export interface SignUpContextProps {
  email?: string;
  invitationCode?: string;
  resendSignUpComplete: () => Promise<void>;
  state: string;
  signUp: (formInput: {
    email: string;
    invitationCode: string;
  }) => Promise<void>;
}

const SignUpContext = React.createContext<SignUpContextProps>({
  email: '',
  invitationCode: '',
  resendSignUpComplete: () => new Promise(() => undefined),
  state: INITIAL,
  signUp: (formInput: { email: string; invitationCode: string }) =>
    new Promise(() => undefined),
});

export default SignUpContext;

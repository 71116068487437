import Joi from '@hapi/joi';

// Type
import { CurrencyType } from '../../../../types/Currency.type';
import { ContractTimeDepositStatusType } from '../../../../types/ContractTimeDeposit.type';
import {
  ContractTimeDepositRenewalReservationRawDataType,
  ContractTimeDepositRenewalReservationProps,
} from '../../../../value/contract/contractTimeDeposit/ContractTimeDepositRenewalReservation/ContractTimeDepositRenewalReservation.type';
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';

// Constant
import {
  FORMAT_SAVINGS_ACCOUNT_NUMBER,
  FORMAT_TIME_DEPOSIT_ID,
  DATE_FORMAT_SLASH,
} from '../../../../config/constants/regularExpression';

export interface ContractTimeDepositProps {
  userId: string;

  bankTransactionCommonId: string;

  commissionRate: number;

  savingsAccountNumber: string;

  depositAmount: number;

  depositCurrency: CurrencyType;

  interestRate: number;

  term: number;

  renewalReservation?: ContractTimeDepositRenewalReservationProps;

  depositDate: string; // YYYY/MM/DD

  maturityDate: string; // YYYY/MM/DD

  status: ContractTimeDepositStatusType;

  isAutoRenewal: boolean;

  isAnnualPayOut: boolean;

  createdAt?: number;

  updatedAt?: number;
}

export interface ContractTimeDepositRawDataType {
  id: string;
  props: Omit<ContractTimeDepositProps, 'renewalReservation'> & {
    renewalReservation?: ContractTimeDepositRenewalReservationRawDataType;
  };
}

export const ContractTimeDepositPropsFormat = {
  id: Joi.string().pattern(FORMAT_TIME_DEPOSIT_ID).required(),

  bankTransactionCommonId: Joi.string().required(),

  userId: Joi.string().required(),

  commissionRate: Joi.number().min(0).required(),

  savingsAccountNumber: Joi.string()
    .pattern(FORMAT_SAVINGS_ACCOUNT_NUMBER)
    .required(),

  depositAmount: Joi.number().min(0).required(),

  depositCurrency: Object.keys(FiatCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),

  interestRate: Joi.number().min(0).max(50).required(),

  term: Joi.number().min(1).max(100).required(),

  renewalReservation: Joi.any(),

  depositDate: Joi.string().pattern(DATE_FORMAT_SLASH).required(),

  maturityDate: Joi.string().pattern(DATE_FORMAT_SLASH).required(),

  status: Object.keys(ContractTimeDepositStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),

  isAutoRenewal: Joi.boolean().required(),

  isAnnualPayOut: Joi.boolean().required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

import React, { useState } from 'react';

// Type
import { TransferSavingsAccountType } from '../../../../../../types/Transfer.type';

export interface FormInputTransferSavingsAccount {
  savingsAccountNumber: string;
  transferSavingsAccountType: TransferSavingsAccountType;
  bankName: string;
  amount: string;
  beneficiaryAccountNumber: string;
  purpose: string;
  fee: number;
}

export const useFormInputTransferSavingsAccount = () => {
  const [formInput, setFormInput] = useState<FormInputTransferSavingsAccount>();

  return {
    formInput,
    setFormInput,
  };
};

export const FormInputTransferSavingsAccountContext = React.createContext<{
  formInput?: FormInputTransferSavingsAccount;
  setFormInput: (formInput: FormInputTransferSavingsAccount) => void;
}>({
  formInput: undefined,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFormInput: (formInput?: FormInputTransferSavingsAccount) => undefined,
});

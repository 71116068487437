/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export interface ConsentFormFileContextProps {
  consentFormFile: File | undefined;
  setConsentFormFile: (file: File | undefined) => void;
}

const ConsentFormFileContext = React.createContext<ConsentFormFileContextProps>(
  {
    consentFormFile: undefined,
    setConsentFormFile: (file: File | undefined) => undefined,
  },
);

export default ConsentFormFileContext;

import React, { FC, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { upperFirst } from 'lodash';
import ReregisterContext from '../../../../../enhancers/useCase/authentication/ReregisterProvider/ReregisterContext';
import ReregistrationPageStateContext from '../../../../../enhancers/pageState/authentication/ReregistrationPageState/ReregistrationPageStateContext';
import ResignUpRequestContext from '../../../../../domain/userRequest/resignUpRequest/ResignUpRequest/ResignUpRequestContext';
import UserReregistrationContext from '../../../../../domain/public/user/User/UserReregistration/UserReregistrationContext';

// Lib
import { deleteFullWidth } from '../../../../../utils/helpers/stringHelper';
import {
  registerPasswordRegister,
  registerConfirmPasswordRegister,
} from '../../../../../utils/validation/registers';

// Type
import { ReregistrationFormInput } from './FormReregistrationInput';

// Constant
import { IS_LOADING } from '../../../../../config/constants/requestState';
import { RE_REGISTRATION } from '../../../../../config/constants/pageId.json';
import { ErrorCodeType } from '../../../../../utils/errors/ErrorHandler';

// Style
import { LabelStyle } from './style';

// Component
import FormInputTextLabelColumn from '../../../../molecules/form/formInput/textField/FormInputTextLabelColumn';
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../atoms/button/Button2';
import { TextPrimary } from '../../../../atoms/text2/Text2';
import FormReregistrationLayout from './FormReregistrationLayout';

export interface FormReregistrationInput {
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
}

const FormReregistration: FC = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserReregistrationContext);
  const { resignUpRequest } = useContext(ResignUpRequestContext);
  const { state, createAuthenticator } = useContext(ReregisterContext);
  const { setPageState } = useContext(ReregistrationPageStateContext);

  const methods = useForm<
    ReregistrationFormInput & { usernameClientError: string }
  >({
    mode: 'onChange',
  });

  const {
    clearErrors,
    errors,
    formState: { isValid },
    handleSubmit,
    setValue,
    register,
    setError,
    trigger,
    watch,
  } = methods;

  useEffect(() => {
    if (state === ErrorCodeType.AUTHENTICATION_SIGN_UP_USERNAME_EXISTED)
      setError('usernameClientError', {
        type: '',
        message: t(`validation.${state}`),
      });
  }, [state, setError, t]);

  if (!user || !resignUpRequest) return <></>;

  const { email, contractName, type } = user.getProps();

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit((formInput: ReregistrationFormInput) => {
          createAuthenticator(formInput.password, () => {
            setPageState('twoFactorAuth');
          });
        })}
      >
        <FormReregistrationLayout>
          <FormInputTextLabelColumn
            key="contractName"
            disabled={true}
            label={t(`${RE_REGISTRATION}.form.contractName.label`)}
            labelStyle={LabelStyle}
            name="contractName"
            maxLength={255}
            marginBetween={12}
            width={400}
            value={contractName}
          />
          <FormInputTextLabelColumn
            key="userType"
            disabled={true}
            label={t(`${RE_REGISTRATION}.form.userType.label`)}
            labelStyle={LabelStyle}
            name="userType"
            maxLength={255}
            marginBetween={12}
            width={400}
            value={upperFirst(type)}
          />
          <FormInputTextLabelColumn
            key="email"
            disabled={true}
            label={t(`${RE_REGISTRATION}.form.email.label`)}
            labelStyle={LabelStyle}
            name="email"
            maxLength={255}
            marginBetween={12}
            width={400}
            value={email}
          />
          <FormInputTextLabelColumn
            key="password"
            errorMessage={errors.password && errors.password.message}
            label={t(`${RE_REGISTRATION}.form.password.label`)}
            labelStyle={LabelStyle}
            name="password"
            maxLength={20}
            onChange={(text: string) => {
              clearErrors('usernameClientError');
              trigger(['password', 'confirmPassword']);
              setValue('password', deleteFullWidth(text));
            }}
            marginBetween={12}
            register={register(registerPasswordRegister(t))}
            type="password"
            width={400}
            value={watch(`password`)}
          />
          <FormInputTextLabelColumn
            key="confirmPassword"
            errorMessage={
              errors.confirmPassword && errors.confirmPassword.message
            }
            label={t(`${RE_REGISTRATION}.form.confirmPassword.label`)}
            labelStyle={LabelStyle}
            name="confirmPassword"
            maxLength={20}
            onChange={(text: string) => {
              clearErrors('usernameClientError');
              trigger(['password', 'confirmPassword']);
              setValue('confirmPassword', deleteFullWidth(text));
            }}
            marginBetween={12}
            register={register(
              registerConfirmPasswordRegister(t, watch('password')),
            )}
            type="password"
            width={400}
            value={watch(`confirmPassword`)}
          />
          <SubmitButton
            key="button"
            disabled={!isValid}
            height={50}
            isLoading={state === IS_LOADING}
            theme={{ borderRadius: 25 }}
            sizeProgress={20}
            width={165}
          >
            <TextPrimary theme={DefaultButtonTextStyle}>
              {t(`atoms.button.next`)}
            </TextPrimary>
          </SubmitButton>
        </FormReregistrationLayout>
      </form>
    </FormProvider>
  );
};

export default FormReregistration;

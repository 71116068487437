import { useState } from 'react';

export type ContractTimeDepositAddPageState =
  | 'initial'
  | 'confirm'
  | 'complete';

export const useContractTimeDepositAddState = () => {
  const [pageState, setPageState] = useState<ContractTimeDepositAddPageState>(
    'initial',
  );

  return {
    pageState,
    setPageState,
  };
};

import Joi from '@hapi/joi';

// Type
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
import { AssetProps, AssetPropsFormat } from '../../asset/Asset/Asset.type';

export interface AssetSavingsAccountProps extends AssetProps {
  joint: {
    balance: number;
    pendingBalance: number;
  };
}

export const AssetSavingsAccountInitialProps: AssetSavingsAccountProps = {
  single: {
    balance: 0,
    pendingBalance: 0,
  },
  joint: {
    balance: 0,
    pendingBalance: 0,
  },
  createdAt: Date.now(),
  updatedAt: Date.now(),
};

export interface AssetSavingsAccountRawDataType {
  currency: FiatCurrencyType;

  props: AssetSavingsAccountProps;
}

export interface AssetSavingsAccountPrimaryKey {
  userId: string;
  currency: FiatCurrencyType;
}

export const AssetSavingsAccountPropsFormat = {
  ...AssetPropsFormat,

  id: Object.keys(FiatCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),

  joint: Joi.object({
    balance: Joi.number().required(),

    pendingBalance: Joi.number().required(),
  }),
};

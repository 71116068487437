// React
import React, { FC } from 'react';

// Lib
import { isEmpty } from 'lodash';
import { getExplorerUrl } from '../../../../../utils/helpers/cryptoCurrencyHelper';

// Types
import { CryptoCurrencyType } from '../../../../../types/CryptoCurrency.type';

// Components
import Box from '../../../../atoms/utils/layout/Box/Box';
import LineBreakReplaced from '../../../../atoms/text2/LineBreakReplaced';

export interface CryptoCurrencyTxidTdBoxProps {
  currency: CryptoCurrencyType | '';
  txid: string;
}

const CryptoCurrencyTxidTdBox: FC<CryptoCurrencyTxidTdBoxProps> = ({
  currency,
  txid,
}: CryptoCurrencyTxidTdBoxProps) => {
  return (
    <Box width="100%" height="100%" textAlign="left" padding="13">
      {!isEmpty(txid) && (
        <a
          href={currency === '' ? '' : getExplorerUrl(currency, txid)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LineBreakReplaced text={`${txid.substr(0, 25)}...`} />
        </a>
      )}
    </Box>
  );
};

export default CryptoCurrencyTxidTdBox;

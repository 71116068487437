import { RankCType, RankType } from '../../../types/Invite.type';
import Rank from './Rank';

class RankC extends Rank {
  constructor(rank: RankCType) {
    super(rank);
    if (rank !== RankType.C01 && rank !== RankType.C02)
      throw new Error(`Invalid Rank ${rank} at ${this.constructor.name}`);
  }

  public isRankD = () => false;

  public isRankA = () => false;

  public isRankC = () => true;
}
export default RankC;

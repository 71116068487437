import Joi from '@hapi/joi';
import { RankType } from '../../../../types/Invite.type';

export interface InviterProps {
  username: string;

  rank: RankType;

  totalReferralTimeDepositAmount: number;

  isDeleted: boolean;

  createdAt?: number;

  updatedAt?: number;
}

export const InviterPropsFormat = {
  id: Joi.string().required(),

  username: Joi.string().min(2).max(20).required(),

  rank: Object.entries(RankType)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .reduce((joi, [key, value]) => joi.valid(value), Joi.string())
    .required(),

  totalReferralTimeDepositAmount: Joi.number().min(0).required(),

  isDeleted: Joi.boolean().required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// React
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserAuthorizedAction } from '../../../../redux/actions/signInUserActions';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';
import { executeWithTimeout } from '../../../../utils/helpers/timeout';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';
import { REQUEST_TIMEOUT } from '../../../../config/constants/business';

// UseCase
import ReregisterUseCase from '../../../../useCases/authentication/reregistration/ReregisterUseCase';

// Repository, Functions
import AuthenticatorFunctions from '../../../../repository/authenticator/AuthenticatorFunctions';
import ResignUpRequestFunctions from '../../../../repository/userRequest/resignUpRequest/ResignUpRequestFunctions';
import UserFirebaseAuth from '../../../../repository/public/user/UserFirebaseAuth';

// DomainObject
import { useAuthenticator } from '../../../../domain/authenticator/authenticator/Authenticator/useAuthenticator';
import UserReregistration from '../../../../domain/public/user/User/UserReregistration/UserReregistration';
import Authenticator from '../../../../domain/authenticator/authenticator/Authenticator/Authenticator';

export const useReregisterUseCase = (
  oobCode: string,
  user?: UserReregistration,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const [password, setPassword] = useState<string>('');
  const { authenticator, setAuthenticator } = useAuthenticator();
  const dispatch = useDispatch();

  // Repository, Auth
  const authenticatorCreateRepository = new AuthenticatorFunctions();
  const resignUpRequestRepository = new ResignUpRequestFunctions();
  const userAuthentication = new UserFirebaseAuth();

  // UseCase
  const useCase = new ReregisterUseCase(
    authenticatorCreateRepository,
    resignUpRequestRepository,
    userAuthentication,
  );

  const createAuthenticator = async (
    newPassword: string,
    callback?: () => void,
  ) => {
    if (!user) return;

    setState(IS_LOADING);

    try {
      checkInternetConnection();

      const output = await executeWithTimeout(
        useCase.createAuthenticator(user, oobCode),
        REQUEST_TIMEOUT,
      );

      setPassword(newPassword);
      setAuthenticator(output);

      if (callback) callback();

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  // UseCase.register
  const reregister = async (
    formInput: { code: string },
    callback?: () => void,
  ) => {
    if (!user) return;

    setState(IS_LOADING);

    const { code } = formInput;

    try {
      checkInternetConnection();

      const userAuthorized = await executeWithTimeout(
        useCase.reregister(oobCode, password, code, user),
        REQUEST_TIMEOUT,
      );

      dispatch(
        setUserAuthorizedAction({
          id: userAuthorized.getId(),
          signInUserProps: {
            ...userAuthorized.getProps(),
            token: userAuthorized.getToken(),
          },
        }),
      );

      if (callback) callback();

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  return {
    authenticator,
    createAuthenticator,
    setState,
    state,
    reregister,
  };
};

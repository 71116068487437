import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ConsentFormSubmitContext from '../../../../../../enhancers/useCase/verification/consentForm/ConsentFormSubmitProvider/ConsentFormSubmitContext';

import { isValid, getDefaultValues } from './helper';

// Type
import {
  FormInputConsentForm,
  FormInputConsentFormContext,
} from './FormConsentForm.type';

// Constant
import { IS_LOADING } from '../../../../../../config/constants/requestState';
import { ROUTE_KYC_STATUS } from '../../../../../../config/constants/routing';

// Component
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../../atoms/button/Button2';
import LabelSendIcon from '../../../../../atoms/button/ButtonLabel/LabelSendIcon';
import ConsentFormDownload from './ConsentFormDownload';
import ConsentFormSignatureExplanation from './ConsentFormSignatureExplanation';
import FormConsentFormUpload from './FormConsentFormUpload';
import FormConsentFormLayout from './FormConsentFormLayout';

// ValueObject
import ConsentFormContent from '../../../../../../value/verification/consentForm/ConsentFormContent/ConsentFormContent';

export interface FormConsentFormProps {
  consentFormContent?: ConsentFormContent;
}

const FormConsentForm: FC<FormConsentFormProps> = ({
  consentFormContent,
}: FormConsentFormProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { formInput, setFormInput } = useContext(FormInputConsentFormContext);

  // Method
  const { state, submitConsentForm } = useContext(ConsentFormSubmitContext);

  /* *
   *
   *  Form
   *
   * */
  const methods = useForm<FormInputConsentForm & { document: File }>({
    mode: 'onChange',
  });
  const { errors, handleSubmit, reset, setValue, watch } = methods;

  /* *
   *
   *  DefaultValues
   *
   * */
  useEffect(() => {
    if (formInput) reset(formInput);
    // eslint-disable-next-line
  }, [formInput]);

  // Set Default Value from PersonalInformation
  useEffect(() => {
    if (!formInput && consentFormContent) {
      const defaultValues = getDefaultValues(consentFormContent);

      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [formInput, consentFormContent, setValue]);

  /* *
   *
   *  Event
   *
   * */

  const onSubmit = useCallback(
    async (input: FormInputConsentForm) => {
      setFormInput(input);

      await submitConsentForm(input, () => {
        history.push(ROUTE_KYC_STATUS);
      });
    },
    // eslint-disable-next-line
    [submitConsentForm],
  );

  return (
    <FormProvider {...methods}>
      <FormConsentFormLayout>
        <ConsentFormDownload key="download" />
        <ConsentFormSignatureExplanation key="signatureExplanation" />
        <FormConsentFormUpload
          key="consentForm"
          consentFormContent={consentFormContent}
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          key="button"
          style={{ width: '100%' }}
        >
          <SubmitButton
            key="button"
            disabled={!isValid(errors, watch)}
            isLoading={state === IS_LOADING}
            height={75}
            width={'100%'}
          >
            <LabelSendIcon
              iconSize={26}
              iconStyle={{ color: 'white' }}
              label={t(`atoms.button.submit`)}
              marginBetween={8}
              textStyle={DefaultButtonTextStyle}
            />
          </SubmitButton>
        </form>
      </FormConsentFormLayout>
    </FormProvider>
  );
};

export default FormConsentForm;

import React, { FC, useContext } from 'react';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import CryptoCurrencyTradeTransactionSeedContext from '../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeedContext';

// Style
import {
  CountdownNumberStyle,
  CountdownTextStyle,
  DescriptionStyle,
  TableTitleStyle,
} from './style';

// Component
import { TextPrimary, TextSecondary } from '../../../../atoms/text2/Text2';
import HeaderCryptoCurrencyTradeConfirm from '../../../../organisms/header/HeaderContentTitle/cryptoCurrency/HeaderCryptoCurrencyTradeConfirm';
import HeaderCryptoCurrencyTradeType from '../../../../organisms/header/HeaderCyptoCurrencyTradeType';
import TableConfirmCryptoCurrencyTrade from '../../../../organisms/table/confirm/TableConfirmCryptoCurrencyTrade';
import CountdownDescription from '../../../../molecules/textGroup/CountdownDescription';
import FormCryptoCurrencyTradeConfirm from '../../../../organisms/form/cryptoCurrency/cryptoCurrencyTrade/FormCryptoCurrencyTradeConfirm';
import CryptoCurrencyTradeConfirmTemplateLayout from './CryptoCurrencyTradeConfirmTemplateLayout';

const TEMPLATE_ID =
  'templates.cryptoCurrency.CryptoCurrencyTradeConfirmTemplate';

const CryptoCurrencyTradeConfirmTemplate: FC = () => {
  const { t } = useTranslation();

  const { cryptoCurrencyTradeTransactionSeed } = useContext(
    CryptoCurrencyTradeTransactionSeedContext,
  );

  if (!cryptoCurrencyTradeTransactionSeed) return <></>;

  return (
    <CryptoCurrencyTradeConfirmTemplateLayout>
      <HeaderCryptoCurrencyTradeConfirm key="header" />
      <HeaderCryptoCurrencyTradeType key="contentHeader" />
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${TEMPLATE_ID}.description`, {
          tradeType: capitalize(
            cryptoCurrencyTradeTransactionSeed.getTradeType(),
          ),
        })}
      </TextSecondary>
      <TextPrimary key="tableTitle" theme={TableTitleStyle}>
        {t(`${TEMPLATE_ID}.table.title`)}
      </TextPrimary>
      <CountdownDescription
        key="rateUpdateInterval"
        text={t(`${TEMPLATE_ID}.rateUpdateIntervalText`)}
        marginBetween={10}
        theme={{
          textStyle: CountdownTextStyle,
          timerStyle: CountdownNumberStyle,
        }}
      />
      <TableConfirmCryptoCurrencyTrade key="table" />
      <FormCryptoCurrencyTradeConfirm key="form" />
    </CryptoCurrencyTradeConfirmTemplateLayout>
  );
};

export default CryptoCurrencyTradeConfirmTemplate;

import { useCallback, useState, useEffect } from 'react';
import storage from './storage';

export const useStorageImage = (sourceUrl?: string) => {
  const [storageUrl, setStorageUrl] = useState<string | undefined>(sourceUrl);

  /* *
   *
   *  Method
   *
   * */
  const setUrl = useCallback(async () => {
    if (!sourceUrl) return;

    const pathReference = storage.ref(sourceUrl);

    try {
      const url = await pathReference.getDownloadURL();
      setStorageUrl(url);
    } catch {
      // eslint-disable-next-line no-console
      // console.clear();
      setStorageUrl(undefined);
    }
  }, [sourceUrl]);

  /* *
   *
   *  UseEffect
   *
   * */
  useEffect(() => {
    let isMounted = true;

    if (isMounted && sourceUrl) setUrl();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [setUrl]);

  return {
    storageUrl,
  };
};

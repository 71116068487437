/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash';
import PersonalInformation from '../../../../../../value/verification/kycRequest/KycRequestContent/PersonalInformation/PersonalInformation';

export const isValid = (errors: any, watch: any) => {
  return (
    isEmpty(errors) &&
    !isEmpty(watch('firstName')) &&
    !isEmpty(watch('lastName')) &&
    !isEmpty(watch('gender')) &&
    !isEmpty(watch('dateOfBirthYear')) &&
    !isEmpty(watch('dateOfBirthMonth')) &&
    !isEmpty(watch('dateOfBirthDay')) &&
    !isEmpty(watch('passportNo')) &&
    !isEmpty(watch('passportUrl')) &&
    !isEmpty(watch('expiredDateYear')) &&
    !isEmpty(watch('expiredDateMonth')) &&
    !isEmpty(watch('expiredDateDay')) &&
    !isEmpty(watch('selfieUrl'))
  );
};

export const getDefaultValues = (personalInformation: PersonalInformation) => {
  const props = personalInformation.getProps();

  return {
    firstName: props.firstName,
    middleName: props.middleName,
    lastName: props.lastName,
    gender: props.gender as string,
    dateOfBirthYear: props.dateOfBirth.substr(0, 4),
    dateOfBirthMonth: props.dateOfBirth
      ? String(Number(props.dateOfBirth.substr(5, 2)))
      : '',
    dateOfBirthDay: props.dateOfBirth
      ? String(Number(props.dateOfBirth.substr(8, 2)))
      : '',
    passportNo: props.passport.passportNo,
    passportUrl: props.passport.passportUrl,
    expiredDateYear: props.passport.expiredAt.substr(0, 4),
    expiredDateMonth: props.passport.expiredAt
      ? String(Number(props.passport.expiredAt.substr(5, 2)))
      : '',
    expiredDateDay: props.passport.expiredAt
      ? String(Number(props.passport.expiredAt.substr(8, 2)))
      : '',
    selfieUrl: props.selfieUrl,
  };
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ConsentFormDoc } from '../../../../utils/firebase/firestore/verification/ConsentForm.type';
import { FormInputConsentForm } from '../../../../components/organisms/form/verification/consentForm/FormConsentForm/FormConsentForm.type';
import { INITIAL } from '../../../../config/constants/requestState';

export interface ConsentFormDataContextProps {
  consentFormData?: ConsentFormDoc;
  consentFormState: string;
  updateConsentForm: (
    content: FormInputConsentForm,
    callback?: () => void,
  ) => void;
}

const ConsentFormDataContext = React.createContext<ConsentFormDataContextProps>(
  {
    consentFormData: undefined,
    consentFormState: INITIAL,
    updateConsentForm: (content: FormInputConsentForm, callback?: () => void) =>
      undefined,
  },
);

export default ConsentFormDataContext;

/* eslint-disable @typescript-eslint/no-explicit-any */
// React
import React, { FC, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CryptoCurrencySpotTransactionsContext from '../../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransactions/CryptoCurrencySpotTransactionsContext';
import CryptoCurrencyWalletContext from '../../../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWalletContext';

// Mapper
import TableCryptoCurrencySpotTransactionHistoryMapper, {
  TdCryptoCurrencySpotTransactionHistory,
} from './TableCryptoCurrencySpotTransactionHistoryMapper';

// Components
import CryptoCurrencyTxidTdBox from '../../../../../molecules/table/td/CryptoCurrencyTxidTdBox';
import DefaultTdBox from '../../../../../molecules/table/td/DefaultTdBox';
import NumberTdBox from '../../../../../molecules/table/td/NumberTdBox';
import HistoryTable from '../../../../../molecules/table/table/HistoryTable';

// ID
const ORGANISM_ID = `organisms.table.TableCryptoCurrencyTransactionHistory`;

const TableCryptoCurrencySpotTransactionHistory: FC = () => {
  const { t } = useTranslation();

  const { cryptoCurrencySpotTransactions } = useContext(
    CryptoCurrencySpotTransactionsContext,
  );
  const { cryptoCurrencyWallet } = useContext(CryptoCurrencyWalletContext);

  const currency = useMemo(
    () => cryptoCurrencyWallet?.getProps().currency || '',
    [cryptoCurrencyWallet],
  );

  const data = useMemo(
    () =>
      TableCryptoCurrencySpotTransactionHistoryMapper.domainToTd(
        cryptoCurrencySpotTransactions,
        t,
      ),
    [cryptoCurrencySpotTransactions, t],
  );

  const columns = useMemo(
    () => [
      {
        Header: t(`${ORGANISM_ID}.th.date`),
        accessor: (values: TdCryptoCurrencySpotTransactionHistory) =>
          values.createdAt,
        Cell: (value: any) =>
          DefaultTdBox({ text: value.row.original.createdAt }),
        minWidth: `${150 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.transactionType`),
        accessor: (values: TdCryptoCurrencySpotTransactionHistory) =>
          values.transactionType,
        Cell: (value: any) =>
          DefaultTdBox({ text: value.row.original.transactionType }),
        minWidth: `${135 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.deposit`, { currency }),
        accessor: (values: TdCryptoCurrencySpotTransactionHistory) =>
          values.deposit,
        Cell: (value: any) => NumberTdBox({ text: value.row.original.deposit }),
        minWidth: `${195 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.withdrawal`, { currency }),
        accessor: (values: TdCryptoCurrencySpotTransactionHistory) =>
          values.withdrawal,
        Cell: (value: any) =>
          NumberTdBox({ text: value.row.original.withdrawal }),
        minWidth: `${195 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.fee`, { currency }),
        accessor: (values: TdCryptoCurrencySpotTransactionHistory) =>
          values.fee,
        Cell: (value: any) => NumberTdBox({ text: value.row.original.fee }),
        minWidth: `${120 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.status`),
        accessor: (values: TdCryptoCurrencySpotTransactionHistory) =>
          values.test,
        Cell: (value: any) => DefaultTdBox({ text: value.row.original.status }),
        minWidth: `${165 / 16}rem`,
      },
      {
        Header: t(`${ORGANISM_ID}.th.txid`),
        accessor: (values: TdCryptoCurrencySpotTransactionHistory) =>
          values.txid,
        Cell: (value: any) =>
          CryptoCurrencyTxidTdBox({ txid: value.row.original.txid, currency }),
        minWidth: `${180 / 16}rem`,
      },
    ],
    [t, currency],
  );

  return <HistoryTable data={data} columns={columns} width={1110} />;
};

export default TableCryptoCurrencySpotTransactionHistory;

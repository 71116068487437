import React, { FC, useContext } from 'react';
import { UpdatePasswordContext } from '../../../enhancers/settings/UpdatePasswordProvider/UpdatePasswordContext';
import { SUCCESS } from '../../../config/constants/requestState';
import DashboardTemplate from '../../../components/templates/common/DashboardTemplate';
import PasswordEditTemplate from '../../../components/templates/settings/PasswordEditTemplate';
import PasswordEditCompleteTemplate from '../../../components/templates/settings/PasswordEditCompleteTemplate';

const PasswordEditPage: FC = () => {
  const { updatePasswordState } = useContext(UpdatePasswordContext);

  return (
    <DashboardTemplate>
      {(() => {
        if (updatePasswordState === SUCCESS)
          return <PasswordEditCompleteTemplate />;
        return <PasswordEditTemplate />;
      })()}
    </DashboardTemplate>
  );
};

export default PasswordEditPage;

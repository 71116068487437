import Joi from '@hapi/joi';

// Type
import { CurrencyType } from '../../../../../../../types/Currency.type';
import { CryptoCurrencyTradeType } from '../../../../../../../types/CryptoCurrency.type';
import {
  CryptoCurrencyTransactionProps,
  CryptoCurrencyTransactionPropsFormat,
} from '../../../../cryptoCurrencyTransaction/CryptoCurrencyTransaction/CryptoCurrencyTransaction.type';

export interface CryptoCurrencyTradeTransactionProps
  extends CryptoCurrencyTransactionProps {
  savingsAccountNumber: string;

  payAmount: number;

  payCurrency: CurrencyType;

  receiveAmount: number;

  receiveCurrency: CurrencyType;

  rate: number;

  tradeMargin: number;

  tradeType: CryptoCurrencyTradeType;
}

export const CryptoCurrencyTradeTransactionPropsFormat = {
  ...CryptoCurrencyTransactionPropsFormat,

  savingsAccountNumber: Joi.string().min(1).empty(''),

  payAmount: Joi.number().min(0).required(),

  receiveAmount: Joi.number().min(0).required(),

  rate: Joi.number().min(0).required(),

  tradeType: Object.keys(CryptoCurrencyTradeType)
    .reduce((joi, tradeType) => joi.valid(tradeType), Joi.string())
    .required(),

  tradeMargin: Joi.number().required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

// Type
import { CorporateInformationProps } from '../../../../../value/verification/kycRequest/KycRequestContent/CorporateInformation/CorporateInformation.type';
import { CorporateAddressProps } from '../../../../../value/verification/kycRequest/KycRequestContent/CorporateAddress/CorporateAddress.type';
import { KycRequestCorporateProps } from './KycRequestCorporate.type';
import { PersonalInformationProps } from '../../../../../value/verification/kycRequest/KycRequestContent/PersonalInformation/PersonalInformation.type';
import { ProofOfResidencyProps } from '../../../../../value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidency.type';

// DomainObject
import KycRequest from '../KycRequest/KycRequest';

// ValueObject
import CorporateInformation from '../../../../../value/verification/kycRequest/KycRequestContent/CorporateInformation/CorporateInformation';
import CorporateInformationFactory from '../../../../../value/verification/kycRequest/KycRequestContent/CorporateInformation/CorporateInformationFactory';
import CorporateAddress from '../../../../../value/verification/kycRequest/KycRequestContent/CorporateAddress/CorporateAddress';
import CorporateAddressFactory from '../../../../../value/verification/kycRequest/KycRequestContent/CorporateAddress/CorporateAddressFactory';
import KycStepStatusCorporate from '../../../../../value/verification/kycRequest/KycStepStatus/KycStepStatusCorporate/KycStepStatusCorporate';

class KycRequestCorporate extends KycRequest {
  private corporateInformation: CorporateInformation;

  private corporateAddress: CorporateAddress;

  protected kycStepStatus: KycStepStatusCorporate;

  constructor(id: string, props: KycRequestCorporateProps) {
    super(id, props);
    this.corporateInformation = CorporateInformationFactory.create(
      props.corporateInformation,
    );
    this.corporateAddress = CorporateAddressFactory.create(
      props.corporateAddress,
    );
    this.kycStepStatus = new KycStepStatusCorporate(props.kycStepStatus);
  }

  public getProps() {
    return {
      ...super.getProps(),
      corporateInformation: this.corporateInformation.getProps(),
      corporateAddress: this.corporateAddress.getProps(),
      kycStepStatus: this.kycStepStatus.getProps(),
    };
  }

  public getCorporateInformation = () => this.corporateInformation;

  public getCorporateAddress = () => this.corporateAddress;

  public getKycStepStatus = () => this.kycStepStatus;

  public getCommandParameter = () => {
    const allStatus = this.kycStepStatus.getProps();

    return {
      ...super.getCommandParameter(),
      status: allStatus.status,
      corporate: {
        corporateInformation: {
          ...this.corporateInformation.getCommandParameter(),
          status: allStatus.corporateInformation,
        },
        corporateAddress: {
          ...this.corporateAddress.getCommandParameter(),
          status: allStatus.corporateAddress,
        },
        personalInformation: {
          ...this.personalInformation.getCommandParameter(),
          status: allStatus.personalInformation,
        },
        proofOfResidency: {
          ...this.proofOfResidency.getCommandParameter(),
          status: allStatus.proofOfResidency,
        },
        waitingReview: {
          status: allStatus.waitingReview,
        },
      },
    };
  };

  public updateCorporateInformation = (props: CorporateInformationProps) => {
    const corporateInformation = this.corporateInformation.updateProps(props);

    return new KycRequestCorporate(this.id, {
      ...this.getProps(),
      corporateInformation: corporateInformation.getProps(),
    });
  };

  public updateCorporateAddress = (props: CorporateAddressProps) => {
    const corporateAddress = this.corporateAddress.updateProps(props);

    return new KycRequestCorporate(this.id, {
      ...this.getProps(),
      corporateAddress: corporateAddress.getProps(),
    });
  };

  public updatePersonalInformation = (props: PersonalInformationProps) => {
    const personalInformation = this.personalInformation.updateProps(props);

    return new KycRequestCorporate(this.id, {
      ...this.getProps(),
      personalInformation: personalInformation.getProps(),
    });
  };

  public updateProofOfResidency = (props: ProofOfResidencyProps) => {
    const proofOfResidency = this.proofOfResidency.updateProps(props);

    return new KycRequestCorporate(this.id, {
      ...this.getProps(),
      proofOfResidency: proofOfResidency.getProps(),
    });
  };
}
export default KycRequestCorporate;

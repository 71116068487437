import Joi from '@hapi/joi';
import { BankWithdrawalType } from '../../../../../../types/BankAccountTransaction.type';
import {
  BankSpotTransactionProps,
  BankSpotTransactionPropsFormat,
} from '../../bankSpotTransaction/BankSpotTransaction/BankSpotTransaction.type';

export interface BankWithdrawalProps extends BankSpotTransactionProps {
  bankWithdrawalType: BankWithdrawalType;
}

export const BankWithdrawalPropsFormat = {
  ...BankSpotTransactionPropsFormat,

  bankWithdrawalType: Object.keys(BankWithdrawalType)
    .reduce(
      (joi, bankWithdrawalType) => joi.valid(bankWithdrawalType),
      Joi.string(),
    )
    .required(),
};

// Type
import { TypeOfT } from 'types/typeof/Translation.type';
import { TdTransferDebitCardTransactionHistory } from 'components/organisms/table/history/TableTransferDebitCardTransactionHistory/TableTransferDebitCardTransactionHistoryMapper';

// Libs
import {
  convertMoneyFormat,
  getSymbolFromCurrency,
} from 'utils/helpers/currencyHelper';

const ORGANISM_ID = `organisms.table.TableConfirmTransferDebitCardTransactionV2s`;

class TableConfirmTransferDebitCardTransactionV2sMapper {
  static toTableData = (
    topUpData: TdTransferDebitCardTransactionHistory,
    t: TypeOfT,
  ) => {
    const { amount, createdAtString, currency } = topUpData;
    const currencySymbol = getSymbolFromCurrency(currency);

    // Amount
    const amountFormatted = convertMoneyFormat(amount, currency);

    return [
      {
        header: t(`${ORGANISM_ID}.th.requestDate`),
        data: createdAtString,
      },
      {
        header: t(`${ORGANISM_ID}.th.amount`),
        data: `${currencySymbol} ${amountFormatted}`,
      },
    ];
  };
}

export default TableConfirmTransferDebitCardTransactionV2sMapper;

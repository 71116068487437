// Type
import { FiatCurrencyType } from '../../../../../types/FiatCurrency.type';
import { TypeOfT } from '../../../../../types/typeof/Translation.type';

// DomainObject
import BankDeposit from '../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankDeposit/BankDeposit/BankDeposit';
import BankWithdrawal from '../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankWithdrawal/BankWithdrawal/BankWithdrawal';
import BankSpotTransactions from '../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankSpotTransaction/BankSpotTransactions/BankSpotTransactions';
import BankSpotTransaction from '../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankSpotTransaction/BankSpotTransaction/BankSpotTransaction';

// ValueObject
import TimeZonedTime from '../../../../../value/datetime/TimeZonedTime';
import { convertMoneyFormat } from '../../../../../utils/helpers/currencyHelper';

type Object = {
  [key: string]: string | number | TimeZonedTime | FiatCurrencyType | undefined;
};

export interface TdBankTransactionHistory extends Object {
  createdAt: TimeZonedTime;
  currency: FiatCurrencyType;
  description: string;
  deposit: number;
  withdrawal: number;
  balance: number;
  createdAtCSV: string;
  depositCSV: string;
  withdrawalCSV: string;
  balanceCSV: string;
}

class TableBankTransactionHistoryMapper {
  static domainToTd = (transactions: BankSpotTransactions, t: TypeOfT) => {
    const converter = (bankSpotTransaction: BankSpotTransaction) => {
      const {
        createdAt,
        amount,
        balance,
        currency,
      } = bankSpotTransaction.getProps();

      const tdDeposit =
        bankSpotTransaction instanceof BankDeposit ? amount : undefined;

      const tdWithdrawal =
        bankSpotTransaction instanceof BankWithdrawal ? amount : undefined;

      return {
        createdAt: new TimeZonedTime(createdAt),
        currency,
        description: bankSpotTransaction.createDescription(t),
        deposit: tdDeposit,
        withdrawal: tdWithdrawal,
        balance,
        createdAtCSV: new TimeZonedTime(createdAt).getTwoLinesDateTime(),
        depositCSV:
          (tdDeposit && convertMoneyFormat(tdDeposit, currency)) || '',
        withdrawalCSV:
          (tdWithdrawal && convertMoneyFormat(tdWithdrawal, currency)) || '',
        balanceCSV: (balance && convertMoneyFormat(balance, currency)) || '',
      } as TdBankTransactionHistory;
    };

    return transactions.map<TdBankTransactionHistory>(converter);
  };
}

export default TableBankTransactionHistoryMapper;

import React, { FC } from 'react';
import { Container } from './style';

export interface SettingsTemplateFormContentProps {
  children: React.ReactNode;
}

const SettingsTemplateFormContent: FC<SettingsTemplateFormContentProps> = ({
  children,
}: SettingsTemplateFormContentProps) => {
  return <Container>{children}</Container>;
};

export default SettingsTemplateFormContent;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

// type
import { TransferSavingsAccountType } from '../../../../../../types/Transfer.type';

// LayoutComponent
import Box from '../../../../../atoms/utils/layout/Box/Box';

// Component
import {
  DefaultOptionContainer,
  DefaultSingleValueContainer,
} from '../../../option/optionContainer/DefaultOptionContainer';
import HiddenInput from '../../../../../atoms/form/HiddenInput';
import SelectBox from '../../../../../atoms/select/SelectBox';

export interface TransferSavingsAccountTypeSelectBoxProps {
  name: string;
  onSelect?: (value: string) => void;
}

const TransferSavingsAccountTypeSelectBox: FC<TransferSavingsAccountTypeSelectBoxProps> = ({
  name,
  onSelect,
}: TransferSavingsAccountTypeSelectBoxProps) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();

  // Options
  const options = Object.keys(TransferSavingsAccountType).map((value) => ({
    label: t(`molecules.select.transferSavingsAccountType.options.${value}`),
    value,
  }));

  return (
    <Box>
      <SelectBox
        borderColor="#d6ddec"
        dropdownColor="#212F41"
        isSearchable={false}
        marginLeft={`${0 / 16}rem`}
        minHeight={`${70 / 16}rem`}
        onSelect={(transferType: string) => {
          setValue(name, transferType);
          (onSelect || Function)(transferType);
        }}
        options={options || []}
        placeholderColor="#D5D0DA"
        value={watch(name)}
        OptionComponent={DefaultOptionContainer}
        SingleValueComponent={DefaultSingleValueContainer}
      />
      <HiddenInput name={name} value={watch(name) || ''} />
    </Box>
  );
};

export default TransferSavingsAccountTypeSelectBox;

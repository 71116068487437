import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import fontColor from '../../../../fonts/color.json';
import fontWeight from '../../../../fonts/fontWeight.json';
import TextFonted from '../../../atoms/text/TextFonted';

export interface LinkTextProps {
  text: string;
  urlList: Array<{ replaceText: string; url: string; target?: string }>;
  color?: string;
  pixel?: number;
  fontFamilyType?: 'primary' | 'sub';
  textFontWeight?: number;
  textDecoration?: string;
}

const LinkText: FC<LinkTextProps> = ({
  text,
  urlList,
  pixel = 18,
  textFontWeight = fontWeight.regular,
  fontFamilyType = 'sub',
  color = fontColor.textBright,
  textDecoration = 'underline',
}: LinkTextProps) => {
  let items: string | React.ReactNodeArray = text;

  // eslint-disable-next-line no-restricted-syntax
  for (const replace of urlList) {
    items = reactStringReplace(items, replace.replaceText, (match) => (
      <>
        <Link
          to={`${replace.url}`}
          target={replace.target ? replace.target : ''}
        >
          <TextFonted
            pixel={pixel}
            color={color}
            fontWeight={textFontWeight}
            fontFamilyType={fontFamilyType}
            textDecoration={textDecoration}
          >
            {match}
          </TextFonted>
        </Link>
      </>
    ));
  }

  return (
    <>
      {(items as React.ReactNodeArray).map((item, i) => {
        if (typeof item === 'string') {
          if (urlList.length === 1)
            return (
              <span key={`match${i}`}>
                <TextFonted
                  pixel={pixel}
                  color={color}
                  fontWeight={textFontWeight}
                  fontFamilyType={fontFamilyType}
                >
                  {item}
                </TextFonted>
              </span>
            );

          return (
            <pre key={`match${i}`}>
              <TextFonted
                pixel={pixel}
                color={color}
                fontWeight={textFontWeight}
                fontFamilyType={fontFamilyType}
              >
                {item}
              </TextFonted>
            </pre>
          );
        }
        return (
          <span style={{ color }} key={`match${i}`}>
            {item}
          </span>
        );
      })}
    </>
  );
};
export default LinkText;

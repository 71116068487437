import { includeFullWidth } from '../../../../../../utils/helpers/stringHelper';

export const getAmount = (value: string) => {
  // 全角 or 数字以外の文字
  if (includeFullWidth(value) || Number.isNaN(Number(value))) {
    const res = value.replace(/[^0-9]/g, '');
    return res;
  }
  return String(value);
};

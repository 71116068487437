import { useState, useEffect } from 'react';
import storage from '../../../utils/firebase/cloudStorage/storage';

// Constant
import { PATH_DEBIT_CARD_FEE_FILE } from '../../../config/constants/routing';

export const useDebitCardFeeFile = () => {
  const [debitCardFeeFileUrl, setDebitCardFeeFileUrl] = useState();

  useEffect(() => {
    const getUrl = async () => {
      const pathReference = storage.ref(PATH_DEBIT_CARD_FEE_FILE);
      const url = await pathReference.getDownloadURL();
      setDebitCardFeeFileUrl(url);
    };
    getUrl();
  }, []);

  return { debitCardFeeFileUrl };
};

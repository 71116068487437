import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
import { DebitCardTransactionIdProps } from './DebitCardTransactionId.type';
import TransactionId from '../TransactionId/TransactionId';

class DebitCardTransactionId extends TransactionId {
  private currency: FiatCurrencyType;

  constructor(props: DebitCardTransactionIdProps) {
    super(props);
    this.currency = props.currency;
  }

  protected getPropsOrderForId = () => [
    this.prefix,
    this.currency,
    this.date,
    this.serialNumber.getSerialNumber(),
    this.target.substr(-6),
  ];
}
export default DebitCardTransactionId;

import React, { FC, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDebitCardRequestCherryVisa01StatusViewUseCase } from './useDebitCardRequestCherryVisa01StatusViewUseCase';

// Error
import { ErrorCodeType } from '../../../../../../utils/errors/ErrorHandler/ErrorCode.type';
import SystemErrorHandler from '../../../../../errorHandler/SystemErrorHandler';

// Constant
import { ROUTE_DEBIT_CARD } from '../../../../../../config/constants/routing';

// Context
import DebitCardRequestCherryVisa01Context from '../../../../../../domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Context';
import UserAuthorizedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// For Redirect
const REDIRECT_ERROR_CODES = [
  ErrorCodeType.DEBIT_CARD_REQUEST_INVALID_ISSUER,
  ErrorCodeType.SAVINGS_ACCOUNT_INVALID_ACCOUNT_NUMBER_BY_CLIENT,
  ErrorCodeType.SAVINGS_ACCOUNT_FORBIDDEN_READ_SAVINGS_INFO,
];

export type DebitCardRequestCherryVisa01StatusViewProviderProps = {
  children: React.ReactNode;
};

const DebitCardRequestCherryVisa01StatusViewProvider: FC<DebitCardRequestCherryVisa01StatusViewProviderProps> = ({
  children,
}: DebitCardRequestCherryVisa01StatusViewProviderProps) => {
  const history = useHistory();

  // DomainObject
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const { id } = useParams<{ id: string }>();

  const {
    state,
    setState,
    debitCardRequestCherryVisa01,
  } = useDebitCardRequestCherryVisa01StatusViewUseCase(id, userAuthorized);

  /* *
   *
   *  UseEffect
   *
   * */

  // state (client error)
  useEffect(() => {
    if (REDIRECT_ERROR_CODES.includes(state as ErrorCodeType))
      history.push(ROUTE_DEBIT_CARD);
    // eslint-disable-next-line
  }, [state]);

  return (
    <DebitCardRequestCherryVisa01Context.Provider
      value={{ debitCardRequestCherryVisa01 }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </DebitCardRequestCherryVisa01Context.Provider>
  );
};

export default DebitCardRequestCherryVisa01StatusViewProvider;

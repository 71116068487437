// IUseCase
import IResignUpUseCase from '../../../enhancers/useCase/authentication/ResignUpProvider/IResignUpUseCase';

// IService
import IResignUpRequestCreateService from './IResignUpRequestCreateService';

// Service
import ResignUpRequestCreateService from '../../../service/authentication/SignUpRequestCreateService';

// IRepository
import { IResignUpRequestCreateRepository } from '../../../domain/userRequest/resignUpRequest/IResignUpRequestRepository';

class ResignUpUseCase implements IResignUpUseCase {
  private resignUpRequestCreateService: IResignUpRequestCreateService;

  constructor(
    resignUpRequestCreateRepository: IResignUpRequestCreateRepository,
  ) {
    this.resignUpRequestCreateService = new ResignUpRequestCreateService(
      resignUpRequestCreateRepository,
    );
  }

  public resignUp = async (email: string, reregistrationCode: string) => {
    await this.resignUpRequestCreateService.executeCreate(
      email,
      reregistrationCode,
    );
  };
}
export default ResignUpUseCase;

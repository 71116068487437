import { required, emailFormat } from '../validator';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const forgotPasswordRegister = (field: string, t: any) => {
  switch (field) {
    case 'email':
      return {
        required: required('Email Address', t),
        validate: {
          emailFormat: emailFormat(t),
        },
      };
    default:
      return undefined;
  }
};

export default forgotPasswordRegister;

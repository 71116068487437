import { ReferralResultOptionType } from '../../../components/organisms/tabBar/ReferralResultTabBar/ReferralResultTabBar.type';

export const ReferralResultRankDTabs = [
  ReferralResultOptionType.contractTimeDeposit,
  ReferralResultOptionType.commission,
];

export const ReferralResultRankATabs = [
  ReferralResultOptionType.contractTimeDeposit,
];

import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const TextStyle = {
  fontSize: 23,
  color: color.primary,
  fontWeight: fontWeight.regular,
};

export const SelectedStyle = {
  borderBottom: `${6 / 16}rem solid ${color.primary}`,
};

export const UnSelectedStyle = {
  opacity: 0.3,
};

import React, { FC } from 'react';
import PassportExampleImage from '../../../../assets/images/passport-example.png';
import PassportExampleBrownImage from '../../../../assets/images/passport-example-brawn.svg';
import SelfieExampleImage from '../../../../assets/images/selfie-example.png';
import SignatureExample1Image from '../../../../assets/images/example_signature_example1.png';
import SignatureExample2Image from '../../../../assets/images/example_signature_example2.png';
import PlainSignatureExampleImage from '../../../../assets/images/plain_signature_example.png';

export interface ExampleImageProps {
  width?: string | number;
  height?: string | number;
  type:
    | 'passport'
    | 'selfie'
    | 'signatureExample1'
    | 'signatureExample2'
    | 'plainSignatureExample'
    | 'selfieNG1'
    | 'selfieNG2'
    | 'selfieNG3'
    | 'selfieOK'
    | 'passportBrown';
}

const ExampleImage: FC<ExampleImageProps> = ({
  width = '100%',
  height = '100%',
  type,
}: ExampleImageProps) => {
  let src = '';

  switch (type) {
    case 'passport':
      src = PassportExampleImage;
      break;
    case 'passportBrown':
      src = PassportExampleBrownImage;
      break;
    case 'selfie':
      src = SelfieExampleImage;
      break;
    case 'signatureExample1':
      src = SignatureExample1Image;
      break;
    case 'signatureExample2':
      src = SignatureExample2Image;
      break;
    case 'plainSignatureExample':
      src = PlainSignatureExampleImage;
      break;
    default:
  }

  return (
    <div>
      <img src={src} alt={type} width={width} height={height} />
    </div>
  );
};
export default ExampleImage;

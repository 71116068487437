class HooksError implements Error {
  public name = 'HooksError';

  public message = '';

  public code = '';

  public response: {
    data: { code: string; message: string };
  };

  constructor(code: string, message: string) {
    this.code = code;
    this.message = message;
    this.response = {
      data: { code, message },
    };
  }
}

export default HooksError;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxRow } from '../../../../../atoms/utils/layout/Box/FlexBox';

export interface HeaderTwoFactorAuthNewAccountKeyTemplateLayoutProps {
  children: React.ReactElement[];
}

const HeaderTwoFactorAuthNewAccountKeyTemplateLayout: FC<HeaderTwoFactorAuthNewAccountKeyTemplateLayoutProps> = ({
  children,
}: HeaderTwoFactorAuthNewAccountKeyTemplateLayoutProps) => {
  const elements = ['left', 'right'];
  const [left, right] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow width={1200} justifyContent="space-between">
      {left}
      {right}
    </FlexBoxRow>
  );
};
export default HeaderTwoFactorAuthNewAccountKeyTemplateLayout;

import { NameProps } from './Name.type';

class Name {
  private firstName: string;

  private middleName?: string;

  private lastName: string;

  constructor(props: NameProps) {
    this.firstName = props.firstName;
    this.middleName = props.middleName;
    this.lastName = props.lastName;
  }

  public getProps = () => {
    const props = {
      firstName: this.firstName,
      lastName: this.lastName,
    };
    if (this.middleName) Object.assign(props, { middleName: this.middleName });

    return props as NameProps;
  };

  public getFullName = () =>
    this.middleName
      ? `${this.firstName} ${this.middleName} ${this.lastName}`
      : `${this.firstName} ${this.lastName}`;
}
export default Name;

// Functions
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// Type
import { DebitCardRequestRawDataType } from '../../../domain/debitCard/debitCardRequest/DebitCardRequest/DebitCardRequest/DebitCardRequest.type';
import { DebitCardIssuerType } from '../../../types/DebitCard.type';

// IRepository
import IDebitCardRequestRepository from '../../../domain/debitCard/debitCardRequest/IDebitCardRequestRepository';

// Constant
import {
  DEBIT_CARD_REQUEST_CREATE,
  DEBIT_CARD_REQUEST_GET_UNCONFIRMED_RAW_DATA,
  DEBIT_CARD_REQUEST_GET_RAW_ALL_BY_USER_ID,
} from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// DomainObject
import DebitCardRequests from '../../../domain/debitCard/debitCardRequest/DebitCardRequest/DebitCardRequests/DebitCardRequests';
import DebitCardRequestFactory from '../../../domain/debitCard/debitCardRequest/DebitCardRequest/DebitCardRequest/DebitCardRequestFactory';

class DebitCardRequestFunctions
  extends FirebasePrivateFunctions
  implements IDebitCardRequestRepository {
  public findAllByUserId = async (userId: string) => {
    const endpoint = DEBIT_CARD_REQUEST_GET_RAW_ALL_BY_USER_ID.replace(
      /:userId/,
      userId,
    );

    const response = await this.privateGET(endpoint);

    const rows = response.data.data as Array<DebitCardRequestRawDataType>;

    return rows.reduce((debitCardRequests, row) => {
      debitCardRequests.add(DebitCardRequestFactory.create(row.id, row.props));

      return debitCardRequests;
    }, new DebitCardRequests());
  };

  public findUncompletedRequestOrUndef = async (
    savingsAccountNumber: string,
    issuer: DebitCardIssuerType,
  ) => {
    // Replace savingsAccountNumber & issuer
    const endpoint = DEBIT_CARD_REQUEST_GET_UNCONFIRMED_RAW_DATA.replace(
      /:savingsAccountNumber/,
      savingsAccountNumber,
    ).replace(/:issuer/, issuer);

    const response = await this.privateGET(endpoint);

    const row = response.data.data as DebitCardRequestRawDataType;

    if (!row) return undefined;

    return DebitCardRequestFactory.create(row.id, row.props);
  };

  public create = async (
    savingsAccountNumber: string,
    issuer: DebitCardIssuerType,
  ) => {
    const response = await this.privatePOST(DEBIT_CARD_REQUEST_CREATE, {
      issuer,
      savingsAccountNumber,
    });

    const row = response.data.data as DebitCardRequestRawDataType;

    return DebitCardRequestFactory.createByIssuer(row.id, row.props);
  };
}
export default DebitCardRequestFunctions;

import Joi from '@hapi/joi';

// Type
import { CherryImage } from '../../../../types/CherryImage.type';
import {
  DebitCardRequestContentProps,
  DebitCardRequestContentPropsFormat,
} from '../DebitCardRequestContent/DebitCardRequestContent.type';
import { Gender } from '../../../../types/KycRequest.type';

export enum CherryVisa01ProofOfIdentityDocumentType {
  idCard = 'id_card',
  residencePermit = 'residence_permit',
  drivingLicense = 'driving_license',
}

export type CherryVisa01ProofOfIdentitySystemProps = {
  email: string;
  firstName: string;
  middleName?: string;
  lastName: string;
};

export type CherryVisa01ProofOfIdentityUserProps = Omit<
  CherryVisa01ProofOfIdentityProps,
  keyof CherryVisa01ProofOfIdentitySystemProps
>;

export interface CherryVisa01ProofOfIdentityProps
  extends DebitCardRequestContentProps {
  email: string;

  firstName: string;

  middleName?: string;

  lastName: string;

  gender: Gender;

  nationality: string;

  phoneNumber: string;

  countryCode: string;

  occupation: string;

  isInscribed: boolean;

  dateOfBirth: string;

  document: {
    type: CherryVisa01ProofOfIdentityDocumentType;

    issuingCountry: string;

    documentId1: string;

    documentId2?: string;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document1?: any;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document2?: any;
  };

  passport: {
    passportNo: string;

    issuedAt: string;

    expiredAt: string;

    passportId: string;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    passport?: any;

    selfieId: string;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selfie?: any;
  };

  signatureId: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signature?: any;

  shippingAddress: {
    country: string;

    addressLine1: string;

    addressLine2?: string;

    city: string;

    state: string;

    postalCode: string;
  };
}

export type CherryVisa01ProofOfIdentityRawDataType = CherryVisa01ProofOfIdentityProps;

export type CherryVisa01ProofOfIdentityRawDataTypeWithFiles = CherryVisa01ProofOfIdentityRawDataType & {
  document: {
    type: CherryVisa01ProofOfIdentityDocumentType;
    issuingCountry: string;
    documentId1: string;
    documentImage1?: CherryImage;
    documentId2?: string;
    documentImage2?: CherryImage;
  };
  passport: {
    passportNo: string;
    issuedAt: string;
    expiredAt: string;
    passportId: string;
    passportImage?: CherryImage;
    selfieId: string;
    selfieImage?: CherryImage;
  };
  signatureId: string;
  signatureImage?: CherryImage;
};

export const CherryVisa01ProofOfIdentityPropsFormat = {
  ...DebitCardRequestContentPropsFormat,

  email: Joi.string().empty(''),

  firstName: Joi.string().required().allow(''),

  middleName: Joi.string().empty('').allow(null),

  lastName: Joi.string().required().allow(''),

  gender: Object.keys(Gender)
    .reduce((joi, gender) => joi.valid(gender), Joi.string())
    .required(),

  nationality: Joi.string().required(),

  countryCode: Joi.string().required(),

  phoneNumber: Joi.string().required(),

  occupation: Joi.string().required(),

  isInscribed: Joi.boolean().required(),

  dateOfBirth: Joi.string().required(),

  document: {
    type: Object.values(CherryVisa01ProofOfIdentityDocumentType)
      .reduce((joi, type) => joi.valid(type), Joi.string())
      .required(),

    issuingCountry: Joi.string().required(),

    documentId1: Joi.string().required(),

    documentId2: Joi.string().allow('').allow(null),

    document1: Joi.any(),

    document2: Joi.any(),
  },

  passport: {
    passportNo: Joi.string().required(),

    issuedAt: Joi.string().required(),

    expiredAt: Joi.string().required(),

    passportId: Joi.string().required(),

    passport: Joi.any(),

    selfieId: Joi.string().required(),

    selfie: Joi.any(),
  },

  signatureId: Joi.string().required(),

  signature: Joi.any(),

  shippingAddress: {
    country: Joi.string().required(),

    addressLine1: Joi.string().required(),

    addressLine2: Joi.string().allow('').allow(null),

    city: Joi.string().required(),

    state: Joi.string().required(),

    postalCode: Joi.string().required(),
  },
};

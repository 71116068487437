import React from 'react';

// DomainObject
import DebitCardV2s from './DebitCardV2s';

export interface DebitCardV2sContextProps {
  debitCards: DebitCardV2s;
}

const DebitCardV2sContext = React.createContext<DebitCardV2sContextProps>({
  debitCards: new DebitCardV2s(),
});

export default DebitCardV2sContext;

import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

export interface HiddenInputProps {
  name: string;
  value: string;
}

const HiddenInput: FC<HiddenInputProps> = ({
  name,
  value = '',
}: HiddenInputProps) => {
  const { register } = useFormContext();

  return <input type="hidden" name={name} value={value} ref={register} />;
};

export default HiddenInput;

import React, { FC } from 'react';
import TextField from '../TextField';

export interface SettingsTextFieldProps {
  name: string;
  value?: string;
  type?: 'password';
  placeholder?: string;
  errorMessage?: string;
  maxLength: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any;
  onChange?: (text: string) => void;
  textInputWidth?: string;
  disabled?: boolean;
}

const SettingsTextField: FC<SettingsTextFieldProps> = ({
  name,
  value = '',
  errorMessage = '',
  placeholder = '',
  maxLength,
  onChange = () => undefined,
  register = null,
  type,
  textInputWidth = '100%',
  disabled = false,
}: SettingsTextFieldProps) => {
  return (
    <TextField
      name={name}
      value={value}
      type={type}
      placeholder={placeholder}
      errorMessage={errorMessage}
      onChange={onChange}
      maxLength={maxLength}
      register={register}
      textInputWidth={textInputWidth}
      disabled={disabled}
    />
  );
};

export default SettingsTextField;

import React, { FC } from 'react';

// Style
import { LabelStyle } from './style';

// DomainObject
import { TextPrimary } from '../../../../../../atoms/text2/Text2';
import DateSelectBox from '../../../../../selectBox/selectBox/verification/DateSelectBox';
import FormInputDateSelectBoxLayout from './FormInputDateSelectBoxLayout';

interface FormInputDateSelectBoxProps {
  label: string;
  labelWidth: string | number;
  name: string;
  dateStart?: { year: number; month: number; day: number }; // timestamp
  dateEnd?: { year: number; month: number; day: number }; // timestamp
}

const FormInputDateSelectBox: FC<FormInputDateSelectBoxProps> = ({
  label,
  labelWidth,
  name,
  dateStart,
  dateEnd,
}: FormInputDateSelectBoxProps) => {
  return (
    <FormInputDateSelectBoxLayout labelWidth={labelWidth}>
      <TextPrimary key="label" theme={LabelStyle}>
        {label}
      </TextPrimary>
      <DateSelectBox
        key="input"
        name={name}
        dateStart={dateStart}
        dateEnd={dateEnd}
      />
    </FormInputDateSelectBoxLayout>
  );
};

export default FormInputDateSelectBox;

// IUseCase
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import IDebitCardViewUseCase from '../../../enhancers/useCase/debitCard/debitCard/DebitCardViewProvider/IDebitCardViewUseCase';

// IService
import IDebitCardV2QueryService from './IDebitCardV2QueryService';
import ISavingsAccountQueryService from '../../bankAccount/savingsAccount/ISavingsAccountQueryService';

// Service
import DebitCardV2QueryService from '../../../service/debitCard/debitCard/DebitCardV2QueryService';
import SavingsAccountQueryService from '../../../service/bankAccount/savingsAccount/SavingsAccountQueryService';

// IRepository
import IDebitCardV2Repository from '../../../domain/debitCard/debitCardV2/IDebitCardV2Repository';
import ISavingsAccountRepository from '../../../domain/bankAccount/savingsAccount/ISavingsAccountRepository';

// DomainObject
import SavingsAccount from '../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

class DebitCardViewUseCase implements IDebitCardViewUseCase {
  private debitCardV2QueryService: IDebitCardV2QueryService;

  private savingsAccountQueryService: ISavingsAccountQueryService;

  constructor(
    debitCardV2Repository: IDebitCardV2Repository,
    savingsAccountRepository: ISavingsAccountRepository,
  ) {
    this.debitCardV2QueryService = new DebitCardV2QueryService(
      debitCardV2Repository,
    );

    this.savingsAccountQueryService = new SavingsAccountQueryService(
      savingsAccountRepository,
    );
  }

  public open = async (user: UserAuthorized) => {
    const savingsAccounts = await this.savingsAccountQueryService.findAllByUserId(
      user.getId(),
    );

    return { savingsAccounts };
  };

  public findDebitCards = async (savingsAccount: SavingsAccount) => {
    const debitCardV2s = await this.debitCardV2QueryService.findAllBySavingsAccountNumber(
      savingsAccount.getRawAccountNumber(),
    );

    return { debitCardV2s };
  };
}
export default DebitCardViewUseCase;

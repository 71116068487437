// Error
import { ErrorIdType } from '../../../errors/ErrorMessage/ErrorMessage';
import SystemErrorFactory from '../../../errors/ErrorFactory/SystemErrorFactory';

// Type & Error
import { CryptoCurrencyType } from '../../../types/CryptoCurrency.type';

// IRepository
import ICryptoCurrencyWalletRepository from '../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/ICryptoCurrencyWalletRepository';

// Repository
import BitcoinWalletFirestore from './BitcoinWalletFirestore';
import LitecoinTestnetWalletFirestore from './LitecoinTestnetWalletFirestore';

class CryptoCurrencyWalletFirestoreFactory {
  static create = (
    cryptoCurrency: CryptoCurrencyType,
  ): ICryptoCurrencyWalletRepository => {
    switch (cryptoCurrency) {
      case CryptoCurrencyType.BTC:
        return new BitcoinWalletFirestore();
      case CryptoCurrencyType.LTCT:
        return new LitecoinTestnetWalletFirestore();
      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_SWITCH,
          {
            value: cryptoCurrency,
            place: `CryptoCurrencyWalletFirestoreFactory.create`,
          },
        );
    }
  };
}

export default CryptoCurrencyWalletFirestoreFactory;

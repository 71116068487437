import color from '../../utils/const/color';
import fontWeight from '../../utils/const/fontWeight';

export const DefaultButtonTextStyle = {
  fontSize: 24,
  color: color.white,
  fontWeight: fontWeight.medium,
};

export const CancelButtonTextStyle = {
  fontSize: 24,
  color: color.blue,
  fontWeight: fontWeight.medium,
};

import { values } from 'lodash';

// Error
import SystemErrorFactory from '../../../../errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from '../../../../errors/ErrorMessage/ErrorMessage';

// Type
import {
  DebitCardRequestCherryVisa01StatusType,
  ProofOfIdentityReviewDebitCardRequestCherryVisa01Status,
} from '../../../../types/DebitCardRequestCherryVisa01.type';
import { CherryVisa01ProofOfIdentityProps } from '../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentity.type';
import { CherryVisa01ProofOfReceiptProps } from '../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfReceipt/CherryVisa01ProofOfReceipt.type';
import { DebitCardRequestCherryVisa01Props } from './DebitCardRequestCherryVisa01.type';

// Constant
import { ROUTE_DEBIT_CARD_REQUEST_CHERRY_VISA_01_STATUS } from '../../../../config/constants/routing';

// DomainObject
import DebitCardRequest from '../DebitCardRequest/DebitCardRequest/DebitCardRequest';

// ValueObject
import DeliveryTrackingInformation from '../../../../value/debitCard/DebitCardRequestContent/DeliveryTrackingInformation/DeliveryTrackingInformation';
import IssuedDebitCardInformation from '../../../../value/debitCard/DebitCardRequestContent/IssuedDebitCardInformation/IssuedDebitCardInformation';
import CherryVisa01ProofOfIdentity from '../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentity';
import CherryVisa01ProofOfReceipt from '../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfReceipt/CherryVisa01ProofOfReceipt';
import CherryVisa01ProofOfIdentityFactory from '../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentityFactory';
import CherryVisa01ProofOfReceiptFactory from '../../../../value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfReceipt/CherryVisa01ProofOfReceiptFactory';

class DebitCardRequestCherryVisa01 extends DebitCardRequest {
  private customerId: string;

  private status: DebitCardRequestCherryVisa01StatusType;

  private proofOfIdentity?: CherryVisa01ProofOfIdentity;

  private issuedDebitCardInformation?: IssuedDebitCardInformation;

  private deliveryTrackingInformation?: DeliveryTrackingInformation;

  private proofOfReceipt?: CherryVisa01ProofOfReceipt;

  constructor(id: string, props: DebitCardRequestCherryVisa01Props) {
    super(id, props);
    this.customerId = props.customerId;
    this.status = props.status;
    this.proofOfIdentity = props.proofOfIdentity;
    this.issuedDebitCardInformation = props.issuedDebitCardInformation;
    this.deliveryTrackingInformation = props.deliveryTrackingInformation;
    this.proofOfReceipt = props.proofOfReceipt;
    this.props = props;
  }

  public getProps() {
    return {
      ...super.getProps(),
      customerId: this.customerId,
      status: this.status,
      proofOfIdentity: this.proofOfIdentity,
      issuedDebitCardInformation: this.issuedDebitCardInformation,
      deliveryTrackingInformation: this.deliveryTrackingInformation,
      proofOfReceipt: this.proofOfReceipt,
    };
  }

  public updateId = (id: string) => {
    return new DebitCardRequestCherryVisa01(id, this.getProps());
  };

  public updateCustomerId(customerId: string) {
    return new DebitCardRequestCherryVisa01(this.getId(), {
      ...this.getProps(),
      customerId,
    });
  }

  public isInitialStatus = () =>
    this.status === DebitCardRequestCherryVisa01StatusType.initial;

  public isRequiresShippingAction = () =>
    this.status === DebitCardRequestCherryVisa01StatusType.waitingForShipment;

  public isProofOfIdentityReviewing = () =>
    values(ProofOfIdentityReviewDebitCardRequestCherryVisa01Status).includes(
      this.status,
    );

  public isProcessingShipment = () =>
    this.status === DebitCardRequestCherryVisa01StatusType.waitingForShipment;

  public isRequiresActivation = () =>
    this.status === DebitCardRequestCherryVisa01StatusType.waitingForShipment;

  public isProofOfIdentityApproved = () => {
    switch (this.status) {
      case DebitCardRequestCherryVisa01StatusType.waitingForShipment:
        return true;
      case DebitCardRequestCherryVisa01StatusType.proofOfIdentityRejected:
        return false;
      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_SWITCH,
          {
            value: this.status,
            place: `DebitCardRequestCherryVisa01.isProofOfIdentityApproved`,
          },
        );
    }
  };

  public isProofOfIdentityEditable = () =>
    [
      DebitCardRequestCherryVisa01StatusType.initial,
      DebitCardRequestCherryVisa01StatusType.proofOfIdentityRejected,
    ].includes(this.status);

  public updateProofOfIdentity = (props: CherryVisa01ProofOfIdentityProps) => {
    return new DebitCardRequestCherryVisa01(this.getId(), {
      ...this.getProps(),
      proofOfIdentity: this.proofOfIdentity
        ? this.proofOfIdentity.updateProps(props)
        : CherryVisa01ProofOfIdentityFactory.create(props),
    });
  };

  public isProofOfReceiptEditable = () =>
    [
      DebitCardRequestCherryVisa01StatusType.proofOfReceiptRequested,
      DebitCardRequestCherryVisa01StatusType.proofOfReceiptRejected,
    ].includes(this.status);

  public updateProofOfReceipt = (props: CherryVisa01ProofOfReceiptProps) => {
    return new DebitCardRequestCherryVisa01(this.getId(), {
      ...this.getProps(),
      proofOfReceipt: this.proofOfReceipt
        ? this.proofOfReceipt.updateProps(props)
        : CherryVisa01ProofOfReceiptFactory.create(props),
    });
  };

  public getDefinedProofOfIdentity = () => {
    if (!this.proofOfIdentity) {
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
        {
          type: `DebitCardRequestCherryVisa01.proofOfIdentity`,
          place: `DebitCardRequestCherryVisa01.getDefinedProofOfIdentity`,
        },
      );
    }

    return this.proofOfIdentity;
  };

  public getDefinedIssuedDebitCardInformation = () => {
    if (!this.issuedDebitCardInformation) {
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
        {
          type: `DebitCardRequestCherryVisa01.issuedDebitCardInformation`,
          place: `DebitCardRequestCherryVisa01.getDefinedIssuedDebitCardInformation`,
        },
      );
    }

    return this.issuedDebitCardInformation;
  };

  public getDefinedDeliveryTrackingInformation = () => {
    if (!this.deliveryTrackingInformation) {
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
        {
          type: `DebitCardRequestCherryVisa01.deliveryTrackingInformation`,
          place: `DebitCardRequestCherryVisa01.getDeliveryTrackingInformation`,
        },
      );
    }

    return this.deliveryTrackingInformation;
  };

  public getDefinedProofOfReceipt = () => {
    if (!this.proofOfReceipt) {
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_ARGUMENT_TYPE_GUARD,
        {
          type: `DebitCardRequestCherryVisa01.proofOfReceipt`,
          place: `DebitCardRequestCherryVisa01.getDefinedProofOfReceipt`,
        },
      );
    }

    return this.proofOfReceipt;
  };

  public getStatusPageUrl = () =>
    `${ROUTE_DEBIT_CARD_REQUEST_CHERRY_VISA_01_STATUS}/${this.id}`;
}
export default DebitCardRequestCherryVisa01;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import ContentBox from '../../../atoms/utils/layout/Box/Box/ContentBox';

export interface CountdownTimerProps {
  children: React.ReactElement[];
  width: string | number;
  height: string | number;
}

const CountdownTimerLayout: FC<CountdownTimerProps> = ({
  children,
  width,
  height,
}: CountdownTimerProps) => {
  const elements = ['description', 'seconds'];
  const [description, seconds] = getElementsFromKeys(children, elements);

  return (
    <ContentBox
      alignItems="center"
      flexDirection="column"
      height={height}
      justifyContent="space-between"
      padding="20"
      width={width}
    >
      {description}
      {seconds}
    </ContentBox>
  );
};
export default CountdownTimerLayout;

// React
import { useState } from 'react';

// Error
import ErrorHandler from '../../../../utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from '../../../../utils/helpers/connection';
// import { executeWithTimeout } from '../../../../utils/helpers/timeout';

// Constant
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../config/constants/requestState';
// import { REQUEST_TIMEOUT } from '../../../../config/constants/business';

// UseCase
import ResignUpUseCase from '../../../../useCases/authentication/resignUp/ResignUpUseCase';

// Repository, Functions
import ResignUpRequestFunctions from '../../../../repository/userRequest/resignUpRequest/ResignUpRequestFunctions';

export const useResignUpUseCase = () => {
  const [state, setState] = useState<string>(INITIAL);
  const [email, setEmail] = useState<string>('');
  const [reregistrationCode, setReregistrationCode] = useState<string>('');

  // Repository, Auth
  const resignUpRequestCreateRepository = new ResignUpRequestFunctions();

  // UseCase
  const useCase = new ResignUpUseCase(resignUpRequestCreateRepository);

  // UseCase.signUp
  const resignUp = async (
    formInput: {
      email: string;
      reregistrationCode: string;
    },
    isResend = false,
  ) => {
    if (!isResend) setState(IS_LOADING);

    try {
      // ---check onLine ---- //
      checkInternetConnection();

      // SetValue
      setEmail(formInput.email);
      setReregistrationCode(formInput.reregistrationCode);

      // UseCase.signUp
      await useCase.resignUp(formInput.email, formInput.reregistrationCode);

      if (!isResend) setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
  };

  const resendResignUpComplete = async () => {
    await resignUp(
      { email: email!, reregistrationCode: reregistrationCode! },
      true,
    );
  };

  return {
    email,
    reregistrationCode,
    resendResignUpComplete,
    resignUp,
    setState,
    state,
  };
};

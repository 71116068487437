import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CryptoCurrencyTradeType } from '../../../../types/CryptoCurrency.type';
import { SellButton, BuyButton, DefaultButtonTextStyle } from '../Button2';
import { TextPrimary } from '../../text2/Text2';

export interface CryptoCurrencyTradeExecuteButtonProps {
  tradeType: CryptoCurrencyTradeType;
  width: string | number;
  height: string | number;
  isLoading: boolean;
  disabled: boolean;
  onClick: () => void;
}

const CryptoCurrencyTradeExecuteButton: FC<CryptoCurrencyTradeExecuteButtonProps> = ({
  tradeType,
  width,
  height,
  isLoading,
  disabled,
  onClick,
}: CryptoCurrencyTradeExecuteButtonProps) => {
  const { t } = useTranslation();
  switch (tradeType) {
    case CryptoCurrencyTradeType.buy:
      return (
        <BuyButton
          key="left"
          width={width}
          height={height}
          isLoading={isLoading}
          disabled={disabled}
          onClick={onClick}
        >
          <TextPrimary theme={DefaultButtonTextStyle}>
            {t(`atoms.button.buy`)}
          </TextPrimary>
        </BuyButton>
      );
    case CryptoCurrencyTradeType.sell:
      return (
        <SellButton
          key="left"
          width={width}
          height={height}
          isLoading={isLoading}
          disabled={disabled}
          onClick={onClick}
        >
          <TextPrimary theme={DefaultButtonTextStyle}>
            {t(`atoms.button.sell`)}
          </TextPrimary>
        </SellButton>
      );
    default:
      throw new Error(`Error`);
  }
};

export default CryptoCurrencyTradeExecuteButton;

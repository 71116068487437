import ActionButton from './ActionButton';
import BankActionButton from './BankActionButton';
import BuyButton from './BuyButton';
import CancelButton from './CancelButton';
import CloseButton from './CloseButton';
import CopyButton from './CopyButton';
import FileDeleteButton from './FileDeleteButton';
import FileUploadButton from './FileUploadButton';
import SellButton from './SellButton';
import SubmitButton from './SubmitButton';
import { DefaultButtonTextStyle, CancelButtonTextStyle } from './style';

const ConfirmButton = SubmitButton;

// Button
export {
  ActionButton,
  BankActionButton,
  BuyButton,
  CancelButton,
  CloseButton,
  ConfirmButton,
  CopyButton,
  FileDeleteButton,
  FileUploadButton,
  SellButton,
  SubmitButton,
};

// ButtonTextStyle
export { DefaultButtonTextStyle, CancelButtonTextStyle };

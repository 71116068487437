import { CryptoCurrencyType } from '../../../../types/CryptoCurrency.type';
import {
  CryptoCurrencySellType,
  CryptoCurrencyBuyType,
} from '../../../../types/CryptoCurrencyTransaction.type';
import { TransactionIdProps } from '../TransactionId/TransactionId.type';

export interface CryptoCurrencyTransactionIdProps extends TransactionIdProps {
  currency: CryptoCurrencyType;
}

export const PrefixCryptoCurrencyTransactionId: { [key: string]: string } = {
  [CryptoCurrencyBuyType.cryptoCurrencyBuy]: 'BCC',
  [CryptoCurrencySellType.cryptoCurrencySell]: 'SCC',
};

import React, { FC } from 'react';
import IconUnderConstruction from '../../../../assets/images/underconstruction.svg';
import Box from '../../utils/layout/Box/Box';

export interface UnderConstructionIconProps {
  width: number | string;
  height: number | string;
}

const UnderConstructionIcon: FC<UnderConstructionIconProps> = ({
  width,
  height,
}: UnderConstructionIconProps) => {
  return (
    <Box width={width} height={height}>
      <img
        src={IconUnderConstruction}
        alt="underConstruction"
        width="100%"
        height="100%"
      />
    </Box>
  );
};
export default UnderConstructionIcon;

import React, { FC, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useContractTimeDepositRenewalReservationViewUseCase } from './useContractTimeDepositRenewalReservationViewUseCase';

// Context
import ContractTimeDepositContext from '../../../../../domain/contract/contractTimeDeposit/ContractTimeDeposit/ContractTimeDepositContext';
import ContractTimeDepositRenewalReservationPageStateContext from '../../../../pageState/contract/ContractTimeDepositRenewalReservationPageState/ContractTimeDepositRenewalReservationPageStateContext';
import SavingsAccountsContext from '../../../../../domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccountsContext';
import TimeDepositsContext from '../../../../../domain/product/timeDeposit/TimeDeposits/TimeDepositsContext';
import UserVerifiedContext from '../../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import SystemErrorHandler from '../../../../errorHandler/SystemErrorHandler';

// Constant
import { ROUTE_TIME_DEPOSIT } from '../../../../../config/constants/routing';
import { HOURS_TIME_LIMIT_RESERVATION_CHANGE_CONTRACT_TIME_DEPOSIT } from '../../../../../config/constants/business';

export type ContractTimeDepositRenewalReservationViewProviderProps = {
  children: React.ReactNode;
};

const ContractTimeDepositRenewalReservationViewProvider: FC<ContractTimeDepositRenewalReservationViewProviderProps> = ({
  children,
}: ContractTimeDepositRenewalReservationViewProviderProps) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  // DomainObject
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // Get Parameters
  useEffect(() => {
    if (isEmpty(id)) history.push(ROUTE_TIME_DEPOSIT);
    // eslint-disable-next-line
    }, [id]);

  const {
    state,
    setState,
    contractTimeDeposit,
    setContractTimeDeposit,
    savingsAccounts,
    timeDeposits,
    userVerified,
    pageState,
    setPageState,
  } = useContractTimeDepositRenewalReservationViewUseCase(id, userAuthorized);

  /* *
   *
   *  Redirect
   *
   * */
  useEffect(() => {
    if (!contractTimeDeposit) return;

    // Redirect if unauthorized Access occurs
    if (
      contractTimeDeposit.isMaturedWithInHours(
        HOURS_TIME_LIMIT_RESERVATION_CHANGE_CONTRACT_TIME_DEPOSIT,
      )
    )
      history.push(ROUTE_TIME_DEPOSIT);
  }, [contractTimeDeposit, history]);

  return (
    <ContractTimeDepositRenewalReservationPageStateContext.Provider
      value={{ pageState, setPageState }}
    >
      <SavingsAccountsContext.Provider value={{ savingsAccounts }}>
        <TimeDepositsContext.Provider value={{ timeDeposits }}>
          <ContractTimeDepositContext.Provider
            value={{ contractTimeDeposit, setContractTimeDeposit }}
          >
            <UserVerifiedContext.Provider value={{ user: userVerified }}>
              <SystemErrorHandler state={state} setState={setState}>
                {children}
              </SystemErrorHandler>
            </UserVerifiedContext.Provider>
          </ContractTimeDepositContext.Provider>
        </TimeDepositsContext.Provider>
      </SavingsAccountsContext.Provider>
    </ContractTimeDepositRenewalReservationPageStateContext.Provider>
  );
};

export default ContractTimeDepositRenewalReservationViewProvider;

// Type
import { CryptoCurrencySellTransactionSeedProps } from './CryptoCurrencySellTransactionSeed.type';

// DomainObject
import CryptoCurrencySellTransaction from '../../CryptoCurrencyTradeTransaction/CryptoCurrencySellTransaction/CryptoCurrencySellTransaction/CryptoCurrencySellTransaction';
import CryptoCurrencyTradeTransactionSeed from '../CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed';
import CryptoCurrencyTransactionIdFactory from '../../../../../idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionIdFactory';

class CryptoCurrencySellTransactionSeed extends CryptoCurrencyTradeTransactionSeed {
  // Multiple Inheritance by Delegation
  private cryptoCurrencySellTransaction: CryptoCurrencySellTransaction;

  constructor(props: CryptoCurrencySellTransactionSeedProps) {
    super(props);
    this.cryptoCurrencySellTransaction = new CryptoCurrencySellTransaction(
      CryptoCurrencyTransactionIdFactory.createEmptyId(),
      props,
    );
  }

  public getCurrencyPair = () =>
    this.cryptoCurrencySellTransaction.getCurrencyPair();

  public getTargetCurrency = () =>
    this.cryptoCurrencySellTransaction.getTargetCurrency();

  public getTradeDetail = () =>
    this.cryptoCurrencySellTransaction.getTradeDetail();

  public getTransactionType = () =>
    this.cryptoCurrencySellTransaction.getTransactionType();
}

export default CryptoCurrencySellTransactionSeed;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getAddressQRCodeValue } from '../../../../../utils/helpers/cryptoCurrencyHelper';
import {
  CryptoCurrencyType,
  CryptoCurrencyProps,
} from '../../../../../types/CryptoCurrency.type';
import { CRYPTO_CURRENCY_DEPOSIT_WITHDRAWAL } from '../../../../../config/constants/pageId.json';
import {
  DescriptionStyle,
  AddressTextStyle,
  CopyButtonStyle,
  ButtonTextStyle,
  SuccessMessageStyle,
  TextContainerStyle,
} from './style';
import { TextSecondary } from '../../../../atoms/text2/Text2';
import QRCodeImage from '../../../../atoms/barcode/QRCodeImage';
import TextCopyButtonGroup from '../../../text/TextCopyButtonGroup';
import CryptoCurrencyAddressQRCodeCardLayout from './CryptoCurrencyAddressQRCodeCardLayout';

export interface CryptoCurrencyAddressQRCodeCardProps {
  cryptoCurrencyType: CryptoCurrencyType;
  address: string;
}

const CryptoCurrencyAddressQRCodeCard: FC<CryptoCurrencyAddressQRCodeCardProps> = ({
  cryptoCurrencyType,
  address,
}: CryptoCurrencyAddressQRCodeCardProps) => {
  const { t } = useTranslation();
  const qrCodeValue = getAddressQRCodeValue(cryptoCurrencyType, address);

  return (
    <CryptoCurrencyAddressQRCodeCardLayout>
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(
          `${CRYPTO_CURRENCY_DEPOSIT_WITHDRAWAL}.deposit.description.depositAddress.line1`,
          {
            currency: CryptoCurrencyProps[cryptoCurrencyType].nameLowerCase,
          },
        )}
      </TextSecondary>
      <QRCodeImage
        key="qrcode"
        value={qrCodeValue}
        width="100%"
        height="100%"
      />
      <TextCopyButtonGroup
        key="address"
        buttonMarginLeft={20}
        buttonMarginRight={15}
        buttonStyle={CopyButtonStyle}
        buttonTextStyle={ButtonTextStyle}
        buttonWidth={148}
        height={50}
        successMessageStyle={SuccessMessageStyle}
        text={address}
        textContainerWidth={560}
        textContainerStyle={TextContainerStyle}
        textStyle={AddressTextStyle}
      />
    </CryptoCurrencyAddressQRCodeCardLayout>
  );
};

export default CryptoCurrencyAddressQRCodeCard;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

// Lib
import { deleteFullWidth } from '../../../../../../../utils/helpers/stringHelper';
import { corporateInformationValidation } from '../../../../../../../utils/validation/registers';

// Style
import { LabelStyle } from './style';

// Component
import FormInputTextField from '../../../../../../molecules/form/formInput/textField/FormInputTextField';
import KycFormContainer from '../../../../common/KycFormContainer';

const FORM_ID = `organisms.form.verification.FormCorporateInformation.corporateInformation`;

const FormCorporateInformationInput: FC = () => {
  const { t } = useTranslation();

  const { setValue } = useFormContext();

  return (
    <KycFormContainer key="corporateInformation" order={1} formId={FORM_ID}>
      <FormInputTextField
        name="corporateName"
        labelProps={{
          label: t(`${FORM_ID}.corporateName.label`),
          theme: LabelStyle,
        }}
        layoutProps={{
          inputWidth: 600,
          width: 800,
        }}
        maxLength={255}
        onChange={(text: string) => {
          setValue('corporateName', deleteFullWidth(text));
        }}
        placeHolder={t(`${FORM_ID}.corporateName.placeholder.corporateName`)}
        validation={corporateInformationValidation(t).corporateName}
      />
    </KycFormContainer>
  );
};

export default FormCorporateInformationInput;

import React, { FC, useContext } from 'react';
import { CryptoCurrencyHistoryTabs } from '../../../../../../config/menu/tab/CryptoCurrencyHistoryTab';
import CryptoCurrencyHistoryOptionContext from './CryptoCurrencyHistoryOptionContext';
import { SelectedStyle, TextStyle, UnSelectedStyle } from './style';
import UnderBarTabBar from '../UnderBarTabBar';

const CryptoCurrencyHistoryTabBar: FC = () => {
  const { historyOption, setHistoryOption } = useContext(
    CryptoCurrencyHistoryOptionContext,
  );

  return (
    <UnderBarTabBar
      height={55}
      items={CryptoCurrencyHistoryTabs}
      onClick={setHistoryOption}
      selected={historyOption}
      theme={{
        text: TextStyle,
        selected: SelectedStyle,
        unSelected: UnSelectedStyle,
      }}
      widthItem={250}
    />
  );
};

export default CryptoCurrencyHistoryTabBar;

// Type
import { UseStateType } from '../../../types/typeof/UseState';

// IUseCase
import IReferralResultsViewUseCase, {
  SettersReferralResultsViewUseCase,
} from '../../../enhancers/useCase/invite/ReferralResultsViewProvider/IReferralResultsViewUseCase';

// IService
import IBankFutureDepositQueryService from '../../transaction/bankTransaction/bankFutureTransaction/bankFutureDeposit/IBankFutureDepositQueryService';
import IInviterQueryService from '../inviter/IInviterQueryService';
import IReferralTimeDepositQueryService from './IReferralTimeDepositQueryService';

// Service
import BankFutureDepositQueryService from '../../../service/transaction/bankTransaction/bankFutureTransaction/bankFutureDeposit/BankFutureDepositQueryService';
import InviterQueryService from '../../../service/invite/inviter/InviterQueryService';
import ReferralTimeDepositQueryService from '../../../service/invite/referralTimeDeposit/ReferralTimeDepositQueryService';

// IRepository
import IBankFutureDepositRepository from '../../../domain/transaction/bankTransaction/bankFutureTransaction/bankFutureDeposit/IBankFutureDepositRepository';
import IInviterRepository from '../../../domain/invite/inviter/IInviterRepository';
import IReferralTimeDepositRepository from '../../../domain/invite/referralTimeDeposit/IReferralTimeDepositRepository';

// DomainObject
import UserVerified from '../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerified';

class ReferralResultsViewUseCase implements IReferralResultsViewUseCase {
  private bankFutureDepositQueryService: IBankFutureDepositQueryService;

  private inviterQueryService: IInviterQueryService;

  private referralTimeDepositQueryService: IReferralTimeDepositQueryService;

  constructor(
    bankFutureDepositRepository: IBankFutureDepositRepository,
    inviterRepository: IInviterRepository,
    referralTimeDepositRepository: IReferralTimeDepositRepository,
  ) {
    this.bankFutureDepositQueryService = new BankFutureDepositQueryService(
      bankFutureDepositRepository,
    );

    this.inviterQueryService = new InviterQueryService(inviterRepository);

    this.referralTimeDepositQueryService = new ReferralTimeDepositQueryService(
      referralTimeDepositRepository,
    );
  }

  public open = async (
    user: UserVerified,
    setters: SettersReferralResultsViewUseCase,
    setState: UseStateType<string>,
  ) => {
    // Fetch Inviter , ReferralsTimeDeposit
    await this.inviterQueryService.setById(
      user.getId(),
      setters.inviter,
      setState,
    );

    const [bankFutureDeposits, referralsTimeDeposit] = await Promise.all([
      this.bankFutureDepositQueryService.findFutureCommissionsTimeDepositByUserId(
        user.getId(),
      ),
      this.referralTimeDepositQueryService.findRawAllByUser(user),
    ]);

    return { referralsTimeDeposit, bankFutureDeposits };
  };

  public executeUnsubscribe = () => {
    this.inviterQueryService.executeUnsubscribe();
  };
}
export default ReferralResultsViewUseCase;

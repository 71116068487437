// axios
import FirebasePrivateFunctions from '../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';
import { USER_REQUEST_SIGN_IN } from '../../../infrastructure/firebase/firebaseFunctions/endpoint';

// IRepository
import {
  ISignInRequestCreateRepository,
  ISignInRequestUpdateRepository,
} from '../../../domain/userRequest/signInRequest/ISignInRequestRepository';

// DomainObject
import UserAuthorized from '../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class SignInRequestFunctions
  implements ISignInRequestCreateRepository, ISignInRequestUpdateRepository {
  public create = async (user: UserAuthorized) => {
    await new FirebasePrivateFunctions().privatePOST(USER_REQUEST_SIGN_IN, {
      id: user.getId(),
    });
  };

  public update = async (user: UserAuthorized, code: string) => {
    await new FirebasePrivateFunctions().privatePUT(
      `${USER_REQUEST_SIGN_IN}/${user.getId()}`,
      {
        code,
      },
    );
  };
}
export default SignInRequestFunctions;

import Joi from '@hapi/joi';

// Type
import { CorporateInformationProps } from '../../../../../value/verification/kycRequest/KycRequestContent/CorporateInformation/CorporateInformation.type';
import { CorporateAddressProps } from '../../../../../value/verification/kycRequest/KycRequestContent/CorporateAddress/CorporateAddress.type';
import {
  KycRequestInitialProps,
  KycRequestProps,
  KycRequestPropsFormat,
} from '../KycRequest/KycRequest.type';
import { KycStepStatusCorporateProps } from '../../../../../value/verification/kycRequest/KycStepStatus/KycStepStatusCorporate/KycStepStatusCorporate.type';
import { UserType } from '../../../../../types/User.type';

// ValueObject
import CorporateInformationFactory from '../../../../../value/verification/kycRequest/KycRequestContent/CorporateInformation/CorporateInformationFactory';
import CorporateAddressFactory from '../../../../../value/verification/kycRequest/KycRequestContent/CorporateAddress/CorporateAddressFactory';
import KycStepStatusCorporateFactory from '../../../../../value/verification/kycRequest/KycStepStatus/KycStepStatusCorporate/KycStepStatusCorporateFactory';

export interface KycRequestCorporateProps extends KycRequestProps {
  corporateInformation: CorporateInformationProps;

  corporateAddress: CorporateAddressProps;

  kycStepStatus: KycStepStatusCorporateProps;
}

export const KycRequestCorporatePropsFormat = {
  ...KycRequestPropsFormat,

  type: Joi.string().valid(UserType.corporate),

  corporateInformation: Joi.any().required(),

  corporateAddress: Joi.any().required(),
};

export const KycRequestCorporateInitialProps = {
  ...KycRequestInitialProps,

  corporateInformation: CorporateInformationFactory.createInitial().getProps(),

  corporateAddress: CorporateAddressFactory.createInitial().getProps(),

  kycStepStatus: KycStepStatusCorporateFactory.createInitial().getProps(),
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { DescriptionStyle } from './style';

// Component
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import { TextSecondary } from '../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../atoms/text2/LineBreakReplaced';
import FormTwoFactorAuthAppEdit from '../../../organisms/form/settings/FormTwoFactorAuthAppEdit';
import TwoFactorAuthAppEditTemplateLayout from './TwoFactorAuthAppEditTemplateLayout';

const TEMPLATE_ID = `templates.userSettings.TwoFactorAuthAppEditTemplate`;

const TwoFactorAuthAppEditTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <TwoFactorAuthAppEditTemplateLayout>
      <PageTitle key="header">{t(`${TEMPLATE_ID}.title`)}</PageTitle>
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced text={t(`${TEMPLATE_ID}.description`)} />
      </TextSecondary>
      <FormTwoFactorAuthAppEdit key="form" />
    </TwoFactorAuthAppEditTemplateLayout>
  );
};

export default TwoFactorAuthAppEditTemplate;

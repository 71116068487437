import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

// Type
import { Gender } from '../../../../../../../types/KycRequest.type';

// Constant
import {
  MIN_YEAR_DATE_OF_BIRTH,
  MAX_YEAR_DATE_OF_BIRTH,
} from '../../../../../../../config/constants/business';

// Style
import { LabelStyle, RadioButtonLayout, RadioButtonTheme } from './style';

// Component
import KycFormContainer from '../../../../common/KycFormContainer';
import FormInputFullName from '../../../../../../molecules/form/formInputGroup/FormInputFullName';
import FormInputRadioButton from '../../../../../../molecules/form/formInput/radioButton/FormInputRadioButton';
import FormInputDateSelectBox from '../../../../../../molecules/form/formInput/selectBox/verification/FormInputDateSelectBox';
import FormPersonalInformationInputLayout from './FormPersonalInformationInputLayout';

const FORM_ID = `organisms.form.verification.FormPersonalInformation.personalInformation`;

const FormPersonalInformationInput: FC = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  return (
    <KycFormContainer order={1} formId={FORM_ID}>
      <FormPersonalInformationInputLayout>
        <FormInputFullName
          key="name"
          label={t(`${FORM_ID}.name.label`)}
          theme={{ text: LabelStyle }}
          textInputWidth={400}
        />
        <FormInputRadioButton
          key="gender"
          labelWidth={200}
          labelProps={{
            label: t(`${FORM_ID}.gender.label`),
            theme: LabelStyle,
          }}
          radioButtonProps={{
            displayItems: [
              t(`gender.${Gender.male}`),
              t(`gender.${Gender.female}`),
            ],
            layout: RadioButtonLayout,
            items: [Gender.male, Gender.female],
            name: 'gender',
            selected: watch('gender'),
            theme: RadioButtonTheme,
          }}
        />
        <FormInputDateSelectBox
          key="dateOfBirth"
          name="dateOfBirth"
          labelWidth={200}
          label={t(`${FORM_ID}.dateOfBirth.label`)}
          dateStart={{
            year: MAX_YEAR_DATE_OF_BIRTH,
            month: 0,
            day: 0,
          }}
          dateEnd={{
            year: MIN_YEAR_DATE_OF_BIRTH,
            month: 0,
            day: 0,
          }}
        />
      </FormPersonalInformationInputLayout>
    </KycFormContainer>
  );
};

export default FormPersonalInformationInput;

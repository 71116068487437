/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ReferralResultOptionType } from './ReferralResultTabBar.type';

export interface ReferralResultOptionContextProps {
  referralResultOption: ReferralResultOptionType;
  setReferralResultOption: (type: ReferralResultOptionType) => void;
}

const ReferralResultOptionContext = React.createContext<
  ReferralResultOptionContextProps
>({
  referralResultOption: ReferralResultOptionType.contractTimeDeposit,
  setReferralResultOption: (type: ReferralResultOptionType) => undefined,
});

export default ReferralResultOptionContext;

// React
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import UserAuthorizedContext from '../../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Constant
import {
  ROUTE_DEBIT_CARD_REQUEST,
  ROUTE_SAVINGS_ACCOUNT_HISTORY,
  ROUTE_TRANSFER_SAVING_ACCOUNT,
} from '../../../../../../config/constants/routing';

// Type
import { DebitCardIssuerType } from '../../../../../../types/DebitCard.type';
import { InvestingUserStatusType } from '../../../../../../types/User.type';

// Style
import {
  AccountNumberStyle,
  BalanceStyle,
  CurrencyStyle,
  LabelStyle,
  HolderTextStyle,
  SavingsAccountTypeBadgeStyle,
  LinkTextStyle,
} from './style';

// Layout
import Margin from '../../../../../atoms/utils/layout/Margin';

// Components
import { TextPrimary, TextSecondary } from '../../../../../atoms/text2/Text2';
import {
  BankActionButton,
  DefaultButtonTextStyle,
} from '../../../../../atoms/button/Button2';
import LabeledText from '../../../../../molecules/textGroup/LabeledText';
import LabeledCurrencyAmount from '../../../../../molecules/textGroup/LabeledCurrencyAmount';
import CSVDownloadButton from '../../../../../atoms/button/CSVDownloadButton';
import SavingsAccount from '../../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';
import SavingsAccountTypeBadge from '../../../../../molecules/badge/SavingsAccountTypeBadge';
import { LinkTextPrimary } from '../../../../../atoms/text2/LinkText';
import SavingsAccountCardLayout from './SavingsAccountCardLayout';

// DomainObject
import DebitCardRequests from '../../../../../../domain/debitCard/debitCardRequest/DebitCardRequest/DebitCardRequests/DebitCardRequests';

// CSV
import bankSpotTransactionsCSVScheme from '../../../../csv/CSVScheme/bankSpotTransactions/bankSpotTransactionsCSVScheme';

export interface SavingsAccountCardProps {
  savingsAccount: SavingsAccount | undefined;
  debitCardRequests?: DebitCardRequests;
  isHistory?: boolean;
  isCSVDownloadButtonVisible?: boolean;
}

const ORGANISM_ID = `organisms.card.SavingsAccountCard`;

const SavingsAccountCard: FC<SavingsAccountCardProps> = ({
  savingsAccount,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  debitCardRequests,
  isHistory = false,
  isCSVDownloadButtonVisible = false,
}: SavingsAccountCardProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { fileName, headers } = bankSpotTransactionsCSVScheme(t);

  if (!savingsAccount || !userAuthorized) return <></>;

  const { status } = userAuthorized.getProps();
  const savingsAccountNumber = savingsAccount.getRawAccountNumber();
  const { accountType, currency, balance } = savingsAccount.getProps();

  return (
    <SavingsAccountCardLayout
      isHistory={isHistory}
      debitCardRequests={debitCardRequests}
      userAuthorized={userAuthorized}
      isCSVDownloadButtonVisible={isCSVDownloadButtonVisible}
    >
      <TextSecondary key="accountNumber" theme={AccountNumberStyle}>
        {savingsAccount.getFormattedAccountNumber()}
      </TextSecondary>
      <SavingsAccountTypeBadge
        key="badge"
        savingsAccountType={accountType}
        {...SavingsAccountTypeBadgeStyle}
      />
      <LabeledCurrencyAmount
        key="balance"
        amount={balance}
        currency={currency}
        direction="row"
        isSymbol
        label={t(`atoms.label.balance`)}
        marginBetween={20}
        theme={{
          amount: BalanceStyle,
          currency: CurrencyStyle,
          label: LabelStyle,
        }}
      />
      <LabeledText
        key="accountHolder"
        direction="column"
        label={t(`molecules.textGroup.LabeledName.accountHolder`)}
        marginBetween={10}
        theme={{ label: LabelStyle, value: HolderTextStyle }}
        value={userAuthorized.getProps().contractName || ''}
      />
      <div key="csvDownloadButton">
        {isCSVDownloadButtonVisible && (
          <CSVDownloadButton
            height={50}
            width={220}
            headers={headers}
            fileName={fileName}
          />
        )}
      </div>
      <div key="action">
        {!isHistory && (
          <>
            {Object.keys(InvestingUserStatusType).includes(status) && (
              <BankActionButton
                width={215}
                height={50}
                onClick={() => {
                  history.push(`${ROUTE_TRANSFER_SAVING_ACCOUNT}`);
                }}
              >
                <TextPrimary theme={DefaultButtonTextStyle}>
                  {t(`atoms.button.transfer`)}
                </TextPrimary>
              </BankActionButton>
            )}
            <Margin top={30} />
            <BankActionButton
              width={215}
              height={50}
              onClick={() => {
                history.push(
                  `${ROUTE_SAVINGS_ACCOUNT_HISTORY}/${savingsAccountNumber}`,
                );
              }}
            >
              <TextPrimary theme={DefaultButtonTextStyle}>
                {t(`atoms.button.history`)}
              </TextPrimary>
            </BankActionButton>
          </>
        )}
      </div>
      <LinkTextPrimary
        key="link"
        onClick={() => {
          history.push(
            `${ROUTE_DEBIT_CARD_REQUEST}?savingsAccountNumber=${savingsAccountNumber}&issuer=${DebitCardIssuerType.cherryVisa01}`,
          );
        }}
        theme={LinkTextStyle}
      >
        {t(`${ORGANISM_ID}.link.applyNewDebitCard`)}
      </LinkTextPrimary>
    </SavingsAccountCardLayout>
  );
};

export default SavingsAccountCard;

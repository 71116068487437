import { TransferSavingsAccountType } from '../../../../../types/Transfer.type';
import TransferForbesAccountTransaction from '../TransferForbesAccountTransaction/TransferForbesAccountTransaction';
import TransferSavingsAccountTransactionId from '../../../../idManager/transactionId/TransferSavingsAccountTransactionId/TransferSavingsAccountTransactionId';
import TransferSavingsAccountTransactionSeed from '../../TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed/TransferSavingsAccountTransactionSeed';

// Error
import InvalidArgumentError from '../../../../../utils/errors/InvalidArgumentError';

class TransferSavingsAccountTransactionFactory {
  static createFromSeed = (
    transferSavingsAccountTransactionSeed: TransferSavingsAccountTransactionSeed,
    newId: string,
  ) => {
    const transferSavingsAccountTransactionId = TransferSavingsAccountTransactionId.createNumberFromRawId(
      newId,
    );

    const transferType = transferSavingsAccountTransactionSeed.getTransferType();

    switch (transferType) {
      case TransferSavingsAccountType.forbes:
        return new TransferForbesAccountTransaction(
          transferSavingsAccountTransactionId,
          transferSavingsAccountTransactionSeed.getProps(),
        );
      case TransferSavingsAccountType.ecb:
        return new TransferForbesAccountTransaction(
          transferSavingsAccountTransactionId,
          transferSavingsAccountTransactionSeed.getProps(),
        );
      default:
        throw new InvalidArgumentError(
          `Unknown transferType ${transferType} is found at TransferSavingsAccountTransactionFactory.createFromSeed.`,
        );
    }
  };
}

export default TransferSavingsAccountTransactionFactory;

import React, { FC, useContext } from 'react';
import { useCryptoCurrencyTradeViewUseCase } from './useCryptoCurrencyTradeViewUseCase';
import { useCryptoCurrencyTradePageState } from '../../../pageState/cryptoCurrency/CryptoCurrencyTradePageState/useCryptoCurrencyTradePageState';
import AssetsCryptoCurrencyContext from '../../../../domain/asset/assetCryptoCurrency/AssetsCryptoCurrency/AssetsCryptoCurrencyContext';
import CryptoCurrencyOptionContext from '../../../../components/molecules/selectBox/option/option/CryptoCurrencyOption/CryptoCurrencyOptionContext';
import CryptoCurrencyTradePageStateContext from '../../../pageState/cryptoCurrency/CryptoCurrencyTradePageState/CryptoCurrencyTradePageStateContext';
import CryptoCurrencyWalletContext from '../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWalletContext';
import RequestStateContext from '../../../../value/view/RequestState/RequestStateContext';
import SavingsAccountsContext from '../../../../domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccountsContext';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import UserVerifiedContext from '../../../../domain/public/user/User/UserAuthorized/UserVerified/UserVerifiedContext';
import { FormInputCryptoCurrencyTradeContext } from '../../../../components/organisms/form/cryptoCurrency/cryptoCurrencyTrade/FormCryptoCurrencyTrade/FormInputCryptoCurrencyTrade.type';
import CryptoCurrencyTradeLimitContext from '../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeLimit/CryptoCurrencyTradeLimit/CryptoCurrencyTradeLimitContext';

// Error Handler
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type CryptoCurrencyTradeViewProviderProps = {
  children: React.ReactNode;
};

const CryptoCurrencyTradeViewProvider: FC<CryptoCurrencyTradeViewProviderProps> = ({
  children,
}: CryptoCurrencyTradeViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // PageState
  const { pageState, setPageState } = useCryptoCurrencyTradePageState();

  const {
    state,
    setState,
    userVerified,
    assetsCryptoCurrency,
    cryptoCurrencyWallet,
    cryptoCurrencyOption,
    setCryptoCurrencyOption,
    savingsAccounts,
    formInputCryptoCurrencyTradeContext,
    cryptoCurrencyTradeLimit
  } = useCryptoCurrencyTradeViewUseCase(userAuthorized);

  return (
    <FormInputCryptoCurrencyTradeContext.Provider
      value={formInputCryptoCurrencyTradeContext}
    >
      <CryptoCurrencyTradePageStateContext.Provider
        value={{ pageState, setPageState }}
      >
        <RequestStateContext.Provider value={{ requestState: state }}>
          <UserVerifiedContext.Provider value={{ user: userVerified }}>
            <AssetsCryptoCurrencyContext.Provider
              value={{ assetsCryptoCurrency }}
            >
              <CryptoCurrencyWalletContext.Provider
                value={{ cryptoCurrencyWallet }}
              >
                <CryptoCurrencyTradeLimitContext.Provider
                  value={{ cryptoCurrencyTradeLimit }}
                >
                  <CryptoCurrencyOptionContext.Provider
                    value={{ cryptoCurrencyOption, setCryptoCurrencyOption }}
                  >
                    <SavingsAccountsContext.Provider value={{ savingsAccounts }}>
                      <SystemErrorHandler state={state} setState={setState}>
                        {children}
                      </SystemErrorHandler>
                    </SavingsAccountsContext.Provider>
                  </CryptoCurrencyOptionContext.Provider>
                </CryptoCurrencyTradeLimitContext.Provider>
              </CryptoCurrencyWalletContext.Provider>
            </AssetsCryptoCurrencyContext.Provider>
          </UserVerifiedContext.Provider>
        </RequestStateContext.Provider>
      </CryptoCurrencyTradePageStateContext.Provider>
    </FormInputCryptoCurrencyTradeContext.Provider>
  );
};
export default CryptoCurrencyTradeViewProvider;

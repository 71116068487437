import { useEffect, useState, useCallback } from 'react';
import useInterval from 'use-interval';
import { useIdleTimer } from 'react-idle-timer';
import { useSignInTimeOutModal } from '../../../../components/organisms/modal/SignInTimeOutModal/useSignInTimeOutModal';

// Constant
import { SIGN_IN_DURATION } from '../../../../config/constants/business';

enum TimeOutState {
  initial = 'initial',
  oneMinuteLeft = 'oneMinuteLeft',
  expired = 'expired',
}

export const useSignInTimeOut = (signOut: () => Promise<void>) => {
  const [timeOutState, setTimeOutState] = useState<TimeOutState>(
    TimeOutState.initial,
  );
  const [expiredTime, setExpiredTime] = useState<number>();
  const [remainingTime, setRemainingTime] = useState<number>();

  /* *
   *
   *  Initialization
   *
   * */
  const refreshSignInTimeOut = useCallback(() => {
    setExpiredTime(Date.now() + SIGN_IN_DURATION);
    setTimeOutState(TimeOutState.initial);
  }, []);

  // Change Status (INITIAL)
  useEffect(() => {
    refreshSignInTimeOut();
    // eslint-disable-next-line
  }, []);

  // Set RemainingTime
  useEffect(() => {
    if (expiredTime) setRemainingTime(SIGN_IN_DURATION);
  }, [expiredTime]);

  /* *
   *
   * Modal
   *
   * */

  const {
    showSignInTimeOutModal,
    hideSignInTimeOutModal,
  } = useSignInTimeOutModal(remainingTime, refreshSignInTimeOut, signOut);

  /* *
   *
   *  Remaining Time
   *
   * */
  useInterval(() => {
    if (remainingTime && expiredTime && remainingTime > 0)
      setRemainingTime(expiredTime - Date.now());
  }, 1000);

  /* *
   *
   *  Handling State
   *
   * */

  // Change Status (INITIAL -> )
  useEffect(() => {
    if (!remainingTime || !expiredTime) return;

    if (timeOutState === TimeOutState.initial && remainingTime <= 60 * 1000)
      setTimeOutState(TimeOutState.oneMinuteLeft);

    if (remainingTime <= 0 || expiredTime < Date.now())
      setTimeOutState(TimeOutState.expired);

    if (remainingTime > 60 * 1000) {
      setTimeOutState(TimeOutState.initial);
    }
  }, [expiredTime, timeOutState, remainingTime]);

  // SignOut if status === expired
  useEffect(() => {
    if (timeOutState === TimeOutState.expired) {
      signOut();
    }
    // eslint-disable-next-line
  }, [timeOutState]);

  /* *
   *
   *  Handling Modal
   *
   * */
  useEffect(() => {
    if (timeOutState === TimeOutState.oneMinuteLeft) showSignInTimeOutModal();
    else hideSignInTimeOutModal();
  }, [hideSignInTimeOutModal, showSignInTimeOutModal, timeOutState]);

  /* *
   *
   * Idle Timer
   *
   * */
  useIdleTimer({
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
    ],
    onAction: () => {
      if (
        expiredTime &&
        expiredTime > Date.now() &&
        timeOutState === TimeOutState.initial
      ) {
        refreshSignInTimeOut();
      }
    },
  });

  return {
    remainingTime,
    refreshSignInTimeOut,
  };
};

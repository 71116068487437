// React
import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InviterContext from '../../../../../domain/invite/inviter/Inviter/InviterContext';

// Style
import { TdStyle, ThStyle, TableLayout } from './style';

// Mapper
import TableInviterDetailMapper from './TableInviterDetailMapper';

// Components
import TableVerticalCurrency from '../../../../molecules/table/table/TableVerticalCurency';

const TableInviterDetail: FC = () => {
  const { t } = useTranslation();

  const { inviter } = useContext(InviterContext);

  // Mapping
  const rows = useMemo(
    () => TableInviterDetailMapper.inviterToRow(inviter, t),
    [inviter, t],
  );

  return (
    <TableVerticalCurrency
      key="table"
      rows={rows}
      layout={TableLayout}
      theme={{
        th: ThStyle,
        tdCurrency: TdStyle,
        tdAmount: TdStyle,
      }}
    />
  );
};

export default TableInviterDetail;

import { useState } from 'react';
import CryptoCurrencyWithdrawals from './CryptoCurrencyWithdrawals';

export const useCryptoCurrencyWithdrawals = (
  defaultValue?: CryptoCurrencyWithdrawals,
) => {
  const [cryptoCurrencyWithdrawals, setCryptoCurrencyWithdrawals] = useState<
    CryptoCurrencyWithdrawals
  >(defaultValue || new CryptoCurrencyWithdrawals());

  return {
    cryptoCurrencyWithdrawals,
    setCryptoCurrencyWithdrawals,
  };
};

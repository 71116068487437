import styled from 'styled-components';
import color from '../../../atoms/utils/const/color';
import fontWeight from '../../../atoms/utils/const/fontWeight';

export const MessageContainer = styled.div`
  display: inline-block;
  padding: ${6 / 16}rem ${20 / 16}rem;
  border: ${1 / 16}rem solid ${color.gallery};
  background-color: ${color.gallery};
  border-radius: ${9 / 16}rem;
`;

export const NavigationTextStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const NoticeTextStyle = {
  fontSize: 18,
  color: color.primary,
  fontWeight: fontWeight.medium,
};

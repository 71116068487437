import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  padding-top: ${40 / 16}rem;
  padding-bottom: ${40 / 16}rem;
  background-color: #e1e3ed;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

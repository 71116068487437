import React from 'react';
import InvitationCodes from './InvitationCodes';

export interface InvitationCodesContextProps {
  invitationCodes: InvitationCodes;
}

const InvitationCodesContext = React.createContext<InvitationCodesContextProps>(
  {
    invitationCodes: new InvitationCodes(),
  },
);

export default InvitationCodesContext;

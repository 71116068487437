import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
import { TransferDebitCardType } from '../../../../types/Transfer.type';
import { TransactionIdProps } from '../TransactionId/TransactionId.type';

export interface TransferDebitCardTransactionIdProps
  extends TransactionIdProps {
  currency: FiatCurrencyType;
}

export const PrefixTransferDebitCardTransactionId: {
  [key: string]: string;
} = {
  [TransferDebitCardType.savingsAccountToCard]: 'TSTD',
  [TransferDebitCardType.cardToSavingsAccount]: 'TDTS',
};

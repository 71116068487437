import color from '../../../../atoms/utils/const/color';
import fontWeight from '../../../../atoms/utils/const/fontWeight';

export const ContainerStyle = {
  backgroundColor: color.background.ghostWhite,
};

export const QRCodeImageContainerStyle = {
  backgroundColor: color.background.white,
};

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const CopyButtonStyle = {
  borderRadius: 25,
};

export const AddressTextStyle = DescriptionStyle;

export const ButtonTextStyle = {
  fontSize: 20,
  color: color.white,
  fontWeight: fontWeight.regular,
};

export const SuccessMessageStyle = {
  fontSize: 18,
  color: color.success,
  fontWeight: fontWeight.regular,
};

export const TextContainerStyle = {
  backgroundColor: 'white',
};

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';
import Display from '../../../atoms/div/Display';

export interface DropzonePreviewLayoutProps {
  children: React.ReactElement[];
  isImageDisplay: boolean;
}

const DropzonePreviewLayout: FC<DropzonePreviewLayoutProps> = ({
  children,
  isImageDisplay,
}: DropzonePreviewLayoutProps) => {
  const elements = ['icon', 'description', 'image'];
  const [icon, description, image] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn alignItems="center">
      <Display isDisplay={!isImageDisplay}>
        <FlexBoxColumn alignItems="center">
          {icon}
          <Margin top={20} />
          {description}
        </FlexBoxColumn>
      </Display>
      <Display isDisplay={isImageDisplay} style={{ width: '100%' }}>
        {image}
      </Display>
    </FlexBoxColumn>
  );
};
export default DropzonePreviewLayout;

import { useCallback, useMemo, useState, useEffect } from 'react';
import { useCSVData } from '../../../../../components/organisms/csv/CSVData/CSVData';
import { checkInternetConnection } from '../../../../../utils/helpers/connection';
import {
  INITIAL,
  IS_LOADING,
  SUCCESS,
} from '../../../../../config/constants/requestState';
import ErrorHandler from '../../../../../utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import SavingsAccountHistoryViewUseCase from '../../../../../useCases/bankAccount/savingsAccount/SavingsAccountHistory/SavingsAccountHistoryViewUseCase';

// Reposit
import SavingsAccountFunctions from '../../../../../repository/bankAccount/savingsAccount/SavingsAccountFunctions';
import BankSpotTransactionFunctions from '../../../../../repository/transaction/bankTransaction/bankSpotTransaction/bankSpotTransaction/BankSpotTransactionFunctions';

// DomainObject
import { useSavingsAccount } from '../../../../../domain/bankAccount/savingsAccount/SavingsAccount/useSavingsAccount';
import { useBankDeposits } from '../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankDeposit/BankDeposits/useBankDeposits';
import { useBankWithdrawals } from '../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankWithdrawal/BankWithdrawals/useBankWithdrawals';
import { useBankSpotTransactions } from '../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankSpotTransaction/BankSpotTransactions/useBankSpotTransactions';
import BankSpotTransactions from '../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankSpotTransaction/BankSpotTransactions/BankSpotTransactions';
import BankSpotTransactionsFactory from '../../../../../domain/transaction/bankTransaction/bankSpotTransaction/bankSpotTransaction/BankSpotTransactions/BankSpotTransactionsFactory';
import UserAuthorized from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useSavingsAccountHistoryViewUseCase = (
  user: UserAuthorized | undefined,
  savingsAccountNumber: string,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  DomainObject
   *
   * */
  const { savingsAccount, setSavingsAccount } = useSavingsAccount();
  const { bankDeposits, setBankDeposits } = useBankDeposits();
  const { bankWithdrawals, setBankWithdrawals } = useBankWithdrawals();
  const {
    bankSpotTransactions,
    setBankSpotTransactions,
  } = useBankSpotTransactions();
  const csvDataContext = useCSVData();

  // Sorted
  const bankSpotTransactionsSorted = useMemo(
    () => bankSpotTransactions.sortDescByCreatedAt(),
    [bankSpotTransactions],
  );

  // Balance History Calculated
  const bankSpotTransactionBalanceHistory = useMemo(
    () =>
      !savingsAccount
        ? new BankSpotTransactions()
        : bankSpotTransactionsSorted.calculateBalanceHistory(savingsAccount),
    [bankSpotTransactionsSorted, savingsAccount],
  );

  /* *
   *
   *  Repository
   *
   * */
  const bankSpotTransactionRepository = new BankSpotTransactionFunctions();
  const savingsAccountRepository = new SavingsAccountFunctions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new SavingsAccountHistoryViewUseCase(
    bankSpotTransactionRepository,
    savingsAccountRepository,
  );

  /* *
   *
   *  Methods
   *
   * */
  const openSavingsAccount = useCallback(async () => {
    if (!user) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const output = await useCase.open(savingsAccountNumber);

      setSavingsAccount(output.savingsAccount);
      setBankDeposits(output.bankDeposits);
      setBankWithdrawals(output.bankWithdrawals);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [user]);

  /* *
   *
   *  UseEffect
   *
   * */
  // UseCase.open
  useEffect(() => {
    let isMounted = true;

    if (isMounted && user) openSavingsAccount();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [openSavingsAccount]);

  // Merge Transactions
  useEffect(() => {
    const mergedTransactions = BankSpotTransactionsFactory.createByMerge(
      bankDeposits,
      bankWithdrawals,
    );
    setBankSpotTransactions(mergedTransactions);
    // eslint-disable-next-line
  }, [bankDeposits, bankWithdrawals]);

  return {
    state,
    setState,
    savingsAccount,
    bankSpotTransactions: bankSpotTransactionBalanceHistory,
    csvDataContext,
  };
};

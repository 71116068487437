import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Constant
import {
  ROUTE_SIGN_IN,
  ROUTE_HOME,
} from '../../../../config/constants/routing';
import { SIGN_UP } from '../../../../config/constants/pageId.json';

// Style
import { NavigationTextStyle } from './style';

// Component
import { FrontPageTitle } from '../../../atoms/headLine/HeadLine';
import FormSignUp from '../../../organisms/form/front/FormSignUp';
import Breadcrumb from '../../../molecules/link/Breadcrumb';
import BreadcrumbSentence from '../../../molecules/link/BreadcrumbSentence';
import SignUpTemplateLayout from './SignUpTemplateLayout';

const SignUpTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <SignUpTemplateLayout>
      <FrontPageTitle key="title">{t(`${SIGN_UP}.title`)}</FrontPageTitle>
      <FormSignUp key="form" />
      <BreadcrumbSentence
        key="linkSignIn"
        iconSize={10}
        marginBetween={13}
        text={t(`${SIGN_UP}.navigation.signIn`)}
        urlList={[
          {
            replaceText: t(`${SIGN_UP}.link.signIn`),
            url: ROUTE_SIGN_IN,
          },
        ]}
        theme={{
          text: NavigationTextStyle,
          link: NavigationTextStyle,
        }}
      />
      <Breadcrumb
        key="linkTop"
        href={ROUTE_HOME}
        iconSize={10}
        marginBetween={13}
        text={t(`${SIGN_UP}.link.top`)}
        theme={{ text: NavigationTextStyle }}
      />
    </SignUpTemplateLayout>
  );
};

export default SignUpTemplate;

import React from 'react';
import CryptoCurrencyWallet from './CryptoCurrencyWallet';

export interface CryptoCurrencyWalletContextProps {
  cryptoCurrencyWallet?: CryptoCurrencyWallet;
}

const CryptoCurrencyWalletContext = React.createContext<
  CryptoCurrencyWalletContextProps
>({
  cryptoCurrencyWallet: undefined,
});

export default CryptoCurrencyWalletContext;

import React from 'react';
import UserReregistration from './UserReregistration';

export interface UserReregistrationContextProps {
  user?: UserReregistration;
}

const UserReregistrationContext = React.createContext<
  UserReregistrationContextProps
>({
  user: undefined,
});

export default UserReregistrationContext;

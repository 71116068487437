import React, { FC } from 'react';

// Lib
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';

// Style
import { ContentStyle, LeftContainerStyle } from './style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from '../../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../../atoms/utils/layout/Margin';

export interface ContractTimeDepositCardLayoutProps {
  children: React.ReactElement[];
}

const ContractTimeDepositCardLayout: FC<ContractTimeDepositCardLayoutProps> = ({
  children,
}: ContractTimeDepositCardLayoutProps) => {
  const elements = [
    'title',
    'amount',
    'term',
    'progressBar',
    'interestRate',
    'remainingDays',
  ];

  const [
    title,
    amount,
    term,
    progressBar,
    interestRate,
    remainingDays,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn height="auto">
      {title}
      <Margin top={15} />
      <FlexBoxRow height={252} theme={ContentStyle}>
        <FlexBoxColumn
          width={462}
          height="auto"
          padding={40}
          theme={LeftContainerStyle}
        >
          {amount}
        </FlexBoxColumn>
        <FlexBoxColumn width={738} height="auto" padding={40}>
          {term}
          <Margin top={30} />
          {progressBar}
          <Margin top={30} />
          <FlexBoxRow justifyContent="space-between">
            {interestRate}
            {remainingDays}
          </FlexBoxRow>
        </FlexBoxColumn>
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};

export default ContractTimeDepositCardLayout;

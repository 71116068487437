import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DebitCardOptionContext from '../../../molecules/selectBox/option/option/DebitCardOption/DebitCardOptionContext';
import PageRequestStateContext from '../../../../enhancers/pageState/general/PageRequestState/PageRequestStateContext';
import SavingsAccountOptionContext from '../../../molecules/selectBox/option/option/SavingsAccountOption/SavingsAccountOptionContext';

// Component
import { PageTitle } from '../../../atoms/headLine/HeadLine';
import CircleSpinLoader from '../../../atoms/spinner/CircleSpinLoader';
import DebitCardTemplateLayout from './DebitCardTemplateLayout';
import DebitCardTemplateContent from './content/DebitCardTemplateContent';
import NoDebitCardTemplateContent from './content/NoDebitCardTemplateContent';
import SavingsAccountTabBar from '../../../molecules/selector/tabBar/SavingsAccountTabBar';

const TEMPLATE_ID = `templates.debitCards.DebitCardTemplate`;

const DebitCardTemplate: FC = () => {
  const { t } = useTranslation();

  // State
  const { state } = useContext(PageRequestStateContext);

  // Option
  const { savingsAccountOption } = useContext(SavingsAccountOptionContext);
  const { debitCardOption } = useContext(DebitCardOptionContext);

  if (!savingsAccountOption) return <></>;

  const TemplateContent: FC = () =>
    debitCardOption ? (
      <DebitCardTemplateContent debitCardV2={debitCardOption} />
    ) : (
      <NoDebitCardTemplateContent />
    );

  return (
    <DebitCardTemplateLayout state={state}>
      <PageTitle key="title">{t(`${TEMPLATE_ID}.title`)}</PageTitle>
      <SavingsAccountTabBar key="tabBar" height={`${90 / 16}rem`} />
      <TemplateContent key="content" />
      <CircleSpinLoader key="loading" />
    </DebitCardTemplateLayout>
  );
};
export default DebitCardTemplate;

import styled from 'styled-components';

interface WidthProps {
  widthValue?: string | number;
}

export const Width = styled.div`
  width: ${(props: WidthProps) => {
    const width = props?.widthValue;

    if (!width) return 0;

    if (Number.isNaN(Number(width))) return width;

    return `${(width as number) / 16}rem`;
  }};
`;

import { call, select } from 'redux-saga/effects';
import { counterStore } from '../selectors';
import { storeCounter } from './reducer.saga';

export function* userPressPlusButton() {
  const { count } = counterStore(yield select());

  yield call(storeCounter, count + 1);
}

export function* userPressMinusButton() {
  const { count } = counterStore(yield select());

  yield call(storeCounter, count - 1);
}

// Lib
import { addByDecimal } from '../../../../utils/helpers/calculationHelper';

// Interface
import { TransferDebitCardStatusType } from '../../../../types/Transfer.type';

// DomainObject
import TransferDebitCardTransactionV2 from '../TransferDebitCardTransactionV2/TransferDebitCardTransactionV2';
import DomainObjects from '../../../interface/Iterator/DomainObjects';

class TransferDebitCardTransactionV2s extends DomainObjects<
  TransferDebitCardTransactionV2
> {
  public sortDescByCreatedAt = () => {
    const compare = (
      a: TransferDebitCardTransactionV2,
      b: TransferDebitCardTransactionV2,
    ) => {
      const aCreatedAt = a.getProps().createdAt!;
      const bCreatedAt = b.getProps().createdAt!;

      return aCreatedAt < bCreatedAt ? 1 : -1;
    };
    return new TransferDebitCardTransactionV2s(this.items.sort(compare));
  };

  public filterByDebitCardId = (debitCardId: string) => {
    return new TransferDebitCardTransactionV2s(
      this.items.filter((item) => item.getProps().debitCardId === debitCardId),
    );
  };

  public filterByStatus = (status: TransferDebitCardStatusType) => {
    return new TransferDebitCardTransactionV2s(
      this.items.filter((item) => item.getProps().status === status),
    );
  };

  public filterActive = () => {
    const filtered = this.items.filter((item) => item.isActive());

    return new TransferDebitCardTransactionV2s(filtered);
  };

  public filterUnconfirmed = () => {
    const filtered = this.items.filter((item) => item.isUnconfirmed());

    return new TransferDebitCardTransactionV2s(filtered);
  };

  public getTotalToppedUpAmount = () => {
    return this.items.reduce(
      (total, transaction) =>
        addByDecimal(transaction.getProps().amount, total),
      0,
    );
  };

  public filterCreatedAfter = (timestamp: number) => {
    const filtered = this.items.filter((item) =>
      item.isCreatedAfter(timestamp),
    );

    return new TransferDebitCardTransactionV2s(filtered);
  };

  public hasTransaction = () => this.items.length > 0;
}
export default TransferDebitCardTransactionV2s;

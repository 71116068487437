import React, { FC } from 'react';

// Component
import { TextPrimary, TextThemeProps } from '../../../../../atoms/text2/Text2';
import LineBreakReplaced from '../../../../../atoms/text2/LineBreakReplaced';
import RadioButton, {
  RadioButtonProps,
} from '../../../../../atoms/form/RadioButton';
import FormInputRadioButtonLayout from './FormInputRadioButtonLayout';

interface FormInputRadioButtonProps {
  labelProps: {
    label: string;
    theme: TextThemeProps;
  };
  labelWidth: string | number;
  radioButtonProps: RadioButtonProps;
}

const FormInputRadioButton: FC<FormInputRadioButtonProps> = ({
  labelProps,
  labelWidth,
  radioButtonProps,
}: FormInputRadioButtonProps) => {
  return (
    <FormInputRadioButtonLayout labelWidth={labelWidth}>
      <TextPrimary key="label" theme={labelProps.theme}>
        <LineBreakReplaced text={labelProps.label} />
      </TextPrimary>
      <RadioButton key="input" {...radioButtonProps} />
    </FormInputRadioButtonLayout>
  );
};

export default FormInputRadioButton;

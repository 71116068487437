// IUseCase
import IReregisterUseCase from '../../../enhancers/useCase/authentication/ReregisterProvider/IReregisterUseCase';

// IService
import IAuthenticatorCreateService from '../../authenticator/authenticator/interface/IAuthenticatorCreateService';
import IResignUpRequestUpdateService from '../resignUp/IResignUpRequestUpdateService';
import IUserAuthenticationService from '../IUserAuthenticationService';

// Service
import AuthenticatorCreateService from '../../../service/authenticator/AuthenticatorCreateService';
import ResignUpRequestUpdateService from '../../../service/userRequest/resignUpRequest/ResignUpRequestUpdateService';
import UserAuthenticationService from '../../../service/authentication/UserAuthenticationService';

// IRepository
import { IAuthenticatorCreateRepository } from '../../../domain/authenticator/authenticator/IAuthenticatorRepository';
import { IResignUpRequestUpdateRepository } from '../../../domain/userRequest/resignUpRequest/IResignUpRequestRepository';
import IUserAuthentication from '../../../domain/public/user/User/IUserAuthentication';

// DomainObject
import UserReregistration from '../../../domain/public/user/User/UserReregistration/UserReregistration';

class ReregisterUseCase implements IReregisterUseCase {
  private authenticatorCreateService: IAuthenticatorCreateService;

  private resignUpRequestUpdateService: IResignUpRequestUpdateService;

  private userAuthenticationService: IUserAuthenticationService;

  constructor(
    authenticatorCreateRepository: IAuthenticatorCreateRepository,
    resignUpRequestUpdateRepository: IResignUpRequestUpdateRepository,
    userAuthentication: IUserAuthentication,
  ) {
    this.authenticatorCreateService = new AuthenticatorCreateService(
      authenticatorCreateRepository,
    );

    this.resignUpRequestUpdateService = new ResignUpRequestUpdateService(
      resignUpRequestUpdateRepository,
    );

    this.userAuthenticationService = new UserAuthenticationService(
      userAuthentication,
    );
  }

  public createAuthenticator = async (
    user: UserReregistration,
    oobCode: string,
  ) => {
    const authenticator = await this.authenticatorCreateService.executeCreateForReregistration(
      user,
      oobCode,
    );

    return authenticator;
  };

  public reregister = async (
    oobCode: string,
    password: string,
    code: string,
    user: UserReregistration,
  ) => {
    await this.resignUpRequestUpdateService.executeConfirm(
      oobCode,
      password,
      code,
    );

    // signIn
    const { email } = user.getProps();

    const userAuthorized = await this.userAuthenticationService.signIn(
      email,
      password,
    );

    return userAuthorized;
  };
}
export default ReregisterUseCase;

// Type
import { DebitCardIssuerType } from '../../../../types/DebitCard.type';

// IRepository
import { IDebitCardRequestQueryRepository } from '../../../../domain/debitCard/debitCardRequest/IDebitCardRequestRepository';

class DebitCardRequestViewUseCase {
  private debitCardRequestQueryRepository: IDebitCardRequestQueryRepository;

  constructor(
    debitCardRequestQueryRepository: IDebitCardRequestQueryRepository,
  ) {
    this.debitCardRequestQueryRepository = debitCardRequestQueryRepository;
  }

  public open = async (
    savingsAccountNumber: string,
    issuer: DebitCardIssuerType,
  ) => {
    const debitCardRequest = await this.debitCardRequestQueryRepository.findUncompletedRequestOrUndef(
      savingsAccountNumber,
      issuer,
    );

    return { debitCardRequest };
  };
}

export default DebitCardRequestViewUseCase;

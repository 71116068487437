import React, { FC } from 'react';
import { BiCheck } from 'react-icons/bi';
import { FlexBoxRow } from '../../utils/layout/Box/FlexBox';

export interface CheckboxProps {
  checked: boolean;
  height: string | number;
  iconSize: string | number;
  onClick: () => void;
  theme: {
    checked: {
      backgroundColor: string;
      border: string;
      color?: string;
    };
    unChecked: {
      backgroundColor: string;
      border: string;
      color?: string;
    };
  };
  width: string | number;
}

const Checkbox: FC<CheckboxProps> = ({
  checked,
  height,
  iconSize,
  onClick,
  theme,
  width,
}: CheckboxProps) => {
  if (!checked)
    return (
      <FlexBoxRow
        alignItems="center"
        height={height}
        justifyContent="center"
        onClick={onClick}
        theme={theme.unChecked}
        width={width}
      />
    );

  return (
    <FlexBoxRow
      alignItems="center"
      height={height}
      onClick={onClick}
      justifyContent="center"
      theme={theme.checked}
      width={width}
    >
      <BiCheck size={iconSize} color={theme.checked.color || '#fff'} />
    </FlexBoxRow>
  );
};

export default Checkbox;

import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { signInRegister } from '../../../../../utils/validation/registers';
import { deleteFullWidth } from '../../../../../utils/helpers/stringHelper';
import { ErrorCodeType } from '../../../../../utils/errors/ErrorHandler/ErrorCode.type';
import { IS_LOADING } from '../../../../../config/constants/requestState';
import { SIGN_IN } from '../../../../../config/constants/pageId.json';
import { ROUTE_FORGOT_PASSWORD } from '../../../../../config/constants/routing';
import { SignInFormInput } from './SignInForm.type';
import {
  Container,
  SubmitButtonContainer,
  LinkTextContainer,
  ErrorMessageContainer,
} from './style';
import TextField from '../../../../molecules/form/TextField/TextField';
import LinkText from '../../../../molecules/navigation/LinkText';
import { TextErrorMessage } from '../../../../atoms/text/DomainText';
import { SubmitButton } from '../../../../atoms/button/DomainButton';
import SignInContext from '../../../../../enhancers/useCase/authentication/SignInProvider/SignInContext';

const SignInForm: FC = () => {
  const { t } = useTranslation();
  const { state, signIn } = useContext(SignInContext);

  const {
    clearErrors,
    errors,
    formState: { isValid },
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
  } = useForm<SignInFormInput & { authentication: string }>({
    mode: 'onChange',
  });

  useEffect(() => {
    const authenticationErrorState = [
      ErrorCodeType.FIREBASE_AUTH_USER_NOT_FOUND,
      ErrorCodeType.FIREBASE_AUTH_WRONG_PASSWORD,
      ErrorCodeType.AUTHENTICATION_LOGIN_INVALID_ROLE,
    ];

    if (authenticationErrorState.indexOf(state as ErrorCodeType) > -1) {
      setError('authentication', {
        type: '',
        message: t(`validation.${ErrorCodeType.FIREBASE_AUTH_WRONG_PASSWORD}`),
      });
    }
  }, [state, setError, t]);

  return (
    <Container>
      <form
        onSubmit={handleSubmit(() => {
          signIn({ email: watch(`email`), password: watch(`password`) });
        })}
      >
        <div>
          <TextField
            name="email"
            value={watch('email')}
            label={t(`${SIGN_IN}.form.email.label`)}
            placeholder={t(`${SIGN_IN}.form.email.placeholder`)}
            onChange={(text: string) => {
              if (errors.authentication) clearErrors('authentication');
              setValue('email', deleteFullWidth(text));
            }}
            maxLength={255}
            register={register(signInRegister('email', t))}
          />
        </div>
        <div>
          <TextField
            name="password"
            value={watch('password')}
            type="password"
            onChange={(text: string) => {
              if (errors.authentication) clearErrors('authentication');
              setValue('password', deleteFullWidth(text));
            }}
            label={t(`${SIGN_IN}.form.password.label`)}
            register={register(signInRegister('password', t))}
            maxLength={20}
          />
        </div>
        <div>
          <LinkTextContainer>
            <LinkText
              text={t(`${SIGN_IN}.navigation.forgotPassword`)}
              urlList={[
                {
                  replaceText: t(`${SIGN_IN}.navigation.forgotPassword`),
                  url: ROUTE_FORGOT_PASSWORD,
                },
              ]}
            />
          </LinkTextContainer>
        </div>
        <div>
          <ErrorMessageContainer>
            <TextErrorMessage>
              {errors?.email?.message ||
                errors?.password?.message ||
                errors.authentication?.message ||
                ''}
            </TextErrorMessage>
          </ErrorMessageContainer>
        </div>
        <div style={{ marginTop: `${25 / 16}rem` }}>
          <SubmitButtonContainer>
            <SubmitButton
              name={t(`atoms.button.submit`)}
              width={`${162 / 16}rem `}
              height={`${50 / 16}rem `}
              borderRadius={`${25 / 16}rem`}
              textSize={20}
              sizeProgress={`${20 / 16}rem`}
              disabled={!isValid}
              isLoading={state === IS_LOADING}
            />
          </SubmitButtonContainer>
        </div>
      </form>
    </Container>
  );
};

export default SignInForm;

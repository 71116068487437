// Type
import { CryptoCurrencyBuyTransactionSeedProps } from './CryptoCurrencyBuyTransactionSeed.type';

// DomainObject
import CryptoCurrencyBuyTransaction from '../../CryptoCurrencyTradeTransaction/CryptoCurrencyBuyTransaction/CryptoCurrencyBuyTransaction/CryptoCurrencyBuyTransaction';
import CryptoCurrencyTradeTransactionSeed from '../CryptoCurrencyTradeTransactionSeed/CryptoCurrencyTradeTransactionSeed';
import CryptoCurrencyTransactionIdFactory from '../../../../../idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionIdFactory';

class CryptoCurrencyBuyTransactionSeed extends CryptoCurrencyTradeTransactionSeed {
  // Multiple Inheritance by Delegation
  private cryptoCurrencyBuyTransaction: CryptoCurrencyBuyTransaction;

  constructor(props: CryptoCurrencyBuyTransactionSeedProps) {
    super(props);
    this.cryptoCurrencyBuyTransaction = new CryptoCurrencyBuyTransaction(
      CryptoCurrencyTransactionIdFactory.createEmptyId(),
      props,
    );
  }

  public getCurrencyPair = () =>
    this.cryptoCurrencyBuyTransaction.getCurrencyPair();

  public getTargetCurrency = () =>
    this.cryptoCurrencyBuyTransaction.getTargetCurrency();

  public getTradeDetail = () =>
    this.cryptoCurrencyBuyTransaction.getTradeDetail();

  public getTransactionType = () =>
    this.cryptoCurrencyBuyTransaction.getTransactionType();
}

export default CryptoCurrencyBuyTransactionSeed;

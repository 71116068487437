import { TransferSavingsAccountType } from '../../../../../types/Transfer.type';
import SavingsAccountNumber from '../../../../../value/id/SavingsAccountNumber';
import { TransferSavingsAccountTransactionProps } from './TransferSavingsAccountTransaction.type';
import { FiatCurrencyType } from '../../../../../types/FiatCurrency.type';
import TransferSavingsAccountTransactionId from '../../../../idManager/transactionId/TransferSavingsAccountTransactionId/TransferSavingsAccountTransactionId';
import Transaction from '../../../transaction/Transaction/Transaction';

abstract class TransferSavingsAccountTransaction extends Transaction {
  protected savingsAccountNumber: SavingsAccountNumber;

  protected beneficiaryAccountNumber: SavingsAccountNumber;

  protected beneficiaryAccountHolder: string;

  protected transferSavingsAccountType: TransferSavingsAccountType;

  protected amount: number;

  protected currency: FiatCurrencyType;

  protected purpose: string;

  protected fee: number;

  protected createdAt: number;

  protected updatedAt: number;

  protected props: TransferSavingsAccountTransactionProps;

  constructor(
    transferSavingsAccountTransactionId: TransferSavingsAccountTransactionId,
    props: TransferSavingsAccountTransactionProps,
  ) {
    super(transferSavingsAccountTransactionId);
    this.savingsAccountNumber = new SavingsAccountNumber(
      props.savingsAccountNumber,
    );
    this.beneficiaryAccountNumber = new SavingsAccountNumber(
      props.beneficiaryAccountNumber,
    );
    this.beneficiaryAccountHolder = props.beneficiaryAccountHolder;
    this.transferSavingsAccountType = props.transferSavingsAccountType;
    this.amount = props.amount;
    this.currency = props.currency;
    this.purpose = props.purpose;
    this.fee = props.fee;

    this.createdAt = props.createdAt ?? Date.now();
    this.updatedAt = props.updatedAt ?? Date.now();
    this.props = props;
  }

  public getProps = () => {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  };

  public getFormattedSavingsAccountNumber = () =>
    this.savingsAccountNumber.getFormattedId();

  public getFormattedBeneficiaryAccountNumber = () =>
    this.beneficiaryAccountNumber.getFormattedId(
      this.transferSavingsAccountType,
    );

  public getTransferType = () => this.transferSavingsAccountType;
}

export default TransferSavingsAccountTransaction;

import React, { FC, useContext } from 'react';
import { useNotificationCreateReadLogUseCase } from './useNotificationCreateReadLogUseCase';
import UserAuthorizedContext from '../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import NotificationLogCreateReadLogContext from './NotificationLogCreateReadLogContext';

// Error
import SystemErrorHandler from '../../../errorHandler/SystemErrorHandler';

export type NotificationLogCreateReadLogProviderProps = {
  children: React.ReactNode;
};

const NotificationLogCreateReadLogProvider: FC<NotificationLogCreateReadLogProviderProps> = ({
  children,
}: NotificationLogCreateReadLogProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    createReadLog,
  } = useNotificationCreateReadLogUseCase(userAuthorized);

  return (
    <NotificationLogCreateReadLogContext.Provider
      value={{
        requestState: state,
        createReadLog,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </NotificationLogCreateReadLogContext.Provider>
  );
};

export default NotificationLogCreateReadLogProvider;

// IService
import IResetAuthenticatorRequestUpdateService from '../../../useCases/userRequest/resetAuthenticatorRequest/IResetAuthenticatorRequestUpdateService';

// IRepository
import IResetAuthenticatorRequestRepository from '../../../domain/userRequest/resetAuthenticatorRequest/IResetAuthenticatorRequestRepository';

class ResetAuthenticatorRequestUpdateService
  implements IResetAuthenticatorRequestUpdateService {
  private resetAuthenticatorRequestUpdateRepository: IResetAuthenticatorRequestRepository;

  constructor(
    resetAuthenticatorRequestRepository: IResetAuthenticatorRequestRepository,
  ) {
    this.resetAuthenticatorRequestUpdateRepository = resetAuthenticatorRequestRepository;
  }

  public executeResetAuthenticator = async (
    id: string,
    oobCode: string,
    code: string,
  ) => {
    await this.resetAuthenticatorRequestUpdateRepository.resetAuthenticator(
      id,
      oobCode,
      code,
    );
  };
}

export default ResetAuthenticatorRequestUpdateService;

import styled from 'styled-components';
import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const ErrorMessageContainer = styled.div`
  box-sizing: border-box;
  height: ${40 / 16}rem;
  display: flex;
  align-items: center;
  padding-left: ${(1200 - 200 - 594) / 16}rem;
`;

export const CountdownTextStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  opacity: 0.5,
};

export const CountdownNumberStyle = {
  fontSize: 26,
  color: color.primary,
  fontWeight: fontWeight.bold,
};

export const LabelStyle = {
  fontSize: 24,
  color: color.primary,
  fontWeight: fontWeight.regular,
  opacity: 0.5,
};

export const CurrencyStyle = {
  fontSize: 31,
  color: color.primary,
  fontWeight: fontWeight.bold,
  opacity: 0.5,
};

export const BalanceStyle = {
  fontSize: 31,
  color: color.primary,
  fontWeight: fontWeight.bold,
};

export const MessageStyle = {
  fontSize: 22,
  color: color.text.error,
  fontWeight: fontWeight.bold,
};

export const CryptoCurrencyRatePriceCardStyle = {
  label: {
    fontSize: 27,
    color: color.primary,
    fontWeight: fontWeight.regular,
    opacity: 0.5,
  },
  arrow: {
    fontSize: 20,
    color: color.primary,
    fontWeight: fontWeight.regular,
    opacity: 0.5,
    textAlign: 'center',
  },
};

export const ButtonTextStyle = {
  label: {
    fontSize: 24,
    color: color.text.white,
    fontWeight: fontWeight.regular,
  },
  arrow: {
    fontSize: 20,
    color: color.text.white,
    fontWeight: fontWeight.regular,
    textAlign: 'center',
  },
};

export const DescriptionStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.medium,
};

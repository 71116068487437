import React, { FC, useContext } from 'react';
import TransferSavingsAccountPageStateContext from 'enhancers/pageState/transfer/TransferSavingsAccountPageState/TransferSavingsAccountPageStateContext';

// Component
import DashboardTemplate from 'components/templates/common/DashboardTemplate';
import TransferSavingsAccountTemplate from 'components/templates/transfer/savingsAccount/TransferSavingsAccountTemplate';
import TransferSavingsAccountConfirmTemplate from 'components/templates/transfer/savingsAccount/TransferSavingsAccountConfirmTemplate';
import TransferSavingsAccountCompleteTemplate from 'components/templates/transfer/savingsAccount/TransferSavingsAccountCompleteTemplate';

const TransferSavingsAccountPage: FC = () => {
  const { pageState } = useContext(TransferSavingsAccountPageStateContext);

  return (
    <DashboardTemplate>
      {
        {
          initial: <TransferSavingsAccountTemplate />,
          confirm: <TransferSavingsAccountConfirmTemplate />,
          complete: <TransferSavingsAccountCompleteTemplate />,
        }[pageState]
      }
    </DashboardTemplate>
  );
};

export default React.memo(TransferSavingsAccountPage);

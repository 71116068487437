// Lib
import { getCurrentTimeTimezone } from '../../../../utils/helpers/datetimeHelper';

// Type
import { CryptoCurrencyType } from '../../../../types/CryptoCurrency.type';
import { PrefixCryptoCurrencyTransactionId } from './CryptoCurrencyTransactionId.type';
import {
  CryptoCurrencyTransactionType,
  CryptoCurrencySellType,
} from '../../../../types/CryptoCurrencyTransaction.type';

// DomainObject
import CryptoCurrencyTransactionId from './CryptoCurrencyTransactionId';

// Error
import InvalidDataFoundError from '../../../../utils/errors/InvalidDataFoundError';

// Constant
const TRANSACTION_ID_LENGTH = 26;
class CryptoCurrencyTransactionIdFactory {
  static create = (
    cryptoCurrencyTransactionType: CryptoCurrencyTransactionType,
    commonId: string,
    currency: CryptoCurrencyType,
    target: string,
    serialNumber: string,
  ): CryptoCurrencyTransactionId => {
    const prefix =
      PrefixCryptoCurrencyTransactionId[cryptoCurrencyTransactionType];
    const now = getCurrentTimeTimezone();
    const date = now.format('YYYYMMDD');

    if (!prefix)
      throw new Error(
        `Prefix of ${cryptoCurrencyTransactionType} is not found.`,
      );

    return new CryptoCurrencyTransactionId({
      prefix,
      currency,
      date,
      target,
      commonId,
      serialNumber,
    });
  };

  static createFromRawId = (id: string, commonId: string) => {
    if (
      !(
        id.length === TRANSACTION_ID_LENGTH ||
        id.length === TRANSACTION_ID_LENGTH + 1
      )
    )
      throw new InvalidDataFoundError(
        `CryptoCurrencyTransactionId with invalid length '${id}' is  found.`,
      );

    if (id.length === TRANSACTION_ID_LENGTH)
      return new CryptoCurrencyTransactionId({
        prefix: id.substr(0, 3),
        currency: id.substr(3, 3) as CryptoCurrencyType,
        date: id.substr(6, 8),
        serialNumber: id.substr(14, 6),
        target: id.substr(20, 6),
        commonId,
      });
    return new CryptoCurrencyTransactionId({
      prefix: id.substr(0, 3),
      currency: id.substr(3, 4) as CryptoCurrencyType,
      date: id.substr(7, 8),
      serialNumber: id.substr(15, 6),
      target: id.substr(21, 6),
      commonId,
    });
  };

  static createEmptyId = (): CryptoCurrencyTransactionId => {
    const transactionType = CryptoCurrencySellType.cryptoCurrencySell;
    const commonId = '';
    const currency = CryptoCurrencyType.BTC;
    const serialNumber = '999999';
    const target = '';

    const prefix = PrefixCryptoCurrencyTransactionId[transactionType];
    const now = getCurrentTimeTimezone();
    const date = now.format('YYYYMMDD');

    return new CryptoCurrencyTransactionId({
      prefix,
      currency,
      date,
      target,
      commonId,
      serialNumber,
    });
  };
}
export default CryptoCurrencyTransactionIdFactory;

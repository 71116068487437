enum ErrorCodeType {
  // General
  CLIENT_ERROR = '400',
  UNAUTHORIZED_ERROR = '401',
  REQUEST_TIMEOUT_ERROR = '408',
  SERVER_ERROR = '500',
  AUTHENTICATION_AUTHENTICATOR_INVALID_CODE = '400-3001',

  // 40x-00xx Authentication
  AUTHENTICATION_LOGIN_INVALID_ROLE = '401-0000',
  AUTHENTICATION_LOGIN_LOGIN_FAILED = '401-0001',
  AUTHENTICATION_LOGIN_INACTIVE = '401-0002',
  AUTHENTICATION_LOGIN_REQUEST_EXPIRED = '401-0003',
  AUTHENTICATION_LOGIN_INVALID_CODE = '400-0004',

  AUTHENTICATION_FORGOT_PASSWORD_EMAIL_NOT_FOUND = '400-0005',
  AUTHENTICATION_FORGOT_PASSWORD_USER_DELETED = '400-0006',
  AUTHENTICATION_FORGOT_PASSWORD_INVALID_ROLE = '400-0007',

  AUTHENTICATION_SIGN_UP_USER_EXISTED = '400-0008',
  AUTHENTICATION_SIGN_UP_INVALID_INVITATION_CODE = '400-0009',
  AUTHENTICATION_SIGN_UP_INVALID_INVITER = '400-0010',
  AUTHENTICATION_SIGN_UP_REQUEST_NOT_FOUND = '401-0011',
  AUTHENTICATION_SIGN_UP_REQUEST_EXPIRED = '401-0012',
  AUTHENTICATION_SIGN_UP_USERNAME_EXISTED = '400-0013',
  AUTHENTICATION_RE_SIGN_UP_USER_NOT_FOUND = '400-0014',
  AUTHENTICATION_RE_SIGN_UP_INVALID_REREGISTRATION_CODE = '400-0015',
  AUTHENTICATION_RE_SIGN_UP_REQUEST_NOT_FOUND = '400-0016',
  AUTHENTICATION_RE_SIGN_UP_ALREADY_CONFIRMED = '400-0017',
  AUTHENTICATION_RE_SIGN_UP_REQUEST_EXPIRED = '401-0018',
  AUTHENTICATION_RESET_AUTHENTICATOR_EXPIRED = '400-0020',
  AUTHENTICATION_RESET_AUTHENTICATOR_NOT_FOUND = '400-0021',
  AUTHENTICATION_SIGN_UP_EMAIL_EXISTED = '400-0022',
  AUTHENTICATION_LOGIN_USER_INACTIVE = '401-0103',

  // Axios
  AXIOS_CONNECTION_TIMEOUT = 'ECONNABORTED',

  // Firebase Auth
  FIREBASE_AUTH_WRONG_PASSWORD = 'auth/wrong-password',
  FIREBASE_AUTH_NETWORK_FAILED = 'auth/network-request-failed',
  FIREBASE_AUTH_USER_NOT_FOUND = 'auth/user-not-found',

  // 40x-04xx Settings
  SETTINGS_EMAIL_UPDATE_ALREADY_IN_USE = '400-0400',
  SETTINGS_EMAIL_UPDATE_EXPIRED = '401-0401',
  SETTINGS_EMAIL_UPDATE_INVALID_OTP = '400-0402',

  // 40x-07xx ContractTimeDeposit
  CONTRACT_TIME_DEPOSIT_FORBIDDEN_READ_DEPOSIT_INFO = '400-0701',
  CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_ALREADY_EXISTS = '400-0702',
  CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION_NOT_EXISTS = '403-0703',

  // 40x-08xx SavingsAccount
  SAVINGS_ACCOUNT_INVALID_BENEFICIARY_ACCOUNT_NUMBER = '400-0801',
  SAVINGS_ACCOUNT_FORBIDDEN_READ_SAVINGS_INFO = '403-0803',
  SAVINGS_ACCOUNT_ACCOUNT_HOLDER_WITH_NO_CONTRACT_NAME = '400-0804',
  SAVINGS_ACCOUNT_INVALID_ACCOUNT_NUMBER_BY_CLIENT = '400-0805',
  SAVINGS_ACCOUNT_INVALID_ACCOUNT_NUMBER_ECB_BY_CLIENT = '400-0807',

  // 40x-09xx DebitCard
  DEBIT_CARD_REQUEST_INVALID_ISSUER = '400-0903',
  DEBIT_CARD_REQUEST_INVALID_MAP_ADDRESS = '400-0905',

  // 40x-12xx Staff
  STAFF_EMAIL_ALREADY_EXISTED = '400-1202',

  // 40x-99xx General
  GENERAL_DATA_NOT_FOUND_BY_FIELDS_CLIENT = '400-9903',

  // Reset Password
  RESET_PASSWORD_UNAUTHORIZED = 'reset_password/unauthorized',
}

export { ErrorCodeType };

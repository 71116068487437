import styled from 'styled-components';
import color from '../../../../atoms/utils/const/color';

export const SelectBoxContainerStyle = {
  backgroundColor: color.background.white,
};

export const SelectBoxContainer = styled.div`
  width: ${600 / 16}rem;
  background-color: #fff;
`;

import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import CryptoCurrencyTemplateContainer from '../../common/CryptoCurrencyTemplateContainer';
import ContentBox from '../../../../atoms/utils/layout/Box/Box/ContentBox';
import Margin from '../../../../atoms/utils/layout/Margin';
import { FlexRow } from '../../../../atoms/utils/layout/Flex';
import { FlexBoxRow } from '../../../../atoms/utils/layout/Box/FlexBox';

export interface CryptoCurrencyTradeCompleteTemplateLayoutProps {
  children: React.ReactElement[];
}

const CryptoCurrencyTradeCompleteTemplateLayout: FC<CryptoCurrencyTradeCompleteTemplateLayoutProps> = ({
  children,
}: CryptoCurrencyTradeCompleteTemplateLayoutProps) => {
  const elements = [
    'header',
    'icon',
    'contentTitle',
    'description',
    'orderIdLabel',
    'orderIdValue',
    'table',
  ];
  const [
    header,
    icon,
    contentTitle,
    description,
    orderIdLabel,
    orderIdValue,
    table,
  ] = getElementsFromKeys(children, elements);

  return (
    <CryptoCurrencyTemplateContainer>
      {header}
      <Margin top={50} />
      <ContentBox padding={100} alignItems="center">
        {icon}
        <Margin top={30} />
        {contentTitle}
        <Margin top={20} />
        <FlexRow alignItems="center">{description}</FlexRow>
        <Margin top={60} />
        <FlexBoxRow alignItems="center" justifyContent="flex-start">
          {orderIdLabel}
          <Margin left={20} />
          {orderIdValue}
        </FlexBoxRow>
        <Margin top={30} />
        {table}
      </ContentBox>
    </CryptoCurrencyTemplateContainer>
  );
};
export default CryptoCurrencyTradeCompleteTemplateLayout;

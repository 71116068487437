import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { store, persistor, sagaMiddleware } from './redux/store';
import rootSaga from './redux/saga/rootSaga';

sagaMiddleware.run(rootSaga);

//  <React.StrictMode>
ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </React.Fragment>,
  document.getElementById('root'),
);
//  </React.StrictMode>,

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Types
import { FormInputTransferSavingsAccount } from '../../../../../components/organisms/form/transfer/savingsAccount/FormTransferSavingsAccount/FormInputTransferSavingsAccount.type';

// Constant
import { INITIAL } from '../../../../../config/constants/requestState';

// DomainObject
import SavingsAccount from '../../../../../domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

export interface TransferSavingsAccountCreateContextProps {
  requestState: string;

  createTransferSavingsAccount: (
    params: FormInputTransferSavingsAccount,
    savingsAccount: SavingsAccount,
    callback?: () => void,
  ) => void;

  executeCreateTransferSavingsAccount: (
    code: string,
    callback?: () => void,
  ) => void;
}

const TransferSavingsAccountCreateContext = React.createContext<
  TransferSavingsAccountCreateContextProps
>({
  requestState: INITIAL,

  createTransferSavingsAccount: (
    params: FormInputTransferSavingsAccount,
    savingsAccount: SavingsAccount,
    callback?: () => void,
  ) => undefined,

  executeCreateTransferSavingsAccount: (code: string, callback?: () => void) =>
    undefined,
});

export default TransferSavingsAccountCreateContext;

import { useState } from 'react';
import TransferSavingsAccountTransactionSeed from './TransferSavingsAccountTransactionSeed';

export const useTransferSavingsAccountTransactionSeed = (
  defaultValue?: TransferSavingsAccountTransactionSeed,
) => {
  const [
    transferSavingsAccountTransactionSeed,
    setTransferSavingsAccountTransactionSeed,
  ] = useState<TransferSavingsAccountTransactionSeed | undefined>(defaultValue);

  return {
    transferSavingsAccountTransactionSeed,
    setTransferSavingsAccountTransactionSeed,
  };
};

import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { upperCase } from 'lodash';
import { RiFileCopy2Line } from 'react-icons/ri';
import DebitCardHistoryOptionContext from 'components/molecules/selector/tabBar/UnderBarTabBar/DebitCardHistoryTabBar/DebitCardHistoryOptionContext';
import LinksDebitCardContext from 'enhancers/storage/context/LinksDebitCardContext';
// import DebitCardStatementModalContext from '../../../../../organisms/modal/DebitCardStatementModal/DebitCardStatementModalContext';
// import DebitCardStatementViewContext from '../../../../../../enhancers/useCase/debitCard/debitCard/DebitCardStatementViewProvider/DebitCardStatementViewContext';

// Constant
import { ROUTE_TRANSFER_DEBIT_CARD } from 'config/constants/routing';

// Style
import {
  AnnualFeeLabeledTextStyle,
  BalanceStyle,
  // ButtonTextStyle,
  CurrencyStyle,
  DebitCardImageLayout,
  DescriptionStyle,
  DescriptionImpactStyle,
  HolderNameTextStyle,
  IconStyle,
  LabelStyle,
  LinkTextStyle,
  // StatementTextStyle,
} from 'components/templates/debitCard/DebitCardTemplate/content/DebitCardTemplateContent/style';

// Component
import SecureBadge from 'components/molecules/badge/SecureBadge';
import DebitCardTemplateContentLayout from 'components/templates/debitCard/DebitCardTemplate/content/DebitCardTemplateContent/DebitCardTemplateContentLayout';
import DebitCardImage from 'components/molecules/image/DebitCardImage';
import LabeledCurrencyAmount from 'components/molecules/textGroup/LabeledCurrencyAmount';
import LabeledText from 'components/molecules/textGroup/LabeledText';
import DebitCardSelectBox from 'components/molecules/selectBox/selectBox/debitCard/DebitCardSelectBox';
import { LinkTextSecondary } from 'components/atoms/text2/LinkText';
import {
  BankActionButton,
  DefaultButtonTextStyle,
} from 'components/atoms/button/Button2';
import { TextPrimary } from 'components/atoms/text2/Text2';
import CSVDownloadButton from 'components/atoms/button/CSVDownloadButton';
import DebitCardHistoryTabBar from 'components/molecules/selector/tabBar/UnderBarTabBar/DebitCardHistoryTabBar';
import TableDebitCardTransactionHistory from 'components/organisms/table/history/TableDebitCardTransactionHistory';
import TableTransferDebitCardTransactionHistory from 'components/organisms/table/history/TableTransferDebitCardTransactionHistory';

// DomainObject
import DebitCardV2 from 'domain/debitCard/debitCardV2/DebitCardV2/DebitCardV2';

// ValueObject
import TimeZonedTimeFactory from 'value/datetime/TimeZonedTimeFactory';

// CSV
import debitCardSpotTransactionsCSVScheme from 'components/organisms/csv/CSVScheme/debitCard/debitCardSpotTransactionsCSVScheme';

interface DebitCardTemplateContentProps {
  debitCardV2: DebitCardV2;
}

const TEMPLATE_ID = `templates.debitCards.DebitCardTemplate`;

const DebitCardTemplateContent: FC<DebitCardTemplateContentProps> = ({
  debitCardV2,
}: DebitCardTemplateContentProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  // TabOption
  const { historyOption } = useContext(DebitCardHistoryOptionContext);

  // debitCardFee File Url
  const { debitCardFeeFileUrl } = useContext(LinksDebitCardContext);

  // Method
  // const { fetchDebitCardStatements } = useContext(
  //   DebitCardStatementViewContext,
  // );

  // Modal
  // const { showModal } = useContext(DebitCardStatementModalContext);

  // CSV
  const { fileName, headers } = debitCardSpotTransactionsCSVScheme(t);

  // DomainObject
  const {
    balance,
    cardHolder,
    currency,
    annualFeeNextPaymentDate,
    is3DSecure,
  } = debitCardV2.getProps();

  const annualFeeNextPaymentDateFormatted = useMemo(
    () =>
      annualFeeNextPaymentDate &&
      TimeZonedTimeFactory.createZeroOClock(annualFeeNextPaymentDate).format(
        'DD/MMM/YYYY',
      ),
    [annualFeeNextPaymentDate],
  );

  return (
    <DebitCardTemplateContentLayout
      debitCardHistoryOption={historyOption}
      isAnnualFeeDateDisplay={!!annualFeeNextPaymentDate}
      is3DSecure={is3DSecure}
    >
      <DebitCardImage
        key="debitCard"
        debitCardV2={debitCardV2}
        layout={DebitCardImageLayout}
      />
      <LabeledCurrencyAmount
        key="balance"
        amount={balance}
        currency={currency}
        direction="row"
        isSymbol
        marginBetween={68}
        label={t(`atoms.label.balance`)}
        theme={{
          amount: BalanceStyle,
          currency: CurrencyStyle,
          label: LabelStyle,
        }}
      />
      <LabeledText
        key="accountHolder"
        direction="row"
        label={t(`${TEMPLATE_ID}.label.cardHolder`)}
        marginBetween={20}
        value={upperCase(cardHolder)}
        theme={{
          label: LabelStyle,
          value: HolderNameTextStyle,
        }}
      />
      <DebitCardSelectBox key="selectBox" name="debitCard" />
      <SecureBadge key="badge3dSecure" />
      <LabeledText
        key="annualFeeDate"
        direction="row"
        label={t(`${TEMPLATE_ID}.label.annualFeeDate`)}
        marginBetween={20}
        value={annualFeeNextPaymentDateFormatted ?? ''}
        theme={AnnualFeeLabeledTextStyle}
      />
      <RiFileCopy2Line key="iconCardFee" {...IconStyle} />
      <LinkTextSecondary
        key="linkCardFee"
        href={debitCardFeeFileUrl}
        target="_blank"
        theme={LinkTextStyle}
      >
        {t(`${TEMPLATE_ID}.link.cardRelatedFee`)}
      </LinkTextSecondary>
      {/* <RiFileCopy2Line
        key="statementIcon"
        color={color.icon.black}
        size={`${22 / 16}rem`}
      />  */}
      {/* <LinkTextSecondary
        key="statement"
        onClick={() => {
          fetchDebitCardStatements();
          showModal();
        }}
        theme={StatementTextStyle}
      >
        {t(`${TEMPLATE_ID}.viewStatement`)}
      </LinkTextSecondary> */}
      <BankActionButton
        key="button"
        onClick={() => {
          history.push(ROUTE_TRANSFER_DEBIT_CARD);
        }}
        height={50}
        width={220}
      >
        <TextPrimary theme={DefaultButtonTextStyle}>
          {t(`atoms.button.topUp`)}
        </TextPrimary>
      </BankActionButton>
      <CSVDownloadButton
        key="csvButton"
        height={50}
        width={220}
        headers={headers}
        fileName={fileName}
      />

      <DebitCardHistoryTabBar key="historyTabBar" />
      <TextPrimary key="cardHistoryDescription" theme={DescriptionStyle}>
        {t(`${TEMPLATE_ID}.description.cardHistory.sentence1`)}
        &nbsp;
        <TextPrimary theme={DescriptionImpactStyle}>
          {t(`${TEMPLATE_ID}.description.cardHistory.days`)}
        </TextPrimary>
        &nbsp;
        {t(`${TEMPLATE_ID}.description.cardHistory.sentence2`)}
      </TextPrimary>
      <TableDebitCardTransactionHistory key="cardHistory" />
      <TableTransferDebitCardTransactionHistory key="topUpHistory" />
    </DebitCardTemplateContentLayout>
  );
};
export default DebitCardTemplateContent;

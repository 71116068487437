// Type
import {
  CryptoCurrencyTransactionStatusType,
  CryptoCurrencyDepositType,
} from '../../../../../../../types/CryptoCurrencyTransaction.type';
import { CryptoCurrencyDepositProps } from './CryptoCurrencyDeposit.type';

// DomainObject
import CryptoCurrencySpotTransaction from '../../CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction/CryptoCurrencySpotTransaction';

// ValueObject
import CryptoCurrencyTransactionId from '../../../../../../idManager/transactionId/CryptoCurrencyTransactionId/CryptoCurrencyTransactionId';

class CryptoCurrencyDeposit extends CryptoCurrencySpotTransaction {
  private ipnId: string;

  private actualFee: number;

  private txid: string;

  private type: CryptoCurrencyDepositType;

  constructor(
    cryptoCurrencyTransactionId: CryptoCurrencyTransactionId,
    props: CryptoCurrencyDepositProps,
  ) {
    super(cryptoCurrencyTransactionId, props);
    this.ipnId = props.ipnId;
    this.actualFee = props.actualFee;
    this.txid = props.txid;
    this.type = props.type;
  }

  public getProps = () => ({
    ...super.getProps(),
    ipnId: this.ipnId,
    status: this.status,
    actualFee: this.actualFee,
    txid: this.txid,
    type: this.type,
  });

  public isCompleted = () =>
    this.status === CryptoCurrencyTransactionStatusType.confirmed;

  public getTransactionType = () =>
    CryptoCurrencyDepositType.cryptoCurrencyDeposit;
}

export default CryptoCurrencyDeposit;

import firestore from '../../client/firestoreClient';
import Collection from '../collection/Collection';
import { FIRESTORE_DATA_VERSION } from '../../../../../config/constants/firebase';
import { FirestoreDomain, CollectionName } from '../collection/Collection.type';

class NotificationsCustodyFeeAndTopUpFeeCollection extends Collection {
  collection: firestore.CollectionReference;

  constructor() {
    super();
    this.collection = firestore()
      .collection(FirestoreDomain.notification)
      .doc(FIRESTORE_DATA_VERSION)
      .collection(CollectionName.notificationsCustodyFeeAndTopUpFee);
  }
}

export default NotificationsCustodyFeeAndTopUpFeeCollection;

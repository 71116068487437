// IService
import ISignUpRequestCreateService from '../../useCases/authentication/signUp/ISignUpRequestCreateService';

// IRepository
import ISignUpRequestCreator from '../../domain/userRequest/signUpRequest/ISignUpRequestCreator';

class SignUpRequestCreateService implements ISignUpRequestCreateService {
  private signUpRequestCreator: ISignUpRequestCreator;

  constructor(signUpRequestCreator: ISignUpRequestCreator) {
    this.signUpRequestCreator = signUpRequestCreator;
  }

  public executeCreate = async (email: string, invitationCode: string) => {
    await this.signUpRequestCreator.create(email, invitationCode);
  };
}

export default SignUpRequestCreateService;

// Type
import { UserType } from '../../../../types/User.type';
import { ConsentFormProps } from './ConsentForm.type';
import { ConsentFormContentProps } from '../../../../value/verification/consentForm/ConsentFormContent/ConsentFormContent.type';

// ValueObject
import ConsentFormStepsStatus from '../../../../value/verification/consentForm/ConsentFormStepsStatus/ConsentFormStepsStatus/ConsentFormStepsStatus';
import ConsentFormStepsStatusFactory from '../../../../value/verification/consentForm/ConsentFormStepsStatus/ConsentFormStepsStatus/ConsentFormStepsStatusFactory';
import ConsentFormContent from '../../../../value/verification/consentForm/ConsentFormContent/ConsentFormContent';
import ConsentFormContentFactory from '../../../../value/verification/consentForm/ConsentFormContent/ConsentFormContentFactory';

class ConsentForm {
  private id: string;

  private username: string;

  private type: UserType;

  private consentFormStepsStatus: ConsentFormStepsStatus;

  private consentFormContent: ConsentFormContent;

  private updatedAt: number;

  private createdAt: number;

  private props: ConsentFormProps;

  constructor(id: string, props: ConsentFormProps) {
    this.id = id;
    this.props = props;
    this.username = props.username;
    this.type = props.type;
    this.consentFormContent = ConsentFormContentFactory.create(
      props.consentFormContent,
    );
    this.consentFormStepsStatus = ConsentFormStepsStatusFactory.create(
      props.consentFormStepsStatus,
    );
    this.createdAt = props.createdAt || Date.now();
    this.updatedAt = props.updatedAt || Date.now();
  }

  public getId = () => this.id;

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public getConsentFormContent = () => this.consentFormContent;

  public getConsentFormStepsStatus = () => this.consentFormStepsStatus;

  public getCommandParameter() {
    const allStatus = this.consentFormStepsStatus.getProps();

    return {
      username: this.username,
      type: this.type,
      status: allStatus.status,
      consentForm: {
        ...this.consentFormContent.getCommandParameter(),
        status: allStatus.statusConsentForm,
      },
      twoFactorAuthenticationAppSettings: {
        status: allStatus.statusTwoFactorAuthenticationAppSettings,
      },
      waitingConsentFormReview: {
        status: allStatus.statusWaitingConsentFormReview,
      },
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public isReviewing = () => this.consentFormStepsStatus.isReviewing();

  public updateConsentForm = (props: ConsentFormContentProps) => {
    const consentFormContent = this.consentFormContent.updateProps(props);

    return new ConsentForm(this.id, {
      ...this.getProps(),
      consentFormContent: consentFormContent.getProps(),
    });
  };
}
export default ConsentForm;

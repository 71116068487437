import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
import { TransferSavingsAccountType } from '../../../../types/Transfer.type';
import { TransactionIdProps } from '../TransactionId/TransactionId.type';

export interface TransferSavingsAccountTransactionIdProps
  extends TransactionIdProps {
  currency: FiatCurrencyType;
}

export const PrefixTransferSavingsAccountTransactionId: {
  [key: string]: string;
} = {
  [TransferSavingsAccountType.forbes]: 'TSAF',
};

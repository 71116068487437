import React, { FC, useCallback, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuthenticatorContext from '../../../../../domain/authenticator/authenticator/Authenticator/AuthenticatorContext';
import TwoFactorAuthAppSettingsSubmitContext from '../../../../../enhancers/useCase/verification/consentForm/TwoFactorAuthAppSettingsSubmitProvider/TwoFactorAuthAppSettingsSubmitContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Type
import { FormInputTwoFactorAuthCode } from './FormTwoFactorAuthAppSettings.type';

// Constant
import { ROUTE_KYC_STATUS } from '../../../../../config/constants/routing';
import { IS_LOADING } from '../../../../../config/constants/requestState';

// Component
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from '../../../../atoms/button/Button2';
import LabelSendIcon from '../../../../atoms/button/ButtonLabel/LabelSendIcon';
import GoogleAuthenticatorDownload from './GoogleAuthenticatorDownload';
import AccountKeyDetail from './AccountKeyDetail';
import FormTwoFactorAuthCode from './FormTwoFactorAuthCode';
import FormTwoFactorAuthAppSettingsLayout from './FormTwoFactorAuthAppSettingsLayout';

const FormTwoFactorAuthAppSettings: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  // DomainObject
  const { authenticator } = useContext(AuthenticatorContext);
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // Method
  const { submitTwoFactorAuthAppSettings, state } = useContext(
    TwoFactorAuthAppSettingsSubmitContext,
  );

  /* *
   *
   *  Form
   *
   * */

  const methods = useForm<FormInputTwoFactorAuthCode & { validCode: string }>({
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  /* *
   *
   *  Event
   *
   * */

  const onSubmit = useCallback(
    async (formInput: FormInputTwoFactorAuthCode) => {
      submitTwoFactorAuthAppSettings(formInput.code, () => {
        history.push(ROUTE_KYC_STATUS);
      });
    },
    // eslint-disable-next-line
    [submitTwoFactorAuthAppSettings],
  );

  // Props
  const accountName = userAuthorized?.getProps().email;
  const accountKey = authenticator?.getProps().accountKey;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTwoFactorAuthAppSettingsLayout>
          <GoogleAuthenticatorDownload key="downloadApp" />
          <AccountKeyDetail
            key="accountKey"
            accountKey={accountKey}
            accountName={accountName}
          />
          <FormTwoFactorAuthCode key="form" state={state} />
          <SubmitButton
            key="button"
            disabled={!isValid}
            height={75}
            isLoading={state === IS_LOADING}
            theme={{ borderRadius: `${10 / 16}rem` }}
            width={'100%'}
          >
            <LabelSendIcon
              iconSize={26}
              iconStyle={{ color: 'white' }}
              label={t(`atoms.button.submit`)}
              marginBetween={8}
              textStyle={DefaultButtonTextStyle}
            />
          </SubmitButton>
        </FormTwoFactorAuthAppSettingsLayout>
      </form>
    </FormProvider>
  );
};

export default FormTwoFactorAuthAppSettings;

import React, { FC } from 'react';
import ArrowIcon from '../../../atoms/icon/ArrowIcon';
import LinkText from '../LinkText';
import {
  Container,
  ArrowIconContainer,
  NavigationTextContainer,
} from './style';
import fontColor from '../../../../fonts/color.json';
import fontWeight from '../../../../fonts/fontWeight.json';

export interface BreadcrumbProps {
  text: string;
  pixel: number;
  textFontWeight?: number;
  fontFamilyType?: 'primary' | 'sub';
  color?: string;
  urlList: Array<{ replaceText: string; url: string }>;
  lineHeight?: string;
  iconSize?: number | string;
}

const Breadcrumb: FC<BreadcrumbProps> = ({
  text,
  pixel = 18,
  textFontWeight = fontWeight.regular,
  fontFamilyType = 'primary',
  color = fontColor.textBright,
  urlList,
  iconSize = 16,
}: BreadcrumbProps) => {
  return (
    <Container>
      <ArrowIconContainer>
        <ArrowIcon type="forward" size={iconSize} color={color} />
      </ArrowIconContainer>
      <NavigationTextContainer>
        <LinkText
          text={text}
          urlList={urlList}
          color={color}
          pixel={pixel}
          textFontWeight={textFontWeight}
          fontFamilyType={fontFamilyType}
        />
      </NavigationTextContainer>
    </Container>
  );
};

export default Breadcrumb;

import styled from 'styled-components';
import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

export const TransferDetailContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-left: ${20 / 16}rem;
`;

export const FormInputGroupContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-left: ${20 / 16}rem;
  border-left: ${6 / 16}rem solid ${color.background.ghostWhite};
`;

export const LabelStyle = {
  fontSize: 21,
  color: color.primary,
  fontWeight: fontWeight.medium,
};

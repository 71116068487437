// Helper
import { convertMoneyFormat } from '../../../../../../utils/helpers/currencyHelper';

// Type
import { TypeOfT } from '../../../../../../types/typeof/Translation.type';

// DomainObject
import CryptoCurrencyTradeTransactions from '../../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransactions/CryptoCurrencyTradeTransactions';
import CryptoCurrencyTradeTransaction from '../../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction';
import CryptoCurrencySellTransaction from '../../../../../../domain/transaction/cryptoCurrencyTransaction/cryptoCurrencyTradeTransaction/CryptoCurrencyTradeTransaction/CryptoCurrencySellTransaction/CryptoCurrencySellTransaction/CryptoCurrencySellTransaction';

// ValueObject
import TimeZonedTime from '../../../../../../value/datetime/TimeZonedTime';

// Constant
const ORGANISM_ID = `organisms.table.TableCryptoCurrencyTransactionHistory`;

type Object = { [key: string]: string };

export interface TdCryptoCurrencyTradeHistory extends Object {
  createdAt: string;
  transactionType: string;
  deposit: string;
  withdrawal: string;
  fee: string;
  rate: string;
  status: string;
  orderId: string;
}

class TableCryptoCurrencyTradeHistoryMapper {
  static domainToTd = (
    transactions: CryptoCurrencyTradeTransactions,
    t: TypeOfT,
  ) => {
    const converter = (
      cryptoCurrencyTradeTransaction: CryptoCurrencyTradeTransaction,
    ) => {
      // Preparation
      const id = cryptoCurrencyTradeTransaction.getRawId();
      const {
        createdAt,
        amount,
        rate,
      } = cryptoCurrencyTradeTransaction.getProps();

      const targetCurrency = cryptoCurrencyTradeTransaction.getTargetCurrency();
      const currencyPair = cryptoCurrencyTradeTransaction.getCurrencyPair();

      // Conversion
      const tdCreatedAt = new TimeZonedTime(createdAt).getTwoLinesDateTime();
      const [tdTransactionType, tdDeposit, tdWithdrawal] =
        cryptoCurrencyTradeTransaction instanceof CryptoCurrencySellTransaction
          ? [
              t(`${ORGANISM_ID}.td.transactionType.sell`),
              '',
              convertMoneyFormat(amount, targetCurrency),
            ]
          : [
              t(`${ORGANISM_ID}.td.transactionType.buy`),
              convertMoneyFormat(amount, targetCurrency),
              '',
            ];
      const tdFee = convertMoneyFormat(0, targetCurrency);

      const tdRate = convertMoneyFormat(rate, currencyPair);

      // Mapping
      return {
        createdAt: tdCreatedAt,
        transactionType: tdTransactionType,
        deposit: tdDeposit,
        withdrawal: tdWithdrawal,
        fee: tdFee,
        status: 'completed',
        orderId: id,
        rate: tdRate,
      } as TdCryptoCurrencyTradeHistory;
    };

    return transactions.map<TdCryptoCurrencyTradeHistory>(converter);
  };
}

export default TableCryptoCurrencyTradeHistoryMapper;

// Functions
import FirebasePrivateFunctions from '../../../../infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';
import {
  // TRANSACTION_DEBIT_CARD_FETCH_SPOT_RAW_ALL_BY_DEBIT_CARD_NUMBER,
  TRANSACTION_DEBIT_CARD_FETCH_SPOT_RAW_ALL_BY_CARD_ID,
} from '../../../../infrastructure/firebase/firebaseFunctions/endpoint';

// Type
import { DebitCardSpotTransactionV2RawDataType } from '../../../../domain/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardSpotTransactionV2/DebitCardSpotTransactionV2/DebitCardSpotTransactionV2.type';

// IRepository
import IDebitCardSpotTransactionV2Repository from '../../../../domain/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardSpotTransactionV2/IDebitCardSpotTransactionV2Repository';

// Mapper
// import DebitCardDepositFunctionsMapper from './mapper/DebitCardDepositFunctionsMapper';
// import DebitCardWithdrawalFunctionsMapper from './mapper/DebitCardWithdrawalFunctionsMapper';

// DomainObject
// import DebitCardDepositV2s from '../../../../domain/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardDepositV2/DebitCardDepositV2s/DebitCardDepositV2s';
// import DebitCardWithdrawalV2s from '../../../../domain/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardWithdrawalV2/DebitCardWithdrawalV2s/DebitCardWithdrawalV2s';
import DebitCardSpotTransactionV2s from '../../../../domain/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardSpotTransactionV2/DebitCardSpotTransactionV2s/DebitCardSpotTransactionV2s';
import DebitCardSpotTransactionV2Factory from '../../../../domain/transaction/debitCardTransaction/debitCardSpotTransaction/DebitCardSpotTransactionV2/DebitCardSpotTransactionV2/DebitCardSpotTransactionV2Factory';

// ValueObject
import DebitCardTransactionIdFactory from '../../../../domain/idManager/transactionId/DebitCardTransactionId/DebitCardTransactionIdFactory';

class DebitCardSpotTransactionFunctions
  extends FirebasePrivateFunctions
  implements IDebitCardSpotTransactionV2Repository {
  public findAllByCardId = async (cardId: string) => {
    const endpoint = TRANSACTION_DEBIT_CARD_FETCH_SPOT_RAW_ALL_BY_CARD_ID.replace(
      /:cardId/g,
      cardId,
    );

    const response = await this.privateGET(endpoint);

    const rows = response.data.data as Array<
      DebitCardSpotTransactionV2RawDataType
    >;

    const fakeId = 'WBCCUSD20200101999999999999';

    return rows.reduce((transactions, row) => {
      const debitCardTransactionId = DebitCardTransactionIdFactory.createFromRawId(
        // row.id,
        fakeId,
        row.props.commonId,
      );

      const transaction = DebitCardSpotTransactionV2Factory.createByTransactionType(
        debitCardTransactionId,
        row.props,
        row.props.type,
      );

      transactions.add(transaction);

      return transactions;
    }, new DebitCardSpotTransactionV2s());
  };
}
export default DebitCardSpotTransactionFunctions;

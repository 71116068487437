// React
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RiBookOpenFill } from 'react-icons/ri';
import ManualFilesContext from '../../../../enhancers/storage/context/ManualFilesContext';

// Style
import { LinkTextStyle, SubTitleStyle } from './style';

// LayoutComponent
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';

// Component
import { LinkTextSecondary } from '../../../atoms/text2/LinkText';
import { TextPrimary } from '../../../atoms/text2/Text2';
import Margin from '../../../atoms/utils/layout/Margin';
import ManualListLayout from './ManualListLayout';

export interface ManualListProps {
  fileKey: string;
}

const ORGANISM_ID = `organisms.list.ManualList.subTitle`;

const ManualList: FC<ManualListProps> = ({ fileKey }: ManualListProps) => {
  const { files } = useContext(ManualFilesContext);
  const { t } = useTranslation();

  return (
    <ManualListLayout>
      <RiBookOpenFill key="icon" color={`#000`} size={20} />
      <TextPrimary key="subTitle" theme={SubTitleStyle}>
        {t(`${ORGANISM_ID}.${fileKey}`)}
      </TextPrimary>
      <FlexBoxColumn key="list">
        {files[fileKey]?.map((file) => (
          <Margin bottom={30} key={file.fileName}>
            <LinkTextSecondary
              theme={LinkTextStyle}
              href={file.url}
              target="_blank"
            >
              {file.fileName}
            </LinkTextSecondary>
          </Margin>
        ))}
      </FlexBoxColumn>
    </ManualListLayout>
  );
};

export default ManualList;

// Type
import { FiatCurrencyType } from 'types/FiatCurrency.type';
import {
  TransferDebitCardStatusType,
  TransferDebitCardType,
} from '../../../../../types/Transfer.type';
import { TypeOfT } from '../../../../../types/typeof/Translation.type';

// DomainObject
import TransferDebitCardTransactionV2 from '../../../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2/TransferDebitCardTransactionV2';
import TransferDebitCardTransactionV2s from '../../../../../domain/transaction/transferDebitCardTransaction/TransferDebitCardTransactionV2s/TransferDebitCardTransactionV2s';

// ValueObject
import TimeZonedTime from '../../../../../value/datetime/TimeZonedTime';

// ID
const ORGANISM_ID = `organisms.table.TableTransferDebitCardTransactionHistory`;

type Object = {
  [key: string]:
    | string
    | number
    | TransferDebitCardStatusType
    | TransferDebitCardType;
};

export interface TdTransferDebitCardTransactionHistory extends Object {
  id: string;
  createdAtString: string;
  createdAt: number;
  updatedAtString: string;
  updatedAt: number;
  amount: number;
  transferType: TransferDebitCardType;
  transferTypeString: string;
  status: TransferDebitCardStatusType;
  statusString: string;
  currency: FiatCurrencyType;
}

class TableTransferDebitCardTransactionHistoryMapper {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static domainToTd = (
    transferDebitCardTransactions: TransferDebitCardTransactionV2s,
    t: TypeOfT,
  ) => {
    const converter = (
      transferDebitCardTransaction: TransferDebitCardTransactionV2,
    ) => {
      const {
        createdAt,
        updatedAt,
        amount,
        currency,
        transferType,
        status,
      } = transferDebitCardTransaction.getProps();
      const id = transferDebitCardTransaction.getRawId();

      const tdCreatedAtString = new TimeZonedTime(
        createdAt,
      ).getTwoLinesDateTime();
      const tdUpdatedAtString = new TimeZonedTime(
        updatedAt,
      ).getTwoLinesDateTime();

      const transferTypeString = t(
        `${ORGANISM_ID}.td.transferType.${transferType}`,
      );

      const tdStatusString = t(`${ORGANISM_ID}.td.status.${status}`);

      return {
        id,
        createdAtString: tdCreatedAtString,
        createdAt,
        updatedAtString: tdUpdatedAtString,
        updatedAt,
        transferType,
        transferTypeString,
        amount,
        currency,
        status,
        statusString: tdStatusString,
      } as TdTransferDebitCardTransactionHistory;
    };

    return transferDebitCardTransactions.map<
      TdTransferDebitCardTransactionHistory
    >(converter);
  };
}

export default TableTransferDebitCardTransactionHistoryMapper;

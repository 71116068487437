import { useState } from 'react';
import CryptoCurrenciesAvailable from './CryptoCurrenciesAvailable';

export const useCryptoCurrenciesAvailable = (
  defaultValue?: CryptoCurrenciesAvailable,
) => {
  const [cryptoCurrenciesAvailable, setCryptoCurrenciesAvailable] = useState<
    CryptoCurrenciesAvailable
  >(defaultValue || new CryptoCurrenciesAvailable());

  return {
    cryptoCurrenciesAvailable,
    setCryptoCurrenciesAvailable,
  };
};

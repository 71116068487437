import { Reducer, AnyAction } from 'redux';
import { SignInUserAction } from '../actions/signInUserActions';
import SignInUserActionType from '../constants/signInUser.constant';
import { UserAuthorizedProps } from '../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized.type';

export interface SignInUserState {
  id: string;
  signInUserProps?: UserAuthorizedProps;
}

export const initialState: SignInUserState = {
  id: '',
  signInUserProps: undefined,
};

const SignInUserReducer: Reducer<SignInUserState, SignInUserAction> = (
  state: SignInUserState = initialState,
  action: AnyAction,
): SignInUserState => {
  switch (action.type) {
    case SignInUserActionType.SET_SIGN_IN_USER:
      return {
        ...state,
        ...action.payload,
      };
    case SignInUserActionType.INITIALIZE_SIGN_IN_USER:
      return initialState;
    default:
      return state;
  }
};

export default SignInUserReducer;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UpdatePasswordContext } from '../../../../enhancers/settings/UpdatePasswordProvider/UpdatePasswordContext';
import { INITIAL } from '../../../../config/constants/requestState';
import {
  PASSWORD_EDIT,
  PASSWORD_EDIT_COMPLETE,
} from '../../../../config/constants/pageId.json';
import SettingsTemplate from '../SettingsTemplateContainer';
import { TitleWithBackLink } from '../../../molecules/text/TitleWithActionLink';
import { NotificationContainer, TitleStyle, DescriptionStyle } from './style';
import NotificationSuccess from '../../../organisms/notification/NotificationSuccess';

const PasswordEditCompleteTemplate: FC = () => {
  const { t } = useTranslation();
  const { setUpdatePasswordState } = useContext(UpdatePasswordContext);

  return (
    <SettingsTemplate>
      <TitleWithBackLink
        title={t(`${PASSWORD_EDIT}.title`)}
        onClick={() => {
          setUpdatePasswordState(INITIAL);
        }}
      />
      <div style={{ marginTop: `${50 / 16}rem` }}>
        <NotificationContainer>
          <NotificationSuccess
            title={t(`${PASSWORD_EDIT_COMPLETE}.notification.title`)}
            titleStyle={TitleStyle}
            descriptionStyle={DescriptionStyle}
            description={t(
              `${PASSWORD_EDIT_COMPLETE}.notification.description`,
            )}
          />
        </NotificationContainer>
      </div>
    </SettingsTemplate>
  );
};

export default PasswordEditCompleteTemplate;

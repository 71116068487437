import color from '../../../../../atoms/utils/const/color';
import fontWeight from '../../../../../atoms/utils/const/fontWeight';

export const CodeBoxStyle = {
  border: `${1 / 16}rem solid ${color.border.lightBlue}`,
};

export const CodeTextStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const LabelStyle = {
  fontSize: 18,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

export const SuccessMessageStyle = {
  fontSize: 18,
  color: color.success,
  fontWeight: fontWeight.regular,
};

export const TitleTextStyle = {
  fontSize: 24,
  color: color.primary,
  fontWeight: fontWeight.bold,
};

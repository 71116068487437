import Joi from '@hapi/joi';

// Type
import { KycStatusType } from '../../../../../types/KycRequest.type';

export interface KycStepStatusProps {
  status: KycStatusType;

  personalInformation: KycStatusType;

  proofOfResidency: KycStatusType;

  waitingReview: KycStatusType;
}

export const KycStepStatusPropsFormat = {
  status: Object.keys(KycStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),

  personalInformation: Object.keys(KycStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),

  proofOfResidency: Object.keys(KycStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),

  waitingReview: Object.keys(KycStatusType)
    .reduce((joi, status) => joi.valid(status), Joi.string())
    .required(),
};

export const KycStepStatusInitialProps = {
  status: KycStatusType.initial,

  personalInformation: KycStatusType.initial,

  proofOfResidency: KycStatusType.disabled,

  waitingReview: KycStatusType.disabled,
};

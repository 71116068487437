// Type
import { NotificationLogType } from '../../../../types/Notification.type';
import { NotificationLogProps } from './NotificationLog.type';

abstract class NotificationLog {
  protected id: string;

  protected isRead: boolean;

  protected type: NotificationLogType;

  protected createdAt: number;

  protected updatedAt: number;

  protected props: NotificationLogProps;

  constructor(id: string, props: NotificationLogProps) {
    this.id = id;
    this.isRead = props.isRead;
    this.type = props.type;
    this.createdAt = props.createdAt ?? Date.now();
    this.updatedAt = props.updatedAt ?? Date.now();
    this.props = props;
  }

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}

export default NotificationLog;

import { useState } from 'react';
import TimeDeposits from './TimeDeposits';

export const useTimeDeposits = (defaultValue?: TimeDeposits) => {
  const [timeDeposits, setTimeDeposits] = useState<TimeDeposits>(
    defaultValue || new TimeDeposits(),
  );

  return {
    timeDeposits,
    setTimeDeposits,
  };
};

// React
import React, { FC } from 'react';
import { convertMoneyFormat } from '../../../../../utils/helpers/currencyHelper';

// Type
import { CurrencyType } from '../../../../../types/Currency.type';

// Components
import Box from '../../../../atoms/utils/layout/Box/Box';
import LineBreakReplaced from '../../../../atoms/text2/LineBreakReplaced';

export interface CurrencyAmountTdBoxProps {
  currency: CurrencyType;
  number: number | undefined;
}

const CurrencyAmountTdBox: FC<CurrencyAmountTdBoxProps> = ({
  currency,
  number,
}: CurrencyAmountTdBoxProps) => {
  return (
    <Box
      width="100%"
      height="100%"
      textAlign="right"
      paddingRight={13}
      paddingTop={13}
    >
      {number !== undefined && (
        <LineBreakReplaced text={convertMoneyFormat(number, currency)} />
      )}
    </Box>
  );
};

export default CurrencyAmountTdBox;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { TransactionType } from '../../../../../../types/Transaction.type';

export interface BankTransactionOptionContextProps {
  transactionOption: TransactionType;
  setTransactionOption: (type: TransactionType) => void;
}

const BankTransactionOptionContext = React.createContext<
  BankTransactionOptionContextProps
>({
  transactionOption: TransactionType.deposit,
  setTransactionOption: (type: TransactionType) => undefined,
});

export default BankTransactionOptionContext;
